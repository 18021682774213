/**
 * @file Created on Thu Sep 06 2018
 * @author SKu
 */

import {
  AgencyTypeEnum,
  CodebookLookupSequence,
  PricingPermissions,
  ResearchTypeEnum,
  StructPropBuilder,
  UploadTypeEnum,
  UploadValidityEnum,
  ValidationError,
} from '@logio/common-be-fe';
import {
  BoxDefault,
  Button,
  ButtonColor,
  ButtonLoading,
  ConfirmBox,
  Field,
  FileUploadAdapter,
  Form,
  FormFieldTypeEnum,
  FormGroup,
  FormGroupInline,
  getPath,
  Icon,
  IconType,
  LoadingState,
  Modal,
  Page,
  ProgressBar,
  translate,
  withPermission,
} from '@logio/common-fe';
import {observer, Observer} from 'mobx-react';
import * as React from 'react';
import {Col, Row} from 'react-flexbox-grid';
import {RouteComponentProps, withRouter} from 'react-router';
import DataUploadResult from '../../../components/DataUploadResult/DataUploadResult';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {
  DataUploadModalEnum,
  DataUploadNewPageStore,
} from '../../../stores/pages/DataManagement/DataUpload/DataUploadNewPageStore';

export interface Props {
  store: DataUploadNewPageStore;
}

/**
 * To avoid "if hell"
 * I have created different modals for each workflow state
 * Like: Progress, Confirmation, Uploading or Sensitivity uploading
 */
@observer
class DataUploadNewPage extends React.Component<RouteComponentProps<DataUploadNewPage>> {
  store = new DataUploadNewPageStore(this.props.history.push);

  componentWillMount() {
    this.store.loadingState = LoadingState.Success;
  }

  render() {
    return (
      <Page
        titleKey={PageNamesEnum.DataUploadNew}
        loadingState={this.store.loadingState}
        backButtonPath={getPath(PagePathsEnum.DataUpload)}
      >
        <Observer>
          {() => (
            <>
              <DataUploadNewForm store={this.store} />
              <DataUploadNewModal store={this.store} />
              <DataUploadNewProgressModal store={this.store} />
              <DataUploadNewApproveConfirmBox store={this.store} />
              <DataUploadNewRejectConfirmBox store={this.store} />

              <DataUploadNewSensitivityModal store={this.store} />
              <DataUploadNewSensitivityConfirmBox store={this.store} />

              <DataUploadNewFamilyModal store={this.store} />
              <DataUploadNewFamilyConfirmBox store={this.store} />
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

export default withRouter(DataUploadNewPage);

/** New upload form */
export const DataUploadNewForm: React.FC<Props> = observer(({store}) => {
  const bld = new StructPropBuilder('upload');
  const descriptions = {
    researchName: bld.str('researchName'),
    researchType: bld.opt(
      bld.senum<ResearchTypeEnum>('researchType', ResearchTypeEnum, CodebookLookupSequence.ResearchType),
    ),
    uploadType: bld.senum<UploadTypeEnum>('uploadType', UploadTypeEnum, CodebookLookupSequence.UploadType),
    agencyType: bld.senum<AgencyTypeEnum>('agencyType', AgencyTypeEnum, CodebookLookupSequence.AgencyType),
  };

  const getInitialValues = () => {
    const defaultInitialValues = {uploadType: UploadTypeEnum.AGENCY, agencyType: AgencyTypeEnum.AC_Nielsen};
    const priceSensitivityInitialValues = {uploadType: UploadTypeEnum.PRICE_SENSITIVITY};

    return withPermission([PricingPermissions.DATA_UPLOAD_COMPETITOR_EDIT])
      ? defaultInitialValues
      : priceSensitivityInitialValues;
  };

  const hideResearchTypeFieldProps = {uploadType: [
    UploadTypeEnum.PRICE_SENSITIVITY,
    UploadTypeEnum.FAMILY,
  ]};
  const hideAgencyTypeFieldProps = {uploadType: [
    UploadTypeEnum.WEBSCRAPING,
    UploadTypeEnum.PRICE_SENSITIVITY,
    UploadTypeEnum.FAMILY,
  ]};

  const validateUploadComponent = (value: any) => {
    if (!value.name.endsWith('.csv')) {
      return new ValidationError(translate('error_File_upload_wrong_extension', '.csv'));
    } else if (!value) {
      return new ValidationError('err-required');
    }

    return  null;
  };

  return (
    <Row>
      {/* This will render downloading modal when exported file is ready, initialized by store.downloadTemplate */}
      {store.downloadStore.renderModal()}
      <Col md={9}>
        <Form
          descriptions={descriptions}
          onSubmit={store.onUploadSubmit}
          initialValues={getInitialValues()}
          onChange={store.onFormChange}
        >
          <Field name={'uploadType'} fieldType={FormFieldTypeEnum.RadioButton} />
          <Field name={'researchName'} />
          <Field name={'researchType'} fieldHide={hideResearchTypeFieldProps} />
          <Field name={'agencyType'} fieldHide={hideAgencyTypeFieldProps} />
          <Field
            name="dataFile"
            component={(params) => FileUploadAdapter('dataFile')(params)}
            validate={validateUploadComponent}
          />
          <FormGroup noLabel>
            <Button>{translate('upload')}</Button>
          </FormGroup>
        </Form>
      </Col>

      <Col md={3}>
        <BoxDefault>
          <p>{translate(`DataUpload_downloadTemplate_msg: ${store.dataUploadType}`)}:</p>
          <ButtonLoading
            isLoading={store.isTemplateDownloading}
            buttonColor={ButtonColor.Transparent}
            iconLeft
            onClick={store.downloadTemplate}
          >
            <Icon iconType={IconType.document} />
            {translate('DataUpload_downloadTemplate')}
          </ButtonLoading>
        </BoxDefault>
      </Col>
    </Row>
  );
});

/** Renders Modal if polling is on */
export const DataUploadNewProgressModal: React.FC<Props> = observer(({store}) => {
  return (
    <Modal title={translate('data-upload-progress')} hidden={store.isModalHidden(DataUploadModalEnum.UPLOAD_PROGRESS)}>
      <ProgressBar percentage={store.progress} label={`${store.dataUploadState}-in-progress`} />
    </Modal>
  );
});

/** Renders modal to show default upload info */
export const DataUploadNewModal: React.FC<Props> = observer(({store}) => {
  const validButtons = (
    <FormGroupInline right>
      <Button buttonColor={ButtonColor.Light} onClick={store.getOpenModalEvent(DataUploadModalEnum.CONFIRM_REJECT)}>
        {translate('Reject')}
      </Button>
      <Button onClick={store.getOpenModalEvent(DataUploadModalEnum.CONFIRM_APPROVE)}>{translate('Approve')}</Button>
    </FormGroupInline>
  );

  const invalidButtons = (
    <Button buttonColor={ButtonColor.Light} onClick={store.hideModal}>
      {translate('Close')}
    </Button>
  );

  return (
    <Modal title={translate('data-upload-new')} hidden={store.isModalHidden(DataUploadModalEnum.UPLOAD)}>
      {store.dataUpload && (
        <>
          <DataUploadResult uploadedData={store.dataUpload} />
          {store.dataUpload.validity === UploadValidityEnum.VALID ? validButtons : invalidButtons}
        </>
      )}
    </Modal>
  );
});

/** Renders modal to show sensitivity upload info */
export const DataUploadNewSensitivityModal: React.FC<Props> = observer(({store}) => {
  const redirect = () => {
    store.historyPush(getPath(PagePathsEnum.DataUpload));
  };

  return (
    <Modal title={translate('data-upload-new')} hidden={store.isModalHidden(DataUploadModalEnum.UPLOAD_SENSITIVITY)}>
      {store.dataUpload && (
        <>
          <DataUploadResult uploadedData={store.dataUpload} />
          <Button buttonColor={ButtonColor.Light} onClick={redirect}>
            {translate('Close')}
          </Button>
        </>
      )}
    </Modal>
  );
});

/** Renders modal to show family upload info */
export const DataUploadNewFamilyModal: React.FC<Props> = observer(({store}) => {
  const redirect = () => {
    store.historyPush(getPath(PagePathsEnum.DataUpload));
  };

  return (
    <Modal title={translate('data-upload-new')} hidden={store.isModalHidden(DataUploadModalEnum.UPLOAD_FAMILY)}>
      {store.dataUpload && (
        <>
          <DataUploadResult uploadedData={store.dataUpload} />
          <Button buttonColor={ButtonColor.Light} onClick={redirect}>
            {translate('Close')}
          </Button>
        </>
      )}
    </Modal>
  );
});

/** Default approve confirmation */
export const DataUploadNewApproveConfirmBox: React.FC<Props> = observer(({store}) => {
  return (
    <ConfirmBox
      hidden={store.isModalHidden(DataUploadModalEnum.CONFIRM_APPROVE)}
      title={translate('upload-approve-title')}
      onAccept={store.onApprove}
      close={store.getOpenModalEvent(DataUploadModalEnum.UPLOAD)}
    >
      <div className="mb1">{`${translate('upload-approve-confirmation')}`}</div>
    </ConfirmBox>
  );
});

/** Default reject confirmation */
export const DataUploadNewRejectConfirmBox: React.FC<Props> = observer(({store}) => {
  return (
    <ConfirmBox
      hidden={store.isModalHidden(DataUploadModalEnum.CONFIRM_REJECT)}
      title={translate('upload-reject-title')}
      onAccept={store.onReject}
      close={store.getOpenModalEvent(DataUploadModalEnum.UPLOAD)}
    >
      <div className="mb1">{`${translate('upload-reject-confirmation')}`}</div>
    </ConfirmBox>
  );
});

/** Price sensitivity upload confirmation */
export const DataUploadNewSensitivityConfirmBox: React.FC<Props> = observer(({store}) => {
  return (
    <ConfirmBox
      hidden={store.isModalHidden(DataUploadModalEnum.CONFIRM_SENSITIVITY)}
      title={translate('sensitivity-confirm-title')}
      onAccept={store.onSensitivityConfirm}
      close={store.hideModal}
    >
      <div className="mb1">
        {`${translate('sensitivity-confirm-confirmation', store.sensitivityData && store.sensitivityData.name)}`}
      </div>
    </ConfirmBox>
  );
});

/** Price sensitivity upload confirmation */
export const DataUploadNewFamilyConfirmBox: React.FC<Props> = observer(({store}) => {
  return (
    <ConfirmBox
      hidden={store.isModalHidden(DataUploadModalEnum.CONFIRM_FAMILY)}
      title={translate('family-confirm-title')}
      onAccept={store.onFamilyConfirm}
      close={store.hideModal}
    >
      <div className="mb1">
        {`${translate('family-confirm-confirmation', store.familyData && store.familyData.name)}`}
      </div>
    </ConfirmBox>
  );
});
