import { Utils } from '@logio/common-be-fe';
/**
 * Converts array of any items to map
 * where default key - item.id,
 * but could be changed with keyName param
 * if passed, key will be item[keyName]
 * Example: keyName - productId => map key will be item.productId
 * @param items any entity that has id on it
 * @param keyName map key property name
 * @returns Map<item[keyName], item>
 */

export function arrayToMap(items) {
  var keyName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'id';
  var list = new Map();
  items.map(function (item) {
    var key = item[keyName];

    if (!Utils.isString(key)) {
      throw new Error("Value with keyName ".concat(keyName, " is ").concat(typeof key));
    }

    list.set(key, item);
  });
  return list;
}