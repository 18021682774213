/**
 * @author VBr
 */

import {
  CleaningSettings,
  FamilySettings,
  GlobalSettings,
  OutlierPriceDetectionSettings,
  PromoPriceDetectionSettings,
} from '@logio/common-be-fe';
import {GlobalSettingsStore, LoadingState, PageStore, rootStore, StoreName, translate, KeycloakStore} from '@logio/common-fe';
import {action, computed} from 'mobx';

export class ConfigurationsSettingsPageStore extends PageStore {
  /** Get dependant stores */
  private globalSettingsStore = rootStore.getStore(StoreName.GlobalSettings) as GlobalSettingsStore;
  keycloakStore = rootStore.getStore(StoreName.Keycloak) as KeycloakStore; 

  /** Extract all desired values from domain object structure and returns them as a flat object for final form */
  @computed
  get initialValues() {
    const id = this.globalSettingsStore.terms.id;
    const {
      impactHistoryLength,
      finalPriceDefault,
      sensitivityDefaultId,
      priceStickerLimit,
      missingCompetitorPrice,
    } = this.globalSettingsStore.terms;

    const {leaderMaxHistoryLength, leaderFrequencyRecalculation} = this.globalSettingsStore.terms.familySettings;

    const {
      referencePriceChangeIndex,
      competitorReferencePriceChangeIndex,
      internalVsCompetitorReferencePriceChangeIndex,
      extremePriceChangeIndex,
      agencyResearchWeight,
      webscrapingWeight,
    } = this.globalSettingsStore.terms.cleaningSettings;

    const {
      outlierHistoryLength,
      outlierLimit,
    } = this.globalSettingsStore.terms.cleaningSettings.outlierPriceDetectionSettings;

    const {
      minPriceDecrease,
      minPriceIncrease,
      minPriceLength,
      maxPriceLength,
      promoLimit,
    } = this.globalSettingsStore.terms.cleaningSettings.promoPriceDetectionSettings;

    return {
      id,
      outlierHistoryLength,
      outlierLimit,
      minPriceLength,
      maxPriceLength,
      promoLimit,
      minPriceDecrease,
      minPriceIncrease,
      referencePriceChangeIndex,
      competitorReferencePriceChangeIndex,
      internalVsCompetitorReferencePriceChangeIndex,
      extremePriceChangeIndex,
      impactHistoryLength,
      finalPriceDefault,
      sensitivityDefaultId,
      missingCompetitorPrice,
      priceStickerLimit,
      agencyResearchWeight,
      webscrapingWeight,
      leaderMaxHistoryLength,
      leaderFrequencyRecalculation,
    };
  }

  /** Updates configurations */
  @action.bound
  update(values: any): void {
    const {
      id,
      outlierHistoryLength,
      outlierLimit,
      minPriceLength,
      maxPriceLength,
      promoLimit,
      minPriceDecrease,
      minPriceIncrease,
      referencePriceChangeIndex,
      competitorReferencePriceChangeIndex,
      internalVsCompetitorReferencePriceChangeIndex,
      extremePriceChangeIndex,
      impactHistoryLength,
      finalPriceDefault,
      sensitivityDefaultId,
      missingCompetitorPrice,
      priceStickerLimit,
      agencyResearchWeight,
      webscrapingWeight,
      leaderMaxHistoryLength,
      leaderFrequencyRecalculation,
    } = values;

    const familySettings = new FamilySettings(leaderMaxHistoryLength, leaderFrequencyRecalculation);

    const cleaningSettings = new CleaningSettings(
      referencePriceChangeIndex,
      competitorReferencePriceChangeIndex,
      internalVsCompetitorReferencePriceChangeIndex,
      extremePriceChangeIndex,
      agencyResearchWeight,
      webscrapingWeight,
      new PromoPriceDetectionSettings(
        minPriceDecrease,
        minPriceIncrease,
        minPriceLength,
        maxPriceLength,
        promoLimit,
      ),
      new OutlierPriceDetectionSettings(outlierHistoryLength, outlierLimit),
    );

    /** FIXME: SKU add pricestickers to the page */
    this.globalSettingsStore
      .update(
        new GlobalSettings(
          id,
          familySettings,
          cleaningSettings,
          finalPriceDefault,
          impactHistoryLength,
          sensitivityDefaultId,
          missingCompetitorPrice,
          priceStickerLimit,
        ),
      )
      .then(() => {
        this.setLoadingState(LoadingState.Success);
        this.messages.setSuccess(translate('GlobalSettings_saved'));
      })
      .catch((error) => {
        this.setLoadingState(LoadingState.Error);
        // this.messages.setError(error);
      });
  }

  /** Fetches all data for page from entity stores */
  @action.bound
  load(): void {
    this.setLoadingState(LoadingState.Pending);
    Promise.all([this.globalSettingsStore.getAll()])
      .then(() => this.setLoadingState(LoadingState.Success))
      .catch((error) => {
        this.setLoadingState(LoadingState.Error);
        // this.messages.setError(error);
      });
  }
}
