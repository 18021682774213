import { Utils } from "@logio/common-be-fe";
import generalComparator from "./generalComparator";
/**
 * @file Created on September 2019
 * @author Atreo Digital s.r.o.
 * @description Custom comparator for fields rendered by CheckBoxRenderer
 */

var checkBoxComparator = function checkBoxComparator(valA, valB) {
  return generalComparator(toComparableValue(valA), toComparableValue(valB));
};

var toComparableValue = function toComparableValue(val) {
  return Utils.isValueMissing(val) ? null : val ? 1 : 0;
};

export default checkBoxComparator;