"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Enum of possible window select options. The value is used for filtering only.
 * Valid - The promotion occurs in the given period.
 * Open - The promotion started in the given period.
 */

var WindowSelectEnum;

(function (WindowSelectEnum) {
  WindowSelectEnum["Valid"] = "VALID";
  WindowSelectEnum["Open"] = "OPEN";
})(WindowSelectEnum = exports.WindowSelectEnum || (exports.WindowSelectEnum = {}));