import React, { useCallback } from 'react';
import { DateOlderThanValidator, DateYoungerThanValidator, OptionalDesc, SelloutTerminationReportFilterDTO, ProductUtils } from '@logio/common-be-fe';
import { BoxDefault, ButtonLoading, Field, Form, Icon, IconType, translate, FormGroupInline, StringMapping, ValidationUtils, CONSTANTS, Tooltip, MultiInput, OptionsProvider, SelectOptions, SelectAdapter } from '@logio/common-fe';
import { List } from 'immutable';
import { PriceZoneField } from '../../../components/PriceZoneField/PriceZoneField';

interface SelloutTerminationReportFilterProps {
  initialValues: SelloutTerminationReportFilterDTO;
  onSubmit(filterDTO: SelloutTerminationReportFilterDTO): void;
  onExport(): void;
  exportPossible?: boolean;
  hidden?: boolean;
  validateProducts(productIds: List<string>): Promise<any>;
}

/**
 * Renders filter form (including box around) for Sellout Termination Report.
 * TODO JPk: restrict "date from" and "date to" so user cannot pick invalid date.
 * 
 * @param props
 */
export default function SelloutTerminationReportFilter(props: SelloutTerminationReportFilterProps) {
  const onSubmit = useCallback((value: any) => {
    props.onSubmit(prepareFilter(value));
  }, [props.onSubmit]);

  const onExport = useCallback((evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    props.onExport();
  }, [props.onExport]);

  return (
    <BoxDefault className={`mb1${props.hidden ? ' dn' : ''}`}>
      <Form
        descriptions={SelloutTerminationReportFilterDTO.schema}
        formLayoutVertical
        initialValues={props.initialValues}
        onSubmit={onSubmit}
      >
        <FormGroupInline>
          <div className="pb0 mt-auto">
            <Field name="categoryIds" className="err-form-vertical" />
          </div>
          <div className="pb0 mt-auto">
            <Field name="selloutTypes" />
          </div>
          <div className="pb0 mt-auto">
            <PriceZoneField name="priceZoneId" label="SelloutTerminationReportFilterDTO_priceZoneId" />
          </div>
          <div className="pb0 mt-auto">
            <OptionsProvider
              name="siteIds"
              dependsOn={{ priceZoneId: 'priceZoneId' }}
              component={(options: SelectOptions) => (
                <Field
                  name="siteIds"
                  component={
                    SelectAdapter(
                      'itemSiteIds',
                      options,
                      true,
                      false,
                      false,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      true,
                    )
                  }
                />
              )}
            />
          </div>
          <div className="pb0 mt-auto">
            <Field
              name="endDateFrom"
              validate={(value: StringMapping<any>, formValues: StringMapping<any>) => {
                return ValidationUtils.validateField(value, formValues, [
                  new DateYoungerThanValidator(
                    formValues.endDateTo,
                    true,
                    true,
                    CONSTANTS.FORMAT.DATE,
                    'DATE_FROM_IS_MORE_THAN',
                    SelloutTerminationReportFilterDTO.schema.endDateFrom instanceof OptionalDesc,
                  ),
                ]);
              }}
            />
          </div>
          <div className="pb0 mt-auto">
            <Field
              name="endDateTo"
              validate={(value: StringMapping<any>, formValues: StringMapping<any>) => {
                return ValidationUtils.validateField(value, formValues, [
                  new DateOlderThanValidator(
                    formValues.endDateFrom,
                    true,
                    true,
                    CONSTANTS.FORMAT.DATE,
                    'DATE_TO_IS_LESS_THAN',
                    SelloutTerminationReportFilterDTO.schema.endDateTo instanceof OptionalDesc,
                  ),
                ]);
              }}
            />
          </div>
        </FormGroupInline>
        <FormGroupInline>
          <div className="pb0 mt-auto">
            <Field
              name="productExternalIds"
              component={MultiInput}
              validate={props.validateProducts}
            />
          </div>
          <div className="mt-auto ml-auto">
            <ButtonLoading iconRight isLoading={false}>
              {translate('Find')} <Icon iconType={IconType.search} />
            </ButtonLoading>
          </div>
          <div className="mt-auto">
            <Tooltip overlay={translate('SelloutTerminationReport_ExportTooltip')} placement="bottom" noHeight>
              <div>
                <ButtonLoading
                  /*isLoading={store.exportIsLoading}*/
                  onClick={onExport}
                  type="button"
                  disabled={!props.exportPossible}
                >
                  <Icon iconType={IconType.exportToExcel}/>
                </ButtonLoading>
              </div>
            </Tooltip>
          </div>
        </FormGroupInline>
      </Form>
    </BoxDefault>
  );
}

function prepareFilter(value: any) {
  return SelloutTerminationReportFilterDTO.fromDataDict({
    ...value,
    // End date must reference end of the day
    endDateTo: value.endDateTo.clone().endOf('day'),
    // Add `:1` for external product ids - probably not optimal and should fix on BE as regex
    productExternalIds: value.productExternalIds ? value.productExternalIds.map(ProductUtils.goldIdToProductExternalId) : null,
  });
}
