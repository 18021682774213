/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
// This file is just for the styleguidist documentation.
import * as React from 'react';
import FormGroupStyled from './styled/FormGroupStyled';

var FormGroup = function FormGroup(props) {
  return React.createElement(FormGroupStyled, Object.assign({
    className: "form-group"
  }, props));
};

export default FormGroup;