import { AgGrid, Icon, IconColor, IconType, Page, translate, ProgressMonitorBar } from '@logio/common-fe';
import { Observer } from 'mobx-react';
import React from 'react';
import { PageNamesEnum } from '../../../shared/localization/PageNamesEnum';
import { SelloutImpactReportPageStore } from '../../../stores/pages/Reports/SelloutImpactReport/SelloutImpactReportPageStore';
import SelloutImpactReportFilter from './SelloutImpactReportFilter';
import { RouteComponentProps, withRouter } from 'react-router';

function SelloutImpactReportPage(props: RouteComponentProps<{}>) {
  const store = React.useMemo(() => new SelloutImpactReportPageStore(props.history), []);

  return (
    <Observer>
      {() => (
        <Page titleKey={PageNamesEnum.ReleasesSelloutImpact} loadingState={store.loadingState}>
          <ProgressMonitorBar
            progressMonitorId={store.generatedReportProgressMonitorId}
            onSuccess={store.reportReady}
            onError={store.reportFailedToGenerate}
            label="SelloutImpactReport-Generating"
            className="mb1"
            interval={1000}
          />
          <SelloutImpactReportFilter
            initialValues={store.defaultFilter}
            onSubmit={store.findReport}
            hidden={!store.filterVisible}
            validateProducts={store.productStore.validate}
            generationState={store.generationState}
            selectedReleases={store.selectedReleases}
            onGenerateReport={store.generateReport}
          />
          {store.reportVisible && (
            <>
              <div className="tac pointer mb1 fs-90 c-secondary" onClick={store.toggleFilter}>
                <Icon
                  iconHeight={15}
                  iconColor={IconColor.Secondary}
                  iconType={store.filterVisible ? IconType.chevronUp : IconType.chevronDown}
                />
                {" "}
                <span className="underline">
                  {translate(store.filterVisible ? "hide-filters" : "show-filters")}
                </span>
              </div>
              <AgGrid
                rowData={store.reportRowData}
                columnDefs={store.reportColumnDefs}
                gridId="SelloutImpactReportPage"
                quickView
                onGridReady={store.onGridReady}
                rowSelection="multiple"
                onSelectionChanged={store.onSelectionChanged}
              />
            </>
          )}
        </Page>
      )}
    </Observer>
  );
}

export default withRouter(SelloutImpactReportPage);
