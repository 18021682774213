/**
 * @file Created on Sun Sep 30 2018
 * @author SKu
 */

import {CompetitorsPriority} from '@logio/common-be-fe';
import {AgGrid, Button, ButtonColor, Field, Form, FormGroupInline, Modal, translate} from '@logio/common-fe';
import {List} from 'immutable';
import {RowDragEvent, RowNode, RowSelectedEvent} from 'ag-grid-community';
import {observer} from 'mobx-react';
import * as React from 'react';
import {FieldRenderProps} from 'react-final-form';
import {CompetitorsPriorityPageStore, ModalEnum} from '../../stores/pages/Settings/CompetitorsPriorityPageStore';

interface Props {
  /** CompetitorsPriority Page Store */
  store: CompetitorsPriorityPageStore;
  /**
   * If true, renders createModal else editModal
   */
  createMode?: boolean;
}

/**
 * Component renders modals for CompetitorsPriorityPage
 */
export const CompetitorsPriorityPageForm: React.SFC<Props> = observer((props: Props) => {
  const mode = props.createMode ? ModalEnum.CREATE : ModalEnum.EDIT;
  return (
    <Modal
      title={translate(`${mode}-competitorsPriority`)}
      hidden={props.store.modalHidden[mode]}
      close={props.store.getModalToggleEvent(mode)}
    >
      <Form
        onSubmit={props.store.onSubmit(mode)}
        descriptions={CompetitorsPriority.schema}
        initialValues={props.store.initialValues}
      >
        <Field name="title" />
        <Field name={'competitors'} component={({input}) => <AgGridFieldComponent {...props} input={input} />} />
        <FormGroupInline right={true}>
          <Button buttonColor={ButtonColor.Light} onClick={props.store.getModalToggleEvent(mode)}>
            {translate('Cancel')}
          </Button>
          <Button type="submit">{translate(`${mode}`)}</Button>
        </FormGroupInline>
      </Form>
    </Modal>
  );
});

/** Ag-grid for the modal */
const AgGridFieldComponent: React.SFC<Props & {input: FieldRenderProps['input']}> = (props) => {
  /**
   * Function sort nodes from Aggrid to the right order
   * and transform them to the List of competitor Ids
   * @param event event from AG-Grid
   */
  const setCompetitors = (event: RowDragEvent | RowSelectedEvent) => {
    const nodeSort = (nodes: RowNode[]) => {
      for (let i = 0; i < nodes.length; i++) {
        const value = nodes[i];
        let j = i - 1;
        for (j; j > -1 && nodes[j].rowIndex > value.rowIndex; j--) {
          nodes[j + 1] = nodes[j];
        }
        nodes[j + 1] = value;
      }
      return nodes;
    };

    const nodesArr = nodeSort(event.api.getSelectedNodes());
    return List(nodesArr.map((node: RowNode) => node.data.Competitors_id));
  };

  const onGridReady = (event) => {
    /** Control if initial values have id and set them as checked in Ag-Grid */
    event.api.getModel().forEachNode((node) => {
      props.input.value.forEach((competitor) => {
        if (competitor === node.data.Competitors_id) {
          node.selectThisNode(true);
        }
      });
    });
  };

  const onRowEvent = (event: RowDragEvent | RowSelectedEvent) => props.input.onChange(setCompetitors(event));

  return (
    <div className="mb1">
      <AgGrid
        rowData={props.store.rowDataModal()}
        columnDefs={props.store.columnDefsModal()}
        animateRows
        suppressRowClickSelection
        sizeColumnsToFit
        rowDragManaged
        height="400px"
        rowSelection="multiple"
        onGridReady={onGridReady}
        onRowDragEnd={onRowEvent}
        onRowSelected={onRowEvent}
        gridId="PricingCompetitorsPriorityPageForm"
      />
    </div>
  );
};
