"use strict";

var _defineProperty = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _dashboardPromoTypeTo;

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Disjunctive types of promotion type used in sales dashboard. User can select any combination of the types to display.
 */

var DashboardPromoType;

(function (DashboardPromoType) {
  DashboardPromoType["Regular"] = "REGULAR";
  DashboardPromoType["Total"] = "TOTAL";
  DashboardPromoType["Leaflet"] = "LEAFLET";
  DashboardPromoType["NonLeaflet"] = "NON_LEAFLET";
  DashboardPromoType["RegularNonLeaflet"] = "REGULAR_NON_LEAFLET";
})(DashboardPromoType = exports.DashboardPromoType || (exports.DashboardPromoType = {}));

var DashboardPromoTypeDbKey;

(function (DashboardPromoTypeDbKey) {
  DashboardPromoTypeDbKey["Regular"] = "regularKpiValues";
  DashboardPromoTypeDbKey["Total"] = "totalKpiValues";
  DashboardPromoTypeDbKey["Leaflet"] = "leafletKpiValues";
  DashboardPromoTypeDbKey["NonLeaflet"] = "nonLeafletKpiValues";
  DashboardPromoTypeDbKey["RegularNonLeaflet"] = "regNonLeafletKpiValues";
})(DashboardPromoTypeDbKey = exports.DashboardPromoTypeDbKey || (exports.DashboardPromoTypeDbKey = {}));

var dashboardPromoTypeToDbKey = (_dashboardPromoTypeTo = {}, _defineProperty(_dashboardPromoTypeTo, DashboardPromoType.Regular, DashboardPromoTypeDbKey.Regular), _defineProperty(_dashboardPromoTypeTo, DashboardPromoType.Total, DashboardPromoTypeDbKey.Total), _defineProperty(_dashboardPromoTypeTo, DashboardPromoType.Leaflet, DashboardPromoTypeDbKey.Leaflet), _defineProperty(_dashboardPromoTypeTo, DashboardPromoType.NonLeaflet, DashboardPromoTypeDbKey.NonLeaflet), _defineProperty(_dashboardPromoTypeTo, DashboardPromoType.RegularNonLeaflet, DashboardPromoTypeDbKey.RegularNonLeaflet), _dashboardPromoTypeTo);

exports.convertDashboardPromoTypeToDbKey = function (promoType) {
  return dashboardPromoTypeToDbKey[promoType];
};

exports.convertDashboardPromoTypesToDbKey = function (promoTypes) {
  return promoTypes.map(function (pt) {
    return exports.convertDashboardPromoTypeToDbKey(pt);
  });
};