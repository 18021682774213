/**
 * @file Created on Tue Apr 09 2019
 * @author SKu
 */

import {
  DataDesc,
  ProductCategory,
  SelloutIdentificationDTO,
  SelloutType,
  SelloutTypeWithItemCounts,
  StructPropBuilder,
  Utils,
} from '@logio/common-be-fe';
import {
  ActionProps,
  ActionsGenerator,
  arrayToMap,
  ColumnDefinition,
  ColumnGenerator,
  getPath,
  IconType,
  LoadingState,
  MessageStore,
  ReportLayer,
  StringMapping,
} from '@logio/common-fe';
import {List} from 'immutable';
import {CellClickedEvent, GridApi, GridReadyEvent} from 'ag-grid-community';
import {History} from 'history';
import {computed, observable, runInAction} from 'mobx';
import {stringify} from 'querystring';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {CategoryTraverseHelper} from '../CategoryTraverseHelper';
import {PollingHelper} from '../PollingHelper';

export class SelloutIdentificationStore {
  constructor(public messages: MessageStore, public history: History) {}

  private reportLayer = new ReportLayer();

  /** Map of DTOs with categoryId as key */
  @observable
  public selloutIdentificationDTOList = new Map<string, SelloutIdentificationDTO>();

  /** Ag-grid services */
  private gridApi: GridApi;
  public onGridReady = ({api}: GridReadyEvent) => (this.gridApi = api);

  private builder = new StructPropBuilder('SelloutIdentificationReport');
  private get description(): DataDesc {
    /** categoryId used for link */
    const description = {categoryId: this.builder.opt(this.builder.str('categoryId'))};
    /** Description from enum */
    const arr = Object.values(SelloutType).map((value) => ({[value]: this.builder.bigNum(value)}));
    return Object.assign(description, ...arr);
  }

  private actionsGenerator = new ActionsGenerator();
  private columnGenerator = new ColumnGenerator<StringMapping<any>>(this.description);

  /**
   * Returns path to report detail with query params
   */
  private getPathWithQuery = (categoryId: string, selloutTypes: SelloutType[], totalRows?: number) => {
    return `${getPath(PagePathsEnum.SelloutIdentificationReportDetail)}?${stringify({
      categoryId,
      selloutTypes,
      totalRows,
    })}`
  };

  /** Returns generated column definitions for the ag-grid */
  @computed
  public get columnDefs(): ColumnDefinition[] {
    /** Redirect to the detail category page(works only on the lowest categories, and when value exists) */
    const handleCellClick = (selloutType: SelloutType) => (params: CellClickedEvent) => {
      const categoryId = params.node.data[this.description.categoryId.description.nameKey];
      const totalRows = params.node.data[`SelloutIdentificationReport_${selloutType}`];
      if (categoryId && params.value) {
        this.history.push(this.getPathWithQuery(categoryId, [selloutType], totalRows));
      }
    };

    /** Styles in case if cell could be redirected */
    const getClassRules = () => ({
      ['c-link pointer']: (params: CellClickedEvent) =>
        !!params.node.data[this.description.categoryId.description.nameKey] && !!params.value,
    });

    /** Definitions for action column */
    const actionColumnDefs: ColumnDefinition = {
      checkboxSelection: false,
      headerName: '',
      headerCheckboxSelection: false,
      width: 70,
    };

    /** Definition dynamically from enum */
    const colDefs: ColumnDefinition[] = Object.values(SelloutType).map(
      (value): ColumnDefinition => ({
        field: value,
        cellClassRules: getClassRules(),
        onCellClicked: handleCellClick(value),
      }),
    );

    return [
      this.actionsGenerator.getColumnDefinition(actionColumnDefs),
      ...this.columnGenerator.getColumnDefinitions(colDefs),
    ];
  }

  /** Converts data from BE for Ag-grid generators */
  private convertToGridData = (data: List<SelloutTypeWithItemCounts>) => {
    /** Data will look like {REMODELLING: 10, ...} */
    let gridData = {};
    /** Iterating through each sellout type */
    // TODO: reducer
    data.map((item) => {
      gridData = {...gridData, ...{[item.type]: item.itemCount}};
    });
    return gridData;
  };

  /** Checkout {@link CategoryTraverseHelper} constructor for detailed info */
  private getDataPerRow = ({id: categoryId}: ProductCategory) => {
    if (!this.selloutIdentificationDTOList.has(categoryId)) {
      return {};
    }
    const {dataPerCategory} = this.selloutIdentificationDTOList.get(categoryId);
    return this.columnGenerator.getColumnData({
      ...this.convertToGridData(dataPerCategory),
      categoryId: this.CategoryTraverseHelper.bottomCategoryLevel && categoryId,
    });
  };

  /** If user dont have permission to edit matrix table, edit actions shouldn't be pushed */
  private getActionsPerRow = (category: ProductCategory): ActionProps[] => [
    {
      name: 'view',
      icon: IconType.view,
      linkProps: {to: this.getPathWithQuery(category.id, Object.values(SelloutType))}, // HERE
    },
  ];

  /** Store handling ag-grid tree view */
  public CategoryTraverseHelper = new CategoryTraverseHelper(this.getDataPerRow, this.getActionsPerRow);

  private onPollingStateChanged = (pollingState: LoadingState) => {
    switch (pollingState) {
      case LoadingState.Pending:
        this.gridApi.showLoadingOverlay();
        break;
      case LoadingState.Success:
        this.load();
      default:
        this.gridApi.hideOverlay();
    }
  };

  public pollingHelper = new PollingHelper(this.messages, this.onPollingStateChanged);

  /** Fetches data for the page */
  public load = async () => {
    try {
      const [response] = await Promise.all([
        this.reportLayer.getSelloutIdentificationReportDTOs(),
        this.CategoryTraverseHelper.load(),
      ]);
      if (Utils.isString(response)) {
        const pollingCouldBeStarted = await this.pollingHelper.couldPollingBeStarted(response);
        if (pollingCouldBeStarted) {
          this.pollingHelper.startPolling(response, 'sellout-identification');
        }
      } else {
        runInAction(() => (this.selloutIdentificationDTOList = arrayToMap(response, 'categoryId')));
      }
    } catch (error) {
      // this.messages.setError(error);
    }
  };
}
