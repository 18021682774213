/**
 * @file Created on Wed Aug 08 2018
 * @author SKu
 */
import TooltipStyled from './styled/TooltipStyled';
import TooltipWrapper from './styled/TooltipWrapper';
import TooltipRC from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import * as React from 'react';
/**
 * Create Tooltip component
 * [See DOCS for rc-tooltip](http://react-component.github.io/tooltip/)
 */

var Tooltip = function Tooltip(props) {
  var container;
  return React.createElement(TooltipWrapper, {
    tooltipType: props.tooltipType,
    innerRef: function innerRef(ref) {
      container = ref;
    },
    className: "etn-tooltip-wrapper",
    noHeight: props.noHeight
  }, React.createElement(TooltipStyled, {
    className: props.className
  }, React.createElement(TooltipRC, {
    overlayClassName: props.overlayClassName,
    placement: props.placement,
    overlay: props.overlay,
    getTooltipContainer: function getTooltipContainer() {
      return container;
    }
  }, props.children)));
};

export default Tooltip;