import _slicedToArray from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";

/**
 * @file Created on Thu Sep 20 2018
 * @author BPo
 */
import { ArrayDesc, ArrayParser, BigNumberParser, BooleanParser, DateParser, DayOfWeekValidator, EnumParser, ListDesc, ListParser, NumberParser, ObjectDescParser, OptionalDesc, PropDesc, RangeSliderValidator, SliderValidator, StringParser } from '@logio/common-be-fe';
import { SetDesc } from '@logio/common-be-fe/dist/core/model/data-dictionary/SetDesc';
import createFilterOptions from 'react-select-fast-filter-options';
import translate from '../../../i18n/translate/translate';
import { CheckboxAdapter, CheckboxGroupAdapter, DatePickerInputAdapter, InputAdapter, InputNumberAdapter, InputRangeAdapter, RadioGroupAdapter, RangeSliderAdapter, SelectAdapter, SliderAdapter, TextAreaAdapter } from '../Adapters';
import { FormFieldTypeEnum } from '../FormFieldTypeEnum';
/**
 * Skip the "OptionalDesc" from the data dictionary
 * @param propDesc ValueDesc which can bee instance of OptionalDesc
 * @returns First index is ValueDescription and the second one is true when the field is required
 */

var filterOptionalDesc = function filterOptionalDesc(propDesc) {
  if (propDesc instanceof OptionalDesc) {
    return [propDesc.base, false];
  }

  return [propDesc, true];
};
/**
 * @returns The list of days for the SelectAdapter
 */


var getWeekDaySelectOptions = function getWeekDaySelectOptions() {
  var weekDayOptions = [{
    label: translate('None'),
    value: '0'
  }, {
    label: translate('WeekOfDay1'),
    value: '1'
  }, {
    label: translate('WeekOfDay2'),
    value: '2'
  }, {
    label: translate('WeekOfDay3'),
    value: '3'
  }, {
    label: translate('WeekOfDay4'),
    value: '4'
  }, {
    label: translate('WeekOfDay5'),
    value: '5'
  }, {
    label: translate('WeekOfDay6'),
    value: '6'
  }, {
    label: translate('WeekOfDay7'),
    value: '7'
  }];
  return {
    options: weekDayOptions,
    filterOptions: createFilterOptions({
      weekDayOptions: weekDayOptions
    })
  };
};
/**
 * @returns The select adapter. Automatically render multi-select (you can set the multiSelect argument to false)
 */


var getSelectAdapterElement = function getSelectAdapterElement(propDesc, options, fieldReadOnly, fieldNoLabel, required) {
  var multiSelect = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : true;
  var className = arguments.length > 6 ? arguments[6] : undefined;
  var list = arguments.length > 7 ? arguments[7] : undefined;
  var creatable = arguments.length > 8 ? arguments[8] : undefined;
  return {
    type: FormFieldTypeEnum.SelectMulti,
    adapter: SelectAdapter(propDesc.description.nameKey, options, multiSelect, fieldReadOnly, fieldNoLabel, required, className, undefined, undefined, list, creatable)
  };
};
/**
 * Evaluates what element to render when it is `PropDesc`
 */


var getPropDescAdapter = function getPropDescAdapter(propDescBase, propDesc, options, fieldReadOnly, fieldNoLabel, required, className) {
  switch (propDescBase.parser.constructor.name) {
    case BigNumberParser.name:
    case NumberParser.name:
      // RangeSlider element
      var sliderValidator = propDescBase.validatorOf(SliderValidator);

      if (sliderValidator) {
        return {
          type: FormFieldTypeEnum.Slider,
          // This should be one value between min - max
          adapter: SliderAdapter(propDesc.description.nameKey, sliderValidator.min, sliderValidator.max, required, className)
        };
      }

      if (propDescBase.validatorOf(DayOfWeekValidator)) {
        return getSelectAdapterElement(propDesc, getWeekDaySelectOptions(), fieldReadOnly, fieldNoLabel, required, true, className);
      } // Input type number element


      return {
        type: FormFieldTypeEnum.Slider,
        adapter: InputNumberAdapter(propDesc.description.nameKey, fieldReadOnly, fieldNoLabel, required, className)
      };

    case BooleanParser.name:
      // CheckBox element
      return {
        type: FormFieldTypeEnum.CheckBox,
        adapter: CheckboxAdapter(propDesc.description.nameKey, fieldReadOnly, fieldNoLabel, required, className)
      };

    case DateParser.name:
      // Date picker element
      return {
        type: FormFieldTypeEnum.DatePicker,
        adapter: DatePickerInputAdapter(propDesc.description.nameKey, fieldReadOnly, {}, required, className)
      };

    case ObjectDescParser.name:
      // Range input element
      var desc = propDescBase.parser.desc;

      if (desc.minimum && desc.maximum) {
        return {
          type: FormFieldTypeEnum.InputRange,
          // TODO: check these to be instance of propDesc with NumberParser
          adapter: InputRangeAdapter(propDesc.description.nameKey, fieldReadOnly, fieldNoLabel, required, className)
        };
      }

    case EnumParser.name:
    case ArrayParser.name:
    case ListParser.name:
    default:
      // Select element
      if (options) {
        // Valid for the EnumParser, ArrayParser and ListParser as well
        return getSelectAdapterElement(propDesc, options, fieldReadOnly, fieldNoLabel, required, false, className);
      } // TextArea element


      if (propDesc.description.multiline) {
        return {
          type: FormFieldTypeEnum.TextArea,
          adapter: TextAreaAdapter(propDesc.description.nameKey, fieldReadOnly, fieldNoLabel, required, className)
        };
      } // Input element


      return {
        type: FormFieldTypeEnum.Input,
        adapter: InputAdapter(propDesc.description.nameKey, fieldReadOnly, fieldNoLabel, required, className)
      };
  }
};
/**
 * Evaluates what element to render when it is `ArrayDesc`
 */


var getArrayAdapter = function getArrayAdapter(propDescBase, propDesc, required, className) {
  var rangeSliderValidator = propDescBase.validatorOf(RangeSliderValidator);

  if (rangeSliderValidator) {
    // This should be one value between min - max
    return {
      type: FormFieldTypeEnum.RangeSlider,
      adapter: RangeSliderAdapter(propDesc.description.nameKey, rangeSliderValidator.min, rangeSliderValidator.max, required, className)
    };
  }

  return null;
};
/**
 * Evaluates what element to render when it is `ListDesc`
 */


var getListAdapter = function getListAdapter(propDescBase, propDesc, fieldReadOnly, fieldNoLabel, required, className) {
  if (propDescBase.members instanceof PropDesc) {
    if (propDescBase.members.validatorOf(DayOfWeekValidator)) {
      return getSelectAdapterElement(propDesc, getWeekDaySelectOptions(), fieldReadOnly, fieldNoLabel, required, true, className);
    }

    if (propDescBase.members.parser.constructor === StringParser) {
      return getSelectAdapterElement(propDesc, {
        options: [],
        filterOptions: function filterOptions(value) {
          return [];
        }
      }, fieldReadOnly, fieldNoLabel, required, true, className, true, true);
    }
  }

  return null;
};
/**
 * Try to select adapter automatically
 * @param propDesc
 * @param options
 * @param fieldReadOnly
 * @param fieldNoLabel
 * @param AdapterElement
 */


export var automaticSelector = function automaticSelector(propDesc, options, fieldReadOnly, fieldNoLabel, // works only for input adapter
className) {
  // Skip optional description
  var _filterOptionalDesc = filterOptionalDesc(propDesc),
      _filterOptionalDesc2 = _slicedToArray(_filterOptionalDesc, 2),
      propDescBase = _filterOptionalDesc2[0],
      required = _filterOptionalDesc2[1];

  if (options && (propDescBase instanceof ArrayDesc || propDescBase instanceof ListDesc || propDescBase instanceof SetDesc)) {
    return getSelectAdapterElement(propDesc, options, fieldReadOnly, fieldNoLabel, required, true, className, propDescBase instanceof ListDesc);
  } else if (propDescBase instanceof ArrayDesc) {
    return getArrayAdapter(propDescBase, propDesc, required, className);
  } else if (propDescBase instanceof ListDesc) {
    return getListAdapter(propDescBase, propDesc, fieldReadOnly, fieldNoLabel, required, className);
  } else if (propDescBase instanceof PropDesc) {
    return getPropDescAdapter(propDescBase, propDesc, options, fieldReadOnly, fieldNoLabel, required, className);
  }

  return null;
};
/**
 * Try to select adapter manually by the passed field type.
 * @param fieldType Manually selected form field
 * @param propDesc Description object of the form field
 * @param required When true, field will get the required mark
 */

export var hybridSelector = function hybridSelector(fieldType, propDesc, options, className, fieldNoLabel) {
  var adapterElement = {
    adapter: null,
    type: null
  };

  var _filterOptionalDesc3 = filterOptionalDesc(propDesc),
      _filterOptionalDesc4 = _slicedToArray(_filterOptionalDesc3, 2),
      required = _filterOptionalDesc4[1];

  if (options) {
    switch (fieldType) {
      case FormFieldTypeEnum.RadioButton:
        adapterElement.adapter = RadioGroupAdapter(propDesc.description.nameKey, options.options, true, required, className);
        break;

      case FormFieldTypeEnum.RadioList:
        adapterElement.adapter = RadioGroupAdapter(propDesc.description.nameKey, options.options, false, required, className);
        break;

      case FormFieldTypeEnum.CheckBoxGroup:
        adapterElement.adapter = CheckboxGroupAdapter(propDesc.description.nameKey, options.options, required, className, fieldNoLabel);

      default:
        break;
    }
  }

  return adapterElement;
};