/**
 * @file Data cleaning page
 * @author VBr
 * @author LZe
 */

import {
  AgGrid,
  AgGridWrapper,
  BoxTabContainer,
  Button,
  ButtonColor,
  ButtonSize,
  Field,
  Form,
  FormGroupInline,
  GroupActions,
  Messages,
  Modal,
  Page,
  translate,
  WithPermission,
} from '@logio/common-fe';
import {observer, Observer} from 'mobx-react';
import * as React from 'react';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {PriceZonesCompetitorsInZonesPageStore} from '../../../stores/pages/DataManagement/PriceZones/PriceZonesCompetitorsInZonesPageStore';
import Tabs from './Tabs';
import {PricingPermissions} from '@logio/common-be-fe';

@observer
class PriceZonesCompetitorsInZonesPage extends React.Component {
  store = new PriceZonesCompetitorsInZonesPageStore();

  componentWillMount() {
    this.store.load();
  }

  render() {
    return (
      <Page
        titleKey={PageNamesEnum.PriceZonesCompetitorsInZones}
        loadingState={this.store.loadingState}
        showMessages={false}
      >
        <Observer>
          {() => (
            <AgGridWrapper>
              <Tabs />
              <BoxTabContainer>
                <AgGridWrapper>
                  <Messages />
                  <WithPermission permissionTypes={[PricingPermissions.COMPETITOR_SITES_MANAGE]}>
                    <GroupActions selectedRowsCount={this.store.selectedRowsCount}>
                      {({disabled}) => (
                        <>
                          <Button
                            buttonSize={ButtonSize.Tiny}
                            onClick={() => this.store.toggleChangePriceZoneModal('add')}
                            disabled={disabled}
                          >
                            {translate('CompetitorsInZones_assignZone')}
                          </Button>
                          <Button
                              buttonSize={ButtonSize.Tiny}
                              onClick={() => this.store.toggleChangePriceZoneModal('remove')}
                              disabled={disabled}
                          >
                              {translate('CompetitorsInZones_unassignZone')}
                          </Button>
                          <Button buttonSize={ButtonSize.Tiny} onClick={this.store.toggleActive} disabled={disabled}>
                            {translate('CompetitorsInZones_toggleActive')}
                          </Button>
                        </>
                      )}
                    </GroupActions>
                  </WithPermission>
                  <AgGrid
                    rowData={this.store.rowData}
                    columnDefs={this.store.columnDefs}
                    onGridReady={this.store.onGridReady}
                    rowSelection="multiple"
                    onSelectionChanged={this.store.onSelectionChanged}
                    onRowDataChanged={this.store.onSelectionChanged}
                    suppressRowClickSelection
                    gridId="PricingPriceZonesCompetitorsInZonesPage"
                    quickView
                  />
                </AgGridWrapper>
                <Modal
                  title={translate('Change price zone')}
                  close={() => this.store.toggleChangePriceZoneModal(null)}
                  hidden={!this.store.changePriceZoneModalVisible}
                >
                  <Form descriptions={this.store.priceZoneChangeFormDataDesc} onSubmit={this.store.changePriceZones}>
                    <Field name="priceZoneIds" />
                    <FormGroupInline right={true}>
                      <Button buttonColor={ButtonColor.Light} onClick={() => this.store.toggleChangePriceZoneModal(null)}>
                        {translate('Cancel')}
                      </Button>
                      <Button type="submit">{translate('Edit')}</Button>
                    </FormGroupInline>
                  </Form>
                </Modal>
              </BoxTabContainer>
            </AgGridWrapper>
          )}
        </Observer>
      </Page>
    );
  }
}

export default PriceZonesCompetitorsInZonesPage;
