/**
 * @author VBr
 */

import {LockInfo} from '@logio/common-be-fe';
import {
  ActionsGenerator,
  ColumnDefinition,
  ColumnGenerator,
  IconType,
  LoadingState,
  PageStore,
  ProfileStore,
  rootStore,
  StoreName,
  StringMapping,
  WorkflowAdministrationStore,
  translate,
} from '@logio/common-fe';
import {ColumnApi, GridApi, GridReadyEvent, RowNode, SelectionChangedEvent} from 'ag-grid-community';
import {action, computed, observable} from 'mobx';
import {List} from 'immutable';

export class WorkflowLocksPageStore extends PageStore {
  /** Get dependant stores */
  workflowAdministrationStore = rootStore.getStore(StoreName.WorkflowAdministration) as WorkflowAdministrationStore;
  profileStore = rootStore.getStore(StoreName.Profile) as ProfileStore;

  /** Data generators */
  lockInfoGenerator = new ColumnGenerator<LockInfo>(LockInfo.schema);
  actionsGenerator = new ActionsGenerator();

  gridApi: GridApi;
  columnApi: ColumnApi;

  @observable
  selectedRowsCount: number = 0;
  @observable
  workflowDefinition: string;
  @observable
  workflowCase: string;

  /** Return generated column definitions for ag-grid */
  @computed
  get columnDefs(): ColumnDefinition[] {
    return [
      this.actionsGenerator.getColumnDefinition(),
      ...this.lockInfoGenerator.getColumnDefinitions([
        {field: 'issued'},
        {
          field: 'cases',
          valueFormatter: ({value}) =>
            value
              .filter((caseId) => this.workflowAdministrationStore.cases.has(caseId))
              .map((caseId) => this.workflowAdministrationStore.cases.get(caseId).entity)
              .join(', '),
        },
        {
          field: 'issuers',
          valueFormatter: ({value}) =>
            value
              .filter((userId) => this.profileStore.list.has(userId))
              .map((userId) => this.profileStore.list.get(userId).name)
              .join(', '),
        },
      ]),
    ];
  }

  /** Return generated data for ag-grid */
  @computed
  get rowData(): Array<StringMapping<any>> {
    const rowData = [];
    this.workflowAdministrationStore.locks.forEach((lockInfo: LockInfo) => {
      const removeLock = {
        name: 'remove',
        icon: IconType.delete,
        props: {onClick: () => this.unlock(lockInfo)},
        disabled: !!lockInfo.cases.length,
      };

      rowData.push({
        ...this.actionsGenerator.getColumnData(removeLock),
        ...this.lockInfoGenerator.getColumnData(lockInfo),
      });
    });
    return rowData;
  }

  /** Checks if there's external filter set for families  */
  @action.bound
  isExternalFilterPresent(): boolean {
    return !!this.workflowDefinition || !!this.workflowCase;
  }

  /** Function that checks products against filter parameters - fulltext and checkbox filters */
  @action.bound
  doesExternalFilterPass(node: RowNode): boolean {
    const casesDefinitions = [];
    const casesIds = node.data[LockInfo.schema.cases.description.nameKey] || [];

    casesIds.forEach((caseId) => {
      const caseInfo = this.workflowAdministrationStore.cases.get(caseId);
      if (caseInfo && !casesDefinitions.includes(caseInfo.wflowId)) {
        casesDefinitions.push(caseInfo.wflowId);
      }
    });

    return (
      (!this.workflowDefinition || casesDefinitions.includes(this.workflowDefinition)) &&
      (!this.workflowCase || casesIds.includes(this.workflowCase))
    );
  }

  @action.bound
  updateFiler(data: StringMapping<any>) {
    this.workflowDefinition = data.workflowDefinition;
    this.workflowCase = data.workflowCase;

    if (this.gridApi) {
      this.gridApi.onFilterChanged();
    }
  }

  @action.bound
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  @action
  onSelectionChanged = (event: SelectionChangedEvent): void => {
    this.selectedRowsCount = event.api.getSelectedRows().length;
  };

  @action
  unlock = (lockInfo: LockInfo): void => {
    if (lockInfo.cases.length) {
      this.messages.setError(translate('workflow_lock-in_case_error'));
    } else {
      this.workflowAdministrationStore
        .unlock([lockInfo])
        .then((unlocked: string[]) => {
          this.messages.setSuccess(translate('workflow_locks-unlocked', unlocked.length.toString()));
          this.setLoadingState(LoadingState.Success);
        })
        .catch((error) => {
          this.setLoadingState(LoadingState.Error);
          this.messages.setError(error);
        });
    }
  };

  @action
  unlockGroup = (): void => {
    const selectedLocks = this.gridApi
      .getSelectedNodes()
      .filter((node) => !node.data[LockInfo.schema.cases.description.nameKey].length)
      .map((node) => node.data[LockInfo.schema.id.description.nameKey]);

    const locksInfo = selectedLocks.map((lockId) => this.workflowAdministrationStore.locks.get(lockId));

    this.workflowAdministrationStore
      .unlock(locksInfo)
      .then(() => {
        this.messages.setSuccess(translate('workflow_locks-unlocked'));
        this.setLoadingState(LoadingState.Success);
      })
      .catch((error) => {
        this.setLoadingState(LoadingState.Error);
        this.messages.setError(error);
      });
  };

  /** Fetches all data for page from entity stores */
  @action.bound
  load(): void {
    this.setLoadingState(LoadingState.Pending);
    Promise.all([this.workflowAdministrationStore.getWorkflowCases(), this.workflowAdministrationStore.getLocks()])
      .then(([cases, locks]) => {
        const userIds = [];
        locks.forEach((lockInfo) => {
          lockInfo.issuers.forEach((issuer) => {
            if (issuer && !userIds.includes(issuer)) {
              userIds.push(issuer);
            }
          });
        });
        this.profileStore
          .getUsers(userIds)
          .then(() => this.setLoadingState(LoadingState.Success))
          .catch((error) => {
            this.setLoadingState(LoadingState.Error);
            this.messages.setError(error);
          });
      })
      .catch((error) => {
        this.setLoadingState(LoadingState.Error);
        this.messages.setError(error);
      });
  }
}
