export var Key;

(function (Key) {
  Key["Backspace"] = "Backspace";
  Key["Tab"] = "Tab";
  Key["Enter"] = "Enter";
  Key["Shift"] = "Shift";
  Key["Ctrl"] = "Control";
  Key["Alt"] = "Alt";
  Key["PauseBreak"] = "MediaPlayPause";
  Key["CapsLock"] = "CapsLock";
  Key["Escape"] = "Escape";
  Key["Space"] = " ";
  Key["PageUp"] = "PageUp";
  Key["PageDown"] = "PageDown";
  Key["End"] = "End";
  Key["Home"] = "Home";
  Key["LeftArrow"] = "ArrowLeft";
  Key["UpArrow"] = "ArrowUp";
  Key["RightArrow"] = "ArrowRight";
  Key["DownArrow"] = "ArrowDown";
  Key["Insert"] = "Insert";
  Key["Delete"] = "Delete";
  Key["Zero"] = "0";
  Key["ClosedParen"] = "0";
  Key["One"] = "1";
  Key["ExclamationMark"] = "1";
  Key["Two"] = "2";
  Key["AtSign"] = "2";
  Key["Three"] = "3";
  Key["PoundSign"] = "3";
  Key["Hash"] = "3";
  Key["Four"] = "4";
  Key["DollarSign"] = "4";
  Key["Five"] = "5";
  Key["PercentSign"] = "5";
  Key["Six"] = "6";
  Key["Caret"] = "6";
  Key["Hat"] = "6";
  Key["Seven"] = "7";
  Key["Ampersand"] = "7";
  Key["Eight"] = "8";
  Key["Star"] = "8";
  Key["Asterisk"] = "8";
  Key["Nine"] = "9";
  Key["OpenParen"] = "9";
  Key["A"] = "A";
  Key["B"] = "B";
  Key["C"] = "C";
  Key["D"] = "D";
  Key["E"] = "E";
  Key["F"] = "F";
  Key["G"] = "G";
  Key["H"] = "H";
  Key["I"] = "I";
  Key["J"] = "J";
  Key["K"] = "K";
  Key["L"] = "L";
  Key["M"] = "M";
  Key["N"] = "N";
  Key["O"] = "O";
  Key["P"] = "P";
  Key["Q"] = "Q";
  Key["R"] = "R";
  Key["S"] = "S";
  Key["T"] = "T";
  Key["U"] = "U";
  Key["V"] = "V";
  Key["W"] = "W";
  Key["X"] = "X";
  Key["Y"] = "Y";
  Key["Z"] = "Z";
  Key["LeftWindowKey"] = "Meta";
  Key["RightWindowKey"] = "Meta";
  Key["SelectKey"] = "ContextMenu";
  Key["Multiply"] = "*";
  Key["Add"] = "+";
  Key["Subtract"] = "/";
  Key["DecimalPoint"] = ".";
  Key["Divide"] = "/";
  Key["F1"] = "F1";
  Key["F2"] = "F2";
  Key["F3"] = "F3";
  Key["F4"] = "F4";
  Key["F5"] = "F5";
  Key["F6"] = "F6";
  Key["F7"] = "F7";
  Key["F8"] = "F8";
  Key["F9"] = "F9";
  Key["F10"] = "F10";
  Key["F11"] = "F11";
  Key["F12"] = "F12";
  Key["NumLock"] = "NumLock";
  Key["ScrollLock"] = "ScrollLock";
  Key["SemiColon"] = ";";
  Key["Equals"] = "=";
  Key["Comma"] = ",";
  Key["Dash"] = "-";
  Key["Period"] = ".";
  Key["UnderScore"] = "-";
  Key["PlusSign"] = "=";
  Key["ForwardSlash"] = "/";
  Key["Tilde"] = "~";
  Key["GraveAccent"] = "~";
  Key["OpenBracket"] = "{";
  Key["ClosedBracket"] = "}";
  Key["Quote"] = "'";
})(Key || (Key = {}));