"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Application module.
 *
 * @author Radek Beran
 */

var AppModule = function AppModule() {
  _classCallCheck(this, AppModule);
};

exports.AppModule = AppModule;