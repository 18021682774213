"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * the state of the upload based on user decision
 *
 * @author Jaroslav Sevcik
 */

var UploadStateEnum;

(function (UploadStateEnum) {
  UploadStateEnum["SUCCESS"] = "SUCCESS";
  UploadStateEnum["FAIL"] = "FAIL";
  UploadStateEnum["UNSET"] = "UNSET";
})(UploadStateEnum = exports.UploadStateEnum || (exports.UploadStateEnum = {}));