import generalComparator from "./generalComparator";
import { Utils } from "@logio/common-be-fe";
/**
 * @file Created on January 2020
 * @author Atreo Digital s.r.o.
 * @description Custom comparator for fields rendered by PriceHistoryRenderer (Data Cleaning)
 */

var dataCleaningCRPAndNewCRPComparator = function dataCleaningCRPAndNewCRPComparator(valA, valB) {
  return generalComparator(toComparableValue(valA), toComparableValue(valB));
};

var toComparableValue = function toComparableValue(val) {
  return Utils.isValueMissing(val) || !val.hasOwnProperty("price") || Utils.isValueMissing(val.price) ? null : val.price.toNumber();
};

export default dataCleaningCRPAndNewCRPComparator;