"use strict";
/*
 * Created on Fri Jul 20 2018
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Level of validation to be applied during value validation. Different validations take different amount of
 * time (processor power). In certain situation (such as user input) value should be tested thoroughly, in
 * other scenario only basic check are sufficient. Using levels validators user may decide if they prefer
 * fast or thorough testing.
 * For example value of lookup field may be tested at basic level to be a string and to be an identifier
 * (no spaces, ...). In case of user input (form) given value should be tested even against lookup database
 * table to be one of its rows.
 *
 * @author Stepan Mik
 */

var ValidationLevel;

(function (ValidationLevel) {
  ValidationLevel[ValidationLevel["Fast"] = 0] = "Fast";
  ValidationLevel[ValidationLevel["Basic"] = 1] = "Basic";
  ValidationLevel[ValidationLevel["Form"] = 2] = "Form";
})(ValidationLevel = exports.ValidationLevel || (exports.ValidationLevel = {}));