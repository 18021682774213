/**
 * @file Created on Fri Apr 17 2020
 */

import moment from 'moment';
import qs from 'query-string';
import {Location} from 'history';
import {CONSTANTS, IReleaseImpactFilter} from '@logio/common-fe';
import {AbstractRelease} from '@logio/common-be-fe';

/**
 * @interface {IReleaseFilter}
 */
export interface IReleaseFilter {
  dateFrom: moment.Moment;
  dateTo: moment.Moment;
}

/**
 * @author Tomas Leity
 */
export class ReleaseUtils {
  /**
   * Query parser for filters and data loaders
   * @param location
   */
  static parseQuery(location: Location): IReleaseImpactFilter {
    /** query from url(could has inside categoryId and productId) */
    const queryPath = location.search;
    const dateFromQuery: string = qs.parse(queryPath, {ignoreQueryPrefix: true}).dateFrom;
    const dateToQuery: string = qs.parse(queryPath, {ignoreQueryPrefix: true}).dateTo;

    /** If query not passed, will be used default values */
    const dateFrom = dateFromQuery ? moment(dateFromQuery, CONSTANTS.FORMAT.DATE) : moment().subtract(7, 'd');
    const dateTo = dateToQuery ? moment(dateToQuery, CONSTANTS.FORMAT.DATE) : moment();

    return {
      dateFrom: dateFrom.format(CONSTANTS.FORMAT.DATE),
      dateTo: dateTo.format(CONSTANTS.FORMAT.DATE),
    };
  }

  /**
   * Parsed query params override based on release parameters
   * @param filter
   * @param release
   */
  static parseQueryRelease(filter: IReleaseImpactFilter, release: AbstractRelease): IReleaseImpactFilter {
    let dateFrom: string = filter.dateFrom;
    let dateTo: string = filter.dateTo;

    if (release && release.priceValidityStartDate) {
      dateFrom = moment(release.priceValidityStartDate).format(CONSTANTS.FORMAT.DATE);
      dateTo = moment(release.priceValidityStartDate)
        .add(7, 'd')
        .format(CONSTANTS.FORMAT.DATE);
    }

    return {dateFrom, dateTo};
  }

  /**
   * Create Moment instances from filter params
   * @param filter
   */
  static queryToMoment(filter: IReleaseImpactFilter): IReleaseFilter {
    return {
      dateFrom: moment(filter.dateFrom, CONSTANTS.FORMAT.DATE),
      dateTo: moment(filter.dateTo, CONSTANTS.FORMAT.DATE),
    };
  }
}
