import _defineProperty from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _Editors;

/**
 * @file Created on Wed Aug 15 2018
 * @author VBr
 */
import ReleaseSelloutEndDateEditor from './ReleaseSelloutEndDateEditor';
import TextEditor from './TextEditor';
import NumberEditor from './NumberEditor';
import SelectEditor from './SelectEditor';
import DateEditor from './DateEditor';
import CheckBoxEditor from './CheckBoxEditor';
import PercentageEditor from './PercentageEditor';
import { ReleaseItemFinalPriceEditor } from './ReleaseItemFinalPriceEditor';
import { ReleaseItemPriceCodeEditor } from './ReleaseItemPriceCodeEditor';
import { ReleaseSelloutFinalPriceEditor } from './ReleaseSelloutFinalPriceEditor';
export var EditorNames;

(function (EditorNames) {
  EditorNames["TextEditor"] = "TEXT_EDITOR";
  EditorNames["NumberEditor"] = "NUMBER_EDITOR";
  EditorNames["SelectEditor"] = "SELECT_EDITOR";
  EditorNames["DateEditor"] = "DATE_EDITOR";
  EditorNames["CheckBoxEditor"] = "CHECKBOX_EDITOR";
  EditorNames["PercentageEditor"] = "PERCENTAGE_EDITOR";
  EditorNames["ReleaseItemFinalPriceEditor"] = "RELEASE_ITEM_FINAL_PRICE_EDITOR";
  EditorNames["ReleaseItemPriceCodeEditor"] = "RELEASE_ITEM_PRICE_CODE_EDITOR";
  EditorNames["ReleaseSelloutFinalPriceEditor"] = "RELEASE_SELLOUT_FINAL_PRICE_EDITOR";
  EditorNames["ReleaseSelloutEndDateEditor"] = "RELEASE_SELLOUT_END_DATE_EDITOR";
})(EditorNames || (EditorNames = {}));

var Editors = (_Editors = {}, _defineProperty(_Editors, EditorNames.TextEditor, TextEditor), _defineProperty(_Editors, EditorNames.NumberEditor, NumberEditor), _defineProperty(_Editors, EditorNames.SelectEditor, SelectEditor), _defineProperty(_Editors, EditorNames.DateEditor, DateEditor), _defineProperty(_Editors, EditorNames.CheckBoxEditor, CheckBoxEditor), _defineProperty(_Editors, EditorNames.PercentageEditor, PercentageEditor), _defineProperty(_Editors, EditorNames.ReleaseItemFinalPriceEditor, ReleaseItemFinalPriceEditor), _defineProperty(_Editors, EditorNames.ReleaseItemPriceCodeEditor, ReleaseItemPriceCodeEditor), _defineProperty(_Editors, EditorNames.ReleaseSelloutFinalPriceEditor, ReleaseSelloutFinalPriceEditor), _defineProperty(_Editors, EditorNames.ReleaseSelloutEndDateEditor, ReleaseSelloutEndDateEditor), _Editors);
export default Editors;