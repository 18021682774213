/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
import BadgeStyled from './styled/BadgeStyled';
import * as React from 'react';
/**
 * Badge
 * @component
 */

var Badge = function Badge(props) {
  /** Badge should be placed to element with position relative. Badge has absolute position. */
  return React.createElement(BadgeStyled, {
    className: props.className
  }, props.children);
};

export default Badge;