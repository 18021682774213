/**
 * @file Created on Fri Oct 12 2018
 * @author SKu
 */

import {
  ReleaseType,
  ReleaseState,
  SelloutItemFilter,
  DataDesc,
  StructPropBuilder,
  CodebookLookupSequence,
  NumberRange,
  PriceZone,
  Utils,
  ProductUtils,
} from '@logio/common-be-fe';
import {
  getPath,
  LoadingState,
  PageStore,
  ReleaseSelloutStore,
  rootStore,
  StoreName,
  translate,
  SelectOptions,
  PriceZoneStore,
  ProductStore,
} from '@logio/common-fe';

import {runInAction, computed, observable, action} from 'mobx';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {ReleaseSelloutHeaderStore, SettingsComponentStore} from '../../components';
import {PollingHelper} from '../../components/PollingHelper';
import { List, Set } from 'immutable';

enum Weighted {
  Yes = 'Yes',
  No = 'No',
}

export class ReleaseSelloutSettingsPageStore extends PageStore {
  /** Stores */
  releaseSelloutStore = rootStore.getStore(StoreName.SelloutRelease) as ReleaseSelloutStore;
  priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;
  productStore = rootStore.getStore(StoreName.Product) as ProductStore;
  releaseHeaderStore = new ReleaseSelloutHeaderStore(this.messages);
  settingsComponentStore = new SettingsComponentStore(this.messages.setError);

  @observable
  releaseItemFilter: SelloutItemFilter;

  @observable
  isModalHidden: boolean = true;

  @action
  showModal = () => (this.isModalHidden = false);

  @action
  hideModal = () => (this.isModalHidden = true);

  @computed
  get defaultFilterValues() {
    return {
      siteIds: [],
      zoneId: this.nationalZone.id,
      weighted: null,
      returnable: null,
      unitsPerSite: ['', ''],
      stockValue: ['', ''],
      selloutVolume: ['', ''],
      daysToExpiration: ['', ''],
      futurePromoInWeeks: 0,
      productExternalIds: null,
    };
  }

  @computed
  get initialValues() {
    const data = this.release.itemFilter;
    if (data.isEmpty() || data.isEmptyExceptBase()) {
      return {...this.defaultFilterValues, weighted: null};
    } else {
      return {
        ...data,
        siteIds: data.siteIds.toArray(),
        zoneId: data.zoneId === null ? this.nationalZone.id : this.release.itemFilter.zoneId,
        weighted: Utils.isValueMissing(data.weighted) ? null : (data.weighted ? Weighted.Yes : Weighted.No),
        returnable: Utils.isValueMissing(data.returnable) ? null : (data.returnable ? 'true' : 'false'),
        unitsPerSite: [
          Utils.isValueMissing(data.unitsPerSite.minimum) ? '' : data.unitsPerSite.minimum,
          Utils.isValueMissing(data.unitsPerSite.maximum) ? '' : data.unitsPerSite.maximum,
        ],
        stockValue: [
          Utils.isValueMissing(data.stockValue.minimum) ? '' : data.stockValue.minimum,
          Utils.isValueMissing(data.stockValue.maximum) ? '' : data.stockValue.maximum,
        ],
        selloutVolume: [
          Utils.isValueMissing(data.selloutVolume.minimum) ? '' : data.selloutVolume.minimum,
          Utils.isValueMissing(data.selloutVolume.maximum) ? '' : data.selloutVolume.maximum,
        ],
        daysToExpiration: [
          Utils.isValueMissing(data.daysToExpiration.minimum) ? '' : data.daysToExpiration.minimum,
          Utils.isValueMissing(data.daysToExpiration.maximum) ? '' : data.daysToExpiration.maximum,
        ],
        productExternalIds: data.productExternalIds ? List(data.productExternalIds.map(ProductUtils.productExternalIdToGoldId)) : null,
      };
    }
  }

  @computed
  public get nationalZone(): PriceZone {
    return this.priceZoneStore.nationalZone;
  }

  readonly builder = new StructPropBuilder('SelloutReleaseItemFilterForm');

  private onPollingStateChanged = (pollingState: LoadingState) => {
    if (pollingState === LoadingState.Success) {
      runInAction(() => (this.settingsComponentStore.settingsOverviewDTOStore.progressMonitorId = undefined));
      this.settingsComponentStore.loadSellout(this.release);
      this.removeWarnings();
    }
    if (pollingState === LoadingState.Pending) {
      this.hideModal();
    }
  };

  private removeWarnings = () => {
    const warnings = [...this.messages.list].filter(([key, value]) => value.type === 'WARNING');
    warnings.forEach(([key]) => this.messages.remove(key));
  };

  public pollingHelper = new PollingHelper(this.messages, this.onPollingStateChanged, 1000);

  /** Check if there is at least one included category */
  get isSomeCategoriesIncluded() {
    return Array.from(
      this.settingsComponentStore.settingsOverviewDTOStore.overview,
      ([, value]) => value.includedStats.includedInRelease,
    ).includes(true);
  }

  @computed
  get release() {
    return this.releaseSelloutStore.release;
  }
  @computed
  get descriptions(): DataDesc {
    return {
      zoneId: this.builder.opt(this.builder.lookup('zoneId', CodebookLookupSequence.ReleasePriceZones)),
      siteIds: this.builder.opt(
        this.builder.listOf(
          this.builder.lookup('sitesIds', `${CodebookLookupSequence.InternalSites}?active&checkTime`),
        ),
      ),
      weighted: this.builder.opt(this.builder.str('weighted')),
      returnable: this.builder.opt(this.builder.str('returnable')),
      productExternalIds: this.builder.opt(this.builder.listOf(this.builder.str('productExternalIds'))),
      unitsPerSite: this.builder.opt(SelloutItemFilter.schema.unitsPerSite),
      stockValue: this.builder.opt(SelloutItemFilter.schema.stockValue),
      selloutVolume: this.builder.opt(SelloutItemFilter.schema.selloutVolume),
      futurePromoInWeeks: this.builder.opt(SelloutItemFilter.schema.futurePromoInWeeks),
      daysToExpiration: this.builder.opt(SelloutItemFilter.schema.daysToExpiration),
    };
  }

  @computed
  public get weightedOptions(): SelectOptions {
    const options = [
      {
        value: Weighted.Yes,
        label: translate(`weighted_${Weighted.Yes}`),
      },
      {
        value: Weighted.No,
        label: translate(`weighted_${Weighted.No}`),
      }
    ];
    return {
      options,
      filterOptions: () => options,
    };
  }

  @computed
  public get returnableOptions(): SelectOptions {
    const options = [
      {
        value: 'true',
        label: translate(`returnableOptions_true`),
      },
      {
        value: 'false',
        label: translate(`returnableOptions_false`),
      }
    ];
    return {
      options,
      filterOptions: () => options,
    };
  }

  /** Include categories to release */
  includeCategories = async () => {
    try {
      await this.settingsComponentStore.settingsOverviewDTOStore.includeCategoriesToSelloutRelease(
        this.release.id,
        this.settingsComponentStore.selectedCategoryIds,
      );
      this.updateRelease();
      this.settingsComponentStore.gridApi.deselectAll();
      runInAction(() => (this.settingsComponentStore.selectedCategoryIds = []));
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  onFilterFormSubmit = async (data) => {
    const newFilter: SelloutItemFilter = this.releaseItemFilter.copyMulti({
      zoneId: data.zoneId === this.nationalZone.id ? null : data.zoneId,
      siteIds: data.siteIds,
      weighted: data.weighted == null ? null : (data.weighted === Weighted.No ? false : true),
      returnable: data.returnable == null ? null : (data.returnable === 'false' ? false : true),
      unitsPerSite: new NumberRange(
        Utils.isEmptyString(data.unitsPerSite[0]) ? null : data.unitsPerSite[0],
        Utils.isEmptyString(data.unitsPerSite[1]) ? null : data.unitsPerSite[1],
      ),
      stockValue: new NumberRange(
        Utils.isEmptyString(data.stockValue[0]) ? null : data.stockValue[0],
        Utils.isEmptyString(data.stockValue[1]) ? null : data.stockValue[1],
      ),
      selloutVolume: new NumberRange(
        Utils.isEmptyString(data.selloutVolume[0]) ? null : data.selloutVolume[0],
        Utils.isEmptyString(data.selloutVolume[1]) ? null : data.selloutVolume[1],
      ),
      futurePromoInWeeks: Utils.isValueMissing(data.futurePromoInWeeks) ? 0 : data.futurePromoInWeeks,
      daysToExpiration: new NumberRange(
        Utils.isEmptyString(data.daysToExpiration[0]) ? null : data.daysToExpiration[0],
        Utils.isEmptyString(data.daysToExpiration[1]) ? null : data.daysToExpiration[1],
      ),
      productExternalIds: data.productExternalIds && data.productExternalIds.size
        ? Set(data.productExternalIds.map(ProductUtils.goldIdToProductExternalId))
        : null,
    });
    try {
      await this.releaseSelloutStore.updateItemFilter(this.release.id, newFilter).then(() => {
        this.settingsComponentStore.settingsOverviewDTOStore
          .computeSelloutOverview(this.release.id, this.release.itemFilter)
          .then(() => {
            if (this.settingsComponentStore.settingsOverviewDTOStore.progressMonitorId) {
              this.pollingHelper.startPolling(
                this.settingsComponentStore.settingsOverviewDTOStore.progressMonitorId,
                'release-item-count-recalculating',
              );
            }
          });
      });
    } catch (error) {}
  };

  /** Exclude categories from release */
  excludeCategories = async () => {
    try {
      await this.settingsComponentStore.settingsOverviewDTOStore.excludeCategoriesToSelloutRelease(
        this.release.id,
        this.settingsComponentStore.selectedCategoryIds,
      );
      this.settingsComponentStore.gridApi.deselectAll();
      runInAction(() => (this.settingsComponentStore.selectedCategoryIds = []));
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  /**
   * Function open new release and redirect to release detail.
   * @param {function} redirect- function that get as parameter Path, adn provide redirect
   */
  proceedPriceSimulation = (redirect: (path: string) => void) => async () => {
    if (this.isSomeCategoriesIncluded) {
      this.setLoadingState(LoadingState.Pending);
      try {
        await this.releaseSelloutStore
          .proceedPriceSimulation(this.release.id, this.release)
          .then(() => redirect(getPath(PagePathsEnum.ReleaseSelloutDetail, this.release.id)));
        // setTimeout(() => redirect(getPath(PagePathsEnum.ReleaseSelloutDetail, this.release.id)), 2000);
      } catch (error) {
        this.setLoadingState(LoadingState.Success);
        // this.messages.setError(error);
      }
    } else {
      this.messages.setInfo(translate('error-include-one-category'));
    }
  };

  /** Allows edit settings and open Release if it has not been opened yet */
  handleReadOnlyMode = () => {
    if (this.release.state !== ReleaseState.New && this.release.releaseType !== ReleaseType.Simulation) {
      this.messages.setInfo(translate('settings-read-only-mode'));
      this.setReadOnly(true);
    }
  };

  setAlerts = () => {
    this.messages.clear();
    this.messages.setValidationResult(this.release.validationResult);
  };

  updateRelease = async () => {
    await this.releaseHeaderStore.load(this.release.id);
    this.setAlerts();
  };

  /**
   * Fletches data for the page
   */
  load = async (releaseId: string) => {
    try {
      this.setLoadingState(LoadingState.Pending);
      await this.priceZoneStore.getAll();
      const selloutRelease = await this.releaseSelloutStore.getOne(releaseId);
      runInAction(() => (this.releaseItemFilter = selloutRelease.itemFilter));
      await this.settingsComponentStore.loadSellout(selloutRelease).then(() => {
        if (this.settingsComponentStore.settingsOverviewDTOStore.progressMonitorId) {
          this.pollingHelper.startPolling(
            this.settingsComponentStore.settingsOverviewDTOStore.progressMonitorId,
            'release-item-count-recalculating',
          );
        }
      });
      this.handleReadOnlyMode();
      this.setAlerts();
    } finally {
      this.setLoadingState(LoadingState.Success);
    }
  };
}
