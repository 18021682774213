/**
 * @file Created on Tue Jan 14 2020
 * @author TSv
 */
import { NewProduct, Utils } from "@logio/common-be-fe";
/**
 * Remove NewProduct flag from product if newProduct === false to prevent appending it to filter
 */

export var getProductFlags = function getProductFlags(product) {
  var flags = product.flags;

  if (Utils.isValueMissing(product.flags)) {
    return null;
  }

  if (product.flags.has(NewProduct) && !product.flags.get(NewProduct).newProduct) {
    flags.remove(NewProduct);
  }

  return flags;
};