/**
 * @File created 10 Dec 2019
 *
 * @author Adam Drda
 * @author VSm
 */
export var PromoOptimizationLearningState;

(function (PromoOptimizationLearningState) {
  /**
   * New learning was created. Initial state of Promotion Optimization Learning.
   */
  PromoOptimizationLearningState["Created"] = "Created";
  /**
   * Export of learning files has started and the export is in progress.
   */

  PromoOptimizationLearningState["ExportInProgress"] = "ExportInProgress";
  /**
   * Learning files has been exported and are ready to be sent to Engine.
   */

  PromoOptimizationLearningState["ExportSuccess"] = "ExportSuccess";
  /**
   * Upload of learning files to Engine has started.
   */

  PromoOptimizationLearningState["UploadStarted"] = "UploadStarted";
  /**
   * BulkWriteRequest has been created. Engine waits to start downloading.
   */

  PromoOptimizationLearningState["UploadWaiting"] = "UploadWaiting";
  /**
   * Engine is downloading the files from URLs given.
   */

  PromoOptimizationLearningState["UploadInProgress"] = "UploadInProgress";
  /**
   * Upload of the files to the Engine is complete.
   */

  PromoOptimizationLearningState["UploadSuccess"] = "UploadSuccess";
  /**
   * Calculation has started.
   */

  PromoOptimizationLearningState["CalculationStarted"] = "CalculationStarted";
  /**
   *
   */

  PromoOptimizationLearningState["CalculationWaiting"] = "CalculationWaiting";
  /**
   * Calculation of the Engine is in progress.
   */

  PromoOptimizationLearningState["CalculationInProgress"] = "CalculationInProgress";
  /**
   * Calculation complete.
   */

  PromoOptimizationLearningState["CalculationSuccess"] = "CalculationSuccess";
  /**
   * An error has occurred.
   */

  PromoOptimizationLearningState["Error"] = "Error";
  /**
   * FIXME: Temporary.
   * Learning was killed manually.
   */

  PromoOptimizationLearningState["Killed"] = "Killed";
})(PromoOptimizationLearningState || (PromoOptimizationLearningState = {}));