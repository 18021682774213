"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var GOSIdWithSiteBindings = function GOSIdWithSiteBindings(gosId, bindings) {
  _classCallCheck(this, GOSIdWithSiteBindings);

  this.gosId = gosId;
  this.bindings = bindings;
};

exports.GOSIdWithSiteBindings = GOSIdWithSiteBindings;