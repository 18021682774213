/**
 * @file Created on Fri Oct 12 2018
 * @author SKu
 */

import {Permission, PricingPermissions, ReleaseAction} from '@logio/common-be-fe';
import {getPath, ReleaseStore, rootStore, StoreName} from '@logio/common-fe';
import {History} from 'history';
import {computed, observable, action, runInAction} from 'mobx';
import {PagePathsEnum} from '../../../../shared/localization/PagePathsEnum';
import {ReleaseDetailCategoryComponentStore, ReleaseHeaderStore} from '../../../components';
import {AbstractReleaseDetailCategoryPageStore} from './AbstractReleaseDetailCategoryPageStore';

export class ReleaseDetailCategoryPageStore extends AbstractReleaseDetailCategoryPageStore {
  protected releaseStore = rootStore.getStore(StoreName.Release) as ReleaseStore;
  public releaseHeaderStore = new ReleaseHeaderStore(this.messages);
  public releaseDetailCategoryComponentStore = new ReleaseDetailCategoryComponentStore(
    this.history,
    this.messages,
    this.releaseId,
    this.categoryId,
  );

  /** @inheritdoc */
  constructor(history: History, releaseId: string, categoryId: string) {
    super(history, releaseId, categoryId);
  }

  recalculate = async (manualPrices: boolean) => {
    try {
      await this.releaseStore
        .updateReleasePartManualPrices(this.releaseId, this.categoryId, manualPrices)
        .then(() => {
          this.onAction(ReleaseAction.Recalculate);
        });
    } catch (error) {
      // no op
    }
  };

  /** @inheritdoc */
  @computed
  public get actionFeasibilities() {
    const total = this.releaseDetailCategoryComponentStore.totalWithActions.get(this.categoryId);
    return total && total.actionFeasibilities;
  }

  /** @inheritdoc */
  protected get pathToDetail() {
    return getPath(PagePathsEnum.ReleaseDetail, this.releaseId);
  }

  /** @inheritdoc */
  public get permissionsForEdit(): Permission[] {
    return [
      PricingPermissions.REGULAR_RELEASE_FINAL_PRICE_EDIT,
      PricingPermissions.REGULAR_RELEASE_RECALCULATE,
      PricingPermissions.REGULAR_RELEASE_CATEGORY_APPROVE,
      PricingPermissions.REGULAR_RELEASE_CATEGORY_REJECT,
    ];
  }
}
