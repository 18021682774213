"use strict";
/*
 * Created on 03 04 2021
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author Jaroslav Sevcik
 */

var SelloutTerminationLocalReason;

(function (SelloutTerminationLocalReason) {
  /**
   * The price was automatically terminated based on data from a source system but we do not know the exact reason.
   */
  SelloutTerminationLocalReason["Automatic"] = "AUTOMATIC";
  /**
   * The price was manually terminated.
   */

  SelloutTerminationLocalReason["Manual"] = "MANUAL";
  /**
   * The price validity has expired.
   */

  SelloutTerminationLocalReason["Expiration"] = "EXPIRATION";
  /**
   * Change from delist to in-out delist
   */

  SelloutTerminationLocalReason["ListedAgainInPromo"] = "LISTED_AGAIN_IN_PROMO";
  /**
   * Change from in-out delist to regular delist
   */

  SelloutTerminationLocalReason["ListedAgainInRegularAssortment"] = "LISTED_AGAIN_IN_REGULAR_ASSORTMENT";
  /**
   * Change from overstock to promo overstock
   */

  SelloutTerminationLocalReason["ProductIsInPromotion"] = "PRODUCT_IS_IN_PROMOTION";
})(SelloutTerminationLocalReason = exports.SelloutTerminationLocalReason || (exports.SelloutTerminationLocalReason = {}));