/**
 * @file Created on Fri Nov 16 2018
 * @author SKu
 */
import { ReleaseAction, PricingPermissions, Utils } from '@logio/common-be-fe';
import * as React from 'react';
import Button, { ButtonColor, ButtonSize } from '../../../../controls/Button';
import translate from '../../../../i18n/translate';
import Tooltip from '../../../../info/Tooltip';
import { WithPermission } from '../../../../security/WithPermission';
import { CONSTANTS } from '../../../../../constants';
/** Component renders Release action buttons to the Ag-grid */

export var ReleaseActionRenderer = function ReleaseActionRenderer(props) {
  // Empty value renderer
  if (Utils.isValueMissing(props.value)) {
    return React.createElement(React.Fragment, null, CONSTANTS.FORMAT.NULL);
  }

  return props.value.action.map(function (actionFeasibility, index) {
    var isActionFired = props.colDef.cellRendererParams.isActionFired;
    var button;
    var isReadonly = isActionFired || !actionFeasibility.allowed || props.value.readOnly; // LOG-5283

    if (actionFeasibility.action === ReleaseAction.Approve) {
      button = React.createElement(WithPermission, {
        permissionTypes: [PricingPermissions.REGULAR_RELEASE_CATEGORY_APPROVE]
      }, React.createElement(Button, {
        className: "mr1",
        buttonSize: ButtonSize.Tiny,
        disabled: !actionFeasibility.allowed || isReadonly,
        onClick: function onClick() {
          return props.value.onAction(actionFeasibility.action);
        },
        value: actionFeasibility.action
      }, translate('Approve')));
    } else if (actionFeasibility.action === ReleaseAction.Reject && actionFeasibility.allowed) {
      /** Button should be shown only if it is allowed */
      button = React.createElement(WithPermission, {
        permissionTypes: [PricingPermissions.REGULAR_RELEASE_CATEGORY_REJECT]
      }, React.createElement(Button, {
        className: "mr1",
        buttonSize: ButtonSize.Tiny,
        buttonColor: ButtonColor.Light,
        onClick: function onClick() {
          return props.value.onAction(actionFeasibility.action);
        },
        value: actionFeasibility.action
      }, translate('Reject')));
    }

    return React.createElement(React.Fragment, {
      key: actionFeasibility.action
    }, button ?
    /** If action locked by someone, tooltip should be shown */
    actionFeasibility.lockedBy ? React.createElement("div", {
      className: "fixed z1000"
    }, React.createElement(Tooltip, {
      key: index,
      overlay: actionFeasibility.lockedBy.name ? actionFeasibility.lockedBy.name : translate('user-name-not-exist'),
      placement: "right",
      children: button
    })) : button : null);
  });
};