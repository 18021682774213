/**
 * @file Created on Tue Sep 25 2018
 * @author LZe
 */

import {getPath, TabMenu, translate} from '@logio/common-fe';
import * as React from 'react';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';

export interface Props {
  // CategoryId
  id: string;
}

const Tabs: React.SFC<Props> = (props) => (
  <TabMenu
    tabs={[
      {
        to: getPath(PagePathsEnum.DataPairingRecommended, props.id),
        content: translate(PageNamesEnum.DataPairingRecommended),
      },
      {
        to: getPath(PagePathsEnum.DataPairingUnpaired, props.id),
        content: translate(PageNamesEnum.DataPairingUnpaired),
      },
      {
        to: getPath(PagePathsEnum.DataPairingPaired, props.id),
        content: translate(PageNamesEnum.DataPairingPaired),
      },
    ]}
  />
);

export default Tabs;
