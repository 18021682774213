"use strict";
/*
 * Created on 08.07.2019
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author Tomáš Leity
 */

var PromotionWeekType;

(function (PromotionWeekType) {
  PromotionWeekType["Calendar"] = "WEEK_TYPE_CALENDAR";
  PromotionWeekType["Promo"] = "WEEK_TYPE_PROMO";
})(PromotionWeekType = exports.PromotionWeekType || (exports.PromotionWeekType = {}));