import _slicedToArray from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React, { useCallback, useEffect, useState } from 'react';
import { List, Set } from 'immutable';
import FormGroup from '../../forms/FormGroup/FormGroup';
import { SelectValidated } from '../Select';
import { getMarkedLabel } from '../../forms/Field/Adapters';
/**
 *
 * @author Jan Prokop
 */

export default function MultiInput(props) {
  var handleChange = useCallback(function (selectedOptions) {
    props.input.onChange(selectedOptions.length === 0 ? null : List(selectedOptions));
  }, [props.input.onChange]);
  var value = props.input.value ? props.input.value.toArray() : null;
  var validationError = props.meta.error;
  var options = value ? value.map(function (val) {
    return {
      label: val,
      value: val,
      className: validationError && validationError.messageArgs.indexOf(val) >= 0 ? 'bg-error' : ''
    };
  }) : [];

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      focusedElement = _useState2[0],
      setFocusedElement = _useState2[1];

  useEffect(function () {
    if (focusedElement) {
      var onPaste = function onPaste(event) {
        var paste = event.clipboardData.getData('text');
        var matches = paste.match(/[^\s]+/gm);

        if (matches) {
          var currentValues = props.input.value ? Set(props.input.value) : Set();
          var newValues = Set(matches);
          props.input.onChange(List(currentValues.concat(newValues)));
        }

        event.preventDefault();
      };

      focusedElement.addEventListener('paste', onPaste);
      return function () {
        return focusedElement.removeEventListener('paste', onPaste);
      };
    }

    return undefined;
  }, [focusedElement, props.input.value]); // Register copy handler

  var onFocus = useCallback(function (evt) {
    setFocusedElement(evt.target);

    if (props.input.onFocus) {
      props.input.onFocus(evt);
    }
  }, [props.input.onFocus]); // Unregister copy handler

  var onBlur = useCallback(function (evt) {
    setFocusedElement(null);

    if (props.input.onBlur) {
      props.input.onBlur(evt);
    }
  }, [props.input.onBlur]);
  return React.createElement(FormGroup, {
    noLabel: props.fieldNoLabel
  }, React.createElement(SelectValidated, Object.assign({}, props.input, {
    value: value,
    onChange: handleChange,
    onFocus: onFocus,
    onBlur: onBlur,
    options: options,
    className: props.className,
    disabled: props.fieldReadOnly,
    label: props.fieldNoLabel ? undefined : getMarkedLabel("forms_select_".concat(props.description ? props.description.description.nameKey : ''), props.required, (value ? value.length : 0).toString()),
    placeholder: props.placeholder,
    tiny: props.fieldNoLabel,
    validationError: props.meta.touched ? props.validationError || props.meta.error : null,
    labelTooltipText: props.labelTooltipText,
    multi: true,
    creatable: true
  })));
}