/**
 * @file Created on Fri Jan 31 2020
 * @author Tomáš Leity
 */
import * as React from 'react';
import Loader from '../../../../info/Loader';
/**
 * @component DefaultLoadingOverlay
 */

var DefaultLoadingOverlay = function DefaultLoadingOverlay(props) {
  return React.createElement(Loader, null);
};

export default DefaultLoadingOverlay;