/**
 * @file Created on Wed Sep 19 2018
 * @author LZe
 */
var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import * as React from 'react';
import { LoadingState } from '../../shared/api/LoadingState';
import Loader from '../info/Loader';
import Messages from '../info/Messages';
import PageInfo from '../info/PageInfo';
import BackButton from '../navigation/Menu/BackButton';
import { WorkflowCasesLockHeartbeat } from '../../components/shared/WorkflowCasesLockHeartbeat';
/**
 * A wrapper for the page with the loading spinner, page info and error boundary
 *
 * Usage example:
 * <Page titleKey={PageNames.DataUpload} loadingState={this.store.loadingState}>
 *   <Observer>
 *     {() => (
 *       <div>My page</div>
 *     )}
 *   </Observer>
 * </Page>
 *
 * The Observer component and the function wrapper is needed by MobX (otherwise the render menthod is rerendered multiple times)
 * More info: https://github.com/mobxjs/mobx/issues/958#issuecomment-294837780
 */

var pageContent = function pageContent(loadingState, children) {
  switch (loadingState) {
    case LoadingState.Pending:
      return React.createElement(Loader, null);

    case LoadingState.Error:
      return null;

    default:
      return children;
  }
};

var Page = function Page(_a) {
  var loadingState = _a.loadingState,
      showMessages = _a.showMessages,
      backButtonPath = _a.backButtonPath,
      workflowCasesLockHeartbeat = _a.workflowCasesLockHeartbeat,
      children = _a.children,
      pageInfoProps = __rest(_a, ["loadingState", "showMessages", "backButtonPath", "workflowCasesLockHeartbeat", "children"]);

  return React.createElement("main", {
    id: "main"
  }, workflowCasesLockHeartbeat && React.createElement(WorkflowCasesLockHeartbeat, Object.assign({}, workflowCasesLockHeartbeat)), React.createElement("div", {
    id: "main-content"
  }, React.createElement(PageInfo, Object.assign({}, pageInfoProps)), (showMessages !== false || loadingState === LoadingState.Error) && React.createElement(Messages, null), pageContent(loadingState, children), React.createElement(BackButton, {
    path: backButtonPath
  })));
};

export default Page;