/**
 * @file Created on Fri Oct 12 2018
 * @author SKu
 */

import {
  DataDesc,
  OptimizationGoalsSettings,
  PricingPermissions,
  PricingRoles,
  ProductCategory,
  Range,
  RecalculationInitiatedFromRelease,
  ReleasePart,
  ReleasePartMessageCode,
  ReleaseTotalDTO,
  ReleaseType,
  ReleaseWorkflowType,
  RequiresRecalculation,
  UserMessageCode,
} from '@logio/common-be-fe';
import {
  ColumnDefinition,
  FilterNames,
  IconType,
  PriceZoneStore,
  ReleaseStore,
  RendererNames,
  rootStore,
  StoreName,
  StringMapping,
  translate,
  withPermission,
  Comparators,
  getPath,
  CONSTANTS,
} from '@logio/common-fe';
import {List} from 'immutable';
import {History} from 'history';
import {PagePathsEnum} from '../../../../shared/localization/PagePathsEnum';
import {ReleaseHeaderStore} from '../../../components';
import {AbstractReleaseDetailPageStore} from './AbstractReleaseDetailPageStore';
import {action, computed, observable} from 'mobx';

export class ReleaseDetailPageStore extends AbstractReleaseDetailPageStore {
  constructor(public history: History) {
    super(history);
  }

  /** Stores */
  public releaseStore = rootStore.getStore(StoreName.Release) as ReleaseStore;
  public releaseHeaderStore = new ReleaseHeaderStore(this.messages, this.onReleaseHeaderUpdated);
  private priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;

  public list: Map<string, ReleaseTotalDTO>;

  protected async loadData() {
    await Promise.all([
      super.loadData(),
      this.productCategoryStore.loadForRelease(this.releaseStore.release.id),
      this.priceZoneStore.getAll(),
    ]);
  }

  /** Current release */
  @computed
  public get release() {
    return this.releaseStore.release;
  }

  /** Returns true if release workflow type is urgent */
  @computed
  public get isUrgent() {
    return this.releaseStore.release.workflowType === ReleaseWorkflowType.Urgent;
  }

  /** Permissions for settings view(influence on icon showing) */
  @computed
  private get settingsViewPermission() {
    if (this.release.releaseType === ReleaseType.Simulation) {
      return PricingPermissions.SIMULATION_RELEASE_SETTINGS_DETAIL_VIEW;
    }
    /** Release should be regular in that case */
    return PricingPermissions.REGULAR_RELEASE_SETTINGS_DETAIL_VIEW;
  }

  /** Page specific description for ag-grid generators */
  @computed
  protected get customTotalColumnsDescription(): DataDesc {
    return {
      priceZoneName: this.totalBuilder.str('priceZoneName'),
      priceChange: this.totalBuilder.bigNum('priceChange'),
      priceSticker: this.totalBuilder.bigNum('priceSticker'),
      marginConstraint: this.totalBuilder.obj(
        'marginConstraint',
        Range.schemaBigNumber,
        (mat) => new Range(mat.maximum, mat.minimum),
      ),
      optimizationGoal: this.totalBuilder.opt(
        this.totalBuilder.obj(
          'optimizationGoal',
          OptimizationGoalsSettings.schema,
          (mat) => new OptimizationGoalsSettings(mat.optimalizationGoal),
        ),
      ),
      marginOld: this.totalBuilder.opt(this.totalBuilder.bigNum('marginOld')),
      marginOldPercent: this.totalBuilder.opt(this.totalBuilder.bigNum('marginOldPercent')),
      marginNew: this.totalBuilder.opt(this.totalBuilder.bigNum('marginNew')),
      marginNewPercent: this.totalBuilder.opt(this.totalBuilder.bigNum('marginNewPercent')),
      marginImpact: this.totalBuilder.opt(this.totalBuilder.bigNum('marginImpact')),
      marginImpactPercent: this.totalBuilder.opt(this.totalBuilder.bigNum('marginImpactPercent')),
      salesVolumeOld: this.totalBuilder.opt(this.totalBuilder.bigNum('salesVolumeOld')),
      salesVolumeNew: this.totalBuilder.opt(this.totalBuilder.bigNum('salesVolumeNew')),
      salesVolumeImpact: this.totalBuilder.opt(this.totalBuilder.bigNum('salesVolumeImpact')),
      salesVolumeImpactPercent: this.totalBuilder.opt(this.totalBuilder.bigNum('salesVolumeImpactPercent')),
      revenueOld: this.totalBuilder.opt(this.totalBuilder.bigNum('revenueOld')),
      revenueNew: this.totalBuilder.opt(this.totalBuilder.bigNum('revenueNew')),
      revenueImpact: this.totalBuilder.opt(this.totalBuilder.bigNum('revenueImpact')),
      revenueImpactPercent: this.totalBuilder.opt(this.totalBuilder.bigNum('revenueImpactPercent')),
    };
  }

  /** Page specific ag-grid column definition */
  protected get customTotalColumnsDefs(): ColumnDefinition[] {
    return [
      {field: 'priceZoneName'},
      {field: 'priceChange', dontCreateColumn: this.isUrgent},
      {field: 'priceSticker'},
      {
        field: 'marginConstraint',
        cellRenderer: RendererNames.ReleasePriceRangeRenderer,
        filter: FilterNames.ReleasePriceRangeFilter,
      },
      {
        field: 'optimizationGoal',
        valueFormatter: (params) => params.value ? translate(`optimizationGoal_${params.value}`) : CONSTANTS.FORMAT.NULL,
      },
      {field: 'marginOld', dontCreateColumn: this.isUrgent},
      {field: 'marginOldPercent', dontCreateColumn: this.isUrgent},
      {field: 'marginNew', dontCreateColumn: this.isUrgent},
      {field: 'marginNewPercent', dontCreateColumn: this.isUrgent},
      {
        field: 'marginImpact',
        comparator: Comparators.arrowComparator,
        cellRenderer: RendererNames.ArrowRenderer,
        dontCreateColumn: this.isUrgent,
      },
      {
        field: 'marginImpactPercent',
        comparator: Comparators.arrowComparator,
        cellRenderer: RendererNames.ArrowRenderer,
        dontCreateColumn: this.isUrgent,
      },
      {field: 'salesVolumeOld', dontCreateColumn: this.isUrgent},
      {field: 'salesVolumeNew', dontCreateColumn: this.isUrgent},
      {
        field: 'salesVolumeImpact',
        comparator: Comparators.arrowComparator,
        cellRenderer: RendererNames.ArrowRenderer,
        dontCreateColumn: this.isUrgent,
      },
      {
        field: 'salesVolumeImpactPercent',
        comparator: Comparators.arrowComparator,
        cellRenderer: RendererNames.ArrowRenderer,
        dontCreateColumn: this.isUrgent,
      },
      {field: 'revenueOld', dontCreateColumn: this.isUrgent},
      {field: 'revenueNew', dontCreateColumn: this.isUrgent},
      {
        field: 'revenueImpact',
        comparator: Comparators.arrowComparator,
        cellRenderer: RendererNames.ArrowRenderer,
        dontCreateColumn: this.isUrgent,
      },
      {
        field: 'revenueImpactPercent',
        comparator: Comparators.arrowComparator,
        cellRenderer: RendererNames.ArrowRenderer,
        dontCreateColumn: this.isUrgent,
      },
    ];
  }

  /** Parent method should be overwritten, because we need action column here */
  @computed
  public get columnDefs(): ColumnDefinition[] {
    const colDefs: ColumnDefinition[] = [];
    /** Definitions for action column */
    const actionColumnDefs: ColumnDefinition = {
      checkboxSelection: false,
      headerName: '',
      headerCheckboxSelection: false,
      width: 70,
      suppressMovable: false,
      lockPinned: false,
      lockPosition: false,
    };
    /** Actions are allowed only if user has proper permissions */
    if (withPermission([this.settingsViewPermission])) {
      colDefs.push(this.actionsGenerator.getColumnDefinition(actionColumnDefs));
    }
    colDefs.push(
      ...this.totalColumnGenerator.getColumnDefinitions(this.totalColumnsDefs),
      ...this.impactColumnGenerator.getColumnDefinitions(this.impactColumnDefs),
    );
    return colDefs;
  }

  /** Tree data definition */
  @computed
  public get autoGroupColumnDefs() {
    /** Remove price zone id from tree view */
    const formatPriceZoneIds = ({value}: {value: string}) => {
      const transformedValue = value.toString().replace(/&amp;/g, '&');
      return this.priceZoneStore.list.get(value) ? ' ' : transformedValue;
    };
    // LOG-4593
    const formatForExcel = ({node}) => {
      const pathItems = [...node.data.pathData.path];
      const maxPathLength = 5;
      // we need as well "empty levels" of the path due to correct count of semicolons
      for (let i = pathItems.length; i <= maxPathLength; i++) {
        pathItems.push('');
      }
      // slice is here due to we don't want to show last item (= zone ID)
      return pathItems.slice(0, maxPathLength).join(';');
    };

    const autoGroupColDefs: ColumnDefinition = {
      ...this.commonAutoGroupColumnDefs,
      valueFormatter: formatPriceZoneIds,
      excel: {
        valueFormatter: formatForExcel,
      },
    };
    return this.productCategoryColumnGenerator.getAutoGroupColumnDefinitions(autoGroupColDefs);
  }

  protected getRowData = (
    category: ProductCategory,
    releaseTotalItem: ReleaseTotalDTO,
    pathData: {categoryId: string; path: List<string>},
  ) => {
    const gridData: Array<StringMapping<any>> = [];
    /** True if category level is the lowest */
    const bottomLevel = category.level === this.generalConfigurationsStore.config.categoryLevel;

    /** Icon with link for editing matrixTable */
    const actionGeneratorData = {
      name: 'settings',
      icon: IconType.settings,
      linkProps: {
        to: getPath(PagePathsEnum.ReleaseMatrixTable, category.id, this.release.id),
      },
    };

    /** Merge impact data with common created data for bottom category */
    const getTotalGeneratorData = (): any => {
      return {
        ...releaseTotalItem.impact,
        ...this.getCommonTotalGeneratorData(category, releaseTotalItem),
        /** add link, if category level is the lowest */
        link: bottomLevel && this.getPathToDetailCategory(PagePathsEnum.ReleaseDetailCategory, category.id),
      };
    };

    /** Push category data */
    let data: any = {
      pathData: {
        categoryId: pathData.categoryId,
        path: pathData.path.toArray(),
      },
      ...this.totalColumnGenerator.getColumnData(getTotalGeneratorData()),
      ...this.impactColumnGenerator.getColumnData(releaseTotalItem.realImpact),
    };
    /** We should push actions if we are on the lowest category */
    if (bottomLevel) {
      data = Object.assign(data, this.actionsGenerator.getColumnData(actionGeneratorData));
    }
    gridData.push(data);

    if (bottomLevel) {
      /** Push Impact data for each included PriceZone */
      releaseTotalItem.nestedPriceZoneIds.forEach((priceZoneId) => {
        const priceZone = this.priceZoneStore.list.get(priceZoneId);
        /** Return impact data for price Zones(also add PriceZone name to grid row) */
        const getTotalWithPriceZoneData = () => ({
          ...this.list.get(`${category.id}_${priceZone.id}`).impact,
          ...{priceZoneName: priceZone.name},
        });

        gridData.push({
          pathData: {
            categoryId: pathData.categoryId,
            path: pathData.path.push(priceZone.id).toArray(), // LOG-6405 do not show pricezone ID in category column
          },
          ...this.totalColumnGenerator.getColumnData(getTotalWithPriceZoneData()),
          ...this.impactColumnGenerator.getColumnData(this.list.get(`${category.id}_${priceZone.id}`).realImpact),
        });
      });
    }

    return gridData;
  };

  /**
   * Copy settings from release to general settings
   * Works only for simulation release
   */
  public onConfirmCopyToGeneralSettings = async () => {
    try {
      const copySuccess = await this.releaseStore.saveToGeneralSettings(this.release.id);
      copySuccess
        ? this.messages.setSuccess(translate('release-copy-to-general-settings-success'))
        : this.messages.setWarning(translate('release-copy-to-general-settings-failed'));
    } catch (error) {
      // this.messages.setError(error);
    }
    this.hideModal();
  };

  // Move following methods to AbstractReleaseDetailPageStore, when needed in sellout releases.
  @computed
  public get showRecalculationButton() {
    return this.isRecalculationRequired;
  }

  @computed
  public get isRecalculationRequired() {
    return this.release.releaseParts.some(releasePart => (
      releasePart.validationResult.hasMessageWithFlag(RequiresRecalculation) &&
      !releasePart.validationResult.hasMessageWithKey(ReleasePartMessageCode.ReleasePartRecalculationInProgress) &&
      PricingRoles.PRICE_MANAGER.equals(this.categoryOpenedFor(releasePart.productCategoryId))
    ));
  }

  recalculate = async (manualPrices: boolean) => {
    try {
      await this.releaseStore.recalculateNeededReleaseParts(this.release.id, manualPrices);
      // Update action buttons
      await this.loadTotal();
    } catch (e) {
      // no op
    }
  }

  /**
   * Contains array of calculation progress monitors, which were initiated from release page.
   */
  @computed
  public get recalculationProgressMonitors() {
    return this.recalculationReleaseParts.map(rp => rp.calcProgressMonitorId);
  }

  /**
   * Contains array of release parts being recalculated (and calculation was initiated from release page).
   */
  @computed
  public get recalculationReleaseParts() {
    if (!this.release) {
      return [] as ReleasePart[];
    }

    return this.release.releaseParts
      .filter(rp => rp.validationResult.hasMessageWithFlag(RecalculationInitiatedFromRelease) && rp.calcProgressMonitorId)
      .toArray();
  }

  @computed
  public get recalculationCategories() {
    return this.recalculationReleaseParts
      .map(rp => this.productCategoryStore.getCategoryForRelease(this.release.id, rp.productCategoryId))
      .filter(category => category)
      .map(category => category.name);
  }
}
