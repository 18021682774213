import {
  CodebookItem,
  CommentItemUpdate,
  DataDesc,
  DateParser,
  EndDateItemUpdate,
  ExportedItemUpdate,
  FinalPriceItemUpdate,
  FinalProductPrice,
  MinMarginChangeItemUpdate,
  MinPriceChangeItemUpdate,
  PriceZone,
  PricingPermissions,
  ReleaseState,
  SelloutRelease,
  SelloutReleaseItem,
  SelloutReleaseItemPrices,
  SelloutReleaseType,
  StructPropBuilder,
  UserMessage,
  SelloutImpactRecord,
  PriceFormulas,
  Utils,
  PriceStats,
  MaxPriceChangeItemUpdate,
  ValidationError,
} from '@logio/common-be-fe';
import {
  ColumnDefinition,
  Comparators,
  CONSTANTS,
  EditorNames,
  FilterNames,
  FormElOption,
  RendererNames,
  StringMapping,
  TooltipNames,
  translate,
  withPermission,
} from '@logio/common-fe';
import {RowNode, ValueGetterParams} from 'ag-grid-community';

/**
 * @return description for ag-grid inside product detail modal
 */
export const getReleaseSelloutDetailProductDescription = (reopenCount: number): DataDesc => {
  const builder = new StructPropBuilder('ReleaseSelloutItemDetail');
  return {
    readonly: builder.bool('readonly'),
    itemId: builder.str('itemId'),
    /** Site id */
    id: builder.str('id'),
    productId: builder.str('productId'),
    /** Current site name */
    siteName: builder.str('siteName'),
    /** validationResult */
    messages: builder.listOf(builder.obj('messages', UserMessage.schema, (mat) => UserMessage.fromDataDict(mat))),
    minPriceChangeInPercent: builder.opt(builder.bigNum('minPriceChangeInPercent')),
    maxPriceChangeInPercent: builder.opt(builder.bigNum('maxPriceChangeInPercent')),
    minMarginChangeInPercent: builder.opt(builder.bigNum('minMarginChangeInPercent')),
    /** getStockAmount() on supplyData entity */
    stockAmount: builder.opt(builder.bigNum('stockAmount')),
    stockValue: builder.opt(builder.bigNum('stockValue')),
    supplier: builder.opt(builder.str('supplier')),
    /** Regular price national zone */
    regularPriceNational: builder.opt(builder.bigNum('regularPriceNational')),
    /** Regular price */
    actualPrice: builder.opt(builder.bigNum('actualPrice')),
    /** Margin old */
    marginOld: builder.opt(builder.bigNum('marginOld')),
    /** Margin old percent */
    marginOldPercent: builder.opt(builder.bigNum('marginOldPercent')),
    /** endDateOld */
    endDate: builder.opt(builder.date('endDate', DateParser.ISO_PATTERN)),
    /** daysLeftOld */
    daysLeft: builder.opt(builder.bigNum('daysLeft')),
    selloutPrice: builder.opt(builder.bigNum('selloutPrice')),
    salesVolumeOld: builder.opt(builder.bigNum('salesVolumeOld')),
    salesVolumeNew: builder.opt(builder.bigNum('salesVolumeNew')),
    revenueOld: builder.opt(builder.bigNum('revenueOld')),
    revenueNew: builder.opt(builder.bigNum('revenueNew')),
    discountPercent: builder.opt(builder.bigNum('discountPercent')),
    marginNew: builder.opt(builder.bigNum('marginNew')),
    marginNewPercent: builder.opt(builder.bigNum('marginNewPercent')),
    marginChange: builder.opt(builder.bigNum('marginChange')),
    marginChangePercent: builder.opt(builder.bigNum('marginChangePercent')),
    exported: builder.bool('exported'),
    /** On  prices entity */
    priceCode: builder.opt(builder.str('priceCode')),
    /** finalPrice */
    finalPrice: SelloutReleaseItemPrices.schema.finalPrice,
    ...getPreviousFinalPricesDescription(reopenCount),
    excel_finalPrice_type: new StructPropBuilder(SelloutReleaseItemPrices.name).obj(
      'excel_finalPrice_type',
      FinalProductPrice.schema,
      (mat) => FinalProductPrice.fromDataDict(mat),
    ),
    excel_finalPrice: new StructPropBuilder(SelloutReleaseItemPrices.name).obj(
      'excel_finalPrice',
      FinalProductPrice.schema,
      (mat) => FinalProductPrice.fromDataDict(mat),
    ),
    /** In future, BE structure will be changed. For now, used the first comment from the List inside prices entity */
    temporaryComment: builder.opt(builder.str('temporaryComment')),
    /** finalPrice.validTo */
    endDateFinalPrice: builder.opt(builder.date('endDateFinalPrice', DateParser.ISO_PATTERN)),
    /** daysLeftNew */
    daysLeftFinalPrice: builder.opt(builder.bigNum('daysLeftFinalPrice')),
    purchasePrice: builder.opt(builder.bigNum('purchasePrice')),
    openPurchasePrice: builder.opt(builder.bigNum('openPurchasePrice')),
    /** True if exist at least one selloutReleaseItem.prices.promoPriceStats.currentPrices */
    promo: builder.opt(builder.obj(
      'promo',
      PriceStats.schema,
      mat => new PriceStats(mat.currentPrices, mat.lastAmount, mat.minAmount, mat.averageAmount, mat.openingAmount),
    )),
    /** Available only for DEADSTOCK !!!  */
    deadstockProvisionDiscountPercent: builder.opt(builder.bigNum('deadstockProvisionDiscountPercent')),
    deadstockProvision: builder.opt(builder.bigNum('deadstockProvision')),
    deadstockInvestment: builder.opt(builder.bigNum('deadstockInvestment')),
    deadstockSelloutImpact: builder.opt(builder.bigNum('deadstockSelloutImpact')),
    /** Expiry date */
    delistedFrom: builder.opt(builder.date('delistedFrom', DateParser.ISO_PATTERN)),
    /** SELLOUT EVALUATION REPORT */
    actualSalesAmount: builder.bigNum('actualSalesAmount'),
    estimatedDays: builder.opt(builder.num('estimatedDays')),
    estimatedEndDate: builder.opt(builder.date('estimatedEndDate', DateParser.ISO_PATTERN)),
    estimationInPercent: builder.opt(builder.bigNum('estimationInPercent')),
    expectedSalesAmount: builder.opt(builder.bigNum('expectedSalesAmount')),
    priceValidFrom: builder.date('priceValidFrom', DateParser.ISO_PATTERN),
    /** IMPACT REPORT/RELEASE EVALUATION REPORT */
    realImpactSalesVolumeImpact: builder.opt(builder.bigNum('realImpactSalesVolumeImpact')),
    realImpactBmImpact: builder.opt(builder.bigNum('realImpactBmImpact')),
    realImpactRevenueImpact: builder.opt(builder.bigNum('realImpactRevenueImpact')),
    availableSupplyBeforeRepricing: SelloutImpactRecord.schema.availableSupplyBeforeRepricing,
    availableSupplyBeforeRepricingPrice: SelloutImpactRecord.schema.availableSupplyBeforeRepricingPrice,
    availableSupplyPriceChange: SelloutImpactRecord.schema.availableSupplyPriceChange,
    availableSupplyChange: SelloutImpactRecord.schema.availableSupplyChange,
    availableSupplyPriceChangePercent: SelloutImpactRecord.schema.availableSupplyPriceChangePercent,
    availableSupplyChangePercent: SelloutImpactRecord.schema.availableSupplyChangePercent,
  };
};

/**
 * @param priceCodeOptions
 * @param handleUpdateFromAgGrid function that updates any data from ag-grid, could be used as bulked action(more in ReleaseSelloutDetailCategoryComponentStore)
 * @param editable
 * @param release
 * @param nationalPriceZone National price zone.
 * @return column definition for product detail modal
 */
export const getReleaseSelloutDetailProductColDefs = (
  priceCodeOptions: FormElOption[],
  handleUpdateFromAgGrid: (
    updatedField: string,
    bulk?: boolean,
  ) => (rowId: string, column: string, newValue: any) => Promise<any>,
  editable: () => boolean,
  release: SelloutRelease,
  nationalPriceZone: PriceZone,
  reopenCount: number,
  isCellEdited: (productId: string, siteId: string | null, updatedField: string) => boolean,
  onEditStart: () => void,
  onEditEnd: () => void,
): ColumnDefinition[] => {
  const rowIsReadOnly = (params): boolean => {
    return params.data.ReleaseSelloutItemDetail_readonly ? !!params.data.ReleaseSelloutItemDetail_readonly : false;
  };

  /** Temporary(review), comments */
  const shouldFinalPriceBeEditable = (params): boolean => {
    const finalPrice: FinalProductPrice = params.data.SelloutReleaseItemPrices_finalPrice;
    let valid = true;
    if (finalPrice) {
      valid = finalPrice.validationResult.isSuccessful();
    }
    return (
      valid &&
      !rowIsReadOnly(params) &&
      editable() &&
      withPermission([PricingPermissions.SELLOUT_RELEASE_FINAL_PRICE_EDIT])
    );
  };

  /** Temporary(review), comments */
  const shouldFinalPriceDateBeEditable = (params): boolean => {
    return (
      editable() && !rowIsReadOnly(params) && withPermission([PricingPermissions.SELLOUT_RELEASE_FINAL_PRICE_EDIT])
    );
  };

  return [
    {
      field: 'siteName',
      pinned: true,
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
    },
    {
      field: 'messages',
      cellRenderer: RendererNames.ReleaseAlertsRenderer,
      comparator: (a, b) => {
        if (a === b) {
          return 0;
        }
        return a === translate(`BLANKS_FILTER`) ? -1 : 1;
      },
      filterValueGetter: (params: ValueGetterParams) => {
        const messages: string[] = [];
        params.data.ReleaseSelloutItemDetail_messages
          ? params.data.ReleaseSelloutItemDetail_messages.resultObjects.map((obj) =>
              messages.push(translate(`${obj.severity}_${obj.messageKey}`)),
            )
          : messages.push(translate(`BLANKS_FILTER`));
        return messages;
      },
      width: 80,
      excel: {hide: true},
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
    },
    {
      field: 'minPriceChangeInPercent',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      action: handleUpdateFromAgGrid(MinPriceChangeItemUpdate.TAG),
      editable: (params) => editable() && !rowIsReadOnly(params),
      bulkFunc: handleUpdateFromAgGrid(MinPriceChangeItemUpdate.TAG, true),
      cellClassRules: {
        'state-success': params => isCellEdited(
          params.data.ReleaseSelloutItemDetail_productId,
          params.data.ReleaseSelloutItemDetail_id,
          MinPriceChangeItemUpdate.TAG,
        ),
      },
      cellEditorParams: {
        validate: validateMinPriceChange('ReleaseSelloutItemDetail_maxPriceChangeInPercent'),
      },
      onEditStart,
      onEditEnd,
    },
    {
      field: 'maxPriceChangeInPercent',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      action: handleUpdateFromAgGrid(MaxPriceChangeItemUpdate.TAG),
      editable: (params) => editable() && !rowIsReadOnly(params),
      bulkFunc: handleUpdateFromAgGrid(MaxPriceChangeItemUpdate.TAG, true),
      cellClassRules: {
        'state-success': params => isCellEdited(
          params.data.ReleaseSelloutItemDetail_productId,
          params.data.ReleaseSelloutItemDetail_id,
          MaxPriceChangeItemUpdate.TAG,
        ),
      },
      cellEditorParams: {
        validate: validateMaxPriceChange('ReleaseSelloutItemDetail_minPriceChangeInPercent'),
      },
      onEditStart,
      onEditEnd,
    },
    {
      field: 'minMarginChangeInPercent',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      action: handleUpdateFromAgGrid(MinMarginChangeItemUpdate.TAG),
      editable: (params) => editable() && !rowIsReadOnly(params),
      bulkFunc: handleUpdateFromAgGrid(MinMarginChangeItemUpdate.TAG, true),
      cellClassRules: {
        'state-success': params => isCellEdited(
          params.data.ReleaseSelloutItemDetail_productId,
          params.data.ReleaseSelloutItemDetail_id,
          MinMarginChangeItemUpdate.TAG,
        ),
      },
      onEditStart,
      onEditEnd,
    },
    {
      field: 'stockAmount',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      round: true,
      excel: {
        valueFormatter: ({value}) => (value ? value.value.toString() : ''),
      },
    },
    {
      field: 'stockValue',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      round: true,
      valueFormatter: ({value}) => getRoundedPrice(value),
    },
    {field: 'availableSupplyBeforeRepricing'},
    {field: 'availableSupplyBeforeRepricingPrice', round: true, valueFormatter: ({value}) => getRoundedPrice(value)},
    {field: 'availableSupplyPriceChange', round: true, valueFormatter: ({value}) => getRoundedPrice(value)},
    {field: 'availableSupplyChange'},
    {field: 'availableSupplyPriceChangePercent'},
    {field: 'availableSupplyChangePercent'},
    {
      field: 'regularPriceNational',
      headerName: translate('ReleaseItemDetail_regularPriceNational', nationalPriceZone.name),
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      round: true,
      valueFormatter: ({value}) => getRoundedPrice(value),
    },
    {
      field: 'actualPrice',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      round: true,
      valueFormatter: ({value}) => getRoundedPrice(value),
    },
    {field: 'marginOld', pinnedRowCellRenderer: RendererNames.BulkRenderer, round: true},
    {
      field: 'marginOldPercent',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      round: true,
    },
    {
      field: 'endDate',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      cellEditor: EditorNames.DateEditor,
    },
    {
      field: 'daysLeft',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      valueFormatter: ({value}) => getRoundedPrice(value, 0),
    },
    {
      field: 'selloutPrice',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      valueFormatter: ({value}) => getRoundedPrice(value),
    },
    {field: 'marginNew', pinnedRowCellRenderer: RendererNames.BulkRenderer, round: true},
    {
      field: 'marginNewPercent',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      round: true,
      cellClassRules: {
        'c-error': ({node}: {node: RowNode}) => {
          /** FIXME: silly implementation */
          const minMarginChange = node.data[`ReleaseSelloutItemDetail_minMarginChangeInPercent`]
            ? node.data[`ReleaseSelloutItemDetail_minMarginChangeInPercent`].toNumber()
            : 0;
          const marginNewPercent = node.data[`ReleaseSelloutItemDetail_marginNewPercent`]
            ? node.data[`ReleaseSelloutItemDetail_marginNewPercent`].toNumber()
            : 0;
          return !(marginNewPercent === 0 && minMarginChange === 0) && marginNewPercent < minMarginChange; // LOG-5203
        },
      },
    },
    {
      field: 'marginChange',
      comparator: Comparators.arrowComparator,
      cellRenderer: RendererNames.ArrowRenderer,
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      round: true,
    },
    {
      field: 'marginChangePercent',
      cellRenderer: RendererNames.ArrowRenderer,
      comparator: Comparators.arrowComparator,
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      round: true,
    },
    {
      field: `priceCode`,
      // selectOptions: priceCodeOptions,
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      // cellEditor: EditorNames.SelectEditor,
      filter: 'agSetColumnFilter',
      // action: handleUpdateFromAgGrid(PriceCodeItemUpdate.TAG),
      // editable: (params) => editable() && !rowIsReadOnly(params),
    },
    {
      field: 'exported',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      action: handleUpdateFromAgGrid(ExportedItemUpdate.TAG),
      editable: (params) => editable() && !rowIsReadOnly(params),
      cellClassRules: {
        'state-success': params => isCellEdited(
          params.data.ReleaseSelloutItemDetail_productId,
          params.data.ReleaseSelloutItemDetail_id,
          ExportedItemUpdate.TAG,
        ),
      },
      onEditStart,
      onEditEnd,
    },
    {
      field: 'finalPrice',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      editable: shouldFinalPriceBeEditable,
      minWidth: 80,
      width: 80,
      cellRenderer: RendererNames.ReleaseSelloutFinalPriceRenderer,
      comparator: Comparators.releaseFinalPriceComparator,
      cellEditor: EditorNames.ReleaseSelloutFinalPriceEditor,
      action: handleUpdateFromAgGrid(FinalPriceItemUpdate.TAG),
      bulkFunc: handleUpdateFromAgGrid(FinalPriceItemUpdate.TAG, true),
      filter: 'agNumberColumnFilter',
      hide: false,
      filterValueGetter: (params) => {
        const finalPrice = params.data.SelloutReleaseItemPrices_finalPrice;
        return finalPrice ? (finalPrice.valueRounded ? finalPrice.valueRounded : finalPrice.value) : null;
      },
      cellClassRules: {
        'state-success': params => isCellEdited(
          params.data.ReleaseSelloutItemDetail_productId,
          params.data.ReleaseSelloutItemDetail_id,
          FinalPriceItemUpdate.TAG,
        ),
      },
      onEditStart,
      onEditEnd,
    },
    ...getPreviousFinalPricesColDefs(reopenCount),
    {
      field: 'excel_finalPrice_type',
      valueFormatter: ({value}) => (value ? (value.priceType ? `${value.priceType}` : '') : ''),
      suppressToolPanel: true,
      hide: true,
      excel: {
        hide: false,
        valueFormatter: ({value}) => (value ? (value.priceType ? `${value.priceType}` : '') : ''),
      },
    },
    {
      field: 'excel_finalPrice',
      valueFormatter: ({value}) =>
        value
          ? value.valueRounded
            ? `${value.valueRounded
                .toFixed(1)
                .toString()
                .replace('.', ',')}`
            : value.value
              ? `${value.value
                  .toFixed(1)
                  .toString()
                  .replace('.', ',')}`
              : ''
          : '',
      hide: true,
      suppressToolPanel: true,
      excel: {
        hide: false,
        valueFormatter: ({value}) =>
          value
            ? value.valueRounded
              ? `${value.valueRounded
                  .toFixed(1)
                  .toString()
                  .replace('.', ',')}`
              : value.value
                ? `${value.value
                    .toFixed(1)
                    .toString()
                    .replace('.', ',')}`
                : ''
            : '',
      },
    },
    {
      field: 'temporaryComment',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      action: handleUpdateFromAgGrid(CommentItemUpdate.TAG),
      editable: (params) => editable() && !rowIsReadOnly(params),
      bulkFunc: handleUpdateFromAgGrid(CommentItemUpdate.TAG, true),
      cellClassRules: {
        'state-success': params => isCellEdited(
          params.data.ReleaseSelloutItemDetail_productId,
          params.data.ReleaseSelloutItemDetail_id,
          CommentItemUpdate.TAG,
        ),
      },
      onEditStart,
      onEditEnd,
    },
    {
      field: 'endDateFinalPrice',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      editable: shouldFinalPriceDateBeEditable,
      cellRenderer: RendererNames.ReleaseSelloutEndDateFinalPriceRenderer,
      cellEditor: EditorNames.ReleaseSelloutEndDateEditor,
      action: handleUpdateFromAgGrid(EndDateItemUpdate.TAG),
      bulkFunc: handleUpdateFromAgGrid(EndDateItemUpdate.TAG, true),
      cellClassRules: {
        'state-success': params => isCellEdited(
          params.data.ReleaseSelloutItemDetail_productId,
          params.data.ReleaseSelloutItemDetail_id,
          EndDateItemUpdate.TAG,
        ),
      },
      onEditStart,
      onEditEnd,
    },
    {
      field: 'daysLeftFinalPrice',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      valueFormatter: ({value}) => getRoundedPrice(value, 0),
    },
    {
      field: 'purchasePrice',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      valueFormatter: ({value}) => getRoundedPrice(value),
    },
    {
      field: 'openPurchasePrice',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      valueFormatter: ({value}) => getRoundedPrice(value),
    },
    {
      field: 'promo',
      filter: FilterNames.PromoPriceStatsFilter,
      tooltipComponent: TooltipNames.ReleaseSelloutPromoTooltip,
      valueFormatter: ({value}) => {
        if (value) {
          return value.currentPrices && value.currentPrices.size > 0 ? 'Yes' : 'No';
        }
        return CONSTANTS.FORMAT.NULL;
      },
    },
    {
      field: 'deadstockProvisionDiscountPercent',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      dontCreateColumn: release.selloutReleaseType !== SelloutReleaseType.Deadstock,
      round: true,
    },
    {
      field: 'deadstockProvision',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      dontCreateColumn: release.selloutReleaseType !== SelloutReleaseType.Deadstock,
      round: true,
    },
    {
      field: 'deadstockInvestment',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      dontCreateColumn: release.selloutReleaseType !== SelloutReleaseType.Deadstock,
      round: true,
    },
    {
      field: 'deadstockSelloutImpact',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      dontCreateColumn: release.selloutReleaseType !== SelloutReleaseType.Deadstock,
      round: true,
    },
    {
      field: 'expectedSalesAmount',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      dontCreateColumn: release.state !== ReleaseState.Released,
      round: true,
    },
    {
      field: 'actualSalesAmount',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      dontCreateColumn: release.state !== ReleaseState.Released,
      round: true,
    },
    {
      field: 'estimationInPercent',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      dontCreateColumn: release.state !== ReleaseState.Released,
      round: true,
    },
    {
      field: 'priceValidFrom',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
    },
    {
      field: 'estimatedEndDate',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      dontCreateColumn: release.state !== ReleaseState.Released,
    },
    {
      field: 'estimatedDays',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      dontCreateColumn: release.state !== ReleaseState.Released,
      round: true,
    },
    {
      field: 'discountPercent',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      valueFormatter: ({value}) => getRoundedPrice(value),
    },
    {
      field: 'salesVolumeOld',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      valueFormatter: ({value}) => getRoundedPrice(value, 0),
    },
    {
      field: 'salesVolumeNew',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      valueFormatter: ({value}) => getRoundedPrice(value, 0),
    },
    {
      field: 'revenueOld',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      valueFormatter: ({value}) => getRoundedPrice(value),
    },
    {
      field: 'revenueNew',
      pinnedRowCellRenderer: RendererNames.BulkRenderer,
      valueFormatter: ({value}) => getRoundedPrice(value),
    },
    {
      field: 'delistedFrom',
    },
    {
      field: 'readonly',
      dontCreateColumn: true,
    },
  ];
};

/**
 * @param site - InternalSite as CodebookItem
 * @param item - SelloutReleaseItem
 * @return data for product detail modal per row
 */
export const getReleaseSelloutDetailProductData = (
  site: CodebookItem | undefined,
  item: SelloutReleaseItem,
  reopenCount: number,
): StringMapping<any> => {
  const stockAmount = item.supplyData && item.supplyData.getStockAmount();
  const stockValue = item.getStockValue();
  const initialStockValue = item.getInitialStockValue();
  const initialStockVolume = item.getInitialStockVolume();

  /** NOTE: look getReleaseSelloutDetailProductDescription doc for detailed info about why those data have been used */
  return {
    itemId: item.id,
    id: item.siteId,
    productId: item.productId,
    siteName: site && site.title,
    minPriceChangeInPercent: item.minPriceChangeInPercent,
    maxPriceChangeInPercent: item.maxPriceChangeInPercent,
    minMarginChangeInPercent: item.minMarginChangeInPercent,
    messages: item.validationResult,
    stockAmount,
    stockValue,
    regularPriceNational: item.prices.regularPriceNational && item.prices.regularPriceNational.getPresentedValue(),
    actualPrice: item.prices.regularPrice && item.prices.regularPrice.getPresentedValue(),
    marginOld: item.prices.marginOld,
    marginOldPercent: item.prices.marginOldPercent,
    endDate: item.prices.endDateOld,
    daysLeft: item.prices.daysLeftOld,
    discountPercent: item.prices.discountPercent,
    selloutPrice: item.prices.selloutPrice && item.prices.selloutPrice.getPresentedValue(),
    profitOld: item.prices.profitOld,
    profitOldPercent: item.prices.profitOldPercent,
    profitNew: item.prices.profitNew,
    profitNewPercent: item.prices.profitNewPercent,
    profitImpact: item.prices.profitImpact,
    profitImpactPercent: item.prices.profitImpactPercent,
    salesVolumeOld: item.prices.salesVolumeOld,
    salesVolumeNew: item.prices.salesVolumeNew,
    marginNew: item.prices.marginNew,
    revenueOld: item.prices.revenueOld,
    revenueNew: item.prices.revenueNew,
    marginNewPercent: item.prices.marginNewPercent,
    marginChange: item.prices.marginChange,
    marginChangePercent: item.prices.marginChangePercent,
    exported: item.exported,
    priceCode: item.prices.priceCode,
    finalPrice: item.prices.finalPrice,
    ...getPreviousFinalPricesData(reopenCount, item),
    excel_finalPrice: item.prices.finalPrice,
    excel_finalPrice_type: item.prices.finalPrice,
    temporaryComment: item.prices.comments && item.prices.comments.has(0) ? item.prices.comments.get(0).text : '',
    endDateFinalPrice: item.prices.finalPrice && item.prices.finalPrice.validTo,
    daysLeftFinalPrice: item.prices.daysLeftNew,
    purchasePrice:
      item.prices.purchasePrice && item.prices.purchasePrice.avgPrice && item.prices.purchasePrice.avgPrice.value,
    openPurchasePrice:
      item.prices.purchasePrice &&
      item.prices.purchasePrice.priceListPrice &&
      item.prices.purchasePrice.priceListPrice.value,
    promo: item.prices.promoPriceStats,
    expectedSalesAmount: item.evaluation && item.evaluation.expectedSalesAmount,
    actualSalesAmount: item.evaluation && item.evaluation.actualSalesAmount,
    estimationInPercent: item.evaluation && item.evaluation.estimationInPercent,
    priceValidFrom: item.prices.finalPrice && item.prices.finalPrice.validFrom,
    estimatedEndDate: item.evaluation && item.evaluation.estimatedEndDate,
    estimatedDays: item.evaluation && item.evaluation.estimatedDays,
    deadstockProvisionDiscountPercent: item.prices.deadstockProvisionDiscountPercent,
    deadstockProvision: item.prices.deadstockProvision,
    deadstockInvestment: item.prices.deadstockInvestment,
    deadstockSelloutImpact: item.prices.deadstockSelloutImpact,
    delistedFrom: item.supplyData && item.supplyData.delistedFrom,
    readonly: item.readonly,
    availableSupplyBeforeRepricing: !Utils.isValueMissing(initialStockVolume)
      ? PriceFormulas.roundDefault(initialStockVolume)
      : null,
    availableSupplyBeforeRepricingPrice: !Utils.isValueMissing(initialStockValue)
      ? PriceFormulas.roundDefault(initialStockValue)
      : null,
    availableSupplyPriceChange:
      !Utils.isValueMissing(initialStockValue) && !Utils.isValueMissing(stockValue)
        ? PriceFormulas.roundDefault(PriceFormulas.computeAvailableSupplyPriceChange(initialStockValue, stockValue))
        : null,
    availableSupplyChange:
      !Utils.isValueMissing(initialStockVolume) && !Utils.isValueMissing(stockAmount.value)
        ? PriceFormulas.roundDefault(PriceFormulas.computeAvailableSupplyChange(initialStockVolume, stockAmount.value))
        : null,
    availableSupplyPriceChangePercent:
      !Utils.isValueMissing(initialStockValue) && !Utils.isValueMissing(stockValue)
        ? PriceFormulas.roundDefault(
            PriceFormulas.computeAvailableSupplyPriceChangePercent(initialStockValue, stockValue),
          )
        : null,
    availableSupplyChangePercent:
      !Utils.isValueMissing(initialStockVolume) && !Utils.isValueMissing(stockAmount.value)
        ? PriceFormulas.roundDefault(
            PriceFormulas.computeAvailableSupplyChangePercent(initialStockVolume, stockAmount.value),
          )
        : null,
  };
};

/**
 * Returns descriptions of columns for previous final prices (from reopens).
 *
 * @param reopenCount
 */
function getPreviousFinalPricesDescription(reopenCount: number) {
  const builder = new StructPropBuilder('ReleaseSelloutItemDetail');

  return [...Array(reopenCount)].reduce((prev, curr, ind) => {
    prev[`finalPrice_${ind}`] = builder.opt(builder.obj(`finalPrice_${ind}`, FinalProductPrice.schema, mat => FinalProductPrice.fromDataDict(mat)));
    prev[`discountPercent_${ind}`] = builder.opt(builder.bigNum(`discountPercent_${ind}`));
    return prev;
  }, {});
}

/**
 * Returns col definition for previous final prices (from reopens).
 *
 * @param reopenCount
 */
function getPreviousFinalPricesColDefs(reopenCount: number) {
  const colDefs = (val, ind) => [{
    field: `finalPrice_${ind}`,
    comparator: Comparators.releaseFinalPriceComparator,
    filter: 'agNumberColumnFilter',
    hide: false,
    headerName: ind === 0 ?
      translate(`SelloutReleaseItemPrices_finalPrice_initial`) :
      translate('SelloutReleaseItemPrices_finalPrice_reopen', ind.toString(10)),
    valueFormatter: ({value: finalPrice}) => {
      if (Utils.isValueMissing(finalPrice) || Utils.isValueMissing(finalPrice.value)) {
        return CONSTANTS.FORMAT.NULL;
      }

      const price = Number(finalPrice.valueRounded || finalPrice.value);
      return price.toFixed(2).toString().replace('.', ',');
    },
    filterValueGetter: (params) => {
      const finalPrice = params.data[`SelloutReleaseItemPrices_finalPrice_${ind}`];
      return finalPrice ? (finalPrice.valueRounded ? finalPrice.valueRounded : finalPrice.value) : null;
    },
  }, {
    field: `discountPercent_${ind}`,
    headerName: ind === 0 ?
      translate(`SelloutReleaseItemPrices_discountPercent_initial`) :
      translate('SelloutReleaseItemPrices_discountPercent_reopen', ind.toString(10)),
    valueFormatter: ({value}) => getRoundedPrice(value),
  }];

  return [].concat.apply([], [...Array(reopenCount)].map(colDefs));
}

/**
 * Returns data for previous final prices (from reopens).
 *
 * @param reopenCount
 */
function getPreviousFinalPricesData(reopenCount: number, item: SelloutReleaseItem) {
  return [...Array(reopenCount)].reduce((prev, curr, ind) => {
    const snapshot = item.reopenSnapshots.get(ind);
    if (snapshot && snapshot.exported) {
      prev[`finalPrice_${ind}`] = item.reopenSnapshots.get(ind).prices.finalPrice;
      prev[`discountPercent_${ind}`] = item.reopenSnapshots.get(ind).prices.discountPercent;
    } else {
      prev[`finalPrice_${ind}`] = null;
      prev[`discountPercent_${ind}`] = null;
    }
    return prev;
  }, {});
}

const getRoundedPrice = (value: any, digits: number = 2): string => {
  if (!value) {
    return CONSTANTS.FORMAT.NULL;
  }

  return value
    .toFixed(digits)
    .toString()
    .replace('.', ',');
};

export const validateMinPriceChange = (maxPriceField: string) => (value: number, node: RowNode) => {
  console.log('validate', value, node);
  if (value != null) {
    if (value < 0) {
      return new ValidationError('err-minPriceChange-negative');
    }
    const maxPriceChange = node.data[maxPriceField];

    if (maxPriceChange && maxPriceChange < value) {
      return new ValidationError('ERROR_minPriceChangeMoreThanMaxPriceChange');
    }
  }

  return null;
}

export const validateMaxPriceChange = (minPriceField: string) => (value: number, node: RowNode) => {
  console.log('validate', value, node);
  if (value != null) {
    if (value < 0) {
      return new ValidationError('err-maxPriceChange-negative');
    }
    const minPriceChange = node.data[minPriceField];

    if (minPriceChange && value < minPriceChange) {
      return new ValidationError('ERROR_maxPriceChangeLessThanMinPriceChange');
    }
  }

  return null;
}
