import { ReleaseState } from '@logio/common-be-fe';
import { theme } from '@logio/common-fe';

export default [
  {
    id: ReleaseState.New,
    interior: {
      color: theme.colors.stateNew,
      pattern: 'Solid',
    },
  },
  {
    id: ReleaseState.ExportStarted,
    interior: {
      color: theme.colors.stateExportStarted,
      pattern: 'Solid',
    },
  },
  {
    id: ReleaseState.Computing,
    interior: {
      color: theme.colors.stateInProgress,
      pattern: 'Solid',
    },
  },
  {
    id: ReleaseState.Opened,
    interior: {
      color: theme.colors.stateOpened,
      pattern: 'Solid',
    },
  },
  {
    id: ReleaseState.Approved,
    interior: {
      color: theme.colors.stateApproved,
      pattern: 'Solid',
    },
  },
  {
    id: ReleaseState.ExportFailed,
    interior: {
      color: theme.colors.stateFailed,
      pattern: 'Solid',
    },
  },
  {
    id: ReleaseState.Released,
    interior: {
      color: theme.colors.stateReleased,
      pattern: 'Solid',
    },
  },
  {
    id: ReleaseState.Cancelled,
    interior: {
      color: theme.colors.stateReleased,
      pattern: 'Solid',
    },
  },
];
