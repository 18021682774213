"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Error item describing failure (line, column, msg) of the imported .xls file.
 * @author: OPt
 */

var StructPropBuilder_1 = require("../../../core/model/data-dictionary/StructPropBuilder");

var WindowImportErrorSeverityEnum_1 = require("./WindowImportErrorSeverityEnum");

var WindowImportValidationError =
/**
 * @param line Number of the line.
 * @param column Caption of the column.
 * @param type Description of the error.
 * @param severity
 */
function WindowImportValidationError(line, column, type, //public readonly column: WindowImportColumnEnum, //public readonly type: WindowImportErrorEnum,
severity) {
  _classCallCheck(this, WindowImportValidationError);

  this.line = line;
  this.column = column;
  this.type = type;
  this.severity = severity;
};

exports.WindowImportValidationError = WindowImportValidationError;

WindowImportValidationError.schema = function () {
  var builder = new StructPropBuilder_1.StructPropBuilder(WindowImportValidationError.name);
  return {
    line: builder.opt(builder.num('line')),
    column: builder.str('column'),
    type: builder.str('type'),
    severity: builder.senum('severity', WindowImportErrorSeverityEnum_1.WindowImportErrorSeverityEnum, 'enum/WindowImportErrorSeverityEnum')
  };
}();