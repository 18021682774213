export var StoreName;

(function (StoreName) {
  StoreName["CleaningState"] = "CLEANING_STATE_STORE";
  StoreName["Competitor"] = "COMPETITOR_STORE";
  StoreName["CompetitorPrice"] = "COMPETITOR_PRICE_STORE";
  StoreName["CompetitorPriceHistoryIndex"] = "COMPETITOR_PRICE_HISTORY_INDEX_STORE";
  StoreName["CompetitorProduct"] = "COMPETITOR_PRODUCT_STORE";
  StoreName["CompetitorSite"] = "COMPETITOR_SITE_STORE";
  StoreName["CompetitorsPriority"] = "COMPETITORS_PRIORITY";
  StoreName["Currency"] = "CURRENCY";
  StoreName["CRP"] = "CRP_STORE";
  StoreName["DataPairing"] = "DATA_PAIRING_STORE";
  StoreName["DataUpload"] = "DATA_UPLOAD_STORE";
  StoreName["Dictionary"] = "DICTIONARY_STORE";
  StoreName["DummyWindow"] = "DUMMY_WINDOW_STORE";
  StoreName["Error"] = "ERROR_STORE";
  StoreName["Family"] = "FAMILY_STORE";
  StoreName["FormStore"] = "FORM_STORE";
  StoreName["GeneralConfigurations"] = "GENERAL_CONFIGURATIONS_STORE";
  StoreName["GeneralSettings"] = "GENERAL_SETTINGS_STORE";
  StoreName["GlobalSettings"] = "GLOBAL_SETTINGS_STORE";
  StoreName["Grid"] = "GRID_STORE";
  StoreName["GroupOfSites"] = "GROUP_OF_SITES_STORE";
  StoreName["InternalProductPrices"] = "INTERNAL_PRODUCT_PRICES_STORE";
  StoreName["Keycloak"] = "KEYCLOAK_STORE";
  StoreName["Message"] = "MESSAGE_STORE";
  StoreName["Profile"] = "PROFILE_STORE";
  StoreName["Notification"] = "NOTIFICATION_STORE";
  StoreName["PageInfo"] = "PAGE_INFO_STORE";
  StoreName["PriceZone"] = "PRICE_ZONE_STORE";
  StoreName["Product"] = "PRODUCT_STORE";
  StoreName["ProductCategory"] = "PRODUCT_CATEGORY_STORE";
  StoreName["ProductSensitivity"] = "PRODUCT_SENSITIVITY_STORE";
  StoreName["PromoSettings"] = "PROMO_GENERAL_SETTINGS";
  StoreName["Promotion"] = "PROMOTION_STORE";
  StoreName["Release"] = "RELEASE_STORE";
  StoreName["ReplePromo"] = "REPLE_PROMO_STORE";
  StoreName["SelloutRelease"] = "SELLOUT_RELEASE_STORE";
  StoreName["RoundingRule"] = "ROUNDING_RULES_STORE";
  StoreName["SegmentationModel"] = "SEGMENTATION_MODEL_STORE";
  StoreName["SettingsOverviewDTOStore"] = "SETTINGS_OVERVIEW_DTO_STORE";
  StoreName["SettingsStore"] = "SETTINGS_STORE";
  StoreName["SupplierAgreement"] = "SUPPLIER_AGREEMENT_STORE";
  StoreName["Supplier"] = "SUPPLIER_STORE";
  StoreName["Theme"] = "THEME_STORE";
  StoreName["Tier"] = "TIERS_STORE";
  StoreName["Report"] = "REPORT_STORE";
  StoreName["User"] = "USER_STORE";
  StoreName["Window"] = "WINDOW_STORE";
  StoreName["WindowType"] = "WINDOW_TYPE_STORE";
  StoreName["WindowItem"] = "WINDOW_ITEM_STORE";
  StoreName["WindowFile"] = "WINDOW_FILE_STORE";
  StoreName["Kpi"] = "KPI_STORE";
  StoreName["WorkflowAdministration"] = "WORKFLOW_ADMINISTRATION_STORE";
  StoreName["RouteBlocker"] = "ROUTE_BLOCKER_STORE";
  StoreName["SDRExcelFile"] = "SALES_DASHBOARD_EXCEL_FILE_STORE";
  StoreName["SelloutTerminationStore"] = "SELLOUT_TERMINATION_STORE";
  StoreName["Download"] = "DOWNLOAD";
})(StoreName || (StoreName = {}));