/**
 * @file Created on Wed Jul 24 2019
 * @author SKu
 */
import * as React from 'react';
import FlagsRenderer from '../../../../info/FlagsRenderer';

var ProductFlagsRenderer = function ProductFlagsRenderer(_ref) {
  var value = _ref.value;
  return React.createElement("span", null, React.createElement(FlagsRenderer, {
    flagSet: value,
    iconHeight: 18
  }));
};

export default ProductFlagsRenderer;