/**
 * @file Created on Thu Jun 13 2019
 * @author SKu
 */
import { Utils } from '@logio/common-be-fe';
import * as React from 'react';
import { CONSTANTS } from '../../../../../constants';
import { N } from '../../../../i18n/N';
import Tooltip from '../../../../info/Tooltip';
export var ReleaseRegularPriceRenderer = function ReleaseRegularPriceRenderer(props) {
  // Empty value renderer
  if (Utils.isValueMissing(props.value)) {
    return React.createElement(React.Fragment, null, CONSTANTS.FORMAT.NULL);
  }

  var edlp = props.data[props.colDef.cellRendererParams.tooltipDataColKey];
  var regularPrice = React.createElement("span", {
    className: edlp ? 'c-error' : undefined
  }, React.createElement(N, {
    value: props.value
  }));
  return edlp ? React.createElement(Tooltip, {
    overlay: React.createElement("span", null, React.createElement(N, {
      value: edlp
    })),
    children: regularPrice,
    placement: "top"
  }) : regularPrice;
};