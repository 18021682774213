/**
 * @file Created on Fri Nov 16 2018
 * @author SKu
 */
import { Utils, ReleaseItemRepricingMessageCode } from '@logio/common-be-fe';
import * as React from 'react';
import translate, { translateResultObject } from '../../../../i18n/translate';
import Tooltip from '../../../../info/Tooltip';
import Icon, { IconColor, IconType } from '../../../../media/Icon';
export var ReleaseAlertsRenderer = function ReleaseAlertsRenderer(_ref) {
  var value = _ref.value;

  if (Utils.isValueMissing(value) || Utils.isValueMissing(value.resultObjects) || value.resultObjects.size === 0) {
    return null;
  }

  var groupedValidationResults = new Map();
  value.resultObjects.forEach(function (item) {
    var key = item.messageKey;
    var collection = groupedValidationResults.get(key);

    if (!collection) {
      groupedValidationResults.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  var tooltipInfos = [];
  groupedValidationResults.forEach(function (value, key) {
    var el;

    if (key === ReleaseItemRepricingMessageCode.ReleaseItemPromoProtectionViolation) {
      el = React.createElement("li", {
        key: key
      }, translate("".concat(value[0].severity, "_").concat(value[0].messageKey)), React.createElement("ul", {
        style: {
          margin: '0px auto'
        }
      }, value.map(function (resultObject, index) {
        var amount = Number(resultObject.messageVariables.get(0)).toString();
        var dateFrom = new Date(resultObject.messageVariables.get(1)).toLocaleDateString();
        var dateTo = new Date(resultObject.messageVariables.get(2)).toLocaleDateString();
        return React.createElement("li", {
          key: index
        }, dateFrom, " - ", dateTo, ", ", amount, " K\u010D");
      })));
    } else {
      el = value.map(function (resultObject, index) {
        return React.createElement("li", {
          key: index
        }, translateResultObject(resultObject));
      });
    }

    tooltipInfos.push(el);
  });
  return React.createElement(Tooltip, {
    overlay: tooltipInfos.map(function (value) {
      return value;
    }),
    children: React.createElement("div", null, React.createElement(Icon, {
      iconType: IconType.alert,
      iconColor: IconColor.Error,
      iconHeight: 20
    }))
  });
};