"use strict";
/*
 * Created on 09 02 2020
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * <p>Enumerates states of a {@link PriceTermination termination process}. Enumeration may also be used to define state of
 * some sub-parts of a termination process, but not all of the states may be used.</p>
 *
 * @author JMe
 */

var PriceTerminationState;

(function (PriceTerminationState) {
  /**
   * Data is opened for editing after successful upload. This is the only state in which user can edit rows.
   */
  PriceTerminationState["Opened"] = "OPENED";
  /**
   * User has confirmed prices termination, either as the whole or individual release after its failure (summary item).
   * <br/>
   * User should not be able to edit anything from this state.
   */

  PriceTerminationState["Terminating"] = "TERMINATING";
  /**
   * State in which prices have been terminated for all rows (or rows related to specific release in case of summary item).
   * <br/>
   * User should not be able to edit anything from this state.
   */

  PriceTerminationState["Terminated"] = "TERMINATED";
  /**
   * State in which the entire process (or individual release) is after an error occurred at any stage of the termination process.
   * <br/>
   * This state may or may no be final.
   * <br/>
   * If there is a release, for which termination has ended with an error, the user can try to terminate the release again.
   * <br/>
   * User should not be able to edit any row in this state, but he/she should be able to retry termination of a release after its failure.
   */

  PriceTerminationState["Failed"] = "FAILED";
  /**
   * User interrupted process of {@link PriceTermination}.
   * <br/>
   * User should not be able to edit anything in this state.
   */

  PriceTerminationState["Cancelled"] = "CANCELLED";
})(PriceTerminationState = exports.PriceTerminationState || (exports.PriceTerminationState = {}));