/**
 * @file Created on Fri Nov 16 2018
 * @author SKu
 */
import { Utils } from '@logio/common-be-fe';
import * as React from 'react';
import { CONSTANTS } from '../../../../../constants';
import { N } from '../../../../i18n/N';
import Icon, { IconColor, IconType } from '../../../../media/Icon';
/**
 * Renders Arrow near the number
 */

export var ArrowRenderer = function ArrowRenderer(props) {
  var cellRendererParams = props.colDef.cellRendererParams;
  var limit = cellRendererParams && cellRendererParams.limit;
  var colId = props.colDef.colId; // Empty value renderer

  if (Utils.isValueMissing(props.value)) {
    return React.createElement(React.Fragment, null, CONSTANTS.FORMAT.NULL);
  } // Extracting value if exists


  var finalValue = props.value.value ? props.value.value : props.value; // LOG-4064

  var getIndexColorClassName = function getIndexColorClassName() {
    if ("toNumber" in finalValue) {
      if (finalValue.toNumber() <= -limit) {
        return 'c-red-cell-text';
      }

      if (finalValue.toNumber() > limit) {
        return 'c-green-cell-text';
      }
    } else {
      if (finalValue <= -limit) {
        return 'c-red-cell-text';
      }

      if (finalValue > limit) {
        return 'c-green-cell-text';
      }
    }

    return undefined;
  };

  var renderElement = [React.createElement(N, {
    key: "value",
    className: limit && "fw500 ".concat(getIndexColorClassName()),
    value: finalValue,
    round: true
  })];

  var pushIcon = function pushIcon(iconType, iconColor) {
    var iconHeight = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 10;
    renderElement.push(React.createElement(Icon, {
      key: 'icon',
      iconType: iconType,
      iconColor: iconColor,
      iconHeight: iconHeight
    }));
  };

  var changePrice = props.data.PriceElasticityReport_salesVolumeChangePercent ? props.data.PriceElasticityReport_salesVolumeChangePercent.toNumber() : null;

  if (colId === 'PriceElasticityReport_salesVolume') {
    if (changePrice > 0) {
      pushIcon(IconType.arrowUp, IconColor.Green);
    } else if (changePrice !== 0) {
      pushIcon(IconType.arrowDown, IconColor.Red);
    }
  } else if (['PriceElasticityReport_profit', 'PriceElasticityReport_margin'].includes(colId)) {
    if (changePrice > 0) {
      pushIcon(IconType.arrowDown, IconColor.Red);
    } else if (changePrice !== 0) {
      pushIcon(IconType.arrowUp, IconColor.Green);
    }
  } else if (finalValue.isNeg()) {
    pushIcon(IconType.arrowDown, IconColor.Red);
  } else if (finalValue.toNumber() !== 0) {
    pushIcon(IconType.arrowUp, IconColor.Green);
  }

  return React.createElement("div", {
    className: "tar"
  }, renderElement);
};