"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var immutable_1 = require("immutable");

var Role_1 = require("./Role");
/**
 * Well-known promo roles that have specific usage in application business logic.
 *
 * @author MSu
 */


var PromoRoles = function PromoRoles() {
  _classCallCheck(this, PromoRoles);
};

exports.PromoRoles = PromoRoles;
PromoRoles.ADMIN = new Role_1.Role('PROMO_Admin');
PromoRoles.ASSISTANT = new Role_1.Role('PROMO_Assistant');
PromoRoles.AUDITOR = new Role_1.Role('PROMO_Auditor');
PromoRoles.CATEGORY_MANAGER = new Role_1.Role('PROMO_CategoryManager');
PromoRoles.COMMERCE = new Role_1.Role('PROMO_Commerce');
PromoRoles.COMMERCIAL_DIRECTOR = new Role_1.Role('PROMO_CommercialDirector');
PromoRoles.CONTROLLING = new Role_1.Role('PROMO_Controlling');
PromoRoles.FORECAST_MANAGER = new Role_1.Role('PROMO_ForecastManager');
PromoRoles.IT_HELP_DESK = new Role_1.Role('PROMO_ITHelpDesk');
PromoRoles.PROMO_MANAGER = new Role_1.Role('PROMO_PromoManager');
PromoRoles.REPLENISHMENT = new Role_1.Role('PROMO_Replenishment');
PromoRoles.SENIOR_CATEGORY_MANAGER = new Role_1.Role('PROMO_SeniorCategoryManager');
PromoRoles.SHELF_PLANNING = new Role_1.Role('PROMO_ShelfPlanning');
PromoRoles.TRADE_DIRECTOR = new Role_1.Role('PROMO_TradeDirector');
PromoRoles.PROMO_SYSTEM_ADMIN = new Role_1.Role('PROMO_SystemAdmin'); // Register new roles here!

PromoRoles.ALL_ROLES = immutable_1.List.of(PromoRoles.ADMIN, PromoRoles.ASSISTANT, PromoRoles.AUDITOR, PromoRoles.CATEGORY_MANAGER, PromoRoles.COMMERCE, PromoRoles.COMMERCIAL_DIRECTOR, PromoRoles.CONTROLLING, PromoRoles.FORECAST_MANAGER, PromoRoles.IT_HELP_DESK, PromoRoles.PROMO_MANAGER, PromoRoles.REPLENISHMENT, PromoRoles.SENIOR_CATEGORY_MANAGER, PromoRoles.SHELF_PLANNING, PromoRoles.TRADE_DIRECTOR, PromoRoles.PROMO_SYSTEM_ADMIN);