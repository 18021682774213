/**
 * @file Created on Thu Sep 06 2018
 * @author SKu
 */

import {DataUpload, ProgressMonitorStatus, ResearchTypeEnum, UploadTypeEnum, Utils} from '@logio/common-be-fe';
import {
  DataUploadStore,
  DownloadStore,
  getPath,
  KeycloakStore,
  PageStore,
  Polling,
  ProgressMonitorLayer,
  rootStore,
  StoreName,
  translate,
} from '@logio/common-fe';
import {action, observable, runInAction} from 'mobx';
import {PagePathsEnum} from '../../../../shared/localization/PagePathsEnum';

/** Modal Types enum */
export enum DataUploadModalEnum {
  UPLOAD_PROGRESS = 'UPLOAD_PROGRESS',
  UPLOAD = 'UPLOAD',
  UPLOAD_SENSITIVITY = 'UPLOAD_PRICE_SENSITIVITY',
  UPLOAD_FAMILY = 'UPLOAD_FAMILY',

  CONFIRM_SENSITIVITY = 'CONFIRM_SENSITIVITY',
  CONFIRM_APPROVE = 'CONFIRM_APPROVE',
  CONFIRM_REJECT = 'CONFIRM_REJECT',
  CONFIRM_FAMILY = 'CONFIRM_FAMILY',
}

/** Enum for handling current workflow state */
export enum DataUploadStateEnum {
  UPLOAD_PROGRESS = 'UPLOAD_PROGRESS',
  ANALYZING = 'ANALYZING',
  APPROVING = 'APPROVING',
}

export class DataUploadNewPageStore extends PageStore {
  dataUploadStore = rootStore.getStore(StoreName.DataUpload) as DataUploadStore;
  keycloakStore = rootStore.getStore(StoreName.Keycloak) as KeycloakStore;
  progressMonitorLayer: ProgressMonitorLayer = new ProgressMonitorLayer();
  downloadStore = rootStore.getStore(StoreName.Download) as DownloadStore;

  /** @param historyPush - function form React Router */
  constructor(public historyPush: (path: string) => void) {
    super();
  }

  /** Polling service */
  polling = new Polling(2000);

  /** Id of uploaded file */
  @observable
  dataUploadId: string;

  /** Uploaded file */
  @observable
  dataUpload: DataUpload;

  /** Current upload state  */
  @observable
  dataUploadState: DataUploadStateEnum = DataUploadStateEnum.UPLOAD_PROGRESS;

  /** Variable used for opening and closing modals */
  @observable
  modalShown: DataUploadModalEnum;

  /** Used to store sensitivity upload data for confirmation modal */
  @observable
  sensitivityData: {name: string; data: FormData};

  /** Used to store family upload data for confirmation modal */
  @observable
  familyData: {name: string; data: FormData};

  /** Variable used for progress monitoring(for axios or polling). Unit - [%] */
  @observable
  progress: number = 0;

  @observable
  isTemplateDownloading: boolean = false;

  @observable
  dataUploadType: UploadTypeEnum = UploadTypeEnum.AGENCY;

  /** Checks if modal should be hidden. */
  isModalHidden = (name: DataUploadModalEnum) => this.modalShown !== name;

  /**
   *  Function used as callback for axios progress monitoring
   *  First workflow state progress bar handling
   * @param progressEvent
   */
  onUploadProgress = (progressEvent): void => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    runInAction(() => (this.progress = percentCompleted));
  };

  /**
   * Function handle polling monitoring
   *
   * @param errors
   * @param response
   */
  onPollResponse = (errors?, response?: ProgressMonitorStatus) => {
    if (!Utils.isValueMissing(response)) {
      /** If polling just started, progress should be 0 */
      if (response.tag === 'new') {
        runInAction(() => (this.progress = 0));
      }
      /** Sets progress to current percentage */
      if (response.tag === 'working') {
        runInAction(() => (this.progress = Math.round((response.worked * 100) / response.total)));
      }
      /** Here is logic, that changing workflow state depending on
       * Current Upload type and current workflow state
       */
      if (response.tag === 'done') {
        /** If action done, we should turn off polling  and set progress to 100%(in case if previous value was less then 100) */
        this.polling.stopPolling();
        runInAction(() => (this.progress = 100));
        /** Switching through different workflow states */
        switch (this.dataUploadState) {
          case DataUploadStateEnum.ANALYZING:
            /** In case if workflow state was analyzing, we should update dataUpload variable,
             * to get analyzed warnings/info from BE
             */
            this.onUploadAnalyzingFinished();
            break;
          case DataUploadStateEnum.APPROVING:
            /** If user just approved upload, he should be redirected to Upload Overview */
            this.historyPush(getPath(PagePathsEnum.DataUpload));
            this.messages.setSuccess(translate('DataUpload_approved'));
            break;
          default:
            throw new Error('Wrong Upload State');
        }
      }
      if (response.tag === 'failed') {
        this.polling.stopPolling();
        this.hideModal();
        this.messages.setError(translate('upload-failed'));
      }
    }
  };

  /** Handle case, when upload has been analyzed */
  onUploadAnalyzingFinished = async () => {
    try {
      const dataUpload = await this.dataUploadStore.getOne(this.dataUploadId);
      runInAction(() => (this.dataUpload = dataUpload));
      /**
       * We should close progress modal
       * And in the same time open other modal to show warnings to user
       * depending on upload type.
       */

      let modal: DataUploadModalEnum;
      if (dataUpload.type === UploadTypeEnum.PRICE_SENSITIVITY) {
        modal = DataUploadModalEnum.UPLOAD_SENSITIVITY;
      } else if (dataUpload.type === UploadTypeEnum.FAMILY) {
        modal = DataUploadModalEnum.UPLOAD_FAMILY;
      } else {
        modal = DataUploadModalEnum.UPLOAD;
      }
      this.openModal(modal);
    } catch (error) {
      this.hideModal();
      // this.messages.setError(error);
    }
  };

  /** Fires on upload Form Submit
   * Upload data depending on Upload Type
   * @param values - callback from Form
   */
  onUploadSubmit = async ({
    agencyType,
    researchType,
    researchName,
    uploadType,
    dataFile,
  }: {
    researchType?: ResearchTypeEnum;
    agencyType?: string;
    researchName: string;
    dataFile: any;
    uploadType: UploadTypeEnum;
  }) => {
    this.openModal(DataUploadModalEnum.UPLOAD_PROGRESS);
    const data = new FormData();
    let uploadResponse;
    data.append('dataFile', dataFile.data);
    data.append('researchName', researchName);
    data.append('dataSource', uploadType);
    try {
      if (uploadType === UploadTypeEnum.PRICE_SENSITIVITY) {
        runInAction(() => (this.sensitivityData = {name: dataFile.name, data}));
        this.openModal(DataUploadModalEnum.CONFIRM_SENSITIVITY);
      } else if(uploadType === UploadTypeEnum.FAMILY) {
        runInAction(() => (this.familyData = {name: dataFile.name, data}));
        this.openModal(DataUploadModalEnum.CONFIRM_FAMILY);
      } else {
        /** Add researchType if uploadType is not Sensitivity */
        if (!Utils.isValueMissing(researchType)) {
          data.append('researchType', researchType);
        }
        if (uploadType === UploadTypeEnum.AGENCY) {
          /** Add agencyType to request if Upload type is Agency */
          data.append('agencyType', agencyType);
        }
        /** Default upload (the first progress bar handles without polling) */
        uploadResponse = await this.dataUploadStore.dataUploadLayer.upload(data, this.onUploadProgress);
        this.handleUpload(uploadResponse);
      }
    } catch (error) {
      this.hideModal();
      // this.messages.setError(error);
    }
  };

  /** Handle Uploading */
  handleUpload = (uploadResponse: any) => {
    runInAction(() => {
      /** Change workflow state to analyzing (the second workflow state) */
      this.dataUploadState = DataUploadStateEnum.ANALYZING;
      this.dataUploadId = uploadResponse.uploadId;
    });
    /** analyzing polling */
    this.polling.startPolling(this.onPollingStarted(uploadResponse), this.onPollResponse);
  };

  /** Handle User approve */
  onApprove = async () => {
    try {
      /** Approve upload */
      const uploadResponse = await this.dataUploadStore.dataUploadLayer.approve(this.dataUploadId);
      /** Switch modals */
      this.openModal(DataUploadModalEnum.UPLOAD_PROGRESS);
      /** Change workflow state to approving */
      runInAction(() => (this.dataUploadState = DataUploadStateEnum.APPROVING));
      /** Start approve polling */
      this.polling.startPolling(this.onPollingStarted(uploadResponse), this.onPollResponse);
    } catch (error) {
      this.hideModal();
      // this.messages.setError(error);
    }
  };

  /** Handle User Reject upload */
  onReject = async () => {
    try {
      await this.dataUploadStore.dataUploadLayer.reject(this.dataUploadId);
      this.messages.setSuccess(translate('DataUpload_rejected'));
    } catch (error) {
      // this.messages.setError(error);
    }
    this.hideModal();
  };

  /** Handles sensitivity upload */
  onSensitivityConfirm = async () => {
    try {
      this.openModal(DataUploadModalEnum.UPLOAD_PROGRESS)
      /** Upload sensitivity (the first progress bar handles without polling) */
      const uploadResponse = await this.dataUploadStore.dataUploadLayer.uploadSensitivity(
        this.sensitivityData.data,
        this.onUploadProgress,
      );
      this.handleUpload(uploadResponse);
    } catch (error) {
      // this.messages.setError(error);
      this.hideModal();
    }
  };

  /** Handles sensitivity upload */
  onFamilyConfirm = async () => {
    try {
      this.openModal(DataUploadModalEnum.UPLOAD_PROGRESS)
      /** Upload sensitivity (the first progress bar handles without polling) */
      const uploadResponse = await this.dataUploadStore.dataUploadLayer.uploadFamily(
        this.familyData.data,
        this.onUploadProgress,
      );
      this.handleUpload(uploadResponse);
    } catch (error) {
      // this.messages.setError(error);
      this.hideModal();
    }
  };

  /** Function that passed to start polling function */
  onPollingStarted = (uploadResponse) => async () => {
    try {
      const response: ProgressMonitorStatus = await this.progressMonitorLayer.status(uploadResponse.progress.id);
      return response;
    } catch (error) {
      this.polling.stopPolling();
      this.hideModal();
      // this.messages.setError(error);
    }
  };

  @action
  downloadTemplate = () => {
    this.isTemplateDownloading = true;
    this.downloadStore.download(translate(`DataUpload_template: ${this.dataUploadType}`)).then(() => {
      runInAction(() => {
        this.isTemplateDownloading = false;
      });
    });
  };

  /**
   * Negate modalHiddenProp
   * @param title - name of the modalHidden prop that should be negated
   */
  @action
  openModal = (modalType: DataUploadModalEnum) => {
    this.modalShown = modalType;
  };

  /**
   * HOF for toggle modal
   * used in Tiers page when u get event besides parameter
   */
  getOpenModalEvent = (modalTypes: DataUploadModalEnum) => () => {
    this.openModal(modalTypes);
  };

  /** Function used in case if endpoint return error */
  @action
  hideModal = () => {
    this.modalShown = undefined;
  };

  @action
  onFormChange = (values) => {
    this.dataUploadType = values.uploadType;
  };
}
