/**
 * @file Created on Thu May 09 2019
 * @author SKu
 */

import {
  ReleaseSelloutStore,
  rootStore,
  StoreName,
} from '@logio/common-fe';
import {History} from 'history';
import { PagePathsEnum } from '../../../../shared/localization/PagePathsEnum';
import {ReleaseSelloutDetailCategoryComponentStore, ReleaseSelloutHeaderStore} from '../../../components';
import {AbstractReleaseExportPageStore} from './AbstractReleaseExportPageStore';

export class ReleaseSelloutExportPageStore extends AbstractReleaseExportPageStore {
  /** @inheritdoc */
  constructor(public history: History, public releaseId: string) {
    super(history, releaseId);
  }

  public releaseStore = rootStore.getStore(StoreName.SelloutRelease) as ReleaseSelloutStore;
  public releaseHeaderStore = new ReleaseSelloutHeaderStore(this.messages);
  public releaseDetailCategoryComponentStore = new ReleaseSelloutDetailCategoryComponentStore(
    this.history,
    this.messages,
    this.releaseId,
  );
  public detailPath = PagePathsEnum.ReleaseSelloutDetail;
}
