"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var StructPropBuilder_1 = require("../../core/model/data-dictionary/StructPropBuilder");

var Impact_1 = require("./Impact");
/**
 * Information about one product in price impact structure.
 *
 * @author Michal Sevcenko
 */


var PriceImpactProductReport =
/**
 * ctor
 *
 * @param productId identifier of the product
 * @param salesVolumeImpact sales volume impact of the product
 */
function PriceImpactProductReport(productId, salesVolumeImpact, marginImpact, revenueImpact, profitImpact) {
  _classCallCheck(this, PriceImpactProductReport);

  this.productId = productId;
  this.salesVolumeImpact = salesVolumeImpact;
  this.marginImpact = marginImpact;
  this.revenueImpact = revenueImpact;
  this.profitImpact = profitImpact;
};

exports.PriceImpactProductReport = PriceImpactProductReport;

PriceImpactProductReport.schema = function () {
  var builder = new StructPropBuilder_1.StructPropBuilder(PriceImpactProductReport.name);
  return {
    productId: builder.str('productId'),
    salesVolumeImpact: builder.obj('salesVolumeImpact', Impact_1.Impact.schema, Impact_1.Impact.ctor),
    marginImpact: builder.obj('marginImpact', Impact_1.Impact.schema, Impact_1.Impact.ctor),
    revenueImpact: builder.obj('revenueImpact', Impact_1.Impact.schema, Impact_1.Impact.ctor),
    profitImpact: builder.obj('profitImpact', Impact_1.Impact.schema, Impact_1.Impact.ctor)
  };
}();