import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  left: -10000px;\n\n  & + label {\n    display: inline-block;\n    padding-left: 25px;\n    left: 0;\n    position: static;\n    background-image: url(", ");\n    background-repeat: no-repeat;\n    background-position: center left;\n    margin-right: 10px;\n  }\n\n  &:checked + label {\n    background-image: url(", ");\n  }\n\n  &:disabled + label {\n    opacity: 0.5;\n  }\n\n  &:focus + label {\n    color: ", ";\n    text-shadow: 2px 2px 1px ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Tue Aug 14 2018
 * @author PKl
 */
import { darken } from 'polished';
import styled, { withTheme } from 'styled-components';
import radio0 from '../../../../assets/images/radio0.svg';
import radio1 from '../../../../assets/images/radio1.svg';
/**
 * Create an Radio component
 */

var RadioStyled = styled.input(_templateObject(), radio0, radio1, function (props) {
  return darken(1, props.theme.colors.text);
}, function (props) {
  return props.theme.colors.border;
});
export default withTheme(RadioStyled);