"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var SnapshotType;

(function (SnapshotType) {
  SnapshotType["DailyRegular"] = "DAILY_REGULAR";
  SnapshotType["WorkflowChanged"] = "WORKFLOW_CHANGED"; // This type is used in night recalculation to compare with original data and eventually send window to changed state

  SnapshotType["ChangedStateEvaluation"] = "CHANGED_STATE_EVALUATION";
})(SnapshotType = exports.SnapshotType || (exports.SnapshotType = {}));