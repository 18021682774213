/**
 * @file Created on Tue Sep 25 2018
 * @author PKl
 */
import * as React from 'react';
import BoxTabContainerStyled from './styled/BoxTabContainerStyled';

var BoxTabContainer = function BoxTabContainer(_ref) {
  var children = _ref.children;
  return React.createElement(BoxTabContainerStyled, {
    className: "box-tab-container",
    children: children
  });
};

export default BoxTabContainer;