import generalComparator from "./generalComparator";
/**
 * @file Created on September 2019
 * @author Atreo Digital s.r.o.
 * @description General custom comparator usable when 2 level comparing needed
 */
// Comparing by level 1 values at first, then comparing by level 2 values when both items have the same level 1 values

var generalDoubleLevelComparator = function generalDoubleLevelComparator(level1ValA, level1ValB, level2ValA, level2ValB) {
  var level1Result = generalComparator(level1ValA, level1ValB);
  return level1Result !== 0 ? level1Result : generalComparator(level2ValA, level2ValB);
};

export default generalDoubleLevelComparator;