import {
  AbstractRelease,
  CodebookItem,
  CodebookLookupSequence,
  DateParser,
  PricingType,
  ProductPricesUpload,
  Release,
  ReleaseState,
  ReleaseType,
  ReleaseValidationKey,
  ReleaseWorkflowType,
  SelloutItemFilter,
  SelloutRelease,
  StructPropBuilder,
  UploadValidityEnum,
  UserInfo,
  Utils,
  ValidationResultWrapper,
} from '@logio/common-be-fe';
import {
  getPath,
  LoadingState,
  PageStore,
  ProductCategoryStore,
  ReleaseSelloutStore,
  ReleaseStore,
  rootStore,
  SelloutSettingsLayer,
  StoreName,
  CodeBookItemLayer,
} from '@logio/common-fe';
import {PollingHelper} from '../../components/PollingHelper';
import {List} from 'immutable';
import {History} from 'history';
import {action, observable, runInAction} from 'mobx';
import moment from 'moment';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {SelloutSettingsComponentStore} from '../../../stores/components';
import {TemplateButtonEnum} from '../../../pages/Releases/TemplateButtonEnum';

export enum ReleaseNewModalEnum {
  /** When local pricing type selected. */
  LocalPricing = 'LOCAL_PRICING',
  /** When select from other releases selected. */
  OtherRelease = 'OTHER_RELEASE',
  /** After the user creates the urgent release */
  PriceUploadProgress = 'PRICE_UPLOAD_PROGRESS',
  /** After user clicks on Show sellout description button */
  SelloutDescription = 'SELLOUT_DESCRIPTION',
}

/**
 * This is main release form store. Release consists mainly from release parts nested within
 * the release (these have separate workflow processed, can be approved and submitted to
 * export individually).
 */
export class ReleaseNewPageStore extends PageStore {
  /** Stores */
  releaseStore = rootStore.getStore(StoreName.Release) as ReleaseStore;
  selloutReleaseStore = rootStore.getStore(StoreName.SelloutRelease) as ReleaseSelloutStore;
  productCategoryStore = rootStore.getStore(StoreName.ProductCategory) as ProductCategoryStore;
  selloutSettingsStore = new SelloutSettingsComponentStore(this.messages, true);
  codeBookItemLayer: CodeBookItemLayer = new CodeBookItemLayer();

  /** InitialFormValues */
  public initialValues: any = {
    releaseType: this.releaseTypes[0],
    workflowType: ReleaseWorkflowType.Regular,
    templateButton: TemplateButtonEnum.NationalZone,
    pricingType: PricingType.National,
    cmDeadline: moment(),
    priceValidityStartDate: moment().add(1, 'd'),
    endDate: moment().add(2, 'd'),
    endDateFrom: moment(),
    endDateTo: moment().add(1, 'w'),
  };

  /** Variable used for opening and closing modals */
  @observable
  modalShown: ReleaseNewModalEnum;

  /** Indicates which button was pressed. */
  @observable
  templateButtonSelected: TemplateButtonEnum;

  /** If priceListType is chosen exists in this array, EndDate form field will be hidden */
  priceListTypeIdsWithFixedEndDate: string[];

  priceListTypes: CodebookItem[];

  @observable
  progress: number = 0;

  @observable
  productPricesUpload: ProductPricesUpload;

  /** Derived columns data description builder */
  builder = new StructPropBuilder('ReleaseNewStore');

  @observable
  release: AbstractRelease;

  @observable
  uploadId: string;

  @observable
  submitting: boolean = false;

  /**
   * @param history 
   * @param releaseTypes Restricts release types you can create in the form.
   */
  constructor(public history: History, public releaseTypes: ReleaseType[]) {
    super();
  }

  /** Describes the structure of the form. */
  get descriptions() {
    const descriptions = {
      cmDeadline: this.builder.date('cmDeadline', DateParser.ISO_PATTERN),
      description: Release.schema.description,
      name: Release.schema.name,
      priceListTypeId: this.builder.lookup('priceListTypeId', CodebookLookupSequence.PriceListType),
      priceValidityStartDate: this.builder.date('priceValidityStartDate', DateParser.ISO_PATTERN),
      priceZoneIds: this.builder.listOf(this.builder.lookup('priceZoneIds', CodebookLookupSequence.ReleasePriceZones)),
      pricingType: this.builder.senum<PricingType>(
        'pricingType',
        PricingType,
        CodebookLookupSequence.ReleasePricingType,
      ),
      releaseType: Release.schema.releaseType,
      sourceReleaseId: Release.schema.sourceReleaseId,
      totalPriceChangeLimit: this.builder.num('totalPriceChangeLimit'),
      templateButton: this.builder.str('templateButton'),
      workflowType: this.builder
        .lookup('workflowType', CodebookLookupSequence.ReleaseWorkflowType)
        .withDescription({mandatoryParameter: true}),
      endDate: this.builder.date('endDate', DateParser.ISO_PATTERN),
      endDateFrom: this.builder.date('endDateFrom', DateParser.ISO_PATTERN),
      endDateTo: this.builder.date('endDateTo', DateParser.ISO_PATTERN),
      minPriceChangeInPercent: this.builder.bigNum('minPriceChangeInPercent'),
      maxPriceChangeInPercent: this.builder.opt(this.builder.bigNum('maxPriceChangeInPercent')),
      minMarginChangeInPercent: this.builder.bigNum('minMarginChangeInPercent'),
    };

    return descriptions;
  }

  /** Checks if modal should be hidden. */
  isModalHidden = (name: ReleaseNewModalEnum) => {
    return this.modalShown !== name;
  };

  /** Once the user has confirmed the form. */
  onSubmit = async (values) => {
    if (this.submitting) {
      // prevent to submit form more times
      return;
    }

    this.setSubmitting(true);

    // LOG-3566. Not the best solution.
    this.initialValues = values;

    // NOTE: User info is created on the BE
    const userInfo = new UserInfo(Utils.VOID_ID, null);
    /** Converting string to number */
    const totalPriceChangeLimit = values.totalPriceChangeLimit ? Number(values.totalPriceChangeLimit) : null;

    let createdRelease: Release | SelloutRelease;

    // Converting cmDeadline to end of day LOG-5107
    const cmDeadlineConverted = values.cmDeadline ? values.cmDeadline.endOf('d') : null;
    const startDateConverted = values.priceValidityStartDate ? values.priceValidityStartDate.startOf('d') : null;
    const endDateConverted = values.endDate ? values.endDate.endOf('d') : null;
    const endDateFromConverted = values.endDateFrom ? values.endDateFrom.startOf('d') : null;
    const endDateToConverted = values.endDateTo ? values.endDateTo.endOf('d') : null;

    try {
      if (values.priceListTypeId === this.priceListTypes.find((type) => type.externalId === '153').id) {
        this.uploadRegularSiteRelease(
          values.dataFile,
          values.name,
          !Utils.isValueMissing(values.description) && values.description,
        );
      } else {
        if (values.releaseType === ReleaseType.Sellout) {
          const allSettings = await this.selloutReleaseStore.getPriceListSettings();
          const settingsForPriceListTypeId = allSettings.find(
            (item) => item.priceListTypeId === values.priceListTypeId,
          );

          const release = new SelloutRelease(
            Utils.VOID_ID,
            values.name,
            values.description || null,
            values.releaseType,
            moment(), // created
            userInfo, // createdBy
            moment(), // lastModified
            userInfo, // lastModifiedBy
            values.priceListTypeId || null,
            '', // priceListType
            settingsForPriceListTypeId ? settingsForPriceListTypeId.selloutReleaseType : null,
            List(), // productCategoryIds
            endDateFromConverted && endDateToConverted
              ? SelloutItemFilter.EMPTY.copyMulti({endDateFrom: endDateFromConverted, endDateTo: endDateToConverted})
              : SelloutItemFilter.EMPTY,
            // SelloutItemFilter.EMPTY,
            List(), // releaseParts
            values.workflowType,
            ReleaseState.New,
            endDateConverted,
            null, // calcWorkflowCaseId
            null, // exportWorkflowCaseId
            null, // exportProgressMonitorId
            null,
            startDateConverted,
            totalPriceChangeLimit,
            values.minPriceChangeInPercent || null,
            values.maxPriceChangeInPercent || null,
            values.minMarginChangeInPercent || null,
            cmDeadlineConverted,
            false, // favorite
            ValidationResultWrapper.EMPTY as ValidationResultWrapper<ReleaseValidationKey>,
            false,
            null,
            null,
            null,
            null,
            null,
            List(),
          );
          createdRelease = await this.selloutReleaseStore.create(release);
        } else {
          const release = new Release(
            Utils.VOID_ID,
            values.name,
            values.description || null,
            values.releaseType,
            values.workflowType,
            ReleaseState.New,
            values.pricingType,
            values.sourceReleaseId || null,
            List(values.priceZoneIds),
            values.priceListTypeId || null,
            '', // priceListType
            List(), // productCategoryIds
            List(), // releaseParts
            moment(), // created
            userInfo, // createdBy
            moment(), // lastModified
            userInfo, // lastModifiedBy
            cmDeadlineConverted,
            startDateConverted,
            endDateConverted,
            totalPriceChangeLimit,
            null, // exported
            null, // calcWorkflowCaseId
            null, // exportWorkflowCaseId
            null, // exportProgressMonitorId
            null, // editInfo
            false, // favorite
            ValidationResultWrapper.EMPTY as ValidationResultWrapper<ReleaseValidationKey>,
            null,
            null,
            null,
          );
          createdRelease = await this.releaseStore.create(release);
        }
        /** For urgent releases, prices should be uploaded */
        if (createdRelease.isUrgent()) {
          await this.uploadPrice(values.dataFile, createdRelease);
        } else {
          if (createdRelease.releaseType === ReleaseType.Sellout) {
            this.history.push(getPath(PagePathsEnum.ReleaseSelloutSettings, createdRelease.id));
          } else {
            this.history.push(getPath(PagePathsEnum.ReleaseSettings, createdRelease.id));
          }
        }
      }
    } catch (error) {
      // this.messages.setError(error);
      this.setSubmitting(false);
    }
  };

  /**
   * Opens the release with current settings.
   * @param release Created release
   */
  openRelease = async (release: AbstractRelease) => {
    this.setLoadingState(LoadingState.Pending);
    try {
      switch (release.workflowType) {
        case ReleaseWorkflowType.Sellout:
          await this.selloutReleaseStore.open(release.id);
          this.history.push(getPath(PagePathsEnum.ReleaseSelloutDetail, release.id));
          break;
        case ReleaseWorkflowType.SelloutUrgent:
          await this.selloutReleaseStore.open(release.id);
          this.history.push(getPath(PagePathsEnum.ReleaseSelloutDetailCategory, release.id));
          break;
        case ReleaseWorkflowType.Urgent:
          await this.releaseStore.open(release.id);
          this.history.push(getPath(PagePathsEnum.ReleaseDetail, release.id));
          break;
        default:
          throw new Error('Wrong Release State');
      }
      // this.messages.setSuccess(translate('release-creation-success')); // do not display this msg based on LOG-4026
    } catch (error) {
      this.setLoadingState(LoadingState.Success);
      // this.messages.setError(error);
      this.hideModal();
    }
  };

  /**
   * Calculates current upload progress
   */
  onUploadProgress = (progressEvent: {loaded: number; total: number}) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    runInAction(() => (this.progress = percentCompleted));
  };

  uploadRegularSiteRelease = async (dataFile, name: string, description?: string) => {
    try {
      this.openModal(ReleaseNewModalEnum.PriceUploadProgress);
      const regularSiteUpload = await this.releaseStore.uploadSiteRelease(dataFile, name, description);
      runInAction(() => (this.uploadId = regularSiteUpload.id));
      if (regularSiteUpload && regularSiteUpload.progressId) {
        this.siteReleasePollingHelper.startPolling(regularSiteUpload.progressId, 'price-upload-success');
      }
    } catch (error) {
      this.hideModal();
    }
  };

  async getUloadedRegularSiteRelease(id: string) {
    try {
      const releaseUpload = await this.releaseStore.getSiteReleaseUpload(id);
      if (releaseUpload.validation.isSuccessful() && releaseUpload.releaseId) {
        this.history.push(getPath(PagePathsEnum.ReleaseRegularSiteDetail, releaseUpload.releaseId));
      } else {
        this.messages.setValidationResult(releaseUpload.validation);
      }
      this.hideModal();
    } catch (error) {
      this.hideModal();
    }
  }

  /**
   * Uploads prices.
   * @param dataFile File upload value
   * @param release New urgent release
   */
  uploadPrice = async (dataFile = {data: null}, release: AbstractRelease) => {
    const data = new FormData();
    data.append('productPricesFile', dataFile.data);
    this.setProductPricesUpload(null);
    this.messages.clear();
    const productPricesUpload = await this.releaseStore.priceUpload(release.id, data);
    this.release = release;
    this.uploadId = productPricesUpload.id;
    if (productPricesUpload.progressMonitorId) {
      this.pollingHelper.startPolling(productPricesUpload.progressMonitorId, 'price-upload-success');
    } else {
      this.setProductPricesUpload(productPricesUpload);
      this.setSubmitting(false);
    }
  };

  getUploadedPrice = async (release: AbstractRelease, uploadId: string) => {
    try {
      const uploadedPrice = await this.releaseStore.getUploadedPrice(release.id, uploadId);

      if (uploadedPrice.validity === UploadValidityEnum.VALID) {
        this.setProductPricesUpload(null);
        await this.openRelease(release);
      } else {
        this.setProductPricesUpload(uploadedPrice);
        this.hideModal();
      }
    } catch (error) {
      this.hideModal();
    }
  };

  /**
   * Negate modalHiddenProp
   * @param title - name of the modalHidden prop that should be negated
   */
  @action
  openModal = (modalType: ReleaseNewModalEnum) => {
    this.modalShown = modalType;
  };

  /**
   * HOF for toggle modal
   * used in Tiers page when u get event besides parameter
   */
  getOpenModalEvent = (modalTypes: ReleaseNewModalEnum) => () => {
    this.openModal(modalTypes);
  };

  /**
   * Function used in case if endpoint return error
   */
  @action
  hideModal = () => {
    this.modalShown = undefined;
    this.setSubmitting(false);
  };

  /** Fetches data for SelloutSettings modal */
  load = async (): Promise<void> => {
    try {
      const [priceListTypeWithFixEndDate, priceListTypeIds] = await Promise.all([
        this.selloutReleaseStore.getPLTsWithFixedEndDate(),
        this.codeBookItemLayer.loadLookup(CodebookLookupSequence.PriceListType),
        this.selloutSettingsStore.load(),
        this.releaseStore.getAll({state: [ReleaseState.Released, ReleaseState.Opened]}),
      ]);
      this.priceListTypeIdsWithFixedEndDate = priceListTypeWithFixEndDate;
      this.priceListTypes = priceListTypeIds.codeBooks;
      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      // this.messages.setError(error);
      this.setLoadingState(LoadingState.Error);
    }
  };

  private onPollingStateChanged = (pollingState: LoadingState) => {
    if (pollingState === LoadingState.Pending) {
      this.openModal(ReleaseNewModalEnum.PriceUploadProgress);
    }
    if (pollingState === LoadingState.Success || pollingState === LoadingState.Error) {
      this.getUploadedPrice(this.release, this.uploadId);
    }
  };

  private onSiteReleasePollingStateChanged = (pollingState: LoadingState) => {
    if (pollingState === LoadingState.Pending) {
      this.openModal(ReleaseNewModalEnum.PriceUploadProgress);
    }
    if (pollingState === LoadingState.Success) {
      this.getUloadedRegularSiteRelease(this.uploadId);
    }
    if (pollingState === LoadingState.Error) {
      this.getUloadedRegularSiteRelease(this.uploadId);
      this.hideModal();
    }
  };

  public pollingHelper = new PollingHelper(this.messages, this.onPollingStateChanged, 1000);
  public siteReleasePollingHelper = new PollingHelper(this.messages, this.onSiteReleasePollingStateChanged, 1000, true);

  @action
  setSubmitting(submitting: boolean) {
    this.submitting = submitting;
  }

  @action
  setProductPricesUpload(productPricesUpload: ProductPricesUpload | null) {
    this.productPricesUpload = productPricesUpload;
  }
}
