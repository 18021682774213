import {
  AgGrid,
  Page,
  withPermission,
} from '@logio/common-fe';
import { Observer } from 'mobx-react';
import * as React from 'react';
import { PageNamesEnum } from '../../../shared/localization/PageNamesEnum';
import ReleasePageHeader from './ReleasePageHeader';
import excelStyles from './excelStyles';
import { RegularReleasePageStore } from '../../../stores/pages/Release/Overview/RegularReleasePageStore';
import { PricingPermissions } from '@logio/common-be-fe';
import { PagePathsEnum } from '../../../shared/localization/PagePathsEnum';

/**
 * New page containing all (and only) regular and site releases.
 *
 * @author Jan Prokop
 */
export default function RegularReleasePage() {
  const store = React.useMemo(() => new RegularReleasePageStore(), []);

  React.useEffect(() => {
    store.load();
  }, [store]);

  const createPermissions = [
    PricingPermissions.REGULAR_RELEASE_CREATE_OTHERS,
    PricingPermissions.REGULAR_RELEASE_CREATE_SUPERFAST,
    PricingPermissions.REGULAR_RELEASE_CREATE_URGENT,
    PricingPermissions.SIMULATION_RELEASE_CREATE,
  ];

  return (
    <Observer>
      {() => (
        <Page titleKey={PageNamesEnum.ReleasesRegular} loadingState={store.loadingState}>
          <ReleasePageHeader
            releaseStates={store.currentReleaseStates}
            onReleaseStateFilter={store.onReleaseStateFilter}
            newPath={withPermission(createPermissions, true) ? PagePathsEnum.ReleaseRegularNew : null}
            impactPath={withPermission([PricingPermissions.IMPACT_REPORT_REGULAR]) ? PagePathsEnum.ReleasesRegularImpact : null}
          />
          <AgGrid
            rowData={store.rowData}
            columnDefs={store.columnDefs}
            excelStyles={excelStyles}
            gridId="RegularReleasePage"
            quickView
            onGridReady={store.onGridReady}
          />
        </Page>
      )}
    </Observer>
  );
}
