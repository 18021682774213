/**
 * @file Created on Thu Oct 11 2018
 * @author BPo
 * @author SKu
 */
import {PricingType, ReleaseType, ReleaseWorkflowType, Utils, ValidationError, Release} from '@logio/common-be-fe';
import {
  Button,
  ButtonColor,
  ButtonGroup,
  Field,
  FileUploadAdapter,
  Form,
  FormFieldTypeEnum,
  FormGroup,
  FormGroupInline,
  getPath,
  Icon,
  IconType,
  Label,
  Modal,
  OptionsProvider,
  RadioGroupAdapter,
  RenderPropsProvider,
  SelectAdapter,
  SelectOptions,
  StringMapping,
  T,
  translate,
  CheckboxGroupAdapter,
  BoxDefault,
} from '@logio/common-fe';
import {Col, Row} from 'react-flexbox-grid';
import {FormApi} from 'final-form';
import {action, computed} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import {FieldRenderProps, FormRenderProps, FormSpy, FormSpyRenderProps} from 'react-final-form';
import {PagePathsEnum} from '../../shared/localization/PagePathsEnum';
import {ReleaseNewModalEnum, ReleaseNewPageStore} from '../../stores/pages/Release/ReleaseNewPageStore';
import moment from 'moment';
import {TemplateButtonEnum} from './TemplateButtonEnum';
// @ts-ignore
import SelloutUrgentTemplate from './Templates/Template_urgent_sellout.csv';
// @ts-ignore
import RegularUrgentEDLPTemplate from './Templates/Template_urgent_regular_EDLP.csv';
// @ts-ignore
import RegularUrgentEDLPNationalTemplate from './Templates/Template_urgent_regular_EDLP_national.csv';
// @ts-ignore
import RegularUrgentRegularTemplate from './Templates/Template_urgent_regular.csv';
// @ts-ignore
import RegularUrgentRegularSiteTemplate from './Templates/Template_urgent_regular_site.csv';
// @ts-ignore
import RegularUrgentRegularNationalTemplate from './Templates/Template_urgent_regular_national.csv';

@observer
export class ReleaseCreateForm extends React.Component<{store: ReleaseNewPageStore}> {
  /** Closure to store prev Release Type */
  didReleaseTypeChanged = () => {
    let prevReleaseType: ReleaseType;
    const inner = (releaseType: ReleaseType) => {
      const didChange = releaseType !== prevReleaseType;
      prevReleaseType = releaseType;
      return didChange;
    };
    return inner;
  };

  @computed
  get releaseTypeField(): JSX.Element {
    const didReleaseTypeChanged = this.didReleaseTypeChanged();
    /** Sets default workflow type depending on release type */
    const setInitialValues = (values: StringMapping<any>) => {
      switch (values.releaseType) {
        case ReleaseType.Regular:
          values.workflowType = ReleaseWorkflowType.Regular;
          break;
        case ReleaseType.Simulation:
          values.workflowType = ReleaseWorkflowType.Simulation;
          break;
        case ReleaseType.Sellout:
          values.workflowType = ReleaseWorkflowType.Sellout;
          values.endDate = moment().add(2, 'd');
          values.endDateFrom = moment();
          values.endDateTo = moment().add(1, 'w');
          break;
      }
    };

    return (
      <FormSpy subscription={{values: true}}>
        {({values}) => {
          if (didReleaseTypeChanged(values.releaseType)) {
            setInitialValues(values);
          }
          return (
            <Field
              name="releaseType"
              component={
                RadioGroupAdapter(
                  'releaseType',
                  this.props.store.releaseTypes.map(releaseType => ({
                    label: releaseType,
                    value: releaseType,
                  })),
                  true,
                  true,
                )
              }
            />
          );
        }}
      </FormSpy>
    );
  }

  /** Closure to store prev Price List Type */
  didPriceListTypeChanged = () => {
    let prevPriceListType;
    const inner = (priceListType) => {
      const didChange = priceListType !== prevPriceListType;
      prevPriceListType = priceListType;
      return didChange;
    };
    return inner;
  };

  @computed
  get priceListTypeIdField(): JSX.Element {
    const didPriceListTypeChanged = this.didPriceListTypeChanged();
    const setInitialValuesDependOnPriceListType = (values: StringMapping<any>) => {
      if (
        values.priceListTypeId === this.delistPriceListType.id ||
        values.priceListTypeId === this.inOutDelistPriceListType.id
      ) {
        values.endDateFrom = moment();
        values.endDateTo = moment().add(1, 'w');
      }
    };

    return (
      <FormSpy subscription={{values: true}}>
        {({values}) => {
          if (values.releaseType === ReleaseType.Sellout) {
            this.props.store.selloutSettingsStore.onPriceListTypeChange(values.priceListTypeId);
          }
          if (didPriceListTypeChanged(values.priceListTypeId)) {
            setInitialValuesDependOnPriceListType(values);
          }
          return (
            <OptionsProvider
              name="priceListTypeId"
              dependsOn={{pricingType: 'pricingType', releaseType: 'releaseType', workflowType: 'workflowType'}}
              component={(options: SelectOptions) => (
                <Field name="priceListTypeId" component={(props) => SelectAdapter('priceListTypeId', options)(props)} />
              )}
            />
          );
        }}
      </FormSpy>
    );
  }

  @computed
  get regularSitePriceListType() {
    return this.props.store.priceListTypes.find((o) => o.title === 'Regular - site');
  }
  @computed
  get regularNationalPriceListType() {
    return this.props.store.priceListTypes.find((o) => o.title === 'Regular - national');
  }
  @computed
  get regularPriceListType() {
    return this.props.store.priceListTypes.find((o) => o.title === 'Regular');
  }

  @computed
  get delistPriceListType() {
    return this.props.store.priceListTypes.find((o) => o.title === 'Delist');
  }

  @computed
  get edlpPriceListTypeIds() {
    return this.props.store.priceListTypes.filter((o) => o.title.substr(0, 4) === 'EDLP').map((i) => i.id);
  }

  @computed
  get edlpPriceListTypeId() {
    return this.props.store.priceListTypes.filter((o) => o.title === 'EDLP').map((i) => i.id);
  }

  @computed
  get edlpNationalPriceListTypeId() {
    return this.props.store.priceListTypes.filter((o) => o.title === 'EDLP - national').map((i) => i.id);
  }

  @computed
  get inOutDelistPriceListType() {
    return this.props.store.priceListTypes.find((o) => o.title === 'IN-OUT delist (promo)');
  }

  @computed
  get otherPriceListTypesIds() {
    return this.props.store.priceListTypes.map((o) => {
      if (o.id !== this.delistPriceListType.id && o.id !== this.inOutDelistPriceListType.id) {
        return o.id;
      }
    });
  }

  validateDate(value, values) {
    return (
      (!value && new ValidationError('err-required')) ||
      (values.endDateFrom > values.endDateTo && new ValidationError('err-dateFrom-bigger-than-dateTo'))
    );
  }

  validateMinPriceChange = (value: string, allValues: any) => {
    const val = parseFloat(value);
    if (val < 0) {
      return new ValidationError('err-minPriceChange-negative');
    } else if (allValues.maxPriceChangeInPercent && val > parseFloat(allValues.maxPriceChangeInPercent)) {
      return new ValidationError('ERROR_minPriceChangeMoreThanMaxPriceChange');
    }
  }

  validateMaxPriceChange = (value: string, allValues: any) => {
    const val = parseFloat(value);
    if (val < 0) {
      return new ValidationError('err-maxPriceChange-negative');
    } else if (allValues.minPriceChangeInPercent && val < parseFloat(allValues.minPriceChangeInPercent)) {
      return new ValidationError('ERROR_maxPriceChangeLessThanMinPriceChange');
    }
  }

  @computed
  get endDateFromField(): JSX.Element {
    return (
      <FormSpy subscription={{values: true}}>
        {({values}) => {
          return (
            <Field
              name="endDateFrom"
              fieldShow={{
                ...this.selloutWorkflowType,
                priceListTypeId: [this.delistPriceListType.id, this.inOutDelistPriceListType.id],
              }}
              fieldHide={{...this.notUrgentWorkflowType, priceListTypeId: [...this.otherPriceListTypesIds, undefined]}}
              validate={this.validateDate.bind(values)}
              labelTooltipText="Filter the end date of sellout product when is delisted from the assortment - from end date"
            />
          );
        }}
      </FormSpy>
    );
  }

  @computed
  get endDateToField(): JSX.Element {
    return (
      <FormSpy subscription={{values: true}}>
        {({values}) => {
          return (
            <Field
              name="endDateTo"
              fieldShow={{
                ...this.selloutWorkflowType,
                priceListTypeId: [this.delistPriceListType.id, this.inOutDelistPriceListType.id],
              }}
              fieldHide={{...this.notUrgentWorkflowType, priceListTypeId: [...this.otherPriceListTypesIds, undefined]}}
              validate={this.validateDate.bind(values)}
              labelTooltipText="Filter the end date of sellout product when is delisted from the assortment - to end date"
            />
          );
        }}
      </FormSpy>
    );
  }

  /**
   * Getter for price validity end date field
   */
  @computed
  get endDateField(): JSX.Element {
    return (
      <FormSpy subscription={{values: true}}>
        {({values}) => {
          if (
            values.priceListTypeId &&
            ((values.workflowType === ReleaseWorkflowType.Sellout &&
              !this.props.store.priceListTypeIdsWithFixedEndDate.includes(values.priceListTypeId)) ||
              ((values.workflowType === ReleaseWorkflowType.Regular ||
                values.workflowType === ReleaseWorkflowType.Simulation) &&
                this.edlpPriceListTypeIds.includes(values.priceListTypeId)))
          ) {
            return <Field name="endDate" />;
          } else {
            values.endDate = null;
            return null;
          }
        }}
      </FormSpy>
    );
  }

  /**
   * Common field that could change:
   * priceZoneIds, sourceReleaseId, pricingType
   */
  @computed
  get templateButtonField(): JSX.Element {
    return (
      <RenderPropsProvider
        component={({form}: FormRenderProps) => (
          <Field
            name="templateButton"
            fieldShow={{
              workflowType: [
                ReleaseWorkflowType.Regular,
                ReleaseWorkflowType.Superfast,
                ReleaseWorkflowType.Simulation,
              ],
            }}
            component={({input}: FieldRenderProps) => (
              <FormGroup noLabel={false}>
                <Label htmlFor={'templateButton-label'}>
                  <h3>
                    <T id="new_release-select_template" />
                  </h3>
                </Label>
                <ButtonGroup>
                  <>
                    <Button
                      type="button"
                      buttonColor={ButtonColor.Outline}
                      onClick={this.onNationalPricingClick(form)}
                      isActive={input.value === TemplateButtonEnum.NationalZone}
                      descriptionKey={`new_release-${PricingType.National}-desc`}
                    >
                      <T id={`new_release-${PricingType.National}-btn`} />
                    </Button>
                    <Button
                      type="button"
                      buttonColor={ButtonColor.Outline}
                      onClick={this.onLocalPricingClick(form)}
                      isActive={input.value === TemplateButtonEnum.SelectZones}
                      descriptionKey={`new_release-${PricingType.Local}-desc`}
                    >
                      <T id={`new_release-${PricingType.Local}-btn`} />
                    </Button>
                    <Button
                      type="button"
                      buttonColor={ButtonColor.Outline}
                      onClick={this.onSourceReleaseClick(form)}
                      isActive={input.value === TemplateButtonEnum.BrowseRelease}
                      descriptionKey="new_release-browse-desc"
                    >
                      <T id="new_release-browse-btn" />
                    </Button>
                  </>
                </ButtonGroup>
              </FormGroup>
            )}
          />
        )}
      />
    );
  }
  /**
   * Modal for choosing PriceZoneIds
   * Sets pricingType to Local
   */
  @computed
  get pricingLocalModal(): JSX.Element {
    return (
      <Modal
        title={translate(`new_release-${PricingType.Local}-modal`)}
        hidden={this.props.store.isModalHidden(ReleaseNewModalEnum.LocalPricing)}
        close={this.props.store.hideModal}
      >
        <>
          <OptionsProvider
            name="priceZoneIds"
            dependsOn={{pricingType: 'pricingType'}}
            component={({options}: SelectOptions) => (
              <Field name="priceZoneIds" component={(props) => CheckboxGroupAdapter('priceZoneIds', options)(props)} />
            )}
          />
          <Button buttonColor={ButtonColor.Light} onClick={this.props.store.hideModal}>
            {translate('Select')}
          </Button>
        </>
      </Modal>
    );
  }
  /**
   * Modal for choosing sourceReleaseId
   * Also sets priceZoneIds and pricingType from selected release
   */
  @computed
  get otherReleaseModal(): JSX.Element {
    return (
      <Modal
        title={translate(`new_release-other-modal`)}
        hidden={this.props.store.isModalHidden(ReleaseNewModalEnum.OtherRelease)}
        close={this.props.store.hideModal}
      >
        <FormSpy subscription={{values: true}}>
          {(form) => (
            <>
              <Field name="sourceReleaseId" />
              <Button buttonColor={ButtonColor.Light} onClick={this.onSourceReleaseSelect(form)}>
                {translate('Select')}
              </Button>
            </>
          )}
        </FormSpy>
      </Modal>
    );
  }
  /** File upload available only for urgent releases */
  @computed
  get fileUploadField(): JSX.Element {
    const validate = (value) => !value && new ValidationError('err-required');
    return (
      <Field
        name={'dataFile'}
        fieldShow={{workflowType: [ReleaseWorkflowType.Urgent, ReleaseWorkflowType.SelloutUrgent]}}
        component={(params) => FileUploadAdapter('dataFile')(params)}
        validate={validate}
      />
    );
  }
  /** When national pricing type selected. */
  @action
  onNationalPricingClick = (form: FormApi) => () => {
    /** Set related values manually */
    form.change('pricingType', PricingType.National);
    form.change('sourceReleaseId', undefined);
    form.change('priceZoneIds', undefined);
    form.change('templateButton', TemplateButtonEnum.NationalZone);
  };

  /** When local pricing type selected. Modal with price zones selection will be shown. */
  onLocalPricingClick = (form: FormApi) => () => {
    /** Set related values manually */
    /** PriceZones will be set automatically inside modal */
    form.change('pricingType', PricingType.Local);
    form.change('sourceReleaseId', undefined);
    form.change('priceZoneIds', undefined);
    form.change('templateButton', TemplateButtonEnum.SelectZones);

    this.props.store.openModal(ReleaseNewModalEnum.LocalPricing);
  };

  /** When select from other releases selected. Modal with other releases selection will be shown. */
  onSourceReleaseClick = (form: FormApi) => () => {
    /** Values will be set after release selection */
    form.change('pricingType', undefined);
    form.change('sourceReleaseId', undefined);
    form.change('priceZoneIds', undefined);
    form.change('templateButton', TemplateButtonEnum.BrowseRelease);

    this.props.store.openModal(ReleaseNewModalEnum.OtherRelease);
  };

  /**
   * LOG-3562
   * FIXME: HACK: all Released and Opened releases are "prefetched". It works ok now, but in future -
   * count of Released releases will be n*1000, so page lading will be unnecessarily slow.
   * Could not be done now because of bug in final-form(fixed in final-form@^4.13.0).
   *
   * HOW TO FIX HACK:
   * update final-form & react-final-form
   * delete release.getAll() from store load method, and fetch releases by getOne method inside this function
   */
  /** When select from other releases selected. Modal with other releases selection will be shown. */
  onSourceReleaseSelect = ({form, values}: FormSpyRenderProps) => async () => {
    try {
      if (!Utils.isValueMissing(values.sourceReleaseId)) {
        const release = this.props.store.releaseStore.list.get(values.sourceReleaseId);
        form.change('pricingType', (release as Release).pricingType);
        form.change('priceListTypeId', (release as Release).priceListTypeId);
        form.change('priceZoneIds', (release as Release).priceZoneIds.toArray());
        this.props.store.hideModal();
      }
    } catch (error) {
      this.props.store.messages.setError(error);
    }
  };

  /** Returns not urgent workflow types for dependOn property  */
  get notUrgentWorkflowType() {
    return {workflowType: [ReleaseWorkflowType.Urgent, ReleaseWorkflowType.SelloutUrgent]};
  }
  /** Returns regular sellout for dependOn property */
  get selloutWorkflowType() {
    return {workflowType: [ReleaseWorkflowType.Sellout]};
  }

  get delistPriceListTypes() {
    return {priceListTypeId: [this.delistPriceListType.id, this.inOutDelistPriceListType.id]};
  }

  renderTemplate(values): boolean {
    return (
      (values.releaseType === ReleaseType.Sellout && values.workflowType === ReleaseWorkflowType.SelloutUrgent) ||
      (values.releaseType === ReleaseType.Regular &&
        values.workflowType === ReleaseWorkflowType.Urgent &&
        (this.edlpPriceListTypeId.includes(values.priceListTypeId) ||
          this.edlpNationalPriceListTypeId.includes(values.priceListTypeId) ||
          (this.regularPriceListType && values.priceListTypeId === this.regularPriceListType.id) ||
          (this.regularNationalPriceListType && values.priceListTypeId === this.regularNationalPriceListType.id) ||
          (this.regularSitePriceListType && values.priceListTypeId === this.regularSitePriceListType.id)))
    );
  }

  renderTemplateContent(values): string {
    if (values.releaseType === ReleaseType.Regular) {
      if (this.edlpPriceListTypeId.includes(values.priceListTypeId)) {
        return RegularUrgentEDLPTemplate;
      }
      if (this.edlpNationalPriceListTypeId.includes(values.priceListTypeId)) {
        return RegularUrgentEDLPNationalTemplate;
      }
      if (values.priceListTypeId === this.regularPriceListType.id) {
        return RegularUrgentRegularTemplate;
      }
      if (values.priceListTypeId === this.regularNationalPriceListType.id) {
        return RegularUrgentRegularNationalTemplate;
      }
      if (values.priceListTypeId === this.regularSitePriceListType.id) {
        return RegularUrgentRegularSiteTemplate;
      }
      return '';
    }
    if (values.releaseType === ReleaseType.Sellout) {
      if (values.workflowType === ReleaseWorkflowType.SelloutUrgent) {
        return SelloutUrgentTemplate;
      }
      return '';
    }
    return '';
  }
  render() {
    return (
      <Form
        onSubmit={this.props.store.onSubmit}
        descriptions={this.props.store.descriptions}
        initialValues={this.props.store.initialValues}
      >
        <Row>
          <Col md={9}>
            <h3>
              <T id="new_release-release_header" />
            </h3>
            <Field name="name" />
            {this.releaseTypeField}
            <OptionsProvider
              name="workflowType"
              dependsOn={{releaseType: 'releaseType'}}
              component={(selectOptions: SelectOptions) => (
                <Field
                  name="workflowType"
                  key="workflowType"
                  component={
                    RadioGroupAdapter('workflowType', selectOptions ? selectOptions.options : [], true)
                  }
                />
              )}
            />
            {this.templateButtonField}
            {this.pricingLocalModal}
            {this.otherReleaseModal}
            <Field
              name="sourceReleaseId"
              fieldReadOnly
              fieldShow={{templateButton: TemplateButtonEnum.BrowseRelease}}
            />
            <Field
              name="priceZoneIds"
              fieldReadOnly
              fieldHide={{templateButton: [TemplateButtonEnum.NationalZone, undefined, null]}}
            />
            {this.priceListTypeIdField}
            <Field
              name="totalPriceChangeLimit"
              fieldHide={{...this.notUrgentWorkflowType, ...this.delistPriceListTypes}}
              labelTooltipText="Number of products (unique gold) that will be repriced"
            />
            <Field
              name="minPriceChangeInPercent"
              fieldShow={this.selloutWorkflowType}
              validate={this.validateMinPriceChange}
              labelTooltipText="Minimal x % discount of the actual regular price that can be even higher than the user set. When the second round of repricing the discount is calculated from an actual sellout price."
            />
            <Field
              name="maxPriceChangeInPercent"
              fieldShow={this.selloutWorkflowType}
              validate={this.validateMaxPriceChange}
              labelTooltipText="Maximal x % discount of the actual regular price."
            />
            <Field
              name="minMarginChangeInPercent"
              fieldShow={this.selloutWorkflowType}
              labelTooltipText="Minimal level of margin (BM2 %). New sellout price should not be under this value."
            />
            <Field
              name="cmDeadline"
              fieldHide={{
                workflowType: [
                  ReleaseWorkflowType.Urgent,
                  ReleaseWorkflowType.SelloutUrgent,
                  ReleaseWorkflowType.Simulation,
                  ReleaseWorkflowType.Superfast,
                ],
              }}
              labelTooltipText="The deadline for a category manager to approve the new prices of each category."
            />
            <Field name="priceValidityStartDate" fieldHide={this.notUrgentWorkflowType} />
            {this.endDateField}
            {this.endDateFromField}
            {this.endDateToField}
            <Field name="description" />
            {this.fileUploadField}
            <FormGroup noLabel>
              <FormGroupInline>
                <Button type="submit" disabled={this.props.store.submitting}>
                  {translate('Save')}
                </Button>
                <Button
                  type="button"
                  buttonColor={ButtonColor.Light}
                  onClick={() => this.props.store.history.goBack()}
                >
                  {translate('Cancel')}
                </Button>
                <FormSpy subscription={{values: true}}>
                  {({values}) => {
                    return (
                      values.releaseType === ReleaseType.Sellout && (
                        <Button
                          buttonColor={ButtonColor.Light}
                          iconRight
                          onClick={this.props.store.getOpenModalEvent(ReleaseNewModalEnum.SelloutDescription)}
                          type="button"
                          disabled={!this.props.store.selloutSettingsStore.selloutSettings}
                        >
                          {translate('Settings')}
                          <Icon iconType={IconType.view} />
                        </Button>
                      )
                    );
                  }}
                </FormSpy>
              </FormGroupInline>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormSpy subscription={{values: true}}>
              {({values}) => {
                return (
                  this.renderTemplate(values) && (
                    <BoxDefault>
                      <strong> {translate('Download_New_Release_template_title')}</strong>
                      <a href={this.renderTemplateContent(values)} style={{textDecoration: 'none', display: 'block'}}>
                        <Button buttonColor={ButtonColor.Transparent} iconLeft type="button">
                          <Icon iconType={IconType.document} />
                          {translate('Download_New_Release_template')}
                        </Button>
                      </a>
                    </BoxDefault>
                  )
                );
              }}
            </FormSpy>
          </Col>
        </Row>
      </Form>
    );
  }
}
