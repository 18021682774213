/**
 * @file Created on Sat Oct 06 2018
 * @author SKu
 * @author LZe
 */
import {
  BindDTO,
  Competitor,
  CompetitorProduct,
  CompetitorProductBindDTO,
  CompetitorProductDTO,
  DataPairingProductRowDTO,
  PriceFormulas,
  ProductInfo,
  StructPropBuilder,
  Utils,
  FlagSet,
  ProductFlag,
  NewProduct,
} from '@logio/common-be-fe';
import {
  ActionsGenerator,
  ColumnDefinition,
  ColumnGenerator,
  DataPairingStore,
  listOfStringsColumnDefinition,
  LoadingState,
  PageStore,
  PriceZoneStore,
  ProductCategoryStore,
  productFlagsColumnDefinition,
  ProductStore,
  rootStore,
  StoreName,
  StringMapping,
  SupplierStore,
  translate,
  FormElOption,
  CompetitorStore,
} from '@logio/common-fe';
import {List} from 'immutable';
import {CellClickedEvent, GridApi, GridReadyEvent} from 'ag-grid-community';
import debounce from 'lodash/debounce';
import {action, computed, observable, runInAction} from 'mobx';
import React from 'react';
import {packageSizeHelper} from '../../../../shared/packageSizeHelper';
import {categoryColumnGenerator, eanColumnGenerator} from './columnGenerators';

export interface ProductsParameters {
  categoryId: string;
  fullTextSearch: string;
}

export interface CompetitorProductsParameters {
  fullTextSearch: string;
  ignored: boolean;
  competitorIds: List<string> | null;
}

export class DataPairingUnpairedPageStore extends PageStore {
  /** Get dependant stores */
  productStore = rootStore.getStore(StoreName.Product) as ProductStore;
  dataPairingStore = rootStore.getStore(StoreName.DataPairing) as DataPairingStore;
  supplierStore = rootStore.getStore(StoreName.Supplier) as SupplierStore;
  categoryStore = rootStore.getStore(StoreName.ProductCategory) as ProductCategoryStore;
  priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;
  competitors = rootStore.getStore(StoreName.Competitor) as CompetitorStore;

  /** Data generators */
  actionsGenerator = new ActionsGenerator();
  productColumnGenerator = new ColumnGenerator<ProductInfo>(ProductInfo.schema);
  competitorProductColumnGenerator = new ColumnGenerator<CompetitorProduct>(CompetitorProduct.schema);
  productDTOColumnGenerator = new ColumnGenerator<DataPairingProductRowDTO>(DataPairingProductRowDTO.schema);
  competitorProductDTOColumnGenerator = new ColumnGenerator<CompetitorProductDTO>(CompetitorProductDTO.schema);
  priceZonePricesGenerator: ColumnGenerator<StringMapping<any>>;
  competitorColumnGenerator = new ColumnGenerator<Competitor>(Competitor.schema);

  @observable
  loadingState: LoadingState = LoadingState.Pending;

  @observable
  lockInputsInternal: boolean = false;
  @observable
  lockInputsExternal: boolean = false;

  @observable
  productsParameters: ProductsParameters;
  @observable
  competitorProductsParameters: CompetitorProductsParameters;

  @observable
  gridApiProducts: GridApi;
  @observable
  gridApiCompetitorProducts: GridApi;
  @observable
  gridApiCompetitorProductsInModal: GridApi;

  @observable
  categoryId: string;

  @observable
  selectedProductsRowsCount: number = 0;
  @observable
  selectedCompetitorProductsRowsCount: number = 0;
  @observable
  selectedCompetitorProductsInModalRowsCount: number = 0;

  @observable
  competitorProductsInModalRowData: Array<StringMapping<any>> = [];
  @observable
  productsInModalRowData: Array<StringMapping<any>> = [];

  @observable
  pairingModalHidden: boolean = true;

  constructor(categoryId: string, internalQuery: string | string[]) {
    super();
    this.categoryId = categoryId;
    this.productsParameters = {
      categoryId,
      fullTextSearch: internalQuery ? (typeof internalQuery === 'string' ? internalQuery : internalQuery.join(' ')) : '',
    };
    this.competitorProductsParameters = {
      fullTextSearch: '',
      ignored: false,
      competitorIds: null,
    };
  }

  createPriceZoneGenerator = () => {
    const builder = new StructPropBuilder('PriceZonePricesBuilder');
    const description = {};
    this.priceZoneStore.listNotArchived.forEach(({id, isNational}) => {
        description[`${id}_actualRegularPrice`] = builder.bigNum(`${id}_actualRegularPrice`);
        description[`${id}_actualMarginPrice`] = builder.bigNum(`${id}_actualMarginPrice`);
        description[`${id}_actualMarginPercentage`] = builder.bigNum(`${id}_actualMarginPercentage`);
    });
    this.priceZonePricesGenerator = new ColumnGenerator<StringMapping<any>>(description);
  };

  @computed
  get productPaging() {
    return {
      totalPages: this.dataPairingStore.productsOverview.pagination.getPageCount(),
      activePage: this.dataPairingStore.productsOverview.pagination.getPage(),
      limit: this.dataPairingStore.productsOverview.pagination.limit,
      offset: this.dataPairingStore.productsOverview.pagination.offset,
      total: this.dataPairingStore.productsOverview.pagination.totalCount,
    };
  }

  @computed
  get competitorProductPaging() {
    return {
      totalPages: this.dataPairingStore.competitorProductsOverview.pagination.getPageCount(),
      activePage: this.dataPairingStore.competitorProductsOverview.pagination.getPage(),
      limit: this.dataPairingStore.competitorProductsOverview.pagination.limit,
      offset: this.dataPairingStore.competitorProductsOverview.pagination.offset,
      total: this.dataPairingStore.competitorProductsOverview.pagination.totalCount,
    };
  }

  /** Gets list of competitors */
  @computed
  get competitorOptions(): FormElOption[] {
    return Array.from(this.competitors.list).map(([competitorId, competitor]) => {
      return {label: competitor.name, value: competitorId};
    });
  }

  /**
   * Return generated column definitions for ag-grid
   */
  @computed
  get productsColDefs(): ColumnDefinition[] {
    const priceZonePricesDefinition: ColumnDefinition[] = [];
    this.priceZoneStore.listNotArchived.forEach(({id, name, isNational}) => {
        priceZonePricesDefinition.push(
          {
            field: `${id}_actualRegularPrice`,
            round: true,
            headerName: translate('PriceZonePricesBuilder_actualRegularPrice', name),
          },
          {
            field: `${id}_actualMarginPrice`,
            round: true,
            headerName: translate('PriceZonePricesBuilder_actualMarginPrice', name),
            filterValueGetter: (params) =>
              params.data[params.colDef.colId] ? parseFloat(params.data[params.colDef.colId].toFixed(2)) : null,
          },
          {
            field: `${id}_actualMarginPercentage`,
            headerName: translate('PriceZonePricesBuilder_actualMarginPercentage', name),
            filterValueGetter: (params) =>
              params.data[params.colDef.colId] ? parseFloat(params.data[params.colDef.colId].toFixed(3)) : null,
          },
        );
    });
    /** Each generator return column definition from related data description */
    return [
      ...this.productColumnGenerator.getColumnDefinitions([
        {
          field: 'name',
          pinned: 'left',
          width: 250,
          onCellClicked: (params: CellClickedEvent) => this.searchProductsAndCompetitorProducts(params.value),
        },
      ]),
      ...this.productDTOColumnGenerator.getColumnDefinitions([
        {
          field: 'purchasePrice',
          round: true,
          filterValueGetter: (params) =>
            params.data[params.colDef.colId] ? parseFloat(params.data[params.colDef.colId].toFixed(2)) : null,
        },
        {
          field: 'openPurchasePrice',
          round: true,
          filterValueGetter: (params) =>
              params.data[params.colDef.colId] ? parseFloat(params.data[params.colDef.colId].toFixed(2)) : null,
        },
        {
          field: 'family',
          filter: 'agSetColumnFilter',
        },
      ]),
      ...this.priceZonePricesGenerator.getColumnDefinitions(priceZonePricesDefinition),
      ...categoryColumnGenerator.getColumnDefinitions([
        {
          field: 'box',
          filter: 'agSetColumnFilter',
        },
      ]),
      ...this.productColumnGenerator.getColumnDefinitions([
        {
          field: 'supplierId',
          filter: 'agSetColumnFilter',
          valueFormatter: ({value: supplierId}) => (supplierId ? this.supplierStore.list.get(supplierId).name : null),
        },
        {
          field: 'flags',
          ...productFlagsColumnDefinition,
        },
      ]),
      ...packageSizeHelper.colDefs(this.productStore),
    ];
  }

  /**
   * Return generated column definitions for ag-grid
   */
  @computed
  get productsColDefsWithCheckBox(): ColumnDefinition[] {
    /** Each generator return column definition from related data description */
    return [
      ...this.productsColDefs,
      this.actionsGenerator.getColumnDefinition({
        width: 40,
        headerName: '',
        pinned: 'right',
        headerCheckboxSelection: false,
      }),
    ];
  }

  /**
   * LOG-4135
   *   Remove NewProduct flag from product if newProduct=false to prevent appending it to filter
   */
  getProductFlags = (product: DataPairingProductRowDTO): FlagSet<ProductFlag> => {
    const {flags} = product.productInfo;
    if (Utils.isValueMissing(product.productInfo.flags)) {
      return null;
    }
    if (product.productInfo.flags.has(NewProduct) && !product.productInfo.flags.get(NewProduct).newProduct) {
      flags.remove(NewProduct);
    }
    return flags;
  };

  /**
   * Return generated data for ag-grid
   */
  @computed
  get productsRowData(): Array<StringMapping<any>> {
    const rowData = [];
    this.dataPairingStore.products.forEach((product: DataPairingProductRowDTO) => {
      const priceZonePricesData = {};
      product.prices.toArray().map(({actualRegularPrice, actualMarginPercentage, actualMarginPrice, priceZoneId}) => {
        priceZonePricesData[`${priceZoneId}_actualRegularPrice`] = actualRegularPrice;
        priceZonePricesData[`${priceZoneId}_actualMarginPercentage`] = actualMarginPercentage;
        priceZonePricesData[`${priceZoneId}_actualMarginPrice`] = actualMarginPrice;
      });
      const productInfoFixedFlags = {...product.productInfo, flags: this.getProductFlags(product)} as ProductInfo;
      /** Each generator return data definition from related data description */
      rowData.push({
        ...categoryColumnGenerator.getColumnData({
          box: this.categoryStore.list.get(Utils.getCategoryIdWithLevel(product.productInfo.categoryIds, 6)).name,
        }),
        ...this.productColumnGenerator.getColumnData(productInfoFixedFlags),
        ...this.priceZonePricesGenerator.getColumnData(priceZonePricesData),
        ...this.productDTOColumnGenerator.getColumnData(product),
        ...packageSizeHelper.getColumnData(product.productInfo.packageSize),
      });
    });
    return rowData;
  }

  /**
   * Return generated column definitions for ag-grid
   */
  @computed
  get competitorProductsColDefs(): ColumnDefinition[] {
    /** Each generator return column definition from related data description */

    const competitorPriceColId = this.competitorProductDTOColumnGenerator
      .getColumnDefinitions([{field: 'competitorPrice'}])[0].colId;

    return [
      this.actionsGenerator.getColumnDefinition({width: 40, headerName: ''}),
      ...this.competitorProductColumnGenerator.getColumnDefinitions([
        {
          field: 'name',
          pinned: 'left',
          width: 250,
          onCellClicked: (params: CellClickedEvent) => this.searchProductsAndCompetitorProducts(params.value),
        },
        {
          field: 'productSize',
        },
        {
          field: 'productSizeUnit',
          filter: 'agSetColumnFilter',
          width: 90,
        },
      ]),
      ...this.competitorProductDTOColumnGenerator.getColumnDefinitions([
        {
          field: 'competitorPrice',
          round: true,
          filter: 'agNumberColumnFilter',
          valueGetter: p => (p.data && p.data[competitorPriceColId]) ? p.data[competitorPriceColId].value : null,
          filterValueGetter: (params) =>
            params.data[params.colDef.colId] ? parseFloat(params.data[params.colDef.colId].value.toFixed(2)) : null,
        },
      ]),
      ...eanColumnGenerator.getColumnDefinitions([
        {
          field: 'value',
        },
        {
          field: 'eanDescription',
          hide: true,
        },
      ]),
      ...this.competitorProductColumnGenerator.getColumnDefinitions([
        {
          field: 'category',
          filter: 'agSetColumnFilter',
        },
        {
          field: 'suppliers',
          ...listOfStringsColumnDefinition,
        },
        {
          field: 'productContent',
          hide: true,
        },
        {
          field: 'productSpecification',
          hide: true,
        },
        {
          field: 'ignored',
          action: async (rowId, colId, value) => {
            const data = this.gridApiCompetitorProducts.getRowNode(rowId).data;
            try {
              await this.ignoreUnignore([data.CompetitorProduct_id]);
              return Promise.resolve(value);
            } catch (error) {
              // this.messages.setError(error);
            }
          },
        },
      ]),
      ...this.competitorColumnGenerator.getColumnDefinitions([
        {
          field: 'name',
          filter: 'agSetColumnFilter',
        },
      ]),
    ];
  }

  @computed
  get competitorProductsColDefsInModal(): ColumnDefinition[] {
    /** Each generator return column definition from related data description */
    return [
      this.actionsGenerator.getColumnDefinition({width: 40, headerName: ''}),
      ...this.competitorProductColumnGenerator.getColumnDefinitions([
        {
          field: 'name',
          pinned: 'left',
          width: 250,
        },
      ]),
      ...this.competitorProductDTOColumnGenerator.getColumnDefinitions([
        {
          field: 'ratio',
          editable: true,
          lockVisible: true,
        },
        {
          field: 'charger',
          editable: true,
          lockVisible: true,
        },
        {
          field: 'competitorPrice',
          round: true,
          filter: 'agNumberColumnFilter',
          filterValueGetter: (params) =>
            params.data[params.colDef.colId] && params.data[params.colDef.colId].value
              ? parseFloat(params.data[params.colDef.colId].value.toFixed(2)) : null,
          // filterValueGetter: (params) => {console.log("params: ", params);return null;}
        },
      ]),
      ...this.competitorProductColumnGenerator.getColumnDefinitions([
        {
          field: 'productSize',
        },
        {
          field: 'productSizeUnit',
          filter: 'agSetColumnFilter',
          width: 90,
        },
      ]),
      ...eanColumnGenerator.getColumnDefinitions([
        {
          field: 'value',
        },
        {
          field: 'eanDescription',
          hide: true,
        },
      ]),
      ...this.competitorProductColumnGenerator.getColumnDefinitions([
        {
          field: 'category',
          filter: 'agSetColumnFilter',
        },
        {
          field: 'suppliers',
          ...listOfStringsColumnDefinition,
        },
        {
          field: 'productContent',
          hide: true,
        },
        {
          field: 'productSpecification',
          hide: true,
        },
      ]),
      ...this.competitorColumnGenerator.getColumnDefinitions([
        {
          field: 'name',
        },
      ]),
    ];
  }

  /**
   * Return generated data for ag-grid
   */
  @computed
  get competitorProductsRowData(): Array<StringMapping<any>> {
    const rowData = [];
    this.dataPairingStore.competitorProducts.forEach((competitorProduct: CompetitorProductDTO) => {
      /** Each generator return data definition from related data description */
      rowData.push({
        ...this.competitorProductColumnGenerator.getColumnData(competitorProduct.competitorProduct),
        ...this.competitorProductDTOColumnGenerator.getColumnData(competitorProduct),
        ...this.competitorColumnGenerator.getColumnData(competitorProduct.competitor),
        ...eanColumnGenerator.getColumnData(competitorProduct.competitorProduct.eans.last() || null),
      });
    });
    return rowData;
  }

  updateAgSetColumnFilters(): void { // LOG-5469
    this.competitorProductsColDefs.forEach(colDef => {
      if (colDef.filter === 'agSetColumnFilter') {
        const filter = this.gridApiCompetitorProducts.getFilterInstance(colDef.field) as any; // Bad AgGrid types, so as any
        if (filter) {
          filter.resetFilterValues();
        }
      }
    });
  }

  @action.bound
  onProductsGridReady(params: GridReadyEvent) {
    this.gridApiProducts = params.api;
  }

  @action.bound
  onCompetitorProductsGridReady(params: GridReadyEvent) {
    this.gridApiCompetitorProducts = params.api;
  }

  @action.bound
  onCompetitorProductsInModalGridReady(params: GridReadyEvent) {
    this.gridApiCompetitorProductsInModal = params.api;
    this.gridApiCompetitorProductsInModal.selectAllFiltered();
  }

  @action
  onProductsFilter = (event: React.FormEvent<HTMLInputElement>): void => {
    this.productsParameters.fullTextSearch = event.currentTarget.value;
  };

  @action
  onChangeFullTextSearch = (event: React.FormEvent<HTMLInputElement>, ignoredMode?: boolean): void => {
    this.competitorProductsParameters.fullTextSearch = event.currentTarget.value;
  }

  @action
  onChangeHide = (event: React.FormEvent<HTMLInputElement>): void => {
      this.competitorProductsParameters.ignored = !event.currentTarget.checked;
      this.searchCompetitorProducts();
  };

  @action
  onCompetitorsChange = (competitorIds: string[]): void => {
    this.competitorProductsParameters.competitorIds = competitorIds && competitorIds.length ? List(competitorIds) : null;
    this.searchCompetitorProductsDebounce();
  };

  @action
  resetProductsFilter = (): void => {
    this.productsParameters.fullTextSearch = '';
    this.searchProducts(1);
  };

  @action
  resetCompetitorProductsFilter = (): void => {
    this.competitorProductsParameters.fullTextSearch = '';
    this.searchCompetitorProducts();
  };

  public searchProducts = (page: number = 1) => {
    runInAction(() => {
      this.lockInputsInternal = true;
    });
    this.gridApiProducts.showLoadingOverlay();
    this.dataPairingStore
      .getProducts(this.productsParameters.categoryId, this.productsParameters.fullTextSearch, page)
      .then(() => {
        this.gridApiProducts.hideOverlay();
      })
      .catch((error) => {
        this.gridApiProducts.hideOverlay();
        // this.messages.setError(error);
      })
      .finally(() => {
        runInAction(() => {
          this.lockInputsInternal = false;
        });
      });
  };

  searchCompetitorProducts = (page: number = 1) => {
    runInAction(() => {
      this.lockInputsExternal = true;
    });
    this.gridApiCompetitorProducts.showLoadingOverlay();
    this.dataPairingStore
      .getCompetitorProducts(
        this.competitorProductsParameters.fullTextSearch,
        this.competitorProductsParameters.ignored,
        this.competitorProductsParameters.competitorIds,
        page,
      )
      .then(() => {
        this.updateAgSetColumnFilters();
        this.gridApiCompetitorProducts.hideOverlay();
      })
      .catch((error) => {
        this.gridApiCompetitorProducts.hideOverlay();
        // this.messages.setError(error);
      })
      .finally(() => {
        runInAction(() => {
          this.lockInputsExternal = false;
        });
      });
  };

  searchCompetitorProductsDebounce = debounce(this.searchCompetitorProducts, 500);

  @action
  searchProductsAndCompetitorProducts = (value: string) => {
    this.productsParameters.fullTextSearch = value;
    this.competitorProductsParameters.fullTextSearch = value;
    this.searchProducts(1);
    this.searchCompetitorProducts();
  };

  @action
  onProductsSelectionChanged = (): void => {
    if (this.gridApiProducts) {
      this.selectedProductsRowsCount = this.gridApiProducts.getSelectedRows().length;
    }
  };

  @action
  onCompetitorProductsSelectionChanged = (): void => {
    if (this.gridApiCompetitorProducts) {
      this.selectedCompetitorProductsRowsCount = this.gridApiCompetitorProducts.getSelectedRows().length;
    }
  };

  @action
  onCompetitorProductsInModalSelectionChanged = (): void => {
    if (this.gridApiCompetitorProductsInModal) {
      this.selectedCompetitorProductsInModalRowsCount = this.gridApiCompetitorProductsInModal.getSelectedRows().length;
    }
  };

  /**
   * Sets the ignore flag of the competitor product
   */
  ignoreUnignore = (competitorProductsIds: string[]) => {
    this.gridApiCompetitorProducts.showLoadingOverlay();
    this.dataPairingStore.competitorProductLayer
      .ignoreUnignore(competitorProductsIds)
      .then(() => {
        this.messages.setSuccess(translate('DataPairing_productIgnored', competitorProductsIds.length.toString()));
        this.searchCompetitorProducts();
      })
      .catch((error) => {
        this.gridApiCompetitorProducts.hideOverlay();
        // this.messages.setError(error);
      });
  };

  ignoreUnignoreSelected = (): void => {
    if (this.gridApiCompetitorProducts) {
      const selectedRows = this.gridApiCompetitorProducts.getSelectedRows();
      const competitorProductsIds = selectedRows.map((row) => row.CompetitorProduct_id);
      this.ignoreUnignore(competitorProductsIds);
    }
  };

  /**
   * Pairs unpaired competitor products to the internal one
   * @param bindDTOs
   */
  pair = (bindDTOs: BindDTO[]): void => {
    this.gridApiProducts.showLoadingOverlay();
    this.gridApiCompetitorProducts.showLoadingOverlay();
    this.dataPairingStore.dataPairingLayer
      .pair(bindDTOs)
      .then(() => {
        this.messages.setSuccess(translate('DataPairing_productPaired', bindDTOs.length.toString()));
        this.togglePairingModal();
        this.searchProducts(1);
        this.searchCompetitorProducts();
      })
      .catch((error) => {
        this.gridApiProducts.hideOverlay();
        this.gridApiCompetitorProducts.hideOverlay();
        // this.messages.setError(error);
      });
  };

  /**
   * Clone AgGrid and shows pairing modal window
   */
  @action
  pairSelectedInModal = (): void => {
    this.productsInModalRowData = this.gridApiProducts.getSelectedRows();
    this.competitorProductsInModalRowData = this.gridApiCompetitorProducts.getSelectedRows();
    this.togglePairingModal();
  };

  /**
   * Pairs selected internal products to the competitor ones
   */
  pairSelected = (): void => {
    const selectedProducts = this.gridApiProducts.getSelectedRows();
    const selectedCompetitorProducts = this.gridApiCompetitorProductsInModal.getSelectedRows();
    if (selectedProducts.length > 0 && selectedCompetitorProducts.length > 0) {
      const bindDTOs = selectedCompetitorProducts.map((row) => {
        return new BindDTO(
          selectedProducts[0].ProductInfo_id,
          List([
            new CompetitorProductBindDTO(
              row.CompetitorProduct_id,
              row.CompetitorProductDTO_ratio,
              row.CompetitorProductDTO_charger,
            ),
          ]),
        );
      });
      this.pair(bindDTOs);
    }
  };

  @action
  togglePairingModal = (): void => {
    this.pairingModalHidden = !this.pairingModalHidden;
  };

  /** Fetches all data for this page */
  public load = async () => {
    this.dataPairingStore.products.clear();
    this.dataPairingStore.competitorProducts.clear();
    try {
      await Promise.all([
        this.categoryStore.getAll(),
        this.productStore.getUnits(),
        this.supplierStore.getAll(),
        this.priceZoneStore.getAll(),
        this.competitors.getAll(),
        this.dataPairingStore.getProducts(this.productsParameters.categoryId, this.productsParameters.fullTextSearch, 1),
        this.dataPairingStore.getCompetitorProducts(
          this.competitorProductsParameters.fullTextSearch,
          this.competitorProductsParameters.ignored,
          this.competitorProductsParameters.competitorIds,
          1,
        ),
      ]);
      this.createPriceZoneGenerator();
      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      this.setLoadingState(LoadingState.Error);
      // this.messages.setError(error);
    }
  };
}
