/**
 * Tooltip can be rendered in these types
 */
export var TooltipType;

(function (TooltipType) {
  TooltipType["Error"] = "ERROR";
  TooltipType["Warning"] = "WARNING";
  TooltipType["Info"] = "INFO";
  TooltipType["Success"] = "SUCCESS";
  TooltipType["Unstyled"] = "UNSTYLED";
})(TooltipType || (TooltipType = {}));