"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Determines axis for segmentation model - profit lift ().
 *
 * @author ZSr
 */

var ProfitLiftSegmentationEnum;

(function (ProfitLiftSegmentationEnum) {
  ProfitLiftSegmentationEnum["HIGH"] = "HIGH";
  ProfitLiftSegmentationEnum["LOW"] = "LOW";
  ProfitLiftSegmentationEnum["NEGATIVE"] = "NEGATIVE";
})(ProfitLiftSegmentationEnum = exports.ProfitLiftSegmentationEnum || (exports.ProfitLiftSegmentationEnum = {}));