/**
 * @file Created on Tue Feb 12 2019
 * @author SKu
 */

import { CodebookLookupSequence, PricingPermissions, SelloutType, StructPropBuilder } from '@logio/common-be-fe';
import {
  BoxDefault,
  Button,
  ButtonLoading,
  Field,
  Form,
  FormGroupInline,
  getPath,
  Icon,
  IconType,
  LoadingState,
  MultiInput,
  OptionsProvider,
  ProgressBar,
  SelectAdapter,
  SelectOptions,
  Tooltip,
  translate,
  WithPermission,
} from '@logio/common-fe';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { PagePathsEnum } from '../../../shared/localization/PagePathsEnum';
import { SelloutIdentificationReportDetailPageStore, SelloutIdentificationReportFilterValues } from '../../../stores/pages/Reports/SelloutIdentificationReport/SelloutIdentificationReportDetailPageStore';
import { PriceZoneField } from '../../../components/PriceZoneField/PriceZoneField';

export interface FilterProps {
  store: SelloutIdentificationReportDetailPageStore;
}

const SelloutIdentificationReportDetailFilter: React.SFC<FilterProps> = observer(
  ({ store }: FilterProps) => {
    const builder = new StructPropBuilder('SelloutIdentificationReportDetailFilter');
    const filterDescription = {
      // LOG-4977:
      // if there are only "level-5" categories, it's possible that we can click on some another category
      // in dashboard and then if here is not the category it can't be selected after redirecting
      categoryIds: builder.opt(builder.listOf(builder.lookup('categoryIds', `${CodebookLookupSequence.Category}`))),
      selloutTypes: builder.opt(
        builder.listOf(builder.senum<SelloutType>('selloutTypes', SelloutType, CodebookLookupSequence.SelloutType)),
      ),
      priceZoneId: builder.lookup('priceZoneId', `${CodebookLookupSequence.PriceZone}`),
      siteIds: builder.opt(builder.listOf(builder.lookup('siteIds', `${CodebookLookupSequence.InternalSites}`))),
      productExternalIds: builder.opt(builder.listOf(builder.str('productExternalIds'))),
    };

    return (
      <>
        {/* This will render downloading modal, when export document is ready, initialized by `store.export()` */}
        {store.downloadStore.renderModal()}
        {store.exportPollingHelper && (store.exportPollingHelper.pollingState === LoadingState.Pending) && (
          <div className="mb2">
            <ProgressBar
              percentage={store.exportPollingHelper.progress || 0}
              label={'export-loading-progress'}
            />
          </div>
        )}
        <BoxDefault className={`mb2${!store.isFilterVisible ? ' dn' : ''}`}>
          <Form descriptions={filterDescription} onSubmit={store.onFind} formLayoutVertical initialValues={store.filters}>
            <FormGroupInline>
              <div className="pb0">
                <Field name="categoryIds" className="err-form-vertical" />
              </div>
              <div className="pb0">
                <Field name="selloutTypes" />
              </div>
              <div className="pb0 mt-auto">
                <PriceZoneField name="priceZoneId" label="SelloutIdentificationReportDetailFilter_priceZoneId" />
              </div>
              <div className="pb0 mt-auto">
                <OptionsProvider
                  name="siteIds"
                  dependsOn={{ priceZoneId: 'priceZoneId' }}
                  component={(options: SelectOptions) => (
                    <Field
                      name="siteIds"
                      component={
                        SelectAdapter(
                          'itemSiteIds',
                          options,
                          true,
                          false,
                          false,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          true,
                        )
                      }
                    />
                  )}
                />
              </div>
            </FormGroupInline>
            <FormGroupInline>
              <div className="pb0 mt-auto">
                <Field
                  name="productExternalIds"
                  component={MultiInput}
                  validate={store.productStore.validate}
                />
              </div>
              <div className="flex-grow-1">&nbsp;</div>
              <WithPermission
                some
                permissionTypes={[
                  PricingPermissions.SELLOUT_RELEASE_CREATE_OTHERS,
                  PricingPermissions.SELLOUT_RELEASE_CREATE_URGENT,
                ]}
              >
                <Link to={getPath(PagePathsEnum.ReleaseSelloutNew)} className="mt-auto">
                  <Button type="button">{translate('create-new-release')}</Button>
                </Link>
              </WithPermission>
              <div className="mt-auto">
                <ButtonLoading iconRight isLoading={store.isFilterLoading}>
                  {translate('Find')} <Icon iconType={IconType.search} />
                </ButtonLoading>
              </div>
              <div className="mt-auto">
                <Tooltip
                  overlay={translate('SelloutIdentificationReport_ExportTooltip')}
                  placement="bottom"
                  noHeight
                >
                  <div>
                    <ButtonLoading
                      isLoading={store.exportIsLoading}
                      onClick={() => store.export()}
                    >
                      <Icon iconType={IconType.exportToExcel} />
                    </ButtonLoading>
                  </div>
                </Tooltip>
              </div>
            </FormGroupInline>
          </Form>
        </BoxDefault>
      </>
    );
  },
);

export default SelloutIdentificationReportDetailFilter;
