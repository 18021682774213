import * as React from 'react';
import { Legend } from 'react-jsx-highcharts';
import StyledLegend from './styled/StyledLegend';
var defaultProps = {
  layout: 'vertical',
  align: 'right',
  verticalAlign: 'middle'
};
export var ChartLegend = function ChartLegend() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultProps;
  return React.createElement(StyledLegend, null, React.createElement(Legend, {
    layout: props.layout,
    align: props.align,
    verticalAlign: props.verticalAlign
  }));
};