"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
}); // TODO please add at least a brief description of each enumerated item.

var WindowChangeType;

(function (WindowChangeType) {
  WindowChangeType["Deleted"] = "DELETED";
  WindowChangeType["Rejected"] = "REJECTED";
  WindowChangeType["Changed"] = "CHANGED";
  WindowChangeType["Inserted"] = "INSERTED";
  WindowChangeType["Updated"] = "UPDATED";
  WindowChangeType["NotChanged"] = "NOT_CHANGED";
})(WindowChangeType = exports.WindowChangeType || (exports.WindowChangeType = {}));