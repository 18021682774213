/**
 * @file Created on Thu Jun 27 2019
 * @author SKu
 */

import { DashboardTask } from '@logio/common-be-fe';
import { MessageStore, TaskLayer } from '@logio/common-fe';
import { History } from 'history';

export class TaskWidgetStore {
  constructor(public messages: MessageStore, public history: History) {}
  public taskLayer = new TaskLayer();

  tasks: DashboardTask[] = [];

  /** Fetches data for the page */
  public load = async () => {
    try {
      this.tasks = await this.taskLayer.getAll();
    } catch (error) {
      // this.messages.setError(error);
    }
  };
}
