/**
 * @file Created on Thu Aug 09 2018
 * @author BPo
 * @author VBr
 * @author LZe
 */

import '@babel/polyfill';

/* NOT TODO: DO NOT REORGANIZE IMPORTS!! 
BEWARE THAT THE FIRST THREE IMPORTS MUST COME FIRST IN ORDER FOR DEPENDENCY INJECTION AND CONFIG TO WORK */
import {configuration, ProfileStore, StoreName} from '@logio/common-fe';

// Common App configuration. You can override default constants here.
if (window._CONFIG === undefined) {
  throw new Error('You have to fill in the /public/config.js file!');
} else {
  configuration.init(window._CONFIG);
}

import {
  rootStore,
  style,
  theme,
  CONSTANTS,
  LoginControl,
  GeneralConfigurations,
  DictionaryProvider,
} from '@logio/common-fe';
import {configure} from 'mobx';
import {Provider} from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import {BrowserRouter, Switch} from 'react-router-dom';
import {injectGlobal, ThemeProvider} from 'styled-components';
import {appRoutes} from './routes-app';
import main from './styles/main';
import {Routes} from './route-generator';

// MobX: Doesn't allow state modifications outside of actions.
configure({enforceActions: 'observed'});

// Initialize connection to sentry
try {
  Sentry.init({
    enabled: !!CONSTANTS.SENTRY.dsn,
    dsn: CONSTANTS.SENTRY.dsn,
    environment: CONSTANTS.SENTRY.environment,
    integrations: [
      new Integrations.CaptureConsole({
        levels: ['warn', 'error'],
      }),
    ],
  });
} catch (error) {
  console.error('Loading sentry failed.', error);
}

// Register Pricing specific styles
// tslint:disable-next-line:no-unused-expression
injectGlobal`${style}${main}`;

const HTML_TARGET = document.getElementById('root') as HTMLElement;

// Has to be in a separate component otherwise the Routes() function is executed before the dictionary has been loaded!
const AppRouter = () => {
  const profileStore = rootStore.getStore(StoreName.Profile) as ProfileStore;

  return (
    <BrowserRouter>
      <Switch>{Routes(appRoutes)}</Switch>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider {...rootStore.getAll()}>
      <DictionaryProvider>
        <LoginControl>
          <GeneralConfigurations>
            <AppRouter />
          </GeneralConfigurations>
        </LoginControl>
      </DictionaryProvider>
    </Provider>
  </ThemeProvider>,
  HTML_TARGET,
);
