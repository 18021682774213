import { Utils } from "@logio/common-be-fe";
/**
 * @file Created on Mon Feb 10 2020
 * @author TSv
 * @description compares family object by name
 */

var familyNameComparator = function familyNameComparator(valA, valB) {
  // both items are null -> both are same
  if (Utils.isValueMissing(valA) && Utils.isValueMissing(valB)) {
    return 0;
  } // first item is null -> second item is greater


  if (Utils.isValueMissing(valA)) {
    return -1;
  } // second item is null -> first item is greater


  if (Utils.isValueMissing(valB)) {
    return 1;
  }

  return valA.name > valB.name ? 1 : valB.name > valA.name ? -1 : 0;
};

export default familyNameComparator;