/**
 * @file Created on Wed Apr 10 2019
 * @author SKu
 */

import {IconType, LoadingState, logger, PageStore, withPermission} from '@logio/common-fe';
import {History} from 'history';
import moment from 'moment';
import {ReleaseWidgetStore, SelloutIdentificationStore, TaskWidgetStore} from '../../components';
import {PricingPermissions} from '@logio/common-be-fe';

export enum DashboardWidgetsEnum {
  Release = 'RELEASES',
  Task = 'TASKS',
  SelloutIdentification = 'SELLOUT_IDENTIFICATION',
  PriceStickers = 'PRICE_STICKERS',
}

export interface TaskFake {
  id: string;
  name: string;
  done: boolean;
  deadline: moment.Moment;
  iconType: IconType;
}
/** TODO: comments */
export class DashboardPageStore extends PageStore {
  constructor(public allowedWidgets: DashboardWidgetsEnum[], public history: History) {
    super();
  }

  public selloutIdentificationStore = new SelloutIdentificationStore(this.messages, this.history);
  public releaseWidgetStore = new ReleaseWidgetStore(this.messages, this.history);
  public taskWidgetStore = new TaskWidgetStore(this.messages, this.history);

  private get promises(): Array<Promise<any>> {
    return this.allowedWidgets.map((widget) => {
      switch (widget) {
        case DashboardWidgetsEnum.Task:
          return this.taskWidgetStore.load();
        case DashboardWidgetsEnum.Release:
          return this.releaseWidgetStore.load();
        case DashboardWidgetsEnum.SelloutIdentification:
          // FIXME: hack
          break;
        // return this.selloutIdentificationStore.load();
        case DashboardWidgetsEnum.PriceStickers:
          logger.error('NOT IMPLEMENTED');
          break;
      }
    });
  }

  public load = async () => {
    try {
      await Promise.all(this.promises);
      this.setLoadingState(LoadingState.Success);
      // FIXME: hack
      if (withPermission([PricingPermissions.SELLOUT_IDENTIFICATION_REPORT])) {
        await this.selloutIdentificationStore.load();
      }
    } catch (err) {
      // // this.messages.setError(error);
    }
  };
}
