import _slicedToArray from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { default as React, useEffect, useState } from 'react';
import { default as Button, ButtonColor } from '../../components/controls/Button';
import { default as Icon, IconType, IconColor } from '../../components/media/Icon';
import { default as Modal } from '../../components/info/Modal/Modal';
import { default as translate } from '../../components/i18n/translate';
import { default as rootStore } from '../../shared/stores/RootStore';
import { StoreName } from '../../shared/stores/StoreName';
import ReactRouterPause from '@allpro/react-router-pause';
import { Observer } from 'mobx-react';
import { debounce } from 'lodash';
/** @atuhor VSm - 20.2.2020 **/
// Possible future improvement in customization of this component could be done through configuration which would be set in startBlockingRouteNavigation function
// config {
//   modal: {
//     title: translate('Modal title'),
//     cancelBtn: {
//        innerText: translate('buttonsInnerText'),
//        handler: blockerStore.cancelHandler
//     }
//     confirmBtn: {
//        innerText: translate('buttonsInnerText'),
//        handler: blockerStore.confirmHandler
//     }
//   },
// }
// if modal = display modal, if not just block route without any other actions ...
// so developer can unblockNavigation on his own will without any user confirmation needed
// if title -> show title in modal, if cancelBtn show cancelBtn ... etc.

var blockerStore = rootStore.getStore(StoreName.RouteBlocker); // ===== IMPORTANT NOTE ===
// THIS COMPONENT SHOULD BE MOUNTED ONLY ONCE IN WHOLE APP - IT'S GLOBAL AND IS MOUNTED IN AppContainer
// TO CONTROL THIS COMPONENT THERE ARE 2 MAIN FUNCTIONS = startBlockingRouteNavigation(), stopBlockingRouteNavigation()
// YOU CAN FIND THESE AT THE BOTTOM OF THE FILE
// This component is also meant as more globalized version of alreadyExisting RouterBlocker component and should replace it.
// Old RouterBlocker component wasn't removed because of time constraint
// ===== END OF NOTE ======
// This component will listen to navigation events
// when blockerStore.shouldPauseNavigation === true
// When navigation event happens it will display modal window
// with title, confirm and cancel button

export var RouteBlocker = function RouteBlocker(props) {
  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      mount = _useState2[0],
      setMount = _useState2[1];

  useEffect(function () {
    setTimeout(function () {
      setMount(true);
    }, 0);
  }, []);
  return React.createElement(Observer, null, function () {
    return React.createElement(React.Fragment, null, React.createElement(ReactRouterPause, {
      handler: blockerStore.handleNavigationAttempt,
      when: blockerStore.shouldBlockNavigationWithModal
    }), mount ? React.createElement(Modal, {
      hidden: blockerStore.dialogProps.hidden,
      title: blockerStore.modalTitle,
      close: blockerStore.dialogProps.handleStay
    }, React.createElement("div", {
      className: "mb1"
    }, blockerStore.modalBody || props.children), React.createElement("div", {
      className: "tac"
    }, React.createElement(Button, {
      buttonColor: ButtonColor.Light,
      onClick: function onClick() {
        return blockerStore.dialogProps.handleLeave();
      }
    }, React.createElement(Icon, {
      iconType: IconType.approveO,
      iconColor: IconColor.Inverse
    }), "Accept"), React.createElement(Button, {
      iconLeft: true,
      onClick: function onClick() {
        return blockerStore.dialogProps.handleStay();
      }
    }, "Cancel"))) : null);
  });
}; // Will display confirm modal on navigation event until
// stopBlockingRouteNavigation() is called or user confirms the modal

export function startBlockingRouteNavigation() {
  var modalTitle = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'RouteChangeConfirmationModalTitle';
  var modalBody = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  blockerStore.setModalTitle(translate(modalTitle));
  blockerStore.setModalBody(translate(modalBody));
  blockerStore.setBlockNavigationWithModal(true);
}
export function stopBlockingRouteNavigation() {
  blockerStore.setBlockNavigationWithModal(false);
}
var debouncedCheckFormFieldsFocus = debounce(checkValuesAndBlockOrUnblock, 150);

function checkValuesAndBlockOrUnblock(formState) {
  if (formState.submitting || formState.submitSucceeded) {
    stopBlockingRouteNavigation();
  } else if (formState.active !== undefined) {
    startBlockingRouteNavigation(); // LOG-2386
  }
} // This function is for final-form <formSpy> component which has onChange callback where this function is called


export function protectFromRoutingIfTouchedFieldsAreNotEmpty(formState) {
  var touchedFields = Object.keys(formState.touched).filter(function (field) {
    return formState.touched[field] === true;
  });
  touchedFields.forEach(function (fieldName) {
    if (formState.values[fieldName]) {
      startBlockingRouteNavigation();
      return;
    } else {
      stopBlockingRouteNavigation();
    }
  });
} // This function is for final-form <formSpy> component which has onChange callback where this function is called

export function checkFormFieldsFocus(formState) {
  debouncedCheckFormFieldsFocus(formState);
}
/**
 * Show confirm modal in the same manner as during navigation. Returns promise, which is resolved, when user
 * confirms, or rejected when user cancels. Method startBlockingRouteNavigation must be called before, otherwise
 * it is immediate resolve.
 *
 * This is designed to be used on pages where content is changing, but location is the same.
 */

export function simulateNavigation() {
  return blockerStore.simulateNavigation();
}