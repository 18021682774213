"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var DecimalRoundingEnum_1 = require("./DecimalRoundingEnum");

exports.DecimalRoundingEnum = DecimalRoundingEnum_1.DecimalRoundingEnum;

var BigDecimal_1 = require("./BigDecimal");

exports.BigDecimal = BigDecimal_1.BigDecimal;
exports.maxScale = BigDecimal_1.maxScale;
exports.bigPowerTen = BigDecimal_1.bigPowerTen;

var functions_1 = require("./functions");

exports.bigdecimal = functions_1.bigdecimal;
exports.bigDecimalToDecimal128 = functions_1.bigDecimalToDecimal128;
exports.bigDecimalFromDecimal128 = functions_1.bigDecimalFromDecimal128; //Rather idiotic way how to deal with module circular dependencies, but it works

exports.BigDecimal.NaN = exports.bigdecimal('NaN');
exports.BigDecimal.PInf = exports.bigdecimal('Infinity');
exports.BigDecimal.NInf = exports.bigdecimal('-Infinity');
exports.BigDecimal.ZERO = exports.bigdecimal('0');
exports.BigDecimal.ONE = exports.bigdecimal('1');
exports.BigDecimal.valueOf = exports.bigdecimal;