/**
 * @file Data cleaning page
 * @author VBr
 */

import {StructPropBuilder, PricingPermissions} from '@logio/common-be-fe';
import {
  AgGrid,
  AgGridWrapper,
  Button,
  ButtonSize,
  CheckBox,
  Field,
  Form,
  FormGroupInline,
  getPath,
  GroupActions,
  Icon,
  IconColor,
  IconType,
  InputValidated,
  Modal,
  Page,
  translate,
  WithPermission,
  ConfirmBoxWithLoading,
} from '@logio/common-fe';
import {observer, Observer} from 'mobx-react';
import * as React from 'react';
import queryString from 'query-string';
import {match} from 'react-router';
import {IRouteParams} from 'routes-app';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {PriceArchitectureProductsPageStore} from '../../../stores/pages/DataManagement/PriceArchitecture/PriceArchitectureProductsPageStore';
import AssignToFamilyModal from './AssignToFamilyModal';
import {packageSizeHelper} from '../../../shared/packageSizeHelper';

export interface Props {
  match: match<IRouteParams>;
  location: {
    externalId?: string;
    search?: string;
  }
}

export interface PriceArchitectureProductRow {
  actions: JSX.Element | undefined;
  name: string;
}

@observer
class PriceArchitectureProducts extends React.Component<Props> {
  store = new PriceArchitectureProductsPageStore(this.props.match.params.id, this.props.location.externalId);

  componentWillMount() {
    this.store.load();

    const params = queryString.parse(this.props.location.search);
    if (params.externalId) {
      this.store.setSearchText(params.externalId);
    }
  }

  get changeTierDescription() {
    const builder = new StructPropBuilder('ChangeTier');

    return {
      tier: builder.lookup('tier', 'sequences/tiers'),
    };
  }

  render() {
    return (
      <Page
        titleKey={PageNamesEnum.PriceArchitectureProducts}
        titleParams={[this.store.productCategory ? this.store.productCategory.name : '']}
        loadingState={this.store.loadingState}
        backButtonPath={getPath(PagePathsEnum.PriceArchitectureCategories)}
      >
        <Observer>
          {() => (
            <AgGridWrapper>
              <FormGroupInline right={false}>
                <Icon iconType={IconType.newsFull} iconHeight={12} iconColor={IconColor.Secondary} />
                <CheckBox
                  value=""
                  name="recommended"
                  checked={this.store.recommended}
                  onChange={this.store.toggleRecommended}
                  label={translate('price_architecture-recommended_products-label')}
                />
                <Icon iconType={IconType.newsRecommend} iconHeight={12} iconColor={IconColor.Secondary} />
                <CheckBox
                  value=""
                  name="new"
                  checked={this.store.new}
                  onChange={this.store.toggleNew}
                  label={translate('price_architecture-new_products-label')}
                />
                <InputValidated
                  value={this.store.searchText}
                  onChange={this.store.searchProduct}
                  style={{minWidth: '300px'}} // FIXME: Ask PKl for some suitable class
                  placeholder={translate('price_architecture-search_product-placeholder')}
                />
              </FormGroupInline>
              <GroupActions selectedRowsCount={this.store.selectedRows.length}>
                {({disabled}) => (
                  <>
                    <WithPermission permissionTypes={[PricingPermissions.FAMILY_MANAGE]}>
                      <Button
                        buttonSize={ButtonSize.Tiny}
                        onClick={this.store.getModalToggleEvent('assignFamily')}
                        disabled={disabled}
                      >
                        {translate('assign-family')}
                      </Button>
                      <Button
                        buttonSize={ButtonSize.Tiny}
                        onClick={this.store.getModalToggleEvent('removeFamily')}
                        disabled={disabled}
                      >
                        {translate('remove-family')}
                      </Button>
                    </WithPermission>
                    <Button
                      buttonSize={ButtonSize.Tiny}
                      onClick={this.store.getModalToggleEvent('changeTier')}
                      disabled={disabled}
                    >
                      {translate('change-tier')}
                    </Button>
                  </>
                )}
              </GroupActions>
              <ConfirmBoxWithLoading
                title={translate('remove-family')}
                hidden={this.store.modalHidden.removeFamily}
                close={this.store.getModalToggleEvent('removeFamily')}
                onAccept={this.store.removeFamily}
                isLoading={this.store.removeFamilyIsLoading}
              >
                Confirm content
              </ConfirmBoxWithLoading>
              <Modal
                title={translate('assign_to_family-title', this.store.selectedProducts.length.toString())}
                hidden={this.store.modalHidden.assignFamily}
                close={this.store.getModalToggleEvent('assignFamily')}
                wide
              >
                <AssignToFamilyModal productsStore={this.store} />
              </Modal>
              <Modal
                title={translate('change_tier-title', this.store.selectedProducts.length.toString())}
                hidden={this.store.modalHidden.changeTier}
                close={this.store.getModalToggleEvent('changeTier')}
              >
                <Form
                  descriptions={this.changeTierDescription}
                  onSubmit={this.store.updateTiers}
                  initialValues={{tier: this.store.tiers.defaultTier.id}}
                >
                  <Field name="tier" />
                  <Button type="submit">{translate('change_tier-button')}</Button>
                </Form>
              </Modal>
              <AgGrid
                rowData={this.store.rowData}
                columnDefs={this.store.columnDefs}
                rowSelection="multiple"
                onSelectionChanged={this.store.onSelectionChanged}
                onGridReady={this.store.onGridReady}
                // getRowClass={this.store.getTierColor}
                rowClassRules={{
                  'bg-scale1': (params) => this.store.getRowClassRule('bg-scale1', params),
                  'bg-scale2': (params) => this.store.getRowClassRule('bg-scale2', params),
                  'bg-scale3': (params) => this.store.getRowClassRule('bg-scale3', params),
                  'bg-scale4': (params) => this.store.getRowClassRule('bg-scale4', params),
                  'bg-scale5': (params) => this.store.getRowClassRule('bg-scale5', params),
                  'bg-scale6': (params) => this.store.getRowClassRule('bg-scale6', params),
                  'bg-scale7': (params) => this.store.getRowClassRule('bg-scale7', params),
                }}
                isExternalFilterPresent={this.store.isExternalFilterPresent}
                doesExternalFilterPass={this.store.doesExternalFilterPass}
                headerHeight={50}
                onColumnVisible={packageSizeHelper.onColumnVisible}
                gridId="PricingPriceArchitectureProductsPage"
                quickView
                deltaRowDataMode={true}
                getRowNodeId={({Product_id: id}) => id}
                // LOG-2452 BUG 3.
                postSort={this.store.postSort}
                // LOG-4261
                onSortChanged={this.store.postSortDisable}
              />
            </AgGridWrapper>
          )}
        </Observer>
      </Page>
    );
  }
}

export default PriceArchitectureProducts;
