"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Resolved elasticity report information (of a product) at given price point.
 *
 * @author Michal Sevcenko
 */

var ElasticityProductPriceData =
/**
 * ctor.
 *
 * @param price optimal price as reported by YDG
 * @param margin BM2 for this price
 * @param profit profit for this price
 * @param salesVolume estimated sales volume for this price
 * @param priceChangeCZK change of this price vs reference price, in CZK
 * @param priceChangePercent change of this price vs reference price, percents
 * @param marginChangeCZK change of BM2 for this price vs reference price, in CZK
 * @param marginChangePercent  description:change of BM2 for this price vs reference price, in percents
 * @param profitChangeCZK change of profit for this price vs reference price, in CZK
 * @param profitChangePercent change of profit for this price vs reference price, percents
 * @param salesVolumeChangeUnit change of estimated sales price for this price vs reference price, in units
 * @param salesVolumeChangePercent change of estimated sales price for this price vs reference price, in percents
 */
function ElasticityProductPriceData(price, margin, profit, salesVolume, priceChangeCZK, priceChangePercent, marginChangeCZK, marginChangePercent, profitChangeCZK, profitChangePercent, salesVolumeChangeUnit, salesVolumeChangePercent) {
  _classCallCheck(this, ElasticityProductPriceData);

  this.price = price;
  this.margin = margin;
  this.profit = profit;
  this.salesVolume = salesVolume;
  this.priceChangeCZK = priceChangeCZK;
  this.priceChangePercent = priceChangePercent;
  this.marginChangeCZK = marginChangeCZK;
  this.marginChangePercent = marginChangePercent;
  this.profitChangeCZK = profitChangeCZK;
  this.profitChangePercent = profitChangePercent;
  this.salesVolumeChangeUnit = salesVolumeChangeUnit;
  this.salesVolumeChangePercent = salesVolumeChangePercent;
};

exports.ElasticityProductPriceData = ElasticityProductPriceData;