import _defineProperty from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _Tooltips;

/**
 * @file Created on Thu May 09 2019
 * @author LZe
 */
import ReleaseSelloutPromoTooltip from './ReleaseSelloutPromoTooltip';
import ReleaseCRPPriceTooltip from './ReleaseCRPPriceTooltip';
import ReleasePromoTooltip from './ReleasePromoTooltip';
export var TooltipNames;

(function (TooltipNames) {
  TooltipNames["ReleasePromoTooltip"] = "RELEASE_PROMO_TOOLTIP";
  TooltipNames["ReleaseSelloutPromoTooltip"] = "RELEASE_SELLOUT_PROMO_TOOLTIP";
  TooltipNames["ReleaseCRPPriceTooltip"] = "RELEASE_CRP_PRICE_TOOLTIP";
})(TooltipNames || (TooltipNames = {}));

var Tooltips = (_Tooltips = {}, _defineProperty(_Tooltips, TooltipNames.ReleasePromoTooltip, ReleasePromoTooltip), _defineProperty(_Tooltips, TooltipNames.ReleaseSelloutPromoTooltip, ReleaseSelloutPromoTooltip), _defineProperty(_Tooltips, TooltipNames.ReleaseCRPPriceTooltip, ReleaseCRPPriceTooltip), _Tooltips);
export default Tooltips;