/**
 * @author VBr
 */

import {CodebookLookupSequence, PricingRoles, StructPropBuilder, WorkflowCaseInfo} from '@logio/common-be-fe';
import {
  AgGrid,
  AgGridWrapper,
  BoxTabContainer,
  Button,
  ButtonSize,
  Field,
  Form,
  GroupActions,
  Loader,
  LoadingState,
  Messages,
  OptionsProvider,
  Page,
  RoleControl,
  SelectAdapter,
  SelectOptions,
  translate,
} from '@logio/common-fe';
import {Location} from 'history';
import {Observer, observer} from 'mobx-react';
import {parse} from 'querystring';
import * as React from 'react';
import {Col, Row} from 'react-flexbox-grid';
import {isString} from 'util';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {WorkflowLocksPageStore} from '../../../stores/pages/Settings/WorkflowAdministration/WorkflowLocksPageStore';
import Tabs from './Tabs';

interface Props {
  location: Location;
}

@observer
class WorkflowLocksPage extends React.Component<Props> {
  store = new WorkflowLocksPageStore();

  componentWillMount() {
    this.store.load();
  }

  get schema() {
    const builder = new StructPropBuilder(WorkflowCaseInfo.name);

    return {
      workflowDefinition: builder.opt(builder.lookup('workflowDefinition', CodebookLookupSequence.WorkflowDefinition)),
      workflowCase: builder.opt(builder.lookup('workflowCase', CodebookLookupSequence.WorkflowCase)),
    };
  }

  get initialValues() {
    const id = parse(this.props.location.search.slice(1)).caseId;
    const wflowDefinition = id
      ? this.store.workflowAdministrationStore.cases.get(isString(id) ? id : id[0])
      : undefined;

    return {
      workflowCase: id ? (isString(id) ? id : id[0]) : undefined,
      workflowDefinition: wflowDefinition ? wflowDefinition.wflowId : undefined,
    };
  }

  render() {
    return (
      <Page titleKey={PageNamesEnum.WorkflowAdministration} showMessages={false}>
        <RoleControl roles={[PricingRoles.ADMIN]}>
          <Observer>
            {() => (
              <AgGridWrapper>
                <Messages />
                <Tabs />
                <BoxTabContainer>
                  {this.store.loadingState === LoadingState.Pending && <Loader />}
                  {this.store.loadingState === LoadingState.Success && (
                    <>
                      <Form
                        formLayoutVertical
                        descriptions={this.schema}
                        initialValues={this.initialValues}
                        onSubmit={this.store.updateFiler}
                        onChange={this.store.updateFiler}
                      >
                        <Row>
                          <Col xs={6}>
                            <GroupActions selectedRowsCount={this.store.selectedRowsCount}>
                              {({disabled}) => (
                                <>
                                  <Button
                                    buttonSize={ButtonSize.Tiny}
                                    onClick={this.store.unlockGroup}
                                    disabled={disabled}
                                  >
                                    {translate('workflow_lock-remove')}
                                  </Button>
                                </>
                              )}
                            </GroupActions>
                          </Col>
                          <Col xs={3}>
                            <Field
                              name="workflowDefinition"
                              fieldPlaceholder={translate('forms_select_WorkflowCaseInfo_workflowDefinition')}
                              fieldNoLabel
                            />
                          </Col>
                          <Col xs={3}>
                            <OptionsProvider
                              name="workflowCase"
                              component={(options: SelectOptions) => (
                                <Field
                                  name="workflowCase"
                                  fieldPlaceholder={translate('forms_select_workflowCase')}
                                  component={(props) =>
                                    SelectAdapter('workflowCase', options, false, false, true)(props)
                                  }
                                />
                              )}
                              dependsOn={{workflowDefinition: 'workflowDefinition'}}
                            />
                          </Col>
                        </Row>
                      </Form>
                      <AgGrid
                        rowData={this.store.rowData}
                        columnDefs={this.store.columnDefs}
                        onSelectionChanged={this.store.onSelectionChanged}
                        isExternalFilterPresent={this.store.isExternalFilterPresent}
                        doesExternalFilterPass={this.store.doesExternalFilterPass}
                        gridId="WorkflowLocksPage"
                        rowSelection="multiple"
                      />
                    </>
                  )}
                </BoxTabContainer>
              </AgGridWrapper>
            )}
          </Observer>
        </RoleControl>
      </Page>
    );
  }
}

export default WorkflowLocksPage;
