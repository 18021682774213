"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Type of SDR report. SDR has two forms:
 *  - Regular shows history and future
 *  - Actual form shows only current week info
 */

var DashboardReportType;

(function (DashboardReportType) {
  DashboardReportType["Regular"] = "REGULAR";
  DashboardReportType["Actual"] = "ACTUAL";
})(DashboardReportType = exports.DashboardReportType || (exports.DashboardReportType = {}));