import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  @font-face {\n    font-family: 'Nunito';\n    src: \n    url(", ") format('woff2'),\n      url(", ") format('woff'),\n      url(", ") format('truetype');\n    font-weight: 300;\n    font-style: normal;\n  }\n\n  @font-face {\n    font-family: 'Nunito';\n    src: \n    url(", ") format('woff2'),\n      url(", ") format('woff'),\n      url(", ") format('truetype');\n    font-weight: 400;\n    font-style: normal;\n  }\n\n  @font-face {\n    font-family: 'Nunito';\n    src: \n    url(", ") format('woff2'),\n      url(", ") format('woff'),\n      url(", ") format('truetype');\n    font-weight: 600;\n    font-style: normal;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { css } from 'styled-components';
/* tslint:disable */

export var fonts = css(_templateObject(), require('../../../assets/fonts/Nunito-Light.woff2'), require('../../../assets/fonts/Nunito-Light.woff'), require('../../../assets/fonts/Nunito-Light.ttf'), require('../../../assets/fonts/Nunito-Regular.woff2'), require('../../../assets/fonts/Nunito-Regular.woff'), require('../../../assets/fonts/Nunito-Regular.ttf'), require('../../../assets/fonts/Nunito-SemiBold.woff2'), require('../../../assets/fonts/Nunito-SemiBold.woff'), require('../../../assets/fonts/Nunito-SemiBold.ttf'));
/* tslint:enable */