/**
 * @file Created on Fri Nov 16 2018
 * @author SKu
 */
import { Utils } from '@logio/common-be-fe';
import * as React from 'react';
import { CONSTANTS } from '../../../../../constants';
import Tooltip from '../../../../info/Tooltip';
import Circle, { CircleWidth } from '../../../../typography/Circle';
/** Pure Component is used because of types. You cannot return array of elements in SFC */

export var ReleaseStatusRenderer = function ReleaseStatusRenderer(props) {
  if (Utils.isValueMissing(props.value)) {
    return React.createElement(React.Fragment, null, CONSTANTS.FORMAT.NULL);
  }

  var statuses = props.value.roleStatuses;
  return React.createElement(React.Fragment, null, statuses.map(function (status, i) {
    var circle = React.createElement(Circle, {
      className: "mr1",
      color: status.indication,
      key: i,
      width: CircleWidth.Normal
    });
    return status.userInfo ? React.createElement(Tooltip, {
      overlay: status.userInfo.name,
      placement: "right",
      children: circle,
      key: i
    }) : circle;
  }));
};