/**
 * @file Created on Fri Oct 19 2018
 * @author PKl, SKu
 */
import * as React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import NoticeTitlesStyled from './styled/NoticeTitlesStyled';
/**
 * NoticeTitles
 * @component
 */

var NoticeTitles = function NoticeTitles(props) {
  return React.createElement(React.Fragment, null, React.createElement(NoticeTitlesStyled, null, React.createElement(Row, null, props.titles.map(function (title, i) {
    return React.createElement(Col, {
      xs: props.titleSizes[i],
      key: i
    }, React.createElement("strong", null, title));
  }))));
};

export default NoticeTitles;