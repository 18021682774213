/**
 * @file Created on Fri Oct 12 2018
 * @author SKu
 */

import {BigDecimal} from '@logio/big-decimal';
import {
  BlockedProduct,
  FinalProductPrice,
  ImpactRecord,
  PriceComment,
  PriceCommentSaveRequest,
  Product,
  Range,
  ReleaseItem,
  ReleaseState,
  ReleaseTotalDTO,
  ReleaseWorkflowType,
  ReleaseItemRepricingMessageCode,
  Utils,
  ValidationResultObject,
  ValidationResultWrapper,
  YDGOptimizationResultCode,
  FinalProductPriceMessageCode,
  ReleaseItemValidationKey,
} from '@logio/common-be-fe';
import {
  ColumnDefinition,
  Comparators,
  CONSTANTS,
  getPath,
  getProductFlags,
  IReleaseImpactFilter,
  MessageStore,
  productFlagsColumnDefinition,
  ReleaseStore,
  RendererNames,
  rootStore,
  StoreName,
  StringMapping,
  translate,
} from '@logio/common-fe';
import {List, Map as IMap, Set as ISet} from 'immutable';
import {CellClickedEvent, FilterChangedEvent, RowNode, ValueGetterParams} from 'ag-grid-community';
import {History} from 'history';
import debounce from 'lodash/debounce';
import {computed, observable, runInAction} from 'mobx';
import {Moment} from 'moment';
import {PagePathsEnum} from '../../../../shared/localization/PagePathsEnum';
import {AbstractReleaseDetailCategoryComponentStore} from './AbstractReleaseDetailCategoryComponentStore';
import {ReleaseDetailCategoryComponentAgGridServices} from './ReleaseDetailCategoryComponentAgGridServices';

export enum DetailCategoryModalEnum {
  Comment = 'COMMENT',
}

export class ReleaseDetailCategoryComponentStore extends AbstractReleaseDetailCategoryComponentStore {
  /** @inheritdoc */
  constructor(public history: History, messages: MessageStore, releaseId: string, categoryId?: string) {
    super(messages, releaseId, categoryId);
  }

  /** Stores */
  public releaseStore = rootStore.getStore(StoreName.Release) as ReleaseStore;
  private agGridServices = new ReleaseDetailCategoryComponentAgGridServices();

  /** Ids of categoryTotals that have been filtered in bottom ag-grid (used for fetching subtotal for top ag-grid) */
  private filteredCategoryReleaseItemIds = new Map<string, string[]>();

  /** Ids of release items that have been filtered in bottom ag-grid */
  @observable
  private filteredReleaseItemIds: string[] = [];

  /** Data for TotalOverview Grid(top) */
  @observable
  public totals: Map<string, object> = new Map();

  @observable
  public subtotals: Map<string, object> = new Map();

  public totalWithActions: Map<string, ReleaseTotalDTO> = new Map();

  @observable
  releaseCannotBeExported: boolean = false;

  /**
   * Return true if forecast obsolete is true on regular release item
   * @param params ag-grid params
   */
  public isForecastObsolete = (params) => {
    return params.data.ReleaseDetailCategory_realImpact_forecastObsolete
      ? !!params.data.ReleaseDetailCategory_realImpact_forecastObsolete
      : false;
  };

  /**
   * Returns true if product has validation result of ReleaseItemStartDateBeforeTomorrow
   */
  public isReleaseItemStartDateBeforeTomorrow = (params) => {
    let error;
    if (params.data.ReleaseDetailCategory_messages) {
      error = params.data.ReleaseDetailCategory_messages.resultObjects.find(
        (resultObject) => resultObject.messageKey === ReleaseItemRepricingMessageCode.ReleaseItemStartDateBeforeTomorrow,
      );
    }
    return !!error;
  };

  /** Stores data for ItemOverview Grid (bottom) */
  @observable
  public items = new Map<string, ReleaseItem>();

  /** Initial values for comment modal */
  @observable
  public comments: {releaseItemId: string; priceZoneId: string; comments: PriceComment[]};

  /** Separate alerts that should be shown as productMessages */
  private cleaningAlerts = new Map<string, ValidationResultObject<ReleaseItemValidationKey>>();

  /** Current release */
  @computed
  public get release() {
    return this.releaseStore.release;
  }

  /** Returns true if release workflow type is urgent */
  @computed
  public get isUrgent() {
    return this.releaseStore.release.workflowType === ReleaseWorkflowType.Urgent;
  }

  @computed
  public get isReleased() {
    return this.release.state === ReleaseState.Released;
  }

  /** TotalOverview Ag-Grid Column definitions */
  @computed
  public get totalOverviewColumnDefs(): ColumnDefinition[] {
    const totalColDefs = [
      {field: 'name'},
      {field: 'priceSticker'},
      {
        field: 'marginConstraint',
        cellRenderer: ({value}: {value: Range<BigDecimal>}) =>
          value ? `${value.minimum} - ${value.maximum}` : CONSTANTS.FORMAT.NULL,
      },
      {
        field: 'optimizationGoal',
        valueFormatter: (params) => params.value ? translate(`optimizationGoal_${params.value}`) : CONSTANTS.FORMAT.NULL,
      },
      {field: 'marginOld', dontCreateColumn: this.isUrgent},
      {field: 'marginOldPercent', dontCreateColumn: this.isUrgent},
      {field: 'marginNew', dontCreateColumn: this.isUrgent},
      {field: 'marginNewPercent', dontCreateColumn: this.isUrgent},
      {
        field: 'marginImpact',
        comparator: Comparators.arrowComparator,
        cellRenderer: RendererNames.ArrowRenderer,
        dontCreateColumn: this.isUrgent,
      },
      {
        field: 'marginImpactPercent',
        comparator: Comparators.arrowComparator,
        cellRenderer: RendererNames.ArrowRenderer,
        dontCreateColumn: this.isUrgent,
      },
      {field: 'salesVolumeOld', dontCreateColumn: this.isUrgent},
      {field: 'salesVolumeNew', dontCreateColumn: this.isUrgent},
      {
        field: 'salesVolumeImpact',
        comparator: Comparators.arrowComparator,
        cellRenderer: RendererNames.ArrowRenderer,
        dontCreateColumn: this.isUrgent,
      },
      {
        field: 'salesVolumeImpactPercent',
        comparator: Comparators.arrowComparator,
        cellRenderer: RendererNames.ArrowRenderer,
        dontCreateColumn: this.isUrgent,
      },
      {field: 'revenueOld', dontCreateColumn: this.isUrgent},
      {field: 'revenueNew', dontCreateColumn: this.isUrgent},
      {
        field: 'revenueImpact',
        comparator: Comparators.arrowComparator,
        cellRenderer: RendererNames.ArrowRenderer,
        dontCreateColumn: this.isUrgent,
      },
      {
        field: 'revenueImpactPercent',
        comparator: Comparators.arrowComparator,
        cellRenderer: RendererNames.ArrowRenderer,
        dontCreateColumn: this.isUrgent,
      },
      {field: 'priceChange', dontCreateColumn: this.isUrgent},
      {
        field: 'realImpact_bm',
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'realImpact_bmPercent',
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'realImpact_bmImpact',
        cellRenderer: RendererNames.ArrowRenderer,
        comparator: Comparators.arrowComparator,
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'realImpact_bmImpactInPercent',
        cellRenderer: RendererNames.ArrowRenderer,
        comparator: Comparators.arrowComparator,
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'realImpact_saleValueWithoutVat',
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'realImpact_revenueImpact',
        cellRenderer: RendererNames.ArrowRenderer,
        comparator: Comparators.arrowComparator,
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'realImpact_amount',
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'realImpact_salesVolumeImpact',
        cellRenderer: RendererNames.ArrowRenderer,
        comparator: Comparators.arrowComparator,
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'realImpact_diffDays',
        valueFormatter: (params) => {
          return params.value ? Number(params.value).toFixed(0) : CONSTANTS.FORMAT.NULL;
        },
        dontCreateColumn: !this.isReleased,
      },
    ];
    return [...this.agGridServices.generators.totalOverviewGenerator.getColumnDefinitions(totalColDefs)];
  }
  public isChangedDueToRecalculate = (params) => {
    return (
      params.data.ReleaseDetailCategory_messages &&
      params.data.ReleaseDetailCategory_messages.resultObjects.messageKey === FinalProductPriceMessageCode.FinalPriceValueChangedDueToRecalculation
    );
  };

  /** ItemOverview Ag-Grid Column definitions */
  @computed
  public get itemOverviewColumnDefs(): ColumnDefinition[] {
    const zoneActions = {
      updateItemPriceCode: this.updateItemPriceCode,
      updateItemFinalPrice: this.updateItemFinalPrice,
      handleCommentModalOpen: this.handleCommentModalOpen,
      updateItemFinalPriceDate: this.updateItemFinalPriceDate,
    };

    const itemColDefs: ColumnDefinition[] = [
      {
        field: 'productName',
        pinned: true,
        width: 250,
      },
      {
        field: 'messages',
        cellRenderer: RendererNames.ReleaseAlertsRenderer,
        comparator: alertsFilterComparator,
        filterValueGetter: (params: ValueGetterParams) => {
          return alertsFilterValueGetter(params.data.ReleaseDetailCategory_messages.resultObjects);
        },
        width: 80,
        excel: {
          hide: true,
        },
      },
      {
        field: 'ydg_messages',
        width: 80,
        excel: {
          hide: true,
        },
        cellRenderer: RendererNames.ReleaseAlertsRenderer,
        comparator: alertsFilterComparator,
        valueGetter: ydgAlertsValueGetter,
        filterValueGetter: (params: ValueGetterParams) => {
          return alertsFilterValueGetter(ydgAlertsValueGetter(params).resultObjects);
        },
      },
      {
        field: 'supplier',
        valueFormatter: (value) => (value.value ? value.value.name : null),
        filterValueGetter: (params) =>
          params.data.ReleaseDetailCategory_supplier ? params.data.ReleaseDetailCategory_supplier.name : null,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'name4BOX',
        filter: 'agSetColumnFilter',
      },
      {
        field: 'categoryPlan',
        filter: 'agSetColumnFilter',
      },
      {
        field: 'flags',
        ...productFlagsColumnDefinition,
      },
      {
        field: 'productSensitivity',
        filter: 'agSetColumnFilter',
      },
      {
        field: 'family',
        valueFormatter: (value) => (value.value ? value.value.name : null),
        cellClassRules: {'c-link pointer': (value) => !!value.value},
        onCellClicked: (params) => this.navigateToFamily(params),
        filter: 'agSetColumnFilter',
        filterValueGetter: (params) =>
          params.data.ReleaseDetailCategory_family ? params.data.ReleaseDetailCategory_family.name : null,
        comparator: Comparators.familyNameComparator,
      },
      {
        field: 'tier',
        filter: 'agSetColumnFilter',
      },
      {
        field: 'exported',
        editable: (params) => {
          return (
            (this.editMode && !params.data.ReleaseDetailCategory_flags.has(BlockedProduct)) ||
            (this.editMode && params.data.ReleaseDetailCategory_exported)
          );
        },
        action: this.exportItem,
      },
      {
        field: 'purchasePrice',
        round: true,
      },
      {
        field: 'openPurchasePrice',
        round: true,
      },
      {
        field: 'regularPrice',
        headerName: translate('ReleaseItemDetail_regularPriceNational', this.nationalZone.name),
        round: true,
      },
    ];

    const realImpactColDefs: ColumnDefinition[] = [
      {field: 'forecastObsolete', dontCreateColumn: true},
      {
        field: 'realImpact_bm',
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'realImpact_bmPercent',
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'realImpact_bmImpact',
        cellRenderer: RendererNames.ArrowRenderer,
        comparator: Comparators.arrowComparator,
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'realImpact_bmImpactInPercent',
        cellRenderer: RendererNames.ArrowRenderer,
        comparator: Comparators.arrowComparator,
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'realImpact_saleValueWithoutVat',
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'realImpact_revenueImpact',
        cellRenderer: RendererNames.ArrowRenderer,
        comparator: Comparators.arrowComparator,
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'realImpact_amount',
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'realImpact_salesVolumeImpact',
        cellRenderer: RendererNames.ArrowRenderer,
        comparator: Comparators.arrowComparator,
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'realImpact_diffDays',
        valueFormatter: (params) => {
          return params.value ? Number(params.value).toFixed(0) : CONSTANTS.FORMAT.NULL;
        },
        dontCreateColumn: !this.isReleased,
      },
    ];

    const zonesColDefs: ColumnDefinition[] = this.agGridServices.createItemOverviewZoneCoDefs(
      zoneActions,
      this.editMode,
    );

    return [
      ...this.agGridServices.generators.itemOverviewGenerator.getColumnDefinitions(itemColDefs),
      ...this.agGridServices.generators.itemOverviewGenerator.getColumnDefinitions(realImpactColDefs),
      ...this.agGridServices.generators.itemOverviewZoneGenerator.getColumnDefinitions(zonesColDefs),
    ];
  }

  /**
   * Return generated data for the top ag-grid(totalOverview)
   */
  @computed
  public get totalOverviewRowData(): Array<StringMapping<any>> {
    const rowData = [];
    if (this.categoryId && this.totals && this.totals.size) {
      Array.from(this.totals.values()).forEach((value) => {
        rowData.push(value);
      });
    }
    if (this.subtotals && this.subtotals.size) {
      Array.from(this.subtotals.values()).forEach((value) => {
        rowData.push(value);
      });
    }
    return rowData;
  }

  /**
   * Returns row data for total
   * @param total
   * @param categoryName
   */
  private getTotalRowData(total: ReleaseTotalDTO, categoryName: string) {
    const realImpact: any = total.realImpact && total.realImpact.getValue();
    const convertedRealImpact = realImpact
      ? {
          realImpact_bm: realImpact.bm,
          realImpact_bmPercent: realImpact.bmPercent,
          realImpact_bmImpact: realImpact.bmImpact,
          realImpact_bmImpactInPercent: realImpact.bmImpactInPercent,
          realImpact_saleValueWithoutVat: realImpact.saleValueWithoutVat,
          realImpact_revenueImpact: realImpact.revenueImpact,
          realImpact_amount: realImpact.amount,
          realImpact_salesVolumeImpact: realImpact.salesVolumeImpact,
          realImpact_diffDays: realImpact.diffDays,
        }
      : {};
    const totalData = {name: categoryName, ...total.impact, ...convertedRealImpact};
    return {...this.agGridServices.generators.totalOverviewGenerator.getColumnData(totalData)};
  }

  /**
   * Returns row data for subtotal
   * @param subtotal
   */
  private getSubtotalRowData(subtotal) {
    const subTotalData = {
      ...{name: this.getSubtotalName(subtotal)},
      ...subtotal,
    };
    return {...this.agGridServices.generators.totalOverviewGenerator.getColumnData(subTotalData)};
  }

  /**
   * Returns name for category subtotal
   * @param subtotal
   */
  private getSubtotalName(subtotal: ImpactRecord): string {
    if (this.categoryId) {
      return subtotal.priceZoneId ? this.priceZoneStore.list.get(subtotal.priceZoneId).name : 'SUBTOTAL';
    } else {
      const category = this.productCategoryStore.getCategoryForRelease(this.releaseId, subtotal.categoryId);
      const categoryName = category ? category.name : CONSTANTS.FORMAT.NULL;

      return subtotal.priceZoneId
        ? `${categoryName} ${
            this.priceZoneStore.list.get(subtotal.priceZoneId).name
          }`
        : `SUBTOTAL ${categoryName}`;
    }
  }

  /**
   * Returns true, if filtered category items equals all items
   */
  private isVisibleAllProducts() {
    return this.filteredCategoryReleaseItemIds.get(this.categoryId).length === this.productStore.list.size;
  }

  /**
   * Return generated data for the bottom ag-grid(itemOverview)
   */
  @computed
  public get itemOverviewRowData(): Array<StringMapping<any>> {
    const rowData = [];
    // Added due to showing uploaded items firstly
    const rowDataUploaded = [];
    this.productStore.list.forEach((product: Product) => {
      /** ReleaseItem for current product */
      const releaseItem: ReleaseItem = this.items.get(product.id);
      if (!Utils.isValueMissing(releaseItem)) {
        /** Data for zone generator */
        const zoneData = {};

        /** Creating price date for each zone */
        releaseItem.prices.map((price) => Object.assign(zoneData, this.agGridServices.getZoneData(price)));

        const getReleaseItemGeneratorData = () => {
          /** 4Box name - is the first in the map */
          const name4BOX = this.productCategoryStore.getCategoryForRelease(this.releaseId, Utils.getCategoryIdWithLevel(product.categoryIds, 6));
          /** category plan - is the second in the map */
          const categoryPlan = this.productCategoryStore.getCategoryForRelease(this.releaseId, Utils.getCategoryIdWithLevel(product.categoryIds, 5));
          const productSensitivity = this.productSensitivityStore.list.get(product.productSensitivityId);
          const family = this.familyStore.list.get(product.familyId);
          let familyAction: any;
          if (family) {
            familyAction = {
              name: family.name,
              linkProps: {
                to: {
                  pathname: `${getPath(PagePathsEnum.PriceArchitectureProducts)}${Utils.getCategoryIdWithLevel(
                    product.categoryIds,
                    5,
                  )}`,
                  externalId: product.externalId,
                },
              },
            };
          }
          const tier = this.tierStore.list.get(family ? family.tierId : product.tierId);

          /** Merge existing Validation Result with additional */
          const additionalAlert = this.cleaningAlerts.get(product.id);
          const newValidationResult = releaseItem.validationResult;
          newValidationResult.addObject(additionalAlert);

          const realImpact = releaseItem.realImpact ? releaseItem.realImpact.getValue() : null;
          const convertedRealImpact = realImpact
            ? {
                realImpact_forecastObsolete: realImpact.forecastObsolete,
                realImpact_bm: realImpact.bm,
                realImpact_bmPercent: realImpact.bmPercent,
                realImpact_bmImpact: realImpact.bmImpact,
                realImpact_bmImpactInPercent: realImpact.bmImpactInPercent,
                realImpact_saleValueWithoutVat: realImpact.saleValueWithoutVat,
                realImpact_revenueImpact: realImpact.revenueImpact,
                realImpact_amount: realImpact.amount,
                realImpact_salesVolumeImpact: realImpact.salesVolumeImpact,
                realImpact_diffDays: realImpact.diffDays,
              }
            : {};
          const supplier = this.supplierStore.list.get(product.supplierId);

          return {
            id: releaseItem.id,
            productId: product.id,
            categoryId: Utils.getCategoryIdWithLevel(product.categoryIds, 5),
            productName: product.name,
            supplier,
            goldId: product.externalId,
            name4BOX: name4BOX ? name4BOX.name : CONSTANTS.FORMAT.NULL,
            categoryPlan: categoryPlan ? categoryPlan.name : CONSTANTS.FORMAT.NULL,
            flags: getProductFlags(product),
            productSensitivity: productSensitivity ? productSensitivity.title : CONSTANTS.FORMAT.NULL,
            family: familyAction,
            tier: tier ? tier.title : CONSTANTS.FORMAT.NULL,
            exported: releaseItem.exported,
            purchasePrice:
              releaseItem.purchasePrice && releaseItem.purchasePrice.avgPrice
                ? releaseItem.purchasePrice.avgPrice.value
                : CONSTANTS.FORMAT.NULL,
            openPurchasePrice:
              releaseItem.purchasePrice && releaseItem.purchasePrice.priceListPrice
                ? releaseItem.purchasePrice.priceListPrice.value
                : CONSTANTS.FORMAT.NULL,
            regularPrice: releaseItem.regularPrice ? releaseItem.regularPrice.value : CONSTANTS.FORMAT.NULL,
            messages: newValidationResult,
            uploaded: releaseItem.uploaded ? releaseItem.uploaded : CONSTANTS.FORMAT.NULL,
            ...convertedRealImpact,
          };
        };

        const pushItemTo = (arr: Array<StringMapping<any>>) => {
          arr.push({
            ...this.agGridServices.generators.itemOverviewGenerator.getColumnData(getReleaseItemGeneratorData()),
            ...this.agGridServices.generators.itemOverviewZoneGenerator.getColumnData(zoneData),
          });
        };

        if (getReleaseItemGeneratorData().uploaded === true) {
          pushItemTo(rowDataUploaded);
        } else {
          pushItemTo(rowData);
        }
      }
    });

    return [...rowDataUploaded, ...rowData];
  }

  checkIfReleaseCanBeExported(releaseItems: Map<string, ReleaseItem>) {
    this.releaseCannotBeExported = false;
    this.messages.remove('WARN_ReleaseItemRepricingFrequencyViolation');
    releaseItems.forEach((releaseItem) => {
      releaseItem.prices.forEach((price) => {
        if (price.repricing.possible === false && releaseItem.exported) {
          this.releaseCannotBeExported = true;
          return;
        }
      });
    });
    if (this.releaseCannotBeExported) {
      this.messages.setWarning(translate('WARN_ReleaseItemRepricingFrequencyViolation'));
    }
  }

  /**
   * Fire on Ag-grid Exported column checkbox click
   * Set {exported} flag in releaseItem
   * because we set getRowNodeId as releaseItemId, we have ReleaseItemId instead of rowIndex
   */
  private exportItem = async (rowIndex: string, column: string, value: boolean) => {
    this.itemOverviewGridApi.showLoadingOverlay();
    try {
      const affectedItems = await this.releaseStore.exportItem(rowIndex, value);
      runInAction(() => {
        affectedItems.forEach(item => {
          this.items.set(item.productId, item);
        });
      });
      /** Total and subtotal data should be updated after final price change */
      await this.updateTotal(Utils.getCategoryIdWithLevel(affectedItems.first()!.categoryIds, 5));
      this.updateSubTotalDebounce();
    } catch (error) {
      this.handleUpdateError(error);
    } finally {
      this.itemOverviewGridApi.hideOverlay();
    }
  };

  /**
   * Fire on Ag-grid PriceCode Select
   * Update Release Item Price Code
   * because we set getRowNodeId as releaseItemId, we have ReleaseItemId instead of rowIndex
   */
  private updateItemPriceCode = async (rowIndex: string, column: string, value: string) => {
    const priceZoneId = this.itemOverviewGridApi.getColumnDef(column).cellEditorParams.priceZoneId;
    const data = {priceZoneId, priceCode: value};
    this.itemOverviewGridApi.showLoadingOverlay();
    try {
      const item = await this.releaseStore.editItemPriceCode(rowIndex, data);
      runInAction(() => this.items.set(item.productId, item));
    } catch (error) {
      this.handleUpdateError(error);
    } finally {
      this.itemOverviewGridApi.hideOverlay();
    }
  };

  /**
   * Fire on Ag-grid FinalPrice Select
   * Update Release Item Final Price
   * because we set getRowNodeId as releaseItemId, we have ReleaseItemId instead of rowIndex
   */
  private updateItemFinalPrice = async (rowIndex: string, column: string, data: FinalProductPrice) => {
    this.updateFinalPrice(rowIndex, data);
  };

  /**
   * Allows to change
   * Update Release Item Final Price dateFrom/dateTo
   * @param type - date type to be updated
   */
  private updateItemFinalPriceDate = (type: 'validFrom' | 'validTo') => async (
    rowIndex: string,
    column: string,
    date: Moment,
  ) => {
    const priceZoneId = column.split('_')[1];
    const finalPrice = this.itemOverviewGridApi.getRowNode(rowIndex).data[
      `ReleaseDetailCategory_${priceZoneId}_finalPrice`
    ];
    const updatedFinalPrice = {...finalPrice, [type]: date};
    this.updateFinalPrice(rowIndex, updatedFinalPrice);
  };

  private async updateFinalPrice(rowIndex, updatedFinalPrice) {
    this.itemOverviewGridApi.showLoadingOverlay();
    try {
      /** Convert number to primitive value */
      const items = await this.releaseStore.editItemFinalPrice(rowIndex, updatedFinalPrice);
      runInAction(() => items.map((item) => this.items.set(item.productId, item)));
      let categoryIds: string[] = [];
      items.map((item: ReleaseItem) => categoryIds.push(Utils.getCategoryIdWithLevel(item.categoryIds, 5)));
      categoryIds = [...new Set(categoryIds)];
      /** Total and subtotal data should be updated after final price change */
      await categoryIds.forEach((id: string) => this.updateTotal(id));
      await this.releaseStore.getOne(this.releaseId);
      this.updateSubTotalDebounce();
    } catch (error) {
      this.handleUpdateError(error);
    } finally {
      this.itemOverviewGridApi.hideOverlay();
    }
  }

  /**
   * Function fires on comment form submit,
   * handle createComment endpoint
   */
  public onCommentSubmit = async (data) => {
    const comment = new PriceCommentSaveRequest(data.priceZoneId, data.text, data.persistent, data.deleteOn);
    this.itemOverviewGridApi.showLoadingOverlay();
    try {
      const item = await this.releaseStore.createItemComment(data.releaseItemId, comment);
      runInAction(() => this.items.set(item.productId, item));
    } catch (error) {
      this.handleUpdateError(error);
    } finally {
      this.itemOverviewGridApi.hideOverlay();
      this.hideModal();
    }
  };

  /**
   * Function sets all filtered ReleaseItemIds to store variable,
   * and using debounce call endpoint for getting total overview
   * @param params = callback frm Ag-grid OnFilter method
   */
  public onFilterChanged = (params: FilterChangedEvent) => {
    this.filteredCategoryReleaseItemIds.clear();
    if (this.categoryId) {
      const filteredReleaseItemIds = [];
      params.api.forEachNodeAfterFilter((node: RowNode) =>
        filteredReleaseItemIds.push(node.data.ReleaseDetailCategory_id),
      );
      this.filteredCategoryReleaseItemIds.set(this.categoryId, filteredReleaseItemIds);
    } else {
      let categoriesIds: string[] = [];
      params.api.forEachNodeAfterFilter((node: RowNode) =>
        categoriesIds.push(node.data.ReleaseDetailCategory_categoryId),
      );
      categoriesIds = [...new Set(categoriesIds)];
      for (const categoryId of categoriesIds) {
        const filteredReleaseItemIds = [];
        params.api.forEachNodeAfterFilter((node: RowNode) => {
          if (node.data.ReleaseDetailCategory_categoryId === categoryId) {
            filteredReleaseItemIds.push(node.data.ReleaseDetailCategory_id);
          }
        });
        if (filteredReleaseItemIds.length) {
          this.filteredCategoryReleaseItemIds.set(categoryId, filteredReleaseItemIds);
        }
      }
    }
    this.updateSubTotalDebounce();
  };

  /** Fetching and Updating subtotal in Grid */
  private updateSubTotal = async () => {
    try {
      if (this.categoryId) {
        const filteredCategoryReleaseItemsIds: string[] = this.filteredCategoryReleaseItemIds.get(this.categoryId);
        if (
          (filteredCategoryReleaseItemsIds && filteredCategoryReleaseItemsIds.length) ||
          this.isVisibleAllProducts()
        ) {
          const subtotals = await this.releaseStore.getSubtotals(
            this.releaseId,
            this.categoryId,
            filteredCategoryReleaseItemsIds,
          );
          subtotals.forEach((subtotal) => {
            runInAction(() => {
              this.subtotals.set(`${subtotal.categoryId}_${subtotal.priceZoneId}`, this.getSubtotalRowData(subtotal));
            });
          });
        } else {
          this.subtotals.forEach((value, key) => {
            runInAction(() =>
              this.subtotals.set(key, {
                ...this.agGridServices.generators.totalOverviewGenerator.getColumnData({
                  name: (value as any).ReleaseDetailCategory_name,
                }),
              }),
            );
          });
        }
      } else {
        const promises = [];
        for (const categoryId of this.filteredCategoryReleaseItemIds.keys()) {
          const filteredCategoryId = this.filteredCategoryReleaseItemIds.get(categoryId);
          if (filteredCategoryId && filteredCategoryId.length) {
            const subtotalFilter = this.filteredCategoryReleaseItemIds.get(categoryId);
            promises.push(this.releaseStore.getSubtotals(this.releaseId, categoryId, subtotalFilter));
          }
        }
        if (promises.length) {
          await Promise.all(promises).then((responses) => {
            responses.forEach((subtotals) => {
              subtotals.forEach((subtotal) => {
                const subtotalRowData = this.getSubtotalRowData(subtotal);
                runInAction(() =>
                  this.subtotals.set(`${subtotal.categoryId}_${subtotal.priceZoneId}`, subtotalRowData),
                );
              });
            });
          });
        } else {
          this.subtotals.forEach((value, key) => {
            runInAction(() =>
              this.subtotals.set(key, {
                ...this.agGridServices.generators.totalOverviewGenerator.getColumnData({
                  name: (value as any).ReleaseDetailCategory_name,
                }),
              }),
            );
          });
        }
      }
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  /** Debounce for calling subTotal Endpoint */
  private updateSubTotalDebounce = debounce(this.updateSubTotal, 500);

  /**
   * Function fires on Comment Icon Click.
   * Sets initialValues for modal, and
   * opens modal for creating new comments
   */
  private handleCommentModalOpen = (releaseItemId: string, priceZoneId: string, comments: PriceComment[]) => () => {
    // LOG-5859
    if (this.release.state === ReleaseState.Released) {
      return;
    }
    runInAction(() => (this.comments = {releaseItemId, priceZoneId, comments}));
    this.openModal(DetailCategoryModalEnum.Comment);
  };

  /** @inheritDoc */
  public onImpactFilter = async (values: StringMapping<any>): Promise<void> => {
    try {
      const filter: IReleaseImpactFilter = {
        dateFrom: values.dateFrom,
        dateTo: values.dateTo,
      };

      runInAction(() => (this.impactFilter = filter));
      const items = await this.releaseStore.getItemsWithImpact(this.release.id, {
        categoryIds: [this.categoryId],
        ...this.impactFilter,
      });

      runInAction(() => (this.items = items));
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  /** @inheritDocs */
  @computed
  public get productIds() {
    return List(Array.from(this.items.values(), (item) => item.productId));
  }

  /** @inheritDocs */
  protected handleBasicRequest = async () => {
    try {
      await Promise.all([
        this.releaseStore.getOne(this.releaseId),
        this.productCategoryStore.loadForRelease(this.releaseId),
      ]);
      await this.handleDependentRequest();
      this.checkIfReleaseCanBeExported(this.items);
    } catch (error) {
      await Promise.reject(error);
    }
  };

  /** Request that depends on values from previous requests */
  private handleDependentRequest = async () => {
    /** Get data for all release items */
    const handleItemRequest = async () => {
      const categoryIds = this.categoryId ? [this.categoryId] : this.approvedCategories;
      const getItemsFilter = {categoryIds};
      const getItemsWithImpactFilter = {categoryIds, ...this.impactFilter};
      /** If release is released - data for impact report should be fetched */
      const items =
        this.release.state === ReleaseState.Released && this.impactFilter
          ? await this.releaseStore.getItemsWithImpact(this.release.id, getItemsWithImpactFilter)
          : await this.releaseStore.getItems(this.release.id, getItemsFilter);
      runInAction(() => (this.items = items));
      await this.fetchItemProducts();
    };

    try {
      await Promise.all([handleItemRequest(), this.agGridServices.load()]);
      await this.updateTotal(this.categoryId);
    } catch (error) {
      await Promise.reject(error);
    }
  };

  /**
   * Updates TotalOverview in loading or after grid values changed
   * @param categoryId
   */
  private async updateTotal(categoryId?: string): Promise<void> {
    const totalFilter = {releasePartCategoryIds: categoryId ? [categoryId] : this.approvedCategories};
    const getItemsWithImpactFilter = {...totalFilter, ...this.impactFilter};
    try {
      const totals =
        this.release.state === ReleaseState.Released && this.impactFilter
          ? await this.releaseStore.getTotalWithImpact(this.release.id, getItemsWithImpactFilter)
          : await this.releaseStore.getTotal(this.release.id, totalFilter);
      this.setTotals(totals);
    } catch (error) {
      // this.messages.setError(error);
    }
  }

  /**
   *  Sets impact records for totals and subtotals
   * @param totals - map of ReleaseTotalDTO with categoryId as key
   */
  private setTotals(totals: Map<string, ReleaseTotalDTO>) {
    totals.forEach((total, key) => {
      const category = this.productCategoryStore.getCategoryForRelease(this.releaseId, total.categoryId);
      if (category && category.level === this.configurationsStore.config.categoryLevel && !total.priceZoneId) {
        this.totalWithActions.set(key, total);
        const totalRowData = this.getTotalRowData(total, category.name);
        runInAction(() => (this.totals = new Map().set(key, totalRowData)));
      }
    });
  }

  /** Navigates to family page */
  private navigateToFamily = (params: CellClickedEvent) => {
    if (params.value) {
      window.open(params.value.linkProps.to.pathname + '?externalId=' + params.value.linkProps.to.externalId);
    }
  };
}


function alertsFilterValueGetter(resultObjects: List<ValidationResultObject>) {
  const messages: string[] = [];
  resultObjects.size > 0
    ? resultObjects.map((obj) =>
        messages.push(translate(`${obj.severity}_${obj.messageKey}`)),
      )
    : messages.push(translate(`BLANKS_FILTER`));
  return messages;
}

function alertsFilterComparator(a, b) {
  if (a === b) {
    return 0;
  }
  return a === translate(`BLANKS_FILTER`) ? -1 : 1;
}

function ydgAlertsValueGetter(params: ValueGetterParams) {
  // find all ydg_messages
  const dataKeys = Object.keys(params.data).filter(key => key.indexOf('_ydg_messages') !== -1);

  const resultObjects: List<ValidationResultObject> = dataKeys.reduce((acc, key) => acc.concat(params.data[key].resultObjects), List());

  // group YDG errors by type
  const ydgAlertsMap = IMap<string, ValidationResultObject>().asMutable();
  resultObjects.forEach(resultObject => {
    if (YDGOptimizationResultCode[resultObject.messageKey]) {
      if (ydgAlertsMap.has(resultObject.messageKey)) {
        const currentAlert = ydgAlertsMap.get(resultObject.messageKey);
        ydgAlertsMap.set(
          resultObject.messageKey,
          currentAlert.copy(
            'messageVariables',
            List(ISet(currentAlert.messageVariables.concat(resultObject.messageVariables))),
          ),
        );
      } else {
        ydgAlertsMap.set(resultObject.messageKey, resultObject);
      }
    }
  });

  // join message variables
  const ydgAlerts = List(ydgAlertsMap.values()).map(alert => alert.copy('messageVariables', List.of(alert.messageVariables.join(', '))));

  return new ValidationResultWrapper(ydgAlerts);
}
