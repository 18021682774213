/**
 * Divider can be rendered in many types
 */
export var DividerType;

(function (DividerType) {
  /** Content will be split vertically */
  DividerType["Vertical"] = "VERTICAL";
  /** Content will be split horizontally */

  DividerType["Horizontal"] = "HORIZONTAL";
  /** Vertical divider (|) for Status component  */

  DividerType["StatusVertical"] = "STATUS VERTICAL";
  /** Progress divider (>) for Status component  */

  DividerType["StatusProgress"] = "STATUS PROGRESS";
})(DividerType || (DividerType = {}));