/**
 * @file Created on Tue Feb 12 2019
 * @author SKu
 */

import {CodebookLookupSequence, DateParser, StructPropBuilder} from '@logio/common-be-fe';
import {
  BoxDefault,
  Field,
  Form,
  OptionsProvider,
  Page,
  SelectAdapter,
  SelectOptions,
  FormGroupInline,
  translate,
  Button,
  Icon,
  IconType,
  ButtonSize,
  AgGrid,
  LoadingState,
} from '@logio/common-fe';
import {Observer, observer} from 'mobx-react';
import * as React from 'react';
import {Col, Row, Grid} from 'react-flexbox-grid';
import {PageNamesEnum} from '../../shared/localization/PageNamesEnum';
import {PriceChangeReportPageStore} from '../../stores/pages/Reports/PriceChangeReportPageStore';

@observer
class PriceChangeReportPage extends React.Component {
  store = new PriceChangeReportPageStore();

  componentWillMount() {
    this.store.setLoadingState(LoadingState.Success);
  }

  render() {
    return (
      <Page titleKey={PageNamesEnum.PriceChangeAndStickersReport} loadingState={this.store.loadingState}>
        <Observer>
          {() => (
            <>
              <PriceChangeReportFilter store={this.store} />
              {this.store.priceChangeReportDTOs && (
                <AgGrid rowData={this.store.rowData} columnDefs={this.store.columnDefs} gridId="PriceChangeReport" />
              )}
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

export default PriceChangeReportPage;

@observer
class PriceChangeReportFilter extends React.Component<{store: PriceChangeReportPageStore}> {
  builder = new StructPropBuilder('PriceChangeReportFilter');
  get filterDescription() {
    return {
      dateFrom: this.builder.date('dateFrom', DateParser.ISO_PATTERN),
      dateTo: this.builder.date('dateTo', DateParser.ISO_PATTERN),
      categoryId: this.builder.lookup('categoryId', `${CodebookLookupSequence.Category}?level=5`),
      productIds: this.builder.arr(
        this.builder
          .lookup('productIds', `${CodebookLookupSequence.Product}?titleSpecification=NAME_WITH_EXTERNAL_ID`)
          .withDescription({mandatoryParameter: true}),
      ),
    };
  }
  render() {
    return (
      <BoxDefault className="mb2">
        <Form descriptions={this.filterDescription} onSubmit={this.props.store.onFind} formLayoutVertical>
          <Grid fluid>
            <Row>
              <Col xs={2}>
                <Field name="dateFrom" />
              </Col>
              <Col xs={2}>
                <Field name="dateTo" />
              </Col>
              <Col xs={3}>
                <OptionsProvider
                  name="categoryId"
                  component={(selectOptions: SelectOptions) => (
                    <Field
                      name="categoryId"
                      component={(props) => SelectAdapter('categoryId', selectOptions, false)(props)}
                    />
                  )}
                />
              </Col>
              <Col xs={4}>
                <OptionsProvider
                  name="productIds"
                  component={(selectOptions: SelectOptions) => (
                    <Field
                      fieldHide={{
                        categoryId: [null, undefined],
                      }}
                      name="productIds"
                      component={(props) => SelectAdapter('productIds', selectOptions, true)(props)}
                    />
                  )}
                  dependsOn={{categoryId: 'categoryId'}}
                />
              </Col>
              <Col xs={1}>
                <div className="mt1">
                  <Button iconRight>
                    {translate('Find')} <Icon iconType={IconType.search} />
                  </Button>
                </div>
              </Col>
            </Row>
          </Grid>
        </Form>
      </BoxDefault>
    );
  }
}
