/**
 * @file Created on Mon Jan 28 2019
 * @author SKu
 */

import { LoadingState, PageStore } from '@logio/common-fe';
import { SelloutSettingsComponentStore } from 'stores/components';

export class SelloutSettingsPageStore extends PageStore {
  selloutSettingsComponentStore = new SelloutSettingsComponentStore(this.messages);

  load = async (): Promise<void> => {
    await this.selloutSettingsComponentStore.load();
    this.setLoadingState(LoadingState.Success);
  };
}
