import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n\n  .sg-color-box { \n    width: 130px; \n    height: 130px; \n    line-height: 130px;\n    text-align: center;\n    font-size: 80%;\n    display: inline-block; \n    padding: 0 1rem;\n    margin: 0 1rem 1rem 0;\n    border: 1px solid #ddd;\n  }\n\n  .sg-aggrid-wrapper {\n    display: flex;\n    flex-direction: column;\n    /* height: calc(100vh - 60px); */\n    height: 400px;\n  }\n\n  .sg-aggrid-wrapper .ag-theme-balham {\n    background-color: transparent;\n    flex: 1;\n  }\n  \n  .sg-aggrid-json-formatter {\n    padding-left: 100px; \n  }\n\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { css } from 'styled-components';
export var styleguide = css(_templateObject());