/**
 * @file Created on Fri Oct 05 2018
 * @author SKu
 */

import {
  ReleaseState,
  ReleaseWorkflowType,
  SelloutRelease,
  Utils,
  SelloutReleaseType,
  DateOlderThanValidator,
  DateYoungerThanValidator,
  IntegerPositiveValidator,
  SelloutReleaseMinMarginChangeValidator,
  NumberInRangeValidator,
} from '@logio/common-be-fe';
import {
  BoxStatus,
  Field,
  getPath,
  translate,
  getDateFormat,
  IconColor,
  IconType,
  Icon,
  Tooltip,
  Form,
  Modal,
  FormGroupInline,
  Button,
  ButtonColor,
  WithPermission,
  CONSTANTS,
  DatePickerInputAdapter,
  StringMapping,
  ValidationUtils,
  MultiInput,
} from '@logio/common-fe';
import { FieldRenderProps, FormSpy, FormSpyRenderProps } from 'react-final-form';
import { observer } from 'mobx-react';
import * as React from 'react';
import { withRouter } from 'react-router';
import { PagePathsEnum } from '../../../shared/localization/PagePathsEnum';
import { ReleaseSelloutHeaderStore } from '../../../stores/components';
import AbstractReleaseHeader from './AbstractReleaseHeader';
import { Col, Row } from 'react-flexbox-grid';
import { computed } from 'mobx';
import { DayPickerProps } from 'react-day-picker';
import moment from 'moment';

/** Release Header, used on every release page */
@observer
class ReleaseSelloutHeader extends AbstractReleaseHeader<ReleaseSelloutHeaderStore> {
  /** @inheritDoc */
  public get release(): SelloutRelease {
    return this.props.store.release;
  }

  /** @inheritDoc */
  public get tooltipInfo() {
    return (
      <>
        {this.getTooltipItem(['workflowType'])}
        {this.getTooltipItem(['releaseType'])}
        {this.getTooltipItem(['priceListType'])}
        {this.getTooltipItem(['pricingType'])}
        {this.getTooltipItem(['totalPriceChangeLimit'])}
        {this.getTooltipItem(['minPriceChangeInPercent'])}
        {this.getTooltipItem(['maxPriceChangeInPercent'])}
        {this.getTooltipItem(['minMarginChangeInPercent'])}
        {this.getTooltipItem(['priceValidityStartDate'])}
        {this.getTooltipItem(['endDate'])}
        {this.getTooltipItem(['itemFilter', 'endDateFrom'])}
        {this.getTooltipItem(['itemFilter', 'endDateTo'])}
        {this.getTooltipItem(['exported'])}
        {this.getTooltipItem(['description'])}
      </>
    );
  }

  /** @inheritDoc */
  public get formContent() {
    const dateComponent = ({ values }: FormSpyRenderProps, label: string) => {
      return (props: FieldRenderProps) => {
        const getDate = (value: moment.Moment) => (value ? value.toDate() : undefined);
        const originDateFrom = getDate(values.originDateFrom);
        // const originDateTo = getDate(values.originDateTo);

        const options: DayPickerProps = {
          month: originDateFrom,
          fromMonth: originDateFrom,
          // toMonth: originDateTo,
          disabledDays: {
            before: originDateFrom,
            // after: originDateTo,
          },
        };

        return DatePickerInputAdapter(label, false, options, true, '', CONSTANTS.FORMAT.DATE)(props);
      };
    };
    return (
      <>
        <Field name="name" />
        <Field name="priceListTypeId" />

        <Field
          name="totalPriceChangeLimit"
          validate={(value: StringMapping<any>, formValues: StringMapping<any>) => {
            return ValidationUtils.validateField(value, formValues, [
              new IntegerPositiveValidator(true, 'TOTAL_PRICE_CHANGE_NEGATIVE'),
            ]);
          }}
        />
        <Field
          name="minPriceChangeInPercent"
          validate={(value: StringMapping<any>, formValues: StringMapping<any>) => {
            return ValidationUtils.validateField(value, formValues, [
              new NumberInRangeValidator(0, formValues.maxPriceChangeInPercent || 100),
            ]);
          }}
        />
        <Field
          name="maxPriceChangeInPercent"
          validate={(value: StringMapping<any>, formValues: StringMapping<any>) => {
            return ValidationUtils.validateField(value, formValues, [
              new NumberInRangeValidator(formValues.minPriceChangeInPercent || 0, 100),
            ]);
          }}
        />
        <Field
          name="minMarginChangeInPercent"
          validate={(value: StringMapping<any>, formValues: StringMapping<any>) => {
            return ValidationUtils.validateField(value, formValues, [new SelloutReleaseMinMarginChangeValidator()]);
          }}
        />
        <FormSpy subscription={{ values: true }}>
          {(spyProps: FormSpyRenderProps) => (
            <Field
              name="priceValidityStartDate"
              component={dateComponent(spyProps, 'priceValidityStartDate')}
              validate={(value: StringMapping<any>, formValues: StringMapping<any>) => {
                return ValidationUtils.validateField(value, formValues, [
                  new DateOlderThanValidator(
                    formValues.cmDeadline,
                    false,
                    true,
                    CONSTANTS.FORMAT.DATE,
                    'START_DATE_BEFORE_CM_DEADLINE',
                  ),
                ]);
              }}
            />
          )}
        </FormSpy>

        <FormSpy subscription={{ values: true }}>
          {(spyProps: FormSpyRenderProps) => (
            <Field
              name="cmDeadline"
              component={dateComponent(spyProps, 'cmDeadline')}
              validate={(value: StringMapping<any>, formValues: StringMapping<any>) => {
                return ValidationUtils.validateField(value, formValues, [
                  new DateYoungerThanValidator(
                    formValues.priceValidityStartDate,
                    false,
                    true,
                    CONSTANTS.FORMAT.DATE,
                    'CM_DEADLINE_AFTER_START_DATE',
                  ),
                ]);
              }}
            />
          )}
        </FormSpy>
        <FormSpy subscription={{ values: true }}>
          {(spyProps: FormSpyRenderProps) => (
            <Field
              name="endDate"
              component={dateComponent(spyProps, 'endDate')}
              validate={(value: StringMapping<any>, formValues: StringMapping<any>) => {
                return ValidationUtils.validateField(value, formValues, [
                  new DateOlderThanValidator(
                    formValues.priceValidityStartDate,
                    true,
                    true,
                    CONSTANTS.FORMAT.DATE,
                    'END_DATE_BEFORE_START_DATE',
                  ),
                ]);
              }}
            />
          )}
        </FormSpy>
        <Field name="description" />
      </>
    );
  }

  /** @inheritDoc */
  public get detailPath() {
    return getPath(PagePathsEnum.ReleaseSelloutDetail, this.release.id);
  }

  public get detailCategoryPath() {
    return getPath(PagePathsEnum.ReleaseSelloutDetailCategory, this.release.id);
  }

  get settingsPath() {
    return getPath(PagePathsEnum.ReleaseSelloutSettings, this.release.id);
  }

  get exportPath() {
    return getPath(PagePathsEnum.ReleaseSelloutExport, this.release.id);
  }

  get settingsTooltipContent() {
    return (
      <Form descriptions={this.props.store.settingsTooltipDescriptions} initialValues={this.props.store.initialValues}>
        <Row>
          <Col xs={6}>
            <Field name="zoneId" fieldReadOnly />

            <Field name="siteIds" className="Select-max-4-lines" fieldReadOnly />

            <Field name="weighted" fieldReadOnly />

            {(
              this.props.store.release.selloutReleaseType === SelloutReleaseType.ExitRegularDelist
              || this.props.store.release.selloutReleaseType === SelloutReleaseType.ExitInOutDelist
            ) && (
              <Field name="returnable" fieldReadOnly />
            )}

            <Field
              name="productExternalIds"
              /* If you want to change height, you must set it in common-fe/src/components/inputs/Select/styled/SelectStyled.tsx */
              component={(props: any) => (
                <MultiInput
                  className="Select-max-4-lines"
                  labelTooltipText={translate('SELLOUT_IDENTIFICATION_FILTER_TOOLTIP_PRODUCTS')}
                  fieldReadOnly
                  {...props}
                />
              )}
            />
          </Col>{' '}
          <Col xs={6}>
            <Field name="unitsPerSite" fieldReadOnly />

            <Field name="stockValue" fieldReadOnly />
            <Field name="selloutVolume" fieldReadOnly />
            {this.release.selloutReleaseType === SelloutReleaseType.EndangeredExpiry && (
              <Field name="daysToExpiration" fieldReadOnly />
            )}

            <Field name="futurePromoInWeeks" fieldReadOnly />
          </Col>
        </Row>
      </Form>
    );
  }

  /* Important info  */
  private get endDateRenderer() {
    if (Utils.isValueMissing(this.release.endDate)) {
      return null;
    }
    return (
      <div className="status-bar-item">
        <span>
          <span className="status-bar-label"> {translate('SelloutRelease_endDate')}</span>
          <span className="status-bar-value">
            <strong>{this.release.endDate.format(getDateFormat())}</strong>
          </span>
        </span>
      </div>
    );
  }

  private get settingsIconRenderer() {
    return (
      this.release.workflowType !== ReleaseWorkflowType.SelloutUrgent &&
      (this.release.state === ReleaseState.New && this.props.location.pathname !== this.settingsPath ? (
        <div className="status-bar-item">
          <Tooltip
            overlay={this.settingsTooltipContent}
            placement="bottomLeft"
            children={
              <div>
                <Icon
                  iconColor={IconColor.Text}
                  iconType={IconType.settings}
                  onClick={() => this.props.store.cancellSimulation(this.historyPush(this.settingsPath))}
                />
              </div>
            }
          />
        </div>
      ) : (
          <div className="status-bar-item">
            <Tooltip
              overlay={this.settingsTooltipContent}
              placement="bottomLeft"
              children={
                <div>
                  <Icon iconColor={IconColor.Text} iconType={IconType.settings} />
                </div>
              }
            />
          </div>
        ))
    );
  }

  public get editIconRenderer() {
    return (
      <WithPermission permissionTypes={this.editHeaderPermissions}>
        <div className="status-bar-item">
          <Icon
            iconType={IconType.edit}
            onClick={this.props.store.openModal}
            iconColor={IconColor.Text}
            disabled={
              this.release.state === ReleaseState.Computing ||
              (this.release.state === ReleaseState.New && this.props.location.pathname === this.settingsPath)
            }
          />
        </div>
      </WithPermission>
    );
  }

  @computed
  public get headerEditModal() {
    const { store } = this.props;
    return (this.release.state === ReleaseState.New || this.release.state === ReleaseState.Reopening) &&
      this.props.location.pathname !== this.settingsPath ? (
        <Modal title={translate(`recalculate-release-header`)} hidden={store.modalHidden} close={store.hideModal}>
          <Form
            onSubmit={store.onRecalculateHeaderSubmit}
            descriptions={store.descriptions}
            customDefinition={store.customDefinition}
            initialValues={store.release}
          >
            {this.formContent}
            <FormGroupInline right>
              <Button type="submit">{translate('recalculate-release-header-button')}</Button>
            </FormGroupInline>
          </Form>
        </Modal>
      ) : (
        <Modal title={translate(`edit-release-header`)} hidden={store.modalHidden} close={store.hideModal}>
          <Form
            onSubmit={store.onEditHeaderSubmit}
            descriptions={store.descriptions}
            customDefinition={store.customDefinition}
            initialValues={store.release}
          >
            {this.formContent}
            <FormGroupInline right>
              <Button type="button" buttonColor={ButtonColor.Light} onClick={store.hideModal}>
                {translate('Cancel')}
              </Button>
              <Button type="submit">{translate('Save')}</Button>
            </FormGroupInline>
          </Form>
        </Modal>
      );
  }

  render() {
    return (
      <>
        {this.props.store.downloadStore.renderModal()}
        {this.headerEditModal}

        <BoxStatus>
          {this.releaseStateRenderer}
          {this.divider}
          {this.releaseFavoriteRenderer}
          {this.divider}
          {this.releaseInfoRenderer}
          {this.editIconRenderer}
          {this.settingsIconRenderer}
          {this.detailIconRenderer}
          {this.exportIconRenderer}
          {this.divider}
          {this.cmDeadlineRenderer}
          {this.divider}
          {this.priceValidityStartDateRenderer}
          {this.divider}
          {this.endDateRenderer}
          {this.divider}
          {this.lastModifiedRenderer}
          {this.divider}
          {this.customItemsRenderer}
        </BoxStatus>
      </>
    );
  }
}

export default withRouter(ReleaseSelloutHeader);
