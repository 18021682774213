import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  \n  textarea.input-error {\n    border: 1px solid ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Tue Aug 14 2018
 * @author SKu
 */
import styled, { withTheme } from 'styled-components';
var TextAreaWrapper = styled.div(_templateObject(), function (props) {
  return props.theme.state.danger.color;
});
export default withTheme(TextAreaWrapper);