"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Enum of possible errors that may occur during import.
 * @author OPt
 */

var WindowImportErrorEnum;

(function (WindowImportErrorEnum) {
  // parse error
  WindowImportErrorEnum["APPLICATION_MIME"] = "file-mime-type";
  WindowImportErrorEnum["APPLICATION_FILE_SIZE"] = "file-too-large";
  WindowImportErrorEnum["APPLICATION_FILE_EMPTY"] = "file-empty";
  WindowImportErrorEnum["APPLICATION_FILE_TOO_MANY_ROWS"] = "file-too-many-rows";
  WindowImportErrorEnum["APPLICATION_FILE_STRUCTURE_COLUMNS"] = "invalid-columns"; // validation error

  WindowImportErrorEnum["VALIDATION_REQUIRED"] = "REQUIRED";
  WindowImportErrorEnum["VALIDATION_INVALID"] = "INVALID";
  WindowImportErrorEnum["VALIDATION_NOT_ALLOWED"] = "NOT_ALLOWED";
  WindowImportErrorEnum["VALIDATION_NOT_FOUND"] = "NOT_FOUND"; // validation flags

  WindowImportErrorEnum["ITEM_PROMOTION_GOS_NO_OVERLAP"] = "ITEM_PROMOTION_GOS_NO_OVERLAP";
  WindowImportErrorEnum["VALUE_MISSING_POS_ATTRIBUTES"] = "VALUE_MISSING_POS_ATTRIBUTES";
  WindowImportErrorEnum["PRODUCT_INACTIVE"] = "PRODUCT_INACTIVE";
  WindowImportErrorEnum["PRODUCT_OVERLAPPING_PROMO"] = "PRODUCT_OVERLAPPING_PROMO";
  WindowImportErrorEnum["SUPPLIER_NOT_PREFERRED"] = "SUPPLIER_NOT_PREFERRED";
  WindowImportErrorEnum["PROMOTION_FORBIDEN_WF_STATE"] = "PROMOTION_FORBIDEN_WF_STATE";
  WindowImportErrorEnum["SITE_NOT_FOUND"] = "SITE_NOT_FOUND";
  WindowImportErrorEnum["INCOMPLETE_UNIT_BACK_DISCOUNT"] = "INCOMPLETE_UNIT_BACK_DISCOUNT";
  WindowImportErrorEnum["VALUE_MISSING_DIRECT_DELIVERY_SITES"] = "VALUE_MISSING_DIRECT_DELIVERY_SITES";
  WindowImportErrorEnum["PRODUCT_RETURNABLE_INCOMPLETE"] = "PRODUCT_RETURNABLE_INCOMPLETE";
  WindowImportErrorEnum["PRODUCT_IN_DIFFERENT_CATEGORY"] = "PRODUCT_IN_DIFFERENT_CATEGORY";
  WindowImportErrorEnum["DIRECT_DELIVERY_PROMOTION_GOS_NO_OVERLAP"] = "DIRECT_DELIVERY_PROMOTION_GOS_NO_OVERLAP";
})(WindowImportErrorEnum = exports.WindowImportErrorEnum || (exports.WindowImportErrorEnum = {}));