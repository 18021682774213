import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  padding: ", "rem;\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: ", ";\n  margin-bottom: ", "rem;\n\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  padding: ", "rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
import styled, { css, withTheme } from 'styled-components';
var BoxDashboard = css(_templateObject(), function (_ref) {
  var theme = _ref.theme;
  return theme.spacing.spacer;
});
var BoxDefaultStyled = styled.div(_templateObject2(), function (_ref2) {
  var theme = _ref2.theme;
  return theme.spacing.spacer;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.paper;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.border;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.common.borderRadius;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.spacing.spacer;
}, function (_ref7) {
  var boxDashboard = _ref7.boxDashboard;
  return boxDashboard ? BoxDashboard : null;
});
export default withTheme(BoxDefaultStyled);