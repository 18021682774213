"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var DateParser_1 = require("../../core/model/data-dictionary/parsers/DateParser");

var StructPropBuilder_1 = require("../../core/model/data-dictionary/StructPropBuilder");

var CompetitorPriceIndex_1 = require("./CompetitorPriceIndex");
/**
 * Information about price index for given product and week.
 *
 * @author Michal Sevcenko
 */


var PriceIndexRecord = function PriceIndexRecord(dateFrom, arpRpIndexUnweighted, arpRpIndexBlended, arpRpIndexUnblended, arpIndexUnweighted, arpIndexBlended, arpIndexUnblended, crpIndices) {
  _classCallCheck(this, PriceIndexRecord);

  this.dateFrom = dateFrom;
  this.arpRpIndexUnweighted = arpRpIndexUnweighted;
  this.arpRpIndexBlended = arpRpIndexBlended;
  this.arpRpIndexUnblended = arpRpIndexUnblended;
  this.arpIndexUnweighted = arpIndexUnweighted;
  this.arpIndexBlended = arpIndexBlended;
  this.arpIndexUnblended = arpIndexUnblended;
  this.crpIndices = crpIndices;
};

exports.PriceIndexRecord = PriceIndexRecord;

PriceIndexRecord.schema = function () {
  var builder = new StructPropBuilder_1.StructPropBuilder(PriceIndexRecord.name);
  return {
    dateFrom: builder.date('dateFrom', DateParser_1.DateParser.ISO_PATTERN),
    arpRpIndexUnweighted: builder.bigNum('arpRpIndexUnweighted'),
    arpRpIndexBlended: builder.bigNum('arpRpIndexBlended'),
    arpRpIndexUnblended: builder.bigNum('arpRpIndexUnblended'),
    arpIndexUnweighted: builder.bigNum('arpIndexUnweighted'),
    arpIndexBlended: builder.bigNum('arpIndexBlended'),
    arpIndexUnblended: builder.bigNum('arpIndexUnblended'),
    crpIndices: builder.listOf(builder.obj('crpIndices', CompetitorPriceIndex_1.CompetitorPriceIndex.schema, function (mat) {
      return new CompetitorPriceIndex_1.CompetitorPriceIndex(mat.competitorId, mat.index);
    }))
  };
}();