/**
 * @file Created on Thu Jun 27 2019
 * @author SKu
 */

import {AbstractRelease, ReleaseType, ReleaseState} from '@logio/common-be-fe';
import {Circle, CircleWidth, getDateFormat, getPath, IconType, Notice, NoticeTitles, translate} from '@logio/common-fe';
import moment from 'moment';
import * as React from 'react';
import {PagePathsEnum} from '../../shared/localization/PagePathsEnum';
import {ReleaseWidgetStore} from '../../stores/components';

export interface Props {
  store: ReleaseWidgetStore;
}

export const ReleaseWidget: React.FC<Props> = ({store}: Props) => {
  const pushToReleaseDetail = ({releaseType, id: releaseId, state: releaseState}: AbstractRelease) => () => {
    store.history.push(
      getPath(
        releaseType === ReleaseType.Regular 
          ? releaseState === ReleaseState.New 
            ? PagePathsEnum.ReleaseSettings 
            : PagePathsEnum.ReleaseDetail 
          : PagePathsEnum.ReleaseSelloutDetail,
        releaseId,
      ),
    );
  };

  const getReleaseNameComponent = ({name, state}: AbstractRelease) => (
    <>
      <Circle color={state} width={CircleWidth.Normal} className="mr1" /> {name}
    </>
  );

  const getCmDeadLineComponent = ({cmDeadline}: AbstractRelease) =>
    cmDeadline && (
      <div className={cmDeadline.isBefore(moment(), 'day') ? 'c-error' : undefined}>
        {cmDeadline.format(getDateFormat())}
      </div>
    );

  const getSingleNotice = (release: AbstractRelease): JSX.Element => (
    <Notice
      key={release.id}
      contentSizes={[7, 3, 2]}
      content={[getReleaseNameComponent(release), release.releaseType, getCmDeadLineComponent(release)]}
      noticeIconType={IconType.release}
      onIconClick={pushToReleaseDetail(release)}
    />
  );

  return (
    <>
      <NoticeTitles
        titles={[translate('releases-dashboard'), translate('releaseState'), translate('cmDeadline')]}
        titleSizes={[7, 3, 2]}
        noticeWithIcon
      />
      <div className="ofa" style={{maxHeight: '310px'}}>
        {Array.from(store.list.values()).map((release) => getSingleNotice(release))}
      </div>
    </>
  );
};
