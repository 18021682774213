import React, { useEffect } from 'react';
import { OptionsProvider, Field, SelectOptions, SelectAdapter, rootStore, StoreName, PriceZoneStore } from '@logio/common-fe';

export interface PriceZoneFieldProps {
  name: string;
  tooltip?: string;
  label?: string;
}

/**
 * Field to display price zone in select box, when cleared, national zone is selected.
 * 
 * @param props
 */
export function PriceZoneField(props: PriceZoneFieldProps) {
  const priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;

  useEffect(() => {
    if (priceZoneStore.list.size === 0) {
      priceZoneStore.getAll();
    }
  }, [priceZoneStore]);

  return (
    <OptionsProvider
      name={props.name}
      component={(options: SelectOptions) => (
        <Field
          name={props.name}
          component={(fieldProps) =>
            SelectAdapter(
              props.label || props.name,
              options,
              false,
              false,
              false,
              undefined,
              undefined,
              undefined,
              props.tooltip,
            )({
              ...fieldProps,
              input: {
                ...fieldProps.input,
                onChange: (val) => {
                  if (!val) {
                    if (priceZoneStore.nationalZone) {
                      fieldProps.input.onChange(priceZoneStore.nationalZone.id);
                      return;
                    }
                  }
                  fieldProps.input.onChange(val);
                }
              },
            })
          }
        />
      )}
    />
  );
}
