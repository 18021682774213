"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Enum of acceptable POS multibuy variant.
 * @author ZSr
 */

var MultiBuyVariantEnum;

(function (MultiBuyVariantEnum) {
  MultiBuyVariantEnum["OnePlusOne"] = "1+1";
  MultiBuyVariantEnum["TwoPlusOne"] = "2+1";
  MultiBuyVariantEnum["ThreePlusOne"] = "3+1";
  MultiBuyVariantEnum["FourPlusTwo"] = "4+2";
})(MultiBuyVariantEnum = exports.MultiBuyVariantEnum || (exports.MultiBuyVariantEnum = {}));