import { AgGrid, CONSTANTS, Icon, IconColor, IconType, Page, ProgressMonitorBar, translate } from '@logio/common-fe';
import { Observer } from 'mobx-react';
import React from 'react';
import { PageNamesEnum } from '../../../shared/localization/PageNamesEnum';
import { SelloutTerminationReportPageStore } from '../../../stores/pages/Reports/SelloutTerminationReport/SelloutTerminationReportPageStore';
import SelloutTerminationReportFilter from './SelloutTerminationReportFilter';

export default function SelloutTerminationReportPage() {
  const store = React.useMemo(() => new SelloutTerminationReportPageStore(), []);

  return (
    <Observer>
      {() => (
        <Page titleKey={PageNamesEnum.SelloutTerminationReport} loadingState={store.loadingState}>
          {store.downloadStore.renderModal()}
          {store.exportInProgress && (
            <div className="mb2">
              <ProgressMonitorBar
                progressMonitorId={store.exportProgressMonitorId}
                label="export-loading-progress"
                onSuccess={store.downloadExportedFile}
                onEnd={store.finishExport}
                alwaysVisible
              />
            </div>
          )}
          <SelloutTerminationReportFilter
            initialValues={store.defaultFilter}
            onSubmit={store.findReport}
            exportPossible={store.reportVisible && !store.exportInProgress}
            onExport={store.exportReport}
            hidden={!store.filterVisible}
            validateProducts={store.productStore.validate}
          />
          {store.reportVisible && (
            <>
              <div className="tac pointer mb1 fs-90 c-secondary" onClick={store.toggleFilter}>
                <Icon
                  iconHeight={15}
                  iconColor={IconColor.Secondary}
                  iconType={store.filterVisible ? IconType.chevronUp : IconType.chevronDown}
                />
                {" "}
                <span className="underline">
                  {translate(store.filterVisible ? "hide-filters" : "show-filters")}
                </span>
              </div>
              <AgGrid
                columnDefs={store.reportColumnDefs}
                gridId="SelloutTerminationReportPage"
                serverSideDatasource={store.dataSource}
                onGridReady={store.onGridReady}
                rowModelType="serverSide"
                pagination
                internalPagination
                paginationPageSize={CONSTANTS.SELLOUT_TERMINATION_REPORT.PAGE_SIZE}
                cacheBlockSize={CONSTANTS.SELLOUT_TERMINATION_REPORT.PAGE_SIZE}
                quickView
                /*excelExportParams={{
                  type: 'custom',
                  fileName: 'Sellout Identification Report',
                  getRowDataToExport: this.store.getDataToExport,
                }}*/
              />
            </>
          )}
        </Page>
      )}
    </Observer>
  );
}
