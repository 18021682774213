/**
 * @file Data cleaning page
 * @author VBr
 */

import {PricingPermissions} from '@logio/common-be-fe';
import {
  AgGrid,
  AgGridWrapper,
  Button,
  ButtonSize,
  FormGroupInline,
  getPath,
  GroupActions,
  Label,
  Page,
  translate,
  WithPermission,
  ButtonLoading,
  Tooltip,
  Icon,
  IconType, ProgressBar, LoadingState,
} from '@logio/common-fe';
import {History} from 'history';
import {Observer, observer} from 'mobx-react';
import * as React from 'react';
import {Col, Row} from 'react-flexbox-grid';
import {RouteComponentProps, withRouter} from 'react-router';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {DataCleaningCategoriesPageStore} from '../../../stores/pages/DataManagement/DataCleaning/DataCleaningCategoriesPageStore';

export interface Props extends RouteComponentProps<any> {
  history: History;
}

@observer
class DataCleaningCategoriesPage extends React.Component<Props> {
  store = new DataCleaningCategoriesPageStore();

  componentWillMount() {
    this.store.load();
  }

  render() {
    const pushToReport = () => this.props.history.push(getPath(PagePathsEnum.AgencyQualityEvaluationReport));
    return (
      <Page titleKey={PageNamesEnum.DataCleaningCategories} loadingState={this.store.loadingState}>
        {/* This will render downloading modal when exported file is ready, initialized by store.export() */}
        {this.store.downloadStore.renderModal()}
        <Observer>
          {() => (
            <AgGridWrapper>
              {this.store.exportPollingHelper && (this.store.exportPollingHelper.pollingState === LoadingState.Pending) && (
              <div className="mb2">
                <ProgressBar
                  percentage={this.store.exportPollingHelper.progress || 0}
                  label={'export-loading-progress'}
                />
              </div>
              )}
              <Row>
                <Col xs md={4}>
                  <WithPermission permissionTypes={[PricingPermissions.DATA_CLEAN_EDIT]}>
                    <GroupActions selectedRowsCount={this.store.selectedCategories.length}>
                      {({disabled}) => (
                        <Button
                          buttonSize={ButtonSize.Tiny}
                          onClick={this.store.onApprove}
                          disabled={disabled || this.store.cleaningInProgress}
                        >
                          {translate('data_cleaning-approve_button')}
                        </Button>
                      )}
                    </GroupActions>
                  </WithPermission>
                </Col>
                <Col xs md={8}>
                  <FormGroupInline right>
                    <Label>{this.store.cleaningProgress}</Label>
                    <WithPermission permissionTypes={[PricingPermissions.DATA_CLEAN_AI_START]}>
                      <Button buttonSize={ButtonSize.Tiny} onClick={this.store.startCleaning}
                              disabled={this.store.cleaningInProgress}>
                        {translate('data_cleaning-start_ai_cleaning_button')}
                      </Button>
                    </WithPermission>
                    <Button buttonSize={ButtonSize.Tiny} onClick={pushToReport}>
                      {translate('data_cleaning-agency_quality_evaluation_report_button')}
                    </Button>
                    <Tooltip
                      overlay={translate('DataCleaning_ExportTooltip')}
                      placement="bottom"
                      noHeight
                    >
                      <div>
                        <ButtonLoading
                          buttonSize={ButtonSize.Tiny}
                          isLoading={this.store.exportIsLoading}
                          onClick={() => this.store.export()}
                        >
                          <Icon iconType={IconType.exportToExcel}/>
                        </ButtonLoading>
                      </div>
                    </Tooltip>
                  </FormGroupInline>
                </Col>
              </Row>
              <AgGrid
                rowData={this.store.CategoryTraverseHelper.treeData}
                columnDefs={this.store.columnDefs}
                autoGroupColumnDef={this.store.CategoryTraverseHelper.categoryAutoGroupColumnDef}
                getDataPath={this.store.CategoryTraverseHelper.getDataPath}
                isRowSelectable={this.store.isRowSelectable}
                onGridReady={this.store.onGridReady}
                rowSelection="multiple"
                treeData
                gridId="PricingDataCleaningCategoriesPage"
                quickView
                rememberGroupStateWhenNewData
                saveTreeDataExpansionId="CategoriesTreeExpanded"
                aggFuncs={{stars: (values) => values.some((value) => value)}}
                groupDefaultExpanded={3}
              />
            </AgGridWrapper>
          )}
        </Observer>
      </Page>
    );
  }
}

export default withRouter(DataCleaningCategoriesPage);
