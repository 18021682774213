import { Button, ButtonColor, ConfirmBox, Icon, IconColor, IconType, translate } from '@logio/common-fe';
import React, { useCallback, useState } from 'react';

interface RecalculationButtonProps {
  /**
   * Trigger recalculation process.
   */
  recalculate(manualPrices: boolean): void;
}

export function RecalculationButton(props: RecalculationButtonProps) {
  const [manualPrices, setManualPrices] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleClick = useCallback(() => {
    if (!manualPrices) {
      setShowConfirmModal(true);
    } else {
      props.recalculate(manualPrices);
    }
  }, [manualPrices, props.recalculate, setShowConfirmModal]);

  return (
    <>
      <ConfirmBox
        hidden={!showConfirmModal}
        title={translate('DISCARD_MANUAL_PRICES_HEADER')}
        onAccept={() => {
          setShowConfirmModal(false);
          props.recalculate(false);
        }}
        close={() => setShowConfirmModal(false)}
        children={<div className="mb1">{`${translate('DISCARD_MANUAL_PRICES_DESCRIPTION')}`}</div>}
        confirmButtonLabel={'CONFIRM'}
        discardButtonLabel={'CANCEL'}
      />
      <Icon iconType={IconType.calc} iconColor={IconColor.Text} onClick={handleClick} />
      <Button buttonColor={ButtonColor.TransparentDark} onClick={() => setManualPrices(!manualPrices)}>
        {translate('MANUAL_PRICES')}
        <Icon
          iconType={manualPrices ? IconType.checkboxChecked : IconType.checkboxUnchacked}
          viewBox={17}
        />
      </Button>
    </>
  );
}
