/**
 * @file Created on Thu Oct 11 2018
 * @author BPo
 * @author SKu
 */
import { ReleaseType } from '@logio/common-be-fe';
import {
  BoxForm,
  BoxFormContent,
  getPath,
  Modal,
  Page,
  ProgressBar,
  translate,
} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import DataUploadResult from '../../components/DataUploadResult';
import SelloutSettingsComponent from '../../components/SettingsOverview/SelloutSettingsComponent';
import {PageNamesEnum} from '../../shared/localization/PageNamesEnum';
import {PagePathsEnum} from '../../shared/localization/PagePathsEnum';
import {ReleaseNewModalEnum, ReleaseNewPageStore} from '../../stores/pages/Release/ReleaseNewPageStore';
import { ReleaseCreateForm } from './ReleaseCreateForm';

interface ReleaseNewPageProps extends RouteComponentProps<{}> {
  releaseTypes?: ReleaseType[];
  backButtonPath?: string;
}

/** Release new page */
@observer
class ReleaseNewPage extends React.Component<ReleaseNewPageProps> {
  static defaultProps = {
    releaseTypes: Object.keys(ReleaseType).map(key => ReleaseType[key]),
  }

  store = new ReleaseNewPageStore(this.props.history, this.props.releaseTypes);

  componentWillMount() {
    this.store.load();
  }

  render() {
    return (
      <Page
        titleKey={PageNamesEnum.ReleaseNew}
        backButtonPath={this.props.backButtonPath}
        loadingState={this.store.loadingState}
      >
        <>
          {this.store.productPricesUpload && <DataUploadResult uploadedData={this.store.productPricesUpload} />}
          <BoxForm>
            <BoxFormContent>
              <ReleaseCreateForm store={this.store} />
            </BoxFormContent>
          </BoxForm>
          <Modal
            title={translate('new-release-sellout-description')}
            hidden={this.store.isModalHidden(ReleaseNewModalEnum.SelloutDescription)}
            close={this.store.hideModal}
            wide
          >
            <SelloutSettingsComponent store={this.store.selloutSettingsStore} />
          </Modal>
          <Modal
            title={translate('new_release-price_upload-modal')}
            hidden={this.store.isModalHidden(ReleaseNewModalEnum.PriceUploadProgress)}
          >
            <ProgressBar
              percentage={this.store.pollingHelper.progress || this.store.siteReleasePollingHelper.progress || 0}
              label={'new_release-price_upload-in_progress'}
            />
          </Modal>
        </>
      </Page>
    );
  }
}

export default withRouter(ReleaseNewPage);
