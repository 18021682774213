/**
 * @file Created on Mon Oct 08 2018
 * @author LZe
 */
import * as React from 'react';
import translate from '../../i18n/translate';
import { IconType } from '../../media/Icon';
import Input from '../Input';
/**
 * Search component, shows proper search and reset icons in the input field
 */

var Search = function Search(_ref) {
  var value = _ref.value,
      onChange = _ref.onChange,
      onReset = _ref.onReset,
      _ref$placeholder = _ref.placeholder,
      placeholder = _ref$placeholder === void 0 ? "".concat(translate('Search'), "...") : _ref$placeholder,
      title = _ref.title,
      onKeyPress = _ref.onKeyPress,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === void 0 ? false : _ref$disabled;
  return React.createElement(Input, {
    disabled: disabled,
    title: title,
    withIconIn: true,
    onKeyPress: onKeyPress,
    placeholder: placeholder,
    inputIconType: value.length > 0 ? IconType.cancelO : IconType.search,
    value: value,
    onChange: onChange,
    onIconClick: value.length > 0 ? onReset : undefined
  });
};

export default Search;