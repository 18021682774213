/**
 * @file 404 Not found page
 * @author VBr
 */

import * as React from 'react';
import {Page, LoadingState, Alert, translate, AlertType} from '@logio/common-fe';
import {match} from 'react-router';
import {PageNamesEnum} from '../../shared/localization/PageNamesEnum';

export interface Props {
  match: match<{releaseId: string}>;
}

const NotFound: React.SFC<Props> = (props) => {
  return (
    <Page titleKey={PageNamesEnum.NotFound} loadingState={LoadingState.Success}>
      <>
        <Alert type={AlertType.Error}>
          {props.match.url} - {translate('not-found-error')}
        </Alert>
      </>
    </Page>
  );
};
export default NotFound;
