"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Message keys related to AbstractRelease.
 */

var ReleaseMessageCode;

(function (ReleaseMessageCode) {
  /**
   * The total price change limit was exceeded.
   */
  ReleaseMessageCode["TotalPriceChangeLimitExceeded"] = "TotalPriceChangeLimitExceeded";
  /**
   * There are not cleaned/validated data. Product has not cleaned competitor prices.
   */

  ReleaseMessageCode["CompetitorPricesNotCleaned"] = "CompetitorPricesNotCleaned";
})(ReleaseMessageCode = exports.ReleaseMessageCode || (exports.ReleaseMessageCode = {}));