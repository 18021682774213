/**
 * @file Created on Wed Apr 10 2019
 * @author SKu
 */

import {AgGrid} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';
import {SelloutIdentificationStore} from '../../stores/components';

/** Used for report page as well as for Dashboard */
@observer
class SelloutIdentification extends React.Component<{store: SelloutIdentificationStore}> {
  /** Removes polling in case if has been started */
  componentWillUnmount() {
    this.props.store.pollingHelper.polling.stopPolling();
  }

  render() {
    return (
      <AgGrid
        rowData={this.props.store.CategoryTraverseHelper.treeData}
        columnDefs={this.props.store.columnDefs}
        autoGroupColumnDef={this.props.store.CategoryTraverseHelper.categoryAutoGroupColumnDef}
        getDataPath={this.props.store.CategoryTraverseHelper.getDataPath}
        onGridReady={this.props.store.onGridReady}
        treeData
        gridId="SelloutIdentificationReportPageStore"
        quickView
        domLayout="autoHeight"
        groupDefaultExpanded={3}
      />
    );
  }
}

export default SelloutIdentification;
