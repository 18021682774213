/**
 * @file Created on Tue Aug 21 2018
 * @author BPo
 */

/**
 * Show/render element if given condition is true
 * @param condition Condition to check
 * @param element Any type of rect component that should be rendered
 */
export var showIf = function showIf(condition, element) {
  if (condition) {
    return element;
  }

  return null;
};