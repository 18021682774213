import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: table-cell;\n  vertical-align: top;\n  border-left: 1px solid ", ";\n  padding: ", "rem;\n  width: 30%;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: table-cell;\n  vertical-align: top;\n  max-width: ", "px;\n  padding: ", "rem;\n  background-color: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: table;\n  width: 100%;\n  border: 1px solid ", ";\n  border-radius: ", ";\n  margin-bottom: ", "rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Tue Oct 23 2018
 * @author PKl
 */
import styled, { withTheme } from 'styled-components';
export var BoxForm = styled.div(_templateObject(), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.border;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.common.borderRadius;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.spacing.spacer;
});
var BoxFormContent = styled.div(_templateObject2(), function (_ref4) {
  var theme = _ref4.theme;
  return theme.form.width;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.spacing.spacer * 2;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.colors.paper;
});
var BoxFormDescription = styled.div(_templateObject3(), function (_ref7) {
  var theme = _ref7.theme;
  return theme.colors.border;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.spacing.spacer * 2;
});
export var BoxFormStyled = withTheme(BoxForm);
export var BoxFormContentStyled = withTheme(BoxFormContent);
export var BoxFormDescriptionStyled = withTheme(BoxFormDescription);