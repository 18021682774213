"use strict";
/*
 * Created on 09 17 2018
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});

function createUploadNotificationSchema(builder, key) {
  return builder.data(key, {
    severity: builder.str('severity'),
    text: builder.str('text'),
    params: builder.opt(builder.arr(builder.str('params'))),
    lineNumber: builder.opt(builder.num('lineNumber'))
  });
}

exports.createUploadNotificationSchema = createUploadNotificationSchema;