/**
 * @file Created on Thu Sep 06 2018
 * @author SKu
 */

import {Page, SubstitutionComponent} from '@logio/common-fe';
import {Observer, observer} from 'mobx-react';
import * as React from 'react';
import {PageNamesEnum} from '../../shared/localization/PageNamesEnum';
import {SubstitutionPageStore} from '../../stores/pages/Substitution/SubstitutionPageStore';
/**
 * Substitution page allows to substitute other users(get their rights)
 */
@observer
class SubstitutionPage extends React.Component {
  store = new SubstitutionPageStore();

  componentWillMount() {
    this.store.load();
  }

  render() {
    return (
      <Page titleKey={PageNamesEnum.Substitution} loadingState={this.store.loadingState}>
        <Observer>
          {() => (
            <>
              <SubstitutionComponent usersAllowedToSubstitute={this.store.usersAllowedToSubstitute} />
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

export default SubstitutionPage;
