export enum PageNamesEnum {
  Substitution = 'PRICING-SUBSTITUTION-NAME',
  Dashboard = 'PRICING-HOME-NAME',
  Release = 'PRICING-RELEASES-NAME',
  ReleasesRegular = 'PRICING-RELEASES-REGULAR-NAME',
  ReleasesSellout = 'PRICING-RELEASES-SELLOUT-NAME',
  ReleasesRegularImpact = 'PRICING-REGULAR-IMPACT-REPORT-NAME',
  ReleasesSelloutImpact = 'PRICING-SELLOUT-IMPACT-REPORT-NAME',
  ReleasesSelloutImpactDetail = 'PRICING-SELLOUT-IMPACT-REPORT-DETAIL-NAME',
  ReleaseNew = 'PRICING-RELEASE-NEW-NAME',
  ReleaseRegularNew = 'PRICING-RELEASE-REGULAR-NEW-NAME',
  ReleaseSelloutNew = 'PRICING-RELEASE-SELLOUT-NEW-NAME',
  ReleaseDetail = 'PRICING-RELEASE-DETAIL-NAME',
  ReleaseDetailCategory = 'PRICING-RELEASE-DETAIL-CATEGORY-NAME',
  ReleaseExport = 'PRICING-RELEASE-EXPORT-NAME',
  ReleaseSelloutDetail = 'PRICING-RELEASE-SELLOUT-DETAIL-NAME',
  ReleaseSelloutDetailCategory = 'PRICING-RELEASE-SELLOUT-DETAIL-CATEGORY-NAME',
  ReleaseSelloutExport = 'PRICING-RELEASE-SELLOUT-EXPORT-NAME',
  ReleaseSettings = 'PRICING-RELEASE-SETTINGS-NAME',
  ReleaseSelloutSettings = 'PRICING-RELEASE-SELLOUT-SETTINGS-NAME',
  DataManagement = 'PRICING-DATA-MANAGEMENT-NAME',
  PriceArchitectureCategories = 'PRICING-PRICE-ARCHITECTURE-NAME',
  PriceArchitectureProducts = 'PRICING-PRICE-ARCHITECTURE-PRODUCTS-NAME',
  DataPairing = 'PRICING-DATA-PAIRING-NAME',
  DataPairingProducts = 'PRICING-DATA-PAIRING-PRODUCTS-NAME',
  DataPairingRecommended = 'PRICING-DATA-PAIRING-RECOMMENDED-NAME',
  DataPairingPaired = 'PRICING-DATA-PAIRING-PAIRED-NAME',
  DataPairingUnpaired = 'PRICING-DATA-PAIRING-UNPAIRED-NAME',
  DataCleaningCategories = 'PRICING-DATA-CLEANING-NAME',
  DataCleaningProducts = 'PRICING-DATA-CLEANING-PRODUCTS-NAME',
  DataUpload = 'PRICING-DATA-UPLOAD-NAME',
  DataUploadDetail = 'PRICING-DATA-UPLOAD-DETAIL-NAME',
  DataUploadNew = 'PRICING-DATA-UPLOAD-NEW-NAME',
  PriceZones = 'PRICING-PRICE-ZONES-NAME',
  PriceZonesCompetitorsInZones = 'PRICING-PRICE-ZONES-COMPETITORS-IN-ZONES-NAME',
  GeneralSettingsCategories = 'PRICING-GENERAL-SETTINGS-NAME',
  Reports = 'PRICING-REPORTS-NAME',
  SelloutIdentificationReport = 'PRICING-SELLOUT-IDENTIFICATION-REPORT-NAME',
  SelloutIdentificationReportDetail = 'PRICING-SELLOUT-IDENTIFICATION-REPORT-DETAIL-NAME',
  SelloutTerminationReport = 'PRICING-SELLOUT-TERMINATION-REPORT-NAME',
  PriceSpiralReport = 'PRICING-PRICE-SPIRAL-REPORT-NAME',
  PriceChangeAndStickersReport = 'PRICING-PRICE-CHANGE-AND-STICKERS-REPORT-NAME',
  CompetitorsPriceChangeReport = 'PRICING-COMPETITORS-PRICE-CHANGE-REPORT-NAME',
  PriceInflationReport = 'PRICING-PRICE-INFLATION-REPORT-NAME',
  AgencyQualityEvaluationReport = 'PRICING-AGENCY-QUALITY-EVALUATION-REPORT-NAME',
  ImpactReport = 'PRICING-IMPACT-REPORT-NAME',
  PriceIndexReport = 'PRICING-PRICE-INDEX-REPORT-NAME',
  PriceElasticityReport = 'PRICING-PRICE-ELASTICITY-REPORT-NAME',
  Sellouts = 'PRICING-SELLOUTS-NAME',
  EndSellouts = 'PRICING-SELLOUTS-END-NAME',
  Terminations = 'PRICING-TERMINATIONS-NAME',
  RegularSite = 'PRICING-REGULAR-SITE-NAME',
  TerminateRegularSite = 'PRICING-REGULAR-SITE-TERMINATE-NAME',
  Settings = 'PRICING-SETTINGS-NAME',
  MatrixTable = 'PRICING-MATRIX-TABLE-NAME',
  RoundingRulesSettings = 'PRICING-ROUNDING-RULES-NAME',
  ConfigurationsSettings = 'PRICING-CONFIGURATIONS-SETTINGS-NAME',
  CompetitorsPrioritySettings = 'PRICING-COMPETITORS-PRIORITY-NAME',
  SelloutSettings = 'PRICING-SELLOUT-SETTINGS-NAME',
  TiersSettings = 'PRICING-TIER-SETTINGS-NAME',
  WorkflowAdministration = 'PRICING-WORKFLOW-ADMINISTRATION-NAME',
  WorkflowCases = 'PRICING-WORKFLOW-CASES-NAME',
  WorkflowLocks = 'PRICING-WORKFLOW-LOCKS-NAME',
  NotFound = 'PRICING-NOT-FOUND-NAME',
  PromoSalesDashoboardReport = 'PROMO-REPORTING-SALES-DASHBOARD-NAME',
  ReleaseRegularSiteDetail = 'PRICING-REGULAR-SITE-RELEASE-DETAIL-NAME',
}
