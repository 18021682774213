/**
 * @file Created on Mon Aug 13 2018
 * @author VBr
 * @author LZe
 */

import {
  Competitor,
  CompetitorSite,
  DataDesc,
  jsonConversions,
  PriceZone,
  StructPropBuilder,
  PricingPermissions,
} from '@logio/common-be-fe';
import {
  ActionsGenerator,
  ColumnDefinition,
  ColumnGenerator,
  CompetitorSiteStore,
  CompetitorStore,
  LoadingState,
  PageStore,
  PriceZoneLayer,
  PriceZoneStore,
  rootStore,
  StoreName,
  StringMapping,
  translate,
  KeycloakStore,
} from '@logio/common-fe';
import {List} from 'immutable';
import {ColumnApi, GridApi, GridReadyEvent, SelectionChangedEvent} from 'ag-grid-community';
import {action, computed, observable} from 'mobx';

export class PriceZonesCompetitorsInZonesPageStore extends PageStore {
  /** Get dependant stores */
  priceZones = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;
  competitorSites = rootStore.getStore(StoreName.CompetitorSite) as CompetitorSiteStore;
  competitors = rootStore.getStore(StoreName.Competitor) as CompetitorStore;
  keycloakStore = rootStore.getStore(StoreName.Keycloak) as KeycloakStore;

  priceZoneBuilder = new StructPropBuilder('Zone');

  /** Data generators */
  actionsGenerator = new ActionsGenerator();
  priceZoneGenerator = new ColumnGenerator<PriceZone>(PriceZone.schema);
  competitorSitesGenerator = new ColumnGenerator<CompetitorSite>(CompetitorSite.schema);
  competitorGenerator = new ColumnGenerator<Competitor>(
    (() => {
      const bld = new StructPropBuilder('Competitor');
      return {
        name: bld.str('name'),
      };
    })(),
  );
  priceZonesColumnGenerator: ColumnGenerator<StringMapping<any>>;

  priceZoneChangeFormDataDesc: DataDesc = {
    priceZoneIds: this.priceZoneBuilder.arr(this.priceZoneBuilder.lookup('priceZoneIds', PriceZone.PRICE_ZONES_LOOKUP)),
  };

  priceZoneChangeType: 'add' | 'remove' = 'add';

  @observable
  gridApi: GridApi;

  @observable
  columnApi: ColumnApi;

  @observable
  changePriceZoneModalVisible: boolean = false;

  @observable
  selectedRowsCount: number = 0;

  /**
   * Return column definitions for ag-grid
   */
  @computed
  get columnDefs(): ColumnDefinition[] {
    const colDefs: ColumnDefinition[] = [];
    /** Gets array of all zones in column definition format */
    const zones: ColumnDefinition[] = [];
    this.priceZones.list.forEach((zone) => {
      if (zone.id && !zone.archived) {
        zones.push({
          field: zone.id,
          action: this.pageReadOnly ? null : this.updateCompetitorsInZone,
          headerName: zone.name,
        });
      }
    });

    if (!this.pageReadOnly) {
      colDefs.push(this.actionsGenerator.getColumnDefinition({width: 40, headerName: ''}));
    }

    colDefs.push(
      ...this.competitorSitesGenerator.getColumnDefinitions([
        {
          field: 'active',
          action: this.pageReadOnly ? null : this.editCompetitor,
          booleanFilterMap: {
            true: translate('active'),
            false: translate('deactivated'),
          },
        },
        {field: 'externalId'},
      ]),
      ...this.competitorGenerator.getColumnDefinitions([{field: 'name'}]),
      ...this.competitorSitesGenerator.getColumnDefinitions([{field: 'address'}]),
      ...this.priceZonesColumnGenerator.getColumnDefinitions(zones),
    );

    return colDefs;
  }

  /**
   * Return data for ag-grid
   */
  @computed
  get rowData(): Array<StringMapping<any>> {
    const priceZonesColumnsDescription: DataDesc = {};

    this.priceZones.list.forEach((zone) => {
      if (zone.id) {
        priceZonesColumnsDescription[zone.id] = this.priceZoneBuilder.bool(zone.id);
      }
    });

    this.priceZonesColumnGenerator = new ColumnGenerator<StringMapping<any>>(priceZonesColumnsDescription);

    const rowData = [];
    this.competitorSites.list.forEach((competitorSite: CompetitorSite) => {
      const enabled: StringMapping<boolean> = {};

      this.priceZones.list.forEach((zone) => {
        if (zone.id && !zone.archived) {
          /** Check competitorSite exists in zone */
          if (competitorSite.id !== null) {
            enabled[zone.id] = zone.competitorSitesIds.includes(competitorSite.id);
          }
        }
      });

      const competitor = this.competitors.list.get(competitorSite.companyId);
      /** Each generator return data definition from related data description */
      rowData.push({
        ...this.competitorSitesGenerator.getColumnData(competitorSite!),
        ...this.priceZonesColumnGenerator.getColumnData(enabled),
        ...this.competitorGenerator.getColumnData(competitor),
      });
    });
    return rowData;
  }

  /**
   * Fetches all data for this page
   */
  @action.bound
  load(): void {
    this.setLoadingState(LoadingState.Pending);

    Promise.all([
      /** List of all priceZones */
      this.priceZones.getAll(),
      /** List of all competitor sites */
      this.competitorSites.getAll(),
      /** List of all competitors */
      this.competitors.getAll(),
    ])
      .then(() => {
        this.setReadOnly(!this.keycloakStore.userHasPermissions([PricingPermissions.COMPETITOR_SITES_MANAGE]));
        this.setLoadingState(LoadingState.Success);
      })
      .catch((error) => {
        this.setLoadingState(LoadingState.Error);
        // this.messages.setError(error);
      });
  }

  @action.bound
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  /**
   * Toggles multiple rows active state according to the slected rows
   */
  @action
  toggleActive = () => {
    this.gridApi.showLoadingOverlay();
    const isSomeNotActive = this.gridApi
      .getSelectedRows()
      .some((comp) => comp[CompetitorSite.schema.active.description.nameKey] === false);
    const selectedCompetitors = this.gridApi.getSelectedRows();
    const selectedCompetitorsIds = selectedCompetitors.map((c) => c[CompetitorSite.schema.id.description.nameKey]);

    this.competitorSites
      .toggleActive(selectedCompetitorsIds, isSomeNotActive)
      .then(() => {
        this.messages.setSuccess(
          translate('CompetitorsInZones_activeToggled', selectedCompetitorsIds.length.toString()),
        );
        this.gridApi.hideOverlay();
      })
      .catch((error) => {
        this.gridApi.hideOverlay();
        // this.messages.setError(error);
      });
  };

  @action
  toggleChangePriceZoneModal = (changeType: 'add' | 'remove' | null) => {
    if (changeType) {
      this.priceZoneChangeType = changeType;
    }
    this.changePriceZoneModalVisible = !this.changePriceZoneModalVisible;
  };

  @action
  changePriceZones = (values: {priceZoneIds: string[]}) => {
    this.gridApi.showLoadingOverlay();
    const zoneLayer = new PriceZoneLayer();
    const selectedCompetitors = this.gridApi.getSelectedRows();
    const selectedCompetitorsIds = selectedCompetitors.map((c) => c[CompetitorSite.schema.id.description.nameKey]);

    const updates = values.priceZoneIds.map((zoneId) => {
      const zone: PriceZone = this.priceZones.list.get(zoneId);
      let competitorSitesIds: List<string> = List(zone.competitorSitesIds);

      selectedCompetitorsIds.forEach((competitorId) => {
        if (this.priceZoneChangeType === 'add' && !competitorSitesIds.includes(competitorId)) {
          competitorSitesIds = competitorSitesIds.push(competitorId);
        } else if (this.priceZoneChangeType === 'remove') {
          const index = competitorSitesIds.indexOf(competitorId);
          if (index >= 0) {
              competitorSitesIds = competitorSitesIds.remove(index);
          }
        }
      });

      return this.priceZones.update(
        zoneLayer.getConverter().toObject(
          {
            ...zoneLayer.getConverter().toDocument(zone, jsonConversions),
            competitorSitesIds: competitorSitesIds.toArray(),
          },
          jsonConversions,
        ),
      );
    });

    Promise.all(updates)
      .then(() => {
        this.messages.setSuccess(
          translate('CompetitorsInZones_competitorChanged', selectedCompetitorsIds.length.toString()),
        );
        this.toggleChangePriceZoneModal(null);
        this.gridApi.hideOverlay();
      })
      .catch((error) => {
        this.toggleChangePriceZoneModal(null);
        this.gridApi.hideOverlay();
        this.messages.setError(error);
      });
  };

  @action
  onSelectionChanged = (event: SelectionChangedEvent): void => {
    this.selectedRowsCount = event.api.getSelectedRows().length;
  };

  @action.bound
  updateCompetitorsInZone(rowIndex: string, column: string, value: boolean): Promise<void> {
    const zoneLayer = new PriceZoneLayer();

    const competitorId: string = this.rowData[rowIndex][CompetitorSite.schema.id.description.nameKey];
    const zone: PriceZone = this.priceZones.list.get(StructPropBuilder.nameId(column));
    let competitorSitesIds: List<string> = List(zone.competitorSitesIds);

    if (value && !competitorSitesIds.includes(competitorId)) {
      competitorSitesIds = competitorSitesIds.push(competitorId);
    } else if (competitorSitesIds.includes(competitorId)) {
      competitorSitesIds = competitorSitesIds.delete(competitorSitesIds.indexOf(competitorId));
    }

    return this.priceZones
      .update(
        zoneLayer.getConverter().toObject(
          {
            ...zoneLayer.getConverter().toDocument(zone, jsonConversions),
            competitorSitesIds: competitorSitesIds.toArray(),
          },
          jsonConversions,
        ),
      )
      .catch((error) => this.messages.setError(error));
  }

  @action.bound
  editCompetitor(rowIndex: string, column: string, value: any): Promise<any> {
    const competitorId: string = this.rowData[rowIndex][CompetitorSite.schema.id.description.nameKey];
    const competitor = this.competitorSites.list.get(competitorId).copy(StructPropBuilder.nameId(column) as any, value);
    return this.competitorSites
      .update(competitor)
      .then((updatedCompetitorSite) => {
        return updatedCompetitorSite[StructPropBuilder.nameId(column)];
      })
      .catch((error) => {
        // this.messages.setError(error);
        return Promise.reject(error);
      });
  }
}
