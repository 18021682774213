/**
 * @file Created on Mon Nov 26 2019
 *
 * @author Tomáš Leity
 * @author Vojtěch Smolař
 */

/**
 * @enum
 */
export var DPContextStateStatus;

(function (DPContextStateStatus) {
  /**
   * Task finished
   */
  DPContextStateStatus["Completed"] = "COMPLETED";
  /**
   * Task is running
   */

  DPContextStateStatus["Running"] = "RUNNING";
  /**
   * Task is being INITIALIZED.
   */

  DPContextStateStatus["Initialized"] = "INITIALIZED";
})(DPContextStateStatus || (DPContextStateStatus = {}));