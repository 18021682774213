/**
 * @file Created on Tue Aug 28 2018
 * @author VBr
 * @author LZe
 */

import {
  AgGrid,
  BoxTabContainer,
  Button,
  ButtonColor,
  ButtonSize,
  Messages,
  GroupActions,
  Icon,
  IconColor,
  IconType,
  Page,
  Search,
  translate,
  AgGridWrapper,
  getPath,
} from '@logio/common-fe';
import {observer, Observer} from 'mobx-react';
import * as React from 'react';
import {Col, Row} from 'react-flexbox-grid';
import {match} from 'react-router';
import {IRouteParams} from '../../../routes-app';
import {DataPairingRecommendedPageStore} from '../../../stores/pages/DataManagement/DataPairing/DataPairingRecommendedPageStore';
import Tabs from './Tabs';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {packageSizeHelper} from '../../../shared/packageSizeHelper';

export interface Props {
  match: match<IRouteParams>;
}
@observer
class DataPairingRecommendedPage extends React.Component<Props> {
  store = new DataPairingRecommendedPageStore(this.props.match.params.id);

  componentWillMount() {
    this.store.load();
  }

  render() {
    return (
      <Page
        titleKey={PageNamesEnum.DataPairingProducts}
        titleParams={[
          this.store.categoryStore.list.get(this.store.categoryId)
            ? this.store.categoryStore.list.get(this.store.categoryId).name
            : '',
        ]}
        loadingState={this.store.loadingState}
        showMessages={false}
        backButtonPath={getPath(PagePathsEnum.DataPairing)}
      >
        <Observer>
          {() => (
            <AgGridWrapper>
              <Tabs id={this.props.match.params.id} />
              <BoxTabContainer>
                <AgGridWrapper>
                  <Messages />
                  <Row>
                    <Col xs={10} md={4} className="mb1">
                      <Search
                        disabled={this.store.lockInputs}
                        placeholder={`${translate('Search internal products')}...`}
                        value={this.store.internalQuery}
                        onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                            this.store.reload();
                            const target = event.currentTarget;
                            setTimeout(() => {
                              target.focus();
                            }, 500);
                          }
                        }}
                        onChange={this.store.onFilter}
                        onReset={() => {
                          if (this.store.lockInputs) {
                            return;
                          }
                          this.store.resetFilter();
                        }}
                      />
                    </Col>
                    <Col xs={14} md={8}>
                      <GroupActions selectedRowsCount={this.store.selectedRowsCount} right>
                        {({disabled}) => (
                          <>
                            <Button
                              iconLeft
                              buttonSize={ButtonSize.Tiny}
                              onClick={this.store.pairSelected}
                              disabled={disabled}
                            >
                              <Icon iconType={IconType.pair} iconColor={IconColor.Inverse} />
                              {translate('Pair selected')}
                            </Button>
                            <Button
                              iconLeft
                              buttonColor={ButtonColor.Light}
                              buttonSize={ButtonSize.Tiny}
                              onClick={this.store.unpairSelected}
                              disabled={disabled}
                            >
                              <Icon iconType={IconType.delete} iconColor={IconColor.Secondary} />
                              {translate('Remove recommendation')}
                            </Button>
                          </>
                        )}
                      </GroupActions>
                    </Col>
                  </Row>
                  <AgGrid
                    rowData={this.store.rowData}
                    columnDefs={this.store.columnDefs}
                    onGridReady={this.store.onGridReady}
                    rowSelection="multiple"
                    onSelectionChanged={this.store.onSelectionChanged}
                    onRowDataChanged={this.store.onSelectionChanged}
                    suppressRowClickSelection
                    onColumnVisible={packageSizeHelper.onColumnVisible}
                    gridId="PricingDataPairingRecommendedPage"
                    quickView
                  />
                </AgGridWrapper>
              </BoxTabContainer>
            </AgGridWrapper>
          )}
        </Observer>
      </Page>
    );
  }
}

export default DataPairingRecommendedPage;
