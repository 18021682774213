/**
 * @file Created on Tue Nov 27 2018
 * @author LZe
 */

import {Notification, NotificationDoc, NotificationGroup, NotificationType} from '@logio/common-be-fe';
import {CONSTANTS, getPath, IconType, Notice, translate} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {PagePathsEnum} from '../../shared/localization/PagePathsEnum';

export interface Props<T extends NotificationDoc> {
  notification: Notification<T>;
  onClose: () => void;
}

const notificationGroupToIcon = new Map([
  [NotificationGroup.dataUpload, IconType.upload],
  [NotificationGroup.release, IconType.release],
  [NotificationGroup.dataManagement, IconType.data],
  [NotificationGroup.user, IconType.user],
]);

@observer
export default class NotificationRenderer extends React.Component<Props<any>> {
  content<T extends NotificationDoc>(notification: Notification<T>) {
    switch (notification.type) {
      case NotificationType.releaseOpened:
      case NotificationType.releaseUploadSuccessful:
      case NotificationType.releaseUploadFailed:
        return (
          <Link to={getPath(PagePathsEnum.ReleaseDetail, notification.entityId)}>
            {translate(`Notifications_${notification.messageKey}`)}
          </Link>
        );
      case NotificationType.uploadSuccessful:
      case NotificationType.uploadFailed:
        return (
          <Link to={getPath(PagePathsEnum.DataUploadDetail, notification.entityId)}>
            {translate(`Notifications_${notification.messageKey}`)}
          </Link>
        );
      case NotificationType.automaticCleaningFinished:
        return (
          <Link to={getPath(PagePathsEnum.DataCleaningCategories)}>
            {translate(`Notifications_${notification.messageKey}`)}
          </Link>
        );
      case NotificationType.userDelegationExpired:
        return <Link to={getPath(PagePathsEnum.Substitution)}>{translate(`Notifications_${notification.type}`)}</Link>;
      default:
        return <span>{notification.messageKey}</span>;
    }
  }

  render() {
    const {onClose, notification} = this.props;
    const date = notification.eventDate.format(CONSTANTS.FORMAT.DATE_TIME);
    return (
      <Notice
        contentSizes={[8, 4]}
        content={[this.content(notification), date]}
        noticeIconType={notificationGroupToIcon.get(notification.group)}
        onClose={onClose}
      />
    );
  }
}
