"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Elasticity report information about one product at several price points.
 *
 * @author Michal Sevcenko
 */

var ElasticityProductData =
/**
 * ctor.
 *
 * @param productId identifier of the product
 * @param price ARP which was used as input for this report (reference value for other prices)
 * @param ydgElasticityCoefficient elasticity as reported by YDG, constant for all optimal prices of a product
 * @param elasticityCoefficient elasticity coefficient = ydgElasticityCoefficient / (ydgElasticityCoefficient - 1.1)
 * @param priceData elasticity report results for one product at different price points
 *
 */
function ElasticityProductData(productId, price, ydgElasticityCoefficient, elasticityCoefficient, priceData) {
  _classCallCheck(this, ElasticityProductData);

  this.productId = productId;
  this.price = price;
  this.ydgElasticityCoefficient = ydgElasticityCoefficient;
  this.elasticityCoefficient = elasticityCoefficient;
  this.priceData = priceData;
};

exports.ElasticityProductData = ElasticityProductData;