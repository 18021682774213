"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * The kind of upload (price sensitivity, webscraping, ...).
 *
 * @author Jaroslav Sevcik
 */

var CodebookLookupSequence_1 = require("../../core/model/codebook/CodebookLookupSequence");

exports.UPLOAD_TYPE_LOOKUP = CodebookLookupSequence_1.CodebookLookupSequence.UploadType;
var UploadTypeEnum;

(function (UploadTypeEnum) {
  UploadTypeEnum["AGENCY"] = "AGENCY";
  UploadTypeEnum["WEBSCRAPING"] = "WEBSCRAPING";
  UploadTypeEnum["PRICE_SENSITIVITY"] = "PRICE SENSITIVITY";
  UploadTypeEnum["FAMILY"] = "FAMILY";
})(UploadTypeEnum = exports.UploadTypeEnum || (exports.UploadTypeEnum = {}));