/**
 * @file Created on Tue Mar 14 2020
 * @author MHl
 */

import {UppyFile} from '@uppy/core';
import {PageStore, LoadingState, SelloutTerminationLayer, getPath} from '@logio/common-fe';
import {SelloutTermination} from '@logio/common-be-fe';
import {observable, action, runInAction} from 'mobx';
import {PollingHelper} from '../../components/PollingHelper';
import {History} from 'history';
import {PagePathsEnum} from 'shared/localization/PagePathsEnum';

export class EndSelloutsFormPageStore extends PageStore {
  selloutTerminationLayer = new SelloutTerminationLayer();
  pollingHelper: PollingHelper;

  constructor(public history: History) {
    super();

    this.pollingHelper = new PollingHelper(this.messages, this.onPollingStateChanged, 1000, true);
  }

  get importTerminationDescriptions() {
    return {
      name: SelloutTermination.schema.name,
    };
  }
  @observable
  isModalHidden: boolean = true;

  @observable
  uploadId: string;

  @action
  openModal = () => {
    this.isModalHidden = false;
  };

  @action
  closeModal = () => {
    this.isModalHidden = true;
  };

  @observable
  submitting = false;

  @action
  setSubmitting(submitting: boolean) {
    this.submitting = submitting;
  }

  @action
  importTerminations = async (values: {name: string; dataFile: UppyFile}): Promise<void> => {
    if (this.submitting) {
      return;
    }
    this.setSubmitting(true);

    try {
      const terminationUpload = await this.selloutTerminationLayer.uploadFile(values.name, values.dataFile);
      runInAction(() => (this.uploadId = terminationUpload.id));
      this.pollingHelper.startPolling(terminationUpload.progressId, 'termination-upload-succes');
    } catch (err) {
      this.setSubmitting(false);
    }
  };

  getUploadedTermination = async (uploadId: string) => {
    try {
      const uploadedTermination = await this.selloutTerminationLayer.getUpload(uploadId);
      if (uploadedTermination.validation.isSuccessful() && uploadedTermination.terminationId) {
        this.openTermination(uploadedTermination.terminationId);
      } else {
        uploadedTermination.validation.isEmpty() && this.pollingHelper.errorFallback
          ? this.messages.setError(this.pollingHelper.errorFallback)
          : this.messages.setValidationResult(uploadedTermination.validation);
        this.closeModal();
      }
    } catch (error) {
      this.closeModal();
    } finally {
      this.setSubmitting(false);
    }
  };

  private onPollingStateChanged = (pollingState: LoadingState) => {
    if (pollingState === LoadingState.Pending) {
      this.openModal();
    }
    if (pollingState === LoadingState.Success) {
      this.getUploadedTermination(this.uploadId);
    }
    if (pollingState === LoadingState.Error) {
      this.getUploadedTermination(this.uploadId);
    }
  };

  openTermination = (terminationId: string) => {
    this.history.push(getPath(PagePathsEnum.EndSelloutsDetail, terminationId));
  };
}
