import { PricingPermissions, ReleaseType } from '@logio/common-be-fe';
import { getPath, KeycloakStore, rootStore, StoreName } from '@logio/common-fe';
import React from 'react';
import { PagePathsEnum } from '../../shared/localization/PagePathsEnum';
import ReleaseNewPage from './ReleaseNewPage';

export default function RegularReleaseNewPage() {
  const keycloakStore = rootStore.getStore(StoreName.Keycloak) as KeycloakStore;
  const releaseTypes = [];

  if (keycloakStore.userHasPermissions([
    PricingPermissions.REGULAR_RELEASE_CREATE_OTHERS,
    PricingPermissions.REGULAR_RELEASE_CREATE_SUPERFAST,
    PricingPermissions.REGULAR_RELEASE_CREATE_URGENT,
  ], true)) {
    releaseTypes.push(ReleaseType.Regular);
  }

  if (keycloakStore.userHasPermissions([PricingPermissions.SIMULATION_RELEASE_CREATE], true)) {
    releaseTypes.push(ReleaseType.Simulation);
  }

  return <ReleaseNewPage releaseTypes={releaseTypes} backButtonPath={getPath(PagePathsEnum.ReleasesRegular)} />;
}
