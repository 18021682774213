/**
 * @file Created on Tue Apr 02 2019
 * @author SKu
 */

import {
  DataDesc,
  Release,
  SelloutRelease,
  Utils,
  StructPropBuilder,
  DateParser,
  SelloutItemFilter,
  CodebookLookupSequence,
  PriceZone,
  ReleaseState,
  ProductUtils,
} from '@logio/common-be-fe';
import {
  ReleaseSelloutStore,
  rootStore,
  StoreName,
  MessageStore,
  translate,
  PriceZoneStore,
  CONSTANTS,
  CustomDefinition,
  getPath,
} from '@logio/common-fe';
import {AbstractReleaseHeaderStore} from './AbstractReleaseHeaderStore';
import {computed} from 'mobx';
import {PagePathsEnum} from '../../../../shared/localization/PagePathsEnum';
import {bigdecimal} from '@logio/big-decimal';
import { List } from 'immutable';

enum weighted {
  yes = 'Yes',
  no = 'No',
  all = 'All',
}
export class ReleaseSelloutHeaderStore extends AbstractReleaseHeaderStore {
  public releaseStore = rootStore.getStore(StoreName.SelloutRelease) as ReleaseSelloutStore;
  priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;

  /** @inheritdoc */
  public get release(): SelloutRelease {
    return this.releaseStore.release;
  }

  @computed
  get nationalZone(): PriceZone {
    return this.priceZoneStore.nationalZone;
  }

  public async cancellSimulation(callback: () => void) {
    callback();
    await this.releaseStore.cancellPriceSimulation(this.release.id);
  }

  /** @inheritdoc */
  public get descriptions(): DataDesc {
    const builder = new StructPropBuilder('Release');
    return {
      name: Release.schema.name,
      priceListTypeId: Release.schema.priceListTypeId,
      totalPriceChangeLimit: Release.schema.totalPriceChangeLimit,
      minPriceChangeInPercent: builder.bigNum('minPriceChangeInPercent'),
      maxPriceChangeInPercent: builder.bigNum('maxPriceChangeInPercent'),
      minMarginChangeInPercent: builder.bigNum('minMarginChangeInPercent'),
      priceValidityStartDate: Release.schema.priceValidityStartDate,
      cmDeadline: Release.schema.cmDeadline,
      endDate: builder.date('endDate', DateParser.ISO_PATTERN),
      description: Release.schema.description,
    };
  }

  get settingsTooltipDescriptions(): DataDesc {
    const builder = new StructPropBuilder('SelloutReleaseItemFilterForm');
    return {
      zoneId: builder.opt(builder.lookup('zoneId', CodebookLookupSequence.ReleasePriceZones)),
      siteIds: builder.opt(builder.listOf(builder.lookup('sitesIds', CodebookLookupSequence.InternalSites))),
      weighted: builder.opt(builder.str('weighted')),
      returnable: builder.opt(builder.str('returnable')),
      unitsPerSite: builder.opt(SelloutItemFilter.schema.unitsPerSite),
      stockValue: builder.opt(SelloutItemFilter.schema.stockValue),
      selloutVolume: builder.opt(SelloutItemFilter.schema.selloutVolume),
      futurePromoInWeeks: builder.opt(SelloutItemFilter.schema.futurePromoInWeeks),
      daysToExpiration: builder.opt(SelloutItemFilter.schema.daysToExpiration),
      productExternalIds: builder.opt(builder.listOf(builder.str('productExternalIds'))),
    };
  }

  get defaultFilterValues() {
    return {
      siteIds: [],
      zoneId: this.nationalZone.id,
      weighted: weighted.yes,
      returnable: null,
      unitsPerSite: ['', ''],
      stockValue: ['', ''],
      selloutVolume: ['', ''],
      daysToExpiration: ['', ''],
      futurePromoInWeeks: 0,
      productExternalIds: null,
    };
  }

  get initialValues() {
    const data = this.release.itemFilter;
    if (data.isEmpty() || data.isEmptyExceptBase()) {
      return {...this.defaultFilterValues, weighted: weighted.all};
    } else {
      return {
        ...data,
        siteIds: data.siteIds.toArray(),
        zoneId: data.zoneId === null ? this.nationalZone.id : this.release.itemFilter.zoneId,
        weighted: Utils.isValueMissing(data.weighted) ? weighted.all : data.weighted ? weighted.yes : weighted.no,
        returnable: Utils.isValueMissing(data.returnable) ? null : translate(data.returnable ? 'returnableOptions_true' : 'returnableOptions_false'),
        unitsPerSite: [
          Utils.isValueMissing(data.unitsPerSite.minimum) ? '' : data.unitsPerSite.minimum,
          Utils.isValueMissing(data.unitsPerSite.maximum) ? '' : data.unitsPerSite.maximum,
        ],
        stockValue: [
          Utils.isValueMissing(data.stockValue.minimum) ? '' : data.stockValue.minimum,
          Utils.isValueMissing(data.stockValue.maximum) ? '' : data.stockValue.maximum,
        ],
        selloutVolume: [
          Utils.isValueMissing(data.selloutVolume.minimum) ? '' : data.selloutVolume.minimum,
          Utils.isValueMissing(data.selloutVolume.maximum) ? '' : data.selloutVolume.maximum,
        ],
        daysToExpiration: [
          Utils.isValueMissing(data.daysToExpiration.minimum) ? '' : data.daysToExpiration.minimum,
          Utils.isValueMissing(data.daysToExpiration.maximum) ? '' : data.daysToExpiration.maximum,
        ],
        productExternalIds: data.productExternalIds ? List(data.productExternalIds.map(ProductUtils.productExternalIdToGoldId)) : null,
      };
    }
  }

  get settingsPath() {
    return getPath(PagePathsEnum.ReleaseSelloutSettings, this.release.id);
  }

  @computed
  public get customDefinition(): CustomDefinition {
    const customDefinition = {
      path: `${CONSTANTS.API.ENDPOINTS.RELEASES}${CONSTANTS.API.ENDPOINTS.RELEASE_EDIT_HEADER}`,
      params: {
        id: this.release.id,
        releaseType: this.release.releaseType,
        workflowType: this.release.workflowType,
        simulation:
          !this.release.isUrgent() &&
          this.path !== this.settingsPath &&
          (this.release.state === ReleaseState.New || this.release.state === ReleaseState.Reopening)
            ? true
            : undefined,
      },
    };
    return customDefinition;
  }

  /** @inheritdoc */
  public onEditHeaderSubmit = async (release: SelloutRelease) => {
    try {
      const newRelease: SelloutRelease = new SelloutRelease(
        release.id,
        release.name,
        release.description,
        release.releaseType,
        release.created,
        release.createdBy,
        release.lastModified,
        release.lastModifiedBy,
        release.priceListTypeId,
        release.priceListType,
        release.selloutReleaseType,
        release.productCategoryIds,
        release.itemFilter,
        release.releaseParts,
        release.workflowType,
        release.state,
        release.endDate ? release.endDate.endOf('d') : null,
        release.calcWorkflowCaseId,
        release.exportWorkflowCaseId,
        release.exportProgressMonitorId,
        release.exported,
        release.priceValidityStartDate,
        release.totalPriceChangeLimit,
        bigdecimal(release.minPriceChangeInPercent),
        release.maxPriceChangeInPercent ? bigdecimal(release.maxPriceChangeInPercent) : null,
        bigdecimal(release.minMarginChangeInPercent),
        release.cmDeadline ? release.cmDeadline.clone().endOf('day') : release.cmDeadline,
        release.favourite,
        release.validationResult,
        release.reopened,
        release.realImpact,
        release.calcProgressMonitorId,
        release.repricingEstimationThreshold,
        release.itemCountProgressMonitorId,
        release.computingProcessId,
        release.reopenSnapshots,
      );
      await this.releaseStore.update(newRelease);
      // if (!Utils.isValueMissing(this.onHeaderUpdated)) {
      //   this.onHeaderUpdated();
      // }
      this.messages.setSuccess(translate('release-header-updated'));
    } catch (error) {
      // this.messages.setError(error);
    }
    this.hideModal();
  };

  public onRecalculateHeaderSubmit = async (release: SelloutRelease) => {
    try {
      if (!Utils.isValueMissing(this.onHeaderUpdated)) {
        this.hideModal();
        this.onHeaderUpdated();
      }
      const newRelease: SelloutRelease = new SelloutRelease(
        release.id,
        release.name,
        release.description,
        release.releaseType,
        release.created,
        release.createdBy,
        release.lastModified,
        release.lastModifiedBy,
        release.priceListTypeId,
        release.priceListType,
        release.selloutReleaseType,
        release.productCategoryIds,
        release.itemFilter,
        release.releaseParts,
        release.workflowType,
        release.state,
        release.endDate ? release.endDate.endOf('d') : null,
        release.calcWorkflowCaseId,
        release.exportWorkflowCaseId,
        release.exportProgressMonitorId,
        release.exported,
        release.priceValidityStartDate,
        release.totalPriceChangeLimit,
        bigdecimal(release.minPriceChangeInPercent),
        release.maxPriceChangeInPercent ? bigdecimal(release.maxPriceChangeInPercent) : null,
        bigdecimal(release.minMarginChangeInPercent),
        release.cmDeadline,
        release.favourite,
        release.validationResult,
        release.reopened,
        release.realImpact,
        release.calcProgressMonitorId,
        release.repricingEstimationThreshold,
        release.itemCountProgressMonitorId,
        release.computingProcessId,
        release.reopenSnapshots,
      );
      await this.releaseStore
        .proceedPriceSimulation(newRelease.id, newRelease)
        .then(() => this.recalculate(this.release.calcProgressMonitorId));
    } catch (error) {
      // this.messages.setError(error);
    }
    this.hideModal();
  };
}
