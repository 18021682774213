/**
 * @file Created on Fri Aug 17 2018
 * @author VBr
 */

import {
  CategoryCleaningState,
  Competitor,
  DataDesc,
  DataPropDesc,
  Family,
  FamilyBinding,
  InternalProductPricesDTO,
  PriceFormulas,
  PricingPermissions,
  Product,
  ProductCategory,
  ResourceLockType,
  StructPropBuilder,
  Utils,
} from '@logio/common-be-fe';
import {
  ActionsGenerator,
  CategoryCleaningStateStore,
  ColumnDefinition,
  ColumnGenerator,
  Comparators,
  CompetitorSiteStore,
  CompetitorStore,
  CONSTANTS,
  CRPStore,
  FamilyStore,
  FormElOption,
  GeneralConfigurationsStore,
  getPath,
  getProductFlags,
  GlobalSettingsStore,
  IconType,
  InternalProductPricesStore,
  KeycloakStore,
  LoadingState,
  N,
  PageStore,
  Polling,
  PriceZoneStore,
  ProductCategoryStore,
  productFlagsColumnDefinition,
  ProductSensitivityStore,
  ProductStore,
  RendererNames,
  Renderers,
  ResourceLockLayer,
  rootStore,
  startBlockingRouteNavigation,
  stopBlockingRouteNavigation,
  StoreName,
  StringMapping,
  SupplierStore,
  translate,
} from '@logio/common-fe';
import {ColumnApi, ColumnVisibleEvent, GridApi, GridReadyEvent, RowNode, RowSelectedEvent} from 'ag-grid-community';
import {CellClassParams} from 'ag-grid-community/dist/lib/entities/colDef';
import {History} from 'history';
import {action, computed, observable, runInAction} from 'mobx';
import {stringify} from 'querystring';
import * as React from 'react';
import {PollingHelper} from 'stores/components/PollingHelper';
import {PageNamesEnum} from '../../../../shared/localization/PageNamesEnum';
import {PagePathsEnum} from '../../../../shared/localization/PagePathsEnum';
import {PriceHistoryModalStore} from './PriceHistoryModalStore';
import PriceHistoryRenderer from '../../../../pages/DataManagement/DataCleaning/PriceHistoryRenderer';
import {BigDecimal} from '@logio/big-decimal';
import { packageSizeHelper } from '../../../../shared/packageSizeHelper';

export enum DataCleaningModalEnum {
  COPY_DESIGNATION_TO_ZONES = 'COPY_DESIGNATION_TO_ZONES',
}

export interface ModalHiddenProps {
  [DataCleaningModalEnum.COPY_DESIGNATION_TO_ZONES]: boolean;
}

export class DataCleaningProductsPageStore extends PageStore {
  /** Get dependant stores */
  productCategoryStore = rootStore.getStore(StoreName.ProductCategory) as ProductCategoryStore;
  productStore = rootStore.getStore(StoreName.Product) as ProductStore;
  priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;
  familyStore = rootStore.getStore(StoreName.Family) as FamilyStore;
  competitors = rootStore.getStore(StoreName.Competitor) as CompetitorStore;
  competitorSiteStore = rootStore.getStore(StoreName.CompetitorSite) as CompetitorSiteStore;
  supplierStore = rootStore.getStore(StoreName.Supplier) as SupplierStore;
  categoryCleaningStateStore = rootStore.getStore(StoreName.CleaningState) as CategoryCleaningStateStore;
  internalProductPricesStore = rootStore.getStore(StoreName.InternalProductPrices) as InternalProductPricesStore;
  CRPStore = rootStore.getStore(StoreName.CRP) as CRPStore;
  generalConfigurationsStore = rootStore.getStore(StoreName.GeneralConfigurations) as GeneralConfigurationsStore;
  globalSettingsStore = rootStore.getStore(StoreName.GlobalSettings) as GlobalSettingsStore;
  priceHistoryModalStore: PriceHistoryModalStore = new PriceHistoryModalStore();
  productSensitivityStore = rootStore.getStore(StoreName.ProductSensitivity) as ProductSensitivityStore;
  keycloakStore = rootStore.getStore(StoreName.Keycloak) as KeycloakStore;

  resourceLockLayer: ResourceLockLayer = new ResourceLockLayer();
  polling = new Polling(400);
  lastCategoryId: string;

  /** Derived columns data description builder */
  builder = new StructPropBuilder('DataCleaning');
  categoryBuilder = new StructPropBuilder('Category');

  /** Derived columns data description */
  categoryColumnsDescription: DataDesc = {
    box: this.categoryBuilder.str('box'),
  };

  /** Derived columns data description */
  customColumnsDescription: DataDesc = {
    category: this.builder.str('category'),
    approved: this.builder.bool('approved'),
  };

  bindingsSchema = Family.schema.bindings.members as DataPropDesc<FamilyBinding>;

  /** Data generators */
  actionsGenerator = new ActionsGenerator();
  productColumnGenerator = new ColumnGenerator<Product>(Product.schema);
  internalProductPricesColumnGenerator = new ColumnGenerator<StringMapping<any>>({
    purchasePrice: InternalProductPricesDTO.schema.purchasePrice,
    openPurchasePrice: InternalProductPricesDTO.schema.openPurchasePrice,
  });
  familyColumnGenerator = new ColumnGenerator<Family>(Family.schema);
  familyBindingsColumnGenerator = new ColumnGenerator<FamilyBinding>(this.bindingsSchema.data);
  categoryColumnGenerator = new ColumnGenerator<StringMapping<any>>(this.categoryColumnsDescription);
  priceZonePricesGenerator: ColumnGenerator<StringMapping<any>>;
  customColumnGenerator = new ColumnGenerator<StringMapping<any>>(this.customColumnsDescription);

  @observable
  productCategory: ProductCategory;
  @observable
  loadingState: LoadingState = LoadingState.Pending;
  @observable
  showedCompetitors: string[];
  @observable
  showedZones: string[];
  @observable
  showedPriceChanges: string[] = [...CONSTANTS.DATA_CLEANING.PRICE_CHANGES_SCALE.keys()];
  @observable
  columnIds: Map<string, string[]> = new Map();
  @observable
  selectedRows: RowNode[] = [];
  @observable
  selectedProductIds: string[] = [];
  @observable
  cleaningInProgress: boolean;
  @observable
  categoryCleaningState: CategoryCleaningState;

  @observable
  modalHidden: ModalHiddenProps = {
    [DataCleaningModalEnum.COPY_DESIGNATION_TO_ZONES]: true,
  };

  gridApi: GridApi;
  columnApi: ColumnApi;

  scales: Map<string, (percent: BigDecimal) => boolean> = CONSTANTS.DATA_CLEANING.PRICE_CHANGES_SCALE;

  @observable
  scaleOptions: Map<string, number> = new Map();

  constructor(public history: History) {
    super();
    this.setReadOnly(true);
  }

  /** Gets scale options  */
  @computed
  get getScaleOptions(): FormElOption[] {
    const scaleOptions: FormElOption[] = [];

    for (const k of this.scaleOptions.keys()) {
      scaleOptions.push({
        label: this.scaleOptions.get(k).toString(),
        value: k,
      });
    }
    return scaleOptions;
  }

  @computed
  get canUserEdit() {
    return this.keycloakStore.userHasPermissions([PricingPermissions.DATA_CLEAN_EDIT]);
  }

  /** Gets list of competitors assigned to zones */
  @computed
  get competitorOptions(): FormElOption[] {
    return Array.from(this.competitorsInZones).map(([competitorId, competitor]) => {
      return {label: competitor.name, value: competitorId};
    });
  }

  /** Gets not archived price zones as options */
  @computed
  get priceZoneOptions(): FormElOption[] {
    const zones: FormElOption[] = [];

    this.priceZoneStore.list.forEach((zone) => {
      if (!zone.archived) {
        zones.push({label: zone.name, value: zone.id});
      }
    });
    return zones;
  }

  createPriceZoneGenerator = () => {
    const builder = new StructPropBuilder('PriceZonePricesBuilder');
    const description = {};
    this.priceZoneStore.listNotArchived.forEach(({id, isNational}) => {
      description[`${id}_actualRegularPrice`] = builder.bigNum(`${id}_actualRegularPrice`);
      description[`${id}_actualMarginPrice`] = builder.bigNum(`${id}_actualMarginPrice`);
      description[`${id}_actualMarginPercentage`] = builder.bigNum(`${id}_actualMarginPercentage`);
      description[`${id}_unitPrice`] = builder.bigNum(`${id}_unitPrice`);
    });
    this.priceZonePricesGenerator = new ColumnGenerator<StringMapping<any>>(description);
  };

  // Deprecated
  /** Calculates difference between actual and new price and assigns the cell the scale dependant color */
  markPriceChange(params: CellClassParams, zone, competitor) {
    let className = 'ag-cell-type-number';
    const actualPrice =
      params.data[this.customColumnsDescription[`actual_${zone.id}_${competitor.id}`].description.nameKey];

    const newPrice = params.data[this.customColumnsDescription[`new_${zone.id}_${competitor.id}`].description.nameKey];

    if (
      !Utils.isValueMissing(newPrice) &&
      !Utils.isValueMissing(actualPrice) &&
      !Utils.isValueMissing(newPrice.price) &&
      !Utils.isValueMissing(actualPrice.price)
    ) {
      for (const key of this.scales.keys()) {
        const index = PriceFormulas.computePriceChangeIndexPercent(newPrice.price, actualPrice.price);
        if (this.scales.get(key)(index)) {
          className += ` bg-${key}`;
          break;
        }
      }
    } else {
      className += ' bg-transparent';
    }
    return className;
  }

  /** Returns map of competitors assigned to one of zones */
  @computed
  get competitorsInZones(): Map<string, Competitor> {
    // Iterate through each competitor
    return new Map(
      Array.from(this.competitors.list).filter(([competitorId]) => {
        // If one of price zone competitor sites belongs to this competitor, store it to options
        return Array.from(this.priceZoneStore.list).some(([zoneId, zone]) =>
          // Iterate through price zone's competitor sites
          zone.competitorSitesIds.some((competitorSiteId) => {
            const competitorSite = this.competitorSiteStore.list.get(competitorSiteId);
            // If sites competitor matches this iterations competitor, store it to options
            return competitorSite && competitorId === competitorSite.companyId;
          }),
        );
      }),
    );
  }

  /** Stores selected products on row selection */
  onSelectionChanged = (params: RowSelectedEvent) => {
    runInAction(() => {
      this.selectedRows = params.api.getSelectedNodes();
      if (this.selectedRows.length) {
        this.selectedProductIds = this.selectedRows.map(
          (row) => this.productStore.list.get(row.data[Product.schema.id.description.nameKey]).id,
        );
      }
    });
  };

  /**
   * Handle modal form submit
   * @param values:{sourceZoneId, competitorIds, targetZoneIds}
   */
  onCopyDesignationToZonesSubmit = async (values: {
    sourceZoneId: string;
    competitorIds: string[];
    targetZoneIds: string[];
  }) => {
    try {
      await this.categoryCleaningStateStore.categoryCleaningStateLayer.copyDesignationToZones({
        ...values,
        ...{productIds: this.selectedProductIds},
      });
      this.toggleModal(DataCleaningModalEnum.COPY_DESIGNATION_TO_ZONES);
      await this.CRPStore.getCRPAndFlags({categoryId: this.productCategory.id});
      this.messages.setSuccess('copy-designation-to-zones-success');
      this.load(this.lastCategoryId);
    } catch (error) {
      this.messages.setError(error);
    }
  };

  /** Return generated column definitions for ag-grid */
  @computed
  get columnDefs(): ColumnDefinition[] {
    /** Gets array of all zones in column definition format */
    const competitorsInZones: ColumnDefinition[] = [];
    const priceZonePricesDefinition: ColumnDefinition[] = [];
    // Get actual and new price column for each competitor - zone pair
    this.priceZoneStore.listNotArchived.forEach((zone) => {
      priceZonePricesDefinition.push(
        {
          field: `${zone.id}_actualRegularPrice`,
          round: true,
          headerName: translate('PriceZonePricesBuilder_actualRegularPrice', zone.name),
          filterValueGetter: (params) =>
            params.data[params.colDef.colId] ? parseFloat(params.data[params.colDef.colId].toFixed(2)) : null,
        },
        {
          field: `${zone.id}_actualMarginPrice`,
          round: true,
          headerName: translate('PriceZonePricesBuilder_actualMarginPrice', zone.name),
          filterValueGetter: (params) =>
            params.data[params.colDef.colId] ? parseFloat(params.data[params.colDef.colId].toFixed(2)) : null,
        },
        {
          field: `${zone.id}_actualMarginPercentage`,
          round: true,
          // LOG-5514
          // cellRendererFramework: Renderers[RendererNames.ArrowRenderer],
          // comparator: Comparators.arrowComparator,
          headerName: translate('PriceZonePricesBuilder_actualMarginPercentage', zone.name),
          filterValueGetter: (params) =>
            params.data[params.colDef.colId] ? parseFloat(params.data[params.colDef.colId].toFixed(2)) : null,
        },
        {
          field: `${zone.id}_unitPrice`,
          round: true,
          headerName: translate('PriceZonePricesBuilder_unitPrice', zone.name),
          filterValueGetter: (params) =>
            params.data[params.colDef.colId] ? parseFloat(params.data[params.colDef.colId].toFixed(2)) : null,
        },
      );
      this.competitorsInZones.forEach((competitor) => {
        competitorsInZones.push(
          {
            headerName: translate('cleaning_actual', competitor.name, zone.name),
            field: `actual_${zone.id}_${competitor.id}`,
            colId: `actual_${zone.id}_${competitor.id}`,
            width: 170,
            cellClass: (params: CellClassParams) => this.markPriceChange(params, zone, competitor),
            comparator: Comparators.dataCleaningCRPAndNewCRPComparator,
            cellRendererFramework: (props) => (
              <PriceHistoryRenderer
                {...props}
                isNewCRP={false}
                priceHistoryModalStore={this.priceHistoryModalStore}
                zoneId={props.colDef.colId.split('_')[1]}
                competitorId={props.colDef.colId.split('_')[2]}
                productId={props.data[Product.schema.id.description.nameKey]}
                rowId={props.node.id}
                pageReadOnly={this.pageReadOnly}
                rowData={this.rowData}
              />
            ),
            excel: {
              valueFormatter: ({value}) => (value != null && value.price != null ? Utils.toFixed(value.price) : null),
            },
            filterValueGetter: (params) =>
              params.data[`DataCleaning_${params.colDef.colId}`] &&
              params.data[`DataCleaning_${params.colDef.colId}`].price
                ? parseFloat(params.data[`DataCleaning_${params.colDef.colId}`].price.toFixed(2))
                : null,
          },
          {
            headerName: translate('cleaning_new', competitor.name, zone.name),
            field: `new_${zone.id}_${competitor.id}`,
            colId: `new_${zone.id}_${competitor.id}`,
            width: 170,
            cellClass: (params: CellClassParams) => this.markPriceChange(params, zone, competitor),
            comparator: Comparators.dataCleaningCRPAndNewCRPComparator,
            cellRendererFramework: (props) => (
              <PriceHistoryRenderer
                {...props}
                isNewCRP={true}
                priceHistoryModalStore={this.priceHistoryModalStore}
                zoneId={props.colDef.colId.split('_')[1]}
                competitorId={props.colDef.colId.split('_')[2]}
                productId={props.data[Product.schema.id.description.nameKey]}
                rowId={props.node.id}
                pageReadOnly={this.pageReadOnly}
                rowData={this.rowData}
              />
            ),
            excel: {
              valueFormatter: ({value}) => (value != null && value.price != null ? Utils.toFixed(value.price) : null),
            },
            filterValueGetter: (params) =>
              params.data[`DataCleaning_${params.colDef.colId}`] &&
              params.data[`DataCleaning_${params.colDef.colId}`].price
                ? parseFloat(params.data[`DataCleaning_${params.colDef.colId}`].price.toFixed(2))
                : null,
          },
          {
            headerName: translate('cleaning_index', competitor.name, zone.name),
            field: `index_${zone.id}_${competitor.id}`,
            colId: `index_${zone.id}_${competitor.id}`,
            width: 170,
            // cellClass: (params: CellClassParams) => this.markPriceChange(params, zone, competitor),
            cellRendererFramework: Renderers[RendererNames.ArrowRenderer],
            cellRendererParams: {
              limit: this.globalSettingsStore.terms.cleaningSettings.competitorReferencePriceChangeIndex,
            },
            comparator: Comparators.arrowComparator,
            excel: {
              valueFormatter: ({value}) => (value != null && value.index != null ? Utils.toFixed(value.index) : null),
            },
            filterValueGetter: (params) =>
              params.data[`DataCleaning_${params.colDef.colId}`] &&
              params.data[`DataCleaning_${params.colDef.colId}`].price
                ? parseFloat(params.data[`DataCleaning_${params.colDef.colId}`].price.toFixed(2))
                : null,
          },
          {
            headerName: translate('cleaning_internal', competitor.name, zone.name),
            field: `internal_${zone.id}_${competitor.id}`,
            colId: `internal_${zone.id}_${competitor.id}`,
            width: 250,
            // cellClass: (params: CellClassParams) => this.markPriceChange(params, zone, competitor),
            cellRendererFramework: Renderers[RendererNames.ArrowRenderer],
            cellRendererParams: {
              limit: this.globalSettingsStore.terms.cleaningSettings.internalVsCompetitorReferencePriceChangeIndex,
            },
            comparator: Comparators.arrowComparator,
            filterValueGetter: (params) =>
              params.data[`DataCleaning_${params.colDef.colId}`]
                ? parseFloat(params.data[`DataCleaning_${params.colDef.colId}`].toFixed(2))
                : null,
          },
        );
      });
    });

    return [
      this.actionsGenerator.getColumnDefinition({
        headerName: '',
        // width: 40, // LOG-5371 - column moving doesn't work for narrow columns
        suppressMovable: false,
        lockPinned: false,
        lockPosition: false,
      }),
      ...this.categoryColumnGenerator.getColumnDefinitions([{field: 'box', filter: 'agSetColumnFilter'}]),
      ...this.familyColumnGenerator.getColumnDefinitions([
        {
          field: 'name',
          filter: 'agSetColumnFilter',
        },
      ]),
      ...this.familyBindingsColumnGenerator.getColumnDefinitions([
        {field: 'sizeRatio', hide: true},
        {field: 'sizeCharger', hide: true},
      ]),
      ...this.productColumnGenerator.getColumnDefinitions([
        {field: 'name'},
        {
          field: 'flags',
          ...productFlagsColumnDefinition,
        },
        {
          field: 'supplierId',
          filter: 'agSetColumnFilter',
          valueFormatter: ({value: supplierId}) => (supplierId ? this.supplierStore.list.get(supplierId).name : null),
        },
      ]),
      ...packageSizeHelper.colDefs(this.productStore),
      ...this.internalProductPricesColumnGenerator.getColumnDefinitions([
        {
          field: 'purchasePrice',
          round: true,
          filterValueGetter: (params) =>
            params.data[params.colDef.colId] ? params.data[params.colDef.colId].toNumber() : null,
        },
        {
          field: 'openPurchasePrice',
          round: true,
          filterValueGetter: (params) =>
            params.data[params.colDef.colId] ? params.data[params.colDef.colId].toNumber() : null,
        },
      ]),
      ...this.priceZonePricesGenerator.getColumnDefinitions(priceZonePricesDefinition),
      ...this.customColumnGenerator.getColumnDefinitions([...competitorsInZones]),
      {
        field: 'reports',
        headerName: translate('DataCleaning_reports'),
        cellRenderer: RendererNames.ActionsRenderer,
        cellClass: 'ag-cell-align-center',
        filter: false,
        sortable: false,
        width: 100,
        excel: {
          hide: true,
        },
      },
      {
        field: 'productSensitivity',
        headerName: translate('ProductSensitivity_title'),
        filter: 'agSetColumnFilter',
      },
    ];
  }

  @computed
  get rowData(): Array<StringMapping<any>> {
    const rowData = [];

    Array.from(this.productStore.list).forEach(([productId, product]) => {
      const internalProductPrices = this.internalProductPricesStore.list.get(productId);
      const competitorsInZones: StringMapping<any> = {};
      const priceZonePricesData: StringMapping<any> = {};
      const purchasePrice = internalProductPrices && internalProductPrices.purchasePrice;
      const openPurchasePrice = internalProductPrices && internalProductPrices.openPurchasePrice;
      const family = this.familyStore.list.get(product.familyId);
      const productSensitivity = this.productSensitivityStore.list.get(product.productSensitivityId);

      const familyBindings: FamilyBinding | boolean =
        family && family.bindings.find((binding) => binding.productId === productId);

      const reports = [
        {
          name: 'competitor_price_change_report',
          icon: IconType.reports,
          tooltipOverlay: translate(PageNamesEnum.CompetitorsPriceChangeReport),
          linkProps: {
            to: `${getPath(PagePathsEnum.CompetitorsPriceChangeReport)}?${stringify({
              categoryId: this.productCategory.id,
              productId: product.id,
            })}`,
          },
        },
        // {
        //   name: 'missing_competitor_price_report',
        //   icon: IconType.reports,
        //   tooltipOverlay: translate(PageNamesEnum.MissingCompetitorPriceReport),
        //   linkProps: {
        //     to: `${getPath(PagePathsEnum.MissingCompetitorPriceReport)}?${stringify({
        //       categoryId: this.productCategory.id,
        //       productId: product.id,
        //     })}`,
        //   },
        // },
        {
          name: 'pair',
          icon: IconType.pair,
          tooltipOverlay: translate(PageNamesEnum.DataPairing),
          linkProps: {
            to: `${getPath(
              product.numberOfProductBindings > 0 ? PagePathsEnum.DataPairingPaired : PagePathsEnum.DataPairingUnpaired,
              this.productCategory.id,
            )}?${stringify({
              internalQuery: `"${product.name}"`,
            })}`,
          },
        },
      ];
      /** Gets array of all zones in key - value pairs for generating of row */
      this.priceZoneStore.listNotArchived.forEach((zone) => {
        const {prices} = internalProductPrices && internalProductPrices;
        prices
          .toArray()
          .map(({actualRegularPrice, actualMarginPercentage, actualMarginPrice, unitPrice, priceZoneId}) => {
            priceZonePricesData[`${priceZoneId}_actualRegularPrice`] = actualRegularPrice;
            priceZonePricesData[`${priceZoneId}_actualMarginPercentage`] = actualMarginPercentage;
            priceZonePricesData[`${priceZoneId}_actualMarginPrice`] = actualMarginPrice;
            priceZonePricesData[`${priceZoneId}_unitPrice`] = unitPrice;
          });
        this.competitorsInZones.forEach((competitor) => {
          const crpDTO = this.CRPStore.getDTOForCompetitorAndZone(productId, competitor.id, zone.id);

          if (crpDTO) {
            const {newPrice, actualPrice, flags, internalVsCompetitorReferencePriceChangeIndex} = crpDTO;

            const roundedInternalVsCompetitorReferencePriceChangeIndex = internalVsCompetitorReferencePriceChangeIndex
              ? internalVsCompetitorReferencePriceChangeIndex
              : undefined;

            const index =
              !Utils.isValueMissing(newPrice) &&
              !Utils.isValueMissing(actualPrice) &&
              !Utils.isValueMissing(newPrice.modifiedValue) &&
              !Utils.isValueMissing(actualPrice.modifiedValue)
                ? PriceFormulas.computePriceChangeIndexPercent(newPrice.modifiedValue, actualPrice.modifiedValue)
                : null;
            competitorsInZones[`actual_${zone.id}_${competitor.id}`] = actualPrice
              ? {price: actualPrice.modifiedValue}
              : null;
            competitorsInZones[`new_${zone.id}_${competitor.id}`] = newPrice
              ? {
                  price: newPrice.modifiedValue,
                  flags,
                }
              : null;
            competitorsInZones[`index_${zone.id}_${competitor.id}`] = index;
            competitorsInZones[
              `internal_${zone.id}_${competitor.id}`
            ] = roundedInternalVsCompetitorReferencePriceChangeIndex;
          }
        });
      });
      rowData.push({
        ...this.productColumnGenerator.getColumnData({
          ...product,
          flags: getProductFlags(product),
        } as Product), // LOG-4639
        ...packageSizeHelper.getColumnData(product.packageSize),
        ...this.familyColumnGenerator.getColumnData(family),
        ...this.familyBindingsColumnGenerator.getColumnData(familyBindings),
        ...this.priceZonePricesGenerator.getColumnData(priceZonePricesData),
        ...this.internalProductPricesColumnGenerator.getColumnData({purchasePrice, openPurchasePrice}),
        ...this.categoryColumnGenerator.getColumnData({
          box: this.productCategoryStore.list.get(Utils.getCategoryIdWithLevel(product.categoryIds, 6)).name,
        }),
        ...this.customColumnGenerator.getColumnData(competitorsInZones),
        reports,
        productSensitivity: productSensitivity ? productSensitivity.title : CONSTANTS.FORMAT.NULL,
      });
    });

    return rowData;
  }

  /** Binds ag-grid api */
  @action.bound
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.updateFilter(params);
  }

  /** Returns default (0) count for every scale option */
  @computed
  get defaultPriceChangesCount() {
    const defaultCount = [];
    // reverse due to we need the scale in the filter shown in reverse order than names of scale constants
    Array.from(CONSTANTS.DATA_CLEANING.PRICE_CHANGES_SCALE.keys())
      .reverse()
      .forEach((key: string) => defaultCount.push([key, 0]));

    return defaultCount;
  }

  /** Updates external filters after init and changes in column visibility */
  @action.bound
  updateFilter(params: GridReadyEvent | ColumnVisibleEvent) {
    const scaleOptions: Map<string, number> = new Map(this.defaultPriceChangesCount);
    const visibleColumns: Map<string, boolean> = new Map();
    const showedZones = [];
    const showedCompetitors = [];

    // Filter out the hidden columns
    params.columnApi.getAllColumns().forEach((column) => {
      visibleColumns.set(column.getColId(), column.isVisible());

      if (column.isVisible()) {
        const zoneId = column.getColId().split('_')[1];
        const competitorId = column.getColId().split('_')[2];

        if (zoneId && !showedZones.includes(zoneId)) {
          showedZones.push(zoneId);
        }
        if (competitorId && !showedCompetitors.includes(competitorId)) {
          showedCompetitors.push(competitorId);
        }
      }
    });

    // Iterate through every table row
    params.api.forEachNodeAfterFilter((node: RowNode) => {
      /*
       * LOG-4063: Counting only one the most extreme value per row to showing as a number in the scale filter:
       * - In case of at least one negative value -> we should take extreme of negative values
       * - In case of all positive values -> we should take extreme of positive values
       */
      let mostExtremeValue = 0;
      let whichScaleShouldBeIncremented = 'transparent'; // default scale

      // Iterate through all zone - competitor column pairs
      for (const actualNew of this.columnIds.values()) {
        // Check wether columns are visible
        if (visibleColumns.get(actualNew[0]) && visibleColumns.get(actualNew[1])) {
          // Get prices and calculate ratio
          const actualPrice = node.data[this.customColumnsDescription[actualNew[0]].description.nameKey];
          const newPrice = node.data[this.customColumnsDescription[actualNew[1]].description.nameKey];

          if (
            !Utils.isValueMissing(newPrice) &&
            !Utils.isValueMissing(actualPrice) &&
            !Utils.isValueMissing(newPrice.price) &&
            !Utils.isValueMissing(actualPrice.price)
          ) {
            const index = PriceFormulas.computePriceChangeIndexPercent(newPrice.price, actualPrice.price);
            const indexAsNumber = index.toNumber();
            let currentScale = 'transparent';

            // Finding current scale for the index value
            for (const scale of this.scales.keys()) {
              // Test if the index value belongs to the scale
              if (this.scales.get(scale)(index)) {
                currentScale = scale;
                // scaleOptions.set(scale, scaleOptions.get(scale) + 1);
                break;
              }
            }

            // we have negative value and we have been finding negative extreme
            if (indexAsNumber < 0 && mostExtremeValue < 0) {
              if (indexAsNumber < mostExtremeValue) {
                mostExtremeValue = indexAsNumber;
                whichScaleShouldBeIncremented = currentScale;
              }
            }
            // we have negative value but we were finding positive extreme
            else if (indexAsNumber < 0 && mostExtremeValue >= 0) {
              mostExtremeValue = indexAsNumber;
              whichScaleShouldBeIncremented = currentScale;
            }
            // we have positive/zero value and we have been finding positive extreme
            else if (indexAsNumber >= 0 && mostExtremeValue >= 0) {
              if (indexAsNumber > mostExtremeValue) {
                mostExtremeValue = indexAsNumber;
                whichScaleShouldBeIncremented = currentScale;
              }
            }
          }
        }
      }
      scaleOptions.set(whichScaleShouldBeIncremented, scaleOptions.get(whichScaleShouldBeIncremented) + 1);
    });

    // Update filters
    this.scaleOptions = scaleOptions;
    this.showedZones = showedZones;
    this.showedCompetitors = showedCompetitors;
  }

  /** Updates selected competitors */
  @action.bound
  filterCompetitors(ids: string[]) {
    this.showedCompetitors = ids;
    this.filterColumns();
  }

  /** Updates selected zones */
  @action.bound
  filterZones(ids: string[]) {
    this.showedZones = ids;
    this.filterColumns();
  }

  /** Changes visible columns depending on selected competitors and zones */
  filterColumns() {
    const show: string[] = [];
    const hide: string[] = [];

    for (const k of this.columnIds.keys()) {
      if (this.showedZones.includes(k.split('_')[0]) && this.showedCompetitors.includes(k.split('_')[1])) {
        show.push(...this.columnIds.get(k));
      } else {
        hide.push(...this.columnIds.get(k));
      }
    }

    this.columnApi.setColumnsVisible(show, true);
    this.columnApi.setColumnsVisible(hide, false);
  }

  /** Updates selected price changes range  */
  @action.bound
  filterOutliers(ids: string[]) {
    this.showedPriceChanges = ids;
    this.gridApi.onFilterChanged();
  }

  /** Checks if there's external filter set for families  */
  @action.bound
  isExternalFilterPresent(): boolean {
    return this.showedPriceChanges.length !== this.scales.size;
  }

  onFilterOrSortChanged = () => {
    if (this.priceHistoryModalStore.modalHidden) {
      this.priceHistoryModalStore.updateListOfShownRows();
    }
  };

  /** Function that checks products against filter parameters - fulltext and checkbox filters */
  @action.bound
  doesExternalFilterPass(node: RowNode): boolean {
    let pass = false;
    this.showedPriceChanges.forEach((scale) => {
      if (pass) {
        return;
      }
      this.columnIds.forEach((columns) => {
        const actualPrice = node.data[this.customColumnsDescription[columns[0]].description.nameKey];
        const newPrice = node.data[this.customColumnsDescription[columns[1]].description.nameKey];
        let index: BigDecimal;

        if (
          !Utils.isValueMissing(newPrice) &&
          !Utils.isValueMissing(actualPrice) &&
          !Utils.isValueMissing(newPrice.price) &&
          !Utils.isValueMissing(actualPrice.price)
        ) {
          index = PriceFormulas.computePriceChangeIndexPercent(newPrice.price, actualPrice.price);
        } else {
          index = BigDecimal.ZERO;
        }

        if (this.scales.get(scale)(index)) {
          pass = true;
          return;
        }
      });
    });

    return pass;
  }

  /** Approves this category */
  public onCategoryApprove = async (): Promise<void> => {
    const {progress} = await this.categoryCleaningStateStore.approveCategories([this.productCategory.id]);
    this.pollingHelper.startPolling(progress.id, 'sellout-identification');
  };

  /** Handles polling response */
  private onPollingStateChanged = async (pollingState: LoadingState) => {
    if (pollingState === LoadingState.Success) {
      this.history.push(getPath(PagePathsEnum.DataCleaningCategories));
    }
  };

  public pollingHelper = new PollingHelper(this.messages, this.onPollingStateChanged, 1000);

  /** Fetches all data for this page */
  @action.bound
  load(categoryId: string): void {
    this.lastCategoryId = categoryId;
    this.setLoadingState(LoadingState.Pending);
    this.categoryCleaningStateStore
      .getCleaningInProgress()
      .then((cleaningInProgress) => {
        if (cleaningInProgress) {
          runInAction(() => {
            this.cleaningInProgress = true;
            this.setLoadingState(LoadingState.Error);
          });
        } else {
          // LOG-3926 clear values after approved
          runInAction(() => {
            this.CRPStore.CRPAndFlags.clear();
          });
          Promise.all([
            this.productCategoryStore.getOne(categoryId),
            this.productStore.getInCategory(categoryId),
            this.internalProductPricesStore.getInCategory(categoryId),
            this.productStore.getUnits(),
            this.priceZoneStore.getAll(),
            this.familyStore.getAll(),
            this.competitors.getAll(),
            this.competitorSiteStore.getAll(),
            this.supplierStore.getAll(),
            this.CRPStore.getCRPAndFlags({categoryId}),
          ])
            .then(([productCategory]) => {
              Promise.all([
                this.categoryCleaningStateStore.getAll(),
                this.globalSettingsStore.getAll(),
                this.productCategoryStore.getAll(),
                this.productSensitivityStore.getAll(),
              ])
                .then(async () => {
                  const competitorIds: string[] = [];
                  const zoneIds: string[] = [];
                  const colIds: Map<string, string[]> = new Map();

                  // Create data description for each price zone
                  this.priceZoneStore.list.forEach((zone) => {
                    if (!zone.archived) {
                      zoneIds.push(zone.id);
                    }
                    // Stores column id of every competitor - zone column and adds it to schema
                    this.competitorsInZones.forEach((competitor) => {
                      if (zone.id && !zone.archived && competitor.id) {
                        if (competitor.id && !competitorIds.includes(competitor.id)) {
                          competitorIds.push(competitor.id);
                        }
                        // avoid duplicates
                        const columnIds = colIds.get(zone.id + '_' + competitor.id) || [];

                        // Map actual and new price columns to zone_competitor ids string
                        colIds.set(zone.id + '_' + competitor.id, [
                          ...columnIds,
                          `actual_${zone.id}_${competitor.id}`,
                          `new_${zone.id}_${competitor.id}`,
                          `index_${zone.id}_${competitor.id}`,
                          `internal_${zone.id}_${competitor.id}`,
                        ]);

                        // Add actual and new price columns to schema
                        this.customColumnsDescription[`actual_${zone.id}_${competitor.id}`] = this.builder.bigNum(
                          `actual_${zone.id}_${competitor.id}`,
                        );
                        this.customColumnsDescription[`new_${zone.id}_${competitor.id}`] = this.builder.bigNum(
                          `new_${zone.id}_${competitor.id}`,
                        );
                        this.customColumnsDescription[`index_${zone.id}_${competitor.id}`] = this.builder.bigNum(
                          `index_${zone.id}_${competitor.id}`,
                        );
                        this.customColumnsDescription[`internal_${zone.id}_${competitor.id}`] = this.builder.bigNum(
                          `internal_${zone.id}_${competitor.id}`,
                        );
                      }
                    });
                  });
                  this.customColumnGenerator = new ColumnGenerator<StringMapping<any>>(this.customColumnsDescription);

                  runInAction(() => {
                    this.showedZones = zoneIds;
                    this.showedCompetitors = competitorIds;
                    this.columnIds = colIds;
                    this.productCategory = productCategory;
                    this.categoryCleaningState = this.categoryCleaningStateStore.list.get(productCategory.id);
                  });
                  this.createPriceZoneGenerator();

                  if (this.canUserEdit) {
                    await this.lockCategory();
                  }
                  this.setLoadingState(LoadingState.Success);
                })
                .catch((error) => {
                  this.messages.setError(error);
                  this.setLoadingState(LoadingState.Error);
                });
            })
            .catch((error) => {
              this.messages.setError(error);
              this.setLoadingState(LoadingState.Error);
            });
        }
      })
      .catch((error) => {
        this.messages.setError(error);
        this.setLoadingState(LoadingState.Error);
      });
  }

  /**
   * Negate modalHiddenProp
   *
   * @param title - name of the modalHidden prop that should be changed
   */
  toggleModal = (title: string) => runInAction(() => (this.modalHidden[title] = !this.modalHidden[title]));

  /**
   * HOF for toggle modal
   */
  getModalToggleEvent = (title: string) => () => this.toggleModal(title);

  /** Function locks category for user and set page to read only mode if lock action has not been allowed
   */
  lockCategory = async () => {
    try {
      await this.resourceLockLayer.lockResource(this.productCategory.id, ResourceLockType.CleaningCategory);
      this.setReadOnly(false);
      this.messages.setInfo(translate('data-cleaning-locked'));
      startBlockingRouteNavigation('history-push-modal', 'cleaning-unlock-confirmation');
      return;
    } catch (error) {
      this.setReadOnly(true);
      this.messages.setError(error);
    }
  };

  /**
   * Function unlocks category for other users and set page to read only mode if unlock action succeed
   */
  unlockCategory = async (withMessage = true) => {
    try {
      stopBlockingRouteNavigation();
      await this.resourceLockLayer.unlockResource(this.productCategory.id, ResourceLockType.CleaningCategory);
      if (withMessage) {
        this.messages.setWarning(translate('data-cleaning-read-only'));
      }
      this.setReadOnly(true);
    } catch (error) {
      this.messages.setError(error);
    }
  };
}
