"use strict";
/*
 * Created on Fri Aug 17 2018
 */

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * DTO object for page that displays overview of all general settings or release settings.
 * This object represents part of single line, more specifically,
 * the status of whether there exists some {@link Settings settings} for a particular zone.
 *
 * @author RV
 */

var SettingsZonesOverviewDTO =
/**
 *
 * @param zoneId
 * @param isInherited
 */
function SettingsZonesOverviewDTO(zoneId, isInherited) {
  _classCallCheck(this, SettingsZonesOverviewDTO);

  this.zoneId = zoneId;
  this.isInherited = isInherited;
};

exports.SettingsZonesOverviewDTO = SettingsZonesOverviewDTO;