/**
 * @file Created on Thu Sep 27 2018
 * @author SKu
 * @author VBr
 */

import {
  AgGrid,
  AgGridWrapper,
  Page,
  FormGroupInline,
  ButtonLoading,
  translate,
  ButtonSize,
  Tooltip,
  Icon,
  IconType,
  ProgressBar, LoadingState,
} from '@logio/common-fe';
import {observer, Observer} from 'mobx-react';
import * as React from 'react';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {DataPairingCategoriesPageStore} from '../../../stores/pages/DataManagement/DataPairing/DataPairingCategoriesPageStore';

@observer
class DataPairingCategoriesPage extends React.Component {
  store = new DataPairingCategoriesPageStore();

  componentWillMount() {
    this.store.load();
  }

  render() {
    const {
      loadingState,
      columnDefs,
      CategoryTraverseHelper: {treeData, categoryAutoGroupColumnDef, getDataPath},
    } = this.store;

    return (
      <Page titleKey={PageNamesEnum.DataPairing} loadingState={loadingState}>
        {/* This will render downloading modal when exported file is ready, initialized by store.export() */}
        {this.store.downloadStore.renderModal()}
        {this.store.pollingHelper && (this.store.pollingHelper.pollingState === LoadingState.Pending) && (
          <div className="mb2">
            <ProgressBar
              percentage={this.store.pollingHelper.progress || 0}
              label={'export-loading-progress'}
            />
          </div>
        )}
        <FormGroupInline right>
          <ButtonLoading
            buttonSize={ButtonSize.Tiny}
            isLoading={this.store.exportIsLoading}
            onClick={() => this.store.export()}
          >
            <Tooltip
              overlay={translate("DataPairing_ExportTooltip")}
              placement="bottom"
              noHeight
              children={
                <div>
                  <Icon iconType={IconType.exportToExcel} />
                </div>
              }
            />
          </ButtonLoading>
        </FormGroupInline>
        <Observer>
          {() => (
            <AgGridWrapper>
              <AgGrid
                rowData={treeData}
                columnDefs={columnDefs}
                autoGroupColumnDef={categoryAutoGroupColumnDef}
                getDataPath={getDataPath}
                headerHeight={50}
                treeData
                gridId="PricingDataPairingCategoriesPage"
                quickView
                saveTreeDataExpansionId="CategoriesTree"
                groupDefaultExpanded={3}
              />
            </AgGridWrapper>
          )}
        </Observer>
      </Page>
    );
  }
}

export default DataPairingCategoriesPage;
