/**
 * @author PKr
 */
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Utils } from '@logio/common-be-fe';
import Tooltip from '../../../../info/Tooltip';
import translate from '../../../../i18n/translate/translate';
import { ButtonSize } from "../../../../controls/Button";
import ButtonLoading from "../../../../controls/Button/ButtonLoading";
/**
 * Checkbox Input renderer (with disabled edit of column)
 */

var ButtonActionsRenderer = function ButtonActionsRenderer(props) {
  var _a, _b, _c;

  var actions = props.value;
  var rootId = ((_b = (_a = props.node) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.entityId) || 'bulk';
  var isLoadingFunc = rootId === 'bulk' && ((_c = props.colDef.pinnedRowCellRendererParams) === null || _c === void 0 ? void 0 : _c.isLoading) ? props.colDef.pinnedRowCellRendererParams.isLoading : props.isLoading;
  var isLoading = isLoadingFunc && isLoadingFunc(rootId); // Getter for button

  var getButton = function getButton(action) {
    var btn = React.createElement(ButtonLoading, {
      buttonSize: ButtonSize.Tiny,
      disabled: action.disabled,
      isLoading: isLoading
    }, translate(action.name));
    return action.tooltipOverlay ? React.createElement(Tooltip, {
      overlay: action.tooltipOverlay,
      children: React.createElement("div", null, btn)
    }) : btn;
  };

  if (Utils.isValueMissing(actions)) {
    return null;
  }

  var onClickEvent = function onClickEvent(action, event) {
    if (props.data.modalControl) {
      props.data.modalControl(action, {
        event: event,
        props: props
      });
    } else {
      if (action.onClick) {
        action.onClick(event, props);
      }

      if (action.props && action.props.onClick) {
        action.props.onClick(event);
      }
    }
  };

  return React.createElement(React.Fragment, null, actions.map(function (action) {
    return React.createElement("span", Object.assign({
      key: action.name
    }, action.props, {
      onClick: function onClick(e) {
        onClickEvent(action, e);
      }
    }), action.linkProps && !action.disabled ? React.createElement(Link, Object.assign({}, action.linkProps), getButton(action)) : getButton(action));
  }));
};

export default ButtonActionsRenderer;