"use strict";
/*
 * Created on 08 06 2020
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author Jaroslav Sevcik
 */

var SimulationState;

(function (SimulationState) {
  /** without any simulated value / all are accepted */
  SimulationState["Empty"] = "EMPTY";
  /** waiting for approval/rejection */

  SimulationState["Pending"] = "PENDING";
  /** at least one simulated value rejected */

  SimulationState["Rejected"] = "REJECTED";
})(SimulationState = exports.SimulationState || (exports.SimulationState = {}));