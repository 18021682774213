/**
 * @file Created on Fri Nov 16 2018
 * @author SKu
 */
import { Utils } from '@logio/common-be-fe';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { CONSTANTS } from '../../../../../constants';
import translate, { translateResultObject } from '../../../../i18n/translate';
import Tooltip from '../../../../info/Tooltip';
import Icon, { IconType, IconColor } from '../../../../media/Icon';
import { N } from '../../../../i18n/N/';
export var ReleaseFinalPriceRenderer = observer(function (props) {
  var cellRendererParams = props.colDef.cellRendererParams;
  var limit = cellRendererParams && cellRendererParams.limit;
  var priceZoneId = cellRendererParams && cellRendererParams.priceZoneId;
  var finalPrice = props.value;
  var regularPriceChangeIndexPercent = props.node.data["ReleaseDetailCategory_".concat(priceZoneId, "_regularPriceChangeIndexPercent")];
  var noFinalPrice = React.createElement(Grid, {
    fluid: true,
    style: {
      padding: 0
    }
  }, React.createElement(Row, null, React.createElement(Col, {
    className: "p0 tac",
    xs: 4
  }, CONSTANTS.FORMAT.NULL), React.createElement(Col, {
    className: "p0 tac",
    xs: 3
  }, alert), React.createElement(Col, {
    className: "p0 tac",
    xs: 5
  }, CONSTANTS.FORMAT.NULL)));

  if (props.value) {
    var _alert = React.createElement(React.Fragment, null, " ", CONSTANTS.FORMAT.NULL, " ");

    if (finalPrice && finalPrice.validationResult.resultObjects.size > 0) {
      var tooltipInfo = finalPrice.validationResult.resultObjects.map(function (resultObject, index) {
        return React.createElement("li", {
          key: index
        }, translateResultObject(resultObject));
      });
      _alert = React.createElement(Tooltip, {
        overlay: tooltipInfo,
        children: React.createElement("div", null, React.createElement(Icon, {
          iconType: IconType.alert,
          iconColor: IconColor.Error,
          iconHeight: 15
        }))
      });
    }

    var getIndexColorClassName = function getIndexColorClassName() {
      if (regularPriceChangeIndexPercent.value <= -limit) {
        return 'c-red-cell-text';
      }

      if (regularPriceChangeIndexPercent.value > limit) {
        return 'c-green-cell-text';
      }

      return "";
    };

    if (props.value && !Utils.isValueMissing(finalPrice) && !Utils.isValueMissing(finalPrice.priceType) && (!Utils.isValueMissing(finalPrice.value) || !Utils.isValueMissing(finalPrice.valueRounded))) {
      return React.createElement(Grid, {
        fluid: true,
        style: {
          padding: 0
        }
      }, React.createElement(Row, null, React.createElement(Col, {
        className: "p0 tac",
        xs: 4
      }, translate("".concat(finalPrice.priceType, "-finalPrice"))), React.createElement(Col, {
        className: "p0 tac",
        xs: 3
      }, _alert), React.createElement(Col, {
        className: "p0 tac " + (regularPriceChangeIndexPercent && limit && "fw500 ".concat(getIndexColorClassName())),
        xs: 5
      }, React.createElement(N, {
        className: regularPriceChangeIndexPercent && limit && "fw500 ".concat(getIndexColorClassName()),
        value: finalPrice.valueRounded || finalPrice.value
      }))));
    } else {
      return noFinalPrice;
    }
  } else {
    return noFinalPrice;
  }
});