"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function initValueDifferenceBigNumber(oldValue, newValue) {
  return {
    oldValue: oldValue,
    newValue: newValue,
    difference: oldValue && newValue && newValue.sub(oldValue) || null
  };
}

exports.initValueDifferenceBigNumber = initValueDifferenceBigNumber;

function deserializeValueDifferenceBigNumber(conversions) {
  return function (document) {
    return {
      oldValue: document.oldValue ? conversions.toBigNumber(document.oldValue) : null,
      newValue: document.newValue ? conversions.toBigNumber(document.newValue) : null,
      difference: document.difference ? conversions.toBigNumber(document.difference) : null
    };
  };
}

exports.deserializeValueDifferenceBigNumber = deserializeValueDifferenceBigNumber;

function serializeValueDifferenceBigNumber(conversions) {
  return function (object) {
    return {
      oldValue: object.oldValue ? conversions.fromBigNumber(object.oldValue) : null,
      newValue: object.newValue ? conversions.fromBigNumber(object.newValue) : null,
      difference: object.difference ? conversions.fromBigNumber(object.difference) : null
    };
  };
}

exports.serializeValueDifferenceBigNumber = serializeValueDifferenceBigNumber;

function initValueDifferenceMoment(oldValue, newValue) {
  return {
    oldValue: oldValue,
    newValue: newValue,
    difference: null
  };
}

exports.initValueDifferenceMoment = initValueDifferenceMoment;

function deserializeValueDifferenceMoment(conversions) {
  return function (document) {
    return {
      oldValue: document.oldValue ? conversions.toDate(document.oldValue) : null,
      newValue: document.newValue ? conversions.toDate(document.newValue) : null,
      difference: document.difference ? conversions.toDate(document.difference) : null
    };
  };
}

exports.deserializeValueDifferenceMoment = deserializeValueDifferenceMoment;

function serializeValueDifferenceMoment(conversions) {
  return function (object) {
    return {
      oldValue: object.oldValue ? conversions.fromDate(object.oldValue) : null,
      newValue: object.newValue ? conversions.fromDate(object.newValue) : null,
      difference: object.difference ? conversions.fromDate(object.difference) : null
    };
  };
}

exports.serializeValueDifferenceMoment = serializeValueDifferenceMoment;

function initValueDifferenceString(oldValue, newValue) {
  return {
    oldValue: oldValue,
    newValue: newValue,
    difference: null
  };
}

exports.initValueDifferenceString = initValueDifferenceString;

function deserializeValueDifferenceString() {
  return function (document) {
    return {
      oldValue: document.oldValue ? document.oldValue : null,
      newValue: document.newValue ? document.newValue : null,
      difference: document.difference ? document.difference : null
    };
  };
}

exports.deserializeValueDifferenceString = deserializeValueDifferenceString;

function serializeValueDifferenceString() {
  return function (object) {
    return {
      oldValue: object.oldValue ? object.oldValue : null,
      newValue: object.newValue ? object.newValue : null,
      difference: object.difference ? object.difference : null
    };
  };
}

exports.serializeValueDifferenceString = serializeValueDifferenceString;