/**
 * @file Created on Tue Aug 28 2018
 * @author VBr
 */

import {CodebookLookupSequence, ProductCategory, RangeSliderValidator, StructPropBuilder} from '@logio/common-be-fe';
import {
  AgGrid,
  Button,
  Field,
  Form,
  FormGroup,
  Loader,
  LoadingState,
  Messages,
  Search,
  TabNavigation,
  translate,
  Select,
  FormGroupInline,
} from '@logio/common-fe';
import {Observer} from 'mobx-react';
import * as React from 'react';
import {Col, Grid, Row} from 'react-flexbox-grid';
import {packageSizeHelper} from '../../../shared/packageSizeHelper';
import AssignToFamilyModalStore from '../../../stores/pages/DataManagement/PriceArchitecture/AssignToFamilyModalStore';
import {PriceArchitectureProductsPageStore} from '../../../stores/pages/DataManagement/PriceArchitecture/PriceArchitectureProductsPageStore';

interface Props {
  productsStore: PriceArchitectureProductsPageStore;
}

class AssignToFamilyModal extends React.Component<Props> {
  store = new AssignToFamilyModalStore(this.props.productsStore);
  builder = new StructPropBuilder('assign-family');

  componentWillMount() {
    this.store.load();
  }

  private get filterFamilyDescriptions() {
    return {
      supplier: this.builder.opt(this.builder.arr(this.builder.str('supplier'))),
      purchase_price: this.builder.arr(
        this.builder.num('purchase_price'),
        new RangeSliderValidator(
          Math.floor(this.props.productsStore.minPurchasePrice),
          Math.ceil(this.props.productsStore.maxPurchasePrice),
        ),
      ),
      box: this.builder.opt(
        this.builder.arr(
          this.builder.lookup(
            'box',
            `${ProductCategory.CATEGORIES_LOOKUP}?level=6&parentId=${this.props.productsStore.productCategory.id}`,
          ),
        ),
      ),
    };
  }

  private get createFamilyDescriptions() {
    return {
      name: this.builder.str('name'),
      tier: this.builder.lookup('tier', CodebookLookupSequence.Tier),
    };
  }

  private get updateFamilyDescriptions() {
    return {
      name: this.builder.str('name'),
      tierId: this.builder.lookup('tier', CodebookLookupSequence.Tier),
    };
  }

  render() {
    // NOTE: Do not wrap the whole component in Observer, the filter form would cause rendering cycle
    return (
      <Grid fluid className="relative minh30 bg-inverse">
        <Observer>
          {() => (
            <>
              {this.store.loadingState === LoadingState.Pending && <Loader className="bg-inverse" />}
              <Messages clearOnMount />
            </>
          )}
        </Observer>
        <Row>
          <Col xs={12} md={6} className="form-group-wide">
            <Form
              onSubmit={() => null}
              descriptions={this.filterFamilyDescriptions}
              onChange={this.store.updateFilter}
              initialValues={this.store.initialFilterFamilyValues}
            >
              <Field
                name="supplier"
                component={({input}) => (
                  <FormGroup>
                    <Select
                      multi
                      name="supplier"
                      value={input.value}
                      label={translate('Product_supplierIds')}
                      onChange={input.onChange}
                      options={this.store.familiesSuppliersOptions}
                    />
                  </FormGroup>
                )}
              />
              <Field name="purchase_price" />
              <Field name="box" />
            </Form>
            <br />
            <Observer>
              {() => (
                <>
                  {this.store.loadingState === LoadingState.Success && (
                    <TabNavigation
                      onSelect={this.store.selectTab}
                      fullWidth={true}
                      content={[
                        {
                          tabName: translate('assign_to_family-suggested_families'),
                          tabContent: (
                            <>
                              <FormGroup>
                                <Search
                                  placeholder={translate('assign_to_family-search-family')}
                                  value={this.store.searchFamilyText}
                                  onChange={this.store.searchFamily}
                                  onReset={this.store.resetSearchFamilyFilter}
                                />
                              </FormGroup>
                              <AgGrid
                                className="pt1"
                                rowData={this.store.familiesData}
                                columnDefs={this.store.familiesDefs}
                                onGridReady={this.store.onFamiliesReady}
                                onRowSelected={this.store.onRowSelected}
                                height="333px"
                                suppressRowTransform={true}
                                suppressAggFuncInHeader={true}
                                lockMovableGroups={true}
                                isExternalFilterPresent={this.store.isExternalFamilyFilterPresent}
                                doesExternalFilterPass={this.store.doesFamilyFilterPass}
                                gridId="PricingAssignToFamilyModalFamilies"
                              />
                              <div className="mt1">
                                <Button onClick={this.store.assignFamily} disabled={!this.store.selectedFamily}>
                                  {translate('assign_to_family-assign_button')}
                                </Button>
                              </div>
                            </>
                          ),
                        },
                        {
                          tabName: translate('assign_to_family-new_family'),
                          tabContent: (
                            <Form
                              onSubmit={this.store.assignFamily}
                              initialValues={this.store.newFamilyValues}
                              descriptions={this.createFamilyDescriptions}
                              onChange={this.store.updateFamily}
                            >
                              <Field name="name" />
                              <Field name="tier" />
                              <div className="mt1">
                                <Button>{translate('assign_to_family-assign_button')}</Button>
                              </div>
                            </Form>
                          ),
                        },
                      ]}
                    />
                  )}
                </>
              )}
            </Observer>
          </Col>
          <Col xs={12} md={6}>
            <Observer>
              {() => (
                <>
                  {this.store.loadingState === LoadingState.Success && (
                    <>
                      {this.store.selectedFamily && (
                        <Form
                          onSubmit={this.store.assignFamily}
                          initialValues={
                            this.store.selectedFamily.tierId
                              ? this.store.selectedFamily
                              : this.store.selectedFamily.copy('tierId', this.store.tiers.defaultTier.id)
                          }
                          descriptions={this.updateFamilyDescriptions}
                          onChange={this.store.updateSelectedFamily}
                        >
                          <Field name="name" />
                          <Field name="tierId" />
                        </Form>
                      )}
                      <h3>{translate('assign_to_family-products_in_family')}</h3>
                      {this.store.selectedFamily || this.store.newFamily ? (
                        <AgGrid
                          rowData={this.store.productsData}
                          columnDefs={this.store.productsDefs}
                          onGridReady={this.store.onProductsReady}
                          height={this.store.selectedFamily ? '511px' : '614px'}
                          suppressRowTransform={true}
                          suppressAggFuncInHeader={true}
                          deltaRowDataMode={true}
                          getRowNodeId={({Product_id: id}) => id}
                          lockMovableGroups={true}
                          onColumnVisible={packageSizeHelper.onColumnVisible}
                          getRowClass={this.store.rowClass}
                          gridId="PricingAssignToFamilyModalProducts"
                          quickView
                        />
                      ) : (
                        <p>{translate('assign_to_family-select_family')}</p>
                      )}
                    </>
                  )}
                </>
              )}
            </Observer>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default AssignToFamilyModal;
