import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  .accordion-header {\n    display: flex;\n    align-items: center;\n    height: 40px;\n    color: ", ";\n    background-color: ", ";\n    border-top: 1px solid ", ";\n    border-left: 1px solid ", ";\n    border-right: 1px solid ", ";\n    user-select: none;\n    \n    &--label {\n      flex: 1;\n      margin: 0 0.6rem;\n      padding: 0;\n    }\n    \n    &--icon {\n      margin: 0 0 0 0.6rem;\n    }\n    \n    &--iconArrow {\n      margin: 0 0.6rem 0 0;\n    }\n  }\n  \n  .accordion-inner {}\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Tue Jul 16 2019
 * @author Tomáš Leity
 */
import styled, { withTheme } from 'styled-components';
var AccordionSectionStyled = styled.div(_templateObject(), function (props) {
  return props.theme.colors.text;
}, function (props) {
  return props.theme.colors.even;
}, function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.colors.border;
});
export default withTheme(AccordionSectionStyled);