"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Resolver for implementation of {@link AbstractConstraintConverter} based on criteria.
 *
 * @author RV
 */

var ConstraintConverterResolver = function ConstraintConverterResolver() {
  _classCallCheck(this, ConstraintConverterResolver);
};

exports.ConstraintConverterResolver = ConstraintConverterResolver;