"use strict";
/*
 * Created on 4.12.2018
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Values are used as IDs for states in window workflow!
 *
 * @author MSu
 */

var immutable_1 = require("immutable"); // TODO briefly describe each state with respect to business.


var WindowStateEnum;

(function (WindowStateEnum) {
  WindowStateEnum["Incomplete"] = "INCOMPLETE";
  WindowStateEnum["Concept"] = "CONCEPT";
  WindowStateEnum["Prepared"] = "PREPARED";
  WindowStateEnum["RejectedApproval"] = "REJECTED_APPROVAL";
  WindowStateEnum["Approved"] = "APPROVED";
  WindowStateEnum["Changed"] = "CHANGED";
  WindowStateEnum["RejectedChange"] = "REJECTED_CHANGE";
  WindowStateEnum["Finalized"] = "FINALIZED";
  WindowStateEnum["Active"] = "ACTIVE";
  WindowStateEnum["Finished"] = "FINISHED";
  WindowStateEnum["Evaluated"] = "EVALUATED";
  WindowStateEnum["Canceled"] = "CANCELED";
})(WindowStateEnum = exports.WindowStateEnum || (exports.WindowStateEnum = {})); // NOTE: This list contains all WF states
// Even though it is redundant, it cannot be just removed
// WorkflowService.lockWorkflow is used for both Windows and Promotions and it expects this list as parameter


var lockableStatesEnumList = immutable_1.List.of(WindowStateEnum.Incomplete, WindowStateEnum.Concept, WindowStateEnum.Prepared, WindowStateEnum.RejectedApproval, WindowStateEnum.Approved, WindowStateEnum.Changed, WindowStateEnum.RejectedChange, WindowStateEnum.Canceled, WindowStateEnum.Active, WindowStateEnum.Finalized, WindowStateEnum.Finished, // for window copy
WindowStateEnum.Evaluated);
/**
 * Enumeration of window states in which selling/purchase prices should be recalculated.
 */

exports.priceRecalculationEnumList = immutable_1.List.of(WindowStateEnum.Concept, WindowStateEnum.Prepared, WindowStateEnum.RejectedApproval, WindowStateEnum.Approved, WindowStateEnum.Changed, WindowStateEnum.RejectedChange);
exports.forecastCoordinatorStateEnumList = immutable_1.List.of(WindowStateEnum.Approved, WindowStateEnum.Changed);
exports.lockableWindowStates = lockableStatesEnumList.map(function (state) {
  return state.toString();
});
/**
 * <p>Set of targeted window states, in which related promotion should be exported with respect to LOG-6552.</p>
 */

exports.exportableWindowStates = immutable_1.Set.of(WindowStateEnum.Prepared, WindowStateEnum.RejectedApproval, WindowStateEnum.Approved, WindowStateEnum.Changed, WindowStateEnum.RejectedChange, WindowStateEnum.Finalized, WindowStateEnum.Active, // PROMO-205
WindowStateEnum.Canceled);