/**
 * @file Created on Tue Nov 20 2018
 * @author BPo
 */

import {
  AbovePromotion,
  AverageMargin,
  Constraint,
  ConstraintSettings,
  ConstraintTypeEnum,
  ListingPriceProtection,
  Margin,
  MultiTargetStrategy,
  MultiTargetStrategyBuilder,
  OptimizationGoalsSettings,
  PriceChange,
  PriceDistance,
  PromoProtection,
  Range,
  RepricingFrequency,
  Settings,
  SingleTargetStrategy,
  SingleTargetStrategyBuilder,
  StrategySettings,
  StrategyTypeEnum,
  Utils,
  WeekDayRestriction,
} from '@logio/common-be-fe';
import { StringMapping } from '@logio/common-fe';
import { List } from 'immutable';
import { bigdecimal } from '@logio/big-decimal';

const getStrategySettings = (
  strategySettings: StrategyTypeEnum,
  competitorId?: string,
  competitorIds?: string[],
): MultiTargetStrategy | SingleTargetStrategy | null => {
  if (Utils.isArray(competitorIds) && competitorIds.length > 0) {
    const builder = new MultiTargetStrategyBuilder(strategySettings, List(competitorIds));
    return builder.build();
  }
  if (!Utils.isValueMissing(competitorId)) {
    const builder = new SingleTargetStrategyBuilder(strategySettings, competitorId);
    return builder.build();
  }

  return null;
};

const rebuildConstraint = (type: ConstraintTypeEnum, value: number | [number, number] | number[]): Constraint => {
  if (!Utils.isValueMissing(value)) {
    switch (type) {
      case ConstraintTypeEnum.Margin:
        return new Margin(new Range(bigdecimal(value[0]), bigdecimal(value[1])));
      case ConstraintTypeEnum.AverageMargin:
        return new AverageMargin(bigdecimal(value as number));
      case ConstraintTypeEnum.PriceChange:
        return new PriceChange(new Range(bigdecimal(value[0]), bigdecimal(value[1])));
      case ConstraintTypeEnum.AbovePromotion:
        return new AbovePromotion(bigdecimal(value as number));
      case ConstraintTypeEnum.ListingPriceProtection:
        return new ListingPriceProtection(bigdecimal(value as number));
      case ConstraintTypeEnum.PromoProtection:
        return new PromoProtection(bigdecimal(value as number));
      case ConstraintTypeEnum.RepricingFrequency:
        return new RepricingFrequency(bigdecimal(value as number));
      case ConstraintTypeEnum.WeekDayRestriction:
        if (Utils.isArray(value)) {
          const days = List(value.map(Number));
          return new WeekDayRestriction(days);
        }
    }
  }
  return null;
};

const getPriceDistance = (value: any): PriceDistance | null => {
  if (Utils.isArray(value) && value.length) {
    return new PriceDistance(new Range(bigdecimal(value[0]), bigdecimal(value[1])));
  }

  return null;
};

/**
 * Updates all fields in the current settings.
 *
 * @param settingsPar Existing settings that will be updated
 * @param constraintsArr Array of constraint types
 * @param values Flatten form data (or from another source)
 * @param releaseId Optional release ID
 */
export const SettingsUpdater = (
  settingsPar: Settings,
  constraintsArr: ConstraintTypeEnum[],
  values: StringMapping<any>,
  releaseId?: string,
): Settings => {
  let settings = settingsPar;
  // Get non-constraints initial values
  if (!Utils.isValueMissing(values.optimizationGoalsSettings)) {
    settings = settings.copy(
      'optimizationGoalsSettings',
      new OptimizationGoalsSettings(values.optimizationGoalsSettings),
    );
  } else {
    settings = settings.copy('optimizationGoalsSettings', null);
  }

  if (!Utils.isValueMissing(values.competitorHistoryLength)) {
    settings = settings.copy('competitorHistoryLength', values.competitorHistoryLength);
  } else {
    settings = settings.copy('competitorHistoryLength', null);
  }

  if (!Utils.isValueMissing(values.competitorsPriorityId)) {
    settings = settings.copy('competitorsPriorityId', values.competitorsPriorityId);
  } else {
    settings = settings.copy('competitorsPriorityId', null);
  }

  if (!Utils.isValueMissing(releaseId)) {
    settings = settings.copy('releaseId', releaseId);
  }

  const strategy = getStrategySettings(
    values[`strategySettings-${settings.productSensitivityId}`],
    values[`competitorId-${settings.productSensitivityId}`],
    values[`competitorIds-${settings.productSensitivityId}`],
  );

  const priceDistance = getPriceDistance(values[`priceDistance-${settings.productSensitivityId}`]);
  if (priceDistance || strategy) {
    settings = settings.copy('strategySettings', new StrategySettings(priceDistance, strategy));
  } else {
    settings = settings.copy('strategySettings', null);
  }

  // Get constraints initial values
  if (!Utils.isValueMissing(values[`competitorsPriorityId-${settings.productSensitivityId}`])) {
    settings = settings.copy('competitorsPriorityId', values[`competitorsPriorityId-${settings.productSensitivityId}`]);
  } else {
    settings = settings.copy('competitorsPriorityId', null);
  }
  let constraints: List<Constraint> = List();
  constraintsArr.forEach((constraintType) => {
    const value = values[`${constraintType}-${settings.productSensitivityId}`];
    const constraint = rebuildConstraint(constraintType, value);
    if (constraint) {
      constraints = constraints.push(constraint);
    }
  });
  const constraintMarginValue = values[ConstraintTypeEnum.Margin];
  const constraintMargin = rebuildConstraint(ConstraintTypeEnum.Margin, constraintMarginValue);
  if (constraintMargin) {
    constraints = constraints.push(constraintMargin);
  }
  const constraintAverageMarginValue = values[ConstraintTypeEnum.AverageMargin];
  const constraintAverageMargin = rebuildConstraint(ConstraintTypeEnum.AverageMargin, constraintAverageMarginValue);
  if (constraintAverageMargin) {
    constraints = constraints.push(constraintAverageMargin);
  }

  const constraintSettings = new ConstraintSettings(constraints);
  return settings.copy('constraintSettings', constraintSettings);
};
