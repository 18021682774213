/**
 * @file Created on Wed Aug 29 2018
 * @author SKu
 */
var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import * as React from 'react';
import ButtonGroup from '../../controls/ButtonGroup';
import StyledLabel from '../../typography/Label';
import Radio from '../Radio';
import withValidation from '../withValidation';
/**
 * RadioGroup component - get field of labels, and than render labels.lenght radio components
 * They all are connected(all group has just one value)
 * And through onCahnge  prop, returns one  checked value as a string
 */

var RadioGroup = function RadioGroup(props) {
  /**
   * Set checked value and call onChange prop
   */
  var handleChange = function handleChange(_ref) {
    var target = _ref.target;
    props.onChange(target.value);
  };

  return React.createElement(React.Fragment, null, props.groupLabel && React.createElement(StyledLabel, {
    htmlFor: props.name
  }, props.groupLabel), React.createElement(ButtonGroup, null, props.options && props.options.map(function (_a, index) {
    var label = _a.label,
        value = _a.value,
        htmlProps = __rest(_a, ["label", "value"]);

    return React.createElement(Radio, Object.assign({}, htmlProps, props, {
      key: index,
      label: label,
      id: "".concat(props.name).concat(index),
      checked: props.value === value,
      value: value,
      onChange: handleChange
    }));
  })));
};

export default RadioGroup;
export var RadioGroupValidated = withValidation(RadioGroup);