/**
 * @file Created on Fri Oct 05 2018
 * @author SKu
 */

import {PricingPermissions, Release, ReleaseState, ReleaseType} from '@logio/common-be-fe';
import {BoxStatus, Field, getPath, Icon, IconColor, IconType, WithPermission} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';
import {withRouter} from 'react-router';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {ReleaseHeaderStore} from '../../../stores/components';
import AbstractReleaseHeader from './AbstractReleaseHeader';

export interface Props {
  categoryId?: string;
}

/** Release Header, used on every release page */
@observer
class ReleaseHeader extends AbstractReleaseHeader<ReleaseHeaderStore, Props> {
  /** @inheritDoc */
  get release(): Release {
    return this.props.store.release;
  }

  /** @inheritDoc */
  get tooltipInfo() {
    return (
      <>
        {this.getTooltipItem(['workflowType'])}
        {this.getTooltipItem(['releaseType'])}
        {this.getTooltipItem(['priceListType'])}
        {this.getTooltipItem(['pricingType'])}
        {this.getTooltipItem(['totalPriceChangeLimit'])}
        {this.getTooltipItem(['exported'])}
        {this.getTooltipItem(['description'])}
        {this.getTooltipItem(['lastModified'])}
      </>
    );
  }

  isReleaseOpenedAndSimulation() {
      const { state, releaseType } = this.release;
      return state === ReleaseState.Opened && releaseType === ReleaseType.Simulation;
  }

  /** @inheritDoc */
  get formContent() {
    return (
      <>
        <Field name="name" />
        <Field name="priceListTypeId" fieldReadOnly />
        <Field name="totalPriceChangeLimit" fieldReadOnly={this.isReleaseOpenedAndSimulation()} />
        <Field name="priceValidityStartDate" />
        <Field name="cmDeadline" />
        <Field name="description" />
      </>
    );
  }

  /** @inheritDoc */
  get detailPath() {
    return getPath(PagePathsEnum.ReleaseDetail, this.release.id);
  }

  /** Path to release settings */
  get settingsPath() {
    return this.props.categoryId
      ? getPath(PagePathsEnum.ReleaseMatrixTable, this.props.categoryId, this.release.id)
      : getPath(PagePathsEnum.ReleaseSettings, this.release.id);
  }

  get exportPath() {
    return getPath(PagePathsEnum.ReleaseExport, this.release.id);
  }

  /** Link to the Release settings */
  get settingsIconRenderer() {
    return (
      <WithPermission permissionTypes={[PricingPermissions.REGULAR_RELEASE_SETTINGS_VIEW]}>
        <div className="status-bar-item">
          <Icon
            iconColor={IconColor.Text}
            iconType={IconType.settings}
            disabled={this.props.location.pathname === this.settingsPath}
            onClick={this.historyPush(this.settingsPath)}
          />
        </div>
      </WithPermission>
    );
  }

  render() {
    return (
      <>
        {this.props.store.downloadStore.renderModal()}
        {this.headerEditModal}

        <BoxStatus>
          {this.releaseStateRenderer}
          {this.divider}
          {this.releaseFavoriteRenderer}
          {this.divider}
          {this.releaseInfoRenderer}
          {this.editIconRenderer}
          {this.settingsIconRenderer}
          {this.detailIconRenderer}
          {this.exportIconRenderer}
          {this.divider}
          {this.cmDeadlineRenderer}
          {this.divider}
          {this.priceValidityStartDateRenderer}
          {this.divider}
          {this.customItemsRenderer}
        </BoxStatus>
      </>
    );
  }
}

export default withRouter(ReleaseHeader);
