import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  line-height: ", "px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author BPo
 * @author PKl
 */
import styled, { withTheme } from 'styled-components';
/**
 * Create an Input component that'll render an <input> tag with some styles
 */

var CheckBoxWrapper = styled.div(_templateObject(), function (props) {
  return props.theme.input.height;
});
export default withTheme(CheckBoxWrapper);