/**
 * @file Created on Mon Oct 08 2018
 * @author BPo
 */
import {
  AbovePromotion,
  AverageMargin,
  CodebookLookupSequence,
  Constraint,
  ConstraintTypeEnum,
  DataDesc,
  jsonConversions,
  ListingPriceProtection,
  MultiTargetStrategy,
  PriceChange,
  PriceDistance,
  PriceZone,
  PricingPermissions,
  ProductCategory,
  ProductSensitivity,
  PromoProtection,
  RangeConstraint,
  Release,
  ReleaseAction,
  ReleaseState,
  RepricingFrequency,
  RequiresRecalculation,
  Settings,
  SimpleConstraint,
  SingleTargetStrategy,
  StructPropBuilder,
  Utils,
  WeekDayRestriction,
} from '@logio/common-be-fe';
import {
  CompetitorsPriorityStore,
  CompetitorStore,
  CopyZonesData,
  DeleteSettingsParams,
  GeneralConfigurationsStore,
  KeycloakStore,
  LoadingState,
  logger,
  PageStore,
  PriceZoneStore,
  ProductCategoryStore,
  ProductSensitivityStore,
  ReleaseStore,
  rootStore,
  SelectOptions,
  SettingsStore,
  StoreName,
  StringMapping,
  translate,
  startBlockingRouteNavigation,
  stopBlockingRouteNavigation,
} from '@logio/common-fe';
import {AxiosError} from 'axios';
import {action, computed, observable, runInAction} from 'mobx';
import {ReleaseHeaderStore} from '../../../components';
import {SettingsUpdater} from './MatrixTable/SettingsUpdater';
import {List} from 'immutable';
import {OptimizationStrategyStore} from './OptimizationStrategyStore';
import {PollingHelper} from '../../../components/PollingHelper';
import createFilterOptions from 'react-select-fast-filter-options';

export enum MatrixTableModalEnum {
  CopyBetweenZones = 'COPY_BETWEEN_ZONES',
  SaveConfirmation = 'SAVE_CONFIRMATION',
}

export class MatrixTablePageStore extends PageStore {
  builder = new StructPropBuilder('MatrixTable');

  // Inject all stores
  productSensitivityStore = rootStore.getStore(StoreName.ProductSensitivity) as ProductSensitivityStore;
  releaseHeaderStore = new ReleaseHeaderStore(this.messages);
  settingsStore = rootStore.getStore(StoreName.SettingsStore) as SettingsStore;
  priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;
  categoriesStore = rootStore.getStore(StoreName.ProductCategory) as ProductCategoryStore;
  competitorsPriorityStore = rootStore.getStore(StoreName.CompetitorsPriority) as CompetitorsPriorityStore;
  releaseStore = rootStore.getStore(StoreName.Release) as ReleaseStore;
  generalConfigurationsStore = rootStore.getStore(StoreName.GeneralConfigurations) as GeneralConfigurationsStore;
  keycloakStore = rootStore.getStore(StoreName.Keycloak) as KeycloakStore;
  competitorStore = rootStore.getStore(StoreName.Competitor) as CompetitorStore;

  @observable
  loadingState: LoadingState = LoadingState.Pending;

  /** IDs of all items to display. */
  @observable
  filteredZones: string[] = [];

  /** All price zones mapped to the options array */
  @observable
  filterZoneOptions: Array<{
    option: string;
    value: string;
  }> = [];

  @observable
  manualPrices: boolean = true;

  @action
  setManualPrices = () => {
    runInAction(() => (this.manualPrices = !this.manualPrices));
  };

  @observable
  confirmModal: boolean = false;

  @action
  showConfirmModal = () => runInAction(() => (this.confirmModal = true));

  @action
  hideConfirmModal = () => runInAction(() => (this.confirmModal = false));

  recalculate() {
    this.manualPrices ? this.updateManualPricesAndRecalculate() : this.showConfirmModal();
  }

  updateManualPricesAndRecalculate = async () => {
    try {
      await this.releaseStore
        .updateReleasePartManualPrices(this.release.id, this.productCategory.id, this.manualPrices)
        .then(() => {
          this.recalculateAction();
          !this.manualPrices && this.setManualPrices();
        });
    } catch (error) {}
  };

  /** A variable that keeps the status of all open modals. */
  @observable
  modalShown: MatrixTableModalEnum;

  /** A list of all items to highlight. The list is selected by price zone id. */
  @observable
  changedValues: Map<string, StringMapping<any>> = new Map();

  /** Current Category */
  @observable
  productCategory: ProductCategory;

  /** Current available zones */
  @observable
  priceZones: Map<string, PriceZone> = new Map<string, PriceZone>();

  /** true if release settings can be edited */
  @observable
  settingsEditable: boolean = true;

  @observable
  isRecalculateActionFired: boolean = false;

  optimizationStrategy = new Map<string, OptimizationStrategyStore>();

  /**
   * Checks if modal should be hidden.
   */
  isModalHidden = (name: MatrixTableModalEnum) => this.modalShown !== name;

  /**
   * True if whole matrix table can be edited.
   */
  isSettingsEditable(): boolean {
    return (
      this.settingsEditable &&
      ((this.keycloakStore.userHasPermissions([PricingPermissions.REGULAR_RELEASE_SETTINGS_DETAIL_EDIT]) &&
        !Utils.isValueMissing(this.releaseHeaderStore.release)) ||
        (this.keycloakStore.userHasPermissions([PricingPermissions.MATRIX_TABLE_SETTINGS_EDIT]) &&
          Utils.isValueMissing(this.releaseHeaderStore.release)))
    );
  }

  /**
   * @returns The list of days for the SelectAdapter
   */
  get weekDaySelectOptions(): any {
    const weekDayOptions = [
      {
        label: translate('None'),
        value: 0,
      },
      {
        label: translate('WeekOfDay1'),
        value: 1,
      },
      {
        label: translate('WeekOfDay2'),
        value: 2,
      },
      {
        label: translate('WeekOfDay3'),
        value: 3,
      },
      {
        label: translate('WeekOfDay4'),
        value: 4,
      },
      {
        label: translate('WeekOfDay5'),
        value: 5,
      },
      {
        label: translate('WeekOfDay6'),
        value: 6,
      },
      {
        label: translate('WeekOfDay7'),
        value: 7,
      },
    ];
    return {options: weekDayOptions, filterOptions: createFilterOptions(weekDayOptions)};
  }

  /**
   * True if field can be edited.
   * @param name Field name
   */
  isFieldEditable = (name: string): boolean => {
    if (this.isSettingsEditable()) {
      // TODO: Field specific validation not implemented yet..
      return true;
    }

    return false;
  };

  /** Filtered tables / forms. Depends on the `filteredZones` */
  @computed
  get tables(): PriceZone[] {
    const priceZones: PriceZone[] = [];
    this.priceZones.forEach((priceZone) => {
      if (this.filteredZones.some((id) => priceZone.id === id)) {
        priceZones.push(priceZone);
      }
    });

    return priceZones;
  }

  /** All table columns / product sensitivity */
  @computed
  get columns(): ProductSensitivity[] {
    return Array.from(this.productSensitivityStore.list).map(([, sensitivity]) => sensitivity);
  }

  /**
   * All constraint type rows (per each sensitivity group separately). These are not all
   * rows in the table (some are static).
   */
  @computed
  get rows(): ConstraintTypeEnum[] {
    const output = Object.entries(ConstraintTypeEnum).reduce(
      (previous: ConstraintTypeEnum[], [, value]) => {
        // HACK: Margin and AverageMargin is not set per each group separately but for all at once.
        if (value !== ConstraintTypeEnum.Margin && value !== ConstraintTypeEnum.AverageMargin) {
          previous.push(value);
        }
        return previous;
      },
      [] as ConstraintTypeEnum[],
    );
    return output;
  }

  /** Current release */
  @computed
  public get release() {
    return this.releaseStore.release;
  }

  /** Current release part from release entity */
  @computed
  private get currentReleasePart() {
    if (this.release.state === ReleaseState.New) {
      return null;
    } else {
      try {
        return this.release.requireReleasePartForCategory(this.productCategory.id);
      } catch (error) {
        return null;
      }
    }
  }

  /** If recalculation is required,  appropriate icon should be shown  */
  @computed
  public get isRecalculationRequired() {
    return (
      this.currentReleasePart &&
      this.currentReleasePart.validationResult.resultObjects.some((rObject) => rObject.flags.has(RequiresRecalculation))
    );
  }

  /** Filtered when  */
  @action.bound
  filterZones(ids: string[]) {
    this.filteredZones = ids;
  }

  private onPollingStateChanged = async (pollingState: LoadingState) => {
    if (pollingState === LoadingState.Success || pollingState === LoadingState.Error) {
      await this.releaseStore.getOne(this.release.id);
      runInAction(() => (this.settingsEditable = true));
      runInAction(() => (this.isRecalculateActionFired = false));
    }
  };

  pollingHelper = new PollingHelper(this.messages, this.onPollingStateChanged);

  /**
   * Function for using Release action endpoint
   * @param action{ReleaseAction} - action type
   */
  public recalculateAction = async (): Promise<void> => {
    runInAction(() => (this.settingsEditable = false));
    const actionData = {
      releaseId: this.release.id,
      action: ReleaseAction.Recalculate,
      allCategories: false,
      categoryIds: [this.productCategory.id],
    };
    try {
      const actionResponse = await this.releaseStore.action(actionData);
      actionResponse.successAll ? this.handleRecalculateSuccess() : this.handleRecalculateFail(actionResponse);
    } catch (error) {
      // this.messages.setError(error);
    } finally {
      await this.releaseStore.getOne(this.release.id);
    }
  };

  /**
   * Used if all requested actions was successful
   * Depending on ReleaseAction type executes different operations
   * @param action
   */
  private handleRecalculateSuccess = () => {
    runInAction(() => (this.isRecalculateActionFired = true));
    const msgArgs = [translate(`${ReleaseAction.Recalculate}-action`), this.productCategory.name];
    this.releaseHeaderStore
      .load(this.release.id)
      .then(() => this.handleRecalculation())
      .catch((err) => this.messages.setError(err));
    this.messages.setSuccess(translate('release-action-success', ...msgArgs));
  };

  /**
   * Handle recalculation polling
   */
  private handleRecalculation = () => {
    const {calcProgressMonitorId} = this.currentReleasePart;
    if (calcProgressMonitorId) {
      this.pollingHelper.startPolling(calcProgressMonitorId, 'release-recalculation');
    }
  };

  /**
   * Used if at least one of requested actions failed
   * Sets info-errors
   * @param actionResponse  data from BE
   */
  handleRecalculateFail = (actionResponse: any) => {
    actionResponse.results.map((result) => {
      const msgArgs = [translate(`${actionResponse.action}-action`)];
      if (result.errorCode === undefined) {
        this.messages.setWarning(translate('action-error-undefined', ...msgArgs));
      } else {
        msgArgs.push(translate(`${result.errorCode}-${result.errorSubcode}`));
        if (result.conflictingUser) {
          msgArgs.push(result.conflictingUser.name);
          this.messages.setWarning(translate('user-action-error', ...msgArgs));
        } else {
          this.messages.setWarning(translate('action-error', ...msgArgs));
        }
      }
    });
    runInAction(() => (this.settingsEditable = true));
    runInAction(() => (this.isRecalculateActionFired = false));
  };

  /**
   * It will try to get the constraint description for the given constraint type
   * @param type Constraint enum value
   */
  private getConstraintDescription(type: ConstraintTypeEnum) {
    switch (type) {
      case ConstraintTypeEnum.AverageMargin:
        return this.builder.opt(AverageMargin.schema.value);
      case ConstraintTypeEnum.PriceChange:
        return this.builder.opt(PriceChange.schema.range);
      case ConstraintTypeEnum.AbovePromotion:
        return this.builder.opt(AbovePromotion.schema.value);
      case ConstraintTypeEnum.ListingPriceProtection:
        return this.builder.opt(ListingPriceProtection.schema.value);
      case ConstraintTypeEnum.PromoProtection:
        return this.builder.opt(PromoProtection.schema.value);
      case ConstraintTypeEnum.RepricingFrequency:
        return this.builder.opt(RepricingFrequency.schema.value);
      case ConstraintTypeEnum.WeekDayRestriction:
        return this.builder.opt(WeekDayRestriction.schema.days);
      default: {
        throw new Error(`Illegal argument exception for given type ${type}`);
      }
    }
  }

  /**
   * It will try to change constraint structure to the HTML field value.
   * @param constraint
   */
  getConstraintValue(constraint: Constraint): number | number[] {
    if (constraint instanceof RangeConstraint) {
      return [constraint.range.minimum.toNumber(), constraint.range.maximum.toNumber()];
    }

    if (constraint instanceof SimpleConstraint) {
      return constraint.value.toNumber();
    }

    if (constraint instanceof WeekDayRestriction) {
      return jsonConversions.asObjectArray(constraint, 'days', (day) => day);
    }

    return undefined;
  }

  /**
   * Returns field value for competitor priority field
   *  If value used for placeholder, should  be title, otherwise - id
   * @param id - competitorPriorityId
   * @param resolvedSettings - true if values from resolved settings(that used for placeholders)
   */
  getCompetitorsPriorityValue(id: string, resolvedSettings: boolean = false) {
    if (resolvedSettings) {
      const competitorPriority = this.competitorsPriorityStore.priorities.get(id);
      if (competitorPriority) {
        return competitorPriority.title;
      } else {
        logger.error(`Competitor priority with id - ${id} doesn't exist`);
        return 'ERROR';
      }
    }
    return id;
  }

  /**
   * Returns field value for competitor priority field
   *  If value used for placeholder, should  be title, otherwise - id
   * @param id - competitorPriorityId
   * @param resolvedSettings - true if values from resolved settings(that used for placeholders)
   */
  getCompetitorsForStrategy(ids: List<string>, resolvedSettings: boolean = false) {
    if (resolvedSettings) {
      const competitors = [];
      ids.forEach((id) => {
        const competitor = this.competitorStore.list.get(id);
        if (competitor) {
          competitors.push(competitor.name);
        } else {
          logger.error(`Competitor with id - ${id} doesn't exist`);
          competitors.push('ERROR');
        }
      });
      return competitors.join(',');
    }
    return ids.toArray();
  }

  /**
   * Returns field value for competitor priority field
   *  If value used for placeholder, should  be title, otherwise - id
   * @param id - competitorPriorityId
   * @param resolvedSettings - true if values from resolved settings(that used for placeholders)
   */
  getCompetitorForStrategy(id: string, resolvedSettings: boolean = false) {
    if (resolvedSettings) {
      const competitor = this.competitorStore.list.get(id);
      if (competitor) {
        return competitor.name;
      } else {
        logger.error(`Competitor with id - ${id} doesn't exist`);
        return 'ERROR';
      }
    }
    return id;
  }

  /**
   * Tries to get values in a flatten structure. Regardless of the type of setting..
   * @param settings List of settings
   * @param resolvedSettings - true if values from resolved settings(that used for placeholders)
   */
  private getSettingsValues(settings: Settings[], resolvedSettings: boolean = false): StringMapping<any> {
    const values = {
      optimizationGoalsSettings: undefined,
      competitorHistoryLength: undefined,
    };

    settings.forEach((settingsItem) => {
      // Get non-constraints initial values
      values.optimizationGoalsSettings = settingsItem.optimizationGoalsSettings
        ? settingsItem.optimizationGoalsSettings.optimalizationGoal
        : undefined;

      values.competitorHistoryLength = settingsItem.competitorHistoryLength;
      values[`competitorsPriorityId-${settingsItem.productSensitivityId}`] =
        settingsItem.competitorsPriorityId &&
        this.getCompetitorsPriorityValue(settingsItem.competitorsPriorityId, resolvedSettings);
      // LOG-4503 - Wrongly highlighted item when value as placeholder and id as value, due to added item with id yet
      values[`competitorsPriorityId-${settingsItem.productSensitivityId}-id`] = settingsItem.competitorsPriorityId;
      if (settingsItem.strategySettings) {
        if (settingsItem.strategySettings.strategy) {
          values[
            `strategySettings-${settingsItem.productSensitivityId}`
          ] = settingsItem.strategySettings.strategy.getType();
        }

        if (settingsItem.strategySettings.priceDistance) {
          values[`priceDistance-${settingsItem.productSensitivityId}`] = [
            settingsItem.strategySettings.priceDistance.range.minimum.toNumber(),
            settingsItem.strategySettings.priceDistance.range.maximum.toNumber(),
          ];
        }

        if (settingsItem.strategySettings.strategy instanceof MultiTargetStrategy) {
          values[`competitorIds-${settingsItem.productSensitivityId}`] = this.getCompetitorsForStrategy(
            settingsItem.strategySettings.strategy.competitorIds,
            resolvedSettings,
          );
        } else if (settingsItem.strategySettings.strategy instanceof SingleTargetStrategy) {
          values[`competitorId-${settingsItem.productSensitivityId}`] = this.getCompetitorForStrategy(
            settingsItem.strategySettings.strategy.competitorId,
            resolvedSettings,
          );
        }
      }

      // Get constraints initial values
      if (settingsItem.constraintSettings) {
        settingsItem.constraintSettings.constraints.map((constraint) => {
          const value = this.getConstraintValue(constraint);
          if (!Utils.isValueMissing(value)) {
            const constraintType = constraint.getType();
            if (ConstraintTypeEnum.AverageMargin === constraintType) {
              // HACK: AverageMargin is not set per each group separately but for all at once.
              values[ConstraintTypeEnum.AverageMargin] = value;
            } else {
              values[`${constraintType}-${settingsItem.productSensitivityId}`] = value;
            }
          }
        });
      }
    });

    return values;
  }

  /**
   * Group all settings by one price zone.
   */
  @computed
  get groupedResolvedSettings(): Map<string, StringMapping<any>> {
    const grouped = new Map<string, StringMapping<any>>();

    this.settingsStore.listResolved.forEach((settings, priceZoneId) => {
      grouped.set(priceZoneId, this.getSettingsValues(settings, true));
    });

    return grouped;
  }

  /**
   * Returns grouped resolved settings, or empty object
   * @param priceZoneId Price zone for which records will be displayed
   */
  getPlaceholderValues(priceZoneId: string): StringMapping<any> {
    return this.groupedResolvedSettings.get(priceZoneId) || {};
  }

  /**
   * Group all settings by one price zone.
   */
  @computed
  get groupedInitialSettings(): Map<string, StringMapping<any>> {
    const grouped = new Map<string, StringMapping<any>>();

    this.settingsStore.list.forEach((settings, priceZoneId) => {
      grouped.set(priceZoneId, this.getSettingsValues(settings));
    });

    return grouped;
  }

  /**
   * Returns grouped initial settings, or empty object
   * @param priceZoneId Price zone for which records will be displayed
   */
  getInitialValues(priceZoneId: string): StringMapping<any> {
    return this.groupedInitialSettings.get(priceZoneId) || {};
  }

  /**
   * Adds or removes actual values to be highlighted.
   * NOTE: The function remained here due to the previous logic. It is
   * probably going to be removed. But now I leave it here, in case the
   * logic will be increased in the future. Currently, the logic is here
   * and not in the component. Which is not wrong.
   *
   * @param priceZoneId Price zone at which the value will be checked.
   * @param values Flatten settings for the given price zone
   */
  setHighlightedValues(priceZoneId, valuesToCompare = {}) {
    const values = {};

    for (const [key, val] of Object.entries(valuesToCompare)) {
      if (
        Utils.isValueMissing(val) ||
        val === this.getPlaceholderValues(priceZoneId)[key] ||
        val === this.getPlaceholderValues(priceZoneId)[`${key}-id`] // LOG-4503 - Wrongly highlighted item due to comparing value and id
      ) {
        values[key] = false;
      } else {
        values[key] = true;
      }
    }

    runInAction(() => {
      this.changedValues.set(priceZoneId, values);
    });
  }

  /**
   * Construct form description for the specific price zone
   * @param priceZoneId Price zone internal ID
   */
  getDescriptions(): DataDesc | null {
    const descriptions: any = {
      optimizationGoalsSettings: Settings.schema.optimizationGoalsSettings,
      competitorHistoryLength: Settings.schema.competitorHistoryLength,
      [ConstraintTypeEnum.AverageMargin]: this.getConstraintDescription(ConstraintTypeEnum.AverageMargin),
    };

    this.productSensitivityStore.list.forEach((productSensitivity) => {
      this.rows.forEach((constraintType) => {
        descriptions[`${constraintType}-${productSensitivity.id}`] = this.getConstraintDescription(constraintType);
      });

      // Static settings per sensitivity group
      descriptions[`strategySettings-${productSensitivity.id}`] = Settings.schema.strategySettings;
      descriptions[`priceDistance-${productSensitivity.id}`] = this.builder.opt(PriceDistance.schema.range);
      descriptions[`competitorsPriorityId-${productSensitivity.id}`] = Settings.schema.competitorsPriorityId;
      descriptions[`competitorId-${productSensitivity.id}`] = this.builder.opt(
        this.builder.lookup('competitorId', CodebookLookupSequence.Competitor),
      );
      descriptions[`competitorIds-${productSensitivity.id}`] = this.builder.opt(
        this.builder.listOf(this.builder.lookup('competitorIds', CodebookLookupSequence.Competitor)),
      );
    });
    return descriptions;
  }

  /**
   * Mutate list of settings after update. If no settings exist, the method will reload all data.
   * @param promise Update promise
   * @param priceZoneId Id of selected price zone
   */
  handleUpdatePromise(promise: Promise<Map<string, Settings[]>>, categoryId: string, releaseId?: string) {
    return promise
      .then((settingsMap) => {
        runInAction(() => {
          if (settingsMap.size === 0) {
            this.load(categoryId, releaseId, false);
          } else {
            settingsMap.forEach((settingsArr, key) => {
              this.settingsStore.list.set(key, settingsArr);
            });
          }

          this.messages.setSuccess(translate('PRICING-MATRIX-TABLE-SAVE'));
          window.scrollTo(0, 0);
        });
      })
      .catch((error) => {
        this.messages.setError(error);
      });
  }

  /**
   * Function fired on CopyBetweenZonesForm submit,
   * used for coping settings from one zone to others
   * @param values - callback from Form
   */
  onCopyBetweenZonesSubmit = async (values: CopyZonesData) => {
    try {
      const zoneNames = values.targetZoneIds.map((zoneId) => this.priceZones.get(zoneId).name).join(', ');
      const copyZonesData: CopyZonesData = {...values, ...{categoryIds: [this.productCategory.id]}};
      await this.settingsStore.copyBetweenZones(copyZonesData);
      this.messages.setSuccess(translate('settings-between-zones-copy-success', zoneNames));
    } catch (error) {
      this.messages.setError(error);
    }
    this.hideModal();
  };

  /**
   * Saves all current settings for the given price zone ID to the server.
   */
  onSave = (priceZone: PriceZone, categoryId: string, releaseId?: string) => async (
    values: StringMapping<any>,
    form,
  ) => {
    const settingsList = this.settingsStore.list.get(priceZone.id);
    const settingsToUpdate: Settings[] = [];
    const notResetChart = Object.keys(form.getState().dirtyFields).every(
      (value) => value === 'AVERAGE_MARGIN' || value === 'optimizationGoalsSettings',
    );
    if (!notResetChart) {
      this.optimizationStrategy.get(priceZone.id).removeData();
    }
    this.productSensitivityStore.list.forEach((productSensitivity) => {
      let settingsItem: Settings =
        settingsList && settingsList.find((settings) => settings.productSensitivityId === productSensitivity.id);
      /** If settings already exists, update existing, if not create new one */
      if (Utils.isValueMissing(settingsItem)) {
        settingsItem = new Settings(
          Utils.VOID_ID,
          `Settings ${priceZone.name} - ${productSensitivity.title}`,
          priceZone.id,
          categoryId,
          productSensitivity.id,
          null,
          null,
          null,
          null,
          null,
          null,
        );
      }

      // LOG-5516
      const isStrategy = values[`strategySettings-${productSensitivity.id}`] !== undefined;
      const isCompetitor =
        values[`competitorId-${productSensitivity.id}`] !== undefined ||
        values[`competitorIds-${productSensitivity.id}`] !== undefined;

      if (isCompetitor && !isStrategy) {
        values[`strategySettings-${productSensitivity.id}`] = this.getPlaceholderValues(priceZone.id)[
          `strategySettings-${productSensitivity.id}`
        ];
      }

      settingsToUpdate.push(
        SettingsUpdater(
          settingsItem,
          [...this.rows, ConstraintTypeEnum.Margin, ConstraintTypeEnum.AverageMargin],
          values,
          releaseId,
        ),
      );
    });
    await this.handleUpdatePromise(this.settingsStore.settingsLayer.update(settingsToUpdate), categoryId, releaseId);
    stopBlockingRouteNavigation();
    if (releaseId) {
      await this.releaseStore.getOne(releaseId);
    }
  };

  /**
   * Edits a list of all highlighted fields (<td />) for the given price zone ID.
   */
  onChange = (priceZoneId: string) => (values: StringMapping<any>) => {
    if (this.haveValuesChanged(priceZoneId, values)) {
      startBlockingRouteNavigation('PRICING-MATRIX-TABLE-NAME', 'PRICING-MATRIX-CONFIRM-LEAVE');
    } else {
      stopBlockingRouteNavigation();
    }
    this.setHighlightedValues(priceZoneId, values);
  };

  /**
   * Deletes all settings for the given price zone ID on the server.
   */
  onDelete = (priceZoneId: string, categoryId: string, releaseId?: string) => async () => {
    const settingsList = this.settingsStore.list.get(priceZoneId);
    if (settingsList) {
      const params: DeleteSettingsParams = {
        ids: settingsList.map((settings) => settings.id),
        releaseId,
      };
      try {
        await this.settingsStore.settingsLayer.delete(params);
        this.messages.setSuccess(translate('PRICING-MATRIX-TABLE-DELETE'));
        window.scrollTo(0, 0);
        runInAction(() => this.settingsStore.list.delete(priceZoneId));
      } catch (error) {
        this.messages.setError(error);
      } finally {
        setTimeout(async () => {
          await this.releaseStore.getOne(releaseId);
        }, 1000);
        stopBlockingRouteNavigation();
      }
      this.optimizationStrategy.get(priceZoneId).removeData();
    } else {
      logger.warn(`Trying to delete price zone settings (${priceZoneId}) failed. No settings found.`);
    }
  };

  /**
   * Returns an array of promises that should be resolved on the page load.
   */
  getLoadPromises(categoryId: string, releaseId?: string): any[] {
    return [
      this.categoriesStore.getOne(categoryId),
      this.priceZoneStore.getAll(),
      this.productSensitivityStore.getAll(),
      this.settingsStore.getAll(categoryId, {releaseId}),
      this.settingsStore.getResolvedSettings(categoryId, {releaseId}),
      this.competitorsPriorityStore.getAllPriorities(),
      this.competitorStore.getAll(),
    ];
  }

  /**
   * Creates an internal list of price zones and fills the price zone filters.
   */
  addPriceZone = (priceZone: PriceZone) => {
    // Use only not archived price zone
    if (!priceZone.archived) {
      this.optimizationStrategy.set(priceZone.id, new OptimizationStrategyStore(this.messages));
      this.priceZones.set(priceZone.id, priceZone);
      this.filteredZones.push(priceZone.id);
      this.filterZoneOptions.push({option: priceZone.name, value: priceZone.id});
      this.setHighlightedValues(priceZone.id, this.getInitialValues(priceZone.id));
    }
  };

  /**
   * Sets warning for user, if he is editing category that could influence lower level categories
   */
  setInfluenceWarning = () => {
    if (
      this.productCategory.level !== this.generalConfigurationsStore.config.categoryLevel &&
      this.isSettingsEditable()
    ) {
      this.messages.setWarning(translate('matrix-table-edit-influence'));
    }
  };

  /**
   * Called with an array of results when all release promises (see the load method) are resolved.
   */
  handleReleaseLoadResponse = ([release, productCategory, priceZones]: [
    Release,
    ProductCategory,
    Map<string, PriceZone>
  ]) => {
    runInAction(() => {
      if (release && release.editInfo) {
        this.settingsEditable = this.releaseStore.release.editInfo.settingsEditable;
      }
      this.productCategory = productCategory;
      // Use only price zones that are included to release
      release.priceZoneIds.map((priceZoneId) => this.addPriceZone(priceZones.get(priceZoneId)));
    });
    this.setInfluenceWarning();
    this.setLoadingState(LoadingState.Success);
  };
  /**
   * Called with an array of results when all load promises are resolved.
   */
  handleLoadResponse = ([productCategory, priceZones]: [ProductCategory, Map<string, PriceZone>]) => {
    runInAction(() => {
      this.productCategory = productCategory;

      this.setInfluenceWarning();

      priceZones.forEach((priceZone) => {
        this.addPriceZone(priceZone);
      });

      this.setLoadingState(LoadingState.Success);
    });
  };

  /**
   * Callback for the rejection of the load / release load Promise.
   */
  handleLoadError = (error: AxiosError) => {
    this.setLoadingState(LoadingState.Error);
    this.messages.setError(error);
  };

  /**
   * Fetches all data for this page
   */
  @action
  load = (categoryId: string, releaseId?: string, loading = true) => {
    if (loading) {
      this.setLoadingState(LoadingState.Pending);
    }
    const promises = this.getLoadPromises(categoryId, releaseId);

    if (releaseId) {
      promises.unshift(this.releaseHeaderStore.load(releaseId));
      Promise.all(promises)
        .then(this.handleReleaseLoadResponse)
        .catch(this.handleLoadError);
    } else {
      Promise.all(promises)
        .then(this.handleLoadResponse)
        .catch(this.handleLoadError);
    }
  };

  /**
   * Negate modalHiddenProp
   * @param title - name of the modalHidden prop that should be negated
   */
  @action
  openModal = (modalType: MatrixTableModalEnum) => {
    this.modalShown = modalType;
  };

  /**
   * HOF for toggle modal
   * used in Tiers page when u get event besides parameter
   */
  getOpenModalEvent = (modalTypes: MatrixTableModalEnum) => () => {
    this.openModal(modalTypes);
  };

  /**
   * Function used in case if endpoint return error
   */
  @action
  hideModal = () => {
    this.modalShown = undefined;
  };

  /**
   * Returns true, if settings (matrix) values are different than initial.
   */
  private haveValuesChanged(priceZoneId: string, values: StringMapping<any>) {
    const initialValues = this.getInitialValues(priceZoneId);

    for (const key of Object.keys(values)) {
      // != is ok in this case - we want null == undefined or '5' == 5 to return true
      // tslint:disable-next-line:triple-equals
      if (values[key] != initialValues[key]) {
        return true;
      }
    }

    return false;
  }
}
