import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  margin-top: 0.25rem;\n  font-size: 80%;\n  color: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
import styled, { withTheme } from 'styled-components';
/**
 * Create an Input component that'll render an <input> tag with some styles
 *
 * @author BPo
 */

var StyledError = styled.span(_templateObject(), function (props) {
  return props.theme.state.danger.color;
});
export default withTheme(StyledError);