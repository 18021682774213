"use strict";
/**
 * Created on 09 14 2020
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * <p>Enumerates possible message keys related to management of regular site release module (not the termination of prices, there is
 * special for these, see {@link SiteReleaseTerminationMsgKeyEnum}), ie. not just {@link SiteRelease entity} as name of enumeration could
 * say. Within description of an individual may be suggested severity of a msg.</p>
 *
 * <p><b>Enumerated keys should be directly used for localisation keys. This is the reason that the values of the individual items violate
 * the naming convention for a value of an enumerated value.</b></p>
 *
 * @author JMe
 */

var SiteReleaseMsgKeyEnum;

(function (SiteReleaseMsgKeyEnum) {
  /**
   * Message key related to situation when the input file has incorrect structure (headers).
   * <br/>
   * Suggested severity: error.
   */
  SiteReleaseMsgKeyEnum["SourceDataInvalidDataStructure"] = "regular_site_release_management__source_data_invalid_data_structure";
  /**
   * Message key related to situation when the input file does not contain any data row.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseMsgKeyEnum["SourceDataNoDataRow"] = "regular_site_release_management__source_data_no_data_row";
  /**
   * Message key related to situation when the input file contains a row which has invalid value of price validity start date set
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseMsgKeyEnum["SourceDataInvalidPriceValidityStart"] = "regular_site_release_management__source_data_price_valid_from_invalid";
  /**
   * Message key related to situation when the input file contains a row which has price validity start date set in the past.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseMsgKeyEnum["SourceDataPriceValidityStartInThePast"] = "regular_site_release_management__source_data_price_valid_from_in_the_past";
  /**
   * Message key related to situation when some row within the input file misses required attribute(s).
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseMsgKeyEnum["SourceDataRequiredAttributeMissing"] = "regular_site_release_management__source_data_required_attribute_missing";
  /**
   * Message key related to situation when some product from the input file does not exist.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseMsgKeyEnum["SourceDataProductNotExists"] = "regular_site_release_management__source_data_product_not_exists";
  /**
   * Message key related to situation when some group of sites from the input file does not exist.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseMsgKeyEnum["SourceDataGoSNotExists"] = "regular_site_release_management__source_data_gos_not_exists";
  /**
   * Message key related to situation when some entity from the input file does not exist.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseMsgKeyEnum["SourceDataEntitiesNotExist"] = "regular_site_release_management__source_data_entities_not_found";
  /**
   * Message key related to situation when the input file contains duplicated product/GoS combinations.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseMsgKeyEnum["SourceDataDuplicatedProductGoSCombinations"] = "regular_site_release_management__source_data_duplicated_product_gos_combinations";
  /**
   * Message key related to situation when the input file contains colliding GoSes
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseMsgKeyEnum["SourceDataProductGoSCollision"] = "regular_site_release_management__source_data_product_gos_collision";
  /**
   * Message key related to situation when none of {@link SiteReleaseItem items} belong to {@link SiteRelease}.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseMsgKeyEnum["ItemsNotBoundToAnyRelease"] = "regular_site_release_management__items_not_bound_to_any_release";
  /**
   * Message key related to situation when some {@link SiteReleaseItem items} do not belong to single {@link SiteRelease}.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseMsgKeyEnum["ItemsNotBoundToSingleRelease"] = "regular_site_release_management__items_not_bound_to_single_release";
  /**
   * Message key related to situation when value of price validity start date of specific item is in the past.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseMsgKeyEnum["ItemPriceValidityStartInThePast"] = "regular_site_release_management__item_price_valid_from_in_the_past";
  /**
   * Message key related to situation when price validity start day of a {@link SiteReleaseItem} is in the past.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseMsgKeyEnum["ReleasePriceValidityStartInThePast"] = "regular_site_release_management__release_price_valid_from_in_the_past";
  /**
   * Message key related to situation when a {@link SiteRelease} should be editable but someone/something tries to edit it.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseMsgKeyEnum["ReleaseNotEditable"] = "regular_site_release_management__release_not_editable";
})(SiteReleaseMsgKeyEnum = exports.SiteReleaseMsgKeyEnum || (exports.SiteReleaseMsgKeyEnum = {}));