/**
 * @file Created on Sat Feb 16 2019
 * @author SKu
 */
import * as React from 'react';
import Icon, { IconType, IconColor } from '../../../../media/Icon';
export var FavoriteItemRenderer = function FavoriteItemRenderer(_ref) {
  var value = _ref.value;
  return value ? React.createElement(Icon, {
    iconType: IconType.newsFull,
    iconColor: IconColor.Secondary,
    iconHeight: 20
  }) : null;
};