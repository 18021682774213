/**
 * @file Created on Fri Oct 12 2018
 * @author SKu
 */

import {
  ReleaseStore,
  rootStore,
  StoreName,
} from '@logio/common-fe';
import {History} from 'history';
import { PagePathsEnum } from '../../../../shared/localization/PagePathsEnum';
import {ReleaseDetailCategoryComponentStore, ReleaseHeaderStore} from '../../../components';
import {AbstractReleaseExportPageStore} from './AbstractReleaseExportPageStore';

export class ReleaseExportPageStore extends AbstractReleaseExportPageStore {
  /** @inheritdoc */
  constructor(public history: History, public releaseId: string) {
    super(history, releaseId);
  }

  public releaseStore = rootStore.getStore(StoreName.Release) as ReleaseStore;
  public releaseHeaderStore = new ReleaseHeaderStore(this.messages);
  public releaseDetailCategoryComponentStore = new ReleaseDetailCategoryComponentStore(this.history, this.messages, this.releaseId);
  public detailPath = PagePathsEnum.ReleaseDetail;
}
