/**
 * @file Data cleaning page
 * @author VBr
 * @author LZe
 */

import {PriceZone, PricingPermissions} from '@logio/common-be-fe';
import {
  AgGrid,
  BoxTabContainer,
  Button,
  ButtonSize,
  ConfirmBox,
  FormGroupInline, LoadingState,
  Messages,
  Page, ProgressBar,
  translate,
  WithPermission,
} from '@logio/common-fe';
import {observer, Observer} from 'mobx-react';
import * as React from 'react';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {ModalEnum, PriceZonePageStore} from '../../../stores/pages/DataManagement/PriceZones/PriceZonePageStore';
import {PriceZonesPageForm} from './PriceZonesPageForm';
import Tabs from './Tabs';

@observer
class PriceZonesPage extends React.Component {
  store = new PriceZonePageStore();

  componentWillMount() {
    this.store.load();
  }

  render() {
    return (
      <Page titleKey={PageNamesEnum.PriceZones} loadingState={this.store.loadingState} showMessages={false}>
        <Observer>
          {() => (
            <>
              <Tabs/>
              <BoxTabContainer>
                <Messages/>
                {(this.store.pollingHelper.pollingState === LoadingState.Pending ||
                  this.store.isPolling) ? (
                  <ProgressBar percentage={this.store.pollingHelper.progress || 0} label={translate('create-price-zone-in-progress')}/>
                ) : (
                  <WithPermission permissionTypes={[PricingPermissions.ZONES_MANAGE_EDIT]}>
                    <FormGroupInline right>
                      <Button buttonSize={ButtonSize.Tiny} onClick={this.store.toggleCreate()}>
                        {translate('create-price-zone')}
                      </Button>
                    </FormGroupInline>
                  </WithPermission>)}
                <PriceZonesPageForm createMode priceZonesPageStore={this.store}/>
                <PriceZonesPageForm priceZonesPageStore={this.store}/>
                <ConfirmBox
                  title={translate('archive-price-zone-title')}
                  hidden={this.store.modalHidden.Archive}
                  onAccept={this.store.onArchiveAccept}
                  close={this.store.getModalToggleEvent(ModalEnum.ARCHIVE)}
                >
                  <Messages/>
                  {translate('archive-price-zone-text')}
                </ConfirmBox>
                <AgGrid
                  rowData={this.store.rowData}
                  columnDefs={this.store.columnDefs}
                  sizeColumnsToFit
                  onGridReady={this.store.onGridReady}
                  paginationAutoPageSize
                  headerHeight={50}
                  getRowClass={(params: any) =>
                    params.node.data[PriceZone.schema.archived.description.nameKey] ? 'disabled' : ''
                  }
                  deltaRowDataMode={true}
                  getRowNodeId={({PriceZone_id: id}) => id}
                  gridId="PricingPriceZonesPage"
                  defaultFilterModel={this.store.defaultFilterModel}
                  quickView
                />
              </BoxTabContainer>
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

export default PriceZonesPage;
