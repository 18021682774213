"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var WindowItemValidatorHint;

(function (WindowItemValidatorHint) {
  WindowItemValidatorHint[WindowItemValidatorHint["NOOP"] = 0] = "NOOP";
  WindowItemValidatorHint[WindowItemValidatorHint["SIMPLE"] = 1] = "SIMPLE";
  WindowItemValidatorHint[WindowItemValidatorHint["SITES"] = 2] = "SITES";
  WindowItemValidatorHint[WindowItemValidatorHint["OVERLAP"] = 3] = "OVERLAP";
  WindowItemValidatorHint[WindowItemValidatorHint["FULL"] = 4] = "FULL";
  WindowItemValidatorHint[WindowItemValidatorHint["PRE_SAVE"] = 5] = "PRE_SAVE";
  WindowItemValidatorHint[WindowItemValidatorHint["RPM_REPORT"] = 6] = "RPM_REPORT";
  WindowItemValidatorHint[WindowItemValidatorHint["BEFORE_COPY"] = 7] = "BEFORE_COPY";
})(WindowItemValidatorHint = exports.WindowItemValidatorHint || (exports.WindowItemValidatorHint = {}));