/**
 * @file Created on Mon Oct 01 2018
 * @author LZe
 */
import * as React from 'react';
import FormGroupInline from '../../../forms/FormGroupInline/FormGroupInline';
import translate from '../../../i18n/translate';
import Label from '../../../typography/Label';
/**
 * Renders group action buttons, provide disabled prop
 * Example:
 * <GroupActions selectedRowsCount={this.store.selectedRowsCount}>
 *   {({disabled}) => (
 *     <>
 *       <Button
 *         buttonSize={ButtonSize.Tiny}
 *         onClick={this.store.toggleChangePriceZoneModal}
 *         disabled={disabled}
 *       >
 *         {translate('Change price zone')}
 *       </Button>
 *       <Button buttonSize={ButtonSize.Tiny} onClick={this.store.toggleActive} disabled={disabled}>
 *         {translate('Toggle active')}
 *       </Button>
 *     </>
 *   )}
 * </GroupActions>
 * @param className
 * @param children
 * @param right
 * @param selectedRowsCount AgGrid selected rows counter
 */

export var GroupActions = function GroupActions(_ref) {
  var className = _ref.className,
      selectedRowsCount = _ref.selectedRowsCount,
      children = _ref.children,
      _ref$right = _ref.right,
      right = _ref$right === void 0 ? false : _ref$right;
  return React.createElement(FormGroupInline, {
    right: right,
    className: className
  }, React.createElement(Label, null, translate('Group actions'), ":", selectedRowsCount > 0 && " (".concat(selectedRowsCount, " ").concat(translate('selected'), ")")), children({
    disabled: selectedRowsCount === 0
  }));
};
export default GroupActions;