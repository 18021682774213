"use strict";
/*
 * Created on 09 13 2018
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author Jaroslav Sevcik
 */

var UploadValidityEnum;

(function (UploadValidityEnum) {
  UploadValidityEnum["VALID"] = "VALID";
  UploadValidityEnum["INVALID"] = "INVALID";
  UploadValidityEnum["EVALUATING"] = "EVALUATING";
})(UploadValidityEnum = exports.UploadValidityEnum || (exports.UploadValidityEnum = {}));