import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
import styled from 'styled-components';
/**
 * Create an Label component that'll render an <label> on the top of input
 *
 * @author BPo
 */

var StyledLabel = styled.label(_templateObject());
export default StyledLabel;