"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var ValidationResult_1 = require("./ValidationResult");

exports.BAD_INPUT_TYPE_ERROR = 'BAD_INPUT_TYPE';
exports.BAD_ENUM_VALUE_ERROR = 'BAD_ENUM_VALUE';
exports.UNSUPPORTED_VALUE_ERROR = 'UNSUPPORTED_VALUE';
exports.INVALID_FLAG_SET = 'INVALID_FLAG_SET';

function badType(v, e) {
  return new ValidationResult_1.ValidationError(exports.BAD_INPUT_TYPE_ERROR, v, e, typeof v);
}

exports.badType = badType;

function badEnum(v, enumVal) {
  return new ValidationResult_1.ValidationError(exports.BAD_ENUM_VALUE_ERROR, v, typeof enumVal);
}

exports.badEnum = badEnum;

function unsupported(v) {
  return new ValidationResult_1.ValidationError(exports.UNSUPPORTED_VALUE_ERROR, v, typeof v);
}

exports.unsupported = unsupported;

function succ(v) {
  return new ValidationResult_1.ValidationSuccess(v);
}

exports.succ = succ;