/**
 * @file Created on Sat Feb 02 2019
 * @author SKu
 */

import {CodebookLookupSequence, DateParser, StructPropBuilder} from '@logio/common-be-fe';
import {AgGrid, DatePickerInputAdapter, Field, LoadingState, SplineChart, translate} from '@logio/common-fe';
import {Location} from 'history';
import {computed} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import {withRouter} from 'react-router';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {
  CompetitorsPriceChangeReportPageStore,
  IFilter,
} from '../../../stores/pages/Reports/RelatedReports/CompetitorsPriceChangeReportPageStore';
import {AbstractRelatedReportsPage} from './AbstractRelatedReportsPage';
import {RelatedReportsFilterProps} from './RelatedReportsFilter';
import moment from 'moment';

export interface Props {
  location: Location;
}

/**
 * Competitor Price change Report
 */
@observer
class CompetitorsPriceChangeReportPage extends AbstractRelatedReportsPage<IFilter> {
  public pageName = PageNamesEnum.CompetitorsPriceChangeReport;
  public store = new CompetitorsPriceChangeReportPageStore();

  @computed
  public get customFormProps() {
    const builder = new StructPropBuilder('CompetitorsPriceChangeReport');
    const formProps: RelatedReportsFilterProps<IFilter> = {
      customFormDescription: {
        priceZoneId: builder.lookup('priceZoneId', CodebookLookupSequence.PriceZone),
        competitorIds: builder.arr(builder.lookup('competitorIds', CodebookLookupSequence.Competitor)),
        dateFrom: builder.date('dateFrom', DateParser.ISO_PATTERN),
      },
      customFormFields: (
        <>
          <Field name="priceZoneId" />
          <Field name="competitorIds" />
          <Field
            name="dateFrom"
            component={(props) =>
              DatePickerInputAdapter('dateFrom', false, {}, true, 'err-form-vertical', 'w YYYY')(props)
            }
          />
        </>
      ),
      initialValues: {...this.store.commonInitialValues, ...this.queryParsed},
      isLoading: this.store.loadingState === LoadingState.Pending,
    };
    return formProps;
  }

  @computed
  public get renderContent() {
    return (
      <>
        <AgGrid
          rowData={this.store.gridRowData}
          columnDefs={this.store.columnDefs}
          gridId="CompetitorPriceChangeReport"
          className="mb2"
          domLayout="autoHeight"
        />
        <SplineChart
          showToolTip
          showLegend
          yVisible
          xVisible
          xAxisTitle={translate('week')}
          yAxisTitle={translate('competitor-price-change-report-chart')}
          data={this.store.chartRowData}
          xAxisOptions={{
            type: 'datetime',
            tickInterval: 7 * 24 * 36e5, // one week
            labels: {
              format: '{value}',
              formatter: function() {
                const week = moment(this.value).week();
                const year = moment(this.value).year();
                return `${week} (${year})`;
              },
              align: 'right',
              rotation: -30,
            },
          }}
          tooltipOptions={{
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: '{point.x:%e. %b}: {point.y:.2f}',
          }}
        />
      </>
    );
  }
}

export default withRouter(CompetitorsPriceChangeReportPage);
