/**
 * @file Created on Tue Mar 14 2020
 * @author Sve
 */

import * as React from 'react';
import { AgGrid, AgGridWrapper, Page, FormGroupInline, Button, getPath, translate } from '@logio/common-fe';
import { PageNamesEnum } from '../../shared/localization/PageNamesEnum';
import { Observer, observer } from 'mobx-react';
import { EndSelloutsPageStore } from '../../stores/pages/Sellouts/EndSelloutsPageStore';
import { Link } from 'react-router-dom';
import { PagePathsEnum } from '../../shared/localization/PagePathsEnum';

/**
 * End sellout main page
 */
//
@observer
class EndSelloutsPage extends React.Component {
    store = new EndSelloutsPageStore();

    componentWillMount() {
        this.store.load();
    }

    render() {
        return (
            <Page titleKey={PageNamesEnum.EndSellouts} loadingState={this.store.loadingState}>
                <FormGroupInline>
                    <div className="flex-grow-1">&nbsp;</div>
                    <FormGroupInline>
                        <div className="mr1">
                            <Link to={getPath(PagePathsEnum.EndSelloutsForm)}>
                                <Button>{translate('UPLOAD_NEW_TERMINATION')}</Button>
                            </Link>
                        </div>
                    </FormGroupInline>
                </FormGroupInline>
                <AgGridWrapper>
                    <AgGrid
                        rowData={this.store.rowData}
                        columnDefs={this.store.columnDefinitions}
                        gridId="SelloutTerminationsSummary"
                        quickView
                        sizeColumnsToFit
                    />
                </AgGridWrapper>
            </Page>
        );
    }
}

export default EndSelloutsPage;
