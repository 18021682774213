import { ReleaseState, ReleaseType } from "@logio/common-be-fe";
/**
 * Returns the workflow cases lock heartbeat data for given release and categoryId. For release which:
 * - is Opened
 * - is of type Regular or Sellout
 * @param release {Release} - Release
 * @param categoryId {string} - The category ID
 * @returns WorkflowCasesLockHeartbeatProps
 */

export var getWorkflowCasesLockHeartbeat = function getWorkflowCasesLockHeartbeat(release, categoryId) {
  var workflowCasesLockHeartbeat;

  if (release && release.state === ReleaseState.Opened && (release.releaseType === ReleaseType.Regular || release.releaseType === ReleaseType.Sellout)) {
    var categoryPart = release.releaseParts.find(function (part) {
      return part.productCategoryId === categoryId;
    });

    if (categoryPart) {
      workflowCasesLockHeartbeat = {
        caseIds: [categoryPart.workflowCaseId]
      };
    }
  }

  return workflowCasesLockHeartbeat;
};
export var getExportWorkflowCaseLockHeartbeat = function getExportWorkflowCaseLockHeartbeat(release) {
  if (release.state === ReleaseState.ExportEditing && release.exportWorkflowCaseId) {
    return {
      caseIds: [release.exportWorkflowCaseId]
    };
  }

  return undefined;
};