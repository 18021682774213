/**
 * @file Created on Tue Aug 14 2018
 * @author SKu
 */
import TextAreaStyled from './styled/TextAreaStyled';
import TextAreaWrapper from './styled/TextAreaWrapper';
import * as React from 'react';
import StyledLabel from '../../typography/Label';
import withValidation from '../withValidation';
/**
 * Component creates textarea
 */

var TextArea = function TextArea(props) {
  return React.createElement(React.Fragment, null, props.label && React.createElement(StyledLabel, null, props.label), React.createElement(TextAreaWrapper, {
    className: "input-wrapper"
  }, React.createElement(TextAreaStyled, Object.assign({
    rows: props.rows ? props.rows : 10
  }, props))));
};

export default TextArea;
export var TextAreaValidated = withValidation(TextArea);