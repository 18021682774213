"use strict";
/*
 * Created on Tue Aug 07 2018
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});

var CodebookLookupSequence_1 = require("../../../core/model/codebook/CodebookLookupSequence");

exports.CONSTRAINT_TYPE_LOOKUP = CodebookLookupSequence_1.CodebookLookupSequence.ConstraintType;
/**
 * Constraint types enumeration
 *
 * @author RV
 */

var ConstraintTypeEnum;

(function (ConstraintTypeEnum) {
  ConstraintTypeEnum["RepricingFrequency"] = "REPRICING_FREQUENCY";
  ConstraintTypeEnum["ListingPriceProtection"] = "LISTING_PRICE_PROTECTION";
  ConstraintTypeEnum["AbovePromotion"] = "ABOVE_PROMOTION";
  ConstraintTypeEnum["PromoProtection"] = "PROMO_PROTECTION";
  ConstraintTypeEnum["Margin"] = "MARGIN";
  ConstraintTypeEnum["AverageMargin"] = "AVERAGE_MARGIN";
  ConstraintTypeEnum["PriceChange"] = "PRICE_CHANGE";
  ConstraintTypeEnum["WeekDayRestriction"] = "WEEK_DAY_RESTRICTION";
})(ConstraintTypeEnum = exports.ConstraintTypeEnum || (exports.ConstraintTypeEnum = {}));