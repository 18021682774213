/**
 * @file Created on Fri Feb 01 2019
 * @author SKu
 */

import {
  DataDesc,
  ElasticityProductData,
  ElasticityReport,
  PriceFormulas,
  StructPropBuilder,
  Utils,
} from '@logio/common-be-fe';
import {
  arrayToMap,
  ChartGenerator,
  ChartValue,
  ColumnDefinition,
  ColumnGenerator,
  LoadingState,
  Polling,
  RendererNames,
  StringMapping,
  translate,
  Comparators,
} from '@logio/common-fe';
import { bigdecimal } from '@logio/big-decimal';
import {computed, observable, runInAction} from 'mobx';
import {AbstractRelatedReportsPageStore} from './AbstractRelatedReportsPageStore';
import {RelatedReportsFormFilter} from '../../../../pages/Reports/RelatedReports/RelatedReportsFilter';

/**
 * Based on ElasticityProductPriceData
 */
export enum ElasticityProductPriceDataEnum {
  Margin = 'margin',
  MarginChangeCZK = 'marginChangeCZK',
  MarginChangePercent = 'marginChangePercent',
  Price = 'price',
  PriceChangeCZK = 'priceChangeCZK',
  PriceChangePercent = 'priceChangePercent',
  Profit = 'profit',
  ProfitChangeCZK = 'profitChangeCZK',
  ProfitChangePercent = 'profitChangePercent',
  SalesVolume = 'salesVolume',
  SalesVolumeChangeUnit = 'salesVolumeChangeUnit',
  SalesVolumeChangePercent = 'salesVolumeChangePercent'
}

export class PriceElasticityReportPageStore extends AbstractRelatedReportsPageStore<RelatedReportsFormFilter> {
  /**
   * Map of all product elasticity report data per category,
   * where key - productId
   */
  @observable
  public categoryData = new Map<string, ElasticityProductData>();

  /** Returns data for currently selected product */
  @computed
  public get productData() {
    return this.filterValues && this.categoryData.get(this.filterValues.productId);
  }

  /** Polling services */
  private polling = new Polling(10000); // LOG-5980 interval 10s
  /** Description builder */
  private builder = new StructPropBuilder('PriceElasticityReport');
  /** Definitions for the ag-grid */
  private description: DataDesc = {
    elasticityCoefficient: this.builder.bigNum('elasticityCoefficient'),
    margin: this.builder.bigNum('margin'),
    marginChangeCZK: this.builder.bigNum('marginChangeCZK'),
    marginChangePercent: this.builder.bigNum('marginChangePercent'),
    price: this.builder.bigNum('price'),
    priceChangeCZK: this.builder.bigNum('priceChangeCZK'),
    priceChangePercent: this.builder.bigNum('priceChangePercent'),
    profit: this.builder.bigNum('profit'),
    profitChangeCZK: this.builder.bigNum('profitChangeCZK'),
    profitChangePercent: this.builder.bigNum('profitChangePercent'),
    salesVolume: this.builder.bigNum('salesVolume'),
    salesVolumeChangeUnit: this.builder.bigNum('salesVolumeChangeUnit'),
    salesVolumeChangePercent: this.builder.bigNum('salesVolumeChangePercent'),
  };
  /** Ag-grid generator */
  private columnGenerator = new ColumnGenerator<StringMapping<any>>(this.description);
  /** Chart generator */
  private percentChartGenerator = new ChartGenerator<StringMapping<any>>({
    ARP: this.builder.num('ARP'),
    ['%']: this.builder.num('%'),
  });
  private czkChartGenerator = new ChartGenerator<StringMapping<any>>({
    ARP: this.builder.num('ARP'),
    ['CZK']: this.builder.num('CZK'),
  });
  private unitChartGenerator = new ChartGenerator<StringMapping<any>>({
    ARP: this.builder.num('ARP'),
    ['Unit']: this.builder.num('Unit'),
  });

  /**
   * Function updates Page filter on Form change
   * also updates data needed to the page
   * If all values are valid, set global filter variable,
   * else set it to undefined
   * @param filterValues - Form callback
   */
  public onFilter = async (filterValues: RelatedReportsFormFilter) => {
    const prevFilter = this.filterValues;
    runInAction(() => (this.filterValues = filterValues));
    if (filterValues.categoryId !== prevFilter.categoryId || this.categoryData.size === 0) {
      runInAction(() => {
        this.productInfo = undefined;
        this.categoryData.clear();
      });
      this.updatePageData();
      await this.loadProductsInfo();
    }
    if (filterValues.productId !== prevFilter.productId || Utils.isValueMissing(this.productInfo)) {
      this.setProductInfo();
    }
  };

  /**
   * Function clears cached data on BE
   */
  public onCacheClear = async (): Promise<void> => {
    try {
      runInAction(() => this.categoryData.clear());
      await this.reportLayer.clearPriceElasticityReport();
      this.messages.setSuccess(translate('elasticity-report-cache-cleared'));
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  /**
   * Returns generated column definitions for the ag-grid
   */
  @computed
  public get columnDefs(): ColumnDefinition[] {
    const colDefs: ColumnDefinition[] = Object.keys(this.description).map(
      (key) =>
        /** IF values are percents, should be rendered with special renderer */
        key.match(/^(price|elasticityCoefficient)$/)
          ? {field: key}
          : {field: key, comparator: Comparators.arrowComparator, cellRenderer: RendererNames.ArrowRenderer},
    );
    return this.columnGenerator.getColumnDefinitions(colDefs);
  }

  /**
   * Return generated data for the ag-grid
   */
  @computed
  public get rowData(): Array<StringMapping<any>> {
    return this.productData.priceData.map((price) => {
      /** number => bigNumber => round */
      const convertPrice = (p: number) => PriceFormulas.roundDefault(bigdecimal(p || 0));
      const newPrice = {
        elasticityCoefficient: convertPrice(this.productData.elasticityCoefficient),
        margin: convertPrice(price.margin),
        marginChangeCZK: convertPrice(price.marginChangeCZK),
        marginChangePercent: convertPrice(price.marginChangePercent),
        price: convertPrice(price.price),
        priceChangeCZK: convertPrice(price.priceChangeCZK),
        priceChangePercent: convertPrice(price.priceChangePercent),
        profit: convertPrice(price.profit),
        profitChangeCZK: convertPrice(price.profitChangeCZK),
        profitChangePercent: convertPrice(price.profitChangePercent),
        salesVolume: convertPrice(price.salesVolume),
        salesVolumeChangeUnit: convertPrice(price.salesVolumeChangeUnit),
        salesVolumeChangePercent: convertPrice(price.salesVolumeChangePercent),
      };
      return {...this.columnGenerator.getColumnData(newPrice)};
    });
  }

  /**
   * Chart data in %
   */
  @computed
  public get chartDataPercent(): Map<string, ChartValue[]> {
    const data = new Map<string, ChartValue[]>();
    const yAxis = [
      ElasticityProductPriceDataEnum.PriceChangePercent,
      ElasticityProductPriceDataEnum.MarginChangePercent,
      ElasticityProductPriceDataEnum.ProfitChangePercent,
      ElasticityProductPriceDataEnum.SalesVolumeChangePercent
    ];
    yAxis.forEach((key) => {
        const values = this.productData.priceData.map((p) => ({ARP: p.price, ['%']: p[key] || 0}));
        data.set(
          translate(`PriceElasticityReport_${key}`),
          this.percentChartGenerator.getSeries(values, 'ARP', '%'),
        );
    });
    return data;
  }

  /**
   * Chart data in CZK
   */
  @computed
  public get chartDataCzk(): Map<string, ChartValue[]> {
    const data = new Map<string, ChartValue[]>();
    const yAxis = [
      ElasticityProductPriceDataEnum.PriceChangeCZK,
      ElasticityProductPriceDataEnum.MarginChangeCZK,
      ElasticityProductPriceDataEnum.ProfitChangeCZK,
      ElasticityProductPriceDataEnum.Profit,
      ElasticityProductPriceDataEnum.Margin
    ];
    yAxis.forEach((key) => {
      const values = this.productData.priceData.map((p) => ({ARP: p.price, ['CZK']: p[key] || 0}));
      data.set(
        translate(`PriceElasticityReport_${key}`),
        this.czkChartGenerator.getSeries(values, 'ARP', 'CZK'),
      );
    });
    return data;
  }

  /**
   * Chart data in Unit
   */
  @computed
  public get chartDataUnit(): Map<string, ChartValue[]> {
    const data = new Map<string, ChartValue[]>();
    const yAxis = [
      ElasticityProductPriceDataEnum.SalesVolumeChangeUnit,
      ElasticityProductPriceDataEnum.SalesVolume
    ];
    yAxis.forEach((key) => {
      const values = this.productData.priceData.map((p) => ({ARP: p.price, ['Unit']: p[key] || 0}));
      data.set(
        translate(`PriceElasticityReport_${key}`),
        this.unitChartGenerator.getSeries(values, 'ARP', 'Unit'),
      );
    });
    return data;
  }

  /**
   * Fires when all Filter Fields have been chosen
   */
  protected updatePageData = async (): Promise<void> => {
    try {
      const response = await this.reportLayer.getPriceElasticityReport(this.filterValues.categoryId);
      if (response.message.match(/^(ready|failed)$/)) {
        this.handleFinishedResponse(response);
      } else {
        this.polling.startPolling(this.onPollingStarted, this.onPollResponse);
      }
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  /** Function that passed to start polling function */
  private onPollingStarted = async () => {
    try {
      this.setLoadingState(LoadingState.Pending);
      const response = await this.reportLayer.getPriceElasticityReport(this.filterValues.categoryId);
      return response;
    } catch (error) {
      this.polling.stopPolling();
      // this.messages.setError(error);
      this.setLoadingState(LoadingState.Success);
    }
  };

  /**
   * Function handle polling monitoring
   *
   * @param errors
   * @param response
   */
  private onPollResponse = (errors?, response?: ElasticityReport) => {
    if (response && response.message.match(/^(ready|failed)$/)) {
      this.handleFinishedResponse(response);
      this.polling.stopPolling();
    }
  };

  /** Handle endpoint response */
  private handleFinishedResponse = (response: ElasticityReport) => {
    this.setLoadingState(LoadingState.Success);
    if (response.message === 'ready') {
      runInAction(() => (this.categoryData = arrayToMap(response.results, 'productId')));
    }
    if (response.message === 'failed') {
      this.messages.setError('elasticity-YDG-failed');
    }
  };

  /** Fetches data for the page if initial values passed
   * @param initialValues - have inside productId and CategoryId
   */
  public load = async (initialValues?: RelatedReportsFormFilter) => {
    await this.priceZoneStore.getAll();
    runInAction(() => (this.filterValues = initialValues));
    if (initialValues.categoryId) {
      await this.loadProductsInfo();
    }
    if (initialValues.productId) {
      await this.updatePageData();
      this.setProductInfo();
    }
    this.setLoadingState(LoadingState.Success);
  };
}
