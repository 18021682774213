"use strict";
/*
 * Created on Wed Aug 08 2018
 *
 *
 * Strategy enums
 *
 * @author RV
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
var StrategyConverterTypeEnum;

(function (StrategyConverterTypeEnum) {
  StrategyConverterTypeEnum["SingleTargetStrategy"] = "SINGLE_TARGET_STRATEGY";
  StrategyConverterTypeEnum["MultiTargetStrategy"] = "MULTI_TARGET_STRATEGY";
})(StrategyConverterTypeEnum = exports.StrategyConverterTypeEnum || (exports.StrategyConverterTypeEnum = {}));