"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Enum of possible distribution options.
 * @author ZSr
 */
// TODO delete this, use codebook instead

var DistributionModelEnum;

(function (DistributionModelEnum) {
  DistributionModelEnum["EVERYTHING"] = "everything_in_1st_delivery_only";
  DistributionModelEnum["ALL_DELIVERIES"] = "all_deliveries";
  DistributionModelEnum["ONE_PER_SITE"] = "just_one_colli_per_site";
  DistributionModelEnum["MINIMUM_PER_SITE"] = "minimum_one_colli_per_site";
  DistributionModelEnum["FULFILL_SHELF"] = "fulfill_the_shelf_capacity_by_first_delivery";
  DistributionModelEnum["MANUAL_PUSH"] = "manual_push_orders";
  DistributionModelEnum["CLACULATED_PUSH"] = "calculated_push";
  DistributionModelEnum["OUT_OF_STOCK"] = "managed_out_of_stock";
  DistributionModelEnum["HOW_MANY_DAYS_TO_COVER_THE_FIRST_DELIVERY"] = "how_many_days_to_cover_the_first_delivery";
  DistributionModelEnum["REGIONAL_PRODUCT"] = "regional_product";
})(DistributionModelEnum = exports.DistributionModelEnum || (exports.DistributionModelEnum = {}));