"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Enum of expected columns in XLS file.
 * @author OPt
 */

var WindowImportColumnEnum;

(function (WindowImportColumnEnum) {
  WindowImportColumnEnum["windowStartDate"] = "window start date";
  WindowImportColumnEnum["windowEndDate"] = "window end date";
  WindowImportColumnEnum["groupOfSite"] = "group of sites";
  WindowImportColumnEnum["windowType"] = "window type";
  WindowImportColumnEnum["onPicture"] = "on picture";
  WindowImportColumnEnum["windowName"] = "window name";
  WindowImportColumnEnum["leafletText"] = "leaflet text";
  WindowImportColumnEnum["marketingComment"] = "marketing comment";
  WindowImportColumnEnum["promoPriceValidityStartDate"] = "promo price validity start date";
  WindowImportColumnEnum["promoPriceValidityEndDate"] = "promo price validity end date";
  WindowImportColumnEnum["goldNumber"] = "gold number";
  WindowImportColumnEnum["promoPrice"] = "promo price";
  WindowImportColumnEnum["cmForecastVolume"] = "cm forecast volume";
  WindowImportColumnEnum["promoVolume"] = "promo volume";
  WindowImportColumnEnum["posActionFlag"] = "pos action flag";
  WindowImportColumnEnum["posMechanics"] = "pos mechanics";
  WindowImportColumnEnum["posType"] = "pos type";
  WindowImportColumnEnum["posMultiBuyVariant"] = "multibuy variant";
  WindowImportColumnEnum["posDiscountPerPiece"] = "discount per piece";
  WindowImportColumnEnum["posGiftGold"] = "gift gold";
  WindowImportColumnEnum["posGiftPrice"] = "gift price";
  WindowImportColumnEnum["posGiftForecast"] = "gift forecast";
  WindowImportColumnEnum["posGiftDistributionModel"] = "gift distribution model";
  WindowImportColumnEnum["posGiftDistributionModelDays"] = "gift distribution model days";
  WindowImportColumnEnum["posMarginImpactTheoretical"] = "pos margin impact theoretical";
  WindowImportColumnEnum["posRedemption"] = "pos redemption";
  WindowImportColumnEnum["requestForSecondaryPlacement"] = "request for secondary placement";
  WindowImportColumnEnum["distributionModel"] = "distribution model";
  WindowImportColumnEnum["distributionModelDays"] = "distribution model days";
  WindowImportColumnEnum["directDelivery"] = "direct delivery";
  WindowImportColumnEnum["directDeliverySites"] = "direct delivery sites";
  WindowImportColumnEnum["returnable"] = "returnable";
  WindowImportColumnEnum["returnConditions"] = "return conditions";
  WindowImportColumnEnum["endDateOfReturn"] = "end date of return";
  WindowImportColumnEnum["lockType"] = "lock type";
  WindowImportColumnEnum["logisticComment"] = "logistic comment";
  WindowImportColumnEnum["supplierID"] = "supplier id";
  WindowImportColumnEnum["supplierDepartment"] = "supplier department";
  WindowImportColumnEnum["purchasePricePromo"] = "purchase price promo";
  WindowImportColumnEnum["purchasePricePromoStartDate"] = "purchase price promo start date";
  WindowImportColumnEnum["purchasePricePromoEndDate"] = "purchase price promo end date";
  WindowImportColumnEnum["minimalVolume"] = "minimal volume";
  WindowImportColumnEnum["maximalVolume"] = "maximal volume";
  WindowImportColumnEnum["leafletFee"] = "leaflet fee";
  WindowImportColumnEnum["secondaryPlacementFee"] = "secondary placement fee";
  WindowImportColumnEnum["unitBackDiscount"] = "unit back discount";
  WindowImportColumnEnum["backDiscountRealization"] = "back discount realization";
  WindowImportColumnEnum["supplierIDInvoice"] = "supplier id invoice";
  WindowImportColumnEnum["supplierDepartmentInvoice"] = "supplier department invoice";
  WindowImportColumnEnum["costPriceFormat"] = "purchase promo format";
})(WindowImportColumnEnum = exports.WindowImportColumnEnum || (exports.WindowImportColumnEnum = {}));

exports.mandatoryWindowImportColumns = [WindowImportColumnEnum.windowName, WindowImportColumnEnum.goldNumber];