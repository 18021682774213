/**
 * @file Created on Wed Apr 03 2019
 * @author SKu
 */

import {
  AbstractRelease,
  AbstractReleaseTotalDTO,
  ApprovalStatusIndication,
  DataDesc,
  PricingRoles,
  ProductCategory,
  ProductPricesUpload,
  RealImpactValue,
  ReleaseAction,
  ReleaseActionFeasibility,
  ReleaseState,
  ReleaseType,
  Role,
  RoleApprovalStatus,
  StructPropBuilder,
  UserInfo,
  Utils,
  ValidationResultWrapper,
} from '@logio/common-be-fe';
import {
  AbstractReleaseStore,
  ActionsGenerator,
  ColumnDefinition,
  ColumnGenerator,
  Comparators,
  CONSTANTS,
  FilterNames,
  FindTotalFilter,
  GeneralConfigurationsStore,
  getPath,
  IReleaseImpactFilter,
  LoadingState,
  PageStore,
  ProductCategoryStore,
  ProfileStore,
  RendererNames,
  rootStore,
  StoreName,
  StringMapping,
  translate,
  WorkflowAssigneeObserverStore,
} from '@logio/common-fe';
import {List} from 'immutable';
import {GridApi, GridReadyEvent, RowNode, ValueGetterParams} from 'ag-grid-community';
import {EventEmitter} from 'events';
import {History} from 'history';
import {action, computed, observable, runInAction} from 'mobx';
import moment from 'moment';
import {stringify} from 'querystring';
import {PollingHelper} from 'stores/components/PollingHelper';
import {PagePathsEnum} from '../../../../shared/localization/PagePathsEnum';
import {AbstractReleaseHeaderStore} from '../../../components';

export enum ReleaseDetailModalEnum {
  /** When the user clicks the upload button. */
  Upload = 'UPLOAD',
  /**
   * Modal for coping settings from current release to general settings
   * Not available  for Sellout releases
   */
  SaveToGeneralSettings = 'SAVE_TO_GENERAL_SETTINGS',
  /** Modal for cancelling release from release header */
  CancelRelease = 'CANCEL_RELEASE',
  /** Modal for release sellout reopening */
  Reopen = 'REOPEN_RELEASE',
}

export abstract class AbstractReleaseDetailPageStore extends PageStore {
  /** Stores */
  public abstract releaseStore: AbstractReleaseStore;
  public abstract releaseHeaderStore: AbstractReleaseHeaderStore;
  public profileStore: ProfileStore = rootStore.getStore(StoreName.Profile) as ProfileStore;
  public generalConfigurationsStore = rootStore.getStore(StoreName.GeneralConfigurations) as GeneralConfigurationsStore;
  /** Map of ReleaseTotalDTO, that has inside data for the  */
  @observable
  public abstract list: Map<string, AbstractReleaseTotalDTO>;
  /** Used only when release state is Released */
  @observable
  public impactFilter: IReleaseImpactFilter;
  /** Percentage of uploading */
  @observable
  public uploadProgress?: number;
  /** Info about product prices data upload. */
  /** A variable that keeps the status of opened modals. */
  @observable
  public modalShown: ReleaseDetailModalEnum;
  @observable
  public isConfirmModal: boolean = false;
  @observable
  public isConfirm4BOX: boolean = false;
  public confirmCategoryEventEmitter = new EventEmitter();
  @observable
  productPricesUpload: ProductPricesUpload;
  @observable
  uploadId: string;
  /**
   * A variable to show progress bar when recalculate action was fired
   */
  @observable
  isRecalculateActionFired: boolean = false;
  @observable
  isActionFired: boolean = false;
  /**
   * Cancel realease modal
   * Default value is false
   * After click on accept button in the modal the value is changed to true
   * When done the value is changed back to false
   */
  public isLoading: boolean = false;
  protected productCategoryStore = rootStore.getStore(StoreName.ProductCategory) as ProductCategoryStore;

  protected releasePartsAssigneeObserver = new WorkflowAssigneeObserverStore(
    CONSTANTS.WORKFLOW_ASSIGNEE_UPDATE_INTERVAL_IN_MS,
    () => {
      this.loadTotal();
      this.releaseStore.getOne(this.release.id);
    },
  );

  /********************************************************** AG-grid ****************************************************************************/
  protected gridApi: GridApi;
  /** Derived columns data description builders */
  protected totalBuilder = new StructPropBuilder('ReleaseTotalOverview');
  /** Ag-grid data generators */
  protected actionsGenerator = new ActionsGenerator();
  protected productCategoryColumnGenerator = new ColumnGenerator<StringMapping<any>>(this.productColumnsDescription);
  protected totalColumnGenerator = new ColumnGenerator<StringMapping<any>>(this.totalColumnsDescription);
  protected impactColumnGenerator = new ColumnGenerator<StringMapping<any>>(RealImpactValue.schema);
  /** Returns grid data for traverse method */
  protected abstract getRowData: (
    category: ProductCategory,
    releaseTotalItem: AbstractReleaseTotalDTO,
    pathData: {categoryId: string; path: List<string>},
  ) => Array<StringMapping<any>>;

  /**
   * @param history - fromm react router
   */
  constructor(public history: History) {
    super();
  }

  /** Current release */
  public abstract get release(): AbstractRelease;

  @computed
  public get releasePriceValidityStartDate() {
    return this.release ? this.release.priceValidityStartDate : null;
  }

  @computed
  public get isReleased() {
    return this.release.state === ReleaseState.Released;
  }

  // NOTE: overwritten in release detail page for all release types
  /** All col defs used in ag-grid */
  @computed
  public get columnDefs(): ColumnDefinition[] {
    return [
      ...this.totalColumnGenerator.getColumnDefinitions(this.totalColumnsDefs),
      ...this.impactColumnGenerator.getColumnDefinitions(this.impactColumnDefs),
    ];
  }

  /** Return autogroup column definition (for tree view) */
  public abstract get autoGroupColumnDefs();

  /**
   * Return generated data for ag-grid
   */
  @computed
  public get rowData() {
    const gridData: Array<StringMapping<any>> = [];
    if (this.productCategoryStore) {
      const rootCategory = this.productCategoryStore.getRootCategoryForRelease(this.release.id);
      if (rootCategory) {
        this.traverse(gridData, rootCategory, {categoryId: '', path: List()});
      }
    }
    return gridData;
  }

  /**
   * Return true if user need to confirm approve action
   */
  get isConfirmNeeded() {
    if (this.release.releaseType === ReleaseType.Regular) {
      return (
        this.profileStore.profile.hasRole(PricingRoles.CATEGORY_MANAGER) ||
        this.profileStore.profile.hasRole(PricingRoles.SENIOR_CATEGORY_MANAGER) ||
        this.profileStore.profile.hasRole(PricingRoles.PRICE_MANAGER)
      );
    } else if (this.release.releaseType === ReleaseType.Sellout) {
      return (
        this.profileStore.profile.hasRole(PricingRoles.CATEGORY_MANAGER) ||
        this.profileStore.profile.hasRole(PricingRoles.SELLOUT_MANAGER)
      );
    }
    return false;
  }

  /** Release specific description */
  protected abstract get customTotalColumnsDescription(): DataDesc;

  /** Merged description for ag-grid generator */
  @computed
  protected get totalColumnsDescription(): DataDesc {
    return {...this.commonTotalColumnsDescription, ...this.customTotalColumnsDescription};
  }

  /** Common definitions for total generator */
  @computed
  protected get impactColumnDefs(): ColumnDefinition[] {
    return [
      {
        field: 'saleValueWithoutVat',
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'revenueImpact',
        cellRenderer: RendererNames.ArrowRenderer,
        comparator: Comparators.arrowComparator,
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'amount',
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'salesVolumeImpact',
        cellRenderer: RendererNames.ArrowRenderer,
        comparator: Comparators.arrowComparator,
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'diffDays',
        valueFormatter: (params) => {
          return params.value ? Number(params.value).toFixed(0) : CONSTANTS.FORMAT.NULL;
        },
        dontCreateColumn: !this.isReleased,
      },
    ];
  }

  /** Release specific description */
  protected abstract get customTotalColumnsDefs(): ColumnDefinition[];

  /** Merged ag-grid columns definitions */
  @computed
  protected get totalColumnsDefs(): ColumnDefinition[] {
    return [...this.commonTotalColumnsDefs, ...this.customTotalColumnsDefs];
  }

  /** Return autogroup column definition (for tree view) */
  @computed
  protected get commonAutoGroupColumnDefs(): ColumnDefinition {
    /** Redirect to the detail category page(works only on the lowest categories) */
    const handleCellClick = ({node}: {node: RowNode}) => {
      const link = node.data[this.totalColumnsDescription.link.description.nameKey];
      if (!Utils.isValueMissing(link) && !this.isProductCategoryReadonly(node)) {
        this.history.push(link);
      }
    };

    /** Styles in case if node has link */
    const getClassRules = () => ({
      ['c-link pointer']: ({node}: {node: RowNode}) => {
        return (
          !!node.data[this.totalColumnsDescription.link.description.nameKey] && !this.isProductCategoryReadonly(node)
        );
      },
      ['c-mute']: ({node}: {node: RowNode}) => {
        return this.isProductCategoryReadonly(node);
      },
    });

    const autoGroupColDefs: ColumnDefinition = {
      field: 'name',
      width: 400,
      onCellClicked: handleCellClick,
      cellClassRules: getClassRules(),
      cellRendererParams: {
        suppressCount: true,
      },
      valueFormatter: ({ value }) => value.toString().replace(/&amp;/g, '&'),
    };

    return autoGroupColDefs;
  }

  /** Definition for tree data */
  @computed
  private get productColumnsDescription(): DataDesc {
    return {
      name: ProductCategory.schema.name,
    };
  }

  /** Common description for any release type */
  @computed
  private get commonTotalColumnsDescription(): DataDesc {
    return {
      link: this.totalBuilder.opt(this.totalBuilder.str('link')),
      messages: this.totalBuilder.obj('messages', ValidationResultWrapper.schema, (mat) => {
        return new ValidationResultWrapper(mat.resultObjects);
      }),
      approvalStatus: this.totalBuilder.listOf(
        this.totalBuilder.data('approvalStatus', {
          role: this.totalBuilder.str('role'),
          indication: this.totalBuilder.str('indication'),
          userInfo: this.totalBuilder.opt(this.totalBuilder.data('userInfo', UserInfo.schema)),
        }),
      ),
      actionFeasibilities: this.totalBuilder.listOf(
        this.totalBuilder.data('actionFeasibilities', {
          action: this.totalBuilder.senum('action', ReleaseAction, 'seq'),
          allowed: this.totalBuilder.bool('allowed'),
          lockedBy: this.totalBuilder.opt(this.totalBuilder.data('lockedBy', UserInfo.schema)),
        }),
      ),
    };
  }

  /** Common definitions for total generator */
  @computed
  private get commonTotalColumnsDefs(): ColumnDefinition[] {
    return [
      {
        field: 'messages',
        cellRenderer: RendererNames.ReleaseAlertsRenderer,
        comparator: Comparators.releaseAlertsComparator,
        filterValueGetter: (params: ValueGetterParams) => {
          const messages: string[] = [];
          if (params.data.ReleaseTotalOverview_messages) {
            params.data.ReleaseTotalOverview_messages.resultObjects.forEach(
              (obj: {severity: string; messageKey: string}) =>
                messages.push(translate(`${obj.severity}_${obj.messageKey}`)),
            );
          }
          return messages;
        },
        sortable: false,
        width: 80,
        excel: {
          hide: true,
        },
        dontCreateColumn: this.release.state === ReleaseState.Released,
      },
      {
        field: 'approvalStatus',
        sortable: false,
        suppressMenu: true,
        excel: {
          hide: true,
        },
        /** Convert value to status semaphore */
        cellRenderer: RendererNames.ReleaseStatusRenderer,
        /** Column is useless for simulation release */
        dontCreateColumn: this.release.releaseType === ReleaseType.Simulation,
      },
      {
        field: 'actionFeasibilities',
        sortable: false,
        suppressMenu: true,
        excel: {
          hide: true,
        },
        /** Convert value to buttons */
        cellRenderer: RendererNames.ReleaseActionRenderer,
        cellRendererParams: {isActionFired: this.isActionFired},
        /** Column is useless for simulation release */
        dontCreateColumn:
          this.release.releaseType === ReleaseType.Simulation || this.release.state === ReleaseState.Released,
      },
    ];
  }

  /** Checks if modal should be hidden. */
  public isModalHidden = (name: ReleaseDetailModalEnum) => this.modalShown !== name;

  /** Functions fire onGridReady, and set gridApi to the class variable */
  public onGridReady = (params: GridReadyEvent) => (this.gridApi = params.api);

  /** Return data path for tree view */
  public getDataPath = (data) => data.pathData.path;

  /********************************************************** AG-grid ****************************************************************************/

  /**
   * Returns true when category has exported products
   * @param node RowNode
   */
  public isProductCategoryReadonly = (node: RowNode) => {
    if (this.release.releaseParts) {
      const categoryId = node.data.pathData.categoryId;
      const foundProductCategoryId = this.release.releaseParts.find(
        (releasePart) => releasePart.productCategoryId === categoryId,
      );
      return !!(foundProductCategoryId && foundProductCategoryId.readonly);
    }
    return false;
  };

  /**
   * Called on confirm from modal
   */
  public onConfirm = () => {
    this.confirmCategoryEventEmitter.emit('confirmCategory', true);
  };

  /**
   * Called on cancel from modal
   */
  public onCancel = () => {
    this.confirmCategoryEventEmitter.emit('confirmCategory', false);
  };

  /** Handle opening price upload modal */
  public openPriceUploadModal = () => {
    runInAction(() => (this.productPricesUpload = undefined));
    this.openModal(ReleaseDetailModalEnum.Upload);
  };

  /** Handle opening price upload modal */
  public onImpactFilterSubmit = async (values: StringMapping<any>) => {
    runInAction(
      () =>
        (this.impactFilter = {
          dateFrom: Utils.isString(values.dateFrom)
            ? moment(values.dateFrom, CONSTANTS.FORMAT.DATE).format(CONSTANTS.FORMAT.DATE)
            : moment(values.dateFrom).format(CONSTANTS.FORMAT.DATE),
          dateTo: Utils.isString(values.dateTo)
            ? moment(values.dateTo, CONSTANTS.FORMAT.DATE).format(CONSTANTS.FORMAT.DATE)
            : moment(values.dateTo).format(CONSTANTS.FORMAT.DATE),
        }),
    );
    try {
      const totalMap = await this.releaseStore.getTotalWithImpact(this.release.id, this.impactFilter);
      this.setList(totalMap);
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  /**
   * Once the user has confirmed the upload form.
   */
  public onSubmitUpload = async ({dataFile}: any) => {
    if (dataFile.data) {
      const data = new FormData();
      data.append('productPricesFile', dataFile.data);
      runInAction(() => {
        this.productPricesUpload = null;
        this.messages.clear();
      });
      try {
        const productPricesUpload = await this.releaseStore.priceUpload(this.release.id, data);
        this.uploadId = productPricesUpload.id;
        if (productPricesUpload.progressMonitorId) {
          this.uploadPollingHelper.startPolling(productPricesUpload.progressMonitorId, 'price-upload-success');
        } else {
          runInAction(() => {
            this.productPricesUpload = productPricesUpload;
          });
        }
      } catch (error) {
        this.hideModal();
      }
    } else {
      throw new Error('Prices should be uploaded for this release, however no file was found.');
    }
  };

  /**
   * Cancel release
   */
  public onConfirmCancelRelease = async () => {
    this.isLoading = true;
    const actionData = {
      releaseId: this.release.id,
      action: ReleaseAction.Cancel,
      allCategories: true,
      categoryIds: null,
    };
    try {
      await this.releaseStore.action(actionData);
      /** Update whole page */
      const [totalMap] = await Promise.all([
        this.releaseStore.getTotal(this.release.id),
        this.releaseStore.getOne(this.release.id),
      ]);
      this.setList(this.list);
    } catch (error) {
      this.messages.setError(error);
    }
    this.isLoading = false;
    this.hideModal();
  };

  /**
   * Sets modalShown to current opened modal
   * @param modalType - name of ModalEnum that should be opened
   */
  public openModal = (modalType: ReleaseDetailModalEnum) => runInAction(() => (this.modalShown = modalType));

  /**
   * HOF for openModal
   * @param modalType - name of ModalEnum that should be opened
   */
  public getOpenModalEvent = (modalType: ReleaseDetailModalEnum) => () => this.openModal(modalType);

  /**
   * Hides any modal
   */
  public hideModal = () => runInAction(() => (this.modalShown = undefined));

  /**
   * Fletches data for the page
   * @param releaseId
   */
  public load = async (releaseId: string, getImpactFilter?: () => IReleaseImpactFilter): Promise<void> => {
    try {
      this.setLoadingState(LoadingState.Pending);

      // This will secretly load release into `this.release`
      await this.releaseHeaderStore.load(releaseId);

      /** If release not opened yet, we should start polling */
      if (this.release.state === ReleaseState.Computing) {
        // NOTE: calcProgressMonitorId is the same for all release parts, when whole release is recalculating
        // so we could pick progressID from any release part
        // const progressMonitor = this.release.releaseParts.get(0).calcProgressMonitorId;
        // LOG-4039 - calcProgressMonitorId is now on release
        const progressMonitor = this.release.calcProgressMonitorId;
        if (progressMonitor) {
          const pollingCouldBeStarted = await this.pollingHelper.couldPollingBeStarted(progressMonitor);
          if (pollingCouldBeStarted) {
            this.pollingHelper.startPolling(progressMonitor, 'RELEASE');
          }
        }
        /** Fetch data for the page only if release has been computed */
      } else if (this.release.state !== ReleaseState.ComputationFailed) {
        if (getImpactFilter) {
          runInAction(() => (this.impactFilter = getImpactFilter()));
        }
        await this.loadData();

        // LOG-6250 - start checking assignees for release parts to update buttons
        this.initReleasePartsAssigneeObserver();
      }

      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  /**
   * Cleans data of the store, which should be cleaned. Should be called in componentWillUnmount in component, which called `load()`
   */
  public destroy() {
    this.pollingHelper.polling.stopPolling();
    this.destroyReleasePartsAssigneeObserver();
  }

  /**
   * This function is called during store `load`, when release is not in `Computing` or `ComputationFailed` state.
   */
  protected async loadData() {
    await this.loadTotal();
  }

  /**
   * Loads data for main release aggregation table.
   */
  protected async loadTotal() {
    if (this.release.state === ReleaseState.Released) {
      this.setList(await this.releaseStore.getTotalWithImpact(this.release.id, this.impactFilter));
    } else {
      this.setList(await this.releaseStore.getTotal(this.release.id));
    }
  }

  /** Returns common data for Total grid generator(they used only for bottom category level) */
  protected getCommonTotalGeneratorData = (category: ProductCategory, releaseTotalItem: AbstractReleaseTotalDTO) => {
    const releasePart = this.release.releaseParts.find(({productCategoryId}) => category.id === productCategoryId);
    const allPartsToApprove = this.release.releaseParts.filter(
      ({workflowCaseId}) => !Utils.isValueMissing(workflowCaseId),
    );

    const categoryIds = [];
    allPartsToApprove.forEach((part) => categoryIds.push(part.productCategoryId));

    return {
      /** Messages that should be shown as Alert Icon in grid */
      messages: releasePart && releasePart.validationResult,
      /** Semaphore that shows status */
      approvalStatus: releaseTotalItem.approvalStatus,
      /** Approve/Reject buttons */
      actionFeasibilities: {
        action: releaseTotalItem.actionFeasibilities,
        onAction: (releaseAction) => this.onAction(releaseAction, releasePart ? [category.id] : categoryIds),
        readOnly: this.release.releaseParts ? !!(releasePart && releasePart.readonly) : false,
      },
    };
  };

  /**
   * Function fires when user click [Approve || Reject] button in Ag-Grid
   * Updating Total overview and Release Header
   * @param categoryId
   */
  protected onAction = async (releaseAction: ReleaseAction, categoryIds: string[]) => {
    const actionData = {
      releaseId: this.release.id,
      action: releaseAction,
      allCategories: false,
      categoryIds,
    };
    const confirmCategory = async () => {
      try {
        // lOG-6011 disable buttons
        runInAction(() => (this.isActionFired = true));
        this.gridApi.clearFocusedCell();
        // this.gridApi.refreshCells();
        this.gridApi.showLoadingOverlay();
        const filterModel = this.gridApi.getFilterModel(); // LOG-4411
        await this.releaseStore.action(actionData);
        /** If action relates only for one category, we need to update only that and root category */
        const filter: FindTotalFilter =
          categoryIds.length === 1 ? {releasePartCategoryIds: categoryIds, includeRoot: true} : {};
        const [totalMap] = await Promise.all([
          this.releaseStore.getTotal(this.release.id, filter),
          this.releaseStore.getOne(this.release.id),
        ]);
        /** If action relates only for one category, we need only set new values to the map, otherwise update whole map */
        runInAction(
          () => (categoryIds.length === 1 ? totalMap.forEach((v, k) => this.list.set(k, v)) : (this.list = totalMap)),
        );
        this.gridApi.setFilterModel(filterModel); // LOG-4411
      } catch (error) {
        // this.messages.setError(error);
      } finally {
        this.gridApi.hideOverlay();
        runInAction(() => (this.isActionFired = false));
      }
    };
    if (actionData.action === ReleaseAction.Approve && this.isConfirmNeeded) {
      this.confirmCategoryEventEmitter.addListener('confirmCategory', async (isConfirmed) => {
        runInAction(() => (this.isConfirmModal = false));
        this.confirmCategoryEventEmitter.removeAllListeners();
        if (isConfirmed) {
          await confirmCategory();
        }
      });
      runInAction(() => (this.isConfirm4BOX = actionData.allCategories));
      runInAction(() => (this.isConfirmModal = true));
    } else {
      await confirmCategory();
    }
  };

  /** Function update total overview after release header has been updated */
  protected onReleaseHeaderUpdated = async () => {
    try {
      const totalMap = await this.releaseStore.getTotal(this.release.id);
      this.setList(totalMap);
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  protected getPathToDetailCategory = (path: PagePathsEnum, categoryId: string): string => {
    /** Final path could be influenced by impact mode */
    return this.release.state === ReleaseState.Released
      ? `${getPath(path, this.release.id, categoryId)}?${stringify({
          dateFrom: this.impactFilter.dateFrom,
          dateTo: this.impactFilter.dateTo,
        })}`
      : getPath(path, this.release.id, categoryId);
  };

  /** Since we need tree view structure we need to traverse through children of each category */
  private traverse = (
    gridData: Array<StringMapping<any>>,
    category: ProductCategory,
    pathData: {categoryId: string; path: List<string>},
  ) => {
    /** Path for tree view */
    pathData.categoryId = category.id;
    pathData.path = pathData.path.push(category.name);
    /** Current release item */
    const releaseTotalItem: AbstractReleaseTotalDTO = this.list.get(category.id);
    /** Total list should be shown only for categories that are included */
    if (!Utils.isValueMissing(releaseTotalItem)) {
      gridData.push(...this.getRowData(category, releaseTotalItem, Object.assign({}, pathData)));
    }

    // LOG-7803 4BOX: Active categories do not have inactive categories among their childrenIds,
    // so we must use parentId attribute to gather also inactive children
    const allCategoriesForRelease = Array.from(this.productCategoryStore.getCategoriesForRelease(this.release.id).values());
    const childrenCategories = allCategoriesForRelease.filter(c => c.parentId === category.id);

    /** Run this function for every child category */
    childrenCategories.forEach((childCategory: ProductCategory) => {
      if (this.list.has(childCategory.id)) {
        this.traverse(gridData, childCategory, Object.assign({}, pathData));
      }
    });
  };

  /**
   * Calculates current upload progress
   */
  private onUploadProgress = (progressEvent: {loaded: number; total: number}) => {
    runInAction(() => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      this.uploadProgress = percentCompleted;
    });
  };

  private onPollingStateChanged = (pollingState: LoadingState) => {
    if (pollingState === LoadingState.Success) {
      runInAction(() => (this.isRecalculateActionFired = false));
      this.load(this.release.id);
    }
    if (pollingState === LoadingState.Error) {
      runInAction(() => (this.isRecalculateActionFired = false));
      this.load(this.release.id);
    }
  };

  public pollingHelper = new PollingHelper(this.messages, this.onPollingStateChanged);

  private onUploadStateChanged = (pollingState: LoadingState) => {
    console.log(pollingState);
    if (pollingState === LoadingState.Success) {
      this.getUploadedPrice(this.release, this.uploadId);
    }
    if (pollingState === LoadingState.Error) {
      this.hideModal();
    }
  };

  getUploadedPrice = async (release: AbstractRelease, uploadId: string) => {
    try {
      const uploadedPrice = await this.releaseStore.getUploadedPrice(release.id, uploadId);
      runInAction(() => {
        this.productPricesUpload = uploadedPrice;
      });
      await this.loadTotal();
    } catch (error) {
      this.hideModal();
    }
  };

  public uploadPollingHelper = new PollingHelper(this.messages, this.onUploadStateChanged);

  @action
  private setList(list: Map<string, AbstractReleaseTotalDTO>) {
    this.list = list;
  }

  private initReleasePartsAssigneeObserver() {
    if (this.release) {
      this.releasePartsAssigneeObserver.init(
        this.release.releaseParts.map((part) => part.workflowCaseId).filter((caseId) => caseId !== null),
      );
    }
  }

  private destroyReleasePartsAssigneeObserver() {
    this.releasePartsAssigneeObserver.destroy();
  }

  /**
   * Simple method to update release entity.
   */
  @action.bound
  public async refreshRelease() {
    return await this.releaseStore.getOne(this.release.id);
  }

  /**
   * Get current approval role for specified category.
   * @param categoryId
   */
  protected categoryOpenedFor(categoryId: string): Role | null {
    if (this.list.has(categoryId)) {
      const totalDTO = this.list.get(categoryId)!;
      const approvalStatus = totalDTO.approvalStatus.roleStatuses.find(
        roleStatus => roleStatus.indication === ApprovalStatusIndication.InProgress,
      );

      if (approvalStatus) {
        return approvalStatus.role;
      }
    }

    return null;
  }
}
