/**
 * @file Created on Fri Oct 05 2018
 * @author SKu
 */

import {PricingPermissions, ReleaseState, ReleaseAction} from '@logio/common-be-fe';
import {WithPermission} from '@logio/common-fe';
import {computed} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import {withRouter} from 'react-router';
import ReleaseSelloutDetailCategoryComponent from '../../../components/Release/ReleaseDetailCategory/ReleaseSelloutDetailCategoryComponent';
import ReleaseSelloutHeader from '../../../components/Release/ReleaseHeader/ReleaseSelloutHeader';
import {ReleaseSelloutDetailCategoryPageStore} from '../../../stores/pages/Release/ReleaseDeatailCategory/ReleaseSelloutDetailCategoryPageStore';
import AbstractReleaseDetailCategoryPage from './AbstractReleaseDetailCategoryPage';
import {ReleasedImpactFilter} from 'components/Release/ReleasedImpactFilter';

/**
 * Release sellout detail category page
 */
@observer
class ReleaseSelloutDetailCategoryPage extends AbstractReleaseDetailCategoryPage {
  public store = new ReleaseSelloutDetailCategoryPageStore(
    this.props.history,
    this.props.match.params.releaseId,
    this.props.match.params.categoryId,
  );

  /** @inheritdoc */
  public get releaseHeader() {
    return <ReleaseSelloutHeader store={this.store.releaseHeaderStore} customItems={this.releaseHeaderItems} />;
  }

  /** @inheritdoc */
  @computed
  public get releaseHeaderItems() {
    return this.store.release.state === ReleaseState.Released
      ? [
          // <ReleasedImpactFilter
          //   key="impact"
          //   initialValues={this.queryParsed}
          //   onChange={this.store.releaseDetailCategoryComponentStore.onFilterChanged}
          // />,
          <ReleasedImpactFilter
            key="impact"
            initialValues={this.parsedQueryFilter}
            onSubmit={this.store.releaseDetailCategoryComponentStore.onImpactFilter}
          />,
        ]
      : this.store.releaseDetailCategoryComponentStore.editMode
        ? [
            <WithPermission permissionTypes={[PricingPermissions.SELLOUT_RELEASE_CATEGORY_APPROVE]} key={'approve'}>
              {this.approveHeaderItem}
            </WithPermission>,
            <WithPermission some permissionTypes={this.store.permissionsForEdit} key={'unlock'}>
              {!this.store.releaseHeaderStore.releaseStore.isSelloutSimulation && this.unlockHeaderItem}
            </WithPermission>,
            // TODO: add permission on BE for recalculating
            // <WithPermission permissionTypes={[PricingPermissions.REGULAR_RELEASE_RECALCULATE]} key={'recalculate'}>
            this.recalculateHeaderItem,
            // </WithPermission>,
          ]
        : [
            <WithPermission some permissionTypes={this.store.permissionsForEdit} key={'lock'}>
              {!this.store.releaseHeaderStore.releaseStore.isSelloutSimulation && this.lockHeaderItem}
            </WithPermission>,
          ];
  }

  /** @inheritdoc */
  @computed
  get renderContent() {
    return (
      <ReleaseSelloutDetailCategoryComponent
        store={this.store.releaseDetailCategoryComponentStore}
        recalculate={(recalculateEvent) => this.store.onAction(ReleaseAction.Recalculate, recalculateEvent)}
        isRecalculationRequired={this.store.isRecalculationRequired}
        recalculateActionFired={this.store.recalculateActionFired}
        pollingHelper={this.store.pollingHelper}
      />
    );
  }
}

export default withRouter(ReleaseSelloutDetailCategoryPage);
