/**
 * Construct className props by given condition
 * @param condition Condition to check
 * @param className Will be included in the returned object
 * @param defaultClassName Prefix of the CSS class name
 */
export var getClassIf = function getClassIf(condition, className, defaultClassName) {
  if (condition) {
    return {
      className: "".concat(defaultClassName, " ").concat(className)
    };
  } else {
    return {
      className: "".concat(defaultClassName)
    };
  }
};