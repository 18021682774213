import {
  ReleaseState,
  ReleaseType,
  CommonReleaseOverview,
} from '@logio/common-be-fe';
import {
  getPath,
} from '@logio/common-fe';
import { PagePathsEnum } from '../../../../shared/localization/PagePathsEnum';
import { AbstractReleasePageStore } from './AbstractReleasePageStore';

/**
 * Store for Release overview release page.
 * 
 * @author Jan Prokop
 */
export class RegularReleasePageStore extends AbstractReleasePageStore {
  /** @inheritdoc */
  protected releaseTypes = [ReleaseType.Regular, ReleaseType.Simulation];

  // For some reason CodebookLookupSequence.PriceListType was loaded

  // /** CodeBookItemLayer to load possible price list types. */
  // private codeBookItemLayer: CodeBookItemLayer = new CodeBookItemLayer();

  // /** Price list type to distinguish site releases. */
  // private priceListTypes: CodebookItem[];

  // /**
  //  * @inheritdoc
  //  */
  //  protected async loadData() {
  //   const priceListTypeIds = await this.codeBookItemLayer.loadLookup(CodebookLookupSequence.PriceListType);
  //   this.priceListTypes = priceListTypeIds.codeBooks;
  //   await super.loadData();
  // }

  /**
   * @inheritdoc
   */
  protected getReleaseDetailPath(overview: CommonReleaseOverview) {
    // Path depends on different release attributes
    let path: PagePathsEnum;

    if (overview && overview.priceListType === 'Regular - site') {
      path = PagePathsEnum.ReleaseRegularSiteDetail;
    } else {
      path = overview.state === ReleaseState.New ? PagePathsEnum.ReleaseSettings : PagePathsEnum.ReleaseDetail;
    }

    return getPath(path, overview.id);
  };
}
