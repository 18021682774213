import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n/* LOG-4180 */\n   position:absolute;\n   z-index:-100;\n   left:0;\n   margin:auto;\n  opacity:0;\n\n  & + label {\n    display: inline-block;\n    left: 0;\n    position: static;\n    background-image: url(", ");\n    background-repeat: no-repeat;\n    background-position: center left;\n    margin-right: 10px;\n  }\n\n  &:checked + label {\n    background-image: url(", ");\n  }\n\n  &:disabled + label {\n    opacity: 0.5;\n  }\n\n  &:focus + label {\n    color: ", ";\n    text-shadow: 2px 2px 1px ", ";\n  }\n\n  &.scale {\n    padding-left: ", ";\n  }\n\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  padding-left: 25px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  + label {\n    width: 22px;\n    padding: 0;\n    min-height: 22px;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author BPo
 * @author PKl
 */
import { darken } from 'polished';
import styled, { css, withTheme } from 'styled-components';
import checkbox0 from '../../../../assets/images/checkbox0_dark.svg';
import checkbox1 from '../../../../assets/images/checkbox1_dark.svg'; // interface Face extends

var minimalCheckBox = css(_templateObject());
/**
 * Create an Input component that'll render an <input> tag with some styles
 *
 * @author BPo
 * @author PKl
 */

export var StyledRequired = styled.div(_templateObject2());
var StyledCheckBox = styled.input(_templateObject3(), checkbox0, checkbox1, function (props) {
  return darken(1, props.theme.colors.text);
}, function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return !props.label ? minimalCheckBox : null;
});
export default withTheme(StyledCheckBox);