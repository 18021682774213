import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  html {\n    -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */\n  }\n\n  /* apply a natural box layout model to all elements */\n  *,\n  *:before,\n  *:after {\n    box-sizing: border-box;\n  }\n\n  /* remember to define focus styles! */\n  :focus {\n    outline: 0;\n  }\n\n  button,\n  input,\n  optgroup,\n  select,\n  textarea {\n    background-color: transparent;\n    border: 0;\n  }\n  input[type='button' i],\n  input[type='submit' i],\n  input[type='reset' i],\n  input[type='file' i]::-webkit-file-upload-button,\n  button {\n    border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);\n    border-style: solid;\n    border-width: 1px;\n    padding: 1px 7px 2px;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { css } from 'styled-components';
export var reset = css(_templateObject());