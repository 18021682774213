"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var DateParser_1 = require("../../../core/model/data-dictionary/parsers/DateParser");

var StructPropBuilder_1 = require("../../../core/model/data-dictionary/StructPropBuilder");

var WindowItemWarningFlag_1 = require("../../model/WindowItemWarningFlag");
/**
 * Window identification.
 * @author OPt
 */


var WindowImportWindowIdentification =
/**
 * @param id ID of the Window.
 * @param name Name of the Window.
 * @param startDate Start date of the window.
 * @param endDate End date of the Window.
 * @param productCount Count of products of the Window.
 * @param warningFlags Warning flags collected from windows items
 */
function WindowImportWindowIdentification(id, name, startDate, endDate, productCount, warningFlags) {
  _classCallCheck(this, WindowImportWindowIdentification);

  this.id = id;
  this.name = name;
  this.startDate = startDate;
  this.endDate = endDate;
  this.productCount = productCount;
  this.warningFlags = warningFlags;
};

exports.WindowImportWindowIdentification = WindowImportWindowIdentification; // tslint:disable-next-line:member-ordering

WindowImportWindowIdentification.schema = function () {
  var builder = new StructPropBuilder_1.StructPropBuilder(WindowImportWindowIdentification.name);
  return {
    id: builder.id('id'),
    name: builder.str('name'),
    startDate: builder.date('startDate', DateParser_1.DateParser.ISO_PATTERN),
    endDate: builder.date('endDate', DateParser_1.DateParser.ISO_PATTERN),
    productCount: builder.num('productCount'),
    warningFlags: builder.flags('warningFlags', WindowItemWarningFlag_1.WindowItemWarningFlag)
  };
}();