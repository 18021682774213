"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * JMe: I think we do not need this at all. Price list type is dynamically set from {@link PriceListSettings} and
 * {@link SelloutRelease.priceListType} is set based on the settings.
 *
 * @author Jaroslav Sevcik
 */

var SelloutReleaseType;

(function (SelloutReleaseType) {
  /**
   * @see {@link SelloutType.DeadStock}
   */
  SelloutReleaseType["Deadstock"] = "DEADSTOCK";
  /**
   * @see {@link SelloutType.Expiry}
   */

  SelloutReleaseType["EndangeredExpiry"] = "ENDANGERED_EXPIRY";
  /**
   * @see {@link SelloutType.ExitProcessRegularDelist}
   */

  SelloutReleaseType["ExitRegularDelist"] = "EXIT_REGULAR_DELIST";
  /**
   * @see {@link SelloutType.ExitProcessInOutDelist}
   */

  SelloutReleaseType["ExitInOutDelist"] = "EXIT_IN_OUT_DELIST";
  /**
   * @see {@link SelloutType.Overstock} ({@link SelloutSupply.promo} is set to false)
   */

  SelloutReleaseType["Overstock"] = "OVERSTOCK";
  /**
   * @see {@link SelloutType.Overstock} ({@link SelloutSupply.promo} is set to true)
   */

  SelloutReleaseType["OverstockPromo"] = "OVERSTOCK_PROMO";
  /**
   * @see {@link SelloutType.Remodeling}
   */

  SelloutReleaseType["Remodeling"] = "REMODELING";
  /**
   * Not imported type, used for urgent sellout releases only (items are uploaded by user).
   */

  SelloutReleaseType["ReturnableItems"] = "RETURNABLE_ITEMS";
})(SelloutReleaseType = exports.SelloutReleaseType || (exports.SelloutReleaseType = {}));