/**
 * @file Created on Tue Apr 09 2019
 * @author SKu
 */

import { CONSTANTS } from '@logio/common-fe';
import { GridApi, GridReadyEvent, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-community';
import { debounce } from 'lodash';
import { runInAction } from 'mobx';
import { SelloutIdentificationReportDetailPageStore } from './SelloutIdentificationReportDetailPageStore';

export class SelloutIdentificationReportDataSource implements IServerSideDatasource {
  lastParams: IServerSideGetRowsParams;
  private gridApi: GridApi;

  constructor(private store: SelloutIdentificationReportDetailPageStore) {
    //this.getRows = debounce(this.getRows.bind(this), 500);
  }

  public onGridReady = (params: GridReadyEvent) => {
    this.gridApi = params.api;
  };

  reloadRows() {
    if (this.gridApi) {
      this.gridApi.purgeServerSideCache();
    }

    /*const filter = {
      ...this.store.filters,
      startRow: 0,
      endRow: CONSTANTS.SELLOUT_IDENTIFICATION_REPORT.PAGE_SIZE,
    };

    this.store.setDTOs(filter).then(() => {
      const lastRow =
        this.store.rowData.length < CONSTANTS.SELLOUT_IDENTIFICATION_REPORT.PAGE_SIZE
          ? this.lastParams.request.startRow + this.store.rowData.length
          : null;
      this.lastParams.successCallback(this.store.rowData, lastRow);
      runInAction(() => (this.store.isFilterLoading = false)); // LOG-4975
    });*/
  }

  getRows(params: IServerSideGetRowsParams) {
    this.lastParams = params;

    const filter = {
      ...this.store.filters,
      startRow: params.request.startRow,
      endRow: params.request.endRow,
    };

    this.store.setDTOs(filter).then(() => {
      let lastRow = this.store.totalCount;
      if (lastRow == null && this.store.rowData.length < CONSTANTS.SELLOUT_IDENTIFICATION_REPORT.PAGE_SIZE) {
        lastRow = this.lastParams.request.startRow + this.store.rowData.length
      }

      params.successCallback(this.store.rowData, lastRow);
      runInAction(() => (this.store.isFilterLoading = false)); // LOG-4975
    });
  }
}
