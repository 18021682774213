"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var Permission_1 = require("./Permission");
/**
 * Well-known pricing permissions (use-cases).
 * These permissions must correspond to the roles imported to the KeyCloak server (see importPermissions.ts).
 * When new permission is added, it should be added also in KeyCloak server.
 *
 * Each permission role is then mapped to a composite role - regular realm roles,
 * (each application role has list of "more detailed" roles - permissions).
 *
 * @author Radek Beran
 */


var PricingPermissions = function PricingPermissions() {
  _classCallCheck(this, PricingPermissions);
};

exports.PricingPermissions = PricingPermissions; // Pricing user access rights
// Generated by importPermissions.ts from input:
// https://docs.google.com/spreadsheets/d/1jhTv0nto5Esf7Krqf0CELxzV_L6HB_iq_kqU9FMno4M/edit#gid=551633943

PricingPermissions.DATA_UPLOAD_PRICE_SENSITIVITY_VIEW = new Permission_1.Permission('data-upload-price-sensitivity-view');
PricingPermissions.REGULAR_RELEASE_SAVE_TO_GENERAL_SETTINGS = new Permission_1.Permission('regular-release-save-to-general-settings');
PricingPermissions.TIERS_MANAGE = new Permission_1.Permission('tiers-manage');
PricingPermissions.SELLOUT_IDENTIFICATION_REPORT = new Permission_1.Permission('sellout-identification-report');
PricingPermissions.REGULAR_MARGIN_REPORT = new Permission_1.Permission('regular-margin-report');
PricingPermissions.CONFIGURATIONS_MANAGE_EDIT = new Permission_1.Permission('configurations-manage-edit');
PricingPermissions.DATA_UPLOAD_COMPETITOR_EDIT = new Permission_1.Permission('data-upload-competitor-edit');
PricingPermissions.SIMULATION_RELEASE_SETTINGS_DETAIL_VIEW = new Permission_1.Permission('simulation-release-settings-detail-view');
PricingPermissions.SIMULATION_RELEASE_FAVOURITE_TAG = new Permission_1.Permission('simulation-release-favourite-tag'); // RFC

PricingPermissions.SELLOUT_SETTINGS_EDIT = new Permission_1.Permission('sellout-settings-edit');
PricingPermissions.SELLOUT_RELEASE_ADD_END_DATE = new Permission_1.Permission('sellout-release-add-end-date');
PricingPermissions.SIMULATION_RELEASE_SETTINGS_VIEW = new Permission_1.Permission('simulation-release-settings-view');
PricingPermissions.PRICE_ELASTICITY_REPORT = new Permission_1.Permission('price-elasticity-report');
PricingPermissions.DATA_CLEAN_AI_START = new Permission_1.Permission('data-clean-ai-start');
PricingPermissions.SIMULATION_RELEASE_EDIT_HEADER = new Permission_1.Permission('simulation-release-edit-header');
PricingPermissions.MATRIX_TABLE_SETTINGS_VIEW = new Permission_1.Permission('matrix-table-settings-view');
PricingPermissions.DATA_UPLOAD_PRICE_SENSITIVITY_EDIT = new Permission_1.Permission('data-upload-price-sensitivity-edit');
PricingPermissions.DATA_CLEAN_EDIT = new Permission_1.Permission('data-clean-edit');
PricingPermissions.DATA_CLEAN_VIEW = new Permission_1.Permission('data-clean-view');
PricingPermissions.SIMULATION_RELEASE_CANCEL = new Permission_1.Permission('simulation-release-cancel');
PricingPermissions.SIMULATION_RELEASE_OPEN_DISCARD = new Permission_1.Permission('simulation-release-open-discard');
PricingPermissions.SIMULATION_RELEASE_RECALCULATE = new Permission_1.Permission('simulation-release-recalculate');
PricingPermissions.COMPETITOR_SITES_MANAGE = new Permission_1.Permission('competitor-sites-manage');
PricingPermissions.COMPETITOR_PRIORITY_SETTINGS_VIEW = new Permission_1.Permission('competitor-priority-settings-view');
PricingPermissions.ZONES_MANAGE_VIEW = new Permission_1.Permission('zones-manage-view');
PricingPermissions.PRICE_STICKERS_REPORT = new Permission_1.Permission('price-stickers-report');
PricingPermissions.SELLOUT_RELEASE_DETAIL = new Permission_1.Permission('sellout-release-detail');
PricingPermissions.SIMULATION_RELEASE_CREATE = new Permission_1.Permission('simulation-release-create');
PricingPermissions.SIMULATION_RELEASE_SETTINGS_EDIT = new Permission_1.Permission('simulation-release-settings-edit');
PricingPermissions.REGULAR_RELEASE_DETAIL = new Permission_1.Permission('regular-release-detail');
PricingPermissions.SIMULATION_RELEASE_SETTINGS_DETAIL_EDIT = new Permission_1.Permission('simulation-release-settings-detail-edit');
PricingPermissions.MATRIX_TABLE_SETTINGS_EDIT = new Permission_1.Permission('matrix-table-settings-edit');
PricingPermissions.PRICE_SENSITIVITY_GROUP_EDIT = new Permission_1.Permission('price-sensitivity-group-edit');
PricingPermissions.GENERAL_SETTINGS_VIEW = new Permission_1.Permission('general-settings-view');
PricingPermissions.REGULAR_RELEASE_EXPORT = new Permission_1.Permission('regular-release-export');
PricingPermissions.SELLOUT_RELEASE_EXPORT = new Permission_1.Permission('sellout-release-export');
PricingPermissions.COMPETITOR_PRICE_CHANGE_REPORT = new Permission_1.Permission('competitor-price-change-report');
PricingPermissions.COMPETITOR_PRIORITY_SETTINGS_EDIT = new Permission_1.Permission('competitor-priority-settings-edit');
PricingPermissions.ZONES_MANAGE_EDIT = new Permission_1.Permission('zones-manage-edit');
PricingPermissions.PRICE_CHANGE_REPORT = new Permission_1.Permission('price-change-report');
PricingPermissions.PRICE_ARCHITECTURE_TASK = new Permission_1.Permission('price-architecture-task'); // TODO JMe/RBe: Sellouts. Check SELLOUT_RELEASE_EVALUATION_REPORT permission.

PricingPermissions.SELLOUT_RELEASE_EVALUATION_REPORT = new Permission_1.Permission('sellout-release-evaluation-report');
PricingPermissions.REGULAR_RELEASE_EVALUATION_REPORT = new Permission_1.Permission('regular-release-evaluation-report');
/** Edit sellout release target attributes (LOG-2025) */

PricingPermissions.SELLOUT_RELEASE_EDIT_TARGET = new Permission_1.Permission('sellout-release-edit-target');
PricingPermissions.REGULAR_RELEASE_SETTINGS_DETAIL_VIEW = new Permission_1.Permission('regular-release-settings-detail-view');
PricingPermissions.FAMILY_MANAGE = new Permission_1.Permission('family-manage');
PricingPermissions.GENERAL_SETTINGS_EDIT = new Permission_1.Permission('general-settings-edit');
PricingPermissions.LEADING_ITEM_SET = new Permission_1.Permission('leading-item-set');
PricingPermissions.MISSING_COMPETITOR_PRICE = new Permission_1.Permission('missing-competitor-price');
PricingPermissions.AGENCY_QUALITY_EVALUATION_REPORT = new Permission_1.Permission('agency-quality-evaluation-report');
PricingPermissions.REGULAR_RELEASE_CANCEL = new Permission_1.Permission('regular-release-cancel');
PricingPermissions.SELLOUT_RELEASE_CANCEL = new Permission_1.Permission('sellout-release-cancel');
PricingPermissions.SIMULATION_RELEASE_FINAL_PRICE_EDIT = new Permission_1.Permission('simulation-release-final-price-edit');
PricingPermissions.REGULAR_RELEASE_OPEN_DISCARD = new Permission_1.Permission('regular-release-open-discard');
PricingPermissions.SELLOUT_RELEASE_OPEN_DISCARD = new Permission_1.Permission('sellout-release-open-discard');
PricingPermissions.ROUNDING_RULES_SETTINGS = new Permission_1.Permission('rounding-rules-settings');
PricingPermissions.TIERS_SETTINGS_VIEW = new Permission_1.Permission('tiers-settings-view');
PricingPermissions.PRICE_INDEX_REPORT = new Permission_1.Permission('price-index-report');
PricingPermissions.SELLOUT_RELEASE_CREATE_OTHERS = new Permission_1.Permission('sellout-release-create-sellout');
PricingPermissions.SELLOUT_RELEASE_CREATE_URGENT = new Permission_1.Permission('sellout-release-create-urgent');
PricingPermissions.REGULAR_RELEASE_SETTINGS_DETAIL_EDIT = new Permission_1.Permission('regular-release-settings-detail-edit');
PricingPermissions.SELLOUT_RELEASE_REOPEN = new Permission_1.Permission('sellout-release-reopen');
PricingPermissions.SELLOUT_RELEASE_CATEGORY_APPROVE = new Permission_1.Permission('sellout-release-category-approve');
PricingPermissions.REGULAR_RELEASE_CATEGORY_APPROVE = new Permission_1.Permission('regular-release-category-approve');
PricingPermissions.REGULAR_RELEASE_FAVOURITE_TAG = new Permission_1.Permission('regular-release-favourite-tag'); // RFC

PricingPermissions.SIMULATION_RELEASE_SAVE_TO_GENERAL_SETTINGS = new Permission_1.Permission('simulation-release-save-to-general-settings');
PricingPermissions.SELLOUT_RELEASE_CATEGORY_REJECT = new Permission_1.Permission('sellout-release-category-reject');
PricingPermissions.REGULAR_RELEASE_CATEGORY_REJECT = new Permission_1.Permission('regular-release-category-reject');
PricingPermissions.REGULAR_RELEASE_SETTINGS_VIEW = new Permission_1.Permission('regular-release-settings-view');
PricingPermissions.TIERS_SETTINGS_EDIT = new Permission_1.Permission('tiers-settings-edit');
PricingPermissions.SELLOUT_RELEASE_EDIT_HEADER = new Permission_1.Permission('sellout-release-edit-header');
PricingPermissions.REGULAR_RELEASE_EDIT_HEADER = new Permission_1.Permission('regular-release-edit-header');
PricingPermissions.ROUNDING_RULES_EXAMPLE = new Permission_1.Permission('rounding-rules-example');
PricingPermissions.PRICE_INFLATION_REPORT = new Permission_1.Permission('price-inflation-report');
PricingPermissions.IMPACT_REPORT_REGULAR = new Permission_1.Permission('impact-report-regular');
PricingPermissions.IMPACT_REPORT_SELLOUT = new Permission_1.Permission('impact-report-sellout');
PricingPermissions.REGULAR_RELEASE_RECALCULATE = new Permission_1.Permission('regular-release-recalculate');
PricingPermissions.REGULAR_RELEASE_FINAL_PRICE_EDIT = new Permission_1.Permission('regular-release-final-price-edit');
PricingPermissions.SELLOUT_RELEASE_FINAL_PRICE_EDIT = new Permission_1.Permission('sellout-release-final-price-edit');
PricingPermissions.SELLOUT_RELEASE_DATA_UPLOAD_DETAIL = new Permission_1.Permission('sellout-release-data-upload-detail'); // RFC

PricingPermissions.SELLOUT_RELEASE_FAVOURITE_TAG = new Permission_1.Permission('sellout-release-favourite-tag'); // RFC

PricingPermissions.DATA_UPLOAD_COMPETITOR_VIEW = new Permission_1.Permission('data-upload-competitor-view');
PricingPermissions.REGULAR_RELEASE_DATA_UPLOAD_DETAIL = new Permission_1.Permission('regular-release-data-upload-detail'); // RFC

PricingPermissions.REGULAR_RELEASE_CREATE_URGENT = new Permission_1.Permission('regular-release-create-urgent');
PricingPermissions.REGULAR_RELEASE_CREATE_SUPERFAST = new Permission_1.Permission('regular-release-create-superfast');
PricingPermissions.SIMULATION_RELEASE_DETAIL = new Permission_1.Permission('simulation-release-detail');
PricingPermissions.CONFIGURATIONS_MANAGE_VIEW = new Permission_1.Permission('configurations-manage-view');
PricingPermissions.REGULAR_RELEASE_CREATE_OTHERS = new Permission_1.Permission('regular-release-create-others');
PricingPermissions.REGULAR_RELEASE_SETTINGS_EDIT = new Permission_1.Permission('regular-release-settings-edit');
PricingPermissions.DATA_PAIR = new Permission_1.Permission('data-pair');
PricingPermissions.SELLOUT_SETTINGS_VIEW = new Permission_1.Permission('sellout-settings-view');
PricingPermissions.RELEASE_EVALUATION_REPORT = new Permission_1.Permission('release-evaluation-report');
PricingPermissions.TIERS_SETTINGS = new Permission_1.Permission('tiers-settings');
PricingPermissions.SHARED_GRID_SETTINGS = new Permission_1.Permission('shared-grid-settings-edit');
PricingPermissions.DASHBOARD_RELEASES = new Permission_1.Permission('dashboard-releases');
PricingPermissions.DASHBOARD_MY_TASKS = new Permission_1.Permission('dashboard-my-tasks');
PricingPermissions.WORKFLOW_ADMINISTRATION = new Permission_1.Permission('workflow-administration'); // Internal system administrations

PricingPermissions.SYSTEMADMIN_IMPORT_STATUS_ACCESS_SCREEN = new Permission_1.Permission('systemadmin-import-status-access-screen'); // Previous deprecated permissions (must be removed from frontend/backend and then deleted):
// General settings

PricingPermissions.PRICE_ZONE_ADMIN = new Permission_1.Permission('price-zone-admin'); // Release

PricingPermissions.RELEASE_OPEN = new Permission_1.Permission('release-open'); // Sellouts sections
// tool for termination of sellout prices

PricingPermissions.END_SELLOUT_PRICES = new Permission_1.Permission('sellouts-end-sellout-prices'); // Regular site release sections/rights
// release management (there is currently only one permission to manage, view, etc. regular site release)

PricingPermissions.REGULAR_SITE_RELEASE_MANAGEMENT = new Permission_1.Permission('regular-release-site-management'); // tool for termination of regular prices set on specific sites.

PricingPermissions.END_REGULAR_SITE_PRICES = new Permission_1.Permission('end-regular-site-prices'); // Permission for SM to access sellout price termination report.

PricingPermissions.SELLOUT_TERMINATION_REPORT = new Permission_1.Permission('ended-sellout-prices-report');
PricingPermissions.DATA_UPLOAD_FAMILY_VIEW = new Permission_1.Permission('data-upload-family-view');
PricingPermissions.DATA_UPLOAD_FAMILY_EDIT = new Permission_1.Permission('data-upload-family-edit');