import { Utils } from "@logio/common-be-fe";
import generalComparator from "./generalComparator";
/**
 * @file Created on September 2019
 * @author Atreo Digital s.r.o.
 * @description Custom comparator for fields rendered by ReleaseReferencePriceRenderer
 */

var releaseReferencePriceComparator = function releaseReferencePriceComparator(valA, valB) {
  return generalComparator(toComparableValue(valA), toComparableValue(valB));
};

var toComparableValue = function toComparableValue(val) {
  return Utils.isValueMissing(val) || !val.hasOwnProperty("value") || Utils.isValueMissing(val.value) ? null : val.value.toNumber();
};

export default releaseReferencePriceComparator;