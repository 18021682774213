"use strict";
/*
 * Created on 8.12.2018
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author MSu
 */

var LockActionResultType;

(function (LockActionResultType) {
  LockActionResultType["Locked"] = "LOCKED";
  LockActionResultType["AlreadyLocked"] = "ALREADY_LOCKED";
  LockActionResultType["ConflictLock"] = "CONFLICT_LOCK";
  LockActionResultType["CannotLock"] = "CANNOT_LOCK";
  LockActionResultType["CannotLockPromoStateOpen"] = "CANNOT_LOCK_PROMOTION_STATE_OPEN";
})(LockActionResultType = exports.LockActionResultType || (exports.LockActionResultType = {}));