"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var StructPropBuilder_1 = require("../../../core/model/data-dictionary/StructPropBuilder");

var WindowImportError = function WindowImportError(description) {
  var line = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var column = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var flagMessage = arguments.length > 3 ? arguments[3] : undefined;
  var flagMessageVars = arguments.length > 4 ? arguments[4] : undefined;

  _classCallCheck(this, WindowImportError);

  this.description = description;
  this.line = line;
  this.column = column;
  this.flagMessage = flagMessage;
  this.flagMessageVars = flagMessageVars;
};

exports.WindowImportError = WindowImportError;

WindowImportError.schema = function () {
  var builder = new StructPropBuilder_1.StructPropBuilder(WindowImportError.name);
  return {
    description: builder.str('description'),
    line: builder.opt(builder.num('line')),
    column: builder.opt(builder.str('column')),
    flagMessage: builder.opt(builder.str('flagMessage')),
    flagMessageVars: builder.opt(builder.arr(builder.str('flagMessageVars')))
  };
}();