import { DateUtils as DateUtilsBeFe } from '@logio/common-be-fe';
/**
 * Extension of utility methods from common-be-fe
 */

export var DateUtils = Object.assign({
  /**
   * Retrieves start moment of day with respect to configured timezone. Resulted Moment is in UTC.
   * @param date moment in UTC to be aligned to the start of day in UTC with respect to configured timezone
   * @returns provided moment aligned to start of day in configured timezone.
   */
  startOfDayUTC: function startOfDayUTC(date) {
    return date.clone().startOf('day').utc();
  },

  /**
   * Retrieves last moment of day with respect to configured timezone. Resulted Moment is in UTC.
   * @param date moment in UTC to be aligned to the end of day in UTC with respect to configured timezone
   * @returns provided moment aligned to start of day in configured timezone.
   */
  endOfDayUTC: function endOfDayUTC(date) {
    return date.clone().endOf('day').utc();
  }
}, DateUtilsBeFe);