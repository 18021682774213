/**
 * @file Created on Fri Feb 22 2019
 * @author SKu
 */

import {
  DataDesc,
  FinalPriceType,
  FinalProductPrice,
  PriceChangeReportDTO,
  PriceChangeReportPricesDTO,
  PriceChangeReportRequest,
  ReleasePart,
  StructPropBuilder,
  Utils,
} from '@logio/common-be-fe';
import {
  ColumnDefinition,
  ColumnGenerator,
  CONSTANTS,
  getDateTimeFormat,
  PageStore,
  RendererNames,
  Renderers,
  ReportLayer,
  StringMapping,
  translate,
} from '@logio/common-fe';
import {computed, observable, runInAction} from 'mobx';
import {Moment} from 'moment';

export class PriceChangeReportPageStore extends PageStore {
  private reportLayer = new ReportLayer();

  /** Ag-grid services */
  builder = new StructPropBuilder('PriceChangeReport');
  priceChangeReportColumnsDescription: DataDesc = {
    releaseName: this.builder.str('releaseName'),
    productName: this.builder.str('productName'),
    pricingAreaId: this.builder.str('pricingAreaId'),
    typeOfPriceChange: FinalProductPrice.schema.priceType,
    finalPrice: FinalProductPrice.schema.value,
    comments: PriceChangeReportPricesDTO.schema.comments,
    changedBy: FinalProductPrice.schema.changedByUserName,
    changeDate: FinalProductPrice.schema.changeDate,
    approvedBy: ReleasePart.schema.approvedByUserName,
    approveDate: ReleasePart.schema.approved,
    priceValidationStartDate: FinalProductPrice.schema.validFrom,
    priceValidationEndDate: FinalProductPrice.schema.validTo,
  };
  priceChangeReportGenerator = new ColumnGenerator<StringMapping<any>>(this.priceChangeReportColumnsDescription);

  /** All data for current page */
  @observable
  priceChangeReportDTOs: PriceChangeReportDTO[];

  /**
   * Update data for the page
   * @param filterValues - Form callback
   */
  onFind = async (filterValues: PriceChangeReportRequest) => {
    try {
      const priceChangeReportDTOs = await this.reportLayer.getPriceChangeReportDTOs(filterValues);
      /** If data already exists, we should set info message */
      if (!Utils.isValueMissing(this.priceChangeReportDTOs)) {
        this.messages.setSuccess(translate('price-change-report-data-updated'));
      }
      runInAction(() => (this.priceChangeReportDTOs = priceChangeReportDTOs));
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  /**
   * Returns generated column definitions for the ag-grid
   */
  @computed
  get columnDefs(): ColumnDefinition[] {
    const colDefs: ColumnDefinition[] = [
      {
        field: 'releaseName',
      },
      {
        field: 'productName',
      },
      {
        field: 'pricingAreaId',
      },
      {
        field: 'typeOfPriceChange',
        valueFormatter: ({value}: {value: FinalPriceType}) =>
          Utils.isValueMissing(value) ? CONSTANTS.FORMAT.NULL : translate(`${value}-finalPrice`),
      },
      {
        field: 'finalPrice',
      },
      {
        field: 'comments',
        cellRendererFramework: Renderers[RendererNames.PriceChangeReportCommentsRenderer],
      },
      {
        field: 'changedBy',
      },
      {
        field: 'changeDate',
        valueFormatter: ({value}: {value: Moment}) =>
          Utils.isValueMissing(value) ? CONSTANTS.FORMAT.NULL : value.format(getDateTimeFormat()),
      },
      {
        field: 'approvedBy',
      },
      {
        field: 'approveDate',
      },
      {
        field: 'priceValidationStartDate',
      },
      {
        field: 'priceValidationEndDate',
      },
    ];

    return this.priceChangeReportGenerator.getColumnDefinitions(colDefs);
  }

  /**
   * Return generated data for the ag-grid
   */
  @computed
  get rowData(): Array<StringMapping<any>> {
    const rowData = [];
    this.priceChangeReportDTOs.map((priceChangeReportDTO) => {
      priceChangeReportDTO.prices.map((price) => {
        const generatorData = {
          releaseName: priceChangeReportDTO.releaseName,
          productName: priceChangeReportDTO.productInfo.name,
          approvedBy: priceChangeReportDTO.approvedBy,
          approveDate: priceChangeReportDTO.approveDate,
          pricingAreaId: price.pricingAreaId,
          typeOfPriceChange: price.finalPrice.priceType,
          finalPrice: price.finalPrice.value,
          comments: price.comments,
          changedBy: price.finalPrice.changedByUserName,
          changeDate: price.finalPrice.changeDate,
          priceValidationStartDate: price.finalPrice.validFrom,
          priceValidationEndDate: price.finalPrice.validTo,
        };
        rowData.push({...this.priceChangeReportGenerator.getColumnData(generatorData)});
      });
    });
    return rowData;
  }
}
