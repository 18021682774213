/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
import * as React from 'react';
import { showIf } from '../../../shared/rendering/showIf';
import { T } from '../../i18n/T';
import ButtonStyled from './styled/ButtonStyled';
import debounce from 'lodash/debounce';

var Button = function Button(props) {
  return React.createElement(ButtonStyled, {
    buttonColor: props.buttonColor,
    buttonSize: props.buttonSize,
    iconButton: props.iconButton,
    iconLeft: props.iconLeft,
    iconRight: props.iconRight,
    isActive: props.isActive,
    disabled: props.disabled,
    onClick: props.onClick && debounce(props.onClick, props.debounce ? props.debounce : 200),
    type: props.type,
    value: props.value,
    className: 'btn' + (props.className ? ' ' + props.className : ''),
    style: props.style
  }, props.children, showIf(props.descriptionKey, React.createElement("div", {
    className: "btn-description"
  }, React.createElement(T, {
    id: props.descriptionKey
  }))));
};

export default Button;