"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Contains all possible types of an user message.
 */

var UserMessageSeverity;

(function (UserMessageSeverity) {
  UserMessageSeverity["ERROR"] = "ERROR";
  UserMessageSeverity["WARNING"] = "WARNING";
  UserMessageSeverity["INFO"] = "INFO";
})(UserMessageSeverity = exports.UserMessageSeverity || (exports.UserMessageSeverity = {}));