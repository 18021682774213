import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  /* margin-bottom: ", "rem; */\n  max-height: ", "px;\n  margin-right: ", "rem;\n  vertical-align: top;\n  position: relative;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
import styled, { withTheme } from 'styled-components';
/**
 * Create an wrapper for CheckBoxButton
 */

var CheckBoxButtonWrapper = styled.div(_templateObject(), function (props) {
  return props.theme.spacing.spacer / 4;
}, function (props) {
  return props.theme.button.height;
}, function (props) {
  return props.theme.spacing.spacer / 4;
});
export default withTheme(CheckBoxButtonWrapper);