import {CodebookLookupSequence, StructPropBuilder} from '@logio/common-be-fe';
import {
  BoxDefault,
  ButtonLoading,
  Field,
  Form,
  FormGroupInline,
  Icon,
  IconType,
  OptionsProvider,
  StringMapping,
  translate,
  SelectOptions,
  SelectAdapter,
} from '@logio/common-fe';
import * as React from 'react';
import {observer} from 'mobx-react';
import { Moment } from "moment";
export interface RelatedReportsFormFilter {
  categoryId?: string;
  productId?: string;
  priceZoneId?: string;
  competitorIds?: string[];
  dateFrom?: Moment;
}

export interface RelatedReportsFilterProps<T> {
  /** Will be fired on form submit */
  onSubmit?: (values: StringMapping<any>) => void;
  /** Will be fired on every form change */
  onChange?: (values: object) => void;
  /** Form initial values
   * will be set if user came from somewhere
   */
  initialValues?: any;
  /** Schema for custom fields */
  customFormDescription?: StringMapping<any>;
  /**
   * Custom fields for the form
   * default are category and product
   */
  customFormFields?: JSX.Element;
  /** Page loading state (used for showing button) */
  isLoading?: boolean;
}

@observer
export class RelatedReportsFilter<T> extends React.Component<RelatedReportsFilterProps<T>> {
  /** Schema builder */
  builder = new StructPropBuilder('RelatedReportsFilter');
  /** Default form description that is the same trough all "related" reports */
  commonDescription = {
    categoryId: this.builder.lookup('categoryId', `${CodebookLookupSequence.Category}?level=5`),
    productId: this.builder
      .lookup('productId', `${CodebookLookupSequence.Product}?titleSpecification=NAME_WITH_EXTERNAL_ID`)
      .withDescription({mandatoryParameter: true}),
  };
  /** Merge custom description with common if exists, else returns commonDescription */
  getDescription = () =>
    this.props.customFormDescription
      ? {...this.props.customFormDescription, ...this.commonDescription}
      : this.commonDescription;

  render() {
    return (
      <BoxDefault className="mb2">
        <Form
          onSubmit={this.props.onSubmit}
          onChange={this.props.onChange}
          descriptions={this.getDescription()}
          initialValues={this.props.initialValues}
        >
          <FormGroupInline className="pb0">
            <Field name="categoryId" className="err-form-vertical" />
            <OptionsProvider
              name="productId"
              component={(selectOptions: SelectOptions) => (
                <Field
                  name="productId"
                  component={(params) =>
                    SelectAdapter('productId', selectOptions, false, false, false, true, 'err-form-vertical')(params)
                  }
                />
              )}
              dependsOn={{categoryId: 'categoryId'}}
            />
            {/* Rendering custom fields if passed  */}
            {this.props.customFormFields}
            <div className="flex-grow-1">&nbsp;</div>
            <ButtonLoading iconRight isLoading={this.props.isLoading}>
              {translate('Find')} <Icon iconType={IconType.search} />
            </ButtonLoading>
          </FormGroupInline>
        </Form>
      </BoxDefault>
    );
  }
}
