import { rem, darken, lighten } from 'polished';
var common = {
  borderRadius: '5px',
  shadow: '3px 3px 20px #eee'
};
var colors = {
  transparent: 'transparent',
  green: '#558b2f',
  greenLight: '#96Ba63',
  greenCellTextAndArrow: '#018c01',
  blue: '#0277BD',
  azure: '#e1f2fd',
  azureLight: '#f7fafd',
  steelLight: '#8797bc',
  steel: '#606679',
  steelDark: '#3f4d6c',
  white: '#fff',
  grayDark: '#484b51',
  gray: '#a1a3a5',
  grayLight: '#e0e1e0',
  grayUltraLight: '#f5f5f6',
  salmon: '#fab5b1',
  pink: '#fbd7d6',
  pinkLight: '#f8ebeb',
  apricot: '#fdd7ad',
  red: '#ff564c',
  redCellTextAndArrow: '#ff0000',
  yellow: '#ffdb52',
  yellowLight: '#fefedb',
  aquamarineDark: '#c3f4af',
  aquamarine: '#dcfacf',
  aquamarineLight: '#ecfae6',

  get primary() {
    return this.green;
  },

  get secondary() {
    return this.blue;
  },

  get inverse() {
    return this.white;
  },

  get paper() {
    return this.white;
  },

  get text() {
    return this.grayDark;
  },

  get link() {
    return this.blue;
  },

  get muted() {
    return this.gray;
  },

  get background() {
    return this.azureLight;
  },

  get border() {
    return this.grayLight;
  },

  get header() {
    return this.steelDark;
  },

  get subHeader() {
    return this.steelLight;
  },

  get hover() {
    return lighten(0.5, this.selected);
  },

  get selected() {
    return this.green;
  },

  get focus() {
    return this.yellowLight;
  },

  get odd() {
    return this.white;
  },

  get even() {
    return this.grayUltraLight;
  },

  get error() {
    return this.salmon;
  },

  get warning() {
    return this.apricot;
  },

  get success() {
    return this.green;
  },

  get info() {
    return this.secondary;
  },

  // States
  get stateApproved() {
    return this.green;
  },

  get stateOpened() {
    return this.yellow;
  },

  get stateNew() {
    return this.blue;
  },

  get stateFailed() {
    return this.red;
  },

  get stateReleased() {
    return this.gray;
  },

  get stateCanceled() {
    return this.red;
  },

  get stateInProgress() {
    return this.yellow;
  },

  get stateExportStarted() {
    return this.grayLight;
  },

  // Category states
  get stateCategoryWaiting() {
    return this.gray;
  },

  get stateCategoryInProgress() {
    return this.yellow;
  },

  get stateCategoryApproved() {
    return this.green;
  },

  get stateCategoryAfterDeadline() {
    return this.red;
  },

  get stateCategoryRejected() {
    return this.red;
  },

  // Promotion states
  get statePromotionConcept() {
    return this.blue;
  },

  get statePromotionCanceled() {
    return this.red;
  },

  get statePromotionApproved() {
    return this.green;
  },

  get statePromotionFinalized() {
    return this.green;
  },

  get statePromotionActive() {
    return this.green;
  },

  get statePromotionOpen() {
    return this.yellow;
  },

  get statePromotionClosed() {
    return this.yellow;
  },

  get statePromotionFinished() {
    return this.gray;
  },

  get statePromotionEvaluated() {
    return this.gray;
  },

  // Window states
  get stateWindowConcept() {
    return this.blue;
  },

  get stateWindowCanceled() {
    return this.red;
  },

  get stateWindowRejectedApproval() {
    return this.red;
  },

  get stateWindowRejectedChange() {
    return this.red;
  },

  get stateWindowApproved() {
    return this.green;
  },

  get stateWindowFinalized() {
    return this.green;
  },

  get stateWindowActive() {
    return this.green;
  },

  get stateWindowPrepared() {
    return this.yellow;
  },

  get stateWindowChanged() {
    return this.yellow;
  },

  get stateWindowFinished() {
    return this.gray;
  },

  get stateWindowEvaluated() {
    return this.gray;
  },

  // DP Transfer status
  get dpTransferStatusSuccess() {
    return this.green;
  },

  get dpTransferStatusFailed() {
    return this.red;
  },

  get dpTransferStatusPending() {
    return this.yellow;
  },

  get dpTransferStatusNotStarted() {
    return this.gray;
  },

  // Dp Task state
  get dpTaskStateFinished() {
    return this.green;
  },

  get dpTaskStateProcessing() {
    return this.yellow;
  },

  // Dp context state status
  get dpContextStateStatusInitialized() {
    return this.gray;
  },

  get dpContextStateStatusRunning() {
    return this.yellow;
  },

  get dpContextStateStatusCompleted() {
    return this.green;
  },

  get exportedEntityStateRunning() {
    return this.yellow;
  },

  get exportedEntityStateProcessing() {
    return this.yellow;
  },

  get exportedEntityStateWaiting() {
    return this.gray;
  },

  get exportedEntityStateFailed() {
    return this.red;
  },

  get exportedEntityStateSucces() {
    return this.green;
  },

  get exportedEntityStateClosed() {
    return this.blue;
  },

  // Optimization learning state
  get promoOptimizationLearningStateStarted() {
    return this.blue;
  },

  get promoOptimizationLearningStateWaiting() {
    return this.gray;
  },

  get promoOptimizationLearningStateError() {
    return this.red;
  },

  get promoOptimizationLearningStateSuccess() {
    return this.green;
  },

  get promoOptimizationLearningStateInProgress() {
    return this.yellow;
  },

  get promoOptimizationLearningStateCreated() {
    return this.blue;
  },

  // =====
  get scale1() {
    return this.aquamarineDark;
  },

  get scale2() {
    return this.aquamarine;
  },

  get scale3() {
    return this.aquamarineLight;
  },

  get scale4() {
    return this.grayUltraLight;
  },

  get scale5() {
    return this.pinkLight;
  },

  get scale6() {
    return this.pink;
  },

  get scale7() {
    return this.salmon;
  },

  get group1() {
    return this.yellowLight;
  },

  get group2() {
    return this.aquamarine;
  },

  get group3() {
    return this.apricot;
  },

  get group4() {
    return this.azure;
  }

};
export var theme = {
  common: common,
  colors: colors,
  spacing: {
    spacer: 1
  },
  font: {
    family: "\n          Nunito,\n          Arial,\n          sans-serif",
    base: rem(16),
    small: rem(12),
    large: rem(20)
  },
  layout: {
    padding: '2rem'
  },
  body: {
    bg: colors.background,
    color: colors.text
  },
  header: {
    height: 60
  },
  link: {
    color: colors.link,
    hoverColor: darken(0.27, colors.link)
  },
  form: {
    width: 765,
    labelWidth: 230,
    inputWidth: 330,
    breakpoint: 620
  },
  label: {
    padding: '.5rem .5rem 0 0'
  },
  input: {
    bg: colors.white,
    color: colors.text,
    borderColor: colors.border,
    disabledBg: colors.grayUltraLight,
    height: 35,
    tinyHeight: 28,
    borderRadius: common.borderRadius,
    padding: '5px 15px',
    tinyPadding: '0 15px'
  },
  textarea: {
    padding: '10px 15px'
  },
  button: {
    height: 35,
    tinyHeight: 28,
    borderRadius: common.borderRadius
  },
  badge: {
    width: 25
  },
  tag: {
    padding: '5px 30px 5px 15px'
  },
  modal: {
    borderRadius: '7px 7px 5px 5px',
    headerBorderRadius: '5px 5px 0 0'
  },
  table: {
    tablePadding: 10,
    tableRowHeight: 42,
    tableFontSize: '85%',
    summaryPadding: 7,
    summaryRowHeight: 32,
    summaryFontSize: '100%'
  },
  state: {
    success: {
      bg: '#dff0d8',
      color: '#3c763d'
    },
    info: {
      bg: '#d9edf7',
      color: '#31708f'
    },
    warning: {
      bg: '#fcf8e3',
      color: '#8a6d3b'
    },
    danger: {
      bg: '#f2dede',
      color: '#a94442'
    }
  }
};