import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n\n  border: 1px solid ", ";\n  border-radius: ", ";\n  width: 100%;\n  margin-bottom: ", "rem;\n\n  & > div:first-of-type {\n    border-radius: ", " ", " 0 0;\n  }\n\n  & > div:last-of-type {\n    border-radius: 0 0 ", " ", ";\n  }\n\n  .status-bar {\n    display: flex;\n    min-height: 60px;\n\n    .status-bar-item {\n      display: flex;\n      align-items: center;\n      padding: 0 ", "rem;\n      min-height: 40px;\n    }\n\n    svg {\n      fill: ", ";\n    }\n  }\n\n  .status-bar-workflow {\n    background-color: ", ";\n    border-bottom: 1px solid ", ";\n  }\n\n\n  .status-bar-label {\n    text-transform: uppercase;\n    font-size: 80%;\n    color: ", ";\n  }\n\n  .status-bar-value {\n    display: block;\n    font-weight: bold;\n  }\n\n\n  .status-alerts {\n    visibility: hidden;\n    opacity: 0;\n    height: 0;\n    transition: all .5s;\n  }\n  .status-alerts--open {\n    visibility: visible;\n    height: inherit;\n    opacity: 1;\n  }\n\n  .status-alerts-hide {\n    text-align: center;\n    padding: ", "rem;\n    background-color: ", ";\n    border-radius: 0 0 ", " ", ";\n\n    a {\n      text-decoration: none;\n    }\n  }\n\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Mon Sep 10 2018
 * @author PKl
 */
import styled, { withTheme } from 'styled-components';
/**
 * Create an Status component that'll render an <Status>
 *
 * @author PKl
 */

var StatusStyled = styled.div(_templateObject(), function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.common.borderRadius;
}, function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.theme.common.borderRadius;
}, function (props) {
  return props.theme.common.borderRadius;
}, function (props) {
  return props.theme.common.borderRadius;
}, function (props) {
  return props.theme.common.borderRadius;
}, function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.theme.colors.text;
}, function (props) {
  return props.theme.colors.paper;
}, function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.colors.muted;
}, function (props) {
  return props.theme.spacing.spacer / 2;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return props.theme.common.borderRadius;
}, function (props) {
  return props.theme.common.borderRadius;
});
export default withTheme(StatusStyled);