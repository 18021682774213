/**
 * @file Created on Tue Mar 15 2020
 * @author MHl
 */

import * as React from 'react';
import {
  Page,
  Form,
  BoxForm,
  BoxFormContent,
  Field,
  Button,
  FormGroup,
  Label,
  FileUploaderValidated,
  translate,
  ButtonColor,
  Modal,
  ProgressBar,
  getPath,
  FormGroupInline,
  BoxDefault,
  Icon,
  IconType,
} from '@logio/common-fe';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {Observer, observer} from 'mobx-react';
import {TerminateRegularSiteFormPageStore} from '../../../stores/pages/Terminations/RegularSite/TerminateRegularSiteFormPageStore';
import {ValidationError} from '@logio/common-be-fe';
import {Link, RouteComponentProps} from 'react-router-dom';
import {withRouter} from 'react-router';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {IRouteParams} from '../../../stores/pages/Terminations/RegularSite/TerminateRegularSiteDetailPageStore';
//@ts-ignore
import TemplateTable from './Template - End regular-site prices.csv';
import {Col, Row} from 'react-flexbox-grid';
/**
 * End regular site upload page
 */
//

@observer
class TerminateRegularSiteFormPage extends React.Component<RouteComponentProps<IRouteParams>> {
  store = new TerminateRegularSiteFormPageStore(this.props.history);

  render() {
    return (
      <Page titleKey={PageNamesEnum.TerminateRegularSite}>
        <Form descriptions={this.store.importTerminationDescriptions} onSubmit={this.store.importTerminations}>
          <BoxForm>
            <BoxFormContent>
              <Row>
                <Col md={9}>
                  <Field name="name" />
                  <Field
                    name="dataFile"
                    component={({input, meta}) => (
                      <FormGroup>
                        <Label>{translate('file-uploader-label')}</Label>
                        <FileUploaderValidated
                          id="dataFile"
                          restrictions={{
                            maxFileSize: 100000000,
                            maxNumberOfFiles: 1,
                            //allowedFileTypes: ['text/csv', 'application/vnd.ms-excel'],
                          }}
                          events={[
                            {
                              key: 'file-added',
                              action: (file) => input.onChange(file),
                            },
                          ]}
                          validationError={meta.touched ? meta.error : null}
                        />
                      </FormGroup>
                    )}
                    validate={(value) => {
                      if (!value) {
                        return new ValidationError('err-required');
                      }
                      return null;
                    }}
                  />
                  <FormGroupInline>
                    <Button type="submit" buttonColor={ButtonColor.Secondary} disabled={this.store.submitting}>
                      {translate('action_regular-site_Termination_import-button')}
                    </Button>
                    <Link to={getPath(PagePathsEnum.TerminateRegularSite)}>
                      <Button type="button" buttonColor={ButtonColor.Light} disabled={this.store.submitting}>
                        {translate('Cancel')}
                      </Button>
                    </Link>
                  </FormGroupInline>
                </Col>
                <Col md={3}>
                  <BoxDefault>
                    <strong> {translate('Download_template_title')}</strong>
                    <a href={TemplateTable} style={{textDecoration: 'none', display: 'block'}}>
                      <Button buttonColor={ButtonColor.Transparent} iconLeft type="button">
                        <Icon iconType={IconType.document} /> {translate('DownloadCSVRegularSiteTerminationTemplate')}
                      </Button>
                    </a>
                  </BoxDefault>
                </Col>
              </Row>
            </BoxFormContent>
          </BoxForm>
        </Form>
        <Modal title={translate('new_regular-site_termination_upload-modal')} hidden={this.store.isModalHidden}>
          <ProgressBar
            percentage={this.store.pollingHelper.progress || 0}
            label={'new_release-price_upload-in_progress'}
          />
        </Modal>
      </Page>
    );
  }
}

export default withRouter(TerminateRegularSiteFormPage);
