import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n\n  .tab-link {\n    display: inline-block;\n    font-size: 110%;\n    padding: 5px 20px;\n    cursor: pointer;\n    text-decoration: none;\n    color: ", ";\n\n    &.active {\n      border-bottom: 4px solid ", ";\n      color: ", ";\n      cursor: default;\n    }\n  }\n\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Tue Sep 25 2018
 * @author PKl
 */
import styled, { withTheme } from 'styled-components';
/**
 *  Styled Tabs component
 */

var StyledTabs = styled.div(_templateObject(), function (props) {
  return props.theme.colors.link;
}, function (props) {
  return props.theme.colors.header;
}, function (props) {
  return props.theme.colors.text;
});
export default withTheme(StyledTabs);