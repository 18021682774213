"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var AdtFlagConstructorParser_1 = require("../../core/model/data-dictionary/parsers/AdtFlagConstructorParser");

var StructPropBuilder_1 = require("../../core/model/data-dictionary/StructPropBuilder");

var WindowItemWarningFlag_1 = require("../../window/model/WindowItemWarningFlag");

var WindowItemValidatorHints_1 = require("./WindowItemValidatorHints");
/**
 * Holds information about desired window item validation
 * Those information are used in {@link WindowUpdateRequest} when working with window items
 */


var WindowItemValidationRequest =
/**
 * @param hint Hint used in validation
 * @param instantErrors list of flags ( window item warning tag ) that are automatically evaluated as instant errors
 */
function WindowItemValidationRequest(hint, instantErrors) {
  _classCallCheck(this, WindowItemValidationRequest);

  this.hint = hint;
  this.instantErrors = instantErrors;
};

exports.WindowItemValidationRequest = WindowItemValidationRequest;

WindowItemValidationRequest.schema = function () {
  var builder = new StructPropBuilder_1.StructPropBuilder(WindowItemValidationRequest.name);
  return {
    hint: builder.senum('hint', WindowItemValidatorHints_1.WindowItemValidatorHint, ''),
    instantErrors: builder.listOf(builder.custom('instantErrors', new AdtFlagConstructorParser_1.AdtFlagConstructorParser(WindowItemWarningFlag_1.WindowItemWarningFlag)))
  };
}();