/**
 * @file Created on Thu Sep 13 2018
 * @author LZe
 */

import {AgGrid, Page, getPath} from '@logio/common-fe';
import {Observer, observer} from 'mobx-react';
import * as React from 'react';
import {match} from 'react-router';
import {IRouteParams} from 'routes-app';
import DataUploadResult from '../../../components/DataUploadResult';
import {DataUploadDetailPageStore} from '../../../stores/pages/DataManagement/DataUpload/DataUploadDetailPageStore';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';

interface Props {
  match: match<IRouteParams>;
}
/**
 * Data upload modalData page
 */
@observer
export default class DataUploadDetailPage extends React.Component<Props> {
  store = new DataUploadDetailPageStore(this.props.match.params.id);

  componentWillMount() {
    this.store.load();
  }

  render() {
    const dataUpload = this.store.dataUploadStore.list.get(this.store.id);
    return (
      <Page
        titleKey={PageNamesEnum.DataUploadDetail}
        loadingState={this.store.loadingState}
        backButtonPath={getPath(PagePathsEnum.DataUpload)}
      >
        <Observer>
          {() => (
            <>
              <div className="mb2">
                <AgGrid
                  rowData={this.store.rowData}
                  columnDefs={this.store.columnDefs}
                  sizeColumnsToFit
                  domLayout="autoHeight"
                  gridId="PricingDataUploadDetailPage"
                />
              </div>
              <DataUploadResult uploadedData={dataUpload} />
            </>
          )}
        </Observer>
      </Page>
    );
  }
}
