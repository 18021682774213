"use strict";
/*
 * Created on 01 24 2019
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author Jaroslav Sevcik
 * @author Tomas Leity
 */

var NotificationType;

(function (NotificationType) {
  /**
   * PRICING
   */
  NotificationType["releaseOpened"] = "RELEASE_OPENED";
  NotificationType["automaticCleaningFinished"] = "AUTOMATIC_CLEANING_FINISHED";
  NotificationType["uploadSuccessful"] = "UPLOAD_SUCCESSFUL";
  NotificationType["uploadFailed"] = "UPLOAD_FAILED";
  NotificationType["userDelegationExpired"] = "USER_DELEGATION_EXPIRED";
  NotificationType["releaseUploadSuccessful"] = "RELEASE_UPLOAD_SUCCESSFUL";
  NotificationType["releaseUploadFailed"] = "RELEASE_UPLOAD_FAILED";
  /**
   * PROMO
   */

  NotificationType["PromoForecastSimCreated"] = "PROMO_FORECAST_SIM_CREATED";
  NotificationType["PromoForecastSimApplied"] = "PROMO_FORECAST_SIM_APPLIED";
  NotificationType["PromoForecastSimDummyCreated"] = "PROMO_FORECAST_SIM_DUMMY_CREATED";
  NotificationType["PromoProductThresholdPromoVolume"] = "PROMO_PRODUCT_THRESHOLD_PROMO_VOLUME";
  NotificationType["PromoProductThresholdSystemVolume"] = "PROMO_PRODUCT_THRESHOLD_SYSTEM_VOLUME";
  NotificationType["PromoWindowWFStateChanged"] = "PROMO_WINDOW_WORKFLOW_STATE_CHANGED";
  NotificationType["PromoWindowWFActionDelete"] = "PROMO_WINDOW_WORKFLOW_ACTION_DELETED";
  NotificationType["PromoWindowWFActionRBCD"] = "PROMO_WINDOW_WORKFLOW_ACTION_REJECTED_BY_CD";
  NotificationType["PromoWindowWFActionRBPM"] = "PROMO_WINDOW_WORKFLOW_ACTION_REJECTED_BY_PM";
  NotificationType["PromoWindowReminderForFC"] = "PROMO_WINDOW_REMINDER_FOR_FC";
  NotificationType["PromoWindowChangedRepleThreshold"] = "PROMO_WINDOW_CHANGED_REPLE_THRESHOLD";
  NotificationType["PromoThemeRemoved"] = "PROMO_THEME_REMOVED";
  NotificationType["PromoSitesRemoved"] = "PROMO_SITES_REMOVED";
  NotificationType["PromoMasterAreaRemoved"] = "PROMO_MASTER_AREA_REMOVED";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));