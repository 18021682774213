/**
 * @file Created on Sat Feb 02 2019
 * @author SKu
 */

import { BoxDefault, ButtonColor, ButtonLoading, Icon, IconType, translate } from '@logio/common-fe';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { withRouter } from 'react-router';
import { PriceIndexReportPageStore } from 'stores/pages/Reports/RelatedReports/PriceIndexReportPageStore';
import { PageNamesEnum } from '../../../shared/localization/PageNamesEnum';
import { AbstractRelatedReportsPage } from './AbstractRelatedReportsPage';

/** Price index report */
@observer
class PriceIndexReportPage extends AbstractRelatedReportsPage<never> {
    public pageName = PageNamesEnum.PriceIndexReport;
    public store = new PriceIndexReportPageStore();

    /** @inheritDoc */
    protected relatedReportsFilterVisible = false;

    /** @inheritDoc */
    @computed
    public get customFormProps() {
        return {};
    }

    /** @inheritDoc */
    public get renderDownloadReport(): JSX.Element {
        return (
            <BoxDefault>
                {this.store.downloadStore.renderModal()}
                <ButtonLoading
                    isLoading={this.store.isReportDownloading}
                    buttonColor={ButtonColor.Transparent}
                    iconLeft
                    onClick={this.store.downloadReportFile}
                >
                    <Icon iconType={IconType.document} />
                    {translate('PriceIndexReport_download_buttonLabel')}
                </ButtonLoading>
            </BoxDefault>
        );
    }

    /** @inheritDoc */
    @computed
    public get renderContent() {
        return (
            <iframe
                width="1140"
                height="541.25"
                src="https://app.powerbi.com/reportEmbed?reportId=179a5106-d22a-46d6-8e18-298a118eae5a&autoAuth=true&ctid=e9bd13bf-b839-4ba1-b367-071cf0480f8c&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtZXVyb3BlLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9"
                frameBorder="0"
                allowFullScreen={true}
            />
        );
    }
}

export default withRouter(PriceIndexReportPage);
