import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n\n  a {\n    color: ", ";\n  }\n\n  svg {\n    fill: ", ";\n  }\n\n  & > * {\n    padding: ", "rem\n      ", "rem;\n  }\n\n  .alert-type {\n    text-transform: uppercase;\n    white-space: nowrap;\n    font-weight: bold;\n  }\n\n  .alert-content {\n    width: 100%;\n    font-size: 90%;\n  }\n\n  .alert-action {\n    white-space: nowrap;\n  }\n\n  .alert-action-close {\n    cursor: pointer;\n\n    svg {\n      margin-bottom: -2px;\n    }\n  }\n\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  color: ", ";\n\n  a {\n    color: ", ";\n  }\n  svg {\n    fill: ", ";\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  color: ", ";\n\n  a {\n    color: ", ";\n  }\n  svg {\n    fill: ", ";\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Thu Aug 09 2018
 * @author PKl
 */
import { AlertType } from '../AlertType';
import styled, { css, withTheme } from 'styled-components';
var AlertError = css(_templateObject(), function (props) {
  return props.theme.colors.error;
});
var AlertWarning = css(_templateObject2(), function (props) {
  return props.theme.colors.warning;
});
var AlertSuccess = css(_templateObject3(), function (props) {
  return props.theme.colors.success;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return props.theme.colors.inverse;
});
var AlertInfo = css(_templateObject4(), function (props) {
  return props.theme.colors.info;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return props.theme.colors.inverse;
});
/**
 * Create an Alert component that'll render an <Alert>
 */

var AlertStyled = styled.div(_templateObject5(), function (props) {
  return props.theme.colors.text;
}, function (props) {
  return props.theme.colors.text;
}, function (props) {
  return props.theme.spacing.spacer / 2;
}, function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.type === AlertType.Error ? AlertError : null;
}, function (props) {
  return props.type === AlertType.Warning ? AlertWarning : null;
}, function (props) {
  return props.type === AlertType.Success ? AlertSuccess : null;
}, function (props) {
  return props.type === AlertType.Info ? AlertInfo : null;
});
export default withTheme(AlertStyled);