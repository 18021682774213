/**
 * @file Created on Thu Aug 09 2018
 * @author VBr
 */
import { Utils } from '@logio/common-be-fe';
import * as React from 'react';

var CheckBoxRenderer = function CheckBoxRenderer(props) {
  var colDef = props.column.getColDef();

  var toggleValue = function toggleValue() {
    // This toggle value on enter or click
    if (Utils.isValueMissing(colDef.editable) || props.column.isCellEditable(props.node)) {
      if (!props.node.aggData && !props.node.allChildrenCount) {
        if (colDef.action) {
          props.node.setDataValue(props.column.getId(), !props.value);
          colDef.action(props.node.id, props.column.getId(), !props.value).catch(function () {
            props.node.setDataValue(props.column.getId(), props.value);
            props.context.store.setSelectedRows(props.api.getSelectedNodes());
          });
        }
      }
    }
  }; // TODO: possibly some bulk operation over group?


  if (props.node.aggData || props.node.allChildrenCount) {
    return React.createElement("span", null);
  } else {
    return React.createElement("span", {
      onClick: toggleValue,
      className: "ag-icon ag-icon-checkbox-".concat(props.value === true ? 'checked' : props.value === false ? 'unchecked' : '')
    });
  }
};

export default CheckBoxRenderer;