/**
 * @file Created on Fri Feb 01 2019
 * @author SKu
 */

import {
  CompetitorReferentialPrice,
  DataDesc,
  StructPropBuilder,
  Utils,
  CompetitorPriceChangeReportDTO,
  PriceZone,
  Competitor,
} from '@logio/common-be-fe';
import {
  ChartGenerator,
  ChartValue,
  ColumnDefinition,
  ColumnGenerator,
  CompetitorStore,
  LoadingState,
  rootStore,
  StoreName,
  StringMapping,
  translate,
} from '@logio/common-fe';
import {computed, observable, runInAction} from 'mobx';
import moment, {Moment} from 'moment';
import {RelatedReportsFormFilter} from '../../../../pages/Reports/RelatedReports/RelatedReportsFilter';
import {AbstractRelatedReportsPageStore} from './AbstractRelatedReportsPageStore';

export interface IFilter extends RelatedReportsFormFilter {
  dateFrom: Moment;
  competitorIds?: string[];
  priceZoneId?: string;
}

export class CompetitorsPriceChangeReportPageStore extends AbstractRelatedReportsPageStore<IFilter> {
  /** Stores/Layers */
  private competitorsStore = rootStore.getStore(StoreName.Competitor) as CompetitorStore;

  /**
   * Data converted for AG-grid
   * {@link setPageData} for more information
   */
  @observable
  public gridData: Array<StringMapping<any>> = [];
  @observable
  public chartData: CompetitorPriceChangeReportDTO[] = [];

  private builder = new StructPropBuilder('CompetitorPriceChangeReport');
  /** Dynamic grid definition */
  @observable
  private description: DataDesc = {};
  /** dynamic Ag-grid generator */
  @observable
  private columnGenerator = new ColumnGenerator<StringMapping<any>>(this.description);
  /** Chart generator */
  private chartGenerator = new ChartGenerator<CompetitorReferentialPrice>(CompetitorReferentialPrice.schema);

  /**
   * Sets initial Values for the page.
   */
  @computed
  public get commonInitialValues() {
    // Default priceZoneId value
    const allZones: Array<PriceZone> = Array.from(this.priceZoneStore.list.values());
    const nationalZone = allZones.find((zone: PriceZone) => zone.isNational);
    const priceZoneId: string = nationalZone && nationalZone.id;

    // Default competitorIds value
    const allCompetitors: Array<Competitor> = Array.from(this.competitorsStore.list.values());
    const competitorIds: string[] = allCompetitors.map((competitor) => competitor.id);

    // Default dateFrom value
    const dateFrom = moment().subtract(6, 'w');

    return {priceZoneId, competitorIds, dateFrom};
  }

  /** @inheritDoc */
  public onFilter = async (filterValues: IFilter) => {
    this.setLoadingState(LoadingState.Pending);
    const prevFilter = this.filterValues;
    runInAction(() => (this.filterValues = filterValues));
    if (filterValues.categoryId !== prevFilter.categoryId) {
      // TODO: Clear ALL values
      await this.loadProductsInfo();
    }
    if (filterValues.productId !== prevFilter.productId || Utils.isValueMissing(this.productInfo)) {
      this.setProductInfo();
    }
    await this.updatePageData();
    this.setLoadingState(LoadingState.Success);
  };

  /** @inheritDoc */
  protected updatePageData = async (): Promise<void> => {
    try {
      runInAction(() => {
        this.gridData = [];
        this.description = {};
      });
      this.setPageData(await this.reportLayer.getCompetitorPriceChangeReportDTOs(this.filterValues));
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  private setPageData = (pageData: CompetitorPriceChangeReportDTO[]) => {
    const description = {competitorName: this.builder.str('competitorName')};
    const gridData = pageData.map(({competitorName, prices}) => {
      let convertedData: any = {};
      prices.map((price) => {
        const weekKey = `week_${price.date.weekYear()}_${price.date.week()}`;
        if (Utils.isValueMissing(description[weekKey])) {
          description[weekKey] = this.builder.bigNum(weekKey);
        }
        convertedData = {...convertedData, [weekKey]: price.value};
      });
      return {...convertedData, competitorName};
    });
    runInAction(() => {
      this.description = description;
      this.gridData = gridData;
      this.chartData = pageData;
      this.columnGenerator = new ColumnGenerator<StringMapping<any>>(description);
    });
  };

  /** Generates dynamic column definition(with week as columns) */
  @computed
  public get columnDefs(): ColumnDefinition[] {
    const colDefs: ColumnDefinition[] = [];
    if (!Utils.isEmptyObject(this.description)) {
      Object.keys(this.description)
        .sort(this.sortByYearWeek)
        .map((key, i) => {
          let colDef: ColumnDefinition = {field: key};
          if (key !== 'competitorName') {
            // Params for Column header
            const keySplit = key.split('_');
            const yearNumber = keySplit[1];
            const weekNumber = keySplit[2];

            colDef = {
              ...colDef,
              headerName: translate('Week_number', weekNumber, yearNumber),
            };
          }
          colDefs.push(colDef);
        });
    }
    return this.columnGenerator.getColumnDefinitions(colDefs);
  }

  /** Return generated data for the ag-grid */
  @computed
  public get gridRowData(): Array<StringMapping<any>> {
    const rowData = [];
    this.gridData.forEach((item) => rowData.push({...this.columnGenerator.getColumnData(item)}));
    return rowData;
  }

  /** Chart data */
  @computed
  public get chartRowData(): Map<string, ChartValue[]> {
    const data = new Map<string, ChartValue[]>();
    this.chartData.forEach((dto) => {
      data.set(dto.competitorName, this.chartGenerator.getSeries(dto.prices.toArray(), 'date', 'value'));
    });
    return data;
  }

  /**
   * Returns new Date from week and year
   * @param w
   * @param y
   */
  public getDateOfISOWeek = (w: number, y: number): Date => {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    const isoWeekStart = simple;
    if (dow <= 4)
      isoWeekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
      isoWeekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return isoWeekStart;
  };

  /**
   * Sort function for column definitions, sorts by year and week
   * @param a
   * @param b
   */
  public sortByYearWeek = (a, b): number => {
    if (a === 'competitorName' || b === 'competitorName') return 0;
    a = a.split('_');
    b = b.split('_');
    let aDate = this.getDateOfISOWeek(Number.parseInt(a[2]), Number.parseInt(a[1]));
    let bDate = this.getDateOfISOWeek(Number.parseInt(b[2]), Number.parseInt(b[1]));
    return aDate.getTime() - bDate.getTime();
  };

  /** @inheritDoc */
  public load = async (initialValues: RelatedReportsFormFilter) => {
    try {
      await Promise.all([this.competitorsStore.getAll(), this.priceZoneStore.getAll(), this.priceZoneStore.getAll()]);
      runInAction(() => (this.filterValues = {...this.commonInitialValues, ...initialValues}));
      if (this.filterValues.categoryId) {
        await this.loadProductsInfo();
      }
      if (!Utils.isValueMissing(this.filterValues.productId)) {
        await this.updatePageData();
        this.setProductInfo();
      }
      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      this.setLoadingState(LoadingState.Error);
      // this.messages.setError(error);
    }
  };
}
