export var ExcelStyles = [{
  id: 'header',
  interior: {
    color: '#3f4d6c',
    pattern: 'Solid',
    height: '60px'
  },
  font: {
    color: '#ffffff'
  },
  alignment: {
    vertical: 'center'
  }
}, {
  id: 'bg-error',
  interior: {
    color: '#fab5b1',
    pattern: 'Solid'
  }
}, {
  id: 'bg-warning',
  interior: {
    color: '#fdd7ad',
    pattern: 'Solid'
  }
}, {
  id: 'bg-scale1',
  interior: {
    color: '#c3f4af',
    pattern: 'Solid'
  }
}, {
  id: 'bg-c-scale2',
  interior: {
    color: '#d4f4fd',
    pattern: 'Solid'
  }
}, {
  id: 'bg-scale6',
  interior: {
    color: '#fbd7d6',
    pattern: 'Solid'
  }
}, {
  id: 'bg-scale7',
  interior: {
    color: '#fab5b1',
    pattern: 'Solid'
  }
}, {
  id: 'c-error',
  font: {
    color: '#ff564c'
  }
}, {
  id: 'c-success',
  font: {
    color: '#558b2f'
  }
}, {
  id: 'decimal',
  dataType: 'number',
  numberFormat: {
    format: '#,##0'
  }
}, {
  id: 'numberOneDecimalPlace',
  dataType: 'number',
  numberFormat: {
    format: '#,##0.0'
  }
}, {
  id: 'numberTwoDecimalPlaces',
  dataType: 'number',
  numberFormat: {
    format: '#,##0.00'
  }
}, {
  id: "percentage",
  numberFormat: {
    format: '0.0%'
  }
}, {
  id: "datetime",
  // dataType: 'dateTime',
  numberFormat: {
    format: 'yyyy-mm-dd hh:mm:ss'
  }
}, {
  id: "date",
  // dataType: 'dateTime',
  numberFormat: {
    format: 'yyyy-mm-dd'
  }
}, {
  id: "dateCustom",
  // dataType: 'dateTime',
  numberFormat: {
    format: 'dd.mm.yyyy'
  }
}, {
  id: "datetimeCustom",
  // dataType: 'dateTime',
  numberFormat: {
    format: 'dd.mm.yyyy hh:mm:ss'
  }
}, {
  id: "excel-bg-muted",
  interior: {
    color: "#a1a3a5",
    pattern: 'Solid'
  },
  borders: {
    borderBottom: {
      color: "#FFFFFF",
      lineStyle: 'Continuous',
      weight: 1
    },
    borderLeft: {
      color: "#FFFFFF",
      lineStyle: 'Continuous',
      weight: 1
    },
    borderRight: {
      color: "#FFFFFF",
      lineStyle: 'Continuous',
      weight: 1
    },
    borderTop: {
      color: "#FFFFFF",
      lineStyle: 'Continuous',
      weight: 1
    }
  }
}];