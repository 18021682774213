import { Utils } from "@logio/common-be-fe";
/**
 * @file Created on September 2019
 * @author Atreo Digital s.r.o.
 * @description General custom comparator usable as a base for all other custom comparators
 */

var generalComparator = function generalComparator(valA, valB) {
  // both items are null -> both are same
  if (Utils.isValueMissing(valA) && Utils.isValueMissing(valB)) {
    return 0;
  } // first item is null -> second item is greater


  if (Utils.isValueMissing(valA)) {
    return -1;
  } // second item is null -> first item is greater


  if (Utils.isValueMissing(valB)) {
    return 1;
  } // both items are strings (impossible to compare them by minus operator)


  if (typeof valA === "string" && typeof valB === "string") {
    return valA > valB ? 1 : valB > valA ? -1 : 0;
  } // both items are numbers (possible to compare them by minus operator)


  if (typeof valA === "number" && typeof valB === "number") {
    return valA - valB;
  } // at least one item is not string/number/null -> impossible to compare which one is greater


  return 0;
};

export default generalComparator;