/**
 * @file Created on Thu Sep 06 2018
 * @author SKu
 */

import {PricingPermissions, Tier, Utils} from '@logio/common-be-fe';
import {
  ActionsGenerator,
  ColumnDefinition,
  ColumnGenerator,
  IconType,
  KeycloakStore,
  LoadingState,
  PageStore,
  rootStore,
  StoreName,
  StringMapping,
  TierStore,
  translate,
} from '@logio/common-fe';
import {List} from 'immutable';
import {action, computed, observable, runInAction} from 'mobx';

export enum TiersModalEnum {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export class TiersPageStore extends PageStore {
  /** Get dependant stores */
  tiersStore = rootStore.getStore(StoreName.Tier) as TierStore;
  keycloakStore = rootStore.getStore(StoreName.Keycloak) as KeycloakStore;

  /** Data generators */
  actionsGenerator = new ActionsGenerator();
  tierGenerator = new ColumnGenerator<Tier>(Tier.tierSchema);

  /** Variable used for opening and closing modals */
  @observable
  modalHidden = {
    [TiersModalEnum.CREATE]: true,
    [TiersModalEnum.EDIT]: true,
    [TiersModalEnum.DELETE]: true,
  };

  /**
   * Variable used to set initial values to Form
   */
  @observable
  initialValues: Tier = new Tier(Utils.VOID_ID, '', 0, '', false, List());

  /**
   * Returns generated column definitions for the ag-grid on the main page
   */
  @computed
  get columnDefs(): ColumnDefinition[] {
    const colDefs: ColumnDefinition[] = [];

    const tierDefs = [
      {
        field: 'title',
        width: 300,
        suppressMenu: true,
      },
      {
        field: 'description',
        width: 800,
        suppressMenu: true,
      },
      {
        field: 'level',
        width: 100,
        suppressMenu: true,
      },
    ];

    if (this.keycloakStore.userHasPermissions([PricingPermissions.TIERS_SETTINGS_EDIT])) {
      colDefs.push(this.actionsGenerator.getColumnDefinition({headerName: ''}));
    }

    colDefs.push(...this.tierGenerator.getColumnDefinitions(tierDefs));

    return colDefs;
  }

  /**
   * Return generated data for the ag-grid on the main page
   */
  @computed
  get rowData(): Array<StringMapping<any>> {
    const rowData = [];
    this.tiersStore.list.forEach((tier: Tier) => {
      /** Generating edit icon to actions */
      const editTier = {
        name: 'edit',
        icon: IconType.edit,
        props: {onClick: this.openModal(TiersModalEnum.EDIT, tier)},
      };

      /** Generating delete icon to actions */
      const deleteTier = {
        name: 'delete',
        icon: IconType.delete,
        props: {onClick: this.openModal(TiersModalEnum.DELETE, tier)},
      };

      /** Generating delete icon to actions */
      const defaultTier = {
        name: 'default',
        icon: IconType.locked,
        tooltip: 'DEFAULT\n(could not be removed)',
      };

      rowData.push({
        ...this.actionsGenerator.getColumnData(editTier, tier.isDefault ? defaultTier : deleteTier),
        ...this.tierGenerator.getColumnData(tier),
      });
    });
    return rowData;
  }

  /**
   * Returns generated column definitions for the ag-grid from Modal
   */
  @action
  columnDefsModal = (): ColumnDefinition[] => {
    const modalColDefs = [
      {
        field: 'title',
        /** Function allows to drag only row without id */
        rowDrag: (params) => {
          return params.data.Tier_id === this.initialValues.id;
        },
        suppressMenu: true,
      },
      {field: 'description', suppressMenu: true},
    ];

    return [...this.tierGenerator.getColumnDefinitions(modalColDefs)];
  };

  /**
   * Return generated data for the ag-grid from createModal
   */
  @action
  rowDataModal = (createMode: boolean): Array<StringMapping<any>> => {
    const rowData = [];
    /** Appending all tiers to the ag-grid */
    this.tiersStore.list.forEach((tier: Tier) => {
      /** Generator return data definition from related data description */
      rowData.push({
        ...this.tierGenerator.getColumnData(tier),
      });
    });

    if (createMode) {
      /** Appending new draggable tier with empty values */
      rowData.push({
        ...this.tierGenerator.getColumnData(this.initialValues),
      });
    }

    return rowData;
  };

  /**
   * Fetches all data for this page
   */
  @action.bound
  load(): void {
    Promise.all([this.tiersStore.getAll()])
      .then(() => this.setLoadingState(LoadingState.Success))
      .catch((error) => {
        this.setLoadingState(LoadingState.Error);
        // this.messages.setError(error);
      });
  }

  /**
   * Negate modalHiddenProp
   * @param title - name of the modalHidden prop that should be negated
   */
  toggleModal = (modalType: TiersModalEnum) => {
    runInAction(() => {
      this.modalHidden[modalType] = !this.modalHidden[modalType];
    });
  };

  /**
   * HOF for togglemodal
   * used in Tiers page when u get event besides parameter
   */
  getModalToggleEvent = (modalType: TiersModalEnum) => () => this.toggleModal(modalType);

  /**
   * Set values of tier to the Form
   * @param tier - picked tier
   */
  @action.bound
  setInitialValues(tier: Tier) {
    runInAction(() => {
      this.initialValues = tier;
    });
  }

  /**
   * Open modal for tier creating/editing/deleting
   */
  openModal = (modalType: TiersModalEnum, tier?: Tier) => async () => {
    tier
      ? await this.setInitialValues(tier)
      : await this.setInitialValues(new Tier(Utils.VOID_ID, 'New Tier', this.rowData.length, ' ', false, List()));
    this.toggleModal(modalType);
  };

  /**
   * Creates new Tier with TierStore
   * @param tier - data ofnew Tier
   */
  @action.bound
  onCreateSubmit = async (tier: Tier): Promise<void> => {
    try {
      await this.tiersStore.create(tier);
      this.toggleModal(TiersModalEnum.CREATE);
      this.messages.setSuccess(translate('Tier_created', tier.title));
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  /**
   * Updates the Tier with TierStore
   * @param tier - consist data of edited tier
   */
  @action.bound
  onEditSubmit = async (tier: Tier): Promise<void> => {
    try {
      await this.tiersStore.update(tier);
      this.toggleModal(TiersModalEnum.EDIT);
      this.messages.setSuccess(translate('Tier_edited', tier.title));
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  /**
   * Delete the Tier with TierStore
   */
  @action.bound
  onDeleteSubmit = async (): Promise<void> => {
    try {
      await this.tiersStore.delete(this.initialValues.id);
      this.toggleModal(TiersModalEnum.DELETE);
      this.messages.setSuccess(translate('Tier_removed', this.initialValues.title));
    } catch (error) {
      // this.messages.setError(error);
    }
  };
}
