import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  & > .radio-button,\n  & > .checkbox-button {\n    margin-right: 0;\n  }\n  & > .radio-button input[type='radio'] + label,\n  & > .checkbox-button input[type='checkbox'] + label,\n  & > button {\n    border-radius: 0;\n    margin-right: -1px;\n  }\n\n  & > .radio-button:first-of-type input[type='radio'] + label,\n  & > .checkbox-button:first-of-type input[type='checkbox'] + label,\n  & > button:first-of-type {\n    border-top-left-radius: ", ";\n    border-bottom-left-radius: ", ";\n  }\n\n  & > .radio-button:last-of-type input[type='radio'] + label,\n  & > .checkbox-button:last-of-type input[type='checkbox'] + label,\n  & > button:last-of-type {\n    border-top-right-radius: ", ";\n    border-bottom-right-radius: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Mon Aug 20 2018
 * @author PKl
 */
import styled, { withTheme } from 'styled-components';
var ButtonGroupStyled = styled.div(_templateObject(), function (props) {
  return props.theme.common.borderRadius;
}, function (props) {
  return props.theme.common.borderRadius;
}, function (props) {
  return props.theme.common.borderRadius;
}, function (props) {
  return props.theme.common.borderRadius;
});
export default withTheme(ButtonGroupStyled);