"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Determines option for select box in segmentation model.
 *
 * @author ZSr
 */

var SegmentationItemEnum;

(function (SegmentationItemEnum) {
  SegmentationItemEnum["GOOD"] = "GOOD";
  SegmentationItemEnum["CONDITIONAL"] = "CONDITIONAL";
  SegmentationItemEnum["AVOID"] = "AVOID";
})(SegmentationItemEnum = exports.SegmentationItemEnum || (exports.SegmentationItemEnum = {}));