import {css} from 'styled-components';
import {lighten} from 'polished';
// import theme from './theme';

export default css`
// LOG-6013
.columns-panel-overflow .ag-tool-panel-wrapper {
    height: 245px;
    overflow: visible;
    z-index: 11;
    background-color: #f6f7f7;
    border-left: 1px solid #bdc3c7;
    margin-right: 20px;
}

.columns-panel-overflow .ag-side-buttons {
    border-left: 1px solid #BDC3C7;
    position: absolute !important;
    top: 0;
    bottom: 0;
}

.columns-panel-overflow .ag-column-panel {
    min-height: 245px;
}

/*
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  *,
  *:focus {
    outline: none;
  }
  body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #f6fafd;
  }

  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }

  .loading {
    opacity: 0.6;
    pointer-events: none;
  }
*/


  .ControlBar {
    padding: 2px 0 4px 0;
    background-color: #dedede;
  }
`;
