import {
  AbstractRelease,
  ReleaseType,
  CommonReleaseOverview,
} from '@logio/common-be-fe';
import {
  StringMapping,
  CONSTANTS,
  IReleaseImpactFilter,
} from '@logio/common-fe';
import { action, computed, observable } from 'mobx';
import { RegularReleasePageStore } from './RegularReleasePageStore';
import moment from 'moment';
import { stringify } from 'querystring';

/**
 * Store for Release price impact report page.
 * 
 * @author Jan Prokop
 */
export class RegularReleaseImpactPageStore extends RegularReleasePageStore {
  protected impactMode = true;

  /** Impact filter values used for release detail link. */
  public defaultImpactFilterValues: IReleaseImpactFilter = {
    dateFrom: moment()
      .subtract(7, 'd')
      .format(CONSTANTS.FORMAT.DATE),
    dateTo: moment().format(CONSTANTS.FORMAT.DATE),
  };

  /** Current impact filter values. */
  @observable
  public impactFilter: IReleaseImpactFilter = {
    dateFrom: moment()
      .subtract(7, 'd')
      .format(CONSTANTS.FORMAT.DATE),
    dateTo: moment().format(CONSTANTS.FORMAT.DATE),
  };

  /**
   * Called when impact filter form changes.
   * @param values
   */
  @action.bound
  public async onImpactFilterSubmit(values: IReleaseImpactFilter) {
    this.impactFilter = values;
    this.gridApi.showLoadingOverlay();

    try {
      await this.releaseStore.getPriceImpactReport({ ...this.impactFilter, releaseType: ReleaseType.Regular });
    } catch (error) {
      // something ?
    } finally {
      this.gridApi.hideOverlay();
    }
  };

  /** @inheritdoc */
  @computed
  public get rowData(): Array<StringMapping<any>> {
    const rowData = [];

    this.releaseStore.list.forEach((release: AbstractRelease) => {
      const overview = CommonReleaseOverview.createFromRelease(release);
      const overviewData = this.getOverviewData(overview);

      rowData.push(release.realImpact ? {
        ...overviewData,
        ...this.releaseColumnGenerator.getColumnData(release.realImpact.getValue()),
      } : overviewData);
    });

    return rowData;
  }

  /**
   * @inheritdoc
   */
  protected async loadData() {
    await this.releaseStore.getPriceImpactReport({ ...this.impactFilter, releaseType: ReleaseType.Regular });
  }

  /**
   * @inheritdoc
   */
  protected getReleaseDetailPath(overview: CommonReleaseOverview) {
    const path = super.getReleaseDetailPath(overview);
    const dateFrom = overview.priceValidityStartDate ? overview.priceValidityStartDate.clone() : null; // LOG-6123

    return `${path}?${stringify({
      dateFrom: dateFrom ? dateFrom.format(CONSTANTS.FORMAT.DATE) : this.defaultImpactFilterValues.dateFrom,
      dateTo: dateFrom ? dateFrom.add(7, 'd').format(CONSTANTS.FORMAT.DATE) : this.defaultImpactFilterValues.dateTo,
    })}`;
  };
}
