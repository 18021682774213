/**
 * @file Created on Thu Sep 06 2018
 * @author SKu
 */

import {DataDesc, Release, Utils} from '@logio/common-be-fe';
import {ReleaseStore, rootStore, StoreName,translate} from '@logio/common-fe';
import {AbstractReleaseHeaderStore} from './AbstractReleaseHeaderStore';

export class ReleaseHeaderStore extends AbstractReleaseHeaderStore {
  public releaseStore = rootStore.getStore(StoreName.Release) as ReleaseStore;

  /** @inheritdoc */
  public get release(): Release {
    return this.releaseStore.release;
  }

  /** @inheritdoc */
  public get descriptions(): DataDesc {
    return {
      name: Release.schema.name,
      priceListTypeId: Release.schema.priceListTypeId,
      totalPriceChangeLimit: Release.schema.totalPriceChangeLimit,
      priceValidityStartDate: Release.schema.priceValidityStartDate,
      cmDeadline: Release.schema.cmDeadline,
      description: Release.schema.description,
    };
  }

  /** @inheritdoc */
  public onEditHeaderSubmit = async (release: Release) => {
    try {
      if (release.cmDeadline) {
        // PROFACT-443 deadline date includes today (this will mutate release)
        release.cmDeadline.endOf('day');
      }

      await this.releaseStore.update(release);
      if (!Utils.isValueMissing(this.onHeaderUpdated)) {
        this.onHeaderUpdated();
      }
      this.messages.setSuccess(translate('release-header-updated'));
    } catch (error) {
      // this.messages.setError(error);
    }
    this.hideModal();
  };
}
