/**
 * @file Created on Fri Aug 17 2018
 * @author VBr
 */

import {
  DataPropDesc,
  Family,
  FamilyBinding,
  InternalProductPricesDTO,
  Product,
  ProductCategory,
  ProductSensitivity,
  StructPropBuilder,
  Supplier,
  Tier,
  Utils,
} from '@logio/common-be-fe';
import {
  ActionsGenerator,
  ColumnDefinition,
  ColumnGenerator,
  ErrorStore,
  FamilyStore,
  FormElOption,
  InternalProductPricesStore,
  listOfStringsColumnDefinition,
  LoadingState,
  PageStore,
  ProductCategoryStore,
  ProductSensitivityStore,
  ProductStore,
  rootStore,
  StoreName,
  StringMapping,
  SupplierStore,
  TierStore,
  translate,
  PriceZoneStore,
  Renderers,
  RendererNames,
  Comparators,
} from '@logio/common-fe';
import {List} from 'immutable';
import {ColumnApi, GridApi, GridReadyEvent, RowNode, RowSelectedEvent} from 'ag-grid-community';
import { bigdecimal } from '@logio/big-decimal';
import {action, computed, observable} from 'mobx';
import {packageSizeHelper} from '../../../../shared/packageSizeHelper';
import {PriceArchitectureProductsPageStore} from './PriceArchitectureProductsPageStore';

export default class AssignToFamilyModalStore extends PageStore {
  /** Entity stores */
  errorStore = rootStore.getStore(StoreName.Error) as ErrorStore;
  categories = rootStore.getStore(StoreName.ProductCategory) as ProductCategoryStore;
  sensitivities = rootStore.getStore(StoreName.ProductSensitivity) as ProductSensitivityStore;
  families = rootStore.getStore(StoreName.Family) as FamilyStore;
  tiers = rootStore.getStore(StoreName.Tier) as TierStore;
  priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;

  products = rootStore.getStore(StoreName.Product) as ProductStore;
  suppliers = rootStore.getStore(StoreName.Supplier) as SupplierStore;
  priceArchitectureProductsPageStore: PriceArchitectureProductsPageStore;
  internalProductPricesStore = rootStore.getStore(StoreName.InternalProductPrices) as InternalProductPricesStore;

  /** Custom schemas */
  assignToFamilyBuilder = new StructPropBuilder('AssignToFamily');
  bindingsSchema = Family.schema.bindings.members as DataPropDesc<FamilyBinding>;
  assignToFamilySchema = {
    assign: this.assignToFamilyBuilder.bool('assign'),
    average: this.assignToFamilyBuilder.num('average'),
    total: this.assignToFamilyBuilder.num('total'),
    suppliers: this.assignToFamilyBuilder.listOf(Supplier.schema.name),
  };

  /** Data generators */
  actionsGenerator = new ActionsGenerator();
  productColumnGenerator = new ColumnGenerator<Product>(Product.schema);
  familyColumnGenerator = new ColumnGenerator<Family>(Family.schema);
  tierColumnGenerator = new ColumnGenerator<Tier>(Tier.tierSchema);
  productSensitivityColumnGenerator = new ColumnGenerator<ProductSensitivity>(ProductSensitivity.schema);
  familyBindingsColumnGenerator = new ColumnGenerator<FamilyBinding>(this.bindingsSchema.data);
  assignToFamilyColumnGenerator = new ColumnGenerator<StringMapping<any>>(this.assignToFamilySchema);
  priceZonePricesGenerator: ColumnGenerator<StringMapping<any>>;
  internalProductPricesColumnGenerator = new ColumnGenerator<InternalProductPricesDTO>(InternalProductPricesDTO.schema);

  createPriceZoneGenerator = () => {
    const builder = new StructPropBuilder('PriceZonePricesBuilder');
    const description = {};
    this.priceZoneStore.listNotArchived.forEach(({id, isNational}) => {
        description[`${id}_actualRegularPrice`] = builder.bigNum(`${id}_actualRegularPrice`);
        description[`${id}_actualMarginPrice`] = builder.bigNum(`${id}_actualMarginPrice`);
        description[`${id}_actualMarginPercentage`] = builder.bigNum(`${id}_actualMarginPercentage`);
        description[`${id}_unitPrice`] = builder.bigNum(`${id}_unitPrice`);
    });
    this.priceZonePricesGenerator = new ColumnGenerator<StringMapping<any>>(description);
  };

  @observable
  productCategory: ProductCategory;
  @observable
  purchasePrice: number[];
  @observable
  selectedFamily: Family;
  @observable
  selectedFamilyEdited: Family;
  @observable
  selectedTier: string = '';
  @observable
  searchFamilyText: string = '';
  @observable
  familyName: string = '';
  @observable
  newFamily: boolean = false;
  @observable
  suggestedFamilies: string[] = [];
  @observable
  allSuggestedFamilies: string[] = [];

  @observable
  loadingState: LoadingState = LoadingState.Pending;

  @observable
  assignedProducts: Map<string, boolean> = new Map<string, boolean>();

  @observable
  familiesApi: GridApi;
  @observable
  familiesColumnApi: ColumnApi;
  @observable
  productsApi: GridApi;
  @observable
  productsColumnApi: ColumnApi;

  constructor(productsStore: PriceArchitectureProductsPageStore) {
    super();
    this.priceArchitectureProductsPageStore = productsStore;
    this.rowClass = this.rowClass.bind(this);
    this.suggestedFamilies = Array.from(this.families.list).map(([familyId]) => familyId);
  }

  /** Dimes excluded products */
  rowClass(params) {
    return this.assignedProducts.get(params.data[Product.schema.id.description.nameKey]) === false ? 'disabled' : '';
  }

  /** Gets tier options */
  @computed
  get getTiers() {
    const options: FormElOption[] = [];

    this.tiers.list.forEach((tier) => {
      options.push({value: tier.id, label: tier.title});
    });

    return options;
  }

  @computed
  get newFamilyValues() {
    return {
      name: this.priceArchitectureProductsPageStore.selectedProducts[0]!.name,
      tier: this.tiers.defaultTier.id,
    };
  }

  /** Column definitions for recommended families */
  @computed
  get familiesDefs(): ColumnDefinition[] {
    /** Each generator return column definition from related data description */
    return [
      this.actionsGenerator.getColumnDefinition({width: 40, headerName: ''}),
      ...this.familyColumnGenerator.getColumnDefinitions([{field: 'name'}]),
      ...this.tierColumnGenerator.getColumnDefinitions([
        {
          field: 'title',
          filter: 'agSetColumnFilter',
        },
      ]),
      ...this.assignToFamilyColumnGenerator.getColumnDefinitions([
        {
          field: 'suppliers',
          ...listOfStringsColumnDefinition,
        },
        {
          field: 'average',
          round: true,
        },
      ]),
    ];
  }

  /**
   * Returns families of products that match filtered parameters
   * @returns list of recommended families
   */
  @computed
  get familiesData(): Array<StringMapping<any>> {
    const rowData = [];

    const selectedProductFamily = this.priceArchitectureProductsPageStore.selectedProducts.find(
      (product) => !!product.familyId,
    );
    // Iterate through families to calculate average purchase price and get suppliers for every family
    this.suggestedFamilies.forEach((familyId: string) => {
      let average: number = 0;
      const supplierIds: string[] = [];
      const family = this.families.list.get(familyId);

      // Iterate through bindings to calculate average purchase price and get suppliers
      family.bindings.forEach((binding) => {
        const product = this.products.list.get(binding.productId);
        average += this.products.purchasePrices.get(binding.productId) || 0;
        // Get supplier ids (with no duplicity)
        if (product && product.supplierId) {
          if (!supplierIds.includes(product.supplierId)) {
            supplierIds.push(product.supplierId);
          }
        }
      });

      // Get string of supplier names
      const suppliers = List(supplierIds.map((supplierId) => this.suppliers.list.get(supplierId).name));
      // Divided average price
      average = average / family.bindings.size;

      rowData.push({
        ...this.familyColumnGenerator.getColumnData(family),
        ...this.tierColumnGenerator.getColumnData(this.tiers.list.get(family.tierId)),
        ...this.assignToFamilyColumnGenerator.getColumnData({
          average,
          suppliers,
        }),
        selected: selectedProductFamily && family && family.id === selectedProductFamily.familyId,
      });
    });

    // LOG-2427 - Selected rows comes first
    rowData.sort((r) => (r.selected ? -1 : 1));

    return rowData;
  }

  /**
   * Suppliers form options for the family filter select
   */
  get familiesSuppliersOptions(): FormElOption[] {
    // Unique not null supplier IDs in category
    const supplierIds = [...new Set(Array.from(this.products.list).map(([_, product]) => product.supplierId))].filter(
      Boolean,
    );

    return supplierIds
      .map((supplierId) => {
        const supplier = this.suppliers.list.get(supplierId);
        return {
          label: supplier.name,
          value: supplier.id,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  /**
   * Column definitions for products in family
   */
  @computed
  get productsDefs(): ColumnDefinition[] {
    const priceZonePricesDefinition: ColumnDefinition[] = [];
    this.priceZoneStore.listNotArchived.forEach(({id, name, isNational}) => {
        priceZonePricesDefinition.push(
          {
            field: `${id}_actualRegularPrice`,
            round: true,
            headerName: translate('PriceZonePricesBuilder_actualRegularPrice', name),
          },
          {
            field: `${id}_actualMarginPrice`,
            round: true,
            headerName: translate('PriceZonePricesBuilder_actualMarginPrice', name),
          },
          {
            field: `${id}_actualMarginPercentage`,
            round: true,
            // LOG-5514
            // cellRendererFramework: Renderers[RendererNames.ArrowRenderer],
            // comparator: Comparators.arrowComparator,
            headerName: translate('PriceZonePricesBuilder_actualMarginPercentage', name),
          },
        );
    });

    return [
      ...this.assignToFamilyColumnGenerator.getColumnDefinitions([
        {field: 'assign', width: 80, filter: false, action: this.toggleAssigned},
      ]),
      ...this.productColumnGenerator.getColumnDefinitions([
        {field: 'name', sort: 'asc'},
        // {
        //   field: 'supplierIds',
        //   valueFormatter: (params: ValueFormatterParams) => {
        //     return params.value.map((supplierId) => this.suppliers.list.get(supplierId)).join(', ');
        //   },
        // },
        {
          field: 'supplierId',
          filter: 'agSetColumnFilter',
          valueFormatter: ({value: supplierId}) => (supplierId ? this.suppliers.list.get(supplierId).name : ''),
        },
      ]),
      ...packageSizeHelper.colDefs(this.products),
      ...this.familyBindingsColumnGenerator.getColumnDefinitions([
        {
          field: 'sizeRatio',
          editable: true,
        },
        {field: 'sizeCharger', editable: true},
      ]),
      ...this.assignToFamilyColumnGenerator.getColumnDefinitions([
        {
          field: 'total',
          round: true,
        },
      ]),
      ...this.priceZonePricesGenerator.getColumnDefinitions(priceZonePricesDefinition),
    ];
  }

  /**
   * Takes all products from selected category and adds selected products marking them as checked (for assign) as default
   * @return view of the family with products selected to assign
   */
  @computed
  get productsData(): Array<StringMapping<any>> {
    const rowData = [];

    const getPriceZonePricesData = ({prices}: InternalProductPricesDTO) => {
      const priceZonePricesData = {};
      prices
        .toArray()
        .map(({actualRegularPrice, actualMarginPercentage, actualMarginPrice, unitPrice, priceZoneId}) => {

          priceZonePricesData[`${priceZoneId}_actualRegularPrice`] = actualRegularPrice;
          priceZonePricesData[`${priceZoneId}_actualMarginPercentage`] = actualMarginPercentage;
          priceZonePricesData[`${priceZoneId}_actualMarginPrice`] = actualMarginPrice;
          priceZonePricesData[`${priceZoneId}_unitPrice`] = unitPrice;
        });
      return priceZonePricesData;
    };

    if (!this.newFamily) {
      const selectedFamilyProducts = new Map<string, Product>();
      for (const k of this.products.list.keys()) {
        if (this.products.list.get(k).familyId === this.selectedFamily.id) {
          selectedFamilyProducts.set(k, this.products.list.get(k));
        }
      }
      selectedFamilyProducts.forEach((product: Product) => {
        rowData.push({
          ...this.assignToFamilyColumnGenerator.getColumnData({
            total: this.products.purchasePrices.get(product.id),
          }),
          ...this.productColumnGenerator.getColumnData(product),
          ...packageSizeHelper.getColumnData(product.packageSize),
          ...this.familyBindingsColumnGenerator.getColumnData(
            this.selectedFamily.bindings.find((binding) => binding.productId === product.id),
          ),
          ...this.priceZonePricesGenerator.getColumnData(
            getPriceZonePricesData(this.internalProductPricesStore.list.get(product.id)),
          ),
        });
      });
    }

    this.priceArchitectureProductsPageStore.selectedProducts.forEach((product) => {
      if (!rowData.find((data) => data[Product.schema.id.description.nameKey] === product.id)) {
        rowData.push({
          ...this.assignToFamilyColumnGenerator.getColumnData({
            assign: this.assignedProducts.get(product.id),
            total: this.products.purchasePrices.get(product.id),
          }),
          ...this.productColumnGenerator.getColumnData(product),
          ...packageSizeHelper.getColumnData(product.packageSize),
          ...this.familyBindingsColumnGenerator.getColumnData({
            productId: product.id,
            sizeCharger: bigdecimal(0),
            sizeRatio: product.packageSize.value,
          }),
          ...this.priceZonePricesGenerator.getColumnData(
            getPriceZonePricesData(this.internalProductPricesStore.list.get(product.id)),
          ),
        });
      }
    });

    return rowData;
  }

  /**
   * Toggles value of assigned checkbox which decides wether we assign product to the family
   */
  @action.bound
  toggleAssigned(productId: string, column: string, value: boolean): Promise<void> {
    this.assignedProducts = this.assignedProducts.set(productId, value);
    return Promise.resolve();
  }

  @action
  updateSelectedFamily = (family: Family) => {
    this.selectedFamilyEdited = family;
  };

  /**
   * Gets all selected products to assign and assignees them to selected family
   */
  @action.bound
  assignFamily(): Promise<void> {
    const bindings: FamilyBinding[] = [];

    this.setLoadingState(LoadingState.Pending);

    // Get all new products marked as assigned and store them to array of family bindings
    this.productsApi.forEachNode((node: RowNode) => {
      if (node.data[this.assignToFamilySchema.assign.description.nameKey] !== false) {
        const sizeCharger = node.data[this.bindingsSchema.data.sizeCharger.description.nameKey];
        const sizeRatio = node.data[this.bindingsSchema.data.sizeRatio.description.nameKey];

        // FIXME sizeCharger and sizeRatio cannot be null!
        bindings.push({
          productId: node.data[Product.schema.id.description.nameKey],
          sizeCharger: sizeCharger != null ? bigdecimal(sizeCharger) : null,
          sizeRatio: sizeRatio != null ? bigdecimal(sizeRatio) : null,
        });
      }
    });

    const selectedProductsCount = bindings.length.toString();

    // Creates new family and assigns products marked as assigned to it
    if (this.newFamily) {
      return (
        this.families
          // Create family
          .createWithAffected(
            new Family(Utils.VOID_ID, Utils.VOID_NUMERIC_ID, this.familyName, this.selectedTier, List(bindings)),
          )
          .then(({family, affectedFamilies}) => {
            const productIds = [];
            const families = affectedFamilies.concat(family);
            families.forEach((f) => {
              f.bindings.forEach(({productId}) => productIds.push(productId));
            });
            this.products
              .updateByfilter({ids: List(productIds)})
              .then(() => {
                this.priceArchitectureProductsPageStore.toggleModal('assignFamily');
                this.priceArchitectureProductsPageStore.messages.setSuccess(
                  translate('AssignToFamily_productsAssigned', selectedProductsCount, family.name),
                );
                this.priceArchitectureProductsPageStore.preparePostSort(bindings.map((b) => b.productId));
                this.priceArchitectureProductsPageStore.gridApi.deselectAll(); // LOG-2869
                this.families.getInCategory(this.priceArchitectureProductsPageStore.categoryId); // Refresh for deleted families
              })
              .catch((error) => {
                // this.messages.setError(error);
              });
          })
          .catch((error) => {
            // this.messages.setError(error);
            this.setLoadingState(LoadingState.Error);
          })
      );
    }
    // Assigns products marked as assigned to existing family
    else {
      return Promise.all([
        this.selectedFamilyEdited && this.selectedFamilyEdited.tierId !== this.selectedFamily.tierId
          ? this.families.updateTier(
              List<{familyId: string; tierId: string}>([
                {familyId: this.selectedFamily.id, tierId: this.selectedFamilyEdited.tierId},
              ]),
            )
          : Promise.resolve(),
        this.selectedFamilyEdited && this.selectedFamilyEdited.name !== this.selectedFamily.name
          ? this.families.updateName(
              List<{familyId: string; name: string}>([
                {familyId: this.selectedFamily.id, name: this.selectedFamilyEdited.name},
              ]),
            )
          : Promise.resolve(),
      ])
        .then(() => {
          return this.products.assignToFamily(this.selectedFamily.id, bindings);
        })
        .then(() => {
          this.priceArchitectureProductsPageStore.toggleModal('assignFamily');
          this.priceArchitectureProductsPageStore.messages.setSuccess(
            translate(
              'AssignToFamily_productsAssigned',
              selectedProductsCount,
              this.selectedFamilyEdited && this.selectedFamilyEdited.name !== this.selectedFamily.name
                ? this.selectedFamilyEdited.name
                : this.selectedFamily.name,
            ),
          );
          this.priceArchitectureProductsPageStore.preparePostSort(bindings.map((b) => b.productId));
          this.priceArchitectureProductsPageStore.gridApi.deselectAll(); // LOG-2869
          this.families.getInCategory(this.priceArchitectureProductsPageStore.categoryId); // Refresh for deleted families
        })
        .catch((error) => {
          // this.messages.setError(error);
        });
    }
  }

  /** Binds ag-grid api of families table and default selects all of the selected products as assigned */
  @action.bound
  onFamiliesReady(params: GridReadyEvent) {
    this.familiesApi = params.api;
    this.familiesColumnApi = params.columnApi;

    this.priceArchitectureProductsPageStore.selectedProducts.forEach(
      (product) => (this.assignedProducts = this.assignedProducts.set(product.id, true)),
    );

    const selectedProductFamily = this.priceArchitectureProductsPageStore.selectedProducts.find(
      (product) => !!product.familyId,
    );
    if (selectedProductFamily) {
      this.familiesApi.forEachNode((rowNode: RowNode) => {
        if (rowNode.data[Family.schema.id.description.nameKey] === selectedProductFamily.familyId) {
          rowNode.selectThisNode(true);
        }
      });
    }
  }

  /** Binds ag-grid api of products table */
  @action.bound
  onProductsReady(params: GridReadyEvent) {
    this.productsApi = params.api;
    this.productsColumnApi = params.columnApi;
  }

  /** Changes selected family on row check */
  @action.bound
  onRowSelected(params: RowSelectedEvent) {
    const rows = params.api.getSelectedNodes();
    if (rows.length) {
      this.selectedFamily = this.families.list.get(rows[0].data[Family.schema.id.description.nameKey]);
    } else {
      this.selectedFamily = undefined;
    }
  }

  /** Checks if there's external filter set for families  */
  @action.bound
  isExternalFamilyFilterPresent(): boolean {
    return !!this.searchFamilyText;
  }

  /** Regexp fulltext search in family names */
  @action.bound
  doesFamilyFilterPass(node: RowNode): boolean {
    return node.data[Family.schema.name.description.nameKey].search(new RegExp(this.searchFamilyText, 'ig')) !== -1;
  }

  /** Updates searched family text on input change */
  @action.bound
  searchFamily(e: React.ChangeEvent<HTMLInputElement>): void {
    this.searchFamilyText = e.target.value;
    this.familiesApi.onFilterChanged();
  }

  /** Resets searched family text on input change */
  @action.bound
  resetSearchFamilyFilter(): void {
    this.searchFamilyText = '';
    this.familiesApi.onFilterChanged();
  }

  /** Updates new family input states */
  @action.bound
  updateFamily(values: StringMapping<any>): void {
    this.familyName = values.name;
    this.selectedTier = values.tier;
  }

  /** Toggles selected tab */
  @action.bound
  selectTab(index: number, lastIndex: number, event: Event): boolean | void {
    // Toggles displayed tab
    this.newFamily = false;
    if(index === 1) {
      this.newFamily = true;
      // Clears previously selected family on tab change
      this.selectedFamily = undefined;
    }
  }

  /** Gets suggested families list on filter change */
  @action.bound
  updateFilter(filterValues: StringMapping<any>) {
    const suggestedFamilies = [];

    // Iterate through families
    this.families.list.forEach((family) => {
      if (
        // Iterate through products in family, if some of the products meets the filter parameters, store it for suggested families table
        family.bindings.some((binding) => {
          const product = this.products.list.get(binding.productId);

          // Skip undefined products
          if(!product) {
            return false;
          }

          const purchasePrice = this.products.purchasePrices.get(binding.productId) || 0;

          // If purchase price is in selected interval
          const isPriceOk = (
              purchasePrice >= filterValues.purchase_price[0] &&
              purchasePrice <= filterValues.purchase_price[1]
          );
          // If product has one of selected suppliers
          const isSupplierOk = (
            !filterValues.supplier ||
            !filterValues.supplier.length ||
            !filterValues.supplier[0] || // if the array contains one null value instead of empty array
            filterValues.supplier.some((supplier) => product.supplierId && product.supplierId === supplier)
          );
          // If product is in one of selected categories
          const isBoxOk = (
            !filterValues.box ||
            !filterValues.box.length ||
            !filterValues.box[0] || // if the array contains one null value instead of empty array
            filterValues.box.some((box) => product.categoryIds.includes(box))
          );

          if (isPriceOk && isSupplierOk && isBoxOk) {
            return true;
          }
          return false;
        })
      ) {
        suggestedFamilies.push(family.id);
      }
    });
    // Store it at once so store doesn't change with every iteration
    this.suggestedFamilies = suggestedFamilies;
  }

  get initialFilterFamilyValues() {
    let minPrice = Math.floor(this.priceArchitectureProductsPageStore.minPurchasePrice);
    let maxPrice = Math.ceil(this.priceArchitectureProductsPageStore.maxPurchasePrice);
    this.priceArchitectureProductsPageStore.selectedProducts.forEach((product) => {
      const productPrice = this.products.purchasePrices.get(product.id);
      if (productPrice < minPrice) {
        minPrice = productPrice;
      }
      if (productPrice > maxPrice) {
        maxPrice = productPrice;
      }
    });

    return {
      supplier: [
        ...new Set(this.priceArchitectureProductsPageStore.selectedProducts.map((product) => product.supplierId)),
      ], // Unique array of IDs
      purchase_price: [minPrice, maxPrice],
      box: [
        ...new Set(
          this.priceArchitectureProductsPageStore.selectedProducts.map((product) => Utils.getCategoryIdWithLevel(product.categoryIds, 6)),
        ),
      ], // Unique array of IDs
    };
  }

  /**
   * Fetches all data for this page
   */
  @action.bound
  load(): void {
    Promise.all([this.suppliers.getAll()]).then(() => {
      this.createPriceZoneGenerator();
      this.setLoadingState(LoadingState.Success);
    });
  }
}
