/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
import * as React from 'react';
import CircleStyled from './styled/CircleStyled';

var Circle = function Circle(props) {
  return React.createElement(CircleStyled, Object.assign({}, props, {
    className: props.className
  }));
};

export default Circle;