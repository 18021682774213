"use strict";
/*
 * Created on 28/11/2018
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author JMr
 */

var CodebookLookupSequence_1 = require("../../core/model/codebook/CodebookLookupSequence");

exports.AGENCY_TYPE_LOOKUP = CodebookLookupSequence_1.CodebookLookupSequence.AgencyType;
var AgencyTypeEnum;

(function (AgencyTypeEnum) {
  AgencyTypeEnum["AC_Nielsen"] = "AC Nielsen";
  AgencyTypeEnum["PPM_Factum"] = "PPM Factum";
  AgencyTypeEnum["OTHERS"] = "Others";
})(AgencyTypeEnum = exports.AgencyTypeEnum || (exports.AgencyTypeEnum = {}));