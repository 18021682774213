/**
 * Button can be rendered in these colors
 */
export var ButtonColor;

(function (ButtonColor) {
  ButtonColor["Secondary"] = "SECONDARY";
  ButtonColor["Light"] = "LIGHT";
  ButtonColor["Outline"] = "OUTLINE";
  ButtonColor["Transparent"] = "TRANSPARENT";
  ButtonColor["TransparentDark"] = "Transparent_DARK";
})(ButtonColor || (ButtonColor = {}));