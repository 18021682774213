/**
 * @file Data cleaning page
 * @author Atreo
 */

import { FlagSet, Utils } from '@logio/common-be-fe';
import { CONSTANTS, Icon, IconColor, IconType, N, Tooltip, TooltipType, translate } from '@logio/common-fe';
import * as React from 'react';
import { PriceHistoryModalStore } from 'stores/pages/DataManagement/DataCleaning/PriceHistoryModalStore';
import { action, runInAction } from 'mobx';
import { GridApi } from 'ag-grid-community';
import { BigDecimal } from '@logio/big-decimal';

interface PriceHistoryRendererProps {
  value: {price: BigDecimal; flags?: FlagSet<any>};
  priceHistoryModalStore: PriceHistoryModalStore;
  eGridCell: HTMLElement;
  zoneId: any;
  competitorId: any;
  productId: any;
  rowId: any;
  api: GridApi;
  rowData: any;
  pageReadOnly;
  isNewCRP: boolean;
}

class PriceHistoryRenderer extends React.Component<PriceHistoryRendererProps> {

  constructor(props) {
    super(props);
    this.props.eGridCell.addEventListener('dblclick', this.loadModal);
    this.props.priceHistoryModalStore.dataCleaningProductsPageGridApi = this.props.api;
    runInAction(() => (this.props.priceHistoryModalStore.pageReadOnly = this.props.pageReadOnly));
  }

  componentWillUnmount() {
    this.props.eGridCell.removeEventListener('dblclick', this.loadModal);
  }

  @action.bound
  async loadModal() {
    await this.props.priceHistoryModalStore.loadModal(this.props.isNewCRP, this.props.competitorId, this.props.zoneId, this.props.productId, this.props.rowId);
  }

  get flagWarnings(): JSX.Element[] {
    const flags: JSX.Element[] = [];
    if (!Utils.isValueMissing(this.props.value.flags)) {
      this.props.value.flags.serialize().forEach((flag) => {
        flags.push(<li key={flag.tag}> {translate(flag.tag)}</li>);
      });
    }
    return flags;
  }

  render() {
    if (!Utils.isValueMissing(this.props.value)) {
      return (
        <div className="pointer">
          {this.flagWarnings.length > 0 && (
            <div className="fixed z1000 mr1">
              <Tooltip
                overlay={this.flagWarnings}
                tooltipType={TooltipType.Warning}
                placement="right"
                children={
                  <div className="ag-cell-icon">
                    <Icon iconColor={IconColor.Error} iconType={IconType.alert} iconHeight={15}/>
                  </div>
                }
              />
            </div>
          )}

          <N value={this.props.value.price} round/>

        </div>
      );
    }
    return CONSTANTS.FORMAT.NULL;
  }

}

export default PriceHistoryRenderer;
