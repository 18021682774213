/**
 * @file Created on Mon Oct 08 2018
 * @author BPo
 */
import {CheckBoxGroupProps, FormGroupInline, FormGroupInlineProps, Select, translate} from '@logio/common-fe';
import * as React from 'react';

interface PriceZoneFilterProps {
  className?: string;
}

/**
 * Renders price zone filter
 */
export const PriceZoneFilter: React.SFC<
  PriceZoneFilterProps & FormGroupInlineProps & Pick<CheckBoxGroupProps, 'values' | 'onChange' | 'options'>
> = ({values, onChange, options, right, className}) => {
  return (
    <div className="form-horizontal">
      <FormGroupInline right={right} className={className}>
        <Select
          multi
          name="price-zone-filter"
          value={values}
          label={translate('filters-price_zones')}
          onChange={onChange}
          groupLabel={translate('filters-price_zones')}
          options={options}
        />
      </FormGroupInline>
    </div>
  );
};
