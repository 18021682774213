"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Price origin.
 * @author Radek Beran
 */

var PriceOrigin;

(function (PriceOrigin) {
  PriceOrigin["Transactions"] = "TRANSACTIONS";
  PriceOrigin["PriceList"] = "PRICE_LIST";
})(PriceOrigin = exports.PriceOrigin || (exports.PriceOrigin = {}));