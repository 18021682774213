/**
 * @file Created on Thu May 09 2019
 * @author LZe
 * @author SKu
 */

import {observer} from 'mobx-react';
import * as React from 'react';
import {ReleaseSelloutDetailCategoryComponentStore} from '../../../stores/components';
import ReleaseSelloutDetailCategoryCategoryTotalOverview from './ReleaseSelloutDetailCategoryCategoryTotalOverview';
import ReleaseSelloutDetailCategoryItemModal from './ReleaseSelloutDetailCategoryItemModal';
import ReleaseSelloutDetailCategoryTotalOverview from './ReleaseSelloutDetailCategoryTotalOverview';
import {PollingHelper} from 'stores/components/PollingHelper';

export interface Props {
  store: ReleaseSelloutDetailCategoryComponentStore;
  /** Should be true if component used for export page */
  export?: boolean;
  recalculate?: (event: any) => void;
  isRecalculationRequired?: boolean;
  recalculateActionFired?: boolean;
  pollingHelper?: PollingHelper
}

/**
 * Release sellout detail category component
 * Used also in export page
 */
const ReleaseSelloutDetailCategoryComponent: React.SFC<Props> = observer((props: Props) => (
  <>
    <ReleaseSelloutDetailCategoryTotalOverview store={props.store} export={props.export}/>
    <ReleaseSelloutDetailCategoryCategoryTotalOverview store={props.store} export={props.export}/>
    <ReleaseSelloutDetailCategoryItemModal
      store={props.store}
      recalculate={props.recalculate}
      isRecalculationRequired={props.isRecalculationRequired}
      recalculateActionFired={props.recalculateActionFired}
      pollingHelper={props.pollingHelper}
    />
  </>
));

export default ReleaseSelloutDetailCategoryComponent;
