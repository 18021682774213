/**
 * @file Created on Fri Oct 05 2018
 * @author SKu
 */

import {ReleaseAction} from '@logio/common-be-fe';
import {
  Button,
  ButtonColor,
  ButtonSize,
  getPath,
  Icon,
  IconColor,
  IconType,
  IReleaseImpactFilter,
  LoadingState,
  Messages,
  Modal,
  Page,
  ProgressBar,
  translate,
  getWorkflowCasesLockHeartbeat,
} from '@logio/common-fe';
import {computed} from 'mobx';
import {Observer} from 'mobx-react';
import * as React from 'react';
import {match, RouteComponentProps} from 'react-router';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {AbstractReleaseDetailCategoryPageStore} from '../../../stores/pages/Release/ReleaseDeatailCategory/AbstractReleaseDetailCategoryPageStore';
import {Link} from 'react-router-dom';
import {IReleaseFilter, ReleaseUtils} from '../../../components/Release/ReleaseUtils';

export interface Props {
  match: match<{categoryId: string; releaseId: string}>;
}

/** Abstract Release detail category page */
abstract class AbstractReleaseDetailCategoryPage extends React.Component<
  Props & RouteComponentProps<AbstractReleaseDetailCategoryPage>
> {
  public abstract store: AbstractReleaseDetailCategoryPageStore;

  /**
   * Parses url queries and returns as {IReleaseImpactFilter}
   */
  parsedQuery = (): IReleaseImpactFilter => {
    const filter = ReleaseUtils.parseQuery(this.props.location);
    return ReleaseUtils.parseQueryRelease(filter, this.store.release);
  };

  /**
   * Parses url queries and returns as {IReleaseImpactFilterMoment}
   */
  get parsedQueryFilter(): IReleaseFilter {
    return ReleaseUtils.queryToMoment(this.parsedQuery());
  }

  async componentWillMount() {
    await this.store.load(this.parsedQuery);
  }

  /** Removes polling in case if has been started */
  componentWillUnmount() {
    this.store.destroy();
  }

  /** On click, approves whole category */
  @computed
  public get approveHeaderItem() {
    const actions = this.store.actionFeasibilities;
    return (
      <Button
        buttonSize={ButtonSize.Tiny}
        // get(0) -  approve action, should be disabled if not allowed
        disabled={actions && !actions.get(0).allowed}
        onClick={() => this.store.onAction(ReleaseAction.Approve)}
      >
        {translate('Approve')}
      </Button>
    );
  }

  /** Unlocks category for other users, and turns on editMode */
  @computed
  public get unlockHeaderItem() {
    return (
      <Icon
        iconType={IconType.unlocked}
        iconColor={IconColor.Text}
        onClick={() => this.store.onAction(ReleaseAction.AbandonEdit)}
      />
    );
  }

  /** Locks category(if there are no conflicts) and sets editMode to true */
  @computed
  public get lockHeaderItem() {
    return (
      <Icon
        iconType={IconType.locked}
        iconColor={IconColor.Text}
        onClick={() => this.store.onAction(ReleaseAction.Edit)}
      />
    );
  }

  /** Turn on category data recalculation (using polling) */
  @computed
  public get recalculateHeaderItem() {
    return (
      this.store.isRecalculationRequired &&
      !this.store.pollingHelper.progress &&
      !this.store.recalculateActionFired && (
        <Icon
          iconType={IconType.calc}
          iconColor={IconColor.Text}
          onClick={() => this.store.onAction(ReleaseAction.Recalculate)}
        />
      )
    );
  }

  /** Custom icons for release header,  permissions should be checked */
  public abstract get releaseHeaderItems(): JSX.Element[];

  /** Returns proper release header (depends on release type) */
  public abstract get releaseHeader();

  /** Custom elements, that should rendered under the header */
  public abstract get renderContent(): JSX.Element;

  render() {
    const editMode = this.store.releaseDetailCategoryComponentStore.editMode;
    const title = this.store.isLoadingSuccess && `${this.store.release.name} - ${this.store.productCategory.name}`;
    let releasePath: string;
    let backButtonPath: string;

    if (this.props.location.pathname.indexOf('/sellout/') > -1) {
      releasePath = getPath(PagePathsEnum.ReleaseSelloutDetail, this.props.match.params.releaseId);
      backButtonPath = getPath(PagePathsEnum.ReleasesSellout);
    } else {
      releasePath = getPath(PagePathsEnum.ReleaseDetail, this.props.match.params.releaseId);
      backButtonPath = getPath(PagePathsEnum.ReleasesRegular);
    }

    return (
      <Page
        titleKey={title}
        titleComponent={<Link to={releasePath}>{title}</Link>}
        loadingState={this.store.loadingState}
        showMessages={false}
        backButtonPath={backButtonPath}
        workflowCasesLockHeartbeat={
          editMode && getWorkflowCasesLockHeartbeat(this.store.release, this.store.categoryId)
        }
      >
        <Observer>
          {() => (
            <>
              {this.releaseHeader}
              <Messages />
              {(this.store.pollingHelper.pollingState === LoadingState.Pending ||
                this.store.recalculateActionFired) && (
                <div className="mb2">
                  <ProgressBar
                    percentage={this.store.pollingHelper.progress || 0}
                    label={'release-category-recalculating-now'}
                  />
                </div>
              )}
              <Modal
                hidden={!this.store.isConfirmModal}
                title={translate('confirm-category')}
                close={this.store.onCancel}
              >
                <div className="tar mt3">
                  <Button buttonColor={ButtonColor.Secondary} onClick={this.store.onConfirm}>
                    {translate('Confirm')}
                  </Button>
                  <Button buttonColor={ButtonColor.Light} onClick={this.store.onCancel}>
                    {translate('Cancel')}
                  </Button>
                </div>
              </Modal>
              {this.renderContent}
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

export default AbstractReleaseDetailCategoryPage;
