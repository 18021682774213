/**
 * @file Created on Fri Oct 05 2018
 * @author SKu
 */

import {observer} from 'mobx-react';
import * as React from 'react';
import {ReleaseDetailCategoryComponentStore} from '../../../stores/components';
import {DetailCategoryModalEnum} from '../../../stores/components/Release/ReleaseDetailCategory/ReleaseDetailCategoryComponentStore';
import CommentsModal from '../CommentsModal';
import ReleaseDetailCategoryItemOverview from './ReleaseDetailCategoryItemOverview';
import ReleaseDetailCategoryTotalOverview from './ReleaseDetailCategoryTotalOverview';

export interface Props {
  store: ReleaseDetailCategoryComponentStore;
  /** Should be true if component used for export page */
  export?: boolean;
}

/**
 * Release detail category component
 * Used also in export page
 */
const ReleaseDetailCategoryComponent: React.SFC<Props> = observer((props: Props) => (
  <>
    <ReleaseDetailCategoryTotalOverview store={props.store} />
    <ReleaseDetailCategoryItemOverview store={props.store} export={props.export} />
    <CommentsModal
      hidden={props.store.isModalHidden(DetailCategoryModalEnum.Comment)}
      close={props.store.hideModal}
      onCommentSubmit={props.store.onCommentSubmit}
      initialValues={props.store.comments}
      editable={props.store.editMode}
    />
  </>
));

export default ReleaseDetailCategoryComponent;
