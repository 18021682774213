import {
  ReleaseState,
  ReleaseType,
  CommonReleaseOverview,
} from '@logio/common-be-fe';
import {
  ActionProps,
  ActionsGenerator,
  ColumnDefinition,
  IconType,
  LoadingState,
  PageStore,
  ReleaseStore,
  rootStore,
  StoreName,
  StringMapping,
  translate,
} from '@logio/common-fe';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { action, computed, observable } from 'mobx';
import { ReleaseColumnGenerator } from '../../../components/Release/columnGenerators/ReleaseColumnGenerator';
import moment from 'moment';

/**
 * Abstract store for release overview page containing all logic across all release types.
 * Composition over inheritance would be better, but it is common approach with releases in this project.
 * 
 * @author Jan Prokop
 */
export abstract class AbstractReleasePageStore extends PageStore {
  /** Release store to get data. */
  protected releaseStore = rootStore.getStore(StoreName.Release) as ReleaseStore;

  /** Variable consist of Release States, and used as CheckBoxGroup values */
  @observable
  public currentReleaseStates: string[] = [];

  /** Ag-grid generators */
  protected actionsGenerator = new ActionsGenerator();

  /** Custom release generator */
  protected releaseColumnGenerator = new ReleaseColumnGenerator();

  /** Ag-grid api */
  protected gridApi: GridApi;

  /** List of release types, which should be loaded. */
  protected abstract releaseTypes: ReleaseType[];

  /** Set true, if impact columns should be visible. */
  protected impactMode = false;

  /** Column definition for the Ag-grid */
  @computed
  public get columnDefs(): ColumnDefinition[] {
    return this.getColumnDefs();
  }

  /**
   * Generates column definitions for the AG grid.
   */
  protected getColumnDefs() {
    const actionDefs: ColumnDefinition = {
      headerName: '',
      suppressMovable: false,
      lockPinned: false,
      lockPosition: false,
      checkboxSelection: false,
      headerCheckboxSelection: false,
      hide: false,
    };

    return [
      this.actionsGenerator.getColumnDefinition(actionDefs),
      ...this.releaseColumnGenerator.getColumnDefinitions(this.releaseTypes, this.impactMode),
    ];
  }

  /** Ag-Grid data for each row */
  @computed
  public get rowData(): Array<StringMapping<any>> {
    const rowData = [];

    this.releaseStore.overviews.forEach((overview: CommonReleaseOverview) => {
      rowData.push(this.getOverviewData(overview));
    });

    return rowData;
  }

  /**
   * Generates overview data for one row.
   *
   * @param overview
   * @returns
   */
  protected getOverviewData(overview: CommonReleaseOverview) {
    // If release is new, icon should redirect to settings, else to the release detail
    const redirectIcon: ActionProps = {
      name: 'redirect',
      icon: IconType.view,
      linkProps: { to: this.getReleaseDetailPath(overview) },
    };

    return {
      ...this.actionsGenerator.getColumnData(redirectIcon),
      // We should pass translated release.state value to the grid,
      // to have translated values inside filter, mb not the best solution
      ...this.releaseColumnGenerator.getColumnData({
        ...overview,
        state: translate(`${overview.state}`),
        ...this.getRepricingDaysData(overview),
      }),
      ...this.releaseColumnGenerator.getColumnData(overview.currentImpact && {
        discountPercent: overview.currentImpact.discountPercent,
      }),
      ...this.releaseColumnGenerator.getColumnData(overview.stockValues),
    };
  }

  /**
   * Sets grid api to the var.
   */
  @action.bound
  public onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  /**
   * Set values to CheckboxGroup,
   * refresh agGrid with new values
   * @param values - string array of Release states
   */
  @action.bound
  public async onReleaseStateFilter(values: string[]) {
    this.currentReleaseStates = values;

    // Grid overlay used for hide data from the grid while they are updating
    this.gridApi.showLoadingOverlay();
    try {
      await this.loadOverviews();
    } catch (error) {
      // this.messages.setError(error);
    } finally {
      this.gridApi.hideOverlay();
    }
  }

  /**
   * Load everything needed for the store. Used in initial load.
   */
  @action.bound
  public async load() {
    this.setLoadingState(LoadingState.Pending);
    try {
      await this.loadData();
      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      this.setLoadingState(LoadingState.Error);
    }
  }

  /**
   * Load all necessary data. Used in initial load. Designed to be overload by sub classes to
   * load additional data. Also list of overviews should be fetched here.
   */
  protected async loadData() {
    await this.loadOverviews();
  }

  /**
   * Load overviews into the releaseStore. Use all release states, if none is select.
   */
  protected async loadOverviews() {
    const currentReleaseStates = this.currentReleaseStates.length === 0
      ? Object.values(ReleaseState)
      : this.currentReleaseStates; // LOG-5716
    await this.releaseStore.getOverviews({ state: currentReleaseStates, releaseType: this.releaseTypes });
  }

  /**
   * Prepare repricing column data for sellout releases.
   *
   * @param overview
   * @returns
   */
  private getRepricingDaysData(overview: CommonReleaseOverview) {
    const data: any = {};

    if (overview.releaseType === ReleaseType.Sellout) {
      const now = moment().startOf('day');
      const initialOpen = overview.previousOpens.get(0);
      const initialPriceValidityStartDate = initialOpen ? initialOpen.priceValidityStartDate : overview.priceValidityStartDate;

      if (initialPriceValidityStartDate) {
        const daysSinceFirstRepricing = now.diff(initialPriceValidityStartDate, 'days') + 1;
        if (daysSinceFirstRepricing > 0 && overview.state !== ReleaseState.Inactive) {
          data.daysSinceFirstRepricing = daysSinceFirstRepricing;
        }
      }

      const priceValidityStartDate = overview.priceValidityStartDate;

      if (priceValidityStartDate) {
        const daysSinceLastRepricing = now.diff(priceValidityStartDate, 'days') + 1;
        if (daysSinceLastRepricing > 0 && overview.state !== ReleaseState.Inactive) {
          data.daysSinceLastRepricing = daysSinceLastRepricing;
        }
      }
    }

    return data;
  }

  /**
   * Returns path for ag-grid view icon
   * depends on releaseType, workflowType and release state
   */
  protected abstract getReleaseDetailPath(overview: CommonReleaseOverview);
}
