/**
 * @file Created on Thu Sep 06 2018
 * @author SKu
 */

import {PricingPermissions} from '@logio/common-be-fe';
import {
  AgGrid,
  Button,
  ButtonSize,
  ConfirmBox,
  FormGroupInline,
  Page,
  translate,
  WithPermission,
} from '@logio/common-fe';
import {observer, Observer} from 'mobx-react';
import * as React from 'react';
import {PageNamesEnum} from '../../shared/localization/PageNamesEnum';
import {TiersModalEnum, TiersPageStore} from '../../stores/pages/Settings/TiersPageStore';
import {TiersPageForm} from './TiersPageForm';

/**
 * Renders Main Tier Page
 */
@observer
class TiersPage extends React.Component {
  store = new TiersPageStore();

  componentDidMount() {
    this.store.load();
  }

  render() {
    return (
      <Page titleKey={PageNamesEnum.TiersSettings} loadingState={this.store.loadingState}>
        <Observer>
          {() => (
            <>
              <WithPermission permissionTypes={[PricingPermissions.TIERS_SETTINGS_EDIT]}>
                <FormGroupInline right={true}>
                  <Button buttonSize={ButtonSize.Tiny} onClick={this.store.openModal(TiersModalEnum.CREATE)}>
                    {translate('create-tier')}
                  </Button>
                </FormGroupInline>
              </WithPermission>
              <TiersPageForm store={this.store} />
              <TiersPageForm store={this.store} createMode />
              <TierConfirmBox store={this.store} />
              <AgGrid rowData={this.store.rowData} columnDefs={this.store.columnDefs} gridId="PricingTiersPage" />
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

export default TiersPage;

/** Delete tier confirmation */
@observer
class TierConfirmBox extends React.Component<{store: TiersPageStore}> {
  render() {
    return (
      <ConfirmBox
        hidden={this.props.store.modalHidden[TiersModalEnum.DELETE]}
        title={translate('delete-tier')}
        onAccept={this.props.store.onDeleteSubmit}
        close={this.props.store.getModalToggleEvent(TiersModalEnum.DELETE)}
      >
        {`${translate('delete-tier-confirmation')} "${this.props.store.initialValues.title}"`}
      </ConfirmBox>
    );
  }
}
