/**
 * @file Created on Nov 2019
 * @author Atreo
 */
import * as React from 'react';
import Icon, { IconColor, IconType } from '../../media/Icon';
import Button, { ButtonColor } from '../../controls/Button';
import Modal from '../Modal';
import ButtonLoading from "../../controls/Button/ButtonLoading";
/**
 * ConfirmBox renders modal with 2 buttons. Modal text  - children
 */

var ConfirmBoxWithLoading = function ConfirmBoxWithLoading(props) {
  return React.createElement(Modal, {
    hidden: props.hidden,
    title: props.title,
    close: !props.isLoading && props.close
  }, React.createElement("div", {
    className: "mb1"
  }, " ", props.children), React.createElement("div", {
    className: "tar"
  }, !props.isLoading && React.createElement(Button, {
    buttonColor: ButtonColor.Light,
    onClick: props.close
  }, "Discard"), React.createElement(ButtonLoading, {
    isLoading: props.isLoading,
    onClick: props.onAccept
  }, React.createElement(Icon, {
    iconType: IconType.approveO,
    iconColor: IconColor.Inverse
  }), "Accept")));
};

export default ConfirmBoxWithLoading;