"use strict";
/*
 * Created on 01 23 2019
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author Jaroslav Sevcik
 * @author Tomas Leity
 *
 * Logical groups of notifications. E. g. displayed icons can be chosen according to this group.
 */

var NotificationGroup;

(function (NotificationGroup) {
  /**
   * PRICING
   */
  NotificationGroup["dataUpload"] = "DATA_UPLOAD";
  NotificationGroup["release"] = "RELEASE";
  NotificationGroup["user"] = "USER";
  NotificationGroup["dataManagement"] = "DATA_MANAGEMENT";
  /**
   * PROMO_OLD
   */

  NotificationGroup["window"] = "WINDOW";
  /**
   * PROMO
   */

  NotificationGroup["Theme"] = "THEME";
  NotificationGroup["Promotion"] = "PROMOTION";
  NotificationGroup["Window"] = "WINDOW";
  NotificationGroup["WindowItem"] = "WINDOW_ITEM";
  NotificationGroup["Product"] = "PRODUCT";
  NotificationGroup["Forecast"] = "FORECAST";
  NotificationGroup["Sites"] = "SITES";
  NotificationGroup["MasterArea"] = "MASTER_AREA";
})(NotificationGroup = exports.NotificationGroup || (exports.NotificationGroup = {}));