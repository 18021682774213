import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n      padding-top: 30px;\n\n      .modal {\n        width: 100%;\n        min-width: 1240px;\n        max-width: calc(100% - 60px);\n        height: calc(100% - 60px);\n      }\n    "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  visibility: visible;\n  position: fixed;\n  /* display: flex;\n  justify-content: center; */\n  padding-top: 60px;\n  content: '';\n  z-index: ", ";\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n  background-color: rgba(0, 0, 0, 0.8);\n\n  .modal {\n    position: absolute;\n    left: 50%;\n    transform: translateX(-50%);\n    z-index: ", ";\n    min-width: 600px;\n    max-width: 1025px;\n    background-color: ", ";\n    border-radius: ", ";\n  }\n\n  .modal:after {\n    content: ' . '; /* fix for FF */\n    color: transparent;\n    position: absolute;\n    height: 60px;\n  }\n\n  .modal-header {\n    position: relative;\n    background-color: ", ";\n    color: ", ";\n    height: ", "px;\n    padding: 20px 0 0 ", "rem;\n    border-radius: ", ";\n\n    h3 {\n      margin: 0;\n    }\n    svg {\n      fill: ", ";\n    }\n  }\n\n  .modal-close {\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    right: ", "rem;\n    border: 0;\n    cursor: pointer;\n    background-color: transparent;\n  }\n\n  .modal-content {\n    font-size: 100%;\n    padding: ", "rem;\n    position: relative;\n    min-height: 10rem;\n    background-color: ", ";\n    /* overflow-y: auto;\n    max-height: 80vh; */\n  }\n\n  ", ";\n\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  visibility: hidden;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author SKu
 */
import styled, { css, withTheme } from 'styled-components';
/**
 * Create Modal component
 */

var Hidden = css(_templateObject());
var defaultZIndex = 1000;
var ModalStyled = styled.span(_templateObject2(), function (props) {
  return props.zIndex ? props.zIndex : defaultZIndex;
}, function (props) {
  return props.zIndex ? props.zIndex : defaultZIndex;
}, function (props) {
  return props.theme.colors.paper;
}, function (props) {
  return props.theme.modal.borderRadius;
}, function (props) {
  return props.theme.colors.header;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return props.theme.header.height;
}, function (props) {
  return props.theme.spacing.spacer * 2;
}, function (props) {
  return props.theme.modal.headerBorderRadius;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return props.theme.spacing.spacer * 2;
}, function (props) {
  return props.theme.spacing.spacer * 2;
}, function (props) {
  return props.theme.colors.paper;
}, function (props) {
  return props.hidden ? Hidden : null;
}, function (props) {
  return props.wide && css(_templateObject3());
});
export default withTheme(ModalStyled);