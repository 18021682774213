import React, { useMemo } from 'react';
import { Observer } from 'mobx-react';
import { Page, AgGrid, RadioGroup, translate, Form, Field, ButtonLoading, FormGroupInline, Select, getPath } from '@logio/common-fe';
import { PageNamesEnum } from '../../../shared/localization/PageNamesEnum';
import { withRouter, RouteComponentProps } from 'react-router';
import {
  SelloutImpactReportDetailPageStore,
  SelloutImpactReportDetailMode,
} from '../../../stores/pages/Reports/SelloutImpactReport/SelloutImpactReportDetailPageStore';
import { StructPropBuilder, CodebookLookupSequence } from '@logio/common-be-fe';
import { PagePathsEnum } from '../../../shared/localization/PagePathsEnum';

function SelloutImpactReportDetailPage(props: RouteComponentProps<{ reportId: string }>) {
  const store = useMemo(() => new SelloutImpactReportDetailPageStore(props.match.params.reportId), [props.match.params.reportId]);

  const modeOptions = useMemo(() => [
    {
      value: SelloutImpactReportDetailMode.ReleaseView,
      label: translate(`SelloutImpactReportDetailMode-${SelloutImpactReportDetailMode.ReleaseView}`),
    },
    {
      value: SelloutImpactReportDetailMode.CategoryView,
      label: translate(`SelloutImpactReportDetailMode-${SelloutImpactReportDetailMode.CategoryView}`),
    },
  ], []);

  const filterDescription = useMemo(() => {
    const builder = new StructPropBuilder();

    return {
      hierarchy: builder.arr(builder.lookup('hierarchy', CodebookLookupSequence.SelloutImpactReportLevels)),
    };
  }, []);

  return (
    <Observer>
      {() => (
        <Page
          titleKey={PageNamesEnum.ReleasesSelloutImpactDetail}
          loadingState={store.loadingState}
          backButtonPath={getPath(PagePathsEnum.ReleasesSelloutImpact)}
        >
          <Form
            descriptions={filterDescription}
            initialValues={{ hierarchy: store.initialHierarchy }}
            onSubmit={store.filter}
          >
            <FormGroupInline>
              <RadioGroup
                options={modeOptions}
                value={store.mode}
                name="mode"
                onChange={store.setMode as any}
                typeButton
              />
              <Field
                name="hierarchy"
                fieldNoLabel
                component={(fieldProps) => {
                  const options = fieldProps.options.options.map(option => ({
                    value: option.value,
                    label: translate(`SelloutImpactReportDetailAggregation-${option.label}`),
                    title: translate(`SelloutImpactReportDetailAggregation-${option.title}`),
                  }));

                  return (
                    <Select
                      {...fieldProps.input}
                      {...fieldProps.options}
                      options={options}
                      onChange={value => {
                        if (value) {
                          value.sort();
                        }
                        return fieldProps.input.onChange(value);
                      }}
                      closeOnSelect={false}
                      multi={true}
                      disabled={store.mode !== SelloutImpactReportDetailMode.CategoryView}
                    />
                  );
                }}
              />
              <ButtonLoading
                isLoading={false}
                disabled={store.mode !== SelloutImpactReportDetailMode.CategoryView}
              >
                {translate('SelloutImpactReport-Filter')}
              </ButtonLoading>
            </FormGroupInline>
          </Form>
          <AgGrid
            key={store.mode}
            rowData={store.reportRowData}
            columnDefs={store.reportColumnDefs}
            gridId={`SelloutImpactReportPageDetail_${store.mode}`}
            quickView
            onGridReady={store.onGridReady}
            treeData={store.mode === SelloutImpactReportDetailMode.CategoryView}
            getDataPath={store.getDataPath}
            autoGroupColumnDef={store.reportAutoGroupColumnDef}
            groupDefaultExpanded={4}
          />
        </Page>
      )}
    </Observer>
  );
}

export default withRouter(SelloutImpactReportDetailPage);
