/**
 * @file Created on Thu Sep 06 2018
 * @author LZe
 */

import {UploadStateEnum, PricingPermissions} from '@logio/common-be-fe';
import {
  AgGrid,
  AgGridWrapper,
  Button,
  FormGroupInline,
  Page,
  translate,
  AgGridStateRendererStates,
  WithPermission,
} from '@logio/common-fe';
import {Observer, observer} from 'mobx-react';
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {DataUploadPageStore} from '../../../stores/pages/DataManagement/DataUpload/DataUploadPageStore';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';

export const dataUploadStateValuesMap = new Map([
  [
    UploadStateEnum.SUCCESS,
    {
      state: AgGridStateRendererStates.SUCCESS,
      value: translate(UploadStateEnum.SUCCESS),
    },
  ],
  [
    UploadStateEnum.UNSET,
    {
      state: AgGridStateRendererStates.WARNING,
      value: translate(UploadStateEnum.UNSET),
    },
  ],
  [
    UploadStateEnum.FAIL,
    {
      state: AgGridStateRendererStates.ERROR,
      value: translate(UploadStateEnum.FAIL),
    },
  ],
]);

/**
 * Data upload index page
 */
@observer
class DataUploadPage extends React.Component<RouteComponentProps<DataUploadPage>> {
  store = new DataUploadPageStore();

  componentWillMount() {
    this.store.load();
  }

  render() {
    return (
      <Page titleKey={PageNamesEnum.DataUpload} loadingState={this.store.loadingState}>
        <Observer>
          {() => (
            <AgGridWrapper>
              <WithPermission
                permissionTypes={[
                  PricingPermissions.DATA_UPLOAD_COMPETITOR_EDIT,
                  PricingPermissions.DATA_UPLOAD_PRICE_SENSITIVITY_EDIT,
                  PricingPermissions.DATA_UPLOAD_FAMILY_EDIT,
                ]}
                some
              >
                <FormGroupInline right>
                  <Button onClick={() => this.props.history.push(translate(PagePathsEnum.DataUploadNew))}>
                    {translate('upload-new-data')}
                  </Button>
                </FormGroupInline>
              </WithPermission>
              <AgGrid
                rowData={this.store.rowData}
                columnDefs={this.store.columnDefs}
                sizeColumnsToFit
                gridId="PricingDataUploadPage"
                quickView
              />
            </AgGridWrapper>
          )}

        </Observer>
      </Page>
    );
  }
}

export default withRouter(DataUploadPage);
