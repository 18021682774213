import _classCallCheck from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/classCallCheck";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
/**
 * @file Created on Mon Dec 31 2018
 * @author Tomáš Leity
 */


import { jsonConversions, WindowFileConverter, WindowFileOverviewDTOConverter } from '@logio/common-be-fe';
import { Inject } from '@logio/typescript-ioc';
import { CONSTANTS } from '../../../constants';
import api from '../../api';
import logger from '../../logger';
export var WindowFileLayer = function WindowFileLayer() {
  var _this = this;

  _classCallCheck(this, WindowFileLayer);

  /**
   * Fetches all WindowFile documents
   * @param windowId
   */
  this.findAll = function (windowId) {
    return api(CONSTANTS.API.ENDPOINTS.WINDOW_FILES_LIST(windowId)).get({
      responseType: 'json'
    }).then(function (response) {
      var windowFiles = [];
      (response.data.result || []).map(function (file) {
        windowFiles.push(_this.fileDTOConverter.toObject(file, jsonConversions));
      });
      return windowFiles;
    }).catch(function (error) {
      logger.error(error);
      return Promise.reject(error);
    });
  };
  /**
   * Upload WindowFile document
   * @param data
   */


  this.upload = function (data) {
    return api(CONSTANTS.API.ENDPOINTS.WINDOW_FILES).post(data, {
      responseType: 'json'
    }).then(function (response) {
      return _this.fileDTOConverter.toObject(response.data.result, jsonConversions);
    }).catch(function (error) {
      logger.error(error);
      return Promise.reject(error);
    });
  };
  /**
   * Download WindowFile document
   * @param fileId
   * @returns Blob with file
   */


  this.download = function (fileId) {
    return api(CONSTANTS.API.ENDPOINTS.WINDOW_FILES_ITEM(fileId)).get({
      responseType: 'blob'
    }).then(function (response) {
      return Promise.resolve(response);
    }).catch(function (error) {
      logger.error(error);
      return Promise.reject(error);
    });
  };
  /**
   * Delete WindowFile document
   * @param fileId
   */


  this.delete = function (fileId) {
    return api(CONSTANTS.API.ENDPOINTS.WINDOW_FILES_ITEM(fileId)).delete({
      responseType: 'json'
    }).then(function (response) {
      return Promise.resolve(response.data);
    }).catch(function (error) {
      logger.error(error);
      return Promise.reject(error);
    });
  };
};

__decorate([Inject, __metadata("design:type", WindowFileConverter)], WindowFileLayer.prototype, "converter", void 0);

__decorate([Inject, __metadata("design:type", WindowFileOverviewDTOConverter)], WindowFileLayer.prototype, "fileDTOConverter", void 0);