/**
 * @file Created on Fri Aug 17 2018
 * @author SKu
 */

import {
  DataDesc,
  PricingPermissions,
  ProductCategory,
  StructPropBuilder,
  Utils,
  Release,
  PriceZone,
  AbstractRelease,
  ReleaseType,
  SelloutRelease,
} from '@logio/common-be-fe';
import {
  ActionProps,
  ActionsGenerator,
  AgGridInheritanceRenderer,
  ColumnDefinition,
  ColumnGenerator,
  CopyCategoryData,
  CopyZonesData,
  getPath,
  IconType,
  PriceZoneStore,
  rootStore,
  SettingsOverviewDTOStore,
  SettingsStore,
  StoreName,
  StringMapping,
  translate,
  withPermission,
} from '@logio/common-fe';
import {GridApi, GridReadyEvent, RowNode, SelectionChangedEvent} from 'ag-grid-community';
import {AxiosError} from 'axios';
import {action, computed, observable, runInAction} from 'mobx';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {CategoryTraverseHelper} from '../CategoryTraverseHelper';

export enum CopySettingsModalEnum {
  CopyBetweenZones = 'COPY_BETWEEN_ZONES',
  CopyFromCategory = 'COPY_FROM_CATEGORY',
}

export class SettingsComponentStore {
  /** @param setError function from PageStore, that allows set ErrorMessages */
  constructor(public setError: (error: AxiosError | string) => void) {}

  priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;
  settingsOverviewDTOStore = rootStore.getStore(StoreName.SettingsOverviewDTOStore) as SettingsOverviewDTOStore;
  settingsStore = rootStore.getStore(StoreName.SettingsStore) as SettingsStore;

  /** Used only for Release-settings page  */
  release: AbstractRelease;

  selloutRelease: SelloutRelease;

  /** Ag-grid api */
  @observable
  gridApi: GridApi;

  /** Categories that have been selected in Grid */
  @observable
  selectedCategoryIds: string[] = [];

  /** Variable used for opening and closing modals */
  @observable
  modalShown: CopySettingsModalEnum;

  /** Derived columns data description builder */
  builder = new StructPropBuilder('Settings');

  /** Derived columns data description */
  description: DataDesc = {
    categoryId: this.builder.str('categoryId'),
    includedInRelease: this.builder.str('includedInRelease'),
  };

  /** Data generators */
  actionsGenerator = new ActionsGenerator();
  columnGenerator: ColumnGenerator<StringMapping<any>>;

  /** Sets Ag-Grid api */
  @action.bound
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  /** Checks if modal should be hidden. */
  isModalHidden = (name: CopySettingsModalEnum) => this.modalShown !== name;

  /** Returns priceZones used for current settings(all not archived | release priceZones) */
  get priceZoneIds(): string[] {
    return this.release
      ? Release.requireRegularRelease(this.release).priceZoneIds.toArray()
      : Array.from(this.priceZoneStore.listNotArchived.values()).map((zone: PriceZone) => zone.id);
  }

  /**
   * Returns generated column definitions for ag-grid
   */
  @computed
  get columnDefs(): ColumnDefinition[] {
    const colDefs: ColumnDefinition[] = [this.actionsGenerator.getColumnDefinition({headerName: ''})];

    /** Generate array of all zones in column definition format */
    const getCustomColDefs = (): ColumnDefinition[] => {
      const customColDefs: ColumnDefinition[] = [
        {
          field: 'includedInRelease',
          cellClassRules: {
            /** Yes values should have green cell background */
            'bg-scale2': ({value}) => value && value.startsWith('Yes'),
            /** Not all values should have orange cell background */
            'bg-warning': ({value}) => value && value.startsWith('Not All'),
          },
          sortable: false,
          /** In case if it is not release settings, we dont need this column */
          dontCreateColumn: Utils.isValueMissing(this.release),
        },
      ];

      if (!this.release || (this.release && this.release.releaseType !== ReleaseType.Sellout)) {
        this.priceZoneIds.forEach((zoneId) => {
          const zone = this.priceZoneStore.list.get(zoneId);
          customColDefs.push({
            headerName: zone.name,
            field: zone.id,
            cellRendererFramework: AgGridInheritanceRenderer,
            width: 120,
            suppressMenu: true,
            sortable: false,
            cellClass: 'ag-cell-align-center',
          });
        });
      } else if (this.selloutRelease) {
        customColDefs.push(
          {field: 'itemCount'},
          {field: 'productCount', dontCreateColumn: this.release.releaseType !== ReleaseType.Sellout},
          {field: 'siteCount', dontCreateColumn: this.release.releaseType !== ReleaseType.Sellout},
        );
      }
      return customColDefs;
    };

    if (this.columnGenerator) {
      colDefs.push(...this.columnGenerator.getColumnDefinitions(getCustomColDefs()));
    }

    return colDefs;
  }

  /** Checkout {@link CategoryTraverseHelper} constructor for detailed info */
  private getDataPerRow = ({id: categoryId}: ProductCategory) => {
    if (!this.columnGenerator || !categoryId || !this.settingsOverviewDTOStore.overview.has(categoryId)) {
      return {};
    }
    const {settingsZonesOverviewDTOs, includedStats} = this.settingsOverviewDTOStore.overview.get(categoryId);
    /** Sets inheritance */
    const inherit: StringMapping<any> = {};
    /** Gets array of all zones in key - value pairs for generating of row */
    if (!this.release || (this.release && this.release.releaseType !== ReleaseType.Sellout)) {
      settingsZonesOverviewDTOs.forEach(({zoneId, isInherited}) => {
        inherit[zoneId] = isInherited;
      });
    }
    return this.columnGenerator.getColumnData({
      categoryId,
      includedInRelease: this.getCategoryState(includedStats),
      ...inherit,
      itemCount: includedStats.itemCount,
      productCount: includedStats.productsCount,
      siteCount: includedStats.sitesCount,
    });
  };

  /** Function defines if category has been already included to release */
  private getCategoryState = ({childrenStats, inPathToIncluded, includedInRelease}) => {
    /** lowest categories */
    if (childrenStats.childrenCount === 0) {
      return includedInRelease ? translate('yes') : translate('no');
    }
    /** if all children been included */
    if (childrenStats.yesAll()) {
      return translate('yes-all');
    }
    /** if at least one of the all children has been included */
    if (inPathToIncluded && childrenStats.includedChildrenCount === 0) {
      return translate('not-all-parent');
    }
    /** if at least one of the lower category children has been included */
    if (childrenStats.notAll()) {
      return translate(
        'not-all',
        childrenStats.childrenCount.toString(),
        childrenStats.includedChildrenCount.toString(),
      );
    }
    /** Default - NO */
    return translate('no');
  };

  /** If user dont have permission to edit matrix table, edit actions shouldn't be pushed */
  private getActionsPerRow = (category: ProductCategory): ActionProps[] =>
    (this.release && this.release.releaseType !== ReleaseType.Sellout && this.isMatrixTableEditPermitted()) ||
    (!this.release && this.isMatrixTableEditPermitted())
      ? [
          {
            name: 'edit',
            icon: IconType.edit,
            linkProps: {
              to: this.release
                ? getPath(PagePathsEnum.ReleaseMatrixTable, category.id, this.release.id)
                : getPath(PagePathsEnum.GeneralSettingsMatrixTable, category.id),
            },
          },
        ]
      : [];

  /** Store handling ag-grid tree view */
  public CategoryTraverseHelper = new CategoryTraverseHelper(this.getDataPerRow, this.getActionsPerRow, true);

  /**
   * Sets selected categories to the global var
   * to use it in group action modals
   * @param event Ag-grid callback
   */
  setSelectedCategories = (event: SelectionChangedEvent): void => {
    const selectedCategoryIds = event.api.getSelectedNodes().map((node: RowNode) => node.data[`Settings_categoryId`]);
    runInAction(() => (this.selectedCategoryIds = selectedCategoryIds));
  };

  /**
   * Filters which row on ag-grid is selectable
   * @param node Ag-grid
   */
  public isRowSelectable = (node: any) => {
    return node.data
      ? this.selloutRelease
        ? !!node.data.Settings_itemCount ||
          (node.data.Settings_includedInRelease && node.data.Settings_includedInRelease.startsWith('Yes'))
        : true
      : false;
  };

  /**
   * Control if user have access to edit matrix table
   * depends if it release settings or general settings pages
   */
  isMatrixTableEditPermitted = () =>
    (!Utils.isValueMissing(this.release) &&
      withPermission([PricingPermissions.REGULAR_RELEASE_SETTINGS_DETAIL_VIEW])) ||
    (Utils.isValueMissing(this.release) && withPermission([PricingPermissions.MATRIX_TABLE_SETTINGS_VIEW]));

  /**
   * Each time we have different PriceZones,
   * that's why we should dynamically create columns for each PriceZone
   */
  createDataDescForEachZone = () => {
    this.priceZoneIds.forEach((zoneId: string) => (this.description[zoneId] = this.builder.str(zoneId)));
    this.columnGenerator = new ColumnGenerator<StringMapping<any>>(this.description);
  };

  loadSellout = async (release?: SelloutRelease): Promise<void> => {
    this.selloutRelease = release;
    this.release = release;
    try {
      this.columnGenerator = new ColumnGenerator<StringMapping<any>>({
        ...this.description,
        itemCount: this.builder.num('itemCount'),
        productCount: this.builder.num('productCount'),
        siteCount: this.builder.num('siteCount'),
      });
      await Promise.all([
        this.CategoryTraverseHelper.load(),
        this.settingsOverviewDTOStore
          .getSelloutOverview(
            this.selloutRelease && this.selloutRelease.id,
            this.selloutRelease && this.selloutRelease.itemFilter.hashCode().toString(),
          )
          .catch((error) => {
            return this.settingsOverviewDTOStore.computeSelloutOverview(release.id, release.itemFilter);
          }),
      ]);
    } catch (error) {
      this.setError(error);
    }
  };

  /** Fetches data for the page */
  load = async (release?: AbstractRelease): Promise<void> => {
    this.release = release;
    try {
      if (this.release && this.release.releaseType === ReleaseType.Sellout) {
        this.columnGenerator = new ColumnGenerator<StringMapping<any>>({
          ...this.description,
          itemCount: this.builder.num('itemCount'),
        });
        await Promise.all([
          this.CategoryTraverseHelper.load(),
          this.settingsOverviewDTOStore.getSelloutOverview(this.release && this.release.id),
        ]);
      } else {
        await Promise.all([
          this.CategoryTraverseHelper.load(),
          this.priceZoneStore.getAll(),
          this.settingsOverviewDTOStore.getOverview(this.release && this.release.id),
        ]);
        this.createDataDescForEachZone();
      }
    } catch (error) {
      this.setError(error);
    }
  };

  /**
   * Function fired on CopyBetweenZonesForm submit,
   * used for coping settings from one zone to others
   * @param fromCategory - callback from Form
   */
  onCopySettingsSubmit = (fromCategory: boolean) => async (values: any) => {
    const copyZonesData: CopyZonesData = {...values, ...{categoryIds: this.selectedCategoryIds}};
    const copyCategoryData: CopyCategoryData = {...values, ...{targetCategoryIds: this.selectedCategoryIds}};
    try {
      fromCategory
        ? await this.settingsStore.copyFromCategory(copyCategoryData)
        : await this.settingsStore.copyBetweenZones(copyZonesData);

      await this.settingsOverviewDTOStore.getOverview(this.release && this.release.id);
      /** TODO: Add setSuccess */
    } catch (error) {
      this.setError(error);
    }
    this.hideModal();
  };

  /**
   * Negate modalHiddenProp
   * @param modalType - name of the modalHidden prop that should be negated
   */
  @action
  openModal = (modalType: CopySettingsModalEnum) => {
    this.modalShown = modalType;
  };

  /**
   * HOF for toggle modal
   * used in Tiers page when u get event besides parameter
   */
  getOpenModalEvent = (modalTypes: CopySettingsModalEnum) => () => {
    this.openModal(modalTypes);
  };

  /**
   * Function used in case if endpoint return error
   */
  @action
  hideModal = () => {
    this.modalShown = undefined;
  };
}
