/**
 * @file Created on Wed Feb 27 2019
 * @author Tomáš Leity
 */
import * as React from 'react';
/**
 * Render bold styled value
 */

export var BoldRenderer = function BoldRenderer(_ref) {
  var value = _ref.value;
  return value ? React.createElement("strong", null, value) : null;
};