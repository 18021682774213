/**
 * @file Data cleaning page
 * @author Atreo
 */

import * as React from 'react';
import {
  Modal,
  translate,
  Messages,
  Loader,
  LoadingState,
  IconType,
  IconColor,
  Icon,
  N,
  CONSTANTS,
  AgGridWrapper,
  AgGrid,
  Key,
} from '@logio/common-fe';
import {DataCleaningProductsPageStore} from 'stores/pages/DataManagement/DataCleaning/DataCleaningProductsPageStore';
import {Row, Col} from 'react-flexbox-grid';
import {observer} from 'mobx-react';
import { BigDecimal } from '@logio/big-decimal';

@observer
class PriceHistoryModal extends React.Component<any> {

  store: DataCleaningProductsPageStore;

  constructor(props) {
    super(props);
    this.store = this.props.store;
  }

  onKeyDownNavigation = async (e: KeyboardEvent) => {
    if (!this.store.priceHistoryModalStore.modalHidden && e.shiftKey && e.key === Key.LeftArrow) {
      e.stopPropagation();
      e.preventDefault();
      await this.prevProduct();
    } else if (!this.store.priceHistoryModalStore.modalHidden && e.shiftKey && e.key === Key.RightArrow) {
      e.stopPropagation();
      e.preventDefault();
      await this.nextProduct();
    }
  };

  prevProduct = async () => {
    if (this.store.priceHistoryModalStore.hasPrev()) {
      await this.store.priceHistoryModalStore.prevProductNavigation();
    }
  };

  nextProduct = async () => {
    if (this.store.priceHistoryModalStore.hasNext()) {
      await this.store.priceHistoryModalStore.nextProductNavigation();
    }
  };

  
  getInternalRegularPrice() {
    const { productId, zoneId } = this.store.priceHistoryModalStore;
    const pricesDTO = this.store.internalProductPricesStore.list.get(productId);

    if (!pricesDTO) {
      return null;
    }

    const priceForZone = pricesDTO.prices.find(price => price.priceZoneId === zoneId);

    return priceForZone ? priceForZone.actualRegularPrice : null;
  }

  renderTitle() {
    const { zoneId } = this.store.priceHistoryModalStore;
    const price = this.getInternalRegularPrice();
    const zone = this.store.priceZoneStore.list.get(zoneId);

    return (
      <h2 className="tac">
        {price ? translate('competitor_products_in_family-internal_price', zone ? zone.name : '', price.round(1).toString().replace('.', ',')) : null}
        {' '}
        {this.store.productStore.list.get(this.store.priceHistoryModalStore.productId).name}
        {this.store.priceHistoryModalStore.isNewCRP ?
          (
            <> ({translate(('new-CRP'))} </>
          ) : (
            <> ({translate(('CRP'))} </>
          )
        }
        {this.store.priceHistoryModalStore.getCRPOrNewCRPValue() ?
          (
            <>- <N value={this.store.priceHistoryModalStore.getCRPOrNewCRPValue()} round/>)</>
          ) : (
            `${CONSTANTS.FORMAT.NULL})`
          )
        }
      </h2>
    );
  }

  render() {
    return (
      <>
        {!this.store.priceHistoryModalStore.modalHidden && (
          <Modal
            handleKeyUp={this.onKeyDownNavigation}
            title={translate(
              'competitor_products_in_family-title',
              this.store.priceZoneStore.list.get(this.store.priceHistoryModalStore.zoneId).name,
              this.store.competitors.list.get(this.store.priceHistoryModalStore.competitorId).name,
              this.store.priceHistoryModalStore.dataCleaningProductsPageGridApi.getDisplayedRowCount().toString(),
            )}
            hidden={false}
            close={this.store.priceHistoryModalStore.closeModal}
            wide
            zIndex={10000}
          >
            <div className="relative minh30">
              <Messages/>
              {this.store.priceHistoryModalStore.loadingState === LoadingState.Pending ? (
                <Loader/>
              ) : (
                <div>
                  <Row>
                    <Col md={2}>
                      <Icon
                        iconType={IconType.arrowLeft}
                        iconColor={IconColor.Secondary}
                        onClick={this.prevProduct}
                        disabled={!this.store.priceHistoryModalStore.hasPrev()}
                      />
                    </Col>
                    <Col md={8}>
                      {this.renderTitle()}
                    </Col>
                    <Col md={2} className="tar">
                      <Icon
                        iconType={IconType.arrowRight}
                        iconColor={IconColor.Secondary}
                        onClick={this.nextProduct}
                        disabled={!this.store.priceHistoryModalStore.hasNext()}
                      />
                    </Col>
                  </Row>
                  <AgGridWrapper>
                    <AgGrid
                      rowData={this.store.priceHistoryModalStore.rowData}
                      columnDefs={this.store.priceHistoryModalStore.columnDefs}
                      onGridReady={this.store.priceHistoryModalStore.onGridReady}
                      gridId="PricingPriceHistoryModal"
                      enterMovesDownAfterEdit
                      quickView
                    />
                  </AgGridWrapper>
                </div>
              )}
            </div>
          </Modal>
        )}
      </>
    );
  }
}

export default PriceHistoryModal;
