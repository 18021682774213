/**
 * @file Created on Tue Aug 28 2018
 * @author VBr
 * @author LZe
 */

import {AgGrid, AgGridWrapper, BoxTabContainer, Messages, Page, Search, translate, getPath} from '@logio/common-fe';
import {observer, Observer} from 'mobx-react';
import * as React from 'react';
import {Col, Row} from 'react-flexbox-grid';
import {match} from 'react-router';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {IRouteParams} from '../../../routes-app';
import {DataPairingPairedPageStore} from '../../../stores/pages/DataManagement/DataPairing/DataPairingPairedPageStore';
import Tabs from './Tabs';
import {packageSizeHelper} from '../../../shared/packageSizeHelper';
import {parse} from 'querystring';
import {Location} from 'history';

export interface Props {
  match: match<IRouteParams>;
  location: Location;
}
@observer
class DataPairingPairedPage extends React.Component<Props> {
  store = new DataPairingPairedPageStore(
    this.props.match.params.id,
    parse(this.props.location.search.slice(1)).internalQuery,
  );

  componentWillMount() {
    this.store.load();
  }

  render() {
    return (
      <Page
        titleKey={PageNamesEnum.DataPairingProducts}
        titleParams={[
          this.store.categoryStore.list.get(this.store.categoryId)
            ? this.store.categoryStore.list.get(this.store.categoryId).name
            : '',
        ]}
        loadingState={this.store.loadingState}
        showMessages={false}
        backButtonPath={getPath(PagePathsEnum.DataPairing)}
      >
        <Observer>
          {() => (
            <AgGridWrapper>
              <Tabs id={this.props.match.params.id} />
              <BoxTabContainer>
                <AgGridWrapper>
                  <Messages />
                  <Row>
                    <Col xs={10} md={4} className="mb1">
                      <Search
                        disabled={this.store.lockInputs}
                        placeholder={`${translate('Search internal products')}...`}
                        value={this.store.internalQuery}
                        onChange={this.store.onFilter}
                        onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                            this.store.reload();
                            const target = event.currentTarget;
                            setTimeout(() => {
                              target.focus();
                            }, 500);
                          }
                        }}
                        onReset={() => {
                          if (this.store.lockInputs) {
                            return;
                          }
                          this.store.resetFilter();
                        }}
                      />
                    </Col>
                  </Row>
                  <AgGrid
                    rowData={this.store.rowData}
                    columnDefs={this.store.columnDefs}
                    onGridReady={this.store.onGridReady}
                    deltaRowDataMode={true}
                    getRowNodeId={({id, CompetitorProduct_id: cId}) => `${id}_${cId}`}
                    rowClassRules={this.store.rowClassRules}
                    onColumnVisible={packageSizeHelper.onColumnVisible}
                    gridId="PricingDataPairingPairedPage"
                    quickView
                  />
                </AgGridWrapper>
              </BoxTabContainer>
            </AgGridWrapper>
          )}
        </Observer>
      </Page>
    );
  }
}

export default DataPairingPairedPage;
