/**
 * @author VBr
 * @author SKu
 */

import {
  CleaningSettings,
  DataDesc,
  FamilySettings,
  GlobalSettings,
  OutlierPriceDetectionSettings,
  PromoPriceDetectionSettings,
  PricingPermissions,
} from '@logio/common-be-fe';
import {Button, Field, Form, Icon, IconColor, IconType, Page, Tooltip, translate} from '@logio/common-fe';
import {Observer, observer} from 'mobx-react';
import * as React from 'react';
import {Col, Row} from 'react-flexbox-grid';
import {PageNamesEnum} from '../../shared/localization/PageNamesEnum';
import {ConfigurationsSettingsPageStore} from '../../stores/pages/Settings/ConfigurationsSettingsPageStore';

@observer
class ConfigurationsSettingsSettingsPage extends React.Component {
  store = new ConfigurationsSettingsPageStore();

  componentWillMount() {
    this.store.load();
  }

  get schema(): DataDesc {
    return {
      outlierHistoryLength: OutlierPriceDetectionSettings.schema.outlierHistoryLength,
      minPriceLength: PromoPriceDetectionSettings.schema.minPriceLength,
      maxPriceLength: PromoPriceDetectionSettings.schema.maxPriceLength,
      promoLimit: PromoPriceDetectionSettings.schema.promoLimit,
      outlierLimit: OutlierPriceDetectionSettings.schema.outlierLimit,
      minPriceDecrease: PromoPriceDetectionSettings.schema.minPriceDecrease,
      minPriceIncrease: PromoPriceDetectionSettings.schema.minPriceIncrease,
      referencePriceChangeIndex: CleaningSettings.schema.referencePriceChangeIndex,
      competitorReferencePriceChangeIndex: CleaningSettings.schema.competitorReferencePriceChangeIndex,
      internalVsCompetitorReferencePriceChangeIndex:
        CleaningSettings.schema.internalVsCompetitorReferencePriceChangeIndex,
      extremePriceChangeIndex: CleaningSettings.schema.extremePriceChangeIndex,
      impactHistoryLength: GlobalSettings.schema.impactHistoryLength,
      finalPriceDefault: GlobalSettings.schema.finalPriceDefault,
      sensitivityDefaultId: GlobalSettings.schema.sensitivityDefaultId,
      missingCompetitorPrice: GlobalSettings.schema.missingCompetitorPrice,
      agencyResearchWeight: CleaningSettings.schema.agencyResearchWeight,
      webscrapingWeight: CleaningSettings.schema.webscrapingWeight,
      leaderMaxHistoryLength: FamilySettings.schema.leaderMaxHistoryLength,
      leaderFrequencyRecalculation: FamilySettings.schema.leaderFrequencyRecalculation,
    };
  }

  render() {
    return (
      <Page titleKey={PageNamesEnum.ConfigurationsSettings} loadingState={this.store.loadingState}>
        <Observer>
          {() => (
            <Row>
              <Col xs>
                <Form
                  descriptions={this.schema}
                  onSubmit={this.store.update}
                  initialValues={this.store.initialValues}
                  formLayoutVertical={true}
                >
                  <table className="table w-100">
                    <thead>
                      <tr>
                        <th>{translate('GlobalSettings_section')}</th>
                        <th>{translate('GlobalSettings_term')}</th>
                        <th>{translate('GlobalSettings_value')}</th>
                        <th>{translate('GlobalSettings_unit')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.renderRows('familySettings', ['leaderMaxHistoryLength', 'leaderFrequencyRecalculation'])}
                      {this.renderRows('cleaningSettings', [
                        'outlierHistoryLength',
                        'minPriceLength',
                        'maxPriceLength',
                        'promoLimit',
                        'outlierLimit',
                        'minPriceDecrease',
                        'minPriceIncrease',
                        'competitorReferencePriceChangeIndex',
                        'internalVsCompetitorReferencePriceChangeIndex',
                        'impactHistoryLength',
                      ])}
                      {this.renderRows('priceSensitivity', ['sensitivityDefaultId'])}
                      {this.renderRows('reports', ['missingCompetitorPrice'])}
                      {this.renderRows('release', ['referencePriceChangeIndex', 'extremePriceChangeIndex'])}
                      {this.renderRows('finalPrice', ['finalPriceDefault'])}
                      {this.renderRows('competitorReferencePrice', ['agencyResearchWeight', 'webscrapingWeight'])}
                    </tbody>
                  </table>
                  <Button type="submit">{translate('GlobalSettings_saveConfiguration')}</Button>
                </Form>
              </Col>
            </Row>
          )}
        </Observer>
      </Page>
    );
  }

  renderRows = (section: string, fields: string[]) => {
    return fields.map((field) => {
      const fieldDescription = this.schema[field].description;
      return (
        <tr key={fieldDescription.nameKey}>
          <td>{translate(`general-configurations-${section}`)}</td>
          <td>
            <div className="flex">
              <span className="pr05">{translate(fieldDescription.nameKey)}</span>
              <Tooltip overlay={translate(`${fieldDescription.nameKey}_description`)} placement="right">
                <span>
                  <Icon iconType={IconType.info} iconColor={IconColor.Primary} iconHeight={16} />
                </span>
              </Tooltip>
            </div>
          </td>
          <td>
            <Field
              name={field}
              fieldNoLabel
              fieldReadOnly={
                !this.store.keycloakStore.userHasPermissions([PricingPermissions.CONFIGURATIONS_MANAGE_EDIT])
              }
            />
          </td>
          <td>{translate(`${fieldDescription.nameKey}_unit`)}</td>
        </tr>
      );
    });
  };
}

export default ConfigurationsSettingsSettingsPage;
