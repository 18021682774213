/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
import DividerStyled from './styled/DividerStyled';
import * as React from 'react';

var Divider = function Divider(props) {
  return React.createElement(DividerStyled, Object.assign({}, props));
};

export default Divider;