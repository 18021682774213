"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
}); // import { KpiDataStructureEnum } from '@logio/common-be-fe';

var KpiDataStructureEnum_1 = require("../../kpi/KpiDataStructureEnum");

exports.SALES_DASHBOARD_NON_DELTA_KPI_FIELDS = [KpiDataStructureEnum_1.KpiDataStructureEnum.PromoShareRealRel, KpiDataStructureEnum_1.KpiDataStructureEnum.GmAndBackDiscountRealRel, KpiDataStructureEnum_1.KpiDataStructureEnum.AngNumberOfArticlesReal];
exports.SALES_DASHBOARD_KPI_FIELDS = [// Sales
KpiDataStructureEnum_1.KpiDataStructureEnum.PromoSalesReal, // Volumes
KpiDataStructureEnum_1.KpiDataStructureEnum.VolumeReal, // GM
KpiDataStructureEnum_1.KpiDataStructureEnum.GrossMarginReal, KpiDataStructureEnum_1.KpiDataStructureEnum.GrossMarginRealRel, // BM2
KpiDataStructureEnum_1.KpiDataStructureEnum.BMProxyReal, KpiDataStructureEnum_1.KpiDataStructureEnum.BMProxyRealRel, // VA
KpiDataStructureEnum_1.KpiDataStructureEnum.VendorAllowanceReal, // Back Discount
KpiDataStructureEnum_1.KpiDataStructureEnum.BackDiscountReal, // Promo share[%]
KpiDataStructureEnum_1.KpiDataStructureEnum.PromoShareRealRel, // GM + Back discount [%]
KpiDataStructureEnum_1.KpiDataStructureEnum.GmAndBackDiscountRealRel, // Number of promo articles
KpiDataStructureEnum_1.KpiDataStructureEnum.NumberOfPromoArticlesReal, // Avg number of articles in promo
KpiDataStructureEnum_1.KpiDataStructureEnum.AngNumberOfArticlesReal];