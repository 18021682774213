/**
 * Renders Checkbox of isAllExported value and next to it exportedCount value / itemCount value
 * @file Created on Thu Nov 21 2019
 * @author TSv
 */
import { Utils } from "@logio/common-be-fe";
import * as React from 'react';
/**
 * Renders Arrow near the number
 */

export var ReleaseSelloutExportRenderer = function ReleaseSelloutExportRenderer(props) {
  var colDef = props.column.getColDef();

  var toggleValue = function toggleValue() {
    if (Utils.isValueMissing(colDef.editable) || props.column.isCellEditable(props.node)) {
      if (!props.node.aggData && !props.node.allChildrenCount) {
        if (colDef.action) {
          var originalExportedCount = props.value.exportedCount;
          var exportedCount = props.value.isAllExported ? 0 : props.value.itemCount;
          props.node.setDataValue(props.columnApi.getColumn("SelloutImpactRecord_priceChangesCount").getId(), exportedCount);
          props.node.setDataValue(props.column.getId(), Object.assign(Object.assign({}, props.value), {
            isAllExported: !props.value.isAllExported,
            exportedCount: exportedCount
          }));
          colDef.action(props.node.id, props.column.getId(), Object.assign(Object.assign({}, props.value), {
            isAllExported: !props.value.isAllExported,
            exportedCount: exportedCount
          })).then(function () {
            props.context.store.setSelectedRows(props.api.getSelectedNodes());
          }).catch(function () {
            props.node.setDataValue(props.column.getId(), Object.assign(Object.assign({}, props.value), {
              isAllExported: props.value.isAllExported,
              exportedCount: originalExportedCount
            }));
            props.context.store.setSelectedRows(props.api.getSelectedNodes());
          });
        }
      }
    }
  };

  return React.createElement("div", null, React.createElement("span", {
    onClick: toggleValue,
    className: "ag-icon ag-icon-checkbox-".concat(props.value.isAllExported === true ? 'checked' : props.value.isAllExported === false ? 'unchecked' : '')
  }), React.createElement("span", {
    style: {
      float: "right"
    }
  }, React.createElement("span", null, props.value.exportedCount), React.createElement("span", null, " / "), React.createElement("span", null, props.value.itemCount)));
};