/**
 * @file Created on Mon Aug 13 2018
 * @author PKl, SKu
 */
import { Utils } from '@logio/common-be-fe';
import * as React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { CONSTANTS } from '../../../constants';
import { getClassIf } from '../../../shared/rendering/getClassIf';
import Icon, { IconColor, IconType } from '../../media/Icon';
import NoticeStyled from './styled/NoticeStyled';
/**
 * Notice
 * @component
 */

var Notice = function Notice(props) {
  var validateComponentUsage = function validateComponentUsage() {
    if (Utils.isValueMissing(props.content) || props.content.length === 0) {
      throw new Error('Notice content is missing!');
    }

    if (Utils.isValueMissing(props.contentSizes) || props.contentSizes.length === 0) {
      throw new Error('Notice contentSizes is missing!');
    }

    if (props.content.length !== props.content.length) {
      throw new Error('Notice content & contentSizes lengths are different!');
    } // Flexbox columns sizes should be 12 in sum!


    if (props.contentSizes.reduce(function (a, b) {
      return a + b;
    }, 0) !== 12) {
      throw new Error('Sum of all contentSizes should be 12!');
    }
  };

  validateComponentUsage();
  return React.createElement(NoticeStyled, Object.assign({
    className: props.className,
    noticeDisabled: props.noticeDisabled
  }, getClassIf(props.noticeIconType, 'notice-with-icon', '')), !Utils.isValueMissing(props.noticeIconType) && React.createElement("div", {
    className: "notice-icon"
  }, React.createElement(Icon, {
    iconType: props.noticeIconType,
    iconHeight: 25,
    iconColor: IconColor.Text,
    onClick: props.onIconClick
  })), props.content && React.createElement(Row, null, props.content.map(function (content, i) {
    return React.createElement(Col, {
      xs: props.contentSizes[i],
      key: i
    }, React.createElement(React.Fragment, null, " ", content || CONSTANTS.FORMAT.NULL));
  })), props.onClose && React.createElement("div", {
    className: "notice-close"
  }, React.createElement(Icon, {
    iconType: IconType.cancelO,
    iconHeight: 15,
    onClick: props.onClose
  })));
};

export default Notice;