"use strict";
/*
 * Created on 02 01 2019
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author Jaroslav Sevcik
 */

var ProductCodebookTitle;

(function (ProductCodebookTitle) {
  ProductCodebookTitle["NameWithExternalId"] = "NAME_WITH_EXTERNAL_ID";
})(ProductCodebookTitle = exports.ProductCodebookTitle || (exports.ProductCodebookTitle = {}));