import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n\n  + .input-wrapper {\n    margin-top: 5px;\n  }\n  \n  .input-error .Select-control {\n    border: 1px solid ", ";\n  }\n\n  .input-error svg {\n    fill: ", ";\n  }\n\n  ", ";\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  .input-icon {\n    top: 5px;\n\n    svg {\n      height: 15px;\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  .Select-control .Select-arrow-zone {\n    /* display: none; */\n  }\n\n  .Select-clear-zone {\n    right: 30px;\n  }\n\n  .input-icon {\n    position: absolute;\n    top: 8px;\n    right: 10px;\n    cursor: pointer;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Fri Aug 17 2018
 * @author PKl
 */
import styled, { css, withTheme } from 'styled-components';
var SelectWithIcon = css(_templateObject());
var SelectTinyWithIcon = css(_templateObject2());
/**
 * Create an wrapper for Select
 */

var SelectWrapper = styled.div(_templateObject3(), function (props) {
  return props.theme.state.danger.color;
}, function (props) {
  return props.theme.state.danger.color;
}, function (props) {
  return props.withIconIn ? SelectWithIcon : null;
}, function (props) {
  return props.tiny && props.withIconIn ? SelectTinyWithIcon : null;
});
export default withTheme(SelectWrapper);