/**
 * @file Created on Thu Sep 06 2018
 * @author SKu
 */

import {AbstractRelease, DataDesc, ReleaseType} from '@logio/common-be-fe';
import {
  CONSTANTS,
  CustomDefinition,
  MessageStore,
  ReleaseSelloutStore,
  AbstractReleaseStore,
  LoadingState,
  translate,
  rootStore,
  StoreName,
  ProductStore,
  PriceZoneStore,
  DownloadStore,
} from '@logio/common-fe';
import {computed, observable, runInAction, action} from 'mobx';
import {PollingHelper} from 'stores/components/PollingHelper';

export abstract class AbstractReleaseHeaderStore {
  /** Stores */
  public abstract releaseStore: AbstractReleaseStore;
  private productStore = rootStore.getStore(StoreName.Product) as ProductStore;
  public priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;
  public downloadStore = rootStore.getStore(StoreName.Download) as DownloadStore;

  /**
   * @param setError function from PageStore, that allows set ErrorMessages
   * @param onHeaderUpdated Function that will be called after header update
   * @param path page path
   * @param recalculate Function thah fired recalculation polling helper
   */
  constructor(
    protected messages: MessageStore,
    protected onHeaderUpdated?: () => void,
    protected path?: string,
    protected recalculate?: (progressMonitor: string) => void,
  ) {}

  /** Variable defines edit header modal state */
  @observable
  modalHidden: boolean = true;

  /** Current release */
  public abstract get release(): AbstractRelease;

  /**
   * Creates definition for custom field rendering
   */
  @computed
  public get customDefinition(): CustomDefinition {
    const customDefinition = {
      path: `${CONSTANTS.API.ENDPOINTS.RELEASES}${CONSTANTS.API.ENDPOINTS.RELEASE_EDIT_HEADER}`,
      params: {
        id: this.release.id,
        releaseType: this.release.releaseType,
        workflowType: this.release.workflowType,
      },
    };
    return customDefinition;
  }

  /** Description for Release header Edit Form */
  public abstract get descriptions(): DataDesc;

  /**
   *  Fires on form submit, updates release entity
   * @param release
   */
  public abstract async onEditHeaderSubmit(release: AbstractRelease): Promise<void>;

  /** Sets if release should be favorite */
  public setReleaseFavorability = async (): Promise<void> => {
    try {
      await this.releaseStore.setReleaseFavorability(this.release.id, !this.release.favourite);
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  /**
   * Fetches all data for the page
   * @param id releaseId
   */
  public load = async (id: string): Promise<AbstractRelease> => {
    try {
      await this.priceZoneStore.getAll();
      const release = await this.releaseStore.getOne(id);
      this.messages.setValidationResult(release.validationResult);
      return release;
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  /**
   * Opens edit header modal
   */
  public openModal = () => runInAction(() => (this.modalHidden = false));
  /**
   * Hides edit header modal
   */
  public hideModal = () => runInAction(() => (this.modalHidden = true));

  /** Export - preparing file - PollingHelper callback */
  private onExportPollingStateChanged = (pollingState: LoadingState) => {
    switch (pollingState) {
      case LoadingState.Success:
        if (this.downloadFileName !== null) {
          if (this.downloadFileName !== '') {
            this.downloadStore.download(this.downloadFileName).then(() => {
              runInAction(() => {
                this.exportIsLoading = false;
              });
            });
          } else {
            this.messages.setError(translate('RELEASE_DETAIL_EXPORT_ERROR_NO_FILENAME'));
            runInAction(() => {
              this.exportIsLoading = false;
            });
          }
        }
        break;

      case LoadingState.Error:
        runInAction(() => {
          this.exportIsLoading = false;
        });
        break;

      default:
        // LoadingState.Pending
        if (this.exportIsLoading !== true) {
          runInAction(() => {
            this.exportIsLoading = true;
          });
        }
    }
  };

  /** Export - preparing file - PollingHelper */
  public pollingHelper = new PollingHelper(this.messages, this.onExportPollingStateChanged);

  /** Export - loading state of button */
  @observable
  exportIsLoading = false;

  /** Export - filename for download */
  private downloadFileName;

  /** Export - action */
  @action
  public export() {
    try {
      if (this.release.releaseType === ReleaseType.Sellout) {
        this.productStore.releaseSelloutDetailExport(this.release.id).then((value: any) => {
          if (value.progress.id) {
            this.downloadFileName = !value.filename ? '' : value.filename;
            this.pollingHelper.startPolling(value.progress.id, 'RELEASE_SELLOUT_DETAIL_EXPORT');
          }
        });
      } else {
        this.productStore.releaseDetailExport(this.release.id).then((value: any) => {
          if (value.progress.id) {
            this.downloadFileName = !value.filename ? '' : value.filename;
            this.pollingHelper.startPolling(value.progress.id, 'RELEASE_DETAIL_EXPORT');
          }
        });
      }
    } catch (error) {
      // this.messages.setError(error);
    }
  }
}
