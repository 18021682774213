import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ul {\n    padding: 0;\n    margin: 0;\n  }\n\n  li {\n    display: inline-block;\n    list-style-type: none;\n    position: relative;\n    color: #a3a5a7;\n    padding: 0 1rem;\n    text-transform: uppercase;\n    font-size: 0.9rem;\n  }\n\n  li:before {\n    content: '';\n    width: 5px;\n    height: 5px;\n    border-radius: 50%;\n    background: #a8ee89;\n    font-size: 140%;\n    position: absolute;\n    left: -2px;\n    margin-top: -1px;\n    top: 50%;\n  }\n  li:first-child {\n    padding-left: 0;\n  }\n  li:first-child:before {\n    content: none;\n    padding: 0;\n  }\n\n  a {\n    display: inline-block;\n    text-decoration: none;\n    color: #0089d6;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Fri Jul 27 2018
 * @author VBr
 */
import styled, { withTheme } from 'styled-components';
/**
 *  Styled Breadcrumbs component
 */

var StyledBreadcrumbs = styled.div(_templateObject());
export default withTheme(StyledBreadcrumbs);