import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  border: 1px solid ", ";\n  min-height: ", "px;\n  padding: ", ";\n  width: 100%;\n  max-width: 100%;\n  background-color: ", ";\n  border-radius: ", ";\n\n  &:disabled,\n  &:read-only {\n    background-color: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Tue Aug 14 2018
 * @author SKu
 */
import styled, { withTheme } from 'styled-components';
var TextAreaStyled = styled.textarea(_templateObject(), function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.input.height;
}, function (props) {
  return props.theme.textarea.padding;
}, function (props) {
  return props.theme.colors.paper;
}, function (props) {
  return props.theme.input.borderRadius;
}, function (props) {
  return props.theme.input.disabledBg;
});
export default withTheme(TextAreaStyled);