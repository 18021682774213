import {PricingPermissions} from '@logio/common-be-fe';
import {
  CONSTANTS,
  IconType,
  Route as IRoute,
  ImportsPage,
  CommonPagePathsEnum,
  CommonPageNamesEnum,
} from '@logio/common-fe';
import Dashboard from './pages/Dashboard/DashboardPage';
import DataCleaningCategoriesPage from './pages/DataManagement/DataCleaning/DataCleaningCategoriesPage';
import DataCleaningProductsPage from './pages/DataManagement/DataCleaning/DataCleaningProductsPage';
import DataPairingCategoriesPage from './pages/DataManagement/DataPairing/DataPairingCategoriesPage';
import DataPairingPairedPage from './pages/DataManagement/DataPairing/DataPairingPairedPage';
import DataPairingRecommendedPage from './pages/DataManagement/DataPairing/DataPairingRecommendedPage';
import DataPairingUnpairedPage from './pages/DataManagement/DataPairing/DataPairingUnpairedPage';
import DataUploadDetailPage from './pages/DataManagement/DataUpload/DataUploadDetailPage';
import DataUploadNewPage from './pages/DataManagement/DataUpload/DataUploadNewPage';
import DataUploadPage from './pages/DataManagement/DataUpload/DataUploadPage';
import PriceArchitectureCategoriesPage from './pages/DataManagement/PriceArchitecture/PriceArchitectureCategoriesPage';
import PriceArchitectureProductsPage from './pages/DataManagement/PriceArchitecture/PriceArchitectureProductsPage';
import PriceZonesCompetitorsInZonesPage from './pages/DataManagement/PriceZones/PriceZonesCompetitorsInZonesPage';
import PriceZonesPage from './pages/DataManagement/PriceZones/PriceZonesPage';
import NotFound from './pages/General/NotFound';
import ReleaseDetailPage from './pages/Releases/ReleaseDetail/ReleaseDetailPage';
import ReleaseSelloutDetailPage from './pages/Releases/ReleaseDetail/ReleaseSelloutDetailPage';
import ReleaseDetailCategoryPage from './pages/Releases/ReleaseDetailCategory/ReleaseDetailCategoryPage';
import ReleaseSelloutDetailCategoryPage from './pages/Releases/ReleaseDetailCategory/ReleaseSelloutDetailCategoryPage';
import ReleaseExportPage from './pages/Releases/ReleaseExport/ReleaseExportPage';
import ReleaseSelloutExportPage from './pages/Releases/ReleaseExport/ReleaseSelloutExportPage';
import ReleaseSettingsPage from './pages/Releases/ReleaseSettingsPage';
import AgencyQualityEvaluationReportPage from './pages/Reports/AgencyQualityEvaluationReportPage';
import CompetitorsPriceChangeReportPage from './pages/Reports/RelatedReports/CompetitorsPriceChangeReportPage';
import PriceElasticityReportPage from './pages/Reports/RelatedReports/PriceElasticityReportPage';
import SelloutIdentificationReportDetailPage from './pages/Reports/SelloutIdentificationReport/SelloutIdentificationReportDetailPage';
import SelloutIdentificationReportPage from './pages/Reports/SelloutIdentificationReport/SelloutIdentificationReportPage';
import EndSelloutsPage from './pages/Sellouts/EndSelloutsPage';
import EndSelloutsDetailPage from './pages/Sellouts/EndSelloutsDetailPage';
import EndSelloutsFormPage from './pages/Sellouts/EndSelloutsFormPage';
import TerminateRegularSitePage from './pages/Terminations/RegularSite/TerminateRegularSitePage';
import TerminateRegularSiteDetailPage from './pages/Terminations/RegularSite/TerminateRegularSiteDetailPage';
import TerminateRegularSiteFormPage from './pages/Terminations/RegularSite/TerminateRegularSiteFormPage';
import CompetitorsPriorityPage from './pages/Settings/CompetitorsPriorityPage';
import ConfigurationsSettingsPage from './pages/Settings/ConfigurationsSettingsPage';
import MatrixTablePage from './pages/Settings/GeneralSettings/MatrixTablePage';
import GeneralSettingsPage from './pages/Settings/GeneralSettingsPage';
import RoundingRulesPage from './pages/Settings/RoundingRulesPage';
import SelloutSettingsPage from './pages/Settings/SelloutSettingsPage';
import TiersPage from './pages/Settings/TiersPage';
import WorkflowCasesPage from './pages/Settings/WorkflowAdministration/WorkflowCasesPage';
import WorkflowLocksPage from './pages/Settings/WorkflowAdministration/WorkflowLocksPage';
import SubstitutionPage from './pages/Substitution/SubstitutionPage';
import {PageNamesEnum} from './shared/localization/PageNamesEnum';
import {PagePathsEnum} from './shared/localization/PagePathsEnum';
import PriceIndexReportPage from './pages/Reports/RelatedReports/PriceIndexReportPage';
import ReleaseSelloutSettingsPage from './pages/Releases/ReleaseSelloutSettingsPage';
import RegularSiteReleaseDetailPage from './pages/Releases/ReleaseDetail/RegularSiteReleaseDetailPage';
import PriceChangeReportPage from './pages/Reports/PriceChangeReportPage';
import SelloutTerminationReportPage from './pages/Reports/SelloutTerminationReport/SelloutTerminationReportPage';
import RegularReleasePage from './pages/Releases/Overview/RegularReleasePage';
import SelloutReleasePage from './pages/Releases/Overview/SelloutReleasePage';
import RegularReleaseImpactPage from './pages/Releases/Impact/RegularReleaseImpactPage';
import RegularReleaseNewPage from './pages/Releases/RegularReleaseNewPage';
import SelloutReleaseNewPage from './pages/Releases/SelloutReleaseNewPage';
import SelloutReleaseImpactPage from './pages/Releases/Impact/SelloutReleaseImpactPage';
import SelloutImpactReportPage from './pages/Reports/SelloutImpactReport/SelloutImpactReportPage';
import SelloutImpactReportDetailPage from './pages/Reports/SelloutImpactReport/SelloutImpactReportDetailPage';

export interface IRouteParams {
  id?: string;
}

/**
 * Array of paths used to generate either routes, navigation, or breadcrumbs
 */
export const appRoutes: IRoute[] = [
  {
    path: PagePathsEnum.Substitution,
    name: PageNamesEnum.Substitution,
    component: SubstitutionPage,
    hide: true,
  },
  {
    path: PagePathsEnum.Dashboard,
    name: PageNamesEnum.Dashboard,
    component: Dashboard,
    exact: true,
    routeIconType: IconType.home,
  },
  {
    path: CommonPagePathsEnum.Import,
    name: CommonPageNamesEnum.Import,
    routeIconType: IconType.download,
    component: ImportsPage,
    privileges: [PricingPermissions.SYSTEMADMIN_IMPORT_STATUS_ACCESS_SCREEN],
  },
  {
    path: PagePathsEnum.Release,
    name: PageNamesEnum.Release,
    empty: true,
    routeIconType: IconType.release,
    privileges: [
      PricingPermissions.REGULAR_RELEASE_DETAIL,
      PricingPermissions.SELLOUT_RELEASE_DETAIL,
    ],
    routes: [
      {
        path: PagePathsEnum.ReleasesRegular,
        name: PageNamesEnum.ReleasesRegular,
        component: RegularReleasePage,
        exact: true,
        privileges: [PricingPermissions.REGULAR_RELEASE_DETAIL],
      },
      {
        path: PagePathsEnum.ReleasesSellout,
        name: PageNamesEnum.ReleasesSellout,
        component: SelloutReleasePage,
        exact: true,
        privileges: [PricingPermissions.SELLOUT_RELEASE_DETAIL],
      },
      {
        path: PagePathsEnum.ReleaseRegularNew,
        name: PageNamesEnum.ReleaseRegularNew,
        hide: true,
        component: RegularReleaseNewPage,
        privileges: [
          PricingPermissions.REGULAR_RELEASE_CREATE_OTHERS,
          PricingPermissions.REGULAR_RELEASE_CREATE_SUPERFAST,
          PricingPermissions.REGULAR_RELEASE_CREATE_URGENT,
          PricingPermissions.SIMULATION_RELEASE_CREATE,
        ],
      },
      {
        path: PagePathsEnum.ReleaseSelloutNew,
        name: PageNamesEnum.ReleaseSelloutNew,
        hide: true,
        component: SelloutReleaseNewPage,
        privileges: [
          PricingPermissions.SELLOUT_RELEASE_CREATE_URGENT,
          PricingPermissions.SELLOUT_RELEASE_CREATE_OTHERS,
        ],
      },
      {
        path: PagePathsEnum.ReleaseSettings,
        name: PageNamesEnum.ReleaseSettings,
        hide: true,
        component: ReleaseSettingsPage,
        privileges: [PricingPermissions.REGULAR_RELEASE_SETTINGS_VIEW],
        routes: [
          {
            path: PagePathsEnum.ReleaseMatrixTable,
            name: PageNamesEnum.MatrixTable,
            hide: true,
            privileges: [PricingPermissions.REGULAR_RELEASE_SETTINGS_DETAIL_VIEW],
            component: MatrixTablePage,
          },
        ],
      },
      {
        path: PagePathsEnum.ReleaseSelloutSettings,
        name: PageNamesEnum.ReleaseSelloutSettings,
        hide: true,
        component: ReleaseSelloutSettingsPage,
        privileges: [PricingPermissions.REGULAR_RELEASE_SETTINGS_VIEW],
        routes: [
          {
            path: PagePathsEnum.ReleaseMatrixTable,
            name: PageNamesEnum.MatrixTable,
            hide: true,
            privileges: [PricingPermissions.REGULAR_RELEASE_SETTINGS_DETAIL_VIEW],
            component: MatrixTablePage,
          },
        ],
      },
      {
        path: PagePathsEnum.ReleaseDetail,
        name: PageNamesEnum.ReleaseDetail,
        hide: true,
        component: ReleaseDetailPage,
        privileges: [PricingPermissions.REGULAR_RELEASE_DETAIL],
        routes: [
          {
            path: PagePathsEnum.ReleaseDetailCategory,
            name: PageNamesEnum.ReleaseDetailCategory,
            hide: true,
            component: ReleaseDetailCategoryPage,
            privileges: [PricingPermissions.REGULAR_RELEASE_DETAIL],
          },
        ],
      },
      {
        path: PagePathsEnum.ReleaseSelloutDetail,
        name: PageNamesEnum.ReleaseSelloutDetail,
        hide: true,
        component: ReleaseSelloutDetailPage,
        routes: [
          {
            path: PagePathsEnum.ReleaseSelloutDetailCategory,
            name: PageNamesEnum.ReleaseSelloutDetailCategory,
            hide: true,
            component: ReleaseSelloutDetailCategoryPage,
          },
        ],
      },
      {
        path: PagePathsEnum.ReleaseRegularSiteDetail,
        name: PageNamesEnum.ReleaseRegularSiteDetail,
        hide: true,
        component: RegularSiteReleaseDetailPage,
        privileges: [PricingPermissions.REGULAR_RELEASE_DETAIL],
      },
      {
        path: PagePathsEnum.ReleaseSelloutExport,
        name: PageNamesEnum.ReleaseSelloutExport,
        hide: true,
        component: ReleaseSelloutExportPage,
        privileges: [PricingPermissions.SELLOUT_RELEASE_EXPORT],
      },
      {
        path: PagePathsEnum.ReleaseExport,
        name: PageNamesEnum.ReleaseExport,
        hide: true,
        component: ReleaseExportPage,
        privileges: [PricingPermissions.REGULAR_RELEASE_EXPORT],
      },
    ],
  },
  {
    path: PagePathsEnum.DataManagement,
    name: PageNamesEnum.DataManagement,
    empty: true,
    routeIconType: IconType.data,
    privileges: [
      PricingPermissions.FAMILY_MANAGE,
      PricingPermissions.TIERS_MANAGE,
      PricingPermissions.DATA_PAIR,
      PricingPermissions.DATA_CLEAN_VIEW,
      PricingPermissions.ZONES_MANAGE_VIEW,
      PricingPermissions.DATA_UPLOAD_COMPETITOR_VIEW,
      PricingPermissions.DATA_UPLOAD_PRICE_SENSITIVITY_VIEW,
      PricingPermissions.DATA_UPLOAD_FAMILY_VIEW,
      PricingPermissions.DATA_UPLOAD_COMPETITOR_EDIT,
      PricingPermissions.DATA_UPLOAD_PRICE_SENSITIVITY_EDIT,
      PricingPermissions.DATA_UPLOAD_FAMILY_EDIT,
    ],
    routes: [
      {
        path: PagePathsEnum.PriceArchitectureCategories,
        name: PageNamesEnum.PriceArchitectureCategories,
        component: PriceArchitectureCategoriesPage,
        privileges: [PricingPermissions.FAMILY_MANAGE, PricingPermissions.TIERS_MANAGE],
        routes: [
          {
            path: PagePathsEnum.PriceArchitectureProducts,
            name: PageNamesEnum.PriceArchitectureProducts,
            component: PriceArchitectureProductsPage,
            privileges: [PricingPermissions.FAMILY_MANAGE, PricingPermissions.TIERS_MANAGE],
            hide: true,
          },
        ],
      },
      {
        path: PagePathsEnum.DataPairing,
        name: PageNamesEnum.DataPairing,
        component: DataPairingCategoriesPage,
        privileges: [PricingPermissions.DATA_PAIR],
        routes: [
          {
            path: PagePathsEnum.DataPairingRecommended,
            name: PageNamesEnum.DataPairingRecommended,
            component: DataPairingRecommendedPage,
            hide: true,
            privileges: [PricingPermissions.DATA_PAIR],
          },
          {
            path: PagePathsEnum.DataPairingUnpaired,
            name: PageNamesEnum.DataPairingUnpaired,
            component: DataPairingUnpairedPage,
            privileges: [PricingPermissions.DATA_PAIR],
            hide: true,
          },
          {
            path: PagePathsEnum.DataPairingPaired,
            name: PageNamesEnum.DataPairingPaired,
            component: DataPairingPairedPage,
            privileges: [PricingPermissions.DATA_PAIR],
            hide: true,
          },
        ],
      },
      {
        path: PagePathsEnum.DataCleaningCategories,
        name: PageNamesEnum.DataCleaningCategories,
        component: DataCleaningCategoriesPage,
        privileges: [PricingPermissions.DATA_CLEAN_VIEW],
        routes: [
          {
            path: PagePathsEnum.DataCleaningProducts,
            name: PageNamesEnum.DataCleaningProducts,
            component: DataCleaningProductsPage,
            privileges: [PricingPermissions.DATA_CLEAN_VIEW],
            hide: true,
          },
        ],
      },
      {
        path: PagePathsEnum.PriceZones,
        name: PageNamesEnum.PriceZones,
        component: PriceZonesPage,
        privileges: [PricingPermissions.ZONES_MANAGE_VIEW],
        routes: [
          {
            path: PagePathsEnum.PriceZonesCompetitorsInZones,
            name: PageNamesEnum.PriceZonesCompetitorsInZones,
            component: PriceZonesCompetitorsInZonesPage,
            privileges: [PricingPermissions.ZONES_MANAGE_VIEW],
            hide: true,
          },
        ],
      },
      {
        path: PagePathsEnum.DataUpload,
        name: PageNamesEnum.DataUpload,
        component: DataUploadPage,
        privileges: [
          PricingPermissions.DATA_UPLOAD_COMPETITOR_VIEW,
          PricingPermissions.DATA_UPLOAD_PRICE_SENSITIVITY_VIEW,
          PricingPermissions.DATA_UPLOAD_FAMILY_VIEW,
        ],
        routes: [
          {
            path: PagePathsEnum.DataUploadNew,
            name: PageNamesEnum.DataUploadNew,
            component: DataUploadNewPage,
            privileges: [
              PricingPermissions.DATA_UPLOAD_COMPETITOR_EDIT,
              PricingPermissions.DATA_UPLOAD_PRICE_SENSITIVITY_EDIT,
              PricingPermissions.DATA_UPLOAD_FAMILY_EDIT,
            ],
            hide: true,
          },
          {
            path: PagePathsEnum.DataUploadDetail,
            name: PageNamesEnum.DataUploadDetail,
            component: DataUploadDetailPage,
            privileges: [
              PricingPermissions.DATA_UPLOAD_COMPETITOR_VIEW,
              PricingPermissions.DATA_UPLOAD_PRICE_SENSITIVITY_VIEW,
              PricingPermissions.DATA_UPLOAD_FAMILY_VIEW,
            ],
            hide: true,
          },
        ],
      },
    ],
  },
  {
    path: PagePathsEnum.Terminations,
    name: PageNamesEnum.Terminations,
    empty: true,
    routeIconType: IconType.barCode,
    privileges: [PricingPermissions.END_SELLOUT_PRICES, PricingPermissions.END_REGULAR_SITE_PRICES],
    routes: [
      {
        path: PagePathsEnum.TerminateRegularSite,
        name: PageNamesEnum.TerminateRegularSite,
        component: TerminateRegularSitePage,
        privileges: [PricingPermissions.END_REGULAR_SITE_PRICES],
      },
      {
        path: PagePathsEnum.TerminateRegularSiteForm,
        name: PageNamesEnum.TerminateRegularSite,
        component: TerminateRegularSiteFormPage,
        privileges: [PricingPermissions.END_REGULAR_SITE_PRICES],
        hide: true,
      },
      {
        path: PagePathsEnum.TerminateRegularSiteDetail,
        name: PageNamesEnum.TerminateRegularSite,
        component: TerminateRegularSiteDetailPage,
        privileges: [PricingPermissions.END_REGULAR_SITE_PRICES],
        hide: true,
      },

      {
        path: PagePathsEnum.EndSellouts,
        name: PageNamesEnum.EndSellouts,
        component: EndSelloutsPage,
        privileges: [PricingPermissions.END_SELLOUT_PRICES],
      },
      {
        path: PagePathsEnum.EndSelloutsForm,
        name: PageNamesEnum.EndSellouts,
        component: EndSelloutsFormPage,
        privileges: [PricingPermissions.END_SELLOUT_PRICES],
        hide: true,
      },
      {
        path: PagePathsEnum.EndSelloutsDetail,
        name: PageNamesEnum.EndSellouts,
        component: EndSelloutsDetailPage,
        privileges: [PricingPermissions.END_SELLOUT_PRICES],
        hide: true,
      },
    ],
  },
  {
    path: PagePathsEnum.Reports,
    name: PageNamesEnum.Reports,
    empty: true,
    routeIconType: IconType.reports,
    privileges: [
      PricingPermissions.COMPETITOR_PRICE_CHANGE_REPORT,
      PricingPermissions.PRICE_INDEX_REPORT,
      PricingPermissions.PRICE_ELASTICITY_REPORT,
      PricingPermissions.PRICE_INFLATION_REPORT,
      PricingPermissions.AGENCY_QUALITY_EVALUATION_REPORT,
      PricingPermissions.PRICE_STICKERS_REPORT,
      PricingPermissions.SELLOUT_IDENTIFICATION_REPORT,
    ],
    routes: [
      {
        path: `${CONSTANTS.APP.PROMO_HOST}/reporting/sales-dashboard`,
        name: PageNamesEnum.PromoSalesDashoboardReport,
        isExternal: true,
      },
      {
        path: PagePathsEnum.CompetitorsPriceChangeReport,
        name: PageNamesEnum.CompetitorsPriceChangeReport,
        component: CompetitorsPriceChangeReportPage,
        privileges: [PricingPermissions.COMPETITOR_PRICE_CHANGE_REPORT],
      },
      {
        path: PagePathsEnum.PriceIndexReport,
        name: PageNamesEnum.PriceIndexReport,
        component: PriceIndexReportPage,
        privileges: [PricingPermissions.PRICE_INDEX_REPORT],
      },
      {
        path: PagePathsEnum.PriceElasticityReport,
        name: PageNamesEnum.PriceElasticityReport,
        component: PriceElasticityReportPage,
        privileges: [PricingPermissions.PRICE_ELASTICITY_REPORT],
      },
      // LOG-6941
      // {
      //   path: PagePathsEnum.PriceInflationReport,
      //   name: PageNamesEnum.PriceInflationReport,
      //   component: Blank,
      //   privileges: [
      //     PricingPermissions.PRICE_INFLATION_REPORT,
      //   ]
      // },
      {
        path: PagePathsEnum.AgencyQualityEvaluationReport,
        name: PageNamesEnum.AgencyQualityEvaluationReport,
        component: AgencyQualityEvaluationReportPage,
        privileges: [PricingPermissions.AGENCY_QUALITY_EVALUATION_REPORT],
      },
      {
        path: PagePathsEnum.PriceChangeAndStickersReport,
        name: PageNamesEnum.PriceChangeAndStickersReport,
        component: PriceChangeReportPage,
        privileges: [
          PricingPermissions.PRICE_STICKERS_REPORT,
        ]
      },
      {
        path: PagePathsEnum.SelloutIdentificationReport,
        name: PageNamesEnum.SelloutIdentificationReport,
        component: SelloutIdentificationReportPage,
        privileges: [PricingPermissions.SELLOUT_IDENTIFICATION_REPORT],
        routes: [
          {
            path: PagePathsEnum.SelloutIdentificationReportDetail,
            name: PageNamesEnum.SelloutIdentificationReportDetail,
            component: SelloutIdentificationReportDetailPage,
          },
        ],
      },
      {
        path: PagePathsEnum.SelloutTerminationReport,
        name: PageNamesEnum.SelloutTerminationReport,
        component: SelloutTerminationReportPage,
        privileges: [PricingPermissions.SELLOUT_TERMINATION_REPORT],
      },
      {
        path: PagePathsEnum.ReleasesRegularImpact,
        name: PageNamesEnum.ReleasesRegularImpact,
        component: RegularReleaseImpactPage,
        privileges: [PricingPermissions.IMPACT_REPORT_REGULAR],
      },
      {
        path: PagePathsEnum.ReleasesSelloutImpact,
        name: PageNamesEnum.ReleasesSelloutImpact,
        component: SelloutImpactReportPage,
        privileges: [PricingPermissions.IMPACT_REPORT_SELLOUT],
        routes: [
          {
            path: PagePathsEnum.ReleasesSelloutImpactDetail,
            name: PageNamesEnum.ReleasesSelloutImpactDetail,
            hide: true,
            component: SelloutImpactReportDetailPage,
          },
        ],
      },
    ],
  },
  {
    path: PagePathsEnum.Settings,
    name: PageNamesEnum.Settings,
    empty: true,
    routeIconType: IconType.settings,
    privileges: [
      PricingPermissions.GENERAL_SETTINGS_VIEW,
      PricingPermissions.MATRIX_TABLE_SETTINGS_VIEW,
      PricingPermissions.CONFIGURATIONS_MANAGE_VIEW,
      PricingPermissions.ROUNDING_RULES_SETTINGS,
      PricingPermissions.ROUNDING_RULES_EXAMPLE,
      PricingPermissions.COMPETITOR_PRIORITY_SETTINGS_VIEW,
      PricingPermissions.SELLOUT_SETTINGS_VIEW,
      PricingPermissions.TIERS_SETTINGS_VIEW,
      PricingPermissions.WORKFLOW_ADMINISTRATION,
    ],
    routes: [
      {
        path: PagePathsEnum.GeneralSettingsCategories,
        name: PageNamesEnum.GeneralSettingsCategories,
        component: GeneralSettingsPage,
        privileges: [PricingPermissions.GENERAL_SETTINGS_VIEW],
        routes: [
          {
            path: PagePathsEnum.GeneralSettingsMatrixTable,
            name: PageNamesEnum.MatrixTable,
            component: MatrixTablePage,
            privileges: [PricingPermissions.MATRIX_TABLE_SETTINGS_VIEW],
            hide: true,
          },
        ],
      },
      {
        path: PagePathsEnum.ConfigurationsSettings,
        name: PageNamesEnum.ConfigurationsSettings,
        component: ConfigurationsSettingsPage,
        privileges: [PricingPermissions.CONFIGURATIONS_MANAGE_VIEW],
      },
      {
        path: PagePathsEnum.RoundingRulesSettings,
        name: PageNamesEnum.RoundingRulesSettings,
        component: RoundingRulesPage,
        privileges: [PricingPermissions.ROUNDING_RULES_SETTINGS, PricingPermissions.ROUNDING_RULES_EXAMPLE],
        routes: [
          {
            path: PagePathsEnum.RoundingRulesSettingsUnweighted,
            name: PageNamesEnum.RoundingRulesSettings,
            component: RoundingRulesPage,
            privileges: [PricingPermissions.ROUNDING_RULES_SETTINGS, PricingPermissions.ROUNDING_RULES_EXAMPLE],
            hide: true,
          },
        ],
      },
      {
        path: PagePathsEnum.CompetitorsPrioritySettings,
        name: PageNamesEnum.CompetitorsPrioritySettings,
        privileges: [PricingPermissions.COMPETITOR_PRIORITY_SETTINGS_VIEW],
        component: CompetitorsPriorityPage,
      },
      {
        path: PagePathsEnum.SelloutSettings,
        name: PageNamesEnum.SelloutSettings,
        privileges: [PricingPermissions.SELLOUT_SETTINGS_VIEW],
        component: SelloutSettingsPage,
      },
      {
        path: PagePathsEnum.TiersSettings,
        name: PageNamesEnum.TiersSettings,
        privileges: [PricingPermissions.TIERS_SETTINGS_VIEW],
        component: TiersPage,
      },
      {
        path: PagePathsEnum.WorkflowCases,
        name: PageNamesEnum.WorkflowAdministration,
        privileges: [PricingPermissions.WORKFLOW_ADMINISTRATION],
        component: WorkflowCasesPage,
      },
      {
        path: PagePathsEnum.WorkflowLocks,
        name: PageNamesEnum.WorkflowAdministration,
        component: WorkflowLocksPage,
        hide: true,
      },
    ],
  },
  {
    path: PagePathsEnum.NotFound,
    name: PageNamesEnum.NotFound,
    component: NotFound,
    hide: true,
    default: true,
  },
];
