"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var SupplierAgreementValidatorHints;

(function (SupplierAgreementValidatorHints) {
  /** Default validation after creating, updating and saving items. */
  SupplierAgreementValidatorHints[SupplierAgreementValidatorHints["FULL"] = 0] = "FULL";
  /** Used to alter items before copy/move process */

  SupplierAgreementValidatorHints[SupplierAgreementValidatorHints["BEFORE_COPY"] = 1] = "BEFORE_COPY";
})(SupplierAgreementValidatorHints = exports.SupplierAgreementValidatorHints || (exports.SupplierAgreementValidatorHints = {}));