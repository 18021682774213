/**
 * @file Created on Fri Oct 05 2018
 * @author SKu
 */

import {PricingPermissions, ReleaseType, ReleaseState, PricingRoles} from '@logio/common-be-fe';
import {
  Button,
  ButtonSize,
  ConfirmBox,
  getPath,
  Icon,
  IconColor,
  IconType,
  translate,
  WithPermission,
  RoleControl,
  ProgressMonitorBar,
} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';
import {withRouter} from 'react-router';
import ReleaseHeader from '../../../components/Release/ReleaseHeader/ReleaseHeader';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {ReleaseDetailModalEnum} from '../../../stores/pages/Release/ReleaseDetail/AbstractReleaseDetailPageStore';
import {ReleaseDetailPageStore} from '../../../stores/pages/Release/ReleaseDetail/ReleaseDetailPageStore';
import AbstractReleaseDetailPage from './AbstractReleaseDetailPage';
import {ReleasedImpactFilter} from '../../../components/Release/ReleasedImpactFilter';
import { computed } from 'mobx';
import { RecalculationButton } from '../../../components/Release/ReleaseHeader/RecalculationButton';

/**
 * Regular release detail page
 * Renders total overview
 * works also for simulation
 */
@observer
class ReleaseDetailPage extends AbstractReleaseDetailPage {
  public store = new ReleaseDetailPageStore(this.props.history);

  public get releaseHeader() {
    return (
      <>
        <ReleaseHeader store={this.store.releaseHeaderStore} customItems={this.releaseHeaderItems} />
        <ProgressMonitorBar
          progressMonitorId={this.store.recalculationProgressMonitors}
          label={translate('release-calculating-categories-now', this.store.recalculationCategories.join(', '))}
          onEnd={this.store.refreshRelease}
          className="mb1"
        />
      </>
    );
  }

  @computed
  public get releaseHeaderItems() {
    /** Actions are not available in computing mode */
    if (this.isReleaseComputing) {
      return null;
    }

    /** Export not available for Simulation type */
    const exportHeaderItem = this.store.release.releaseType !== ReleaseType.Simulation && (
      <Button
        buttonSize={ButtonSize.Tiny}
        onClick={() => this.props.history.push(getPath(PagePathsEnum.ReleaseExport, this.props.match.params.releaseId))}
        disabled={!this.store.release}
      >
        {translate('export-page')}
      </Button>
    );
    /** Save to General Settings is available only for Simulation type */
    const savetoGeneralSettingsHeaderItem = (
      <Button
        buttonSize={ButtonSize.Tiny}
        iconRight={true}
        disabled={
          this.store.release.releaseType !== ReleaseType.Simulation &&
          this.store.release.state !== ReleaseState.Approved
        }
        onClick={this.store.getOpenModalEvent(ReleaseDetailModalEnum.SaveToGeneralSettings)}
      >
        {translate('save-release-to-general-settings')}
        <Icon iconType={IconType.settings} iconColor={IconColor.Inverse} />
      </Button>
    );

    /** Icon opens modal for price uploading */
    const uploadPricesHeaderItem = (
      <Icon
        iconType={IconType.upload}
        iconColor={IconColor.Secondary}
        disabled={!this.store.release.editInfo.settingsEditable}
        onClick={this.store.openPriceUploadModal}
        className="pr1"
      />
    );

    return this.store.release.state === ReleaseState.Released
      ? [
          <ReleasedImpactFilter
            key="impactFilter"
            initialValues={this.parsedQueryFilter}
            onSubmit={this.store.onImpactFilterSubmit}
          />,
        ]
      : [
          <WithPermission permissionTypes={[PricingPermissions.REGULAR_RELEASE_CANCEL]} key={'cancel-release'}>
            {this.cancelHeaderItem}
          </WithPermission>,
          <WithPermission permissionTypes={[PricingPermissions.REGULAR_RELEASE_EXPORT]} key={'export'}>
            {exportHeaderItem}
          </WithPermission>,
          <WithPermission
            permissionTypes={[PricingPermissions.REGULAR_RELEASE_SAVE_TO_GENERAL_SETTINGS]}
            key={'saveto'}
          >
            {savetoGeneralSettingsHeaderItem}
          </WithPermission>,
          <WithPermission key={'upload'} permissionTypes={[PricingPermissions.REGULAR_RELEASE_FINAL_PRICE_EDIT]}>
            {uploadPricesHeaderItem}
          </WithPermission>,
          <RoleControl roles={[PricingRoles.ADMIN, PricingRoles.PRICE_MANAGER]} hideError key={'recalculate'}>
            {this.store.showRecalculationButton && <RecalculationButton recalculate={this.store.recalculate} />}
          </RoleControl>
        ];
  }

  public get additionalRenderContent() {
    return (
      <ConfirmBox
        hidden={this.store.isModalHidden(ReleaseDetailModalEnum.SaveToGeneralSettings)}
        title={translate('save-to-general-settings')}
        onAccept={this.store.onConfirmCopyToGeneralSettings}
        close={this.store.hideModal}
        children={<div className="mb1">{`${translate('save-to-general-settings-confirmation')}`}</div>}
      />
    );
  }
}

export default withRouter(ReleaseDetailPage);
