"use strict";
/*
 * Created on 04 29 2021
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author Jaroslav Sevcik
 */

var SelloutImpactReportLevel;

(function (SelloutImpactReportLevel) {
  SelloutImpactReportLevel[SelloutImpactReportLevel["Department"] = 10] = "Department";
  SelloutImpactReportLevel[SelloutImpactReportLevel["SCM"] = 20] = "SCM";
  SelloutImpactReportLevel[SelloutImpactReportLevel["CM"] = 30] = "CM";
  SelloutImpactReportLevel[SelloutImpactReportLevel["CategoryPlan"] = 40] = "CategoryPlan";
  SelloutImpactReportLevel[SelloutImpactReportLevel["Product"] = 50] = "Product";
  SelloutImpactReportLevel[SelloutImpactReportLevel["SelloutType"] = 60] = "SelloutType";
})(SelloutImpactReportLevel = exports.SelloutImpactReportLevel || (exports.SelloutImpactReportLevel = {}));