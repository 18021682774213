/**
 * @file Created on Sun May 19 2019
 * @author SKu
 */

import {DateParser, SelloutRelease, StructPropBuilder, Release, ValidationError} from '@logio/common-be-fe';
import {Button, Field, Form, FormGroup, Modal, translate} from '@logio/common-fe';
import moment, { now } from 'moment';
import * as React from 'react';

export interface Props {
  /** Modal hidden prop */
  hidden: boolean;
  /** Function to close modal */
  close: () => void;
  /** Fires on reopen form submit */
  onSubmit: (values: any) => Promise<void>;
  /** Used for load properties from the release */
  release?: SelloutRelease;
}

const ReleaseReopenModal: React.SFC<Props> = (props: Props) => {
  const builder = new StructPropBuilder('Release');
  /**  form description */
  const descriptions = {
    totalPriceChangeLimit: SelloutRelease.schema.totalPriceChangeLimit,
    cmDeadline: builder.date('cmDeadline', DateParser.ISO_PATTERN),
    priceValidityStartDate: builder.date('priceValidityStartDate', DateParser.ISO_PATTERN),
    endDate: builder.date('endDate', DateParser.ISO_PATTERN),
    minPriceChangeInPercent: builder.bigNum('minPriceChangeInPercent'),
    maxPriceChangeInPercent: builder.opt(builder.bigNum('maxPriceChangeInPercent')),
    minMarginChangeInPercent: builder.bigNum('minMarginChangeInPercent'),
    description: SelloutRelease.schema.description,
    repricingEstimationThreshold: SelloutRelease.schema.repricingEstimationThreshold,
  };

  /**  form initial values */
  const initialValues = {
    cmDeadline: moment(),
    priceValidityStartDate: moment().add(1, 'd'),
    endDate: moment().add(2, 'w'),
  };

  /** LOG-5390 Evaluates a condition if the end date field should be shown */
  // const endDateShouldBeShown = (release: SelloutRelease) => {
  //   const priceListTypeConverted = translate(release.priceListType);
  //   if(
  //     priceListTypeConverted.includes("Regular delist") ||
  //     priceListTypeConverted.includes("In-Out delist") ||
  //     priceListTypeConverted.includes("Remodelling")
  //   ){
  //     return false;
  //   }
  //   return true;
  // };

  /** LOG-6620 Evaluates a condition if the total price change limit field should be shown */
  const totalPriceChangeLimitShouldBeShown = (release: SelloutRelease) => {
    const priceListTypeConverted = translate(release.priceListType);
    if(
      priceListTypeConverted.includes("Regular delist") ||
      priceListTypeConverted.includes("In-Out delist") 
    ){
      return false;
    }
    return true;
  };

  function validateCMDeadline(value, values) {
    if (value) {
      if (value.isBefore(moment().subtract(1, 'day').endOf('day'))) {
        return new ValidationError('ERROR_cmDeadlineBeforeCurrentTime');
      } else if (values.priceValidityStartDate && value.isAfter(values.priceValidityStartDate.clone().subtract(1, 'day').endOf('day'))) {
        return new ValidationError('ERROR_cmDeadlineAfterPriceValidityStartDate');
      }
    }
  }

  function validatePriceValidityStartDate(value, values) {
    if (value) {
      if (value.isBefore(moment().endOf('day'))) {
        return new ValidationError('ERROR_priceValidityStartDateBeforeTomorrow');
      } else if (values.endDate && value.isAfter(values.endDate)) {
        return new ValidationError('ERROR_priceValidityStartDateAfterEndDate');
      }
    }
  }

  function validateEndDate(value, values) {
    if (value) {
      if (values.priceValidityStartDate && value.isBefore(values.priceValidityStartDate)) {
        return new ValidationError('err-dateFrom-bigger-than-dateTo');
      }
    }
  }

  function validateMinPriceChange(value: string, allValues: any) {
    const val = parseFloat(value);
    if (val < 0) {
      return new ValidationError('err-minPriceChange-negative');
    } else if (allValues.maxPriceChangeInPercent && val > parseFloat(allValues.maxPriceChangeInPercent)) {
      return new ValidationError('ERROR_minPriceChangeMoreThanMaxPriceChange');
    }
  }

  function validateMaxPriceChange(value: string, allValues: any) {
    const val = parseFloat(value);
    if (val < 0) {
      return new ValidationError('err-maxPriceChange-negative');
    } else if (allValues.minPriceChangeInPercent && val < parseFloat(allValues.minPriceChangeInPercent)) {
      return new ValidationError('ERROR_maxPriceChangeLessThanMinPriceChange');
    }
  }

  return (
    <Modal title={translate('release-reopen-modal')} hidden={props.hidden} close={props.close}>
      <Form descriptions={descriptions} onSubmit={props.onSubmit} initialValues={initialValues}>
       { totalPriceChangeLimitShouldBeShown(props.release)&&<Field name={'totalPriceChangeLimit'} />}
        <Field name="minPriceChangeInPercent" validate={validateMinPriceChange} />
        <Field name="maxPriceChangeInPercent" validate={validateMaxPriceChange} />
        <Field name="minMarginChangeInPercent" />
        <Field name="cmDeadline" validate={validateCMDeadline} />
        <Field name="priceValidityStartDate" validate={validatePriceValidityStartDate} />
        <Field name="endDate" validate={validateEndDate} />
        <Field name="description" />
        <Field name="repricingEstimationThreshold" />
        <FormGroup noLabel>
          <Button>{translate('Reopen')}</Button>
        </FormGroup>
      </Form>
    </Modal>
  );
};

export default ReleaseReopenModal;
