/**
 * @file Created on Sat Feb 02 2019
 * @author SKu
 */

import {Page, BoxDefault, ButtonLoading, ButtonColor, Icon, IconType, translate} from '@logio/common-fe';
import {History, Location} from 'history';
import {computed} from 'mobx';
import {Observer} from 'mobx-react';
import qs from 'query-string';
import {stringify} from 'querystring';
import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {AbstractRelatedReportsPageStore} from '../../../stores/pages/Reports/RelatedReports/AbstractRelatedReportsPageStore';
import {RelatedReportsFilter, RelatedReportsFilterProps, RelatedReportsFormFilter} from './RelatedReportsFilter';
import {RelatedReportsProductInfo} from './RelatedReportsProductInfo';
import {RelatedReportsTabs} from './RelatedReportsTabs';
import moment from "moment";

export interface Props {
  location: Location;
  history: History;
}

/**
 * Abstract related reports page
 * Reports have tab navigation and
 * form filter through category and product
 * in common.
 * Custom filter field should be passed trough abstract methods
 */
export abstract class AbstractRelatedReportsPage<T extends RelatedReportsFormFilter> extends React.Component<
  Props & RouteComponentProps<AbstractRelatedReportsPage<T>>
> {
  /** Current page name */
  public abstract pageName: PageNamesEnum;
  /** Page store */
  public abstract store: AbstractRelatedReportsPageStore<T>;
  /** Should be RelatedReportsFilter filter visible */
  protected relatedReportsFilterVisible = true;

  /** Loads page data if query has some parameters */
  componentWillMount() {
    this.store.load(this.queryParsed);
  }

  /** query from url(could has inside categoryId and productId) */
  @computed
  public get queryPath(): string {
    return this.props.location.search;
  }

  /** Parses query and returns as object with {categoryId, productId} */
  @computed
  public get queryParsed(): RelatedReportsFormFilter {
    const categoryId: string = qs.parse(this.queryPath, {ignoreQueryPrefix: true}).categoryId;

    const productId: string = qs.parse(this.queryPath, {ignoreQueryPrefix: true}).productId;

    // Initial values of the filter are re-written by this object! But categoryId and productId are empty as default
    let returnObject: RelatedReportsFormFilter = {categoryId, productId};

    const priceZoneId: string = qs.parse(location.search, {ignoreQueryPrefix: true}).priceZoneId;
    if(priceZoneId && priceZoneId !== "") {
      returnObject = {...returnObject, priceZoneId}
    }

    const competitorIds: string = qs.parse(location.search, {ignoreQueryPrefix: true}).competitorIds;
    if(competitorIds && competitorIds !== "") {
      returnObject = {...returnObject, competitorIds: competitorIds.split("-")}
    }

    const dateFrom: string = qs.parse(location.search, {ignoreQueryPrefix: true}).dateFrom;
    if(dateFrom && dateFrom !== "") {
      returnObject = {...returnObject, dateFrom: moment(dateFrom, 'w-YYYY')}
    }

    return returnObject;
  }

  /** Default form props(such as initialValues and onSubmit function)  */
  @computed
  public get defaultFormProps(): RelatedReportsFilterProps<T> {
    return {initialValues: this.queryParsed, onSubmit: this.store.onFilter, onChange: this.onFormChange};
  }
  /** Method for passing customDescriptions/fields/overwriting common props etc. to form filter */
  public abstract get customFormProps(): RelatedReportsFilterProps<T>;
  /** Merging custom and common form props  */
  @computed
  public get formProps(): RelatedReportsFilterProps<T> {
    return {...this.defaultFormProps, ...this.customFormProps};
  }

  /** PUsh query to history based on form values */
  public onFormChange = ({categoryId, productId, priceZoneId, competitorIds, dateFrom}: T) => {
    const queryObject = {};
    if (categoryId) {
      Object.assign(queryObject, {categoryId});
    }
    if (productId) {
      Object.assign(queryObject, {productId});
    }
    if (priceZoneId) {
      Object.assign(queryObject, {priceZoneId});
    }
    if (competitorIds) {
      Object.assign(queryObject, {competitorIds: competitorIds.join("-")});
    }
    if (dateFrom) {
      Object.assign(queryObject, {dateFrom: dateFrom.format("w-YYYY")});
    }
    this.props.history.push(`${this.props.location.pathname}?${stringify(queryObject)}`);
  };

  public get renderDownloadReport(): JSX.Element {
    return <></>;
  }

  /** Custom content for the render method(ag-grid, graphs, etc.) */
  public abstract get renderContent(): JSX.Element;

  render() {
    return (
      <Page titleKey={this.pageName}>
        <Observer>
          {() => (
            <>
              <RelatedReportsTabs query={this.props.location.search} pageName={this.pageName} />
              {this.relatedReportsFilterVisible && <RelatedReportsFilter {...this.formProps} />}
              {this.store.productInfo && <RelatedReportsProductInfo productInfo={this.store.productInfo} priceZones={this.store.priceZoneStore.list} />}
              {this.renderContent}
              {this.renderDownloadReport}
            </>
          )}
        </Observer>
      </Page>
    );
  }
}
