import _toConsumableArray from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

/**
 * @file Created on Fri Nov 16 2018
 * @author SKu
 */
import { Utils } from '@logio/common-be-fe';
import * as React from 'react';
import { CONSTANTS } from '../../../../../constants';
import translate from '../../../../i18n/translate';
import Tooltip from '../../../../info/Tooltip';
import { TooltipType } from '../../../../info/Tooltip/TooltipType';
import Icon, { IconColor, IconType } from '../../../../media/Icon';
import { N } from '../../../../i18n/N';
export var ReleaseReferencePriceRenderer = function ReleaseReferencePriceRenderer(props) {
  var price = props.value;

  if (Utils.isValueMissing(price)) {
    return React.createElement(React.Fragment, null, CONSTANTS.FORMAT.NULL);
  }

  var messages = [];

  if (!Utils.isValueMissing(price.messages)) {
    price.messages.forEach(function (msg) {
      messages.push(React.createElement("li", {
        key: msg.code
      }, " - ", translate.apply(void 0, [msg.code].concat(_toConsumableArray(msg.args)))));
    });
  }

  var renderElement = [price.value && React.createElement(N, {
    value: price.value,
    key: 'number'
  })];

  if (messages.length > 0) {
    renderElement.push(React.createElement("span", {
      key: "alert",
      className: 'dib'
    }, React.createElement(Tooltip, {
      overlay: messages,
      tooltipType: TooltipType.Warning,
      placement: "right",
      children: React.createElement("span", null, React.createElement(Icon, {
        iconColor: IconColor.Error,
        iconType: IconType.alert,
        iconHeight: 15
      }))
    })));
  }

  return React.createElement("div", null, renderElement);
};