/**
 * @file Data cleaning page
 * @author VBr
 */

import {CodebookLookupSequence, PricingPermissions, StructPropBuilder} from '@logio/common-be-fe';
import {
  AgGrid,
  Button,
  ButtonColor,
  ButtonSize,
  CONSTANTS,
  Field,
  Form,
  FormGroupInline,
  getPath,
  GroupActions,
  Modal,
  OptionsProvider,
  Page,
  SelectAdapter,
  SelectOptions,
  translate,
  WithPermission,
} from '@logio/common-fe';
import {History} from 'history';
import {Observer, observer} from 'mobx-react';
import * as React from 'react';
import {Col, Row} from 'react-flexbox-grid';
import {match, RouteComponentProps, withRouter} from 'react-router';
import {IRouteParams} from 'routes-app';
import {CompetitorFilter} from '../../../filters/CompetitorFilter';
import {OutlierFilter} from '../../../filters/OutlierFilter';
import {PriceZoneFilter} from '../../../filters/PriceZoneFilter';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {
  DataCleaningModalEnum,
  DataCleaningProductsPageStore,
} from '../../../stores/pages/DataManagement/DataCleaning/DataCleaningProductsPageStore';
import PriceHistoryModal from './PriceHistoryModal';

export interface Props extends RouteComponentProps<any> {
  history: History;
  match: match<IRouteParams>;
}

@observer
class DataCleaningProductsPage extends React.Component<Props> {
  store = new DataCleaningProductsPageStore(this.props.history);

  componentWillMount() {
    this.store.load(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.store.unlockCategory(false);
  }

  render() {
    return (
      <Page
        titleKey={PageNamesEnum.DataCleaningProducts}
        titleParams={[this.store.productCategory ? this.store.productCategory.name : '']}
        loadingState={this.store.loadingState}
        backButtonPath={getPath(PagePathsEnum.DataCleaningCategories)}
      >
        <Observer>
          {() => (
            <>
              <Row>
                <Col lg={5} md={12}>
                  <PriceZoneFilter
                    className="pb1"
                    values={this.store.showedZones ? this.store.showedZones.slice() : []}
                    onChange={this.store.filterZones}
                    options={this.store.priceZoneOptions}
                  />
                </Col>
                <Col lg={7} md={12}>
                  <CompetitorFilter
                    className="pb1"
                    values={this.store.showedCompetitors ? this.store.showedCompetitors.slice() : []}
                    onChange={this.store.filterCompetitors}
                    options={this.store.competitorOptions}
                  />
                </Col>
                <Col lg={12} md={12}>
                  <div style={{height: '3.25rem'}} className="pb1">
                    <OutlierFilter
                      values={this.store.showedPriceChanges ? this.store.showedPriceChanges.slice() : []}
                      onChange={this.store.filterOutliers}
                      scale={CONSTANTS.DATA_CLEANING.PRICE_CHANGES_BOUNDARIES}
                      options={this.store.getScaleOptions}
                    />
                  </div>
                </Col>
              </Row>
              {this.store.pageReadOnly ? (
                <FormGroupInline right>
                  <WithPermission permissionTypes={[PricingPermissions.DATA_CLEAN_EDIT]}>
                    <Button buttonSize={ButtonSize.Tiny} onClick={this.store.lockCategory}>
                      {translate('data_cleaning-lock_button')}
                    </Button>
                  </WithPermission>
                </FormGroupInline>
              ) : (
                <Row>
                  <Col xs={10}>
                    <GroupActions selectedRowsCount={this.store.selectedRows.length}>
                      {({disabled}) => (
                        <>
                          <Button
                            buttonSize={ButtonSize.Tiny}
                            onClick={this.store.getModalToggleEvent(DataCleaningModalEnum.COPY_DESIGNATION_TO_ZONES)}
                            disabled={disabled}
                          >
                            {translate('copy-designation-to-zones')}
                          </Button>
                        </>
                      )}
                    </GroupActions>
                  </Col>
                  <Col xs={2}>
                    <FormGroupInline right={true} className="pb0">
                      <Button
                        buttonColor={ButtonColor.Secondary}
                        onClick={this.store.onCategoryApprove}
                        buttonSize={ButtonSize.Tiny}
                      >
                        {translate('data_cleaning-approve_button')}
                      </Button>
                      <Button
                        buttonColor={ButtonColor.Outline}
                        buttonSize={ButtonSize.Tiny}
                        onClick={() => this.store.unlockCategory(true)}
                      >
                        {translate('data_cleaning-unlock_button')}
                      </Button>
                    </FormGroupInline>
                  </Col>
                </Row>
              )}
              <CopyDesignationToZonesModal store={this.store} />
              <PriceHistoryModal store={this.store} />
              <AgGrid
                rowData={this.store.rowData}
                columnDefs={this.store.columnDefs}
                onGridReady={this.store.onGridReady}
                isExternalFilterPresent={this.store.isExternalFilterPresent}
                doesExternalFilterPass={this.store.doesExternalFilterPass}
                rowSelection="multiple"
                onSelectionChanged={this.store.onSelectionChanged}
                onColumnVisible={this.store.updateFilter}
                onFilterChanged={this.store.onFilterOrSortChanged}
                onSortChanged={this.store.onFilterOrSortChanged}
                gridId="PricingDataCleaningProductsPage"
                quickView
                getRowNodeId={({Product_id: id}) => id}
                onRowDataChanged={this.store.updateFilter}
              />
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

/**
 * Connects component to store, translates and changes page info
 */
export default withRouter(DataCleaningProductsPage);

@observer
class CopyDesignationToZonesModal extends React.Component<{store: DataCleaningProductsPageStore}> {
  /**
   * Describes the structure of the form.
   */
  get descriptions() {
    const bld = new StructPropBuilder('CopyDesignationToZones');
    return {
      sourceZoneId: bld.lookup('sourceZoneId', CodebookLookupSequence.PriceZone),
      competitorIds: bld.listOf(bld.lookup('competitorIds', CodebookLookupSequence.Competitor)),
      targetZoneIds: bld.listOf(
        bld
          .lookup('targetZoneIds', CodebookLookupSequence.CompatiblePriceZone)
          .withDescription({mandatoryParameter: true}),
      ),
    };
  }

  render() {
    return (
      <Modal
        title={translate('copy-designation-to-zones')}
        hidden={this.props.store.modalHidden[DataCleaningModalEnum.COPY_DESIGNATION_TO_ZONES]}
        close={this.props.store.getModalToggleEvent(DataCleaningModalEnum.COPY_DESIGNATION_TO_ZONES)}
      >
        <>
          <Form onSubmit={this.props.store.onCopyDesignationToZonesSubmit} descriptions={this.descriptions}>
            <Field name="sourceZoneId" />
            {/* FIXME: SKu change optionProvider to fieldType */}
            <OptionsProvider
              name="competitorIds"
              component={(options: SelectOptions) => (
                <Field
                  name="competitorIds"
                  key="competitorIds"
                  component={(props) => SelectAdapter('competitorIds', options, true)(props)}
                />
              )}
            />
            <OptionsProvider
              name="targetZoneIds"
              component={(options: SelectOptions) => (
                <Field
                  name="targetZoneIds"
                  key="targetZoneIds"
                  fieldHide={{competitorIds: undefined, sourceZoneId: undefined}}
                  component={(props) => SelectAdapter('targetZoneIds', options, true)(props)}
                />
              )}
              dependsOn={{zoneId: 'sourceZoneId', competitorIds: 'competitorIds'}}
            />
            <FormGroupInline right={true}>
              <Button
                buttonColor={ButtonColor.Light}
                onClick={this.props.store.getModalToggleEvent(DataCleaningModalEnum.COPY_DESIGNATION_TO_ZONES)}
              >
                {translate('Cancel')}
              </Button>
              <Button type="submit">{translate('Copy')}</Button>
            </FormGroupInline>
          </Form>
        </>
      </Modal>
    );
  }
}
