import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  padding: ", "rem;\n  border: 1px solid ", ";\n  border-radius: ", ";\n  margin-bottom: ", "rem;\n\n  display: flex;\n  min-height: 60px;\n\n  .status-bar-item {\n    display: flex;\n    align-items: center;\n    padding: 0 1rem;\n    min-height: 40px;\n  }\n\n  .status-bar-label {\n    text-transform: uppercase;\n    font-size: 80%;\n    color: #a1a3a5;\n  }\n\n  .status-bar-value {\n    display: block;\n    font-weight: bold;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
import styled, { withTheme } from 'styled-components';
var BoxStatusStyled = styled.div(_templateObject(), function (_ref) {
  var theme = _ref.theme;
  return theme.spacing.spacer;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.border;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.common.borderRadius;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.spacing.spacer;
});
export default withTheme(BoxStatusStyled);