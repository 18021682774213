"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Covnverter resolver for SingleTargetStrategyConverterImpl and MultiTargetStrategyConverterImpl
 *
 * @author RV
 */

var StrategyConverterResolver = function StrategyConverterResolver() {
  _classCallCheck(this, StrategyConverterResolver);
};

exports.StrategyConverterResolver = StrategyConverterResolver;