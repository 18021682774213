"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Enum of initial Window type values.
 * @author OPt
 */

var WindowTypeEnum;

(function (WindowTypeEnum) {
  WindowTypeEnum["Bomb"] = "Bomba";
  WindowTypeEnum["FrontPage"] = "Front page";
  WindowTypeEnum["Regular"] = "Regular";
  WindowTypeEnum["NonLeaflet"] = "Non-leaflet";
})(WindowTypeEnum = exports.WindowTypeEnum || (exports.WindowTypeEnum = {}));