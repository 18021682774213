import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n          padding: ", ";\n        "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n          padding: ", ";\n          margin: 0 -", " -", " -", ";\n        "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  .tabs {\n    position: relative;\n    background-color: ", ";\n    margin: 60px -20px 0 -20px;\n    border: 1px solid ", ";\n    border-width: 1px 0;\n  }\n\n  .react-tabs__tab-list {\n    padding: 0;\n    margin: 0;\n  }\n  .react-tabs__tab-list > li {\n    display: inline-block;\n    font-size: 110%;\n    padding: 5px 20px;\n    cursor: pointer;\n    color: ", ";\n\n    &.react-tabs__tab--selected {\n      border-bottom: 4px solid ", ";\n      color: ", ";\n      cursor: default;\n    }\n  }\n  .react-tabs__tab-panel {\n    background-color: ", ";\n    position: relative;\n  }\n  .react-tabs__tab-panel.react-tabs__tab-panel--selected {\n    ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Fri Jul 27 2018
 * @author VBr
 */
import styled, { css, withTheme } from 'styled-components';
/**
 *  Styled Tabs component
 */

var StyledTabs = styled.div(_templateObject(), function (props) {
  return props.theme.colors.paper;
}, function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.colors.link;
}, function (props) {
  return props.theme.colors.header;
}, function (props) {
  return props.theme.colors.text;
}, function (props) {
  return props.theme.colors.white;
}, function (props) {
  var spacer = "".concat(2 * props.theme.spacing.spacer, "rem");

  if (props.fullWidth) {
    return css(_templateObject2(), spacer, spacer, spacer, spacer);
  } else {
    return css(_templateObject3(), spacer);
  }
});
export default withTheme(StyledTabs);