/**
 * @file Created on Tue Feb 12 2019
 * @author SKu
 */

import { SelloutType, Utils } from '@logio/common-be-fe';
import {
  AgGrid,
  CONSTANTS,
  Icon,
  IconColor,
  IconType,
  Page,
  translate,
} from '@logio/common-fe';
import { List } from 'immutable';
import {Location} from 'history';
import {Observer, observer} from 'mobx-react';
import qs from 'query-string';
import * as React from 'react';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {SelloutIdentificationReportDetailPageStore} from '../../../stores/pages/Reports/SelloutIdentificationReport/SelloutIdentificationReportDetailPageStore';
import SelloutIdentificationReportDetailFilter from './SelloutIdentificationReportDetailFilter';

export interface Props {
  location: Location;
}

@observer
class SelloutIdentificationReportDetailPage extends React.Component<Props> {
  store = new SelloutIdentificationReportDetailPageStore();

  componentDidMount() {
    const categoryIdsFromQuery: string | string[] = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).categoryId;
    const categoryIds = categoryIdsFromQuery
      ? Utils.isArray(categoryIdsFromQuery)
        ? List(categoryIdsFromQuery)
        : List.of(categoryIdsFromQuery)
      : List<string>();
    
    const selloutTypesFromQuery: SelloutType | SelloutType[]  = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).selloutTypes;
    const selloutTypes = selloutTypesFromQuery
      ? Utils.isArray(selloutTypesFromQuery)
        ? List(selloutTypesFromQuery)
        : List.of(selloutTypesFromQuery)
      : List<SelloutType>();

    const totalRows: number = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).totalRows;

    this.store.setFilters({
      categoryIds,
      selloutTypes,
      priceZoneId: '',
      siteIds: List(),
      productExternalIds: List(),
    }, totalRows);

    this.store.load();
  }

  render() {
    return (
      <Page titleKey={PageNamesEnum.SelloutIdentificationReportDetail} loadingState={this.store.loadingState}>
        <Observer>
          {() => (
            <>
              <SelloutIdentificationReportDetailFilter store={this.store} />
              {this.store.isReportVisible && (
                <>
                  <div className="tac pointer mb1 fs-90 c-secondary" onClick={this.store.toggleFilter}>
                    <Icon
                      iconHeight={15}
                      iconColor={IconColor.Secondary}
                      iconType={this.store.isFilterVisible ? IconType.chevronUp : IconType.chevronDown}
                    />
                    {" "}
                    <span className="underline">
                      {translate(this.store.isFilterVisible ? "hide-filters" : "show-filters")}
                    </span>
                  </div>
                  <AgGrid
                    serverSideDatasource={this.store.dataSource}
                    columnDefs={this.store.columnDefs}
                    onGridReady={this.store.dataSource.onGridReady}
                    gridId="SelloutIdentificationReportDetailPage"
                    quickView
                    rowModelType="serverSide"
                    pagination
                    internalPagination
                    paginationPageSize={CONSTANTS.SELLOUT_IDENTIFICATION_REPORT.PAGE_SIZE}
                    cacheBlockSize={CONSTANTS.SELLOUT_IDENTIFICATION_REPORT.PAGE_SIZE}
                  />
                </>
              )}
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

export default SelloutIdentificationReportDetailPage;
