/**
 * @file Created on Fri Feb 01 2019
 * @author SKu
 */
import * as React from 'react';
import Icon, { IconColor, IconType } from '../../media/Icon';
import { Utils } from '@logio/common-be-fe';
import { CONSTANTS } from '../../../constants';
import { N } from '../../i18n/N';
/**
 * FlagsRenderer component
 * Render FlagSet as icon with tooltip
 * to add new FlagType, simply add it to FlagTypeToIconTypeEnum
 */

var IndexRenderer = function IndexRenderer(props) {
  /** Check if number is BigNumber, if true, convert it to number */
  var getConvertedValue = function getConvertedValue() {
    if (!Utils.isValueMissing(props.bigNumberValue)) {
      return props.bigNumberValue.toNumber();
    }

    return props.value;
  };

  var value = getConvertedValue();

  var getArrowIcon = function getArrowIcon() {
    if (value < 0) {
      return React.createElement(Icon, {
        iconType: IconType.arrowDown,
        iconColor: IconColor.Red,
        iconHeight: props.iconHeight
      });
    }

    if (value > 0) {
      return React.createElement(Icon, {
        iconType: IconType.arrowUp,
        iconColor: IconColor.Green,
        iconHeight: props.iconHeight
      });
    }

    return null;
  };

  return Utils.isValueMissing(value) ? React.createElement(React.Fragment, null, CONSTANTS.FORMAT.NULL) : React.createElement(React.Fragment, null, React.createElement(N, {
    key: "value",
    value: value,
    round: true
  }), " ", getArrowIcon());
};

export default IndexRenderer;