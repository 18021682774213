"use strict";
/*
 * Created on 08.07.2019
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author Tomáš Leity
 */

var PromotionSplitAggregation;

(function (PromotionSplitAggregation) {
  PromotionSplitAggregation["Day"] = "SPLIT_AGGREGATION_DAY";
  PromotionSplitAggregation["Week"] = "SPLIT_AGGREGATION_WEEK";
})(PromotionSplitAggregation = exports.PromotionSplitAggregation || (exports.PromotionSplitAggregation = {}));