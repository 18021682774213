/**
 * @file Created on Sat Oct 06 2018
 * @author LZe
 */

import {
  BindIdsDTO,
  Competitor,
  CompetitorProduct,
  CompetitorProductDTO,
  DataPairingProductRowDTO,
  StructPropBuilder,
  Utils,
  ProductInfo,
  SimpleZonePrice,
} from '@logio/common-be-fe';
import {
  ActionsGenerator,
  ColumnDefinition,
  ColumnGenerator,
  CONSTANTS,
  DataPairingStore,
  IconType,
  listOfStringsColumnDefinition,
  PageStore,
  PriceZoneStore,
  ProductCategoryStore,
  productFlagsColumnDefinition,
  ProductStore,
  rootStore,
  StoreName,
  StringMapping,
  SupplierStore,
  translate,
  LoadingState,
} from '@logio/common-fe';
import {ColGroupDef, ColumnApi, GridApi, GridReadyEvent} from 'ag-grid-community';
import debounce from 'lodash/debounce';
import {action, computed, observable, runInAction} from 'mobx';
import React from 'react';
import {packageSizeHelper} from '../../../../shared/packageSizeHelper';
import {categoryColumnGenerator, eanColumnGenerator} from './columnGenerators';

export class DataPairingPairedPageStore extends PageStore {
  /** Get dependant stores */
  productStore = rootStore.getStore(StoreName.Product) as ProductStore;
  dataPairingStore = rootStore.getStore(StoreName.DataPairing) as DataPairingStore;
  supplierStore = rootStore.getStore(StoreName.Supplier) as SupplierStore;
  categoryStore = rootStore.getStore(StoreName.ProductCategory) as ProductCategoryStore;
  priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;

  /** Data generators */
  actionsGenerator = new ActionsGenerator();
  dataPairingProductRowDTOColumnGenerator = new ColumnGenerator<DataPairingProductRowDTO>(DataPairingProductRowDTO.schema);
  productColumnGenerator = new ColumnGenerator<ProductInfo>(ProductInfo.schema);
  competitorProductDTOColumnGenerator = new ColumnGenerator<CompetitorProductDTO>(CompetitorProductDTO.schema);
  competitorProductColumnGenerator = new ColumnGenerator<CompetitorProduct>(CompetitorProduct.schema);
  priceZonePricesGenerator: ColumnGenerator<StringMapping<any>>;
  competitorReferencePricesGenerator: ColumnGenerator<StringMapping<any>>;
  modifiedPricesGenerator: ColumnGenerator<StringMapping<any>>;
  competitorColumnGenerator = new ColumnGenerator<Competitor>(Competitor.schema);

  @observable
  categoryId: string;

  @observable
  internalQuery: string = '';

  @observable
  gridApi: GridApi;

  @observable
  columnApi: ColumnApi;

  @observable
  selectedRowsCount: number = 0;

  @observable
  lockInputs: boolean = false;

  constructor(categoryId: string, internalQuery: string | string[]) {
    super();
    this.categoryId = categoryId;
    this.internalQuery = internalQuery
      ? typeof internalQuery === 'string'
        ? internalQuery
        : internalQuery.join(' ')
      : '';
  }

  rowClassRules = {
    'ag-row-top-delimiter': ({data}) => data.isFirst,
  };

  createPriceZoneGenerator = () => {
    const builder = new StructPropBuilder('PriceZonePricesBuilder');
    const description = {};
    const modifiedPrices = {};
    const competitorReferencePrices = {};
    this.priceZoneStore.listNotArchived.forEach(({id, isNational}) => {
        description[`${id}_actualRegularPrice`] = builder.bigNum(`${id}_actualRegularPrice`);
        description[`${id}_actualMarginPrice`] = builder.bigNum(`${id}_actualMarginPrice`);
        description[`${id}_actualMarginPercentage`] = builder.bigNum(`${id}_actualMarginPercentage`);
        competitorReferencePrices[`${id}_competitorReferencePrices`] = builder.bigNum(`${id}_competitorReferencePrices`);
        modifiedPrices[`${id}_modifiedPrices`] = builder.bigNum(`${id}_modifiedPrices`);
    });
    this.priceZonePricesGenerator = new ColumnGenerator<StringMapping<any>>(description);
    this.competitorReferencePricesGenerator = new ColumnGenerator<StringMapping<any>>(competitorReferencePrices);
    this.modifiedPricesGenerator = new ColumnGenerator<StringMapping<any>>(modifiedPrices);
  };

  /**
   * Return generated column definitions for ag-grid
   */
  @computed
  get columnDefs(): Array<ColumnDefinition | ColGroupDef> {
    const priceZonePricesDefinition: ColumnDefinition[] = [];
    const modifiedPrices: ColumnDefinition[] = [];
    const competitorReferencePrices: ColumnDefinition[] = [];
    this.priceZoneStore.listNotArchived.forEach(({id, name, isNational}) => {
        modifiedPrices.push({
          field: `${id}_modifiedPrices`,
          round: true,
          headerName: translate('PriceZonePricesBuilder_modifiedPrice', name),
        })
        competitorReferencePrices.push({
          field: `${id}_competitorReferencePrices`,
          round: true,
          headerName: translate('PriceZonePricesBuilder_competitorReferencePrice', name),
        })
        priceZonePricesDefinition.push(
          {
            field: `${id}_actualRegularPrice`,
            round: true,
            headerName: translate('PriceZonePricesBuilder_actualRegularPrice', name),
          },
          {
            field: `${id}_actualMarginPrice`,
            round: true,
            headerName: translate('PriceZonePricesBuilder_actualMarginPrice', name),
          },
          {
            field: `${id}_actualMarginPercentage`,
            round: true,
            headerName: translate('PriceZonePricesBuilder_actualMarginPercentage', name),
          },
        );
    });

    /** Each generator return column definition from related data description */
    return [
      ...this.productColumnGenerator.getColumnDefinitions([
        {
          field: 'name',
          pinned: 'left',
          width: 250,
        },
      ]),
      ...this.dataPairingProductRowDTOColumnGenerator.getColumnDefinitions([
        {
          field: 'purchasePrice',
          width: 90,
          round: true,
        },
        {
          field: 'openPurchasePrice',
          width: 90,
          round: true,
        },
        {
          field: 'family',
          filter: 'agSetColumnFilter',
        },
      ]),
      ...this.priceZonePricesGenerator.getColumnDefinitions(priceZonePricesDefinition),
      ...this.modifiedPricesGenerator.getColumnDefinitions(modifiedPrices),
      ...this.competitorReferencePricesGenerator.getColumnDefinitions(competitorReferencePrices),
      ...categoryColumnGenerator.getColumnDefinitions([
        {
          field: 'box',
          filter: 'agSetColumnFilter',
        },
      ]),
      ...this.productColumnGenerator.getColumnDefinitions([
        {
          field: 'supplierId',
          filter: 'agSetColumnFilter',
          valueFormatter: ({value: supplierId}) => (supplierId ? this.supplierStore.list.get(supplierId).name : null),
        },
        {
          field: 'flags',
          ...productFlagsColumnDefinition,
        },
      ]),
      ...packageSizeHelper.colDefs(this.productStore),
      this.actionsGenerator.getColumnDefinition({pinned: false, checkboxSelection: false}),
      ...this.competitorProductColumnGenerator.getColumnDefinitions([
        {
          field: 'name',
          width: 250,
        },
      ]),
      ...this.competitorProductDTOColumnGenerator.getColumnDefinitions([
        {
          field: 'ratio',
          editable: true,
          action: this.updateColumn,
        },
        {
          field: 'charger',
          editable: true,
          action: this.updateColumn,
        },
        {
          field: 'competitorPrice',
          filter: 'agNumberColumnFilter',
          round: true,
          valueFormatter: params => (params.value) ? params.value.value : null,
          filterValueGetter: params => params.data[params.colDef.colId] ? parseFloat(params.data[params.colDef.colId].value.toFixed(2)) : null,
        },
      ]),
      ...this.competitorProductColumnGenerator.getColumnDefinitions([
        {
          field: 'productSize',
          valueFormatter: params => {
            console.log(params);
            return params.value;
          },
        },
        {
          field: 'productSizeUnit',
          filter: 'agSetColumnFilter',
          width: 90,
        },
      ]),
      ...eanColumnGenerator.getColumnDefinitions([
        {
          field: 'value',
        },
        {
          field: 'eanDescription',
          hide: true,
        },
      ]),
      ...this.competitorProductColumnGenerator.getColumnDefinitions([
        {
          field: 'category',
          filter: 'agSetColumnFilter',
        },
        {
          field: 'suppliers',
          ...listOfStringsColumnDefinition,
        },
        {
          field: 'productContent',
          hide: true,
        },
        {
          field: 'productSpecification',
          hide: true,
        },
      ]),
      ...this.competitorColumnGenerator.getColumnDefinitions([
        {
          field: 'name',
          filter: 'agSetColumnFilter',
        },
      ]),
    ];
  }

  /**
   * Return generated data for ag-grid
   */
  @computed
  get rowData(): Array<StringMapping<any>> {
    const rows = [];
    this.dataPairingStore.list.forEach((productRecommended: DataPairingProductRowDTO) => {
      const priceZonePricesData = {};
      productRecommended.prices
        .toArray()
        .map(({actualRegularPrice, actualMarginPercentage, actualMarginPrice, priceZoneId}) => {
          priceZonePricesData[`${priceZoneId}_actualRegularPrice`] = actualRegularPrice;
          priceZonePricesData[`${priceZoneId}_actualMarginPercentage`] = actualMarginPercentage;
          priceZonePricesData[`${priceZoneId}_actualMarginPrice`] = actualMarginPrice;
        });

      productRecommended.competitorsProductsDTOs.forEach((competitorProductDTO: CompetitorProductDTO, index) => {
        const unpairProduct = {
          name: 'unpair',
          icon: IconType.pair,
          iconHover: IconType.unpair,
          props: {
            onClick: () =>
              this.unpair([
                new BindIdsDTO(productRecommended.productInfo.id, competitorProductDTO.competitorProduct.id),
              ]),
          },
        };
        const zoneModifiedPrices = {};
        const zoneCompetitorReferencePrices = {};

        competitorProductDTO.modifiedPrices.forEach((modPrice: SimpleZonePrice) => {
            zoneModifiedPrices[`${modPrice.zoneId}_modifiedPrices`] = modPrice.price;
        })
        competitorProductDTO.competitorReferencePrices.forEach((comPrice: SimpleZonePrice) => {
            zoneCompetitorReferencePrices[`${comPrice.zoneId}_competitorReferencePrices`] = comPrice.price;
        })

        rows.push({
          id: productRecommended.productInfo.id,
          isFirst: index === 0,
          ...this.productColumnGenerator.getColumnData(productRecommended.productInfo),
          ...this.dataPairingProductRowDTOColumnGenerator.getColumnData(productRecommended),
          ...categoryColumnGenerator.getColumnData({
            box: this.categoryStore.list.get(Utils.getCategoryIdWithLevel(productRecommended.productInfo.categoryIds, 6)).name,
          }),
          ...this.actionsGenerator.getColumnData(unpairProduct),
          ...this.priceZonePricesGenerator.getColumnData(priceZonePricesData),
          ...this.modifiedPricesGenerator.getColumnData(zoneModifiedPrices),
          ...this.competitorReferencePricesGenerator.getColumnData(zoneCompetitorReferencePrices),
          ...this.competitorProductDTOColumnGenerator.getColumnData(competitorProductDTO),
          ...this.competitorProductColumnGenerator.getColumnData(competitorProductDTO.competitorProduct),
          ...this.competitorColumnGenerator.getColumnData(competitorProductDTO.competitor),
          ...eanColumnGenerator.getColumnData(competitorProductDTO.competitorProduct.eans.last() || null),
          ...packageSizeHelper.getColumnData(productRecommended.productInfo.packageSize),
        });
      });
    });
    return rows;
  }

  @action.bound
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  @action
  onFilter = (event: React.FormEvent<HTMLInputElement>): void => {
    this.internalQuery = event.currentTarget.value;
  };

  @action
  resetFilter = (): void => {
    this.internalQuery = '';
    this.reload();
  };

  reload = () => {
    runInAction(() => {
      this.lockInputs = true;
    });
    this.gridApi.showLoadingOverlay();
    this.dataPairingStore
      .getOverview({
        categoryId: this.categoryId,
        nameOfInternalProduct: this.internalQuery,
        recommended: false,
      })
      .then((responses) => {
        this.gridApi.hideOverlay();
      })
      .catch((error) => {
        this.gridApi.hideOverlay();
        // this.messages.setError(error);
      })
      .finally(() => {
        runInAction(() => {
          this.lockInputs = false;
        });
      });
  };

  @action
  updateColumn = async (rowId, colId, value) => {
    if (colId === 'CompetitorProductDTO_ratio' || colId === 'CompetitorProductDTO_charger') {
      this.gridApi.showLoadingOverlay();
      const data = this.gridApi.getRowNode(rowId).data;
      const ratio = colId === 'CompetitorProductDTO_ratio' ? value : data.CompetitorProductDTO_ratio;
      const charger = colId === 'CompetitorProductDTO_charger' ? value : data.CompetitorProductDTO_charger;
      try {
        await this.dataPairingStore.updatePairParameters(data.id, data.CompetitorProduct_id, ratio, charger);
        this.gridApi.hideOverlay();
      } catch (error) {
        this.gridApi.hideOverlay();
        // this.messages.setError(error);
      }
    }
  };

  /**
   * Removes competitor products from the recommended list
   * @param bindIds - Array of IDs of the internal product and competitor product
   */
  unpair = (bindIds: BindIdsDTO[]): void => {
    this.gridApi.showLoadingOverlay();
    this.dataPairingStore.dataPairingLayer
      .unpairPaired(bindIds)
      .then(() => {
        this.messages.setSuccess(translate('DataPairing_productUnpaired', bindIds.length.toString()));
        this.reload();
      })
      .catch((error) => {
        this.gridApi.hideOverlay();
        // this.messages.setError(error);
      });
  };

  /** Fetches all data for this page */
  public load = async () => {
    this.dataPairingStore.list.clear();
    try {
      await Promise.all([
        this.categoryStore.getAll(),
        this.productStore.getUnits(),
        this.priceZoneStore.getAll(),
        this.supplierStore.getAll(),
        this.dataPairingStore.getOverview({
          categoryId: this.categoryId,
          nameOfInternalProduct: this.internalQuery,
          recommended: false,
        }),
      ]);
      this.createPriceZoneGenerator();
      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      this.setLoadingState(LoadingState.Error);
      // this.messages.setError(error);
    }
  };
}
