/**
 * @file Created on Thu May 09 2019
 * @author SKu
 */

import {
  AgGrid,
  Icon,
  IconColor,
  IconType,
  Loader,
  LoadingState,
  Modal,
  translate,
  ProgressBar,
  Messages,
} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';
import {Col, Row} from 'react-flexbox-grid';
import {
  ReleaseSelloutDetailCategoryComponentStore,
  SelloutDetailCategoryModalEnum,
} from '../../../stores/components/Release/ReleaseDetailCategory/ReleaseSelloutDetailCategoryComponentStore';
import ReleaseSelloutDetailCategoryTotalOverview from './ReleaseSelloutDetailCategoryTotalOverview';
import {ReleaseState, ReleaseItemRepricingMessageCode, Utils} from '@logio/common-be-fe';
import styled from 'styled-components';
import {PollingHelper} from 'stores/components/PollingHelper';

export interface Props {
  store: ReleaseSelloutDetailCategoryComponentStore;
  recalculate?: (event: any) => void;
  isRecalculationRequired?: boolean;
  recalculateActionFired?: boolean;
  pollingHelper?: PollingHelper;
}

/**
 * Release detail category Top table
 */
const ReleaseSelloutDetailCategoryItemModal: React.SFC<Props> = observer(({store, ...props}: Props) => {
  const recalculate = () => {
    if (store.productDetail) {
      return props.recalculate({
        type: 'updateModalItems',
        productId: store.productDetail.id,
        updateFunction: store.updateItemsOverviewModalData,
      });
    }
  };

  /**
   * Returns true if store has validation result of ReleaseItemStartDateBeforeTomorrow
   */
  const isReleaseItemStartDateBeforeTomorrow = (params) => {
    let error;
    if (params.data.ReleaseSelloutItemDetail_messages) {
      error = params.data.ReleaseSelloutItemDetail_messages.resultObjects.find(
        (resultObject) => resultObject.messageKey === ReleaseItemRepricingMessageCode.ReleaseItemStartDateBeforeTomorrow,
      );
    }
    return !!error;
  };

  return (
    <Modal
      title={translate(
        'sellout-product-detail',
        store.productDetail
          ? `${Utils.getCustomerId(store.productDetail.externalId)}, ${store.productDetail.name}`
          : '',
      )}
      hidden={store.isModalHidden(SelloutDetailCategoryModalEnum.Items)}
      close={store.hideModal}
      handleKeyUp={store.handleProductsNavigation}
      wide
      zIndex={10000}
    >
      <>
        <Messages />
        {props.isRecalculationRequired &&
          props.pollingHelper &&
          !props.recalculateActionFired &&
          !props.pollingHelper.progress &&
          (store.releaseStore.release.state !== ReleaseState.Released &&
            props.pollingHelper.pollingState !== LoadingState.Pending &&
            !store.releaseStore.isSelloutSimulation) && <CalcButton onClick={recalculate} />}
        {props.pollingHelper &&
          (props.pollingHelper.pollingState === LoadingState.Pending || props.recalculateActionFired) && (
            <div className="mb2">
              <ProgressBar
                percentage={props.pollingHelper.progress || 0}
                label={'release-category-recalculating-now'}
              />
            </div>
          )}
        <Row>
          <Col xs>
            <Icon
              iconType={IconType.arrowLeft}
              iconColor={IconColor.Secondary}
              onClick={store.prevProduct}
              disabled={!store.isPrevProductAvailable}
            />
          </Col>
          <Col xs>
            <h2>{store.productDetail && store.productDetail.name}</h2>
          </Col>
          <Col xs className="tar">
            <Icon
              iconType={IconType.arrowRight}
              iconColor={IconColor.Secondary}
              onClick={store.nextProduct}
              disabled={!store.isNextProductAvailable}
            />
          </Col>
        </Row>
        <>
          {store.modalLoadingState === LoadingState.Pending && <Loader />}
          <ReleaseSelloutDetailCategoryTotalOverview store={store} itemModal />
          <AgGrid
            columnDefs={store.itemsModalColumnDefs}
            rowData={store.itemsModalRowData}
            gridId={'ReleaseSelloutDetailCategoryItemModal'}
            onGridReady={store.onItemsModalGridReady}
            pinnedTopRowData={[]}
            rowSelection="multiple"
            showBulkRow
            quickView
            deltaRowDataMode={true}
            getRowNodeId={({ReleaseSelloutItemDetail_id: id}) => id}
            rowClassRules={{
              'bg-error': isReleaseItemStartDateBeforeTomorrow,
              ['bg-read-only']: (params) => {
                return params.data.ReleaseSelloutItemDetail_readonly
                  ? !!params.data.ReleaseSelloutItemDetail_readonly
                  : false;
              },
            }}
            debouncedInitialAutoHeight
          />
        </>
      </>
    </Modal>
  );
});

export default ReleaseSelloutDetailCategoryItemModal;

const StyledCalcButton = styled.button`
  position: absolute;
  top: -3rem;
  right: 4rem;
  border: none;
  cursor: pointer;
  outline: none;

  &:focus {
    outline: none;
  }
`;

const CalcButton = (props) => {
  return (
    <StyledCalcButton onClick={props.onClick}>
      <Icon iconType={IconType.calc} iconColor={IconColor.Inverse} />
    </StyledCalcButton>
  );
};
