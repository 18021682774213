"use strict";
/**
 * @file Created on Tue Feb 12 2019
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Enum of possible promotion select options. The value is used for filtering only.
 * Valid - The promotion occurs in the given period.
 * Open - The promotion started in first day of given week intervals (LOG-7162: Yes, only first day requested, not whole week interval).
 * @author BPo
 */

var PromotionSelectEnum;

(function (PromotionSelectEnum) {
  PromotionSelectEnum["Valid"] = "VALID";
  PromotionSelectEnum["Open"] = "OPEN";
})(PromotionSelectEnum = exports.PromotionSelectEnum || (exports.PromotionSelectEnum = {}));