import {
  ReleaseState,
  ReleaseType,
  CommonReleaseOverview,
  ReleaseWorkflowType,
} from '@logio/common-be-fe';
import {
  ColumnDefinition,
  getPath,
} from '@logio/common-fe';
import { computed } from 'mobx';
import { PagePathsEnum } from '../../../../shared/localization/PagePathsEnum';
import { PreviousImpactsColumnGenerator } from '../../../components/Release/columnGenerators/PreviousImpactsColumnGenerator';
import { ReopenInfoColumnGenerator } from '../../../components/Release/columnGenerators/ReopenInfoColumnGenerator';
import { AbstractReleasePageStore } from './AbstractReleasePageStore';

/**
 * Store for sellout release overview page.
 * 
 * @author Jan Prokop
 */
export class SelloutReleasePageStore extends AbstractReleasePageStore {
  /** @inheritdoc */
  protected releaseTypes = [ReleaseType.Sellout];

  // Special Ag grid stores for sellout previous open data - provides dynamic columns
  private reopenColumnGenerator = new ReopenInfoColumnGenerator(this);
  private previousImpactsColumnGenerator = new PreviousImpactsColumnGenerator(this);

  /** Return maximal total count of release opening from all releases. */
  @computed
  public get reopenCount() {
    let maxOpenCount = 1;

    this.releaseStore.overviews.forEach(overview => {
      const openCount = overview.previousOpens.size;
      if (openCount > maxOpenCount) {
        maxOpenCount = openCount;
      }
    });

    return maxOpenCount;
  }

  /** @inheritdoc */
  @computed
  public get columnDefs(): ColumnDefinition[] {
    return [
      ...super.getColumnDefs(),
      ...this.reopenColumnGenerator.getColumnDefinitions(),
      ...this.previousImpactsColumnGenerator.getColumnDefinitions(),
    ];
  }

  /**
   * @inheritdoc
   */
  protected getOverviewData(overview: CommonReleaseOverview) {
    const commonData = super.getOverviewData(overview);
    const reopenData = this.reopenColumnGenerator.getColumnData(overview);
    const previousImpactsData = this.previousImpactsColumnGenerator.getColumnData(
      overview.previousOpens.map((prevOpen, reopen) => ({ ...prevOpen, reopen})),
    );

    return {
      ...commonData,
      ...reopenData,
      ...previousImpactsData,
    };
  }

  /**
   * @inheritdoc
   */
  protected getReleaseDetailPath(overview: CommonReleaseOverview) {
    // Path depends on different release attributes
    const path =
      overview.workflowType === ReleaseWorkflowType.SelloutUrgent
        ? PagePathsEnum.ReleaseSelloutDetailCategory
        : overview.state === ReleaseState.New && overview.stockValues == null
          ? PagePathsEnum.ReleaseSelloutSettings
          : PagePathsEnum.ReleaseSelloutDetail;

    return getPath(path, overview.id);
  };
}
