import { Utils } from "@logio/common-be-fe";
import generalDoubleLevelComparator from "./generalDoubleLevelComparator";
/**
 * @file Created on September 2019
 * @author Atreo Digital s.r.o.
 * @description Custom comparator for fields rendered by ReleaseFinalPriceRenderer
 */

var releaseFinalPriceComparator = function releaseFinalPriceComparator(valA, valB) {
  return generalDoubleLevelComparator(toComparableLevel1Value(valA), toComparableLevel1Value(valB), toComparableLevel2Value(valA), toComparableLevel2Value(valB));
};

var toComparableLevel1Value = function toComparableLevel1Value(val) {
  return Utils.isValueMissing(val) || !val.hasOwnProperty("value") || Utils.isValueMissing(val.value) ? null : val.value.toNumber();
};

var toComparableLevel2Value = function toComparableLevel2Value(val) {
  return Utils.isValueMissing(val) || !val.hasOwnProperty("priceType") || Utils.isValueMissing(val.priceType) ? null : val.priceType;
};

export default releaseFinalPriceComparator;