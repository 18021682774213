/**
 * @file Created on Fri Oct 12 2018
 * @author SKu
 */

import {Release, ReleaseType, ReleaseState} from '@logio/common-be-fe';
import {getPath, LoadingState, PageStore, ReleaseStore, rootStore, StoreName, translate} from '@logio/common-fe';
import {runInAction, computed} from 'mobx';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {ReleaseHeaderStore, SettingsComponentStore} from '../../components';

export class ReleaseSettingsPageStore extends PageStore {
  /** Stores */
  releaseStore = rootStore.getStore(StoreName.Release) as ReleaseStore;
  releaseHeaderStore = new ReleaseHeaderStore(this.messages);
  settingsComponentStore = new SettingsComponentStore(this.messages.setError);

  /** Check if there is at least one included category */
  get isSomeCategoriesIncluded() {
    return Array.from(
      this.settingsComponentStore.settingsOverviewDTOStore.overview,
      ([, value]) => value.includedStats.includedInRelease,
    ).includes(true);
  }

  @computed
  get release() {
    return this.releaseStore.release;
  }

  /** Include categories to release */
  includeCategories = async () => {
    try {
      await this.settingsComponentStore.settingsOverviewDTOStore.includeCategoriesToRelease(
        this.release.id,
        this.settingsComponentStore.selectedCategoryIds,
      );
      this.updateRelease();
      this.settingsComponentStore.gridApi.deselectAll();
      runInAction(() => (this.settingsComponentStore.selectedCategoryIds = []));
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  /** Exclude categories from release */
  excludeCategories = async () => {
    try {
      await this.settingsComponentStore.settingsOverviewDTOStore.excludeCategoriesToRelease(
        this.release.id,
        this.settingsComponentStore.selectedCategoryIds,
      );
      this.settingsComponentStore.gridApi.deselectAll();
      runInAction(() => (this.settingsComponentStore.selectedCategoryIds = []));
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  /**
   * Function open new release and redirect to release detail.
   * @param {function} redirect- function that get as parameter Path, adn provide redirect
   */
  openRelease = (redirect: (path: string) => void) => async () => {
    if (this.isSomeCategoriesIncluded) {
      try {
        await this.releaseStore.open(this.release.id);
        redirect(getPath(PagePathsEnum.ReleaseDetail, this.release.id));
      } catch (error) {
        // this.messages.setError(error);
      }
    } else {
      this.messages.setInfo(translate('error-include-one-category'));
    }
  };

  /** Allows edit settings and open Release if it has not been opened yet */
  handleReadOnlyMode = () => {
    if (this.release.state !== ReleaseState.New && this.release.releaseType !== ReleaseType.Simulation) {
      this.messages.setInfo(translate('settings-read-only-mode'));
      this.setReadOnly(true);
    }
  };

  setAlerts = () => {
    this.messages.clear();
    this.messages.setValidationResult(this.release.validationResult);
  };

  updateRelease = async () => {
    await this.releaseHeaderStore.load(this.release.id);
    this.setAlerts();
  };

  /**
   * Fletches data for the page
   */
  load = async (releaseId: string) => {
    try {
      const release = await this.releaseStore.getOne(releaseId)
      await this.settingsComponentStore.load(release);
      this.handleReadOnlyMode();
      this.setAlerts();
      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      this.setLoadingState(LoadingState.Error);
      // this.messages.setError(error);
    }
  };
}
