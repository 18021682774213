/**
 * @file Created on Mon Jun 24 2019
 * @author SKu
 */
import { BigDecimal, bigdecimal } from '@logio/big-decimal';
import { PriceFormulas, Utils } from '@logio/common-be-fe';
import * as React from 'react';
import { CONSTANTS } from '../../../constants';
/**
 * @param v value
 * @param local from IN interface
 */

var localizeValue = function localizeValue(v, local) {
  return v.toNumber().toLocaleString(local || CONSTANTS.FORMAT.NUMBER);
};
/**
 * @param v - value
 * @param round defines if value should be rounded
 */


var roundValue = function roundValue(v, round) {
  return round ? PriceFormulas.roundDefault(v) : v;
};
/**
 * Localization component for number
 * Will convert passed number(BigNumber) to Localized string
 */


export var N = function N(_ref) {
  var value = _ref.value,
      local = _ref.local,
      className = _ref.className,
      round = _ref.round;

  if (Utils.isValueMissing(value)) {
    return null;
  }

  var valueAsBigNumber;

  if (typeof value === 'number') {
    valueAsBigNumber = bigdecimal(value);
  } else if (value instanceof BigDecimal) {
    valueAsBigNumber = value;
  } else {
    throw new Error("Wrong type passed to number localizator: ".concat(value, " - ").concat(typeof value));
  }

  return React.createElement("span", {
    className: className
  }, localizeValue(roundValue(valueAsBigNumber, round), local));
};