/**
 * @file Data cleaning page
 * @author VBr
 */

import {AgGrid, AgGridWrapper, Page} from '@logio/common-fe';
import {Observer, observer} from 'mobx-react';
import * as React from 'react';
import {PageNamesEnum} from '../../shared/localization/PageNamesEnum';
import {AgencyQualityEvaluationReportPageStore} from '../../stores/pages/Reports/AgencyQualityEvaluationReportPageStore';

@observer
class AgencyQualityEvaluationReportPage extends React.Component {
  store = new AgencyQualityEvaluationReportPageStore();

  componentWillMount() {
    this.store.load();
  }

  render() {
    return (
      <Page titleKey={PageNamesEnum.AgencyQualityEvaluationReport} loadingState={this.store.loadingState}>
        <Observer>
          {() => (
            <>
              <AgGridWrapper>
                <AgGrid
                  rowData={this.store.rowData}
                  columnDefs={this.store.columnDefs}
                  onGridReady={this.store.onGridReady}
                  gridId="PricingAgencyQualityEvaluationReportPage"
                  autoGroupColumnDef={this.store.autoGroupColumnDefs}
                  quickView
                />
              </AgGridWrapper>
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

export default AgencyQualityEvaluationReportPage;
