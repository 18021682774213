export enum PagePathsEnum {
  Substitution = 'PRICING-SUBSTITUTION-PATH',
  Dashboard = 'PRICING-HOME-PATH',
  Release = 'PRICING-RELEASES-PATH',
  ReleasesRegular = 'PRICING-RELEASES-REGULAR-PATH',
  ReleasesSellout = 'PRICING-RELEASES-SELLOUT-PATH',
  ReleasesRegularImpact = 'PRICING-REGULAR-IMPACT-REPORT-PATH',
  ReleasesSelloutImpact = 'PRICING-SELLOUT-IMPACT-REPORT-PATH',
  ReleasesSelloutImpactDetail = 'PRICING-SELLOUT-IMPACT-REPORT-DETAIL-PATH',
  ReleaseNew = 'PRICING-RELEASE-NEW-PATH', // OLD - to be removed
  ReleaseRegularNew = 'PRICING-RELEASE-REGULAR-NEW-PATH',
  ReleaseSelloutNew = 'PRICING-RELEASE-SELLOUT-NEW-PATH',
  ReleaseDetail = 'PRICING-RELEASE-DETAIL-PATH',
  ReleaseDetailCategory = 'PRICING-RELEASE-DETAIL-CATEGORY-PATH',
  ReleaseExport = 'PRICING-RELEASE-EXPORT-PATH',
  ReleaseSelloutDetail = 'PRICING-RELEASE-SELLOUT-DETAIL-PATH',
  ReleaseSelloutDetailCategory = 'PRICING-RELEASE-SELLOUT-DETAIL-CATEGORY-PATH',
  ReleaseSelloutExport = 'PRICING-RELEASE-SELLOUT-EXPORT-PATH',
  ReleaseSettings = 'PRICING-RELEASE-SETTINGS-PATH',
  ReleaseSelloutSettings = 'PRICING-RELEASE-SELLOUT-SETTINGS-PATH',
  DataManagement = 'PRICING-DATA-MANAGEMENT-PATH',
  PriceArchitectureCategories = 'PRICING-PRICE-ARCHITECTURE-PATH',
  PriceArchitectureProducts = 'PRICING-PRICE-ARCHITECTURE-PRODUCTS-PATH',
  DataCleaningCategories = 'PRICING-DATA-CLEANING-PATH',
  DataCleaningProducts = 'PRICING-DATA-CLEANING-PRODUCTS-PATH',
  DataUpload = 'PRICING-DATA-UPLOAD-PATH',
  DataUploadDetail = 'PRICING-DATA-UPLOAD-DETAIL-PATH',
  DataUploadNew = 'PRICING-DATA-UPLOAD-NEW-PATH',
  PriceZones = 'PRICING-PRICE-ZONES-PATH',
  DataPairing = 'PRICING-DATA-PAIRING-PATH',
  DataPairingRecommended = 'PRICING-DATA-PAIRING-RECOMMENDED-PATH',
  DataPairingUnpaired = 'PRICING-DATA-PAIRING-UNPAIRED-PATH',
  DataPairingPaired = 'PRICING-DATA-PAIRING-PAIRED-PATH',
  PriceZonesCompetitorsInZones = 'PRICING-PRICE-ZONES-COMPETITORS-IN-ZONES-PATH',
  GeneralSettingsCategories = 'PRICING-GENERAL-SETTINGS-PATH',
  Reports = 'PRICING-REPORTS-PATH',
  SelloutIdentificationReport = 'PRICING-SELLOUT-IDENTIFICATION-REPORT-PATH',
  SelloutIdentificationReportDetail = 'PRICING-SELLOUT-IDENTIFICATION-REPORT-DETAIL-PATH',
  SelloutTerminationReport = 'PRICING-SELLOUT-TERMINATION-REPORT-PATH',
  PriceSpiralReport = 'PRICING-PRICE-SPIRAL-REPORT-PATH',
  CompetitorsPriceChangeReport = 'PRICING-COMPETITORS-PRICE-CHANGE-REPORT-PATH',
  PriceChangeAndStickersReport = 'PRICING-PRICE-CHANGE-AND-STICKERS-REPORT-PATH',
  PriceInflationReport = 'PRICING-PRICE-INFLATION-REPORT-PATH',
  AgencyQualityEvaluationReport = 'PRICING-AGENCY-QUALITY-EVALUATION-REPORT-PATH',
  ImpactReport = 'PRICING-IMPACT-REPORT-PATH',
  PriceIndexReport = 'PRICING-PRICE-INDEX-REPORT-PATH',
  PriceElasticityReport = 'PRICING-PRICE-ELASTICITY-REPORT-PATH',
  Sellouts = 'PRICING-SELLOUTS-PATH',
  EndSellouts = 'PRICING-SELLOUTS-END-PATH',
  EndSelloutsForm = 'PRICING-SELLOUTS-END-FORM-PATH',
  EndSelloutsDetail = 'PRICING-SELLOUTS-END-DETAIL-PATH',
  Terminations = 'PRICING-TERMINATIONS-PATH',
  RegularSite = 'PRICING-REGULAR-SITE-PATH',
  TerminateRegularSite = 'PRICING-REGULAR-SITE-TERMINATE-PATH',
  TerminateRegularSiteForm = 'PRICING-SELLOUTS-TERMINATE-FORM-PATH',
  TerminateRegularSiteDetail = 'PRICING-REGULAR-SITE-TERMINATE-DETAIL-PATH',
  ReleaseRegularSiteDetail = 'PRICING-REGULAR-SITE-RELEASE-DETAIL-PATH',
  Settings = 'PRICING-SETTINGS-PATH',
  GeneralSettingsMatrixTable = 'PRICING-GENERAL-SETTINGS-MATRIX-TABLE-PATH',
  ReleaseMatrixTable = 'PRICING-RELEASE-MATRIX-TABLE-PATH',
  RoundingRulesSettings = 'PRICING-ROUNDING-RULES-PATH',
  RoundingRulesSettingsUnweighted = 'PRICING-ROUNDING-RULES-UNWEIGHTED-PATH',
  ConfigurationsSettings = 'PRICING-CONFIGURATIONS-SETTINGS-PATH',
  CompetitorsPrioritySettings = 'PRICING-COMPETITORS-PRIORITY-PATH',
  SelloutSettings = 'PRICING-SELLOUT-SETTINGS-PATH',
  TiersSettings = 'PRICING-TIER-SETTINGS-PATH',
  WorkflowCases = 'PRICING-WORKFLOW-CASES-PATH',
  WorkflowLocks = 'PRICING-WORKFLOW-LOCKS-PATH',
  NotFound = '*',
  PromoSalesDashboard = 'PROMO-REPORTING-SALES-DASHBOARD-PATH',
}
