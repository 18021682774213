/**
 * @file Created on Wed Apr 03 2019
 * @author SKu
 */

import {PricingPermissions, ReleaseState, ReleaseWorkflowType} from '@logio/common-be-fe';
import {Button, ButtonSize, getPath, translate, WithPermission} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';
import {match, withRouter} from 'react-router';
import {ReleasedImpactFilter} from '../../../components/Release/ReleasedImpactFilter';
import ReleaseSelloutHeader from '../../../components/Release/ReleaseHeader/ReleaseSelloutHeader';
import ReleaseReopenModal from '../../../components/Release/ReleaseReopenModal';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {ReleaseDetailModalEnum} from '../../../stores/pages/Release/ReleaseDetail/AbstractReleaseDetailPageStore';
import {ReleaseSelloutDetailPageStore} from '../../../stores/pages/Release/ReleaseDetail/ReleaseSelloutDetailPageStore';
import AbstractReleaseDetailPage from './AbstractReleaseDetailPage';
import {computed} from 'mobx';

export interface Props {
  match: match<{releaseId: string}>;
}

/**
 * Release detail page
 * Renders total overview
 */
@observer
class ReleaseSelloutDetailPage extends AbstractReleaseDetailPage {
  public store = new ReleaseSelloutDetailPageStore(this.props.history);

  public get releaseHeader() {
    return <ReleaseSelloutHeader store={this.store.releaseHeaderStore} customItems={this.releaseHeaderItems} />;
  }
  @computed
  get releaseHeaderItems() {
    /** Actions are not available in computing mode */
    if (this.isReleaseComputing) {
      return null;
    }

    const exportComponent = (this.store.release.state !== ReleaseState.New ||
      (this.store.release.state === ReleaseState.New &&
        this.store.release.workflowType === ReleaseWorkflowType.SelloutUrgent)) && (
      <Button
        buttonSize={ButtonSize.Tiny}
        onClick={() => this.props.history.push(getPath(PagePathsEnum.ReleaseSelloutExport, this.store.release.id))}
        // disabled={this.store.release && !this.store.release.isSomeReleasePartApproved()} // LOG-5063
        disabled={!this.store.release}
      >
        {translate('export-page')}
      </Button>
    );

    /** Export not available for Simulation type */
    const reopenComponent = this.store.release.workflowType !== ReleaseWorkflowType.SelloutUrgent && (
      <Button buttonSize={ButtonSize.Tiny} onClick={this.store.getOpenModalEvent(ReleaseDetailModalEnum.Reopen)}>
        {translate('Reopen')}
      </Button>
    );

    const openComponent = this.store.release.workflowType !== ReleaseWorkflowType.SelloutUrgent && (
      <Button buttonSize={ButtonSize.Tiny} onClick={this.store.openRelease}>
        {translate('Open')}
      </Button>
    );

    switch (this.store.release.state) {
      case ReleaseState.Released:
        return [
          <WithPermission permissionTypes={[PricingPermissions.SELLOUT_RELEASE_REOPEN]} key={'reopen'}>
            {reopenComponent}
          </WithPermission>,
          <ReleasedImpactFilter
            key="impactFilter"
            initialValues={this.parsedQueryFilter}
            onSubmit={this.store.onImpactFilterSubmit}
          />,
        ];
      case ReleaseState.Reopening:
        return [openComponent];
      case ReleaseState.New:
        return [
          <WithPermission permissionTypes={[PricingPermissions.SELLOUT_RELEASE_CANCEL]} key={'cancel-release'}>
            {this.cancelHeaderItem}
          </WithPermission>,
          openComponent,
          <WithPermission permissionTypes={[PricingPermissions.SELLOUT_RELEASE_EXPORT]} key={'export'}>
            {exportComponent}
          </WithPermission>,
        ];
      default:
        return [
          <WithPermission permissionTypes={[PricingPermissions.SELLOUT_RELEASE_CANCEL]} key={'cancel-release'}>
            {this.cancelHeaderItem}
          </WithPermission>,
          <WithPermission permissionTypes={[PricingPermissions.SELLOUT_RELEASE_EXPORT]} key={'export'}>
            {exportComponent}
          </WithPermission>,
        ];
    }
  }

  /** Since we do not have additional content to render, we always return null */
  public get additionalRenderContent() {
    return (
      <ReleaseReopenModal
        hidden={this.store.isModalHidden(ReleaseDetailModalEnum.Reopen)}
        close={this.store.hideModal}
        onSubmit={this.store.onReopenSubmit}
        release={this.store.release}
      />
    );
  }
}

export default withRouter(ReleaseSelloutDetailPage);
