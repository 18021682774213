"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * The type of research according to the products being researched.
 *
 * @author Jaroslav Sevcik
 */

var CodebookLookupSequence_1 = require("../../core/model/codebook/CodebookLookupSequence");

exports.RESEARCH_TYPE_LOOKUP = CodebookLookupSequence_1.CodebookLookupSequence.ResearchType;
var ResearchTypeEnum;

(function (ResearchTypeEnum) {
  ResearchTypeEnum["W2W"] = "W2W";
  ResearchTypeEnum["SENS"] = "SENS";
  ResearchTypeEnum["BASKET"] = "Basket";
  ResearchTypeEnum["FRUIT_AND_VEG"] = "Fruit and veg";
  ResearchTypeEnum["PAIRING"] = "Pairing";
  ResearchTypeEnum["LOCAL"] = "Local";
  ResearchTypeEnum["OTHERS"] = "Others";
})(ResearchTypeEnum = exports.ResearchTypeEnum || (exports.ResearchTypeEnum = {}));