import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: table;\n  width: 100%;\n  height: calc(100vh - 61px);\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Thu Aug 09 2018
 * @author VBr
 */
import styled from 'styled-components';
export default styled.div(_templateObject());