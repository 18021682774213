import React from 'react';
import {observer} from 'mobx-react';
import {Row, Col} from 'react-flexbox-grid';
import styled from 'styled-components';
import {
  OptimizationStrategyStore,
  formatPrice,
  formatPercents,
  formatQuantity,
} from '../../../stores/pages/Settings/GeneralSettings/OptimizationStrategyStore';
import {
  SplineChart,
  FormGroup,
  RadioGroup,
  Button,
  translate,
  LoadingState,
  Label,
  Icon,
  IconType,
  IconColor,
} from '@logio/common-fe';
import math from 'mathjs';
import {MatrixTablePageStore} from '../../../stores/pages/Settings/GeneralSettings/MatrixTablePageStore';
import { OptimizationGoal } from '@logio/common-be-fe';

export interface OptimizationStrategyProps {
  categoryId: string;
  releaseId: string;
  priceZoneId: string;
  optimizationGoalsSettings: OptimizationGoal;
  setOptimizationGoalSettings: (optimizationGoalsSettings) => void;
  averageMargin: number;
  setAverageMargin: (averageMargin) => void;
  store: OptimizationStrategyStore;
  matrixTableStore: MatrixTablePageStore;
}

@observer
export class OptimizationStrategy extends React.Component<OptimizationStrategyProps> {
  constructor(props) {
    super(props);
    props.store.setTargetMargin(props.averageMargin);
    props.store.setUpdateTargetMargin(props.setAverageMargin);
  }

  render() {
    const {
      categoryId,
      releaseId,
      priceZoneId,
      optimizationGoalsSettings,
      setOptimizationGoalSettings,
      averageMargin,
      setAverageMargin,
      store,
    } = this.props;
    store.setTargetMargin(averageMargin);
    if (!store.responseResult) {
      return (
        <Button
          type="button"
          onClick={store.load(categoryId, releaseId, priceZoneId, optimizationGoalsSettings)}
          disabled={store.loadingState === LoadingState.Pending || this.props.matrixTableStore.isRecalculateActionFired}
        >
          {store.loadingState === LoadingState.Pending
            ? translate('OptimizationStrategy_loading')
            : translate('OptimizationStrategy_load')}
        </Button>
      );
    }

    return (
      <>
        <Row>
          <Col sm={8}>
            <SplineChart {...store.chartSettings} data={store.chartSeries} />
          </Col>
          <Col sm={4}>
            <Row className="mb1 tac">
              <Col className="w-100">
                <FormGroup>
                  <Label style={{marginBottom: '0.5rem'}}>{translate('OptimizationStrategy_pricingGoal')}</Label>
                  <RadioGroup
                    name="OptimizationStrategy_pricingGoal"
                    value={optimizationGoalsSettings}
                    onChange={(pricingGoal) => {
                      setOptimizationGoalSettings(pricingGoal);
                      store.setPricingGoal(pricingGoal);
                    }}
                    typeButton
                    options={Object.values(OptimizationGoal).map((pricingGoal) => ({
                      label: translate(`OptimizationStrategy_pricingGoal_${pricingGoal}`),
                      value: pricingGoal,
                    }))}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mb1">
              <Card
                label={'OptimizationSummary_profit'}
                actualValue={store.responseResult.actualData.profit}
                newValue={store.summary.profit}
                type="price"
              />
              <Card
                label={'OptimizationSummary_margin'}
                actualValue={store.responseResult.actualData.margin}
                newValue={store.summary.margin}
                type="percents"
              />
            </Row>
            <Row className="mb1">
              <Card
                label={'OptimizationSummary_quantity'}
                actualValue={store.responseResult.actualData.quantity}
                newValue={store.summary.quantity}
                type="quantity"
              />
              <Card
                label={'OptimizationSummary_revenue'}
                actualValue={store.responseResult.actualData.revenue}
                newValue={store.summary.revenue}
                type="price"
              />
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

// TODO: these components should probably be elsewhere..

interface CardProps {
  label: string;
  actualValue: number;
  newValue: number;
  type: 'price' | 'percents' | 'quantity';
}

const Card = (props: CardProps) => {
  const diffValue = math
    .chain(props.newValue)
    .subtract(props.actualValue)
    .done();
  const percents = math
    .chain(diffValue)
    .divide(
      math
        .chain(props.actualValue)
        .divide(100)
        .done(),
    )
    .round(1)
    .done();
  let formattedDiffValue;

  if (props.type === 'price') {
    formattedDiffValue = formatPrice(diffValue);
  } else if (props.type === 'quantity') {
    formattedDiffValue = formatQuantity(diffValue);
  } else {
    formattedDiffValue = formatPercents(diffValue);
  }

  return (
    <Col sm={6}>
      <CardWraper>
        <CardTitle>{translate(props.label)}</CardTitle>
        <CardBody>
          {diffValue > 0 ? (
            <Icon iconType={IconType.arrowUp} iconColor={IconColor.Green} iconHeight={10} />
          ) : (
            <Icon iconType={IconType.arrowDown} iconColor={IconColor.Red} iconHeight={10} />
          )}{' '}
          {props.type === 'percents' ? (
            <>
              {formatPercents(props.actualValue)}{' '}
              <Icon iconType={IconType.arrowRight} iconColor={IconColor.Text} iconHeight={10} />{' '}
              {formatPercents(props.newValue)}
            </>
          ) : (
            <>
              {formattedDiffValue}
              <br />
              {percents > 0 ? '+ ' + formatPercents(percents) : '- ' + formatPercents(Math.abs(percents))}
              <br />
              <p className="mt1 fs-80 c-muted">
                {translate('OptimizationStrategy_actualValue')}
                <br />
                {formatPrice(props.actualValue)}
              </p>
            </>
          )}
        </CardBody>
      </CardWraper>
    </Col>
  );
};

const CardWraper = styled.div`
  height: 100%;
  text-align: center;
  border: 1px solid #efefef;
  border-radius: 0.2rem;
`;

const CardTitle = styled.div`
  line-height: 2;
  border-bottom: 1px solid #efefef;
`;

const CardBody = styled.div`
  padding: 1rem;
  padding-bottom: 0;
  line-height: 1.6;
`;
