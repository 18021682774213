/**
 * @file Created on Thu Aug 09 2018
 * @author VBr
 */
import * as React from 'react';
import { Link } from 'react-router-dom';
import { default as Icon, IconColor } from '../../../../media/Icon';
import { Utils } from '@logio/common-be-fe';
import Tooltip from '../../../../info/Tooltip';
/**
 * Checkbox Input renderer (with disabled edit of column)
 */

var ActionsRenderer = function ActionsRenderer(props) {
  var actions = props.value;

  var getTooltippedIcon = function getTooltippedIcon(action) {
    var icon = React.createElement("span", {
      style: {
        margin: '0 3px',
        cursor: 'pointer'
      }
    }, React.createElement(Icon, {
      iconType: action.icon,
      iconTypeHover: action.iconHover,
      iconHeight: 20,
      disabled: action.disabled,
      iconColor: action.iconColor ? action.iconColor : IconColor.Secondary
    }));
    return action.tooltipOverlay ? // <div className='fixed z1000'>
    React.createElement(Tooltip, {
      overlay: action.tooltipOverlay,
      children: React.createElement("div", null, icon)
    }) : // </div>
    icon;
  };

  if (Utils.isValueMissing(actions)) {
    return null;
  }

  return React.createElement(React.Fragment, null, actions.map(function (action) {
    return React.createElement("span", Object.assign({
      key: action.name
    }, action.props), action.linkProps && !action.disabled ? React.createElement(Link, Object.assign({}, action.linkProps), getTooltippedIcon(action)) : getTooltippedIcon(action));
  }));
};

export default ActionsRenderer;