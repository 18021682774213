import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: ", "rem;\n  font-size: ", ";\n\n  table {\n    border-collapse: collapse;\n  }\n\n  th,\n  td {\n    padding: ", "px;\n    border: 1px solid ", ";\n    height: ", "px;\n    background-color: ", ";\n  }\n\n  th {\n    text-transform: uppercase;\n    font-weight: normal;\n    background-color: ", ";\n    color: ", ";\n    text-align: left;\n  }\n\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  tbody tr:nth-child(even) td {\n    background-color: ", ";\n  }\n\n  tbody tr:nth-child(odd) td {\n    background-color: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Thu Aug 09 2018
 * @author VBr
 */
import styled, { css, withTheme } from 'styled-components';
var TableStriped = css(_templateObject(), function (props) {
  return props.theme.colors.even;
}, function (props) {
  return props.theme.colors.odd;
});
var StyledTable = styled.div(_templateObject2(), function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.theme.table.tableFontSize;
}, function (props) {
  return props.theme.table.tablePadding;
}, function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.table.tableRowHeight;
}, function (props) {
  return props.theme.colors.paper;
}, function (props) {
  return props.theme.colors.header;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return props.striped ? TableStriped : null;
});
export default withTheme(StyledTable);