/**
 * @file Created on Thu Nov 22 2018
 * @author LZe
 */
import * as React from 'react';
import { Link } from 'react-router-dom';
import Icon, { IconType } from '../../media/Icon';
import BackButtonPortal from './BackButtonPortal';

var BackButton = function BackButton(_ref) {
  var path = _ref.path;
  return React.createElement(BackButtonPortal, null, path ? React.createElement(Link, {
    to: path,
    replace: true
  }, React.createElement(Icon, {
    iconType: IconType.back
  })) : React.createElement(Icon, {
    iconType: IconType.back,
    className: "nav-main-item-icon-muted"
  }));
};

export default BackButton;