/**
 * @file Created on Tue Oct 23 2018
 * @author SKu
 */

import {SubstitutedUser} from '@logio/common-be-fe';
import {LoadingState, PageStore, ProfileLayer} from '@logio/common-fe';

export class SubstitutionPageStore extends PageStore {
  readonly profileLayer = new ProfileLayer();
  /** Users that will be shown in table */
  usersAllowedToSubstitute: SubstitutedUser[] = [];

  /**
   * Fetches all data for the page
   */
  load = (): void => {
    this.profileLayer
      .findSubstitutors()
      .then((users: SubstitutedUser[]) => {
        this.usersAllowedToSubstitute = users;
        this.setLoadingState(LoadingState.Success);
      })
      .catch((error) => {
        this.setLoadingState(LoadingState.Error);
        // this.messages.setError(error);
      });
  };
}
