import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: auto;\n\n  @media screen and (min-width: 500px) {\n    margin-left: 20px;\n    margin-right: 20px;\n  }\n\n  @media screen and (min-width: 1200px) {\n    width: 1100px;\n    margin-left: auto;\n    margin-right: auto;\n  }\n\n  @media screen and (min-width: 1600px) {\n    width: 1400px;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Thu Aug 09 2018
 * @author VBr
 */
import * as React from 'react';
import styled from 'styled-components';

var Skeleton = function Skeleton(props) {
  return React.createElement("div", {
    className: props.className
  }, props.children);
};

export default styled(Skeleton)(_templateObject());