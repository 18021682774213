import * as React from 'react';

import {Header, Menu, ProfileStore, rootStore, StoreName, Wrapper, RouteBlocker } from '@logio/common-fe';
import {appRoutes, IRouteParams} from 'routes-app';

import {PagePathsEnum} from '../../shared/localization/PagePathsEnum';
import NotificationRenderer from '../NotificationRenderer/NotificationRenderer';
import {match} from 'react-router';
import {PricingRoles} from '@logio/common-be-fe';

/**
 * Container for logged user state UI of the application
 */
export interface Props {
  name: string;
  match: match<IRouteParams>;
  children?: JSX.Element;
}

const AppContainer: React.SFC<Props> = (props: Props) => {
  const profileStore = rootStore.getStore(StoreName.Profile) as ProfileStore;
  const isSystemAdmin = profileStore.profile.hasRole(PricingRoles.PRICING_SYSTEM_ADMIN);

  return (
    <div id={'rsg-root'}>
      <Header
        navigation={appRoutes}
        substitutionLink={PagePathsEnum.Substitution}
        notificationRenderer={NotificationRenderer}
        isSystemAdmin={isSystemAdmin}
      />
      <Wrapper>
        <Menu navigation={appRoutes} path={props.match.path} documentationPath="https://doc.logio.ahold.net/" />
        {props.children}
        <RouteBlocker />
      </Wrapper>
    </div>
  );
};

export default AppContainer;
