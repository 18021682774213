/**
 * @file Created on Sat Feb 16 2019
 * @author BPo
 * @author SKu
 */

import {ProductPricesUpload, Utils, ValidationError} from '@logio/common-be-fe';
import {Button, Field, FileUploadAdapter, Form, FormGroupInline, Modal, ProgressBar, translate} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';
import DataUploadResult from '../DataUploadResult';

export interface Props {
  /** Upload progress */
  progress: number;
  /** Modal hidden prop */
  hidden: boolean;
  /** Function to close modal */
  close: () => void;
  /** Called on upload submit */
  upload: (values: any) => Promise<void>;
  /** FIXME: */
  uploadedData: ProductPricesUpload;
}

export const PriceUploadModal: React.SFC<Props> = observer((props: Props) => {
  /** Extracted props */
  const {progress, hidden, close, upload} = props;
  /** Field will be always required  */
  const validateField = (value: any) => Utils.isValueMissing(value) && new ValidationError('err-required');

  /** Upload field */
  const uploadForm = (
    <Form onSubmit={upload} descriptions={{}}>
      <Field name="dataFile" component={(params) => FileUploadAdapter('dataFile')(params)} validate={validateField} />
      <FormGroupInline right>
        <Button>{translate('Save')}</Button>
      </FormGroupInline>
    </Form>
  );

  return (
    <Modal hidden={hidden} close={close} title={translate('common-price-upload-modal')}>
      {progress === undefined ? (
        <>
          {props.uploadedData ? (
            /** Shows info about uploaded file(warnings, errors) */
            <>
              <DataUploadResult uploadedData={props.uploadedData} />
              <Button onClick={close}>{translate('Close')}</Button>
            </>
          ) : (
            uploadForm
          )}
        </>
      ) : (
        <ProgressBar percentage={progress} label={'ReleaseDetail-priceUpload-inProgress'} />
      )}
    </Modal>
  );
});
