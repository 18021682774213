/**
 * @file Created on Mon Sep 24 2018
 * @author LZe
 */
var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import * as React from 'react';
import { NavLink } from 'react-router-dom';
import PrivilegesControl from '../../security/PrivilegesControl';
import { RoleControl } from '../../security/RoleControl';
import StyledTabMenu from './styled/StyledTabMenu';
/**
 * Tab menu with react router links
 */

export var TabMenu = function TabMenu(_ref) {
  var tabs = _ref.tabs;
  return React.createElement(StyledTabMenu, {
    className: "tab-menu"
  }, tabs.map(function (_a, i) {
    var content = _a.content,
        roles = _a.roles,
        privileges = _a.privileges,
        linkProps = __rest(_a, ["content", "roles", "privileges"]);

    return React.createElement(RoleControl, {
      roles: roles,
      hideError: true,
      key: i
    }, React.createElement(PrivilegesControl, {
      privileges: privileges,
      hideError: true
    }, React.createElement(NavLink, Object.assign({
      className: "tab-link"
    }, linkProps), content)));
  }));
};