/**
 * @file Created on Thu Feb 06 2020
 * @author Tomáš Leity
 */
import * as React from 'react';
import { Observer } from 'mobx-react';
import Loader from '../../../../info/Loader';
import translate from '../../../../i18n/translate';
import { Utils } from '@logio/common-be-fe';
/**
 * @component BatchLoadingOverlayFramework
 */

function BatchLoadingOverlayFramework(updater) {
  return function (params) {
    return React.createElement(Observer, null, function () {
      return React.createElement(React.Fragment, null, React.createElement(Loader, {
        container: false
      }), React.createElement("div", {
        style: {
          fontWeight: 'bold',
          fontSize: 'initial'
        }
      }, updater.preText ? updater.preText : null, " ", !Utils.isValueMissing(updater.totalCount) ? "".concat(updater.count, " ").concat(translate('of'), " ").concat(updater.totalCount) : updater.count, " ", translate('rows')));
    });
  };
}

export default BatchLoadingOverlayFramework;