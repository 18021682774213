"use strict";
/*
 * Created on 31 01 2019
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author JMt
 *
 * State of the notification
 */

var NotificationState;

(function (NotificationState) {
  NotificationState["unread"] = "UNREAD";
  NotificationState["read"] = "READ";
  NotificationState["deleted"] = "DELETED";
  NotificationState["invalid"] = "INVALID";
})(NotificationState = exports.NotificationState || (exports.NotificationState = {}));