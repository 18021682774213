import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  width: ", "px;\n  height: ", "px;\n  text-align: center;\n  line-height: ", "px;\n  border-radius: 50%;\n  color: ", ";\n  font-size: 80%;\n\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
import { BadgeType } from '../BadgeType';
import styled, { css, withTheme } from 'styled-components';
var BadgeError = css(_templateObject(), function (props) {
  return props.theme.colors.error;
});
var BadgeWarning = css(_templateObject2(), function (props) {
  return props.theme.colors.warning;
});
/**
 * Create an Badge component
 */

var BadgeStyled = styled.span(_templateObject3(), function (props) {
  return props.theme.badge.width;
}, function (props) {
  return props.theme.badge.width;
}, function (props) {
  return props.theme.badge.width;
}, function (props) {
  return props.theme.colors.text;
}, function (props) {
  return props.type === BadgeType.Error ? BadgeError : BadgeWarning;
});
export default withTheme(BadgeStyled);