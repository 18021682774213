/**
 * @file Created on Fri Oct 12 2018
 * @author SKu
 */

import {PageStore, ReleaseStore, rootStore, StoreName, LoadingState, SettingsStore} from '@logio/common-fe';
import {SettingsComponentStore} from '../../components';
import {StructPropBuilder, CodebookLookupSequence} from '@logio/common-be-fe';
import {observable, runInAction} from 'mobx';
import {GridApi, GridReadyEvent} from 'ag-grid-community';

export class GeneralSettingsPageStore extends PageStore {
  /** Stores */
  settingsComponentStore = new SettingsComponentStore(this.messages.setError);

  load = () => {
    this.settingsComponentStore
      .load()
      .then(() => {
        this.setLoadingState(LoadingState.Success);
      })
      .catch((error) => {
        this.setLoadingState(LoadingState.Error);
        // this.messages.setError(error);
      });
  };
}
