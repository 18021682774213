"use strict";
/*
 * Created on 04 24 2020
 */

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var StructPropBuilder_1 = require("../data-dictionary/StructPropBuilder");
/**
 * @author Jaroslav Sevcik
 *
 * TODO Replace this class with expanded Range?
 */


var DateRange = function DateRange(from, to) {
  _classCallCheck(this, DateRange);

  this.from = from;
  this.to = to;
};

exports.DateRange = DateRange;

DateRange.schema = function () {
  var builder = new StructPropBuilder_1.StructPropBuilder(DateRange.name);
  return {
    from: builder.date('from'),
    to: builder.date('to')
  };
}();