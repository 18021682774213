/**
 * @file Created on Fri Oct 05 2018
 * @author SKu
 */

import {PricingPermissions, ReleaseType, ReleaseAction, ReleaseState} from '@logio/common-be-fe';
import {
  WithPermission,
  Button,
  ButtonSize,
  translate,
  Icon,
  IconType,
  IconColor,
  ButtonColor,
  ConfirmBox,
} from '@logio/common-fe';
import {computed} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import {withRouter} from 'react-router';
import ReleaseDetailCategoryComponent from '../../../components/Release/ReleaseDetailCategory/ReleaseDetailCategoryComponent';
import ReleaseHeader from '../../../components/Release/ReleaseHeader/ReleaseHeader';
import {ReleaseDetailCategoryPageStore} from '../../../stores/pages/Release/ReleaseDeatailCategory/ReleaseDetailCategoryPageStore';
import AbstractReleaseDetailCategoryPage from './AbstractReleaseDetailCategoryPage';
import {ReleasedImpactFilter} from 'components/Release/ReleasedImpactFilter';
import { RecalculationButton } from '../../../components/Release/ReleaseHeader/RecalculationButton';

/**
 * Release detail category page
 */
@observer
class ReleaseDetailCategoryPage extends AbstractReleaseDetailCategoryPage {
  public store = new ReleaseDetailCategoryPageStore(
    this.props.history,
    this.props.match.params.releaseId,
    this.props.match.params.categoryId,
  );

  /** Approve and Reject actions are not available for Simulation release type */
  @computed
  public get isSimulation() {
    return this.store.release.releaseType === ReleaseType.Simulation;
  }

  /** @inheritdoc */
  @computed
  public get releaseHeader() {
    return (
      <ReleaseHeader
        store={this.store.releaseHeaderStore}
        customItems={this.releaseHeaderItems}
        categoryId={this.props.match.params.categoryId}
      />
    );
  }

  /** On click, rejects whole category */
  @computed
  public get rejectHeaderItem() {
    const actions = this.store.actionFeasibilities;
    return (
      // get(1) -  reject action, should not be shown if not allowed
      actions &&
      actions.get(1).allowed && (
        <Button buttonSize={ButtonSize.Tiny} onClick={() => this.store.onAction(ReleaseAction.Reject)}>
          {translate('Reject')}
        </Button>
      )
    );
  }

  /** @inheritdoc */
  @computed
  public get releaseHeaderItems() {
    return this.store.release.state === ReleaseState.Released
      ? [
          <ReleasedImpactFilter
            key="impact"
            initialValues={this.parsedQueryFilter}
            onSubmit={this.store.releaseDetailCategoryComponentStore.onImpactFilter}
          />,
        ]
      : this.store.releaseDetailCategoryComponentStore.editMode
        ? [
            <WithPermission permissionTypes={[PricingPermissions.REGULAR_RELEASE_CATEGORY_APPROVE]} key={'approve'}>
              {!this.isSimulation && this.approveHeaderItem}
            </WithPermission>,
            <WithPermission permissionTypes={[PricingPermissions.REGULAR_RELEASE_CATEGORY_REJECT]} key={'reject'}>
              {!this.isSimulation && this.rejectHeaderItem}
            </WithPermission>,
            <WithPermission some permissionTypes={this.store.permissionsForEdit} key={'unlock'}>
              {this.unlockHeaderItem}
            </WithPermission>,
            <WithPermission permissionTypes={[PricingPermissions.REGULAR_RELEASE_RECALCULATE]} key={'recalculate'}>
              {this.store.showRecalculationButton && <RecalculationButton recalculate={this.store.recalculate} />}
            </WithPermission>,
          ]
        : [
            <WithPermission some permissionTypes={this.store.permissionsForEdit} key={'lock'}>
              {this.lockHeaderItem}
            </WithPermission>,
          ];
  }

  /** @inheritdoc */
  @computed
  get renderContent() {
    return (
      <>
        <ReleaseDetailCategoryComponent store={this.store.releaseDetailCategoryComponentStore} />
      </>
    );
  }
}

export default withRouter(ReleaseDetailCategoryPage);
