"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var StructPropBuilder_1 = require("../../core/model/data-dictionary/StructPropBuilder");
/**
 * Information about price index (of a product) for a competitor.
 *
 * @author Michal Sevcenko
 */


var CompetitorPriceIndex =
/**
 * ctor
 *
 * @param competitorId reference to the competitor
 * @param index - the CRP index of the competitor comparing with the previous week
 */
function CompetitorPriceIndex(competitorId, index) {
  _classCallCheck(this, CompetitorPriceIndex);

  this.competitorId = competitorId;
  this.index = index;
};

exports.CompetitorPriceIndex = CompetitorPriceIndex;

CompetitorPriceIndex.schema = function () {
  var builder = new StructPropBuilder_1.StructPropBuilder(CompetitorPriceIndex.name);
  return {
    competitorId: builder.str('competitorId'),
    index: builder.bigNum('index')
  };
}();