import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  line-height: 1.3;\n  position: relative;\n  padding: ", "rem;\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: ", ";\n  margin-bottom: ", "rem;\n\n  &.notice-with-icon {\n    padding-left: 60px;\n  }\n\n  & > * {\n    /* display: table-cell; */\n    vertical-align: text-top;\n\n    svg {\n      position: relative;\n      top: 10px;\n    }\n  }\n\n  .checkbox * {\n    line-height: 1.5;\n  }\n\n  .notice-alert {\n    color: ", ";\n  }\n\n  .notice-icon {\n    position: absolute;\n    top: 5px;\n    left: 10px;\n  }\n\n  .notice-close {\n    position: absolute;\n    right:10px;\n    top: 7px;\n  }\n\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  color: ", ";\n\n  svg {\n    fill: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Mon Aug 13 2018
 * @author PKl
 */
import styled, { css, withTheme } from 'styled-components';
var NoticeDisabled = css(_templateObject(), function (props) {
  return props.theme.colors.odd;
}, function (props) {
  return props.theme.colors.muted;
}, function (props) {
  return props.theme.colors.muted;
});
/**
 * Create an Notice component that'll render an <Notice>
 * @author PKl
 */

var NoticeStyled = styled.div(_templateObject2(), function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.theme.colors.even;
}, function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.common.borderRadius;
}, function (props) {
  return props.theme.spacing.spacer / 2;
}, function (props) {
  return props.theme.colors.error;
}, function (props) {
  return props.noticeDisabled ? NoticeDisabled : null;
});
export default withTheme(NoticeStyled);