import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n\n  body {\n    font-family: ", ";\n    font-size: ", ";\n    font-weight: 300;\n    color: ", ";\n  }\n\n  main {\n    display: table-cell;\n    padding: 2rem;\n    height: calc(100vh - 61px);\n    position: relative;\n    background-color: ", ";\n  }\n\n  a { color: ", "; }\n\n  strong { font-weight: 600; }\n\n  a:not([href]) {\n    cursor: default;\n  }\n\n  h1,\n  h2,\n  h3,\n  h4 {\n    font-weight: normal;\n    margin-top: 0;\n  }\n\n  p { margin-top: 0; }\n\n  input, select, textarea, button {\n    font-family: inherit;\n    font-size: inherit;\n    font-weight: inherit;\n    line-height: inherit;\n  }\n  input, select, textarea, button {\n    transition: all ease-in-out .3s;\n  }\n\n  input:focus, select:focus, textarea:focus {\n    box-shadow: ", ";\n    background-color: ", ";\n    outline: 0;\n  }\n\n  button:focus {\n    outline: 1px dotted ", ";\n    outline-offset: -5px;\n  }\n\n  ", "\n  ", "\n\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { css } from 'styled-components';
import { flexboxgrid } from './css/flexboxgrid';
import { fonts } from './css/fonts';
import { tachyons } from './css/micro-tachyons';
import { normalize } from './css/normalize';
import { reset } from './css/reset';
import { styleguide } from './css/styleguide';
import { tables } from './css/tables';
import { theme } from './theme';
export var style = css(_templateObject(), normalize, reset, fonts, tachyons, flexboxgrid, theme.font.family, theme.font.base, theme.body.color, theme.body.bg, theme.link.color, theme.common.shadow, theme.colors.focus, theme.colors.border, styleguide, tables);