/**
 * @file Created on Tue Mar 14 2020
 * @author MHl
 */

import {
  PageStore,
  ActionsGenerator,
  ColumnDefinition,
  ColumnGenerator,
  StringMapping,
  LoadingState,
  RegularSiteTerminationLayer,
  RendererNames,
  Comparators,
  FilterNames,
  getPath,
  RegularSiteTerminationItemPriceValid,
  RegularSiteTerminationItemTerminate,
  translate,
} from '@logio/common-fe';
import {observable, action, computed, runInAction} from 'mobx';
import {PriceTerminationState, SiteReleaseTermination, SiteReleaseTerminationItem, Utils} from '@logio/common-be-fe';
import {match} from 'react-router';
import {History} from 'history';
import {PagePathsEnum} from '../../../../shared/localization/PagePathsEnum';
import {List} from 'immutable';
import {PollingHelper} from '../../../components/PollingHelper';
import {ColumnApi, GridApi, GridReadyEvent, ValueGetterParams} from 'ag-grid-community';

export interface IRouteParams {
  terminationId: string;
}

export class TerminateRegularSiteDetailPageStore extends PageStore {
  pollingHelper: PollingHelper;
  constructor(
    public readonly history: History,
    public readonly match: match<IRouteParams>,
    private readonly terminationId: string,
  ) {
    super();
    this.pollingHelper = new PollingHelper(this.messages, this.onPollingStateChanged, undefined, true);
  }
  private regularSiteTerminationLayer = new RegularSiteTerminationLayer();
  private terminationItemsColumnGenerator = new ColumnGenerator(SiteReleaseTerminationItem.schema);

  @observable
  termination: SiteReleaseTermination;

  @observable
  terminationItems: Map<string, SiteReleaseTerminationItem> = new Map<string, SiteReleaseTerminationItem>();

  @observable
  isConfirmModalHidden: boolean = true;

  @observable
  isCancellModalHidden: boolean = true;

  /** Ag-Grid api references */
  private gridApi: GridApi;
  private columnApi: ColumnApi;

  /**
   * Binds ag-grid api
   */
  @action.bound
  public onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  @action
  showConfirmModal = () => (this.isConfirmModalHidden = false);

  @action
  hideConfirmModal = () => (this.isConfirmModalHidden = true);

  @action
  showCancellModal = () => (this.isCancellModalHidden = false);

  @action
  hideCancellModal = () => (this.isCancellModalHidden = true);

  @computed
  public get enableHeaderButtons(): boolean {
    return this.termination && this.termination.state === PriceTerminationState.Opened;
  }

  @computed
  public get enableConfirmButton(): boolean {
    let terminate: boolean;
    this.terminationItems && this.terminationItems.forEach((item) => (item.terminate ? (terminate = true) : null));
    return terminate;
  }

  @computed
  get terminationItemsColumnDefinitions(): ColumnDefinition[] {
    const colDefs = [
      {
        field: 'validation',
        cellRenderer: RendererNames.ReleaseAlertsRenderer,
        comparator: (a, b) => {
          if (a === b) {
            return 0;
          }
          return a === translate(`BLANKS_FILTER`) ? -1 : 1;
        },
        filterValueGetter: (params: ValueGetterParams) => {
          const messages: string[] = [];
          params.data.SiteReleaseTerminationItem_validation.resultObjects.size
            ? params.data.SiteReleaseTerminationItem_validation.resultObjects.map((obj) =>
                messages.push(translate(`${obj.severity}_${obj.messageKey}`)),
              )
            : messages.push(translate(`BLANKS_FILTER`));
          return messages;
        },
        sortable: false,
        excel: {
          hide: true,
        },
      }, // Alerts
      {
        field: 'exportResult',
        dontCreateColumn: this.termination && this.termination.state !== PriceTerminationState.Terminated,
        valueGetter: ({data}: ValueGetterParams) => {
          const info: string[] = [];
          data.SiteReleaseTerminationItem_exportResult &&
            data.SiteReleaseTerminationItem_exportResult.resultObjects.map((obj) =>
              info.push(translate(obj.messageKey)),
            );
          return info.length > 0 ? info[0] : null;
        },
      }, //Info
      {field: 'pName'}, // Article
      {field: 'sName'}, // Site name
      {field: 'sExtId'}, // Site ID
      {
        field: 'pValidTo',
        pinnedRowCellRenderer: RendererNames.BulkRenderer,
        editable: this.termination && this.termination.state === PriceTerminationState.Opened,
        action: this.updateTerminationItems,
        bulkFunc: this.updateTerminationItems,
      }, // New End Date
      {
        field: 'terminate',
        pinnedRowCellRenderer: RendererNames.BulkRenderer,
        editable: this.termination && this.termination.state === PriceTerminationState.Opened,
        action: this.updateTerminationItems,
        bulkFunc: this.updateTerminationItems,
      }, // Export
    ];
    return [...this.terminationItemsColumnGenerator.getColumnDefinitions(colDefs)];
  }

  @computed
  public get terminationItemsRowData(): Array<StringMapping<any>> {
    const rowData = [];
    this.terminationItems.forEach((item: SiteReleaseTerminationItem) =>
      rowData.push({
        ...this.terminationItemsColumnGenerator.getColumnData(item),
      }),
    );
    return rowData;
  }

  @computed
  get rowClassRulesReadOnly(): {[cssClassName: string]: (params: any) => boolean} {
    return {
      'bg-read-only': () => this.termination.state !== PriceTerminationState.Opened,
    };
  }

  /**
   * Handles both single and bulk update
   * @param selectedRow
   * @param field
   * @param newValue
   */
  @action.bound
  private async updateTerminationItems(selectedRow: string | undefined, field: string, newValue: any): Promise<any> {
    let rowIds: List<string>;
    if (selectedRow) {
      rowIds = List.of(selectedRow);
    } else {
      rowIds = this.getRowIdsForBulkUpdate();
    }

    await this.update(field, rowIds, newValue);
    return Promise.reject();
  }

  private async update(field: string, rowIds: List<string>, newValue: any): Promise<any> {
    if (field === SiteReleaseTerminationItem.schema.pValidTo.description.nameKey) {
      const updateData = [] as RegularSiteTerminationItemPriceValid[];
      rowIds.forEach((rowId) => {
        const rowNode = this.gridApi.getRowNode(rowId);
        const id = rowNode.data[SiteReleaseTerminationItem.schema.id.description.nameKey];
        updateData.push({id, pValidTo: newValue});
      });
      this.regularSiteTerminationLayer.updateTerminationItemDate(updateData).then((updatedItems) => {
        this.updateTerminationItemRowData(updatedItems);
      });
    }
    if (field === SiteReleaseTerminationItem.schema.terminate.description.nameKey) {
      const updateData: RegularSiteTerminationItemTerminate[] = [];
      rowIds.forEach((rowId) => {
        const rowNode = this.gridApi.getRowNode(rowId);
        const id = rowNode.data[SiteReleaseTerminationItem.schema.id.description.nameKey];
        updateData.push({id, terminate: newValue});
      });
      this.regularSiteTerminationLayer.updateTerminationItemTerminate(updateData).then((updatedItems) => {
        this.updateTerminationItemRowData(updatedItems);
      });
    }
  }

  updateTerminationItemRowData(updatedItems: SiteReleaseTerminationItem[]) {
    const rowNodeData: Array<StringMapping<any>> = [];
    updatedItems.forEach((item) => {
      rowNodeData.push({
        ...this.terminationItemsColumnGenerator.getColumnData(item),
      });
      //runInAction(() => this.terminationItems.set(item.id, item))
    });
    const pinnedRowCount = this.gridApi.getPinnedTopRowCount();
    const pinnedRowData = Array.from({length: pinnedRowCount}, (n, index) => {
      return this.gridApi.getPinnedTopRow(index).data;
    });
    this.gridApi.updateRowData({update: rowNodeData});
    this.gridApi.setPinnedTopRowData(pinnedRowData);
  }

  /**
   * Returns list of node ids
   * Either of selected nodes or all nodes
   */
  @action.bound
  private getRowIdsForBulkUpdate(): List<string> {
    const selected = List(this.gridApi.getSelectedNodes());
    if (selected.size > 0) {
      return selected.map((node) => node.id);
    } else {
      let ids = List();
      this.gridApi.forEachNodeAfterFilter((node) => (ids = ids.push(node.id)));
      return ids;
    }
  }

  confirmTermination = async (): Promise<void> => {
    try {
      await this.regularSiteTerminationLayer.confirmTermination(this.terminationId).then((termination) => {
        runInAction(() => (this.termination = termination));
        this.messages.setValidationResult(termination.validation);
        this.hideConfirmModal();
        this.checkTerminationProgress();
      });
    } catch (error) {
      this.hideConfirmModal();
    }
  };

  checkTerminationProgress = () => {
    if (this.termination.state === PriceTerminationState.Terminating && this.termination.progressId) {
      this.pollingHelper.startPolling(this.termination.progressId, 'termination-successful');
    }
  };

  getTermination = async () => {
    await this.regularSiteTerminationLayer.getTerminationById(this.terminationId).then((termination) => {
      runInAction(() => (this.termination = termination));
    });
  };

  getTerminationItems = async () => {
    await this.regularSiteTerminationLayer.getTerminationItems(this.terminationId).then((terminationItems) => {
      const terminationItemsMap: Map<string, SiteReleaseTerminationItem> = new Map<
        string,
        SiteReleaseTerminationItem
      >();
      for (const terminationItem of terminationItems) {
        terminationItemsMap.set(terminationItem.id, terminationItem);
      }
      runInAction(() => (this.terminationItems = terminationItemsMap));
    });
  };

  cancelTermination = async (): Promise<void> => {
    try {
      await this.regularSiteTerminationLayer.cancelTermination(this.terminationId).then((termination) => {
        runInAction(() => (this.termination = termination));
        this.hideCancellModal();
      });
    } catch (error) {
      this.hideCancellModal();
    }
  };

  public load = async (): Promise<void> => {
    this.setLoadingState(LoadingState.Pending);
    try {
      await this.getTermination();
      await this.getTerminationItems();
      this.checkTerminationProgress();
      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      this.setLoadingState(LoadingState.Error);
    }
  };

  private onPollingStateChanged = (pollingState: LoadingState) => {
    if (pollingState === LoadingState.Success) {
      this.load();
    }
  };
}
