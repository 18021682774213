/**
 * @file Created on Fri Oct 05 2018
 * @author SKu
 */

import {Permission, PricingPermissions, ReleaseType, Utils, AbstractRelease, ReleaseState} from '@logio/common-be-fe';
import {
  Button,
  ButtonColor,
  Circle,
  CircleWidth,
  Divider,
  DividerType,
  Form,
  FormGroup,
  FormGroupInline,
  getDateFormat,
  Icon,
  IconColor,
  IconType,
  logger,
  Modal,
  Tooltip,
  translate,
  WithPermission,
} from '@logio/common-fe';
import {computed} from 'mobx';
import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import {AbstractReleaseHeaderStore} from '../../../stores/components';

export interface ComponentProps<T> {
  store: T;
  /** Custom icons , shown in the right side of header */
  customItems?: JSX.Element[];
}

/** Release Header, used on every release page */
abstract class AbstractReleaseHeader<T extends AbstractReleaseHeaderStore, S = {}> extends React.Component<
  ComponentProps<T> & S & RouteComponentProps<AbstractReleaseHeader<T>>
> {
  /** HOF for history push
   * Redirects to passed path
   * @param path react router path
   */
  public historyPush = (path: string) => () => {
    this.props.history.push(path);
  };

  /**
   * Function returns JSX element prepared for tooltip info
   * @param key
   * @returns JSX.Element
   */
  public getTooltipItem = (keys: string[]) => {
    let item: any = this.release;
    let lastKey: string;
    let propertyExists = true;
    let value;
    for (let key of keys) {
      lastKey = key;
      if (item && key in item) {
        item = item[key];
      } else {
        propertyExists = false;
      }
    }
    if (!propertyExists || Utils.isValueMissing(item)) {
      return null;
    }
    if (Utils.isMoment(item)) {
      value = item.format('YYYY-MM-DD');
    } else {
      value = item.toString();
    }

    if (lastKey === 'lastModified') {
      return (
        <FormGroup>
          {translate('last-saved')}{' '}
          <strong>
            {`${this.release.lastModified.format(getDateFormat())} ${translate('by')} ${
              this.release.lastModifiedBy.name
            }`}
          </strong>
        </FormGroup>
      );
    }
    return (
      <FormGroup>
        {translate(`Release_${lastKey}`)}: <strong> {value}</strong>
      </FormGroup>
    );
  };

  /** Current release */
  public abstract get release(): AbstractRelease;

  /** Handling Release edit header permissions */
  public get editHeaderPermissions(): Permission[] {
    switch (this.release.releaseType) {
      case ReleaseType.Regular:
        return [PricingPermissions.REGULAR_RELEASE_EDIT_HEADER];
      case ReleaseType.Simulation:
        return [PricingPermissions.SIMULATION_RELEASE_EDIT_HEADER];
      case ReleaseType.Sellout:
        return [PricingPermissions.SELLOUT_RELEASE_EDIT_HEADER];
      default:
        logger.error('Wrong Release Type');
    }
  }

  @computed
  public get headerEditModal() {
    const {store} = this.props;
    return (
      <Modal title={translate(`edit-release-header`)} hidden={store.modalHidden} close={store.hideModal}>
        <Form
          onSubmit={store.onEditHeaderSubmit}
          descriptions={store.descriptions}
          customDefinition={store.customDefinition}
          initialValues={store.release}
        >
          {this.formContent}
          <FormGroupInline right>
            <Button buttonColor={ButtonColor.Light} onClick={store.hideModal} type="button">
              {translate('Cancel')}
            </Button>
            <Button type="submit">{translate('Save')}</Button>
          </FormGroupInline>
        </Form>
      </Modal>
    );
  }

  /** Returns vertical divider */
  public get divider() {
    return <Divider type={DividerType.StatusVertical} />;
  }

  /** Renders release state */
  public get releaseStateRenderer() {
    return (
      <div className="status-bar-item">
        <Circle color={translate(this.release.state)} width={CircleWidth.Big} />
        <span className="ml1 status-bar-value">{translate(this.release.state)}</span>
      </div>
    );
  }

  /* Renders star, that allows mark release as favorite */
  public get releaseFavoriteRenderer() {
    return (
      <div className="status-bar-item">
        <Icon
          iconColor={IconColor.Text}
          iconType={this.release.favourite ? IconType.newsFull : IconType.news}
          onClick={this.props.store.setReleaseFavorability}
        />
      </div>
    );
  }

  /* Icon with info about current release */
  public get releaseInfoRenderer() {
    return (
      <div className="status-bar-item">
        <Tooltip
          overlay={this.tooltipInfo}
          placement="bottom"
          children={
            <div>
              <Icon iconType={IconType.info} iconColor={IconColor.Text} />
            </div>
          }
        />
      </div>
    );
  }

  /* Edit icon opens modal */
  public get editIconRenderer() {
    return (
      <WithPermission permissionTypes={this.editHeaderPermissions}>
        <div className="status-bar-item">
          <Icon
            iconType={IconType.edit}
            onClick={this.props.store.openModal}
            iconColor={IconColor.Text}
            disabled={this.release.state === ReleaseState.Computing}
          />
        </div>
      </WithPermission>
    );
  }

  /* Link to the Release detail  */
  public get detailIconRenderer() {
    return (
      <div className="status-bar-item">
        <Icon
          iconColor={IconColor.Text}
          iconType={IconType.home}
          disabled={this.props.location.pathname === this.detailPath}
          onClick={this.historyPush(this.detailPath)}
        />
      </div>
    );
  }

  /* Export to excel  */
  public get exportIconRenderer() {
    return (
      <div className="status-bar-item">
        <Tooltip
          overlay={translate('ReleaseDetail_ExportTooltip')}
          placement="bottom"
          noHeight
          children={
            <div>
              <Icon
                iconColor={IconColor.Text}
                iconType={IconType.exportToExcel}
                disabled={this.props.store.exportIsLoading}
                onClick={() => this.props.store.export()}
              />
            </div>
          }
        />
      </div>
    );
  }

  /* Info about who last modified release  */
  public get lastModifiedRenderer() {
    return (
      <div className="status-bar-item">
        <span>
          <span className="status-bar-label"> {translate('last-saved')}</span>
          <span className="status-bar-value">
            <strong>{this.release.lastModified.format(getDateFormat())} </strong>
            {translate('by')}
            <strong> {this.release.lastModifiedBy.name}</strong>
          </span>
        </span>
      </div>
    );
  }

  /* Important info  */
  public get cmDeadlineRenderer() {
    if (Utils.isValueMissing(this.release.cmDeadline)) {
      return null;
    }
    return (
      <div className="status-bar-item">
        <span>
          <span className="status-bar-label"> {translate('deadline')}</span>
          <span className="status-bar-value">
            <strong>{this.release.cmDeadline.format(getDateFormat())}</strong>
          </span>
        </span>
      </div>
    );
  }

  /* Important info   */
  public get priceValidityStartDateRenderer() {
    if (Utils.isValueMissing(this.release.priceValidityStartDate)) {
      return null;
    }
    return (
      <div className="status-bar-item">
        <span>
          <span className="status-bar-label"> {translate('valid-from')}</span>
          <span className="status-bar-value">
            <strong>{this.release.priceValidityStartDate.format(getDateFormat())}</strong>
          </span>
        </span>
      </div>
    );
  }

  /* Custom cions/buttons that will be rendered in the right corner  */
  public get customItemsRenderer() {
    if (Utils.isValueMissing(this.props.customItems)) {
      return null;
    }
    const customItems = this.props.customItems.map((item, index) => {
      return item ? (
        <div className="status-bar-item" key={index}>
          {item}
        </div>
      ) : null;
    });
    return (
      <>
        <div className="flex-grow-1">&nbsp;</div>
        {customItems}
      </>
    );
  }

  /** Path to release detail(depending on release type) */
  public abstract get detailPath(): string;
  /** Returns element for tooltip overlay */
  public abstract get tooltipInfo(): JSX.Element;
  /** Returns fields for edit header form inside modal */
  public abstract get formContent(): JSX.Element;
  public abstract get exportPath(): string;
}

export default AbstractReleaseHeader;
