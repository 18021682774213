import {
  SelloutImpactReportFilterDTO,
  ReleaseWorkflowType,
  CommonReleaseOverview,
  ReleaseType,
  SelloutRelease,
} from '@logio/common-be-fe';
import {
  ActionsGenerator,
  ColumnDefinition,
  ExtendedGridReadyEvent,
  getPath,
  IconType,
  LoadingState,
  PageStore,
  PriceZoneStore,
  ProductStore,
  ReportLayer,
  rootStore,
  StoreName,
  StringMapping,
  translate,
} from '@logio/common-fe';
import { GridApi } from 'ag-grid-community';
import { List } from 'immutable';
import { action, computed, observable, runInAction } from 'mobx';
import moment from 'moment';
import { PagePathsEnum } from '../../../../shared/localization/PagePathsEnum';
import { ReleaseColumnGenerator } from '../../../components/Release/columnGenerators/ReleaseColumnGenerator';
import { ReopenInfoColumnGenerator } from '../../../components/Release/columnGenerators/ReopenInfoColumnGenerator';
import { PreviousImpactsColumnGenerator } from '../../../components/Release/columnGenerators/PreviousImpactsColumnGenerator';
import { History } from 'history';

/**
 * Represents state of Sellout Impact Report Detail generation.
 */
export enum SelloutImpactReportDetailGenerationState {
  /** Nothing is being generated - we are waiting for user to click generate button */
  Pending = 'Pending',

  /** Report generating has been requested, we are waiting for progress monitor */
  Preparing = 'Preparing',

  /** Report is being generated */
  Generating = 'Generating',
}

export class SelloutImpactReportPageStore extends PageStore {
  /**
   * Default filter of the report. After loading this will never change.
   */
  public defaultFilter: SelloutImpactReportFilterDTO;

  /**
   * If true, AG grid table with the report should be rendered - in the initial state it isn't.
   */
  @observable
  public reportVisible = false;

  /**
   * True if filter should be visible.
   */
  @observable
  public filterVisible = true;

  /**
   * AG grid API for manipulation - try to use as little as possible. Declarative control is much more reliable.
   */
  private gridApi: GridApi;

  /**
   * ColumnGenerator to generate report action column.
   */
  private actionsGenerator = new ActionsGenerator();

  private releaseColumnGenerator = new ReleaseColumnGenerator();

  private reopenColumnGenerator = new ReopenInfoColumnGenerator(this, true);
  private previousImpactsColumnGenerator = new PreviousImpactsColumnGenerator(this);

  /**
   * Price zone store to get national zone id for filter.
   */
  private priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;

  /**
   * Product store for validation of products.
   */
  public productStore = rootStore.getStore(StoreName.Product) as ProductStore;

  /**
   * Report API layer for getting row data.
   */
  private reportLayer = new ReportLayer();

  /**
   * Rows of the report matching the current filter.
   */
  @observable
  private rows = List<CommonReleaseOverview>();

  /**
   * Contains currently selected rows - releases.
   */
  @observable
  public selectedReleases = List<string>();

  /**
   * Contains currently active filter (state of the filter for the current result).
   */
  public activeFilter: SelloutImpactReportFilterDTO;

  /**
   * If report detail is being generated, contains id of the report.
   */
  @observable
  public generationState = SelloutImpactReportDetailGenerationState.Pending;

  /**
   * If report detail is being generated, contains id of the report.
   */
  @observable
  public generatedReportId: string | null = null;

  /**
   * If report detail is being generated, contains progress monitor id of the report.
   */
  @observable
  public generatedReportProgressMonitorId: string | null = null;


  /** Return maximal total count of release opening from all releases. */
  @computed
  public get reopenCount() {
    let maxOpenCount = 1;

    this.rows.forEach(overview => {
      const openCount = overview.previousOpens.size;
      if (openCount > maxOpenCount) {
        maxOpenCount = openCount;
      }
    });

    return maxOpenCount;
  }

  constructor(private history: History) {
    super();
    this.load();
  }

  /**
   * Fetches data sellout termination report
   */
  public async load() {
    try {
      await this.priceZoneStore.getAll();

      this.defaultFilter = SelloutImpactReportFilterDTO.fromDataDict({
        dateFrom: moment().subtract(14, 'day').startOf('day'),
        dateTo: moment().subtract(1, 'day').endOf('day'),
        priceZoneId: this.priceZoneStore.nationalZone.id,
      } as any);

      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      this.setLoadingState(LoadingState.Error);
    }
  };

  @action.bound
  public async findReport(filter: SelloutImpactReportFilterDTO) {
    this.reportVisible = true;
    this.activeFilter = filter;

    if (this.gridApi) {
      this.gridApi.showLoadingOverlay();
    }
    
    try {
      const rows = await this.reportLayer.getSelloutImpactReport(filter);
      runInAction(() => {
        this.rows = rows;
      });
    } catch(error) {
      // ?
    } finally {
      this.gridApi.hideOverlay();
    }
  }

  /**
   * Return generated column definitions of the report for ag-grid
   */
  @computed
  public get reportColumnDefs(): ColumnDefinition[] {
    const columns = this.releaseColumnGenerator.getColumnDefinitions([ReleaseType.Sellout], false);
    const fieldsToDisplay = [
      '_name',
      '_state',
      '_workflowType',
      '_priceListType',
      '_daysSinceFirstRepricing',
      '_daysSinceLastRepricing',
      '_availableSupplyBeforeRepricing',
      '_availableSupply',
      '_availableSupplyChange',
      '_availableSupplyChangePercent',
      '_availableSupplyBeforeRepricingPrice',
      '_availableSupplyPrice',
      '_availableSupplyPriceChange',
      '_availableSupplyPriceChangePercent',
      '_priceValidityStartDate',
      '_endDate',
      '_minPriceChangeInPercent',
      '_maxPriceChangeInPercent',
      '_minMarginChangeInPercent',
      '_discountPercent',
    ];

    return [
      this.actionsGenerator.getColumnDefinition({
        width: 110,
      }),
      ...columns.filter(col => fieldsToDisplay.some(field => col.field.indexOf(field) >= 0)),
      ...this.reopenColumnGenerator.getColumnDefinitions(),
      ...this.previousImpactsColumnGenerator.getColumnDefinitions(),
    ];
  }

  /**
   * Return generated row data of the report for ag-grid
   */
  @computed
  public get reportRowData(): Array<StringMapping<any>> {
    const rowData = this.rows.map(overview => {
      const releaseDetail = {
        name: 'releaseDetail',
        icon: IconType.view,
        linkProps: { to: this.getReleaseDetailPath(overview.id, overview.workflowType) },
      };

      return {
        ...this.actionsGenerator.getColumnData(releaseDetail),
        ...this.releaseColumnGenerator.getColumnData({
          ...overview,
          state: translate(`${overview.state}`),
          // ...this.getRepricingDaysData(overview),
        }),
        ...this.releaseColumnGenerator.getColumnData(overview.stockValues),
        ...this.reopenColumnGenerator.getColumnData(overview),
        ...this.previousImpactsColumnGenerator.getColumnData(
          overview.previousOpens.map((prevOpen, reopen) => ({ ...prevOpen, reopen}))
        ),
      };
    });

    return rowData.toArray();
  }

  @action.bound
  public onGridReady(grid: ExtendedGridReadyEvent) {
    this.gridApi = grid.api;
    this.gridApi.showLoadingOverlay();
  }

  @action.bound
  public toggleFilter() {
    this.filterVisible = !this.filterVisible;
  }

  @action.bound
  public onSelectionChanged(params: { api: GridApi }) {
    const selectedRows = params.api.getSelectedRows();
    this.selectedReleases = List(selectedRows.map(row => row[SelloutRelease.schema.id.description.nameKey]));
  }

  @action.bound
  public async generateReport() {
    this.generationState = SelloutImpactReportDetailGenerationState.Preparing;
    const result = await this.reportLayer.generateSelloutImpactReportDetail(this.activeFilter, this.selectedReleases);

    runInAction(() => {
      this.generationState = SelloutImpactReportDetailGenerationState.Generating;
      this.generatedReportId = result.reportId;
      this.generatedReportProgressMonitorId = result.progress.id;
    });
  }

  @action.bound
  public async reportReady() {
    this.generatedReportProgressMonitorId = null;
    this.generationState = SelloutImpactReportDetailGenerationState.Pending;
    this.history.push(getPath(PagePathsEnum.ReleasesSelloutImpactDetail, this.generatedReportId));
  }

  @action.bound
  public async reportFailedToGenerate() {
    this.generatedReportProgressMonitorId = null;
    this.generationState = SelloutImpactReportDetailGenerationState.Pending;
    this.generatedReportId = null;
    // If error is not shown, create it here.
  }

  /**
   * @inheritdoc
   */
  protected getReleaseDetailPath(releaseId: string, workflowType: ReleaseWorkflowType) {
    const path = workflowType === ReleaseWorkflowType.SelloutUrgent ? 'ReleaseSelloutDetailCategory' : 'ReleaseSelloutDetail';
    return getPath(PagePathsEnum[path], releaseId);
  };
}
