/**
 * @file Created on Thu Aug 30 2018
 * @author LZe
 */
import * as React from 'react';
import Button, { ButtonSize } from '../../../../controls/Button';
import FormGroup from '../../../../forms/FormGroup/FormGroup';
import { T } from '../../../../i18n/T';

var FilterWrapper = function FilterWrapper(props) {
  var onClear = props.onClear,
      onApply = props.onApply,
      children = props.children; // FIXME: LZe -> PKl: Add styled version

  return React.createElement("div", {
    className: props.className,
    style: {
      flexDirection: 'column',
      padding: '1rem 1rem 0'
    }
  }, children, ' ', React.createElement(FormGroup, {
    noLabel: false
  }, React.createElement("div", {
    className: "tar"
  }, React.createElement(Button, {
    buttonSize: ButtonSize.Tiny,
    onClick: onClear
  }, React.createElement(T, {
    id: "Clear filter"
  })))));
};

export default FilterWrapper;