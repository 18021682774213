/**
 * @file Created on Fri Oct 05 2018
 * @author SKu
 */

import {computed} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import {withRouter} from 'react-router';
import ReleaseSelloutDetailCategoryComponent from '../../../components/Release/ReleaseDetailCategory/ReleaseSelloutDetailCategoryComponent';
import ReleaseSelloutHeader from '../../../components/Release/ReleaseHeader/ReleaseSelloutHeader';
import {ReleaseSelloutExportPageStore} from '../../../stores/pages/Release/ReleaseExport/ReleaseSelloutExportPageStore';
import AbstractReleaseExportPage from './AbstractReleaseExportPage';
import {ReleaseState} from "@logio/common-be-fe";
import {ProgressBar} from "@logio/common-fe";

/**
 * Release export page
 * Almost the same as category detail, but shows all approved categories
 */
@observer
class ReleaseSelloutExportPage extends AbstractReleaseExportPage {
  public store = new ReleaseSelloutExportPageStore(this.props.history, this.props.match.params.id);

  @computed
  public get releaseHeader() {
    return <ReleaseSelloutHeader store={this.store.releaseHeaderStore} customItems={this.releaseHeaderItems} />;
  }

  @computed
  public get renderContent() {
    return this.store.release.state === ReleaseState.ExportChecking ? (
      <div className="mb2">
        <ProgressBar percentage={this.store.progress} label={'release-export-preparation'} />
      </div>
    ) : (
      <ReleaseSelloutDetailCategoryComponent store={this.store.releaseDetailCategoryComponentStore} export/>
    );
  }
}

export default withRouter(ReleaseSelloutExportPage);
