"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var BigDecimal_1 = require("./BigDecimal");

var BigDecimalNaN_1 = require("./BigDecimalNaN");

var BigDecimalNInf_1 = require("./BigDecimalNInf");

var BigDecimalPInf_1 = require("./BigDecimalPInf");

var RealBigDecimal_1 = require("./RealBigDecimal");

var nan = new BigDecimalNaN_1.BigDecimalNaN();
var pinf = new BigDecimalPInf_1.BigDecimalPInf();
var ninf = new BigDecimalNInf_1.BigDecimalNInf();
var zero = new RealBigDecimal_1.RealBigDecimal(BigInt(0), 0);
var one = new RealBigDecimal_1.RealBigDecimal(BigInt(1), 0);

function bigdecimal(n, skipDownscale) {
  switch (typeof n) {
    case 'number':
      return convertNumber(n, skipDownscale);

    case 'string':
      switch (n) {
        case 'NaN':
          return nan;

        case 'Infinity':
          return pinf;

        case '-Infinity':
          return ninf;

        case '0':
          return zero;

        case '1':
          return one;

        default:
          return parseString(n, skipDownscale);
      }

    case 'boolean':
      return n ? BigDecimal_1.BigDecimal.ONE : BigDecimal_1.BigDecimal.ZERO;

    case 'bigint':
      return new RealBigDecimal_1.RealBigDecimal(n, 0);

    case 'object':
      if (n === null) {
        return BigDecimal_1.BigDecimal.ZERO;
      }

      if (n instanceof BigDecimal_1.BigDecimal) {
        return n;
      }

    case 'undefined':
      return BigDecimal_1.BigDecimal.NaN;

    default:
      throw new Error('Unsupported value: ' + n);
  }
}

exports.bigdecimal = bigdecimal;
var maxNumber = Math.pow(10, BigDecimal_1.maxInt.toString().length - 1 - BigDecimal_1.maxScale);

function convertNumber(n, skipDownscale) {
  if (isNaN(n)) {
    return BigDecimal_1.BigDecimal.NaN;
  }

  if (!isFinite(n)) {
    return n < 0 ? BigDecimal_1.BigDecimal.NInf : BigDecimal_1.BigDecimal.PInf;
  }

  var an = Math.abs(n);

  if (an > maxNumber) {
    throw new Error('Number too big: ' + n);
  }

  var anf = Math.floor(an);
  var iv = BigInt(anf);

  if (an === anf) {
    return new RealBigDecimal_1.RealBigDecimal(n < 0 ? -iv : iv, 0);
  }

  var power = BigDecimal_1.bigPowerTen(BigDecimal_1.maxScale);
  iv = iv * power + BigInt(Math.round((an - anf) * Number(power)));
  var ret = new RealBigDecimal_1.RealBigDecimal(n < 0 ? -iv : iv, BigDecimal_1.maxScale);
  return skipDownscale ? ret : ret.downscale();
} // tslint:disable:no-bitwise


var power32 = BigInt(1 << 30) * BigInt(4);
var power64 = power32 * power32;
var power96 = power64 * power32;

function bigDecimalFromDecimal128(bytes) {
  var m = bytes.readUInt8(15);

  if ((m & 0x7c) === 0x7c) {
    return BigDecimal_1.BigDecimal.NaN;
  }

  var sign = (m & 0x80) > 0 ? -1 : 1;

  if ((m & 0x78) === 0x78) {
    return sign < 0 ? BigDecimal_1.BigDecimal.NInf : BigDecimal_1.BigDecimal.PInf;
  }

  var ed = bytes.readUInt32LE(12);
  var exp;

  if ((m & 0x60) === 0x60) {
    //twoHighestCombinationBitsAreSet
    exp = ((ed & 0x1fffe000) >>> 15) - 6176;
    return BigDecimal_1.BigDecimal.ZERO; //.scale(-exp)
  }

  exp = ((ed & 0x7fff8000) >>> 17) - 6176;

  if (exp < -34 - BigDecimal_1.maxScale) {
    //Even with D128 max precision, number is too small to be represented by BigDecimal
    return BigDecimal_1.BigDecimal.ZERO;
  }

  if (exp > BigDecimal_1.maxPrecision - BigDecimal_1.maxScale) {
    console.error('Converting D128 with too big exponent: ' + JSON.stringify(bytes)); //Number > 10^20 can not be safely represented by BigDecimal

    return sign < 0 ? BigDecimal_1.BigDecimal.NInf : BigDecimal_1.BigDecimal.PInf;
  }

  var bn = biZero;
  var i = ed & 0x0001ffff;

  if (i !== 0) {
    bn = power96 * BigInt(i);
  }

  i = bytes.readUInt32LE(8);

  if (i !== 0) {
    bn = bn + power64 * BigInt(i);
  }

  i = bytes.readUInt32LE(4);

  if (i !== 0) {
    bn = bn + power32 * BigInt(i);
  }

  i = bytes.readUInt32LE(0);
  bn = bn + BigInt(i);

  if (bn === biZero) {
    if (sign < 0) {
      console.error('Can not express negative zero');
    }

    return BigDecimal_1.BigDecimal.ZERO;
  }

  if (-exp > BigDecimal_1.maxScale) {
    bn = bn / BigDecimal_1.bigPowerTen(-exp - BigDecimal_1.maxScale);
    exp = -BigDecimal_1.maxScale;

    if (bn === biZero) {
      return BigDecimal_1.BigDecimal.ZERO;
    }
  }

  var scale = 0;

  if (exp > 0) {
    bn = BigDecimal_1.bigMultiplyPowerTen(bn, exp);
  }

  if (bn > BigDecimal_1.maxInt) {
    console.error('Converting D128 with too big base: ' + JSON.stringify(bytes));
    return sign < 0 ? BigDecimal_1.BigDecimal.NInf : BigDecimal_1.BigDecimal.PInf;
  }

  if (exp < 0) {
    scale = -exp;
  }

  if (bn === biZero) {
    return BigDecimal_1.BigDecimal.ZERO;
  }

  if (sign < 0) {
    bn = -bn;
  }

  return new RealBigDecimal_1.RealBigDecimal(bn, scale);
}

exports.bigDecimalFromDecimal128 = bigDecimalFromDecimal128;
var one32times = BigInt('4294967295'); //BigInt(2) ** BigInt(32) - BigInt(1);

var one17times = BigInt('131071'); //BigInt(2) ** BigInt(17) - BigInt(1);

var biZero = BigInt(0);
var bi32 = BigInt(32);
var bi10 = BigInt(10);

function bigDecimalToDecimal128(bd, bytes) {
  var sign = 1;
  var last = 0;

  if (bd instanceof RealBigDecimal_1.RealBigDecimal) {
    var intVal = bd.intVal;

    if (intVal < biZero) {
      sign = -1;
      intVal = -intVal;
    }

    bytes.writeUInt32LE(Number(intVal & one32times), 0);
    intVal = intVal >> bi32;
    bytes.writeUInt32LE(Number(intVal & one32times), 4);
    intVal = intVal >> bi32;
    bytes.writeUInt32LE(Number(intVal & one32times), 8);
    intVal = intVal >> bi32;

    if (intVal > one17times) {
      throw new Error('Overflow');
    }

    last = Number(intVal & one17times) | -bd.scale + 6176 << 17;
  } else {
    bytes.writeUInt32LE(0, 0);
    bytes.writeUInt32LE(0, 4);
    bytes.writeUInt32LE(0, 8);

    if (bd instanceof BigDecimalNaN_1.BigDecimalNaN) {
      last = 0x7c << 24;
    } else if (bd instanceof BigDecimalPInf_1.BigDecimalPInf) {
      last = 0x78 << 24;
    } else if (bd instanceof BigDecimalNInf_1.BigDecimalNInf) {
      last = 0x78 << 24;
      sign = -1;
    } else {
      throw new Error('SHN');
    }
  }

  last = sign < 0 ? last + 0x80000000 : last;
  bytes.writeUInt32LE(last, 12);
}

exports.bigDecimalToDecimal128 = bigDecimalToDecimal128;

function parseString(s, skipDownscale) {
  var scl = 0;
  var intVal = BigInt(0);
  var offset = 0; // use array bounds checking to handle too-long, len == 0,
  // bad offset, etc.
  // handle the sign

  var isneg = false; // assume positive

  if (s.charAt(offset) === '-') {
    isneg = true; // leading minus means negative

    offset++;
  } else if (s.charAt(offset) === '+') {
    // leading + allowed
    offset++;
  }

  var tz = 0; //trailing zeroes

  var dot = false; // true when there is a '.'

  for (; offset < s.length; offset++) {
    var c = s.charAt(offset); // have digit

    if (c >= '0' && c <= '9') {
      if (scl < BigDecimal_1.maxScale) {
        intVal = intVal * bi10 + BigInt(c);

        if (dot) {
          scl++;

          if (c === '0') {
            tz++;
          } else {
            tz = 0;
          }
        }
      }

      continue;
    } // have dot


    if (c === '.') {
      if (dot) {
        // two dots
        throw new Error('Invalid decimal format');
      }

      dot = true;
      continue;
    }

    throw new Error('Invalid character in number: ' + c);
  }

  intVal = isneg ? -intVal : intVal;

  if (tz > 0 && !skipDownscale) {
    intVal = intVal / BigDecimal_1.bigPowerTen(tz);
    scl = scl - tz;
  }

  return intVal === biZero ? BigDecimal_1.BigDecimal.ZERO : new RealBigDecimal_1.RealBigDecimal(intVal, scl);
}