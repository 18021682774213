import {
  SelloutImpactReportFilterDTO,
  ReleaseWorkflowType,
  SelloutImpactReportDetailRow4BOXDTO,
  SelloutImpactReportDetailRowReleaseDTO,
  SelloutImpactReportLevel,
  SelloutImpactReportDetailValuesDTO,
  StructPropBuilder,
  CodebookLookupSequence,
  SelloutReleaseType,
} from '@logio/common-be-fe';
import {
  ActionsGenerator,
  ColumnDefinition,
  ExtendedGridReadyEvent,
  getPath,
  LoadingState,
  PageStore,
  ReportLayer,
  StringMapping,
  ColumnGenerator,
  RendererNames,
  translate,
  IconType,
} from '@logio/common-fe';
import { GridApi } from 'ag-grid-community';
import { List } from 'immutable';
import { action, computed, observable, runInAction } from 'mobx';
import { PagePathsEnum } from '../../../../shared/localization/PagePathsEnum';

export enum SelloutImpactReportDetailMode {
  /** Report will contain data aggregated for releases. */
  ReleaseView = 'ReleaseView',

  /** Report will contain data aggregated as specified by the user in tree view - AKA 4Box view */
  CategoryView = 'CategoryView',
}

export class SelloutImpactReportDetailPageStore extends PageStore {
  /**
   * AG grid API for manipulation - try to use as little as possible. Declarative control is much more reliable.
   */
  private gridApi: GridApi;

  /**
   * ColumnGenerator to generate report action column.
   */
  private actionsGenerator = new ActionsGenerator();

  /**
   * Report API layer for getting row data.
   */
  private reportLayer = new ReportLayer();

  /**
   * Contains currently active filter (state of the filter for the current result).
   */
  public activeFilter: SelloutImpactReportFilterDTO;

  /**
   * Ag-grid generator for 4Box view specific columns
   */
  private categoryViewColumnGenerator = new ColumnGenerator((() => {
    const builder = new StructPropBuilder(SelloutImpactReportDetailRow4BOXDTO.name);

    return {
      hierarchy: builder.listOf(builder.str('hierarchy')),
      productName: builder.opt(builder.str('productName')),
      supplierNames: builder.listOf(builder.str('supplierNames')),
    };
  })());

  /**
   * Ag-grid generator for Release view specific columns
   */
  private releaseViewColumnGenerator = new ColumnGenerator((() => {
    const builder = new StructPropBuilder(SelloutImpactReportDetailRowReleaseDTO.name);

    return {
      releaseName: builder.str('releaseName'),
      workflowType: builder.senum<ReleaseWorkflowType>(
        'workflowType',
        ReleaseWorkflowType,
        CodebookLookupSequence.ReleaseWorkflowType,
      ),
      selloutReleaseType: builder.senum<SelloutReleaseType>(
        'selloutReleaseType',
        SelloutReleaseType,
        CodebookLookupSequence.SelloutReleaseType,
      ),
    };
  })());

  /**
   * Values Ag-grid generator
   */
  private valuesColumnGenerator = new ColumnGenerator(SelloutImpactReportDetailValuesDTO.schema);

  /**
   * Rows of the impact report.
   */
  @observable
  private rows: List<SelloutImpactReportDetailRow4BOXDTO> | List<SelloutImpactReportDetailRowReleaseDTO> = List<SelloutImpactReportDetailRow4BOXDTO>();

  /**
   * Report mode
   */
  @observable
  public mode = SelloutImpactReportDetailMode.CategoryView;

  @observable
  public rowsLoading: boolean = false;

  /**
   * Initial hierarchy.
   */
  public initialHierarchy = [
    SelloutImpactReportLevel.Department,
    SelloutImpactReportLevel.SCM,
    SelloutImpactReportLevel.CM,
    SelloutImpactReportLevel.CategoryPlan,
    SelloutImpactReportLevel.Product,
    SelloutImpactReportLevel.SelloutType,
  ];

  private lastSelectedHierarchy: SelloutImpactReportLevel[] = [];

  constructor(public reportId: string) {
    super();
    this.load();
  }

  /**
   * Fetches data sellout termination report
   */
  public async load() {
    this.setLoadingState(LoadingState.Success);
    this.filter({ hierarchy: this.initialHierarchy });
  };

  @action.bound
  public async filter(values: { hierarchy: SelloutImpactReportLevel[] }) {
    if (this.gridApi) {
      this.gridApi.showLoadingOverlay();
    }

    this.lastSelectedHierarchy = values.hierarchy;
    this.rowsLoading = true;

    try {
      const result = await this.reportLayer.getSelloutImpactReportDetail4Box(this.reportId, List(values.hierarchy));
      runInAction(() => {
        this.rows = result;
      });
    } finally {
      runInAction(() => {
        this.rowsLoading = false;
      });
    }

    if (this.gridApi) {
      this.gridApi.hideOverlay();
    }
  }

  @action.bound
  public async loadReleaseView() {
    if (this.gridApi) {
      this.gridApi.showLoadingOverlay();
    }

    this.rowsLoading = true;

    try {
      const result = await this.reportLayer.getSelloutImpactReportDetailRelease(this.reportId);
      runInAction(() => {
        this.rows = result;
      });
    } finally {
      runInAction(() => {
        this.rowsLoading = false;
      });

      if (this.gridApi) {
        this.gridApi.hideOverlay();
      }
    }
  }

  /**
   * Sets mode of the report.
   *
   * @param mode
   */
  @action.bound
  public setMode(mode: SelloutImpactReportDetailMode) {
    if (mode !== this.mode) {
      this.mode = mode;
      if (mode === SelloutImpactReportDetailMode.CategoryView) {
        this.rows = List();
        this.filter({ hierarchy: this.lastSelectedHierarchy });
      } else {
        this.rows = List();
        this.loadReleaseView();
      }
    }
  }

  /**
   * Return generated column definitions of the report for ag-grid
   */
  @computed
  public get reportColumnDefs(): ColumnDefinition[] {
    let metaColumns;
    if (this.mode === SelloutImpactReportDetailMode.CategoryView) {
      metaColumns = this.categoryViewColumnGenerator.getColumnDefinitions([
        { field: 'productName' },
        {
          field: 'supplierNames',
          valueGetter: ({data}) => data.SelloutImpactReportDetailRow4BOXDTO_supplierNames.toArray(),
          valueFormatter: ({ value }) => Array.isArray(value) ? value.join(', ') : value,
        },
      ]);
    } else {
      metaColumns = [
        this.actionsGenerator.getColumnDefinition({
          headerName: '',
          suppressMovable: false,
          lockPinned: false,
          lockPosition: false,
          checkboxSelection: false,
          headerCheckboxSelection: false,
          hide: false,
          width: 70,
        }),
        ...this.releaseViewColumnGenerator.getColumnDefinitions([
          { field: 'releaseName' },
          {
            field: 'workflowType',
            valueFormatter: ({ value }) => value ? translate(value) : value,
          },
          {
            field: 'selloutReleaseType',
            valueFormatter: ({ value }) => value ? translate(value) : value,
          },
        ])
      ];
    }

    const columns = this.valuesColumnGenerator.getColumnDefinitions([
      {
        // Real Quantity
        field: 'salesVolume',
        round: true,
      },
      {
        // Sellout Quantity
        field: 'salesVolumeSellout',
        round: true,
      },
      {
        // Actual Quantity
        field: 'salesVolumeActual',
        round: true,
      },
      {
        // Real ΔQuantity
        field: 'salesVolumeImpact',
        cellRenderer: RendererNames.ArrowRenderer,
        round: true,
      },
      {
        // Real ΔQuantity %
        field: 'salesVolumeImpactPercents',
        cellRenderer: RendererNames.ArrowRenderer,
        round: true,
      },
      {
        // ΔQuantity
        field: 'salesVolumeForecastDelta',
        cellRenderer: RendererNames.ArrowRenderer,
        round: true,
      },
      {
        // ΔQuantity %
        field: 'salesVolumeForecastDeltaPercents',
        cellRenderer: RendererNames.ArrowRenderer,
        round: true,
      },
      {
        // FA ΔQuantity
        field: 'salesVolumeFA',
        cellRenderer: RendererNames.ArrowRenderer,
        round: true,
      },
      {
        // FA ΔQuantity %
        field: 'salesVolumeFAPercents',
        cellRenderer: RendererNames.ArrowRenderer,
        round: true,
      },
      {
        // Real Sales
        field: 'revenue',
        round: true,
      },
      {
        // Sellout Sales
        field: 'revenueSellout',
        round: true,
      },
      {
        // Actual Sales
        field: 'revenueActual',
        round: true,
      },
      {
        // Real ΔSales
        field: 'revenueImpact',
        cellRenderer: RendererNames.ArrowRenderer,
        round: true,
      },
      {
        // Real ΔSales %
        field: 'revenueImpactPercents',
        cellRenderer: RendererNames.ArrowRenderer,
        round: true,
      },
      {
        // ΔSales
        field: 'revenueForecastDelta',
        cellRenderer: RendererNames.ArrowRenderer,
        round: true,
      },
      {
        // ΔSales %
        field: 'revenueForecastDeltaPercents',
        cellRenderer: RendererNames.ArrowRenderer,
        round: true,
      },
      {
        // FA ΔSales
        field: 'revenueFA',
        cellRenderer: RendererNames.ArrowRenderer,
        round: true,
      },
      {
        // FA ΔSales %
        field: 'revenueFAPercents',
        cellRenderer: RendererNames.ArrowRenderer,
        round: true,
      },
      {
        // Real BM2
        field: 'margin',
        round: true,
      },
      {
        // Sellout BM2
        field: 'marginSellout',
        round: true,
      },
      {
        // Actual BM2
        field: 'marginActual',
        round: true,
      },
      {
        // Real ΔBM2
        field: 'marginImpact',
        cellRenderer: RendererNames.ArrowRenderer,
        round: true,
      },
      {
        // Real ΔBM2 %
        field: 'marginImpactPercents',
        cellRenderer: RendererNames.ArrowRenderer,
        round: true,
      },
      {
        // ΔBM2
        field: 'marginForecastDelta',
        cellRenderer: RendererNames.ArrowRenderer,
        round: true,
      },
      {
        // ΔBM2 %
        field: 'marginForecastDeltaPercents',
        cellRenderer: RendererNames.ArrowRenderer,
        round: true,
      },
      {
        // FA ΔBM2
        field: 'marginFA',
        cellRenderer: RendererNames.ArrowRenderer,
        round: true,
      },
      {
        // FA ΔBM2 %
        field: 'marginFAPercents',
        cellRenderer: RendererNames.ArrowRenderer,
        round: true,
      },
      {
        // Initial stock volume
        field: 'initialStockVolume',
        round: true,
      },
      {
        // Stock volume
        field: 'stockVolume',
        round: true,
      },
      {
        // ΔStock volume
        field: 'stockVolumeDelta',
        round: true,
      },
      {
        // ΔStock volume %
        field: 'stockVolumeDeltaPercents',
        round: true,
      },
      {
        // Initial stock value
        field: 'initialStockValue',
        round: true,
      },
      {
        // Stock value
        field: 'stockValue',
        round: true,
      },
      {
        // ΔStock value
        field: 'stockValueDelta',
        round: true,
      },
      {
        // ΔStock value %
        field: 'stockValueDeltaPercents',
        round: true,
      },
      {
        // FA %
        field: 'faPercents',
        round: true,
      },
      {
        // Real Days
        field: 'realDays',
        round: true,
      },
    ]);

    return [...metaColumns, ...columns];
  }

  /** Tree data definition */
  @computed
  public get reportAutoGroupColumnDef() {
    return this.categoryViewColumnGenerator.getAutoGroupColumnDefinitions({
      field: 'hierarchy',
      valueFormatter: ({ value }) => value.toString().replace(/&amp;/g, '&'),
      cellRendererParams: {
        suppressCount: true,
      },
    });
  }

  /**
   * Return generated row data of the report for ag-grid
   */
  @computed
  public get reportRowData(): Array<StringMapping<any>> {
    return (this.rows as any).map(row => {
      let metaColumns;

      if (this.mode === SelloutImpactReportDetailMode.CategoryView) {
        metaColumns = {
          hierarchy: row.hierarchy.toArray().map(val => translate(val)),
          ...this.categoryViewColumnGenerator.getColumnData(row)
        };
      } else {
        const releaseDetail = {
          name: 'releaseDetail',
          icon: IconType.view,
          linkProps: { to: this.getReleaseDetailPath(row.releaseId, row.workflowType) },
        };

        metaColumns = {
          ...this.actionsGenerator.getColumnData(releaseDetail),
          ...this.releaseViewColumnGenerator.getColumnData(row)
        };
      }

      return {
        ...metaColumns,
        ...this.valuesColumnGenerator.getColumnData(row.values),
      };
    }).toArray();
  }

  @action.bound
  public onGridReady(grid: ExtendedGridReadyEvent) {
    this.gridApi = grid.api;

    if (this.rowsLoading) {
      this.gridApi.showLoadingOverlay();
    }
  }

  /**
   * Return data path for tree view
   */
  public getDataPath = (data: any) => data.hierarchy;

  /**
   * @inheritdoc
   */
  protected getReleaseDetailPath(releaseId: string, workflowType: ReleaseWorkflowType) {
    const path = workflowType === ReleaseWorkflowType.SelloutUrgent ? 'ReleaseSelloutDetailCategory' : 'ReleaseSelloutDetail';
    return getPath(PagePathsEnum[path], releaseId);
  };
}
