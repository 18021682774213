/**
 * @file Created on Wed Sep 26 2018
 * @author PKl
 */

import {CompetitorsPriority, DataDesc, StructPropBuilder, Utils, PricingPermissions} from '@logio/common-be-fe';
import {
  ActionsGenerator,
  ColumnDefinition,
  ColumnGenerator,
  CompetitorsPriorityStore,
  IconType,
  LoadingState,
  PageStore,
  rootStore,
  StoreName,
  StringMapping,
  KeycloakStore,
} from '@logio/common-fe';
import {List} from 'immutable';
import {action, computed, observable, runInAction, toJS} from 'mobx';

export enum ModalEnum {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export class CompetitorsPriorityPageStore extends PageStore {
  /** Get dependant stores */
  competitorsPriorityStore = rootStore.getStore(StoreName.CompetitorsPriority) as CompetitorsPriorityStore;
  keycloakStore = rootStore.getStore(StoreName.Keycloak) as KeycloakStore;

  /**
   * Variable used to set initial values to Form
   */
  @observable
  initialValues: CompetitorsPriority = new CompetitorsPriority(Utils.VOID_ID, undefined, List());

  /** Schema for Competitors */
  builder = new StructPropBuilder('Competitors');
  competitorsDescription: DataDesc = {
    name: this.builder.str('name'),
    id: this.builder.str('id'),
  };

  /** Data generators */
  actionsGenerator = new ActionsGenerator();
  prioritiesGenerator = new ColumnGenerator<CompetitorsPriority>(CompetitorsPriority.schema);
  competitorsGenerator = new ColumnGenerator(this.competitorsDescription);

  /** Variable used for opening and closing modals */
  @observable
  modalHidden = {
    [ModalEnum.CREATE]: true,
    [ModalEnum.EDIT]: true,
    [ModalEnum.DELETE]: true,
  };

  /**
   * Returns generated column definitions for the ag-grid on the main page
   */
  @computed
  get columnDefs(): ColumnDefinition[] {
    const colDefs: ColumnDefinition[] = [];

    const actionsColDef = {
      headerCheckboxSelection: false,
      checkboxSelection: false,
      cellClass: 'ag-cell-align-center',
    };

    const priorityColDefs = [
      {
        field: 'title',
        width: 250,
        suppressMenu: true,
      },
    ];

    const competitorColDefs = [
      {
        field: 'name',
        width: 500,
        suppressMenu: true,
      },
    ];

    if (!this.pageReadOnly) {
      colDefs.push(this.actionsGenerator.getColumnDefinition(actionsColDef));
    }

    colDefs.push(
      ...this.prioritiesGenerator.getColumnDefinitions(priorityColDefs),
      ...this.competitorsGenerator.getColumnDefinitions(competitorColDefs),
    );

    return colDefs;
  }

  /**
   * Return generated data for the ag-grid on the main page
   */
  @computed
  get rowData(): Array<StringMapping<any>> {
    const rowData = [];
    this.competitorsPriorityStore.priorities.forEach((priority: CompetitorsPriority) => {
      const editPriority = {
        name: 'edit',
        icon: IconType.edit,
        props: {onClick: this.openModal(ModalEnum.EDIT, priority)},
      };
      const deletePriority =
        /** Last competitor priority could not be deleted */
        this.competitorsPriorityStore.priorities.size > 1
          ? {
              name: 'delete',
              icon: IconType.delete,
              props: {onClick: this.openModal(ModalEnum.DELETE, priority)},
            }
          : {
              name: 'delete',
              icon: IconType.locked,
            };
      // FIXME: better?
      // Mapping ids to competitor name
      const competitors = {name: ''};
      priority.competitors.forEach((competitorId) => {
        if (this.competitorsPriorityStore.competitors.has(competitorId)) {
          competitors.name = competitors.name.concat(
            ' > ',
            toJS(this.competitorsPriorityStore.competitors.get(competitorId)).name,
          );
        }
      });
      // Deleting the first separator
      competitors.name = competitors.name.slice(3);

      /** Each generator returns data definition from related data description */
      rowData.push({
        ...this.actionsGenerator.getColumnData(editPriority, deletePriority),
        ...this.prioritiesGenerator.getColumnData(priority),
        ...this.competitorsGenerator.getColumnData(competitors),
      });
    });
    return rowData;
  }

  /**
   * Returns generated column definitions for the ag-grid from Modal
   */
  columnDefsModal = (): ColumnDefinition[] => {
    const competitorsColDefs: ColumnDefinition[] = [
      {
        field: 'name',
        suppressMenu: true,
        rowDrag: true,
      },
      {
        field: 'id',
        hide: true,
      },
    ];
    const actionsColDefs: ColumnDefinition = {width: 40, headerName: '', pinned: 'right'};

    return [
      this.actionsGenerator.getColumnDefinition(actionsColDefs),
      ...this.competitorsGenerator.getColumnDefinitions(competitorsColDefs),
    ];
  };

  /**
   * Return generated data for the ag-grid from Modal
   */
  rowDataModal = (): Array<StringMapping<any>> => {
    const rowData = [];

    /** Variable will get competitors that exist on the priority list */
    const existingCompetitors = new Set();

    /** Appending all Competitors to the ag-grid */

    /** Pushing competitors that exist on the Priority list  */
    this.initialValues.competitors.forEach((competitorID: string) => {
      existingCompetitors.add(competitorID);
      const value = toJS(this.competitorsPriorityStore.competitors.get(competitorID));
      rowData.push({
        ...this.competitorsGenerator.getColumnData(value),
      });
    });

    /** Pushing rest of the competitors */
    for (const value of this.competitorsPriorityStore.competitors.values()) {
      if (!existingCompetitors.has(value.id)) {
        rowData.push({
          ...this.competitorsGenerator.getColumnData(value),
        });
      }
    }

    return rowData;
  };

  /**
   * Fetches all data for this page
   */
  load = async (): Promise<void> => {
    try {
      await Promise.all([
        this.competitorsPriorityStore.getAllCompetitors(),
        this.competitorsPriorityStore.getAllPriorities(),
      ]);
      this.setReadOnly(!this.keycloakStore.userHasPermissions([PricingPermissions.COMPETITOR_PRIORITY_SETTINGS_EDIT]));
      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      this.setLoadingState(LoadingState.Error);
      // this.messages.setError(error);
    }
  };

  /**
   * Negate modalHiddenProp
   * @param title - name of the modalHidden prop that should be negated
   */
  toggleModal = (modalType: ModalEnum) =>
    runInAction(() => (this.modalHidden[modalType] = !this.modalHidden[modalType]));

  /**
   * HOF for toggleModal
   * used in CompetitorsPriority page when u get event besides parameter
   */
  getModalToggleEvent = (modalType: ModalEnum) => () => this.toggleModal(modalType);

  /**
   * Set values of CompetitorsPriority to the Form
   * @param competitorsPriority - picked competitorsPriority
   */
  setInitialValues = (competitorsPriority: CompetitorsPriority) =>
    runInAction(() => (this.initialValues = competitorsPriority));

  /**
   * Open modal for competitorsPriority creating/editing/deleting
   */
  openModal = (modalType: ModalEnum, priority?: CompetitorsPriority) => () => {
    priority
      ? this.setInitialValues(priority)
      : this.setInitialValues(new CompetitorsPriority(Utils.VOID_ID, undefined, List()));
    this.toggleModal(modalType);
  };

  /**
   * Creates new Priority with PriorityStore
   * @param competitorPriority - data of new Priority(not need for the delete method)
   * @param modalType - defines method tha will be used
   */
  @action.bound
  onSubmit = (modalType: ModalEnum) => async (competitorPriority?: CompetitorsPriority): Promise<void> => {
    try {
      switch (modalType) {
        case ModalEnum.CREATE:
          await this.competitorsPriorityStore.create(competitorPriority);
          break;
        case ModalEnum.EDIT:
          await this.competitorsPriorityStore.update(competitorPriority);
          break;
        case ModalEnum.DELETE:
          await this.competitorsPriorityStore.delete(this.initialValues.id);
          break;
      }
    } catch (error) {
      // this.messages.setError(error);
    }
    this.toggleModal(modalType);
  };
}
