/**
 * @file Created on Thu Apr 04 2019
 * @author SKu
 */

import {
  DataDesc,
  ProductCategory,
  SelloutImpactRecord,
  SelloutReleaseTotalDTO,
  SelloutReleaseType,
  ReleaseWorkflowType,
  PricingPermissions,
  ReleaseState,
  SelloutRelease,
  SelloutTargetUpdate,
} from '@logio/common-be-fe';
import {
  ColumnDefinition,
  ReleaseSelloutStore,
  rootStore,
  StoreName,
  StringMapping,
  translate,
  RendererNames,
  withPermission,
  Comparators,
  LoadingState,
  ColumnGenerator,
} from '@logio/common-fe';
import {List} from 'immutable';
import {History} from 'history';
import {computed, runInAction} from 'mobx';
import {PagePathsEnum} from '../../../../shared/localization/PagePathsEnum';
import {ReleaseSelloutHeaderStore} from '../../../components';
import {AbstractReleaseDetailPageStore} from './AbstractReleaseDetailPageStore';
import {BigDecimal, bigdecimal} from '@logio/big-decimal';
import {RowNode} from 'ag-grid-community';
import {PreviousImpactsColumnGenerator} from '../../../components/Release/columnGenerators/PreviousImpactsColumnGenerator';

export class ReleaseSelloutDetailPageStore extends AbstractReleaseDetailPageStore {
  constructor(public history: History) {
    super(history);
  }

  /** Stores */
  public releaseStore = rootStore.getStore(StoreName.SelloutRelease) as ReleaseSelloutStore;
  public releaseHeaderStore = new ReleaseSelloutHeaderStore(
    this.messages,
    () => this.setLoadingState(LoadingState.Pending),
    location.pathname,
    (progressMonitor: string) => {
      this.pollingHelper.startPolling(progressMonitor, 'RELEASE');
      this.setLoadingState(LoadingState.Success);
    },
  );

  public list: Map<string, SelloutReleaseTotalDTO>;

  protected async loadData() {
    await Promise.all([
      super.loadData(),
      this.productCategoryStore.loadForSelloutRelease(this.releaseStore.release.id),
    ]);
  }

  /** Current release */
  @computed
  public get release() {
    return this.releaseStore.release;
  }

  /** Returns true if release workflow type is selloutUrgent */
  @computed
  public get isUrgent() {
    return this.releaseStore.release.workflowType === ReleaseWorkflowType.SelloutUrgent;
  }

  /** Some features on this page available only for deadstock release */
  @computed
  private get isDeadstock() {
    return this.release.selloutReleaseType === SelloutReleaseType.Deadstock;
  }

  /** Page specific description for ag-grid generators */
  @computed
  protected get customTotalColumnsDescription(): DataDesc {
    return {
      ...SelloutImpactRecord.schema,
      targetMargin: this.totalBuilder.opt(this.totalBuilder.bigNum('targetMargin')),
    };
  }

  private previousImpactsColumnGenerator = new PreviousImpactsColumnGenerator(this.releaseStore);

  // LOG-5661
  public getRoundedPrice = (value: any, digits: number = 2): string => {
    if (!value) {
      return '';
    }

    return value
      .toFixed(digits)
      .toString()
      .replace('.', ',');
  };

  /** Page specific ag-grid column definition */
  protected get customTotalColumnsDefs(): ColumnDefinition[] {
    /** Fields are editable only if user has permission and release is in changeable state */
    const isTargetsEditable =
      withPermission([PricingPermissions.SELLOUT_RELEASE_EDIT_TARGET]) &&
      (this.release.state === ReleaseState.Opened || this.release.state === ReleaseState.Approved);
    return [
      {field: 'selloutProductsCount'},
      {field: 'sitesCount'},
      {field: 'priceChangesCount'},
      {
        field: 'discountPercent',
        dontCreateColumn: this.isUrgent,
        valueFormatter: ({value}) => this.getRoundedPrice(value),
      },
      {
        field: 'marginNew',
      },
      {
        field: 'marginNewPercent',
        dontCreateColumn: this.isUrgent,
        cellClassRules: {
          ['c-red-cell-text']: ({node, value: expected}: {node: RowNode; value: BigDecimal}) => {
            const target: BigDecimal = node.data[`ReleaseTotalOverview_targetMargin`];
            return target && expected && target.greaterThan(expected);
          },
          ['c-green-cell-text']: ({node, value: expected}: {node: RowNode; value: BigDecimal}) => {
            const target: BigDecimal = node.data[`ReleaseTotalOverview_targetMargin`];
            return target && expected && target.lessThan(expected);
          },
          ['fw500']: ({node, value: expected}: {node: RowNode; value: BigDecimal}) => true,
        },
      },
      {
        field: 'marginChange',
      },
      {
        field: 'marginChangePercent',
        comparator: Comparators.arrowComparator,
        cellRenderer: RendererNames.ArrowRenderer,
        dontCreateColumn: this.isUrgent,
        valueFormatter: ({value}) => this.getRoundedPrice(value),
      },
      {field: 'minMarginChangeInPercent'},
      {field: 'minPriceChangeInPercent'},
      {field: 'maxPriceChangeInPercent'},
      // Values are editable only for unit directory, and it is defined by field value(on other categories will always be null)
      {
        field: 'targetMargin',
        action: this.updateTargets,
        editable: (value) => !!value.data.ReleaseTotalOverview_targetMargin && isTargetsEditable,
      },
      {
        field: 'availableSupplyPrice',
        valueFormatter: ({value}) => this.getRoundedPrice(value),
      },
      {field: 'availableSupply'},
      {field: 'availableSupplyBeforeRepricing'},
      {field: 'availableSupplyBeforeRepricingPrice'},
      {field: 'availableSupplyPriceChange'},
      {field: 'availableSupplyChange'},
      {field: 'availableSupplyPriceChangePercent'},
      {field: 'availableSupplyChangePercent'},
      {field: 'deadstockProvisionDiscountPercent', dontCreateColumn: !this.isDeadstock || this.isUrgent},
      {field: 'deadstockProvision', dontCreateColumn: !this.isDeadstock || this.isUrgent},
      {field: 'deadstockInvestment', dontCreateColumn: !this.isDeadstock || this.isUrgent},
      {field: 'deadstockImpact', dontCreateColumn: !this.isDeadstock || this.isUrgent},
      {
        field: 'salesVolumeOld',
        valueFormatter: ({value}) => this.getRoundedPrice(value, 0),
      },
      {
        field: 'salesVolumeNew',
        valueFormatter: ({value}) => this.getRoundedPrice(value, 0),
      },
      {
        field: 'revenueOld',
        valueFormatter: ({value}) => this.getRoundedPrice(value),
      },
      {
        field: 'revenueNew',
        valueFormatter: ({value}) => this.getRoundedPrice(value),
      },
      {field: 'marginOld'},
      {field: 'marginOldPercent'},
      {field: 'estimationInPercent'},
    ];
  }

  @computed
  public get columnDefs(): ColumnDefinition[] {
    return [
      ...this.totalColumnGenerator.getColumnDefinitions(this.totalColumnsDefs),
      ...this.impactColumnGenerator.getColumnDefinitions(this.impactColumnDefs),
      ...this.previousImpactsColumnGenerator.getColumnDefinitions(),
    ];
  }

  /** Tree data definition */
  @computed
  public get autoGroupColumnDefs() {
    return this.productCategoryColumnGenerator.getAutoGroupColumnDefinitions(this.commonAutoGroupColumnDefs);
  }

  /** Data for ag-grid rows */
  protected getRowData = (
    category: ProductCategory,
    releaseTotalItem: SelloutReleaseTotalDTO,
    pathData: {categoryId: string; path: List<string>},
  ) => {
    const gridData: Array<StringMapping<any>> = [];
    /** True if category level is the lowest */
    const bottomLevel = category.level === this.generalConfigurationsStore.config.categoryLevel;

    /** Merge impact data with common created data for bottom category */
    const getTotalGeneratorData = (): any => {
      return {
        ...releaseTotalItem.impact,
        ...releaseTotalItem.targets,
        ...this.getCommonTotalGeneratorData(category, releaseTotalItem),
        /** add link, if category level is the lowest */
        link: bottomLevel && this.getPathToDetailCategory(PagePathsEnum.ReleaseSelloutDetailCategory, category.id),
      };
    };

    /** Push category data */
    gridData.push({
      pathData: {
        categoryId: pathData.categoryId,
        path: pathData.path.toArray(),
      },
      ...this.totalColumnGenerator.getColumnData(getTotalGeneratorData()),
      ...this.impactColumnGenerator.getColumnData(releaseTotalItem.realImpact),
      ...this.previousImpactsColumnGenerator.getColumnData(releaseTotalItem.previousImpacts),
    });

    return gridData;
  };

  /**
   * Fire on Ag-grid FinalPrice Select
   * Update Release Item Final Price
   * because we set getRowNodeId as releaseItemId, we have ReleaseItemId instead of rowIndex
   * @param rowIndex represented by numbers as strings(0 => n, where n - row count)
   * but should be changed to some id and turn on delta mode
   * @param column
   * @param value
   */
  private updateTargets = async (rowIndex: string, column: string, value?: BigDecimal) => {
    try {
      const type = column.slice(column.indexOf('_') + 1);
      const categoryId = this.gridApi.getRowNode(rowIndex).data.SelloutImpactRecord_categoryId;
      // Updated target
      const target: SelloutTargetUpdate = {
        releaseId: this.release.id,
        categoryId,
        [type]: value && value.toNumber(),
      };
      /** Call endpoint to update target */
      const newItemTargets = await this.releaseStore.updateTargets([target]);
      /**  mutating items for each updated target */
      runInAction(() => {
        newItemTargets.map((newTarget) => {
          const updatedTotal = this.list.get(newTarget.categoryId);
          // Updating old item with new targets value
          this.list.set(updatedTotal.categoryId, updatedTotal.copy('targets', newTarget));
        });
      });
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  openRelease = async () => {
    this.setLoadingState(LoadingState.Pending);
    try {
      await this.releaseStore.open(this.release.id).then(() => this.load(this.release.id));
      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      this.setLoadingState(LoadingState.Success);
      // this.messages.setError(error);
    }
  };

  /** Handles Release reopen(possible only for Release state)
   * @param values - callback from form
   */
  public onReopenSubmit = async (values: any) => {
    // LOG-5390
    const priceListTypeConverted = translate(this.release.priceListType);
    // LOG-6221
    // const notShowEndDate = (
    //   priceListTypeConverted.includes('Regular delist') ||
    //   priceListTypeConverted.includes('In-Out delist') ||
    //   priceListTypeConverted.includes('Remodelling')
    // );

    // LOG-5284
    const valuesConverted = {
      ...values,
      cmDeadline: values && values.cmDeadline ? values.cmDeadline.endOf('d') : null,
      priceValidityStartDate:
        values && values.priceValidityStartDate ? values.priceValidityStartDate.startOf('d') : null,
      endDate: values.endDate ? values.endDate.endOf('d') : null,
    };
    const release = this.updateReleaseWithFormValues(this.release, valuesConverted);

    this.hideModal();
    try {
      runInAction(() => (this.isRecalculateActionFired = true));
      await this.releaseStore.proceedPriceSimulation(release.id, release);
      await this.load(this.release.id);
      this.messages.setSuccess(translate('release-reopened'));
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  private updateReleaseWithFormValues = (release: SelloutRelease, values: any): SelloutRelease => {
    const {
      totalPriceChangeLimit,
      cmDeadline,
      priceValidityStartDate,
      endDate,
      minPriceChangeInPercent,
      maxPriceChangeInPercent,
      minMarginChangeInPercent,
      description,
      repricingEstimationThreshold,
    } = values;

    return release.copyMulti({
      totalPriceChangeLimit: totalPriceChangeLimit ? Number(totalPriceChangeLimit) : null,
      cmDeadline,
      priceValidityStartDate,
      endDate,
      minPriceChangeInPercent: bigdecimal(minPriceChangeInPercent),
      maxPriceChangeInPercent: maxPriceChangeInPercent ? bigdecimal(maxPriceChangeInPercent) : null,
      minMarginChangeInPercent: bigdecimal(minMarginChangeInPercent),
      description: description || null,
      repricingEstimationThreshold: repricingEstimationThreshold ? Number(repricingEstimationThreshold) : null,
    });
  };
}
