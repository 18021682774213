import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  ", ";\n  align-items: center;\n\n  > * {\n    margin-right: ", "rem;\n    width: auto;\n  }\n\n  & .input-wrapper {\n    min-width: 250px;\n  }\n\n  & .Select-multi-value-wrapper {\n    padding-right: 70px;\n  }\n\n  ", ";\n  ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  padding-bottom: ", "rem;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  flex-wrap:wrap;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  justify-content: flex-end;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
import styled, { css, withTheme } from 'styled-components';
var contentToRight = css(_templateObject());
var flexWrap = css(_templateObject2());
var padding = css(_templateObject3(), function (props) {
  return props.theme.spacing.spacer;
});
var FormGroupInlineStyled = styled.div(_templateObject4(), function (props) {
  return props.wrap ? flexWrap : null;
}, function (props) {
  return props.theme.spacing.spacer / 2;
}, function (props) {
  return props.right ? contentToRight : null;
}, function (props) {
  return props.padding !== false ? padding : null;
});
export default withTheme(FormGroupInlineStyled);