/**
 * @file Created on Tue Nov 18 2019
 * @author SKu
 */
import { Utils, UserMessageCode } from '@logio/common-be-fe';
import * as React from 'react';
import { CONSTANTS } from '../../../../../constants';
import { translateResultObject } from '../../../../i18n/translate';
import Tooltip from '../../../../info/Tooltip';
import { TooltipType } from '../../../../info/Tooltip/TooltipType';
import Icon, { IconColor, IconType } from '../../../../media/Icon';
import { ArrowRenderer } from './ArrowRenderer';
export var ReleaseCRPIndexRenderer = function ReleaseCRPIndexRenderer(props) {
  if (Utils.isValueMissing(props.value)) {
    return React.createElement(React.Fragment, null, CONSTANTS.FORMAT.NULL);
  }

  var validation = props.value.validationResult.resultObjects;

  var getTooltipInfo = function getTooltipInfo() {
    return validation.map(function (resultObject, index) {
      return React.createElement("li", {
        key: index
      }, translateResultObject(resultObject));
    });
  };

  return React.createElement("div", null, React.createElement(ArrowRenderer, Object.assign({}, props)), validation.size > 0 && !validation.some(function (value) {
    return value.messageKey === UserMessageCode.ActualCRPUsed;
  }) && React.createElement("span", {
    key: "alert",
    className: 'dib'
  }, React.createElement(React.Fragment, null, "\xA0"), React.createElement(Tooltip, {
    overlay: getTooltipInfo(),
    tooltipType: TooltipType.Warning,
    placement: "right",
    children: React.createElement("span", null, React.createElement(Icon, {
      iconColor: IconColor.Error,
      iconType: IconType.alert,
      iconHeight: 15
    }))
  })));
};