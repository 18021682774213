/**
 * @file Created on Mon Dec 10 2018
 * @author LZe
 */
import translate from '../../../i18n/translate';
import { RendererNames } from '../components/renderers';
/* Common AgGrid column definitions */

export var productFlagsColumnDefinition = {
  width: 75,
  cellClass: 'ag-cell-align-center',
  cellRenderer: RendererNames.ProductFlagsRenderer,
  keyCreator: function keyCreator(_ref) {
    var value = _ref.value;
    return value && value.flags.size > 0 ? value.serialize().map(function (f) {
      return f.title ? "".concat(translate("flag-".concat(f.tag)), " (").concat(f.title, ")") : translate("flag-".concat(f.tag));
    }) : undefined;
  },
  sortable: false,
  filterParams: {
    suppressMiniFilter: true,
    cellRenderer: function cellRenderer(_ref2) {
      var value = _ref2.value;
      return value ? value : translate('BLANKS_FILTER');
    }
  },
  excel: {
    valueFormatter: function valueFormatter(_ref3) {
      var value = _ref3.value;
      return value && value.flags.size > 0 ? value.serialize().map(function (f) {
        return translate(f.tag);
      }).join(', ') : '';
    }
  }
};
/**
 * Suitable for List of strings
 * eg. cell value is List(["St. Joe Distributing", "Fanestil meat", "Kansas Farmers Market"])
 */

export var listOfStringsColumnDefinition = {
  valueFormatter: function valueFormatter(_ref4) {
    var value = _ref4.value;
    return value && value.join ? value.join(', ') : undefined;
  },
  keyCreator: function keyCreator(_ref5) {
    var value = _ref5.value;
    return value && !value.isEmpty() ? value.toArray() : undefined;
  },
  filterParams: {
    cellRenderer: function cellRenderer(_ref6) {
      var value = _ref6.value;
      return value ? translate(value) : translate('BLANKS_FILTER');
    }
  },
  excel: {
    valueFormatter: function valueFormatter(_ref7) {
      var value = _ref7.value;
      return value && value.join ? value.join(', ') : '';
    }
  }
};