/*
* Created on Wed Jul 11 2018
*/

/**
 * Helper for working with local/session storage in browser.
 * @param storage local / session storage object
 *
 * @author BPo
 */
var getStorageHelper = function getStorageHelper(storage) {
  return {
    /**
     * Add value to storage
     */
    add: function add(key, value) {
      if (storage) {
        storage.setItem(key, value);
      }
    },

    /**
     * Get one item by key from storage
     * @returns Value when found, null otherwise
     */
    get: function get(key) {
      if (storage) {
        return storage.getItem(key);
      } else {
        return null;
      }
    },

    /**
     * Remove value from storage
     */
    remove: function remove(key) {
      if (storage) {
        storage.removeItem(key);
      }
    },

    /**
     * Clear storage (remove all items from it)
     */
    clearStorage: function clearStorage() {
      if (storage) {
        storage.clear();
      }
    }
  };
};
/**
 * Accesses the current domain's local Storage helper.
 */


export var local = getStorageHelper(window.localStorage);
/**
 * Accesses the current domain's session Storage helper.
 */

export var session = getStorageHelper(window.sessionStorage);