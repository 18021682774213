"use strict";
/*
 * Created on 02 20 2019
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Type of {@link SelloutSupply sellout supply} which determines products for sellouts (in other terms it represents cause of sellout).
 * In contrast with {@link SelloutReleaseType} this enumerates only core sellout types which should be common to all clients and it states
 * real cause for sellout (whereas sellout release type may differ from it).
 *
 * @author JMe
 */

var SelloutType;

(function (SelloutType) {
  /**
   * Product has good stock amount at site, but product sales are so low, that subset of that product is endangered by the fact that
   * it will be older than it is required.
   */
  SelloutType["DeadStock"] = "DEAD_STOCK";
  /**
   * Product is endangered by expiry and should be sold out.
   */

  SelloutType["Expiry"] = "EXPIRY";
  /**
   * Product will not be part of site assortment anymore and must be sold out.
   */

  SelloutType["ExitProcessRegularDelist"] = "EXIT_PROCESS_REGULAR_DELIST";
  /**
   * Product will not be part of site assortment anymore and must be sold out. It differs from regular delist in way, that
   * product end date is equal to end of promotion on product and site.
   *
   */

  SelloutType["ExitProcessInOutDelist"] = "EXIT_IN_OUT_DELIST";
  /**
   * Product has overstock at a site and its stock amount must be reduced to required amount. Overstock can have multiple
   * reasons.
   */

  SelloutType["Overstock"] = "OVERSTOCK";
  /**
   * Product has overstock at a site and its stock amount must be reduced to required amount. Overstock can have multiple
   * reasons (overstock due to running promotion).
   */

  SelloutType["OverstockPromo"] = "OVERSTOCK_PROMO";
  /**
   * Product must be sold out due to planned remodeling at site. Among affected products typically belong fresh products.
   */

  SelloutType["Remodeling"] = "REMODELING";
})(SelloutType = exports.SelloutType || (exports.SelloutType = {}));