import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["background: ", ";"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n        position: absolute;\n        top: 0;\n        bottom: 0;\n        left: 0;\n        right: 0;\n        z-index: 1;\n        ", ";\n      "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n\n  @keyframes bounce {\n    0%,\n    80%,\n    100% {\n      transform: scale(0);\n    }\n    40% {\n      transform: scale(1);\n    }\n  }\n\n  .spinner {\n    position: relative;\n    top: 50%;\n    left: 50%;\n    transform: translate(-27px, -11px);\n    width: 54px;\n  }\n\n  .spinner .bounce {\n    width: 18px;\n    height: 18px;\n    background-color: ", ";\n\n    border-radius: 100%;\n    display: inline-block;\n    animation: bounce 1.4s infinite ease-in-out both;\n  }\n\n  .spinner .bounce1 {\n    animation-delay: -0.32s;\n    background-color: ", ";\n  }\n\n  .spinner .bounce2 {\n    animation-delay: -0.16s;\n    background-color: #ffda00;\n  }\n\n  .spinner .bounce3 {\n    background-color: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Thu Jun 04 2018
 * @author BPo
 */
import * as React from 'react';
import styled, { css } from 'styled-components';

var Skeleton = function Skeleton(props) {
  return React.createElement("div", {
    className: props.className
  }, React.createElement("div", {
    className: "spinner"
  }, React.createElement("span", {
    className: "bounce bounce1"
  }), React.createElement("span", {
    className: "bounce bounce2"
  }), React.createElement("span", {
    className: "bounce bounce3"
  })));
};

var StyledLoader = styled(Skeleton)(_templateObject(), function (props) {
  if (props.container !== false) {
    return css(_templateObject2(), props.background ? css(_templateObject3(), props.background) : '');
  }

  return null;
}, function (props) {
  return props.theme.colors.text;
}, function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.colors.primary;
});
/**
 * Styled loader
 */

export default (function (props) {
  return React.createElement(StyledLoader, Object.assign({}, props));
});