/**
 * @file Created on Fri Feb 01 2019
 * @author SKu
 */
import { Utils, NewProduct } from '@logio/common-be-fe';
import * as React from 'react';
import translate from '../../i18n/translate';
import Icon, { IconColor, IconType } from '../../media/Icon';
import Tooltip from '../Tooltip';
import { TooltipType } from '../Tooltip/TooltipType';
import logger from '../../../shared/logger';
/**
 * key - Flag.TAG
 * value - IconType
 * Make sure that properly icon exist
 */

var FlagTypeToIconTypeEnum;

(function (FlagTypeToIconTypeEnum) {
  FlagTypeToIconTypeEnum["NewProduct"] = "newsFull";
  FlagTypeToIconTypeEnum["LeadingProduct"] = "leader";
  FlagTypeToIconTypeEnum["WeightedProduct"] = "scale";
  FlagTypeToIconTypeEnum["SeasonalProduct"] = "seasonal";
  FlagTypeToIconTypeEnum["FixedPriceProduct"] = "fixedPrice";
  FlagTypeToIconTypeEnum["BlockedProduct"] = "blockedProduct";
  FlagTypeToIconTypeEnum["SelloutRestricted"] = "selloutRestriction";
  FlagTypeToIconTypeEnum["RecommendedProduct"] = "newsRecommend";
  FlagTypeToIconTypeEnum["PrivateLabel"] = "privateLabel";
  FlagTypeToIconTypeEnum["FirstPricePoint"] = "firstPricePoint";
  FlagTypeToIconTypeEnum["Campaign"] = "returnable";
})(FlagTypeToIconTypeEnum || (FlagTypeToIconTypeEnum = {}));
/**
 * FlagsRenderer component
 * Render FlagSet as icon with tooltip
 * to add new FlagType, simply add it to FlagTypeToIconTypeEnum
 */


var FlagsRenderer = function FlagsRenderer(_ref) {
  var flagSet = _ref.flagSet,
      iconHeight = _ref.iconHeight;

  var getFlagIcon = function getFlagIcon(flag) {
    return React.createElement(Tooltip, {
      key: flag.tag,
      tooltipType: TooltipType.Info,
      overlay: flag.title ? "".concat(translate("flag-".concat(flag.tag)), " (").concat(flag.title, ")") : translate("flag-".concat(flag.tag)),
      placement: 'right',
      children: React.createElement("span", null, React.createElement(Icon, {
        iconType: getIconType(flag.tag),
        iconHeight: iconHeight,
        iconColor: IconColor.Secondary
      }))
    });
  };

  var getIconType = function getIconType(tag) {
    var iconType = IconType[FlagTypeToIconTypeEnum[tag]];

    if (Utils.isValueMissing(iconType)) {
      logger.error("Flag ".concat(tag, " is not supported yet"));
      return IconType[FlagTypeToIconTypeEnum.NewProduct];
    } else {
      return iconType;
    }
  };

  var getFlagItems = function getFlagItems() {
    return flagSet.serialize().map(function (flag) {
      if (flag.tag === NewProduct.name && !flag.newProduct) {
        return null;
      }

      return getFlagIcon(flag);
    });
  };

  return React.createElement(React.Fragment, null, !Utils.isValueMissing(flagSet) && getFlagItems());
};

export default FlagsRenderer;