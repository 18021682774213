import { DateParser, RealImpactValue, Release, ReleaseState, ReleaseType, SelloutImpactRecord, StructPropBuilder, Utils, ValueOfObjectDesc } from '@logio/common-be-fe';
import { ColumnDefinition, ColumnGenerator, Comparators, CONSTANTS, getDateTimeFormat, RendererNames, translate } from '@logio/common-fe';
import moment from 'moment';

// hoping it is possible to init builder during parsing
const builder = new StructPropBuilder('ReleaseList');
const schema = {
  favourite: Release.schema.favourite,
  id: Release.schema.id,
  name: Release.schema.name,
  workflowType: Release.schema.workflowType,
  releaseType: Release.schema.releaseType,
  priceListType: Release.schema.priceListType,
  pricingType: Release.schema.pricingType,
  totalPriceChangeLimit: Release.schema.totalPriceChangeLimit,
  priceValidityStartDate: Release.schema.priceValidityStartDate,
  cmDeadline: Release.schema.cmDeadline,
  exported: Release.schema.exported,
  state: Release.schema.state,
  endDate: builder.date('endDate', DateParser.ISO_PATTERN),
  minPriceChangeInPercent: builder.bigNum('minPriceChangeInPercent'),
  maxPriceChangeInPercent: builder.bigNum('maxPriceChangeInPercent'),
  minMarginChangeInPercent: builder.bigNum('minMarginChangeInPercent'),
  ...RealImpactValue.schema,
  discountPercent: SelloutImpactRecord.schema.discountPercent,
  availableSupplyPrice: SelloutImpactRecord.schema.availableSupplyPrice,
  availableSupply: SelloutImpactRecord.schema.availableSupply,
  availableSupplyBeforeRepricingPrice: SelloutImpactRecord.schema.availableSupplyBeforeRepricingPrice,
  availableSupplyBeforeRepricing: SelloutImpactRecord.schema.availableSupplyBeforeRepricing,
  availableSupplyPriceChange: SelloutImpactRecord.schema.availableSupplyPriceChange,
  availableSupplyChange: SelloutImpactRecord.schema.availableSupplyChange,
  availableSupplyPriceChangePercent: SelloutImpactRecord.schema.availableSupplyPriceChangePercent,
  availableSupplyChangePercent: SelloutImpactRecord.schema.availableSupplyChangePercent,
  daysSinceFirstRepricing: builder.num('daysSinceFirstRepricing'),
  daysSinceLastRepricing: builder.num('daysSinceLastRepricing'),
}

/**
 * Generates common release columns. Designed for Release page.
 *
 * TODO: Impact should be separated.
 */
export class ReleaseColumnGenerator {
  columnGenerator = new ColumnGenerator(schema);

  getColumnDefinitions(releaseTypes: ReleaseType[], impactMode: boolean) {
    const columns = columnDefinitions();

    columns.filter(impactMode ? notInImpactMode : inImpactModeOnly).forEach(column => (column.dontCreateColumn = true));

    if (releaseTypes.indexOf(ReleaseType.Sellout) === -1) {
      columns.filter(selloutColumn).forEach(column => (column.dontCreateColumn = true));
    }

    if (releaseTypes.indexOf(ReleaseType.Regular) === -1) {
      columns.filter(regularReleaseColumn).forEach(column => (column.dontCreateColumn = true));
    }

    return this.columnGenerator.getColumnDefinitions(columns);
  }

  getColumnData(data: Partial<ValueOfObjectDesc<typeof schema>>) {
    return this.columnGenerator.getColumnData(data);
  }
}

/**
 * Get column definitions, must be function because of `translate` function inside.
 */
function columnDefinitions(): ColumnDefinition[] {
  return [
    {
      field: 'favourite',
      cellRenderer: RendererNames.FavoriteItemRenderer,
      booleanFilterMap: {
        true: translate('filter-title-favorite'),
        false: translate('filter-title-not'),
      },
    },
    { field: 'id' },
    { field: 'name' },
    {
      field: 'workflowType',
      valueFormatter: ({ value }) => translate(value),
    },
    { field: 'releaseType' },
    {
      field: 'priceListType',
      filter: 'agSetColumnFilter',
    },
    { field: 'pricingType' },
    { field: 'totalPriceChangeLimit' },
    { field: 'minPriceChangeInPercent' },
    { field: 'maxPriceChangeInPercent' },
    { field: 'minMarginChangeInPercent' },
    { field: 'priceValidityStartDate' },
    { field: 'cmDeadline' },
    { field: 'endDate' },
    {
      field: 'exported',
      valueFormatter: getExportedValueFormatter,
    },
    {
      field: 'state',
      comparator: compareReleaseStates,
      cellRenderer: RendererNames.CircleStateRenderer,
      cellClassRules: {
        [ReleaseState.New]: ({value}: {value: ReleaseState}) => value === ReleaseState.New,
        [ReleaseState.Reopening]: ({value}: {value: ReleaseState}) => value === ReleaseState.Reopening,
        [ReleaseState.Computing]: ({value}: {value: ReleaseState}) => value === ReleaseState.Computing,
        [ReleaseState.ComputationFailed]: ({value}: {value: ReleaseState}) => value === ReleaseState.ComputationFailed,
        [ReleaseState.Opened]: ({value}: {value: ReleaseState}) => value === ReleaseState.Opened,
        [ReleaseState.Approved]: ({value}: {value: ReleaseState}) => value === ReleaseState.Approved,
        [ReleaseState.ExportChecking]: ({value}: {value: ReleaseState}) => value === ReleaseState.ExportChecking,
        [ReleaseState.ExportEditing]: ({value}: {value: ReleaseState}) => value === ReleaseState.ExportEditing,
        [ReleaseState.ExportStarted]: ({value}: {value: ReleaseState}) => value === ReleaseState.ExportStarted,
        [ReleaseState.ExportFailed]: ({value}: {value: ReleaseState}) => value === ReleaseState.ExportFailed,
        [ReleaseState.Released]: ({value}: {value: ReleaseState}) => value === ReleaseState.Released,
        [ReleaseState.Cancelled]: ({value}: {value: ReleaseState}) => value === ReleaseState.Cancelled,
        [ReleaseState.Inactive]: ({value}: {value: ReleaseState}) => value === ReleaseState.Inactive,
      },
    },
    {
      field: 'bmImpact',
      comparator: Comparators.arrowComparator,
      cellRenderer: RendererNames.ArrowRenderer,
    },
    {
      field: 'salesVolumeImpact',
      comparator: Comparators.arrowComparator,
      cellRenderer: RendererNames.ArrowRenderer,
    },
    {
      field: 'revenueImpact',
      comparator: Comparators.arrowComparator,
      cellRenderer: RendererNames.ArrowRenderer,
    },
    {
      field: 'diffDays',
      valueFormatter: (params) => {
        return params.value ? Number(params.value).toFixed(0) : CONSTANTS.FORMAT.NULL;
      },
    },
    { field: 'bm' },
    { field: 'amount' },
    { field: 'saleValueWithoutVat' }, // LOG-5715
    { field: 'discountPercent' },
    { field: 'availableSupply' },
    { field: 'availableSupplyPrice' },
    { field: 'availableSupplyBeforeRepricing' },
    { field: 'availableSupplyBeforeRepricingPrice' },
    { field: 'availableSupplyPriceChange' },
    { field: 'availableSupplyChange' },
    { field: 'availableSupplyPriceChangePercent' },
    { field: 'availableSupplyChangePercent' },
    { field: 'daysSinceFirstRepricing' },
    { field: 'daysSinceLastRepricing' },
  ];
}

/**
 * Returns true for columns, which should be visible only in impact mode.
 * @param column
 */
function inImpactModeOnly(column: ColumnDefinition) {
  return [
    'bmImpact',
    'salesVolumeImpact',
    'revenueImpact',
    'diffDays',
    'bm',
    'amount',
    'saleValueWithoutVat',
  ].indexOf(column.field) >= 0;
}

/**
 * Returns true for columns, which should not be visible in impact mode.
 * @param column
 */
function notInImpactMode(column: ColumnDefinition) {
  return [
    'discountPercent',
    'availableSupply',
    'availableSupplyPrice',
    'availableSupplyBeforeRepricing',
    'availableSupplyBeforeRepricingPrice',
    'availableSupplyPriceChange',
    'availableSupplyChange',
    'availableSupplyPriceChangePercent',
    'availableSupplyChangePercent',
  ].indexOf(column.field) >= 0;
}

/**
 * Returns true for columns which should be visible for sellouts
 */
function selloutColumn(column: ColumnDefinition) {
  return [
    'minPriceChangeInPercent',
    'maxPriceChangeInPercent',
    'minMarginChangeInPercent',
    'discountPercent',
    'availableSupplyPrice',
    'availableSupply',
    'availableSupplyBeforeRepricingPrice',
    'availableSupplyBeforeRepricing',
    'availableSupplyPriceChange',
    'availableSupplyChange',
    'availableSupplyPriceChangePercent',
    'availableSupplyChangePercent',
    'daysSinceFirstRepricing',
    'daysSinceLastRepricing',
  ].indexOf(column.field) >= 0;
}

/**
 * Returns true for columns which should be visible for regular
 */
function regularReleaseColumn(column: ColumnDefinition) {
  return [
    'pricingType',
  ].indexOf(column.field) >= 0;
}

/** Exported value should be shown with export time */
function getExportedValueFormatter({value}: {value: moment.Moment}) {
  return Utils.isValueMissing(value) ? CONSTANTS.FORMAT.NULL : value.format(getDateTimeFormat());
}

/**
 * Sort rows in the next order =>
 * [New, Computing, Opened, Approved, ExportFailed, Released, Canceled]
 * @param valA
 * @param valB
 */
function compareReleaseStates(valA: ReleaseState, valB: ReleaseState) {
  return convertReleaseState(valA) - convertReleaseState(valB);
}

/**
 * Function converts Release state to number
 * @param state - translated RelaseState enum
 * @return {number} - value based on release state importance
 */
function convertReleaseState(state: string) {
  switch (state) {
    case translate(ReleaseState.New):
      return 0;
    case translate(ReleaseState.Computing):
      return 1;
    case translate(ReleaseState.ComputationFailed):
      return 2;
    case translate(ReleaseState.Opened):
      return 3;
    case translate(ReleaseState.Approved):
      return 4;
    case translate(ReleaseState.ExportChecking):
      return 5;
    case translate(ReleaseState.ExportEditing):
      return 6;
    case translate(ReleaseState.ExportStarted):
      return 7;
    case translate(ReleaseState.ExportFailed):
      return 8;
    case translate(ReleaseState.Cancelled):
      return 9;
    case translate(ReleaseState.Released):
      return 10;
    case translate(ReleaseState.Inactive):
      return 11;
    case translate(ReleaseState.Reopening):
      return 12;
    default:
      throw new Error('ReleaseState is not supported');
  }
};
