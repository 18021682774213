/**
 * @file Created on Mon Sep 17 2018
 * @author BPo
 */

/**
 * Forms automatically evaluate how to render fields, or
 * the element can be selected manually.
 */
export var FormFieldTypeEnum;

(function (FormFieldTypeEnum) {
  FormFieldTypeEnum["DatePicker"] = "DATE_PICKER";
  FormFieldTypeEnum["CheckBox"] = "CHECKBOX";
  FormFieldTypeEnum["Input"] = "INPUT";
  FormFieldTypeEnum["InputRange"] = "INPUT_RANGE";
  FormFieldTypeEnum["RangeSlider"] = "RANGE_SLIDER";
  FormFieldTypeEnum["Select"] = "SELECT";
  FormFieldTypeEnum["SelectMulti"] = "SELECT_MULTI";
  FormFieldTypeEnum["Slider"] = "SLIDER";
  FormFieldTypeEnum["TextArea"] = "TEXT_AREA";
  /**
   * Originally rendered as multi-select. This option will
   * be displayed as Checkbox group component
   */

  FormFieldTypeEnum["CheckBoxGroup"] = "CHECKBOX_GROUP";
  /**
   * Originally rendered as select. This option will be
   * displayed as Radio list component
   */

  FormFieldTypeEnum["RadioList"] = "RADIO_LIST";
  /**
   * Originally rendered as select. This option will be
   * displayed as Radio button component
   */

  FormFieldTypeEnum["RadioButton"] = "RADIO_BUTTON";
})(FormFieldTypeEnum || (FormFieldTypeEnum = {}));