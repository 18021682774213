/**
 * @file Created on Mon Nov 26 2019
 *
 * @author Tomáš Leity
 * @author Vojtěch Smolař
 */

/**
 * @enum
 */
export var DPTaskState;

(function (DPTaskState) {
  /**
   * Task finished
   */
  DPTaskState["finished"] = "finished";
  /**
   * Task is being processed.
   */

  DPTaskState["processing"] = "processing";
})(DPTaskState || (DPTaskState = {}));