import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  background-color: ", ";\n  margin: 0 -", "rem;\n  border: 1px solid ", ";\n  border-width: 1px 0;\n  padding: ", "rem;\n  flex: 1;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Tue Sep 25 2018
 * @author PKl
 */
import styled, { withTheme } from 'styled-components';
var BoxTabContainerStyled = styled.div(_templateObject(), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.paper;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.spacing.spacer * 2;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.border;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.spacing.spacer * 2;
});
export default withTheme(BoxTabContainerStyled);