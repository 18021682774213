import _defineProperty from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

/**
 * @file Created on Fri Jan 31 2020
 * @author Tomáš Leity
 */
import { default as DefaultLoadingOverlay } from './DefaultLoadingOverlay';
/**
 * @enum OverlayNames
 */

export var OverlayNames;

(function (OverlayNames) {
  OverlayNames["DefaultLoadingOverlay"] = "DEFAULT_LOADING_OVERLAY";
})(OverlayNames || (OverlayNames = {}));

var Overlays = _defineProperty({}, OverlayNames.DefaultLoadingOverlay, DefaultLoadingOverlay);

export default Overlays;