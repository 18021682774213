/**
 * @file Created on Wed Aug 08 2018
 * @author BPo
 */
import { bigdecimal } from '@logio/big-decimal';
import { ParamParsers } from '@logio/common-be-fe';
import merge from 'lodash/merge';
var CONSTANTS = {
  APP: {
    NAME: window._CONFIG.APP.NAME,
    TITLE_SUFFIX_KEY: window._CONFIG.APP.TITLE_SUFFIX_KEY,
    PRICING_HOST: window._CONFIG.APP.PRICING_HOST,
    PROMO_HOST: window._CONFIG.APP.PROMO_HOST,
    API_HOST: window._CONFIG.APP.API_HOST,
    DP_HOST: window._CONFIG.APP.DP_HOST,
    PPM_HOST: window._CONFIG.APP.PPM_HOST,
    STORE_REPLE_HOST: window._CONFIG.APP.STORE_REPLE_HOST,
    DC_REPLE_HOST: window._CONFIG.APP.DC_REPLE_HOST
  },
  APP_ALERT: {
    enabled: false,
    message: null,
    type: null
  },
  SENTRY: {
    dsn: undefined,
    environment: undefined
  },
  KEYCLOAK: {
    CONFIG: {
      bearer: window._CONFIG.KEYCLOAK.CONFIG.bearer,
      url: window._CONFIG.KEYCLOAK.CONFIG.url,
      realm: 'logiopp',
      clientId: 'logiopp-fe'
    },
    INIT_OPTIONS: {
      onLoad: 'login-required',
      flow: 'standard'
    },
    SUBSTITUTED_USER_ID: 'SUBSTITUTED_USER_ID',
    TOKENS_STORAGE: window._CONFIG.KEYCLOAK.TOKENS_STORAGE,
    STORAGE_TOKEN: 'KC_TOKEN',
    STORAGE_ID_TOKEN: 'KC_ID_TOKEN',
    STORAGE_REFRESH_TOKEN: 'KC_REFRESH_TOKEN',
    MIN_VALIDITY: 5
  },
  API: {
    ENDPOINTS: {
      TASK_OPENED: '/dashboard-tasks/open',
      PRODUCTS: '/products',
      PRODUCTS_FILTER: '/products/filter',
      PRODUCTS_IN_CATEGORY: function PRODUCTS_IN_CATEGORY(categoryId) {
        return "/products/category/".concat(categoryId);
      },
      PURCHASE_PRICE_IN_CATEGORY: function PURCHASE_PRICE_IN_CATEGORY(categoryId) {
        // FIXME: Implement the right solution. See PurchasePriceRouterProvider.ts
        // return `/products/purchase-price/category/${categoryId}`;
        return "/purchase-prices/regular/category/".concat(categoryId);
      },
      COMPETITOR_PRODUCT: '/competitor-product',
      COMPETITOR_PRICE_CHANGE_REPORT: '/reports/competitor-price-change-report',
      CURRENCY: '/currencies',
      PRODUCTS_UPDATE_TIER: '/products/update-tier',
      PRODUCT_SENSITIVITY: '/sensitivity-groups',
      PRODUCTS_UPDATE_SENSITIVITY: '/products/update-sensitivity',
      PRODUCTS_SUPPLIED: '/products/supplied',
      PRODUCTS_APPROVE_PRICE_SETTINGS: function PRODUCTS_APPROVE_PRICE_SETTINGS(id) {
        return "/products/approve-price-settings/".concat(id);
      },
      PRODUCTS_SET_LEADER: function PRODUCTS_SET_LEADER(id) {
        return "/families/set-leader/".concat(id);
      },
      PRODUCTS_SUPPLIERS: 'products/suppliers',
      PRODUCTS_WINDOW: 'products/window',
      PRODUCTS_WINDOW_BY_CATEGORY: function PRODUCTS_WINDOW_BY_CATEGORY(categoryId) {
        return "products/window/".concat(categoryId);
      },
      PRODUCTS_SECONDARY_SUPPLIERS_ID: '/products/secondary-suppliers',
      PRODUCTS_SECONDARY_SUPPLIERS_ID_GROUPS: '/products/secondary-suppliers/groups',
      PRODUCT_CATEGORY_STATS: '/products/stats',
      COPY_DESIGNATION_TO_ZONES: '/copy-designation-to-zone',
      CATEGORIES: '/categories',
      CATEGORIES_USER: '/categories/user',
      FULL_TEXT_SEARCH: '/full-text-search',
      FULL_TEXT_SEARCH_UNPAIRED: '/full-text-search-unpaired',
      DATA_PAIRING: '/data-pairing',
      FAVORITES: '/favourites',
      OVERVIEW: '/overview',
      RELEASE_EDIT_HEADER: '/forms/header-edit',
      RELEASE_ACTION: function RELEASE_ACTION(id) {
        return "/".concat(id, "/action");
      },
      RELEASE_OPEN: function RELEASE_OPEN(id) {
        return "/".concat(id, "/open");
      },
      RELEASE_UPLOAD_PRICES: function RELEASE_UPLOAD_PRICES(id) {
        return "/".concat(id, "/price-upload");
      },
      RELEASE_UPLOADED_PRICES: function RELEASE_UPLOADED_PRICES(id, uploadId) {
        return "/".concat(id, "/price-upload/").concat(uploadId);
      },
      RELEASE_ITEMS: function RELEASE_ITEMS(id) {
        return "/".concat(id, "/items");
      },
      RELEASES_DASHBOARD: '/dashboard',
      RELEASE_SELLOUT_TOTAL: function RELEASE_SELLOUT_TOTAL(id) {
        return "/sellout/".concat(id, "/total");
      },
      RELEASE_SELLOUT_PLT_WITH_FIXED_DATE: '/sellout/price-lists-with-fixed-end-date',
      RELEASE_SELLOUT_CATEGORY_TOTAL: function RELEASE_SELLOUT_CATEGORY_TOTAL(id, categoryId) {
        return "/sellout/".concat(id, "/category-with-children-impact/").concat(categoryId);
      },
      RELEASE_SELLOUT_CATEGORY_SUBTOTAL: function RELEASE_SELLOUT_CATEGORY_SUBTOTAL(id, categoryId) {
        return "/sellout/".concat(id, "/category-impact/").concat(categoryId);
      },
      RELEASE_SELLOUT_CATEGORY_ITEMS: function RELEASE_SELLOUT_CATEGORY_ITEMS(id, categoryId) {
        return "/sellout/".concat(id, "/total/").concat(categoryId);
      },
      RELEASE_SELLOUT_CATEGORY_ITEMS_EXPORT: function RELEASE_SELLOUT_CATEGORY_ITEMS_EXPORT(id) {
        return "/sellout/".concat(id, "/category-total");
      },
      RELEASE_SELLOUT_ITEMS: function RELEASE_SELLOUT_ITEMS(releaseId) {
        return "/sellout/".concat(releaseId, "/items");
      },
      RELEASE_SELLOUT_PROMO: function RELEASE_SELLOUT_PROMO(releaseItemId) {
        return "/sellout/release-item/".concat(releaseItemId, "/promo");
      },
      RELEASE_PROMO: function RELEASE_PROMO(releaseItemId, zoneId) {
        return "/release-item/".concat(releaseItemId, "/zone/").concat(zoneId, "/promo");
      },
      RELEASE_SELLOUT_REOPEN: '/sellout/reopen',
      RELEASE_SELLOUT_UPDATE_TARGETS: '/sellout/update-targets',
      RELEASE_SELLOUT_GET_PRICE_LIST_SETTING: '/price-list-settings',
      RELEASE_SELLOUT_UPDATE_ITEMS: '/sellout/update-items',
      RELEASE_FAVORITE: function RELEASE_FAVORITE(releaseId) {
        return "/".concat(releaseId, "/favourite");
      },
      RELEASE_ITEM_COMMENT: function RELEASE_ITEM_COMMENT(releaseItemId) {
        return "/release-item/".concat(releaseItemId, "/comment");
      },
      RELEASE_ITEM_FINAL_PRICE: function RELEASE_ITEM_FINAL_PRICE(releaseItemId) {
        return "/release-item/".concat(releaseItemId, "/final-price");
      },
      RELEASE_ITEM_PRICE_CODE: function RELEASE_ITEM_PRICE_CODE(releaseItemId) {
        return "/release-item/".concat(releaseItemId, "/price-code");
      },
      RELEASE_ITEM_EXPORTED: function RELEASE_ITEM_EXPORTED(releaseItemId) {
        return "/release-item/".concat(releaseItemId, "/exported");
      },
      RELEASE_COPY_TO_GENERAL_SETTINGS: function RELEASE_COPY_TO_GENERAL_SETTINGS(releaseID) {
        return "/".concat(releaseID, "/copy-to-general-settings");
      },
      PRICE_CHANGE_REPORT: '/reports/price-change-report',
      IMPACT_REPORT: '/reports/price-impact-report/releases',
      IMPACT_REPORT_TOTALS: function IMPACT_REPORT_TOTALS(releaseId) {
        return "/reports/price-impact-report/releases/".concat(releaseId, "/total");
      },
      IMPACT_REPORT_ITEMS: function IMPACT_REPORT_ITEMS(releaseId) {
        return "/reports/price-impact-report/releases/".concat(releaseId, "/items");
      },
      IMPACT_REPORT_SELLOUT_TOTALS: function IMPACT_REPORT_SELLOUT_TOTALS(releaseId) {
        return "/reports/price-impact-report/releases/sellout/".concat(releaseId, "/total");
      },
      IMPACT_REPORT_SELLOUT_ITEMS: function IMPACT_REPORT_SELLOUT_ITEMS(releaseId) {
        return "/reports/price-impact-report/releases/sellout/".concat(releaseId, "/items");
      },
      REAL_IMPACT_REPORT_SELLOUT_PRODUCTS: function REAL_IMPACT_REPORT_SELLOUT_PRODUCTS(releaseId) {
        return "/reports/price-impact-report/releases/sellout/".concat(releaseId, "/products");
      },
      PRICE_STICKERS_REPORT: '/reports/price-stickers-report',
      SELLOUT_IDENTIFICATION_REPORT: '/reports/sellout-identification-report',
      SELLOUT_IDENTIFICATION_REPORT_DETAIL: '/reports/sellout-identification-report-detail',
      PRICE_ELASTICITY_REPORT: '/reports/price-elasticity-report',
      PRICE_ELASTICITY_REPORT_CLEAR: '/reports/clear-price-elasticity-report',
      STATS: '/stats',
      RELEASE_TOTAL: function RELEASE_TOTAL(id) {
        return "/".concat(id, "/total");
      },
      RELEASE_SUBTOTAL: function RELEASE_SUBTOTAL(releaseId, categoryId) {
        return "/".concat(releaseId, "/category-impact/").concat(categoryId);
      },
      FORMS: '/forms',
      ROUNDING_RULE: '/rounding-rule',
      ROUNDING_RULE_ROUND: '/round',
      PAIR_UNPAIRED: '/pair-unpaired',
      PAIR: '/pair',
      UNPAIR_PAIRED: '/unpair-paired',
      UPDATE_PAIR_PARAMETERS: '/update-pair-parameters',
      CATEGORY: function CATEGORY(categoryId) {
        return "/categories/".concat(categoryId);
      },
      ZONES: '/zones',
      NATIONAL_ZONE: '/zones/national-zone',
      ZONES_FORMS_EDIT: function ZONES_FORMS_EDIT(id) {
        return id ? "/zones/forms/edit?id=".concat(id) : '/zones/forms/edit';
      },
      ARCHIVE_ZONE: function ARCHIVE_ZONE(id) {
        return "/zones/".concat(id, "/archive");
      },
      ZONES_CHANGE_COMPETITORS: '/zones/change-competitors',
      COMPETITOR_SITE: '/sites',
      COMPETITOR_SITE_CHANGE_STATE: '/sites/change-state',
      GROUP_OF_SITES: '/groups-of-sites',
      GROUP_OF_SITES_FILTER: '/groups-of-sites/filter',
      GROUP_OF_SITES_FORMAT: '/groups-of-sites/site-format',
      RELEASES: '/releases',
      SELLOUT: '/sellout',
      RELEASES_ONE: function RELEASES_ONE(id) {
        return "/".concat(id);
      },
      CREATE_SELLOUT_RELEASE: '/sellout',
      SELLOUT_SETTINGS: "/sellout-settings",
      SELLOUT_SETTINGS_UPDATE: '/sellout-settings/update',
      SELLOUT_TERMINATION_ITEMS_TERMINATE: '/sellout-termination-items/termination',
      SELLOUT_TERMINATION_ITEMS_TERMINATE_DATE: '/sellout-termination-items/termination-date',
      SELLOUT_TERMINATION_UPLOADS_ID: function SELLOUT_TERMINATION_UPLOADS_ID(id) {
        return "/sellout-termination-uploads/".concat(id);
      },
      SELLOUT_TERMINATION_UPLOADS_FILE: '/sellout-termination-uploads/file',
      SELLOUT_TERMINATION_SUMMARIES: function SELLOUT_TERMINATION_SUMMARIES(id) {
        return "/sellout-termination-summaries/".concat(id);
      },
      SELLOUT_TERMINATION_CONFIRMATION: function SELLOUT_TERMINATION_CONFIRMATION(id, rId) {
        return "/sellout-termination-summaries/".concat(id, "/termination-confirmation?rId=").concat(rId);
      },
      SELLOUT_TERMINATIONS: '/sellout-terminations',
      SELLOUT_TERMINATIONS_ID: function SELLOUT_TERMINATIONS_ID(id) {
        return "/sellout-terminations/".concat(id);
      },
      SELLOUT_TERMINATIONS_ITEMS: function SELLOUT_TERMINATIONS_ITEMS(id) {
        return "/sellout-terminations/".concat(id, "/items");
      },
      SELLOUT_TERMINATIONS_SUMMARY: function SELLOUT_TERMINATIONS_SUMMARY(id) {
        return "/sellout-terminations/".concat(id, "/summary");
      },
      SELLOUT_TERMINATIONS_CONFIRMATION: function SELLOUT_TERMINATIONS_CONFIRMATION(id) {
        return "/sellout-terminations/".concat(id, "/termination-confirmation");
      },
      SELLOJUT_TERMINATIONS_CANCELL: function SELLOJUT_TERMINATIONS_CANCELL(id) {
        return "/sellout-terminations/".concat(id, "/cancellation");
      },
      INCLUDE_CATEGORIES: '/include-categories',
      EXCLUDE_CATEGORIES: '/exclude-categories',
      SETTINGS_OVERVIEW: '/settings/overview',
      SETTINGS_WINDOW_TYPE: '/window-type',
      SETTINGS_WINDOW_TYPE_BLOCK: '/window-type/change-block',
      SETTINGS_WINDOW_TYPE_SINGLE: function SETTINGS_WINDOW_TYPE_SINGLE(windowTypeId) {
        return "/window-type/".concat(windowTypeId);
      },
      RELEASES_QUERY: '/?state=',
      USER: '/user',
      TIERS: '/tiers',
      THEMES: '/themes',
      PROMOTIONS: '/promotions',
      PROMOTIONS_SPLITS: '/promotions/splits',
      PROMOTIONS_UNLOCK_SOME: '/promotions/unlock',
      // following master/promotion areas related apis should be redesigned
      PROMOTIONS_ASSIGNED_AREAS: '/promotions/assigned-areas',
      PROMOTIONS_AREAS_CHECK: '/promotions/areas/pre-assignment-validation',
      PROMOTIONS_AREAS_COPY: '/promotions/areas/assignment-from-promotions-validation',
      PROMOTIONS_AREAS_ASSIGN: '/promotions/areas/assignment-confirmation',
      PROMOTIONS_AREAS_BINDING_PROMOTION_IDS: '/promotions/ids/with-assigned-areas',
      PROMOTIONS_ACTION: '/promotions/action',
      PROMOTIONS_OVERVIEW: '/promotions/overview',
      PROMOTIONS_FILTER: '/promotions/filter',
      PROMOTIONS_LOCK: function PROMOTIONS_LOCK(id) {
        return "/promotions/".concat(id, "/lock");
      },
      PROMOTIONS_UNLOCK: function PROMOTIONS_UNLOCK(id) {
        return "/promotions/".concat(id, "/unlock");
      },
      PROMOTIONS_SINGLE: function PROMOTIONS_SINGLE(id) {
        return "/promotions/".concat(id);
      },
      PROMOTIONS_WORKFLOW_STATE: function PROMOTIONS_WORKFLOW_STATE(promotionId) {
        return "/promotions/".concat(promotionId, "/status");
      },
      PROMOTIONS_WORKFLOW_STATES: "/promotions/status",
      PROMOTIONS_CHANGE_HISTORY: function PROMOTIONS_CHANGE_HISTORY(promotionId) {
        return "/promotions/".concat(promotionId, "/history");
      },
      PROMOTIONS_CHANGE_HISTORY_SET: function PROMOTIONS_CHANGE_HISTORY_SET(promotionId, setId) {
        return "/promotions/".concat(promotionId, "/history/changeset/").concat(setId);
      },
      PROMOTIONS_CONFIG: '/promotions/config',
      APPROVE: '/approve',
      REJECT: '/reject',
      USER_SUBSTITUTED: '/user/substituted',
      RESOURCE_LOCK: '/resource-lock',
      RESOURCE_LOCK_LOCK: function RESOURCE_LOCK_LOCK(id) {
        return "/resource-lock/".concat(id, "/lock");
      },
      RESOURCE_LOCK_UNLOCK: function RESOURCE_LOCK_UNLOCK(id) {
        return "/resource-lock/".concat(id, "/unlock");
      },
      USERS: '/user/profile/list',
      USER_PROFILE: '/user/profile/login',
      USER_INFO: function USER_INFO(userId) {
        return "".concat(this.BASE, "users/").concat(userId);
      },
      CODEBOOK_LOOKUP: '/codebook/lookup/',
      DATA_UPLOAD: '/data-upload',
      DATA_UPLOAD_SENSITIVITY: '/data-upload/product-sensitivity',
      COMPETITORS_DATA: '/competitors-data',
      FAMILIES: '/families',
      FAMILIES_IN_CATEGORY: function FAMILIES_IN_CATEGORY(categoryId) {
        return "/families/category/".concat(categoryId);
      },
      FAMILY_UPDATE_BINDINGS: '/families/update-binding',
      FAMILY_UPDATE_TIER: '/families/update-tier',
      FAMILY_UPDATE_NAME: '/families/update-name',
      FAMILY_UNASSIGN_PRODUCTS: '/families/unassign-products',
      FAMILY_ASSIGN_PRODUCTS: function FAMILY_ASSIGN_PRODUCTS(familyId) {
        return "/families/".concat(familyId, "/assign-products");
      },
      FAMILY_CREATE_WITH_AFFECTED: '/families/create-with-affected',
      GENERAL_SETTINGS_OVERVIEW: '/general-settings/overview',
      GENERAL_SETTINGS: '/general-settings',
      GENERAL_SETTINGS_SAVE: '/general-settings/save',
      GENERAL_SETTINGS_COPY_ZONES: '/general-settings/copy-zones',
      GENERAL_SETTINGS_COPY_CATEGORIES: '/general-settings/copy-category',
      GENERAL_SETTINGS_CATEGORY: '/general-settings/category/',
      GENERAL_SETTINGS_RESOLVE_CATEGORY: '/general-settings/resolve/category/',
      GENERAL_SETTINGS_OPTIMIZATION_GRAPH: '/general-settings/optimization-graph',
      COMPETITORS: '/competitors',
      COMPETITORS_PRIORITY: '/competitors/priority',
      SUPPLIER_AGREEMENTS: '/supplier-agreement',
      SUPPLIER_AGREEMENTS_CREATE: function SUPPLIER_AGREEMENTS_CREATE(sessionId) {
        return "/supplier-agreement".concat(sessionId ? "?sessionId=".concat(sessionId) : '');
      },
      SUPPLIER_AGREEMENTS_FILTER: function SUPPLIER_AGREEMENTS_FILTER(sessionId) {
        return "/supplier-agreement/filter".concat(sessionId ? "?sessionId=".concat(sessionId) : '');
      },
      SUPPLIER_AGREEMENTS_DELETE: function SUPPLIER_AGREEMENTS_DELETE(sessionId) {
        return "/supplier-agreement".concat(sessionId ? "?sessionId=".concat(sessionId) : '');
      },
      SUPPLIER_AGREEMENTS_RECALCULATE_PURCHASE_PRICES: function SUPPLIER_AGREEMENTS_RECALCULATE_PURCHASE_PRICES(sessionId) {
        return "/supplier-agreement/recalculate-purchase-prices".concat(sessionId ? "?sessionId=".concat(sessionId) : '');
      },
      SUPPLIER_AGREEMENTS_PROMO_PURCHASE_PRICE_RESET: function SUPPLIER_AGREEMENTS_PROMO_PURCHASE_PRICE_RESET(sessionId) {
        return "/supplier-agreement/promo-purchase-price/reset".concat(sessionId ? "?sessionId=".concat(sessionId) : '');
      },
      TRANSFER_STATUS_IMPORT: "/transfer-status/import",
      DP_WORKFLOW_CONFIGURATION: "/dp-workflow/configuration",
      TRANSFER_STATUS_DP_TASK: "/transfer-status/dp-task",
      EXPORT_QUE_PROMOTION: "/export/promotion",
      EXPORT_QUE_PROMOTION_FORECAST: "/export/forecast",
      EXPORT_QUE_PROMOTION_PLID: "/export/promotion-plid",
      PRE_EXPORT_QUE_PROMOTION_FORECAST: "/export/pre-forecast",
      PROMOTION_OPTIMIZATION_LEARNING: "/promo-opt/learning",
      SUPPLIERS: '/suppliers',
      SUPPLIERS_FILTER: '/suppliers/filter',
      SUPPLIERS_OTHER: '/suppliers/other',
      COMPETITOR_PRICES_IN_ZONE: '/competitor-prices-in-zone',
      SUPPLIER_AGREEMENT_UPDATE: function SUPPLIER_AGREEMENT_UPDATE(sessionId) {
        return "/supplier-agreement/update".concat(typeof sessionId === 'string' ? "?sessionId=".concat(sessionId) : '');
      },
      MISSING_COMPETITOR_PRICES: function MISSING_COMPETITOR_PRICES(productId) {
        return "/missing-competitor-prices/".concat(productId);
      },
      AGENCY_QUALITY_EVALUATION: '/agency-quality-evaluation',
      COMPETITOR_PRICE_HISTORY_INDEXES: '/calculate-prices-index',
      COMPETITOR_MODIFIED_PRICES: '/calculate-modified-prices',
      UPDATE_COMPETITOR_PRICE: function UPDATE_COMPETITOR_PRICE(competitorPriceId) {
        return "/cleaning/competitor-price/".concat(competitorPriceId);
      },
      CATEGORY_CLEANING_STATE: '/cleaning/categories-state',
      CLEANING_STATE: function CLEANING_STATE(categoryId) {
        return "/cleaning/cleaning-state/".concat(categoryId);
      },
      IGNORE_UNIGNORE: '/ignore-unignore',
      GLOBAL_SETTINGS: '/global-settings',
      CLEANING: '/cleaning',
      GENERAL_CONFIGURATIONS: '/application-configurations',
      WINDOW: '/windows',
      WINDOW_UPDATE: function WINDOW_UPDATE(windowId, sessionId) {
        return joinSessionId("/windows/".concat(windowId), sessionId);
      },
      WINDOW_UPDATE_WF_NOTE: function WINDOW_UPDATE_WF_NOTE(windowId, sessionId) {
        return joinSessionId("windows/".concat(windowId, "/workflow-note"), sessionId);
      },
      WINDOW_SIMILAR: function WINDOW_SIMILAR(windowId) {
        return "/windows/".concat(windowId, "/similar");
      },
      WINDOW_SIMULATED: function WINDOW_SIMULATED(windowId) {
        return "/windows/".concat(windowId, "/simulated");
      },
      WINDOW_EDITED: function WINDOW_EDITED(windowId) {
        return "windows/".concat(windowId, "/edited");
      },
      WINDOW_ACTION: '/windows/action',
      WINDOW_OVERVIEW: '/windows/overview',
      WINDOW_FILTER: function WINDOW_FILTER(forUser) {
        return "/windows/filter?forUser=".concat(forUser);
      },
      WINDOW_NEXT: 'windows/next',
      WINDOW_SINGLE: function WINDOW_SINGLE(windowId) {
        return "/windows/".concat(windowId);
      },
      WINDOW_MOVE: '/windows/move',
      WINDOW_MOVE_RESULT: function WINDOW_MOVE_RESULT(processId) {
        return "/windows/move/".concat(processId, "/result");
      },
      WINDOW_MOVE_CONFIRM: '/windows/confirm-move',
      WINDOW_MOVE_CANCEL: '/windows/cancel-move',
      WINDOW_COPY: '/windows/copy',
      WINDOW_COPY_RESULT: function WINDOW_COPY_RESULT(processId) {
        return "/windows/copy/".concat(processId, "/result");
      },
      WINDOW_COPY_CONFIRM: '/windows/confirm-copy',
      WINDOW_COPY_CANCEL: '/windows/cancel-copy',
      WINDOW_LOCK: function WINDOW_LOCK(id) {
        return "/windows/".concat(id, "/lock");
      },
      WINDOW_UNLOCK: function WINDOW_UNLOCK(id) {
        return "/windows/".concat(id, "/unlock");
      },
      WINDOW_WORKFLOW_STATE: function WINDOW_WORKFLOW_STATE(windowId) {
        return "windows/".concat(windowId, "/status");
      },
      WINDOW_WORKFLOW_STATES: 'windows/status',
      WINDOW_CHANGE_HISTORY: function WINDOW_CHANGE_HISTORY(windowId) {
        return "/windows/".concat(windowId, "/history");
      },
      WINDOW_CHANGE_HISTORY_SET: function WINDOW_CHANGE_HISTORY_SET(windowId, setId) {
        return "/windows/".concat(windowId, "/history/changeset/").concat(setId);
      },
      WINDOW_ITEM: '/window-items',
      WINDOW_ITEM_SET_SYS_VOL: function WINDOW_ITEM_SET_SYS_VOL(log, sessionId) {
        return "/window-items/system-volume".concat(typeof sessionId === 'string' ? "?sessionId=".concat(sessionId, "&log=").concat(log) : "?log=".concat(log));
      },
      WINDOW_ITEM_GOS_NUMBERS: function WINDOW_ITEM_GOS_NUMBERS(sessionId) {
        return "/window-items/number-of-sites".concat(typeof sessionId === 'string' ? "?sessionId=".concat(sessionId) : '');
      },
      WINDOW_ITEM_DELETE: function WINDOW_ITEM_DELETE(sessionId) {
        return "/window-items".concat(typeof sessionId === 'string' ? "?sessionId=".concat(sessionId) : '');
      },
      WINDOW_ITEM_CREATE: function WINDOW_ITEM_CREATE(sessionId) {
        return "/window-items".concat(typeof sessionId === 'string' ? "?sessionId=".concat(sessionId) : '');
      },
      WINDOW_ITEM_BY_WINDOW_ID: function WINDOW_ITEM_BY_WINDOW_ID(windowId, sessionId) {
        return "/window-items/window/".concat(windowId).concat(typeof sessionId === 'string' ? "?sessionId=".concat(sessionId) : '');
      },
      SIMULATED_WINDOW_ITEM_BY_WINDOW_ID: function SIMULATED_WINDOW_ITEM_BY_WINDOW_ID(windowId) {
        return "/window-items/window/".concat(windowId, "/simulated");
      },
      WINDOW_ITEM_UPDATE: function WINDOW_ITEM_UPDATE(sessionId) {
        return "/window-items/update".concat(typeof sessionId === 'string' ? "?sessionId=".concat(sessionId) : '');
      },
      WINDOW_ITEM_PROMO_PRICE_UNITS: '/window-items/promo-price-units',
      WINDOW_ITEM_PROMO_PRICE: '/window-items/promo-price',
      WINDOW_ITEM_HISTORY_TABLE: '/window-items/history-table',
      WINDOW_ITEM_HISTORY_TABLE_BY_ITEM: '/window-items/history-table/item',
      WINDOW_ITEM_FIND_SUPPLIERS: '/window-items/find-suppliers',
      IMPORT_WINDOWS: '/windows/import',
      IMPORT_WINDOWS_RESULT: function IMPORT_WINDOWS_RESULT(processId) {
        return "/windows/import/".concat(processId, "/result");
      },
      WINDOW_FILES: '/window-files',
      WINDOW_FILES_LIST: function WINDOW_FILES_LIST(windowId) {
        return "/window-files/window/".concat(windowId);
      },
      WINDOW_FILES_ITEM: function WINDOW_FILES_ITEM(fileId) {
        return "/window-files/".concat(fileId);
      },
      KPI_DESCRIPTION: '/kpis/descriptions',
      KPI_RECORDS: '/kpis/records',
      KPI_SETS: function KPI_SETS(setPeerType, setPeerId, setKey) {
        return "/kpis/sets/".concat(setPeerType, "/").concat(setPeerId, "/").concat(setKey);
      },
      KPI_RECALCULATION: function KPI_RECALCULATION(peerType, peerId) {
        return "/kpis/recalculate/".concat(peerType, "/").concat(peerId);
      },
      CRP: '/competitor-reference-prices',
      NewCRP: '/new-competitor-reference-price',
      CRPDTO: '/crp-and-flagSet-dto',
      UNITS: "/codebook/units",
      GRID_SETTINGS: '/grid-settings',
      START_CLEANING: '/price-classification/start',
      LAST_CLEANING: '/last-cleaning',
      PROGRESS: '/progress',
      STATUS: '/status',
      FIND: '/find',
      INTERNAL_PRODUCT_PRICES: '/prices',
      DUMMY_WINDOWS: '/dummy-window',
      SEGMENTATION_MODEL: '/segmentation-model',
      WORKFLOW_ADMINISTRATION: '/workflow-administration',
      WORKFLOW_CASES: '/cases',
      LOCKS: '/locks',
      UNLOCK: '/unlock',
      TERMINATE_CASES: '/cases/terminate',
      ABANDON_CASES: '/cases/abandon',
      DOWNLOAD: function DOWNLOAD(fileId) {
        return "/download/".concat(fileId);
      },
      NOTIFICATIONS: '/notifications',
      NOTIFICATIONS_LIMIT: function NOTIFICATIONS_LIMIT(limit) {
        return "/notifications/limit?limit=".concat(limit);
      },
      NOTIFICATIONS_MARK_AS_READ: '/notifications/change-state',
      LOCALES: '/locales',
      FORECAST_SIMULATION: 'forecast-simulation',
      FORECAST_SIMULATION_PROMOTIONS: '/forecast-simulation/promotions',
      FORECAST_SIMULATION_LOCK_OVERVIEW: "/forecast-simulation/lock-overview",
      FORECAST_SIMULATION_LOCK: function FORECAST_SIMULATION_LOCK(id) {
        return "/forecast-simulation/".concat(id, "/lock");
      },
      FORECAST_SIMULATION_UNLOCK: function FORECAST_SIMULATION_UNLOCK(id) {
        return "/forecast-simulation/".concat(id, "/unlock");
      },
      FORECAST_SIMULATION_GET: function FORECAST_SIMULATION_GET(promotionId) {
        return "/forecast-simulation/promotion/".concat(promotionId);
      },
      FORECAST_SIMULATION_FIND_OR_CREATE: function FORECAST_SIMULATION_FIND_OR_CREATE(id) {
        return "forecast-simulation/".concat(id, "/find-or-create");
      },
      FORECAST_SIMULATION_TREE: function FORECAST_SIMULATION_TREE(id) {
        return "/forecast-simulation/".concat(id, "/tree-data");
      },
      FORECAST_SIMULATION_UPDATE: function FORECAST_SIMULATION_UPDATE(id) {
        return "/forecast-simulation/".concat(id, "/update");
      },
      FORECAST_SIMULATION_APPEND_DUMMY_WINDOW: function FORECAST_SIMULATION_APPEND_DUMMY_WINDOW(id) {
        return "/forecast-simulation/".concat(id, "/dummy-window/append");
      },
      FORECAST_SIMULATION_DELETE_DUMMY_WINDOW: function FORECAST_SIMULATION_DELETE_DUMMY_WINDOW(id) {
        return "/forecast-simulation/".concat(id, "/dummy-window/delete");
      },
      FORECAST_SIMULATION_SAVE: function FORECAST_SIMULATION_SAVE(id) {
        return "/forecast-simulation/".concat(id, "/save");
      },
      FORECAST_COORDINATOR: '/forecast-coordinator',
      FORECAST_COORDINATOR_TREE: '/forecast-coordinator/tree-data',
      FORECAST_COORDINATOR_UPDATE: '/forecast-coordinator/update',
      FORECAST_COORDINATOR_APPROVE_WINDOWS: "/forecast-coordinator/approve",
      FORECAST_COORDINATOR_APPROVE_WINDOW_ITEMS: '/forecast-coordinator/approveWindowItems',
      FORECAST_CHANGE_REPORT_WINDOWS: '/reports/forecast-change/windows',
      FORECAST_CHANGE_REPORT_PRODUCTS: function FORECAST_CHANGE_REPORT_PRODUCTS(windowId) {
        return "/reports/forecast-change/windows/".concat(windowId);
      },
      PROMOTION_PERFORMANCE_CREATE_PROMOTION_SET: "/reports/promotion-performance/promotion-set",
      PROMOTION_PERFORMANCE_PROMOTION_SET_REPORT: function PROMOTION_PERFORMANCE_PROMOTION_SET_REPORT(setId) {
        return "/reports/promotion-performance/promotion-set/".concat(setId, "/report");
      },
      PROMOTION_PERFORMANCE_RENEW_REPORT: function PROMOTION_PERFORMANCE_RENEW_REPORT(setId) {
        return "/reports/promotion-performance/promotion-set/".concat(setId, "/report/renew");
      },
      WINDOW_PERFORMANCE_RENEW_REPORT: "/reports/window-performance/report/renew",
      PROMOTION_PERFORMANCE_PROMOTION_SET_REPORT_STATUS: function PROMOTION_PERFORMANCE_PROMOTION_SET_REPORT_STATUS(reportId) {
        return "/reports/promotion-performance/report/".concat(reportId, "/status");
      },
      PROMOTION_PERFORMANCE_PROMOTION_FILTER_PROMOTIONS: "/reports/promotion-performance/promotions",
      PROMOTION_PERFORMANCE_REPORT_GET_TOTAL_COUNT: function PROMOTION_PERFORMANCE_REPORT_GET_TOTAL_COUNT(reportId) {
        return "/reports/promotion-performance/report/".concat(reportId, "/total-rows-count");
      },
      PROMOTION_PERFORMANCE_REPORT_ROW_DATA: function PROMOTION_PERFORMANCE_REPORT_ROW_DATA(reportId) {
        return "/reports/promotion-performance/report/".concat(reportId, "/row-data");
      },
      PROMOTION_PERFORMANCE_REPORT_FILTERS: function PROMOTION_PERFORMANCE_REPORT_FILTERS(reportId) {
        return "/reports/promotion-performance/report/".concat(reportId, "/filters");
      },
      WINDOW_PERFORMANCE_REPORT: '/reports/window-performance-report/report',
      WINDOW_PERFORMANCE_REPORT_STATUS: function WINDOW_PERFORMANCE_REPORT_STATUS(reportId) {
        return "/reports/window-performance-report/report/".concat(reportId, "/status");
      },
      WINDOW_PERFORMANCE_REPORT_FILTERS: function WINDOW_PERFORMANCE_REPORT_FILTERS(reportId) {
        return "/reports/window-performance-report/report/".concat(reportId, "/filters");
      },
      WINDOW_PERFORMANCE_REPORT_NODE: function WINDOW_PERFORMANCE_REPORT_NODE(reportId) {
        return "/reports/window-performance-report/report/".concat(reportId, "/node");
      },
      NEW_WINDOW_PERFORMANCE_REPORT_GET_TOTAL_COUNT: function NEW_WINDOW_PERFORMANCE_REPORT_GET_TOTAL_COUNT(reportId) {
        return "/reports/window-performance-report/report/new/".concat(reportId, "/total-rows-count");
      },
      NEW_WINDOW_PERFORMANCE_REPORT_COUNT_ROW_DATA: function NEW_WINDOW_PERFORMANCE_REPORT_COUNT_ROW_DATA(reportId) {
        return "/reports/window-performance-report/report/new/".concat(reportId, "/row-data");
      },
      WINDOW_PERFORMANCE_PROMOTION_FILTER_PROMOTIONS: "/reports/window-performance-report/promotions",
      REPLE_PROMO_MANAGEMENT_FILTER_PROMOTIONS: "/reports/replenishment-promo-management/promotions",
      REPLE_PROMO_MANAGEMENT_REPORT_GENERATE: "/reports/replenishment-promo-management/report",
      REPLE_PROMO_MANAGEMENT_REPORT: function REPLE_PROMO_MANAGEMENT_REPORT(reportId) {
        return "/reports/replenishment-promo-management/report/".concat(reportId);
      },
      REPLE_PROMO_MANAGEMENT_REPORT_NODES: function REPLE_PROMO_MANAGEMENT_REPORT_NODES(reportId) {
        return "/reports/replenishment-promo-management/report/".concat(reportId, "/rows");
      },
      REPLE_PROMO_MANAGEMENT_REPORT_NODES_UPDATE: function REPLE_PROMO_MANAGEMENT_REPORT_NODES_UPDATE(reportId) {
        return "/reports/replenishment-promo-management/report/".concat(reportId, "/rows/update");
      },
      SALES_DASHBOARD_REPORT: "/reports/sales-dashboard/report",
      SALES_DASHBOARD_REPORT_STATUS: function SALES_DASHBOARD_REPORT_STATUS(reportId) {
        return "/reports/sales-dashboard/report/".concat(reportId, "/status");
      },
      SALES_DASHBOARD_REPORT_NODE: function SALES_DASHBOARD_REPORT_NODE(reportId) {
        return "/reports/sales-dashboard/report/".concat(reportId, "/node");
      },
      SALES_DASHBOARD_EXPORTED: "/reports/sales-dashboard/exported",
      SALES_DASHBOARD_EXPORTED_DOWNLOAD: function SALES_DASHBOARD_EXPORTED_DOWNLOAD(fileId) {
        return "/reports/sales-dashboard/exported/".concat(fileId);
      },
      SALES_DASHBOARD_SERVICE_GENERATE_FUTURE_ITEMS: '/reports/generateFutureItemsTemp',
      SALES_DASHBOARD_SERVICE_GENERATE_HISTORY_ITEMS: '/reports/generateSdrHistoryItems',
      SALES_DASHBOARD_SERVICE_RECALCULATE_FACTS: '/reports/recalculateSalesDashboardFacts',
      SALES_DASHBOARD_LOCK: '/reports/salesDashboardLock',
      SALES_DASHBOARD_UNLOCK: '/reports/salesDashboardUnlock',
      SALES_DASHBOARD_LOCK_STATUS: '/reports/isSalesDashboardLocked',
      SALES_DASHBOARD_COMP_REPORT_FIND: '/reports/sales-dashboard-comp/report',
      SALES_DASHBOARD_COMP_REPORT_GENERATE: '/reports/sales-dashboard-comp/report/generate',
      SALES_DASHBOARD_COMP_REPORT_STATUS: function SALES_DASHBOARD_COMP_REPORT_STATUS(reportId) {
        return "/reports/sales-dashboard-comp/report/".concat(reportId, "/status");
      },
      SALES_DASHBOARD_COMP_REPORT_ROWS: function SALES_DASHBOARD_COMP_REPORT_ROWS(reportId) {
        return "/reports/sales-dashboard-comp/report/".concat(reportId);
      },
      NEW_SALES_DASHBOARD_REPORT: "/reports/new-sales-dashboard/report",
      NEW_SALES_DASHBOARD_REPORT_STATUS: function NEW_SALES_DASHBOARD_REPORT_STATUS(reportId) {
        return "/reports/new-sales-dashboard/report/".concat(reportId, "/status");
      },
      NEW_SALES_DASHBOARD_REPORT_GET_TOTAL_COUNT: function NEW_SALES_DASHBOARD_REPORT_GET_TOTAL_COUNT(reportId) {
        return "/reports/new-sales-dashboard/report/".concat(reportId, "/total-rows-count");
      },
      NEW_SALES_DASHBOARD_REPORT_ROW_DATA: function NEW_SALES_DASHBOARD_REPORT_ROW_DATA(reportId) {
        return "/reports/new-sales-dashboard/report/".concat(reportId, "/row-data");
      },
      NEW_SALES_DASHBOARD_REPORT_RECALCULATION_FINISHED: '/reports/isSalesDashboardReportRecalculated',
      APPROVAL_CD_REPORT_CREATE_PROMOTION_SET: "/reports/approval-cd/promotion-set",
      APPROVAL_CD_PROMOTION_SET_REPORT: function APPROVAL_CD_PROMOTION_SET_REPORT(setId) {
        return "/reports/approval-cd/promotion-set/".concat(setId, "/report");
      },
      APPROVAL_CD_RENEW: function APPROVAL_CD_RENEW(setId) {
        return "/reports/approval-cd/promotion-set/".concat(setId, "/report/renew");
      },
      APPROVAL_CD_PROMOTION_SET_REPORT_STATUS: function APPROVAL_CD_PROMOTION_SET_REPORT_STATUS(setId, reportId) {
        return "/reports/approval-cd/promotion-set/".concat(setId, "/report/").concat(reportId, "/status");
      },
      APPROVAL_CD_FILTER_PROMOTIONS: "/reports/approval-cd/promotions",
      APPROVAL_CD_REPORT_GET_TOTAL_COUNT: function APPROVAL_CD_REPORT_GET_TOTAL_COUNT(reportId) {
        return "/reports/approval-cd/report/".concat(reportId, "/total-rows-count");
      },
      APPROVAL_CD_REPORT_ROW_DATA: function APPROVAL_CD_REPORT_ROW_DATA(reportId) {
        return "/reports/approval-cd/report/".concat(reportId, "/row-data");
      },
      APPROVAL_CD_REPORT_FILTERS: function APPROVAL_CD_REPORT_FILTERS(reportId) {
        return "/reports/approval-cd/report/".concat(reportId, "/filters");
      },
      APPROVAL_PM_REPORT_CREATE_PROMOTION_SET: "/reports/approval-pm/promotion-set",
      APPROVAL_PM_PROMOTION_SET_REPORT: function APPROVAL_PM_PROMOTION_SET_REPORT(setId) {
        return "/reports/approval-pm/promotion-set/".concat(setId, "/report");
      },
      APPROVAL_PM_PROMOTION_SET_REPORT_STATUS: function APPROVAL_PM_PROMOTION_SET_REPORT_STATUS(setId, reportId) {
        return "/reports/approval-pm/promotion-set/".concat(setId, "/report/").concat(reportId, "/status");
      },
      APPROVAL_PM_REPORT_GET_TOTAL_COUNT: function APPROVAL_PM_REPORT_GET_TOTAL_COUNT(reportId) {
        return "/reports/approval-pm/report/".concat(reportId, "/total-rows-count");
      },
      APPROVAL_PM_REPORT_ROW_DATA: function APPROVAL_PM_REPORT_ROW_DATA(reportId) {
        return "/reports/approval-pm/report/".concat(reportId, "/row-data");
      },
      APPROVAL_PM_PROMOTION_PRODUCTS: function APPROVAL_PM_PROMOTION_PRODUCTS(windowId) {
        return "/reports/approval-pm/windows/".concat(windowId);
      },
      APPROVAL_PM_FILTER_PROMOTIONS: "/reports/approval-pm/promotions",
      APPROVAL_PM_REPORT_FILTERS: function APPROVAL_PM_REPORT_FILTERS(reportId) {
        return "/reports/approval-pm/report/".concat(reportId, "/filters");
      },
      METADATA_REPORT_SELLOUT: function METADATA_REPORT_SELLOUT(l, o) {
        return "/reports/meta-data-report/sellout?limit=".concat(l, "&offset=").concat(o);
      },
      METADATA_REPORT_SIMILAR: function METADATA_REPORT_SIMILAR(l, o) {
        return "/reports/meta-data-report/similar?limit=".concat(l, "&offset=").concat(o);
      },
      METADATA_REPORT_NEWS: function METADATA_REPORT_NEWS(l, o) {
        return "/reports/meta-data-report/news?limit=".concat(l, "&offset=").concat(o);
      },
      METADATA_REPORT_HIERARCHY: "/reports/meta-data-report/hierarchy",
      EXPORT_PRICE_ARCHITECTURE: 'products/export/price-architecture',
      EXPORT_DATA_CLEANING: 'cleaning/export',
      EXPORT_DATA_PAIRING: 'data-pairing/export',
      EXPORT_SELLOUT_IDENTIFICATION_REPORT_DETAIL: '/reports/sellout-identification-report-detail-export',
      EXPORT_RELEASE_DETAIL: function EXPORT_RELEASE_DETAIL(releaseId) {
        return "releases/".concat(releaseId, "/export");
      },
      EXPORT_RELEASE_SELLOUT_DETAIL: function EXPORT_RELEASE_SELLOUT_DETAIL(releaseId) {
        return "releases/sellout/".concat(releaseId, "/export");
      },
      SDR_VS_PPR_GET_ALL: function SDR_VS_PPR_GET_ALL() {
        return "/reports/getSdrComparatorItems";
      },
      APPLICATION_STATUS: '/application/status',
      PRODUCTS_VALIDATE_BY_EXTERNAL_IDS: '/products/validate-by-external-ids',
      RELEASE_CATEGORIES: function RELEASE_CATEGORIES(releaseId) {
        return "/releases/".concat(releaseId, "/categories");
      },
      SELLOUT_RELEASE_CATEGORIES: function SELLOUT_RELEASE_CATEGORIES(releaseId) {
        return "/releases/sellout/".concat(releaseId, "/categories");
      },
      SITE_RELEASE_CATEGORIES: function SITE_RELEASE_CATEGORIES(releaseId) {
        return "/site-releases/".concat(releaseId, "/categories");
      },
      RELEASES_OVERVIEW: '/overview',
      RELEASE_SELLOUT_FILTER_UPDATE: function RELEASE_SELLOUT_FILTER_UPDATE(releaseId) {
        return "/sellout/".concat(releaseId, "/item-filter");
      },
      RELEASE_SELLOUT_CATEGORY_SUBTOTAL_PREVIOUS: function RELEASE_SELLOUT_CATEGORY_SUBTOTAL_PREVIOUS(id, categoryId) {
        return "/sellout/".concat(id, "/category-impact-previous/").concat(categoryId);
      },
      RELEASE_SELLOUT_CATEGORY_SUBTOTAL_COMBINED: function RELEASE_SELLOUT_CATEGORY_SUBTOTAL_COMBINED(id, categoryId) {
        return "/sellout/".concat(id, "/category-impact-combined/").concat(categoryId);
      },
      RELEASE_SELLOUT_IMPACT_STOCK_VALUES: '/release-impact/stock-values',
      RELEASE_SELLOUT_PREVIOUS_IMPACTS: '/release-impact/previous-impacts',
      SITE_RELEASE_UPLOAD_FILE: '/site-release-uploads/file',
      SITE_RELEASE_UPLOAD_ID: function SITE_RELEASE_UPLOAD_ID(id) {
        return "site-release-uploads/".concat(id);
      },
      SITE_RELEASE_ID: function SITE_RELEASE_ID(id) {
        return "site-releases/".concat(id);
      },
      SITE_RELEASE_ID_ITEM: function SITE_RELEASE_ID_ITEM(id) {
        return "site-releases/".concat(id, "/items");
      },
      SITE_RELEASE_ID_EXPORT_CONFIRMATION: function SITE_RELEASE_ID_EXPORT_CONFIRMATION(id) {
        return "site-releases/".concat(id, "/export-confirmation");
      },
      SITE_RELEASE_ID_CANCELLATION: function SITE_RELEASE_ID_CANCELLATION(id) {
        return "site-releases/".concat(id, "/cancellation");
      },
      SITE_RELEASE_ITEMS_EXPORTED: '/site-release-items/exported',
      SITE_RELEASE_ITEMS_PRICE_VALUE: '/site-release-items/price-value',
      SITE_RELEASE_ITEMS_PRICE_VALID_FROM: '/site-release-items/price-valid-from',
      RELEASE_SELLOUT_PRICE_SIMULATION: function RELEASE_SELLOUT_PRICE_SIMULATION(id) {
        return "/sellout/".concat(id, "/price-simulation");
      },
      RELEASE_SELLOUT_PRICE_SIMULATION_CANCELLATION: function RELEASE_SELLOUT_PRICE_SIMULATION_CANCELLATION(id) {
        return "/sellout/".concat(id, "/price-simulation-cancellation");
      },
      SELLOUT_RELEASE_CATEGORY_PREVIEWS: function SELLOUT_RELEASE_CATEGORY_PREVIEWS(rId, itemFilterHash) {
        return "/sellout-release-category-previews?rId=".concat(rId, "&itemFilterHash=").concat(itemFilterHash);
      },
      SELLOUT_RELEASE_CATEGORY_PREVIEWS_CALCULATION: function SELLOUT_RELEASE_CATEGORY_PREVIEWS_CALCULATION(rId) {
        return "/sellout-release-category-previews/calculation?rId=".concat(rId);
      },
      SELLOUT_RELEASE_CATEGORY_PREVIEWS_INCLUSION: function SELLOUT_RELEASE_CATEGORY_PREVIEWS_INCLUSION(rId) {
        return "/sellout-release-category-previews/categories-inclusion?rId=".concat(rId);
      },
      SELLOUT_RELEASE_CATEGORY_PREVIEWS_EXCLUSION: function SELLOUT_RELEASE_CATEGORY_PREVIEWS_EXCLUSION(rId) {
        return "/sellout-release-category-previews/categories-exclusion?rId=".concat(rId);
      },
      SELLOUT_TERMINATION_REPORT: '/sellout-termination-report',
      SELLOUT_TERMINATION_REPORT_EXPORT: '/sellout-termination-report/export',
      SELLOUT_IMPACT_REPORT: '/sellout-impact-report',
      SELLOUT_IMPACT_REPORT_GENERATE: '/sellout-impact-report/detail/generate',
      SELLOUT_IMPACT_REPORT_DETAIL_4BOX: function SELLOUT_IMPACT_REPORT_DETAIL_4BOX(reportId) {
        return "/sellout-impact-report/detail/".concat(reportId, "/4box");
      },
      SELLOUT_IMPACT_REPORT_DETAIL_RELEASE: function SELLOUT_IMPACT_REPORT_DETAIL_RELEASE(reportId) {
        return "/sellout-impact-report/detail/".concat(reportId, "/release");
      },
      SELLOUT_TERMINATIONS_CANCELL: function SELLOUT_TERMINATIONS_CANCELL(id) {
        return "/sellout-terminations/".concat(id, "/cancellation");
      },
      REGULAR_SITE_TERMINATIONS: '/site-release-terminations',
      REGULAR_SITE_TERMINATION_UPLOADS_ID: function REGULAR_SITE_TERMINATION_UPLOADS_ID(id) {
        return "/site-release-termination-uploads/".concat(id);
      },
      REGULAR_SITE_TERMINATION_UPLOADS_FILE: '/site-release-termination-uploads/file',
      REGULAR_SITE_TERMINATIONS_CONFIRMATION: function REGULAR_SITE_TERMINATIONS_CONFIRMATION(id) {
        return "/site-release-terminations/".concat(id, "/termination-confirmation");
      },
      REGULAR_SITE_TERMINATIONS_CANCELL: function REGULAR_SITE_TERMINATIONS_CANCELL(id) {
        return "/site-release-terminations/".concat(id, "/cancellation");
      },
      REGULAR_SITE_TERMINATIONS_ID: function REGULAR_SITE_TERMINATIONS_ID(id) {
        return "/site-release-terminations/".concat(id);
      },
      REGULAR_SITE_TERMINATIONS_ITEMS: function REGULAR_SITE_TERMINATIONS_ITEMS(id) {
        return "/site-release-terminations/".concat(id, "/items");
      },
      REGULAR_SITE_TERMINATION_ITEMS_TERMINATE: '/site-release-termination-items/termination',
      REGULAR_SITE_TERMINATION_ITEMS_TERMINATE_DATE: '/site-release-termination-items/termination-date',
      PROMOTIONS_AREAS_HAS_ASSIGNED: '/promotions/areas/has-assigned',
      DATA_UPLOAD_FAMILY: '/data-upload/family',
      RELEASE_PART_MANUAL_PRICES_UPDATE: function RELEASE_PART_MANUAL_PRICES_UPDATE(releaseId, categoryId) {
        return "/".concat(releaseId, "/release-part/").concat(categoryId, "/manual-prices");
      },
      RELEASE_RECALCULATE_RELEASE_PARTS: function RELEASE_RECALCULATE_RELEASE_PARTS(releaseId) {
        return "/".concat(releaseId, "/recalculate");
      },
      SUPPLIERS_PREFERRED: '/suppliers/preferred',
      WINDOW_ITEM_HISTORIC_DATA: '/window-items/historic-data',
      STATUS_AGGREGATION: '/status/aggregation',
      FORECAST_COORDINATOR_APPROVE_WINDOW: function FORECAST_COORDINATOR_APPROVE_WINDOW(windowId) {
        return "/forecast-coordinator/".concat(windowId, "/approve");
      },
      PROMOTION_PERFORMANCE_PROMOTION_SET_REPORT_NODE: function PROMOTION_PERFORMANCE_PROMOTION_SET_REPORT_NODE(setId, reportId) {
        return "/reports/promotion-performance-report/promotion-set/".concat(setId, "/report/").concat(reportId, "/node");
      },
      APPROVAL_CD_PROMOTION_SET_REPORT_NODE: function APPROVAL_CD_PROMOTION_SET_REPORT_NODE(setId, reportId) {
        return "/reports/approval-cd-report/promotion-set/".concat(setId, "/report/").concat(reportId, "/node");
      },
      APPROVAL_PM_PROMOTION_SET_REPORT_NODE: function APPROVAL_PM_PROMOTION_SET_REPORT_NODE(setId, reportId) {
        return "/reports/approval-pm-report/promotion-set/".concat(setId, "/report/").concat(reportId, "/node");
      },
      EXPORT_SITE_RELEASE_DETAIL: function EXPORT_SITE_RELEASE_DETAIL(releaseId) {
        return "/site-releases/".concat(releaseId, "/export-to-xlsx");
      },
      WORKFLOW_CASES_LOCK_REFRESH: '/workflow-cases/lock-refresh',
      WORKFLOW_CASES_ASSIGNEES: '/workflow-cases/assignees'
    },
    OPTIONS: {
      TIMEOUT: 60000
    }
  },
  TAGS: {
    START_AI_CLEANING: 'CompetitorPriceClassificationMonitor'
  },
  LOGGER: {
    QUERY_NAME: 'debug'
  },
  FORMAT: {
    DATE: 'DD.MM.YYYY',
    DATE_TIME: 'DD.MM.YYYY HH:mm',
    NUMBER: 'fr',
    NULL: '---'
  },
  PRICE_ARCHITECTURE: {
    ASSIGN_TO_FAMILY_DEFAULT_SIZE_CHARGER_VALUE: bigdecimal(0),
    ASSIGN_TO_FAMILY_DEFAULT_SIZE_RATIO_VALUE: bigdecimal(1)
  },
  DATA_CLEANING: {
    PRICE_CHANGES_SCALE_COUNT: 7,
    PRICE_CHANGES_SCALE: new Map([['scale1', function (percent) {
      return percent.gte(bigdecimal(40));
    }], ['scale2', function (percent) {
      return percent.lt(bigdecimal(40)) && percent.gte(bigdecimal(20));
    }], ['scale3', function (percent) {
      return percent.lt(bigdecimal(20)) && percent.gt(bigdecimal(0));
    }], ['transparent', function (percent) {
      return percent.isZero();
    }], ['scale5', function (percent) {
      return percent.lt(bigdecimal(0)) && percent.gt(bigdecimal(-20));
    }], ['scale6', function (percent) {
      return percent.lte(bigdecimal(-20)) && percent.gt(bigdecimal(-40));
    }], ['scale7', function (percent) {
      return percent.lte(bigdecimal(-40));
    }]]),
    PRICE_CHANGES_BOUNDARIES: ['< -40%', '> 40%']
  },
  SELLOUT_SETTINGS: {
    ALLOWED_CATEGORY_LEVELS: [2, 4],
    DEFAULT_DISCOUNT1: 25,
    DEFAULT_DISCOUNT2: 25
  },
  AUTOCOMPLETE_DEBOUNCE_IN_MS: 500,
  POLLING_INTERVAL_IN_MS: 5000,
  NOTIFICATIONS_POLLING_INTERVAL_IN_MS: 30000,
  WORKFLOW_ASSIGNEE_UPDATE_INTERVAL_IN_MS: 10000,
  SELLOUT_IDENTIFICATION_REPORT: {
    PAGE_SIZE: 1000
  },
  SELLOUT_TERMINATION_REPORT: {
    PAGE_SIZE: 1000
  },
  REPORT: {
    PRICE_IMPACT_REPORT: {
      ENABLED: isPriceImpactReportEnabled()
    }
  },
  SDR_FILTER: {
    GOS_EXTERNAL_ID: getGosExternalId()
  }
};
/**
 * Allows you to mutate original constants object. This should be called as soon as possible
 * @param customConfig
 */

export var updateConstants = function updateConstants(customConfig) {
  if (customConfig) {
    CONSTANTS = merge(CONSTANTS, customConfig);
  }
};
export { CONSTANTS }; // CONFIGURATION HOOK METHODS BELLOW (IT WOULD BE BETTER TO MOVE THIS TO CUSTOM CFG COMPONENTS)

/**
 * <p>Tries to parse custom config to return information whether price impact report is enabled. By default returns false.</p>
 * @returns true if 'price impact report' should be enabled, false otherwise
 */

function isPriceImpactReportEnabled() {
  if (window._CONFIG.REPORT && window._CONFIG.REPORT.PRICE_IMPACT_REPORT) {
    return ParamParsers.parseBoolean(window._CONFIG.REPORT.PRICE_IMPACT_REPORT, 'ENABLED', false);
  }

  return false;
}
/**
 * Parse GOS externalId parameter from config or return default ID
 */


function getGosExternalId() {
  if (window._CONFIG.SDR_FILTER && window._CONFIG.SDR_FILTER.GOS_EXTERNAL_ID) {
    return ParamParsers.parseString(window._CONFIG.SDR_FILTER.GOS_EXTERNAL_ID, 'GOS_EXTERNAL_ID');
  }

  return '91000';
}
/**
 * Joins base url with sessionId if session exists
 *
 * @param url
 * @param sessionId
 */


function joinSessionId(url, sessionId) {
  return "".concat(url).concat(sessionId ? "?sessionId=".concat(sessionId) : '');
}