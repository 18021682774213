"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var StructPropBuilder_1 = require("../../core/model/data-dictionary/StructPropBuilder");
/**
 * Information about impact of one quantity, such as sales volume, BM2, etc.
 *
 * @author Michal Sevcenko
 */


var Impact =
/**
 * ctor
 *
 * @param expected - expected value, as per YDG estimation
 * @param real - real value, as per transaction log
 * @param impact - impact value in percent, i.e. (real - expected) / expected * 100; if expected is zero, impact is zero
 * @overflow
 */
function Impact(expected, real, impact) {
  _classCallCheck(this, Impact);

  this.expected = expected;
  this.real = real;
  this.impact = impact;
};

exports.Impact = Impact;

Impact.ctor = function (mat) {
  return new Impact(mat.expected, mat.real, mat.impact);
};

Impact.schema = function () {
  var builder = new StructPropBuilder_1.StructPropBuilder(Impact.name);
  return {
    expected: builder.bigNum('expected'),
    real: builder.bigNum('real'),
    impact: builder.bigNum('impact')
  };
}();