"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Message keys related to AbstractReleasePart.
 */

var ReleasePartMessageCode;

(function (ReleasePartMessageCode) {
  /**
   * Message key related to situation when at least some items of release part was recalculated from historical YDG output.
   * <br/>
   * Suggested severity: warning.
   */
  ReleasePartMessageCode["ReleasePartPricesRecalculatedFromHistory"] = "release_management__part_recalculated_from_history";
  /**
   * Message key related to situation when some items of release part could not be recalculated from historical YDG output.
   * <br/>
   * Suggested severity: warning.
   */

  ReleasePartMessageCode["ReleasePartPricesSomeItemsNotRecalculatedFromHistory"] = "release_management__some_part_items_not_recalculated_from_history";
  /**
   * Calculation of optimal prices for a release part failed.
   */

  ReleasePartMessageCode["ReleasePartOptimalPriceCalculationFailed"] = "ReleasePartOptimalPriceCalculationFailed";
  /**
   * Sellout Deadstock Release settings has changed.
   */

  ReleasePartMessageCode["SelloutReleaseDeadStockSettingsChanged"] = "SelloutReleaseDeadStockSettingsChanged";
  /**
   * Release settings has changed.
   */

  ReleasePartMessageCode["ReleaseSettingsChanged"] = "ReleaseSettingsChanged";
  /**
   * <p>Message code related to situation when user enters manual price for an item. According to type of a release this may lead
   * to recalculation of affected category.</p>
   *
   * <p><b>This flag should only be bound to change the value of the manual price, not its other attributes.</b></p>
   */

  ReleasePartMessageCode["ManualPriceChanged"] = "ManualPriceChanged";
  /**
   * Release part is being recalculated.
   */

  ReleasePartMessageCode["ReleasePartRecalculationInProgress"] = "ReleasePartRecalculationInProgress";
  /**
   * Price validity start date of release and items was changed.
   */

  ReleasePartMessageCode["PriceValidityStartDateChanged"] = "PriceValidityStartDateChanged";
  /**
   * Some products were assigned to other tier level.
   */

  ReleasePartMessageCode["TierChanged"] = "TierChanged";
  /**
   * The new rounding rules were set. The price recalculation is required.
   */

  ReleasePartMessageCode["RoundingRulesChanged"] = "RoundingRulesChanged";
  /**
   * New family group <name of the family> was created.
   */

  ReleasePartMessageCode["FamilyCreated"] = "FamilyCreated";
})(ReleasePartMessageCode = exports.ReleasePartMessageCode || (exports.ReleasePartMessageCode = {}));