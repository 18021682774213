import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n\n  .DayPickerInput {\n    width: 100%;\n  }\n\n  input {\n    font-size: ", ";\n    line-height: ", ";\n    color: ", ";\n    background-color: ", ";\n    background-clip: padding-box;\n    border: 1px solid ", ";\n    border-radius: ", ";\n    min-height: ", "px;\n    padding: ", ";\n\n    &:focus {\n      outline: 0;\n    }\n\n    &:disabled {\n      background-color: ", ";\n      // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.\n      opacity: 1;\n    }\n  }\n  \n  &.input-error input {\n    border: 1px solid ", ";\n  }\n\n  &.input-error svg {\n    fill: ", ";\n  }\n\n  .DayPicker {\n    font-size: 90%;\n  }\n\n  .DayPicker-Caption {\n    text-align: center;\n    & > div {\n      font-size: 110%;\n      font-weight: normal;\n      text-transform: uppercase;\n    }\n  }\n\n  .DayPicker-NavButton--prev {\n    right: auto;\n    left: 1.5rem;\n  }\n\n  .DayPicker-Day {\n    border-radius: 0;\n    padding: 5px 0;\n  }\n  .DayPicker-Day--today {\n    color: ", ";\n  }\n  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),\n  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {\n    background-color: ", ";\n    color: ", ";\n  }\n\n  .DayPicker:not(.DayPicker--interactionDisabled)\n    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {\n    background-color: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Thu Aug 09 2018
 * @author VBr
 */
import styled, { withTheme } from 'styled-components';
/**
 * Create an DatePickerInput component that'll render an <input> tag with some styles
 */

var StyledDatePickerInput = styled.div(_templateObject(), function (props) {
  return props.tiny ? '.7rem' : '1rem';
}, function (props) {
  return props.tiny ? '.7rem' : '1.5rem';
}, function (props) {
  return props.theme.input.color;
}, function (props) {
  return props.theme.input.bg;
}, function (props) {
  return props.theme.input.borderColor;
}, function (props) {
  return props.theme.input.borderRadius;
}, function (props) {
  return props.tiny ? props.theme.input.tinyHeight : props.theme.input.height;
}, function (props) {
  return props.tiny ? props.theme.input.tinyPadding : props.theme.input.padding;
}, function (props) {
  return props.theme.input.disabledBg;
}, function (props) {
  return props.theme.state.danger.color;
}, function (props) {
  return props.theme.state.danger.color;
}, function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.colors.selected;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return props.theme.colors.hover;
});
export default withTheme(StyledDatePickerInput);