import React from 'react';
import Field from '../../forms/Field';
import { DatePickerInputAdapter } from '../../forms/Field/Adapters';
import { FormSpy } from 'react-final-form';
import * as moment from 'moment';
import { DateYoungerThanValidator, DateOlderThanValidator } from '@logio/common-be-fe';
import { ValidationUtils } from '../../../shared/validation/ValidationUtils';
import { CONSTANTS } from '../../../constants';
/**
 * Creates two DatePicker fields for entering date range. Date to mustn't be lower than date from and vice versa.
 * For 'date from' the start of the day is returned, for 'date to' end of the day.
 *
 * @author Jan Prokop
 */

export default function DateRange(props) {
  return React.createElement(FormSpy, {
    subscription: {
      values: true
    }
  }, function (form) {
    var values = form.values;
    return React.createElement(React.Fragment, null, React.createElement("div", {
      className: props.fieldWrapperClass
    }, React.createElement(Field, {
      name: props.dateFromKey,
      component: DatePickerInputAdapter(props.dateFromKey, false, {
        disabledDays: {
          after: values[props.dateToKey] && moment.isMoment(values[props.dateToKey]) ? values[props.dateToKey].toDate() : undefined,
          before: props.minDateFrom ? props.minDateFrom.toDate() : undefined
        }
      }, props.required),
      validate: function validate(value, formValues) {
        return ValidationUtils.validateField(value, formValues, [new DateYoungerThanValidator(formValues[props.dateToKey], true, true, CONSTANTS.FORMAT.DATE, 'DATE_FROM_IS_MORE_THAN', !props.required)]);
      }
    })), React.createElement("div", {
      className: props.fieldWrapperClass
    }, React.createElement(Field, {
      name: props.dateToKey,
      component: function component(componentProps) {
        return DatePickerInputAdapter(props.dateToKey, false, {
          disabledDays: {
            before: values[props.dateFromKey] && moment.isMoment(values[props.dateFromKey]) ? values[props.dateFromKey].toDate() : undefined,
            after: props.maxDateTo ? props.maxDateTo.toDate() : undefined
          }
        }, props.required)(Object.assign(Object.assign({}, componentProps), {
          input: Object.assign(Object.assign({}, componentProps.input), {
            // Override onChange - we want end of the day for 'date to' field. For 'date from' we don't have to do anything
            // start of the day is default behavior of DatePickerInput component
            onChange: function onChange(value) {
              return componentProps.input.onChange(value && moment.isMoment(value) ? value.endOf('day') : value);
            }
          })
        }));
      },
      validate: function validate(value, formValues) {
        return ValidationUtils.validateField(value, formValues, [new DateOlderThanValidator(formValues[props.dateFromKey], true, true, CONSTANTS.FORMAT.DATE, 'DATE_TO_IS_LESS_THAN', !props.required)]);
      }
    })));
  });
}
DateRange.defaultProps = {
  dateFromKey: 'dateFrom',
  dateToKey: 'dateTo'
};