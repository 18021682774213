import _classCallCheck from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/classCallCheck";

/**
 * @file Created on Thu Jan 03 2019
 * @author Tomáš Leity
 */
export var FileDownload = function FileDownload(blob, headers) {
  var _this = this;

  _classCallCheck(this, FileDownload);

  this.blob = blob;
  this.headers = headers;
  /**
   * Get filename for download
   * @returns string filename
   */

  this.filename = function () {
    var filename = 'download';
    var composition = _this.headers['content-disposition'];

    if (composition.indexOf('attachment') !== -1) {
      var regex = /filename=["'](.*)["']/;
      var parsed = regex.exec(composition)[1];

      if (parsed) {
        filename = decodeURIComponent(parsed);
      }
    }

    return filename;
  };
  /**
   * File download process
   */


  this.process = function () {
    return new Promise(function (resolve, reject) {
      try {
        var filename = _this.filename();
        /**
         * IE doesn't allow using a blob object directly as link href
         * instead it is necessary to use msSaveOrOpenBlob
         */


        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(_this.blob, filename);
          return resolve(filename);
        }
        /**
         * For other browsers:
         * Create a link pointing to the ObjectURL containing the blob.
         */


        var data = window.URL.createObjectURL(_this.blob);
        var link = document.createElement('a');
        link.href = data;
        link.download = filename;

        if (document.body) {
          document.body.appendChild(link);
        }

        link.click();
        /**
         * For Firefox it is necessary to delay revoking the ObjectURL
         */

        setTimeout(function () {
          if (document.body) {
            document.body.removeChild(link);
          }

          window.URL.revokeObjectURL(data);
        }, 100);
        return resolve(filename);
      } catch (error) {
        return reject(error);
      }
    });
  };
};