/**
 * @file Created on Tue Mar 14 2020
 * @author MHl
 */

import {
  PageStore,
  ActionsGenerator,
  ColumnDefinition,
  ColumnGenerator,
  StringMapping,
  LoadingState,
  SelloutTerminationLayer,
  RendererNames,
  SelloutTerminationItemPriceValid,
  SelloutTerminationItemTerminate,
  IconType,
  ButtonActionProps,
  translate,
} from '@logio/common-fe';
import {observable, action, computed, runInAction} from 'mobx';
import {
  PriceTerminationState,
  SelloutTermination,
  SelloutTerminationItem,
  SelloutTerminationSummaryItem,
  Utils,
  SelloutTerminationSummary,
  SelloutTerminationMsgKeyEnum,
} from '@logio/common-be-fe';
import {match} from 'react-router';
import {History} from 'history';
import {List} from 'immutable';
import {PollingHelper} from '../../components/PollingHelper';
import {ColumnApi, GridApi, GridReadyEvent, ValueGetterParams} from 'ag-grid-community';

export interface IRouteParams {
  id: string;
}

export class EndSelloutsDetailPageStore extends PageStore {
  terminationPollingHelper: PollingHelper;
  exportAgainPollingHelper: PollingHelper;
  constructor(
    public readonly history: History,
    public readonly match: match<IRouteParams>,
    private readonly terminationId: string,
  ) {
    super();
    this.terminationPollingHelper = new PollingHelper(this.messages, this.onTerminationPoolingChange, undefined, true);
    this.exportAgainPollingHelper = new PollingHelper(
      this.messages,
      this.onExportAgainPollingStateChanged,
      undefined,
      true,
    );
  }

  private selloutTerminationLayer = new SelloutTerminationLayer();
  private actionsGenerator = new ActionsGenerator();
  private terminationItemsColumnGenerator = new ColumnGenerator(SelloutTerminationItem.schema);
  private terminationSummaryColumnGenerator = new ColumnGenerator(SelloutTerminationSummaryItem.schema);

  @observable
  termination: SelloutTermination;

  @observable
  terminationItems: Map<string, SelloutTerminationItem> = new Map<string, SelloutTerminationItem>();

  @observable
  terminationSummary: SelloutTerminationSummary;

  @observable
  terminationSummaryItems: Map<string, SelloutTerminationSummaryItem> = new Map<
    string,
    SelloutTerminationSummaryItem
  >();

  @observable
  isConfirmModalHidden: boolean = true;

  @observable
  isCancellModalHidden: boolean = true;

  /** Ag-Grid api references */
  private gridApi: GridApi;
  private columnApi: ColumnApi;

  /**
   * Binds ag-grid api
   */
  @action.bound
  public onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  @action
  showConfirmModal = () => (this.isConfirmModalHidden = false);

  @action
  hideConfirmModal = () => (this.isConfirmModalHidden = true);

  @action
  showCancellModal = () => (this.isCancellModalHidden = false);

  @action
  hideCancellModal = () => (this.isCancellModalHidden = true);
  @computed
  public get renderHeaderButtons(): boolean {
    return this.termination && this.termination.state === PriceTerminationState.Opened;
  }

  @computed
  public get renderSummaryTable(): boolean {
    return (
      this.termination &&
      (this.termination.state === PriceTerminationState.Failed ||
        this.termination.state === PriceTerminationState.Terminated)
    );
  }

  @computed
  get terminationSummaryColumnDefinitions(): ColumnDefinition[] {
    const actionDefs: ColumnDefinition = {
      headerName: 'actions',
      cellRenderer: RendererNames.ButtonActionsRenderer,
      cellRendererParams: {},
      checkboxSelection: false,
      headerCheckboxSelection: false,
    };
    const colDefs = [
      {field: 'rName'}, // Release name
      {field: 'rId'}, // ID
      {field: 'pListType'}, // Price list type
      {field: 'workflowType'}, // Workflow type
    ];
    return [
      ...this.terminationSummaryColumnGenerator.getColumnDefinitions(colDefs),
      this.actionsGenerator.getColumnDefinition(actionDefs),
    ];
  }

  @computed
  public get terminationSummaryRowData(): Array<StringMapping<any>> {
    const rowData = [];

    this.terminationSummaryItems.forEach((terminationItem: SelloutTerminationSummaryItem) => {
      const exportButton: ButtonActionProps = {
        name: 'exportAgain',
        icon: IconType.export,
        disabled: terminationItem.state !== PriceTerminationState.Failed,
        props: {
          onClick: () => this.exportTerminationAgain(this.terminationSummary.id, terminationItem.rId),
        },
      };
      rowData.push({
        ...(terminationItem.state !== PriceTerminationState.Terminated &&
          this.actionsGenerator.getColumnData(exportButton)),
        ...this.terminationSummaryColumnGenerator.getColumnData({...terminationItem}),
      });
    });

    return rowData;
  }

  exportTerminationAgain = async (id: string, rId: string) => {
    try {
      await this.selloutTerminationLayer.confirm(id, rId).then((selloutTerminationSummary) => {
        selloutTerminationSummary.items.forEach((item) => {
          if (item.progressId) {
            this.exportAgainPollingHelper.startPolling(item.progressId, 'export-again-successful');
          }
          runInAction(() => this.terminationSummaryItems.set(item.rId, item));
        });
        this.gridApi.hideOverlay();
      });
    } catch (error) {
      // no op
    }
  };

  @computed
  public get enableConfirmButton(): boolean {
    let terminate: boolean;
    // tslint:disable-next-line: no-unused-expression
    this.terminationItems && this.terminationItems.forEach((item) => (item.terminate ? (terminate = true) : null));
    return terminate;
  }

  @computed
  get terminationItemsColumnDefinitions(): ColumnDefinition[] {
    const colDefs = [
      {
        field: 'validation',
        cellRenderer: RendererNames.ReleaseAlertsRenderer,
        comparator: (a, b) => {
          if (a === b) {
            return 0;
          }
          return a === translate(`BLANKS_FILTER`) ? -1 : 1;
        },
        filterValueGetter: (params: ValueGetterParams) => {
          const messages: string[] = [];
          params.data.SelloutTerminationItem_validation.resultObjects.size
            ? params.data.SelloutTerminationItem_validation.resultObjects.map((obj) =>
                messages.push(translate(`${obj.severity}_${obj.messageKey}`)),
              )
            : messages.push(translate(`BLANKS_FILTER`));
          console.log(messages);
          return messages;
        },
        sortable: false,
        excel: {
          hide: true,
        },
      }, // Alerts
      {field: 'pName'}, // Article
      {field: 'sName'}, // Site name
      {field: 'sExtId'}, // Site ID
      {field: 'rName'}, // Release name
      {field: 'rId'}, // ID
      {field: 'pListType'}, // Price list type
      {field: 'workflowType'}, // Workflow type
      {field: 'pValidFromFPrice'}, // Start date Sellout RP
      {field: 'pValidToFPrice'}, // End date sellout RP
      {field: 'fPrice'}, // FP (final price)
      {
        field: 'pValidTo',
        pinnedRowCellRenderer: RendererNames.BulkRenderer,
        editable: (params) => {
          return this.termination && this.termination.state === PriceTerminationState.Opened && this.isRowEditable(params.data);
        },
        action: this.updateTerminationItems,
        bulkFunc: this.updateTerminationItems,
      }, // New End Date
      {
        field: 'terminate',
        pinnedRowCellRenderer: RendererNames.BulkRenderer,
        editable: (params) => {
          return this.termination && this.termination.state === PriceTerminationState.Opened && this.isRowEditable(params.data);
        },
        action: this.updateTerminationItems,
        bulkFunc: this.updateTerminationItems,
      }, // Export
    ];
    return [...this.terminationItemsColumnGenerator.getColumnDefinitions(colDefs)];
  }

  @computed
  public get terminationItemsRowData(): Array<StringMapping<any>> {
    const rowData = [];
    this.terminationItems.forEach((item: SelloutTerminationItem) =>
      rowData.push({
        ...this.terminationItemsColumnGenerator.getColumnData(item),
      }),
    );

    // display rows with SelloutTerminationMsgKeyEnum.ItemMissingRepriceableItem at the top
    rowData.sort((a, b) => {
      const aNoPrice = this.isRowEditable(a);
      const bNoPrice = this.isRowEditable(b);

      if (aNoPrice && bNoPrice || !aNoPrice && !bNoPrice) {
        return 0;
      }
      if (aNoPrice) {
        return 1;
      } else {
        return -1;
      }
    });

    return rowData;
  }

  @computed
  get rowClassRules(): {[cssClassName: string]: (params: any) => boolean} {
    return {
      'bg-scale6': (params: any) => {
        return params && params.data.SelloutTerminationSummaryItem_state === PriceTerminationState.Failed;
      },
    };
  }

  @computed
  get rowClassRulesReadOnly(): {[cssClassName: string]: (params: any) => boolean} {
    return {
      'bg-read-only': () => this.termination.state !== PriceTerminationState.Opened,
      'bg-error': (params: any) => {
        return params && !this.isRowEditable(params.data);
      },
    };
  }

  /**
   * Handles both single and bulk update
   * @param selectedRow
   * @param field
   * @param newValue
   */
  @action.bound
  private async updateTerminationItems(selectedRow: string | undefined, field: string, newValue: any): Promise<any> {
    let rowIds: List<string>;
    if (selectedRow) {
      rowIds = List.of(selectedRow);
    } else {
      rowIds = this.getRowIdsForBulkUpdate();
    }

    await this.update(field, rowIds, newValue);
    return Promise.reject();
  }

  private async update(field: string, rowIds: List<string>, newValue: any): Promise<any> {
    if (field === SelloutTerminationItem.schema.pValidTo.description.nameKey) {
      const updateData = [] as SelloutTerminationItemPriceValid[];
      rowIds.forEach((rowId) => {
        const rowNode = this.gridApi.getRowNode(rowId);
        const id = rowNode.data[SelloutTerminationItem.schema.id.description.nameKey];
        updateData.push({id, pValidTo: newValue});
      });
      this.selloutTerminationLayer.updateTerminationItemDate(updateData).then((updatedItems) => {
        this.updateTerminationItemRowData(updatedItems);
      });
    }
    if (field === SelloutTerminationItem.schema.terminate.description.nameKey) {
      const updateData: SelloutTerminationItemTerminate[] = [];
      rowIds.forEach((rowId) => {
        const rowNode = this.gridApi.getRowNode(rowId);
        const id = rowNode.data[SelloutTerminationItem.schema.id.description.nameKey];
        updateData.push({id, terminate: newValue});
      });
      this.selloutTerminationLayer.updateTerminationItemTerminate(updateData).then((updatedItems) => {
        this.updateTerminationItemRowData(updatedItems);
      });
    }
  }

  updateTerminationItemRowData(updatedItems: SelloutTerminationItem[]) {
    const rowNodeData: Array<StringMapping<any>> = [];
    updatedItems.forEach((item) => {
      rowNodeData.push({
        ...this.terminationItemsColumnGenerator.getColumnData(item),
      });
    });
    const pinnedRowCount = this.gridApi.getPinnedTopRowCount();
    const pinnedRowData = Array.from({length: pinnedRowCount}, (n, index) => {
      return this.gridApi.getPinnedTopRow(index).data;
    });
    this.gridApi.updateRowData({update: rowNodeData});
    this.gridApi.setPinnedTopRowData(pinnedRowData);
  }

  /**
   * Returns list of node ids
   * Either of selected nodes or all nodes
   */
  @action.bound
  private getRowIdsForBulkUpdate(): List<string> {
    const selected = List(this.gridApi.getSelectedNodes());
    if (selected.size > 0) {
      return selected.map((node) => node.id);
    } else {
      let ids = List();
      this.gridApi.forEachNodeAfterFilter((node) => (ids = ids.push(node.id)));
      return ids;
    }
  }

  confirmTermination = async (): Promise<void> => {
    try {
      await this.selloutTerminationLayer.confirmTermination(this.terminationId).then((termination) => {
        runInAction(() => (this.termination = termination));
        this.messages.setValidationResult(termination.validation);
        this.hideConfirmModal();
        this.checkTerminationProgress();
        if (this.termination.state !== PriceTerminationState.Terminating) {
          this.getTerminationItems();
        }
      });
    } catch (error) {
      this.hideConfirmModal();
    }
  };

  checkTerminationProgress = () => {
    if (this.termination.state === PriceTerminationState.Terminating && this.termination.progressId) {
      this.terminationPollingHelper.startPolling(this.termination.progressId, 'termination-successful');
    }
  };

  getTerminationSummary = async () => {
    if (
      (this.termination && this.termination.state === PriceTerminationState.Failed) ||
      this.termination.state === PriceTerminationState.Terminated
    ) {
      await this.selloutTerminationLayer.getTerminationSummary(this.termination.id).then((terminationSummary) => {
        runInAction(() => (this.terminationSummary = terminationSummary));
        const terminationSummaryItemsMap: Map<string, SelloutTerminationSummaryItem> = new Map<
          string,
          SelloutTerminationSummaryItem
        >();
        for (const summaryItem of this.terminationSummary.items) {
          terminationSummaryItemsMap.set(summaryItem.rId, summaryItem);
          if (summaryItem.state === PriceTerminationState.Terminating && summaryItem.progressId) {
            this.exportAgainPollingHelper.startPolling(summaryItem.progressId, 'export-again-successful');
          }
        }
        runInAction(() => (this.terminationSummaryItems = terminationSummaryItemsMap));
      });
    }
  };

  getTermination = async () => {
    await this.selloutTerminationLayer.getTerminationById(this.terminationId).then((termination) => {
      runInAction(() => (this.termination = termination));
      this.messages.setValidationResult(termination.validation);
    });
  };

  getTerminationItems = async () => {
    await this.selloutTerminationLayer.getTerminationItems(this.terminationId).then((terminationItems) => {
      const terminationItemsMap: Map<string, SelloutTerminationItem> = new Map<string, SelloutTerminationItem>();
      for (const terminationItem of terminationItems) {
        terminationItemsMap.set(terminationItem.id, terminationItem);
      }
      runInAction(() => (this.terminationItems = terminationItemsMap));
    });
  };

  cancelTermination = async (): Promise<void> => {
    try {
      await this.selloutTerminationLayer.cancelTermination(this.terminationId).then((termination) => {
        runInAction(() => (this.termination = termination));
        this.hideCancellModal();
      });
    } catch (error) {
      this.hideCancellModal();
    }
  };

  public load = async (): Promise<void> => {
    this.setLoadingState(LoadingState.Pending);
    try {
      await this.getTermination();
      await this.getTerminationItems();
      this.checkTerminationProgress();
      await this.getTerminationSummary();
      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      this.setLoadingState(LoadingState.Error);
    }
  };

  private onTerminationPoolingChange = (pollingState: LoadingState) => {
    if (pollingState === LoadingState.Success) {
      this.load();
    }
  };

  private onExportAgainPollingStateChanged = (pollingState: LoadingState) => {
    if (pollingState === LoadingState.Success) {
      this.load();
    }
  };

  /**
   * Returns false if validation contains error SelloutTerminationMsgKeyEnum.ItemMissingRepriceableItem
   *
   * @param data
   */
  private isRowEditable(data) {
    return !(
      data &&
      data.SelloutTerminationItem_validation &&
      data.SelloutTerminationItem_validation.hasMessageWithKey(SelloutTerminationMsgKeyEnum.ItemMissingRepriceableItem)
    );
  }
}
