/**
 * @file Created on Fri Nov 16 2018
 * @author SKu
 */
import { Utils } from '@logio/common-be-fe';
import * as React from 'react';
import Tooltip from '../../../../info/Tooltip';
import Icon, { IconColor, IconType } from '../../../../media/Icon';
export var ReleaseCommentsRenderer = function ReleaseCommentsRenderer(props) {
  var priceZoneId = props.colDef.cellRendererParams.priceZoneId;
  var finalPrice = !!props.data["ReleaseDetailCategory_".concat(priceZoneId, "_finalPrice")];

  if (finalPrice) {
    var comments = props.value && props.value.size > 0 ? Array.from(props.value.values()) : null;
    var onClick = props.colDef.cellRendererParams.handleCommentModalOpen(props.data.ReleaseDetailCategory_id, priceZoneId, comments);

    if (!Utils.isValueMissing(comments)) {
      var lastComment = comments[comments.length - 1];
      return React.createElement("div", {
        className: "fixed z1000"
      }, React.createElement(Tooltip, {
        overlay: "".concat(lastComment.text, "\n(").concat(lastComment.username, ")"),
        placement: "right"
      }, React.createElement("div", null, React.createElement(Icon, {
        iconType: IconType.info,
        iconColor: IconColor.Secondary,
        onClick: onClick,
        iconHeight: 25
      }))));
    }

    return React.createElement(Icon, {
      iconType: IconType.info,
      iconColor: IconColor.Light,
      onClick: onClick,
      iconHeight: 25
    });
  } else {
    return React.createElement("span", null, "---");
  }
};