/**
 * @file Created on September 2019
 * @author Atreo Digital s.r.o.
 * @description Custom comparators for fields rendered by custom renderers
 */
import arrowComparator from "./arrowComparator";
import checkBoxComparator from "./checkBoxComparator";
import familyNameComparator from "./familyNameComparator";
import dataCleaningCRPAndNewCRPComparator from "./dataCleaningCRPAndNewCRPComparator";
import priceArchitectureNewComparator from "./priceArchitectureNewComparator";
import releaseAlertsComparator from "./releaseAlertsComparator";
import releaseCommentsComparator from "./releaseCommentsComparator";
import releaseCRPIndexComparator from "./releaseCRPIndexComparator";
import releaseFinalPriceComparator from "./releaseFinalPriceComparator";
import releaseReferencePriceComparator from "./releaseReferencePriceComparator";
export default {
  arrowComparator: arrowComparator,
  checkBoxComparator: checkBoxComparator,
  familyNameComparator: familyNameComparator,
  dataCleaningCRPAndNewCRPComparator: dataCleaningCRPAndNewCRPComparator,
  priceArchitectureNewComparator: priceArchitectureNewComparator,
  releaseAlertsComparator: releaseAlertsComparator,
  releaseCommentsComparator: releaseCommentsComparator,
  releaseCRPIndexComparator: releaseCRPIndexComparator,
  releaseFinalPriceComparator: releaseFinalPriceComparator,
  releaseReferencePriceComparator: releaseReferencePriceComparator
};