/**
 * @file Created on Fri Aug 17 2018
 * @author SKu
 */

import {PriceArchitectureDTO, ProductCategory} from '@logio/common-be-fe';
import {
  ActionProps,
  ActionsGenerator,
  ColumnDefinition,
  ColumnGenerator,
  DownloadStore,
  getPath,
  IconType,
  LoadingState,
  PageStore,
  ProductStore,
  rootStore,
  StoreName,
  translate,
} from '@logio/common-fe';
import {computed, observable, action, values, runInAction} from 'mobx';
import {PagePathsEnum} from '../../../../shared/localization/PagePathsEnum';
import {CategoryTraverseHelper} from '../../../components/CategoryTraverseHelper';
import {PollingHelper} from 'stores/components/PollingHelper';

export class PriceArchitectureCategoriesPageStore extends PageStore {
  private actionsGenerator = new ActionsGenerator();
  private statsColumnGenerator = new ColumnGenerator<PriceArchitectureDTO>(PriceArchitectureDTO.schema);

  productStore = rootStore.getStore(StoreName.Product) as ProductStore;
  downloadStore = rootStore.getStore(StoreName.Download) as DownloadStore;

  /** Returns generated column definitions for ag-grid */
  @computed
  get columnDefs(): ColumnDefinition[] {
    return [
      this.actionsGenerator.getColumnDefinition({
        headerCheckboxSelection: false,
        checkboxSelection: false,
        cellClass: 'ag-cell-align-center',
        // width: 80, // LOG-5371 - column moving doesn't work for narrow columns
        suppressMovable: false,
        lockPinned: false,
        lockPosition: false,
      }),
      ...this.statsColumnGenerator.getColumnDefinitions([
        {
          field: 'newWithRecommended',
          headerIcon: IconType.newsFull,
          aggFunc: 'sum',
        },
        {
          field: 'newWithoutRecommended',
          headerIcon: IconType.newsRecommend,
          aggFunc: 'sum',
        },
        {
          field: 'totalProducts',
          aggFunc: 'sum',
        },
        {
          field: 'notAssignedProducts',
          aggFunc: 'sum',
        },
        {
          field: 'numberOfFamilies',
          aggFunc: 'sum',
        },
      ]),
    ];
  }

  /**
   * Returns true if request for file is younger than 10 minutes
   */
  @observable
  isProgressMonitorIdValid: boolean = localStorage.getItem('priceArchitecture-progressMonitorValidationDate')
    ? new Date().getTime() -
        new Date(localStorage.getItem('priceArchitecture-progressMonitorValidationDate')).getTime() <=
      600000
    : false;

  @computed
  get progressMonitorId() {
    return localStorage.getItem('priceArchitecture-progressMonitorId');
  }

  set progressMonitorId(value) {
    localStorage.setItem('priceArchitecture-progressMonitorId', value);
    localStorage.setItem('priceArchitecture-progressMonitorValidationDate', new Date().toString());
  }

  /** Export - filename for download */
  @computed
  get downloadFileName() {
    return localStorage.getItem('priceArchitecture-downloadFilename');
  }

  set downloadFileName(value) {
    localStorage.setItem('priceArchitecture-downloadFilename', value);
  }

  /**
   * Removes data for export file from locale storage
   */
  removeProgressMonitorDataFromLocalStorage() {
    localStorage.removeItem('priceArchitecture-progressMonitorId');
    localStorage.removeItem('priceArchitecture-downloadFilename');
    localStorage.removeItem('priceArchitecture-progressMonitorValidationDate');
  }

  /** Checkout {@link CategoryTraverseHelper} constructor for detailed info */
  private getDataPerRow = (category: ProductCategory) => {
    const dto = this.CategoryTraverseHelper.productCategoryStore.priceArchitectureStats.get(category.id);
    return dto ? this.statsColumnGenerator.getColumnData(dto) : {};
  };
  private getActionsPerRow = (category: ProductCategory): ActionProps[] => [
    {
      name: 'edit',
      icon: IconType.edit,
      linkProps: {to: getPath(PagePathsEnum.PriceArchitectureProducts, category.id)},
    },
  ];

  /** Store handling ag-grid tree view */
  public CategoryTraverseHelper = new CategoryTraverseHelper(this.getDataPerRow, this.getActionsPerRow);

  /** Fetches all data for this page */
  public async load(): Promise<void> {
    try {
      await Promise.all([
        this.CategoryTraverseHelper.load(),
        this.CategoryTraverseHelper.productCategoryStore.getCategoryStats(),
      ]);
      if (this.isProgressMonitorIdValid && this.progressMonitorId && this.downloadFileName) {
        this.pollingHelper.startPolling(this.progressMonitorId, 'DATA_CLEANING_EXPORT');
      }
      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      // this.messages.setError(error);
    }
  }

  /** Export - preparing file - PollingHelper callback */
  private onExportPollingStateChanged = async (pollingState: LoadingState) => {
    if (pollingState === LoadingState.Pending) {
      if (!this.exportIsLoading) runInAction(() => (this.exportIsLoading = true));
    } else {
      if (pollingState === LoadingState.Success) {
        if (this.downloadFileName) {
          try {
            await this.downloadStore.download(this.downloadFileName);
          } catch (error) {
            // this.messages.setError(error);
          }
        } else {
          this.messages.setError(translate('PRICE_ARCHITECTURE_EXPORT_ERROR_NO_FILENAME'));
        }
      }
      runInAction(() => (this.exportIsLoading = false));
      this.removeProgressMonitorDataFromLocalStorage();
    }
  };

  /** Export - preparing file - PollingHelper */
  public pollingHelper = new PollingHelper(this.messages, this.onExportPollingStateChanged, 1000);

  /** Export - loading state of button */
  @observable
  exportIsLoading = false;

  /** Export - action */
  @action
  public export() {
    try {
      this.productStore.priceArchitectureExport().then((value: any) => {
        if (value.progress.id) {
          this.progressMonitorId = value.progress.id;
          this.downloadFileName = value.filename;
          this.pollingHelper.startPolling(this.progressMonitorId, 'PRICE_ARCHITECTURE_EXPORT');
        }
      });
    } catch (error) {
      // this.messages.setError(error);
    }
  }
}
