/**
 * @author VBr
 */

import {WorkflowCaseInfo} from '@logio/common-be-fe';
import {
  ActionsGenerator,
  ColumnDefinition,
  ColumnGenerator,
  IconType,
  LoadingState,
  PageStore,
  ProfileStore,
  rootStore,
  StoreName,
  StringMapping,
  translate,
  WorkflowAdministrationStore,
} from '@logio/common-fe';
import {List} from 'immutable';
import {ColumnApi, GridApi, GridReadyEvent, RowNode, SelectionChangedEvent} from 'ag-grid-community';
import {action, computed, observable} from 'mobx';
import {PagePathsEnum} from '../../../../shared/localization/PagePathsEnum';

export class WorkflowCasesPageStore extends PageStore {
  /** Get dependant stores */
  workflowAdministrationStore = rootStore.getStore(StoreName.WorkflowAdministration) as WorkflowAdministrationStore;
  profileStore = rootStore.getStore(StoreName.Profile) as ProfileStore;

  /** Data generators */
  workflowCaseInfoGenerator = new ColumnGenerator<WorkflowCaseInfo>(WorkflowCaseInfo.schema);
  actionsGenerator = new ActionsGenerator();

  gridApi: GridApi;
  columnApi: ColumnApi;

  @observable
  selectedRowsCount: number = 0;
  @observable
  workflowDefinition: string;

  /** Return generated column definitions for ag-grid */
  @computed
  get columnDefs(): ColumnDefinition[] {
    return [
      this.actionsGenerator.getColumnDefinition(),
      ...this.workflowCaseInfoGenerator.getColumnDefinitions([
        {
          field: 'wflowId',
          filter: false,
          suppressMenu: true,
          valueFormatter: ({value}) => this.workflowAdministrationStore.caseDefinitions.get(value).title,
        },
        {field: 'entity'},
        {
          field: 'assignee',
          filter: 'agSetColumnFilter',
          valueFormatter: ({value}) => (value ? (this.profileStore.list.get(value).name) ? this.profileStore.list.get(value).name : value : ''),
        },
        {field: 'state', filter: 'agSetColumnFilter'},
        // {field: 'terminated'},
      ]),
    ];
  }

  /** Return generated data for ag-grid */
  @computed
  get rowData(): Array<StringMapping<any>> {
    const rowData = [];
    this.workflowAdministrationStore.cases.forEach((workflowCaseInfo: WorkflowCaseInfo) => {
      const terminateCase = {
        name: 'terminate',
        icon: IconType.delete,
        disabled: workflowCaseInfo.terminated,
        props: {onClick: () => this.terminateCase(workflowCaseInfo.id)},
      };

      const abandonCase = {
        name: 'abandon',
        icon: IconType.user,
        disabled: !workflowCaseInfo.assignee,
        props: {onClick: () => this.abandonCase(workflowCaseInfo.id)},
      };

      const viewLocks = {
        name: 'viewLocks',
        icon: IconType.locked,
        disabled: workflowCaseInfo.terminated,
        linkProps: {to: `${translate(PagePathsEnum.WorkflowLocks)}?caseId=${workflowCaseInfo.id}`},
      };

      rowData.push({
        ...this.actionsGenerator.getColumnData(viewLocks, terminateCase, abandonCase),
        ...this.workflowCaseInfoGenerator.getColumnData(workflowCaseInfo),
      });
    });
    return rowData;
  }

  @action.bound
  abandonCase = (caseId: string) => {
    this.workflowAdministrationStore
      .abandonCases(List([caseId]))
      .then(() => {
        this.messages.setSuccess(translate('workflow_cases-abandoned', '1'));
      })
      .catch((err) => this.messages.setError(err));
  };

  @action.bound
  abandonCases = () => {
    const selectedCases = List(
      this.gridApi
        .getSelectedNodes()
        .filter((node) => node.data[WorkflowCaseInfo.schema.assignee.description.nameKey])
        .map((node) => node.data[WorkflowCaseInfo.schema.id.description.nameKey]),
    );
    this.workflowAdministrationStore
      .abandonCases(selectedCases)
      .then(() => {
        this.messages.setSuccess(translate('workflow_cases-abandoned', selectedCases.size.toString()));
      })
      .catch((err) => this.messages.setError(err));
  };

  @action.bound
  terminateCase = (caseId: string) => {
    this.workflowAdministrationStore
      .terminateCases(List([caseId]))
      .then(() => {
        this.messages.setSuccess(translate('workflow_cases-terminated', '1'));
      })
      .catch((err) => this.messages.setError(err));
  };

  @action.bound
  terminateCases = () => {
    const selectedCases = List(
      this.gridApi
        .getSelectedNodes()
        .filter((node) => !node.data[WorkflowCaseInfo.schema.terminated.description.nameKey])
        .map((node) => node.data[WorkflowCaseInfo.schema.id.description.nameKey]),
    );
    this.workflowAdministrationStore
      .terminateCases(selectedCases)
      .then(() => {
        this.messages.setSuccess(translate('workflow_cases-terminated', selectedCases.size.toString()));
      })
      .catch((err) => this.messages.setError(err));
  };

  /** Checks if there's external filter set for families  */
  @action.bound
  isExternalFilterPresent(): boolean {
    return !!this.workflowDefinition;
  }

  /** Function that checks products against filter parameters - fulltext and checkbox filters */
  @action.bound
  doesExternalFilterPass(node: RowNode): boolean {
    return (
      !this.workflowDefinition ||
      this.workflowDefinition === node.data[WorkflowCaseInfo.schema.wflowId.description.nameKey]
    );
  }

  @action.bound
  updateFiler(data: StringMapping<any>) {
    this.workflowDefinition = data.workflowDefinition;

    if (this.gridApi) {
      this.gridApi.onFilterChanged();
    }
  }

  @action.bound
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  @action.bound
  onSelectionChanged = (event: SelectionChangedEvent): void => {
    this.selectedRowsCount = event.api.getSelectedRows().length;
  };

  /** Fetches all data for page from entity stores */
  @action.bound
  load(): void {
    this.setLoadingState(LoadingState.Pending);
    Promise.all([
      this.workflowAdministrationStore.getWorkflowCases(),
      this.workflowAdministrationStore.getWorkflowCaseDefinitions(),
    ])
      .then(([cases]) => {
        const userIds = [];
        cases.forEach((caseInfo) => {
          if (caseInfo.assignee && !userIds.includes(caseInfo.assignee)) {
            userIds.push(caseInfo.assignee);
          }
        });
        this.profileStore
          .getUsers(userIds)
          .then(() => this.setLoadingState(LoadingState.Success))
          .catch((error) => {
            this.setLoadingState(LoadingState.Error);
            this.messages.setError(error);
          });
      })
      .catch((error) => {
        this.setLoadingState(LoadingState.Error);
        this.messages.setError(error);
      });
  }
}
