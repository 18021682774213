"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
/*
 * Created on Thu Aug 09 2018
 */

/**
 * Representation of list of results. This list/array wrapper can be extended
 * with additional features (such as totalCount, offset etc.) and also its
 * JSON representation can be extended (unlike the raw arrays).
 *
 * @author VRu
 * @author Radek Beran
 */

var ResultList =
/**
 * Creates new result list.
 * @param result
 * @param lastRow sequential number of last fetched record (from the beginning of all filtered records)
 * @param validation Result of validations. It may be handy to provide aggregated result of validations related to entire result.
 */
function ResultList(result, lastRow) {
  var validation = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

  _classCallCheck(this, ResultList);

  this.result = result;
  this.lastRow = lastRow;
  this.validation = validation;
};

exports.ResultList = ResultList;