/**
 * @file Created on Wed Apr 24 2019
 * @author SKu
 */

import {
  Permission,
  PricingRoles,
  ProductCategory,
  ReleaseAction,
  ReleaseActionFeasibility,
  ReleaseState,
  ReleaseType,
  RequiresRecalculation,
  UserInfo,
} from '@logio/common-be-fe';
import {
  AbstractReleaseStore,
  CONSTANTS,
  IReleaseImpactFilter,
  LoadingState,
  PageStore,
  ProfileStore,
  rootStore,
  StoreName,
  translate,
  withPermission,
  WorkflowAssigneeObserverStore,
} from '@logio/common-fe';
import {List} from 'immutable';
import {EventEmitter} from 'events';
import {History} from 'history';
import {computed, observable, runInAction} from 'mobx';
import {AbstractReleaseHeaderStore} from '../../../components';
import {PollingHelper} from '../../../components/PollingHelper';
import {AbstractReleaseDetailCategoryComponentStore} from '../../../components/Release/ReleaseDetailCategory/AbstractReleaseDetailCategoryComponentStore';

interface RecalculateEvent {
  productId: string;
  type: string;
  updateFunction: (productId: string) => void;
}

export abstract class AbstractReleaseDetailCategoryPageStore extends PageStore {
  /**
   * @param history - fromm react router
   * @param releaseId
   * @param categoryId
   */
  constructor(public history: History, public releaseId: string, public categoryId: string) {
    super();
  }

  /** Stores */
  protected abstract releaseStore: AbstractReleaseStore;
  public abstract releaseHeaderStore: AbstractReleaseHeaderStore;
  public abstract releaseDetailCategoryComponentStore: AbstractReleaseDetailCategoryComponentStore;

  /** Current category */
  public productCategory: ProductCategory;

  public recalculateEvent: RecalculateEvent;

  /** All permissions, needed to edit page */
  public abstract get permissionsForEdit(): Permission[];

  /** Current release */
  @computed
  public get release() {
    return this.releaseStore.release;
  }

  /** Current release part from release entity */
  @computed
  private get currentReleasePart() {
    return this.release.requireReleasePartForCategory(this.categoryId);
  }

  /** If recalculation is required,  appropriate icon should be shown  */
  @computed
  public get isRecalculationRequired() {
    return this.currentReleasePart.validationResult.resultObjects.some((rObject) =>
      rObject.flags.has(RequiresRecalculation),
    );
  }

  /** Returns allowed actions for current category */
  public abstract get actionFeasibilities(): List<ReleaseActionFeasibility>;

  /** Return path to release(sellout) detail page */
  protected abstract get pathToDetail(): string;

  // Possibility to hide action icon after fired - LOG-5222
  @observable
  public recalculateActionFired: boolean = false;

  public profileStore: ProfileStore = rootStore.getStore(StoreName.Profile) as ProfileStore;

  public confirmCategoryEventEmitter = new EventEmitter();

  @observable
  public isConfirmModal: boolean = false;

  @computed
  public get showRecalculationButton() {
    return this.isRecalculationRequired && !this.pollingHelper.progress && !this.recalculateActionFired;
  }

  protected categoryAssigneeObserver = new WorkflowAssigneeObserverStore(
    CONSTANTS.WORKFLOW_ASSIGNEE_UPDATE_INTERVAL_IN_MS,
    this.handleAssigneeChange.bind(this),
  );

  /**
   * Function for using Release action endpoint
   * @param action{ReleaseAction} - action type
   */
  public onAction = async (action: ReleaseAction, recalculateEvent?: RecalculateEvent): Promise<void> => {
    this.recalculateEvent = recalculateEvent;
    const actionData = {
      releaseId: this.releaseId,
      action,
      allCategories: false,
      categoryIds: [this.categoryId],
    };
    const runAction = async () => {
      try {
        const actionResponse = await this.releaseStore.action(actionData);
        actionResponse.successAll ? this.handleActionSuccess(action) : this.handleActionFail(actionResponse);
      } catch (error) {
        // this.messages.setError(error);
      }
    };

    switch (action) {
      case ReleaseAction.Approve: {
        if (this.isConfirmNeeded) {
          this.confirmCategoryEventEmitter.addListener('confirmCategory', async (isConfirmed) => {
            runInAction(() => (this.isConfirmModal = false));
            this.confirmCategoryEventEmitter.removeAllListeners();
            if (isConfirmed) {
              await runAction();
            }
          });
          runInAction(() => (this.isConfirmModal = true));
        } else {
          await runAction();
        }
        break;
      }
      case ReleaseAction.Recalculate: {
        runInAction(() => (this.recalculateActionFired = true));
        this.releaseDetailCategoryComponentStore.setEditMode(false);
        await runAction();
        break;
      }
      default:
        await runAction();
    }
  };

  /**
   * Return true if user need to confirm approve action
   */
  get isConfirmNeeded() {
    if (this.release.releaseType === ReleaseType.Regular) {
      return (
        this.profileStore.profile.hasRole(PricingRoles.CATEGORY_MANAGER) ||
        this.profileStore.profile.hasRole(PricingRoles.SENIOR_CATEGORY_MANAGER) ||
        this.profileStore.profile.hasRole(PricingRoles.PRICE_MANAGER)
      );
    } else if (this.release.releaseType === ReleaseType.Sellout) {
      return (
        this.profileStore.profile.hasRole(PricingRoles.CATEGORY_MANAGER) ||
        this.profileStore.profile.hasRole(PricingRoles.SELLOUT_MANAGER)
      );
    }
    return false;
  }

  /**
   * Called on confirm from modal
   */
  public onConfirm = () => {
    this.confirmCategoryEventEmitter.emit('confirmCategory', true);
  };

  /**
   * Called on cancel from modal
   */
  public onCancel = () => {
    this.confirmCategoryEventEmitter.emit('confirmCategory', false);
  };

  /**
   * Used if all requested actions was successful
   * Depending on ReleaseAction type executes different operations
   * @param action
   */
  private handleActionSuccess = (action: ReleaseAction) => {
    const msgArgs = [translate(`${action}-action`), this.productCategory.name];
    switch (action) {
      case ReleaseAction.Edit:
        this.releaseDetailCategoryComponentStore.setEditMode(true);
        break;
      case ReleaseAction.Recalculate:
        /** Updates current release, to get polling id */
        this.releaseHeaderStore.load(this.releaseId).then(() => this.handleRecalculation());
        /*.catch((error) => this.messages.setError(error))*/
        break;
      case ReleaseAction.AbandonEdit:
        this.releaseDetailCategoryComponentStore.setEditMode(false);
        this.messages.setWarning(translate('release-detail-category-read-only-mode'));
        break;
      default:
        this.history.push(this.pathToDetail);
        break;
    }

    this.messages.setSuccess(translate('release-action-success', ...msgArgs));
  };

  /**
   * Used if at least one of requested actions failed
   * Sets info-errors
   * @param actionResponse  data from BE
   */
  handleActionFail = (actionResponse: any) => {
    actionResponse.results.map((result) => {
      const msgArgs = [translate(`${actionResponse.action}-action`)];
      const msgArgsConflictingUser = [];

      if (result.errorCode === undefined) {
        this.messages.setWarning(translate('action-error-undefined', ...msgArgs));
      } else {
        msgArgs.push(translate(`${result.errorCode}-${result.errorSubcode}`));

        if (result.conflictingUser) {
          msgArgsConflictingUser.push(result.conflictingUser.name);
          this.messages.setWarning(translate('category-locked-error', ...msgArgsConflictingUser));
        } else {
          this.messages.setWarning(translate('action-error', ...msgArgs));
        }
      }
    });
  };

  private onPollingStateChanged = async (pollingState: LoadingState) => {
    if (pollingState === LoadingState.Success) {
      if (this.recalculateEvent && this.recalculateEvent.type === 'updateModalItems') {
        await this.recalculateEvent.updateFunction(this.recalculateEvent.productId);
      }
      runInAction(() => (this.recalculateActionFired = false));
      this.setLoadingState(LoadingState.Pending);
      this.load();
      this.removeWarnings();
    } else if (pollingState === LoadingState.Error) {
      runInAction(() => (this.recalculateActionFired = false));
      this.load();
    }
  };

  public pollingHelper = new PollingHelper(this.messages, this.onPollingStateChanged, 1000);

  /**
   * Handle recalculation polling
   */
  private handleRecalculation = () => {
    const {calcProgressMonitorId} = this.currentReleasePart;
    if (calcProgressMonitorId) {
      this.pollingHelper.startPolling(calcProgressMonitorId, 'release-recalculation');
    }
  };

  private removeWarnings = () => {
    const warnings = [...this.messages.list].filter(([key, value]) => value.type === 'WARNING');
    warnings.forEach(([key]) => this.messages.remove(key));
  };

  /** Fletches data for the page    */
  public load = async (getImpactFilter?: () => IReleaseImpactFilter) => {
    try {
      await Promise.all([
        this.releaseHeaderStore.load(this.releaseId),
        this.releaseDetailCategoryComponentStore.load(getImpactFilter),
      ]);
      this.productCategory = this.releaseDetailCategoryComponentStore.productCategoryStore.getCategoryForRelease(
        this.releaseId,
        this.categoryId,
      );
      /** If user has permission,  try to lock page for editing */
      if (withPermission(this.permissionsForEdit, true) && !this.releaseStore.isSelloutSimulation) {
        await this.onAction(ReleaseAction.Edit);
        this.setLoadingState(LoadingState.Success);
        /** If recalculation is going, set up progress monitor, and start polling */
        const {calcProgressMonitorId} = this.currentReleasePart;
        if (calcProgressMonitorId) {
          const pollingCouldBeStarted = await this.pollingHelper.couldPollingBeStarted(calcProgressMonitorId);
          if (pollingCouldBeStarted) {
            this.handleRecalculation();
          }
        }
      }
      this.setLoadingState(LoadingState.Success);

      // initialize WF case assignee observer
      this.initAssigneeObserver();
    } catch (error) {
      // this.messages.setError(error);
      this.setLoadingState(LoadingState.Error);
    }
  };

  /**
   * Cleans data of the store, which should be cleaned. Should be called in componentWillUnmount in component, which called `load()`
   */
  public destroy() {
    this.pollingHelper.polling.stopPolling();
    this.destroyAssigneeObserver();
    this.releaseDetailCategoryComponentStore.destroy();
  }

  private initAssigneeObserver() {
    const wfCaseId = this.getWorkflowCaseId();
    if (wfCaseId) {
      this.categoryAssigneeObserver.init(List.of(wfCaseId));
    }
  }

  private getWorkflowCaseId() {
    if (this.release) {
      const releasePart = this.release.releaseParts.find((rp) => rp.productCategoryId === this.categoryId);

      if (releasePart) {
        return releasePart.workflowCaseId;
      }
    }

    return undefined;
  }

  private destroyAssigneeObserver() {
    this.categoryAssigneeObserver.destroy();
  }

  private handleAssigneeChange(previousAssignees: Map<string, UserInfo | null>) {
    const previousAssignee = previousAssignees.get(this.getWorkflowCaseId());

    if (previousAssignee != null && previousAssignee.id === this.profileStore.profile.id) {
      // current user unlocked himself - we can assume he no longer wants to obtain the lock
      this.categoryAssigneeObserver.destroy();

      // if current user unlocked himself from different tab, fix editMode flag.
      this.releaseDetailCategoryComponentStore.setEditMode(false);
    } else {
      // reload store
      this.load();
    }
  }
}
