/**
 * @file Created on Fri Oct 12 2018
 * @author SKu
 */

import {Permission, PricingPermissions} from '@logio/common-be-fe';
import {getPath, ReleaseSelloutStore, rootStore, StoreName, LoadingState} from '@logio/common-fe';
import {History} from 'history';
import {computed} from 'mobx';
import {PagePathsEnum} from '../../../../shared/localization/PagePathsEnum';
import {ReleaseSelloutDetailCategoryComponentStore, ReleaseSelloutHeaderStore} from '../../../components';
import {AbstractReleaseDetailCategoryPageStore} from './AbstractReleaseDetailCategoryPageStore';

export class ReleaseSelloutDetailCategoryPageStore extends AbstractReleaseDetailCategoryPageStore {
  protected releaseStore = rootStore.getStore(StoreName.SelloutRelease) as ReleaseSelloutStore;
  public releaseHeaderStore = new ReleaseSelloutHeaderStore(
    this.messages,
    () => this.setLoadingState(LoadingState.Pending),
    location.pathname,
    (progressMonitor: string) => {
      this.pollingHelper.startPolling(progressMonitor, 'RELEASE');
      this.setLoadingState(LoadingState.Success);
    },
  );
  public releaseDetailCategoryComponentStore = new ReleaseSelloutDetailCategoryComponentStore(
    this.history,
    this.messages,
    this.releaseId,
    this.categoryId,
  );

  /** @inheritdoc */
  constructor(history: History, releaseId: string, categoryId: string) {
    super(history, releaseId, categoryId);
  }

  /** @inheritdoc */
  @computed
  public get actionFeasibilities() {
    const total = this.releaseDetailCategoryComponentStore.totalWithActions;
    return total && total.actionFeasibilities;
  }

  /** @inheritdoc */
  protected get pathToDetail() {
    return getPath(PagePathsEnum.ReleaseSelloutDetail, this.releaseId);
  }

  /** @inheritdoc */
  public get permissionsForEdit(): Permission[] {
    return [
      PricingPermissions.SELLOUT_RELEASE_FINAL_PRICE_EDIT,
      // PricingPermissions.SELLOUT_RELEASE_RECALCULATE,
      PricingPermissions.SELLOUT_RELEASE_CATEGORY_APPROVE,
      PricingPermissions.SELLOUT_RELEASE_CATEGORY_REJECT,
    ];
  }
}
