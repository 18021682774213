import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n    height: 35px;\n    line-height: 25px;\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n\n  ", "\n\n  .rc-tooltip {\n    opacity: 1;\n  }\n\n  .rc-tooltip-inner {\n    font-size: 90%;\n    padding: ", "rem;\n    background-color: ", ";\n    color: ", ";\n    box-shadow: ", ";\n  }\n\n  .rc-tooltip-placement-right {\n    .rc-tooltip-arrow {\n      border-right-color: ", ";\n    }\n  }\n\n  .rc-tooltip-placement-left {\n    .rc-tooltip-arrow {\n      border-left-color: ", ";\n    }\n  }\n\n  .rc-tooltip-placement-top {\n    .rc-tooltip-arrow {\n      border-top-color: ", ";\n    }\n  }\n\n  .rc-tooltip-placement-bottom {\n    .rc-tooltip-arrow {\n      border-bottom-color: ", ";\n    }\n  }\n\n  .rc-tooltip-placement-bottomLeft {\n    .rc-tooltip-arrow {\n      border-bottom-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-bottomRight {\n    .rc-tooltip-arrow {\n      border-bottom-color: ", ";\n    }\n  }\n\n  .rc-tooltip-content {\n    font-size: 1rem;\n    max-width: 800px;\n    white-space: normal;\n    text-transform: none;\n  }\n\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  height: auto;\n  line-height: inherit;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  .rc-tooltip-inner {\n    background-color: ", ";\n  }\n  .rc-tooltip-placement-right {\n    .rc-tooltip-arrow {\n      border-right-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-left {\n    .rc-tooltip-arrow {\n      border-left-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-top {\n    .rc-tooltip-arrow {\n      border-top-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-bottom {\n    .rc-tooltip-arrow {\n      border-bottom-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-bottomLeft {\n    .rc-tooltip-arrow {\n      border-bottom-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-bottomRight {\n    .rc-tooltip-arrow {\n      border-bottom-color: ", ";\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  .rc-tooltip-inner {\n    background-color: ", ";\n  }\n  .rc-tooltip-placement-right {\n    .rc-tooltip-arrow {\n      border-right-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-left {\n    .rc-tooltip-arrow {\n      border-left-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-top {\n    .rc-tooltip-arrow {\n      border-top-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-bottom {\n    .rc-tooltip-arrow {\n      border-bottom-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-bottomLeft {\n    .rc-tooltip-arrow {\n      border-bottom-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-bottomRight {\n    .rc-tooltip-arrow {\n      border-bottom-color: ", ";\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  .rc-tooltip-inner {\n    background-color: ", ";\n  }\n  .rc-tooltip-placement-right {\n    .rc-tooltip-arrow {\n      border-right-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-left {\n    .rc-tooltip-arrow {\n      border-left-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-top {\n    .rc-tooltip-arrow {\n      border-top-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-bottom {\n    .rc-tooltip-arrow {\n      border-bottom-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-bottomLeft {\n    .rc-tooltip-arrow {\n      border-bottom-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-bottomRight {\n    .rc-tooltip-arrow {\n      border-bottom-color: ", ";\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  .rc-tooltip-inner {\n    background-color: ", ";\n    white-space: nowrap;\n  }\n  .rc-tooltip-placement-right {\n    .rc-tooltip-arrow {\n      border-right-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-left {\n    .rc-tooltip-arrow {\n      border-left-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-top {\n    .rc-tooltip-arrow {\n      border-top-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-bottom {\n    .rc-tooltip-arrow {\n      border-bottom-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-bottomLeft {\n    .rc-tooltip-arrow {\n      border-bottom-color: ", ";\n    }\n  }\n  .rc-tooltip-placement-bottomRight {\n    .rc-tooltip-arrow {\n      border-bottom-color: ", ";\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author SKu
 */
import { TooltipType } from '../TooltipType';
import styled, { css, withTheme } from 'styled-components';
var TooltipError = css(_templateObject(), function (props) {
  return props.theme.colors.error;
}, function (props) {
  return props.theme.colors.error;
}, function (props) {
  return props.theme.colors.error;
}, function (props) {
  return props.theme.colors.error;
}, function (props) {
  return props.theme.colors.error;
}, function (props) {
  return props.theme.colors.error;
}, function (props) {
  return props.theme.colors.error;
});
var TooltipWarning = css(_templateObject2(), function (props) {
  return props.theme.colors.warning;
}, function (props) {
  return props.theme.colors.warning;
}, function (props) {
  return props.theme.colors.warning;
}, function (props) {
  return props.theme.colors.warning;
}, function (props) {
  return props.theme.colors.warning;
}, function (props) {
  return props.theme.colors.warning;
}, function (props) {
  return props.theme.colors.warning;
});
var TooltipInfo = css(_templateObject3(), function (props) {
  return props.theme.colors.focus;
}, function (props) {
  return props.theme.colors.focus;
}, function (props) {
  return props.theme.colors.focus;
}, function (props) {
  return props.theme.colors.focus;
}, function (props) {
  return props.theme.colors.focus;
}, function (props) {
  return props.theme.colors.focus;
}, function (props) {
  return props.theme.colors.focus;
});
var TooltipSuccess = css(_templateObject4(), function (props) {
  return props.theme.colors.scale2;
}, function (props) {
  return props.theme.colors.scale2;
}, function (props) {
  return props.theme.colors.scale2;
}, function (props) {
  return props.theme.colors.scale2;
}, function (props) {
  return props.theme.colors.scale2;
}, function (props) {
  return props.theme.colors.scale2;
}, function (props) {
  return props.theme.colors.scale2;
});
var TooltipUnstyled = css(_templateObject5());
var TooltipWrapper = styled.span(_templateObject6(), function (props) {
  return !props.noHeight && css(_templateObject7());
}, function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.theme.colors.paper;
}, function (props) {
  return props.theme.colors.text;
}, function (props) {
  return props.theme.common.shadow;
}, function (props) {
  return props.theme.colors.paper;
}, function (props) {
  return props.theme.colors.paper;
}, function (props) {
  return props.theme.colors.paper;
}, function (props) {
  return props.theme.colors.paper;
}, function (props) {
  return props.theme.colors.paper;
}, function (props) {
  return props.theme.colors.paper;
}, function (props) {
  return props.tooltipType === TooltipType.Error ? TooltipError : null;
}, function (props) {
  return props.tooltipType === TooltipType.Warning ? TooltipWarning : null;
}, function (props) {
  return props.tooltipType === TooltipType.Info ? TooltipInfo : null;
}, function (props) {
  return props.tooltipType === TooltipType.Success ? TooltipSuccess : null;
}, function (props) {
  return props.tooltipType === TooltipType.Unstyled ? TooltipUnstyled : null;
});
export default withTheme(TooltipWrapper);