"use strict";
/*
 * Created on 3.12.2018
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});

var immutable_1 = require("immutable");
/**
 * Values are used as IDs for states in promotion workflow
 *
 * TODO briefly describe each state with respect to business.
 *
 * @author MSu
 */


var PromotionStateEnum;

(function (PromotionStateEnum) {
  PromotionStateEnum["Concept"] = "CONCEPT";
  PromotionStateEnum["Open"] = "OPEN";
  PromotionStateEnum["Approved"] = "APPROVED";
  PromotionStateEnum["Closed"] = "CLOSED";
  PromotionStateEnum["Finalized"] = "FINALIZED";
  PromotionStateEnum["Active"] = "ACTIVE";
  PromotionStateEnum["Finished"] = "FINISHED";
  PromotionStateEnum["Evaluated"] = "EVALUATED";
  PromotionStateEnum["Canceled"] = "CANCELED";
})(PromotionStateEnum = exports.PromotionStateEnum || (exports.PromotionStateEnum = {})); // noinspection TsLint


var lockableStatesEnumList = immutable_1.List.of(PromotionStateEnum.Concept, PromotionStateEnum.Open, PromotionStateEnum.Approved, PromotionStateEnum.Closed);
exports.lockablePromotionStates = lockableStatesEnumList.map(function (state) {
  return state.toString();
});
/**
 * <p>
 *     There are a few cases in which it is possible to edit promotions for users in the admin role, even in states in which 99 % of
 *     users cannot edit them. In order to distinguish these special states and not add them to all users, there is this list for admin
 *     users.
 * </p>
 */

exports.lockablePromotionStatesExtended = exports.lockablePromotionStates.push(PromotionStateEnum.Finalized.toString());
/**
 * <p>Set of targeted promotion states, in which related promotion should be exported with respect to LOG-6552.</p>
 */

exports.exportablePromotionStates = immutable_1.Set.of(PromotionStateEnum.Open, PromotionStateEnum.Approved, PromotionStateEnum.Closed, PromotionStateEnum.Finalized, PromotionStateEnum.Active, PromotionStateEnum.Finished, PromotionStateEnum.Evaluated, PromotionStateEnum.Canceled);