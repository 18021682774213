"use strict";
/**
 * Created on 07 17 2020
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * <p>Enumerates possible message keys related to entire sellout prices termination module, not just {@link SelloutTermination entity} as
 * name of enumeration could say. Within description of an individual may be suggested severity of an msg.</p>
 *
 * <p><b>Enumerated keys should be directly used for localisation keys. This is the reason that the values of the individual items violate
 * the naming convention.</b></p>
 *
 * @author JMe
 */

var SelloutTerminationMsgKeyEnum;

(function (SelloutTerminationMsgKeyEnum) {
  /**
   * Message key related to situation when the input file has incorrect structure (headers).
   * <br/>
   * Suggested severity: error.
   */
  SelloutTerminationMsgKeyEnum["SourceDataInvalidDataStructure"] = "sellout_prices_termination__source_data_invalid_data_structure";
  /**
   * Message key related to situation when the input file does not contain any data row.
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["SourceDataNoDataRow"] = "sellout_prices_termination__source_data_no_data_row";
  /**
   * Message key related to situation when the input file contains a row which has invalid value of termination date set
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["SourceDataInvalidDate"] = "sellout_prices_termination__source_data_termination_valid_to_invalid";
  /**
   * Message key related to situation when the input file contains a row which has termination date set in the past.
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["SourceDataPValidToInThePast"] = "sellout_prices_termination__source_data_termination_valid_to_in_the_past";
  /**
   * Message key related to situation when some row within the input file misses required attribute(s).
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["SourceDataRequiredAttributeMissing"] = "sellout_prices_termination__source_data_required_attribute_missing";
  /**
   * Message key related to situation when some product from the input file does not exist.
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["SourceDataProductNotExists"] = "sellout_prices_termination__source_data_product_not_exists";
  /**
   * Message key related to situation when some GoS from the input file does not exist.
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["SourceDataGoSNotExists"] = "sellout_prices_termination__source_data_gos_not_exists";
  /**
   * Message key related to situation when some entity from the input file does not exist.
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["SourceDataEntitiesNotExist"] = "sellout_prices_termination__source_data_entities_not_found";
  /**
   * Message key related to situation when the input file contains duplicated product/GoS combinations.
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["SourceDataDuplicatedProductGoSCombinations"] = "sellout_prices_termination__source_data_duplicated_product_gos_combinations";
  /**
   * Message key related to situation when the input file contains two or more GoSes containing the same site for one product.
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["SourceDataProductGoSCollision"] = "sellout_prices_termination__source_data_product_gos_collision";
  /**
   * Message key related to situation when some {@link SelloutTerminationItem items} is not editable (is readonly).
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["ItemsSomeItemNotEditable"] = "sellout_prices_termination__items_some_item_not_editable";
  /**
   * Message key related to situation when none of {@link SelloutTerminationItem items} belong to {@link SelloutTermination}.
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["ItemsNotBoundToAnyTermination"] = "sellout_prices_termination__items_not_bound_to_any_termination";
  /**
   * Message key related to situation when some {@link SelloutTerminationItem items} do not belong to single {@link SelloutTermination}.
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["ItemsNotBoundToSingleTermination"] = "sellout_prices_termination__items_not_bound_to_single_termination";
  /**
   * <p>Message key related to situation when value of {@link SelloutTerminationItem.pValidTo} of specific item is before
   * {@link SelloutTerminationItem.pValidFromFPrice}.</p>
   * <br/>
   * Suggested severity: warning.
   */

  SelloutTerminationMsgKeyEnum["ItemPValidToBeforeValidityStart"] = "sellout_prices_termination__item_p_valid_to_before_validity_start";
  /**
   * <p>Message key related to situation when there is no sellout release item to terminate for product/site specified.</p>
   * <br/>
   * Suggested severity: warning.
   */

  SelloutTerminationMsgKeyEnum["ItemMissingRepriceableItem"] = "sellout_prices_termination__product_site_combination_missing_repriceable_item";
  /**
   * Message key related to situation when value of {@link SelloutTerminationItem.pValidTo} of specific item is in the past.
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["ItemPValidToInThePast"] = "sellout_prices_termination__item_p_valid_to_in_the_past";
  /**
   * <p>Message key related to situation when value of price validity start is equal to validity end of specific release item. <b>This
   * may happen only if the price of an item has been already terminated (termination to the future).</b></p>
   * <br/>
   * ({@link SelloutTerminationItem.pValidFromFPrice} and {@link SelloutTerminationItem.pValidToFPrice})
   * <br/>
   * Suggested severity: error.
   * TODO remove
   */

  SelloutTerminationMsgKeyEnum["ItemPAlreadyTerminated"] = "sellout_prices_termination__item_p_already_terminated";
  /**
   * <p>Message key related to situation when value of {@link SelloutTerminationItem.pValidTo} of specific item is after
   * {@link SelloutTerminationItem.pValidToFPrice}.</p>
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["ItemPValidToAfterValidityEnd"] = "sellout_prices_termination__item_p_valid_to_after_validity_end";
  /**
   * <p>Message key related to situation when value of {@link SelloutTerminationItem.pValidTo} of some item is before
   * {@link SelloutTerminationItem.pValidFromFPrice}.</p>
   * <br/>
   * Suggested severity: warning.
   */

  SelloutTerminationMsgKeyEnum["ItemsSomeItemPValidToBeforeValidityStart"] = "sellout_prices_termination__items_some_item_p_valid_to_before_validity_start";
  /**
   * <p>Message key related to situation when value of {@link SelloutTerminationItem.pValidTo} of some item is after
   * {@link SelloutTerminationItem.pValidToFPrice}.</p>
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["ItemsSomeItemPValidToAfterValidityEnd"] = "sellout_prices_termination__items_some_item_p_valid_to_after_validity_end";
  /**
   * <p>Message key related to situation when value of price validity start is equal to validity end of some release item.
   * <br/>
   * Suggested severity: error.
   *
   * @see {@link SelloutTerminationMsgKeyEnum.ItemPAlreadyTerminated}
   */

  SelloutTerminationMsgKeyEnum["ItemsSomeItemPAlreadyTerminated"] = "sellout_prices_termination__items_some_item_p_already_terminated";
  /**
   * <p>Message key related to situation when some (relevant) value from origin {@link SelloutReleaseItem release item} has been changed
   * for specific item.</p>
   * <br/>
   * May be this will change in the future, this behavior was not part of the assignment.
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["ItemOriginDataChanged"] = "sellout_prices_termination__item_origin_data_changed";
  /**
   * <p>Message key related to situation when some (relevant) value from origin {@link SelloutReleaseItem release item} has been changed
   * for some item(s).</p>
   * <br/>
   * May be this will change in the future, this behavior was not part of the assignment.
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["ItemsSomeItemOriginDataChanged"] = "sellout_prices_termination__items_some_item_origin_data_changed";
  /**
   * <p>Message key related to situation when there is no {@link SelloutReleaseItem release item} to terminate for some product/site.</p>
   * <br/>
   * Suggested severity: warning.
   */

  SelloutTerminationMsgKeyEnum["ItemsSomeItemMissingRepriceableItem"] = "sellout_prices_termination__items_some_product_site_combination_missing_repriceable_item";
  /**
   * Message key related to situation when a {@link SelloutTermination} cannot be cancelled because of invalid state.
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["TerminationCancelInvalidState"] = "sellout_prices_termination__cancel_invalid_state";
  /**
   * Message key related to situation when a {@link SelloutTermination} cannot be confirmed because of invalid state.
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["TerminationConfirmInvalidState"] = "sellout_prices_termination__confirm_invalid_state";
  /**
   * Message key related to situation when termination day of {@link SelloutTerminationItem} is in the past.
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["TerminationValidToInThePast"] = "sellout_prices_termination__termination_valid_to_in_the_past";
  /**
   * Same as {@link TerminationValidToInThePast}, but this is special case for situation when user can no longer edit any termination data
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["TerminationValidToInThePastNoLongerEditable"] = "sellout_prices_termination__termination_valid_to_in_the_past_no_longer_editable";
  /**
   * Message key related to situation when a {@link SelloutTermination} should not be editable but someone/something tries to edit it.
   * <br/>
   * Suggested severity: error.
   */

  SelloutTerminationMsgKeyEnum["TerminationNotEditable"] = "sellout_prices_termination__termination_not_editable";
})(SelloutTerminationMsgKeyEnum = exports.SelloutTerminationMsgKeyEnum || (exports.SelloutTerminationMsgKeyEnum = {}));