/**
 * @file Created on Thu Mar 14 2019
 * @author SKu
 */
import * as React from 'react';
import { CONSTANTS } from '../../../../../constants';
import { N } from '../../../../i18n/N';
import translate from '../../../../i18n/translate';
/**
 * Render bold styled value
 */

export var ReleasePriceRangeRenderer = function ReleasePriceRangeRenderer(_ref) {
  var value = _ref.value;
  return value ? React.createElement(React.Fragment, null, "(", React.createElement(N, {
    value: value.minimum
  }), " - ", value.maximum > 999999 ? translate('INFINITY') : React.createElement(N, {
    value: value.maximum
  }), ")") : React.createElement(React.Fragment, null, CONSTANTS.FORMAT.NULL);
};