/**
 * @file Created on Thu Sep 06 2018
 * @author LZe
 */

import {
  CodebookLookupSequence,
  DataDesc,
  ResearchTypeEnum,
  StructPropBuilder,
  UploadStateEnum,
  UploadTypeEnum,
} from '@logio/common-be-fe';
import {
  ActionsGenerator,
  AgGridStateRenderer,
  ColumnDefinition,
  ColumnGenerator,
  DataUploadStore,
  getPath,
  IconType,
  LoadingState,
  PageStore,
  rootStore,
  StoreName,
  StringMapping,
  translate,
} from '@logio/common-fe';
import {action, computed} from 'mobx';
import {dataUploadStateValuesMap} from '../../../../pages/DataManagement/DataUpload/DataUploadPage';
import {PagePathsEnum} from '../../../../shared/localization/PagePathsEnum';

export class DataUploadPageStore extends PageStore {
  dataUploadStore = rootStore.getStore(StoreName.DataUpload) as DataUploadStore;

  builder = new StructPropBuilder('DataUpload');

  columnsDescription: DataDesc = {
    id: this.builder.str('id'),
    date: this.builder.date('date'),
    type: this.builder.senum('type', UploadTypeEnum, `${CodebookLookupSequence.UploadType}?view=1`),
    researchType: this.builder.opt(
      this.builder.senum('researchType', ResearchTypeEnum, CodebookLookupSequence.ResearchType),
    ),
    researchName: this.builder.opt(this.builder.str('researchName')),
    state: this.builder.senum('state', UploadStateEnum, CodebookLookupSequence.UploadState),
    userName: this.builder.str('userName'),
  };

  actionsGenerator = new ActionsGenerator();
  columnGenerator = new ColumnGenerator(this.columnsDescription);

  get columnDefs(): ColumnDefinition[] {
    return [
      this.actionsGenerator.getColumnDefinition({
        width: 75,
        pinned: false,
        headerCheckboxSelection: false,
        checkboxSelection: false,
        cellClass: 'ag-cell-align-center',
      }),
      ...this.columnGenerator.getColumnDefinitions([
        {field: 'date', sort: 'desc'},
        {field: 'researchName'},
        {field: 'type'},
        {field: 'researchType'},
        {
          field: 'state',
          cellRendererFramework: (props) => AgGridStateRenderer({valuesMap: dataUploadStateValuesMap, ...props}),
        },
        {field: 'userName'},
      ]),
    ];
  }

  @computed
  get rowData(): Array<StringMapping<any>> {
    return Array.from(this.dataUploadStore.list.values()).map((row) => ({
      ...this.actionsGenerator.getColumnData({
        name: translate('View modalData'),
        icon: IconType.view,
        linkProps: {to: getPath(PagePathsEnum.DataUploadDetail, row.id)},
      }),
      ...this.columnGenerator.getColumnData({
        id: row.id,
        date: row.date,
        type: translate(row.type),
        researchType: translate(row.researchType),
        researchName: row.researchName,
        state: row.state,
        userName: row.userName,
      }),
    }));
  }

  /**
   * Fetches all data for the page
   */
  @action
  load = (): void => {
    this.setLoadingState(LoadingState.Pending);
    this.dataUploadStore
      .getAll()
      .then(() => this.setLoadingState(LoadingState.Success))
      .catch((error) => {
        this.setLoadingState(LoadingState.Error);
        // this.messages.setError(error);
      });
  };
}
