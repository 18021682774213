/**
 * @file Created on Tue Apr 09 2019
 * @author SKu
 */

import {
  Overview,
  Pagination,
  PriceFormulas,
  SelloutIdentificationDetailDTO,
  SelloutIdentificationFilter,
  SelloutType,
  Utils,
} from '@logio/common-be-fe';
import {
  ColumnDefinition,
  ColumnGenerator,
  CONSTANTS,
  DownloadStore,
  LoadingState,
  PageStore,
  PriceZoneStore,
  ReportLayer,
  rootStore,
  StoreName,
  StringMapping,
  translate,
  ProductStore,
} from '@logio/common-fe';
import { List } from 'immutable';
import { action, computed, observable, runInAction } from 'mobx';
import { PollingHelper } from '../../../components/PollingHelper';
import { SelloutIdentificationReportDataSource } from './SelloutIdentificationReportDataSource';

export interface SelloutIdentificationReportFilterValues {
  categoryIds: List<string>;
  selloutTypes?: List<SelloutType>;
  priceZoneId: string;
  siteIds?: List<string>;
  productExternalIds?: List<string>;
}

interface ISelloutDtoParams extends SelloutIdentificationReportFilterValues {
  startRow: number;
}

export class SelloutIdentificationReportDetailPageStore extends PageStore {
  public dataSource = new SelloutIdentificationReportDataSource(this);

  /** LOG-4975 Filter loading button state */
  @observable
  public isFilterLoading = false;

  @observable
  public isReportVisible = false;

  @observable
  public isFilterVisible = true;

  public selloutIdentificationDetailDTOs: SelloutIdentificationDetailDTO[];
  public totalCount: number | null;

  filters: SelloutIdentificationReportFilterValues;
  totalRows: number;

  /** Export - loading state of button */
  @observable
  exportIsLoading = false;

  /** Export - download store */
  downloadStore = rootStore.getStore(StoreName.Download) as DownloadStore;

  /**
   * Returns true if request for file is younger than 10 minutes
   */
  @observable
  isProgressMonitorIdValid: boolean = localStorage.getItem(
    `${SelloutIdentificationReportDetailPageStore.name}_progressMonitorValidationDate`,
  )
    ? new Date().getTime() -
    new Date(
      localStorage.getItem(`${SelloutIdentificationReportDetailPageStore.name}_progressMonitorValidationDate`),
    ).getTime() <=
    600000
    : false;
  private reportLayer = new ReportLayer();

  /** Ag-grid services */
  private selloutIdentificationReportDetailGenerator = new ColumnGenerator<StringMapping<any>>(
    SelloutIdentificationDetailDTO.schema,
  );

  private exportFilter: SelloutIdentificationFilter;

  /**
   * Product store for validation of products.
   */
  public productStore = rootStore.getStore(StoreName.Product) as ProductStore;

  /**
   * Price zone store to get national zone id for filter.
   */
  private priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;

  /**
   * Returns generated column definitions for the ag-grid
   */
  public get columnDefs(): ColumnDefinition[] {
    const colDefs: ColumnDefinition[] = Object.keys(SelloutIdentificationDetailDTO.schema)
      .filter((key) => key !== 'productId' && key !== 'siteId' && key !== 'productExternalId')
      .map((key) => ({ field: key, filter: false, sortable: false }));

    const selloutPrice = colDefs.find(colDef => colDef.field === 'selloutPrice');
    selloutPrice.valueFormatter = ({ value }) => (
      value ? PriceFormulas.roundDefault(value).toNumber().toLocaleString(CONSTANTS.FORMAT.NUMBER) : CONSTANTS.FORMAT.NULL
    );

    return this.selloutIdentificationReportDetailGenerator.getColumnDefinitions(colDefs);
  }

  public getRowData(DTOs): Array<StringMapping<any>> {
    const gridData: Array<StringMapping<any>> = [];

    DTOs.map((item) => {
      const rowItem = this.selloutIdentificationReportDetailGenerator.getColumnData(item);
      gridData.push({
        ...rowItem,
        SelloutIdentificationDetailDTO_selloutType: translate(
          `SelloutIdentificationDetailDTO_selloutType_${rowItem.SelloutIdentificationDetailDTO_selloutType}`,
        ),

      });
    });

    return gridData;
  }

  /**
   * Return generated data for the ag-grid
   */
  @computed
  public get rowData(): Array<StringMapping<any>> {
    let gridData: Array<StringMapping<any>> = [];
    if (!Utils.isValueMissing(this.selloutIdentificationDetailDTOs)) {
      gridData = this.getRowData(this.selloutIdentificationDetailDTOs);
    }
    return gridData;
  }

  @computed
  get progressMonitorId() {
    return localStorage.getItem(`${SelloutIdentificationReportDetailPageStore.name}_progressMonitorId`);
  }

  set progressMonitorId(value) {
    localStorage.setItem(`${SelloutIdentificationReportDetailPageStore.name}_progressMonitorId`, value);
    localStorage.setItem(
      `${SelloutIdentificationReportDetailPageStore.name}_progressMonitorValidationDate`,
      new Date().toString(),
    );
  }

  /** Export - filename for download */
  @computed
  get downloadFileName() {
    return localStorage.getItem(`${SelloutIdentificationReportDetailPageStore.name}_downloadFilename`);
  }

  set downloadFileName(value) {
    localStorage.setItem(`${SelloutIdentificationReportDetailPageStore.name}_downloadFilename`, value);
  }

  /**
   * Removes data for export file from locale storage
   */
  removeProgressMonitorDataFromLocalStorage() {
    localStorage.removeItem(`${SelloutIdentificationReportDetailPageStore.name}_progressMonitorId`);
    localStorage.removeItem(`${SelloutIdentificationReportDetailPageStore.name}_downloadFilename`);
    localStorage.removeItem(`${SelloutIdentificationReportDetailPageStore.name}_progressMonitorValidationDate`);
  }

  @action
  public setFilters(values: SelloutIdentificationReportFilterValues, totalRows?: number) {
    this.filters = values;
    this.totalRows = totalRows ? Number(totalRows) : null;
  }

  /** Export - action */
  @action
  public export() {
    try {
      this.reportLayer
        .getSelloutIdentificationReportDetailExport({ filter: this.exportFilter })
        .then((value: any) => {
          if (value.progress.id) {
            this.progressMonitorId = value.progress.id;
            this.downloadFileName = value.filename;
            this.exportPollingHelper.startPolling(
              this.progressMonitorId,
              'SELLOUT-IDENTIFICATION-REPORT-DETAIL-EXPORT-SUCCESS',
            );
          }
        });
    } catch (error) {
      // this.messages.setError(error);
    }
  }

  /** On filter submit */
  @action
  public onFind = async (values: SelloutIdentificationReportFilterValues) => {
    runInAction(() => {
      this.isFilterLoading = true;
      this.isReportVisible = true;
    }); // LOG-4975
    this.setFilters(values);
    this.dataSource.reloadRows();
  };

  /** Fetches data for the page */
  public load = async () => {
    // load price zones (for national zone)
    await this.priceZoneStore.getAll();

    if (!this.filters.priceZoneId) {
      // set national zone as default, if priceZoneId wasn't set by query parameter
      this.setFilters({
        ...this.filters,
        priceZoneId: this.priceZoneStore.nationalZone.id,
      });
    }

    this.setLoadingState(LoadingState.Success);

    if (this.filters.categoryIds && !this.filters.categoryIds.isEmpty()) {
      runInAction(() => {
        this.isReportVisible = true;
      });
    }

    if (this.isProgressMonitorIdValid && this.progressMonitorId && this.downloadFileName) {
      this.exportPollingHelper.startPolling(
        this.progressMonitorId,
        'SELLOUT-IDENTIFICATION-REPORT-DETAIL-EXPORT-SUCCESS',
      );
    }
  };

  public getDTOs = async (params: ISelloutDtoParams) => {
    const selloutIdentificationFilter = new SelloutIdentificationFilter(
      params.selloutTypes || List(),
      params.categoryIds || List(),
      params.priceZoneId,
      params.siteIds || List(),
      params.productExternalIds ? params.productExternalIds.map(id => `${id}:1`) : List(),
      null,
    );

    const pagination = new Pagination(
      CONSTANTS.SELLOUT_IDENTIFICATION_REPORT.PAGE_SIZE,
      params.startRow,
      this.totalRows,
    );
    const exportPagination = new Pagination(this.totalRows, 0, this.totalRows);

    const overview = new Overview<SelloutIdentificationFilter>(selloutIdentificationFilter, null, null, pagination);

    this.exportFilter = selloutIdentificationFilter;

    return this.reportLayer.getSelloutIdentificationReportDetailDTOs(overview);
  };

  /** Fetches DTOs and sets to observable variable */
  public setDTOs = async (params: ISelloutDtoParams) => {
    try {
      const [DTOs, totalCount] = await this.getDTOs(params);
      runInAction(() => {
        this.selloutIdentificationDetailDTOs = DTOs;
        this.totalCount = totalCount;
      });
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  /** Export - preparing file - PollingHelper callback */
  private onExportPollingStateChanged = async (pollingState: LoadingState) => {
    if (pollingState === LoadingState.Pending) {
      if (!this.exportIsLoading) {
        runInAction(() => (this.exportIsLoading = true));
      }
    } else {
      if (pollingState === LoadingState.Success) {
        if (this.downloadFileName) {
          try {
            await this.downloadStore.download(this.downloadFileName);
          } catch (error) {
            this.messages.setError(error);
          }
        } else {
          this.messages.setError(translate('PRICE_ARCHITECTURE_EXPORT_ERROR_NO_FILENAME'));
        }
      }
      runInAction(() => (this.exportIsLoading = false));
      this.removeProgressMonitorDataFromLocalStorage();
    }
  };

  /** Export - preparing file - PollingHelper */
  public exportPollingHelper = new PollingHelper(this.messages, this.onExportPollingStateChanged, 1000);

  @action.bound
  public toggleFilter() {
    this.isFilterVisible = !this.isFilterVisible;
  }
}
