/**
 * @file Created on Thu Jun 27 2019
 * @author SKu
 */

import {DashboardTask, DashboardTaskType} from '@logio/common-be-fe';
import {
  getDateFormat,
  Notice,
  NoticeTitles,
  translate,
  translateResultObject,
  IconType,
  getPath,
} from '@logio/common-fe';
import moment from 'moment';
import * as React from 'react';
import {TaskWidgetStore} from '../../stores/components';
import {PagePathsEnum} from '../../shared/localization/PagePathsEnum';

export interface Props {
  store: TaskWidgetStore;
}

export const TaskWidget: React.SFC<Props> = ({store: {history, tasks}}: Props) => {
  const getDeadlineComponent = ({deadline}: DashboardTask) => (
    <div className={deadline.isBefore(moment(), 'day') ? 'c-error' : undefined}>{deadline.format(getDateFormat())}</div>
  );

  const getSingleNotice = (task: DashboardTask): JSX.Element => {
    let taskIcon: IconType;
    let redirectPath: string;
    switch (task.type) {
      case DashboardTaskType.newProductsConfigurationRequired:
        taskIcon = IconType.data;
        redirectPath = getPath(PagePathsEnum.PriceArchitectureProducts, task.message.entityId);
        break;
      case DashboardTaskType.newRecommendedPairsAvailable:
        taskIcon = IconType.data;
        redirectPath = getPath(PagePathsEnum.DataPairingRecommended, task.message.entityId);
        break;
      case DashboardTaskType.competitorDataCleaningRequired:
        taskIcon = IconType.data;
        redirectPath = getPath(PagePathsEnum.DataCleaningCategories);
        break;
      default:
        throw new Error('Task not supported yet!');
    }

    const historyPush = () => history.push(redirectPath);

    return (
      <Notice
        key={task.id}
        contentSizes={[10, 2]}
        content={[translateResultObject(task.message), getDeadlineComponent(task)]}
        noticeIconType={taskIcon}
        onIconClick={historyPush}
      />
    );
  };

  return (
    <>
      <NoticeTitles titles={[translate('my-tasks'), translate('deadline')]} titleSizes={[10, 2]} />
      <div className="ofa" style={{maxHeight: '310px'}}>
        {tasks.length > 0 ? (
          tasks.map((task) => getSingleNotice(task))
        ) : (
          <Notice
            contentSizes={[12]}
            content={[translate('task-widget-placeholder')]}
            noticeIconType={IconType.approveO}
          />
        )}
      </div>
    </>
  );
};
