"use strict";
/*
 * Created on 10 05 2018
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author Jaroslav Sevcik
 */

var CompetitorReferentialPriceState;

(function (CompetitorReferentialPriceState) {
  CompetitorReferentialPriceState["NEW"] = "NEW";
  CompetitorReferentialPriceState["APPROVED"] = "APPROVED";
  CompetitorReferentialPriceState["OLD"] = "OLD";
})(CompetitorReferentialPriceState = exports.CompetitorReferentialPriceState || (exports.CompetitorReferentialPriceState = {}));