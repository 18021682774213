"use strict";
/*
 * Created on 12 17 2018
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author Jaroslav Sevcik
 */

var FinalPriceDefaultEnum;

(function (FinalPriceDefaultEnum) {
  /*
   * Earlier this enumeration had also value for 'actual regular price'.
   * It was removed according to a client's instruction described in point 3
   * in https://jira.etnetera.cz/browse/LOG-2341?focusedCommentId=255727#comment-255727
   */
  FinalPriceDefaultEnum["OPTIMAL_PRICE_WITH_CONSTRAINT"] = "Optimal price with constraint";
  FinalPriceDefaultEnum["OPTIMAL_PRICE_WITHOUT_CONSTRAINT"] = "Optimal price without constraint";
})(FinalPriceDefaultEnum = exports.FinalPriceDefaultEnum || (exports.FinalPriceDefaultEnum = {}));