/**
 * @file Created on Fri Sep 21 2018
 * @author SKu
 */

import {CodebookLookupSequence, PriceZone, StructPropBuilder} from '@logio/common-be-fe';
import {
  Button,
  ButtonColor,
  Field,
  Form,
  FormGroupInline,
  Modal,
  translate,
  Messages,
  ButtonLoading,
} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';
import {ModalEnum, PriceZonePageStore} from '../../../stores/pages/DataManagement/PriceZones/PriceZonePageStore';

interface Props {
  /** PriceZones Page Store */
  priceZonesPageStore: PriceZonePageStore;
  /**
   * If true render createModal else editModal
   */
  createMode?: boolean;
}

/**
 * Component renders modals for PriceZonePage
 */
export const PriceZonesPageForm: React.SFC<Props> = observer((props) => {
  const getDescriptions = () => {
    const builder = new StructPropBuilder(PriceZone.name);
    return props.createMode
      ? PriceZone.schema
      : {
          ...PriceZone.schema,
          ...{
            groupOfSitesId: builder.lookup('groupOfSitesId', CodebookLookupSequence.GroupOfSites),
          },
        };
  };

  const mode = props.createMode ? ModalEnum.CREATE : ModalEnum.EDIT;
  return (
    <Modal
      title={translate(props.createMode ? 'create-price-zone' : 'edit-price-zone')}
      hidden={props.priceZonesPageStore.modalHidden[`${mode}`]}
      close={props.priceZonesPageStore.getModalToggleEvent(`${mode}`)}
    >
      <Messages />
      <Form
        onSubmit={props.createMode ? props.priceZonesPageStore.onCreateSubmit : props.priceZonesPageStore.onEditSubmit}
        descriptions={getDescriptions()}
        initialValues={props.priceZonesPageStore.initialValues}
      >
        <Field name="name" />
        <Field name="groupOfSitesId" fieldReadOnly={!props.createMode} />
        <Field name="description" />
        <FormGroupInline right={true}>
          <Button
            type="button"
            buttonColor={ButtonColor.Light}
            onClick={props.priceZonesPageStore.getModalToggleEvent(`${mode}`)}
          >
            {translate('Cancel')}
          </Button>
          <ButtonLoading type="submit" isLoading={props.priceZonesPageStore.buttonLoadingState}>
            {translate(`${mode}`)}
          </ButtonLoading>
        </FormGroupInline>
      </Form>
    </Modal>
  );
});
