/**
 * @file Created on Fri Oct 05 2018
 * @author SKu
 */

import {ReleaseAction, Release, ReleaseState} from '@logio/common-be-fe';
import {
  Button,
  ButtonSize,
  getPath,
  Icon,
  IconColor,
  IconType,
  Messages,
  Page,
  translate,
  getExportWorkflowCaseLockHeartbeat,
} from '@logio/common-fe';
import {Observer} from 'mobx-react';
import * as React from 'react';
import {match, RouteComponentProps} from 'react-router';
import {IRouteParams} from '../../../routes-app';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {AbstractReleaseExportPageStore} from '../../../stores/pages/Release/ReleaseExport/AbstractReleaseExportPageStore';
import {Link} from "react-router-dom";

export interface Props {
  match: match<IRouteParams>;
}

/**
 * Release export page
 * Almost the same as category detail, but shows all approved categories
 */
abstract class AbstractReleaseExportPage extends React.Component<
  Props & RouteComponentProps<AbstractReleaseExportPage>
> {
  public abstract store: AbstractReleaseExportPageStore;

  componentWillMount() {
    this.store.load();
  }

  componentWillUnmount() {
    this.store.destroy();
  }

  get releaseHeaderItems() {
    const editMode = this.store.releaseDetailCategoryComponentStore.editMode;

    const unlockComponent = (
      <Icon
        iconType={IconType.unlocked}
        iconColor={IconColor.Text}
        onClick={() => this.store.onAction(ReleaseAction.AbandonExportPreparation)}
      />
    );
    const lockComponent = (this.store.release.state !== ReleaseState.ExportStarted) ? (
      <Icon
        iconType={IconType.locked}
        iconColor={IconColor.Text}
        onClick={() => this.store.onAction(ReleaseAction.PrepareExport)}
      />
    ) : (
      <Icon
        iconType={IconType.locked}
        iconColor={IconColor.Text}
        disabled={true}
      />
    );

    const exportComponent = (
      <Button
        buttonSize={ButtonSize.Tiny}
        onClick={() => this.store.onAction(ReleaseAction.Export)}
        iconRight
        disabled={this.store.release.state === ReleaseState.ExportStarted}
      >
        {translate('export')}
        <Icon
          iconType={IconType.export}
          iconColor={IconColor.Text}
        />
      </Button>
    );

    /** TODO: Add permission control */
    return this.store.releaseDetailCategoryComponentStore.editMode
      ? [unlockComponent, exportComponent]
      : [lockComponent];
  }

  public abstract get releaseHeader(): JSX.Element;

  public abstract get renderContent(): JSX.Element;

  render() {
    const editMode = this.store.releaseDetailCategoryComponentStore.editMode;
    const title = this.store.isLoadingSuccess && `${this.store.releaseHeaderStore.release.name} - Export`;
    const releasePath = getPath(this.store.detailPath, this.props.match.params.id);

    let backButtonPath: string;
    if (this.props.location.pathname.indexOf('/sellout/') > -1) {
      backButtonPath = getPath(PagePathsEnum.ReleasesSellout);
    } else {
      backButtonPath = getPath(PagePathsEnum.ReleasesRegular);
    }

    return (
      <Page
        titleKey={title}
        titleComponent={<Link to={releasePath}>{title}</Link>}
        loadingState={this.store.loadingState}
        showMessages={false}
        backButtonPath={backButtonPath}
        workflowCasesLockHeartbeat={editMode && getExportWorkflowCaseLockHeartbeat(this.store.release)}
      >
        <Observer>
          {() => (
            <>
              {this.releaseHeader}
              <Messages />
              {this.renderContent}
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

export default AbstractReleaseExportPage;
