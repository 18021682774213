/**
 * @file Created on Fri Oct 05 2018
 * @author SKu
 */

import {PricingPermissions} from '@logio/common-be-fe';
import {Button, ButtonSize, FormGroupInline, GroupActions, Page, translate, WithPermission} from '@logio/common-fe';
import {Observer, observer} from 'mobx-react';
import * as React from 'react';
import {match} from 'react-router';
import {SettingsComponent} from '../../components/SettingsOverview/SettingsComponent';
import {IRouteParams} from '../../routes-app';
import {PageNamesEnum} from '../../shared/localization/PageNamesEnum';
import {CopySettingsModalEnum} from '../../stores/components/SettingsOverview/SettingsComponentStore';
import {GeneralSettingsPageStore} from '../../stores/pages/Settings/GeneralSettingsPageStore';

export interface Props {
  match: match<IRouteParams>;
}

/**
 * General settings page
 */
@observer
class GeneralSettingsPage extends React.Component<Props> {
  store = new GeneralSettingsPageStore();

  componentWillMount() {
    this.store.load();
  }

  /**  Returns Buttons that could change Release Matrix Table */
  get matrixTableActions() {
    return (
      <>
        <GroupActions selectedRowsCount={this.store.settingsComponentStore.selectedCategoryIds.length}>
          {({disabled}) => (
            <>
              <Button
                buttonSize={ButtonSize.Tiny}
                disabled={disabled}
                onClick={this.store.settingsComponentStore.getOpenModalEvent(CopySettingsModalEnum.CopyBetweenZones)}
              >
                {translate('copy-between-zones')}
              </Button>
              <Button
                disabled={disabled}
                buttonSize={ButtonSize.Tiny}
                onClick={this.store.settingsComponentStore.getOpenModalEvent(CopySettingsModalEnum.CopyFromCategory)}
              >
                {translate('copy-from-category')}
              </Button>
            </>
          )}
        </GroupActions>
      </>
    );
  }

  render() {
    return (
      <Page titleKey={PageNamesEnum.GeneralSettingsCategories} loadingState={this.store.loadingState}>
        <Observer>
          {() => (
            <>
              <WithPermission permissionTypes={[PricingPermissions.MATRIX_TABLE_SETTINGS_EDIT]}>
                <FormGroupInline right>{this.matrixTableActions}</FormGroupInline>
              </WithPermission>
              <SettingsComponent store={this.store.settingsComponentStore} gridId="Pricing" />
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

export default GeneralSettingsPage;
