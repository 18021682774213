/**
 * @file Created on Fri Aug 17 2018
 * @author VBr
 */

import {
  DataDesc,
  PricingPermissions,
  ProductCategory,
  ProgressMonitorStatus,
  ResourceLockDTO,
  ResourceLockType,
  StructPropBuilder,
  Utils,
} from '@logio/common-be-fe';
import {
  ActionProps,
  ActionsGenerator,
  CategoryCleaningStateStore,
  ColumnDefinition,
  ColumnGenerator,
  GeneralConfigurationsStore,
  getPath,
  Icon,
  IconColor,
  IconType,
  LoadingState,
  PageStore,
  Polling,
  PriceZoneStore,
  ProfileStore,
  ResourceLockLayer,
  rootStore,
  StoreName,
  StringMapping,
  translate,
  ProductStore,
  DownloadStore,
  KeycloakStore,
} from '@logio/common-fe';
import { Map } from 'immutable';
import {GridApi, GridReadyEvent, RowNode, RowSelectedEvent} from 'ag-grid-community';
import {action, computed, observable, runInAction} from 'mobx';
import * as React from 'react';
import {CategoryTraverseHelper} from '../../../components/CategoryTraverseHelper';
import {PagePathsEnum} from '../../../../shared/localization/PagePathsEnum';
import {PollingHelper} from 'stores/components/PollingHelper';

export class DataCleaningCategoriesPageStore extends PageStore {
  /** Get dependant stores */
  private priceZonesStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;
  private categoryCleaningStateStore = rootStore.getStore(StoreName.CleaningState) as CategoryCleaningStateStore;
  private profileStore = rootStore.getStore(StoreName.Profile) as ProfileStore;
  public downloadStore = rootStore.getStore(StoreName.Download) as DownloadStore;
  private resourceLockLayer: ResourceLockLayer = new ResourceLockLayer();
  private generalConfigurationsStore = rootStore.getStore(
    StoreName.GeneralConfigurations,
  ) as GeneralConfigurationsStore;
  private productStore = rootStore.getStore(StoreName.Product) as ProductStore;
  private keycloakStore = rootStore.getStore(StoreName.Keycloak) as KeycloakStore;

  private polling = new Polling();

  private gridApi: GridApi;

  /** Derived columns data description builder */
  private builder = new StructPropBuilder('DataCleaning');

  /** Derived columns data description */
  private description: DataDesc = {
    categoryId: this.builder.str('categoryId'),
    category: this.builder.str('category'),
    hasNewCompetitorPrices: this.builder.bool('hasNewCompetitorPrices'),
    approved: this.builder.bool('approved'),
  };

  /** Data generators */
  private actionsGenerator = new ActionsGenerator();
  private columnGenerator = new ColumnGenerator<StringMapping<any>>(this.description);

  @observable
  selectedCategories: string[] = [];

  /** Categories that are currently locked(here also will be categories that are locked for you) */
  private lockedCategories: Map<string, ResourceLockDTO> = Map<string, ResourceLockDTO>();

  @observable
  progressMonitorStatus: ProgressMonitorStatus;

  @observable
  cleaningInProgress: boolean = false;

  /**
   * Returns true if request for file is younger than 10 minutes
   */
  @observable
  isProgressMonitorIdValid: boolean = localStorage.getItem('dataCleaning-progressMonitorValidationDate')
    ? new Date().getTime() - new Date(localStorage.getItem('dataCleaning-progressMonitorValidationDate')).getTime() <=
      600000
    : false;

  @computed
  get progressMonitorId() {
    return localStorage.getItem('dataCleaning-progressMonitorId');
  }

  set progressMonitorId(value) {
    localStorage.setItem('dataCleaning-progressMonitorId', value);
    localStorage.setItem('dataCleaning-progressMonitorValidationDate', new Date().toString());
  }

  @computed
  get canUserEdit() {
    return this.keycloakStore.userHasPermissions([PricingPermissions.DATA_CLEAN_EDIT]);
  }

  /** Export - filename for download */
  @computed
  get downloadFileName() {
    return localStorage.getItem('dataCleaning-downloadFilename');
  }

  set downloadFileName(value) {
    localStorage.setItem('dataCleaning-downloadFilename', value);
  }

  /**
   * Removes data for export file from locale storage
   */
  removeProgressMonitorDataFromLocalStorage() {
    localStorage.removeItem('dataCleaning-progressMonitorId');
    localStorage.removeItem('dataCleaning-downloadFilename');
    localStorage.removeItem('dataCleaning-progressMonitorValidationDate');
  }

  /** Returns generated column definitions for ag-grid */
  @computed
  public get columnDefs(): ColumnDefinition[] {
    /** Gets array of all zones in column definition format */
    const zones: ColumnDefinition[] = [];
    this.priceZonesStore.listNotArchived.forEach((zone) => {
      zones.push({
        headerName: translate('number_of_outliers', zone.name),
        field: zone.id,
        width: 160,
        cellClass: 'ag-cell-align-center',
        aggFunc: 'sum',
      });
    });

    /** Each generator return column definition from related data description */
    return [
      this.actionsGenerator.getColumnDefinition({
        width: 110,
        suppressMovable: false,
        lockPinned: false,
        lockPosition: false,
      }),
      ...this.columnGenerator.getColumnDefinitions([
        {
          field: 'hasNewCompetitorPrices',
          aggFunc: 'stars', // LOG-2742 - Defined in the DataCleaningCategoriesPage
          cellRendererFramework: ({value}) => (
            <span>{value && <Icon iconType={IconType.newsFull} iconHeight={20} />}</span>
          ),
          cellClass: 'ag-cell-align-center',
        },
        ...zones,
      ]),
    ];
  }

  /** @inheritDocs */
  private getDataPerRow = (category: ProductCategory) => {
    if (category.level < this.generalConfigurationsStore.config.categoryLevel) {
      return {};
    }
    const cleaningState = this.categoryCleaningStateStore.list.get(category.id);

    const numberOfOutlier: StringMapping<number> = {};
    this.priceZonesStore.listNotArchived.forEach((zone) => {
      numberOfOutlier[zone.id] = 0;
      if (cleaningState) {
        const innerSuspiciousCRPCounts = cleaningState.suspiciousCRPCounts.find(
          (suspiciousCRPCount) => suspiciousCRPCount.priceZoneId === zone.id,
        );
        if (innerSuspiciousCRPCounts) {
          numberOfOutlier[zone.id] = innerSuspiciousCRPCounts.suspiciousCRPCount;
        }
      }
    });

    return {
      ...this.columnGenerator.getColumnData({
        categoryId: category.id,
        hasNewCompetitorPrices: cleaningState ? cleaningState.hasNewCompetitorPrices : false,
        ...numberOfOutlier,
      }),
    };
  };

  /**
   * Function returns icons represented by grid data depending on different conditions
   *
   * isCategoryApproved
   * isCategoryCleaning
   * isCategoryLocked
   *
   * @param categoryId {string}
   * @param isCategoryApproved {boolean} - value from cleaningState
   */
  private getActionsPerRow = ({id: categoryId}: ProductCategory): ActionProps[] => {
    /** Lock DTO for current category */
    const lockedItem = this.lockedCategories.get(categoryId);

    /** Actions shows that user can edit category, and redirects to detail onClick */
    const editCategory: ActionProps = {
      name: 'edit',
      icon: IconType.edit,
      linkProps: {to: getPath(PagePathsEnum.DataCleaningProducts, categoryId)},
    };
    /** Actions shows that user can only view category, and redirects to detail onClick */
    const viewCategory: ActionProps = {
      name: 'view',
      icon: IconType.view,
      linkProps: {to: getPath(PagePathsEnum.DataCleaningProducts, categoryId)},
      // props: {className: 'mr1'},
    };
    /** Icon shows if category is locked for current user */
    const lockedByUser: ActionProps = {
      name: 'lockedForMe',
      icon: IconType.locked,
      iconColor: IconColor.Primary,
    };
    /** Icon shows if category is locked for other users */
    const lockedByOthers: ActionProps = {
      name: 'locked',
      icon: IconType.locked,
    };
    /** Icon shows if category is locked because of cleaning */
    const lockedByCleaning: ActionProps = {
      name: 'lockedByCleaning',
      icon: IconType.locked,
      disabled: true,
    };
    /** Icon shows if category has been approved */
    const approved: ActionProps = {
      name: 'approved',
      icon: IconType.approve,
      disabled: true,
    };

    /** If category approved, page should be available only for read only mode */
    const cleanState = this.categoryCleaningStateStore.list.get(categoryId);
    if (cleanState && cleanState.cleaned && !this.cleaningInProgress) {
      return [approved, viewCategory];
    }
    /** If category is cleaning, page should be fully locked */
    if (this.cleaningInProgress) {
      return [lockedByCleaning];
    }
    /** If category has been locked, we should determine if it locks for current user and show relevant icon */
    if (!Utils.isValueMissing(lockedItem) && this.profileStore.profile) {
      const user = lockedItem.users.get(0);
      return user && user.id === this.profileStore.profile.id
        ? [editCategory, lockedByUser]
        : [viewCategory, lockedByOthers];
      // : [viewCategory, {...lockedByOthers, ...{tooltipOverlay: user.name}}];
    }
 
    return [this.canUserEdit ? editCategory : viewCategory];
  };

  /** Store handling ag-grid tree view */
  public CategoryTraverseHelper = new CategoryTraverseHelper(this.getDataPerRow, this.getActionsPerRow);

  traverseSelection = (node: RowNode, select: boolean) => {
    node.childrenAfterFilter.forEach((childNode: RowNode) => {
      if (childNode.selectable) {
        childNode.selectThisNode(select);
      }
      this.traverseSelection(childNode, select);
    });
  };

  @action
  onRowSelected = (event: RowSelectedEvent) => {
    const categoryIds = [];
    this.gridApi.removeEventListener('rowSelected', this.onRowSelected);
    this.traverseSelection(event.node, event.node.isSelected());

    event.api.getSelectedNodes().forEach((node) => {
      if (!node.group) {
        categoryIds.push(node.data[this.description.categoryId.description.nameKey]);
      }
    });
    this.selectedCategories = categoryIds;
    this.gridApi.addEventListener('rowSelected', this.onRowSelected);
  };

  /** Binds ag-grid api */
  @action.bound
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.addEventListener('rowSelected', this.onRowSelected);
  }

  /** Decides whether row is selectable or locked */
  isRowSelectable = (rowNode: RowNode) =>
    !rowNode.data || (rowNode.data.actions && rowNode.data.actions.some((a) => a.name === 'edit'));

  @action.bound
  startCleaning(): void {
    this.categoryCleaningStateStore
      .startCleaning()
      .then(() => {
        runInAction(() => {
          this.cleaningInProgress = true;
        });
        this.polling.startPolling(this.getCleaningProgress);
      })
      .catch((e) => {
        this.messages.setError(e);
      });
  }

  @action.bound
  private getCleaningProgress(): Promise<void> {
    return this.categoryCleaningStateStore
      .getCleaningProgress()
      .then((progressMonitorStatus: ProgressMonitorStatus) => {
        runInAction(() => {
          this.progressMonitorStatus = progressMonitorStatus;
        });
        if (progressMonitorStatus.tag === 'done') {
          runInAction(() => {
            this.cleaningInProgress = false;
          });
          this.polling.stopPolling();
          this.categoryCleaningStateStore.getAll().then(() => {
            this.messages.setSuccess(translate('data_cleaning-ai_cleaning_done'));
            this.categoryCleaningStateStore.getLastCleaning();
          });
        } else if (progressMonitorStatus.tag === 'failed') {
          runInAction(() => {
            this.cleaningInProgress = false;
          });
          this.polling.stopPolling();
          this.messages.setError(translate('data_cleaning-ai_cleaning_failed'));
        }
      });
  }

  @computed
  get cleaningProgress() {
    if (this.progressMonitorStatus && this.progressMonitorStatus.tag === 'working') {
      if (this.progressMonitorStatus.total === 0) {
        return translate('data_cleaning-recent_ai_cleaning_progress', '100');
      }
      return translate(
        'data_cleaning-recent_ai_cleaning_progress',
        Utils.toFixed((this.progressMonitorStatus.worked / this.progressMonitorStatus.total) * 100).toString(),
      );
    }
    return translate(
      'data_cleaning-recent_ai_cleaning_label',
      this.categoryCleaningStateStore.lastCleaning.format('LLL'),
    );
  }

  /** Approves selected categories */
  public onApprove = async () => {
    const {progress} = await this.categoryCleaningStateStore.approveCategories(this.selectedCategories);
    this.pollingHelper.startPolling(progress.id, 'data-cleaning');
  };

  /** Handles polling response */
  private onPollingStateChanged = async (pollingState: LoadingState) => {
    switch (pollingState) {
      case LoadingState.Pending:
        this.gridApi.showLoadingOverlay();
        break;
      case LoadingState.Success:
        await this.categoryCleaningStateStore.getAll();
        this.messages.setSuccess(
          translate('DataCleaning_categoriesApproved', this.selectedCategories.length.toString()),
        );
      default:
        this.gridApi.hideOverlay();
        runInAction(() => (this.selectedCategories = []));
    }
  };

  public pollingHelper = new PollingHelper(this.messages, this.onPollingStateChanged, 2000);

  /** Fetches all data for this page */
  public load = async () => {
    try {
      this.lockedCategories = await this.resourceLockLayer.findLockedResources(ResourceLockType.CleaningCategory);
    } catch (error) {
      this.messages.setError(error);
    }
    try {
      const [cleaningInProgress] = await Promise.all([
        this.categoryCleaningStateStore.getCleaningInProgress(),
        this.CategoryTraverseHelper.load(),
        this.priceZonesStore.getAll(),
        this.categoryCleaningStateStore.getAll(),
        this.categoryCleaningStateStore.getLastCleaning(),
      ]);
      if (cleaningInProgress) {
        runInAction(() => (this.cleaningInProgress = true));
        this.polling.startPolling(this.getCleaningProgress);
      }
      this.createDescription();
      if (this.isProgressMonitorIdValid && this.progressMonitorId && this.downloadFileName) {
        this.exportPollingHelper.startPolling(this.progressMonitorId, 'DATA_CLEANING_EXPORT');
      }
      this.setLoadingState(LoadingState.Success);
    } catch (e) {
      this.messages.setError(e);
    }
  };

  /** Create data description for Ag-grid per each price zone */
  private createDescription = () => {
    this.priceZonesStore.listNotArchived.forEach((zone) => {
      this.description[zone.id] = this.builder.num(zone.id);
    });
    this.columnGenerator = new ColumnGenerator<StringMapping<any>>(this.description);
  };

  /** Export - preparing file - PollingHelper callback */
  private onExportPollingStateChanged = async (pollingState: LoadingState) => {
    if (pollingState === LoadingState.Pending) {
      if (!this.exportIsLoading) {
        runInAction(() => (this.exportIsLoading = true));
      }
    } else {
      if (pollingState === LoadingState.Success) {
        if (this.downloadFileName) {
          try {
            await this.downloadStore.download(this.downloadFileName);
          } catch (error) {
            this.messages.setError(error);
          }
        } else {
          this.messages.setError(translate('PRICE_ARCHITECTURE_EXPORT_ERROR_NO_FILENAME'));
        }
      }
      runInAction(() => (this.exportIsLoading = false));
      this.removeProgressMonitorDataFromLocalStorage();
    }
  };

  /** Export - preparing file - PollingHelper */
  public exportPollingHelper = new PollingHelper(this.messages, this.onExportPollingStateChanged, 1000);

  /** Export - loading state of button */
  @observable
  exportIsLoading = false;

  /** Export - action */
  @action
  public export() {
    try {
      this.productStore.dataCleaningExport().then((value: any) => {
        if (value.progress.id) {
          this.progressMonitorId = value.progress.id;
          this.downloadFileName = value.filename;
          this.exportPollingHelper.startPolling(this.progressMonitorId, 'DATA_CLEANING_EXPORT');
        }
      });
    } catch (error) {
      this.messages.setError(error);
    }
  }
}
