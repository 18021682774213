/**
 * @file Created on Thu Sep 27 2018
 * @author SKu
 */

import {DataPairingStatsDTO, ProductCategory} from '@logio/common-be-fe';
import {
  ActionProps,
  ActionsGenerator,
  ColumnDefinition,
  ColumnGenerator,
  DataPairingStore,
  DownloadStore,
  getPath,
  IconType,
  LoadingState,
  PageStore,
  ProductStore,
  rootStore,
  StoreName,
  translate,
} from '@logio/common-fe';
import {action, computed, observable, runInAction} from 'mobx';
import {CategoryTraverseHelper} from 'stores/components/CategoryTraverseHelper';
import {PagePathsEnum} from '../../../../shared/localization/PagePathsEnum';
import {PollingHelper} from 'stores/components/PollingHelper';

export class DataPairingCategoriesPageStore extends PageStore {
  dataPairingStore = rootStore.getStore(StoreName.DataPairing) as DataPairingStore;
  private productStore = rootStore.getStore(StoreName.Product) as ProductStore;
  downloadStore = rootStore.getStore(StoreName.Download) as DownloadStore;

  /** Data generators */
  actionsGenerator = new ActionsGenerator();
  columnGenerator = new ColumnGenerator<DataPairingStatsDTO>(DataPairingStatsDTO.schema);

  /**
   * Returns true if request for file is younger than 10 minutes
   */
  @observable
  isProgressMonitorIdValid: boolean = localStorage.getItem('dataPairing-progressMonitorValidationDate')
    ? new Date().getTime() - new Date(localStorage.getItem('dataPairing-progressMonitorValidationDate')).getTime() <=
      600000
    : false;

  @computed
  get progressMonitorId() {
    return localStorage.getItem('dataPairing-progressMonitorId');
  }

  set progressMonitorId(value) {
    localStorage.setItem('dataPairing-progressMonitorId', value);
    localStorage.setItem('dataPairing-progressMonitorValidationDate', new Date().toString());
  }

  /** Export - filename for download */
  @computed
  get downloadFileName() {
    return localStorage.getItem('dataPairing-downloadFilename');
  }

  set downloadFileName(value) {
    localStorage.setItem('dataPairing-downloadFilename', value);
  }

  /**
   * Removes data for export file from locale storage
   */
  removeProgressMonitorDataFromLocalStorage() {
    localStorage.removeItem('dataPairing-progressMonitorId');
    localStorage.removeItem('dataPairing-downloadFilename');
    localStorage.removeItem('dataPairing-progressMonitorValidationDate');
  }

  /**
   * Returns generated column definitions for ag-grid
   */
  @computed
  get columnDefs(): ColumnDefinition[] {
    return [
      this.actionsGenerator.getColumnDefinition({
        headerCheckboxSelection: false,
        checkboxSelection: false,
        cellClass: 'ag-cell-align-center',
      }),
      ...this.columnGenerator.getColumnDefinitions([
        {
          field: 'numberOfRecommended',
          aggFunc: 'sum',
        },
        {
          field: 'numberOfUnpaired',
          aggFunc: 'sum',
        },
      ]),
    ];
  }

  /** Checkout {@link CategoryTraverseHelper} constructor for detailed info */
  private getDataPerRow = (category: ProductCategory) => {
    const dto = this.dataPairingStore.stats.get(category.id);
    return dto ? this.columnGenerator.getColumnData(dto) : {};
  };
  private getActionsPerRow = (category: ProductCategory): ActionProps[] => [
    {
      name: 'edit',
      icon: IconType.edit,
      linkProps: {to: getPath(PagePathsEnum.DataPairingRecommended, category.id)},
    },
  ];

  /** Store handling ag-grid tree view */
  public CategoryTraverseHelper = new CategoryTraverseHelper(this.getDataPerRow, this.getActionsPerRow);

  /** Fetches all data for this page */
  async load(): Promise<void> {
    try {
      await Promise.all([this.CategoryTraverseHelper.load(), this.dataPairingStore.getStats()]);
      if (this.isProgressMonitorIdValid && this.progressMonitorId && this.downloadFileName) {
        this.pollingHelper.startPolling(this.progressMonitorId, 'DATA_PAIRING_EXPORT');
      }
      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      // this.messages.setError(error);
    }
  }

  /** Export - preparing file - PollingHelper callback */
  private onExportPollingStateChanged = async (pollingState: LoadingState) => {
    if (pollingState === LoadingState.Pending) {
      if (!this.exportIsLoading) runInAction(() => (this.exportIsLoading = true));
    } else {
      if (pollingState === LoadingState.Success) {
        if (this.downloadFileName) {
          try {
            await this.downloadStore.download(this.downloadFileName);
          } catch (error) {
            // this.messages.setError(error);
          }
        } else {
          this.messages.setError(translate('DATA_PAIRING_EXPORT_ERROR_NO_FILENAME'));
        }
      }
      runInAction(() => (this.exportIsLoading = false));
      this.removeProgressMonitorDataFromLocalStorage();
    }
  };

  /** Export - preparing file - PollingHelper */
  public pollingHelper = new PollingHelper(this.messages, this.onExportPollingStateChanged, 1000);

  /** Export - loading state of button */
  @observable
  exportIsLoading = false;

  /** Export - action */
  @action
  public export() {
    try {
      this.productStore.dataPairingExport().then((value: any) => {
        if (value.progress.id && value.filename) {
          this.progressMonitorId = value.progress.id;
          this.downloadFileName = value.filename;
          this.pollingHelper.startPolling(this.progressMonitorId, 'DATA_PAIRING_EXPORT');
        }
      });
    } catch (error) {
      // this.messages.setError(error);
    }
  }
}
