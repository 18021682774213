/**
 * @file Created on Fri Oct 12 2018
 * @author SKu
 */
import moment from 'moment-timezone';
import * as React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import withValidation from '../../inputs/withValidation';
import DatePickerInputStyled from './styled/DatePickerInputStyled';
import { getDateFormat } from '../../../shared/date/getDateFormat';
import { Utils } from '@logio/common-be-fe';
/**
 * [See DOCS for Date picker](https://react-day-picker.js.org/)
 */

var DatePicker = function DatePicker(props) {
  // Custom onChange to convert Date to Moment
  var onChange = function onChange(day, modifiers, dayPickerInput) {
    /** value from input field */
    var inputValue = dayPickerInput.getInput().value;

    if (props.onChange) {
      !Utils.isValueMissing(day) ? props.onChange(moment(day).startOf('day')) : props.onChange(inputValue);
    }
  };

  var inputProps = {
    onBlur: props.onBlur,
    onFocus: props.onFocus
  };

  if (!Utils.isValueMissing(props.disabled)) {
    inputProps.disabled = props.disabled;
  }

  return React.createElement(DatePickerInputStyled, {
    className: "input-wrapper ".concat(props.className)
  }, React.createElement(DayPickerInput, Object.assign({}, props, {
    // component does not work with default input props,
    // so should be passed separately as property
    inputProps: inputProps,
    dayPickerProps: Object.assign({
      showWeekNumbers: true,
      firstDayOfWeek: 1
    }, props.dayPickerProps),
    onDayChange: onChange,
    formatDate: formatDate,
    parseDate: parseDate,
    placeholder: props.placeholder ? props.placeholder : props.format,
    // converts Moment to formated string
    // because component does not work with Moment
    value: moment.isMoment(props.value) ? props.value.format(props.format) : props.defaultValue ? props.defaultValue : undefined
  })));
};

DatePicker.defaultProps = {
  format: getDateFormat(),
  dayPickerProps: {
    disabledDays: {
      after: new Date(2049, 12)
    }
  }
};
export default DatePicker;
export var DatePickerValidated = withValidation(DatePicker);