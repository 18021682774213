import { Utils } from '@logio/common-be-fe';
import generalComparator from './generalComparator';
/**
 * @file Created on September 2019
 * @author Atreo Digital s.r.o.
 * @description Custom comparator for fields rendered by ReleaseCommentsRenderer
 */

var releaseCommentsComparator = function releaseCommentsComparator(valA, valB) {
  return generalComparator(toComparableValue(valA), toComparableValue(valB));
}; // Comparator compares values by their size of list (number of comments)


var toComparableValue = function toComparableValue(val) {
  return Utils.isValueMissing(val) || !val.hasOwnProperty("size") ? null : val.size;
};

export default releaseCommentsComparator;