"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/*
 * Created on Wed Aug 08 2018
 */

var CodebookLookupSequence_1 = require("../../../core/model/codebook/CodebookLookupSequence");
/**
 * Strategy enums
 *
 * @author RV
 */


exports.STRATEGY_TYPE_LOOKUP = CodebookLookupSequence_1.CodebookLookupSequence.StrategyType;
var StrategyTypeEnum;

(function (StrategyTypeEnum) {
  StrategyTypeEnum["FollowOneSelected"] = "FOLLOW_ONE_SELECTED";
  StrategyTypeEnum["NotCheaperThan"] = "NOT_CHEAPER_THAN";
  StrategyTypeEnum["NotMoreExpensiveThan"] = "NOT_MORE_EXPENSIVE_THAN";
  StrategyTypeEnum["FollowMode"] = "FOLLOW_MODE";
  StrategyTypeEnum["FollowCheapest"] = "FOLLOW_CHEAPEST";
  StrategyTypeEnum["FollowMostExpensive"] = "FOLLOW_MOST_EXPENSIVE";
})(StrategyTypeEnum = exports.StrategyTypeEnum || (exports.StrategyTypeEnum = {}));