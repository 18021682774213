/**
 * @file Created on Fri Oct 12 2018
 * @author SKu
 * @author Jan Prokop
 */

import { ProgressMonitorStatus, ReleaseAction, ReleaseState, UserInfo, Utils } from '@logio/common-be-fe';
import { AbstractReleaseStore, CONSTANTS, getPath, LoadingState, PageStore, Polling, ProfileStore, ProgressMonitorLayer, rootStore, StoreName, translate, WorkflowAssigneeObserverStore } from '@logio/common-fe';
import { List } from 'immutable';
import { History } from 'history';
import { computed, observable, runInAction } from 'mobx';
import { PagePathsEnum } from '../../../../shared/localization/PagePathsEnum';
import { AbstractReleaseDetailCategoryComponentStore, AbstractReleaseHeaderStore } from '../../../components';

export abstract class AbstractReleaseExportPageStore extends PageStore {
  /**
   * @param historyPush - function form React Router
   */
  constructor(public history: History, public releaseId: string) {
    super();
  }

  public abstract releaseStore: AbstractReleaseStore;
  public abstract releaseHeaderStore: AbstractReleaseHeaderStore;
  public abstract releaseDetailCategoryComponentStore: AbstractReleaseDetailCategoryComponentStore;
  public abstract detailPath: string;

  public profileStore = rootStore.getStore(StoreName.Profile) as ProfileStore;

  /** Current release */
  @computed
  public get release() {
    return this.releaseStore.release;
  }

  /** Percentage of uploading */
  @observable
  public progress: number = 0;

  // Polling maintain
  protected polling = new Polling(2000);
  protected progressMonitorLayer: ProgressMonitorLayer = new ProgressMonitorLayer();

  /** Flag which should guarantee, that prepare export action is called just once */
  protected isPrepareExportDone: boolean = false;

  protected exportEditingAssigneeObserver = new WorkflowAssigneeObserverStore(
    CONSTANTS.WORKFLOW_ASSIGNEE_UPDATE_INTERVAL_IN_MS,
    this.handleAssigneeChange.bind(this),
  );

  /** Returns ids of categories that have been approved */
  protected get approvedCategoryIds(): string[] {
    return this.release
      .approvedReleaseParts()
      .map((rP) => rP.productCategoryId)
      .toArray();
  }

  /**
   * Function for using Release action endpoint
   * @param action{ReleaseAction} - action type
   */
  public onAction = async (action: ReleaseAction, categoryIds?: string[]): Promise<boolean> => {
    const actionData = {
      releaseId: this.releaseId,
      action,
      allCategories: categoryIds ? false : true,
      categoryIds: categoryIds ? categoryIds : [],
    };
    try {
      const actionResponse = await this.releaseStore.action(actionData);
      if (actionResponse.successAll) {
        this.handleActionSuccess(action);
        return true;
      } else {
        this.handleActionFail(actionResponse, action);
      }
    } catch (error) {
      // this.messages.setError(error);
    }
    return false;
  };

  /**
   * Used if all requested actions was successful
   * Depending on ReleaseAction type executes different operations
   * @param action
   */
  protected handleActionSuccess = async (action: ReleaseAction) => {
    switch (action) {
      case ReleaseAction.PrepareExport:
        this.isPrepareExportDone = true;
        this.load();
        break;
      case ReleaseAction.Export:
        this.destroyAssigneeObserver();
        await this.releaseStore.getOne(this.releaseId);
        this.handleExportPolling();
        break;
      default:
        this.history.push(getPath(this.detailPath, this.releaseId));
        break;
    }

    this.messages.setSuccess(translate('release-export-action-success', translate(`${action}-action`)));
  };

  /**
   * Used if at least one of requested actions failed
   * Sets info-errors
   * @param actionResponse  data from BE
   */
  protected handleActionFail = (actionResponse: any, action: ReleaseAction) => {
    actionResponse.results.map((result) => {
      const msgArgs = [translate(`${actionResponse.action}-action`)];

      if(result.errorCode === undefined) {
        this.messages.setWarning(translate('action-error-undefined', ...msgArgs));

      } else {
        msgArgs.push(translate(`${result.errorCode}-${result.errorSubcode}`));

        if(result.conflictingUser) {
          msgArgs.push(result.conflictingUser.name);
          if (action === ReleaseAction.PrepareExport) {
            this.messages.setWarning(translate('export-editing-locked-error', ...msgArgs));
          } else {
            this.messages.setWarning(translate('user-action-error', ...msgArgs));
          }
        } else {
          this.messages.setWarning(translate('action-error', ...msgArgs));
        }
      }
    });
  };

  /** Fletches data for the page */
  public async load() {
    try {
      // During load disable editing
      this.releaseDetailCategoryComponentStore.setEditMode(false);

      // Set loading state to Pending
      this.setLoadingState(LoadingState.Pending);

      // Load current release object into `this.release`
      await this.releaseStore.getOne(this.releaseId);

      switch (this.release.state) {
        case ReleaseState.ExportChecking:
          this.initExportPolling();
          this.setLoadingState(LoadingState.Success);
          return;
        case ReleaseState.ExportStarted:
        case ReleaseState.Released:
          // In these state editing isn't possible - show warning
          this.messages.setWarning(translate('export-release-read-only', this.release.state));
          break;
        default: {
          if (this.approvedCategoryIds.length === 0) {
            // when there is no approved category, don't go into prepare export state
            this.messages.setWarning(translate('export-release-read-only-no-approved'));
          } else {
            if(this.isPrepareExportDone) {
              // successfully moved into prepare export state
              this.releaseDetailCategoryComponentStore.setEditMode(true);
              this.initAssigneeObserver();
            } else {
              const success = await this.onAction(ReleaseAction.PrepareExport, this.approvedCategoryIds);
              if (success) {
                // action was successful and it will call `this.load` again
                return;
              } else {
                this.initAssigneeObserver();
              }
            }
          }
        }
      }
      
      // load items for main table
      await this.releaseDetailCategoryComponentStore.load();

      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      // this.messages.setError(error);
      this.setLoadingState(LoadingState.Error);
    }
  }

  /**
   * Cleans data of the store, which should be cleaned. Should be called in componentWillUnmount in component, which called `load()`
   */
  public destroy() {
    if (this.polling.isPolling) {
      this.polling.stopPolling();
    }
    this.destroyAssigneeObserver();
    this.releaseDetailCategoryComponentStore.destroy();
  }

  private initExportPolling() {
    // not sure, why setTimeout
    setTimeout(() => {
      this.handleExportPolling();
    }, 1000);
  }

  /**
   * Handle recalculation polling
   */
  protected handleExportPolling = () => {
    this.polling.startPolling(
      () =>
        this.progressMonitorLayer
          .status(this.release.exportProgressMonitorId)
          .then((response: ProgressMonitorStatus) => response)
          .catch((error) => {
            this.polling.stopPolling();
            // this.messages.setError(error);
          }),
      this.onPollResponse,
    );
  };

  /**
   * Function handle polling monitoring
   *
   * @param errors
   * @param response
   */
  protected onPollResponse = (errors?, response?: ProgressMonitorStatus) => {
    if (!Utils.isValueMissing(response)) {
      if (response.tag === 'new') {
        runInAction(() => (this.progress = 0));
      }
      if (response.tag === 'working') {
        runInAction(() => (this.progress = Math.round((response.worked * 100) / response.total)));
      }
      if (response.tag === 'done') {
        this.polling.stopPolling();
        runInAction(() => (this.progress = 100));
        this.setLoadingState(LoadingState.Pending);
        this.messages.setInfo(translate('release-export-preparation-success'));
        this.load();
      }
    }
  };

  private initAssigneeObserver() {
    const wfCaseId = this.release.exportWorkflowCaseId;
    if (wfCaseId) {
      this.exportEditingAssigneeObserver.init(List.of(wfCaseId));
    }
  }

  private destroyAssigneeObserver() {
    this.exportEditingAssigneeObserver.destroy();
  }

  private handleAssigneeChange(previousAssignees: Map<string, UserInfo | null>) {
    const previousAssignee = previousAssignees.get(this.release.exportWorkflowCaseId);

    if (previousAssignee != null && previousAssignee.id === this.profileStore.profile.id) {
      // if current user unlocked himself (or time limit exceeded) go to detail page
      this.history.push(getPath(this.detailPath, this.releaseId));
    } else {
      // reload store
      this.load();
    }
  }
}
