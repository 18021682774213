/**
 * @file Created on Wed Aug 08 2018
 * @author BPo
 * @author PKl
 * @author SKu
 */
var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import * as React from 'react';
import { showIf } from '../../../shared/rendering/showIf';
import Icon, { IconColor } from '../../media/Icon';
import StyledLabel from '../../typography/Label';
import withValidation from '../withValidation';
import CheckBoxButtonStyled from './styled/CheckBoxButtonStyled';
import CheckBoxButtonWrapper from './styled/CheckBoxButtonWrapper';
import StyledCheckBox, { StyledRequired } from './styled/CheckBoxStyled';
import CheckBoxWrapper from './styled/CheckBoxWrapper';
/**
 * CheckBox component render CheckBox or CheckBoxButton if flag typeButton is set
 */

var CheckBox = function CheckBox(props) {
  var ref = props.ref,
      rest = __rest(props, ["ref"]);

  return props.typeButton ? React.createElement(CheckBoxButton, Object.assign({}, props)) : React.createElement(CheckBoxWrapper, {
    className: "checkbox ".concat(props.className)
  }, React.createElement(StyledCheckBox, Object.assign({}, rest, {
    type: "checkbox",
    id: props.name
  })), React.createElement(StyledLabel, {
    htmlFor: props.name
  }, React.createElement(StyledRequired, null, props.label, "\xA0", props.required && '*')));
};

var CheckBoxButton = function CheckBoxButton(props) {
  var ref = props.ref,
      rest = __rest(props, ["ref"]);

  return React.createElement(CheckBoxButtonWrapper, {
    className: "checkbox-button"
  }, React.createElement(CheckBoxButtonStyled, Object.assign({}, rest, {
    type: "checkbox",
    id: props.name
  })), React.createElement(StyledLabel, {
    htmlFor: props.name
  }, props.label, " ", props.required && '*', showIf(props.checkBoxButtonIconType, React.createElement("span", null, React.createElement(Icon, {
    iconType: props.checkBoxButtonIconType,
    iconHeight: 20,
    iconColor: IconColor.Text
  }), "\xA0")), props.description && React.createElement("div", null, props.description)));
};

export default CheckBox;
export var CheckBoxValidated = withValidation(CheckBox);