/**
 * @file Created on Tue Aug 14 2018
 * @author PKl
 * @author SKu
 */
var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import * as React from 'react';
import { showIf } from '../../../shared/rendering/showIf';
import Icon, { IconColor } from '../../media/Icon';
import StyledLabel from '../../typography/Label';
import RadioButtonStyled from './styled/RadioButtonStyled';
import RadioButtonWrapper from './styled/RadioButtonWrapper';
import RadioStyled from './styled/RadioStyled';
import RadioWrapper from './styled/RadioWrapper';

var Radio = function Radio(props) {
  var ref = props.ref,
      rest = __rest(props, ["ref"]);

  return props.typeButton ? React.createElement(RadioButton, Object.assign({}, props)) : React.createElement(RadioWrapper, null, React.createElement(RadioStyled, Object.assign({}, rest, {
    type: "radio"
  })), props.label && React.createElement(StyledLabel, {
    htmlFor: props.id
  }, props.label, " ", props.required && '*'));
};

var RadioButton = function RadioButton(props) {
  var ref = props.ref,
      rest = __rest(props, ["ref"]);

  return React.createElement(RadioButtonWrapper, {
    className: "radio-button"
  }, React.createElement(RadioButtonStyled, Object.assign({}, rest, {
    type: "radio"
  })), React.createElement(StyledLabel, {
    htmlFor: props.id
  }, props.label, " ", props.required && '*', showIf(props.radioButtonIconType, React.createElement("span", null, React.createElement(Icon, {
    iconType: props.radioButtonIconType,
    iconHeight: 20,
    iconColor: IconColor.Text
  }), "\xA0")), props.description && React.createElement("div", null, props.description)));
};

export default Radio;