import { observer } from 'mobx-react';
import { FlagSet, PriceZone, ProductFlag, Utils } from '@logio/common-be-fe';
import { FlagsRenderer, IndexRenderer, N, translate } from '@logio/common-fe';
import * as React from 'react';
import { PriceZoneProductPrice } from '@logio/common-be-fe';
import { List } from 'immutable';
import { BigDecimal } from '@logio/big-decimal';

/** Values from InternalProductPricesDTO */
export interface IRelatedReportsProductInfo {
  /** Product name */
  name: string;
  /** External id */
  externalId: string;
  /** purchasePrice */
  purchasePrice: BigDecimal;
  openPurchasePrice: BigDecimal | null;
  /** Flags from product */
  flags: FlagSet<ProductFlag>;
  prices: List<PriceZoneProductPrice>;
}

/**
 * Product info default table
 * renders table with constant product data
 */
export const RelatedReportsProductInfo: React.FC<{productInfo: IRelatedReportsProductInfo, priceZones: Map<string, PriceZone>}> = observer(
  ({productInfo: {name, externalId, purchasePrice, flags, prices}, priceZones}) => {
    function getHeaders() {
      return (
        <>
          <th>{translate(`product-info-table-name`)}</th>
          <th>{translate(`product-info-table-externalId`)}</th>
          <th>{translate(`product-info-table-purchasePrice`)} </th>
          <th>{translate(`product-info-table-flags`)}</th>
          {prices.toArray().map(({priceZoneId}) => (
            <React.Fragment key={priceZoneId}>
              {/* FIXME: SKu add priceZone name instaead of id */}
              <th>{translate(`product-info-table-actualRegularPrice`, priceZones.get(priceZoneId).name)}</th>
              <th>{translate(`product-info-table-actualMarginPrice`, priceZones.get(priceZoneId).name)}</th>
              <th>{translate(`product-info-table-actualMarginPercentage`, priceZones.get(priceZoneId).name)}</th>
            </React.Fragment>
          ))}
        </>
      );
    }

    function getValues() {
      return (
        <>
          <td>{name}</td>
          <td>{Utils.getCustomerId(externalId)}</td>
          <td className="tar">
            <N value={purchasePrice} round />
          </td>
          <td className="tac">
            <FlagsRenderer flagSet={flags} />
          </td>
          {prices.toArray().map(({actualRegularPrice, actualMarginPercentage, actualMarginPrice, priceZoneId}) => (
            <React.Fragment key={priceZoneId}>
              <td className="tar">
                <N value={actualRegularPrice} round />
              </td>
              <td className="tar">
                <N value={actualMarginPrice} round />
              </td>
              <td className="tar">
                <IndexRenderer bigNumberValue={actualMarginPercentage} iconHeight={15} />
              </td>
            </React.Fragment>
          ))}
        </>
      );
    }

    return (
      <table className="table table-stripedX w-100">
        <thead>
          <tr>{getHeaders()}</tr>
        </thead>
        <tbody>
          <tr>{getValues()}</tr>
        </tbody>
      </table>
    );
  },
);
