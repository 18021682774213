/**
 * @file Created on Fri Oct 12 2018
 * @author SKu
 */

import { BigDecimal } from '@logio/big-decimal';
import {
  CompanyVsCRPChangeIndex,
  CompetitorReferentialPrice,
  DataDesc,
  DateParser,
  FinalPriceType,
  FinalProductPrice,
  OptimizationGoalsSettings,
  PriceChangeIndex,
  PriceComment,
  PriceZone,
  PriceZoneReleasePrices,
  PricingPermissions,
  ProductFlag,
  Range,
  ReferencePrice,
  ReleaseState,
  StructPropBuilder,
  UserMessage,
  ValidationResultWrapper,
} from '@logio/common-be-fe';
import {
  arrayToMap,
  ColumnDefinition,
  ColumnGenerator,
  Comparators,
  CompetitorSiteStore,
  CompetitorStore,
  CONSTANTS,
  DataUploadStore,
  EditorNames,
  FilterNames,
  FormElOption,
  GlobalSettingsStore,
  Key,
  KeycloakStore,
  PriceZoneStore,
  ReleaseStore,
  RendererNames,
  rootStore,
  StoreName,
  StringMapping,
  TooltipNames,
  translate,
  withPermission,
} from '@logio/common-fe';
import {List} from 'immutable';
import {RowNode} from 'ag-grid-community';
import {computed} from 'mobx';
import {Moment} from 'moment';

// In the past price codes could be edited, then it was changed.
// Editing is for now conditioned by this variable - this could be moved into some configuration / API.
const PRICE_CODE_EDITABLE = false;

export class ReleaseDetailCategoryComponentAgGridServices {

  // Stores

  public releaseStore = rootStore.getStore(StoreName.Release) as ReleaseStore;
  public keycloakStore = rootStore.getStore(StoreName.Keycloak) as KeycloakStore;
  public dataUploadStore = rootStore.getStore(StoreName.DataUpload) as DataUploadStore;
  public competitorSiteStore = rootStore.getStore(StoreName.CompetitorSite) as CompetitorSiteStore;
  /** PriceCodes for release */
  public priceCodes: FormElOption[];
  /** ag-grid generators */
  public generators: {
    /** Ag-Grid totalOverviewGenerator (for top ag-grid) */
    totalOverviewGenerator: ColumnGenerator<StringMapping<any>>;
    /** Ag-Grid itemOverviewGenerators (for bottom ag-grid) */
    itemOverviewGenerator: ColumnGenerator<StringMapping<any>>;
    itemOverviewZoneGenerator: ColumnGenerator<StringMapping<any>>;
  };
  /** Common builder for all grids */
  public builder = new StructPropBuilder('ReleaseDetailCategory');

  // Data
  /** Description for  Total overview */
  public totalOverViewDesc: DataDesc = {
    name: this.builder.str('name'),
    priceChange: this.builder.bigNum('priceChange'),
    priceSticker: this.builder.bigNum('priceSticker'),
    marginConstraint: this.builder.obj(
      'marginConstraint',
      Range.schemaBigNumber,
      (mat) => new Range(mat.maximum, mat.minimum),
    ),
    optimizationGoal: this.builder.obj(
      'optimizationGoal',
      OptimizationGoalsSettings.schema,
      (mat) => new OptimizationGoalsSettings(mat.optimalizationGoal),
    ),
    marginOld: this.builder.bigNum('marginOld'),
    marginOldPercent: this.builder.bigNum('marginOldPercent'),
    marginNew: this.builder.bigNum('marginNew'),
    marginNewPercent: this.builder.bigNum('marginNewPercent'),
    marginImpact: this.builder.bigNum('marginImpact'),
    marginImpactPercent: this.builder.bigNum('marginImpactPercent'),
    salesVolumeOld: this.builder.bigNum('salesVolumeOld'),
    salesVolumeNew: this.builder.bigNum('salesVolumeNew'),
    salesVolumeImpact: this.builder.bigNum('salesVolumeImpact'),
    salesVolumeImpactPercent: this.builder.bigNum('salesVolumeImpactPercent'),
    repricingImportanceWithConstraints: this.builder.bigNum('repricingImportanceWithConstraints'),
    repricingImportanceWithoutConstraints: this.builder.bigNum('repricingImportanceWithoutConstraints'),
    revenueOld: this.builder.bigNum('revenueOld'),
    revenueNew: this.builder.bigNum('revenueNew'),
    revenueImpact: this.builder.bigNum('revenueImpact'),
    revenueImpactPercent: this.builder.bigNum('revenueImpactPercent'),
    bmImpact: this.builder.bigNum('bmImpact'),
    realImpact_bm: this.builder.bigNum('realImpact_bm'),
    realImpact_bmPercent: this.builder.bigNum('realImpact_bmPercent'),
    realImpact_bmImpact: this.builder.bigNum('realImpact_bmImpact'),
    realImpact_bmImpactInPercent: this.builder.bigNum('realImpact_bmImpactInPercent'),
    realImpact_saleValueWithoutVat: this.builder.bigNum('realImpact_saleValueWithoutVat'),
    realImpact_saleValueWithVat: this.builder.bigNum('realImpact_saleValueWithVat'),
    realImpact_revenueImpact: this.builder.bigNum('realImpact_revenueImpact'),
    realImpact_amount: this.builder.bigNum('realImpact_amount'),
    realImpact_salesVolumeImpact: this.builder.bigNum('realImpact_salesVolumeImpact'),
    realImpact_diffDays: this.builder.bigNum('realImpact_diffDays'),
  };
  /** Description for common columns of Item overview */
  public itemOverviewDesc: DataDesc = {
    id: this.builder.str('id'),
    categoryId: this.builder.str('categoryId'),
    productId: this.builder.str('productId'),
    supplierId: this.builder.str('supplierId'),
    supplier: this.builder.str('supplier'),
    productName: this.builder.str('productName'),
    isAllExported: this.builder.bool('isAllExported'),
    messages: this.builder.listOf(
      this.builder.obj('messages', UserMessage.schema, mat => UserMessage.fromDataDict(mat)),
    ),
    ydg_messages: this.builder.listOf(
      this.builder.obj('ydg_messages', UserMessage.schema, mat => UserMessage.fromDataDict(mat)),
    ),
    goldId: this.builder.str('goldId'),
    name4BOX: this.builder.str('name4BOX'),
    categoryPlan: this.builder.str('categoryPlan'),
    flags: this.builder.flags('flags', ProductFlag),
    productSensitivity: this.builder.str('productSensitivity'),
    family: this.builder.str('family'),
    tier: this.builder.str('tier'),
    exported: this.builder.bool('exported'),
    purchasePrice: this.builder.bigNum('purchasePrice'),
    openPurchasePrice: this.builder.bigNum('openPurchasePrice'),
    regularPrice: this.builder.bigNum('regularPrice'),
    readonly: this.builder.bool('readonly'),
    realImpact_forecastObsolete: this.builder.bool('realImpact_forecastObsolete'),
    realImpact_bm: this.builder.bigNum('realImpact_bm'),
    realImpact_bmPercent: this.builder.bigNum('realImpact_bmPercent'),
    realImpact_bmImpact: this.builder.bigNum('realImpact_bmImpact'),
    realImpact_bmImpactInPercent: this.builder.bigNum('realImpact_bmImpactInPercent'),
    realImpact_saleValueWithoutVat: this.builder.bigNum('realImpact_saleValueWithoutVat'),
    realImpact_saleValueWithVat: this.builder.bigNum('realImpact_saleValueWithVat'),
    realImpact_revenueImpact: this.builder.bigNum('realImpact_revenueImpact'),
    realImpact_amount: this.builder.bigNum('realImpact_amount'),
    realImpact_salesVolumeImpact: this.builder.bigNum('realImpact_salesVolumeImpact'),
    realImpact_diffDays: this.builder.bigNum('realImpact_diffDays'),
  };
  private competitorStore = rootStore.getStore(StoreName.Competitor) as CompetitorStore;
  private priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;
  private globalSettingsStore = rootStore.getStore(StoreName.GlobalSettings) as GlobalSettingsStore;

  // Computed

  @computed
  public get isReleased() {
    return this.release.state === ReleaseState.Released;
  }

  /** Release destructuring */
  @computed
  private get release() {
    return this.releaseStore.release;
  }

  /**
   * Description for ItemOverview grid per priceZone
   */
  @computed
  private get itemOverviewZoneDesc(): DataDesc {
    let desc: DataDesc = {};
    this.priceZoneStore.list.forEach(({id}) => {
      desc[`${id}_priceCode`] = this.builder.str(`${id}_priceCode`);
      desc[`${id}_ydg_messages`] = this.builder.obj(`${id}_ydg_messages`, ValidationResultWrapper.schema, mat => {
        return new ValidationResultWrapper(mat.resultObjects);
      });
      // desc[`${id}_bmImpact`] = this.builder.bigNum(`${id}_bmImpact`);
      desc[`${id}_marginOld`] = this.builder.bigNum(`${id}_marginOld`);
      desc[`${id}_marginOldPercent`] = this.builder.bigNum(`${id}_marginOldPercent`);
      desc[`${id}_marginNew`] = this.builder.bigNum(`${id}_marginNew`);
      desc[`${id}_marginNewPercent`] = this.builder.bigNum(`${id}_marginNewPercent`);
      desc[`${id}_marginImpact`] = this.builder.bigNum(`${id}_marginImpact`);
      desc[`${id}_marginImpactPercent`] = this.builder.bigNum(`${id}_marginImpactPercent`);
      desc[`${id}_referencePrice`] = this.builder.obj(`${id}_referencePrice`, ReferencePrice.schema, (mat) => ReferencePrice.fromDataDict(mat));
      desc[`${id}_referencePriceOld`] = this.builder.bigNum(`${id}_referencePriceOld`);
      desc[`${id}_priceRangeWithConstraints`] = this.builder.obj(`${id}_priceRangeWithConstraints`, Range.schemaBigNumber, (mat) => new Range(mat.maximum, mat.minimum));
      desc[`${id}_priceRangeWithoutConstraints`] = this.builder.obj(`${id}_priceRangeWithoutConstraints`, Range.schemaBigNumber, (mat) => new Range(mat.maximum, mat.minimum));
      desc[`${id}_referencePriceChangeIndexPercent`] = this.builder.obj(`${id}_referencePriceChangeIndexPercent`, PriceChangeIndex.schema, (mat) => PriceChangeIndex.fromDataDict(mat));
      desc[`${id}_regularPriceChangeIndexPercent`] = this.builder.obj(`${id}_regularPriceChangeIndexPercent`, PriceChangeIndex.schema, (mat) => PriceChangeIndex.fromDataDict(mat));
      desc[`${id}_companyVsReferencePriceChangeIndexPercent`] = this.builder.obj(`${id}_companyVsReferencePriceChangeIndexPercent`, PriceChangeIndex.schema, (mat) => PriceChangeIndex.fromDataDict(mat));
      desc[`${id}_priceElasticity`] = this.builder.bigNum(`${id}_priceElasticity`);
      desc[`${id}_edlp`] = this.builder.bigNum(`${id}_edlp`);
      desc[`${id}_${FinalPriceType.Regular}`] = this.builder.bigNum(`${id}_${FinalPriceType.Regular}`);
      desc[`${id}_${FinalPriceType.OptimalWithConstraints}`] = this.builder.bigNum(`${id}_${FinalPriceType.OptimalWithConstraints}`);
      desc[`${id}_${FinalPriceType.OptimalWithoutConstraints}`] = this.builder.bigNum(`${id}_${FinalPriceType.OptimalWithoutConstraints}`);
      desc[`${id}_finalPrice`] = this.builder.obj(`${id}_finalPrice`, FinalProductPrice.schema, (mat) => FinalProductPrice.fromDataDict(mat));
      desc[`${id}_excel_finalPrice_type`] = this.builder.obj(`${id}_excel_finalPrice_type`, FinalProductPrice.schema, (mat) => FinalProductPrice.fromDataDict(mat));
      desc[`${id}_excel_finalPrice`] = this.builder.obj(`${id}_excel_finalPrice`, FinalProductPrice.schema, (mat) => FinalProductPrice.fromDataDict(mat));
      desc[`${id}_possibleFrom`] = this.builder.date(`${id}_possibleFrom`, DateParser.ISO_PATTERN);
      desc[`${id}_validFromFP`] = this.builder.date(`${id}_validFromFP`, DateParser.ISO_PATTERN);
      desc[`${id}_validToFP`] = this.builder.date(`${id}_validToFP`, DateParser.ISO_PATTERN);
      desc[`${id}_comments`] = this.builder.listOf(this.builder.obj(`${id}_comments`, PriceComment.schema, (mat) => {
        return new PriceComment(mat.id, mat.releaseId, mat.priceZoneId, mat.userId, mat.username, mat.productId, mat.text, mat.persistent, mat.deleteOn);
      }));
      desc[`${id}_promoPriceStats`] = this.builder.str(`${id}_promoPriceStats`);
      desc[`${id}_salesVolumeOld`] = this.builder.bigNum(`${id}_salesVolumeOld`);
      desc[`${id}_salesVolumeNew`] = this.builder.bigNum(`${id}_salesVolumeNew`);
      desc[`${id}_salesVolumeImpact`] = this.builder.bigNum(`${id}_salesVolumeImpact`);
      desc[`${id}_profitOld`] = this.builder.bigNum(`${id}_profitOld`);
      desc[`${id}_profitOldPercent`] = this.builder.bigNum(`${id}_profitOldPercent`);
      desc[`${id}_profitNew`] = this.builder.bigNum(`${id}_profitNew`);
      desc[`${id}_profitNewPercent`] = this.builder.bigNum(`${id}_profitNewPercent`);
      desc[`${id}_profitImpact`] = this.builder.bigNum(`${id}_profitImpact`);
      desc[`${id}_profitImpactPercent`] = this.builder.bigNum(`${id}_profitImpactPercent`);
      desc[`${id}_revenueOld`] = this.builder.bigNum(`${id}_revenueOld`);
      desc[`${id}_revenueNew`] = this.builder.bigNum(`${id}_revenueNew`);
      desc[`${id}_revenueImpact`] = this.builder.bigNum(`${id}_revenueImpact`);
      desc[`${id}_revenueImpactPercent`] = this.builder.bigNum(`${id}_revenueImpactPercent`);
      desc[`${id}_repricingImportanceWithConstraints`] = this.builder.bigNum(`${id}_repricingImportanceWithConstraints`);
      desc[`${id}_repricingImportanceWithoutConstraints`] = this.builder.bigNum(`${id}_repricingImportanceWithoutConstraints`);
      desc[`${id}_realImpact_profit`] = this.builder.bigNum(`${id}_realImpact_profit`);
      desc[`${id}_realImpact_profitPercent`] = this.builder.bigNum(`${id}_realImpact_profitPercent`);
      desc[`${id}_realImpact_profitImpact`] = this.builder.bigNum(`${id}_realImpact_profitImpact`);
      desc[`${id}_realImpact_profitImpactInPercent`] = this.builder.bigNum(`${id}_realImpact_profitImpactInPercent`);
      desc[`${id}_realImpact_bm`] = this.builder.bigNum(`${id}_realImpact_bm`);
      desc[`${id}_realImpact_bmPercent`] = this.builder.bigNum(`${id}_realImpact_bmPercent`);
      desc[`${id}_realImpact_bmImpact`] = this.builder.bigNum(`${id}_realImpact_bmImpact`);
      desc[`${id}_realImpact_bmImpactInPercent`] = this.builder.bigNum(`${id}_realImpact_bmImpactInPercent`);
      desc[`${id}_realImpact_saleValueWithVat`] = this.builder.bigNum(`${id}_realImpact_saleValueWithVat`);
      desc[`${id}_realImpact_saleValueWithoutVat`] = this.builder.bigNum(`${id}_realImpact_saleValueWithoutVat`);
      desc[`${id}_realImpact_revenueImpact`] = this.builder.bigNum(`${id}_realImpact_revenueImpact`);
      desc[`${id}_realImpact_amount`] = this.builder.bigNum(`${id}_realImpact_amount`);
      desc[`${id}_realImpact_salesVolumeImpact`] = this.builder.bigNum(`${id}_realImpact_salesVolumeImpact`);
      desc[`${id}_realImpact_diffDays`] = this.builder.bigNum(`${id}_realImpact_diffDays`);
      desc = {...desc, ...this.getItemOverviewZoneCRPsDesc(id)};
    });
    return desc;
  }

  // Methods

  /**
   * Method goes through each PriceZoneIds from Release
   * and creates Ag-Grid ColDef.
   * @param actions - object with any actions(functions) inside, that you want to add as some "CellAction". (Function should return Promise)
   * @param editMode - boolean that allows edit items in Ag-Grid
   */
  public createItemOverviewZoneCoDefs = (actions: any, editMode: boolean) => {
    const colDefs: ColumnDefinition[] = [];
    // Final price dates allowed to be edited only for EDLP pricingType
    const isEDLP = this.release.priceListType.includes('EDLP');

    this.release.priceZoneIds.map((id) => {
      const priceZone = this.priceZoneStore.list.get(id);
      if (!priceZone.isNational) {
        colDefs.push({
          ...this.getZoneBasicDef(priceZone, FinalPriceType.Regular),
          tooltipField: `${id}_edlp`,
          cellRenderer: RendererNames.ReleaseRegularPriceRenderer,
          cellRendererParams: {tooltipDataColKey: `${id}_edlp`},
        });
      }
      colDefs.push(
        {
          ...this.getZoneBasicDef(priceZone, 'priceCode'),
          editable: PRICE_CODE_EDITABLE && editMode,
          singleClickEdit: true,
          selectOptions: this.priceCodes,
          cellEditorParams: {priceZoneId: id},
          cellEditor: EditorNames.ReleaseItemPriceCodeEditor,
          action: actions.updateItemPriceCode,
          filter: 'agSetColumnFilter',
        },
        // {
        //   ...this.getZoneBasicDef(priceZone, 'bmImpact'),
        //   cellRenderer: RendererNames.ArrowRenderer,
        //   comparator: Comparators.arrowComparator,
        // },
        {...this.getZoneBasicDef(priceZone, 'marginOldPercent')},
        {...this.getZoneBasicDef(priceZone, 'marginOld')},
        {...this.getZoneBasicDef(priceZone, 'marginNew')},
        {...this.getZoneBasicDef(priceZone, 'marginNewPercent')},
        {...this.getZoneBasicDef(priceZone, 'repricingImportanceWithConstraints')},
        {...this.getZoneBasicDef(priceZone, 'repricingImportanceWithoutConstraints')},
        {...this.getZoneBasicDef(priceZone, 'marginImpact')},
        {
          ...this.getZoneBasicDef(priceZone, 'marginImpactPercent'),
          comparator: Comparators.arrowComparator,
          cellRenderer: RendererNames.ArrowRenderer,
        },
        {...this.getZoneBasicDef(priceZone, 'revenueOld')},
        {...this.getZoneBasicDef(priceZone, 'revenueNew')},
        {
          ...this.getZoneBasicDef(priceZone, 'revenueImpact'),
          cellRenderer: RendererNames.ArrowRenderer,
          comparator: Comparators.arrowComparator,
        },
        {
          ...this.getZoneBasicDef(priceZone, 'revenueImpactPercent'),
          cellRenderer: RendererNames.ArrowRenderer,
          comparator: Comparators.arrowComparator,
        },
        {...this.getZoneBasicDef(priceZone, 'referencePriceOld')},
        {
          ...this.getZoneBasicDef(priceZone, 'referencePrice'),
          cellRenderer: RendererNames.ReleaseReferencePriceRenderer,
          comparator: Comparators.releaseReferencePriceComparator,
          filter: FilterNames.ReferencePriceFilter,
          excel: {
            valueFormatter: ({value}: {value: {value: BigDecimal}}) => (value && value.value) || '',
          },
        },
        // NOTE: ADDING CRPS DEFINITIONS !!!!!!!!!!!!!!!!!!!!!!!!!
        ...this.getZoneCRPsColDefs(priceZone),
        {
          ...this.getZoneBasicDef(priceZone, 'referencePriceChangeIndexPercent'),
          cellRenderer: RendererNames.ArrowRenderer,
          comparator: Comparators.arrowComparator,
          filter: FilterNames.PriceChangeIndexFilter,
          cellRendererParams: {limit: this.globalSettingsStore.terms.cleaningSettings.referencePriceChangeIndex},
        },
        {
          ...this.getZoneBasicDef(priceZone, 'regularPriceChangeIndexPercent'),
          cellRenderer: RendererNames.ArrowRenderer,
          comparator: Comparators.arrowComparator,
          filter: FilterNames.PriceChangeIndexFilter,
          cellRendererParams: {limit: this.globalSettingsStore.terms.cleaningSettings.extremePriceChangeIndex},
          excel: {
            valueFormatter: ({value}) => (value && value.value) || '',
          },
        },
        {
          ...this.getZoneBasicDef(priceZone, 'companyVsReferencePriceChangeIndexPercent'),
          cellRenderer: RendererNames.ArrowRenderer,
          comparator: Comparators.arrowComparator,
          filter: FilterNames.PriceChangeIndexFilter,
          cellRendererParams: {
            limit: this.globalSettingsStore.terms.cleaningSettings.internalVsCompetitorReferencePriceChangeIndex,
          },
          excel: {
            valueFormatter: ({value}: {value: {value: BigDecimal}}) => (value && value.value) || '',
          },
        },
        {...this.getZoneBasicDef(priceZone, 'priceElasticity')},
        {
          ...this.getZoneBasicDef(priceZone, 'priceRangeWithConstraints'),
          cellRenderer: RendererNames.ReleasePriceRangeRenderer,
          filter: FilterNames.ReleasePriceRangeFilter,
          excel: {
            valueFormatter: ({value}) => {
              return value && value.minimum && value.maximum
                ? `(${value.minimum.toString().replace('.', ',')} - ${value.maximum > 999999 ? translate('INFINITY') : value.maximum.toString().replace('.', ',')})`
                : '';
            },
          },
        },
        {
          ...this.getZoneBasicDef(priceZone, 'priceRangeWithoutConstraints'),
          cellRenderer: RendererNames.ReleasePriceRangeRenderer,
          filter: FilterNames.ReleasePriceRangeFilter,
          excel: {
            valueFormatter: ({value}) => {
              return value && value.minimum && value.maximum
                ? `(${value.minimum.toString().replace('.', ',')} - ${value.maximum > 999999 ? translate('INFINITY') : value.maximum.toString().replace('.', ',')})`
                : '';
            },
          },
        },
        {...this.getZoneBasicDef(priceZone, FinalPriceType.OptimalWithConstraints)},
        {...this.getZoneBasicDef(priceZone, FinalPriceType.OptimalWithoutConstraints)},
        {
          ...this.getZoneBasicDef(priceZone, 'finalPrice'),
          editable: editMode && withPermission([PricingPermissions.REGULAR_RELEASE_FINAL_PRICE_EDIT])
            ? params => params.data[`ReleaseDetailCategory_${priceZone.id}_finalPrice`]
            : false,
          singleClickEdit: true,
          cellEditor: EditorNames.ReleaseItemFinalPriceEditor,
          filter: FilterNames.FinalPriceFilter,
          cellRenderer: RendererNames.ReleaseFinalPriceRenderer,
          cellRendererParams: {
            priceZoneId: priceZone.id,
            limit: this.globalSettingsStore.terms.cleaningSettings.extremePriceChangeIndex,
          },
          comparator: Comparators.releaseFinalPriceComparator,
          excel: {
            hide: true,
            valueFormatter: ({value}) =>
              (value) ? (value.valueRounded) ? `${translate(`${value.priceType}-finalPrice`)}; ${value.valueRounded.toFixed(1).toString().replace('.', ',')}` : (value.value) ? `${translate(`${value.priceType}-finalPrice`)}; ${value.value.toFixed(1).toString().replace('.', ',')}` : '' : '',
          },
          action: actions.updateItemFinalPrice,
          suppressKeyboardEvent: (params) => {
            const key = params.event.key;
            return params.editing && key && (key === Key.DownArrow || key === Key.UpArrow || key === Key.Enter);
          },
        },
        {
          ...this.getZoneBasicDef(priceZone, 'excel_finalPrice_type'),
          valueFormatter: ({value}) => (value) ? (value.priceType) ? `${value.priceType}` : '' : '',
          suppressToolPanel: true,
          hide: true,
          excel: {
            hide: false,
            valueFormatter: ({value}) => (value) ? (value.priceType) ? `${value.priceType}` : '' : '',
          },
        },
        {
          ...this.getZoneBasicDef(priceZone, 'excel_finalPrice'),
          valueFormatter: ({value}) =>
            (value) ? (value.valueRounded) ? `${value.valueRounded.toFixed(1).toString().replace('.', ',')}` : (value.value) ? `${value.value.toFixed(1).toString().replace('.', ',')}` : '' : '',
          hide: true,
          suppressToolPanel: true,
          excel: {
            hide: false,
            valueFormatter: ({value}) => {
              if (value) {
                if (value.valueRounded) {
                  return value.valueRounded.round(1);
                }
                if (value.value) {
                  return value.value.round(1);
                }
              }
              return '';
            }
          },
        },
        {
          ...this.getZoneBasicDef(priceZone, 'possibleFrom'),
          cellClassRules: {
            // color should be red if possibleFrom is different from final price valid from
            ['c-error']: ({node, value}: {node: RowNode; value: Moment}) => {
              const validFrom = node.data[`PriceZoneReleasePrices_validFromFP`];
              return value && validFrom && !validFrom.isSame(value);
            },
          },
        },
        {
          ...this.getZoneBasicDef(priceZone, 'validFromFP'),
          editable: editMode && withPermission([PricingPermissions.REGULAR_RELEASE_FINAL_PRICE_EDIT]),
          singleClickEdit: true,
          action: actions.updateItemFinalPriceDate('validFrom'),
          dontCreateColumn: !isEDLP,
        },
        {
          ...this.getZoneBasicDef(priceZone, 'validToFP'),
          editable: editMode && withPermission([PricingPermissions.REGULAR_RELEASE_FINAL_PRICE_EDIT]),
          singleClickEdit: true,
          action: actions.updateItemFinalPriceDate('validTo'),
          dontCreateColumn: !isEDLP,
        },
        {
          ...this.getZoneBasicDef(priceZone, 'comments'),
          editable: false,
          comparator: Comparators.releaseCommentsComparator,
          cellRenderer: RendererNames.ReleaseCommentsRenderer,
          cellRendererParams: {handleCommentModalOpen: actions.handleCommentModalOpen, priceZoneId: id},
          filter: FilterNames.ReleaseCommentsFilter,
          excel: {
            valueFormatter: ({value}) => (value ? value.map(item => item.text).join(', ') : ''),
          },
        },
        {
          ...this.getZoneBasicDef(priceZone, 'promoPriceStats'),
          cellRenderer: (props) => (props.value) ? (props.value.currentPrices.size > 0) ? 'Yes' : 'No' : CONSTANTS.FORMAT.NULL,
          filter: FilterNames.PromoPriceStatsFilter,
          tooltipComponent: TooltipNames.ReleasePromoTooltip,
          excel: {
            valueFormatter: (props) => (props.value) ? (props.value.currentPrices.size > 0) ? 'Yes' : 'No' : CONSTANTS.FORMAT.NULL,
          },
        },
        {...this.getZoneBasicDef(priceZone, 'salesVolumeOld')},
        {...this.getZoneBasicDef(priceZone, 'salesVolumeNew')},
        {
          ...this.getZoneBasicDef(priceZone, 'salesVolumeImpact'),
          cellRenderer: RendererNames.ArrowRenderer,
          comparator: Comparators.arrowComparator,
        },
        {
          ...this.getZoneBasicDef(priceZone, 'realImpact_bm'),
          dontCreateColumn: !this.isReleased,
        },
        {
          ...this.getZoneBasicDef(priceZone, 'realImpact_bmPercent'),
          dontCreateColumn: !this.isReleased,
        },
        {
          ...this.getZoneBasicDef(priceZone, 'realImpact_bmImpact'),
          cellRenderer: RendererNames.ArrowRenderer,
          comparator: Comparators.arrowComparator,
          dontCreateColumn: !this.isReleased,
        },
        {
          ...this.getZoneBasicDef(priceZone, 'realImpact_bmImpactInPercent'),
          cellRenderer: RendererNames.ArrowRenderer,
          comparator: Comparators.arrowComparator,
          dontCreateColumn: !this.isReleased,
        },
        {
          ...this.getZoneBasicDef(priceZone, 'realImpact_saleValueWithoutVat'),
          dontCreateColumn: !this.isReleased,
        },
        {
          ...this.getZoneBasicDef(priceZone, 'realImpact_revenueImpact'),
          cellRenderer: RendererNames.ArrowRenderer,
          comparator: Comparators.arrowComparator,
          dontCreateColumn: !this.isReleased,
        },
        {
          ...this.getZoneBasicDef(priceZone, 'realImpact_amount'),
          dontCreateColumn: !this.isReleased,
        },
        {
          ...this.getZoneBasicDef(priceZone, 'realImpact_salesVolumeImpact'),
          cellRenderer: RendererNames.ArrowRenderer,
          comparator: Comparators.arrowComparator,
          dontCreateColumn: !this.isReleased,
        },
        {
          ...this.getZoneBasicDef(priceZone, 'realImpact_diffDays'),
          valueFormatter: (params => {
            return params.value ? Number(params.value).toFixed(0) : CONSTANTS.FORMAT.NULL;
          }),
          dontCreateColumn: !this.isReleased,
        },
      );
    });
    return colDefs;
  };

  /**
   * Converts data from response to Ag-Grid data
   * @param price - part of ReleaseItem
   */
  public getZoneData = (price: PriceZoneReleasePrices) => {
    const id = price.priceZoneId; // priceZoneId
    const realImpact: any = price.realImpact && price.realImpact.getValue(); // data for impact report
    // Transforming data
    const priceRangeWithConstraints = price.optimalPriceWithConstraints
      ? {minimum: price.optimalPriceWithConstraints.rangeMin, maximum: price.optimalPriceWithConstraints.rangeMax}
      : null;
    const priceRangeWithoutConstraints = price.optimalPriceWithoutConstraints
      ? {minimum: price.optimalPriceWithoutConstraints.rangeMin, maximum: price.optimalPriceWithoutConstraints.rangeMax}
      : null;
    // OptimalWithConstraints
    const OPW = price.optimalPriceWithConstraints ? price.optimalPriceWithConstraints.getPresentedValue() : null;
    // OptimalWithoutConstraints
    const OP = price.optimalPriceWithoutConstraints ? price.optimalPriceWithoutConstraints.getPresentedValue() : null;
    /** Fake final price to pass validation */
    const validFrom = price.repricing.possibleFrom ? price.repricing.possibleFrom : this.release.priceValidityStartDate;
    const finalPrice = price.finalPrice
      ? price.finalPrice
      : {priceZoneId: id, validFrom, validationResult: ValidationResultWrapper.EMPTY};

    let ydgMessages = List();
    if (price.optimalPriceWithConstraints) {
      ydgMessages = ydgMessages.concat(price.optimalPriceWithConstraints.validationResult.resultObjects);
    }
    if (price.optimalPriceWithoutConstraints) {
      ydgMessages = ydgMessages.concat(price.optimalPriceWithoutConstraints.validationResult.resultObjects);
    }

    // Final Data
    return {
      [`${id}_priceCode`]: price.priceCode,
      [`${id}_ydg_messages`]: new ValidationResultWrapper(ydgMessages),
      [`${id}_marginOld`]: price.marginOld,
      [`${id}_marginOldPercent`]: price.marginOldPercent,
      [`${id}_marginNew`]: price.marginNew,
      [`${id}_marginNewPercent`]: price.marginNewPercent,
      [`${id}_marginImpact`]: price.marginImpact,
      [`${id}_marginImpactPercent`]: price.marginImpactPercent,
      [`${id}_revenueOld`]: price.revenueOld,
      [`${id}_revenueNew`]: price.revenueNew,
      [`${id}_revenueImpact`]: price.revenueImpact,
      [`${id}_revenueImpactPercent`]: price.revenueImpactPercent,
      [`${id}_referencePriceOld`]: price.referencePriceOld ? price.referencePriceOld.value : null,
      [`${id}_referencePrice`]: price.referencePrice,
      [`${id}_priceRangeWithConstraints`]: priceRangeWithConstraints,
      [`${id}_priceRangeWithoutConstraints`]: priceRangeWithoutConstraints,
      [`${id}_referencePriceChangeIndexPercent`]: price.referencePriceChangeIndexPercent,
      [`${id}_regularPriceChangeIndexPercent`]: price.regularPriceChangeIndexPercent,
      [`${id}_companyVsReferencePriceChangeIndexPercent`]: price.companyVsReferencePriceChangeIndexPercent,
      [`${id}_priceElasticity`]: price.priceElasticity,
      [`${id}_edlp`]: price.edlpPrice && price.edlpPrice.value,
      [`${id}_${FinalPriceType.Regular}`]: price.regularPrice ? price.regularPrice.getPresentedValue() : null,
      [`${id}_${FinalPriceType.OptimalWithConstraints}`]: OPW,
      [`${id}_${FinalPriceType.OptimalWithoutConstraints}`]: OP,
      [`${id}_finalPrice`]: finalPrice,
      [`${id}_excel_finalPrice_type`]: finalPrice,
      [`${id}_excel_finalPrice`]: finalPrice,
      [`${id}_possibleFrom`]: price.repricing.possibleFrom,
      [`${id}_validFromFP`]: price.finalPrice && price.finalPrice.validFrom,
      [`${id}_validToFP`]: price.finalPrice && price.finalPrice.validTo,
      [`${id}_comments`]: price.comments,
      [`${id}_promoPriceStats`]: price.promoPriceStats,
      [`${id}_salesVolumeOld`]: price.salesVolumeOld,
      [`${id}_salesVolumeNew`]: price.salesVolumeNew,
      [`${id}_salesVolumeImpact`]: price.salesVolumeImpact,
      [`${id}_profitOld`]: price.profitOld,
      [`${id}_profitOldPercent`]: price.profitOldPercent,
      [`${id}_profitNew`]: price.profitNew,
      [`${id}_profitNewPercent`]: price.profitNewPercent,
      [`${id}_profitImpact`]: price.profitImpact,
      [`${id}_profitImpactPercent`]: price.profitImpactPercent,
      [`${id}_repricingImportanceWithConstraints`]:
      price.optimalPriceWithConstraints && price.optimalPriceWithConstraints.repricingImportance,
      [`${id}_repricingImportanceWithoutConstraints`]:
      price.optimalPriceWithoutConstraints && price.optimalPriceWithoutConstraints.repricingImportance,
      [`${id}_realImpact_profit`]: realImpact && realImpact.profit,
      [`${id}_realImpact_profitPercent`]: realImpact && realImpact.profitPercent,
      [`${id}_realImpact_profitImpact`]: realImpact && realImpact.profitImpact,
      [`${id}_realImpact_profitImpactInPercent`]: realImpact && realImpact.profitImpactInPercent,
      [`${id}_realImpact_bm`]: realImpact && realImpact.bm,
      [`${id}_realImpact_bmPercent`]: realImpact && realImpact.bmPercent,
      [`${id}_realImpact_bmImpact`]: realImpact && realImpact.bmImpact,
      [`${id}_realImpact_bmImpactInPercent`]: realImpact && realImpact.bmImpactInPercent,
      [`${id}_realImpact_saleValueWithoutVat`]: realImpact && realImpact.saleValueWithoutVat,
      [`${id}_realImpact_revenueImpact`]: realImpact && realImpact.revenueImpact,
      [`${id}_realImpact_amount`]: realImpact && realImpact.amount,
      [`${id}_realImpact_salesVolumeImpact`]: realImpact && realImpact.salesVolumeImpact,
      [`${id}_realImpact_diffDays`]: realImpact && realImpact.diffDays,
      ...this.getZoneCRPData(id, price.competitorReferencePrices, price.companyVsCRPChangeIndices),
    };
  };

  public load = async () => {
    try {
      const [priceCodes] = await Promise.all([
        PRICE_CODE_EDITABLE ? this.releaseStore.getPriceCodeOptions() : Promise.resolve([]),
        this.dataUploadStore.getAll(),
        this.competitorSiteStore.getAll(),
        this.priceZoneStore.getAll(),
        this.competitorStore.getAll(),
        this.globalSettingsStore.getAll(),
      ]);

      this.priceCodes = priceCodes;
      this.generators = this.createGenerators();
    } catch (error) {
      await Promise.reject();
    }
  };

  /**
   * Description fot Item overview per Zone per Competitor
   * @param zoneId price zone id
   */
  private getItemOverviewZoneCRPsDesc = (zoneId: string) => {
    const desc: DataDesc = {};
    this.competitorStore.list.forEach(({id}) => {
      desc[`${zoneId}_${id}_competitorReferencePrice`] = this.builder.bigNum(
        `${zoneId}_${id}_competitorReferencePrice`,
      );
      desc[`${zoneId}_${id}_companyVsCRPChangeIndex`] = this.builder.obj(
        `${zoneId}_${id}_companyVsCRPChangeIndex`,
        CompanyVsCRPChangeIndex.schema,
        (mat) => CompanyVsCRPChangeIndex.fromDataDict(mat),
      );
    });
    return desc;
  };

  /** Returns colDefs per each competitor depending on priceZone
   * @param zone priceZone
   */
  private getZoneCRPsColDefs = (zone: PriceZone) => {
    const colDefs: ColumnDefinition[] = [];
    const tooltip = this.keycloakStore.userHasPermissions([PricingPermissions.DATA_CLEAN_VIEW]) ? {
      tooltipComponent: TooltipNames.ReleaseCRPPriceTooltip,
      tooltipComponentParams: {
        // @ts-ignore Ag-grid BUG! AG-3074 tooltipComponentParams should be type any not iTooltipParams
        dataUploads: this.dataUploadStore.list,
        competitorSites: this.competitorSiteStore.list,
      } as any,
    } : {};

    this.competitorStore.list.forEach((competitor) => {
      colDefs.push(
        {
          field: `${zone.id}_${competitor.id}_competitorReferencePrice`,
          headerName: `${zone.name} (${competitor.name})`,
          ...tooltip,
          comparator: Comparators.releaseCRPIndexComparator,
          valueFormatter: ({value}) => value ? value.toFixed(2).replace('.', ',') : null,
        },
        {
          field: `${zone.id}_${competitor.id}_companyVsCRPChangeIndex`,
          headerName: `${zone.name} (${competitor.name}) ${translate(`PriceZoneReleasePrices_companyVsCRPChangeIndex`)}`,
          cellRenderer: RendererNames.ReleaseCRPIndexRenderer,
          cellRendererParams: {
            limit: this.globalSettingsStore.terms.cleaningSettings.internalVsCompetitorReferencePriceChangeIndex,
          },
          comparator: Comparators.releaseCRPIndexComparator,
          excel: {
            valueFormatter: ({value}: {value: {value: BigDecimal}}) =>
              value && value.value ? value.value.toFixed(3) : '',
          },
        },
      );
    });

    return colDefs;
  };

  /** Returns basic column definition for zone generator */
  private getZoneBasicDef = (zone: PriceZone, key: string): ColumnDefinition => ({
    field: `${zone.id}_${key}`,
    headerName: `${zone.name} ${translate(`PriceZoneReleasePrices_${key}`)}`,
  });

  /**
   * Returns data for each competitor per priceZone
   * @param zoneId
   * @param prices - List of CompetitorReferentialPrice
   * @param indexes - List of CompanyVsCRPChangeIndex
   */
  private getZoneCRPData = (
    zoneId: string,
    prices: List<CompetitorReferentialPrice>,
    indexes: List<CompanyVsCRPChangeIndex>,
  ) => {
    const priceMap = arrayToMap(prices.toArray(), 'competitorId');
    const indexMap = arrayToMap(indexes.toArray(), 'competitorId');
    let data: StringMapping<any> = {};
    this.competitorStore.list.forEach(({id}) => {
      const price = priceMap.get(id);
      const index = indexMap.get(id);
      data = {
        ...data,
        [`${zoneId}_${id}_competitorReferencePrice`]: price && price.modifiedValue,
        [`${zoneId}_${id}_companyVsCRPChangeIndex`]: index,
      };
    });
    return data;
  };

  /** Function creates ColumnGenerator for DetailCategoryComponent */
  private createGenerators = () => ({
    totalOverviewGenerator: new ColumnGenerator<StringMapping<any>>(this.totalOverViewDesc),
    itemOverviewGenerator: new ColumnGenerator<StringMapping<any>>(this.itemOverviewDesc),
    itemOverviewZoneGenerator: new ColumnGenerator<StringMapping<any>>(this.itemOverviewZoneDesc),
  });
}
