/**
 * @file Created on Tue Nov 13 2018
 * @author LZe
 */

import {DataDesc, ProductCategory, ProductEAN, StructPropBuilder} from '@logio/common-be-fe';
import {ColumnGenerator, StringMapping} from '@logio/common-fe';

const eanSchema = (() => {
  const builder = new StructPropBuilder('ean');
  return {
    value: builder.str('value'),
    dateFrom: builder.opt(builder.date('dateFrom')),
    dateTo: builder.opt(builder.date('dateTo')),
    active: builder.opt(builder.bool('active')),
    eanDescription: builder.opt(builder.str('eanDescription')),
  };
})();

/** Derived columns data description builder */
const categoryBuilder = new StructPropBuilder('Category');

/** Derived columns data description */
const categoryColumnsDescription: DataDesc = {
  box: categoryBuilder.str('box'),
};

export const eanColumnGenerator = new ColumnGenerator<ProductEAN>(eanSchema);
export const categoryColumnGenerator = new ColumnGenerator<StringMapping<any>>(categoryColumnsDescription);

const productCategoryColumnGenerator = new ColumnGenerator<ProductCategory>(ProductCategory.schema);

export const categoryAutoGroupColumnDef = productCategoryColumnGenerator.getAutoGroupColumnDefinitions({
  field: 'name',
  width: 400,
  suppressMovable: true,
  cellRendererParams: {
    suppressCount: true,
  },
  valueFormatter: (params) =>
    [params.value, params.node.childrenAfterGroup.length ? ` (${params.node.childrenAfterGroup.length})` : ''].join(''),
});
