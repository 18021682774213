/**
 * Circle can be rendered in these colors
 */
export var CircleColor;

(function (CircleColor) {
  CircleColor["Waiting"] = "WAITING";
  CircleColor["InProgress"] = "IN PROGRESS";
  CircleColor["Approved"] = "APPROVED";
  CircleColor["AfterDeadline"] = "AFTER DEADLINE";
  CircleColor["Rejected"] = "REJECTED";
  CircleColor["Warning"] = "WARNING";
  CircleColor["Error"] = "ERROR";
})(CircleColor || (CircleColor = {}));