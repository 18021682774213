/**
 * @file Created on Tue May 07 2019
 * @author Tomáš Leity
 */
import { Utils } from '@logio/common-be-fe';
import * as React from 'react';
import translate from '../../../../i18n/translate';
import Tooltip from '../../../../info/Tooltip';
import Icon, { IconColor, IconType } from '../../../../media/Icon';
var CellColorEnum;

(function (CellColorEnum) {
  CellColorEnum["Empty"] = "c-inverse bg-muted";
  CellColorEnum["NotCalculated"] = "bg-group4";
})(CellColorEnum || (CellColorEnum = {}));
/**
 * Renderer for KPI info
 */


export var KpiInfoRenderer = function KpiInfoRenderer(props) {
  var kpiKey = props.data[props.colDef.colId];

  var setCellClass = function setCellClass(className) {
    props.eGridCell.className = props.eGridCell.className.concat(" ".concat(className));
  };

  if (Utils.isValueMissing(kpiKey)) {
    setCellClass(CellColorEnum.Empty);
    return null;
  }

  var description = props.getKpiDescription && props.getKpiDescription(kpiKey);
  var kpiValue = props.value;
  var value = translate('EMPTY_VALUE');
  var formula = description && description.formula;

  if (!Utils.isValueMissing(kpiValue) && kpiValue.toString) {
    var infinite = !Utils.isString(kpiValue) && kpiValue.isNaN() || Utils.isString(kpiValue) && isNaN(Number(kpiValue));

    if (infinite) {
      setCellClass(CellColorEnum.NotCalculated);
      value = translate('IS_NAN');
      formula = translate('message_KPI_valueNotSpecified');
    } else {
      value = Number(kpiValue).toLocaleString('cs');
    }
  }

  if (formula) {
    return React.createElement(React.Fragment, null, React.createElement(Tooltip, {
      placement: "right",
      overlay: React.createElement("div", null, formula),
      children: React.createElement("span", null, React.createElement(Icon, {
        iconHeight: 18,
        iconColor: IconColor.Light,
        iconType: IconType.calc
      }), " ", value)
    }));
  }

  return React.createElement("span", null, value);
};