import { SelloutTerminationReportFilterDTO, SelloutTerminationReportRowDTO } from '@logio/common-be-fe';
import { ColumnGenerator, ReportLayer } from '@logio/common-fe';
import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-community';
import debounce from 'lodash/debounce';

type FinalFilter = { -readonly [key in keyof SelloutTerminationReportRowDTO]?: { [key: string]: any } };

export class SelloutTerminationReportDataSource implements IServerSideDatasource {
  private reportLayer = new ReportLayer();
  private mainFilter: SelloutTerminationReportFilterDTO;

  constructor (private columnGenerator: ColumnGenerator<SelloutTerminationReportRowDTO>) {
    this.getRows = debounce(this.getRows.bind(this), 1000);
  }

  public async getRows(params: IServerSideGetRowsParams) {
    try {
      const limit = params.request.endRow - params.request.startRow;

      // create filter from AG grid
      const filter: FinalFilter = {};
      Object.keys(params.request.filterModel).forEach(key => {
        // temp solution to remove prefix
        filter[key.split('_')[1]] = params.request.filterModel[key];
      });

      // create sorting from AG grid
      const sorting = params.request.sortModel.map(sortCriteria => ({
        // temp solution to remove prefix
        colId: sortCriteria.colId.split('_')[1],
        sort: sortCriteria.sort,
      }));

      const result = await this.reportLayer.getSelloutTerminationReport(
        this.mainFilter,
        { filter, sorting },
        params.request.startRow,
        limit,
      );
      const lastRow = result.size < limit ? params.request.startRow + result.size : undefined;

      params.successCallback(result.map(row => this.columnGenerator.getColumnData(row)).toArray(), lastRow);
    } catch(err) {
      params.failCallback();
    }
  }

  public destroy(): void {
    // throw new Error('Method not implemented.');
  }

  /**
   * Sets main filter of report (AG grid filter/pagination will be added by AG grid).
   *
   * @param filter
   */
  public setFilter(filter: SelloutTerminationReportFilterDTO) {
    this.mainFilter = filter;
  }

  public async export() {
    if (this.mainFilter) {
      return await this.reportLayer.exportSelloutTerminationReport(this.mainFilter);
    }
  }
}
