/**
 * @file Created on Mon Aug 06 2018
 * @author BPo
 */
import { Api } from './api';
import { DataPumpAPI } from './dp-api';
/**
 * Api communication helper
 *
 * @param endpoint Will be requested
 * @param auth An optional boolean property
 */

export default function api(endpoint) {
  var auth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return new Api(endpoint, auth);
}
/**
 * Api communication helper
 *
 * @param endpoint Will be requested
 * @param auth An optional boolean property
 */

export function dataPumpAPI(endpoint) {
  var auth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return new DataPumpAPI(endpoint, auth);
}