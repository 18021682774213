import { Utils } from "@logio/common-be-fe";
import { IconType } from "../../../../../components/media/Icon";
import generalComparator from "./generalComparator";
/**
 * @file Created on September 2019
 * @author Atreo Digital s.r.o.
 * @description Custom comparator for NEW field in price architecture.
 */

var priceArchitectureNewComparator = function priceArchitectureNewComparator(valA, valB) {
  return generalComparator(toComparableValue(valA), toComparableValue(valB));
}; // the comparator expects array with one item as a value


var toComparableValue = function toComparableValue(val) {
  if (Utils.isValueMissing(val) || Utils.isValueMissing(val[0]) || !val[0].hasOwnProperty("icon")) {
    return null;
  }

  switch (val[0].icon) {
    case IconType.newsRecommend:
      return 1;

    case IconType.newsFull:
      return 2;

    default:
      return null;
  }
};

export default priceArchitectureNewComparator;