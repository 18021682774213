/**
 * @file Created on Tue Apr 09 2019
 * @author SKu
 */

import { PricingPermissions } from '@logio/common-be-fe';
import { Button, getPath, Page, translate, WithPermission } from '@logio/common-fe';
import { observer, Observer } from 'mobx-react';
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import SelloutIdentification from '../../../components/Dashboard/SelloutIdentification';
import { PageNamesEnum } from '../../../shared/localization/PageNamesEnum';
import { PagePathsEnum } from '../../../shared/localization/PagePathsEnum';
import { SelloutIdentificationReportPageStore } from '../../../stores/pages/Reports/SelloutIdentificationReport/SelloutIdentificationReportPageStore';

@observer
class SelloutIdentificationReportPage extends React.Component<RouteComponentProps<SelloutIdentificationReportPage>> {
  store = new SelloutIdentificationReportPageStore(this.props.history);

  componentWillMount() {
    this.store.load();
  }

  render() {
    return (
      <Page titleKey={PageNamesEnum.SelloutIdentificationReport} loadingState={this.store.loadingState}>
        <Observer>
          {() => (
            <>
              <WithPermission
                some
                permissionTypes={[
                  PricingPermissions.SELLOUT_RELEASE_CREATE_OTHERS,
                  PricingPermissions.SELLOUT_RELEASE_CREATE_URGENT,
                ]}
              >
                <div className="mb1">
                  <Link to={getPath(PagePathsEnum.ReleaseSelloutNew)}>
                    <Button>{translate('create-new-release')}</Button>
                  </Link>
                </div>
              </WithPermission>
              <SelloutIdentification store={this.store.selloutIdentificationStore} />
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

export default withRouter(SelloutIdentificationReportPage);
