"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Determines axis for segmentation model - sales lift.
 *
 * @author ZSr
 */

var UnitBackDiscountRealizationEnum;

(function (UnitBackDiscountRealizationEnum) {
  UnitBackDiscountRealizationEnum["Invoice"] = "INVOICE";
  UnitBackDiscountRealizationEnum["CreditNote"] = "CREDIT_NOTE";
  UnitBackDiscountRealizationEnum["FreePieces"] = "FREE_PIECES";
})(UnitBackDiscountRealizationEnum = exports.UnitBackDiscountRealizationEnum || (exports.UnitBackDiscountRealizationEnum = {}));