"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Code of alert message.
 * Specification of alerts: https://docs.google.com/spreadsheets/d/19VKiEkXXfGuBQUU1s-HmJ6mZUz0GXqwhaH3v-oAbAIg/edit#gid=0
 *
 * @author JGr
 * @author Radek Beran
 */

var UserMessageCode;

(function (UserMessageCode) {
  /**
   * There is no competitor reference price and no reference product.
   * The last regular price was used.
   */
  UserMessageCode["ReferencePriceLastRegularUsed"] = "ReferencePriceLastRegularUsed";
  /**
   * The reference price doesn't meet competitor with top priority.
   */

  UserMessageCode["ReferencePriceCompetitorWithLowerPriority"] = "ReferencePriceCompetitorWithLowerPriority";
  /**
   * No competitors prices detected. Competitor reference price cannot be calculated.
   */

  UserMessageCode["CompetitorReferencePriceNoPrices"] = "CompetitorReferencePriceNoPrices";
  /**
   * No CRP. <Name of competitor> price used.
   */

  UserMessageCode["NoCRPAnotherCompetitorUsed"] = "NoCRPAnotherCompetitorUsed";
  /**
   * There is no new CRP, actual CRP was used (LOG-2366).
   */

  UserMessageCode["ActualCRPUsed"] = "ActualCRPUsed";
  /**
   * Margin is out of min – max boundaries.
   * @deprecated abandoned after implementation of only one average margin (LOG-3113)
   */

  UserMessageCode["MarginOutOfRange"] = "MarginOutOfRange";
  /**
   * Some release's data can be obsoleted because there is a recalculation in a progress.
   */

  UserMessageCode["ReleaseRecalculationInProgress"] = "ReleaseRecalculationInProgress";
  /**
   * Some products were registered as new.
   */

  UserMessageCode["ProductsNewlyListed"] = "ProductsNewlyListed";
  /**
   * Some products were have new recommended pairs with competitor products.
   */

  UserMessageCode["NewRecommendedPair"] = "NewRecommendedPair";
  /**
   * New price is out of min – max boundaries.
   */

  UserMessageCode["PriceViolatesPriceChangeLimit"] = "PriceViolatesPriceChangeLimit";
  /**
   * New price doesn't meet min % above promo price.
   */

  UserMessageCode["PriceViolatesPromoPriceDistance"] = "PriceViolatesPromoPriceDistance";
  /**
   * New price doesn't meet price distance from reference price.
   */

  UserMessageCode["PriceViolatesRefPriceDistance"] = "PriceViolatesRefPriceDistance";
  /**
   * New price has broken line pricing in the family <name of the family>.
   */

  UserMessageCode["PriceBreaksFamilyLinePricing"] = "PriceBreaksFamilyLinePricing";
  /**
   * New price has broken % size charger in size pricing in the family <name of the family>.
   */

  UserMessageCode["PriceBreaksFamilySizePricing"] = "PriceBreaksFamilySizePricing";
  /**
   * Release dos not contain approved category for export.
   */

  UserMessageCode["ReleaseNoApprovedCategoryForExport"] = "ReleaseNoApprovedCategoryForExport";
  /**
   * Exported price was replaced by another release.
   */

  UserMessageCode["PriceReplacedByRelease"] = "PriceReplacedByRelease";
  /**
   * Release item is in conflict with another item.
   */

  UserMessageCode["ProductNotFound"] = "ProductNotFound";
})(UserMessageCode = exports.UserMessageCode || (exports.UserMessageCode = {}));