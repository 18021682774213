/**
 * @author VBr
 */

import {DataUpload, Competitor, AgencyQualityEvaluationDTO, Utils, CompetitorSite} from '@logio/common-be-fe';
import {
  CompetitorPriceStore,
  CompetitorStore,
  LoadingState,
  PageStore,
  rootStore,
  StoreName,
  StringMapping,
  DataUploadStore,
  translate,
  ColumnDefinition,
  ColumnGenerator,
  CompetitorSiteStore,
} from '@logio/common-fe';
import {ColumnApi, GridApi, GridReadyEvent} from 'ag-grid-community';
import {action, computed, observable} from 'mobx';

export class AgencyQualityEvaluationReportPageStore extends PageStore {
  /** Get dependant stores */
  dataUploadStore = rootStore.getStore(StoreName.DataUpload) as DataUploadStore;
  competitorsStore = rootStore.getStore(StoreName.Competitor) as CompetitorStore;
  competitorSiteStore = rootStore.getStore(StoreName.CompetitorSite) as CompetitorSiteStore;
  competitorPriceStore = rootStore.getStore(StoreName.CompetitorPrice) as CompetitorPriceStore;

  dataUploadColumnGenerator = new ColumnGenerator<DataUpload>(DataUpload.schema);
  competitorColumnGenerator = new ColumnGenerator<Competitor>(Competitor.schema);
  competitorSiteColumnGenerator = new ColumnGenerator<CompetitorSite>(CompetitorSite.schema);
  agencyQualityEvaluationColumnGenerator = new ColumnGenerator<AgencyQualityEvaluationDTO>(
    AgencyQualityEvaluationDTO.schema,
  );

  gridApi: GridApi;
  columnApi: ColumnApi;

  /** Return generated column definitions for ag-grid */
  @computed
  get columnDefs(): ColumnDefinition[] {
    return [
      ...this.dataUploadColumnGenerator.getColumnDefinitions([
        {
          field: 'agencyType',
          rowGroup: true,
          hide: true,
          lockVisible: true,
        },
        {field: 'researchName'},
      ]),
      ...this.competitorColumnGenerator.getColumnDefinitions([{field: 'name'}]),
      ...this.competitorSiteColumnGenerator.getColumnDefinitions([{field: 'address'}, {field:'city'}]),
      ...this.agencyQualityEvaluationColumnGenerator.getColumnDefinitions([
        {field: 'date'},
        {field: 'outliers', round: true},
        {field: 'totalCollectedProducts'},
        {field: 'totalCollectedPrices'},
      ]),
    ];
  }

  @computed
  get autoGroupColumnDefs() {
    return {
      headerName: translate(DataUpload.schema.agencyType.description.nameKey),
      field: DataUpload.schema.agencyType.description.nameKey,
    };
  }

  /** Return generated data for ag-grid */
  @computed
  get rowData(): Array<StringMapping<any>> {
    const rowData = [];

    this.competitorPriceStore.agencyQualityEvaluations.forEach((agencyQualityEvaluation) => {
      const competitorSite = this.competitorSiteStore.list.get(agencyQualityEvaluation.competitorSiteId);
      const competitor = this.competitorsStore.list.get(competitorSite.companyId);
      const upload = this.dataUploadStore.list.get(agencyQualityEvaluation.dataUploadId);

      rowData.push({
        ...this.dataUploadColumnGenerator.getColumnData(upload),
        ...this.competitorSiteColumnGenerator.getColumnData(competitorSite),
        ...this.competitorColumnGenerator.getColumnData(competitor),
        ...this.agencyQualityEvaluationColumnGenerator.getColumnData(agencyQualityEvaluation),
      });
    });

    return rowData;
  }

  /** Binds ag-grid api */
  @action.bound
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  /** Fetches all data for this page */
  @action.bound
  load(): void {
    this.setLoadingState(LoadingState.Pending);
    Promise.all([
      this.dataUploadStore.getAll(),
      this.competitorsStore.getAll(),
      this.competitorSiteStore.getAll(),
      this.competitorPriceStore.getAgencyQualityEvaluations(),
    ])
      .then(() => this.setLoadingState(LoadingState.Success))
      .catch((error) => {
        // this.messages.setError(error);
        this.setLoadingState(LoadingState.Error);
      });
  }
}
