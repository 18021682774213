/**
 * @file Created on Fri Oct 05 2018
 * @author SKu
 */

import {ReleaseState} from '@logio/common-be-fe';
import {ProgressBar} from '@logio/common-fe';
import {computed} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import {withRouter} from 'react-router';
import ReleaseDetailCategoryComponent from '../../../components/Release/ReleaseDetailCategory/ReleaseDetailCategoryComponent';
import ReleaseHeader from '../../../components/Release/ReleaseHeader/ReleaseHeader';
import {ReleaseExportPageStore} from '../../../stores/pages/Release/ReleaseExport/ReleaseExportPageStore';
import AbstractReleaseExportPage from './AbstractReleaseExportPage';

/**
 * Release export page
 * Almost the same as category detail, but shows all approved categories
 */
@observer
class ReleaseExportPage extends AbstractReleaseExportPage {
  public store = new ReleaseExportPageStore(this.props.history, this.props.match.params.id);

  @computed
  public get releaseHeader() {
    return (
      <ReleaseHeader
        store={this.store.releaseHeaderStore}
        customItems={this.store.release.state !== ReleaseState.ExportChecking ? this.releaseHeaderItems : null}
      />
    );
  }

  @computed
  public get renderContent() {
    return this.store.release.state === ReleaseState.ExportChecking ? (
      <div className="mb2">
        <ProgressBar percentage={this.store.progress} label={'release-export-preparation'} />
      </div>
    ) : (
      <ReleaseDetailCategoryComponent store={this.store.releaseDetailCategoryComponentStore} export />
    );
  }
}

export default withRouter(ReleaseExportPage);
