"use strict";
/*
 * Created on Wed Oct 24 2018
 */

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var CustomObjectParser_1 = require("../data-dictionary/parsers/CustomObjectParser");

var StructPropBuilder_1 = require("../data-dictionary/StructPropBuilder");

var ApplicationRecalculation_1 = require("./ApplicationRecalculation"); //tslint:disable:max-classes-per-file

/**
 * <p>
 * Common applications configuration which stores some important attributes for application runtime. Values can be used by both
 * frontend and backend applications. This configuration should not contain much specific attributes for certain application, you
 * should create local configs for the given cases. For more complex configurations, consider to use object encapsulating related parts.
 * </p>
 *
 * @author RV
 */


var ApplicationConfiguration =
/**
 * Constructs ApplicationConfiguration instance.
 *
 * @param categoryLevel the deepest category level to work with in some specific cases (e.g. for rendering within UI)
 * @param currencyCode the code of default currency of the system (e.g. code according to ISO 4217)
 * @param version
 * @param pricingVersion
 * @param promoVersion
 * @param timezone
 * @param locale
 * @param localeSpec
 * @param mode - corresponds to config.mode
 * @param recalculation Params related to not only night application recalculation.
 */
function ApplicationConfiguration(categoryLevel, currencyCode, version, pricingVersion, promoVersion, timezone, locale, localeSpec, mode, recalculation) {
  _classCallCheck(this, ApplicationConfiguration);

  this.categoryLevel = categoryLevel;
  this.currencyCode = currencyCode;
  this.version = version;
  this.pricingVersion = pricingVersion;
  this.promoVersion = promoVersion;
  this.timezone = timezone;
  this.locale = locale;
  this.localeSpec = localeSpec;
  this.mode = mode;
  this.recalculation = recalculation;
};

exports.ApplicationConfiguration = ApplicationConfiguration;

ApplicationConfiguration.schema = function () {
  var builder = new StructPropBuilder_1.StructPropBuilder(ApplicationConfiguration.name);
  return {
    categoryLevel: builder.num('categoryLevel'),
    currencyCode: builder.str('currencyCode'),
    version: builder.str('version'),
    pricingVersion: builder.str('pricingVersion'),
    promoVersion: builder.str('promoVersion'),
    timezone: builder.str('timezone'),
    locale: builder.str('locale'),
    localeSpec: builder.custom('localeSpec', new CustomObjectParser_1.CustomObjectParser()),
    mode: builder.str('mode'),
    recalculation: builder.obj('recalculation', ApplicationRecalculation_1.ApplicationRecalculation.schema, function (mat) {
      return ApplicationRecalculation_1.ApplicationRecalculation.fromDataDict(mat);
    })
  };
}();