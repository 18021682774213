"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @param o1 object to compare with o2
 * @param o2 object to compare with o1
 * @returns true if objects are equal (even if both are null), false otherwise
 */

function isEqual(o1, o2) {
  if (!o1 && !o2) {
    return true;
  }

  if (!o1 || !o2) {
    return false;
  }

  if (!o1.value.eq(o2.value)) {
    return false;
  }

  return o1.unitId === o2.unitId;
}

exports.isEqual = isEqual;