/**
 * @file Created on Fri Nov 16 2018
 * @author SKu
 * @author Tomáš Leity
 */
import * as React from 'react';
import translate from '../../../../i18n/translate';
import Circle, { CircleWidth } from '../../../../typography/Circle';
/**
 * Render colorful circle before state name
 */

export var CircleStateRenderer = function CircleStateRenderer(params) {
  if (!params.value) {
    return null;
  }

  return React.createElement(React.Fragment, null, React.createElement(Circle, {
    color: params.value,
    width: CircleWidth.Normal,
    className: "mr1"
  }), React.createElement("strong", null, params.translate ? translate(params.value) : params.value));
};