import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n  ", ";\n\n  ", ";\n  \n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 20px;\n  position: relative;\n\n  &:before {\n    position: absolute;\n    top: 7px;\n    right: 9px;\n    transform: rotate(-40deg);\n    content: \"\";\n    width: 1px;\n    background-color: ", ";\n    height: 26px;\n  }\n\n  &:after {\n    position: absolute;\n    bottom: 7px;\n    right: 9px;\n    transform: rotate(40deg);\n    content: \"\";\n    width: 1px;\n    background-color: ", ";\n    height: 26px;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: 1px;\n  margin: 0;\n  background-color: ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  position: relative;\n  width: ", "rem;\n  height: 15px;\n  :before {\n    content: '|';\n    font-size: 140%;\n    position: absolute;\n    margin-left: ", "rem;\n    margin-top: -3px;\n    color: ", ";\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  color: ", ";\n  background-color: ", ";\n  height: 1px;\n  width: 100%;\n  border: 0px;\n  margin: ", "rem 0;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
import { DividerType } from '../DividerType';
import styled, { css, withTheme } from 'styled-components';
var DividerHorizontal = css(_templateObject(), function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.spacing.spacer;
});
var DividerVertical = css(_templateObject2(), function (props) {
  return props.theme.spacing.spacer * 2;
}, function (props) {
  return props.theme.spacing.spacer / 1.5;
}, function (props) {
  return props.theme.colors.border;
});
var DividerStatusVertical = css(_templateObject3(), function (props) {
  return props.theme.colors.border;
});
var DividerStatusProgress = css(_templateObject4(), function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.colors.border;
});
/**
 * Create an Divider component that'll render an <Divider> on the top of input
 */

var DividerStyled = styled.span(_templateObject5(), function (props) {
  return props.type === DividerType.Vertical ? DividerVertical : null;
}, function (props) {
  return props.type === DividerType.StatusVertical ? DividerStatusVertical : null;
}, function (props) {
  return props.type === DividerType.StatusProgress ? DividerStatusProgress : null;
}, function (props) {
  return !props.type ? DividerHorizontal : null;
});
export default withTheme(DividerStyled);