import React, { useCallback, useMemo } from 'react';
import createFilterOptions from 'react-select-fast-filter-options';
import { DateOlderThanValidator, DateYoungerThanValidator, OptionalDesc, SelloutImpactReportFilterDTO, ProductUtils } from '@logio/common-be-fe';
import { BoxDefault, ButtonLoading, DateRange, Field, Form, Icon, IconType, translate, FormGroupInline, GroupActions, ButtonColor, ButtonSize, StringMapping, ValidationUtils, CONSTANTS, Tooltip, MultiInput, OptionsProvider, SelectOptions, SelectAdapter } from '@logio/common-fe';
import { List } from 'immutable';
import { SelloutImpactReportDetailGenerationState } from '../../../stores/pages/Reports/SelloutImpactReport/SelloutImpactReportPageStore';
import moment from 'moment-timezone';
import { PriceZoneField } from '../../../components/PriceZoneField/PriceZoneField';

interface SelloutImpactReportFilterProps {
  initialValues: SelloutImpactReportFilterDTO;
  onSubmit(filterDTO: SelloutImpactReportFilterDTO): void;
  hidden?: boolean;
  validateProducts(productIds: List<string>): Promise<any>;
  onGenerateReport: (() => void) | null;
  generationState: SelloutImpactReportDetailGenerationState;
  selectedReleases: List<string>;
}

/**
 * Renders filter form (including box around) for Sellout Termination Report.
 * TODO JPk: restrict "date from" and "date to" so user cannot pick invalid date.
 * 
 * @param props
 */
export default function SelloutImpactReportFilter(props: SelloutImpactReportFilterProps) {
  const onSubmit = useCallback((value: any, form) => {
    props.onSubmit(prepareFilter(value));
  }, [props.onSubmit]);

  const yesNoOptions = [{ label: 'yes', value: 'yes' }, { label: 'no', value: 'no' }];
  const countOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => ({ label: `${num}x`, value: `${num + 1}` }));
  const maxDateTo = useMemo(() => moment().subtract(1, 'day').endOf('day'), []);

  return (
    <BoxDefault className={`mb1${props.hidden ? ' dn' : ''}`}>
      <Form
        descriptions={SelloutImpactReportFilterDTO.schema}
        formLayoutVertical
        initialValues={props.initialValues}
        onSubmit={onSubmit}
      >
        <FormGroupInline>
          <DateRange
            required={!(SelloutImpactReportFilterDTO.schema.dateFrom instanceof OptionalDesc)}
            maxDateTo={maxDateTo}
          />
          <div className="pb0 mt-auto">
            <Field name="categoryIds" className="err-form-vertical" />
          </div>
          <div className="pb0 mt-auto">
            <Field name="selloutTypes" />
          </div>
          <div className="pb0 mt-auto">
            <PriceZoneField name="priceZoneId" label="SelloutTerminationReportFilterDTO_priceZoneId" />
          </div>
          <div className="pb0 mt-auto">
            <OptionsProvider
              name="siteIds"
              dependsOn={{ priceZoneId: 'priceZoneId' }}
              component={(options: SelectOptions) => (
                <Field
                  name="siteIds"
                  component={
                    SelectAdapter(
                      'itemSiteIds',
                      options,
                      true,
                      false,
                      false,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      true,
                    )
                  }
                />
              )}
            />
          </div>
        </FormGroupInline>
        <FormGroupInline>
          <div className="pb0 mt-auto">
            <Field name="supplierIds" />
          </div>
          <div className="pb0 mt-auto">
            <Field
              name="repriced"
              component={
                (fieldProps) => SelectAdapter(
                  'repriced',
                  {
                    options: countOptions,
                    filterOptions: createFilterOptions({ options: countOptions }),
                  },
                  true,
                )({
                  ...fieldProps,
                  input: {
                    ...fieldProps.input,
                    value: fieldProps.input.value ? fieldProps.input.value.toArray().map(val => val.toString()) : [],
                    onChange: value => {
                      fieldProps.input.onChange(value.length ? List(value.map(val => parseInt(val, 10))) : null);
                    },
                  }
                })
              }
            />
          </div>
          <div className="pb0 mt-auto">
            <Field
              name="productExternalIds"
              component={MultiInput}
              validate={props.validateProducts}
            />
          </div>
          <div className="mt-auto ml-auto flex items-center">
            <ButtonLoading iconRight isLoading={false}>
              {translate('Find')} <Icon iconType={IconType.search} />
            </ButtonLoading>
            <GroupActions selectedRowsCount={props.selectedReleases.size} className="pb0 ml2">
              {({disabled}) => (
                <ButtonLoading
                  iconRight
                  isLoading={props.generationState === SelloutImpactReportDetailGenerationState.Preparing}
                  disabled={disabled || props.generationState !== SelloutImpactReportDetailGenerationState.Pending}
                  type="button"
                  onClick={props.onGenerateReport}
                >
                  {translate('SelloutImpactReport-GenerateReport')}
                </ButtonLoading>
              )}
            </GroupActions>
          </div>
        </FormGroupInline>
      </Form>
    </BoxDefault>
  );
}

function prepareFilter(value: any) {
  return SelloutImpactReportFilterDTO.fromDataDict({
    ...value,
    // Add `:1` for external product ids - probably not optimal and should fix on BE as regex
    productExternalIds: value.productExternalIds ? value.productExternalIds.map(ProductUtils.goldIdToProductExternalId) : null,
  });
}
