"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var ReleasePriceCalculationOutput = function ReleasePriceCalculationOutput(state, stateDetail,
/**
 * Field which can be used by an calculator's implementation for saving an arbitrary data.
 * Data must be convertible to a mongo document.
 */
data) {
  _classCallCheck(this, ReleasePriceCalculationOutput);

  this.state = state;
  this.stateDetail = stateDetail;
  this.data = data;
};

exports.ReleasePriceCalculationOutput = ReleasePriceCalculationOutput;