/**
 * Injects script into the document. Can be used for external scripts like analytics, which depends on config.
 * @param src
 * @author JPk
 */
export function injectScript(src) {
  return new Promise(function (resolve, reject) {
    var script = document.createElement('script');
    script.src = src;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
}