"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Message keys related to AbstractReleaseItem.
 */

var ReleaseItemMessageCode;

(function (ReleaseItemMessageCode) {
  /**
   * Sellout min price change in percent has changed
   */
  ReleaseItemMessageCode["SelloutMinPriceChanged"] = "SelloutMinPriceChanged";
  /**
   * Sellout max price change in percent has changed
   */

  ReleaseItemMessageCode["SelloutMaxPriceChanged"] = "SelloutMaxPriceChanged";
  /**
   * Sellout min margin change in percent has changed
   */

  ReleaseItemMessageCode["SelloutMinMarginChanged"] = "SelloutMinMarginChanged";
  /**
   * End date of final price has changed.
   */

  ReleaseItemMessageCode["FinalPriceEndDateChanged"] = "FinalPriceEndDateChanged";
  /**
   * Release item is in conflict with another item.
   */

  ReleaseItemMessageCode["ReleaseItemConflicted"] = "ReleaseItemConflicted";
  /**
   * A product associated with a release item is not active.
   */

  ReleaseItemMessageCode["ReleaseItemProductNotListed"] = "ReleaseItemProductNotListed";
  /**
   * Product assigned to family <name of the family>.
   */

  ReleaseItemMessageCode["ProductAssignedToFamily"] = "ProductAssignedToFamily";
  /**
   * The product was removed from the family <name of the family>.
   */

  ReleaseItemMessageCode["ProductRemovedFromFamily"] = "ProductRemovedFromFamily";
  /**
   * Size ratio or size charger changed in family <name of the family>.
   */

  ReleaseItemMessageCode["SizersChangedInFamily"] = "SizersChangedInFamily";
  /**
   * Product was deactivated.
   */

  ReleaseItemMessageCode["ProductDeactivated"] = "ProductDeactivated";
})(ReleaseItemMessageCode = exports.ReleaseItemMessageCode || (exports.ReleaseItemMessageCode = {}));