/**
 * @file Created on Mon Jan 28 2019
 * @author SKu
 */

import {Page, RadioGroup} from '@logio/common-fe';
import SelloutSettingsComponent from '../../components/SettingsOverview/SelloutSettingsComponent';
import {observer} from 'mobx-react';
import * as React from 'react';
import {PageNamesEnum} from '../../shared/localization/PageNamesEnum';
import {SelloutSettingsPageStore} from '../../stores/pages/Settings/SelloutSettingsPageStore';

/**
 * Sellout settings pages
 * Settings dont influence some calculation,
 * used only as user helper on Crete Release Page
 */
@observer
class SelloutSettingsPage extends React.Component {
  store = new SelloutSettingsPageStore();

  componentWillMount() {
    this.store.load();
  }

  render() {
    return (
      <Page titleKey={PageNamesEnum.SelloutSettings} loadingState={this.store.loadingState}>
        <SelloutTypePicker store={this.store} />
        <SelloutSettingsComponent store={this.store.selloutSettingsComponentStore} />
      </Page>
    );
  }
}

export default SelloutSettingsPage;

/** Allows too pick current sellout type  */
const SelloutTypePicker: React.SFC<{store: SelloutSettingsPageStore}> = observer(
  ({store}: {store: SelloutSettingsPageStore}) => {
    return (
      <div className="tac mb2">
        <RadioGroup
          name="radio-sellout-settings-picker"
          typeButton
          options={store.selloutSettingsComponentStore.priceListTypesOptions}
          value={store.selloutSettingsComponentStore.priceListTypeId}
          onChange={store.selloutSettingsComponentStore.onPriceListTypeChange}
        />
      </div>
    );
  },
);
