/**
 * @file Created on Fri Nov 23 2018
 * @author BPo
 */

import {
  PriceZone,
  StrategyTypeEnum,
  ConstraintTypeEnum,
  ValidationError,
} from '@logio/common-be-fe';
import {
  Button,
  CONSTANTS,
  Form,
  FormGroup,
  T,
  Tooltip,
  translate,
  SelectAdapter, ButtonColor,
} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';
import {Col, Row} from 'react-flexbox-grid';
import {MatrixTablePageStore} from '../../../../stores/pages/Settings/GeneralSettings/MatrixTablePageStore';
import {MatrixItem} from './MatrixItem';
import {OptimizationStrategy} from '../OptimizationStrategy';
import {FormSpy} from 'react-final-form';
import { OptimizationGoal } from '@logio/common-be-fe';

interface MatrixTableProps {
  store: MatrixTablePageStore;
  categoryId: string;
  priceZone: PriceZone;
  releaseId?: string;
  optimizationGoalsSettings: OptimizationGoal;
}

@observer
export class MatrixTable extends React.Component<MatrixTableProps> {
  /**
   * Returns map of rows for the specific matrix table
   * @param priceZone Price zone for the given table
   */
  getRows(priceZone: PriceZone): Map<string, JSX.Element> {
    const rows = new Map<string, JSX.Element>();
    rows.set(
      'optimizationGoalsSettings',
      <tr key="optimizationGoalsSettings">
        <td className="bg-header uppercase c-paper fw-600">
          <Tooltip
            overlay={translate('matrix_table-optimizationGoalsSettings_desc')}
            placement="right"
            children={
              <span>
                <T id="matrix_table-optimizationGoalsSettings"/>
              </span>
            }
          />
        </td>
        <MatrixItem
          colSpan={this.props.store.columns.length}
          fields={[
            {
              name: 'optimizationGoalsSettings',
            },
          ]}
          changedValues={this.props.store.changedValues}
          isFieldEditable={this.props.store.isFieldEditable}
          placeholderValues={this.props.store.getPlaceholderValues(priceZone.id)}
          priceZoneId={priceZone.id}
          categoryId={this.props.categoryId}
        />
        <td className="bg-header uppercase c-paper fw-600">
          [<T id="matrix_table-optimizationGoalsSettings-unit"/>]
        </td>
      </tr>,
    );

    // HACK: AverageMargin is not set per each group separately but for all at once.
    rows.set(
      ConstraintTypeEnum.AverageMargin,
      <tr key={`tr${ConstraintTypeEnum.AverageMargin}`}>
        <td className="bg-header uppercase c-paper fw-600">
          <Tooltip
            overlay={translate(ConstraintTypeEnum.AverageMargin + '_DESC')}
            placement="right"
            children={
              <span>
                <T id={ConstraintTypeEnum.AverageMargin}/>
              </span>
            }
          />
        </td>
        <MatrixItem
          colSpan={this.props.store.columns.length}
          fields={[
            {
              name: ConstraintTypeEnum.AverageMargin,
            },
          ]}
          changedValues={this.props.store.changedValues}
          isFieldEditable={this.props.store.isFieldEditable}
          placeholderValues={this.props.store.getPlaceholderValues(priceZone.id)}
          priceZoneId={priceZone.id}
          categoryId={this.props.categoryId}
        />
        <td className="bg-header uppercase c-paper fw-600">
          [{translate(`matrix_table-${ConstraintTypeEnum.AverageMargin}-unit`)}]
        </td>
      </tr>,
    );

    rows.set(
      'competitorHistoryLength',
      <tr key="competitorHistoryLength">
        <td className="bg-subHeader uppercase c-paper fw-600">
          <T id="matrix_table-competitorHistoryLength"/>
        </td>
        <MatrixItem
          colSpan={this.props.store.columns.length}
          fields={[
            {
              name: 'competitorHistoryLength',
            },
          ]}
          changedValues={this.props.store.changedValues}
          isFieldEditable={this.props.store.isFieldEditable}
          placeholderValues={this.props.store.getPlaceholderValues(priceZone.id)}
          priceZoneId={priceZone.id}
          categoryId={this.props.categoryId}
        />
        <td className="bg-subHeader uppercase c-paper fw-600">
          [<T id="matrix_table-competitorHistoryLength-unit"/>]
        </td>
      </tr>,
    );
    rows.set(
      'strategySettings',
      <tr key="strategySettings">
        <td className="bg-subHeader uppercase c-paper fw-600">
          <T id="matrix_table-strategySettings"/>
        </td>
        {this.props.store.columns.map((sensitivity, index) => (
          <MatrixItem
            fields={[
              {
                name: `strategySettings-${sensitivity.id}`,
              },
              {
                name: `competitorId-${sensitivity.id}`,
                fieldShow: {
                  [`strategySettings-${sensitivity.id}`]: [
                    StrategyTypeEnum.FollowOneSelected,
                    StrategyTypeEnum.NotCheaperThan,
                    StrategyTypeEnum.NotMoreExpensiveThan,
                  ],
                },
              },
              {
                name: `competitorIds-${sensitivity.id}`,
                fieldShow: {
                  [`strategySettings-${sensitivity.id}`]: [
                    StrategyTypeEnum.FollowCheapest,
                    StrategyTypeEnum.FollowMode,
                    StrategyTypeEnum.FollowMostExpensive,
                  ],
                },
              },
            ]}
            changedValues={this.props.store.changedValues}
            isFieldEditable={this.props.store.isFieldEditable}
            key={`${sensitivity.id}-${index}`}
            placeholderValues={this.props.store.getPlaceholderValues(priceZone.id)}
            priceZoneId={priceZone.id}
            categoryId={this.props.categoryId}
          />
        ))}
        <td className="bg-subHeader uppercase c-paper fw-600">
          [<T id="matrix_table-strategySettings-unit"/>]
        </td>
      </tr>,
    );

    rows.set(
      'competitorsPriorityId',
      <tr key="competitorsPriorityId">
        <td className="bg-subHeader uppercase c-paper fw-600">
          <T id="matrix_table-competitorsPriorityId"/>
        </td>
        {this.props.store.columns.map((sensitivity, index) => (
          <MatrixItem
            fields={[
              {
                name: `competitorsPriorityId-${sensitivity.id}`,
              },
            ]}
            changedValues={this.props.store.changedValues}
            isFieldEditable={this.props.store.isFieldEditable}
            placeholderValues={this.props.store.getPlaceholderValues(priceZone.id)}
            priceZoneId={priceZone.id}
            key={index}
            categoryId={this.props.categoryId}
          />
        ))}
        <td className="bg-subHeader uppercase c-paper fw-600">
          [<T id="matrix_table-competitorsPriorityId-unit"/>]
        </td>
      </tr>,
    );

    rows.set(
      'priceDistance',
      <tr key="priceDistance">
        <td className="bg-subHeader uppercase c-paper fw-600">
          <Tooltip
            overlay={translate('matrix_table-priceDistance_DESC')}
            placement="right"
            children={
              <span>
                <T id="matrix_table-priceDistance"/>
              </span>
            }
          />
        </td>
        {this.props.store.columns.map((sensitivity, index) => (
          <MatrixItem
            fields={[
              {
                name: `priceDistance-${sensitivity.id}`,
              },
            ]}
            changedValues={this.props.store.changedValues}
            isFieldEditable={this.props.store.isFieldEditable}
            placeholderValues={this.props.store.getPlaceholderValues(priceZone.id)}
            priceZoneId={priceZone.id}
            key={index}
            categoryId={this.props.categoryId}
          />
        ))}
        <td className="bg-subHeader uppercase c-paper fw-600">
          [<T id="matrix_table-priceDistance-unit"/>]
        </td>
      </tr>,
    );

    this.props.store.rows.map((constraintType) => {
      rows.set(
        constraintType,
        <tr key={`tr${constraintType}`}>
          <td>
            <Tooltip
              overlay={translate(constraintType + '_DESC')}
              placement="right"
              children={
                <span>
                  <T id={constraintType}/>
                </span>
              }
            />
          </td>
          {this.props.store.columns.map((sensitivity, index) => {
            const props = {
              changedValues: this.props.store.changedValues,
              isFieldEditable: this.props.store.isFieldEditable,
              placeholderValues: this.props.store.getPlaceholderValues(priceZone.id),
              priceZoneId: priceZone.id,
              categoryId: this.props.categoryId,
            };
            return (
              <MatrixItem
                key={index}
                fields={[
                  {
                    name: `${constraintType}-${sensitivity.id}`,
                    component:
                    // Custom component used to add validation for None option...
                      constraintType === ConstraintTypeEnum.WeekDayRestriction
                        ? (params) => {
                          const {value} = params.input;
                          let validationError = null;
                          if (value.length > 1 && value.includes('0')) {
                            validationError = new ValidationError(translate('err-none-should-be-alone'));
                          }
                          return SelectAdapter(
                            `${constraintType}-${sensitivity.id}`,
                            this.props.store.weekDaySelectOptions,
                            true,
                            false,
                            true,
                            true,
                            '',
                            validationError,
                          )(params);
                        }
                        : undefined,
                  },
                ]}
                {...props}
              />
            );
          })}
          <td>[{translate(`matrix_table-${constraintType}-unit`)}]</td>
        </tr>,
      );
    });

    return rows;
  }

  /**
   * Returns sorted array of rows for the specific matrix table.
   *
   * @param priceZone Price zone for the given table
   * @param order The order of rows in matrix tables. This is an array of
   * strings where each string is the name of the item from the Settings schema.
   */
  getSortedRows(priceZone: PriceZone, order = CONSTANTS.MATRIX_TABLE_SORT_ARRAY): JSX.Element[] {
    const rowMap = this.getRows(priceZone);
    const rowArray = Array.from(rowMap);

    if ((order || []).length === 0) {
      return rowArray.map(([, el]) => el);
    }

    const ordered: JSX.Element[] = [];
    // Sort by names
    order.forEach((key) => {
      ordered.push(rowMap.get(key));
    });

    // Append to the end those that failed to sort
    if (rowArray.length !== ordered.length) {
      rowArray.forEach(([key, value]) => {
        if (!order.includes(key)) {
          ordered.push(value);
        }
      });
    }

    return ordered;
  }

  render() {
    const currentCategoryLevel = this.props.store.productCategory && this.props.store.productCategory.level;
    const bottomCategoryLevel = this.props.store.generalConfigurationsStore.config.categoryLevel;
    return (
      <Row className="flex-grow-1">
        <Col xs>
          <Form
            key={this.props.priceZone.id}
            descriptions={this.props.store.getDescriptions()}
            initialValues={this.props.store.getInitialValues(this.props.priceZone.id)}
            onChange={this.props.store.onChange(this.props.priceZone.id)}
            onSubmit={this.props.store.onSave(this.props.priceZone, this.props.categoryId, this.props.releaseId)}
            formLayoutVertical={true}
          >
            <div style={{overflowX: 'scroll', maxWidth: 'calc(100vw - 11rem)'}}>
              <table className="table table-stripedX">
                <thead>
                <tr>
                  <th className="minw25">{this.props.priceZone.name}</th>
                  {this.props.store.columns.map((sensitivity) => (
                    <th className="minw15" key={sensitivity.id}>
                      <T id={sensitivity.title}/>
                      {sensitivity.isDefault ? <span style={{whiteSpace: 'pre'}}> *</span> : ''}
                    </th>
                  ))}
                  <th style={{minWidth: '6rem'}}>
                    <T id="Unit"/>
                  </th>
                </tr>
                </thead>
                <tbody>{this.getSortedRows(this.props.priceZone)}</tbody>
              </table>
            </div>

            <FormGroup className="mt1 mb2" hidden={!this.props.store.isSettingsEditable()}>
              <Button type="submit">
                <T id="Save"/>
              </Button>
              <Button
                type="button"
                onClick={this.props.store.onDelete(
                  this.props.priceZone.id,
                  this.props.categoryId,
                  this.props.releaseId,
                )}
                buttonColor={ButtonColor.Light}
              >
                <T id="matrix_table-delete_settings"/>
              </Button>
            </FormGroup>

            <FormGroup className="mt1 mb2">
              <FormSpy subscription={{values: true}}>
                {({values, form}) => (
                  !this.props.priceZone.isNational && // viz https://jira.etnetera.cz/browse/LOG-4473?focusedCommentId=320759&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-320759
                  this.props.store.optimizationStrategy.has(this.props.priceZone.id) &&
                  currentCategoryLevel === bottomCategoryLevel && (
                    <OptimizationStrategy
                      categoryId={this.props.categoryId}
                      releaseId={this.props.releaseId}
                      priceZoneId={this.props.priceZone.id}
                      matrixTableStore={this.props.store}
                      optimizationGoalsSettings={(!values.optimizationGoalsSettings) ? this.props.optimizationGoalsSettings : values.optimizationGoalsSettings}
                      setOptimizationGoalSettings={(optimizationGoalsSettings) => {
                        form.change('optimizationGoalsSettings', optimizationGoalsSettings);
                      }}
                      averageMargin={values.AVERAGE_MARGIN}
                      setAverageMargin={(averageMargin) => form.change('AVERAGE_MARGIN', averageMargin)}
                      store={this.props.store.optimizationStrategy.get(this.props.priceZone.id)}
                    />)
                )}
              </FormSpy>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    );
  }
}
