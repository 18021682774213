/**
 * @file Created on Mon Aug 13 2018
 * @author VBr
 * @author LZe
 */

import {DataDesc, GroupOfSites, PriceZone, Utils, StructPropBuilder, PricingPermissions} from '@logio/common-be-fe';
import {
  ActionsGenerator,
  ColumnDefinition,
  ColumnGenerator,
  GroupOfSitesStore,
  Icon,
  IconColor,
  IconType,
  LoadingState,
  PageStore,
  PriceZoneStore,
  rootStore,
  StoreName,
  StringMapping,
  translate,
  KeycloakStore,
} from '@logio/common-fe';
import {List} from 'immutable';
import {ColumnApi, GridApi, GridReadyEvent} from 'ag-grid-community';
import {action, computed, observable, runInAction} from 'mobx';
import * as React from 'react';
import {PollingHelper} from '../../../components/PollingHelper';

// FIXME: SKu Why is this named ModalEnum? This is page specific...
// FIXME: SKu Use same naming convention - See https://www.typescriptlang.org/docs/handbook/enums.html
export enum ModalEnum {
  CREATE = 'Create',
  EDIT = 'Edit',
  ARCHIVE = 'Archive',
}

export interface ModalHiddenProps {
  Create: boolean;
  Edit: boolean;
  Archive: boolean;
}

export interface ArchiveParams {
  priceZone?: PriceZone;
}

export class PriceZoneForm {
  constructor(
    public id: string,
    public name: string,
    public groupOfSitesId: string,
    public description: string | null,
    public competitorSitesIds: List<string> = List(),
    public archived: boolean | null = null,
  ) {}
}

export class PriceZonePageStore extends PageStore {
  /** Get dependant stores */
  priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;
  groupOfSitesStore = rootStore.getStore(StoreName.GroupOfSites) as GroupOfSitesStore;
  keycloakStore = rootStore.getStore(StoreName.Keycloak) as KeycloakStore;

  /** Derived columns data description builder */
  builder = new StructPropBuilder('PriceZones');

  /** Derived columns data description */
  customColumnsDescription: DataDesc = {
    competitorSitesIds: this.builder.num('competitorSitesIds'),
  };

  /** Determines state of component LoadingButton inside Create/Edit form */
  @observable
  buttonLoadingState = false;

  /** Data generators */
  actionsGenerator = new ActionsGenerator();
  priceZoneGenerator = new ColumnGenerator<PriceZone>(PriceZone.schema);
  groupOfSitesGenerator = new ColumnGenerator<GroupOfSites>(GroupOfSites.schema);
  customColumnGenerator = new ColumnGenerator<StringMapping<any>>(this.customColumnsDescription);

  @observable
  isPolling = false;

  @observable
  gridApi: GridApi;

  @observable
  columnApi: ColumnApi;

  @observable
  modalHidden: ModalHiddenProps = {
    Create: true,
    Edit: true,
    Archive: true,
  };

  @observable
  initialValues: PriceZone = null;

  @observable
  archiveParams: ArchiveParams = {
    priceZone: undefined,
  };

  /**
   * Returns generated column definitions for ag-grid
   */
  @computed
  get columnDefs(): ColumnDefinition[] {
    const colDefs: ColumnDefinition[] = [];
    const actionDefinition: ColumnDefinition = {
      headerCheckboxSelection: false,
      checkboxSelection: false,
      cellClass: 'ag-cell-align-center',
    };

    /**
     * Function handles archive icon rendering
     * If zone is archived , it has 'disabled' class on it
     * If page is in readOnly mode, even not archived zone will not have onClick method on it,
     * @param param0 - callback from Ag-grid
     */
    const archiveRendererFramework = ({value, data}) => {
      return data.PriceZone_isNational ? (
        <Icon iconType={IconType.block} iconHeight={20} iconColor={IconColor.Secondary} />
      ) : (
        <Icon
          iconType={IconType.archive}
          iconHeight={20}
          disabled={value}
          iconColor={IconColor.Secondary}
          onClick={!this.pageReadOnly ? this.toggleArchive(this.priceZoneStore.list.get(data.PriceZone_id)) : undefined}
        />
      );
    };

    const priceZoneDefinition: ColumnDefinition[] = [
      {
        field: 'archived',
        width: 90,
        cellRendererFramework: archiveRendererFramework,
        booleanFilterMap: {
          true: translate('archived'),
          false: translate('active'),
        },
      },
      {field: 'name'},
    ];
    const groupOfSitesDefinition: ColumnDefinition[] = [{field: 'name'}];
    const customDefinition: ColumnDefinition[] = [
      /** The number of competitor sites is counted on FE */
      {field: 'competitorSitesIds'},
    ];

    if (!this.pageReadOnly) {
      colDefs.push(this.actionsGenerator.getColumnDefinition(actionDefinition));
    }

    colDefs.push(
      ...this.priceZoneGenerator.getColumnDefinitions(priceZoneDefinition),
      ...this.groupOfSitesGenerator.getColumnDefinitions(groupOfSitesDefinition),
      ...this.customColumnGenerator.getColumnDefinitions(customDefinition),
    );

    return colDefs;
  }

  /**
   * Returns generated data for ag-grid
   */
  @computed
  get rowData(): Array<StringMapping<any>> {
    const rowData = [];
    this.priceZoneStore.list.forEach((zone: PriceZone) => {
      const editZone = {
        name: 'edit',
        icon: IconType.edit,
        disabled: zone.archived,
        props: {onClick: this.toggleEdit(zone)},
      };

      /** Each generator return data definition from related data description */
      rowData.push({
        ...this.actionsGenerator.getColumnData(editZone),
        ...this.priceZoneGenerator.getColumnData(zone!),
        ...this.groupOfSitesGenerator.getColumnData(this.groupOfSitesStore.list.get(zone.groupOfSitesId)!),
        /** Count of competitor site ids */
        ...this.customColumnGenerator.getColumnData({
          competitorSitesIds: zone.competitorSitesIds.size,
        }),
      });
    });
    return rowData;
  }

  get defaultFilterModel(): StringMapping<any> {
    return {
      [PriceZone.schema.archived.description.nameKey]: ['false'], // With the actual version of the AgGrid, false has to be string #$@!!!
    };
  }

  /**
   * Fetches all data for this page
   */
  @action.bound
  load = async (): Promise<void> => {
    const groupOfSiteIds = [];
    try {
      await this.priceZoneStore.getAll();
      this.priceZoneStore.list.forEach((zone) => groupOfSiteIds.push(zone.groupOfSitesId));
      await this.groupOfSitesStore.getFiltered(groupOfSiteIds);
      this.setReadOnly(!this.keycloakStore.userHasPermissions([PricingPermissions.ZONES_MANAGE_EDIT]));
      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      this.messages.setError(error);
    }
  };
  /**
   * Negate modalHiddenProp
   *
   * @param title - name of the modalHidden prop that should be changed
   */
  toggleModal = (title: string) => runInAction(() => (this.modalHidden[title] = !this.modalHidden[title]));

  /**
   * HOF for togglemodal
   */
  getModalToggleEvent = (title: string) => () => {
    this.toggleModal(title);
    this.messages.clear();
  };

  /**
   * Function sets initial values
   * and than opens edit modal
   */
  toggleEdit = (pricezone: PriceZone) => async () => {
    this.setInitialValues(pricezone);
    this.toggleModal(ModalEnum.EDIT);
    this.messages.clear();
  };

  /**
   * Function sets initial values to empty price zone
   * and than opens edit modal
   */
  toggleCreate = () => () => {
    this.setInitialValues(PriceZone.EMPTY);
    this.toggleModal(ModalEnum.CREATE);
    this.messages.clear();
  };

  /**
   * Function sets initial values
   * and than opens edit modal
   */
  toggleArchive = (priceZone: PriceZone) => () => {
    runInAction(() => {
      this.archiveParams.priceZone = priceZone;
    });
    this.toggleModal(ModalEnum.ARCHIVE);
    this.messages.clear();
  };

  /**
   * Function sets initial values to observable
   * @param pricezone - containes data of toggled pricezone
   */
  setInitialValues = (pricezone: PriceZone) => runInAction(() => (this.initialValues = pricezone));

  @action.bound
  onArchiveAccept() {
    this.priceZoneStore
      .archive(this.archiveParams.priceZone)
      .then(() => {
        this.toggleModal(ModalEnum.ARCHIVE);
        this.messages.setSuccess(translate('PriceZones_zoneArchived', this.archiveParams.priceZone.name));
      })
      .catch((error) => {
        this.messages.setError(error);
      });
  }

  /**
   * Function edits pricezone on submit
   * @param pricezone - containes new pricezone data
   */
  onEditSubmit = async (priceZone: PriceZone): Promise<void> => {
    runInAction(() => (this.buttonLoadingState = true));
    try {
      await this.priceZoneStore.update(priceZone);
      this.toggleModal(ModalEnum.EDIT);
      this.messages.setSuccess(translate('PriceZones_zoneEdited', priceZone.name));
    } catch (error) {
      this.messages.setError(error);
    }
    runInAction(() => (this.buttonLoadingState = false));
  };

  /**
   * Creates new PriceZone with PriceZoneLayer
   *
   * @param name - name of the new PriceZone
   * @param groupOfSitesId - id of Selected group of sites for new PriceZone
   * @param description - description of the new PriceZone
   */
  onCreateSubmit = async (priceZone: PriceZone): Promise<void> => {
    runInAction(() => (this.buttonLoadingState = true));
    try {
      runInAction(() => (this.isPolling = true));
      const progress = await this.priceZoneStore.priceZoneLayer.createWithProgress(priceZone);
      if (progress) {
        //const pollingCouldBeStarted = await this.pollingHelper.couldPollingBeStarted(progress);
        //if (pollingCouldBeStarted) {
        this.pollingHelper.startPolling(progress, translate('PriceZones_zoneCreated', priceZone.name));
        this.toggleModal(ModalEnum.CREATE);
        // }
      }
    } catch (error) {
      this.messages.setError(error);
    }
    runInAction(() => (this.buttonLoadingState = false));
  };

  private onPollingStateChanged = (pollingState: LoadingState) => {
    if (pollingState === LoadingState.Success) {
      this.load();
      runInAction(() => (this.isPolling = false));
    }
    if (pollingState === LoadingState.Error) {
      runInAction(() => (this.isPolling = false));
    }
  };

  pollingHelper = new PollingHelper(this.messages, this.onPollingStateChanged, 1000);

  @action.bound
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }
}
