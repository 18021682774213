"use strict";
/*
 * Created on 29.11.2018
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author MSu
 */

var PromotionActionType;

(function (PromotionActionType) {
  PromotionActionType["Update"] = "UPDATE";
})(PromotionActionType = exports.PromotionActionType || (exports.PromotionActionType = {}));