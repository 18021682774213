"use strict";
/**
 * @file Created on Wed Apr 17 2019
 * @author Tomáš Leity
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
var KpiEngineSetKeyEnum;

(function (KpiEngineSetKeyEnum) {
  KpiEngineSetKeyEnum["KEY_NORMAL"] = "normal";
  KpiEngineSetKeyEnum["KEY_SIMULATION"] = "simulation";
})(KpiEngineSetKeyEnum = exports.KpiEngineSetKeyEnum || (exports.KpiEngineSetKeyEnum = {}));