"use strict";
/*
 * Created on Fri Jul 27 2018
 */

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * HTTP header names.
 *
 * @author Radek Beran
 */

var HeaderNames = function HeaderNames() {
  _classCallCheck(this, HeaderNames);
};

exports.HeaderNames = HeaderNames;
HeaderNames.X_PP_SUBSTITUTED_USER = 'X-PP-Substituted-User';
HeaderNames.AUTHORIZATION = 'Authorization';
HeaderNames.CONTENT_TYPE = 'Content-Type';