/**
 * @file Created on Tue Mar 14 2020
 * @author MHl
 */

import {
  PageStore,
  ColumnDefinition,
  ColumnGenerator,
  StringMapping,
  LoadingState,
  SiteReleaseLayer,
  RendererNames,
  SiteReleaseItemExported,
  SiteReleaseItemPriceValue,
  SiteReleaseItemValidFrom,
  rootStore,
  StoreName,
  FamilyStore,
  SupplierStore,
  ProductSensitivityStore,
  TierStore,
  ProductCategoryStore,
  CodeBookItemLayer,
  ProductStore,
  translate,
  PriceZoneStore,
  DownloadStore,
} from '@logio/common-fe';
import {observable, action, computed, runInAction} from 'mobx';
import {
  ReleaseState,
  SiteRelease,
  SiteReleaseItem,
  Utils,
  StructPropBuilder,
  CodebookLookupSequence,
  CodebookItem,
  ZonePrices,
  PriceZone,
  DataDesc,
} from '@logio/common-be-fe';
import {match} from 'react-router';
import {History} from 'history';
import {PagePathsEnum} from '../../../../shared/localization/PagePathsEnum';
import {List} from 'immutable';
import {PollingHelper} from '../../../components/PollingHelper';
import {ColumnApi, GridApi, GridReadyEvent} from 'ag-grid-community';

export interface IRouteParams {
  releaseId: string;
}

export class RegularSiteReleaseDetailPageStore extends PageStore {
  releasePollingHelper: PollingHelper;
  excellPollingHelper: PollingHelper;
  codeBookItemLayer: CodeBookItemLayer = new CodeBookItemLayer();
  downloadStore = rootStore.getStore(StoreName.Download) as DownloadStore;

  private familyStore = rootStore.getStore(StoreName.Family) as FamilyStore;
  private supplierStore = rootStore.getStore(StoreName.Supplier) as SupplierStore;
  private productSensitivityStore = rootStore.getStore(StoreName.ProductSensitivity) as ProductSensitivityStore;
  private tierStore = rootStore.getStore(StoreName.Tier) as TierStore;
  private categoryStore = rootStore.getStore(StoreName.ProductCategory) as ProductCategoryStore;
  private productStore = rootStore.getStore(StoreName.Product) as ProductStore;
  private priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;

  private readonly builder = new StructPropBuilder('SiteReleaseItem');

  private siteReleaseLayer = new SiteReleaseLayer();

  @observable
  private releaseItemsColumnGenerator: ColumnGenerator<any>;

  /**
   * List of price zones which are not archived
   */
  @observable
  private priceZoneIdsInUse: List<string>;

  @observable
  release: SiteRelease;

  @observable
  releaseItems: Map<string, SiteReleaseItem> = new Map<string, SiteReleaseItem>();

  @observable
  isConfirmModalHidden: boolean = true;

  @observable
  isCancellModalHidden: boolean = true;

  @observable
  priceListTypes: CodebookItem[];

  @observable
  exportIsLoading: boolean = false;

  /** Ag-Grid api references */
  private gridApi: GridApi;
  private columnApi: ColumnApi;

  constructor(
    public readonly history: History,
    // tslint:disable-next-line:no-shadowed-variable
    public readonly match: match<IRouteParams>,
    private readonly releaseId: string,
  ) {
    super();
    this.releasePollingHelper = new PollingHelper(this.messages, this.onReleasePoolingChange, undefined, true);
    this.excellPollingHelper = new PollingHelper(this.messages, this.onExportPollingStateChanged);
  }

  /**
   * Binds ag-grid api
   */
  @action.bound
  public onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  @action
  showConfirmModal = () => (this.isConfirmModalHidden = false);

  @action
  hideConfirmModal = () => (this.isConfirmModalHidden = true);

  @action
  showCancellModal = () => (this.isCancellModalHidden = false);

  @action
  hideCancellModal = () => (this.isCancellModalHidden = true);

  @computed
  public get enableHeaderButtons(): boolean {
    return this.release && this.release.state === ReleaseState.Opened;
  }

  @computed
  public get enableExportButton(): boolean {
    let exported: boolean;
    // tslint:disable-next-line: no-unused-expression
    this.releaseItems && this.releaseItems.forEach((item) => (item.exported ? (exported = true) : null));
    return exported;
  }

  @computed
  get releaseItemsColumnDefinitions(): ColumnDefinition[] {
    if (!this.releaseItemsColumnGenerator) {
      return [];
    }

    const colDefs = [
      {field: 'article'}, // Article
      {field: 'siteId'}, // siteId
      {field: 'siteName'}, // Site Name
      {
        field: 'supplier',
        valueGetter: ({data}) =>
          this.supplierStore.list.get(data.SiteReleaseItem_supplier)
            ? this.supplierStore.list.get(data.SiteReleaseItem_supplier).name
            : null,
      }, // Supplier
      {
        field: 'fourBox',
        valueGetter: ({data}) => {
          const category = this.categoryStore.getCategoryForRelease(this.releaseId, data.SiteReleaseItem_fourBox);
          return category ? category.name : null;
        },
      }, // 4BOX
      {
        field: 'categoryPlan',
        valueGetter: ({data}) => {
          const category = this.categoryStore.getCategoryForRelease(this.releaseId, data.SiteReleaseItem_categoryPlan);
          return category ? category.name : null;
        },
      }, // Category Plan
      {
        field: 'sensitivity',
        valueGetter: ({data}) =>
          this.productSensitivityStore.list.get(data.SiteReleaseItem_sensitivity)
            ? this.productSensitivityStore.list.get(data.SiteReleaseItem_sensitivity).title
            : null,
      }, // Sensitivity
      {
        field: 'family',
        valueGetter: ({data}) =>
          this.familyStore.list.get(data.SiteReleaseItem_family)
            ? this.familyStore.list.get(data.SiteReleaseItem_family).name
            : null,
      }, // Family
      {
        field: 'tier',
        valueGetter: ({data}) =>
          this.tierStore.list.get(data.SiteReleaseItem_tier)
            ? this.tierStore.list.get(data.SiteReleaseItem_tier).title
            : null,
      }, // Tier
      {field: 'cp'}, // CP
      {field: 'open_cp'}, // Open Cost price
      ...this.getZoneColumnsDefinitions(),
      {
        field: 'new_rp',
        editable: this.release && this.release.state === ReleaseState.Opened,
        action: this.updateReleaseItems,
        bulkFunc: this.updateReleaseItems,
      }, // New RP
      {
        field: 'exported',
        pinnedRowCellRenderer: RendererNames.BulkRenderer,
        editable: this.release && this.release.state === ReleaseState.Opened,
        action: this.updateReleaseItems,
        bulkFunc: this.updateReleaseItems,
      }, // Export
      {
        field: 'pStartDate',
        editable: this.release && this.release.state === ReleaseState.Opened,
        action: this.updateReleaseItems,
        bulkFunc: this.updateReleaseItems,
      }, // Price Start Date
    ];
    return [...this.releaseItemsColumnGenerator.getColumnDefinitions(colDefs)];
  }

  @computed
  public get releaseItemsRowData(): Array<StringMapping<any>> {
    const rowData = [];
    this.releaseItems.forEach((item: SiteReleaseItem) => rowData.push(this.tableItem(item)));
    return rowData;
  }

  private tableItem(item: SiteReleaseItem) {
    return {
      ...this.releaseItemsColumnGenerator.getColumnData({
        article: item.productName,
        siteId: item.siteExtId,
        siteName: item.siteName,
        supplier: item.supplierId,
        fourBox: Utils.getCategoryIdWithLevel(item.categoryIds, 6),
        categoryPlan: Utils.getCategoryIdWithLevel(item.categoryIds, 5),
        sensitivity: item.productSensitivityId,
        family: item.familyId,
        tier: item.tierId,
        cp: item.purchasePrice.avgPrice ? item.purchasePrice.avgPrice.value : null,
        ...this.getZoneColumnsData(item),
        new_rp: item.price.value,
        exported: item.exported,
        pStartDate: item.price.validFrom,
        id: item.id,
      }),
    };
  }

  /**
   * Handles both single and bulk update
   * @param selectedRow
   * @param field
   * @param newValue
   */
  @action.bound
  private async updateReleaseItems(selectedRow: string | undefined, field: string, newValue: any): Promise<any> {
    let rowIds: List<string>;
    if (selectedRow) {
      rowIds = List.of(selectedRow);
    } else {
      rowIds = this.getRowIdsForBulkUpdate();
    }

    await this.update(field, rowIds, newValue);
    return Promise.reject();
  }

  private async update(field: string, rowIds: List<string>, newValue: any): Promise<any> {
    if (field === 'SiteReleaseItem_pStartDate') {
      const updateData = [] as SiteReleaseItemValidFrom[];
      rowIds.forEach((rowId) => {
        const rowNode = this.gridApi.getRowNode(rowId);
        const id = rowNode.data.SiteReleaseItem_id;
        updateData.push({id, validFrom: newValue});
      });

      this.siteReleaseLayer.updateReleaseItemValidFrom(updateData).then((updatedItems) => {
        this.updateReleaseItemRowData(updatedItems);
      });
    }
    if (field === 'SiteReleaseItem_exported') {
      const updateData: SiteReleaseItemExported[] = [];
      rowIds.forEach((rowId) => {
        const rowNode = this.gridApi.getRowNode(rowId);
        const id = rowNode.data.SiteReleaseItem_id;
        updateData.push({id, exported: newValue});
      });
      this.siteReleaseLayer.updateReleaseItemExported(updateData).then((updatedItems) => {
        this.updateReleaseItemRowData(updatedItems);
      });
    }
    if (field === 'SiteReleaseItem_new_rp') {
      const updateData: SiteReleaseItemPriceValue[] = [];
      rowIds.forEach((rowId) => {
        const rowNode = this.gridApi.getRowNode(rowId);
        const id = rowNode.data.SiteReleaseItem_id;
        updateData.push({id, price: newValue});
      });
      this.siteReleaseLayer.updateReleaseItemPriceValue(updateData).then((updatedItems) => {
        this.updateReleaseItemRowData(updatedItems);
      });
    }
  }

  updateReleaseItemRowData(updatedItems: SiteReleaseItem[]) {
    const rowNodeData: Array<StringMapping<any>> = [];
    updatedItems.forEach((item) => {
      //    runInAction(() => this.releaseItems.set(item.id, item));
      rowNodeData.push({...this.tableItem(item)});
    });
    const pinnedRowCount = this.gridApi.getPinnedTopRowCount();
    const pinnedRowData = Array.from({length: pinnedRowCount}, (n, index) => {
      return this.gridApi.getPinnedTopRow(index).data;
    });
    this.gridApi.updateRowData({update: rowNodeData});
    this.gridApi.setPinnedTopRowData(pinnedRowData);
  }

  /**
   * Returns list of node ids
   * Either of selected nodes or all nodes
   */
  @action.bound
  private getRowIdsForBulkUpdate(): List<string> {
    const selected = List(this.gridApi.getSelectedNodes());
    if (selected.size > 0) {
      return selected.map((node) => node.id);
    } else {
      let ids = List();
      this.gridApi.forEachNodeAfterFilter((node) => (ids = ids.push(node.id)));
      return ids;
    }
  }

  confirmReleaseExport = async (): Promise<void> => {
    try {
      await this.siteReleaseLayer.confirmSiteReleaseExport(this.releaseId).then((release) => {
        runInAction(() => (this.release = release));
        this.messages.setValidationResult(release.validation);
        this.hideConfirmModal();
        this.checkExportProgress();
      });
    } catch (error) {
      this.hideConfirmModal();
    }
  };

  checkExportProgress = () => {
    if (this.release.state === ReleaseState.Computing && this.release.exportProgressMonitorId) {
      this.releasePollingHelper.startPolling(this.release.exportProgressMonitorId, 'release-successful');
    }
  };

  cancellRelease = async (): Promise<void> => {
    try {
      await this.siteReleaseLayer.cancelSiteRelease(this.releaseId).then((release) => {
        runInAction(() => (this.release = release));
        this.hideCancellModal();
      });
    } catch (error) {
      this.hideCancellModal();
    }
  };

  private downloadFileName;

  public exportToExcell() {
    try {
      this.productStore.siteReleaseDetailExport(this.release.id).then((value: any) => {
        if (value.progressMonitor.id) {
          this.downloadFileName = !value.filename ? '' : value.filename;
          this.excellPollingHelper.startPolling(value.progressMonitor.id, 'RELEASE_SITE_DETAIL_EXPORT');
        }
      });
    } catch (error) {
      // this.messages.setError(error);
    }
  }

  private onExportPollingStateChanged = (pollingState: LoadingState) => {
    switch (pollingState) {
      case LoadingState.Success:
        if (this.downloadFileName !== null) {
          if (this.downloadFileName !== '') {
            this.downloadStore.download(this.downloadFileName).then(() => {
              runInAction(() => {
                this.exportIsLoading = false;
              });
            });
          } else {
            this.messages.setError(translate('RELEASE_DETAIL_EXPORT_ERROR_NO_FILENAME'));
            runInAction(() => {
              this.exportIsLoading = false;
            });
          }
        }
        break;

      case LoadingState.Error:
        runInAction(() => {
          this.exportIsLoading = false;
        });
        break;

      default:
        // LoadingState.Pending
        if (this.exportIsLoading !== true) {
          runInAction(() => {
            this.exportIsLoading = true;
          });
        }
    }
  };

  getPriceListTypes = async (): Promise<void> => {
    const pricelistTypeIds = await this.codeBookItemLayer.loadLookup(CodebookLookupSequence.PriceListType);
    runInAction(() => (this.priceListTypes = pricelistTypeIds.codeBooks));
  };

  public async load() {
    this.setLoadingState(LoadingState.Pending);
    this.getPriceListTypes();

    try {
      await Promise.all([
        this.supplierStore.getAll(),
        this.productSensitivityStore.getAll(),
        this.tierStore.getAll(),
        this.familyStore.getAll(),
        this.categoryStore.loadForSiteRelease(this.releaseId),
        this.priceZoneStore.getAll(),
      ]);

      this.filterAndSetPriceZoneIdsInUse();
      this.initColumnGenerator();

      await this.loadRelease();
      await this.loadReleaseItems();

      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      this.setLoadingState(LoadingState.Error);
    }
  };

  private async loadRelease() {
    const release = await this.siteReleaseLayer.getSiteRelease(this.releaseId);
    this.setRelease(release);
  };

  @action
  private setRelease(release: SiteRelease) {
    this.release = release;
    this.checkExportProgress();
  }

  private async loadReleaseItems() {
    const releaseItems = await this.siteReleaseLayer.getSiteReleaseItems(this.releaseId);
    const releaseItemsMap: Map<string, SiteReleaseItem> = new Map<string, SiteReleaseItem>();

    for (const releaseItem of releaseItems) {
      releaseItemsMap.set(releaseItem.id, releaseItem);
    }

    this.setReleaseItems(releaseItemsMap);
  };

  @action
  private setReleaseItems(releaseItems: Map<string, SiteReleaseItem>) {
    this.releaseItems = releaseItems;
  }

  @action
  private filterAndSetPriceZoneIdsInUse() {
    const zoneIds: string[] = [];

    this.priceZoneStore.list.forEach((value) => {
      if (!value.archived) {
        zoneIds.push(value.id);
      }
    });

    zoneIds.sort((id1, id2) =>
      this.priceZoneStore.list.get(id1).name.localeCompare(this.priceZoneStore.list.get(id2).name),
    );

    this.priceZoneIdsInUse = List(zoneIds);
  }

  @action
  private initColumnGenerator() {
    this.releaseItemsColumnGenerator = new ColumnGenerator({
      article: this.builder.str('article'),
      siteId: this.builder.str('siteId'),
      siteName: this.builder.str('siteName'),
      supplier: this.builder.opt(this.builder.str('supplier')),
      fourBox: this.builder.str('fourBox'),
      categoryPlan: this.builder.str('categoryPlan'),
      sensitivity: this.builder.str('sensitivity'),
      family: this.builder.opt(this.builder.str('family')),
      tier: this.builder.str('tier'),
      cp: this.builder.opt(this.builder.bigNum('cp')),
      open_cp: this.builder.opt(this.builder.bigNum('open_cp')),
      ...this.getZoneColumnsDesc(),
      new_rp: this.builder.bigNum('new_rp'),
      exported: this.builder.bool('exported'),
      pStartDate: this.builder.date('pStartDate'),
      id: this.builder.id('id'),
    });
  }

  private getZoneColumnsDesc() {
    const desc: DataDesc = {};

    for(const priceZoneId of this.priceZoneIdsInUse) {
      desc[zoneRPKey(priceZoneId)] = this.builder.bigNum(zoneRPKey(priceZoneId));
    }

    return desc;
  }

  private getZoneColumnsDefinitions() {
    return this.priceZoneIdsInUse.map(priceZoneId => ({
      field: zoneRPKey(priceZoneId),
      headerName: `${getZoneName(this.priceZoneStore.list.get(priceZoneId))} ${translate(`PriceZoneReleasePrices_Regular`)}`,
    }));
  }

  private getZoneColumnsData(item: SiteReleaseItem) {
    const data = {};

    for(const zp of item.zonePrices) {
      data[zoneRPKey(zp.priceZoneId)] = zp.regularPrice;
    }

    return data;
  }

  private onReleasePoolingChange = (pollingState: LoadingState) => {
    if (pollingState === LoadingState.Success) {
      this.load();
    }
  };
}

const zoneRPKey = (priceZoneId: string) => `${priceZoneId}_rp`;
const getZoneName = (priceZone?: PriceZone) => priceZone ? priceZone.name : '?';
