/**
 * @file Created on Wed Aug 22 2018
 * @author SKu
 */
import * as React from 'react';
import Icon, { IconColor, IconType } from '../../media/Icon';
import Button, { ButtonColor } from '../../controls/Button';
import Modal from '../Modal';
import translate from '../../../components/i18n/translate/translate';
/**
 * ConfirmBox renders modal with 2 buttons. Modal text  - children
 */

var ConfirmBox = function ConfirmBox(props) {
  return React.createElement(Modal, {
    hidden: props.hidden,
    title: props.title,
    close: props.close
  }, React.createElement("div", {
    className: "mb1"
  }, " ", props.children), React.createElement("div", {
    className: "tar"
  }, React.createElement(Button, {
    buttonColor: ButtonColor.Light,
    onClick: props.close
  }, translate(props.discardButtonLabel ? props.discardButtonLabel : 'DISCARD')), React.createElement(Button, {
    iconLeft: true,
    onClick: props.onAccept
  }, !props.withoutIcon && React.createElement(Icon, {
    iconType: IconType.approveO,
    iconColor: IconColor.Inverse
  }), translate(props.confirmButtonLabel ? props.confirmButtonLabel : 'ACCEPT'))));
};

export default ConfirmBox;