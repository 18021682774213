"use strict";
/*
 * Created on 14/02/2019
 */

/**
 * Enum representing all collection supported favourites items
 *
 * @author JMr
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
var FavouriteEntityEnum;

(function (FavouriteEntityEnum) {
  FavouriteEntityEnum["Release"] = "RELEASE";
})(FavouriteEntityEnum = exports.FavouriteEntityEnum || (exports.FavouriteEntityEnum = {}));