"use strict";
/*
 * Created on 01 14 2019
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * An enumeration, that denotes the severity of some action, such as API call.
 */

var ResultSeverity;

(function (ResultSeverity) {
  /**
   * Least severe
   */
  ResultSeverity["Info"] = "INFO";
  /**
   * Middle severe
   */

  ResultSeverity["Warn"] = "WARN";
  /**
   * Denotes client errors, most severe
   */

  ResultSeverity["Error"] = "ERROR";
})(ResultSeverity = exports.ResultSeverity || (exports.ResultSeverity = {}));