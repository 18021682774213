/**
 * @file Created on Thu Jun 27 2019
 * @author SKu
 */

import {MessageStore, ReleaseStore, rootStore, StoreName} from '@logio/common-fe';
import {History} from 'history';
import {runInAction, observable} from 'mobx';
import {AbstractRelease} from '@logio/common-be-fe';

export class ReleaseWidgetStore {
  constructor(public messages: MessageStore, public history: History) {}

  /** List of releases */
  @observable
  list: Map<string, AbstractRelease> = new Map<string, AbstractRelease>();

  /** Layers, Stores */
  public releaseStore = rootStore.getStore(StoreName.Release) as ReleaseStore;

  /** Fetches data for the page */
  public load = async () => {
    try {
      const releases = await this.releaseStore.getAllForDashBoard();
      runInAction(() => (this.list = releases));
    } catch (error) {
      // this.messages.setError(error);
    }
  };
}
