"use strict";
/*
 * Created on 28.11.2018
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author MSu
 */

var WindowActionType;

(function (WindowActionType) {
  WindowActionType["Update"] = "UPDATE";
  WindowActionType["UpdateProducts"] = "UPDATE_PRODUCTS";
})(WindowActionType = exports.WindowActionType || (exports.WindowActionType = {}));