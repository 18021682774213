/**
 * @file Created on Thu Jul 12 2018
 * @author PKl
 * @author BPo
 */
import StyledInput from './styled/InputStyled';
import InputWrapper from './styled/InputWrapper';
import withValidation from '../withValidation';
import Icon, { IconColor, IconType } from '../../media/Icon';
import { showIf } from '../../../shared/rendering/showIf';
import StyledLabel from '../../typography/Label';
import * as React from 'react';
import Tooltip from '../../info/Tooltip';

var getLabel = function getLabel(props) {
  return props.labelTooltipText ? React.createElement(StyledLabel, {
    htmlFor: props.name
  }, React.createElement(Tooltip, {
    overlay: props.labelTooltipText,
    children: React.createElement("div", null, props.label)
  })) : React.createElement(StyledLabel, {
    htmlFor: props.name
  }, props.label);
};

var Input = function Input(props) {
  return React.createElement(React.Fragment, null, props.label && getLabel(props), React.createElement(InputWrapper, {
    withIconIn: props.withIconIn,
    withIconOut: props.withIconOut,
    className: "input-wrapper ".concat(props.className)
  }, React.createElement("div", {
    className: "flex justify-between items-center"
  }, props.clearInput && React.createElement(React.Fragment, null, React.createElement(Icon, {
    iconType: IconType.cancel,
    iconColor: IconColor.Text,
    iconHeight: 10,
    onClick: props.clearInput
  }), React.createElement("span", null, "\xA0")), React.createElement(StyledInput, Object.assign({}, props, {
    className: props.withIconOut ? 'with-icon-out' : ''
  })), showIf(props.inputIconType, React.createElement("div", {
    className: "input-icon"
  }, React.createElement(Icon, {
    iconType: props.inputIconType,
    iconHeight: 20,
    iconColor: IconColor.Text,
    onClick: props.onIconClick
  }))))));
};

export default Input; // Export input with validation wrapper.

export var InputValidated = withValidation(Input);