"use strict";
/*
 * Created on 23.04.2019
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author MSu
 */

var TreeLevel;

(function (TreeLevel) {
  TreeLevel[TreeLevel["Root4box"] = 1] = "Root4box";
  TreeLevel[TreeLevel["Promotion"] = 1.5] = "Promotion";
  TreeLevel[TreeLevel["CD"] = 2] = "CD";
  TreeLevel[TreeLevel["SCM"] = 3] = "SCM";
  TreeLevel[TreeLevel["CM"] = 4] = "CM";
  TreeLevel[TreeLevel["Category"] = 5] = "Category";
  TreeLevel[TreeLevel["Category4box"] = 6] = "Category4box";
  TreeLevel[TreeLevel["SwitchingBox"] = 7] = "SwitchingBox";
  TreeLevel[TreeLevel["Window"] = 10] = "Window";
  TreeLevel[TreeLevel["Product"] = 20] = "Product";
  TreeLevel[TreeLevel["Week"] = 30] = "Week";
  TreeLevel[TreeLevel["Split"] = 40] = "Split";
})(TreeLevel = exports.TreeLevel || (exports.TreeLevel = {}));