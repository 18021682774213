/**
 * @file Created on Mon Apr 15 2019
 * @author SKu
 */

import {getPath, TabMenu, translate} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';

export interface RelatedReportsTabsProps {
  /** TODO: Permissions control */
  pageName: PageNamesEnum;
  query?: string;
}

export const RelatedReportsTabs: React.SFC<RelatedReportsTabsProps> = observer((props: RelatedReportsTabsProps) => {
  /** Path with query */
  const getFinalPath = (path: PagePathsEnum) => {
    const defaultPath = getPath(path);
    if (props.query) {
      return `${defaultPath}${props.query}`;
    }
    return defaultPath;
  };

  const competitorPriceChangeTab = {
    to: getFinalPath(PagePathsEnum.CompetitorsPriceChangeReport),
    content: translate(PageNamesEnum.CompetitorsPriceChangeReport),
    isActive: () => PageNamesEnum.CompetitorsPriceChangeReport === props.pageName,
  };

  const priceElasticityTab = {
    to: getFinalPath(PagePathsEnum.PriceElasticityReport),
    content: translate(PageNamesEnum.PriceElasticityReport),
    isActive: () => PageNamesEnum.PriceElasticityReport === props.pageName,
  };

  const priceIndexTab = {
    to: getFinalPath(PagePathsEnum.PriceIndexReport),
    content: translate(PageNamesEnum.PriceIndexReport),
    isActive: () => PageNamesEnum.PriceIndexReport === props.pageName,
  };

  return <TabMenu tabs={[competitorPriceChangeTab, priceElasticityTab, priceIndexTab]} />;
});
