/**
 * @file Created on Wed Oct 10 2018
 * @author BPo
 */
import {Utils} from '@logio/common-be-fe';
import {Field, FieldProps, StringMapping} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';

interface MatrixItemProps {
  /**
   * Form fields props
   *
   * NOTE: For optimization reasons, it is important to make a selection by
   * zone in this SFC component.
   */
  fields: FieldProps[];
  /** A list of all items to highlight. The list is selected by price zone id. */
  changedValues: Map<string, StringMapping<any>>;
  /** Grouped resolved settings */
  placeholderValues: StringMapping<any>;
  /** We use price zone ID to get changed values. */
  priceZoneId: string;
  /** Defines the number of columns a cell should span. */
  colSpan?: number;
  /**
   * True if field can be edited.
   * @param name Field name
   */
  isFieldEditable(name: string): boolean;
  /**
   * Id of category
   */
  categoryId: string;
}

/**
 * It renders one data element of the table.
 */
export const MatrixItem: React.SFC<MatrixItemProps> = observer(
  ({colSpan, fields, changedValues, isFieldEditable, placeholderValues, priceZoneId, categoryId}) => {
    const children = [];
    let changed = false;

    fields.forEach((field) => {
      children.push(
        <Field
          {...field}
          fieldNoLabel
          fieldPlaceholder={placeholderValues[field.name]}
          key={field.name}
          showIfPlaceholder
          placeholderValues={placeholderValues}
          fieldReadOnly={!isFieldEditable(field.name)}
        />,
      );

      // Once one validation fails, it does not make sense to continue.
      if (!changed) {
        changed = changedValues.get(priceZoneId)[field.name];
      }
    });

    // categoryId "5bcda036ee747d7a80a4e9c8" => 4Box
    const shouldBeWarningShown = (
      categoryId !== "5bcda036ee747d7a80a4e9c8" && changed
    );

    return (
      <td colSpan={Utils.isNumber(colSpan) ? colSpan : undefined} className={shouldBeWarningShown ? 'td-warning' : ''}>
        {children}
      </td>
    );
  },
);
