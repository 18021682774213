/**
 * @file Created on Tue May 14 2019
 * @author SKu
 */
import { Utils } from '@logio/common-be-fe';
import * as React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { CONSTANTS } from '../../../../../constants';
import { translateResultObject } from '../../../../i18n/translate';
import Tooltip from '../../../../info/Tooltip';
import Icon, { IconColor, IconType } from '../../../../media/Icon';
export var ReleaseSelloutFinalPriceRenderer = function ReleaseSelloutFinalPriceRenderer(props) {
  var finalPrice = props.value;

  if (Utils.isValueMissing(finalPrice) || Utils.isValueMissing(finalPrice.value)) {
    return React.createElement(React.Fragment, null, CONSTANTS.FORMAT.NULL);
  }

  var alert = React.createElement(React.Fragment, null);

  if (finalPrice.validationResult && finalPrice.validationResult.resultObjects.size > 0) {
    var tooltipInfo = finalPrice.validationResult.resultObjects.map(function (resultObject, index) {
      return React.createElement("li", {
        key: index
      }, translateResultObject(resultObject));
    });
    alert = React.createElement(Tooltip, {
      overlay: tooltipInfo,
      children: React.createElement("div", null, React.createElement(Icon, {
        iconType: IconType.alert,
        iconColor: IconColor.Error,
        iconHeight: 15
      }))
    });
  }

  var price = Number(finalPrice.valueRounded || finalPrice.value);
  var roundedPrice = price.toFixed(2).toString().replace('.', ',');
  return React.createElement(Row, null, React.createElement(Col, {
    style: {
      paddingLeft: "11px"
    }
  }, alert), React.createElement(Col, {
    style: {
      flexGrow: 1,
      paddingRight: "11px"
    }
  }, roundedPrice));
};