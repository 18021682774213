"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Enum of acceptable POS type options. Used in window item.
 * @author ZSr
 */

var PosTypeEnum;

(function (PosTypeEnum) {
  PosTypeEnum["None"] = "NONE";
  PosTypeEnum["MultiBuy"] = "MULTIBUY";
  PosTypeEnum["MultiBuyBetterPrice"] = "MULTIBUY_BETTER_PRICE";
  PosTypeEnum["Gift"] = "GIFT";
  PosTypeEnum["Other"] = "OTHER";
})(PosTypeEnum = exports.PosTypeEnum || (exports.PosTypeEnum = {}));