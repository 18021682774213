/**
 * @file Created on Fri Aug 17 2018
 * @author VBr
 * @author LZe
 */

import {
  BindDTO,
  BindIdsDTO,
  Competitor,
  CompetitorProduct,
  CompetitorProductBindDTO,
  CompetitorProductDTO,
  DataPairingProductRowDTO,
  Product,
  ProductInfo,
  Utils,
  StructPropBuilder,
} from '@logio/common-be-fe';
import {
  ActionsGenerator,
  ColumnDefinition,
  ColumnGenerator,
  CONSTANTS,
  DataPairingStore,
  IconType,
  listOfStringsColumnDefinition,
  LoadingState,
  PageStore,
  ProductCategoryStore,
  productFlagsColumnDefinition,
  ProductStore,
  rootStore,
  StoreName,
  StringMapping,
  SupplierStore,
  translate,
  PriceZoneStore,
  Renderers,
  RendererNames,
  Comparators,
} from '@logio/common-fe';
import {List} from 'immutable';
import {ColGroupDef, ColumnApi, GridApi, GridReadyEvent} from 'ag-grid-community';
import debounce from 'lodash/debounce';
import {action, computed, observable, runInAction} from 'mobx';
import {packageSizeHelper} from '../../../../shared/packageSizeHelper';
import {categoryColumnGenerator, eanColumnGenerator} from './columnGenerators';

export class DataPairingRecommendedPageStore extends PageStore {
  constructor(categoryId: string) {
    super();
    this.categoryId = categoryId;
  }

  /** Get dependant stores */
  productStore = rootStore.getStore(StoreName.Product) as ProductStore;
  dataPairingStore = rootStore.getStore(StoreName.DataPairing) as DataPairingStore;
  supplierStore = rootStore.getStore(StoreName.Supplier) as SupplierStore;
  categoryStore = rootStore.getStore(StoreName.ProductCategory) as ProductCategoryStore;
  priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;

  /** Data generators */
  actionsGenerator = new ActionsGenerator();
  dataPairingProductRowDTOColumnGenerator = new ColumnGenerator<DataPairingProductRowDTO>(
    DataPairingProductRowDTO.schema,
  );
  productColumnGenerator = new ColumnGenerator<ProductInfo>(ProductInfo.schema);
  competitorProductDTOColumnGenerator = new ColumnGenerator<CompetitorProductDTO>(CompetitorProductDTO.schema);
  competitorProductColumnGenerator = new ColumnGenerator<CompetitorProduct>(CompetitorProduct.schema);
  // LOG-1775
  // competitorPriceColumnGenerator = new ColumnGenerator<CompetitorPrice>(CompetitorPrice.schema);
  competitorColumnGenerator = new ColumnGenerator<Competitor>(Competitor.schema);
  priceZonePricesGenerator: ColumnGenerator<StringMapping<any>>;

  // LOG-1775
  // competitorSiteColumnGenerator = new ColumnGenerator<CompetitorSite>(CompetitorSite.schema);

  @observable
  categoryId: string;

  @observable
  internalQuery: string = '';

  @observable
  gridApi: GridApi;

  @observable
  columnApi: ColumnApi;

  @observable
  selectedRowsCount: number = 0;

  @observable
  lockInputs: boolean = false;

  createPriceZoneGenerator = () => {
    const builder = new StructPropBuilder('PriceZonePricesBuilder');
    const description = {};
    this.priceZoneStore.listNotArchived.forEach(({id, isNational}) => {
        description[`${id}_actualRegularPrice`] = builder.bigNum(`${id}_actualRegularPrice`);
        description[`${id}_actualMarginPrice`] = builder.bigNum(`${id}_actualMarginPrice`);
        description[`${id}_actualMarginPercentage`] = builder.bigNum(`${id}_actualMarginPercentage`);
    });
    this.priceZonePricesGenerator = new ColumnGenerator<StringMapping<any>>(description);
  };

  /**
   * Return generated column definitions for ag-grid
   */
  @computed
  get columnDefs(): Array<ColumnDefinition | ColGroupDef> {
    const priceZonePricesDefinition: ColumnDefinition[] = [];
    this.priceZoneStore.listNotArchived.forEach(({id, name, isNational}) => {
        priceZonePricesDefinition.push(
          {
            field: `${id}_actualRegularPrice`,
            round: true,
            headerName: translate('PriceZonePricesBuilder_actualRegularPrice', name),
          },
          {
            field: `${id}_actualMarginPrice`,
            round: true,
            headerName: translate('PriceZonePricesBuilder_actualMarginPrice', name),
          },
          {
            field: `${id}_actualMarginPercentage`,
            // cellRendererFramework: Renderers[RendererNames.ArrowRenderer],
            // comparator: Comparators.arrowComparator,
            round: true,
            headerName: translate('PriceZonePricesBuilder_actualMarginPercentage', name),
          },
        );
    });

    /** Each generator return column definition from related data description */
    return [
      ...this.productColumnGenerator.getColumnDefinitions([
        {
          field: 'name',
          pinned: 'left',
          width: 250,
        },
      ]),
      ...this.dataPairingProductRowDTOColumnGenerator.getColumnDefinitions([
        {
          field: 'purchasePrice',
          round: true,
        },
        {
          field: 'openPurchasePrice',
          round: true,
        },
        {
          field: 'family',
          filter: 'agSetColumnFilter',
        },
      ]),
      ...this.priceZonePricesGenerator.getColumnDefinitions(priceZonePricesDefinition),
      ...categoryColumnGenerator.getColumnDefinitions([
        {
          field: 'box',
          filter: 'agSetColumnFilter',
        },
      ]),
      ...this.productColumnGenerator.getColumnDefinitions([
        // {
        //   field: 'supplierIds',
        //   valueFormatter: ({value: supplierIds}) => {
        //     if (supplierIds && supplierIds.map) {
        //       return supplierIds.map((id) => this.supplierStore.list.get(id).name).join(', ');
        //     }
        //     return '';
        //   },
        //   excelFormatter: (value) => {
        //     if (value && value.map) {
        //       return value.map((id) => this.supplierStore.list.get(id).name).join(', ');
        //     }
        //     return '';
        //   },
        // },
        {
          field: 'supplierId',
          filter: 'agSetColumnFilter',
          valueFormatter: ({value: supplierId}) => (supplierId ? this.supplierStore.list.get(supplierId).name : null),
        },
        {
          field: 'flags',
          ...productFlagsColumnDefinition,
        },
      ]),
      ...packageSizeHelper.colDefs(this.productStore),
      this.actionsGenerator.getColumnDefinition({pinned: false}),
      ...this.competitorProductColumnGenerator.getColumnDefinitions([
        {
          field: 'name',
          width: 250,
        },
      ]),
      ...this.competitorProductDTOColumnGenerator.getColumnDefinitions([
        {
          field: 'competitorPrice',
          round: true,
        },
        // {
        //   field: 'ratio',
        //   editable: true,
        // },
        // {
        //   field: 'charger',
        //   editable: true,
        // },
      ]),
      ...this.competitorProductColumnGenerator.getColumnDefinitions([
        {
          field: 'productSize',
        },
        {
          field: 'productSizeUnit',
          filter: 'agSetColumnFilter',
          width: 90,
        },
      ]),
      // LOG-1775
      // ...this.competitorProductDTOColumnGenerator.getColumnDefinitions([
      //   {
      //     field: 'researchType',
      //     hide: true,
      //   },
      // ]),
      ...eanColumnGenerator.getColumnDefinitions([
        {
          field: 'value',
        },
        {
          field: 'eanDescription',
          hide: true,
        },
      ]),
      ...this.competitorProductColumnGenerator.getColumnDefinitions([
        {
          field: 'category',
          filter: 'agSetColumnFilter',
        },
        {
          field: 'suppliers',
          ...listOfStringsColumnDefinition,
        },
        // {
        //   field: 'available',
        //   hide: true,
        // },
        {
          field: 'productContent',
          hide: true,
        },
        {
          field: 'productSpecification',
          hide: true,
        },
        // LOG-1775
        // {
        //   field: 'comment',
        //   hide: true,
        // },
      ]),
      ...this.competitorColumnGenerator.getColumnDefinitions([
        {
          field: 'name',
          filter: 'agSetColumnFilter',
        },
      ]),
      // LOG-1775
      // ...this.competitorSiteColumnGenerator.getColumnDefinitions([
      //   {
      //     field: 'address',
      //     hide: true,
      //   },
      // ]),
      // ...this.competitorPriceColumnGenerator.getColumnDefinitions([
      //   {
      //     field: 'city',
      //   },
      //   {
      //     field: 'date',
      //     hide: true,
      //   },
      // ]),
    ];
  }

  /**
   * Return generated data for ag-grid
   */
  @computed
  get rowData(): Array<StringMapping<any>> {
    const rows = [];
    let rowIndex = 0;
    this.dataPairingStore.list.forEach((productRecommended: DataPairingProductRowDTO) => {
      const priceZonePricesData = {};
      productRecommended.prices
        .toArray()
        .map(({actualRegularPrice, actualMarginPercentage, actualMarginPrice, priceZoneId}) => {
          priceZonePricesData[`${priceZoneId}_actualRegularPrice`] = actualRegularPrice;
          priceZonePricesData[`${priceZoneId}_actualMarginPercentage`] = actualMarginPercentage;
          priceZonePricesData[`${priceZoneId}_actualMarginPrice`] = actualMarginPrice;
        });

      productRecommended.competitorsProductsDTOs.forEach((competitorProductDTO: CompetitorProductDTO, index) => {
        const localRowIndex = rowIndex++;
        rows.push({
          id: productRecommended.productInfo.id,
          ...(index === 0
            ? categoryColumnGenerator.getColumnData({
                box: this.categoryStore.list.get(Utils.getCategoryIdWithLevel(productRecommended.productInfo.categoryIds, 6)).name,
              })
            : undefined),
          ...(index === 0 ? this.productColumnGenerator.getColumnData(productRecommended.productInfo) : undefined),
          ...(index === 0 ? this.dataPairingProductRowDTOColumnGenerator.getColumnData(productRecommended) : undefined),
          ...this.actionsGenerator.getColumnData(
            {
              name: 'pair',
              icon: IconType.pair,
              props: {
                onClick: () => {
                  const row = this.gridApi.getRowNode(localRowIndex.toString());
                  this.pair([
                    new BindDTO(
                      productRecommended.productInfo.id,
                      List([
                        new CompetitorProductBindDTO(
                          competitorProductDTO.competitorProduct.id,
                          row.data.CompetitorProductDTO_ratio,
                          row.data.CompetitorProductDTO_charger,
                        ),
                      ]),
                    ),
                  ]);
                },
              },
            },
            {
              name: 'unpair',
              icon: IconType.delete,
              props: {
                onClick: () =>
                  this.unpair([
                    new BindIdsDTO(productRecommended.productInfo.id, competitorProductDTO.competitorProduct.id),
                  ]),
              },
            },
          ),
          ...this.competitorProductDTOColumnGenerator.getColumnData(competitorProductDTO),
          ...this.competitorProductColumnGenerator.getColumnData(competitorProductDTO.competitorProduct),
          // LOG-1775
          // ...this.competitorPriceColumnGenerator.getColumnData(competitorProductDTO.competitorPrice),
          ...this.competitorColumnGenerator.getColumnData(competitorProductDTO.competitor),
          // LOG-1775
          // ...this.competitorSiteColumnGenerator.getColumnData(competitorProductDTO.competitorSite),
          ...eanColumnGenerator.getColumnData(competitorProductDTO.competitorProduct.eans.last() || null),
          ...packageSizeHelper.getColumnData(productRecommended.productInfo.packageSize),
          ...this.priceZonePricesGenerator.getColumnData(priceZonePricesData),
        });
      });
    });
    return rows;
  }

  @action.bound
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  @action
  onFilter = (event: React.FormEvent<HTMLInputElement>): void => {
    this.internalQuery = event.currentTarget.value;
  };

  @action
  resetFilter = (): void => {
    this.internalQuery = '';
    this.reload();
  };

  @action
  reload = () => {
    runInAction(() => {
      this.lockInputs = true;
    });
    this.gridApi.showLoadingOverlay();
    this.dataPairingStore
      .getOverview({
        categoryId: this.categoryId,
        nameOfInternalProduct: this.internalQuery,
        recommended: true,
      })
      .then((responses) => {
        this.gridApi.hideOverlay();
      })
      .catch((error) => {
        this.gridApi.hideOverlay();
        // this.messages.setError(error);
      })
      .finally(() => {
        runInAction(() => {
          this.lockInputs = false;
        });
      });
  };

  /**
   * Pairs unpaired competitor products to the internal one
   * @param BindDTOs
   */
  pair = (bindDTOs: BindDTO[]): void => {
    this.gridApi.showLoadingOverlay();
    this.dataPairingStore.dataPairingLayer
      .pair(bindDTOs)
      .then(() => {
        this.messages.setSuccess(translate('DataPairing_productPaired', bindDTOs.length.toString()));
        this.reload();
      })
      .catch((error) => {
        this.gridApi.hideOverlay();
        // this.messages.setError(error);
      });
  };

  /**
   * Pairs selected internal products to the competitor ones
   */
  pairSelected = (): void => {
    const selectedRows = this.gridApi.getSelectedRows();
    const bindDTOs = selectedRows.map((row) => {
      return new BindDTO(
        row.id,
        List([
          new CompetitorProductBindDTO(
            row.CompetitorProduct_id,
            row.CompetitorProductDTO_ratio,
            row.CompetitorProductDTO_charger,
          ),
        ]),
      );
    });
    this.pair(bindDTOs);
  };

  /**
   * Removes competitor products from the recommended list
   * @param bindIds - Array of IDs of the internal product and competitor product
   */
  unpair = (bindIds: BindIdsDTO[]): void => {
    this.gridApi.showLoadingOverlay();
    this.dataPairingStore.dataPairingLayer
      .unpairPaired(bindIds)
      .then(() => {
        this.messages.setSuccess(translate('DataPairing_productUnpaired', bindIds.length.toString()));
        this.reload();
      })
      .catch((error) => {
        this.gridApi.hideOverlay();
        // this.messages.setError(error);
      });
  };

  /**
   * Removes selected rows from the recommended list
   */
  unpairSelected = (): void => {
    const selectedRows = this.gridApi.getSelectedRows();
    const bindIds = selectedRows.map((row) => new BindIdsDTO(row.id, row.CompetitorProduct_id));
    this.unpair(bindIds);
  };

  @action
  onSelectionChanged = (): void => {
    if (this.gridApi) {
      this.selectedRowsCount = this.gridApi.getSelectedRows().length;
    }
  };

  /** Fetches all data for this page */
  public load = async () => {
    runInAction(() => this.dataPairingStore.list.clear());
    try {
      await Promise.all([
        this.categoryStore.getAll(),
        this.productStore.getUnits(),
        this.priceZoneStore.getAll(),
        this.supplierStore.getAll(),
        this.dataPairingStore.getOverview({
          categoryId: this.categoryId,
          nameOfInternalProduct: this.internalQuery,
          recommended: true,
        }),
      ]);
      this.createPriceZoneGenerator();
      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      this.setLoadingState(LoadingState.Error);
      // this.messages.setError(error);
    }
  };
}
