/**
 * @file Created on Tue Oct 23 2018
 * @author PKl
 */
import * as React from 'react';
import { BoxFormContentStyled, BoxFormDescriptionStyled, BoxFormStyled } from './styled/BoxFormStyled';
export var BoxForm = function BoxForm(props) {
  return React.createElement(BoxFormStyled, Object.assign({}, props));
};
/** Followed components works only inside BoxForm */

export var BoxFormContent = function BoxFormContent(props) {
  return React.createElement(BoxFormContentStyled, Object.assign({}, props));
};
export var BoxFormDescription = function BoxFormDescription(props) {
  return React.createElement(BoxFormDescriptionStyled, Object.assign({}, props));
};