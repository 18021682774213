/**
 * @file Created on Mon Oct 15 2018
 * @author SKu
 */

import {AgGrid, AgGridWrapper, translate} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';
import {SettingsComponentStore} from '../../stores/components';
import {CopySettingsModalEnum} from '../../stores/components/SettingsOverview/SettingsComponentStore';
import {CopySettingsModal} from './CopySettingsModal';
import {ReleaseType} from "@logio/common-be-fe";

export interface SettingsComponentProps {
  store: SettingsComponentStore;
  gridId: string | null;
}

export interface CopySettingsModalsProps {
  store: SettingsComponentStore;
  fromCategory?: boolean;
}

/** Settings component used fot General settings and release settings */
export const SettingsComponent: React.SFC<SettingsComponentProps> = observer(
  ({store, gridId}: SettingsComponentProps) => {
    return (
      <>
        <CopySettingsModalWrapper store={store} />
        <CopySettingsModalWrapper store={store} fromCategory />
        <AgGridWrapper>
          <AgGrid
            rowData={store.CategoryTraverseHelper.treeData}
            columnDefs={store.columnDefs}
            autoGroupColumnDef={store.CategoryTraverseHelper.categoryAutoGroupColumnDef}
            getDataPath={store.CategoryTraverseHelper.getDataPath}
            onRowSelected={store.setSelectedCategories}
            rowSelection="multiple"
            isRowSelectable={store.isRowSelectable}
            rememberGroupStateWhenNewData
            suppressRowClickSelection
            groupDefaultExpanded={2}
            onGridReady={store.onGridReady}
            saveTreeDataExpansionId="CategoriesTreeExpanded2"
            treeData
            gridId={gridId}
            quickView
          />
        </AgGridWrapper>
      </>
    );
  },
);

const CopySettingsModalWrapper: React.SFC<CopySettingsModalsProps> = observer((props: CopySettingsModalsProps) => {
  /** Current modal depends on mode */
  const modalName = props.fromCategory
    ? CopySettingsModalEnum.CopyFromCategory
    : CopySettingsModalEnum.CopyBetweenZones;

  return (
    <CopySettingsModal
      title={translate(`copy-settings-${modalName}`)}
      fromCategory={props.fromCategory}
      hidden={props.store.isModalHidden(modalName)}
      releaseId={props.store.release && props.store.release.id}
      close={props.store.hideModal}
      onSubmit={props.store.onCopySettingsSubmit(props.fromCategory)}
      selectedCategoriesCount={props.store.selectedCategoryIds.length.toString()}
    />
  );
});

export default SettingsComponent;
