"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Enum of possible errors that may occur during handling request.
 * @author OPt
 */

var WindowErrorEnum;

(function (WindowErrorEnum) {
  WindowErrorEnum["UPLOAD_MIME"] = "file-mime-type";
  WindowErrorEnum["UPLOAD_FILE_SIZE"] = "file-too-large";
  WindowErrorEnum["UPLOAD_FILE_EMPTY"] = "file-empty";
})(WindowErrorEnum = exports.WindowErrorEnum || (exports.WindowErrorEnum = {})); // + NO_SUCH_OBJECT window