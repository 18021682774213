"use strict";
/*
 * Created on 09 12 2018
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author Jaroslav Sevcik
 */

var UploadNotificationSeverityEnum;

(function (UploadNotificationSeverityEnum) {
  UploadNotificationSeverityEnum["WARNING"] = "WARNING";
  UploadNotificationSeverityEnum["ERROR"] = "ERROR";
})(UploadNotificationSeverityEnum = exports.UploadNotificationSeverityEnum || (exports.UploadNotificationSeverityEnum = {}));