import { Utils } from "@logio/common-be-fe";
import generalComparator from "./generalComparator";
/**
 * @file Created on September 2019
 * @author Atreo Digital s.r.o.
 * @description Custom comparator for fields rendered by ReleaseAlertsRenderer
 */

var releaseAlertsComparator = function releaseAlertsComparator(valA, valB) {
  return generalComparator(toComparableValue(valA), toComparableValue(valB));
}; // Comparator compares values by their size of list (number of comments)


var toComparableValue = function toComparableValue(val) {
  return Utils.isValueMissing(val) || !val.hasOwnProperty("resultObjects") || !val.resultObjects.hasOwnProperty("size") ? null : val.resultObjects.size;
};

export default releaseAlertsComparator;