"use strict";
/**
 * Created on 09 14 2020
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * <p>Enumerates possible message keys related to regular site prices termination module, not just {@link SiteReleaseTermination entity}
 * as name of enumeration could say. Within description of an individual msg may be suggested severity of a msg.</p>
 *
 * <p><b>Enumerated keys should be directly used for localisation keys. This is the reason that the values of the individual items violate
 * the naming convention for a value of an enumerated value.</b></p>
 *
 * @author JMe
 */

var SiteReleaseTerminationMsgKeyEnum;

(function (SiteReleaseTerminationMsgKeyEnum) {
  /**
   * Message key related to situation when the input file has incorrect structure (headers).
   * <br/>
   * Suggested severity: error.
   */
  SiteReleaseTerminationMsgKeyEnum["SourceDataInvalidDataStructure"] = "site_regular_prices_termination__source_data_invalid_data_structure";
  /**
   * Message key related to situation when the input file does not contain any data row.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseTerminationMsgKeyEnum["SourceDataNoDataRow"] = "site_regular_prices_termination__source_data_no_data_row";
  /**
   * Message key related to situation when the input file contains a row which has invalid value of termination date set.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseTerminationMsgKeyEnum["SourceDataInvalidDate"] = "site_regular_prices_termination__source_data_termination_valid_to_invalid";
  /**
   * Message key related to situation when the input file contains a row which has termination date set in the past.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseTerminationMsgKeyEnum["SourceDataPValidToInThePast"] = "site_regular_prices_termination__source_data_termination_valid_to_in_the_past";
  /**
   * Message key related to situation when some row within the input file misses required attribute(s).
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseTerminationMsgKeyEnum["SourceDataRequiredAttributeMissing"] = "site_regular_prices_termination__source_data_required_attribute_missing";
  /**
   * Message key related to situation when some product from the input file does not exist.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseTerminationMsgKeyEnum["SourceDataProductNotExists"] = "site_regular_prices_termination__source_data_product_not_exists";
  /**
   * Message key related to situation when some GoS from the input file does not exist.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseTerminationMsgKeyEnum["SourceDataGoSNotExists"] = "site_regular_prices_termination__source_data_gos_not_exists";
  /**
   * Message key related to situation when some entity from the input file does not exist.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseTerminationMsgKeyEnum["SourceDataEntitiesNotExist"] = "site_regular_prices_termination__source_data_entities_not_found";
  /**
   * Message key related to situation when the input file contains duplicated product/GoS combinations.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseTerminationMsgKeyEnum["SourceDataDuplicatedProductGoSCombinations"] = "site_regular_prices_termination__source_data_duplicated_product_gos_combinations";
  /**
   * Message key related to situation when the input file contains two or more GoSes containing the same site for one product.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseTerminationMsgKeyEnum["SourceDataProductGoSCollision"] = "sellout_prices_termination__source_data_product_gos_collision";
  /**
   * Message key related to situation when some {@link SiteReleaseTerminationItem items} is not editable (is readonly).
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseTerminationMsgKeyEnum["ItemsSomeItemNotEditable"] = "site_regular_prices_termination__items_some_item_not_editable";
  /**
   * Message key related to situation when none of {@link SiteReleaseTerminationItem items} belong to {@link SiteReleaseTermination}.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseTerminationMsgKeyEnum["ItemsNotBoundToAnyTermination"] = "site_regular_prices_termination__items_not_bound_to_any_termination";
  /**
   * <p>Message key related to situation when some {@link SiteReleaseTerminationItem items} do not belong to single
   * {@link SiteReleaseTermination}.</p>
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseTerminationMsgKeyEnum["ItemsNotBoundToSingleTermination"] = "site_regular_prices_termination__items_not_bound_to_single_termination";
  /**
   * Message key related to situation when value of {@link SiteReleaseTerminationItem.pValidTo} of specific item is in the past.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseTerminationMsgKeyEnum["ItemPValidToInThePast"] = "site_regular_prices_termination__item_p_valid_to_in_the_past";
  /**
   * Message key related to situation when a {@link SelloutTermination} cannot be cancelled because of invalid state.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseTerminationMsgKeyEnum["TerminationCancelInvalidState"] = "site_regular_prices_termination__cancel_invalid_state";
  /**
   * Message key related to situation when a {@link SelloutTermination} cannot be confirmed because of invalid state.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseTerminationMsgKeyEnum["TerminationConfirmInvalidState"] = "site_regular_prices_termination__confirm_invalid_state";
  /**
   * Message key related to situation when termination day of {@link SiteReleaseTerminationItem} is in the past.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseTerminationMsgKeyEnum["TerminationValidToInThePast"] = "site_regular_prices_termination__termination_valid_to_in_the_past";
  /**
   * Message key related to situation when a {@link SiteReleaseTermination} should be editable but someone/something tries to edit it.
   * <br/>
   * Suggested severity: error.
   */

  SiteReleaseTerminationMsgKeyEnum["TerminationNotEditable"] = "site_regular_prices_termination__termination_not_editable";
  /**
   * Message key related to situation when there are not any prices to be terminated for a {@link SiteReleaseTerminationItem}.
   * <br/>
   * Suggested severity: info.
   */

  SiteReleaseTerminationMsgKeyEnum["TerminationNoPriceRowToEnd"] = "site_regular_prices_termination__termination_no_price_row_to_end";
})(SiteReleaseTerminationMsgKeyEnum = exports.SiteReleaseTerminationMsgKeyEnum || (exports.SiteReleaseTerminationMsgKeyEnum = {}));