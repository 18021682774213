/**
 * Template options when creating regular release.
 */
export enum TemplateButtonEnum {
    /** National template is selected when this button is pressed. */
    NationalZone = 'NATIONAL_ZONE',

    /** You can select price zones in the modal when this button is pressed. */
    SelectZones = 'SELECT_ZONES',

    /** You can select other release in the modal when this button is pressed. */
    BrowseRelease = 'BROWSE_RELEASE',
}
