/**
 * @file Created on Thu Apr 04 2019
 * @author SKu
 */

import {ReleaseState, ReleaseType} from '@logio/common-be-fe';
import {
  AgGrid,
  AgGridWrapper,
  Alert,
  AlertType,
  Button,
  ButtonColor,
  ButtonSize,
  ConfirmBoxWithLoading,
  getPath,
  IReleaseImpactFilter,
  LoadingState,
  Modal,
  Page,
  ProgressBar,
  translate,
} from '@logio/common-fe';
import {Location} from 'history';
import {Observer} from 'mobx-react';
import * as React from 'react';
import {match, RouteComponentProps} from 'react-router';
import DataUploadResult from '../../../components/DataUploadResult';
import {PriceUploadModal} from '../../../components/Release/PriceUploadModal';
import {IReleaseFilter, ReleaseUtils} from '../../../components/Release/ReleaseUtils';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {
  AbstractReleaseDetailPageStore,
  ReleaseDetailModalEnum,
} from '../../../stores/pages/Release/ReleaseDetail/AbstractReleaseDetailPageStore';

export interface Props {
  match: match<{releaseId: string}>;
  location: Location;
}

/**
 * Release detail page
 * Renders total overview
 */
abstract class AbstractReleaseDetailPage extends React.Component<
  Props & RouteComponentProps<AbstractReleaseDetailPage>
> {
  public abstract store: AbstractReleaseDetailPageStore;

  /** Parses url queries and returns as {IReleaseImpactFilter} */
  /**
   * Parses url queries and returns as {IReleaseImpactFilter}
   */
  parsedQuery = (): IReleaseImpactFilter => {
    const filter = ReleaseUtils.parseQuery(this.props.location);
    return ReleaseUtils.parseQueryRelease(filter, this.store.release);
  };

  /**
   * Parses url queries and returns as {IReleaseImpactFilterMoment}
   */
  get parsedQueryFilter(): IReleaseFilter {
    return ReleaseUtils.queryToMoment(this.parsedQuery());
  }

  /** Loads all page data */
  componentWillMount() {
    this.store.load(this.props.match.params.releaseId, this.parsedQuery);
  }

  /** Cleans store */
  componentWillUnmount() {
    this.store.destroy();
  }

  /** Check if release is in Computing state */
  public get isReleaseComputing() {
    return this.store.release.state === ReleaseState.Computing;
  }

  /** Returns proper release header (depends on release type) */
  public abstract get releaseHeader(): React.ReactElement;

  /** Items that will be passed to release header */
  public abstract get releaseHeaderItems(): React.ReactElement[];

  /** Page specific content that should be rendered */
  public abstract get additionalRenderContent(): React.ReactElement;

  /** Icon opens cancel confirm box */
  public get cancelHeaderItem() {
    return (
      <Button
        buttonSize={ButtonSize.Tiny}
        buttonColor={ButtonColor.Light}
        onClick={this.store.getOpenModalEvent(ReleaseDetailModalEnum.CancelRelease)}
        disabled={
          this.store.release.state !== ReleaseState.New &&
          this.store.release.state !== ReleaseState.Opened &&
          this.store.release.state !== ReleaseState.ComputationFailed &&
          this.store.release.state !== ReleaseState.Approved
        }
      >
        {translate('Cancel')}
      </Button>
    );
  }

  /** Returns ag-grid for showing total overview */
  private get agGrid() {
    return (
      <AgGridWrapper>
        <Modal
          hidden={!this.store.isConfirmModal}
          title={(this.store.isConfirm4BOX) ? translate('confirm-categories') : translate('confirm-category')}
          close={this.store.onCancel}
        >
          <div className="tar mt3">
            <Button buttonColor={ButtonColor.Secondary} onClick={this.store.onConfirm}>{translate('Confirm')}</Button>
            <Button buttonColor={ButtonColor.Light} onClick={this.store.onCancel}>{translate('Cancel')}</Button>
          </div>
        </Modal>
        <AgGrid
          rowData={this.store.rowData}
          columnDefs={this.store.columnDefs}
          autoGroupColumnDef={this.store.autoGroupColumnDefs}
          getDataPath={this.store.getDataPath}
          headerHeight={50}
          onGridReady={this.store.onGridReady}
          treeData
          groupDefaultExpanded={this.store.generalConfigurationsStore.config.categoryLevel}
          gridId="PricingReleaseDetailPage"
          quickView
          deltaRowDataMode={this.store.release.releaseType === ReleaseType.Sellout}
          getRowNodeId={
            this.store.release.releaseType === ReleaseType.Sellout ? ({SelloutImpactRecord_categoryId: id}) => id : null
          }
          rowClassRules={{
            ['ag-readonly-row']: (params) => {
              return this.store.isProductCategoryReadonly(params);
            },
          }}
        />
      </AgGridWrapper>
    );
  }

  /** Renders modal for price uploading */
  private get uploadModal() {
    return (
      <PriceUploadModal
        progress={this.store.uploadPollingHelper.progress}
        hidden={this.store.isModalHidden(ReleaseDetailModalEnum.Upload)}
        close={this.store.hideModal}
        upload={this.store.onSubmitUpload}
        uploadedData={this.store.productPricesUpload}
      />
    );
  }

  /** Renders confirm box for release cancellation */
  private get cancelReleaseModal() {
    return (
      <ConfirmBoxWithLoading
        hidden={this.store.isModalHidden(ReleaseDetailModalEnum.CancelRelease)}
        title={translate('cancel-release')}
        onAccept={this.store.onConfirmCancelRelease}
        close={this.store.hideModal}
        children={<div className="mb1">{`${translate('cancel-release-confirmation')}`}</div>}
        isLoading={this.store.isLoading}
      />
    );
  }

  private get renderBody() {
    const pollingPending = this.store.pollingHelper.pollingState === LoadingState.Pending;
    if (this.isReleaseComputing || pollingPending || this.store.isRecalculateActionFired) {
      /* If release is Computing, progress should be shown instead of grid */
      return <ProgressBar percentage={this.store.pollingHelper.progress || 0} label={'release-calculating-now'} />;
    } else if (this.store.release.state === ReleaseState.ComputationFailed) {
      /** If computation failed, user should have possibility to recalculate whole release */
      return (
        <>
          <div className="mb1">
            <Alert type={AlertType.Error}>{translate('YDG-failed')}</Alert>
          </div>
          <strong>(TODO: Add recalculation button )</strong>
          {this.cancelReleaseModal}
        </>
      );
    } else {
      return (
        <>
          {this.agGrid}
          {this.uploadModal}
          {this.cancelReleaseModal}
          {this.additionalRenderContent}
        </>
      );
    }
  }

  render() {
    let backButtonPath: string;

    if (this.store.release) {
      if (this.store.release.releaseType === ReleaseType.Sellout) {
        backButtonPath = getPath(PagePathsEnum.ReleasesSellout);
      } else {
        backButtonPath = getPath(PagePathsEnum.ReleasesRegular);
      }
    }

    return (
      <Page
        titleKey={this.store.isLoadingSuccess && this.store.release.name}
        loadingState={this.store.loadingState}
        backButtonPath={backButtonPath}
      >
        <Observer>
          {() => (
            <>
              {this.releaseHeader}
              {this.renderBody}
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

export default AbstractReleaseDetailPage;
