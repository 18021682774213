/**
 * @file Created on Mon Jan 28 2019
 * @author SKu
 */

import {
  CodebookItem,
  CodebookLookupSequence,
  ProductCategory,
  ReleaseType,
  ReleaseWorkflowType,
  SelloutCategoryDiscount,
  SelloutDescription,
  SelloutReleaseType,
  SelloutSettings,
} from '@logio/common-be-fe';
import {
  arrayToMap,
  CodeBookItemLayer,
  codebookToSelectOption,
  ColumnDefinition,
  ColumnGenerator,
  CONSTANTS,
  FormElOption,
  MessageStore,
  ProductCategoryStore,
  rootStore,
  SelloutSettingsLayer,
  StoreName,
  StringMapping,
  translate,
} from '@logio/common-fe';
import {List} from 'immutable';
import {CellEditingStoppedEvent} from 'ag-grid-community';
import {action, computed, observable, runInAction} from 'mobx';

export class SelloutSettingsComponentStore {
  constructor(public messages: MessageStore, public readOnly?: boolean) {}
  /** Stores/Layers */
  private productCategoryStore = rootStore.getStore(StoreName.ProductCategory) as ProductCategoryStore;
  private selloutSettingsLayer: SelloutSettingsLayer = new SelloutSettingsLayer();
  protected readonly codeBookItemLayer: CodeBookItemLayer = new CodeBookItemLayer();

  /** Options for selecting priceListTypes in RadioGroup */
  public priceListTypesOptions: FormElOption[];
  /** Map of all priceListTypes */
  public priceListTypes: Map<string, CodebookItem>;

  /** Sellout settings will be shown due to this value */
  @observable
  public priceListTypeId: string;

  /** Stores sellout settings for all PriceListTypes */
  @observable
  private selloutSettingsMap = new Map<string, SelloutSettings>();

  /** Returns settings for currently selected priceListType */
  @computed
  public get selloutSettings() {
    return this.selloutSettingsMap.get(this.priceListTypeId);
  }

  /**
   * Function converts values from BE to initial Values for Form,
   * namely:
   * converts responsibilities. From {name: string}[] to string[] for select options
   * converts releaseTypes. From List to array for select options
   * converts workflowTypes. From List to array for select options
   */
  public convertDescriptionToFormValues = () => {
    if (this.selloutSettings) {
      const responsibilities = this.selloutSettings.description.responsibilities.toArray().map((r) => r.name);
      const releaseTypes = this.selloutSettings.description.releaseTypes.toArray();
      const workflowTypes = this.selloutSettings.description.workflowTypes.toArray();
      return {
        ...this.selloutSettings.description,
        ...{responsibilities, releaseTypes, workflowTypes},
      };
    }
  };

  /**
   * Function converts values from Form to values needed on BE,
   * namely: converts responsibilities. From string[] to {name: string}[]
   * @param description - values from Form
   */
  private convertDescriptionFromFormValues = (description: SelloutDescription) => {
    const responsibilities = description.responsibilities.map((name) => ({name}));
    const releaseTypes = List(description.releaseTypes);
    const workflowTypes = List(description.workflowTypes);
    return {
      ...description,
      ...{responsibilities, releaseTypes, workflowTypes},
    };
  };

  /**
   * Handle Description Save button
   * Merge whole default SelloutSettings with updated description
   * @param description - callback from Form submit
   */
  public onSelloutDescriptionSave = async (description: SelloutDescription) => {
    const updatedSelloutSettings = {
      ...this.selloutSettings,
      ...{description: this.convertDescriptionFromFormValues(description)},
    };
    await this.updateSelloutSettings(updatedSelloutSettings);
  };

  /**
   * If user will pick new SelloutSettings,
   * description/settings values should be updated
   * @param value priceListTypeId
   */
  public onPriceListTypeChange = async (value: any) => {
    runInAction(() => (this.priceListTypeId = value));
  };

  /**
   * Handle updating sellout settings
   * @param {SelloutSettings} settings - updated settings
   */
  private updateSelloutSettings = async (settings) => {
    try {
      const updatedSettings = await this.selloutSettingsLayer.update(settings);
      runInAction(() => this.selloutSettingsMap.set(updatedSettings.priceListTypeId, updatedSettings));
      this.messages.setSuccess(translate('sellout-settings-success-update', settings.selloutType));
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  /**************************************** DeadStock Subsettings **********************************************/

  /** Data for deadstock Ag-grid */
  @observable
  private deadstockSubSettings: Map<string, any> = new Map<string, SelloutCategoryDiscount>();

  /** Ag-grid generators */
  private deadStockSubSettingsGenerator = new ColumnGenerator<any>(SelloutCategoryDiscount.schema);

  /** Returns generated column definitions for ag-grid */
  @computed
  public get columnDefs(): ColumnDefinition[] {
    return this.deadStockSubSettingsGenerator.getColumnDefinitions([
      {field: 'discount1', editable: !this.readOnly, onCellValueChanged: this.setDiscountForCategory, },
      {field: 'discount2', editable: !this.readOnly, onCellValueChanged: this.setDiscountForCategory},
    ]);
  }

  /** Return data path for tree view */
  getDataPath = ({path}: {path: string[]}) => path;

  /** Return generated data for ag-grid */
  @computed
  public get rowData(): Array<StringMapping<any>> {
    const gridData: Array<StringMapping<any>> = [];
    const traverse = (category: ProductCategory, path: List<string>) => {
      /** We need to use only UnitDirector and CategoryManager */
      if (CONSTANTS.SELLOUT_SETTINGS.ALLOWED_CATEGORY_LEVELS.some((v) => v === category.level)) {
        path = path.push(category.name);
        /** If discount is empty, will be set to default value  */
        if (!this.deadstockSubSettings.has(category.id)) {
          this.setDefaultCategoryDiscount(category.id);
        }

        gridData.push({
          path: path.toArray(),
          ...this.deadStockSubSettingsGenerator.getColumnData(this.deadstockSubSettings.get(category.id)),
        });
      }
      /** Run this function for every child category */
      category.childrenIds.map((id: string) => this.productCategoryStore.list.get(id)).forEach((c: ProductCategory) => {
        if (c) {
          traverse(c, path);
        }
      });
    };
    if (this.productCategoryStore.root !== null) {
      traverse(this.productCategoryStore.root, List());
    }
    return gridData;
  }

  private setDiscountForCategory = (event) => {
    this.onDeadStockSubSettingsEdit(event);
    const categoryName = event.data.path[event.data.path.length - 1];
    const colId = event.column.colId;
    const value = event.newValue;
    this.setDiscountForSubcategories(event, categoryName, colId, value);
  };

  setDiscountForSubcategories(event, categoryName, colId, value) {
    event.api.forEachNode((node) => {
      if (node.data.path.includes(categoryName)) {
        node.setDataValue(colId, value);
        this.onDeadStockSubSettingsEdit(node);
      }
    });
  }

  /** In case if settings do not exist for category, should be displayed as default values */
  private setDefaultCategoryDiscount = (categoryId: string) => {
    this.deadstockSubSettings.set(categoryId, {
      cmCategoryId: categoryId,
      discount1: CONSTANTS.SELLOUT_SETTINGS.DEFAULT_DISCOUNT1,
      discount2: CONSTANTS.SELLOUT_SETTINGS.DEFAULT_DISCOUNT2,
    });
  };

  /**
   * Sets updated values to the categoryDiscountsMap
   * @param event - Ag-grid callback
   */
  @action
  public onDeadStockSubSettingsEdit = (event) => {
    const cmCategoryId = event.data[SelloutCategoryDiscount.schema.cmCategoryId.description.nameKey];
    const discount1 = event.data[SelloutCategoryDiscount.schema.discount1.description.nameKey];
    const discount2 = event.data[SelloutCategoryDiscount.schema.discount2.description.nameKey];
    this.deadstockSubSettings.set(cmCategoryId, {cmCategoryId, discount1, discount2});
  };

  /**
   * Handle Remodelling Settings Save button
   * Merge whole default SelloutSettings with updated categoryDiscount value
   */
  public onSelloutDeadstockSave = async () => {
    const categoryDiscounts = List(Array.from(this.deadstockSubSettings.values()));
    const settings = {
      ...this.selloutSettings,
      ...{settings: {...this.selloutSettings.settings, ...{categoryDiscounts}}},
    };
    await this.updateSelloutSettings(settings);
  };

  /**************************************** DeadStock Subsettings **********************************************/

  /**************************************** Page data fetching *************************************************/

  /** Fetches all data for this page */
  public load = async (): Promise<void> => {
    await Promise.all([this.getPriceListTypes(), this.getSelloutSettings(), this.productCategoryStore.getAll()]);
  };

  private getPriceListTypes = async () => {
    try {
      const params = {releaseType: ReleaseType.Sellout, workflowType: ReleaseWorkflowType.Sellout};
      const priceListTypes = await this.codeBookItemLayer.loadLookup(CodebookLookupSequence.PriceListType, params);
      this.priceListTypesOptions = codebookToSelectOption(priceListTypes.codeBooks).map(
        (item) => ({
          value: item.value,
          label: translate("PRICE_LIST_TYPES_OPTION_" + item.label)
        })
      );

      this.priceListTypes = arrayToMap(priceListTypes.codeBooks);
      /** Set default value for the component */
      runInAction(() => (this.priceListTypeId = this.priceListTypesOptions[0].value));
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  private getSelloutSettings = async () => {
    try {
      const settings = await this.selloutSettingsLayer.findAll();
      runInAction(() => (this.selloutSettingsMap = settings));
      this.setDeadStockSettings();
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  /** Sets DeadStock settings as Map */
  @action
  private setDeadStockSettings = () => {
    this.deadstockSubSettings = arrayToMap(
      Array.from(this.selloutSettingsMap.values())
        .find((s) => s.selloutType === SelloutReleaseType.Deadstock)
        .settings.categoryDiscounts.toArray(),
      'cmCategoryId',
    );
  };
}
