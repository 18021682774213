import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n\n  min-height: ", "px;\n  padding: ", ";\n  color: ", ";\n  background-color: ", ";\n  background-clip: padding-box;\n  border: 1px solid ", ";\n  border-radius: ", ";\n  width: 100%;\n\n  &:disabled,\n  &:read-only {\n    background-color: ", ";\n  }\n\n  &.agGrid {\n    padding: 0.28rem 0.4rem;\n    font-size: 0.75rem;\n    line-height: 1;\n    border-radius: 0;\n    min-height: 25px;\n    max-width: unset;\n  }\n\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: 90%;\n  min-height: ", "px;\n  padding: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Thu Jul 12 2018
 * @author PKl
 * @author BPo
 */
import styled, { css, withTheme } from 'styled-components';
var InputTiny = css(_templateObject(), function (props) {
  return props.theme.input.tinyHeight + 2;
}, function (props) {
  return props.theme.input.tinyPadding;
});
/**
 * Create an Input component that'll render an <input> tag with some styles
 */

var InputStyled = styled.input(_templateObject2(), function (props) {
  return props.theme.input.height;
}, function (props) {
  return props.theme.input.padding;
}, function (props) {
  return props.theme.input.color;
}, function (props) {
  return props.theme.input.bg;
}, function (props) {
  return props.theme.input.borderColor;
}, function (props) {
  return props.theme.input.borderRadius;
}, function (props) {
  return props.theme.input.disabledBg;
}, function (props) {
  return props.tiny ? InputTiny : null;
});
export default withTheme(InputStyled);