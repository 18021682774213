
import {PrivilegesControl, translate} from '@logio/common-fe';
import AppContainer from 'components/AppContainer';
import {observer} from 'mobx-react';
import * as React from 'react';
import {Route} from 'react-router-dom';

/**
 * Container for in-app routes, which includes login and privilege control
 * @param props AppRoutes props
 */
const AppRoute = observer(({component: Component, privileges, name, computedMatch, location, history, ...rest}) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <AppContainer name={name} match={computedMatch} {...props}>
                    <PrivilegesControl privileges={privileges}>
                        <Component match={computedMatch} location={location} key={location.pathname} />
                    </PrivilegesControl>
                </AppContainer>
            )}
        />
    );
});

/**
 * Function that generates router structure
 * @param routes Array of application paths
 */
export const Routes = (routes, privileges = []): JSX.Element[] => {
    const result: JSX.Element[] = [];
    if (routes !== undefined) {
        routes.map((route, i) => {
            const childRoutes = route.routes ? Routes(route.routes, route.privileges) : [];
            result.push(...childRoutes);
            if (route.empty !== true) {
                result.push(
                    <AppRoute
                        key={i}
                        path={translate(route.path)}
                        exact={route.exact}
                        component={route.component}
                        privileges={route.privileges}
                        name={route.name}
                    />,
                );
            }
        });
    }
    return result;
};
