import {
  AgGrid,
  Button,
  FormGroupInline,
  getPath,
  Page,
  translate,
} from '@logio/common-fe';
import { Observer } from 'mobx-react';
import * as React from 'react';
import { PageNamesEnum } from '../../../shared/localization/PageNamesEnum';
import excelStyles from '../Overview/excelStyles';
import { ReleasedImpactFilter } from '../../../components/Release/ReleasedImpactFilter';
import { RegularReleaseImpactPageStore } from '../../../stores/pages/Release/Overview/RegularReleaseImpactPageStore';
import { PagePathsEnum } from '../../../shared/localization/PagePathsEnum';
import { Link } from 'react-router-dom';

/**
 * New page containing all (and only) regular and site releases.
 *
 * @author Jan Prokop
 */
export default function RegularReleaseImpactPage() {
  const store = React.useMemo(() => new RegularReleaseImpactPageStore(), []);

  React.useEffect(() => {
    store.load();
  }, [store]);

  return (
    <Observer>
      {() => (
        <Page titleKey={PageNamesEnum.ReleasesRegularImpact} loadingState={store.loadingState}>
          <FormGroupInline>
            <ReleasedImpactFilter onSubmit={store.onImpactFilterSubmit} />
            <div className="flex-grow-1">&nbsp;</div>
            <Link to={getPath(PagePathsEnum.ReleasesRegular)}>
              <Button>{translate(PageNamesEnum.ReleasesRegular)}</Button>
            </Link>
          </FormGroupInline>
          <AgGrid
            rowData={store.rowData}
            columnDefs={store.columnDefs}
            excelStyles={excelStyles}
            gridId="RegularReleaseImpactPage"
            quickView
            onGridReady={store.onGridReady}
          />
        </Page>
      )}
    </Observer>
  );
}
