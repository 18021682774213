"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Optimization result codes defined by YDG.
 * https://documenter.getpostman.com/view/1878926/RWTeW2Wb?version=latest#8218bd66-5a5b-4466-9d39-f6c048a01592
 */

var YDGOptimizationResultCode;

(function (YDGOptimizationResultCode) {
  YDGOptimizationResultCode["Ok"] = "Ok";
  YDGOptimizationResultCode["SupplierPriceInvalid"] = "SupplierPriceInvalid";
  YDGOptimizationResultCode["FamilyRatiosUnreasonable"] = "FamilyRatiosUnreasonable";
  YDGOptimizationResultCode["MaxPriceBelowZeroMargin"] = "MaxPriceBelowZeroMargin";
  YDGOptimizationResultCode["MinPriceTooHigh"] = "MinPriceTooHigh";
  YDGOptimizationResultCode["PricesIncorrect"] = "PricesIncorrect";
  YDGOptimizationResultCode["CurrentPriceInvalid"] = "CurrentPriceInvalid";
  YDGOptimizationResultCode["MinMaxRangeIncorrect"] = "MinMaxRangeIncorrect";
  YDGOptimizationResultCode["AnotherFamilyMemberIssue"] = "AnotherFamilyMemberIssue";
})(YDGOptimizationResultCode = exports.YDGOptimizationResultCode || (exports.YDGOptimizationResultCode = {}));