/**
 * @file Created on Mon Nov 25 2019
 *
 * @///<reference path="packages/common-dp/src/core/transfer/common/model/TransferType.ts"/>
 *
 * @author Tomáš Leity
 * @author Vojtěch Smolař
 */

/**
 * @enum
 */
export var DPTransferType;

(function (DPTransferType) {
  /**
   * Export task.
   */
  DPTransferType["Export"] = "EXPORT";
  /**
   * Import task.
   */

  DPTransferType["Import"] = "IMPORT";
})(DPTransferType || (DPTransferType = {}));