import _slicedToArray from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";

/**
 * @file Created on Wed Oct 03 2018
 * @author BPo
 */
import { bigdecimal } from '@logio/big-decimal';
import { BigNumberParser, NumberParser, ObjectDescParser, OptionalDesc, PropDesc, Range, Utils } from '@logio/common-be-fe';
import { NumberRange } from '@logio/common-be-fe';

var rangeNumberTransformFunc = function rangeNumberTransformFunc(_ref) {
  var _ref2 = _slicedToArray(_ref, 2),
      val1 = _ref2[0],
      val2 = _ref2[1];

  if (Utils.isValueMissing(val1) || Utils.isValueMissing(val2)) {
    return null;
  }

  return new Range(val1, val2);
};

var rangeBigNumberTransformFunc = function rangeBigNumberTransformFunc(_ref3) {
  var _ref4 = _slicedToArray(_ref3, 2),
      val1 = _ref4[0],
      val2 = _ref4[1];

  if (Utils.isValueMissing(val1) || Utils.isValueMissing(val2)) {
    return null;
  }

  return new Range(bigdecimal(val1), bigdecimal(val2));
};

var theRangeNumberTransformFunc = function theRangeNumberTransformFunc(input) {
  if (input instanceof NumberRange) {
    return input;
  }

  if (Utils.isArray(input) && input.length === 2) {
    var val1 = input[0];
    var val2 = input[1];
    return new NumberRange(typeof val1 === 'number' ? val1 : null, typeof val2 === 'number' ? val2 : null);
  }

  return null;
};

var theRangeBigNumberTransformFunc = function theRangeBigNumberTransformFunc(input) {
  if (input instanceof Range) {
    return input;
  }

  if (Utils.isArray(input) && input.length === 2) {
    var val1 = input[0];
    var val2 = input[1];
    return new Range(bigdecimal(val1), bigdecimal(val2));
  }

  return null;
};
/**
 * This function is used when the user changes / confirms the form, then the value of the
 * form is transformed into a data dictionary type.
 * @param propDesc
 * @returns A transformation function for the data dictionary validation or null;
 */


export var getTransform = function getTransform(propDesc) {
  // Skip optional description
  var propDescBase;

  if (propDesc instanceof OptionalDesc) {
    propDescBase = propDesc.base;
  } else {
    propDescBase = propDesc;
  } // FIXME THIS METHOD SHOULD BE REVIEWED AND REFACTORED.


  function isTransformToRange(desc) {
    // FIXME THIS IS NOT EXACT (THERE MAY BE AN OBJECT WHICH IS NOT RANGE RELATED BUT CONTAINS MINIMUM/MAXIMUM PROPERTIES
    return !Utils.isValueMissing(desc.minimum) && !Utils.isValueMissing(desc.maximum);
  }

  function resolveRangeParserName(desc) {
    if (desc.minimum instanceof OptionalDesc) {
      return desc.minimum.base.parser.constructor.name;
    }

    return desc.minimum.parser.constructor.name;
  } // returns whether this desc includes description of the Range class (not the deprecated one)


  function isTheRange(desc) {
    return desc.minimum instanceof OptionalDesc;
  }

  if (propDescBase instanceof PropDesc) {
    switch (propDescBase.parser.constructor.name) {
      case ObjectDescParser.name:
        var desc = propDescBase.parser.desc;

        if (!isTransformToRange(desc)) {
          return null;
        }

        var rangeParserName = resolveRangeParserName(desc);

        if (rangeParserName === BigNumberParser.name) {
          if (!isTheRange(desc)) {
            return theRangeBigNumberTransformFunc;
          }

          return rangeBigNumberTransformFunc;
        } else if (rangeParserName === NumberParser.name) {
          if (isTheRange(desc)) {
            return theRangeNumberTransformFunc;
          }

          return rangeNumberTransformFunc;
        }

    }
  }

  return null;
};