"use strict";
/**
 * @file Created on 02/12/2019
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author Jaroslav Sevcik
 * @author Tomas Leity
 */

var ResourceLockType;

(function (ResourceLockType) {
  // Pricing
  ResourceLockType["CleaningCategory"] = "CLEANING_CATEGORY"; // Promo

  ResourceLockType["ForecastSimulation"] = "FORECAST_SIMULATION";
})(ResourceLockType = exports.ResourceLockType || (exports.ResourceLockType = {}));