/**
 * @file Created on Sat Feb 02 2019
 * @author SKu
 */

import {AgGrid, Button, LoadingState, translate, SplineChart, Alert, AlertType} from '@logio/common-fe';
import {computed} from 'mobx';
import * as React from 'react';
import {Grid, Row, Col} from 'react-flexbox-grid';
import {withRouter} from 'react-router';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {PriceElasticityReportPageStore} from '../../../stores/pages/Reports/RelatedReports/PriceElasticityReportPageStore';
import {AbstractRelatedReportsPage} from './AbstractRelatedReportsPage';
import {RelatedReportsFilterProps, RelatedReportsFormFilter} from './RelatedReportsFilter';
import {observer} from 'mobx-react';

/**
 * Competitor Price change Report
 */
@observer
class PriceElasticityReportPage extends AbstractRelatedReportsPage<RelatedReportsFormFilter> {
  public pageName = PageNamesEnum.PriceElasticityReport;
  public store = new PriceElasticityReportPageStore();

  @computed
  public get customFormProps() {
    const formProps: RelatedReportsFilterProps<RelatedReportsFormFilter> = {
      customFormFields: (
        <Button type="button" onClick={this.store.onCacheClear}>
          {translate('Clear')}
        </Button>
      ),
      isLoading: this.store.loadingState === LoadingState.Pending,
    };
    return formProps;
  }

  @computed
  public get renderContent() {
    return (
      this.store.categoryData.size > 0 &&
      (this.store.productData ? (
        <>
          <div className="mb1">
            <AgGrid
              rowData={this.store.rowData}
              columnDefs={this.store.columnDefs}
              quickView
              domLayout="autoHeight"
              gridId="PriceElasticityReportPage"
            />
          </div>
          <Grid fluid={true}>
            <Row>
              <Col md={4}>
                <SplineChart
                  xVisible
                  xAxisTitle={translate('ARP')}
                  yVisible
                  yAxisTitle={translate('%')}
                  showLegend
                  data={this.store.chartDataPercent}
                  showToolTip
                  tooltipOptions={{pointFormat: '{series.name}: <b>{point.y:.2f}</b>'}}
                />
              </Col>
              <Col md={4}>
                <SplineChart
                  xVisible
                  xAxisTitle={translate('ARP')}
                  yVisible
                  yAxisTitle={translate('CZK')}
                  showLegend
                  data={this.store.chartDataCzk}
                  showToolTip
                  tooltipOptions={{pointFormat: '{series.name}: <b>{point.y:.2f}</b>'}}
                />
              </Col>
              <Col md={4}>
                <SplineChart
                  xVisible
                  xAxisTitle={translate('ARP')}
                  yVisible
                  yAxisTitle={translate('Unit')}
                  showLegend
                  data={this.store.chartDataUnit}
                  showToolTip
                  tooltipOptions={{pointFormat: '{series.name}: <b>{point.y:.2f}</b>'}}
                />
              </Col>
            </Row>
          </Grid>
        </>
      ) : (
        <Alert type={AlertType.Info}>{translate('no-data for current filter')}</Alert>
      ))
    );
  }
}

export default withRouter(PriceElasticityReportPage);
