import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 10px;\n  padding: 1px;\n  border-radius: 4px;\n  border: 1px solid ", ";\n  background-color: ", ";\n\n  .status-bar-inner {\n    height: 6px;\n    background-color: ", ";\n    border-radius: 3px;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Oct 03 2018
 * @author PKl
 */
import styled, { withTheme } from 'styled-components';
/**
 * Create an ProgressBar component that'll render an <ProgressBar>
 */

var ProgressBarStyled = styled.div(_templateObject(), function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.colors.paper;
}, function (props) {
  return props.theme.colors.secondary;
});
export default withTheme(ProgressBarStyled);