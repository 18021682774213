/**
 * @file Created on January 2020
 * @author Atreo s.r.o.
 */
import * as React from 'react';
import { CONSTANTS } from '../../../../../constants';
/**
 * Render a tooltip
 */

export var ReleaseSelloutEndDateFinalPriceRenderer = function ReleaseSelloutEndDateFinalPriceRenderer(props) {
  return React.createElement("div", null, props && props.valueFormatted ? props.valueFormatted : CONSTANTS.FORMAT.NULL);
};