import { Utils } from "@logio/common-be-fe";
import generalComparator from "./generalComparator";
/**
 * @file Created on September 2019
 * @author Atreo Digital s.r.o.
 * @description Custom comparator for fields rendered by ArrowRenderer
 */

var arrowComparator = function arrowComparator(valA, valB) {
  return generalComparator(toComparableValue(valA), toComparableValue(valB));
};
/**
 *
 * @param val - can be consisted by:
 *   number | number object with "toNumber()" | object with "value" with/without "toNumber()"
 */


var toComparableValue = function toComparableValue(val) {
  if (!Utils.isValueMissing(val)) {
    if ("value" in val && !Utils.isValueMissing(val.value)) {
      if ("toNumber" in val.value && typeof val.value.toNumber === "function") {
        return val.value.toNumber();
      }

      if (!("toNumber" in val.value)) {
        return val.value;
      }
    } else {
      if ("toNumber" in val && typeof val.toNumber === "function") {
        return val.toNumber();
      }

      if (!("toNumber" in val.value)) {
        return val;
      }
    }
  }

  return null;
};

export default arrowComparator;