import _slicedToArray from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";

/**
 * @file Created on Tue Oct 30 2018
 * @author BPo
 */
import { Utils } from '@logio/common-be-fe';
/**
 * Checks specific conditions and evaluates if the item should be displayed.
 * @param formValues  All the values of the form
 * @param fieldRestrictions `fieldHide` or `fieldShow` object
 * @returns True if element should be shown, false otherwise
 */

var isValueFound = function isValueFound(formValues, fieldRestrictions, placeholderValues) {
  var _arr = Object.entries(fieldRestrictions);

  var _loop = function _loop() {
    var _arr$_i = _slicedToArray(_arr[_i], 2),
        key = _arr$_i[0],
        value = _arr$_i[1];

    if (Utils.isArray(value) && value.some(function (val) {
      return val === (formValues[key] || !formValues[key] && placeholderValues && placeholderValues[key]);
    })) {
      return {
        v: true
      };
    } else if ((formValues[key] || !formValues[key] && placeholderValues && placeholderValues[key]) === value) {
      return {
        v: true
      };
    }
  };

  for (var _i = 0; _i < _arr.length; _i++) {
    var _ret = _loop();

    if (typeof _ret === "object") return _ret.v;
  }

  return false;
};
/**
 * Checks all rendering conditions and evaluates if the item should be displayed.
 * @param fieldHide The element will be hidden once any of these values are matched
 * @param fieldShow The element will be shown once any of these values are matched
 * @param values All the values of the form
 * @param fieldPlaceholder Field placeholder value
 * @param showIfPlaceholder If value will be true and fieldPlaceholder will be true,
 * field will be rendered
 * @returns True if element should be shown, false otherwise
 */


export var shouldRenderField = function shouldRenderField(fieldHide, fieldShow, values, showIfPlaceholder, placeholderValues) {
  // Hide when match
  if (fieldHide) {
    if (isValueFound(values, fieldHide)) {
      return false;
    }
  } // Show when match


  if (fieldShow) {
    if (isValueFound(values, fieldShow, showIfPlaceholder && placeholderValues)) {
      return true;
    }

    return false;
  }

  return true;
};