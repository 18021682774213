/**
 * @file Created on Wed Sep 26 2018
 * @author SKu
 */

import {
  AgGrid,
  Page,
  FormGroupInline,
  Button,
  ButtonSize,
  translate,
  ConfirmBox,
  WithPermission,
} from '@logio/common-fe';
import {observer, Observer} from 'mobx-react';
import * as React from 'react';
import {CompetitorsPriorityPageStore, ModalEnum} from '../../stores/pages/Settings/CompetitorsPriorityPageStore';
import {CompetitorsPriorityPageForm} from './CompetitorsPriorityPageForm';
import {PageNamesEnum} from '../../shared/localization/PageNamesEnum';
import {PricingPermissions} from '@logio/common-be-fe';

/**
 * Renders Competitors Priority Page
 */
@observer
class CompetitorsPriorityPage extends React.Component {
  store = new CompetitorsPriorityPageStore();

  componentDidMount() {
    this.store.load();
  }

  render() {
    return (
      <Page titleKey={PageNamesEnum.CompetitorsPrioritySettings} loadingState={this.store.loadingState}>
        <Observer>
          {() => (
            <>
              <FormGroupInline right={true}>
                <WithPermission permissionTypes={[PricingPermissions.COMPETITOR_PRIORITY_SETTINGS_EDIT]}>
                  <Button buttonSize={ButtonSize.Tiny} onClick={this.store.openModal(ModalEnum.CREATE)}>
                    {translate('create-competitorsPriority')}
                  </Button>
                </WithPermission>
              </FormGroupInline>
              <CompetitorsPriorityPageForm store={this.store} />
              <CompetitorsPriorityPageForm store={this.store} createMode />
              <DeleteConfirmationModal store={this.store} />
              <AgGrid
                rowData={this.store.rowData}
                columnDefs={this.store.columnDefs}
                headerHeight={50}
                domLayout="autoHeight"
                gridId="PricingCompetitorsPriorityPage"
              />
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

export default CompetitorsPriorityPage;

/**
 * Delete competitorsPriority modal
 */
export const DeleteConfirmationModal: React.SFC<{store: CompetitorsPriorityPageStore}> = observer(
  (props: {store: CompetitorsPriorityPageStore}) => (
    <ConfirmBox
      hidden={props.store.modalHidden[ModalEnum.DELETE]}
      title={translate('Delete-competitorsPriority')}
      onAccept={() => {
        props.store.onSubmit(ModalEnum.DELETE)();
      }}
      close={props.store.getModalToggleEvent(ModalEnum.DELETE)}
    >
      {`${translate('Delete-competitorsPriority-confirmation')} "${props.store.initialValues.title}"`}
    </ConfirmBox>
  ),
);
