"use strict";
/*
 * Created on 10 10 2019
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Identifiers of exported data sets. It was created for export of data necessary for reports created in external BI tool.
 *
 * @author Jaroslav Sevcik
 */

var ExportedDataIdentifier;

(function (ExportedDataIdentifier) {
  ExportedDataIdentifier["Competitors"] = "competitors";
  ExportedDataIdentifier["PriceZones"] = "priceZones";
  ExportedDataIdentifier["Products"] = "products";
  ExportedDataIdentifier["SalePrices"] = "salePrices";
  ExportedDataIdentifier["PurchasePrices"] = "purchasePrices";
  ExportedDataIdentifier["SalesVolume"] = "salesVolume";
  ExportedDataIdentifier["CompetitorReferencePrices"] = "competitorReferencePrices";
  ExportedDataIdentifier["ReferencePrices"] = "referencePrices";
})(ExportedDataIdentifier = exports.ExportedDataIdentifier || (exports.ExportedDataIdentifier = {}));