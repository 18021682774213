import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  margin: 0;\n  padding: 0;\n  background-color: none;\n  flex-direction: column;\n  \n  > * {\n    flex: 1;\n    \n    &:not(:first-child) {\n      .accordion-header {\n        margin-top: 0rem;\n      }\n    }\n    \n    &:not(:last-child) {\n      .accordion-header {\n        border-bottom: none;\n      }\n    }\n    \n    :last-child {\n      .accordion-header {\n        border-bottom: 1px solid ", ";\n      }    \n    }\n    \n    &.isOpened {\n      :not(:last-child) {\n        .accordion-header {\n          border-bottom: 1px solid ", ";\n        }    \n      }\n    }\n    \n    \n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Tue Jul 16 2019
 * @author Tomáš Leity
 */
import styled, { withTheme } from 'styled-components';
var AccordionStyled = styled.div(_templateObject(), function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.colors.border;
});
export default withTheme(AccordionStyled);