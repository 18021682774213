import {
  SelloutReleaseType,
  SelloutTerminationLocalReason,
  SelloutTerminationReportFilterDTO,
  SelloutTerminationReportRowDTO,
  Utils,
} from '@logio/common-be-fe';
import {
  ColumnDefinition,
  ColumnGenerator,
  DownloadStore,
  ProductStore,
  ExtendedGridReadyEvent,
  LoadingState,
  PageStore,
  PriceZoneStore,
  rootStore,
  StoreName,
  translate,
} from '@logio/common-fe';
import { GridApi } from 'ag-grid-community';
import { List } from 'immutable';
import { action, computed, observable } from 'mobx';
import { SelloutTerminationReportDataSource } from './SelloutTerminationReportDataSource';
import moment from 'moment';

export class SelloutTerminationReportPageStore extends PageStore {
  /**
   * Default filter of the report. After loading this will never change.
   */
  public defaultFilter: SelloutTerminationReportFilterDTO;

  /**
   * If true, AG grid table with the report should be rendered - in the initial state it isn't.
   */
  @observable
  public reportVisible = false;

  /**
   * True if filter should be visible.
   */
  @observable
  public filterVisible = true;

  /**
   * ColumnGenerator to generate report columns and data based on `SelloutTerminationReportRowDTO.schema`.
   */
  private selloutIdentificationReportRowGenerator = new ColumnGenerator<SelloutTerminationReportRowDTO>(
    SelloutTerminationReportRowDTO.schema,
  );

  /**
   * AG grid API for manipulation - try to use as little as possible. Declarative control is much more reliable.
   */
  private gridApi: GridApi;

  /**
   * Data source for server Side row model of AG grid.
   */
  public dataSource = new SelloutTerminationReportDataSource(this.selloutIdentificationReportRowGenerator);

  /**
   * Price zone store to get national zone id for filter.
   */
  private priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;

  /**
   * True if export preparing or generating is in the progress.
   */
  @observable
  public exportInProgress = false;

  /**
   * If export is in the progress, contains progress monitor ID.
   */
  @observable
  public exportProgressMonitorId: string | null = null;

  /**
   * If export and its downloading is in the progress, contains export file name.
   */
  @observable
  public exportFilename: string | null = null;

  /**
   * Store/service for the export file download.
   */
  public downloadStore = rootStore.getStore(StoreName.Download) as DownloadStore;

  /**
   * Product store for validation of products.
   */
  public productStore = rootStore.getStore(StoreName.Product) as ProductStore;

  constructor() {
    super();
    this.load();
  }

  /**
   * Fetches data sellout termination report
   */
  public async load() {
    try {
      await this.priceZoneStore.getAll();

      this.defaultFilter = new SelloutTerminationReportFilterDTO(
        null,
        null,
        this.priceZoneStore.nationalZone.id,
        null,
        null,
        moment().startOf('day'),
        moment().endOf('day'),
      );

      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      this.setLoadingState(LoadingState.Error);
    }
  };

  @action.bound
  public async findReport(filter: SelloutTerminationReportFilterDTO) {
    this.reportVisible = true;
    this.dataSource.setFilter(filter);

    if (this.gridApi) {
      // grid already rendered - refresh it with new data
      this.gridApi.purgeServerSideCache();
    }
  }

  /**
   * Return generated column definitions of the report for ag-grid
   */
  @computed
  public get reportColumnDefs(): ColumnDefinition[] {
    const filterParams = {
      newRowsAction: "keep",
      suppressAndOrCondition: true,
      debounceMs: 0, // no need - dataSource is already debounced
      clearButton: true,
    };

    return this.selloutIdentificationReportRowGenerator.getColumnDefinitions([
      {
        field: 'productExternalId',
        valueFormatter: ({ value }) => value ? Utils.getCustomerId(value) : null,
        filterParams,
      },
      { field: 'productName', filterParams },
      { field: 'siteExternalId', filter: false, sortable: false },
      { field: 'siteName', filter: false, sortable: false },
      { field: 'endDate', filter: false, sortable: false },
      {
        field: 'reason',
        filter: false,
        sortable: false,
        valueFormatter: ({ value }) => {
          if (value) {
            if (Object.values(SelloutTerminationLocalReason).indexOf(value) >= 0) {
              return translate(`SelloutTerminationLocalReason_${value}`);
            }
            return value;
          }

          return null;
        },
      },
      { field: 'selloutPrice', filter: false, sortable: false },
      { field: 'priceValidFrom', filter: false, sortable: false },
      { field: 'priceValidTo', filter: false, sortable: false },
      {
        field: 'releaseType',
        valueFormatter: ({ value }) => value ? translate(value) : null,
        filterParams: {
          ...filterParams,
          values: Object.keys(SelloutReleaseType).map(key => SelloutReleaseType[key]),
        },
      },
      {
        field: 'newSelloutTypes',
        valueFormatter: ({ value }) => {
          if (!value) {
            return null;
          } else if (typeof value.map === 'function') {
            return value.map(item => translate(item)).join(', ');
          } else {
            return translate(value);
          }
        },
        filterParams: {
          ...filterParams,
          values: Object.keys(SelloutReleaseType).map(key => SelloutReleaseType[key]),
        },
      },
      { field: 'releaseId', filter: false, sortable: false },
      { field: 'releaseName', filter: false, sortable: false },
      { field: 'releaseWorkflowType', filter: false, sortable: false },
    ]).filter(def => def.fieldKey !== 'productExternalId');
  }

  @action.bound
  public onGridReady(grid: ExtendedGridReadyEvent) {
    this.gridApi = grid.api;
  }

  @action.bound
  public toggleFilter() {
    this.filterVisible = !this.filterVisible;
  }

  @action.bound
  public async exportReport() {
    this.exportInProgress = true;

    const { progressMonitorId, filename } = await this.dataSource.export();
    this.setExportInfo(progressMonitorId, filename);
  }

  @action.bound
  private setExportInfo(monitorId: string, filename: string) {
    this.exportProgressMonitorId = monitorId;
    this.exportFilename = filename;
  }

  @action.bound
  public async downloadExportedFile() {
    if (this.exportFilename) {
      await this.downloadStore.download(this.exportFilename);
      this.setExportInfo(this.exportProgressMonitorId, null);
    }
  }

  @action.bound
  public finishExport() {
    this.setExportInfo(null, this.exportFilename);
    this.exportInProgress = false;
  }
}
