/**
 * @file Created on Tue Sep 04 2018
 * @author BPo
 * @author SKu
 */
import { Utils } from '@logio/common-be-fe';
import { List } from 'immutable';
import * as React from 'react';
import translate from '../../i18n/translate/translate';
import { CheckBoxValidated } from '../../inputs/CheckBox';
import { CheckBoxGroupValidated } from '../../inputs/CheckBoxGroup';
import { DatePickerValidated } from '../../inputs/DatePicker';
import { InputValidated } from '../../inputs/Input';
import { InputRangeValidated } from '../../inputs/InputRange';
import { RadioGroupValidated } from '../../inputs/RadioGroup';
import { SelectValidated } from '../../inputs/Select';
import { SelectIconValidated } from '../../inputs/Select/SelectIcon';
import { RangeSliderValidated, SliderValidated } from '../../inputs/Slider';
import { TextAreaValidated } from '../../inputs/TextArea';
import Label from '../../typography/Label';
import FormGroup from '../FormGroup';
import { FileUploaderValidated } from '../../inputs/FileUploader';
import Tooltip from '../../info/Tooltip';
/**
 * Appends the star character after every required field
 * @param key Key to translate
 * @param required Optional required flag, when true * will be appended
 */

export var getMarkedLabel = function getMarkedLabel(key, required) {
  for (var _len = arguments.length, params = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    params[_key - 2] = arguments[_key];
  }

  return "".concat(translate.apply(void 0, [key].concat(params))).concat(required ? ' *' : '');
};
/**
 * renders Adapted Input
 */

export var InputAdapter = function InputAdapter(key) {
  var fieldReadOnly = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var fieldNoLabel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var required = arguments.length > 3 ? arguments[3] : undefined;
  var className = arguments.length > 4 ? arguments[4] : undefined;
  return function (props) {
    return React.createElement(FormGroup, {
      noLabel: fieldNoLabel
    }, React.createElement(InputValidated, Object.assign({}, props.input, {
      className: className,
      disabled: fieldReadOnly,
      label: fieldNoLabel ? undefined : getMarkedLabel("forms_input_".concat(key), required),
      placeholder: !Utils.isValueMissing(props.placeholder) ? props.placeholder.toString() : '',
      tiny: fieldNoLabel ? true : false,
      validationError: props.meta.touched ? props.meta.error : null,
      labelTooltipText: props.labelTooltipText
    })));
  };
};
/**
 * renders Adapted CheckBox
 */

export var CheckboxAdapter = function CheckboxAdapter(key) {
  var fieldReadOnly = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var fieldNoLabel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var required = arguments.length > 3 ? arguments[3] : undefined;
  var className = arguments.length > 4 ? arguments[4] : undefined;
  return function (props) {
    return React.createElement(FormGroup, {
      noLabel: fieldNoLabel
    }, React.createElement(CheckBoxValidated, Object.assign({}, props.input, {
      className: className,
      checked: Utils.isValueMissing(props.input.checked) && Utils.isBool(props.input.value) ? props.input.value : props.input.checked,
      disabled: fieldReadOnly,
      label: fieldNoLabel ? undefined : getMarkedLabel("forms_checkbox_".concat(key), required),
      placeholder: !Utils.isValueMissing(props.placeholder) ? props.placeholder.toString() : '',
      validationError: props.meta.touched ? props.meta.error : null
    })));
  };
};
/**
 * renders Adapted Input Number
 */

export var InputNumberAdapter = function InputNumberAdapter(key) {
  var fieldReadOnly = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var fieldNoLabel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var required = arguments.length > 3 ? arguments[3] : undefined;
  var className = arguments.length > 4 ? arguments[4] : undefined;
  var clearInput = arguments.length > 5 ? arguments[5] : undefined;
  var labelTooltipText = arguments.length > 6 ? arguments[6] : undefined;
  return function (props) {
    return React.createElement(FormGroup, {
      noLabel: fieldNoLabel
    }, React.createElement(InputValidated, Object.assign({}, props.input, {
      className: className,
      disabled: fieldReadOnly,
      label: fieldNoLabel ? undefined : getMarkedLabel("forms_number_".concat(key), required),
      placeholder: !Utils.isValueMissing(props.placeholder) ? props.placeholder.toString() : '',
      type: "number",
      tiny: fieldNoLabel ? true : false,
      validationError: props.meta.touched ? props.meta.error : null,
      labelTooltipText: labelTooltipText ? labelTooltipText : props.labelTooltipText,
      clearInput: clearInput
    })));
  };
};
/**
 * renders Adapted Select
 */

export var SelectAdapter = function SelectAdapter(key, selectOptions) {
  var multi = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var fieldReadOnly = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var fieldNoLabel = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var required = arguments.length > 5 ? arguments[5] : undefined;
  var className = arguments.length > 6 ? arguments[6] : undefined;
  var validationError = arguments.length > 7 ? arguments[7] : undefined;
  var labelTooltipText = arguments.length > 8 ? arguments[8] : undefined;
  var list = arguments.length > 9 ? arguments[9] : undefined;
  var creatable = arguments.length > 10 ? arguments[10] : undefined;
  return function (props) {
    var valueProps = {
      value: props.input.value,
      onChange: props.input.onChange
    };

    if (list) {
      // Hacky solution for list values (instead of array), but code is ugly, so we don't have a lot of options.
      if (multi) {
        // fix if something else is converting value
        if (props.input.value && !Utils.isArray(props.input.value)) {
          valueProps.value = props.input.value.toArray();
        }
      } else {
        // fix if something else is converting value
        if (props.input.value && typeof props.input.value !== 'string') {
          valueProps.value = props.input.value.first();
        }
      }

      valueProps.onChange = function (value) {
        if (!Utils.isArray(value)) {
          props.input.onChange(value ? value : null);
        } else {
          props.input.onChange(value.length === 0 ? null : List(value));
        }
      };
    }

    return React.createElement(FormGroup, {
      noLabel: fieldNoLabel
    }, React.createElement(SelectValidated, Object.assign({}, props.input, valueProps, {
      className: className,
      disabled: fieldReadOnly,
      filterOptions: selectOptions ? selectOptions.filterOptions : null,
      label: fieldNoLabel ? undefined : getMarkedLabel("forms_select_".concat(key), required),
      multi: multi,
      closeOnSelect: !multi,
      options: selectOptions ? selectOptions.options : null,
      optionRenderer: selectOptions ? selectOptions.optionsRenderer : null,
      placeholder: props.placeholder,
      tiny: fieldNoLabel ? true : false,
      validationError: props.meta.touched ? validationError || props.meta.error : null,
      labelTooltipText: labelTooltipText ? labelTooltipText : props.labelTooltipText,
      creatable: creatable
    })));
  };
};
/**
 * renders Adapted SelectIcon
 */

export var SelectIconAdapter = function SelectIconAdapter(key, selectOptions) {
  var multi = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var fieldReadOnly = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var fieldNoLabel = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var required = arguments.length > 5 ? arguments[5] : undefined;
  var className = arguments.length > 6 ? arguments[6] : undefined;
  return function (props) {
    return React.createElement(FormGroup, {
      noLabel: fieldNoLabel
    }, React.createElement(SelectIconValidated, Object.assign({}, props.input, {
      className: className,
      disabled: fieldReadOnly,
      filterOptions: selectOptions ? selectOptions.filterOptions : null,
      label: fieldNoLabel ? undefined : getMarkedLabel("forms_select_".concat(key), required),
      multi: multi,
      options: selectOptions ? selectOptions.options : null,
      optionRenderer: selectOptions ? selectOptions.optionsRenderer : null,
      placeholder: props.placeholder,
      tiny: fieldNoLabel ? true : false,
      validationError: props.meta.touched ? props.meta.error : null
    })));
  };
};
/**
 * renders Adapted Text Area
 */

export var TextAreaAdapter = function TextAreaAdapter(key) {
  var fieldReadOnly = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var fieldNoLabel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var required = arguments.length > 3 ? arguments[3] : undefined;
  var className = arguments.length > 4 ? arguments[4] : undefined;
  return function (props) {
    return React.createElement(FormGroup, {
      noLabel: fieldNoLabel
    }, React.createElement(TextAreaValidated, Object.assign({}, props.input, {
      className: className,
      disabled: fieldReadOnly,
      rows: fieldNoLabel ? 4 : undefined,
      label: fieldNoLabel ? undefined : getMarkedLabel("forms_textarea_".concat(key), required),
      placeholder: !Utils.isValueMissing(props.placeholder) ? props.placeholder.toString() : '',
      validationError: props.meta.touched ? props.meta.error : null
    })));
  };
};
/**
 * renders Adapted CheckBox Group
 */

export var CheckboxGroupAdapter = function CheckboxGroupAdapter(key, options, required, className) {
  var fieldNoLabel = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  return function (props) {
    return React.createElement(CheckBoxGroupValidated, {
      className: className,
      groupLabel: fieldNoLabel === true ? undefined : getMarkedLabel("forms_checkbox_".concat(key), required),
      name: props.input.name,
      options: options,
      onChange: function onChange(value) {
        return props.input.onChange(value);
      },
      validationError: props.meta.touched ? props.meta.error : null,
      values: props.input.value
    });
  };
};
/**
 * renders Adapted Radio Group
 */

export var RadioGroupAdapter = function RadioGroupAdapter(key, options) {
  var typeButton = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var required = arguments.length > 3 ? arguments[3] : undefined;
  var className = arguments.length > 4 ? arguments[4] : undefined;
  return function (props) {
    return React.createElement(FormGroup, null, React.createElement(RadioGroupValidated, Object.assign({}, props.input, {
      className: className,
      groupLabel: getMarkedLabel("forms_radiogroup_".concat(key), required),
      options: options,
      typeButton: typeButton,
      validationError: props.meta.touched ? props.meta.error : null
    })));
  };
};
/**
 * renders Adapted Slider
 */

export var SliderAdapter = function SliderAdapter(key, min, max, required, className) {
  return function (props) {
    return React.createElement(FormGroup, null, React.createElement(Label, null, getMarkedLabel("forms_number_".concat(key), required)), React.createElement(SliderValidated, {
      className: className,
      marksEnabled: false,
      max: max,
      min: min,
      onChange: function onChange(value) {
        return props.input.onChange(value);
      },
      step: 1,
      validationError: props.meta.touched ? props.meta.error : null,
      value: props.input.value
    }));
  };
};
/**
 * renders Adapted Slider
 */

export var RangeSliderAdapter = function RangeSliderAdapter(key, min, max, required, className) {
  return function (props) {
    return React.createElement(FormGroup, null, React.createElement(Label, null, getMarkedLabel("forms_number_".concat(key), required)), React.createElement(RangeSliderValidated, {
      className: className,
      marksEnabled: false,
      inputsEnabled: true,
      step: 1,
      min: min,
      max: max,
      defaultValue: props.input.value,
      value: props.input.value,
      onChange: function onChange(value) {
        return props.input.onChange(value);
      },
      validationError: props.meta.touched ? props.meta.error : null
    }));
  };
};
/**
 * renders Adapted Input
 */

export var InputRangeAdapter = function InputRangeAdapter(key) {
  var fieldReadOnly = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var fieldNoLabel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var required = arguments.length > 3 ? arguments[3] : undefined;
  var className = arguments.length > 4 ? arguments[4] : undefined;
  var clearInput = arguments.length > 5 ? arguments[5] : undefined;
  var labelTooltipText = arguments.length > 6 ? arguments[6] : undefined;
  return function (props) {
    return React.createElement(FormGroup, {
      noLabel: fieldNoLabel
    }, React.createElement(InputRangeValidated, Object.assign({}, props.input, {
      className: className,
      fieldReadOnly: fieldReadOnly,
      label: fieldNoLabel ? undefined : getMarkedLabel("forms_input_".concat(key), required),
      placeholder: Utils.isArray(props.placeholder) ? props.placeholder : undefined,
      tiny: fieldNoLabel ? true : false,
      validationError: props.meta.touched ? props.meta.error : null,
      clearInput: clearInput,
      labelTooltipText: labelTooltipText ? labelTooltipText : props.labelTooltipText
    })));
  };
};

var getDatePickerInputLabel = function getDatePickerInputLabel(props, key, required) {
  return props.labelTooltipText ? React.createElement(Label, null, React.createElement(Tooltip, {
    overlay: props.labelTooltipText,
    children: React.createElement("div", null, getMarkedLabel("forms_date_".concat(key), required))
  })) : React.createElement(Label, null, getMarkedLabel("forms_date_".concat(key), required));
};
/**
 * renders Adapted DatePicker
 */


export var DatePickerInputAdapter = function DatePickerInputAdapter(key) {
  var fieldReadOnly = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var required = arguments.length > 3 ? arguments[3] : undefined;
  var className = arguments.length > 4 ? arguments[4] : undefined;
  var format = arguments.length > 5 ? arguments[5] : undefined;
  var defaultValue = arguments.length > 6 ? arguments[6] : undefined;
  return function (props) {
    return React.createElement(FormGroup, null, getDatePickerInputLabel(props, key, required), React.createElement(DatePickerValidated, Object.assign({}, props.input, {
      className: className,
      dayPickerProps: options,
      disabled: fieldReadOnly,
      format: format,
      validationError: props.meta.touched ? props.meta.error : null,
      defaultValue: defaultValue
    })));
  };
};
/**
 * renders File uploader
 * Supports only manually field creation(through field component property)
 *
 */

export var FileUploadAdapter = function FileUploadAdapter(key, required, className) {
  return function (props) {
    var label = "forms_file_".concat(key);
    return React.createElement(FormGroup, null, React.createElement(Label, null, getMarkedLabel(label, required)), React.createElement(FileUploaderValidated, {
      className: className,
      id: label,
      events: [{
        key: 'file-added',
        action: function action(file) {
          props.input.onChange(file);
        }
      }],
      validationError: props.meta.touched ? props.meta.error : null
    }));
  };
};