/**
 * @file Created on Mon Nov 25 2019
 *
 * @///<reference path="packages/common-dp/src/core/transfer/common/model/TransferStatus.ts"/>
 *
 * @author Tomáš Leity
 * @author Vojtěch Smolař
 */

/**
 * @enum
 */
export var DPTransferStatus;

(function (DPTransferStatus) {
  /**
   * Task not started
   */
  DPTransferStatus["NotStarted"] = "NOT_STARTED";
  /**
   * Task is running.
   */

  DPTransferStatus["Pending"] = "PENDING";
  /**
   * Task finished with success.
   */

  DPTransferStatus["Success"] = "SUCCESS";
  /**
   * Task failed.
   */

  DPTransferStatus["Failed"] = "FAILED";
})(DPTransferStatus || (DPTransferStatus = {}));