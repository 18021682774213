/**
 * @file Rounding rules page
 * @author VBr
 * @author LZe
 */

import { PricingPermissions, RoundingRule, ValidationError } from '@logio/common-be-fe';
import {
  BoxTabContainer,
  Button,
  ButtonColor,
  ButtonLoading,
  CheckBox,
  Field,
  Form,
  FormGroupInline,
  getPath,
  Icon,
  IconColor,
  IconType,
  Input,
  Messages,
  Modal,
  Page,
  TabMenu,
  translate,
  WithPermission,
} from '@logio/common-fe';
import { Observer, observer } from 'mobx-react';
import * as React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { match } from 'react-router';
import { IRouteParams } from 'routes-app';
import { PageNamesEnum } from '../../shared/localization/PageNamesEnum';
import { PagePathsEnum } from '../../shared/localization/PagePathsEnum';
import { RoundingRulePageStore } from '../../stores/pages/Settings/RoundingRulesPageStore';

interface Props {
  match: match<IRouteParams>;
}
@observer
class RoundingRulesPage extends React.Component<Props> {
  store = new RoundingRulePageStore(this.props.match.path === getPath(PagePathsEnum.RoundingRulesSettings));

  componentWillMount() {
    this.store.load();
  }

  render() {
    return (
      <Page titleKey={PageNamesEnum.RoundingRulesSettings} loadingState={this.store.loadingState} showMessages={false}>
        <Observer>
          {() => (
            <>
              <TabMenu
                tabs={[
                  {
                    to: getPath(PagePathsEnum.RoundingRulesSettings),
                    content: translate('Weighted'),
                    exact: true,
                  },
                  {
                    to: getPath(PagePathsEnum.RoundingRulesSettingsUnweighted),
                    content: translate('Unweighted'),
                  },
                ]}
              />
              <BoxTabContainer>
                <Messages />
                <Row>
                  <Col xs={10} md={6}>
                    <WithPermission permissionTypes={[PricingPermissions.ROUNDING_RULES_EXAMPLE]}>
                      <form onSubmit={this.store.testRoundingRule}>
                        <FormGroupInline>
                          <Input
                            label={`${translate('Test rounding')}:`}
                            value={this.store.roundingTest.value}
                            disabled={this.store.roundingTest.isLoading}
                            onChange={this.store.testRoundingRuleChanged}
                            type="number"
                          />
                          <ButtonLoading type="submit" isLoading={this.store.roundingTest.isLoading}>
                            {translate('Test')}
                          </ButtonLoading>
                          {this.store.roundingTest.result != null && (
                            <span className="ml1">
                              {translate('Result')}: <strong>{this.store.roundingTest.result}</strong>
                            </span>
                          )}
                        </FormGroupInline>
                      </form>
                    </WithPermission>
                  </Col>
                  <Col xs={14} md={6}>
                    <WithPermission permissionTypes={[PricingPermissions.ROUNDING_RULES_SETTINGS]}>
                      <FormGroupInline right>
                        <Button iconLeft onClick={this.store.new}>
                          {translate('Add rule')}
                        </Button>
                      </FormGroupInline>
                    </WithPermission>
                  </Col>
                </Row>
                <table className="table table-stripedX w-100">
                  <thead>
                    <tr>
                      <th className="w-10">{translate('Price range')}</th>
                      <th className="w-10">&nbsp;</th>
                      <th className="w-10">{translate('Rounding step')}</th>
                      <th className="w-10">{translate('Baťa price')}</th>
                      <th className="w-10">{translate('Order of magnitude')}</th>
                      <th className="w-10">{translate('Treshold')}</th>
                      <th className="w-10">{translate('Decrease')}</th>
                      <WithPermission permissionTypes={[PricingPermissions.ROUNDING_RULES_SETTINGS]}>
                        <th className="w-5">&nbsp;</th>
                      </WithPermission>
                    </tr>
                  </thead>
                  <tbody>
                    {this.store.list.map((roundingRule: RoundingRule, index, roundingRules) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td className="tar" rowSpan={2}>
                            {roundingRule.startingAt.toString()}
                          </td>
                          <td className="tar" rowSpan={2}>
                            {roundingRule.startingAt.toString()} -{' '}
                            {roundingRules[index + 1] ? (
                              roundingRules[index + 1].startingAt.sub(0.001).toString()
                            ) : (
                              <span>&infin;</span>
                            )}
                          </td>
                          <td className="tar" rowSpan={2}>
                            {roundingRule.roundingStep.toString()}
                          </td>
                          <td>
                            <CheckBox
                              name={`useBataPrice10_${roundingRule.id}`}
                              disabled={
                                !this.store.keycloakStore.userHasPermissions([
                                  PricingPermissions.ROUNDING_RULES_SETTINGS,
                                ])
                              }
                              checked={roundingRule.useBataPrice10}
                              onChange={() =>
                                this.store.updateBataPrice(roundingRule, 'useBataPrice10', !roundingRule.useBataPrice10)
                              }
                            />
                          </td>
                          <td className="tar">10</td>
                          <td className="tar">
                            {roundingRule.threshold10 != null && roundingRule.threshold10.toString()}
                          </td>
                          <td className="tar">
                            {roundingRule.decrease10 != null && roundingRule.decrease10.toString()}
                          </td>
                          <WithPermission permissionTypes={[PricingPermissions.ROUNDING_RULES_SETTINGS]}>
                            <td rowSpan={2} className="tac">
                              <Icon
                                iconType={IconType.edit}
                                iconColor={IconColor.Secondary}
                                iconHeight={20}
                                className="mr1"
                                onClick={() => this.store.edit(roundingRule.id)}
                              />
                              <Icon
                                iconType={IconType.delete}
                                iconColor={IconColor.Secondary}
                                iconHeight={20}
                                onClick={() => this.store.delete(roundingRule.id)}
                                disabled={roundingRules.length === 1}
                              />
                            </td>
                          </WithPermission>
                        </tr>
                        <tr>
                          <td>
                            <CheckBox
                              name={`useBataPrice1_${roundingRule.id}`}
                              checked={roundingRule.useBataPrice1}
                              disabled={
                                !this.store.keycloakStore.userHasPermissions([
                                  PricingPermissions.ROUNDING_RULES_SETTINGS,
                                ])
                              }
                              onChange={() => {
                                this.store.updateBataPrice(roundingRule, 'useBataPrice1', !roundingRule.useBataPrice1);
                              }}
                            />
                          </td>
                          <td className="tar">1</td>
                          <td className="tar">
                            {roundingRule.threshold1 != null && roundingRule.threshold1.toString()}
                          </td>
                          <td className="tar">{roundingRule.decrease1 != null && roundingRule.decrease1.toString()}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </BoxTabContainer>
              <Modal
                title={this.store.formValues.id ? translate('Edit rule') : translate('Add new rule')}
                hidden={!this.store.modalVisible}
                close={this.store.toggleModal}
              >
                <Form
                  descriptions={RoundingRule.schema}
                  onSubmit={this.store.onSubmit}
                  initialValues={this.store.formValues}
                >
                  <Field name="startingAt" />
                  <Field name="roundingStep" />

                  <h4>{translate('Baťa order of magnitude')} 10:</h4>
                  <Field name="useBataPrice10" />
                  <Field
                    name="threshold10"
                    fieldShow={{useBataPrice10: [true]}}
                    validate={(value) => (!value ? new ValidationError('err-required') : null)}
                  />
                  <Field
                    name="decrease10"
                    fieldShow={{useBataPrice10: [true]}}
                    validate={(value) => (!value ? new ValidationError('err-required') : null)}
                  />

                  <h4>{translate('Baťa order of magnitude')} 1:</h4>
                  <Field name="useBataPrice1" />
                  <Field
                    name="threshold1"
                    fieldShow={{useBataPrice1: [true]}}
                    validate={(value) => (!value ? new ValidationError('err-required') : null)}
                  />
                  <Field
                    name="decrease1"
                    fieldShow={{useBataPrice1: [true]}}
                    validate={(value) => (!value ? new ValidationError('err-required') : null)}
                  />

                  <FormGroupInline right={true}>
                    <Button buttonColor={ButtonColor.Light} onClick={this.store.toggleModal}>
                      {translate('Cancel')}
                    </Button>
                    <Button type="submit">{translate('Submit')}</Button>
                  </FormGroupInline>
                </Form>
              </Modal>
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

export default RoundingRulesPage;
