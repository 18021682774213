import _slicedToArray from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import * as React from 'react';
import { CONSTANTS } from '../../../constants';
import { session } from '../../../shared/storage';
import rootStore from '../../../shared/stores/RootStore';
import { StoreName } from '../../../shared/stores/StoreName';
import Button, { ButtonColor, ButtonSize } from '../../controls/Button';
import FormGroup from '../../forms/FormGroup/FormGroup';
import translate from '../../i18n/translate';
import Alert, { AlertType } from '../../info/Alert';
export var SubstitutionComponent = function SubstitutionComponent(_ref) {
  var usersAllowedToSubstitute = _ref.usersAllowedToSubstitute;
  var keycloakStore = rootStore.getStore(StoreName.Keycloak);
  /** substitutedUserId used only to determine if button should be disabled */

  var _React$useState = React.useState(session.get(CONSTANTS.KEYCLOAK.SUBSTITUTED_USER_ID)),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      substitutedUserId = _React$useState2[0],
      setSubstitutedUserId = _React$useState2[1];
  /**
   * Set substitutor id to session => in api,
   * this id will be added as header to every request
   */


  var setSubstitutor = function setSubstitutor(userId) {
    setSubstitutedUserId(userId);
    session.add(CONSTANTS.KEYCLOAK.SUBSTITUTED_USER_ID, userId);
    keycloakStore.initialize();
  };
  /**
   * Remove id from session storage
   */


  var deleteSubstitutor = function deleteSubstitutor() {
    setSubstitutedUserId(null);
    session.remove(CONSTANTS.KEYCLOAK.SUBSTITUTED_USER_ID);
    keycloakStore.initialize();
  };

  return React.createElement(React.Fragment, null, usersAllowedToSubstitute.length > 0 ? React.createElement(React.Fragment, null, React.createElement(FormGroup, null, React.createElement("table", {
    className: "table table-stripedX w-100"
  }, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, translate('user-name')), React.createElement("th", null, translate('email')), React.createElement("th", null, translate('action')))), React.createElement("tbody", null, usersAllowedToSubstitute.map(function (user) {
    return React.createElement("tr", {
      key: user.id
    }, React.createElement("th", null, "".concat(user.firstName, " ").concat(user.lastName)), React.createElement("td", null, user.email), React.createElement("td", null, React.createElement(Button, {
      value: user.id,
      onClick: function onClick() {
        return setSubstitutor(user.id);
      },
      buttonSize: ButtonSize.Tiny,
      disabled: substitutedUserId === user.id
    }, translate('substitute'))));
  })))), React.createElement(Button, {
    onClick: deleteSubstitutor,
    buttonColor: ButtonColor.Light
  }, translate('cancel'))) : React.createElement(Alert, {
    type: AlertType.Info
  }, translate('info-no-substitutors')));
};