import _toConsumableArray from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

/**
 * @file Created on Thu Aug 09 2018
 * @author BPo
 */
import translate from '../../i18n/translate';
import * as React from 'react';
import { StoreName } from '../../../shared/stores/StoreName';
import rootStore from '../../../shared/stores/RootStore';
import { CONSTANTS } from '../../../constants'; // tslint:disable-next-line

var _require = require('react-helmet'),
    Helmet = _require.Helmet;
/**
 * For the head tags modification you can use reusable `PageInfo` component.
 * It replaces HTML tags (like title, description) in the document head. The
 * ideal way to use it is to render `PageInfo` on every page component after
 * router redirect.
 * @param props
 */


export var PageInfo = function PageInfo(props) {
  var title = props.titleParams ? translate.apply(void 0, [props.titleKey].concat(_toConsumableArray(props.titleParams))) : translate(props.titleKey);
  var titleComponent = props.titleComponent ? props.titleComponent : title;
  var description = props.descriptionParams ? translate.apply(void 0, [props.descriptionKey].concat(_toConsumableArray(props.descriptionParams))) : translate(props.descriptionKey);

  var updateInfo = function updateInfo() {
    var store = rootStore.getStore(StoreName.PageInfo);
    store.updatePageInfo(titleComponent, description);
  };

  return React.createElement(Helmet, {
    onChangeClientState: updateInfo
  }, React.createElement("title", null, CONSTANTS.APP.TITLE_SUFFIX_KEY ? "".concat(title, " \xB7 ").concat(translate(CONSTANTS.APP.TITLE_SUFFIX_KEY)) : title), props.descriptionKey && React.createElement("meta", {
    name: "description",
    content: description
  }));
};