/**
 * @file Created on Mon Aug 13 2018
 * @author VBr
 * @author LZe
 */

import { bigdecimal } from '@logio/big-decimal';
import {RoundingRule} from '@logio/common-be-fe';
import {
  LoadingState,
  PageStore,
  rootStore,
  RoundingRuleStore,
  StoreName,
  translate,
  KeycloakStore,
} from '@logio/common-fe';
import {action, computed, observable, runInAction} from 'mobx';

interface RoundingRuleForm {
  id?: string;
  decrease1?: string;
  decrease10?: string;
  roundingStep?: string;
  startingAt?: string;
  threshold1?: string;
  threshold10?: string;
  useBataPrice1?: boolean;
  useBataPrice10?: boolean;
  weighted?: boolean;
}

export class RoundingRulePageStore extends PageStore {
  constructor(weighted: boolean) {
    super();
    this.weighted = weighted;
  }

  /** Get dependant stores */
  roundingRulesStore = rootStore.getStore(StoreName.RoundingRule) as RoundingRuleStore;
  keycloakStore = rootStore.getStore(StoreName.Keycloak) as KeycloakStore;

  @observable
  weighted: boolean;

  @observable
  roundingTest = {
    value: '',
    result: null,
    isLoading: false,
  };

  @observable
  modalVisible: boolean = false;

  @observable
  formValues: RoundingRuleForm = {};

  @computed
  get list(): RoundingRule[] {
    return Array.from(this.roundingRulesStore.list.values())
      .filter((rule) => rule.weighted === this.weighted)
      .sort((a, b) => a.startingAt.comparedTo(b.startingAt));
  }

  testRoundingRule = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.round(this.roundingTest.value);
  };

  @action
  testRoundingRuleChanged = (e: React.FormEvent<HTMLInputElement>): void => {
    this.roundingTest.value = e.currentTarget.value;
    this.roundingTest.result = null;
  };

  @action
  round = (price: string): void => {
    this.roundingTest.isLoading = true;
    this.roundingRulesStore.roundingRuleLayer
      .round(price, this.weighted)
      .then((result) => {
        runInAction(() => {
          this.roundingTest.result = result;
          this.roundingTest.isLoading = false;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.roundingTest.isLoading = false;
        });
        // this.messages.setError(error);
      });
  };

  @action
  new = () => {
    this.formValues = {
      useBataPrice1: false,
      useBataPrice10: false,
      weighted: this.weighted,
    };
    this.toggleModal();
  };

  /**
   * Converts form values to the RoundingRule
   */
  roundingRuleFromForm = (values: RoundingRuleForm): RoundingRule => {
    return new RoundingRule(
      values.id,
      bigdecimal(values.roundingStep),
      bigdecimal(values.startingAt),
      values.threshold1 != null ? bigdecimal(values.threshold1) : null,
      values.threshold10 != null ? bigdecimal(values.threshold10) : null,
      values.useBataPrice1,
      values.useBataPrice10,
      values.decrease1 != null ? bigdecimal(values.decrease1) : null,
      values.decrease10 != null ? bigdecimal(values.decrease10) : null,
      values.weighted,
    );
  };

  /**
   * Converts RoundingRule to the form values
   */
  roundingRuleToForm = (rule: RoundingRule): any => {
    return {
      id: rule.id,
      roundingStep: rule.roundingStep.toString(),
      startingAt: rule.startingAt.toString(),
      threshold1: rule.threshold1 != null ? rule.threshold1.toString() : null,
      threshold10: rule.threshold10 != null ? rule.threshold10.toString() : null,
      useBataPrice1: rule.useBataPrice1,
      useBataPrice10: rule.useBataPrice10,
      decrease1: rule.decrease1 != null ? rule.decrease1.toString() : null,
      decrease10: rule.decrease10 != null ? rule.decrease10.toString() : null,
      weighted: rule.weighted,
    };
  };

  @action
  create = (rule: RoundingRuleForm): void => {
    const roundingRule = this.roundingRuleFromForm(rule);
    this.setLoadingState(LoadingState.Pending);
    this.roundingRulesStore
      .create(roundingRule)
      .then(() => {
        this.setLoadingState(LoadingState.Success);
        this.toggleModal();
        this.messages.setSuccess(translate('RoundingRules_created'));
      })
      .catch((error) => {
        this.setLoadingState(LoadingState.Error);
        // this.messages.setError(error);
      });
  };

  @action
  update = (rule: RoundingRuleForm): void => {
    const roundingRule = this.roundingRuleFromForm(rule);
    this.setLoadingState(LoadingState.Pending);
    this.roundingRulesStore
      .update(roundingRule)
      .then(() => {
        this.setLoadingState(LoadingState.Success);
        this.messages.setSuccess(translate('RoundingRules_updated'));
        this.toggleModal();
      })
      .catch((error) => {
        this.setLoadingState(LoadingState.Error);
        // this.messages.setError(error);
      });
  };

  @action
  updateBataPrice = (rule: RoundingRule, property: string, value) => {
    let myRule;
    if (value) {
      this.formValues = this.roundingRuleToForm(rule);
      this.formValues[property] = true;
      this.toggleModal();
      return;
    } else {
      if (property === 'useBataPrice1') {
        myRule = rule
          .copy('useBataPrice1', false)
          .copy('decrease1', null)
          .copy('threshold1', null);
      }
      if (property === 'useBataPrice10') {
        myRule = rule
          .copy('useBataPrice10', false)
          .copy('decrease10', null)
          .copy('threshold10', null);
      }
    }
    this.setLoadingState(LoadingState.Pending);
    this.roundingRulesStore
      .update(myRule)
      .then(() => {
        this.setLoadingState(LoadingState.Success);
        this.messages.setSuccess(translate('RoundingRules_updated'));
      })
      .catch((error) => {
        this.setLoadingState(LoadingState.Error);
        // this.messages.setError(error);
      });
  };

  @action
  edit = (id: string) => {
    this.formValues = this.roundingRuleToForm(this.roundingRulesStore.list.get(id));
    this.toggleModal();
  };

  @action
  delete = async (id: string) => {
    this.setLoadingState(LoadingState.Pending);
    try {
      await this.roundingRulesStore.delete(id);
      await this.roundingRulesStore.getAll();
      this.setLoadingState(LoadingState.Success);
      this.messages.setSuccess(translate('RoundingRules_deleted'));
    } catch (error) {
      this.setLoadingState(LoadingState.Error);
      // this.messages.setError(error);
    }
  };

  @action
  toggleModal = () => {
    this.modalVisible = !this.modalVisible;
  };

  onSubmit = (rule: any): void => {
    rule.id ? this.update(rule) : this.create(rule);
  };

  /**
   * Fetches all data for this page
   */
  @action
  load = (): void => {
    this.setLoadingState(LoadingState.Pending);

    this.roundingRulesStore
      .getAll()
      .then(() => this.setLoadingState(LoadingState.Success))
      .catch((error) => {
        this.setLoadingState(LoadingState.Error);
        // this.messages.setError(error);
      });
  };
}
