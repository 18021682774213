/**
 * @file Created on Sat Oct 06 2018
 * @author SKu
 * @author LZe
 */

import {
  AgGrid,
  BoxTabContainer,
  Button,
  ButtonColor,
  CheckBox,
  Messages,
  FormGroupInline,
  Icon,
  IconType,
  Page,
  Search,
  translate,
  ButtonSize,
  Alert,
  AlertType,
  Modal,
  getPath,
  Select,
  FormStore,
  rootStore,
} from '@logio/common-fe';
import {observer, Observer} from 'mobx-react';
import * as React from 'react';
import {Col, Grid, Row} from 'react-flexbox-grid';
import {match} from 'react-router';
import {IRouteParams} from 'routes-app';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {DataPairingUnpairedPageStore} from '../../../stores/pages/DataManagement/DataPairing/DataPairingUnpairedPageStore';
import Tabs from './Tabs';
import {parse} from 'querystring';
import {Location} from 'history';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {packageSizeHelper} from '../../../shared/packageSizeHelper';

export interface Props {
  match: match<IRouteParams>;
  location: Location;
}

@observer
class DataPairingUnpairedPage extends React.Component<Props> {
  store = new DataPairingUnpairedPageStore(
    this.props.match.params.id,
    parse(this.props.location.search.slice(1)).internalQuery,
  );

  componentWillMount() {
    this.store.load();
  }

  render() {
    return (
      <Page
        titleKey={PageNamesEnum.DataPairingProducts}
        titleParams={[
          this.store.categoryStore.list.get(this.store.categoryId)
            ? this.store.categoryStore.list.get(this.store.categoryId).name
            : '',
        ]}
        loadingState={this.store.loadingState}
        showMessages={false}
        backButtonPath={getPath(PagePathsEnum.DataPairing)}
      >
        <Observer>
          {() => (
            <>
              <Tabs id={this.props.match.params.id} />
              <BoxTabContainer>
                <Messages />
                {this.store.dataPairingStore.competitorProducts.size > 1000 && (
                  <Alert className="mb1" type={AlertType.Info}>
                    {translate('DATA_PAIRING_TOO_MANY_COMPETITOR_PRODUCTS')}
                  </Alert>
                )}
                <Grid fluid>
                  <Row>
                    <Col xs>
                      <h3>{translate('Internal')}</h3>
                      <FormGroupInline right={false}>
                        <Search
                          disabled={this.store.lockInputsInternal}
                          title={`${translate('PairingUpairedPageInternalFilterPlaceholder')}`}
                          value={this.store.productsParameters.fullTextSearch}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              this.store.searchProducts();
                              const target = event.currentTarget;
                              setTimeout(() => {
                                target.focus();
                              }, 500);
                            }
                          }}
                          onChange={this.store.onProductsFilter}
                          onReset={() => {
                            if (this.store.lockInputsInternal) {
                              return;
                            }
                            this.store.resetProductsFilter();
                          }}
                        />
                        <Button
                          iconLeft
                          buttonSize={ButtonSize.Normal}
                          disabled={
                            this.store.selectedProductsRowsCount === 0 ||
                            this.store.selectedCompetitorProductsRowsCount === 0
                          }
                          onClick={this.store.pairSelectedInModal}
                        >
                          <Icon iconType={IconType.pair} />
                          {translate('Pair selected')}
                        </Button>
                      </FormGroupInline>
                      <AgGrid
                        rowData={this.store.productsRowData}
                        columnDefs={this.store.productsColDefsWithCheckBox}
                        onGridReady={this.store.onProductsGridReady}
                        rowSelection="single"
                        onSelectionChanged={this.store.onProductsSelectionChanged}
                        onRowDataChanged={this.store.onProductsSelectionChanged}
                        suppressRowClickSelection
                        onColumnVisible={packageSizeHelper.onColumnVisible}
                        deltaRowDataMode={true}
                        getRowNodeId={({ProductInfo_id: id}) => id}
                        gridId="PricingDataPairingUnpairedPageProducts"
                        quickView
                        pagination
                        paginationActivePage={this.store.productPaging.activePage}
                        paginationHandlePageChange={this.store.searchProducts}
                        paginationTotalPages={this.store.productPaging.totalPages}
                        paginationLimit={this.store.productPaging.limit}
                        paginationTotal={this.store.productPaging.total}
                        paginationOffset={this.store.productPaging.offset}
                        height="600px"
                      />
                    </Col>
                    <Col xs>
                      <h3>{translate('External')}</h3>
                      <FormGroupInline right={false}>
                        <Search
                          disabled={this.store.lockInputsExternal}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              this.store.searchCompetitorProducts()
                              const target = event.currentTarget;
                              setTimeout(() => {
                                target.focus();
                              }, 500);
                            }
                          }}
                          title={`${translate('PairingUpairedPageExternalFilterPlaceholder')}`}
                          value={this.store.competitorProductsParameters.fullTextSearch}
                          onChange={this.store.onChangeFullTextSearch}
                          onReset={() => {
                            if (this.store.lockInputsExternal) {
                              return;
                            }
                            this.store.resetCompetitorProductsFilter()
                          }}
                        />
                        <span className="dn" /> {/* Hack to prevent Select to have top margin */}
                        <Select
                          options={this.store.competitorOptions}
                          multi
                          disabled={this.store.lockInputsExternal}
                          placeholder="Competitor"
                          value={this.store.competitorProductsParameters.competitorIds ? this.store.competitorProductsParameters.competitorIds.toArray() : null}
                          onChange={this.store.onCompetitorsChange}
                        />
                        <Button
                          buttonColor={ButtonColor.Light}
                          buttonSize={ButtonSize.Normal}
                          disabled={this.store.selectedCompetitorProductsRowsCount === 0}
                          onClick={this.store.ignoreUnignoreSelected}
                        >
                          {translate('Ignore selected')}
                        </Button>
                        <CheckBox
                          name="Hide ignored"
                          label={
                            this.store.competitorProductsParameters.ignored
                              ? translate('Hide ignored')
                              : translate('Show ignored')
                          }
                          typeButton
                          defaultChecked={true}
                          onChange={this.store.onChangeHide}
                          tinyButton={false}
                        />
                      </FormGroupInline>
                      <AgGrid
                        rowData={this.store.competitorProductsRowData}
                        columnDefs={this.store.competitorProductsColDefs}
                        onGridReady={this.store.onCompetitorProductsGridReady}
                        rowSelection="multiple"
                        onSelectionChanged={this.store.onCompetitorProductsSelectionChanged}
                        onRowDataChanged={this.store.onCompetitorProductsSelectionChanged}
                        suppressRowClickSelection
                        deltaRowDataMode={true}
                        getRowNodeId={({CompetitorProduct_id: id}) => id}
                        rowClassRules={{
                          disabled: ({data}) => data.CompetitorProduct_ignored,
                        }}
                        gridId="PricingDataPairingUnpairedPageCompetitorProducts"
                        quickView
                        pagination
                        paginationActivePage={this.store.competitorProductPaging.activePage}
                        paginationHandlePageChange={this.store.searchCompetitorProducts}
                        paginationTotalPages={this.store.competitorProductPaging.totalPages}
                        paginationLimit={this.store.competitorProductPaging.limit}
                        paginationTotal={this.store.competitorProductPaging.total}
                        paginationOffset={this.store.competitorProductPaging.offset}
                        height="600px"
                      />
                    </Col>
                  </Row>
                  <Modal
                    title={translate('Pair products')}
                    hidden={this.store.pairingModalHidden}
                    close={this.store.togglePairingModal}
                    wide
                  >
                    <Messages />

                    <h2>{translate('Internal product')}</h2>
                    <AgGrid
                      rowData={this.store.productsInModalRowData}
                      columnDefs={this.store.productsColDefs}
                      suppressRowClickSelection
                      domLayout="autoHeight"
                      gridId="PricingDataPairingUnpairedPageProductsModal"
                    />
                    <h2 className="mt2">{translate('External product')}</h2>
                    <AgGrid
                      rowData={this.store.competitorProductsInModalRowData}
                      columnDefs={this.store.competitorProductsColDefsInModal}
                      onGridReady={this.store.onCompetitorProductsInModalGridReady}
                      onSelectionChanged={this.store.onCompetitorProductsInModalSelectionChanged}
                      onRowDataChanged={this.store.onCompetitorProductsInModalSelectionChanged}
                      rowSelection="multiple"
                      suppressRowClickSelection
                      domLayout="autoHeight"
                      onColumnVisible={packageSizeHelper.onColumnVisible}
                      gridId="PricingDataPairingUnpairedPageCompetitorProductsModal"
                      quickView
                    />
                    <FormGroupInline right={true} className="mt2">
                      <Button buttonColor={ButtonColor.Light} onClick={this.store.togglePairingModal}>
                        {translate('Cancel')}
                      </Button>
                      <Button
                        iconLeft
                        disabled={this.store.selectedCompetitorProductsInModalRowsCount === 0}
                        onClick={this.store.pairSelected}
                      >
                        <Icon iconType={IconType.pair} />
                        {translate('Pair selected')}
                      </Button>
                    </FormGroupInline>
                  </Modal>
                </Grid>
              </BoxTabContainer>
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

export default DataPairingUnpairedPage;
