import { BigDecimal, bigdecimal } from '@logio/big-decimal';
import { PriceFormulas } from '@logio/common-be-fe';
import { CONSTANTS } from '../../../../../constants';

var localizeNumberValue = function localizeNumberValue(v, local) {
  return v.toNumber().toLocaleString(local || CONSTANTS.FORMAT.NUMBER);
};

var roundNumberValue = function roundNumberValue(v, round) {
  return round ? PriceFormulas.roundDefault(v) : v;
};

var formatValue = function formatValue(value, colDef) {
  if (typeof value === 'number') {
    return localizeNumberValue(roundNumberValue(bigdecimal(value), colDef === null || colDef === void 0 ? void 0 : colDef.round), colDef === null || colDef === void 0 ? void 0 : colDef.local);
  } else if (value instanceof BigDecimal) {
    return localizeNumberValue(roundNumberValue(value, colDef === null || colDef === void 0 ? void 0 : colDef.round), colDef === null || colDef === void 0 ? void 0 : colDef.local);
  } else {
    return value.value ? value.value.toString() : value.toString();
  }
};
/**
 * This function is used as default `valueFormatter` of grid field. It must return only string without HTML.
 * For legacy reasons it can be also used as React component with type `RendererNames.DefaultRenderer`.
 *
 * @see https://www.ag-grid.com/javascript-grid-value-formatters/
 */


var defaultRenderer = function defaultRenderer(props) {
  if ('valueFormatted' in props && props.valueFormatted != null) {
    return formatValue(props.valueFormatted, props.colDef);
  } else if (props.value !== null && props.value !== undefined) {
    return formatValue(props.value, props.colDef);
  } else {
    return null;
  }
};

export default defaultRenderer;