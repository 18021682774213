"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var immutable_1 = require("immutable");

var Role_1 = require("./Role");
/**
 * Well-known pricing roles that have specific usage in application business logic.
 *
 * @author Radek Beran
 */


var PricingRoles = function PricingRoles() {
  _classCallCheck(this, PricingRoles);
};

exports.PricingRoles = PricingRoles; // TODO RBe: Review role names after really final LDAP sync in KeyCloak
// and according to permission matrix defined in:
// https://docs.google.com/spreadsheets/d/1jhTv0nto5Esf7Krqf0CELxzV_L6HB_iq_kqU9FMno4M/edit#gid=551633943

/** Name in LDAP: app_logio_PRICING_Admin */

PricingRoles.ADMIN = new Role_1.Role('PRICING_Admin'); // Admin

/** Name in LDAP: app_logio_PRICING_CategoryManager */

PricingRoles.CATEGORY_MANAGER = new Role_1.Role('PRICING_CategoryManager'); // CM

/** Name in LDAP: app_logio_PRICING_SeniorCategoryManager */

PricingRoles.SENIOR_CATEGORY_MANAGER = new Role_1.Role('PRICING_SeniorCategoryManager'); // SCM

/** Name in LDAP: app_logio_PRICING_PriceManager */

PricingRoles.PRICE_MANAGER = new Role_1.Role('PRICING_PriceManager'); // PM

/** Name in LDAP: app_logio_PRICING_CommercialDirector */

PricingRoles.COMMERCIAL_DIRECTOR = new Role_1.Role('PRICING_CommercialDirector'); // CD

/** Name in LDAP: app_logio_PRICING_TradeDirector */

PricingRoles.TRADE_DIRECTOR = new Role_1.Role('PRICING_TradeDirector'); // TD

/** Name in LDAP: app_logio_PRICING_Controlling */

PricingRoles.CONTROLLING = new Role_1.Role('PRICING_Controlling');
/** Name in LDAP: app_logio_PRICING_Auditor */

PricingRoles.AUDITOR = new Role_1.Role('PRICING_Auditor');
/** Name in LDAP: app_logio_PRICING_SelloutManager */

PricingRoles.SELLOUT_MANAGER = new Role_1.Role('PRICING_SelloutManager');
/** Name in LDAP: app_logio_PRICING_RepleManager */

PricingRoles.REPLE_MANAGER = new Role_1.Role('PRICING_RepleManager');
/** Name in LDAP: app_logio_PRICING_Guest */

PricingRoles.GUEST = new Role_1.Role('PRICING_Guest');
/** Name in LDAP: app_logio_PRICING_Assistant */

PricingRoles.ASSISTANT = new Role_1.Role('PRICING_Assistant');
/** Name in LDAP: app_logio_PRICING_MasterData */

PricingRoles.MASTER_DATA = new Role_1.Role('PRICING_MasterData');
PricingRoles.PRICING_SYSTEM_ADMIN = new Role_1.Role('PRICING_SystemAdmin'); // TODO RBe: Maybe HELPDESK should be added (KZi will investigate this)
// Register new roles here!

PricingRoles.ALL_ROLES = immutable_1.Set.of(PricingRoles.ADMIN, PricingRoles.CATEGORY_MANAGER, PricingRoles.SENIOR_CATEGORY_MANAGER, PricingRoles.PRICE_MANAGER, PricingRoles.COMMERCIAL_DIRECTOR, PricingRoles.TRADE_DIRECTOR, PricingRoles.CONTROLLING, PricingRoles.AUDITOR, PricingRoles.SELLOUT_MANAGER, PricingRoles.REPLE_MANAGER, PricingRoles.GUEST, PricingRoles.ASSISTANT, PricingRoles.MASTER_DATA, PricingRoles.PRICING_SYSTEM_ADMIN);