"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Determines axis for segmentation model - sales lift.
 *
 * @author ZSr
 */

var SalesLiftSegmentationEnum;

(function (SalesLiftSegmentationEnum) {
  SalesLiftSegmentationEnum["HIGH"] = "HIGH";
  SalesLiftSegmentationEnum["OK"] = "OK";
  SalesLiftSegmentationEnum["LOW"] = "LOW";
})(SalesLiftSegmentationEnum = exports.SalesLiftSegmentationEnum || (exports.SalesLiftSegmentationEnum = {}));