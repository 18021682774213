import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  position: relative;\n  min-height: ", "px;\n  line-height: 15px;\n  padding: ", ";\n  margin-bottom: ", "rem;\n  border: 1px solid ", ";\n  color: ", ";\n  background-color: ", ";\n  border-radius: ", ";\n  text-transform: uppercase;\n  text-align: center;\n  font-size: 80%;\n  user-select: none;\n\n  svg {\n    position: absolute;\n    right: 7px;\n    top: 5px;\n    fill: ", ";\n    cursor: pointer;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
import styled, { withTheme } from 'styled-components';
/**
 * Create an Tag component that'll render an <Tag> on the top of input
 */

var TagStyled = styled.span(_templateObject(), function (props) {
  return props.theme.input.tinyHeight;
}, function (props) {
  return props.theme.tag.padding;
}, function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.colors.link;
}, function (props) {
  return props.theme.colors.paper;
}, function (props) {
  return props.theme.common.borderRadius;
}, function (props) {
  return props.theme.colors.error;
});
export default withTheme(TagStyled);