/**
 * @file Created on Tue Sep 18 2018
 * @author LZe
 */

import {observer} from 'mobx-react';
import * as React from 'react';

import {Alert, AlertType, Icon, IconType, translate} from '@logio/common-fe';
import {UploadNotification, UploadNotificationSeverityEnum} from '@logio/common-be-fe';

import {Collection, List} from 'immutable';

interface DataUploadResultProps {
  uploadedData: {
    importedRowsCount: number | null,
    notifications: List<UploadNotification>,
  }
}

/**
 * Renders result info boxes of the data upload
 */
const DataUploadResult: React.SFC<DataUploadResultProps> = observer(props => {
  const { notifications, importedRowsCount} = props.uploadedData;
  const warnings = notifications.filter(n => n.severity === UploadNotificationSeverityEnum.WARNING);
  const errors = notifications.filter(n => n.severity === UploadNotificationSeverityEnum.ERROR);

  return (
    <>
      {importedRowsCount !== null && importedRowsCount > 0/* && errors.size === 0*/ && (
        <Alert type={AlertType.Info} className="mb2">
          <div className="mb1">
            <Icon iconType={IconType.approve} className="mr1" /> <strong>{importedRowsCount}</strong>
            &nbsp;
            {translate('data-upload-success')}
          </div>
        </Alert>
      )}
      <DataUploadNotificationsAlert type={AlertType.Error} notifications={errors} />
      <DataUploadNotificationsAlert type={AlertType.Warning} notifications={warnings} />
    </>
  );
});

interface DataUploadNotificationsAlertProps {
  type: AlertType,
  notifications: List<UploadNotification>,
}


const DataUploadNotificationsAlert: React.SFC<DataUploadNotificationsAlertProps> = observer(props => {
  if (props.notifications.size === 0) {
    return null;
  }

  const general = props.notifications.filter(n => n.lineNumber == null);
  const relatedToLines = props.notifications.filter(n => n.lineNumber != null);
  const grouped = relatedToLines.groupBy(n => translate(`priceUpload-error-${n.text}`, ...n.params));

  return (
    <Alert type={props.type} className="mb2">
      <ul>
        {general.map((notification, i) => (
          <li key={i}>{translate(`priceUpload-error-${notification.text}`, ...(notification.params || []))}</li>
        ))}
        {relatedToLines.size > 0 ? (
          <li>
            {translate('priceUpload-errorCount', relatedToLines.size.toString(10))}
            <ul>
              {grouped.map((notifications, errorText) => {
                const ln = lineNumbers(notifications);
                return (
                  <li key={ln}>
                    {translate('priceUpload-errorLines')} {ln} - {errorText}.
                  </li>
                );
              }).toList()}
            </ul>
          </li>
        ) : null}
      </ul>
    </Alert>
  );
});

function lineNumbers(notifications: Collection<number, UploadNotification>) {
  return notifications.map(n => n.lineNumber).join(',');
}

export default DataUploadResult;
