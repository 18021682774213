import _defineProperty from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _Filters;

/**
 * @file Created on Wed Aug 15 2018
 * @author VBr
 */
import DateFilter from './DateFilter';
import RangeFilter from './RangeFilter';
import TextFilter from './TextFilter';
import RangeFloatingFilter from './RangeFloatingFilter';
import DateFloatingFilter from './DateFloatingFilter';
import TextFloatingFilter from './TextFloatingFilter';
import FinalPriceFilter from './FinalPriceFilter';
import PriceChangeIndexFilter from './PriceChangeIndexFilter';
import ReleaseAlertsFilter from './ReleaseAlertsFilter';
import ReleaseCommentsFilter from './ReleaseCommentsFilter';
import ReleasePriceRangeFilter from './ReleasePriceRangeFilter';
import CustomBooleanFilter from './CustomBooleanFilter';
import ReferencePriceFilter from './ReferencePriceFilter';
import PromoPriceStatsFilter from './PromoPriceStatsFilter';
export var FilterNames;

(function (FilterNames) {
  FilterNames["RangeFilter"] = "RANGE_FILTER";
  FilterNames["RangeFloatingFilter"] = "RANGE_FLOATING_FILTER";
  FilterNames["DateFilter"] = "DATE_FILTER";
  FilterNames["DateFloatingFilter"] = "DATE_FLOATING_FILTER";
  FilterNames["TextFloatingFilter"] = "TEXT_FLOATING_FILTER";
  FilterNames["TextFilter"] = "TEXT_FILTER";
  FilterNames["FinalPriceFilter"] = "FINAL_PRICE_FILTER";
  FilterNames["PriceChangeIndexFilter"] = "PRICE_CHANGE_INDEX_FILTER";
  FilterNames["ReleaseAlertsFilter"] = "RELEASE_ALERTS_FILTER";
  FilterNames["ReleaseCommentsFilter"] = "RELEASE_COMMENTS_FILTER";
  FilterNames["ReleasePriceRangeFilter"] = "RELEASE_PRICE_RANGE_FILTER";
  FilterNames["CustomBooleanFilter"] = "CUSTOM_BOOLEAN_FILTER";
  FilterNames["ReferencePriceFilter"] = "REFERENCE_PRICE_FILTER";
  FilterNames["PromoPriceStatsFilter"] = "PROMO_PRICE_STATS_FILTER";
})(FilterNames || (FilterNames = {}));

var Filters = (_Filters = {}, _defineProperty(_Filters, FilterNames.RangeFilter, RangeFilter), _defineProperty(_Filters, FilterNames.RangeFloatingFilter, RangeFloatingFilter), _defineProperty(_Filters, FilterNames.DateFilter, DateFilter), _defineProperty(_Filters, FilterNames.DateFloatingFilter, DateFloatingFilter), _defineProperty(_Filters, FilterNames.TextFloatingFilter, TextFloatingFilter), _defineProperty(_Filters, FilterNames.TextFilter, TextFilter), _defineProperty(_Filters, FilterNames.FinalPriceFilter, FinalPriceFilter), _defineProperty(_Filters, FilterNames.PriceChangeIndexFilter, PriceChangeIndexFilter), _defineProperty(_Filters, FilterNames.ReleaseAlertsFilter, ReleaseAlertsFilter), _defineProperty(_Filters, FilterNames.ReleaseCommentsFilter, ReleaseCommentsFilter), _defineProperty(_Filters, FilterNames.ReleasePriceRangeFilter, ReleasePriceRangeFilter), _defineProperty(_Filters, FilterNames.CustomBooleanFilter, CustomBooleanFilter), _defineProperty(_Filters, FilterNames.ReferencePriceFilter, ReferencePriceFilter), _defineProperty(_Filters, FilterNames.PromoPriceStatsFilter, PromoPriceStatsFilter), _Filters);
export default Filters;