/**
 * @file Created on Thu Aug 09 2018
 * @author VBr
 */
var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import * as React from 'react';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import StyledTabs from './styled/StyledTabs';
export var TabNavigation = function TabNavigation(_a) {
  var fullWidth = _a.fullWidth,
      props = __rest(_a, ["fullWidth"]);

  return React.createElement(StyledTabs, {
    fullWidth: fullWidth
  }, React.createElement(Tabs, Object.assign({}, props), React.createElement(TabList, null, props.content.map(function (tab) {
    return React.createElement(Tab, {
      key: tab.tabName
    }, tab.tabName);
  })), props.content.map(function (tab) {
    return React.createElement(TabPanel, {
      key: tab.tabName
    }, tab.tabContent);
  })));
};