/**
 * @file Created on Tue Mar 15 2020
 * @author MHl
 */

import * as React from 'react';
import {
  AgGrid,
  AgGridWrapper,
  Page,
  FormGroupInline,
  Button,
  getPath,
  translate,
  ConfirmBox,
  BoxStatus,
  Circle,
  CircleWidth,
  ButtonColor,
  Divider,
  DividerType,
} from '@logio/common-fe';
import {Observer, observer} from 'mobx-react';
import {
  TerminateRegularSiteDetailPageStore,
  IRouteParams,
} from '../../../stores/pages/Terminations/RegularSite/TerminateRegularSiteDetailPageStore';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {RouteComponentProps} from 'react-router-dom';

/**
 * End Regular - site detail page
 */

@observer
class TerminateRegularSiteDetailPage extends React.Component<RouteComponentProps<IRouteParams>> {
  store = new TerminateRegularSiteDetailPageStore(
    this.props.history,
    this.props.match,
    this.props.match.params.terminationId,
  );

  componentWillMount() {
    this.store.load();
  }
  render() {
    return (
      <Observer>
        {() => (
          <Page
            titleKey={this.store.termination && this.store.termination.name}
            loadingState={this.store.loadingState}
            backButtonPath={getPath(PagePathsEnum.TerminateRegularSite)}
          >
            {this.store.termination && this.renderHeader()}

            <AgGridWrapper>
              <AgGrid
                getRowNodeId={(data) => data.SiteReleaseTerminationItem_id}
                rowData={this.store.terminationItemsRowData}
                columnDefs={this.store.terminationItemsColumnDefinitions}
                gridId="RegularSiteTerminationItems"
                quickView
                onGridReady={this.store.onGridReady}
                showBulkRow={true}
                pinnedTopRowData={[]}
                sizeColumnsToFit
                rowClassRules={this.store.rowClassRulesReadOnly}
              />
            </AgGridWrapper>

            <ConfirmBox
              hidden={this.store.isConfirmModalHidden}
              title={translate('terminate-regular-site')}
              onAccept={this.store.confirmTermination}
              close={this.store.hideConfirmModal}
              children={<div className="mb1">{`${translate('terminate-regular-site-confirmation')}`}</div>}
            />
            <ConfirmBox
              hidden={this.store.isCancellModalHidden}
              title={translate('cancell-site-termination')}
              onAccept={this.store.cancelTermination}
              close={this.store.hideCancellModal}
              children={<div className="mb1">{`${translate('cancell-site-termination-confirmation')}`}</div>}
            />
          </Page>
        )}
      </Observer>
    );
  }

  renderHeader(): JSX.Element {
    return (
      <BoxStatus>
        <div className="status-bar-item">
          <Circle color={translate(this.store.termination.state)} width={CircleWidth.Big} />
          <span className="ml1 status-bar-value">{translate(this.store.termination.state)}</span>
        </div>
        <Divider type={DividerType.StatusVertical} />
        <div className="flex-grow-1">&nbsp;</div>
        <FormGroupInline>
          <Button
            type="button"
            buttonColor={ButtonColor.Light}
            onClick={this.store.showCancellModal}
            disabled={!this.store.enableHeaderButtons}
          >
            {translate('Cancel')}
          </Button>
          <Button
            type="button"
            buttonColor={ButtonColor.Secondary}
            onClick={this.store.showConfirmModal}
            disabled={!this.store.enableConfirmButton || !this.store.enableHeaderButtons}
          >
            {translate('action_Regular-site_Termination_confirm-button')}
          </Button>
        </FormGroupInline>
      </BoxStatus>
    );
  }
}

export default TerminateRegularSiteDetailPage;
