/**
 * @file Created on Fri Oct 05 2018
 * @author SKu
 */

import {AgGrid, FormGroup} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';
import {ReleaseDetailCategoryComponentStore} from '../../../stores/components';

export interface Props {
  store: ReleaseDetailCategoryComponentStore;
  /** Should be true if component used for export page */
  export?: boolean;
}

/**
 * Release bottom ag-grid
 */
const ReleaseDetailCategoryItemOverview: React.SFC<Props> = observer((props: Props) => {
  /**
   * Because every time, table columns are different,
   * we should generate Ids depending on current included priceZOnes
   */
  const gridId = ['ReleaseDetailCategoryGridID']
    .concat(Array.from(props.store.release.priceZoneIds.values()).sort()) // LOG-5479
    .join('_');

  return (
    <FormGroup>
      <AgGrid
        rowData={props.store.itemOverviewRowData}
        columnDefs={props.store.itemOverviewColumnDefs}
        gridId={gridId}
        deltaRowDataMode
        onFilterChanged={props.store.onFilterChanged}
        onGridReady={props.store.onItemOverviewGridReady}
        enterMovesDownAfterEdit
        quickView
        getRowNodeId={({ReleaseDetailCategory_id: id}) => id}
        rowClassRules={{
          'bg-error': props.store.isForecastObsolete || props.store.isReleaseItemStartDateBeforeTomorrow,
          'bg-warning': props.store.isChangedDueToRecalculate,
        }}
      />
    </FormGroup>
  );
});

export default ReleaseDetailCategoryItemOverview;
