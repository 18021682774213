import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  /* \n  FIXME: PKl: LOG-855 - AgGridWrapper - 100% height - rollback\n\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n\n  .ag-theme-balham {\n    background-color: transparent;\n    flex: 1;\n  } */\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Thu Oct 11 2018
 * @author PKl
 */
import styled, { withTheme } from 'styled-components';
/**
 * Create an div wrapper component for AgGrid and filters block
 */

var AgGridWrapper = styled.div(_templateObject());
export default withTheme(AgGridWrapper);