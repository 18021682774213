/**
 * @file Created on Mon Oct 08 2018
 * @author BPo
 */
import {CheckBoxGroup, FormGroupInline, FormGroupInlineProps, translate, CheckBoxGroupProps} from '@logio/common-fe';
import * as React from 'react';

interface OutlierFilterProps {
  className?: string;
}

/**
 * Renders outliers filter
 */
export const OutlierFilter: React.SFC<
  OutlierFilterProps & FormGroupInlineProps & Pick<CheckBoxGroupProps, 'values' | 'onChange' | 'options' | 'scale'>
> = ({className, right, values, onChange, options, scale}) => {
  return (
    <div className="form-horizontal">
      <FormGroupInline right={right} className={className}>
        <CheckBoxGroup
          name="outliers-filter"
          values={values}
          onChange={onChange}
          groupLabel={translate('filters-outliers')}
          scale={scale}
          options={options}
        />
      </FormGroupInline>
    </div>
  );
};
