/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
// This file is just for the styleguidist documentation.
import FormGroupInlineStyled from './styled/FormGroupInlineStyled';
import * as React from 'react';

var FormGroupInline = function FormGroupInline(props) {
  return React.createElement(FormGroupInlineStyled, Object.assign({}, props));
};

export default FormGroupInline;