/**
 * @file Created on Fri Feb 01 2019
 * @author SKu
 */

import { DownloadStore, LoadingState, rootStore, StoreName, translate } from '@logio/common-fe';
import { action, observable, runInAction } from 'mobx';
import { AbstractRelatedReportsPageStore } from './AbstractRelatedReportsPageStore';

export class PriceIndexReportPageStore extends AbstractRelatedReportsPageStore<never> {
  /** Layer/Stores */
  public downloadStore = rootStore.getStore(StoreName.Download) as DownloadStore;

  /** Used for loading button state for downloading price index report file */
  @observable
  isReportDownloading: boolean = false; // LOG-5302

  /** @inheritDoc */
  public onFilter = async (filterValues: never) => {
    // no op - there is no filter
  };

  /** @inheritDoc */
  @action
  downloadReportFile = () => {
    runInAction(() => {
      this.isReportDownloading = true;
    });
    this.downloadStore.download(translate("PriceIndexReport_download_fileId")).then(() => {
      runInAction(() => {
        this.isReportDownloading = false;
      });
    });
  };

  /** @inheritDoc */
  protected updatePageData = async (): Promise<void> => {
    // no op - there is no data needed
  };

  /** @inheritDoc */
  public load = async (initialValues?: never) => {
    // no need to load anything
    this.setLoadingState(LoadingState.Success);
  };
}
