/**
 * Circle can be rendered in these width types
 */
export var CircleWidth;

(function (CircleWidth) {
  /** width 10px */
  CircleWidth["Tiny"] = "TINY";
  /** width 15px */

  CircleWidth["Normal"] = "NORMAL";
  /** width 20px; */

  CircleWidth["Big"] = "BIG";
})(CircleWidth || (CircleWidth = {}));