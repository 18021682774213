"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var FinalProductPriceMessageCode;

(function (FinalProductPriceMessageCode) {
  /**
   * The final price is under the purchase price.
   */
  FinalProductPriceMessageCode["FinalPriceBelowPurchase"] = "FinalPriceBelowPurchase";
  /**
   * The final price is under the purchase price (sellouts).
   */

  FinalProductPriceMessageCode["SelloutFinalPriceBelowPurchase"] = "SelloutFinalPriceBelowPurchase";
  /**
   * The final price validity ends after product expiration date. Relevant for endangered expiry sellout releases.
   */

  FinalProductPriceMessageCode["PriceValidityEndAfterExpirationDate"] = "PriceValidityEndAfterExpirationDate";
  /**
   * According to forecast, a sellout of a product will take more time then specified by price validity end.
   */

  FinalProductPriceMessageCode["PriceValidityEndNotMetByForecast"] = "PriceValidityEndNotMetByForecast";
  /**
   * New final price does not meet the restriction for a minimal discount. Relevant for delist sellout releases.
   */

  FinalProductPriceMessageCode["DiscountTooLow"] = "DiscountTooLow";
  /**
   * Final price is being changed for the second time (or more) in one re-pricing interval. Relevant for delist sellout releases.
   */

  FinalProductPriceMessageCode["MultipleRepricings"] = "MultipleRepricings";
  /**
   * Final price is lower than the previous promo price. Relevant for overstock sellouts.
   */

  FinalProductPriceMessageCode["FinalPriceLowerThanPreviousPromoPrice"] = "FinalPriceLowerThanPreviousPromoPrice";
  /**
   * Final price felt bellow regular price and this decrease exceeded min. % price change (LOG-3370).
   */

  FinalProductPriceMessageCode["FinalPriceViolatesMinPriceChange"] = "FinalPriceViolatesMinPriceChange";
  /**
   * Final price change difference from RP is bigger than allowed.
   */

  FinalProductPriceMessageCode["FinalPriceViolatesMaxPriceChange"] = "FinalPriceViolatesMaxPriceChange";
  /**
   * New margin value is below min margin, (LOG-5449)
   */

  FinalProductPriceMessageCode["FinalPriceViolatesMinMarginChange"] = "FinalPriceViolatesMinMarginChange";
  /**
   * Final price value has been changed due to recalculation (PROFACT-75).
   * <br/>
   * Localized msg should also display the old value of final price.
   */

  FinalProductPriceMessageCode["FinalPriceValueChangedDueToRecalculation"] = "FinalPriceValueChangedDueToRecalculation";
  /**
   * There is a new VAT. The recalculation is required.
   */

  FinalProductPriceMessageCode["VATChanged"] = "VATChanged";
  /**
   * Newly computed sellout price differs from manually entered final price (LOG-3664).
   * This alert is evaluated within one category recalculation.
   */

  FinalProductPriceMessageCode["NewSelloutPrice"] = "NewSelloutPrice";
  /**
   * The price sensitivity was changed.
   */

  FinalProductPriceMessageCode["PriceSensitivityChanged"] = "PriceSensitivityChanged";
  /**
   * Purchase price has changed.
   */

  FinalProductPriceMessageCode["PurchasePriceChanged"] = "PurchasePriceChanged";
  /**
   * A promo was detected in near future after a sellout release. The price and validity were set accordingly and cannot be reset.
   */

  FinalProductPriceMessageCode["FixPriceBecauseOfFuturePromoDetected"] = "FixPriceBecauseOfFuturePromoDetected";
  /**
   * Final price is lower than the previous promo price. Relevant for overstock sellouts.
   */

  FinalProductPriceMessageCode["FixPriceBecauseFinalPriceLowerThanPreviousPromoPrice"] = "FixPriceBecauseOfFinalPriceLowerThanPreviousPromoPrice";
})(FinalProductPriceMessageCode = exports.FinalProductPriceMessageCode || (exports.FinalProductPriceMessageCode = {}));