/**
 * Icon can be rendered in these colors
 */
export var IconColor;

(function (IconColor) {
  IconColor["Text"] = "TEXT";
  IconColor["Primary"] = "PRIMARY";
  IconColor["Secondary"] = "SECONDARY";
  IconColor["Light"] = "LIGHT";
  IconColor["Inverse"] = "INVERSE";
  IconColor["Error"] = "ERROR";
  IconColor["Green"] = "GREEN";
  IconColor["Red"] = "RED";
})(IconColor || (IconColor = {}));