import { Utils } from "@logio/common-be-fe";
import generalComparator from "./generalComparator";
/**
 * @file Created on September 2019
 * @author Atreo Digital s.r.o.
 * @description Custom comparator for fields rendered by ArrowRenderer
 */

var releaseCRPIndexComparator = function releaseCRPIndexComparator(valA, valB) {
  return generalComparator(toComparableValue(valA), toComparableValue(valB));
};

var toComparableValue = function toComparableValue(val) {
  if (!Utils.isValueMissing(val) && "value" in val) {
    if ("toNumber" in val.value && typeof val.value.toNumber === "function") {
      if ("toNumber" in val.value && typeof val.value.toNumber === "function") {
        return val.value.toNumber();
      }

      if (!("toNumber" in val.value)) {
        return val.value;
      }
    }
  }

  return null;
};

export default releaseCRPIndexComparator;