import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  left: -10000px;\n\n  & + label {\n    display: inline-block;\n    height: ", "px;\n    line-height: ", "px;\n    padding: 0 30px;\n    border: 1px solid ", ";\n    color: ", ";\n    background-color: ", ";\n    border-radius: ", ";\n    text-transform: uppercase;\n    text-align: center;\n    font-size: 80%;\n    cursor: pointer;\n    user-select: none;\n    transition: all ease-in-out .3s;\n    white-space: nowrap;\n\n    svg {\n      position: absolute;\n      top: 8px;\n      left: 21px;\n      max-width: 17px;\n    }\n  }\n\n  & + label div {\n    font-size: 70%;\n    color: ", ";\n    line-height: 15px;\n    margin-top: -10px;\n    padding-bottom: 5px;\n  }\n\n  &:focus + label {\n    background-color: ", ";\n    color: ", ";\n  }\n\n  &:checked + label {\n    background-color: ", ";\n    color: ", ";\n    svg {\n      fill: ", ";\n    }\n    div {\n      color: ", ";\n    }\n  }\n\n  /* &:focus:checked + label {\n    background-color: ", ";\n  } */\n\n  ", ";\n\n  &:disabled + label,\n  &[readonly] + label {\n    cursor: inherit;\n    background-color: ", ";\n    color: ", ";\n  }\n\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  & + label {\n    height: ", "px;\n    line-height: ", "px;\n    padding: 0 30px;\n\n    svg {\n      top: 6px;\n      left: 23px;\n      width: 17px;\n      max-height: 13px;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
import styled, { withTheme, css } from 'styled-components';
var ButtonTiny = css(_templateObject(), function (props) {
  return props.theme.input.tinyHeight;
}, function (props) {
  return props.theme.input.tinyHeight;
});
/**
 * Create an CheckBoxButton component
 */

var StyledCheckBoxButton = styled.input(_templateObject2(), function (props) {
  return props.theme.input.height;
}, function (props) {
  return props.theme.input.height;
}, function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.colors.link;
}, function (props) {
  return props.theme.colors.paper;
}, function (props) {
  return props.theme.common.borderRadius;
}, function (props) {
  return props.theme.colors.text;
}, function (props) {
  return props.theme.colors.focus;
}, function (props) {
  return props.theme.colors.text;
}, function (props) {
  return props.theme.colors.selected;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return props.theme.colors.selected;
}, function (props) {
  return props.tinyButton ? ButtonTiny : null;
}, function (props) {
  return props.theme.colors.muted;
}, function (props) {
  return props.theme.colors.inverse;
});
export default withTheme(StyledCheckBoxButton);