/**
 * @file Dashboard page
 */

import {PricingPermissions} from '@logio/common-be-fe';
import {BoxDefault, Page, translate, withPermission} from '@logio/common-fe';
import {Observer, observer} from 'mobx-react';
import * as React from 'react';
import {Col, Grid, Row} from 'react-flexbox-grid';
import {RouteComponentProps, withRouter} from 'react-router';
import SelloutIdentification from '../../components/Dashboard/SelloutIdentification';
import {PageNamesEnum} from '../../shared/localization/PageNamesEnum';
import {DashboardPageStore, DashboardWidgetsEnum} from '../../stores/pages/Dashboard/DashboardPageStore';
import {ReleaseWidget} from '../../components/Dashboard/ReleaseWidget';
import {TaskWidget} from '../../components/Dashboard/TaskWidget';

@observer
class DashboardPage extends React.Component<RouteComponentProps<DashboardPage>> {
  store = new DashboardPageStore(this.allowedWidgets, this.props.history);

  componentWillMount() {
    this.store.load();
  }

  get allowedWidgets() {
    const allowedWidgets: DashboardWidgetsEnum[] = [];
    /** My Tasks dashboard widget */
    if (withPermission([PricingPermissions.DASHBOARD_MY_TASKS])) {
      allowedWidgets.push(DashboardWidgetsEnum.Task);
    }
    /** Release dashboard widget */
    if (
      withPermission([PricingPermissions.DASHBOARD_RELEASES]) &&
      withPermission([PricingPermissions.REGULAR_RELEASE_DETAIL, PricingPermissions.SELLOUT_RELEASE_DETAIL], true)
    ) {
      allowedWidgets.push(DashboardWidgetsEnum.Release);
    }
    /** Sellout identification widget */
    if (withPermission([PricingPermissions.SELLOUT_IDENTIFICATION_REPORT])) {
      allowedWidgets.push(DashboardWidgetsEnum.SelloutIdentification);
    }
    return allowedWidgets;
  }

  /** TODO: comments */
  getWidget(widgetType: DashboardWidgetsEnum) {
    let widget: JSX.Element;
    switch (widgetType) {
      case DashboardWidgetsEnum.Task:
        widget = <TaskWidget store={this.store.taskWidgetStore} />;
        break;
      case DashboardWidgetsEnum.Release:
        widget = <ReleaseWidget store={this.store.releaseWidgetStore} />;
        break;
      case DashboardWidgetsEnum.SelloutIdentification:
        widget = (
          <>
            <h3>{translate(PageNamesEnum.SelloutIdentificationReport)}</h3>
            <SelloutIdentification store={this.store.selloutIdentificationStore} />
          </>
        );
        break;
      case DashboardWidgetsEnum.PriceStickers:
        throw new Error('Not implemented');
    }
    return <BoxDefault boxDashboard>{widget}</BoxDefault>;
  }

  /**
   * Function returns Row with dashboard widgets inside
   * if second params not passed, left widget will be full-sized
   * @param leftWidgetType
   * @param rightWidgetType
   */
  getRow(leftWidgetType: DashboardWidgetsEnum, rightWidgetType?: DashboardWidgetsEnum) {
    return (
      <Row>
        <Col xs={rightWidgetType ? 6 : 12}>{this.getWidget(leftWidgetType)}</Col>
        {rightWidgetType && <Col xs={6}>{this.getWidget(rightWidgetType)}</Col>}
      </Row>
    );
  }

  render() {
    return (
      <Page titleKey={PageNamesEnum.Dashboard} loadingState={this.store.loadingState}>
        <Observer>
          {() => (
            <Grid fluid className="container-full-width">
              {this.getRow(
                withPermission([PricingPermissions.DASHBOARD_MY_TASKS]) && DashboardWidgetsEnum.Task,
                withPermission([PricingPermissions.DASHBOARD_RELEASES]) &&
                  withPermission(
                    [PricingPermissions.REGULAR_RELEASE_DETAIL, PricingPermissions.SELLOUT_RELEASE_DETAIL],
                    true,
                  ) &&
                  DashboardWidgetsEnum.Release,
              )}
              {withPermission([PricingPermissions.SELLOUT_IDENTIFICATION_REPORT]) &&
                this.getRow(DashboardWidgetsEnum.SelloutIdentification)}
            </Grid>
          )}
        </Observer>
      </Page>
    );
  }
}

export default withRouter(DashboardPage);
