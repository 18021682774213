/**
 * @file Created on Thu Sep 06 2018
 * @author LZe
 */
import * as React from 'react';
import Icon from '../../../../media/Icon';
import { IconType } from '../../../../media/Icon/IconType';
export var InheritanceRenderer = function InheritanceRenderer(props) {
  var height = 10;

  switch (props.value) {
    case true:
      return React.createElement(Icon, {
        iconType: IconType.arrowCorner,
        iconHeight: height
      });

    case false:
      return React.createElement(Icon, {
        iconType: IconType.arrowDouble,
        iconHeight: height
      });

    case null:
      return React.createElement(Icon, {
        iconType: IconType.cancel,
        iconHeight: height
      });

    default:
      return React.createElement("span", null);
  }
};