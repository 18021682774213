import { CommonReleaseOverview, ReleaseType, StructPropBuilder } from '@logio/common-be-fe';
import { ColumnDefinition, ColumnGenerator, translate } from '@logio/common-fe';
import { computed } from 'mobx';

export class ReopenInfoColumnGenerator {
  /**
   * Contains functions to obtain column definitions and row data for fields containing desired
   * impact data from previous reopens. This can be used in any agGrid.
   * 
   * @param store Store/object with `reopenCount` field. If it can change, it must be observable, so ColumnGenerator
   *              is correctly recomputed.
   */
  constructor(private readonly store: { reopenCount: number }, private withParams = false) {}

  @computed
  private get reopenColumnGenerator() {
    const description = {};
    const builder = new StructPropBuilder();

    for (let i = 0; i < this.store.reopenCount; i++) {
      const priceValidityStartDateKey = `priceValidityStartDate_${i}`;
      description[priceValidityStartDateKey] = builder.opt(builder.date(priceValidityStartDateKey));

      if (this.withParams) {
        const minPriceChangeInPercentKey = `minPriceChangeInPercent_${i}`;
        const maxPriceChangeInPercentKey = `maxPriceChangeInPercent_${i}`;
        const minMarginChangeInPercentKey = `minMarginChangeInPercent_${i}`;

        description[minPriceChangeInPercentKey] = builder.opt(builder.bigNum(minPriceChangeInPercentKey));
        description[maxPriceChangeInPercentKey] = builder.opt(builder.bigNum(maxPriceChangeInPercentKey));
        description[minMarginChangeInPercentKey] = builder.opt(builder.bigNum(minMarginChangeInPercentKey));
      }
    }

    const priceValidityEndDateKey = `endDate_0`;
    description[priceValidityEndDateKey] = builder.opt(builder.date(priceValidityEndDateKey));

    return new ColumnGenerator(description);
  }

  /**
   * Gets Ag Grid column definitions for previous impact records.
   */
  public getColumnDefinitions() {
    const definitions: ColumnDefinition[] = [];

    for (let i = 0; i < this.store.reopenCount; i++) {
      const priceValidityStartDateKey = `priceValidityStartDate_${i}`;
      definitions.push({
        field: priceValidityStartDateKey,
        headerName: i === 0 ? 
          translate(`ReleaseList_priceValidityStartDate_initial`) : 
          translate('ReleaseList_priceValidityStartDate_reopen', i.toString(10)),
      });

      if (this.withParams) {
        const minPriceChangeInPercentKey = `minPriceChangeInPercent_${i}`;
        const maxPriceChangeInPercentKey = `maxPriceChangeInPercent_${i}`;
        const minMarginChangeInPercentKey = `minMarginChangeInPercent_${i}`;

        definitions.push({
          field: minPriceChangeInPercentKey,
          headerName: i === 0 ? 
            translate(`ReleaseList_minPriceChangeInPercent_initial`) : 
            translate('ReleaseList_minPriceChangeInPercent_reopen', i.toString(10)),
        });

        definitions.push({
          field: maxPriceChangeInPercentKey,
          headerName: i === 0 ? 
            translate(`ReleaseList_maxPriceChangeInPercent_initial`) : 
            translate('ReleaseList_maxPriceChangeInPercent_reopen', i.toString(10)),
        });

        definitions.push({
          field: minMarginChangeInPercentKey,
          headerName: i === 0 ? 
            translate(`ReleaseList_minMarginChangeInPercent_initial`) : 
            translate('ReleaseList_minMarginChangeInPercent_reopen', i.toString(10)),
        });
      }

      if (i === 0) {
        const priceValidityEndDateKey = `endDate_0`;
        definitions.push({
          field: priceValidityEndDateKey,
          headerName: translate('ReleaseList_endDate_initial'),
        });
      }
    }

    return this.reopenColumnGenerator.getColumnDefinitions(definitions);
  }

  /**
   * Gets Ag Grid column data for previous impact records.
   * 
   * @param impacts
   */
  public getColumnData(overview: CommonReleaseOverview) {
    const data = {};
    if (overview.releaseType === ReleaseType.Sellout) {
      for (let i = 0; i < this.store.reopenCount; i++) {
        const previousOpen = overview.previousOpens.get(i);
        const priceValidityStartDateKey = `priceValidityStartDate_${i}`;
        data[priceValidityStartDateKey] = previousOpen ? previousOpen.priceValidityStartDate : (i === 0 ? overview.priceValidityStartDate : null);

        if (this.withParams) {
          const minPriceChangeInPercentKey = `minPriceChangeInPercent_${i}`;
          const maxPriceChangeInPercentKey = `maxPriceChangeInPercent_${i}`;
          const minMarginChangeInPercentKey = `minMarginChangeInPercent_${i}`;

          data[minPriceChangeInPercentKey] = previousOpen ? previousOpen.minPriceChangeInPercent : (i === 0 ? overview.minPriceChangeInPercent : null);
          data[maxPriceChangeInPercentKey] = previousOpen ? previousOpen.maxPriceChangeInPercent : (i === 0 ? overview.maxPriceChangeInPercent : null);
          data[minMarginChangeInPercentKey] = previousOpen ? previousOpen.minMarginChangeInPercent : (i === 0 ? overview.minMarginChangeInPercent : null);
        }
      }
  
      const firstOpen = overview.previousOpens.get(0);
      const priceValidityEndDateKey = `endDate_0`;
      data[priceValidityEndDateKey] = firstOpen ? firstOpen.endDate : overview.endDate;
    }

    return this.reopenColumnGenerator.getColumnData(data);
  }
}
