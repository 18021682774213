"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Enum of possible promotion split calculation options.
 */

var PromotionSplitOptionsEnum;

(function (PromotionSplitOptionsEnum) {
  PromotionSplitOptionsEnum["PromoOptApi"] = "PROMO_OPT";
  PromotionSplitOptionsEnum["Manual"] = "MANUAL";
})(PromotionSplitOptionsEnum = exports.PromotionSplitOptionsEnum || (exports.PromotionSplitOptionsEnum = {}));