/**
 * @file Created on Mon Jul 30 2019
 * @author SKu
 */

import {ProgressMonitorStatus} from '@logio/common-be-fe';
import {LoadingState, Polling, ProgressMonitorLayer, MessageStore, translate} from '@logio/common-fe';
import {observable, runInAction} from 'mobx';
import {AxiosError} from 'axios';

// FIXME: move to common-fe, could be used for promo
/** Class which helps to handle polling via {@link ProgressMonitor} */
export class PollingHelper {
  /**
   * @param messages {@link MessageStore} allows to push user messages
   * @param onPollingStateChanged - function that should return generated data for ag-grid row
   * @param interval - time between each poll
   */
  constructor(
    private messages: MessageStore,
    private onPollingStateChanged: (pollingState: LoadingState) => any,
    private interval?: number,
    private disableMessages?: boolean,
  ) {}

  /** Polling services */
  public polling = new Polling(this.interval);
  private progressMonitorLayer: ProgressMonitorLayer = new ProgressMonitorLayer();
  @observable
  public errorFallback?: string | AxiosError;

  @observable
  public pollingState: LoadingState = LoadingState.Success;

  /** Percentage of processed data */
  @observable
  public progress?: number;

  private fetchingStatus = false;

  private setPollingState = (pollingState: LoadingState) => {
    this.onPollingStateChanged(pollingState);
    runInAction(() => (this.pollingState = pollingState));
  };

  private onPollingEnd = () => {
    this.polling.stopPolling();
    runInAction(() => (this.progress = undefined));
  };

  private onPollingSuccess = (key: string) => {
    this.onPollingEnd();
    this.setPollingState(LoadingState.Success);
  };

  private onPollingError = (error?: AxiosError | string) => {
    this.onPollingEnd();
    this.setPollingState(LoadingState.Error);
    this.disableMessages && runInAction(() => (this.errorFallback = error));
    !this.disableMessages && this.messages.setError(error || 'ERR_polling-failed');
  };

  /** Function that passed to start polling function
   * @param progressMonitorId
   */
  private onPollingStarted = (progressMonitorId: string) => async () => {
    if (!this.fetchingStatus) {
      try {
        this.fetchingStatus = true;
        runInAction(() => (this.errorFallback = undefined));
        const response: ProgressMonitorStatus = await this.progressMonitorLayer.status(progressMonitorId);
        if (response) {
          this.setPollingState(LoadingState.Pending);
        }
        this.fetchingStatus = false;
        return response;
      } catch (error) {
        this.fetchingStatus = false;
        
        if (error && error.code === 'ECONNABORTED') {
          // don't stop polling on timeout error
          return null;
        }
        
        this.onPollingError(error);
      }
    } else {
      return null;
    }
  };

  /**
   * Function handle polling monitoring
   * @param errors
   * @param response
   */
  private onPollResponse = (successMSGKey: string) => (err?: any, response?: ProgressMonitorStatus) => {
    if (response) {
      switch (response.tag) {
        case 'new':
          runInAction(() => (this.progress = 0));
          break;
        case 'working':
          runInAction(() => (this.progress = Math.round((response.worked * 100) / response.total)));
          break;
        case 'done':
          this.onPollingSuccess(successMSGKey);
          break;
        case 'failed':
          this.onPollingError(err ? err : response.error.message);
          break;
        default:
          throw new Error('Polling response state not supported');
      }
    }
  };

  /** Call whenever you have progressMonitorId
   * @param progressMonitorId
   * @param successMSGKey - Will be added to 'INFO_polling-success' translations and set as SuccessMessage
   */
  public couldPollingBeStarted = async (progressMonitorId: string) => {
    if (progressMonitorId) {
      const response: ProgressMonitorStatus = await this.progressMonitorLayer.status(progressMonitorId);
      return response && (response.tag === 'new' || response.tag === 'working');
    }
    return false;
  };

  /** Call whenever you have progressMonitorId
   * @param progressMonitorId
   * @param successMSGKey - Will be added to 'INFO_polling-success' translations and set as SuccessMessage
   */
  public startPolling = (progressMonitorId: string, successMSGKey: string) => {
    if (progressMonitorId) {
      this.polling.startPolling(this.onPollingStarted(progressMonitorId), this.onPollResponse(successMSGKey));
    } else {
      this.messages.setError('progressMonitorId-is-missing');
    }
  };
}
