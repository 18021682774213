import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n\n  table {\n    border-collapse: collapse;\n  }\n  \n  td {\n    padding: ", "px;\n    height: ", "px;\n  }\n  \n  td:nth-child(1) {\n    font-weight: bold;\n    white-space: nowrap;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Mon Nov 05 2018
 * @author Tomáš Leity
 */
import styled, { withTheme } from 'styled-components';
var styledSummary = styled.div(_templateObject(), function (props) {
  return props.theme.table.summaryFontSize;
}, function (props) {
  return props.theme.table.summaryPadding;
}, function (props) {
  return props.theme.table.summaryRowHeight;
});
export default withTheme(styledSummary);