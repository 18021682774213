/**
 * @file Created on Fri Feb 01 2019
 * @author SKu
 */

import {
  InternalProductPricesStore,
  logger,
  PageStore,
  PriceZoneStore,
  ProductStore,
  ReportLayer,
  rootStore,
  StoreName,
  StringMapping,
} from '@logio/common-fe';
import { observable, runInAction } from 'mobx';
import { RelatedReportsFormFilter } from '../../../../pages/Reports/RelatedReports/RelatedReportsFilter';
import { IRelatedReportsProductInfo } from '../../../../pages/Reports/RelatedReports/RelatedReportsProductInfo';

export abstract class AbstractRelatedReportsPageStore<T extends RelatedReportsFormFilter> extends PageStore {
  /** Injecting stores/layers */
  protected reportLayer = new ReportLayer();
  protected productStore = rootStore.getStore(StoreName.Product) as ProductStore;
  public priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;
  protected internalProductPricesStore = rootStore.getStore(
    StoreName.InternalProductPrices,
  ) as InternalProductPricesStore;

  /** Current filter. After filter changes - data for the page should be updated */
  @observable
  protected filterValues: T;

  /** Product info data(for top  default table) */
  @observable
  public productInfo: IRelatedReportsProductInfo;

  /**
   * Function sets product info from InternalProductPricesDTO and Product
   * to global variable
   */
  protected setProductInfo = () => {
    const internalProductPrices = this.internalProductPricesStore.list.get(this.filterValues.productId);
    const product = this.productStore.list.get(this.filterValues.productId);
    if (product && internalProductPrices) {
      const {name, externalId, flags} = product;
      const {purchasePrice, openPurchasePrice, prices} = internalProductPrices;
      runInAction(() => (this.productInfo = {name, externalId, purchasePrice, openPurchasePrice, flags, prices}));
    } else {
      logger.error('Product or internalProductPrices have not been found');
    }
  };

  /**
   * Function updates Page filter on Form change
   * also updates data needed to the page
   * If all values are valid, set global filter variable,
   * else set it to undefined
   * @param filterValues - form callback
   */
  public abstract onFilter(filterValues: StringMapping<any>): any;

  /**
   * Updates page data, usually after filter changed
   */
  protected abstract async updatePageData(): Promise<any>;

  /** Fetches data for the page if initial values passed
   * @param initialValues - have inside productId and CategoryId
   */
  public abstract async load(initialValues?: RelatedReportsFormFilter);

  /** Loads data needed for product info table */
  protected loadProductsInfo = async () => {
    try {
      await Promise.all([
        this.productStore.getInCategory(this.filterValues.categoryId),
        this.internalProductPricesStore.getInCategory(this.filterValues.categoryId),
      ]);
    } catch (err) {
      // // this.messages.setError(error);
    }
  };
}
