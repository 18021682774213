/**
 * @file Created on Fri Oct 12 2018
 * @author SKu
 */

import {
  CodebookItem,
  CommentItemUpdate,
  EndDateItemUpdate,
  ExportedItemUpdate,
  FinalPriceItemUpdate,
  FinalProductPrice,
  MaxPriceChangeItemUpdate,
  MinMarginChangeItemUpdate,
  MinPriceChangeItemUpdate,
  PriceCodeItemUpdate,
  Product,
  RealImpact,
  RealImpactValue,
  ReleaseState,
  ReleaseType,
  ReleaseWorkflowType,
  SelloutImpactRecord,
  SelloutReleaseCategoryTotalDTO,
  SelloutReleaseItem,
  SelloutReleaseItemKey,
  ReleaseItemRepricingMessageCode,
  SelloutReleaseItemUpdate,
  SelloutReleaseItemUpdateRequest,
  SelloutReleaseTotalDTO,
  SelloutReleaseType,
  Utils,
  ValidationResultObject,
} from '@logio/common-be-fe';
import {
  ActionsGenerator,
  ColumnDefinition,
  ColumnGenerator,
  Comparators,
  CONSTANTS,
  FindTotalFilter,
  getPath,
  getProductFlags,
  IconType,
  IReleaseImpactFilter,
  LoadingState,
  MessageStore,
  productFlagsColumnDefinition,
  ReleaseSelloutStore,
  RendererNames,
  rootStore,
  StoreName,
  StringMapping,
  translate,
  startBlockingRouteNavigation,
  stopBlockingRouteNavigation,
} from '@logio/common-fe';
import { List } from 'immutable';
import { CellClickedEvent, RowNode, ValueGetterParams } from 'ag-grid-community';
import { History } from 'history';
import debounce from 'lodash/debounce';
import { action, computed, observable, runInAction } from 'mobx';
import { PagePathsEnum } from '../../../../shared/localization/PagePathsEnum';
import { categoryColumnGenerator } from '../../../pages/DataManagement/DataPairing/columnGenerators';
import { AbstractReleaseDetailCategoryComponentStore } from './AbstractReleaseDetailCategoryComponentStore';
import { ReleaseDetailCategoryComponentAgGridServices } from './ReleaseDetailCategoryComponentAgGridServices';
import {
  getReleaseSelloutDetailProductColDefs,
  getReleaseSelloutDetailProductData,
  getReleaseSelloutDetailProductDescription,
  validateMaxPriceChange,
  validateMinPriceChange,
} from './ReleaseSelloutDetailCategoryComponentAgGridServices';
import moment from 'moment-timezone';
import { PreviousImpactsColumnGenerator } from '../columnGenerators/PreviousImpactsColumnGenerator';

export enum SelloutDetailCategoryModalEnum {
  Items = 'ITEMS',
}

export class ReleaseSelloutDetailCategoryComponentStore extends AbstractReleaseDetailCategoryComponentStore {
  /** @inheritdoc */
  constructor(public history: History, messages: MessageStore, releaseId: string, categoryId?: string) {
    super(messages, releaseId, categoryId);
  }

  /** Stores */
  public releaseStore = rootStore.getStore(StoreName.SelloutRelease) as ReleaseSelloutStore;

  private agGridServices = new ReleaseDetailCategoryComponentAgGridServices();

  /** Totals and subtotal data for the top ag-grid */
  @observable
  public totals: Map<string, SelloutReleaseTotalDTO> = new Map();

  @observable
  public subtotals: Map<string, object> = new Map();

  /** SelloutReleaseTotalDTO that will be used to know if category could be approved/rejected */
  public totalWithActions: SelloutReleaseTotalDTO;

  @observable
  public productsRealImpact = new Map<string, RealImpact>();

  /** Data for bottom ag-grid */
  @observable
  public categoryTotals = new Map<string, SelloutReleaseCategoryTotalDTO>();

  /** Total for top ag-grid in itemModal */
  @observable
  private totalForItemModal: { impact: SelloutImpactRecord; realImpact: RealImpact, previousImpacts: List<SelloutImpactRecord> };

  /** Subtotal for top ag-grid in itemModal */
  @observable
  private subtotalForItemModal: SelloutImpactRecord;

  /** Data for itemOverview modal */
  @observable
  public items = new Map<string, SelloutReleaseItem>();

  /** Internal sites(used for itemOverview modal) */
  private sites = new Map<string, CodebookItem>();

  /** Index that defines for what itemOverview modal is opened */
  @observable
  public activeProductRowIndex: number;

  /** Ids of categoryTotals that have been filtered in bottom ag-grid (used for fetching subtotal for top ag-grid) */
  private filteredCategoryTotalIds = new Map<string, string[]>();

  /**  */
  @observable
  public modalLoadingState: LoadingState = LoadingState.Pending;

  /** Product that used in itemOverview modal(table with rows per site) */
  @observable
  public productDetail: Product;

  /** Separate alerts that should be shown as productMessages */
  private cleaningAlerts: Map<string, ValidationResultObject> = new Map<string, ValidationResultObject>();

  /** Current release */
  @computed
  public get release() {
    return this.releaseStore.release;
  }

  @computed
  public get isReleased() {
    return this.release.state === ReleaseState.Released;
  }

  /** Some features on this page available only for deadstock release */
  @computed
  private get isDeadstock() {
    return this.release.selloutReleaseType === SelloutReleaseType.Deadstock;
  }

  /** Returns true if release workflow type is selloutUrgent */
  @computed
  public get isUrgent() {
    return this.releaseStore.release.workflowType === ReleaseWorkflowType.SelloutUrgent;
  }

  /** Ag-grid generators */
  private actionsGenerator = new ActionsGenerator();
  private totalOverviewGenerator = new ColumnGenerator(SelloutImpactRecord.schema);
  private categoryTotalGenerator = new ColumnGenerator(this.agGridServices.itemOverviewDesc);
  public impactColumnGenerator = new ColumnGenerator<StringMapping<any>>(RealImpactValue.schema);

  @computed
  private get itemDetailModalGenerator() {
    // product-site modal grid is dependant on reopen count
    return new ColumnGenerator(getReleaseSelloutDetailProductDescription(this.releaseStore.reopenCount));
  }

  private previousImpactsColumnGenerator = new PreviousImpactsColumnGenerator(this.releaseStore);

  private updateRequest = new SelloutReleaseItemUpdateRequest(List());
  private updateTimeout = null;
  private isEditing = false;

  /**************************TOTAL & SUBTOTALS****************************************/

  /** TotalOverview Ag-Grid Column definitions */
  @computed
  public get totalOverviewColumnDefs(): ColumnDefinition[] {
    return [
      ...categoryColumnGenerator.getColumnDefinitions([
        {
          field: 'box',
          pinned: true,
          filter: 'agSetColumnFilter',
        },
      ]),
      ...this.totalOverviewGenerator.getColumnDefinitions([
        { field: 'selloutProductsCount' },
        { field: 'sitesCount' },
        { field: 'priceChangesCount' },
        {
          field: 'discountPercent',
          dontCreateColumn: this.isUrgent,
          valueFormatter: ({ value }) => this.getRoundedPrice(value),
        },
        { field: 'marginNewPercent', dontCreateColumn: this.isUrgent },
        {
          field: 'marginChangePercent',
          comparator: Comparators.arrowComparator,
          cellRenderer: RendererNames.ArrowRenderer,
          dontCreateColumn: this.isUrgent,
          valueFormatter: ({ value }) => this.getRoundedPrice(value),
        },
        { field: 'minPriceChangeInPercent' },
        { field: 'maxPriceChangeInPercent' },
        { field: 'minMarginChangeInPercent' },
        {
          field: 'availableSupplyPrice',
          valueFormatter: ({ value }) => this.getRoundedPrice(value),
        },
        { field: 'availableSupply' },
        { field: 'availableSupplyBeforeRepricing' },
        { field: 'availableSupplyBeforeRepricingPrice' },
        { field: 'availableSupplyPriceChange' },
        { field: 'availableSupplyChange' },
        { field: 'availableSupplyPriceChangePercent' },
        { field: 'availableSupplyChangePercent' },
        { field: 'deadstockProvisionDiscountPercent', dontCreateColumn: !this.isDeadstock },
        { field: 'deadstockProvision', dontCreateColumn: !this.isDeadstock },
        { field: 'deadstockInvestment', dontCreateColumn: !this.isDeadstock },
        { field: 'deadstockImpact', dontCreateColumn: !this.isDeadstock },
        { field: 'estimationInPercent' },
        { field: 'marginNew' },
        { field: 'marginOld' },
        { field: 'marginOldPercent' },
        { field: 'marginChange', comparator: Comparators.arrowComparator, cellRenderer: RendererNames.ArrowRenderer },
        {
          field: 'salesVolumeOld',
          valueFormatter: ({ value }) => this.getRoundedPrice(value, 0),
        },
        {
          field: 'salesVolumeNew',
          valueFormatter: ({ value }) => this.getRoundedPrice(value, 0),
        },
        {
          field: 'revenueOld',
          valueFormatter: ({ value }) => this.getRoundedPrice(value),
        },
        {
          field: 'revenueNew',
          valueFormatter: ({ value }) => this.getRoundedPrice(value),
        },
      ]),
      ...this.impactColumnGenerator.getColumnDefinitions(this.getRealImpactColumnDefs()),
      ...this.previousImpactsColumnGenerator.getColumnDefinitions(),
    ];
  }

  /** Common definitions for total generator */
  public getRealImpactColumnDefs(): ColumnDefinition[] {
    return [
      {
        field: 'bm',
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'bmPercent',
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'bmImpact',
        cellRenderer: RendererNames.ArrowRenderer,
        comparator: Comparators.arrowComparator,
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'bmImpactInPercent',
        cellRenderer: RendererNames.ArrowRenderer,
        comparator: Comparators.arrowComparator,
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'saleValueWithoutVat',
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'revenueImpact',
        cellRenderer: RendererNames.ArrowRenderer,
        comparator: Comparators.arrowComparator,
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'amount',
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'salesVolumeImpact',
        cellRenderer: RendererNames.ArrowRenderer,
        comparator: Comparators.arrowComparator,
        dontCreateColumn: !this.isReleased,
      },
      {
        field: 'diffDays',
        valueFormatter: (params) => {
          return params.value ? Number(params.value).toFixed(0) : CONSTANTS.FORMAT.NULL;
        },
        dontCreateColumn: !this.isReleased,
      },
    ];
  }

  /** TotalOverview Ag-Grid Column definitions */
  @computed
  public get itemModalTotalOverviewColumnDefs(): ColumnDefinition[] {
    return [
      ...categoryColumnGenerator.getColumnDefinitions([
        {
          field: 'box',
          pinned: true,
          filter: 'agSetColumnFilter',
        },
      ]),
      ...this.totalOverviewGenerator.getColumnDefinitions([
        { field: 'sitesCount' },
        { field: 'priceChangesCount' },
        {
          field: 'discountPercent',
          dontCreateColumn: this.isUrgent,
          valueFormatter: ({ value }) => this.getRoundedPrice(value),
        },
        { field: 'marginNewPercent', dontCreateColumn: this.isUrgent },
        {
          field: 'marginChangePercent',
          comparator: Comparators.arrowComparator,
          cellRenderer: RendererNames.ArrowRenderer,
          dontCreateColumn: this.isUrgent,
          valueFormatter: ({ value }) => this.getRoundedPrice(value),
        },
        { field: 'minPriceChangeInPercent' },
        { field: 'maxPriceChangeInPercent' },
        { field: 'minMarginChangeInPercent' },
        {
          field: 'availableSupplyPrice',
          valueFormatter: ({ value }) => this.getRoundedPrice(value),
        },
        { field: 'availableSupply' },
        { field: 'availableSupplyBeforeRepricing' },
        { field: 'availableSupplyBeforeRepricingPrice' },
        { field: 'availableSupplyPriceChange' },
        { field: 'availableSupplyChange' },
        { field: 'availableSupplyPriceChangePercent' },
        { field: 'availableSupplyChangePercent' },
        { field: 'deadstockProvisionDiscountPercent', dontCreateColumn: !this.isDeadstock },
        { field: 'deadstockProvision', dontCreateColumn: !this.isDeadstock },
        { field: 'deadstockInvestment', dontCreateColumn: !this.isDeadstock },
        { field: 'deadstockImpact', dontCreateColumn: !this.isDeadstock },
        { field: 'estimationInPercent' },
        { field: 'marginOld' },
        { field: 'marginOldPercent' },
        { field: 'marginNew' },
        { field: 'marginChange', comparator: Comparators.arrowComparator, cellRenderer: RendererNames.ArrowRenderer },
        {
          field: 'salesVolumeOld',
          valueFormatter: ({ value }) => this.getRoundedPrice(value, 0),
        },
        {
          field: 'salesVolumeNew',
          valueFormatter: ({ value }) => this.getRoundedPrice(value, 0),
        },
        {
          field: 'revenueOld',
          valueFormatter: ({ value }) => this.getRoundedPrice(value),
        },
        {
          field: 'revenueNew',
          valueFormatter: ({ value }) => this.getRoundedPrice(value),
        },
      ]),
      ...this.impactColumnGenerator.getColumnDefinitions(this.getRealImpactColumnDefs()),
      ...this.previousImpactsColumnGenerator.getColumnDefinitions(),
    ];
  }

  /** ItemOverview Ag-Grid Column definitions */
  @computed
  public get categoryTotalsColumnDefs(): ColumnDefinition[] {
    return [
      this.actionsGenerator.getColumnDefinition({
        headerName: '',
        headerCheckboxSelection: false,
        pinnedRowCellRenderer: RendererNames.BulkRenderer,
        checkboxSelection: false,
        suppressMovable: false,
      }),
      ...this.categoryTotalGenerator.getColumnDefinitions([
        {
          field: 'productName',
          pinned: true,
          width: 250,
          pinnedRowCellRenderer: RendererNames.BulkRenderer,
        },
        {
          field: 'messages',
          cellRenderer: RendererNames.ReleaseAlertsRenderer,
          comparator: (a, b) => {
            if (a === b) {
              return 0;
            }
            return a === translate(`BLANKS_FILTER`) ? -1 : 1;
          },
          filterValueGetter: (params: ValueGetterParams) => {
            const messages: string[] = [];
            params.data.ReleaseDetailCategory_messages.resultObjects.size > 0
              ? params.data.ReleaseDetailCategory_messages.resultObjects.map((obj) =>
                messages.push(translate(`${obj.severity}_${obj.messageKey}`)),
              )
              : messages.push(translate(`BLANKS_FILTER`));
            return messages;
          },
          width: 80,
          excel: {
            hide: true,
          },
        },
        {
          field: 'goldId',
          width: 120,
          valueFormatter: ({ value }) => Utils.getCustomerId(value),
          pinnedRowCellRenderer: RendererNames.BulkRenderer,
        },
        {
          field: 'isAllExported',
          cellRenderer: RendererNames.ReleaseSelloutExportRenderer,
          action: this.handleUpdateExportedAll(),
          dontCreateColumn: this.release.releaseType !== ReleaseType.Sellout,
          filter: 'agSetColumnFilter',
          editable: (params) => this.editMode && !this.rowIsReadOnly(params),
          filterValueGetter: (params) => (params.data[params.colDef.colId].exportedCount > 0 ? 'Export' : 'Not'),
          excel: {
            valueFormatter: ({ value }) =>
              value ? `${value.isAllExported} ${value.exportedCount}/${value.itemCount}` : '',
          },
          cellClassRules: {
            'state-success': params => this.isCellEdited(params.data.ReleaseDetailCategory_productId, null, ExportedItemUpdate.TAG),
          },
        },
        {
          field: 'name4BOX',
          filter: 'agSetColumnFilter',
          pinnedRowCellRenderer: RendererNames.BulkRenderer,
        },
        {
          field: 'categoryPlan',
          filter: 'agSetColumnFilter',
          pinnedRowCellRenderer: RendererNames.BulkRenderer,
        },
        {
          field: 'flags',
          ...productFlagsColumnDefinition,
          pinnedRowCellRenderer: RendererNames.BulkRenderer,
        },
        {
          field: 'productSensitivity',
          filter: 'agSetColumnFilter',
          pinnedRowCellRenderer: RendererNames.BulkRenderer,
        },
        {
          field: 'family',
          valueFormatter: (value) => (value.value ? value.value.name : null),
          cellClassRules: { 'c-link pointer': (value) => !!value.value },
          onCellClicked: (params) => this.navigateToFamily(params),
          filter: 'agSetColumnFilter',
          filterValueGetter: (params) =>
            params.data.ReleaseDetailCategory_family ? params.data.ReleaseDetailCategory_family.name : null,
          comparator: Comparators.familyNameComparator,
        },
        {
          field: 'supplier',
          valueFormatter: (value) => (value.value ? value.value.name : null),
          filterValueGetter: (params) =>
            params.data.ReleaseDetailCategory_supplier ? params.data.ReleaseDetailCategory_supplier.name : null,
          filter: 'agSetColumnFilter',
        },
        {
          field: 'tier',
          filter: 'agSetColumnFilter',
          pinnedRowCellRenderer: RendererNames.BulkRenderer,
        },
        { field: 'readonly', dontCreateColumn: true },
      ]),
      ...this.totalOverviewGenerator.getColumnDefinitions([
        { field: 'sitesCount', pinnedRowCellRenderer: RendererNames.BulkRenderer },
        { field: 'priceChangesCount', pinnedRowCellRenderer: RendererNames.BulkRenderer },
        {
          field: 'discountPercent',
          pinnedRowCellRenderer: RendererNames.BulkRenderer,
          dontCreateColumn: this.isUrgent,
          valueFormatter: ({ value }) => this.getRoundedPrice(value),
        },
        { field: 'marginOld' },
        { field: 'marginOldPercent' },
        { field: 'marginNew' },
        {
          field: 'marginNewPercent',
          pinnedRowCellRenderer: RendererNames.BulkRenderer,
          dontCreateColumn: this.isUrgent,
          cellClassRules: {
            'c-error': ({ node }: { node: RowNode }) => {
              const minMarginChange = Number(node.data[`SelloutImpactRecord_minMarginChangeInPercent`]);
              const marginNewPercent = Number(node.data[`SelloutImpactRecord_marginNewPercent`]);
              return marginNewPercent && minMarginChange && marginNewPercent < minMarginChange; // LOG-5239
            },
          },
        },
        {
          field: 'marginChange',
          comparator: Comparators.arrowComparator,
          cellRenderer: RendererNames.ArrowRenderer,
        },
        {
          field: 'marginChangePercent',
          pinnedRowCellRenderer: RendererNames.BulkRenderer,
          comparator: Comparators.arrowComparator,
          cellRenderer: RendererNames.ArrowRenderer,
          dontCreateColumn: this.isUrgent,
          valueFormatter: ({ value }) => this.getRoundedPrice(value),
        },
        { field: 'availableSupply', pinnedRowCellRenderer: RendererNames.BulkRenderer }, // FIXME: Different translation from the one in the sheet
        {
          field: 'availableSupplyPrice',
          pinnedRowCellRenderer: RendererNames.BulkRenderer,
          valueFormatter: ({ value }) => this.getRoundedPrice(value),
        }, // FIXME: Different translation from the one in the sheet
        { field: 'availableSupplyBeforeRepricing' },
        { field: 'availableSupplyBeforeRepricingPrice' },
        { field: 'availableSupplyPriceChange' },
        { field: 'availableSupplyChange' },
        { field: 'availableSupplyPriceChangePercent' },
        { field: 'availableSupplyChangePercent' },
        {
          field: 'minPriceChangeInPercent',
          pinnedRowCellRenderer: RendererNames.BulkRenderer,
          editable: (params) => this.editMode && !this.rowIsReadOnly(params),
          action: this.handleUpdateCategoryTotalsFromAgGrid(MinPriceChangeItemUpdate.TAG),
          bulkFunc: this.handleUpdateCategoryTotalsFromAgGrid(MinPriceChangeItemUpdate.TAG, true),
          onEditStart: this.handleEditStart,
          onEditEnd: this.handleEditEnd,
          cellClassRules: {
            'state-success': params => this.isCellEdited(params.data.ReleaseDetailCategory_productId, null, MinPriceChangeItemUpdate.TAG),
          },
          cellEditorParams: {
            validate: validateMinPriceChange('SelloutImpactRecord_maxPriceChangeInPercent'),
          },
        },
        {
          field: 'maxPriceChangeInPercent',
          pinnedRowCellRenderer: RendererNames.BulkRenderer,
          editable: (params) => this.editMode && !this.rowIsReadOnly(params),
          action: this.handleUpdateCategoryTotalsFromAgGrid(MaxPriceChangeItemUpdate.TAG),
          bulkFunc: this.handleUpdateCategoryTotalsFromAgGrid(MaxPriceChangeItemUpdate.TAG, true),
          onEditStart: this.handleEditStart,
          onEditEnd: this.handleEditEnd,
          cellClassRules: {
            'state-success': params => this.isCellEdited(params.data.ReleaseDetailCategory_productId, null, MaxPriceChangeItemUpdate.TAG),
          },
          cellEditorParams: {
            validate: validateMaxPriceChange('SelloutImpactRecord_minPriceChangeInPercent'),
          },
        },
        {
          field: 'minMarginChangeInPercent',
          pinnedRowCellRenderer: RendererNames.BulkRenderer,
          editable: (params) => this.editMode && !this.rowIsReadOnly(params),
          action: this.handleUpdateCategoryTotalsFromAgGrid(MinMarginChangeItemUpdate.TAG),
          bulkFunc: this.handleUpdateCategoryTotalsFromAgGrid(MinMarginChangeItemUpdate.TAG, true),
          onEditStart: this.handleEditStart,
          onEditEnd: this.handleEditEnd,
          cellClassRules: {
            'state-success': params => this.isCellEdited(params.data.ReleaseDetailCategory_productId, null, MinMarginChangeItemUpdate.TAG),
          },
        },
        {
          field: 'deadstockInvestment',
          dontCreateColumn: !this.isDeadstock,
          pinnedRowCellRenderer: RendererNames.BulkRenderer,
        },
        {
          field: 'deadstockProvision',
          dontCreateColumn: !this.isDeadstock,
          pinnedRowCellRenderer: RendererNames.BulkRenderer,
        },
        {
          field: 'deadstockProvisionDiscountPercent',
          dontCreateColumn: !this.isDeadstock,
          pinnedRowCellRenderer: RendererNames.BulkRenderer,
        },
        {
          field: 'deadstockImpact',
          dontCreateColumn: !this.isDeadstock,
          pinnedRowCellRenderer: RendererNames.BulkRenderer,
        },
        { field: 'estimationInPercent' },
        {
          field: 'salesVolumeOld',
          valueFormatter: ({ value }) => this.getRoundedPrice(value, 0),
        },
        {
          field: 'salesVolumeNew',
          valueFormatter: ({ value }) => this.getRoundedPrice(value, 0),
        },
        {
          field: 'revenueOld',
          valueFormatter: ({ value }) => this.getRoundedPrice(value),
        },
        {
          field: 'revenueNew',
          valueFormatter: ({ value }) => this.getRoundedPrice(value),
        },
      ]),
      ...this.impactColumnGenerator.getColumnDefinitions(this.getRealImpactColumnDefs()),
      ...this.previousImpactsColumnGenerator.getColumnDefinitions(),
    ].filter(row => row.fieldKey !== 'goldId');
  }

  /** Ag-grid row data for the top table(Total Overview) */
  @computed
  public get totalOverviewRowData(): Array<StringMapping<any>> {
    const rowData = [];
    Array.from(this.totals.values()).forEach(({ categoryId, impact, previousImpacts, realImpact }) => {
      const realImpactValue = realImpact && realImpact.getValue();
      rowData.push({
        ...categoryColumnGenerator.getColumnData({ box: this.productCategoryStore.getCategoryForRelease(this.releaseId, categoryId).name }),
        ...this.totalOverviewGenerator.getColumnData(impact),
        ...this.impactColumnGenerator.getColumnData(realImpactValue),
        ...this.previousImpactsColumnGenerator.getColumnData(previousImpacts),
      });
    });
    Array.from(this.subtotals.values()).forEach((value) => {
      rowData.push(value);
    });
    return rowData;
  }

  /**
   * Returns row data for subtotal
   * @param subtotal
   */
  private getSubtotalRowData(subtotal: SelloutImpactRecord, previousSubtotals: List<SelloutImpactRecord>) {
    return {
      ...this.totalOverviewGenerator.getColumnData(subtotal),
      ...categoryColumnGenerator.getColumnData({ box: this.getSubtotalName(subtotal) }),
      ...this.previousImpactsColumnGenerator.getColumnData(previousSubtotals),
    };
  }

  /**
   * Returns name for subtotal
   * @param subtotal
   */
  private getSubtotalName(subtotal): string {
    const category = this.productCategoryStore.getCategoryForRelease(this.releaseId, subtotal.categoryId);
    return this.categoryId ? 'SUBTOTAL' : 'SUBTOTAL ' + category ? category.name : CONSTANTS.FORMAT.NULL;
  }

  /**
   * Removes subtotals which shouldn't be shown
   */
  deleteNotIncludedCategoriesFromSubtotals() {
    const notIncludedCategories = Array.from(this.subtotals.keys()).filter((categoryId) => {
      return !Array.from(this.filteredCategoryTotalIds.keys()).includes(categoryId);
    });

    notIncludedCategories.forEach((categoryId) => {
      this.setSubtotal(categoryId, categoryColumnGenerator.getColumnData({ box: this.getSubtotalName({ categoryId }) }));
    });
  }

  @action
  setSubtotal(categoryId: string, rowData: any) {
    this.subtotals.set(categoryId, rowData);
  }

  @action.bound
  setSubtotals(subtotalsTuples: Array<[SelloutImpactRecord, List<SelloutImpactRecord>]>) {
    subtotalsTuples.forEach(([subtotal, previousSubtotals]) => {
      runInAction(() => this.subtotals.set(subtotal.categoryId, this.getSubtotalRowData(subtotal, previousSubtotals)));
    });
  }

  @action
  public hideModal = () => {
    this.modalShown = undefined;
    this.enforceUpdateIfAny();
    this.itemsModalGridApi = null;
  };

  /** Ag-grid row data for the top table in item modal */
  @computed
  public get itemModalTotalOverviewRowData(): Array<StringMapping<any>> {
    const rowData = [];
    if (this.totalForItemModal && this.totalForItemModal.impact) {
      const realImpactValue = this.totalForItemModal.realImpact && this.totalForItemModal.realImpact.getValue();
      rowData.push({
        ...this.totalOverviewGenerator.getColumnData(this.totalForItemModal.impact),
        ...this.impactColumnGenerator.getColumnData(realImpactValue),
        ...categoryColumnGenerator.getColumnData({ box: 'Total' }),
        ...this.previousImpactsColumnGenerator.getColumnData(this.totalForItemModal.previousImpacts),
      });
    }
    if (this.subtotalForItemModal) {
      rowData.push({
        ...this.totalOverviewGenerator.getColumnData(this.subtotalForItemModal),
        ...categoryColumnGenerator.getColumnData({ box: 'Subtotal' }),
        // TODO: add previous subtotals, when subtotalForItemModal starts to be relevant
      });
    }

    return rowData;
  }

  /** Ag-grid row data for the bottom table(Item Overview) */
  @computed
  public get categoryTotalsRowData(): Array<StringMapping<any>> {
    const rowData = [];
    this.categoryTotals.forEach((item) => {
      const rowItem = this.generateRowItem(item);
      if (rowItem && (this.categoryId || !item.readonly)) {
        rowData.push(rowItem);
      }
    });

    return rowData;
  }

  public destroy() {
    this.cancelUpdate();
  }

  /**
   * Returns row item for AgGrid
   * @param item
   */
  private generateRowItem = (item: SelloutReleaseCategoryTotalDTO | null): any => {
    const product = this.productStore.list.get(item.productId);
    if (product && product.categoryIds) {
      /** Icon for opening item detail modal */
      const itemDetail = {
        name: 'itemDetail',
        icon: IconType.view,
        props: { onClick: this.handleOpenItemsModal(item.productId) },
      };

      /** 4Box name - is the first in the map */
      const name4BOX = this.productCategoryStore.getCategoryForRelease(this.releaseId, Utils.getCategoryIdWithLevel(product.categoryIds, 6));

      const releaseItemGeneratorData = () => {
        /** category plan - is the second in the map */
        const categoryPlan = this.productCategoryStore.getCategoryForRelease(this.releaseId, Utils.getCategoryIdWithLevel(product.categoryIds, 5));
        const productSensitivity = this.productSensitivityStore.list.get(product.productSensitivityId);
        const family = this.familyStore.list.get(product.familyId);
        const supplier = this.supplierStore.list.get(product.supplierId);
        let familyAction: any;
        if (family) {
          familyAction = {
            name: family.name,
            linkProps: {
              to: {
                pathname: `${getPath(PagePathsEnum.PriceArchitectureProducts)}${Utils.getCategoryIdWithLevel(
                  product.categoryIds,
                  5,
                )}`,
                externalId: product.externalId,
              },
            },
          };
        }
        const tier = this.tierStore.list.get(family ? family.tierId : product.tierId);
        /** Merge existing Validation Result with additional */
        const additionalAlert = this.cleaningAlerts.get(product.id);
        item.validationResult.addObject(additionalAlert);

        /** Merge existing Validation Result with additional */
        return {
          productId: product.id,
          productName: product.name,
          goldId: product.externalId,
          isAllExported: {
            productId: product.id,
            isAllExported: item.isAllExported(),
            exportedCount: item.exportedCount,
            itemCount: item.itemCount,
          },
          messages: item.validationResult,
          name4BOX: name4BOX ? name4BOX.name : CONSTANTS.FORMAT.NULL,
          categoryPlan: categoryPlan ? categoryPlan.name : CONSTANTS.FORMAT.NULL,
          flags: getProductFlags(product),
          productSensitivity: productSensitivity ? productSensitivity.title : CONSTANTS.FORMAT.NULL,
          family: familyAction,
          supplier,
          tier: tier ? tier.title : CONSTANTS.FORMAT.NULL,
          readonly: item.readonly,
        };
      };

      let rowData = {
        ...this.actionsGenerator.getColumnData(itemDetail),
        ...this.categoryTotalGenerator.getColumnData(releaseItemGeneratorData()),
        ...this.totalOverviewGenerator.getColumnData(item.impact),
        ...categoryColumnGenerator.getColumnData({
          box: name4BOX ? name4BOX.name : CONSTANTS.FORMAT.NULL,
        }),
        ...this.previousImpactsColumnGenerator.getColumnData(item.previousImpacts),
      };

      if (this.release.state === ReleaseState.Released) {
        const realImpact: RealImpact = this.productsRealImpact.get(product.id);
        const realImpactValue = realImpact && realImpact.getValue();
        rowData = { ...rowData, ...this.impactColumnGenerator.getColumnData(realImpactValue) };
      }

      return rowData;
    } else {
      return null;
    }
  };

  /** Updates subtotal in top grid after filtered items changed */
  public onFilterChanged = (params) => {
    this.filteredCategoryTotalIds.clear();
    const productIds = [];
    if (this.categoryId) {
      params.api.forEachNodeAfterFilter((node: RowNode) => productIds.push(node.id));
      this.filteredCategoryTotalIds.set(this.categoryId, productIds);
    } else {
      let categoriesIds: string[] = [];
      params.api.forEachNodeAfterFilter((node: RowNode) =>
        categoriesIds.push(node.data.SelloutImpactRecord_categoryId),
      );
      categoriesIds = [...new Set(categoriesIds)];
      for (const categoryId of categoriesIds) {
        params.api.forEachNodeAfterFilter((node: RowNode) => {
          if (node.data.SelloutImpactRecord_categoryId === categoryId) {
            productIds.push(node.id);
          }
        });
        if (productIds.length) {
          this.filteredCategoryTotalIds.set(categoryId, productIds);
        }
      }
    }
    this.updateSubTotalDebounced();
  };

  /** Fetching and Updating subtotal in Grid */
  private updateSubTotal = async () => {
    try {
      if (this.categoryId) {
        const filteredCategoryProductIds: string[] = this.filteredCategoryTotalIds.get(this.categoryId);
        if (filteredCategoryProductIds.length || this.isVisibleAllProducts()) {
          const filter = { productIds: filteredCategoryProductIds };
          const [subtotal, previousSubtotals] = await this.releaseStore.getAllSubTotalForDetailCategoryComponent(this.releaseId, this.categoryId, filter);

          this.setSubtotal(subtotal.categoryId, this.getSubtotalRowData(subtotal, previousSubtotals));
        } else {
          this.setSubtotal(this.categoryId, categoryColumnGenerator.getColumnData({ box: this.getSubtotalName(null) }));
        }
      } else {
        const promises = [];
        for (const categoryId of this.filteredCategoryTotalIds.keys()) {
          const filteredCategoryProductIds = this.filteredCategoryTotalIds.get(categoryId);
          if (filteredCategoryProductIds.length) {
            const filter = { productIds: this.filteredCategoryTotalIds.get(categoryId) };
            promises.push(this.releaseStore.getAllSubTotalForDetailCategoryComponent(this.releaseId, categoryId, filter));
          }
        }
        this.deleteNotIncludedCategoriesFromSubtotals();
        const subtotalsTuples = await Promise.all(promises);
        this.setSubtotals(subtotalsTuples);
      }
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  /**
   * Returns true, if filtered category items equals all items
   */
  private isVisibleAllProducts() {
    return this.filteredCategoryTotalIds.get(this.categoryId).length === this.productStore.list.size;
  }

  /** Debounce for calling subTotal Endpoint */
  private updateSubTotalDebounced = debounce(this.updateSubTotal, 500);

  /**
   * Proxy for updater
   * It uses gridApi to find ids of updated entities
   * @param updatedField - field type (TAG of SelloutReleaseItemUpdate)
   * @param bulk - boolean, shows if function used for bulk operation
   */
  private handleUpdateCategoryTotalsFromAgGrid = (updatedField: string, bulk = false) => {
    return this.updateCategoryTotalsFromAgGrid.bind(this, updatedField, bulk);
  };

  /**
   * Proxy for updater
   * It uses gridApi to find ids of updated entities
   * @param updatedField - field type (TAG of SelloutReleaseItemUpdate)
   * @param bulk - boolean, shows if function used for bulk operation
   * @param rowId - rowId - represented by siteId
   * @param column - not used(column key)
   * @param newValue - new value, that should be sent on BE for specific field
   */
  private updateCategoryTotalsFromAgGrid = (
    updatedField: string,
    bulk: boolean,
    rowId: string,
    column: string,
    newValue: any,
  ) => {
    const itemUpdateKeys: SelloutReleaseItemKey[] = [];
    if (bulk) {
      const selectedNodes = this.itemOverviewGridApi.getSelectedNodes();
      if (selectedNodes.length > 0) {
        selectedNodes.forEach((node: RowNode) => {
          const productId = node.data.ReleaseItem_productId;
          if (!this.rowIsReadOnly(this.itemOverviewGridApi.getRowNode(productId))) {
            itemUpdateKeys.push(this.createSelloutReleaseItemKey(null, productId));
          }
        });
      } else {
        this.categoryTotals.forEach((item) => {
          const productId = item.productId;
          if (!this.rowIsReadOnly(this.itemOverviewGridApi.getRowNode(productId))) {
            itemUpdateKeys.push(this.createSelloutReleaseItemKey(null, productId));
          }
        });
      }
    } else {
      if (!this.rowIsReadOnly(this.itemOverviewGridApi.getRowNode(rowId))) {
        itemUpdateKeys.push(this.createSelloutReleaseItemKey(null, rowId));
      }
    }

    this.addUpdateToQueue(updatedField, newValue, itemUpdateKeys, bulk);

    return Promise.resolve();
  };

  private async updateImpact(productIds: string[]) {
    // Update impact
    // LOG-5117:
    // this.productDetail can be undefined and
    // this.releaseStore.getSubTotalForDetailCategoryComponent have to have not-null categoryId
    const categoryId = this.categoryId || Utils.getCategoryIdWithLevel(this.productDetail.categoryIds, 5);
    if (categoryId) {
      const filter = {
        productIds,
      };
      const impact = await this.releaseStore.getSubTotalForDetailCategoryComponent(this.releaseId, categoryId, filter);

      runInAction(() => {
        // Only impact is needed to be updated
        this.totalForItemModal.impact = impact;
      });
    }
  }

  /**
   * Updates data for ReleaseSelloutDetailCategoryTotalOverview table and ReleaseSelloutDetailCategoryCategoryTotalOverview table
   * @param productIds
   */
  private updateDetailCategoryTotalsAndItems = async (productIds: string[]) => {
    this.fetchCategoryTotals();
    this.updateSubTotalDebounced();
    const categoryTotalsItems = await this.fetchCategoryTotalsItems({ productIds });
    categoryTotalsItems.forEach((item) => {
      const rowNode = this.itemOverviewGridApi.getRowNode(item.productId);
      const rowItem = this.generateRowItem(item);
      if (rowNode && rowItem && (this.categoryId || !item.readonly)) {
        rowNode.setData(rowItem);
      }
      runInAction(() => (this.categoryTotals = this.categoryTotals.set(item.productId, item)));
    });
  };

  /**************************TOTAL & SUBTOTALS****************************************/

  /**************************ITEM DETAIL MODAL DATA HANDLING****************************************/

  /** Generates col defs for ag-grid inside modal */
  @computed
  public get itemsModalColumnDefs(): ColumnDefinition[] {
    const productDetailColDefs = getReleaseSelloutDetailProductColDefs(
      this.priceCodeOptions,
      this.handleUpdateItemsFromAgGrid,
      this.editable,
      this.release,
      this.nationalZone,
      this.releaseStore.reopenCount,
      this.isCellEdited,
      this.handleEditStart,
      this.handleEditEnd,
    );
    return [
      ...this.itemDetailModalGenerator.getColumnDefinitions(productDetailColDefs),
      ...this.impactColumnGenerator.getColumnDefinitions(this.getRealImpactColumnDefs()),
    ];
  }

  /** Generates row data for ag-grid inside modal */
  @computed
  public get itemsModalRowData(): Array<StringMapping<any>> {
    const gridData = [];
    this.items.forEach((item) => {
      const site = this.sites.get(item.siteId);
      const data = getReleaseSelloutDetailProductData(site, item, this.releaseStore.reopenCount);
      const realImpactValue = item.prices.realImpact && item.prices.realImpact.getValue();
      const rowData = {
        ...this.itemDetailModalGenerator.getColumnData(data),
        ...this.impactColumnGenerator.getColumnData(realImpactValue),
      };
      gridData.push(rowData);
    });
    return gridData;
  }

  /** Handle itemOverview modal opening */
  public handleOpenItemsModal = (productId: string) => async () => {
    await this.enforceUpdateIfAny();
    runInAction(() => (this.activeProductRowIndex = this.itemOverviewGridApi.getRowNode(productId).childIndex));
    this.openModal(SelloutDetailCategoryModalEnum.Items);
    await this.updateItemsOverviewModalData(productId);
  };

  /**
   * Proxy for updater
   * It uses gridApi to find ids of updated entities
   * @param updatedField - field type (TAG of SelloutReleaseItemUpdate)
   * @param bulk - boolean, shows if function used for bulk operation
   */
  private handleUpdateItemsFromAgGrid = (updatedField: string, bulk = false) => {
    return this.updateItemsFromAgGrid.bind(this, updatedField, bulk);
  };

  /**
   * Update function for release sellout items
   * @param updatedField - field type (TAG of SelloutReleaseItemUpdate)
   * @param bulk - boolean, shows if function used for bulk operation
   * @param rowId - rowId - represented by siteId
   * @param column - not used(column key)
   * @param newValue - new value, that should be sent on BE for specific field
   */
  private updateItemsFromAgGrid = async (
    updatedField: string,
    bulk: boolean,
    rowId: string,
    column: string,
    newValue: any,
  ) => {
    let updatedValue;
    const itemUpdateKeys: SelloutReleaseItemKey[] = [];
    let isValid = true;
    const validRowNodes: any[] = [];
    const notValidRowNodes: any[] = [];

    if (updatedField === EndDateItemUpdate.TAG) {
      if (!bulk) {
        const rowData = this.itemsModalGridApi.getRowNode(rowId).data;
        const finalPrice: FinalProductPrice = rowData.SelloutReleaseItemPrices_finalPrice;
        if (finalPrice) {
          const validFrom = finalPrice.validFrom.clone().add(1, 'day');
          isValid = newValue.isAfter(validFrom) && newValue.isAfter(moment());
        } else {
          isValid =
            newValue.isAfter(rowData.ReleaseSelloutItemDetail_priceValidFrom) &&
            newValue.isAfter(
              moment()
                .add(1, 'day')
                .endOf('day'),
            );
        }
      }
      updatedValue = newValue;
    } else if (updatedField === FinalPriceItemUpdate.TAG) {
      /** If we are updating final price, we should extract number value from final price entity */
      updatedValue = newValue.value;
    } else {
      updatedValue = newValue;
    }

    if (bulk) {
      const selectedNodes = this.itemOverviewGridApi.getSelectedNodes();
      if (selectedNodes.length > 0) {
        selectedNodes.forEach((node: RowNode) => {
          if (updatedField === EndDateItemUpdate.TAG) {
            const rowData = this.itemsModalGridApi.getRowNode(rowId).data;
            const finalPrice: FinalProductPrice = rowData.SelloutReleaseItemPrices_finalPrice;
            if (finalPrice) {
              const validFrom = finalPrice.validFrom.clone().add(1, 'day');
              if (newValue.isAfter(validFrom) && newValue.isAfter(moment())) {
                itemUpdateKeys.push(this.createSelloutReleaseItemKey(node.data.ReleaseSelloutDetailProduct_id));
              } else {
                isValid = false;
              }
            } else {
              isValid =
                newValue.isAfter(rowData.ReleaseSelloutItemDetail_priceValidFrom) &&
                newValue.isAfter(
                  moment()
                    .add(1, 'day')
                    .endOf('day'),
                );
            }
          } else {
            itemUpdateKeys.push(this.createSelloutReleaseItemKey(node.data.ReleaseSelloutDetailProduct_id));
          }
        });
      } else {
        if (updatedField === EndDateItemUpdate.TAG) {
          this.itemsModalGridApi.forEachNode((node: RowNode, index) => {
            const finalPrice: FinalProductPrice = node.data.SelloutReleaseItemPrices_finalPrice;
            if (finalPrice) {
              const validFrom = finalPrice.validFrom.clone().add(1, 'day');
              if (newValue.isAfter(validFrom) && newValue.isAfter(moment())) {
                // validRowNodes.push(node);
                itemUpdateKeys.push(this.createSelloutReleaseItemKey(node.data.ReleaseSelloutItemDetail_id));
              } else {
                notValidRowNodes.push(node);
                isValid = false;
              }
            } else {
              isValid =
                newValue.isAfter(node.data.ReleaseSelloutItemDetail_priceValidFrom) &&
                newValue.isAfter(
                  moment()
                    .add(1, 'day')
                    .endOf('day'),
                );
            }
          });
          // if (isValid) {
          //   this.itemsModalGridApi.getCellRendererInstances({
          //     columns: ['ReleaseSelloutItemDetail_endDateFinalPrice'],
          //     rowNodes: validRowNodes,
          //   }).forEach(value => {
          //     value.getGui().classList.remove('is-loading-error');
          //     value.getGui().classList.add('is-loading-ok');
          //   });
          // } else {
          if (!isValid) {
            this.itemsModalGridApi
              .getCellRendererInstances({
                columns: ['ReleaseSelloutItemDetail_endDateFinalPrice'],
                rowNodes: notValidRowNodes,
              })
              .forEach((value) => {
                value.getGui().classList.remove('is-loading-error');
                value.getGui().classList.remove('is-loading-ok');
                setTimeout(() => {
                  value.getGui().classList.add('is-loading-error');
                }, 50);
              });
          }
          // this.itemsModalGridApi.refreshCells({columns: ['ReleaseSelloutItemDetail_endDateFinalPrice']});
        } else {
          this.itemsModalGridApi.forEachNode((node: RowNode) => {
            itemUpdateKeys.push(this.createSelloutReleaseItemKey(node.data.ReleaseSelloutItemDetail_id));
          });
        }
      }
    } else {
      itemUpdateKeys.push(this.createSelloutReleaseItemKey(rowId));
    }

    if (isValid && itemUpdateKeys.length) {
      this.addUpdateToQueue(updatedField, updatedValue, itemUpdateKeys, bulk);
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  };

  // LOG-4943
  public rowIsReadOnly = (params) =>
    params.data.ReleaseDetailCategory_readonly ? !!params.data.ReleaseDetailCategory_readonly : false;

  /**
   * Returns true if product has validation result of ReleaseItemStartDateBeforeTomorrow
   */
  public isReleaseItemStartDateBeforeTomorrow = (params) => {
    let error;
    if (params.data.ReleaseDetailCategory_messages) {
      error = params.data.ReleaseDetailCategory_messages.resultObjects.find(
        (resultObject) => resultObject.messageKey === ReleaseItemRepricingMessageCode.ReleaseItemStartDateBeforeTomorrow,
      );
    }
    return !!error;
  };

  // LOG-5661
  public getRoundedPrice = (value: any, digits: number = 2): string => {
    if (!value) {
      return '';
    }

    return value
      .toFixed(digits)
      .toString()
      .replace('.', ',');
  };

  /**
   * Updates Exported on All Sellout Release Items of Product
   */
  @action
  private handleUpdateExportedAll = () => async (rowId: string, column: string, newValue: any) => {
    // LOG-5913
    if (!newValue || !newValue.productId) {
      return Promise.reject();
    }
    this.addUpdateToQueue(
      ExportedItemUpdate.TAG,
      newValue.isAllExported,
      [this.createSelloutReleaseItemKey(null, newValue.productId)],
    );

    return Promise.resolve();
  };

  /**
   * Updates data for itemOverview modal
   * @param productId
   * @returns {Promise<void>}
   */
  public updateItemsOverviewModalData = async (productId: string): Promise<void> => {
    runInAction(() => {
      this.modalLoadingState = LoadingState.Pending;
      this.productDetail = this.productStore.list.get(productId);
    });
    const productIds = [productId];
    try {
      const filter = { productIds };
      const categoryId = this.categoryId || Utils.getCategoryIdWithLevel(this.productDetail.categoryIds, 5); // category from product used for export page
      const items =
        this.release.state === ReleaseState.Released && this.impactFilter
          ? await this.releaseStore.getItemsWithImpact(this.releaseId, { ...filter, ...this.impactFilter })
          : await this.releaseStore.getItems(this.releaseId, filter);

      const [impact, previousImpacts] = await this.releaseStore.getAllSubTotalForDetailCategoryComponent(this.releaseId, categoryId, filter);

      let realImpact;
      if (this.release.state === ReleaseState.Released && this.impactFilter) {
        const productsRealImpacts = await this.releaseStore.getProductsRealImpacts(this.releaseId, {
          categoryIds: [this.categoryId],
          productIds: [productId],
          ...(this.impactFilter as any),
        });
        realImpact = productsRealImpacts.get(productId);
      }
      runInAction(() => {
        this.totalForItemModal = { impact, realImpact, previousImpacts };
        this.items = items;
      });
    } catch (error) {
      this.hideModal();
      // this.messages.setError(error);
    } finally {
      runInAction(() => (this.modalLoadingState = LoadingState.Success));
    }
  };

  /**************************itemOverview modal DATA HANDLING****************************************/
  /**************************SELLOUT ITEM UPDATE****************************************/

  /**
   * Adds update to update queue. Value should be optimistically (and automatically) updated in AG grid.
   *
   * @param updatedField - field type (TAG of SelloutReleaseItemUpdate)
   * @param newValue - new value, that should be sent on BE for specific field
   * @param itemUpdateKeys - List of keys for SelloutReleaseItemUpdateRequest
   */
  @action.bound
  private addUpdateToQueue(updatedField: string, newValue: any, itemUpdateKeys: SelloutReleaseItemKey[], processImmediately = false) {
    // remove same updates
    const updates = this.updateRequest.updates.filter(([key, update]) => (
      !itemUpdateKeys.some(newKey => key.equals(newKey)) || update.tag !== updatedField
    ));

    this.updateRequest = new SelloutReleaseItemUpdateRequest(updates.concat(
      List(itemUpdateKeys.map(key => [key, this.createSelloutReleaseItemUpdate(updatedField, newValue)]))
    ));

    if (processImmediately) {
      this.cancelUpdate();
      this.processUpdateQueue();
    } else {
      this.scheduleUpdate();
    }
  }

  /**
   * Should be called when editing to cancel pending update request.
   */
  private handleEditStart = () => {
    this.isEditing = true;
    this.cancelUpdate();
  }

  /**
   * Should be called when editing ends to reschedule update request.
   */
  private handleEditEnd = () => {
    this.isEditing = false;
    if (this.updateRequest.updates.size > 0) {
      this.scheduleUpdate();
    }
  }

  /**
   * Schedules an batch update.
   */
  private scheduleUpdate() {
    this.cancelUpdate();
    this.updateTimeout = window.setTimeout(this.processUpdateQueue, 3000); // TODO: make configurable, or like a constant
    startBlockingRouteNavigation('Release', 'Changes haven\'t been saved yet. Do you want to leave?');
  }

  /**
   * Schedules an batch update.
   */
  private cancelUpdate() {
    if (this.updateTimeout) {
      window.clearTimeout(this.updateTimeout);
    }
  }

  /**
   * Process update queue (request), makes an API call.
   */
  private processUpdateQueue = async () => {
    this.setUpdating(true);
    const updateRequest = this.updateRequest;
    // Clear update request
    this.updateRequest = new SelloutReleaseItemUpdateRequest(List());

    try {
      // Update Release items on BE
      const updatedItems = await this.releaseStore.updateItems(updateRequest);

      // Update Release items in modal if visible
      runInAction(() => updatedItems.forEach((item) => {
        if (this.items.has(item.id)) {
          this.items.set(item.id, item);
        }
      }));

      // Update impact and values in products table
      const productIds = updateRequest.updates.map(([key]) => key.productId).toArray();
      await this.updateDetailCategoryTotalsAndItems(productIds);
      await this.updateImpact(productIds);

      // Update release entity, to get recalculation warnings on releasePart
      await this.releaseStore.getOne(this.releaseId);
      this.setCategoryWarnings();
    } catch (error) {
      this.handleUpdateError(error);
      this.resetAgGrids();
    } finally {
      this.setUpdating(false);
      this.updateTimeout = null;
      stopBlockingRouteNavigation();
    }
  }

  /**
   * Turns on/of editing loading state - this will disable AG grids.
   * @param b
   */
  private setUpdating(b: boolean) {
    if (b) {
      this.itemOverviewGridApi.showLoadingOverlay();

      if (this.itemsModalGridApi) {
        this.itemsModalGridApi.showLoadingOverlay();
      }
    } else {
      this.itemOverviewGridApi.hideOverlay();

      if (this.itemsModalGridApi) {
        this.itemsModalGridApi.hideOverlay();
      }
    }
  }

  /**
   * This will rerender AG grid tables and flushes user edited values (should be called, when update fail).
   */
  @action
  private resetAgGrids() {
    this.categoryTotals = new Map(this.categoryTotals);
    this.items = new Map(this.items);
  }

  /** Return new instance of SelloutReleaseItemUpdate depending on field type
   * @param updatedField - field type (TAG of SelloutReleaseItemUpdate)
   * @param newValue - new value, that should be sent on BE for specific field
   */
  private createSelloutReleaseItemUpdate = (updatedField: string, newValue: any): SelloutReleaseItemUpdate => {
    let itemUpdateData: SelloutReleaseItemUpdate;
    switch (updatedField) {
      case FinalPriceItemUpdate.TAG:
        itemUpdateData = new FinalPriceItemUpdate(newValue);
        break;
      case ExportedItemUpdate.TAG:
        itemUpdateData = new ExportedItemUpdate(newValue);
        break;
      case CommentItemUpdate.TAG:
        itemUpdateData = new CommentItemUpdate(Utils.isValueMissing(newValue) ? '' : newValue);
        break;
      case EndDateItemUpdate.TAG:
        itemUpdateData = new EndDateItemUpdate(newValue.endOf('d'));
        break;
      case MinPriceChangeItemUpdate.TAG:
        itemUpdateData = new MinPriceChangeItemUpdate(newValue);
        break;
      case MaxPriceChangeItemUpdate.TAG:
        itemUpdateData = new MaxPriceChangeItemUpdate(newValue);
        break;
      case MinMarginChangeItemUpdate.TAG:
        itemUpdateData = new MinMarginChangeItemUpdate(newValue);
        break;
      case PriceCodeItemUpdate.TAG:
        itemUpdateData = new PriceCodeItemUpdate(newValue);
        break;
      default:
        throw new Error(`tag ${updatedField} is not supported yet`);
    }
    return itemUpdateData;
  };

  /** New key for SelloutReleaseItemUpdateRequest depending on siteId */
  private createSelloutReleaseItemKey = (siteId: string = null, productId?: string) => {
    return new SelloutReleaseItemKey(this.releaseId, productId ? productId : this.productDetail.id, siteId);
  };

  /**
   * Returns true, if a cell has been edited and waits for an update.
   *
   * @param productId
   * @param siteId
   * @param updatedField
   */
  private isCellEdited = (productId: string, siteId: string | null, updatedField: string) => {
    return this.updateRequest.updates.some(([key, update]) => (
      key.productId === productId && key.siteId === siteId && update.tag === updatedField
    ));
  }

  private async enforceUpdateIfAny() {
    if (this.updateRequest.updates.size > 0) {
      this.cancelUpdate();
      await this.processUpdateQueue();
    }
  }

  /**************************SELLOUT ITEM UPDATE****************************************/
  /**************************MODAL NAVIGATION****************************************/

  /** Iterate modal product detail data through each release category item */
  handleProductsNavigation = async (e: KeyboardEvent) => {
    if (!this.isEditing) {
      if (e.shiftKey && e.code === 'ArrowLeft') {
        e.stopPropagation();
        e.preventDefault();
        await this.prevProduct();
      } else if (e.shiftKey && e.code === 'ArrowRight') {
        e.stopPropagation();
        e.preventDefault();
        await this.nextProduct();
      }
    }
  };

  /** Updates viewed internal product id */
  async productNavigation(rowNode: RowNode) {
    await this.enforceUpdateIfAny();

    if (rowNode) {
      runInAction(() => {
        this.activeProductRowIndex = rowNode.childIndex;
      });
      await this.updateItemsOverviewModalData(rowNode.data.ReleaseDetailCategory_productId);
    }
  }

  public get isPrevProductAvailable() {
    return this.activeProductRowIndex !== 0;
  }

  public get isNextProductAvailable() {
    return this.itemOverviewGridApi
      ? this.activeProductRowIndex + 1 !== this.itemOverviewGridApi.getDisplayedRowCount()
      : false;
  }

  public prevProduct = async () => {
    if (this.isPrevProductAvailable) {
      await this.productNavigation(this.itemOverviewGridApi.getDisplayedRowAtIndex(this.activeProductRowIndex - 1));
    }
  };

  public nextProduct = async () => {
    if (this.isNextProductAvailable) {
      await this.productNavigation(this.itemOverviewGridApi.getDisplayedRowAtIndex(this.activeProductRowIndex + 1));
    }
  };

  /**************************MODAL NAVIGATION****************************************/
  /**************************LOADING****************************************/

  /** @inheritDoc */
  public onImpactFilter = async (filterValues: IReleaseImpactFilter): Promise<void> => {
    try {
      runInAction(() => (this.impactFilter = filterValues));
      const items = await this.releaseStore.getItemsWithImpact(this.release.id, {
        categoryIds: [this.categoryId],
        ...this.impactFilter,
      });
      runInAction(() => (this.items = items));
    } catch (error) {
      // this.messages.setError(error);
    }
  };

  /** @inheritDocs */
  @computed
  public get productIds() {
    return List(Array.from(this.categoryTotals, ([, value]) => value.productId));
  }

  /** @inheritDocs */
  protected handleBasicRequest = async () => {
    try {
      await this.releaseStore.getOne(this.releaseId);
      const [sites, categoryTotalsItems] = await Promise.all([
        this.releaseStore.getSitesAsCodebooks(),
        this.fetchCategoryTotalsItems(),
        this.fetchPriceCodeOptions(),
        this.productCategoryStore.loadForSelloutRelease(this.releaseId),
      ]);
      await this.fetchCategoryTotals();
      const productsIds = Array.from(categoryTotalsItems, ([, value]) => value.productId);
      if (this.release.state === ReleaseState.Released && this.impactFilter) {
        const productsRealImpact = await this.releaseStore.getProductsRealImpacts(this.releaseId, {
          categoryIds: [this.categoryId],
          productIds: productsIds,
          ...(this.impactFilter as any),
        });
        runInAction(() => (this.productsRealImpact = productsRealImpact));
      }
      runInAction(() => (this.categoryTotals = categoryTotalsItems));
      await this.fetchItemProducts(productsIds);
      this.sites = sites;
    } catch (error) {
      await Promise.reject(error);
    }
  };

  /**
   * Fetches release category total data for detail category page
   */
  private fetchCategoryTotals = async (): Promise<void> => {
    /** Promises in case if component used for detail category page */
    if (this.categoryId) {
      const totalFilter = { releasePartCategoryIds: [this.categoryId] };
      const getItemsWithImpactFilter = { ...totalFilter, ...this.impactFilter };
      const totals =
        this.release.state === ReleaseState.Released && this.impactFilter
          ? await this.releaseStore.getTotalWithImpact(this.release.id, getItemsWithImpactFilter)
          : await this.releaseStore.getTotal(this.release.id, totalFilter);
      runInAction(() => (this.totals = totals));
      this.totalWithActions = this.totals.get(this.categoryId);
    }
  };

  /** Fetches category totals for the page */
  private fetchCategoryTotalsItems = async (filter?: FindTotalFilter) => {
    return this.categoryId
      ? this.releaseStore.getCategoryTotals(this.releaseId, this.categoryId, filter)
      : this.releaseStore.getExportedCategoryTotals(this.releaseId, {
        ...filter,
        releasePartCategoryIds: this.approvedCategories.length ? this.approvedCategories : this.allCategories,
      });
  };

  /** Navigates to family page */
  private navigateToFamily = (params: CellClickedEvent) => {
    if (params.value) {
      this.history.push(params.value.linkProps.to);
    }
  };
}
