/**
 * @file Created on Wed Oct 03 2018
 * @author PKl
 */
import ProgressBarStyled from './styled/ProgressBarStyled';
import * as React from 'react';
import translate from '../../i18n/translate';
/**
 * ProgressBar
 * @component
 */

var ProgressBar = function ProgressBar(props) {
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: 'mb1'
  }, props.label && React.createElement("strong", null, translate(props.label), " (", props.percentage, "%)")), React.createElement(ProgressBarStyled, Object.assign({}, props), React.createElement("div", {
    className: "status-bar-inner",
    style: {
      width: "".concat(props.percentage, "%")
    }
  }, "\xA0")));
};

export default ProgressBar;