import _toConsumableArray from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import * as React from 'react';
import rootStore from '../../../shared/stores/RootStore';
import { StoreName } from '../../../shared/stores/StoreName';
import { ReleaseItemMessageCode, ReleaseItemRepricingMessageCode, ResultSeverity, Utils } from '@logio/common-be-fe';
/**
 * Finds translation in dictionary or edits or return key or showKeys mode
 * @param key translation key
 * @returns string
 */

var getString = function getString(key) {
  var _rootStore$getStore = rootStore.getStore(StoreName.Dictionary),
      localizations = _rootStore$getStore.localizations,
      edits = _rootStore$getStore.edits,
      editMode = _rootStore$getStore.editMode,
      showKeys = _rootStore$getStore.showKeys,
      language = _rootStore$getStore.language;

  if (!key) {
    return null;
  } // Show keys mode is active


  if (!editMode && showKeys) {
    return key;
  }

  var translation = key; // Found key in dictionary

  if (localizations[language] && localizations[language][key] !== undefined) {
    translation = localizations[language][key];
  } // Found key in edits


  if (edits[language] && edits[language][key] !== undefined) {
    translation = edits[language][key];
  }

  return translation;
};

var getQueryParam = function getQueryParam(value) {
  return Utils.isValueMissing(value) ? '' : value;
};
/** Gets localized page path */


export var getPath = function getPath(path) {
  for (var _len = arguments.length, substitutions = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    substitutions[_key - 1] = arguments[_key];
  }

  var i = -1;
  return translate(path).replace(/\/:([^\/]*)\/|\/:([^\/]*)/g, function (str) {
    i++;
    var value = getQueryParam(substitutions[i]);
    return str[str.length - 1] === '/' ? "/".concat(value, "/") : "/".concat(value);
  });
};
/**
 *
 * @param str translation
 */

var tokenizeString = function tokenizeString(str) {
  return str.split(/\{\d+\}/);
};
/**
 *
 * @param str translation
 */


var getKeys = function getKeys(str) {
  var keys = str.match(/\{\d+\}/g);

  if (keys == null) {
    return [];
  }

  return keys.map(function (key) {
    return Number(key.slice(1, -1));
  });
};
/**
 * Translates key and allows to replace {0} placeholders with string values.
 * Placeholders are replaced by translated strings - translation is recursive.
 * If the placeholder points outside of the array of substitutions then
 * it will be replaced by empty string.
 * @param key translation key
 * @param substitutions replace pladeholders in translation
 */


var translate = function translate(key) {
  for (var _len2 = arguments.length, substitutions = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    substitutions[_key2 - 1] = arguments[_key2];
  }

  var translation = getString(key);

  if (Utils.isString(translation)) {
    return translation.replace(/\{\d+\}/g, function (str) {
      var index = Number(str.slice(1, -1));
      return 0 <= index && index < substitutions.length ? translate(substitutions[index]) : '';
    });
  } else {
    return '';
  }
};
/**
 * Translates key and allows to replace {0} placeholders with elements
 * @param key translation key
 * @param substitutions replace pladeholders in translation
 */


export var translateWithElements = function translateWithElements(key) {
  var translation = getString(key);
  var tokens = tokenizeString(translation);
  var keys = getKeys(translation);
  var elements = [];

  for (var _len3 = arguments.length, substitutions = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
    substitutions[_key3 - 1] = arguments[_key3];
  }

  for (var i = 0; i < tokens.length; i++) {
    elements.push(tokens[i]);

    if (substitutions[keys[i]]) {
      elements.push(substitutions[keys[i]]);
    }
  }

  return React.createElement.apply(React, ['span', null].concat(elements));
};
export var isTranslationExists = function isTranslationExists(key) {
  var _rootStore$getStore2 = rootStore.getStore(StoreName.Dictionary),
      localizations = _rootStore$getStore2.localizations,
      language = _rootStore$getStore2.language;

  return localizations[language] !== undefined && localizations[language][key] !== undefined;
};
/**
 * Translates result object(part of validation result)
 * @param resultObject {ValidationResultObject} part of validation result
 */

export var translateResultObject = function translateResultObject(resultObject) {
  // FIXME: LOG-5528 custom solution for format messageVariable as date from ValidationResultObject
  if (resultObject.severity === ResultSeverity.Warn && resultObject.messageKey === 'calculatingReleasesLimitExceeded') {
    if (resultObject.messageVariables && resultObject.messageVariables.size === 1) {
      return "Wait until the release <".concat(resultObject.messageVariables.get(0).toString(), "> is opened. Afterward, click on the button: Open.");
    } else if (resultObject.messageVariables && resultObject.messageVariables.size > 1) {
      return "Wait until one of the releases [<".concat(resultObject.messageVariables.join('>, <'), ">] is opened. Afterward, click on the button: Open.");
    } else {
      return "The system is now opening too many releases at once.";
    }
  } else if (resultObject.messageKey === ReleaseItemRepricingMessageCode.ReleaseItemPromoProtectionViolation) {
    var amount = Number(resultObject.messageVariables.get(0)).toString();
    var dateFrom = new Date(resultObject.messageVariables.get(1)).toLocaleDateString();
    var dateTo = new Date(resultObject.messageVariables.get(2)).toLocaleDateString();
    return translate("".concat(resultObject.severity, "_").concat(resultObject.messageKey), undefined) + " <".concat(dateFrom, " - ").concat(dateTo, "; ").concat(amount, ">");
  } else if (resultObject.messageKey === ReleaseItemMessageCode.ReleaseItemConflicted) {
    // message variables are list of items
    return translate("".concat(resultObject.severity, "_").concat(resultObject.messageKey), resultObject.messageVariables.join(', '));
  } else {
    return translate.apply(void 0, ["".concat(resultObject.severity, "_").concat(resultObject.messageKey)].concat(_toConsumableArray(resultObject.messageVariables)));
  }
};
export default translate;