"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ProductChangeType;

(function (ProductChangeType) {
  ProductChangeType["Updated"] = "UPDATED";
  ProductChangeType["Inserted"] = "INSERTED";
  ProductChangeType["Deleted"] = "DELETED";
  ProductChangeType["NotChanged"] = "NOT_CHANGED";
})(ProductChangeType = exports.ProductChangeType || (exports.ProductChangeType = {}));