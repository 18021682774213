/**
 * @file Created on Fri Oct 19 2018
 * @author PKl
 */
import * as React from 'react';
import { showIf } from '../../../shared/rendering/showIf';
import { T } from '../../i18n/T';
import Button from './Button';
/**
 * ButtonLoading
 * @component
 */

var ButtonLoading = function ButtonLoading(props) {
  return React.createElement(React.Fragment, null, showIf(props.isLoading === true, React.createElement(Button, Object.assign({}, props, {
    disabled: true
  }), React.createElement(T, {
    id: "Loading",
    disabled: true
  }), " \u2026")), showIf(props.isLoading !== true, React.createElement(Button, Object.assign({}, props), props.children)));
};

export default ButtonLoading;