import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n\n  + .input-wrapper {\n    margin-top: 5px;\n  }\n\n  &.input-error input {\n    border: 1px solid ", ";\n  }\n\n  &.input-error svg {\n    fill: ", ";\n  }\n\n  .input-icon {\n    position: absolute;\n    cursor: pointer;\n  }\n\n  ", ";\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  .form-horizontal & > input:first-of-type.with-icon-out {\n    width: ", "px;\n  }\n\n  .form-vertical & input:first-of-type {\n    width: calc(100% - 35px);\n  }\n\n  .input-icon {\n    top: 8px;\n    right: 0;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  input {\n    padding-right: 35px;\n  }\n\n  .input-icon {\n    top: 8px;\n    right: 10px;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Thu Jul 12 2018
 * @author PKl
 */
import styled, { withTheme, css } from 'styled-components';
var InputWithIconIn = css(_templateObject());
var InputWithIconOut = css(_templateObject2(), function (props) {
  return props.theme.form.inputWidth - 35;
});
/**
 * Create an wrapper for Input
 */

var InputWrapper = styled.div(_templateObject3(), function (props) {
  return props.theme.state.danger.color;
}, function (props) {
  return props.theme.state.danger.color;
}, function (props) {
  return props.withIconIn ? InputWithIconIn : null;
}, function (props) {
  return props.withIconOut ? InputWithIconOut : null;
});
export default withTheme(InputWrapper);