import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  /* padding: 0 ", "rem; */\n  padding-bottom: ", "rem;\n  font-size: 80%;\n  font-weight: bold;\n  text-transform: uppercase;\n\n\n  h3{\n    margin:0;\n  }\n\n  & > * {\n    vertical-align: bottom;\n  }\n\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Mon Oct 22 2018
 * @author PKl
 */
import styled, { withTheme } from 'styled-components';
/**
 * Create an NoticeTitles component that'll render an <NoticeTitles>
 * @author PKl
 */

var NoticeTitlesStyled = styled.div(_templateObject(), function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.theme.spacing.spacer / 2;
});
export default withTheme(NoticeTitlesStyled);