/**
 * @file Created on Tue Apr 09 2019
 * @author SKu
 */

import {LoadingState, PageStore} from '@logio/common-fe';
import {History} from 'history';
import {SelloutIdentificationStore} from '../../../components';

export class SelloutIdentificationReportPageStore extends PageStore {
  /**
   * @param history - fromm react router
   */
  constructor(public history: History) {
    super();
  }

  selloutIdentificationStore = new SelloutIdentificationStore(this.messages, this.history);

  /** Fetches data sellout identification component */
  public load = async () => {
    try {
      await this.selloutIdentificationStore.load();
      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      this.setLoadingState(LoadingState.Error);
      // this.messages.setError(error);
    }
  };
}
