"use strict";

var _classCallCheck = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var Permission_1 = require("./Permission"); // tslint:disable:max-line-length
// tslint:disable:prettier

/**
 * Well-known pricing permissions (use-cases).
 * These permissions must correspond to the roles imported to the KeyCloak server (see importPermissions.ts).
 * When new permission is added, it should be added also in KeyCloak server.
 *
 * Each permission role is then mapped to a composite role - regular realm roles,
 * (each application role has list of "more detailed" roles - permissions).
 *
 * @author Radek Beran
 * @author MSu
 */


var PromoPermissions = function PromoPermissions() {
  _classCallCheck(this, PromoPermissions);
};

exports.PromoPermissions = PromoPermissions; // IMPORTED PERMISSIONS
// APPLICATION

PromoPermissions.APPLICATION_LOCKED_ACCESS = new Permission_1.Permission('application-locked-access'); // CREATE

PromoPermissions.CREATE_PROMOTION_ACCESS_SCREEN = new Permission_1.Permission('create-promotion-access-screen');
PromoPermissions.CREATE_THEME_ACCESS_SCREEN = new Permission_1.Permission('create-theme-access-screen');
PromoPermissions.CREATE_WINDOW_ACCESS_SCREEN = new Permission_1.Permission('create-window-access-screen');
PromoPermissions.CREATE_WINDOW_ALL_ASSORTMENT = new Permission_1.Permission('create-window-all-assortment');
PromoPermissions.CREATE_WINDOW_PROMOTION_SELECTION_ACTIVE = new Permission_1.Permission('create-window-promotion-selection-active');
PromoPermissions.CREATE_WINDOW_PROMOTION_SELECTION_OTHER = new Permission_1.Permission('create-window-promotion-selection-other'); // DUMMY

PromoPermissions.DUMMY_WINDOWS_ACCESS_SCREEN = new Permission_1.Permission('dummy-windows-access-screen'); // GENERAL

PromoPermissions.GENERAL_CHERRY_PICKING_SETTINGS_ACCESS_SCREEN = new Permission_1.Permission('general-cherry-picking-settings-access-screen');
PromoPermissions.GENERAL_REPLENISHMENT_SETTINGS_ACCESS_SCREEN = new Permission_1.Permission('general-replenishment-settings-access-screen');
PromoPermissions.GENERAL_ROUNDING_RULES_SETTINGS_ACCESS_SCREEN = new Permission_1.Permission('general-rounding-rules-settings-access-screen');
PromoPermissions.GENERAL_SYSTEM_VOLUME_THRESHOLD_SETTINGS_ACCESS_SCREEN = new Permission_1.Permission('general-system-volume-threshold-settings-access-screen');
PromoPermissions.GENERAL_THRESHOLD_SETTINGS_ACCESS_SCREEN = new Permission_1.Permission('general-threshold-settings-access-screen');
PromoPermissions.GENERAL_WINDOW_STATE_SETTINGS_ACCESS_SCREEN = new Permission_1.Permission('general-window-state-settings-access-screen'); // IMPORT

PromoPermissions.IMPORT_WINDOWS_ACCESS_SCREEN = new Permission_1.Permission('import-windows-access-screen'); // LOCKED

PromoPermissions.LOCKED_PROMOTIONS_AND_WINDOWS_ACCESS_SCREEN = new Permission_1.Permission('locked-promotions-and-windows-access-screen'); // NOTIFICATION

PromoPermissions.NOTIFICATION_RECEIVE_ALWAYS_WINDOW_CHANGED = new Permission_1.Permission('notification-receive-always-window-changed');
PromoPermissions.NOTIFICATION_RECEIVE_ALWAYS_WINDOW_DELETED_BY_CM = new Permission_1.Permission('notification-receive-always-window-deleted-by-cm');
PromoPermissions.NOTIFICATION_RECEIVE_ALWAYS_WINDOW_DUMMY_CREATED = new Permission_1.Permission('notification-receive-always-window-dummy-created');
PromoPermissions.NOTIFICATION_RECEIVE_ALWAYS_WINDOW_PROMO_VOLUME_CHANGED = new Permission_1.Permission('notification-receive-always-window-promo-volume-changed');
PromoPermissions.NOTIFICATION_RECEIVE_ALWAYS_WINDOW_REJECTED_BY_CD = new Permission_1.Permission('notification-receive-always-window-rejected-by-cd');
PromoPermissions.NOTIFICATION_RECEIVE_ALWAYS_WINDOW_REJECTED_BY_PM = new Permission_1.Permission('notification-receive-always-window-rejected-by-pm');
PromoPermissions.NOTIFICATION_RECEIVE_ALWAYS_WINDOW_SIMULATION_APPLIED = new Permission_1.Permission('notification-receive-always-window-simulation-applied');
PromoPermissions.NOTIFICATION_RECEIVE_ALWAYS_WINDOW_THRESHOLD_REACHED = new Permission_1.Permission('notification-receive-always-window-threshold-reached');
PromoPermissions.NOTIFICATION_RECEIVE_REPLENISHMENT_THRESHOLD_REACHED = new Permission_1.Permission('notification-receive-replenishment-threshold-reached');
PromoPermissions.NOTIFICATION_RECEIVE_WINDOW_DUMMY_CREATED = new Permission_1.Permission('notification-receive-window-dummy-created');
PromoPermissions.NOTIFICATION_RECEIVE_WINDOW_PROMO_VOLUME_CHANGED = new Permission_1.Permission('notification-receive-window-promo-volume-changed');
PromoPermissions.NOTIFICATION_RECEIVE_WINDOW_REJECTED_BY_CD = new Permission_1.Permission('notification-receive-window-rejected-by-cd');
PromoPermissions.NOTIFICATION_RECEIVE_WINDOW_REJECTED_BY_PM = new Permission_1.Permission('notification-receive-window-rejected-by-pm');
PromoPermissions.NOTIFICATION_RECEIVE_WINDOW_THEME_REMOVED = new Permission_1.Permission('notification-receive-window-theme-removed'); // NOTIFICATIONS

PromoPermissions.NOTIFICATIONS_ACCESS_SCREEN = new Permission_1.Permission('notifications-access-screen'); // PROMOTION

PromoPermissions.PROMOTION_AREA_MANAGEMENT = new Permission_1.Permission('promotion-area-management');
PromoPermissions.PROMOTION_AREA_VIEW = new Permission_1.Permission('promotion-area-view');
PromoPermissions.PROMOTION_DETAIL_DETAIL_ACCESS_SCREEN = new Permission_1.Permission('promotion-detail-detail-access-screen');
PromoPermissions.PROMOTION_DETAIL_DETAIL_ACCESS_WORKFLOW_WORK_ACTIVE = new Permission_1.Permission('promotion-detail-detail-access-workflow-work-active');
PromoPermissions.PROMOTION_DETAIL_DETAIL_ACCESS_WORKFLOW_WORK_APPROVED = new Permission_1.Permission('promotion-detail-detail-access-workflow-work-approved');
PromoPermissions.PROMOTION_DETAIL_DETAIL_ACCESS_WORKFLOW_WORK_CANCELED = new Permission_1.Permission('promotion-detail-detail-access-workflow-work-canceled');
PromoPermissions.PROMOTION_DETAIL_DETAIL_ACCESS_WORKFLOW_WORK_CLOSED = new Permission_1.Permission('promotion-detail-detail-access-workflow-work-closed');
PromoPermissions.PROMOTION_DETAIL_DETAIL_ACCESS_WORKFLOW_WORK_CONCEPT = new Permission_1.Permission('promotion-detail-detail-access-workflow-work-concept');
PromoPermissions.PROMOTION_DETAIL_DETAIL_ACCESS_WORKFLOW_WORK_EVALUATED = new Permission_1.Permission('promotion-detail-detail-access-workflow-work-evaluated');
PromoPermissions.PROMOTION_DETAIL_DETAIL_ACCESS_WORKFLOW_WORK_FINAL = new Permission_1.Permission('promotion-detail-detail-access-workflow-work-final');
PromoPermissions.PROMOTION_DETAIL_DETAIL_ACCESS_WORKFLOW_WORK_FINISHED = new Permission_1.Permission('promotion-detail-detail-access-workflow-work-finished');
PromoPermissions.PROMOTION_DETAIL_DETAIL_ACCESS_WORKFLOW_WORK_OPEN = new Permission_1.Permission('promotion-detail-detail-access-workflow-work-open'); // PROMOTIONS

PromoPermissions.PROMOTIONS_LIST_ACCESS_SCREEN = new Permission_1.Permission('promotions-list-access-screen');
PromoPermissions.PROMOTIONS_LIST_ADMIN_QUICK_FILTER = new Permission_1.Permission('promotions-list-admin-quick-filter');
PromoPermissions.PROMOTIONS_LIST_ALL_DATA = new Permission_1.Permission('promotions-list-all-data');
PromoPermissions.PROMOTIONS_LIST_CREATE_PROMOTION = new Permission_1.Permission('promotions-list-create-promotion');
PromoPermissions.PROMOTIONS_LIST_FILTERED_DATA_CD = new Permission_1.Permission('promotions-list-filtered-data-cd');
PromoPermissions.PROMOTIONS_LIST_FILTERED_DATA_SP = new Permission_1.Permission('promotions-list-filtered-data-sp'); // REPORT

PromoPermissions.REPORT_ALL_ASSORTMENT = new Permission_1.Permission('report-all-assortment');
PromoPermissions.REPORT_APPROVAL_CD_ACCESS_SCREEN = new Permission_1.Permission('report-approval-cd-access-screen');
PromoPermissions.REPORT_APPROVAL_PM_ACCESS_SCREEN = new Permission_1.Permission('report-approval-pm-access-screen');
PromoPermissions.REPORT_FORECAST_CHANGES_ACCESS_SCREEN = new Permission_1.Permission('report-forecast-changes-access-screen');
PromoPermissions.REPORT_FORECAST_COORDINATOR_ACCESS_SCREEN = new Permission_1.Permission('report-forecast-coordinator-access-screen');
PromoPermissions.REPORT_FORECAST_COORDINATOR_DETAIL_ACCESS_SCREEN = new Permission_1.Permission('report-forecast-coordinator-detail-access-screen');
PromoPermissions.REPORT_FORECAST_SIMULATION_ACCESS_SCREEN = new Permission_1.Permission('report-forecast-simulation-access-screen');
PromoPermissions.REPORT_FORECAST_SIMULATION_DETAIL_ACCESS_SCREEN = new Permission_1.Permission('report-forecast-simulation-detail-access-screen');
PromoPermissions.REPORT_METADATA_HIERARCHY_ACCESS_SCREEN = new Permission_1.Permission('report-metadata-hierarchy-access-screen');
PromoPermissions.REPORT_METADATA_NEWS_ACCESS_SCREEN = new Permission_1.Permission('report-metadata-news-access-screen');
PromoPermissions.REPORT_METADATA_RELATIONS_ACCESS_SCREEN = new Permission_1.Permission('report-metadata-relations-access-screen');
PromoPermissions.REPORT_METADATA_SELLOUTS_ACCESS_SCREEN = new Permission_1.Permission('report-metadata-sellouts-access-screen');
PromoPermissions.REPORT_PROMOTION_PERFORMANCE_ACCESS_SCREEN = new Permission_1.Permission('report-promotion-performance-access-screen');
PromoPermissions.REPORT_PROMOTION_PERFORMANCE_DETAIL_ACCESS_SCREEN = new Permission_1.Permission('report-promotion-performance-detail-access-screen');
PromoPermissions.REPORT_RPM_ACCESS_SCREEN = new Permission_1.Permission('report-rpm-access-screen');
PromoPermissions.REPORT_RPM_DETAIL_ACCESS_SCREEN = new Permission_1.Permission('report-rpm-detail-access-screen');
PromoPermissions.REPORT_SALES_DASHBOARD_ACCESS_SCREEN = new Permission_1.Permission('report-sales-dashboard-access-screen');
PromoPermissions.REPORT_SALESDASHBOARD_EXPORT_ACCESS_SCREEN = new Permission_1.Permission('report-salesdashboard-export-access-screen');
PromoPermissions.REPORT_SYSTEMADMIN_FORMS_ACCESS_SCREEN = new Permission_1.Permission('report-systemadmin-forms-access-screen');
PromoPermissions.REPORT_WINDOW_PERFORMANCE_ACCESS_SCREEN = new Permission_1.Permission('report-window-performance-access-screen');
PromoPermissions.REPORT_WINDOW_PERFORMANCE_DETAIL_WORKFLOW_ACTIONS = new Permission_1.Permission('report-window-performance-detail-workflow-actions'); // SEGMENTATION

PromoPermissions.SEGMENTATION_MODEL_SETTINGS_ACCESS_SCREEN = new Permission_1.Permission('segmentation-model-settings-access-screen'); // SHARED

PromoPermissions.SHARED_GRID_SETTINGS_EDIT = new Permission_1.Permission('shared-grid-settings-edit');
PromoPermissions.SHARED_UPDATE_REPLE_IN_WORKFLOW_CHANGED_SNAPSHOTS = new Permission_1.Permission('shared-update-reple-in-workflow-changed-snapshots'); // SWITCH

PromoPermissions.SWITCH_USER_ACCESS_SCREEN = new Permission_1.Permission('switch-user-access-screen'); // SYSTEMADMIN

PromoPermissions.SYSTEMADMIN_DP_TASK_STATUS_ACCESS_SCREEN = new Permission_1.Permission('systemadmin-dp-task-status-access-screen');
PromoPermissions.SYSTEMADMIN_ERROR_DEBUG_API_ACCESS_SCREEN = new Permission_1.Permission('systemadmin-error-debug-api-access-screen');
PromoPermissions.SYSTEMADMIN_IMPORT_STATUS_ACCESS_SCREEN = new Permission_1.Permission('systemadmin-import-status-access-screen');
PromoPermissions.SYSTEMADMIN_NIGHTLY_RECALCULATION_CONFIG_ACCESS_SCREEN = new Permission_1.Permission('systemadmin-nightly-recalculation-config-access-screen');
PromoPermissions.SYSTEMADMIN_PROMO_OPT_LEARNING_ACCESS_SCREEN = new Permission_1.Permission('systemadmin-promo-opt-learning-access-screen');
PromoPermissions.SYSTEMADMIN_QUEUE_PROMO_EXPORT_ACCESS_SCREEN = new Permission_1.Permission('systemadmin-queue-promo-export-access-screen');
PromoPermissions.SYSTEMADMIN_QUEUE_PROMO_EXPORT_PLID_ACCESS_SCREEN = new Permission_1.Permission('systemadmin-queue-promo-export-plid-access-screen');
PromoPermissions.SYSTEMADMIN_QUEUE_PROMO_FORECAST_EXPORT_ACCESS_SCREEN = new Permission_1.Permission('systemadmin-queue-promo-forecast-export-access-screen'); // THEME

PromoPermissions.THEME_DETAIL_ACCESS_SCREEN = new Permission_1.Permission('theme-detail-access-screen');
PromoPermissions.THEME_LIST_ACCESS_SCREEN = new Permission_1.Permission('theme-list-access-screen'); // USER

PromoPermissions.USER_PROFILE_ACCESS_SCREEN = new Permission_1.Permission('user-profile-access-screen'); // WINDOW

PromoPermissions.WINDOW_CONCEPT_PREPARED_CHANGED_WORK = new Permission_1.Permission('window-concept-prepared-changed-work');
PromoPermissions.WINDOW_DETAIL_DETAIL_ACCESS_SCREEN = new Permission_1.Permission('window-detail-detail-access-screen');
PromoPermissions.WINDOW_DETAIL_DOCUMENTS_ACCESS_SCREEN = new Permission_1.Permission('window-detail-documents-access-screen');
PromoPermissions.WINDOW_DETAIL_HISTORY_WINDOWS_ACCESS_SCREEN = new Permission_1.Permission('window-detail-history-windows-access-screen');
PromoPermissions.WINDOW_DETAIL_KPI_ACCESS_SCREEN = new Permission_1.Permission('window-detail-kpi-access-screen');
PromoPermissions.WINDOW_DETAIL_PRODUCTS_ACCESS_SCREEN = new Permission_1.Permission('window-detail-products-access-screen');
PromoPermissions.WINDOW_DETAIL_PRODUCTS_ADD_SUBSTITUTE_PRODUCT_ACTION = new Permission_1.Permission('window-detail-products-add-substitute-product-action');
PromoPermissions.WINDOW_DETAIL_PRODUCTS_COMPUTE_VOLUME_WITH_LOG = new Permission_1.Permission('window-detail-products-compute-volume-with-log');
PromoPermissions.WINDOW_DETAIL_PRODUCTS_MEMBER_CREDITS_COLUMNS = new Permission_1.Permission('window-detail-products-member-credits-columns');
PromoPermissions.WINDOW_DETAIL_PRODUCTS_SUPPLIER_MANIPULATION_LIMIT_RULE = new Permission_1.Permission('window-detail-products-supplier-manipulation-limit-rule');
PromoPermissions.WINDOW_DETAIL_PRODUCTS_TV_COMMERCIAL_COLUMN = new Permission_1.Permission('window-detail-products-tv-commercial-column');
PromoPermissions.WINDOW_DETAIL_SHOW_SIMULATED_VALUES = new Permission_1.Permission('window-detail-show-simulated-values');
PromoPermissions.WINDOW_ENABLE_IGNORE_FOR_TRAINING = new Permission_1.Permission('window-enable-ignore-for-training');
PromoPermissions.WINDOW_TYPE_ACCESS_SCREEN = new Permission_1.Permission('window-type-access-screen'); // WINDOWS

PromoPermissions.WINDOWS_LIST_ACCESS_SCREEN = new Permission_1.Permission('windows-list-access-screen');
PromoPermissions.WINDOWS_LIST_ALL_DATA = new Permission_1.Permission('windows-list-all-data');
PromoPermissions.WINDOWS_LIST_COPY_WINDOWS = new Permission_1.Permission('windows-list-copy-windows');
PromoPermissions.WINDOWS_LIST_CREATE_WINDOW = new Permission_1.Permission('windows-list-create-window');
PromoPermissions.WINDOWS_LIST_FILTERED_DATA_ALL_USERS = new Permission_1.Permission('windows-list-filtered-data-all-users');
PromoPermissions.WINDOWS_LIST_MOVE_WINDOWS = new Permission_1.Permission('windows-list-move-windows'); // WORKFLOW

PromoPermissions.WORKFLOW_ADMINISTRATION = new Permission_1.Permission('workflow-administration'); // WORKFLOW BASE PERMISSIONS

PromoPermissions.WINDOW_WORKFLOW_LOCK_ASSIGNEE = new Permission_1.Permission('window-workflow-lock-assignee-<suffix>');
PromoPermissions.WINDOW_REJECT_WORK = new Permission_1.Permission('window-reject-work-<suffix>');
PromoPermissions.WINDOW_INCOMPLETE_WORK = new Permission_1.Permission('window-incomplete-work-<suffix>');
PromoPermissions.WINDOW_SESSION_ASSIGNEE = new Permission_1.Permission('window-session-assignee-<suffix>');
PromoPermissions.WINDOW_DETAIL_PRODUCTS_COMPUTE_VOLUME_WORK = new Permission_1.Permission('window-detail-products-compute-volume-work-<suffix>');
PromoPermissions.WINDOW_SAVE_WORK = new Permission_1.Permission('window-save-work-<suffix>');
PromoPermissions.WINDOW_CANCEL_WORK = new Permission_1.Permission('window-cancel-work-<suffix>');
PromoPermissions.WINDOW_CHANGED_WORK = new Permission_1.Permission('window-changed-work-<suffix>');
PromoPermissions.WINDOW_APPROVE_BY_FC_WORK = new Permission_1.Permission('window-approve-by-fc-work-<suffix>');
PromoPermissions.REPORT_RPM_DETAIL_EDITABLE_WINDOW_WF = new Permission_1.Permission('report-rpm-detail-editable-window-wf-<suffix>');
PromoPermissions.PROMOTION_OPEN_WORK = new Permission_1.Permission('promotion-open-work-<suffix>');
PromoPermissions.PROMOTION_UPDATE_WORK = new Permission_1.Permission('promotion-update-work-<suffix>');
PromoPermissions.WINDOW_RESTORE_WORK = new Permission_1.Permission('window-restore-work-<suffix>');
PromoPermissions.PROMOTION_WORKFLOW_LOCK_ASSIGNEE = new Permission_1.Permission('promotion-workflow-lock-assignee-<suffix>');
PromoPermissions.WINDOW_APPROVE_WORK = new Permission_1.Permission('window-approve-work-<suffix>');
PromoPermissions.PROMOTION_CLOSE_WORK = new Permission_1.Permission('promotion-close-work-<suffix>');
PromoPermissions.WINDOW_DETAIL_PRODUCTS_EDIT_ALL_COLUMNS_WWF = new Permission_1.Permission('window-detail-products-edit-all-columns-wwf-<suffix>');
PromoPermissions.PROMOTION_FINAL_WORK = new Permission_1.Permission('promotion-final-work-<suffix>');
PromoPermissions.WINDOW_DETAIL_PRODUCTS_DELETE_PRODUCT_ACTION_WORK = new Permission_1.Permission('window-detail-products-delete-product-action-work-<suffix>');
PromoPermissions.WINDOW_RECREATE_WORK = new Permission_1.Permission('window-recreate-work-<suffix>');
PromoPermissions.WINDOW_CONCEPT_WORK = new Permission_1.Permission('window-concept-work-<suffix>');
PromoPermissions.WINDOW_DETAIL_PRODUCTS_EDIT_REPLENISHMENT_COLUMNS_WWF = new Permission_1.Permission('window-detail-products-edit-replenishment-columns-wwf-<suffix>');
PromoPermissions.WINDOW_DETAIL_PRODUCTS_ADD_PRODUCT_ACTION_WORK = new Permission_1.Permission('window-detail-products-add-product-action-work-<suffix>');
PromoPermissions.WINDOW_PREPARE_WORK = new Permission_1.Permission('window-prepare-work-<suffix>');
PromoPermissions.WINDOW_DELETE_WORK = new Permission_1.Permission('window-delete-work-<suffix>');
PromoPermissions.REPORT_FS_DETAIL_EDITABLE_WINDOW_WF = new Permission_1.Permission('report-fs-detail-editable-window-wf-<suffix>');
PromoPermissions.WINDOW_UPDATE_WORK = new Permission_1.Permission('window-update-work-<suffix>');
PromoPermissions.WINDOW_ENABLE_PROMO_VOLUME_WORK = new Permission_1.Permission('window-enable-promo-volume-work-<suffix>');
PromoPermissions.PROMOTION_CANCEL_WORK = new Permission_1.Permission('promotion-cancel-work-<suffix>');
PromoPermissions.PROMOTION_APPROVE_WORK = new Permission_1.Permission('promotion-approve-work-<suffix>');
PromoPermissions.WINDOW_DETAIL_PRODUCTS_EDIT_SHELF_PLANER_COLUMNS_WWF = new Permission_1.Permission('window-detail-products-edit-shelf-planer-columns-wwf-<suffix>');