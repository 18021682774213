"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Message keys related to ReleaseItemRepricing.
 */

var ReleaseItemRepricingMessageCode;

(function (ReleaseItemRepricingMessageCode) {
  /**
   * This product does not have start of price validity set. This cannot be exported to IDB.
   */
  ReleaseItemRepricingMessageCode["ReleaseItemStartDateMissing"] = "ReleaseItemStartDateMissing";
  /**
   * This product has price validity start date set before tomorrow. This cannot be exported to IDB.
   */

  ReleaseItemRepricingMessageCode["ReleaseItemStartDateBeforeTomorrow"] = "ReleaseItemStartDateBeforeTomorrow";
  /**
   * This product has the same price as current regular price.
   */

  ReleaseItemRepricingMessageCode["ReleaseItemFinalPriceSameAsRegular"] = "ReleaseItemFinalPriceSameAsRegular";
  ReleaseItemRepricingMessageCode["ReleaseItemEDLPExists"] = "ReleaseItemEDLPExists";
  /**
   * This product is newly listed and has a listing price protection for x days.
   * Arguments:
   * <ol>
   *     <li>A number of days/li>
   * </ol>
   */

  ReleaseItemRepricingMessageCode["ReleaseItemListingPriceProtectionViolation"] = "ReleaseItemListingPriceProtectionViolation";
  ReleaseItemRepricingMessageCode["ReleaseItemBlockedProductCannotBeRepriced"] = "ReleaseItemBlockedProductCannotBeRepriced";
  /**
   * This product has a fixed price (LOG-1602).
   */

  ReleaseItemRepricingMessageCode["ReleaseItemFixedPrice"] = "ReleaseItemFixedPrice";
  /**
   * This product is in promotion (promo protection).
   * Arguments:
   * <ol>
   *     <li>A number of sites in a zone which violates Promo Protection Constraint</li>
   *     <li>Total number of sites in the zone</li>
   * </ol>
   */

  ReleaseItemRepricingMessageCode["ReleaseItemPromoProtectionViolation"] = "ReleaseItemPromoProtectionViolation";
  /**
   * This product cannot be repriced more than x days.
   * Arguments:
   * <ol>
   *     <li>A number of days/li>
   * </ol>
   */

  ReleaseItemRepricingMessageCode["ReleaseItemRepricingFrequencyViolation"] = "ReleaseItemRepricingFrequencyViolation";
  /**
   * This product has a week restriction <name od day>.
   * Arguments:
   * <ol>
   *     <li>List of codes for week days when the repricing is possible separated by comma.</li>
   * </ol>
   */

  ReleaseItemRepricingMessageCode["ReleaseItemWeekdayRestrictionViolation"] = "ReleaseItemWeekdayRestrictionViolation";
})(ReleaseItemRepricingMessageCode = exports.ReleaseItemRepricingMessageCode || (exports.ReleaseItemRepricingMessageCode = {}));