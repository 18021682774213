/**
 * @file Created on Thu May 09 2019
 * @author LZe
 * @author SKu
 */

import {AgGrid, FormGroup} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';
import {ReleaseSelloutDetailCategoryComponentStore} from '../../../stores/components';

export interface Props {
  store: ReleaseSelloutDetailCategoryComponentStore;
  /** Should be true if component used for export page */
  export?: boolean;
  /** Should be true if component used for ReleaseSelloutDetailCategoryItemModal component */
  itemModal?: boolean;
}

/**
 * Release sellout detail category Top table
 */
const ReleaseSelloutDetailCategoryTotalOverview: React.SFC<Props> = observer((props: Props) => {
  /** Sets bold font to needed categories */
  const getRowClass = (params) =>
    params.node.rowIndex === 0 ||
    (params.node.rowIndex + 1 === props.store.totalOverviewRowData.length && props.store.subtotals.size)
      ? 'bg-bold'
      : '';

  const rowData = props.itemModal ? props.store.itemModalTotalOverviewRowData : props.store.totalOverviewRowData;
  const colData = props.itemModal ? props.store.itemModalTotalOverviewColumnDefs : props.store.totalOverviewColumnDefs;

  return (
    <FormGroup>
      <AgGrid
        className={'columns-panel-overflow'}
        rowData={rowData}
        columnDefs={colData}
        height={props.store.getAgGridTotalHeight(rowData.length, 4)}
        gridId={'SelloutCategoryTotalOverview'}
        onGridReady={!props.itemModal ? props.store.onTotalOverviewGridReady : undefined}
        statusBar={null}
        getRowClass={props.export || props.itemModal ? undefined : getRowClass}
      />
    </FormGroup>
  );
});

export default ReleaseSelloutDetailCategoryTotalOverview;
