/**
 * @file Created on Tue Mar 15 2020
 * @author MHl
 */

import * as React from 'react';
import {
  AgGrid,
  AgGridWrapper,
  Page,
  FormGroupInline,
  Button,
  getPath,
  translate,
  ConfirmBox,
  BoxStatus,
  Circle,
  CircleWidth,
  ButtonColor,
  Divider,
  DividerType,
  Tooltip,
  Icon,
  FormGroup,
  IconType,
  IconColor,
} from '@logio/common-fe';
import {Utils} from '@logio/common-be-fe';
import {Observer, observer} from 'mobx-react';
import {
  RegularSiteReleaseDetailPageStore,
  IRouteParams,
} from '../../../stores/pages/Release/ReleaseDetail/RegularSiteReleaseDetailPageStore';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {RouteComponentProps} from 'react-router-dom';

/**
 * Site Release detail page
 */

@observer
class RegularSiteReleaseDetailPage extends React.Component<RouteComponentProps<IRouteParams>> {
  store = new RegularSiteReleaseDetailPageStore(
    this.props.history,
    this.props.match,
    this.props.match.params.releaseId,
  );

  componentWillMount() {
    this.store.load();
  }
  render() {
    return (
      <Observer>
        {() => (
          <Page
            titleKey={this.store.release && this.store.release.name}
            loadingState={this.store.loadingState}
            backButtonPath={getPath(PagePathsEnum.ReleasesRegular)}
          >
            {this.store.release && this.renderHeader()}

            <AgGridWrapper>
              <AgGrid
                getRowNodeId={(data) => data.SiteReleaseItem_id}
                rowData={this.store.releaseItemsRowData}
                columnDefs={this.store.releaseItemsColumnDefinitions}
                gridId="SiteRelease"
                quickView
                onGridReady={this.store.onGridReady}
                showBulkRow={true}
                pinnedTopRowData={[]}
                sizeColumnsToFit
              />
            </AgGridWrapper>

            <ConfirmBox
              hidden={this.store.isConfirmModalHidden}
              title={translate('export-regular-site-release')}
              onAccept={this.store.confirmReleaseExport}
              close={this.store.hideConfirmModal}
              children={<div className="mb1">{`${translate('export-regular-site-release-confirmation')}`}</div>}
            />
            <ConfirmBox
              hidden={this.store.isCancellModalHidden}
              title={translate('cancell-regular-site-release')}
              onAccept={this.store.cancellRelease}
              close={this.store.hideCancellModal}
              children={<div className="mb1">{`${translate('cancell-regular-site-releaseconfirmation')}`}</div>}
            />
          </Page>
        )}
      </Observer>
    );
  }

  renderHeader(): JSX.Element {
    return (
      <BoxStatus>
        {/* This will render downloading modal when exported file is ready, initialized by store.exportToExcell() */}
        {this.store.downloadStore.renderModal()}

        <div className="status-bar-item">
          <Circle color={translate(this.store.release.state)} width={CircleWidth.Big} />
          <span className="ml1 status-bar-value">{translate(this.store.release.state)}</span>
        </div>
        <Divider type={DividerType.StatusVertical} />
        <div className="status-bar-item">
          <Tooltip
            overlay={this.tooltipInfo}
            placement="bottom"
            children={
              <div>
                <Icon iconType={IconType.info} iconColor={IconColor.Text} />
              </div>
            }
          />
          <div className="status-bar-item">
            <Tooltip
              overlay={translate('ReleaseDetail_ExportTooltip')}
              placement="bottom"
              noHeight
              children={
                <div>
                  <Icon
                    iconColor={IconColor.Text}
                    iconType={IconType.exportToExcel}
                    disabled={this.store.exportIsLoading}
                    onClick={() => this.store.exportToExcell()}
                  />
                </div>
              }
            />
          </div>
          <Divider type={DividerType.StatusVertical} />
        </div>
        <div className="flex-grow-1">&nbsp;</div>
        <FormGroupInline>
          <Button
            type="button"
            buttonColor={ButtonColor.Light}
            onClick={this.store.showCancellModal}
            disabled={!this.store.enableHeaderButtons}
          >
            {translate('Cancel')}
          </Button>

          <Button
            type="button"
            buttonColor={ButtonColor.Secondary}
            onClick={this.store.showConfirmModal}
            disabled={!this.store.enableExportButton || !this.store.enableHeaderButtons}
          >
            {translate('action_Sellouts_Termination_confirm-button')}
          </Button>
        </FormGroupInline>
      </BoxStatus>
    );
  }
  get tooltipInfo() {
    return (
      <>
        {this.getTooltipItem(['workflowType'])}
        {this.getTooltipItem(['releaseType'])}
        {this.getTooltipItem(['priceListTypeId'])}
        {this.getTooltipItem(['description'])}
      </>
    );
  }
  public getTooltipItem = (keys: string[]) => {
    let item: any = this.store.release;
    let lastKey: string;
    let propertyExists = true;
    let value;
    for (let key of keys) {
      lastKey = key;
      if (item && key in item) {
        item = item[key];
      } else {
        propertyExists = false;
      }
    }
    if (!propertyExists || Utils.isValueMissing(item)) {
      return null;
    }
    if (lastKey === 'priceListTypeId') {
      value = this.store.priceListTypes.find((t) => t.id === item).title;
      lastKey = 'priceListType';
    } else {
      if (Utils.isMoment(item)) {
        value = item.format('YYYY-MM-DD');
      } else {
        value = item.toString();
      }
    }
    return (
      <FormGroup>
        {translate(`Release_${lastKey}`)}: <strong> {value}</strong>
      </FormGroup>
    );
  };
}

export default RegularSiteReleaseDetailPage;
