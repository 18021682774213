"use strict";
/**
 * @file Created on 02/27/2020
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author Tomas Leity
 */

var ResourceLockResultStatus;

(function (ResourceLockResultStatus) {
  ResourceLockResultStatus["LockAssigned"] = "RESOURCE_LOCK_ASSIGNED";
  ResourceLockResultStatus["LockRemoved"] = "RESOURCE_LOCK_REMOVED";
  ResourceLockResultStatus["LockInUse"] = "RESOURCE_LOCK_IN_USE";
  ResourceLockResultStatus["CannotLock"] = "RESOURCE_CANNOT_LOCK";
})(ResourceLockResultStatus = exports.ResourceLockResultStatus || (exports.ResourceLockResultStatus = {}));