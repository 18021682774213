/**
 * @file Created on Tue Mar 14 2020
 * @author Sve
 */

import * as React from 'react';
import {AgGrid, AgGridWrapper, Page, FormGroupInline, Button, getPath, translate} from '@logio/common-fe';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {Observer, observer} from 'mobx-react';
import {TerminateRegularSitePageStore} from '../../../stores/pages/Terminations/RegularSite/TerminateRegularSitePageStore';
import {Link} from 'react-router-dom';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';

/**
 * End sellout main page
 */
//
@observer
class TerminateRegularSitePage extends React.Component {
  store = new TerminateRegularSitePageStore();

  componentWillMount() {
    this.store.load();
  }

  render() {
    return (
      <Page titleKey={PageNamesEnum.TerminateRegularSite} loadingState={this.store.loadingState}>
        <FormGroupInline>
          <div className="flex-grow-1">&nbsp;</div>
          <FormGroupInline>
            <div className="mr1">
              <Link to={getPath(PagePathsEnum.TerminateRegularSiteForm)}>
                <Button>{translate('UPLOAD_NEW_TERMINATION')}</Button>
              </Link>
            </div>
          </FormGroupInline>
        </FormGroupInline>
        <AgGridWrapper>
          <AgGrid
            rowData={this.store.rowData}
            columnDefs={this.store.columnDefinitions}
            gridId="RegularSiteTerminationsSummary"
            quickView
            sizeColumnsToFit
          />
        </AgGridWrapper>
      </Page>
    );
  }
}

export default TerminateRegularSitePage;
