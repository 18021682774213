"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Enum of possible window origins.
 */

var WindowOriginEnum;

(function (WindowOriginEnum) {
  WindowOriginEnum["Original"] = "ORIGINAL";
  WindowOriginEnum["Simulation"] = "SIMULATION";
  WindowOriginEnum["Snapshot"] = "SNAPSHOT";
})(WindowOriginEnum = exports.WindowOriginEnum || (exports.WindowOriginEnum = {}));