/**
 * @file Created on Fri Aug 17 2018
 * @author VBr
 */

import {
  DataDesc,
  DataPropDesc,
  Family,
  FamilyBinding,
  InternalProductPricesDTO,
  LeadingProduct,
  NewProduct,
  PricingPermissions,
  Product,
  ProductCategory,
  ProductSensitivity,
  RecommendedProduct,
  StructPropBuilder,
  Tier,
  Utils,
} from '@logio/common-be-fe';
import {
  ActionsGenerator,
  ColumnDefinition,
  ColumnGenerator,
  Comparators,
  CONSTANTS,
  EditorNames,
  FamilyStore,
  FormElOption,
  GeneralConfigurationsStore,
  getProductFlags,
  IconColor,
  IconType,
  InternalProductPricesStore,
  KeycloakStore,
  LoadingState,
  logger,
  PageStore,
  PriceZoneStore,
  ProductCategoryStore,
  productFlagsColumnDefinition,
  ProductSensitivityStore,
  ProductStore,
  RendererNames,
  rootStore,
  StoreName,
  StringMapping,
  SupplierStore,
  TierStore,
  translate,
} from '@logio/common-fe';
import { List } from 'immutable';
import { ColumnApi, GridApi, GridReadyEvent, RowNode, RowSelectedEvent } from 'ag-grid-community';
import { IsColumnFuncParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import * as React from 'react';
import { packageSizeHelper } from '../../../../shared/packageSizeHelper';

export interface ModalHiddenProps {
  assignFamily: boolean;
  removeFamily: boolean;
  changeTier: boolean;
}

enum ProductNewEnum {
  'isNewWithRecommendation' = 'IS_NEW_WITH_RECOMMENDATION',
  'isNewWithoutRecommendation' = 'IS_NEW_WITHOUT_RECOMMENDATION',
  'isNotNew' = 'IS_NOT_NEW',
}

export class PriceArchitectureProductsPageStore extends PageStore {
  /** Get dependant stores */
  categories = rootStore.getStore(StoreName.ProductCategory) as ProductCategoryStore;
  keycloakStore = rootStore.getStore(StoreName.Keycloak) as KeycloakStore;
  sensitivities = rootStore.getStore(StoreName.ProductSensitivity) as ProductSensitivityStore;
  families = rootStore.getStore(StoreName.Family) as FamilyStore;
  tiers = rootStore.getStore(StoreName.Tier) as TierStore;
  products = rootStore.getStore(StoreName.Product) as ProductStore;
  generalConfigurationsStore = rootStore.getStore(StoreName.GeneralConfigurations) as GeneralConfigurationsStore;
  supplierStore = rootStore.getStore(StoreName.Supplier) as SupplierStore;
  internalProductPricesStore = rootStore.getStore(StoreName.InternalProductPrices) as InternalProductPricesStore;
  priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;

  // Due to disable click again on accept when removing is in progress
  public removeFamilyIsLoading: boolean = false; // LOG-4545

  /** Derived columns data description builder */
  categoryBuilder = new StructPropBuilder('Category');
  recommendedBuilder = new StructPropBuilder('PriceArchitectureRecommended');

  supplierNameBuilder = new StructPropBuilder('Supplier');

  /** Derived columns data description */
  categoryColumnsDescription: DataDesc = {
    ms5: this.categoryBuilder.str('ms5'),
    box: this.categoryBuilder.str('box'),
  };

  /** Derived columns data description */
  recommendedColumnsDescription: DataDesc = {
    tier: this.recommendedBuilder.str('tier'),
    family: this.recommendedBuilder.str('family'),
  };

  supplierNameColumnDescription: DataDesc = {
    supplierName: this.supplierNameBuilder.str('supplierName'),
  };

  bindingsSchema = Family.schema.bindings.members as DataPropDesc<FamilyBinding>;

  /** Data generators */
  actionsGenerator = new ActionsGenerator();
  productColumnGenerator = new ColumnGenerator<StringMapping<any>>(Product.schema);
  familyColumnGenerator = new ColumnGenerator<Family>(Family.schema);
  internalProductPricesColumnGenerator = new ColumnGenerator<StringMapping<any>>({
    purchasePrice: InternalProductPricesDTO.schema.purchasePrice,
    openPurchasePrice: InternalProductPricesDTO.schema.openPurchasePrice,
  });
  familyBindingsColumnGenerator = new ColumnGenerator<FamilyBinding>(this.bindingsSchema.data);
  recommendedColumnGenerator = new ColumnGenerator<StringMapping<any>>(this.recommendedColumnsDescription);
  productSensitivityColumnGenerator = new ColumnGenerator<ProductSensitivity>(ProductSensitivity.schema);
  categoryColumnGenerator = new ColumnGenerator<StringMapping<any>>(this.categoryColumnsDescription);
  priceZonePricesGenerator: ColumnGenerator<StringMapping<any>>;
  supplierNameColumnGenerator = new ColumnGenerator<StringMapping<any>>(this.supplierNameColumnDescription);

  @observable
  productCategory: ProductCategory;

  @observable
  selectedRows: RowNode[] = [];
  @observable
  selectedTier: string = undefined;
  @observable
  selectedProducts: Product[] = [];
  @observable
  searchText: string = '';
  @observable
  recommended: boolean = false;
  @observable
  new: boolean = false;

  @observable
  gridApi: GridApi;
  @observable
  columnApi: ColumnApi;

  @observable
  modalHidden: StringMapping<boolean> = {
    assignFamily: true,
    removeFamily: true,
    changeTier: true,
  };

  @observable
  userCouldManageFamily: boolean;

  @observable
  afterUpdateproductIds: string[] = [];

  private isPostSortingShowed = false; // LOG-4261

  constructor(public categoryId: string, public externalId?: string) {
    super();
  }

  createPriceZoneGenerator = () => {
    const builder = new StructPropBuilder('PriceZonePricesBuilder');
    const description = {};
    this.priceZoneStore.listNotArchived.forEach(({id, isNational}) => {
        description[`${id}_actualRegularPrice`] = builder.bigNum(`${id}_actualRegularPrice`);
        description[`${id}_actualMarginPrice`] = builder.bigNum(`${id}_actualMarginPrice`);
        description[`${id}_actualMarginPercentage`] = builder.bigNum(`${id}_actualMarginPercentage`);
        description[`${id}_unitPrice`] = builder.bigNum(`${id}_unitPrice`);
    });
    this.priceZonePricesGenerator = new ColumnGenerator<StringMapping<any>>(description);
  };

  /**
   * Return generated column definitions for ag-grid
   */
  @computed
  get columnDefs(): ColumnDefinition[] {
    const tierOptions: FormElOption[] = [];
    this.tiers.list.forEach((tier) => tierOptions.push({label: tier.title, value: tier.id}));

    const sensitivityOptions: FormElOption[] = [];
    this.sensitivities.list.forEach((productSensitivity) =>
      sensitivityOptions.push({label: productSensitivity.title, value: productSensitivity.id}),
    );

    const familyOptions: FormElOption[] = [];
    this.families.list.forEach((family) => familyOptions.push({label: family.name, value: family.id}));

    const priceZonePricesDefinition: ColumnDefinition[] = [];
    this.priceZoneStore.listNotArchived.forEach(({id, name, isNational}) => {
        priceZonePricesDefinition.push(
          {
            field: `${id}_actualRegularPrice`,
            round: true,
            headerName: translate('PriceZonePricesBuilder_actualRegularPrice', name),
          },
          {
            field: `${id}_actualMarginPrice`,
            round: true,
            headerName: translate('PriceZonePricesBuilder_actualMarginPrice', name),
            filterValueGetter: (params) =>
              params.data[params.colDef.colId] ? parseFloat(params.data[params.colDef.colId].toFixed(2)) : null,
            excel: {
              valueFormatter: ({value}) => (value ? parseFloat(value.toFixed(2)) : null),
            },
          },
          {
            field: `${id}_actualMarginPercentage`,
            round: true,
            // LOG-5514
            // cellRendererFramework: Renderers[RendererNames.ArrowRenderer],
            // comparator: Comparators.arrowComparator,
            headerName: translate('PriceZonePricesBuilder_actualMarginPercentage', name),
            filterValueGetter: (params) =>
              params.data[params.colDef.colId] ? parseFloat(params.data[params.colDef.colId].toFixed(2)) : null,
            excel: {
              valueFormatter: ({value}) => (value ? parseFloat(value.toFixed(2)) : null),
            },
          },
          {
            field: `${id}_unitPrice`,
            round: true,
            headerName: translate('PriceZonePricesBuilder_unitPrice', name),
            filterValueGetter: (params) =>
              params.data[params.colDef.colId] ? parseFloat(params.data[params.colDef.colId].toFixed(2)) : null,
            excel: {
              valueFormatter: ({value}) => (value ? parseFloat(value.toFixed(2)) : null),
            },
          },
        );
    });

    /** Each generator return column definition from related data description */
    return [
      this.actionsGenerator.getColumnDefinition(
        {
          headerName: '',
          width: 40,
          suppressMovable: false,
          lockPinned: false,
          lockPosition: true,
        },
      ),
      {
        field: 'Product_new',
        colId: 'Product_new',
        headerName: translate('Product_new'),
        cellRenderer: RendererNames.ActionsRenderer,
        comparator: Comparators.priceArchitectureNewComparator,
        cellClass: 'ag-cell-align-center',
        suppressMenu: true,
        width: 75,
        excel: {
          valueFormatter: ({value}) => value.map((o: any) => (o.icon) ? (o.icon === 'NEWS RECOMMEND') ? '⭐' : o.name.toString() : '').join(','),
        },
      },
      {
        field: 'Product_leader',
        colId: 'Product_leader',
        headerName: translate('Product_leader'),
        cellRenderer: RendererNames.ActionsRenderer,
        cellClass: 'ag-cell-align-center',
        suppressMenu: true,
        sortable: false,
        width: 75,
        excel: {
          hide: true,
        },
      },
      ...this.productColumnGenerator.getColumnDefinitions([
        {field: 'name'},
        {
          field: 'flags',
          ...productFlagsColumnDefinition,
        },
      ]),
      ...packageSizeHelper.colDefs(this.products),
      ...this.categoryColumnGenerator.getColumnDefinitions([{field: 'box', filter: 'agSetColumnFilter'}]),
      ...this.recommendedColumnGenerator.getColumnDefinitions([
        {
          field: 'family',
          editable: true,
          selectOptions: familyOptions,
          action: this.userCouldManageFamily ? this.assignFamily : null,
          cellEditor: EditorNames.SelectEditor,
          sort: 'desc',
          filter: 'agSetColumnFilter',
        },
        {
          field: 'tier',
          selectOptions: tierOptions,
          editable: true,
          action: this.updateTier,
          cellEditor: EditorNames.SelectEditor,
          filter: 'agSetColumnFilter',
        },
      ]),
      {
        field: 'Product_ChangeFamily',
        colId: 'Product_ChangeFamily',
        cellRenderer: RendererNames.ActionsRenderer,
        cellClass: 'ag-cell-align-center',
        suppressMenu: true,
        sortable: false,
        width: 75,
        excel: {
          hide: true,
        },
      },
      ...this.familyBindingsColumnGenerator.getColumnDefinitions([
        {
          field: 'sizeRatio',
          editable: (params: IsColumnFuncParams) => params.data.Family_id && this.userCouldManageFamily,
          action: this.userCouldManageFamily ? this.setBindings : null,
          cellClass: 'ag-cell-align-right',
        },
        {
          field: 'sizeCharger',
          editable: (params: IsColumnFuncParams) => params.data.Family_id && this.userCouldManageFamily,
          action: this.userCouldManageFamily ? this.setBindings : null,
          cellClass: 'ag-cell-align-right',
        },
      ]),
      ...this.supplierNameColumnGenerator.getColumnDefinitions([
        {
          field: 'supplierName',
          headerName: translate('PriceArchitecture_supplierName'),
          filter: 'agSetColumnFilter',
        },
      ]),
      ...this.productSensitivityColumnGenerator.getColumnDefinitions([
        {
          field: 'title',
          editable: this.keycloakStore.userHasPermissions([PricingPermissions.PRICE_SENSITIVITY_GROUP_EDIT]),
          selectOptions: sensitivityOptions,
          action: this.updateSensitivity,
          cellEditor: EditorNames.SelectEditor,
          filter: 'agSetColumnFilter',
        },
      ]),
      ...this.internalProductPricesColumnGenerator.getColumnDefinitions([
        {
          field: 'purchasePrice',
          round: true,
          excel: {
            valueFormatter: ({value}) => (value ? parseFloat(value.toFixed(2)) : null),
          },
        },
        {
          field: 'openPurchasePrice',
          round: true,
          excel: {
            valueFormatter: ({value}) => (value ? parseFloat(value.toFixed(2)) : null),
          },
        },
      ]),
      ...this.priceZonePricesGenerator.getColumnDefinitions(priceZonePricesDefinition),
    ];
  }

  /**
   * Return generated data for ag-grid
   */
  @computed
  get rowData(): Array<StringMapping<any>> {
    const rowData = [];

    this.products.list.forEach((product) => {
      const family: Family | undefined = this.families.list.get(product.familyId);
      const tier: Tier | undefined = this.tiers.list.get(family ? family.tierId : product.tierId);
      const sensitivity: ProductSensitivity | undefined = this.sensitivities.list.get(product.productSensitivityId);

      const familyBindings: FamilyBinding | boolean =
        family && family.bindings.find((binding) => binding.productId === product.id);
      const productRecommendations = product.flags.get(RecommendedProduct);

      let tierTitle = null;
      if (tier) {
        tierTitle = tier.title;
      } else if (productRecommendations) {
        const recommendedTier = productRecommendations.familyId
          ? this.tiers.list.get(this.families.list.get(productRecommendations.familyId).tierId)
          : this.tiers.list.get(productRecommendations.tierId);
        tierTitle = recommendedTier ? {recommendedId: recommendedTier.id, value: recommendedTier.title} : null;
      }

      let familyName = null;
      if (family) {
        familyName = family.name;
      } else if (productRecommendations) {
        if (productRecommendations.familyId) {
          const recommendedFamily = this.families.list.get(productRecommendations.familyId);
          familyName = recommendedFamily ? {recommendedId: recommendedFamily.id, value: recommendedFamily.name} : null;
        }
      }

      const changeFamily = {
        Product_ChangeFamily: [
          {
            name: 'Product_ChangeFamily',
            icon: IconType.edit,
            props: {
              onClick: () => {
                runInAction(() => {
                  this.selectedProducts = [product];
                });
                this.toggleModal('assignFamily');
              },
            },
          },
        ],
      };

      /* LOG-2081 */
      const approvePriceSettings = {
        Product_new: [
          {
            name: 'Product_new',
            icon:
              product.flags.has(NewProduct) && product.flags.get(NewProduct).requiresConfiguration
                ? product.flags.has(RecommendedProduct)
                ? IconType.newsFull
                : IconType.newsRecommend
                : null,
            props: {
              onClick: () => {
                runInAction(() => {
                  this.approvePriceSettings(product.id);
                });
              },
            },
          },
        ],
      };
      const toggleLeadingProductFlag = {
        Product_leader: [
          {
            name: 'Product_leader',
            icon: IconType.leader,
            iconColor: product.flags.has(LeadingProduct) ? IconColor.Secondary : IconColor.Light,
            props: {
              onClick: () => {
                runInAction(() => {
                  this.toggleLeadingProductFlag(product.id);
                });
              },
            },
          },
        ],
      };
      const productPrices = this.internalProductPricesStore.list.get(product.id);
      if (!productPrices) {
        logger.error(`There are not internal prices for product ${product.id}. The product is either inactive or the prices were not loaded.`);
        return;
      }
      const {prices, purchasePrice, openPurchasePrice} = productPrices;
      const priceZonePricesData = {};
      prices
        .toArray()
        .map(({actualRegularPrice, actualMarginPercentage, actualMarginPrice, unitPrice, priceZoneId}) => {
          priceZonePricesData[`${priceZoneId}_actualRegularPrice`] = actualRegularPrice;
          priceZonePricesData[`${priceZoneId}_actualMarginPercentage`] = actualMarginPercentage;
          priceZonePricesData[`${priceZoneId}_actualMarginPrice`] = actualMarginPrice;
          priceZonePricesData[`${priceZoneId}_unitPrice`] = unitPrice;
        });

      const supplier = this.supplierStore.list.get(product.supplierId);
      const supplierName = supplier ? supplier.name : null;

      rowData.push({
        ...this.productColumnGenerator.getColumnData({...product, flags: getProductFlags(product)}),
        ...packageSizeHelper.getColumnData(product.packageSize),
        ...this.familyColumnGenerator.getColumnData(family),
        ...this.familyBindingsColumnGenerator.getColumnData(familyBindings),
        ...this.recommendedColumnGenerator.getColumnData({tier: tierTitle, family: familyName}),
        ...this.productSensitivityColumnGenerator.getColumnData(sensitivity),
        ...this.priceZonePricesGenerator.getColumnData(priceZonePricesData),
        ...this.internalProductPricesColumnGenerator.getColumnData({purchasePrice, openPurchasePrice}),

        // FIXME: dynamic ms5 name
        ...this.categoryColumnGenerator.getColumnData({
          // ms5: product.classification.ms5,
          box: this.categories.list.get(Utils.getCategoryIdWithLevel(product.categoryIds, 6)).name,
        }),
        ...changeFamily,
        ...approvePriceSettings,
        ...toggleLeadingProductFlag,
        ...this.supplierNameColumnGenerator.getColumnData({supplierName}),
      });
    });

    return rowData;
  }

  @computed
  get minPurchasePrice(): number {
    let minPrice = 0;
    this.products.list.forEach((product) => {
      const productPrice = this.products.purchasePrices.get(product.id);
      if (productPrice < minPrice && product.familyId !== undefined) {
        minPrice = productPrice;
      }
    });

    return minPrice;
  }

  @computed
  get maxPurchasePrice(): number {
    let maxPrice = 0;
    this.products.list.forEach((product) => {
      const productPrice = this.products.purchasePrices.get(product.id);
      if (productPrice > maxPrice && product.familyId !== undefined) {
        maxPrice = productPrice;
      }
    });

    return maxPrice;
  }

  /**
   * Gets row background for corresponding tier level
   * Default level always gets white
   * Highest level gets most significant green
   * Second highest level gets more significant green
   * All other positive levels get dime green
   * Lowest level gets most significant red
   * Second lowest level gets more significant red
   * All other negative levels get dime red
   */
  @action.bound
  getTierColor(params: any): string | string[] {
    const minTier = this.tiers.list.minBy((tier) => tier.level);
    const maxTier = this.tiers.list.maxBy((tier) => tier.level);

    const rowTier = params.data[Product.schema.familyId.description.nameKey]
      ? this.tiers.list.get(params.data[Family.schema.tierId.description.nameKey])
      : this.tiers.list.get(params.data[Product.schema.tierId.description.nameKey]);
    if (rowTier) {
      if (rowTier.id === this.tiers.defaultTier.id) {
        return ''; // bg-scale4
      } else if (rowTier.id === minTier.id) {
        return 'bg-scale7';
      } else if (rowTier.id === maxTier.id) {
        return 'bg-scale1';
      } else if (rowTier.level < this.tiers.defaultTier.level) {
        if (!!this.tiers.list.find((tier) => tier.level < rowTier.level)) {
          return 'bg-scale6';
        } else {
          return 'bg-scale5';
        }
      } else {
        if (!!this.tiers.list.find((tier) => tier.level > rowTier.level)) {
          return 'bg-scale2';
        } else {
          return 'bg-scale3';
        }
      }
    } else {
      return ''; // bg-scale4
    }
  }

  /**
   * TODO: getTierColor should be removed
   * Using rowClassRules instead of the rowClass due the fact:
   * rowClass: All new classes are applied. Old classes are not removed so be aware that classes will accumulate. If you want to remove old classes, then use rowClassRules.
   * More info: https://www.ag-grid.com/javascript-grid-row-styles/#refresh-of-styles
   */
  @action.bound
  getRowClassRule(scale, params: any): boolean {
    const rowTier = params.data[Product.schema.familyId.description.nameKey]
      ? this.tiers.list.get(params.data[Family.schema.tierId.description.nameKey])
      : this.tiers.list.get(params.data[Product.schema.tierId.description.nameKey]);
    if (rowTier) {
      if (rowTier.id === this.tiers.defaultTier.id) {
        return false; // bg-scale4
      } else if (rowTier.id === this.tiers.minTier.id) {
        return scale === 'bg-scale7';
      } else if (rowTier.id === this.tiers.maxTier.id) {
        return scale === 'bg-scale1';
      } else if (rowTier.level < this.tiers.defaultTier.level) {
        if (!!this.tiers.list.find((tier) => tier.level < rowTier.level)) {
          return scale === 'bg-scale6';
        } else {
          return scale === 'bg-scale5';
        }
      } else {
        if (!!this.tiers.list.find((tier) => tier.level > rowTier.level)) {
          return scale === 'bg-scale2';
        } else {
          return scale === 'bg-scale3';
        }
      }
    } else {
      return false; // bg-scale4
    }
  }

  // Updates column filter if cell values changed - LOG-2955
  updateAgSetColumnFilter(colId: string) {
    const filter = this.gridApi.getFilterInstance(colId) as any; // Bad AgGrid types, so as any
    if (filter) {
      filter.resetFilterValues();
    }
  }

  /** Sets product/family's tier
   * rowId == productId, because of the getRowNodeId method in the AgGrid (PriceArchitectureProductsPage.tsx)
   */
  @action.bound
  updateTier(productId: string, column: string, tierId: string): Promise<void> {
    const familyId = this.products.list.get(productId).familyId;
    if (familyId) {
      if (tierId) {
        return this.families
          .updateTier(List<{familyId: string; tierId: string}>([{familyId, tierId}]))
          .then(() => {
            // Updates filter values
            this.updateAgSetColumnFilter(this.recommendedColumnsDescription.tier.description.nameKey);
          })
          .catch((error) => {
            this.messages.setError(error);
          });
      }
    }
    return this.products
      .updateTier(List<{productId: string; tierId: string | null}>([{productId, tierId}]))
      .catch((error) => {
        this.messages.setError(error);
      });
  }

  /** Assigns product to family
   * rowId == productId, because of the getRowNodeId method in the AgGrid (PriceArchitectureProductsPage.tsx)
   */
  @action.bound
  assignFamily(productId: string, column: string, familyId: string): Promise<void> {
    const family = this.families.list.get(familyId);
    const nodeData = this.gridApi.getRowNode(productId).data;
    const sizeCharger = CONSTANTS.PRICE_ARCHITECTURE.ASSIGN_TO_FAMILY_DEFAULT_SIZE_CHARGER_VALUE;
    const sizeRatio = nodeData && nodeData.Product_packageSize && nodeData.Product_packageSize.value ?
      nodeData.Product_packageSize.value
      :
      CONSTANTS.PRICE_ARCHITECTURE.ASSIGN_TO_FAMILY_DEFAULT_SIZE_RATIO_VALUE;

    return this.products
      .assignToFamily(family.id, [...family.bindings, {productId, sizeCharger, sizeRatio}])
      .then(() => this.families.getInCategory(this.categoryId))
      .catch((error) => {
        this.messages.setError(error);
      });
  }

  /** Changes product sensitivity
   * rowId == productId, because of the getRowNodeId method in the AgGrid (PriceArchitectureProductsPage.tsx)
   */
  @action.bound
  updateSensitivity(productId: string, column: string, value: string): Promise<void> {
    return this.products
      .updateSensitivity(
        List<{productId: string; productSensitivityId: string}>([{productId, productSensitivityId: value}]),
      )
      .then(() => this.updateAgSetColumnFilter(ProductSensitivity.schema.title.description.nameKey))
      .catch((error) => {
        this.messages.setError(error);
      });
  }

  /** Bulk operation that removes family from selected products */
  @action
  removeFamily = async () => {
    this.removeFamilyIsLoading = true; // LOG-4545
    const productIds = this.selectedProducts.map((product) => {
      return product.id;
    });
    try {
      await this.products.removeFamily(productIds);
      await this.families.getInCategory(this.categoryId);
      this.toggleModal('removeFamily');
      this.messages.setSuccess(translate('AssignToFamily_familyRemoved', this.selectedProducts.length.toString()));
      this.gridApi.deselectAll(); // LOG-2869
    } catch (error) {
      this.messages.setError(error);
    }
    this.removeFamilyIsLoading = false; // LOG-4545
  };

  /** Updates ratio and charger
   * rowId == productId, because of the getRowNodeId method in the AgGrid (PriceArchitectureProductsPage.tsx)
   */
  @action
  setBindings = async (productId: string, column: string, value: number): Promise<void> => {
    this.gridApi.showLoadingOverlay();
    const data = this.gridApi.getRowNode(productId).data;
    try {
      const affectedFamilies: Family[] = await this.families.setBindings(
        List<{productId: string; familyId: string; sizeCharger: string; sizeRatio: string}>([
          {
            productId,
            familyId: data[Family.schema.id.description.nameKey],
            sizeCharger: data[this.bindingsSchema.data.sizeCharger.description.nameKey].toString(),
            sizeRatio: data[this.bindingsSchema.data.sizeRatio.description.nameKey].toString(),
            [StructPropBuilder.nameId(column)]: value != null ? value.toString() : value,
          },
        ]),
      );
      const productIds: string[] = [];
      affectedFamilies.forEach((f: Family) => {
        f.bindings.forEach((p) => {
          productIds.push(p.productId);
        });
      });
      await this.products.updateByfilter({
        ids: List([...new Set(productIds)]),
      });
      this.gridApi.hideOverlay();
    } catch (error) {
      this.gridApi.hideOverlay();
      this.messages.setError(error);
    }
  };

  @action.bound
  selectTier(tierId: string) {
    this.selectedTier = tierId;
  }

  @action.bound
  updateTiers(values: StringMapping<any>): Promise<void> {
    let productUpdates: List<{productId: string; tierId: string}> = List<{productId: string; tierId: string}>();
    let familyUpdates: List<{familyId: string; tierId: string}> = List<{familyId: string; tierId: string}>();
    const updates: Array<Promise<void>> = [];

    this.selectedProducts.forEach((selectedProduct) => {
      if (selectedProduct.familyId) {
        familyUpdates = familyUpdates.push({familyId: selectedProduct.familyId, tierId: values.tier});
      } else {
        productUpdates = productUpdates.push({productId: selectedProduct.id, tierId: values.tier});
      }
    });

    if (!productUpdates.isEmpty()) {
      updates.push(this.products.updateTier(productUpdates));
    }

    if (!familyUpdates.isEmpty()) {
      updates.push(this.families.updateTier(familyUpdates));
    }

    // LOG-2452 BUG 3.
    this.afterUpdateproductIds = [];

    familyUpdates.forEach((data) => {
      this.families.list.get(data.familyId).bindings.forEach((binding) => {
        this.afterUpdateproductIds.push(binding.productId);
      });
    });

    productUpdates.forEach((data) => {
      this.afterUpdateproductIds.push(data.productId);
    });

    return Promise.all(updates)
      .then(() => {
        this.toggleModal('changeTier');
        this.messages.setSuccess(translate('AssignToFamily_tierChanged', this.selectedProducts.length.toString()));
        this.updateAgSetColumnFilter(this.recommendedColumnsDescription.tier.description.nameKey);
        this.gridApi.deselectAll(); // LOG-2869
      })
      .catch((error) => {
        this.messages.setError(error);
      });
  }

  @action
  preparePostSort(productIds: string[]) {
    this.afterUpdateproductIds = productIds;
  }

  @action
  setSearchText(searchPhrase: string) {
    this.searchText = searchPhrase;
  }

  /**
   * Post sorts affected rows to the top of the table
   */
  @action
  postSort = (rowNodes) => {
    if (this.afterUpdateproductIds.length > 0) {
      let nextInsertPos = 0;
      rowNodes.forEach((row, index) => {
        if (this.afterUpdateproductIds.includes(row.id)) {
          rowNodes.splice(nextInsertPos, 0, rowNodes.splice(index, 1)[0]);
          nextInsertPos++;
        }
      });
    }
    // LOG-4261
    this.isPostSortingShowed = true;
  };

  /**
   * LOG-4261
   */
  @action
  postSortDisable = () => {
    if (this.isPostSortingShowed) {
      this.afterUpdateproductIds = [];
      this.isPostSortingShowed = false;
    }
  };

  /** Binds ag-grid api */
  @action.bound
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  /** Stores selected products on row selection */
  @action.bound
  onSelectionChanged(params: RowSelectedEvent) {
    this.selectedRows = params.api.getSelectedNodes();
    if (this.selectedRows.length) {
      this.selectedProducts = this.selectedRows.map((row) => {
        return this.products.list.get(row.data[Product.schema.id.description.nameKey]);
      });
    }
  }

  /** Checks if there's external filter set for families  */
  @action.bound
  isExternalFilterPresent(): boolean {
    return !!this.searchText || this.recommended || this.new;
  }

  /** Function that checks products against filter parameters - fulltext and checkbox filters */
  @action.bound
  doesExternalFilterPass(node: RowNode): boolean {

    const product = node.data[Product.schema.name.description.nameKey];
    const externalId = node.data[Product.schema.externalId.description.nameKey];
    const family = node.data[this.recommendedColumnsDescription.family.description.nameKey];

    const productNew: ProductNewEnum = (
      node.data.Product_new[0].icon === IconType.newsFull
        ?
        ProductNewEnum.isNewWithRecommendation
        :
        (
          node.data.Product_new[0].icon === IconType.newsRecommend
            ?
            ProductNewEnum.isNewWithoutRecommendation
            :
            ProductNewEnum.isNotNew
        )
    );

    const searchMatch =
      (product && product.search(new RegExp(this.searchText, 'ig')) !== -1) ||
      (externalId && externalId.search(new RegExp(this.searchText, 'ig')) !== -1) ||
      (family && family.search(new RegExp(this.searchText, 'ig')) !== -1);

    const newMatch =
      // no checkbox selected -> all items showed
      (!this.recommended && !this.new) ||
      // new without recommendation selected only -> new without recommendation showed only
      (this.new && !this.recommended && productNew === ProductNewEnum.isNewWithoutRecommendation) ||
      // new wit recommendation selected only -> new with recommendation showed only
      (!this.new && this.recommended && productNew === ProductNewEnum.isNewWithRecommendation) ||
      // both checkboxes selected -> new with/without recommendation showed (all without not new)
      (this.new && this.recommended &&
        (
          productNew === ProductNewEnum.isNewWithRecommendation ||
          productNew === ProductNewEnum.isNewWithoutRecommendation
        )
      );

    return searchMatch && newMatch;
  }

  /** Updates searched family text on input change */
  @action.bound
  searchProduct(e: React.ChangeEvent<HTMLInputElement>): void {
    this.searchText = e.target.value;
    this.gridApi.onFilterChanged();
  }

  @action.bound
  toggleRecommended(e: React.ChangeEvent<HTMLInputElement>): void {
    this.recommended = !this.recommended;
    this.gridApi.deselectAll();
    this.gridApi.onFilterChanged();
  }

  @action.bound
  toggleNew(e: React.ChangeEvent<HTMLInputElement>): void {
    this.new = !this.new;
    this.gridApi.deselectAll();
    this.gridApi.onFilterChanged();
  }

  /**
   * Negate modalHiddenProp
   *
   * @param title - name of the modalHidden prop that should be changed
   */
  toggleModal = (title: string) => {
    runInAction(() => {
      this.modalHidden[title] = !this.modalHidden[title];
    });
  };

  /**
   * HOF for toggle modal
   */
  getModalToggleEvent = (title: string) => () => this.toggleModal(title);

  /**
   * Toggles LeadingProduct flag
   */
  toggleLeadingProductFlag = async (id: string) => {
    this.gridApi.showLoadingOverlay();
    try {
      await this.products.setLeader(id);
      this.gridApi.hideOverlay();
    } catch (error) {
      this.gridApi.hideOverlay();
      this.messages.setError(error);
    }
  };

  /**
   * Approves product price architecture setting - family, tier, product sensitivity.
   * Flags "NEW" and "RECOMMENDED" are removed.
   */
  @action
  approvePriceSettings = async (id: string) => {
    this.gridApi.showLoadingOverlay();
    try {
      await this.products.approvePriceSettings(id);
      this.gridApi.hideOverlay();
    } catch (error) {
      this.gridApi.hideOverlay();
      this.messages.setError(error);
    }
  };

  /**
   * Fetches all data for this page
   */
  @action.bound
  load(): void {
    this.setLoadingState(LoadingState.Pending);
    Promise.all([
      this.categories.getAll(),
      this.products.getInCategory(this.categoryId),
      this.products.getPurchasePriceInCategory(this.categoryId),
      this.families.getInCategory(this.categoryId),
      this.priceZoneStore.getAll(),
      this.tiers.getAll(),
      this.sensitivities.getAll(),
      this.products.getUnits(),
      this.internalProductPricesStore.getInCategory(this.categoryId),
      this.supplierStore.getAll(),
    ])
      .then(([productCategories]) => {
        runInAction(() => {
          this.productCategory = productCategories.get(this.categoryId);
          this.userCouldManageFamily = this.keycloakStore.userHasPermissions([PricingPermissions.FAMILY_MANAGE]);
        });
        this.createPriceZoneGenerator();
        if (this.externalId) {
          this.searchText = this.externalId;
        }
        this.setLoadingState(LoadingState.Success);
      })
      .catch((errors) => {
        this.messages.setError(errors);
        this.setLoadingState(LoadingState.Error);
      });

    // Scrolls on top when affected rows postsorted on top
    const postSortReaction = reaction(
      () => this.afterUpdateproductIds,
      (ids) => (ids.length > 0 && this.gridApi ? this.gridApi.ensureIndexVisible(0, 'top') : null),
    );
  }
}
