/**
 * @file Created on Fri Oct 05 2018
 * @author SKu
 */

import {PricingPermissions, SelloutItemFilter} from '@logio/common-be-fe';
import {
  Button,
  ButtonColor,
  ButtonSize,
  getPath,
  LoadingState,
  Messages,
  Page,
  ProgressBar,
  translate,
  WithPermission,
  Modal,
  Form,
  FormGroupInline,
  Field,
  RenderPropsProvider,
  FormRenderProps,
  InputRangeAdapter,
  SelectAdapter,
  InputNumberAdapter,
  OptionsProvider,
  SelectOptions,
  MultiInput,
} from '@logio/common-fe';
import {Observer, observer} from 'mobx-react';
import * as React from 'react';
import {Col, Row} from 'react-flexbox-grid';
import {match, RouteComponentProps, withRouter} from 'react-router';
import ReleaseSelloutHeader from '../../components/Release/ReleaseHeader/ReleaseSelloutHeader';
import {IRouteParams} from '../../routes-app';
import {PagePathsEnum} from '../../shared/localization/PagePathsEnum';
import {ReleaseSelloutSettingsPageStore} from '../../stores/pages/Release/ReleaseSelloutSettingsPageStore';
import SettingsComponent from '../../components/SettingsOverview/SettingsComponent';
import {SelloutReleaseType} from '@logio/common-be-fe';
import { PriceZoneField } from '../../components/PriceZoneField/PriceZoneField';

export interface Props {
  match: match<IRouteParams>;
}

/**
 * Release settings page
 */
@observer
class ReleaseSelloutSettingsPage extends React.Component<Props & RouteComponentProps<ReleaseSelloutSettingsPage>> {
  store = new ReleaseSelloutSettingsPageStore();

  componentWillMount() {
    this.store.load(this.props.match.params.id);
  }

  /** Removes polling in case if has been started */
  componentWillUnmount() {
    this.store.pollingHelper.polling.stopPolling();
  }

  get releaseHeaderItems() {
    return [
      <WithPermission key="open" permissionTypes={[PricingPermissions.REGULAR_RELEASE_OPEN_DISCARD]}>
        {!this.store.pageReadOnly &&
          this.store.pollingHelper.pollingState !== LoadingState.Pending && (
            <Button buttonSize={ButtonSize.Tiny} onClick={this.store.proceedPriceSimulation(this.props.history.push)}>
              {translate('proceed-price-simulation')}
            </Button>
          )}
      </WithPermission>,
    ];
  }

  /**  Returns Buttons that could change Release Settings */
  get generalSettingsActions() {
    return (
      <FormGroupInline>
        <Button buttonSize={ButtonSize.Tiny} onClick={this.store.includeCategories}>
          {translate('include')}
        </Button>
        <Button buttonSize={ButtonSize.Tiny} buttonColor={ButtonColor.Light} onClick={this.store.excludeCategories}>
          {translate('exclude')}
        </Button>
        <div className="flex-grow-1">&nbsp;</div>
        <Button
          buttonSize={ButtonSize.Tiny}
          disabled={!this.store.isSomeCategoriesIncluded}
          onClick={this.store.showModal}
        >
          {translate('release-sellout-settings-set-filters')}
        </Button>
      </FormGroupInline>
    );
  }
  renderFilterModal() {
    return (
      <Modal
        hidden={this.store.isModalHidden}
        title={translate('sellout-release-settings-filter-modal')}
        close={this.store.hideModal}
      >
        <Form
          descriptions={this.store.descriptions}
          onSubmit={this.store.onFilterFormSubmit}
          initialValues={this.store.initialValues}
        >
          <RenderPropsProvider
            component={({form}: FormRenderProps) => (
              <>
                <Row>
                  <Col xs={6}>
                    <PriceZoneField
                      name="zoneId"
                      label="SelloutReleaseItemFilterForm_zoneId"
                      tooltip={translate('SELLOUT_IDENTIFICATION_FILTER_TOOLTIP_ZONE_ID')}
                    />
                    <OptionsProvider
                      name="siteIds"
                      dependsOn={{priceZoneId: 'zoneId'}}
                      component={(options: SelectOptions) => (
                        <Field
                          name="siteIds"
                          component={(props) =>
                            SelectAdapter(
                              'SelloutReleaseItemFilterForm_sitesIds',
                              options,
                              true,
                              false,
                              false,
                              undefined,
                              'Select-max-4-lines',
                              undefined,
                              translate('SELLOUT_IDENTIFICATION_FILTER_TOOLTIP_SITE_IDS'),
                            )(props)
                          }
                        />
                      )}
                    />
                    <Field
                      name="weighted"
                      component={(adapterProps) =>
                        SelectAdapter(
                          'weigh',
                          this.store.weightedOptions,
                          false,
                          false,
                          false,
                          undefined,
                          undefined,
                          undefined,
                          translate('SELLOUT_IDENTIFICATION_FILTER_TOOLTIP_WEIGHTED'),
                        )(adapterProps)
                      }
                    />
                    {(
                      this.store.release.selloutReleaseType === SelloutReleaseType.ExitRegularDelist
                      || this.store.release.selloutReleaseType === SelloutReleaseType.ExitInOutDelist
                    ) && (
                      <Field
                        name="returnable"
                        component={(adapterProps) =>
                          SelectAdapter(
                            'returnable',
                            this.store.returnableOptions,
                            false,
                            false,
                            false,
                            undefined,
                            undefined,
                            undefined,
                            translate('SELLOUT_IDENTIFICATION_FILTER_TOOLTIP_RETURNABLE'),
                          )(adapterProps)
                        }
                      />
                    )}
                    <Field
                      name="productExternalIds"
                      /* If you want to change height, you must set it in common-fe/src/components/inputs/Select/styled/SelectStyled.tsx */
                      component={(props: any) => (
                        <MultiInput
                          className="Select-max-4-lines"
                          {...props}
                          labelTooltipText={translate('SELLOUT_IDENTIFICATION_FILTER_TOOLTIP_PRODUCTS')}
                        />
                      )}
                      validate={(productExternalIds) => this.store.productStore.validate(
                        productExternalIds,
                        { containsAnyCategoryIds: this.store.release.productCategoryIds },
                      )}
                    />
                  </Col>
                  <Col xs={6}>
                    <Field
                      name="unitsPerSite"
                      optional
                      component={(adapterProps) =>
                        InputRangeAdapter(
                          'unitsPerSite',
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          () => form.change('unitsPerSite', ['', '']),
                          translate('SELLOUT_IDENTIFICATION_FILTER_TOOLTIP_UNITS_PER_SITE'),
                        )(adapterProps)
                      }
                    />

                    <Field
                      name="stockValue"
                      optional
                      component={(adapterProps) =>
                        InputRangeAdapter(
                          'stockValue',
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          () => form.change('stockValue', ['', '']),
                          translate('SELLOUT_IDENTIFICATION_FILTER_TOOLTIP_STOCK_VALUE'),
                        )(adapterProps)
                      }
                    />
                    <Field
                      name="selloutVolume"
                      optional
                      component={(adapterProps) =>
                        InputRangeAdapter(
                          'selloutVolume',
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          () => form.change('selloutVolume', ['', '']),
                          translate('SELLOUT_IDENTIFICATION_FILTER_TOOLTIP_SELLOUT_VOLUME'),
                        )(adapterProps)
                      }
                    />
                    {this.store.release.selloutReleaseType === SelloutReleaseType.EndangeredExpiry && (
                      <Field
                        name="daysToExpiration"
                        optional
                        component={(adapterProps) =>
                          InputRangeAdapter(
                            'daysToExpiration',
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            () => form.change('daysToExpiration', ['', '']),
                            translate('SELLOUT_IDENTIFICATION_FILTER_TOOLTIP_DAYS_TO_EXPIRATION'),
                          )(adapterProps)
                        }
                      />
                    )}

                    <Field
                      name="futurePromoInWeeks"
                      component={(adapterProps) =>
                        InputNumberAdapter(
                          'futurePromoInWeeks',
                          false,
                          false,
                          false,
                          undefined,
                          () => form.change('futurePromoInWeeks', null),
                          translate('SELLOUT_IDENTIFICATION_FILTER_TOOLTIP_FUTURE_PROMO_IN_WEEKS'),
                        )(adapterProps)
                      }
                    />
                  </Col>
                </Row>
                <FormGroupInline>
                  <Button
                    type="button"
                    onClick={() => form.reset(this.store.defaultFilterValues)}
                    buttonColor={ButtonColor.Light}
                  >
                    {translate('clear-filter')}
                  </Button>
                  <div className="flex-grow-1">&nbsp;</div>
                  <Button type="button" buttonColor={ButtonColor.Light} onClick={this.store.hideModal}>
                    {translate('cancel')}
                  </Button>
                  <Button type="submit">{translate('save')}</Button>
                </FormGroupInline>
              </>
            )}
          />
        </Form>
      </Modal>
    );
  }

  render() {
    return (
      <Page
        titleKey={this.store.isLoadingSuccess && this.store.release.name}
        loadingState={this.store.loadingState}
        showMessages={false}
        backButtonPath={getPath(PagePathsEnum.ReleasesSellout)}
      >
        <Observer>
          {() => (
            <>
              <ReleaseSelloutHeader store={this.store.releaseHeaderStore} customItems={this.releaseHeaderItems} />
              {this.store.pollingHelper &&
                this.store.pollingHelper.pollingState !== LoadingState.Success && (
                  <div className="mb2">
                    <ProgressBar
                      percentage={this.store.pollingHelper.progress}
                      label={'release-item-count-recalculating'}
                    />
                  </div>
                )}
              <Messages />
              {!this.store.pageReadOnly &&
                (this.store.pollingHelper &&
                  !this.store.pollingHelper.progress &&
                  this.store.pollingHelper.pollingState === LoadingState.Success) && (
                  <>
                    <Row className="mb1">
                      <Col xs={12}>
                        <WithPermission permissionTypes={[PricingPermissions.REGULAR_RELEASE_SETTINGS_EDIT]}>
                          {this.generalSettingsActions}
                        </WithPermission>
                      </Col>
                    </Row>
                  </>
                )}

              {this.store.pollingHelper &&
                !this.store.pollingHelper.progress &&
                this.store.pollingHelper.pollingState === LoadingState.Success && (
                  <SettingsComponent store={this.store.settingsComponentStore} gridId="SelloutReleaseSettings" />
                )}
              {this.renderFilterModal()}
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

export default withRouter(ReleaseSelloutSettingsPage);
