import _toConsumableArray from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

/**
 * @file Created on Wed Aug 29 2018
 * @author SKu
 */
import * as React from 'react';
import ButtonGroup from '../../controls/ButtonGroup';
import StyledLabel from '../../typography/Label';
import CheckBox from '../CheckBox';
import withValidation from '../withValidation';
import { CONSTANTS } from '../../../constants';
/**
 * Componen renders options.lenght checkbox(button) componnets
 */

var CheckBoxGroup = function CheckBoxGroup(props) {
  /**
   * Update options field with fresh values, than
   * pass value through onChange prop
   */
  var handleChange = function handleChange(_ref) {
    var target = _ref.target;

    if (target.checked) {
      props.onChange([].concat(_toConsumableArray(props.values), [target.value]));
    } else {
      props.onChange(props.values.filter(function (value) {
        return value !== target.value;
      }));
    }
  };

  return React.createElement(React.Fragment, null, props.groupLabel && React.createElement(StyledLabel, null, props.groupLabel), React.createElement(ButtonGroup, null, props.scale && React.createElement("span", {
    className: "mr1"
  }, props.scale[0]), props.options.map(function (_ref2, index) {
    var label = _ref2.label,
        value = _ref2.value;
    return React.createElement(CheckBox, {
      typeButton: props.typeButton,
      key: index,
      name: "".concat(props.name).concat(index),
      value: value,
      label: label,
      checked: props.values.includes(value),
      onChange: handleChange,
      className: props.scale ? "pl1 bg-scale".concat(CONSTANTS.DATA_CLEANING.PRICE_CHANGES_SCALE_COUNT - index) : undefined
    });
  }), props.scale && React.createElement("span", {
    className: "ml1"
  }, props.scale[1])));
};

CheckBoxGroup.defaultProps = {
  values: []
};
export default CheckBoxGroup;
export var CheckBoxGroupValidated = withValidation(CheckBoxGroup);