import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject21() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  border-radius: 50%;\n\n  width: 9px;\n  height: 9px;\n  border: 1px solid currentColor;\n\n  color: ", ";\n  background-color: ", ";\n  \n  ", ";\n  ", ";\n\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  // Release states are sent translated\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  \n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  \n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  \n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  \n  ", ";\n  ", ";\n  \n  ", ";\n  ", ";\n  ", ";\n  \n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  \n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  // Needs to be in the bottom to override other css properties\n  ", ";\n"]);

  _templateObject21 = function _templateObject21() {
    return data;
  };

  return data;
}

function _templateObject20() {
  var data = _taggedTemplateLiteral(["\n  background-color: transparent;\n"]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = _taggedTemplateLiteral(["\n  width: 17px;\n  height: 17px;\n  border: 3px solid currentColor;\n"]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = _taggedTemplateLiteral(["\n  width: 13px;\n  height: 13px;\n  border: 2px solid currentColor;\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: transparent;\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: ", ";\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: transparent;\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: transparent;\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: ", ";\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: ", ";\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: ", ";\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: ", ";\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: ", ";\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: ", ";\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
import styled, { css, withTheme } from 'styled-components';
import { ApprovalStatusIndication, ExportedEntityState, PriceTerminationState, PromotionStateEnum, ReleaseState, WindowStateEnum } from '@logio/common-be-fe';
import { DPTransferStatus } from '../../../../shared/dataPump/enums/DPTransferStatus';
import { CircleColor } from '../CircleColor';
import { CircleType } from '../CircleType';
import { CircleWidth } from '../CircleWidth';
import translate from "../../../i18n/translate";
import { DPTaskState } from '../../../../shared/dataPump/enums/DPTaskState';
import { DPContextStateStatus } from '../../../../shared/dataPump/enums/DPContextStateStatus';
import { PromoOptimizationLearningState } from '../../../../shared/dataPump/enums/PromoOptimizationLearningState';
var CircleWaiting = css(_templateObject(), function (props) {
  return props.theme.colors.stateCategoryWaiting;
}, function (props) {
  return props.theme.colors.stateCategoryWaiting;
});
var CircleInProgress = css(_templateObject2(), function (props) {
  return props.theme.colors.stateCategoryInProgress;
}, function (props) {
  return props.theme.colors.stateCategoryInProgress;
});
var CircleApproved = css(_templateObject3(), function (props) {
  return props.theme.colors.stateCategoryApproved;
}, function (props) {
  return props.theme.colors.stateCategoryApproved;
});
var CircleAfterDeadline = css(_templateObject4(), function (props) {
  return props.theme.colors.stateCategoryAfterDeadline;
}, function (props) {
  return props.theme.colors.stateCategoryAfterDeadline;
});
var CircleRejected = css(_templateObject5(), function (props) {
  return props.theme.colors.stateCategoryRejected;
}, function (props) {
  return props.theme.colors.stateCategoryRejected;
});
var CircleWarning = css(_templateObject6(), function (props) {
  return props.theme.colors.warning;
}, function (props) {
  return props.theme.colors.warning;
});
var CircleError = css(_templateObject7(), function (props) {
  return props.theme.colors.error;
}, function (props) {
  return props.theme.colors.error;
});
var CircleCancelled = css(_templateObject8(), function (props) {
  return props.theme.colors.stateCanceled;
}, function (props) {
  return props.theme.colors.stateCanceled;
});
var CircleComputing = css(_templateObject9(), function (props) {
  return props.theme.colors.stateInProgress;
}, function (props) {
  return props.theme.colors.stateInProgress;
});
var CircleFailed = css(_templateObject10(), function (props) {
  return props.theme.colors.stateFailed;
}, function (props) {
  return props.theme.colors.stateFailed;
});
var CircleNew = css(_templateObject11(), function (props) {
  return props.theme.colors.stateNew;
}, function (props) {
  return props.theme.colors.stateNew;
});
var CircleOpened = css(_templateObject12(), function (props) {
  return props.theme.colors.stateOpened;
}, function (props) {
  return props.theme.colors.stateOpened;
});
var CircleReleased = css(_templateObject13(), function (props) {
  return props.theme.colors.stateReleased;
}, function (props) {
  return props.theme.colors.stateReleased;
});
var CircleLate = css(_templateObject14(), function (props) {
  return props.theme.colors.stateCategoryRejected;
});
var CircleApprovedBy = css(_templateObject15(), function (props) {
  return props.theme.colors.stateCategoryApproved;
});
/*
 * Mixins
 */

var colorMixin = function colorMixin(color) {
  return css(_templateObject16(), color, color);
};

var colorTransparentMixin = function colorTransparentMixin(color) {
  return css(_templateObject17(), color);
};

var CircleNormal = css(_templateObject18());
var CircleBig = css(_templateObject19());
var CircleOutline = css(_templateObject20());
/**
 * Create an Circle component that'll render an <Circle>
 */

var CircleStyled = styled.span(_templateObject21(), function (props) {
  return props.theme.colors.primary;
}, function (props) {
  return props.theme.colors.primary;
}, function (props) {
  return props.width === CircleWidth.Normal ? CircleNormal : null;
}, function (props) {
  return props.width === CircleWidth.Big ? CircleBig : null;
}, function (props) {
  return props.color === CircleColor.Waiting ? CircleWaiting : null;
}, function (props) {
  return props.color === CircleColor.InProgress ? CircleInProgress : null;
}, function (props) {
  return props.color === CircleColor.Approved ? CircleApproved : null;
}, function (props) {
  return props.color === CircleColor.AfterDeadline ? CircleAfterDeadline : null;
}, function (props) {
  return props.color === CircleColor.Rejected ? CircleRejected : null;
}, function (props) {
  return props.color === CircleColor.Warning ? CircleWarning : null;
}, function (props) {
  return props.color === CircleColor.Error ? CircleError : null;
}, function (props) {
  return props.color === translate(PriceTerminationState.Cancelled) ? CircleCancelled : null;
}, function (props) {
  return props.color === translate(PriceTerminationState.Opened) ? CircleOpened : null;
}, function (props) {
  return props.color === translate(PriceTerminationState.Terminating) ? CircleOpened : null;
}, function (props) {
  return props.color === translate(PriceTerminationState.Terminated) ? CircleReleased : null;
}, function (props) {
  return props.color === translate(ReleaseState.Approved) ? CircleApproved : null;
}, function (props) {
  return props.color === translate(ReleaseState.Cancelled) ? CircleCancelled : null;
}, function (props) {
  return props.color === translate(ReleaseState.Computing) ? CircleComputing : null;
}, function (props) {
  return props.color === translate(ReleaseState.ComputationFailed) ? CircleFailed : null;
}, function (props) {
  return props.color === translate(ReleaseState.ExportChecking) ? CircleComputing : null;
}, function (props) {
  return props.color === translate(ReleaseState.ExportEditing) ? CircleComputing : null;
}, function (props) {
  return props.color === translate(ReleaseState.ExportFailed) ? CircleFailed : null;
}, function (props) {
  return props.color === translate(ReleaseState.ExportStarted) ? CircleComputing : null;
}, function (props) {
  return props.color === translate(ReleaseState.New) ? CircleNew : null;
}, function (props) {
  return props.color === translate(ReleaseState.Reopening) ? CircleNew : null;
}, function (props) {
  return props.color === translate(ReleaseState.Opened) ? CircleOpened : null;
}, function (props) {
  return props.color === translate(ReleaseState.Released) ? CircleReleased : null;
}, function (props) {
  return props.color === ApprovalStatusIndication.Approved ? CircleApproved : null;
}, function (props) {
  return props.color === ApprovalStatusIndication.ApprovedByAnotherUser ? CircleApprovedBy : null;
}, function (props) {
  return props.color === ApprovalStatusIndication.InProgress ? CircleInProgress : null;
}, function (props) {
  return props.color === ApprovalStatusIndication.Waiting ? CircleWaiting : null;
}, function (props) {
  return props.color === ApprovalStatusIndication.Rejected ? CircleRejected : null;
}, function (props) {
  return props.color === ApprovalStatusIndication.Late ? CircleLate : null;
}, function (props) {
  return props.color === PromotionStateEnum.Concept ? colorMixin(props.theme.colors.statePromotionConcept) : null;
}, function (props) {
  return props.color === PromotionStateEnum.Canceled ? colorMixin(props.theme.colors.statePromotionCanceled) : null;
}, function (props) {
  return props.color === PromotionStateEnum.Approved ? colorMixin(props.theme.colors.statePromotionApproved) : null;
}, function (props) {
  return props.color === PromotionStateEnum.Finalized ? colorMixin(props.theme.colors.statePromotionFinalized) : null;
}, function (props) {
  return props.color === PromotionStateEnum.Active ? colorMixin(props.theme.colors.statePromotionActive) : null;
}, function (props) {
  return props.color === PromotionStateEnum.Open ? colorMixin(props.theme.colors.statePromotionOpen) : null;
}, function (props) {
  return props.color === PromotionStateEnum.Closed ? colorMixin(props.theme.colors.statePromotionClosed) : null;
}, function (props) {
  return props.color === PromotionStateEnum.Finished ? colorMixin(props.theme.colors.statePromotionFinished) : null;
}, function (props) {
  return props.color === PromotionStateEnum.Evaluated ? colorMixin(props.theme.colors.statePromotionEvaluated) : null;
}, function (props) {
  return props.color === WindowStateEnum.Concept ? colorMixin(props.theme.colors.stateWindowConcept) : null;
}, function (props) {
  return props.color === WindowStateEnum.Canceled ? colorMixin(props.theme.colors.stateWindowCanceled) : null;
}, function (props) {
  return props.color === WindowStateEnum.RejectedApproval ? colorMixin(props.theme.colors.stateWindowRejectedApproval) : null;
}, function (props) {
  return props.color === WindowStateEnum.RejectedChange ? colorMixin(props.theme.colors.stateWindowRejectedChange) : null;
}, function (props) {
  return props.color === WindowStateEnum.Approved ? colorMixin(props.theme.colors.stateWindowApproved) : null;
}, function (props) {
  return props.color === WindowStateEnum.Finalized ? colorMixin(props.theme.colors.stateWindowFinalized) : null;
}, function (props) {
  return props.color === WindowStateEnum.Active ? colorMixin(props.theme.colors.stateWindowActive) : null;
}, function (props) {
  return props.color === WindowStateEnum.Prepared ? colorMixin(props.theme.colors.stateWindowPrepared) : null;
}, function (props) {
  return props.color === WindowStateEnum.Changed ? colorMixin(props.theme.colors.stateWindowChanged) : null;
}, function (props) {
  return props.color === WindowStateEnum.Finished ? colorMixin(props.theme.colors.stateWindowFinished) : null;
}, function (props) {
  return props.color === WindowStateEnum.Evaluated ? colorMixin(props.theme.colors.stateWindowEvaluated) : null;
}, function (props) {
  return props.color === DPTransferStatus.Failed ? colorMixin(props.theme.colors.dpTransferStatusFailed) : null;
}, function (props) {
  return props.color === DPTransferStatus.NotStarted ? colorMixin(props.theme.colors.dpTransferStatusNotStarted) : null;
}, function (props) {
  return props.color === DPTransferStatus.Success ? colorMixin(props.theme.colors.dpTransferStatusSuccess) : null;
}, function (props) {
  return props.color === DPTransferStatus.Pending ? colorMixin(props.theme.colors.dpTransferStatusPending) : null;
}, function (props) {
  return props.color === DPTaskState.finished ? colorMixin(props.theme.colors.dpTaskStateFinished) : null;
}, function (props) {
  return props.color === DPTaskState.processing ? colorMixin(props.theme.colors.dpTaskStateProcessing) : null;
}, function (props) {
  return props.color === DPContextStateStatus.Initialized ? colorMixin(props.theme.colors.dpContextStateStatusInitialized) : null;
}, function (props) {
  return props.color === DPContextStateStatus.Completed ? colorMixin(props.theme.colors.dpContextStateStatusCompleted) : null;
}, function (props) {
  return props.color === DPContextStateStatus.Running ? colorMixin(props.theme.colors.dpContextStateStatusRunning) : null;
}, function (props) {
  return props.color === ExportedEntityState.Running ? colorMixin(props.theme.colors.exportedEntityStateRunning) : null;
}, function (props) {
  return props.color === ExportedEntityState.Processing ? colorMixin(props.theme.colors.exportedEntityStateProcessing) : null;
}, function (props) {
  return props.color === ExportedEntityState.Waiting ? colorMixin(props.theme.colors.exportedEntityStateWaiting) : null;
}, function (props) {
  return props.color === ExportedEntityState.Failed ? colorMixin(props.theme.colors.exportedEntityStateFailed) : null;
}, function (props) {
  return props.color === ExportedEntityState.Success ? colorMixin(props.theme.colors.exportedEntityStateSucces) : null;
}, function (props) {
  return props.color === ExportedEntityState.Closed ? colorMixin(props.theme.colors.exportedEntityStateClosed) : null;
}, function (props) {
  return props.color === PromoOptimizationLearningState.Created ? colorMixin(props.theme.colors.promoOptimizationLearningStateCreated) : null;
}, function (props) {
  return props.color === PromoOptimizationLearningState.ExportInProgress ? colorMixin(props.theme.colors.promoOptimizationLearningStateInProgress) : null;
}, function (props) {
  return props.color === PromoOptimizationLearningState.ExportSuccess ? colorMixin(props.theme.colors.promoOptimizationLearningStateSuccess) : null;
}, function (props) {
  return props.color === PromoOptimizationLearningState.UploadStarted ? colorMixin(props.theme.colors.promoOptimizationLearningStateStarted) : null;
}, function (props) {
  return props.color === PromoOptimizationLearningState.UploadWaiting ? colorMixin(props.theme.colors.promoOptimizationLearningStateWaiting) : null;
}, function (props) {
  return props.color === PromoOptimizationLearningState.UploadInProgress ? colorMixin(props.theme.colors.promoOptimizationLearningStateInProgress) : null;
}, function (props) {
  return props.color === PromoOptimizationLearningState.UploadSuccess ? colorMixin(props.theme.colors.promoOptimizationLearningStateSuccess) : null;
}, function (props) {
  return props.color === PromoOptimizationLearningState.CalculationStarted ? colorMixin(props.theme.colors.promoOptimizationLearningStateStarted) : null;
}, function (props) {
  return props.color === PromoOptimizationLearningState.CalculationWaiting ? colorMixin(props.theme.colors.promoOptimizationLearningStateWaiting) : null;
}, function (props) {
  return props.color === PromoOptimizationLearningState.CalculationInProgress ? colorMixin(props.theme.colors.promoOptimizationLearningStateInProgress) : null;
}, function (props) {
  return props.color === PromoOptimizationLearningState.CalculationSuccess ? colorMixin(props.theme.colors.promoOptimizationLearningStateSuccess) : null;
}, function (props) {
  return props.color === PromoOptimizationLearningState.Error ? colorMixin(props.theme.colors.promoOptimizationLearningStateError) : null;
}, function (props) {
  return props.color === PromoOptimizationLearningState.Killed ? colorMixin(props.theme.colors.promoOptimizationLearningStateError) : null;
}, function (props) {
  return props.type === CircleType.Outline ? CircleOutline : null;
});
export default withTheme(CircleStyled);