"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var CostPriceFormatEnum;

(function (CostPriceFormatEnum) {
  CostPriceFormatEnum["format90000"] = "90000";
  CostPriceFormatEnum["format11111"] = "11111";
  CostPriceFormatEnum["format30000"] = "30000";
})(CostPriceFormatEnum = exports.CostPriceFormatEnum || (exports.CostPriceFormatEnum = {}));