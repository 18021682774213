/**
 * @file Created on Tue Mar 14 2020
 * @author MHl
 */

import { SelloutTermination, PriceTerminationState, Utils } from '@logio/common-be-fe';
import {
    ActionProps,
    ActionsGenerator,
    ColumnDefinition,
    ColumnGenerator,
    getPath,
    IconType,
    PageStore,
    StringMapping,
    translate,
    RendererNames,
    LoadingState,
    SelloutTerminationLayer

} from '@logio/common-fe';
import { computed, runInAction, observable } from 'mobx';
import { PagePathsEnum } from '../../../shared/localization/PagePathsEnum';

export class EndSelloutsPageStore extends PageStore {
 private SeloutTerminationLayer = new SelloutTerminationLayer();
    private actionsGenerator = new ActionsGenerator();
    private columnGenerator = new ColumnGenerator(SelloutTermination.schema);

    constructor() {
        super();
    }

@observable
    terminations:SelloutTermination[] = [] as SelloutTermination[]
    
@computed
    public get rowData(): Array<StringMapping<any>> {
        const rowData = [];
        
      this.terminations.forEach((termination: SelloutTermination) => {
            const redirectIcon: ActionProps = {
                name: 'redirect',
                icon: IconType.view,
                linkProps: { to: getPath(PagePathsEnum.EndSelloutsDetail, termination.id.toString()) },
            };
            rowData.push({
                ...this.actionsGenerator.getColumnData(redirectIcon),
                ...this.columnGenerator.getColumnData({ ...termination, state: translate(termination.state) }),
            });
        });
        return rowData;
    }

    private convertTerminationState = (state: string): number => {
        switch (state) {
            case translate(PriceTerminationState.Opened):
                return 0;
            case translate(PriceTerminationState.Terminating):
                return 1;
            case translate(PriceTerminationState.Terminated):
                return 2;
            case translate(PriceTerminationState.Failed):
                return 3;
            case translate(PriceTerminationState.Cancelled):
                return 4;
            default:
                throw new Error('PriceTerminationState is not supported');
        }
    };

    private compareTerminationStates = (valA: PriceTerminationState, valB: PriceTerminationState) =>
        this.convertTerminationState(valA) - this.convertTerminationState(valB);

    @computed
    get columnDefinitions(): ColumnDefinition[] {
        const actionDefs: ColumnDefinition = {
            headerName: '',
            suppressMovable: false,
            lockPinned: false,
            lockPosition: false,
            checkboxSelection: false,
            headerCheckboxSelection: false,
            hide: false,
        };
        const colDefs = [
            {
                field: 'state',
                comparator: this.compareTerminationStates,
                cellRenderer: RendererNames.CircleStateRenderer,
            },
            { field: 'name' },
            { field: 'terminationIssued' },
        ];
        return [this.actionsGenerator.getColumnDefinition(actionDefs), ...this.columnGenerator.getColumnDefinitions(colDefs)];
    }

    public load = async (): Promise<void> => {
        this.setLoadingState(LoadingState.Pending);
        try {
             await this.SeloutTerminationLayer.getTerminations().then(
                 terminations=>{
                     runInAction(()=>(this.terminations = terminations))
                 }
             )
            this.setLoadingState(LoadingState.Success);
          } catch (error) {
            
            this.setLoadingState(LoadingState.Error);
        }
    };
}
