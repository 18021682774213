"use strict";
/**
 * @file Created on Tue Sep 01 2020
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author Tomas Leity
 */

var ForecastMsgKeys;

(function (ForecastMsgKeys) {
  ForecastMsgKeys["EmptyPromoLoad"] = "fsr-empty-promotion-load";
  ForecastMsgKeys["EmptyPromoSave"] = "fsr-empty-promotion-save";
})(ForecastMsgKeys = exports.ForecastMsgKeys || (exports.ForecastMsgKeys = {}));