import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  font-size: 90%;\n  min-height: ", "px;\n  line-height: ", "px;\n  padding: 0 ", "px;\n  border-radius: ", ";\n  border: 1px solid ", ";\n  text-align: center;\n  cursor: pointer;\n  transition: all 0.5s;\n  margin-right: ", "rem;\n  white-space: nowrap;\n\n  svg,\n  img {\n    top: 3px;\n    position: relative;\n    height: 19px;\n  }\n  svg.right {\n    right: -10px;\n  }\n  svg.left {\n    left: -10px;\n  }\n\n  .btn-description {\n    font-size: 70%;\n    color: ", ";\n    line-height: 15px;\n    margin-top: -10px;\n    padding-bottom: 5px;\n    text-transform: uppercase;\n  }\n\n  ", ";\n  ", ";\n\n  ", ";\n  ", ";\n\n  ", ";\n\n  &:disabled {\n    cursor: inherit;\n    background-color: ", ";\n    color: ", ";\n    border-color: ", ";\n    &:hover {\n      background-color: ", ";\n      border-color: ", ";\n    }\n    svg {\n      fill: ", ";\n    }\n    .btn-description {\n      fill: ", ";\n    }\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  color: ", ";\n  border-color: ", ";\n  &:hover {\n    background-color: ", ";\n    border-color: ", ";\n  }\n  svg {\n    fill: ", ";\n  }\n  .btn-description {\n    color: ", ";\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  border: 0;\n  background-color: transparent;\n  color: ", ";\n  svg {\n    fill: ", ";\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  svg {\n    right: -10px;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  svg {\n    left: -10px;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  border: 0;\n  background-color: transparent;\n  color: ", ";\n  svg {\n    fill: ", ";\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  color: ", ";\n  &:hover {\n    background-color: ", ";\n    border-color: ", ";\n  }\n  svg {\n    fill: ", ";\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  color: ", ";\n  border-color: ", ";\n  &:hover {\n    background-color: ", ";\n    border-color: ", ";\n  }\n  svg {\n    fill: ", ";\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  color: ", ";\n  border-color: ", ";\n  &:hover {\n    background-color: ", ";\n    border-color: ", ";\n  }\n  svg {\n    fill: ", ";\n  }\n  .btn-description {\n    color: ", ";\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  min-height: ", "px;\n  line-height: ", "px;\n  padding: 0 ", "px;\n  border-radius: ", ";\n  font-size: 80%;\n\n  svg {\n    top: 2px;\n    position: relative;\n    height: ", "px;\n  }\n\n  .ag-root & {\n    /* spacial height of Tiny input for AgGrid */\n    max-height: 22px;\n    line-height: 22px;\n    top: -2px;\n    position: relative;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
import { darken } from 'polished';
import styled, { css, withTheme } from 'styled-components';
import { ButtonColor } from '../ButtonColor';
import { ButtonSize } from '../ButtonSize';
var ButtonTiny = css(_templateObject(), function (props) {
  return props.theme.button.tinyHeight;
}, function (props) {
  return props.theme.button.tinyHeight - 2;
}, function (props) {
  return props.iconButton ? 0 : props.theme.button.tinyHeight;
}, function (props) {
  return props.theme.common.borderRadius;
}, function (props) {
  return props.theme.button.tinyHeight / 2;
});
var ButtonSecondary = css(_templateObject2(), function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return darken(0.05, props.theme.colors.secondary);
}, function (props) {
  return darken(0.1, props.theme.colors.secondary);
}, function (props) {
  return darken(0.15, props.theme.colors.secondary);
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return props.theme.colors.inverse;
});
var ButtonLight = css(_templateObject3(), function (props) {
  return props.theme.colors.background;
}, function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return darken(0.1, props.theme.colors.background);
}, function (props) {
  return darken(0.05, props.theme.colors.secondary);
}, function (props) {
  return props.theme.colors.secondary;
});
var ButtonOutline = css(_templateObject4(), function (props) {
  return props.theme.colors.paper;
}, function (props) {
  return props.theme.colors.link;
}, function (props) {
  return darken(0.05, props.theme.colors.paper);
}, function (props) {
  return darken(0.1, props.theme.colors.border);
}, function (props) {
  return props.theme.colors.link;
});
var ButtonTransparent = css(_templateObject5(), function (props) {
  return props.theme.colors.link;
}, function (props) {
  return props.theme.colors.link;
});
var IconLeft = css(_templateObject6());
var IconRight = css(_templateObject7());
var ButtonTransparentDark = css(_templateObject8(), function (props) {
  return props.theme.colors.text;
}, function (props) {
  return props.theme.colors.text;
});
var ButtonActive = css(_templateObject9(), function (props) {
  return props.theme.colors.primary;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return darken(0.05, props.theme.colors.primary);
}, function (props) {
  return props.theme.colors.primary;
}, function (props) {
  return darken(0.05, props.theme.colors.primary);
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return props.theme.colors.inverse;
});
/**
 * Returns colored button
 * @param color
 */

var getColoredButton = function getColoredButton(color) {
  switch (color) {
    case ButtonColor.Light:
      return ButtonLight;

    case ButtonColor.Outline:
      return ButtonOutline;

    case ButtonColor.Transparent:
      return ButtonTransparent;

    case ButtonColor.TransparentDark:
      return ButtonTransparentDark;

    default:
      return ButtonSecondary;
  }
};
/**
 * Create an Button component
 */


var ButtonStyled = styled.button(_templateObject10(), function (props) {
  return props.theme.button.height;
}, function (props) {
  return props.theme.button.height - 2;
}, function (props) {
  return props.iconButton ? 0 : props.theme.button.height / 1.5;
}, function (props) {
  return props.theme.button.borderRadius;
}, function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.spacing.spacer / 4;
}, function (props) {
  return props.theme.colors.text;
}, function (props) {
  return props.iconLeft ? IconLeft : null;
}, function (props) {
  return props.iconRight ? IconRight : null;
}, function (props) {
  return props.buttonSize === ButtonSize.Tiny ? ButtonTiny : null;
}, function (props) {
  return getColoredButton(props.buttonColor);
}, function (props) {
  return props.isActive ? ButtonActive : null;
}, function (props) {
  return props.theme.colors.muted;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return darken(0.05, props.theme.colors.muted);
}, function (props) {
  return props.theme.colors.muted;
}, function (props) {
  return props.theme.colors.muted;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return props.theme.colors.inverse;
});
export default withTheme(ButtonStyled);