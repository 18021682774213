/**
 * @file Created on Tue Mar 15 2020
 * @author MHl
 */

import * as React from 'react';
import {
  AgGrid,
  AgGridWrapper,
  Page,
  FormGroupInline,
  Button,
  getPath,
  translate,
  ConfirmBox,
  BoxStatus,
  Circle,
  CircleWidth,
  ButtonColor,
  Divider,
  DividerType,
} from '@logio/common-fe';
import {PageNamesEnum} from '../../shared/localization/PageNamesEnum';
import {Observer, observer} from 'mobx-react';
import {EndSelloutsDetailPageStore, IRouteParams} from '../../stores/pages/Sellouts/EndSelloutsDetailPageStore';
import {PagePathsEnum} from '../../shared/localization/PagePathsEnum';
import {Link, RouteComponentProps} from 'react-router-dom';

/**
 * End sellout detail page
 */

@observer
class EndSelloutsDetailPage extends React.Component<RouteComponentProps<IRouteParams>> {
  store = new EndSelloutsDetailPageStore(this.props.history, this.props.match, this.props.match.params.id);

  componentWillMount() {
    this.store.load();
  }
  render() {
    return (
      <Observer>
        {() => (
          <Page
            titleKey={this.store.termination && this.store.termination.name}
            loadingState={this.store.loadingState}
            backButtonPath={getPath(PagePathsEnum.EndSellouts)}
          >
            {this.store.termination && this.renderHeader()}
            {this.renderPriceListGrid()}

            <AgGridWrapper>
              <AgGrid
                getRowNodeId={(data) => data.SelloutTerminationItem_id}
                rowData={this.store.terminationItemsRowData}
                columnDefs={this.store.terminationItemsColumnDefinitions}
                gridId="SelloutTerminationItems"
                quickView
                height={this.store.renderSummaryTable ? '650px' : undefined}
                onGridReady={this.store.onGridReady}
                showBulkRow={true}
                pinnedTopRowData={[]}
                sizeColumnsToFit
                rowClassRules={this.store.rowClassRulesReadOnly}
              />
            </AgGridWrapper>

            <ConfirmBox
              hidden={this.store.isConfirmModalHidden}
              title={translate('terminate-sellout')}
              onAccept={this.store.confirmTermination}
              close={this.store.hideConfirmModal}
              children={<div className="mb1">{`${translate('terminate-sellout-confirmation')}`}</div>}
            />
            <ConfirmBox
              hidden={this.store.isCancellModalHidden}
              title={translate('cancell-sellout-termination')}
              onAccept={this.store.cancelTermination}
              close={this.store.hideCancellModal}
              children={<div className="mb1">{`${translate('cancell-sellout-termination-confirmation')}`}</div>}
            />
          </Page>
        )}
      </Observer>
    );
  }

  renderHeader(): JSX.Element {
    return (
      <BoxStatus>
        <div className="status-bar-item">
          <Circle color={translate(this.store.termination.state)} width={CircleWidth.Big} />
          <span className="ml1 status-bar-value">{translate(this.store.termination.state)}</span>
        </div>
        <Divider type={DividerType.StatusVertical} />
        <div className="flex-grow-1">&nbsp;</div>
        {this.renderHeaderButtons()}
      </BoxStatus>
    );
  }

  renderHeaderButtons() {
    if (this.store.renderHeaderButtons) {
      return (
        <FormGroupInline>
          <Button type="button" buttonColor={ButtonColor.Light} onClick={this.store.showCancellModal}>
            {translate('Cancel')}
          </Button>

          <Button
            type="button"
            buttonColor={ButtonColor.Secondary}
            onClick={this.store.showConfirmModal}
            disabled={!this.store.enableConfirmButton}
          >
            {translate('action_Sellouts_Termination_confirm-button')}
          </Button>
        </FormGroupInline>
      );
    }
    return null;
  }

  renderPriceListGrid(): JSX.Element {
    if (this.store.renderSummaryTable) {
      return (
        <AgGridWrapper>
          <AgGrid
            rowData={this.store.terminationSummaryRowData}
            columnDefs={this.store.terminationSummaryColumnDefinitions}
            gridId="SelloutTerminationSummary"
            quickView
            height="250px"
            onGridReady={this.store.onGridReady}
            sizeColumnsToFit
            rowClassRules={this.store.rowClassRules}
          />
        </AgGridWrapper>
      );
    }
  }
}

export default EndSelloutsDetailPage;
