"use strict";
/*
 * Created on 01.02.2019
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Common types of entity changes. This enum is used only for simplification of logging. Change type
 * in EntityChange class is string, so modules can use another values.
 *
 * @author MSu
 */

var EntityChangeType;

(function (EntityChangeType) {
  EntityChangeType["Create"] = "CREATE";
  EntityChangeType["Update"] = "UPDATE";
  EntityChangeType["Delete"] = "DELETE";
  EntityChangeType["WorkflowTransition"] = "WF_TRANSITION";
})(EntityChangeType = exports.EntityChangeType || (exports.EntityChangeType = {}));