/**
 * @file Created on Thu May 09 2019
 * @author LZe
 * @author SKu
 */

import {AgGrid, FormGroup} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';
import {ReleaseSelloutDetailCategoryComponentStore} from '../../../stores/components';

export interface Props {
  store: ReleaseSelloutDetailCategoryComponentStore;
  /** Should be true if component used for export page */
  export?: boolean;
}

/**
 * Release sellout detail category bottom ag-grid
 */
const ReleaseSelloutDetailCategoryCategoryTotalOverview: React.SFC<Props> = observer((props: Props) => {
  return (
    <FormGroup>
      <AgGrid
        columnDefs={props.store.categoryTotalsColumnDefs}
        rowData={props.store.categoryTotalsRowData}
        gridId={'ReleaseSelloutDetailCategoryCategoryTotalOverview'}
        onFilterChanged={props.store.onFilterChanged}
        onGridReady={props.store.onItemOverviewGridReady}
        getRowNodeId={({ReleaseDetailCategory_productId: id}) => id}
        pinnedTopRowData={[]}
        rowSelection="multiple"
        showBulkRow
        quickView
        rowClassRules={{
          'bg-error': props.store.isReleaseItemStartDateBeforeTomorrow,
          'bg-read-only': props.store.rowIsReadOnly
        }}
      />
    </FormGroup>
  );
});

export default ReleaseSelloutDetailCategoryCategoryTotalOverview;
