/**
 * @author VBr
 */

import {CodebookLookupSequence, StructPropBuilder, WorkflowCaseInfo} from '@logio/common-be-fe';
import {
  AgGrid,
  AgGridWrapper,
  BoxTabContainer,
  Button,
  ButtonSize,
  Field,
  Form,
  GroupActions,
  Loader,
  LoadingState,
  Messages,
  Page,
  translate,
} from '@logio/common-fe';
import {Observer, observer} from 'mobx-react';
import * as React from 'react';
import {Col, Row} from 'react-flexbox-grid';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {WorkflowCasesPageStore} from '../../../stores/pages/Settings/WorkflowAdministration/WorkflowCasesPageStore';
import Tabs from './Tabs';

@observer
class WorkflowCasesPage extends React.Component {
  store = new WorkflowCasesPageStore();

  componentWillMount() {
    this.store.load();
  }

  get schema() {
    const builder = new StructPropBuilder(WorkflowCaseInfo.name);

    return {
      workflowDefinition: builder.opt(builder.lookup('workflowDefinition', CodebookLookupSequence.WorkflowDefinition)),
    };
  }

  render() {
    return (
      <Page titleKey={PageNamesEnum.WorkflowAdministration} showMessages={false}>
        <Observer>
          {() => (
            <AgGridWrapper>
              <Messages />
              <Tabs />
              <BoxTabContainer>
                {this.store.loadingState === LoadingState.Pending && <Loader />}
                {this.store.loadingState === LoadingState.Success && (
                  <>
                    <Form
                      formLayoutVertical
                      descriptions={this.schema}
                      onSubmit={this.store.updateFiler}
                      onChange={this.store.updateFiler}
                    >
                      <Row>
                        <Col xs={9}>
                          <GroupActions selectedRowsCount={this.store.selectedRowsCount}>
                            {({disabled}) => (
                              <>
                                <Button
                                  buttonSize={ButtonSize.Tiny}
                                  onClick={this.store.terminateCases}
                                  disabled={disabled}
                                >
                                  {translate('workflow_case-terminate')}
                                </Button>
                                <Button
                                  buttonSize={ButtonSize.Tiny}
                                  onClick={this.store.abandonCases}
                                  disabled={disabled}
                                >
                                  {translate('workflow_case-abandon')}
                                </Button>
                              </>
                            )}
                          </GroupActions>
                        </Col>
                        <Col xs={3}>
                          <Field
                            name="workflowDefinition"
                            fieldNoLabel
                            fieldPlaceholder={translate('forms_select_WorkflowCaseInfo_workflowDefinition')}
                          />
                        </Col>
                      </Row>
                    </Form>
                    <AgGrid
                      rowData={this.store.rowData}
                      columnDefs={this.store.columnDefs}
                      onSelectionChanged={this.store.onSelectionChanged}
                      onGridReady={this.store.onGridReady}
                      isExternalFilterPresent={this.store.isExternalFilterPresent}
                      doesExternalFilterPass={this.store.doesExternalFilterPass}
                      gridId="WorkflowCasesPage"
                      rowSelection="multiple"
                    />
                  </>
                )}
              </BoxTabContainer>
            </AgGridWrapper>
          )}
        </Observer>
      </Page>
    );
  }
}

export default WorkflowCasesPage;
