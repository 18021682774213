/**
 * @file Created on Fri Oct 05 2018
 * @author SKu
 */

import {PricingPermissions} from '@logio/common-be-fe';
import {
  Button,
  ButtonColor,
  ButtonSize,
  FormGroupInline,
  getPath,
  GroupActions,
  Messages,
  Page,
  translate,
  WithPermission,
} from '@logio/common-fe';
import {Observer, observer} from 'mobx-react';
import * as React from 'react';
import {Col, Row} from 'react-flexbox-grid';
import {match, RouteComponentProps, withRouter} from 'react-router';
import ReleaseHeader from '../../components/Release/ReleaseHeader/ReleaseHeader';
import {SettingsComponent} from '../../components/SettingsOverview/SettingsComponent';
import {IRouteParams} from '../../routes-app';
import {PagePathsEnum} from '../../shared/localization/PagePathsEnum';
import {CopySettingsModalEnum} from '../../stores/components/SettingsOverview/SettingsComponentStore';
import {ReleaseSettingsPageStore} from '../../stores/pages/Release/ReleaseSettingsPageStore';
import {Link} from "react-router-dom";

export interface Props {
  match: match<IRouteParams>;
}

/**
 * Release settings page
 */
@observer
class ReleaseSettingsPage extends React.Component<Props & RouteComponentProps<ReleaseSettingsPage>> {
  store = new ReleaseSettingsPageStore();

  componentWillMount() {
    this.store.load(this.props.match.params.id);
  }

  get releaseHeaderItems() {
    return [
      <WithPermission key="open" permissionTypes={[PricingPermissions.REGULAR_RELEASE_OPEN_DISCARD]}>
        {!this.store.pageReadOnly && (
          <Button buttonSize={ButtonSize.Tiny} onClick={this.store.openRelease(this.props.history.push)}>
            {translate('Open')}
          </Button>
        )}
      </WithPermission>,
    ];
  }

  /**  Returns Buttons that could change Release Settings */
  get generalSettingsActions() {
    return (
      <>
        <Button buttonSize={ButtonSize.Tiny} onClick={this.store.includeCategories}>
          {translate('include')}
        </Button>
        <Button buttonSize={ButtonSize.Tiny} buttonColor={ButtonColor.Light} onClick={this.store.excludeCategories}>
          {translate('exclude')}
        </Button>
      </>
    );
  }

  /**  Returns Buttons that could change Release Matrix Table Settings */
  get matrixTableActions() {
    return (
      <>
        <GroupActions selectedRowsCount={this.store.settingsComponentStore.selectedCategoryIds.length}>
          {({disabled}) => (
            <>
              <Button
                buttonSize={ButtonSize.Tiny}
                disabled={disabled}
                onClick={this.store.settingsComponentStore.getOpenModalEvent(CopySettingsModalEnum.CopyBetweenZones)}
              >
                {translate('copy-between-zones')}
              </Button>
              <Button
                disabled={disabled}
                buttonSize={ButtonSize.Tiny}
                onClick={this.store.settingsComponentStore.getOpenModalEvent(CopySettingsModalEnum.CopyFromCategory)}
              >
                {translate('copy-from-category')}
              </Button>
            </>
          )}
        </GroupActions>
      </>
    );
  }

  render() {
    const title = this.store.isLoadingSuccess && this.store.release.name;
    return (
      <Page
        titleKey={title}
        titleComponent={<Link to={getPath(PagePathsEnum.ReleaseDetail, this.props.match.params.id)}>{title}</Link>}
        loadingState={this.store.loadingState}
        showMessages={false}
        backButtonPath={getPath(PagePathsEnum.ReleasesRegular)}
      >
        <Observer>
          {() => (
            <>
              <ReleaseHeader store={this.store.releaseHeaderStore} customItems={this.releaseHeaderItems} />
              <Messages />
              {!this.store.pageReadOnly && (
                <>
                  <Row>
                    <Col xs={6}>
                      <WithPermission permissionTypes={[PricingPermissions.REGULAR_RELEASE_SETTINGS_EDIT]}>
                        {this.generalSettingsActions}
                      </WithPermission>
                    </Col>
                    <Col xs={6}>
                      <WithPermission permissionTypes={[PricingPermissions.REGULAR_RELEASE_SETTINGS_DETAIL_EDIT]}>
                        <FormGroupInline right>{this.matrixTableActions}</FormGroupInline>
                      </WithPermission>
                    </Col>
                  </Row>
                </>
              )}
              <SettingsComponent store={this.store.settingsComponentStore} gridId="PricingReleaseSettingsPage" />
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

export default withRouter(ReleaseSettingsPage);
