"use strict";
/*
 * Created on 10 02 2018
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Enumerates all lookup sequences for {@link CodebookItem codebook items} in one place. Lookup sequence is used in
 * JSON API, try to use semantic sequences (for example instead of camel case, use words delimiters by dash).
 *
 * This enumeration does not follow the strict convention for naming string literals, so do not use it as inspiration.
 *
 * @author JMe
 * @author Radek Beran
 */

var CodebookLookupSequence;

(function (CodebookLookupSequence) {
  /**
   * Stuff stored in data store.
   */

  /**
   * lookup sequence used in UI to retrieve all available competitors (base)
   */
  CodebookLookupSequence["Competitor"] = "sequences/competitors";
  /**
   * lookup sequence used in UI to retrieve all available competitors priorities (base)
   */

  CodebookLookupSequence["CompetitorsPriority"] = "sequences/competitors-priorities";
  /**
   * lookup sequence used in UI to retrieve all available categories (base)
   */

  CodebookLookupSequence["Category"] = "sequences/categories";
  /**
   * lookup sequence used in UI to retrieve all products in category
   */

  CodebookLookupSequence["Product"] = "sequences/products";
  /**
   * lookup sequence used in UI to retrieve all promotions
   */

  CodebookLookupSequence["Promotion"] = "sequences/promotions";
  /**
   * lookup sequence used in UI to retrieve all available currencies (base)
   */

  CodebookLookupSequence["Currency"] = "sequences/currencies";
  /**
   * lookup sequence used in UI to retrieve all available categories with lowest level
   */

  CodebookLookupSequence["CategoryLowestLevel"] = "sequences/categories?level=6";
  /**
   * lookup sequence used in UI to retrieve all available group of sites. (base)
   */

  CodebookLookupSequence["GroupOfSites"] = "sequences/group-of-sites";
  /**
   * lookup sequence used in UI to retrieve all available report formats.
   */

  CodebookLookupSequence["ReportFormats"] = "sequences/report-formats";
  /**
   * lookup sequence used in UI to retrieve all available internal sites based on price zone id.
   */

  CodebookLookupSequence["InternalSites"] = "sequences/internal-sites";
  /**
   * lookup sequence used in UI to retrieve all available group of sites for prize zone creation.
   */

  CodebookLookupSequence["GroupOfSitesNewPriceZone"] = "sequences/group-of-sites?mode=ZONE";
  /**
   * lookup sequence used in UI to retrieve derived site format of group of sites.
   */

  CodebookLookupSequence["GroupOfSitesSiteFormat"] = "sequences/group-of-sites/site-format";
  /**
   * lookup sequence used in UI to retrieve price list types (base).
   */

  CodebookLookupSequence["PriceListType"] = "sequences/price-list-types";
  /**
   * lookup sequence used in UI to retrieve all price zones (base).
   */

  CodebookLookupSequence["PriceZone"] = "sequences/price-zones";
  /**
   * lookup sequence used in UI Create Release to retrieve all price zones based on PricingType.
   */

  CodebookLookupSequence["ReleasePriceZones"] = "sequences/release-price-zones";
  /**
   * lookup sequence used in UI to retrieve all user roles used in pricing application.
   */

  CodebookLookupSequence["PricingRole"] = "sequences/pricing-roles";
  /**
   * lookup sequence used in UI to retrieve all price zones compatible with given price zone for given competitor.
   */

  CodebookLookupSequence["CompatiblePriceZone"] = "sequences/compatible-price-zones";
  /**
   * lookup sequence used in UI to retrieve approved releases that can serve as source of settings (FIXME base?)
   */

  CodebookLookupSequence["SourceReleaseId"] = "sequences/source-releases";
  /**
   * lookup sequence used in UI to retrieve all active and external suppliers (base).
   */

  CodebookLookupSequence["Supplier"] = "sequences/suppliers";
  /**
   * lookup sequence used in UI to retrieve all tiers (base).
   */

  CodebookLookupSequence["Tier"] = "sequences/tiers";
  /**
   * lookup sequence used in UI to retrieve price codes for given priceListType.
   */

  CodebookLookupSequence["PriceCode"] = "sequences/price-codes";
  /**
   * lookup sequence used in UI to retrieve price zones in certain release.
   */

  CodebookLookupSequence["PriceZonesInRelease"] = "sequences/price-zones-in-release";
  /**
   * lookup sequence used in UI to retrieve product sensitivities.
   */

  CodebookLookupSequence["ProductSensitivity"] = "sequences/product-sensitivity";
  /**
   * lookup sequence used in UI to retrieve workflow cases
   */

  CodebookLookupSequence["WorkflowCase"] = "sequences/workflow-case";
  /**
   * lookup sequence used in UI to retrieve workflow definitions
   */

  CodebookLookupSequence["WorkflowDefinition"] = "sequences/workflow-definition";
  /**
   * lookup sequence used in UI to retrieve generated promotion code.
   */

  CodebookLookupSequence["PromotionCode"] = "sequences/generate-promotion-code";
  /**
   * lookup sequence used in UI to filtering by store spec
   */

  CodebookLookupSequence["StoreSpecification"] = "sequences/store-specification";
  /**
   * lookup sequence used in UI to retrieve all promotion types.
   */

  CodebookLookupSequence["PromotionTypes"] = "sequences/promotion-types";
  /**
   * lookup sequence used in UI ro retrieve all promotion years.
   */

  CodebookLookupSequence["PromotionYears"] = "sequences/promotion-years";
  /**
   * lookup sequence used in UI ro retrieve all promotion select filter values.
   */

  CodebookLookupSequence["PromotionSelect"] = "sequences/promotion-select";
  /**
   * lookup sequence used in UI to retrieve all promotion areas
   */

  CodebookLookupSequence["PromotionAreas"] = "sequences/promotion-areas";
  /**
   * lookup sequence used in UI to retrieve promotion areas assigned by the specific promotions.
   */

  CodebookLookupSequence["PromotionAreasAssigned"] = "sequences/promotion-areas?mode=ASSIGNED";
  /**
   * lookup sequence used in UI to retrieve unassigned promotion areas assignable by the specific promotions.
   */

  CodebookLookupSequence["PromotionAreasUnassigned"] = "sequences/promotion-areas?mode=UNASSIGNED";
  /**
   * lookup sequence used in UI ro retrieve all window select filter values.
   */

  CodebookLookupSequence["WindowSelect"] = "sequences/window-select";
  /**
   * lookup sequence used in UI to retrieve all window types.
   */

  CodebookLookupSequence["WindowTypes"] = "sequences/window-types";
  /**
   * lookup sequence used in UI ro retrieve all window years.
   */

  CodebookLookupSequence["WindowYears"] = "sequences/window-years";
  /**
   * lookup sequence used in UI to retrieve all themes.
   */

  CodebookLookupSequence["Theme"] = "sequences/themes";
  /**
   * lookup sequence used in UI to retrieve product category assign to CM user.
   */

  CodebookLookupSequence["WindowPortfolio"] = "sequences/window-portfolio";
  /**
   * lookup sequence used in UI to retrieve values for distribution model for product in window.
   */

  CodebookLookupSequence["DistributionModel"] = "sequences/distribution-model";
  /**
   * lookup sequence used in UI to retrieve values for placement type for space planning
   */

  CodebookLookupSequence["PlacementTypes"] = "sequences/placement-types";
  /**
   * lookup sequence used in UI to retrieve actual category plan options for supplied product
   */

  CodebookLookupSequence["SuppliedProductCategoryPlans"] = "sequences/supplied-product/category-plans";
  /**
   * lookup sequence used in UI to retrieve actual supplier options for supplied product
   */

  CodebookLookupSequence["SuppliedProductSuppliers"] = "sequences/supplied-product/suppliers";
  /**
   * Simple enums, not stored in data store.
   */

  /**
   * lookup sequence to list all agency types
   */

  CodebookLookupSequence["AgencyType"] = "enums/agency-types";
  /**
   * lookup sequence to list all release actions.
   */

  CodebookLookupSequence["ReleaseActions"] = "enums/release-actions";
  /**
   * lookup sequence used in UI to retrieve release types (base).
   */

  CodebookLookupSequence["ReleaseType"] = "enums/release-types";
  /**
   * lookup sequence used in UI to retrieve release workflow types (base).
   */

  CodebookLookupSequence["ReleaseWorkflowType"] = "enums/release-workflow-types";
  /**
   * lookup sequence used in UI to retrieve release states (base).
   */

  CodebookLookupSequence["ReleaseState"] = "enums/release-states";
  /**
   * lookup sequence used in UI to retrieve release pricing types (base).
   */

  CodebookLookupSequence["ReleasePricingType"] = "enums/release-pricing-types";
  /**
   * lookup sequence to list all agency types
   */

  CodebookLookupSequence["SelloutReleaseType"] = "enums/sellout-release-types";
  /**
   * lookup sequence used in UI to retrieve all constraint types (base).
   */

  CodebookLookupSequence["ConstraintType"] = "enums/constraint-types";
  /**
   * lookup sequence used in UI to retrieve final price default values.
   */

  CodebookLookupSequence["FinalPriceDefault"] = "enums/final-price-default";
  /**
   * lookup sequence of FinalPriceType enum
   */

  CodebookLookupSequence["FinalPriceType"] = "enums/final-price-type";
  /**
   * lookup sequence used in UI to retrieve all price zones (base).
   */

  CodebookLookupSequence["OptimizationGoalType"] = "enums/optimization-goal-types";
  /**
   * lookup sequence used in UI to retrieve all research types (base).
   */

  CodebookLookupSequence["ResearchType"] = "enums/research-types";
  /**
   * lookup sequence used in UI to retrieve all strategy types (base).
   */

  CodebookLookupSequence["StrategyType"] = "enums/strategy-types";
  /**
   * lookup sequence used in UI to retrieve all upload states (base).
   */

  CodebookLookupSequence["UploadState"] = "enums/upload-states";
  /**
   * lookup sequence used in UI to retrieve all upload types (base).
   */

  CodebookLookupSequence["UploadType"] = "enums/upload-types";
  /**
   * lookup sequence used in UI to retrieve all price types (base).
   */

  CodebookLookupSequence["PriceClassification"] = "enums/price-flags";
  /**
   * lookup sequence used in UI to retrieve all sellout types (base)
   */

  CodebookLookupSequence["SelloutType"] = "enums/sellout-types";
  /**
   * lookup sequence used in UI to retrieve all site types (base).
   */

  CodebookLookupSequence["SiteType"] = "enums/site-types";
  /**
   * lookup sequence used in UI to retrieve options for segmentation select box.
   */

  CodebookLookupSequence["SegmentationItem"] = "enums/segmentation-model-item";
  /**
   * lookup sequence used in UI to retrieve axis for segmentation model - profit lift.
   */

  CodebookLookupSequence["SegmentationProfitLift"] = "enums/segmentation-profit-lift";
  /**
   * lookup sequence used in UI to retrieve axis for segmentation model - sales lift.
   */

  CodebookLookupSequence["SegmentationSalesLift"] = "enums/segmentation-sales-lift";
  /**
   * lookup sequence used in UI to retrieve values for unit back discount realization.
   */

  CodebookLookupSequence["UnitBackDiscountRealization"] = "enums/unit-back-discount-realization";
  /**
   * lookup sequence used in UI to retrieve values for promotion workflow states.
   */

  CodebookLookupSequence["PromotionWorkflowState"] = "enums/promotion-workflow-states";
  /**
   * lookup sequence used in UI to retrieve values for window workflow states.
   */

  CodebookLookupSequence["WindowWorkflowState"] = "enums/window-workflow-states";
  /**
   * lookup sequence used in UI to retrieve values for simulation states.
   */

  CodebookLookupSequence["SimulationState"] = "enums/simulation-states";
  /**
   * lookup sequence used in UI to retrieve values for window item lock types.
   */

  CodebookLookupSequence["LockType"] = "enums/window-items-lock-types";
  /**
   * lookup sequence used in UI to retrieve values for window item pos types.
   */

  CodebookLookupSequence["PosType"] = "enums/window-items-pos-types";
  /**
   * lookup sequence used in UI to retrieve values for window item pos multibuy variant .
   */

  CodebookLookupSequence["MultiBuyVariant"] = "enums/window-items-pos-multibuy-variant";
  /**
   * lookup sequence used in UI to retrieve values for promotion split calculation option.
   */

  CodebookLookupSequence["PromotionSplitOptions"] = "enums/promotion-split-option";
  CodebookLookupSequence["ForecastSimulationUpdateType"] = "enums/forecast-simulation-update-type";
  /** levels of aggregation for tree in reports */

  CodebookLookupSequence["TreeLevel"] = "enums/tree-level";
  /**
   * lookup sequence used to retrieve value for window change type in forecast change report
   */

  CodebookLookupSequence["WindowChangeType"] = "enums/window-change-type";
  /**
   * lookup sequence used to retrieve value for product change type
   */

  CodebookLookupSequence["ProductChangeType"] = "enums/product-change-type";
  /**
   * lookup sequence used to retrieve value for price termination states
   */

  CodebookLookupSequence["PriceTerminationStates"] = "enums/price-termination-states";
  /**
   * lookup sequence used in UI to retrieve values for window workflow states in Forecast Coordinator Volume Changed report.
   */

  CodebookLookupSequence["FCWindowWorkflowState"] = "enums/fc-window-workflow-states";
  /** LOG-6647 */

  CodebookLookupSequence["CostPriceFormat"] = "enums/cost-price-format";
  /** For CreationSource enum builder */

  CodebookLookupSequence["CreationSource"] = "enums/creation-source";
  /**
   * lookup sequence used to retrieve enumeration of sellout impact report aggregation levels
   */

  CodebookLookupSequence["SelloutImpactReportLevels"] = "enums/sellout-impact-report-levels";
})(CodebookLookupSequence = exports.CodebookLookupSequence || (exports.CodebookLookupSequence = {}));