/**
 * @file Created on Mon Oct 15 2018
 * @author SKu
 */

import {CodebookLookupSequence, StructPropBuilder} from '@logio/common-be-fe';
import {
  Button,
  ButtonColor,
  CheckboxGroupAdapter,
  Field,
  Form,
  FormFieldTypeEnum,
  FormGroupInline,
  Modal,
  ModalProps,
  OptionsProvider,
  SelectAdapter,
  SelectOptions,
  T,
  translate,
} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';

export interface CopySettingsModalProps extends ModalProps {
  /** If releaseId passed, form will be rendered specially for Release */
  releaseId: string;
  /** For group actions */
  selectedCategoriesCount?: string;
  /** Fires on Form submit */
  onSubmit: (values) => any;
  /**
   * Default modal - for Copy from Zones, if value is true,
   *  will be rendered for Copy from category
   */
  fromCategory: boolean;
}

export const CopySettingsModal: React.SFC<CopySettingsModalProps> = observer((props: CopySettingsModalProps) => {
  /**
   * Return description for Form depending if matrix table used for releases or not
   */
  const getDescription = () => {
    const builder = new StructPropBuilder('Settings');

    const copyBasicDesc = {
      sourceZoneId: builder.lookup('sourceZoneId', CodebookLookupSequence.PriceZone),
      targetZoneIds: builder.arr(builder.lookup('targetZoneIds', CodebookLookupSequence.PriceZone)),
    };

    const copyInReleaseBasicDesc = {
      releaseId: builder.lookup('releaseId', `${CodebookLookupSequence.SourceReleaseId}?releaseId=${props.releaseId}`),
      sourceZoneId: builder
        .lookup('sourceZoneId', CodebookLookupSequence.PriceZonesInRelease)
        .withDescription({mandatoryParameter: true}),
      targetZoneIds: builder.arr(
        builder.lookup('targetZoneIds', `${CodebookLookupSequence.PriceZonesInRelease}?releaseId=${props.releaseId}`),
      ),
    };

    const fromCategoryDesc = {
      sourceCategoryId: builder.lookup('sourceCategoryId', CodebookLookupSequence.Category),
    };

    if (props.fromCategory) {
      Object.assign(copyBasicDesc, fromCategoryDesc);
      Object.assign(copyInReleaseBasicDesc, fromCategoryDesc);
    }

    return props.releaseId ? copyInReleaseBasicDesc : copyBasicDesc;
  };

  return (
    <Modal title={props.title} hidden={props.hidden} close={props.close}>
      <Form descriptions={getDescription()} onSubmit={props.onSubmit} initialValues={{releaseId: props.releaseId}}>
        <h3>
          <T id={'From'} />
        </h3>
        {props.fromCategory && <Field name="sourceCategoryId" />}
        {props.releaseId ? (
          <>
            <Field name="releaseId" />
            <OptionsProvider
              name="sourceZoneId"
              component={(options) => (
                <Field
                  name="sourceZoneId"
                  fieldHide={{releaseId: [undefined]}}
                  component={(p) => SelectAdapter('MatrixTable_sourceZoneId', options)(p)}
                />
              )}
              dependsOn={{releaseId: 'releaseId'}}
            />
          </>
        ) : (
          <Field name="sourceZoneId" />
        )}

        <h3>
          <T id={'To'} />
        </h3>
        {props.selectedCategoriesCount && (
          <h3 className="mb1">{translate('settings-categories-selected', props.selectedCategoriesCount)}</h3>
        )}
        <OptionsProvider
          name="targetZoneIds"
          component={(options: SelectOptions) => (
            <Field
              name="targetZoneIds"
              component={(props) => CheckboxGroupAdapter('targetZoneIds', options.options, null, null, true)(props)}
              fieldType={FormFieldTypeEnum.CheckBoxGroup}
              fieldHide={{sourceZoneId: [undefined]}}
            />
          )}
          dependsOn={!props.fromCategory && {omittedZoneId: 'sourceZoneId'}}
        />

        <FormGroupInline right={true} className="mt1">
          <Button buttonColor={ButtonColor.Light} onClick={props.close}>
            {translate('Cancel')}
          </Button>
          <Button type="submit">{translate('Copy')}</Button>
        </FormGroupInline>
      </Form>
    </Modal>
  );
});
