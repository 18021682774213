"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Enum of acceptable Lock type values of the product.
 * @author OPt
 */

var LockTypeEnum;

(function (LockTypeEnum) {
  LockTypeEnum["AllPreDeliveries"] = "ALL_PRE-DELIVERIES";
  LockTypeEnum["AllDeliveries"] = "ALL_DELIVERIES";
  LockTypeEnum["None"] = "NONE";
})(LockTypeEnum = exports.LockTypeEnum || (exports.LockTypeEnum = {}));