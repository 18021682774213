"use strict";
/*
 * Created on 06 17 2019
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @author Jaroslav Sevcik
 */

var DashboardTaskState;

(function (DashboardTaskState) {
  DashboardTaskState["open"] = "OPEN";
  DashboardTaskState["finished"] = "FINISHED";
})(DashboardTaskState = exports.DashboardTaskState || (exports.DashboardTaskState = {}));