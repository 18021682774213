import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  .rc-slider {\n    width: calc(100% - 26px);\n    margin-left: 7px;\n    margin-right: 9px;\n  }\n\n  .rc-slider-rail {\n    border: 1px solid ", ";\n    border-radius: 3px;\n    background-color: ", ";\n    height: 6px;\n  }\n\n  .rc-slider-track {\n    background-color: ", ";\n    height: 6px;\n  }\n\n  .rc-slider-step {\n    display: none;\n  }\n\n  .rc-slider-handle {\n    width: 25px;\n    height: 25px;\n    border-radius: 2px;\n    border: 1px solid ", "\n    background-color: ", ";\n    margin-top: -10px;\n\n    &:before {\n      content: '|';\n      position: absolute;\n      left: 6px;\n      top: 1px;\n      color: ", ";;\n    }\n    &:after {\n      content: '|';\n      position: absolute;\n      right: 6px;\n      top: 1px;\n      color: ", ";;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author SKu
 */
import styled, { withTheme } from 'styled-components';
/**
 * Create Modal component
 */

var SliderStyled = styled.div(_templateObject(), function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.colors.background;
}, function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.colors.background;
}, function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.colors.secondary;
});
export default withTheme(SliderStyled);