import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0 1rem 0 1rem;\n  font-weight: bold;\n\n  a {\n    color: ", ";\n  }\n\n  svg {\n    fill: ", ";\n  }\n\n  & > * {\n    padding: ", "rem\n      ", "rem;\n  }\n\n  .alert-icon {\n  }\n\n  .alert-content {\n  }\n\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  color: ", ";\n\n  a {\n    color: ", ";\n  }\n  svg {\n    fill: ", ";\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  color: ", ";\n\n  a {\n    color: ", ";\n  }\n  svg {\n    fill: ", ";\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Tue Dec 17 2019
 * @author Tomas Leity
 */
import styled, { css, withTheme } from 'styled-components';
import { AppAlertType } from '../AppAlertType';
var AlertError = css(_templateObject(), function (props) {
  return props.theme.colors.error;
});
var AlertWarning = css(_templateObject2(), function (props) {
  return props.theme.colors.warning;
});
var AlertSuccess = css(_templateObject3(), function (props) {
  return props.theme.colors.success;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return props.theme.colors.inverse;
});
var AlertInfo = css(_templateObject4(), function (props) {
  return props.theme.colors.info;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return props.theme.colors.inverse;
}, function (props) {
  return props.theme.colors.inverse;
});
/**
 * Create an Alert component that'll render an <Alert>
 */

var AppAlertStyled = styled.div(_templateObject5(), function (props) {
  return props.theme.colors.text;
}, function (props) {
  return props.theme.colors.text;
}, function (props) {
  return props.theme.spacing.spacer / 2;
}, function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.type === AppAlertType.Error ? AlertError : null;
}, function (props) {
  return props.type === AppAlertType.Warning ? AlertWarning : null;
}, function (props) {
  return props.type === AppAlertType.Success ? AlertSuccess : null;
}, function (props) {
  return props.type === AppAlertType.Info ? AlertInfo : null;
});
export default withTheme(AppAlertStyled);