/**
 * @file Created on Tue Sep 25 2018
 * @author LZe
 */

import {getPath, TabMenu, translate} from '@logio/common-fe';
import * as React from 'react';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {Permission, PricingPermissions} from '@logio/common-be-fe';

const Tabs: React.SFC<{}> = (props) => {
  const priceZoneTab = {
    to: getPath(PagePathsEnum.PriceZones),
    content: translate(PageNamesEnum.PriceZones),
    exact: true,
  };
  const competitorInZoneTab = {
    to: getPath(PagePathsEnum.PriceZonesCompetitorsInZones),
    content: translate(PageNamesEnum.PriceZonesCompetitorsInZones),
  };

  return <TabMenu tabs={[priceZoneTab, competitorInZoneTab]} />;
};

export default Tabs;
