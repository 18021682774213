/**
 * @file Created on Wed Sep 26 2018
 * @author BPo
 */
import {PricingPermissions, ReleaseState} from '@logio/common-be-fe';
import {
  Button,
  getPath,
  Icon,
  IconColor,
  IconType,
  LoadingState,
  Page,
  ProgressBar,
  TabNavigationBlockable,
  translate,
  WithPermission,
  ButtonColor,
  ConfirmBox,
} from '@logio/common-fe';
import {computed} from 'mobx';
import {observer, Observer} from 'mobx-react';
import * as React from 'react';
import {Grid} from 'react-flexbox-grid';
import {match} from 'react-router';
import {Link} from 'react-router-dom';
import ReleaseHeader from '../../../components/Release/ReleaseHeader/ReleaseHeader';
import {CopySettingsModal} from '../../../components/SettingsOverview/CopySettingsModal';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {
  MatrixTableModalEnum,
  MatrixTablePageStore,
} from '../../../stores/pages/Settings/GeneralSettings/MatrixTablePageStore';
import {MatrixTable} from './MatrixTable/MatrixTable';

export interface MatrixTableRouteParams {
  categoryId: string;
  releaseId?: string;
}

export interface MatrixTableProps {
  match: match<MatrixTableRouteParams>;
}

@observer
class MatrixTablePage extends React.Component<MatrixTableProps> {
  store = new MatrixTablePageStore();

  componentWillMount() {
    this.store.load(this.props.match.params.categoryId, this.props.match.params.releaseId);
  }

  @computed
  public get releaseHeaderItems() {
    return [
      <WithPermission permissionTypes={[PricingPermissions.REGULAR_RELEASE_RECALCULATE]} key={'recalculate'}>
        {this.recalculateHeaderItem}
      </WithPermission>,
    ];
  }

  /** Turn on category data recalculation (using polling) */
  @computed
  public get recalculateHeaderItem() {
    return (
      this.store.isRecalculationRequired &&
      !this.store.isRecalculateActionFired && (
        <>
          <Icon iconType={IconType.calc} iconColor={IconColor.Text} onClick={() => this.store.recalculate()} />
          <Button buttonColor={ButtonColor.TransparentDark} onClick={() => this.store.setManualPrices()}>
            {translate('MANUAL_PRICES')}
            <Icon
              iconType={this.store.manualPrices ? IconType.checkboxChecked : IconType.checkboxUnchacked}
              viewBox={17}
            />
          </Button>
        </>
      )
    );
  }

  render() {
    const {categoryId, releaseId} = this.props.match.params;
    const categoryPath = releaseId
      ? this.store.release && this.store.release.state === ReleaseState.New
        ? getPath(PagePathsEnum.ReleaseSettings, this.store.release.id)
        : getPath(PagePathsEnum.ReleaseDetailCategory, releaseId, categoryId)
      : getPath(PagePathsEnum.GeneralSettingsCategories);
    const title =
      this.store.productCategory && this.store.release && releaseId
        ? `${translate(PageNamesEnum.MatrixTable)} - ${this.store.release.name} - ${this.store.productCategory.name}`
        : this.store.productCategory
          ? `${translate(PageNamesEnum.MatrixTable)} - ${this.store.productCategory.name}`
          : translate(PageNamesEnum.MatrixTable);

    return (
      <Page
        titleKey={title}
        titleComponent={<Link to={categoryPath}>{title}</Link>}
        loadingState={this.store.loadingState}
        backButtonPath={
          this.props.match.params.releaseId
            ? getPath(PagePathsEnum.ReleaseSettings, this.props.match.params.releaseId)
            : getPath(PagePathsEnum.GeneralSettingsCategories)
        }
      >
        <Observer>
          {() => (
            <>
              {releaseId && (
                <ReleaseHeader store={this.store.releaseHeaderStore} customItems={this.releaseHeaderItems} />
              )}
              {(this.store.pollingHelper.pollingState === LoadingState.Pending ||
                this.store.isRecalculateActionFired) && (
                <div className="mb2">
                  <ProgressBar
                    percentage={this.store.pollingHelper.progress || 0}
                    label={'release-category-recalculating-now'}
                  />
                </div>
              )}
              <ConfirmBox
                hidden={!this.store.confirmModal}
                title={translate('DISCARD_MANUAL_PRICES_HEADER')}
                onAccept={() => {
                  this.store.hideConfirmModal();
                  this.store.updateManualPricesAndRecalculate();
                }}
                close={() => this.store.hideConfirmModal()}
                children={<div className="mb1">{`${translate('DISCARD_MANUAL_PRICES_DESCRIPTION')}`}</div>}
                confirmButtonLabel={'CONFIRM'}
                discardButtonLabel={'CANCEL'}
              />
              <Grid fluid className="relative">
                {this.store.isSettingsEditable() && (
                  <Button
                    className="absolute right1"
                    style={{marginTop: '-1rem'}}
                    onClick={this.store.getOpenModalEvent(MatrixTableModalEnum.CopyBetweenZones)}
                  >
                    {translate('copy-between-zones')}
                  </Button>
                )}
                <TabNavigationBlockable
                  fullWidth={true}
                  content={this.store.tables.map((priceZone) => {
                    const initialValues = this.store.getInitialValues(priceZone.id);
                    const placeholderValues = this.store.getPlaceholderValues(priceZone.id);
                    const optimizationGoalsSettings =
                      placeholderValues.optimizationGoalsSettings || initialValues.optimizationGoalsSettings;

                    return {
                      tabName: priceZone.name,
                      tabContent: (
                        <>
                          <MatrixTable
                            store={this.store}
                            categoryId={categoryId}
                            releaseId={releaseId}
                            priceZone={priceZone}
                            optimizationGoalsSettings={optimizationGoalsSettings}
                          />
                        </>
                      ),
                    };
                  })}
                />

                <CopySettingsModal
                  title={translate(`copy-settings-${MatrixTableModalEnum.CopyBetweenZones}`)}
                  fromCategory={false}
                  hidden={this.store.isModalHidden(MatrixTableModalEnum.CopyBetweenZones)}
                  releaseId={this.props.match.params.releaseId}
                  close={this.store.hideModal}
                  onSubmit={this.store.onCopyBetweenZonesSubmit}
                />
              </Grid>
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

export default MatrixTablePage;
