/**
 * Alert can be rendered in these types
 */
export var AlertType;

(function (AlertType) {
  AlertType["Error"] = "ERROR";
  AlertType["Warning"] = "WARNING";
  AlertType["Success"] = "SUCCESS";
  AlertType["Info"] = "INFO";
})(AlertType || (AlertType = {}));