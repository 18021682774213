/**
 * @file Created on Fri Oct 05 2018
 * @author SKu
 */

import {PriceComment, ValidationError} from '@logio/common-be-fe';
import {Button, Field, Form, FormGroup, Modal, translate} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';
import {Col, Grid, Row} from 'react-flexbox-grid';
import moment from 'moment';

export interface Props extends FormProps {
  /** Modal hidden prop */
  hidden: boolean;
  /** Function to close modal */
  close: () => void;
  /** Flag that defines if user could add comments */
  editable?: boolean;
}

const CommentsModal: React.SFC<Props> = observer((props: Props) => {
  /** Transform array of comments to JSX array */
  const getComments = () =>
    props.initialValues && props.initialValues.comments
      ? props.initialValues.comments.map((comment, index) => (
          <li className="mb1" key={index}>{`${comment.text} (${comment.username})`}</li>
        ))
      : [<strong key="no-comment">{translate('comments-do-not-exist')}</strong>];

  return (
    <Modal title={translate('release-item-comment')} hidden={props.hidden} close={props.close} zIndex={10000}>
      <>
        {props.editable ? (
          <Grid fluid>
            <Row>
              <Col xs={12} md={6}>
                {getComments()}
              </Col>
              <Col xs={12} md={6}>
                <ReleaseDetailCategoryCommentsForm
                  onCommentSubmit={props.onCommentSubmit}
                  initialValues={props.initialValues}
                />
              </Col>
            </Row>
          </Grid>
        ) : (
          getComments()
        )}
      </>
    </Modal>
  );
});

export default CommentsModal;

export interface FormProps {
  /** Fire when user submits new comment */
  onCommentSubmit: (values: any) => any;
  initialValues: any;
}

const ReleaseDetailCategoryCommentsForm: React.SFC<FormProps> = observer((props: FormProps) => {
  /** Create form description */
  const descriptions = {
    text: PriceComment.schema.text,
    persistent: PriceComment.schema.persistent,
    deleteOn: PriceComment.schema.deleteOn,
  };

  const validateDeleteOn = (value: any) => {
      const tomorrow = moment().subtract(1, "days");
      if (value <= tomorrow) {
        return new ValidationError('err-deleteDate');
      }
      return null;
  };

  return (
    <Form descriptions={descriptions} onSubmit={props.onCommentSubmit} initialValues={props.initialValues}>
      <Field name={'text'} />
      <Field name={'persistent'} />
      <Field name={'deleteOn'} validate={validateDeleteOn}/>
      <FormGroup noLabel>
        <Button>{translate('Save')}</Button>
      </FormGroup>
    </Form>
  );
});
