"use strict";
/*
 * Created on 06 17 2019
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * These types define what is the task about - what users has to do to finish it.
 *
 * @author Jaroslav Sevcik
 */

var DashboardTaskType;

(function (DashboardTaskType) {
  DashboardTaskType["newProductsConfigurationRequired"] = "NEW_PRODUCTS_CONFIGURATION_REQUIRED";
  DashboardTaskType["newRecommendedPairsAvailable"] = "NEW_RECOMMENDED_PAIRS_AVAILABLE";
  DashboardTaskType["competitorDataCleaningRequired"] = "COMPETITOR_DATA_CLEANING_REQUIRED";
})(DashboardTaskType = exports.DashboardTaskType || (exports.DashboardTaskType = {}));