/**
 * @file Created on Fri Oct 12 2018
 * @author SKu
 */

import {AbstractRelease, PriceZone, ReleaseState} from '@logio/common-be-fe';
import {
  AbstractReleaseStore,
  FamilyStore,
  FormElOption,
  GeneralConfigurationsStore,
  IReleaseImpactFilter,
  MessageStore,
  PriceZoneStore,
  ProductCategoryStore,
  ProductSensitivityStore,
  ProductStore,
  rootStore,
  StoreName,
  SupplierStore,
  TierStore,
  translate,
  ExtendedGridReadyEvent,
  GridViewApi,
  GridViewApiEventEnum,
} from '@logio/common-fe';
import {List} from 'immutable';
import {GridApi, GridReadyEvent} from 'ag-grid-community';
import {action, computed, observable, runInAction} from 'mobx';
import {DetailCategoryModalEnum} from './ReleaseDetailCategoryComponentStore';
import {SelloutDetailCategoryModalEnum} from './ReleaseSelloutDetailCategoryComponentStore';

export abstract class AbstractReleaseDetailCategoryComponentStore {
  /**
   * If categoryId not passed, we could say, that component used for export page
   * @param messages {MessageStore} store from PageStore
   * @param releaseId current release id
   * @param categoryId passed only DetailCategoryPage, for export page - used this.setFilteredCategoryIds!
   */
  constructor(public messages: MessageStore, public releaseId: string, public categoryId?: string) {}

  /** Stores */
  public abstract releaseStore: AbstractReleaseStore;
  public productCategoryStore = rootStore.getStore(StoreName.ProductCategory) as ProductCategoryStore;
  protected productStore = rootStore.getStore(StoreName.Product) as ProductStore;
  protected priceZoneStore = rootStore.getStore(StoreName.PriceZone) as PriceZoneStore;
  protected tierStore = rootStore.getStore(StoreName.Tier) as TierStore;
  protected supplierStore = rootStore.getStore(StoreName.Supplier) as SupplierStore;
  protected productSensitivityStore = rootStore.getStore(StoreName.ProductSensitivity) as ProductSensitivityStore;
  protected familyStore = rootStore.getStore(StoreName.Family) as FamilyStore;
  protected configurationsStore = rootStore.getStore(StoreName.GeneralConfigurations) as GeneralConfigurationsStore;

  private minAgGridHeight = 100;
  private minAgGridRowHeight = 27;

  /** Current release */
  public abstract get release(): AbstractRelease;

  /**
   * Cleans everything, that should be cleaned - timeouts, global events...
   */
  public destroy() {
    // no op
  }

  /** If true, allows user to edit category
   *  Next values:
   *  finalPrice
   *  priceCode
   *  comments
   *  exported
   */
  @observable
  public editMode: boolean = false;

  public editable = () => {
    return !!this.editMode;
  };

  /**
   * <p>Gets national price zone, used for both sellout/regular releases. Currently, only for column name of regular price.</p>
   *
   * @returns national price zone
   */
  @computed
  public get nationalZone(): PriceZone {
    return this.priceZoneStore.nationalZone;
  }

  /** Used only when release state is Released */
  @observable
  public impactFilter: IReleaseImpactFilter | undefined;

  /** SelectOptions for PriceCodeEditor */
  protected priceCodeOptions: FormElOption[] = [];

  /** GridApis */
  protected totalOverviewGridViewApi: GridViewApi;
  protected itemOverviewGridViewApi: GridViewApi;

  protected totalOverviewGridApi: GridApi;
  protected itemOverviewGridApi: GridApi;
  protected itemsModalGridApi: GridApi;

  /** Functions fire onGridReady, and set gridApi to the class variable */
  public onTotalOverviewGridReady = (params: ExtendedGridReadyEvent) => {
    this.totalOverviewGridApi = params.api;
    this.totalOverviewGridViewApi = params.gridViewApi;
    if (this.release && this.release.state === ReleaseState.ExportChecking) {
      this.totalOverviewGridApi.showLoadingOverlay();
    }
  };

  public onItemOverviewGridReady = (params: ExtendedGridReadyEvent) => {
    this.itemOverviewGridApi = params.api;
    this.itemOverviewGridViewApi = params.gridViewApi;

    this.itemOverviewGridViewApi.addEvent(
      GridViewApiEventEnum.onSettingsCreate,
      this.totalOverviewGridViewApi.consumeSettingsCreate,
    );
    this.itemOverviewGridViewApi.addEvent(
      GridViewApiEventEnum.onSettingsUpdate,
      this.totalOverviewGridViewApi.consumeSettingsUpdate,
    );
    this.itemOverviewGridViewApi.addEvent(
      GridViewApiEventEnum.onSettingsDelete,
      this.totalOverviewGridViewApi.consumeSettingsDelete,
    );
    this.itemOverviewGridViewApi.addEvent(
      GridViewApiEventEnum.onSettingsSwitch,
      this.totalOverviewGridViewApi.consumeSettingsSwitch,
    );
    this.itemOverviewGridViewApi.addEvent(
      GridViewApiEventEnum.onSettingsReset,
      this.totalOverviewGridViewApi.comsumeSettingsReset,
    );

    this.itemOverviewGridApi.onFilterChanged();
    if (this.release && this.release.state === ReleaseState.ExportChecking) {
      this.itemOverviewGridApi.showLoadingOverlay();
    }
  };

  public onItemsModalGridReady = (params: GridReadyEvent) => {
    this.itemsModalGridApi = params.api;
  };

  /**
   * Sets height of Total AgGrid table
   * @param totalDataLength
   * @param visibleRowsCount
   */
  public getAgGridTotalHeight = (totalDataLength: number, visibleRowsCount: number) => {
    return `${
        totalDataLength >= visibleRowsCount
          ? this.minAgGridHeight + this.minAgGridRowHeight * (visibleRowsCount - 1)
          : totalDataLength <= 1
            ? this.minAgGridHeight
            : this.minAgGridHeight + this.minAgGridRowHeight * (totalDataLength - 1)
      }px`;
  };

  /** Variable used for opening and closing modals */
  @observable
  public modalShown: DetailCategoryModalEnum | SelloutDetailCategoryModalEnum;

  /** Checks if modal should be hidden. */
  public isModalHidden = (name: DetailCategoryModalEnum | SelloutDetailCategoryModalEnum) => this.modalShown !== name;

  /**
   * Negate modalHiddenProp
   * @param title - name of the modalHidden prop that should be negated
   */
  @action
  public openModal = (modalType: DetailCategoryModalEnum | SelloutDetailCategoryModalEnum) => {
    this.modalShown = modalType;
  };

  /** HOF for toggle modal, used when u get event besides parameter
   * @param title - name of the modalHidden prop that should be negated
   */
  public getOpenModalEvent = (modalTypes: DetailCategoryModalEnum | SelloutDetailCategoryModalEnum) => () => {
    this.openModal(modalTypes);
  };

  /** Function used in case if endpoint return error */
  @action
  public hideModal = () => {
    this.modalShown = undefined;
    this.itemsModalGridApi = null;
  };

  /**
   * Sets editMode to new Value
   * Allows parent store to change the editMode value
   * @param newEditModeValue
   */
  public setEditMode = (newEditModeValue?: boolean) => {
    runInAction(() => (this.editMode = newEditModeValue));
  };

  /** Return approved categories for Export page */
  protected get allCategories(): string[] {
    return this.release.releaseParts.map((releasePart) => releasePart.productCategoryId).toArray();
  }

  /** Return approved categories for Export page */
  protected get approvedCategories(): string[] {
    return this.release
      .approvedReleaseParts()
      .map((releasePart) => releasePart.productCategoryId)
      .toArray();
  }

  /** Sets warning from releaseParts(works only for ReleaseDetailPage) */
  protected setCategoryWarnings = () => {
    if (this.categoryId) {
      const warnings = this.release.requireReleasePartForCategory(this.categoryId).validationResult;
      if (warnings) {
        this.messages.setValidationResult(warnings);
      }
    }
  };

  /** All following code, handles loading! */
  public load = async (getImpactFilter?: () => IReleaseImpactFilter): Promise<void> => {
    if (getImpactFilter) {
      this.impactFilter = getImpactFilter();
    }
    try {
      await Promise.all([this.handleBasicRequest(), this.handleCommonIndependentRequest()]);
      this.setCategoryWarnings();
    } catch (error) {
      await Promise.reject(error);
    }
  };

  /** Page basic loading */
  protected abstract handleBasicRequest: () => Promise<any>;

  /**
   * Independent request
   * it means that it is not depending on some previous request result
   * Fetches data for common product detail
   */
  protected handleCommonIndependentRequest = async () => {
    try {
      const promises: any[] = [
        this.tierStore.getAll(),
        this.familyStore.getAll(),
        this.supplierStore.getAll(),
        this.productSensitivityStore.getAll(),
        this.priceZoneStore.getAll(), // LOG-6017 Used even for sellout releases.
      ];
      await Promise.all(promises);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /** List of productIds used in this page(from releaseItems, etc.) */
  public abstract get productIds(): List<string>;

  /** Function fetch products that are included in ReleaseItem */
  protected fetchItemProducts = async (productsIds?: string[]): Promise<void> => {
    const productFilter = {ids: productsIds ? List(productsIds) : this.productIds, activeUnaware: true};
    try {
      await this.productStore.filter(productFilter);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /** Function finds select options for editing priceCodes depending on Release PriceType and release type */
  protected fetchPriceCodeOptions = async (): Promise<void> => {
    try {
      const priceCodeOptions = await this.releaseStore.getPriceCodeOptions();
      runInAction(() => (this.priceCodeOptions = priceCodeOptions));
    } catch (error) {
      return Promise.reject(error);
    }
  };

  protected handleUpdateError = async (error) => {
    // this.messages.setError(error);
    const oldReleaseState = this.release.state;
    await this.handleBasicRequest();
    if (this.release.state !== oldReleaseState) {
      this.messages.setWarning(translate('WARN_ReleaseStateHasChanged'));
    }
  };
}
