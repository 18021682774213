/**
 * @file Created on Mon Jan 28 2019
 * @author SKu
 */

import {
  PricingRoles,
  SelloutCategoryDiscount,
  SelloutDescription,
  SelloutReleaseType,
  ValidationError,
  PricingPermissions,
} from '@logio/common-be-fe';
import {
  AgGrid,
  AgGridWrapper,
  Button,
  Field,
  Form,
  FormGroup,
  FormGroupInline,
  OptionsProvider,
  SelectAdapter,
  SelectOptions,
  SelectValidated,
  TabNavigation,
  translate,
  withPermission,
} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';
import {SelloutSettingsComponentStore} from '../../stores/components';
import {categoryAutoGroupColumnDef} from '../../stores/pages/DataManagement/DataPairing/columnGenerators';

export interface ISelloutSettingsComponent {
  store: SelloutSettingsComponentStore;
}

/**
 * Sellout settings component
 * Settings dont influence any calculation,
 * used only as user helper on Crete Release Page
 */
export const SelloutSettingsComponent: React.SFC<ISelloutSettingsComponent> = observer(
  ({store}: ISelloutSettingsComponent) => {
    /** Adds settings to tabs for deadstock type */
    const getTabContent = () => {
      const tabContent = [
        {
          tabName: translate('sellout-settings-description'),
          tabContent: <SelloutDescriptionForm store={store} />,
        },
      ];
      if (store.selloutSettings && store.selloutSettings.selloutType === SelloutReleaseType.Deadstock) {
        tabContent.push({
          tabName: translate('sellout-settings-subsettings'),
          tabContent: <SelloutDeadstockSettingsGrid store={store} />,
        });
      }
      return tabContent;
    };
    return (
      <>
        <TabNavigation fullWidth={true} content={getTabContent()} />
      </>
    );
  },
);

export default SelloutSettingsComponent;

/** Form allows to edit sellout settings fields */
const SelloutDescriptionForm: React.SFC<ISelloutSettingsComponent> = observer((props: ISelloutSettingsComponent) => {
  /** Available user roles */
  const options = [
    {value: PricingRoles.ADMIN.name, label: `${PricingRoles.ADMIN.name}`},
    {value: PricingRoles.CATEGORY_MANAGER.name, label: `${PricingRoles.CATEGORY_MANAGER.name}`},
    {value: PricingRoles.SENIOR_CATEGORY_MANAGER.name, label: `${PricingRoles.SENIOR_CATEGORY_MANAGER.name}`},
    {value: PricingRoles.PRICE_MANAGER.name, label: `${PricingRoles.PRICE_MANAGER.name}`},
    {value: PricingRoles.COMMERCIAL_DIRECTOR.name, label: `${PricingRoles.COMMERCIAL_DIRECTOR.name}`},
    {value: PricingRoles.TRADE_DIRECTOR.name, label: `${PricingRoles.TRADE_DIRECTOR.name}`},
    {value: PricingRoles.CONTROLLING.name, label: `${PricingRoles.CONTROLLING.name}`},
    {value: PricingRoles.AUDITOR.name, label: `${PricingRoles.AUDITOR.name}`},
    {value: PricingRoles.SELLOUT_MANAGER.name, label: `${PricingRoles.SELLOUT_MANAGER.name}`},
    {value: PricingRoles.REPLE_MANAGER.name, label: `${PricingRoles.REPLE_MANAGER.name}`},
    {value: PricingRoles.GUEST.name, label: `${PricingRoles.GUEST.name}`},
    {value: PricingRoles.ASSISTANT.name, label: `${PricingRoles.ASSISTANT.name}`},
    {value: PricingRoles.MASTER_DATA.name, label: `${PricingRoles.MASTER_DATA.name}`},
  ];

  const getTableRow = (schemaKey: string) => {
    /** Editable field */
    let field: JSX.Element;
    switch (schemaKey) {
      case 'responsibilities':
        field = (
          <Field
            name={'responsibilities'}
            component={({input, meta}) => (
              <FormGroup noLabel>
                <SelectValidated
                  {...input}
                  multi
                  disabled={props.store.readOnly}
                  options={options}
                  validationError={meta.touched ? meta.error : null}
                />
              </FormGroup>
            )}
            validate={(value) => (!value ? new ValidationError('err-required') : null)}
          />
        );
        break;
      case 'workflowTypes':
        field = (
          <OptionsProvider
            name="workflowTypes"
            component={(o: SelectOptions) => (
              <Field
                name="workflowTypes"
                component={(p) => SelectAdapter('workflowTypes', o, true, props.store.readOnly, true)(p)}
              />
            )}
            dependsOn={{releaseType: 'releaseTypes'}}
          />
        );
        break;
      case 'releaseTypes':
        field = <Field name={schemaKey} fieldNoLabel fieldReadOnly />;
        break;
      default:
        field = <Field name={schemaKey} fieldNoLabel fieldReadOnly={props.store.readOnly} />;
    }

    return (
      <tr key={schemaKey}>
        <th className="w-20">{translate(`${SelloutDescription.schema[schemaKey].description.nameKey}`)}</th>
        <td>{field}</td>
      </tr>
    );
  };

  return (
    <Form
      descriptions={SelloutDescription.schema}
      onSubmit={props.store.onSelloutDescriptionSave}
      initialValues={props.store.convertDescriptionToFormValues()}
      formLayoutVertical
    >
      <table className="table table-stripedX w-100">
        <tbody>{Object.keys(SelloutDescription.schema).map((key) => getTableRow(key))}</tbody>
      </table>
      {(!props.store.readOnly && withPermission([PricingPermissions.SELLOUT_SETTINGS_EDIT])) && (
        <FormGroupInline right>
          <Button>{translate('Save')}</Button>
        </FormGroupInline>
      )}
    </Form>
  );
});

/** Deadstock settings tab */
const SelloutDeadstockSettingsGrid: React.SFC<ISelloutSettingsComponent> = observer(
  (props: ISelloutSettingsComponent) => (
    <>
      <div className="mb1">
        <AgGridWrapper>
          <AgGrid
            rowData={props.store.rowData}
            columnDefs={props.store.columnDefs}
            autoGroupColumnDef={categoryAutoGroupColumnDef}
            getDataPath={props.store.getDataPath}
            headerHeight={50}
            treeData
            gridId="SelloutSettingsGrid"
            quickView
            saveTreeDataExpansionId="CategoriesTree"
            deltaRowDataMode={true}
            enterMovesDownAfterEdit={false}
            getRowNodeId={(data) => data[SelloutCategoryDiscount.schema.cmCategoryId.description.nameKey]}
          />
        </AgGridWrapper>
      </div>
      {!props.store.readOnly && (
        <FormGroupInline right>
          <Button onClick={props.store.onSelloutDeadstockSave}>{translate('Save')}</Button>
        </FormGroupInline>
      )}
    </>
  ),
);
