import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n\n  .table {\n    margin-bottom: ", "rem;\n    font-size: ", ";\n    border-collapse: collapse;\n  }\n\n  .table th,\n  .table td {\n    padding: 5px ", "px;\n    border: 1px solid ", ";\n    height: ", "px;\n  }\n\n  .table .td-danger {\n    outline: 2px solid ", ";\n    outline-offset: -1px;\n  }\n\n  .table .td-warning {\n    background-color: ", ";\n  }\n\n  .table th {\n    text-transform: uppercase;\n    font-weight: normal;\n    background-color: ", ";\n    color: ", ";\n    text-align: left;\n  }\n\n  .table tr {\n    background-color: ", ";\n  }\n\n  .table-striped tbody tr:nth-child(even) {\n    background-color: ", ";\n  }\n\n  .table-striped tbody tr:nth-child(odd) {\n    background-color: ", ";\n  }\n\n\n  .ag-theme-balham{\n    background-color: transparent !important;\n    \n    .ag-root-wrapper-body{\n      background-color: white;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { css } from 'styled-components';
import { theme } from '../theme';
export var tables = css(_templateObject(), theme.spacing.spacer, theme.table.tableFontSize, theme.table.tablePadding, theme.colors.border, theme.table.tableRowHeight, theme.colors.error, theme.colors.warning, theme.colors.header, theme.colors.inverse, theme.colors.paper, theme.colors.even, theme.colors.odd);