"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var DecimalRoundingEnum;

(function (DecimalRoundingEnum) {
  /** Rounds away from zero */
  DecimalRoundingEnum[DecimalRoundingEnum["ROUND_UP"] = 0] = "ROUND_UP";
  /** Rounds towards zero */

  DecimalRoundingEnum[DecimalRoundingEnum["ROUND_DOWN"] = 1] = "ROUND_DOWN";
  /** Rounds towards Infinity */

  DecimalRoundingEnum[DecimalRoundingEnum["ROUND_CEIL"] = 2] = "ROUND_CEIL";
  /** Rounds towards -Infinity */

  DecimalRoundingEnum[DecimalRoundingEnum["ROUND_FLOOR"] = 3] = "ROUND_FLOOR";
  /** Rounds towards nearest neighbour. If equidistant, rounds away from zero */

  DecimalRoundingEnum[DecimalRoundingEnum["ROUND_HALF_UP"] = 4] = "ROUND_HALF_UP";
  /** Rounds towards nearest neighbour. If equidistant, rounds towards zero */

  DecimalRoundingEnum[DecimalRoundingEnum["ROUND_HALF_DOWN"] = 5] = "ROUND_HALF_DOWN";
  /** Rounds towards nearest neighbour. If equidistant, rounds towards even neighbour */

  DecimalRoundingEnum[DecimalRoundingEnum["ROUND_HALF_EVEN"] = 6] = "ROUND_HALF_EVEN";
  /** Rounds towards nearest neighbour. If equidistant, rounds towards Infinity */

  DecimalRoundingEnum[DecimalRoundingEnum["ROUND_HALF_CEIL"] = 7] = "ROUND_HALF_CEIL";
  /** Rounds towards nearest neighbour. If equidistant, rounds towards -Infinity */

  DecimalRoundingEnum[DecimalRoundingEnum["ROUND_HALF_FLOOR"] = 8] = "ROUND_HALF_FLOOR";
})(DecimalRoundingEnum = exports.DecimalRoundingEnum || (exports.DecimalRoundingEnum = {}));