import { action, computed, observable, runInAction } from 'mobx';
import { OptimizationGoal, OptimizationGoalsSettings, OptimizationGraph, StrategyGraphData } from '@logio/common-be-fe';
import {
  ChartProps,
  ChartValue,
  LoadingState,
  MessageStore,
  Omit,
  Polling,
  rootStore,
  SettingsStore,
  StoreName,
  translate,
} from '@logio/common-fe';
import { List } from 'immutable';

// TODO: support optimization graph messages (clearing, info, ...)
export class OptimizationStrategyStore {
  settingsStore = rootStore.getStore(StoreName.SettingsStore) as SettingsStore;

  messages: MessageStore;

  private polling = new Polling(2500);

  @observable
  loadingState: LoadingState;

  @observable
  responseResult: OptimizationGraph['result'];

  @observable
  pricingGoal: OptimizationGoal;

  @observable
  targetMargin: number;

  @observable
  updateTargetMargin: (value: number) => void;

  actualMarginPlotLineColor = "#000";
  optimalMarginPlotLineColor = "#c31515";
  targetMarginPlotLineColor = "#ff8000";

  constructor(messages: MessageStore) {
    this.messages = messages;
  }

  @computed
  get summary(): SummaryType {
    const optimalMargin = this.responseResult.optimalMargins.find((item) => {
      const itemEnum = OptimizationGoalsSettings.YDGToGoal(item.optimizationGoal);
      return itemEnum === this.pricingGoal;
    }).margin;
    const fromResponse = this.findClosestPointByMargin(this.responseResult.points, optimalMargin);
    return {
      ...fromResponse,
      diff: {
        profit: fromResponse.profit - this.responseResult.actualData.profit,
        revenue: fromResponse.revenue - this.responseResult.actualData.revenue,
        quantity: fromResponse.quantity - this.responseResult.actualData.quantity,
      },
    };
  }

  @computed
  get chartSeries(): ChartSeriesType {
    const group = this.responseResult.pointsInPercent.reduce(
      (acc, row) => {
        acc.profit.push([row.margin, row.profit]);
        acc.revenue.push([row.margin, row.revenue]);
        acc.quantity.push([row.margin, row.quantity]);
        return acc;
      },
      {profit: [], revenue: [], quantity: []},
    );
    const output = new Map();
    const actualMarginPlotLine = this.actualMarginPlotLine;
    const optimalMarginPlotLine = this.optimalMarginPlotLine;
    const targetMarginPlotLine = this.targetMarginPlotLine;

    output.set('OptimizationChart_profit', {data: group.profit, props: {events: {click: this.setTargetMarginFromChart}}});
    output.set('OptimizationChart_revenue', {data: group.revenue, props: {events: {click: this.setTargetMarginFromChart}}});
    output.set('OptimizationChart_quantity', {data: group.quantity, props: {events: {click: this.setTargetMarginFromChart}}});
    output.set('OptimizationChart_actualMargin', {
      props: {
        name: translate('OptimizationChart_actualMargin'),
        color: this.actualMarginPlotLineColor,
        dashStyle: 'shortdash',
        marker: {
          enabled: false
        },
        events: {
          legendItemClick(e) {
            if(this.visible) {
              this.chart.xAxis[0].removePlotLine('actualMargin_PlotLine');
            } else {
              this.chart.xAxis[0].addPlotLine(actualMarginPlotLine);
            }
          }
        }
      }
    });
    output.set('OptimizationChart_optimalMargin', {
      props: {
        name: translate('OptimizationChart_optimalMargin'),
        color: this.optimalMarginPlotLineColor,
        dashStyle: 'shortdash',
        marker: {
          enabled: false
        },
        events: {
          legendItemClick(e) {
            if (this.visible) {
              this.chart.xAxis[0].removePlotLine('optimalMargin_PlotLine');
            } else {
              this.chart.xAxis[0].addPlotLine(optimalMarginPlotLine);
            }
          }
        }
      }
    });
    output.set('OptimizationChart_targetMargin', {
      props: {
        name: translate('OptimizationChart_targetMargin'),
        color: this.targetMarginPlotLineColor,
        dashStyle: 'shortdash',
        marker: {
          enabled: false
        },
        events: {
          legendItemClick(e) {
            if (this.visible) {
              this.chart.xAxis[0].removePlotLine('targetMargin_PlotLine');
            } else {
              this.chart.xAxis[0].addPlotLine(targetMarginPlotLine);
            }
          }
        }
      }
    });

    return output;
  }

  @action
  setPricingGoal = (value: OptimizationGoal) => {
    this.pricingGoal = value;
  };

  @action
  setTargetMargin = (value: number) => {
    this.targetMargin = value;
  };

  @action
  setUpdateTargetMargin = (updateTargetMargin) => {
    this.updateTargetMargin = updateTargetMargin;
  };

  findClosestPointByMargin = (points: List<StrategyGraphData>, margin: number): StrategyGraphData => {
    return points.reduce((prev: any, curr: any) => {
      return (Math.abs(curr.margin - margin) < Math.abs(prev.margin - margin) ? curr : prev);
    });
  };

  setTargetMarginFromChart = (e) => {
    let x;
    if ('point' in e) {
      x = Number(e.point.x).toFixed(2);
    } else if ('xAxis' in e) {
      x = Number(e.xAxis[0].value).toFixed(2);
    }
    if (x) {
      this.setTargetMargin(x);
      this.updateTargetMargin(x);
    }
  };

  load = (
    categoryId: string,
    releaseId: string,
    priceZoneId: string,
    optimizationGoalsSettings: OptimizationGoal,
  ) => {
    this.setPricingGoal(optimizationGoalsSettings);

    return async () => {
      try {
        runInAction(() => (this.loadingState = LoadingState.Pending));

        const response = await this.settingsStore.settingsLayer.initOptimizationGraph({
          categoryId,
          releaseId,
          priceZoneId,
        });

        if (response.message === 'computing') {
          this.polling.startPolling(this.fetchPromise(response.optimizationId), this.onPollResponse);
        } else if (response.message === 'failed') {
          throw new Error('computing (optimization graph) failed');
        }
      } catch (error) {
        this.polling.stopPolling();
        runInAction(() => (this.loadingState = LoadingState.Error));
        // this.messages.setError(error);
      }
    };
  };

  @action
  removeData = () => {
    this.responseResult = undefined;
  };

  fetchPromise = (optimizationId: string) => async () =>
    await this.settingsStore.settingsLayer.getOptimizationGraph(optimizationId);

  onPollResponse = async (error?, response?: OptimizationGraph) => {
    if (error || response.message === 'failed') {
      this.messages.setError(error || 'computing of the optimization graph failed');
      this.polling.stopPolling();
    } else if (response.message === 'ready') {
      this.polling.stopPolling();
      runInAction(() => {
        this.loadingState = LoadingState.Success;
        this.responseResult = response.result;
      });
    }
  };

  @computed
  get xMin(): number {
    let min = Number.MAX_VALUE;
    for (const o of this.responseResult.pointsInPercent) {
      if (o.margin < min) {
        min = o.margin;
      }
    }
    for (const o of this.responseResult.optimalMargins) {
      if (o.margin < min) {
        min = o.margin;
      }
    }
    if (this.responseResult.actualData.margin < min) {
      min = this.responseResult.actualData.margin;
    }
    if (this.targetMargin < min) {
      min = this.targetMargin;
    }
    return min;
  }

  @computed
  get xMax(): number {
    let max = Number.MIN_VALUE;
    for (const o of this.responseResult.pointsInPercent) {
      if (o.margin > max) {
        max = o.margin;
      }
    }
    for (const o of this.responseResult.optimalMargins) {
      if (o.margin > max) {
        max = o.margin;
      }
    }
    if (this.responseResult.actualData.margin > max) {
      max = this.responseResult.actualData.margin;
    }
    if (this.targetMargin > max) {
      max = this.targetMargin;
    }
    return max;
  }

  @computed
  get actualMarginPlotLine() {
    return {
      id: 'actualMargin_PlotLine',
      value: this.responseResult.actualData.margin,
      color: this.actualMarginPlotLineColor,
      width: 1,
      dashStyle: 'shortdash',
      events: {
        click: (e) => {
          e.stopPropagation();
          this.setTargetMargin(this.responseResult.actualData.margin);
          this.updateTargetMargin(this.responseResult.actualData.margin);
        },
      }
    }
  };

  @computed
  get optimalMarginPlotLine() {
    return {
      id: 'optimalMargin_PlotLine',
      value: this.summary.margin,
      color: this.optimalMarginPlotLineColor,
      width: 1,
      dashStyle: 'shortdash',
      events: {
        click: (e) => {
          e.stopPropagation();
          this.setTargetMargin(this.summary.margin);
          this.updateTargetMargin(this.summary.margin);
        },
      }
    }
  };

  @computed
  get targetMarginPlotLine() {
    return {
      id: 'targetMargin_PlotLine',
      value: this.targetMargin,
      color: this.targetMarginPlotLineColor,
      width: 1,
      dashStyle: 'shortdash',
      events: {
        click: (e) => {
          e.stopPropagation();
          return this.setTargetMarginFromChart(e);
        },
      }
    }
  };

  @computed
  get chartSettings(): Omit<ChartProps, 'data' | 'renderer'> {
    return {
      showToolTip: true,
      showLegend: true,
      yVisible: true,
      xVisible: true,
      xAxisTitle: translate('OptimizationChart_margin'),
      xAxisOptions: {
        gridLineDashStyle: 'Dash',
        gridLineWidth: 1,
        minPadding: "0.01",
        min: this.xMin,
        max: this.xMax
      },
      yAxisOptions: {
        labels: {format: '{value} %'},
        gridLineDashStyle: 'Dash',
      },
      xPlotLines: [
        this.actualMarginPlotLine,
        this.optimalMarginPlotLine,
        this.targetMarginPlotLine
      ],
      tooltipOptions: {
        shared: true,
        valueDecimals: 1,
        valueSuffix: ' %',
      },
      chart: {
        events: {
          click: this.setTargetMarginFromChart
        }
      }
    };
  }
}

interface SummaryType {
  margin: number;
  profit: number;
  revenue: number;
  quantity: number;
  diff: {
    profit: number;
    revenue: number;
    quantity: number;
  };
}

type ChartSeriesType = Map<string, ChartValue[]>;

/**
 * Adds spaces between thousands in number string
 * @param x
 */
export const numberWithSpaces = (x: string): string => {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
};

export const formatPrice = (value: number) => numberWithSpaces(value.toFixed(2)) + ' CZK';

export const formatQuantity = (value: number) => numberWithSpaces(value.toFixed(2)) + ' units';

export const formatPercents = (value: number) => value + ' %';
