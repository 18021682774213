import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  fill: ", ";\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  fill: ", ";\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  fill: ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  fill: ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  fill: ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  fill: ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  fill: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  fill: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
import styled, { css, withTheme } from 'styled-components';
import { IconColor } from '../IconColor';
var IconText = css(_templateObject(), function (props) {
  return props.theme.colors.text;
});
var IconPrimary = css(_templateObject2(), function (props) {
  return props.theme.colors.stateCategoryApproved;
});
var IconSecondary = css(_templateObject3(), function (props) {
  return props.theme.colors.secondary;
});
var IconLight = css(_templateObject4(), function (props) {
  return props.theme.colors.muted;
});
var IconInverse = css(_templateObject5(), function (props) {
  return props.theme.colors.inverse;
});
var IconError = css(_templateObject6(), function (props) {
  return props.theme.colors.stateCanceled;
});
var IconGreen = css(_templateObject7(), function (props) {
  return props.theme.colors.greenCellTextAndArrow;
});
var IconRed = css(_templateObject8(), function (props) {
  return props.theme.colors.redCellTextAndArrow;
});
/**
 * Returns colored icon
 * @param color
 */

var getColoredIcon = function getColoredIcon(color) {
  switch (color) {
    case IconColor.Text:
      return IconText;

    case IconColor.Primary:
      return IconPrimary;

    case IconColor.Secondary:
      return IconSecondary;

    case IconColor.Light:
      return IconLight;

    case IconColor.Inverse:
      return IconInverse;

    case IconColor.Error:
      return IconError;

    case IconColor.Green:
      return IconGreen;

    case IconColor.Red:
      return IconRed;

    default:
      return IconPrimary;
  }
};
/**
 * Create an Icon component that'll render an <Icon> on the top of input
 */


var IconStyled = styled.span(_templateObject9(), function (props) {
  return props.disabled ? IconLight : getColoredIcon(props.iconColor);
});
export default withTheme(IconStyled);