"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var WindowImportErrorSeverityEnum;

(function (WindowImportErrorSeverityEnum) {
  WindowImportErrorSeverityEnum["NORMAL"] = "normal";
  WindowImportErrorSeverityEnum["CRITICAL"] = "critical";
})(WindowImportErrorSeverityEnum = exports.WindowImportErrorSeverityEnum || (exports.WindowImportErrorSeverityEnum = {}));