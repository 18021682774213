"use strict";
/**
 * Created on 10 26 2020
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * <p>Enumerates possible message keys related to management of sellout release module (not the termination of sellout prices, there is
 * special for these, see {@link SelloutTerminationMsgKeyEnum}), ie. not just {@link SelloutRelease entity} as name of enumeration could
 * say. Within description of an individual may be suggested severity of a msg.</p>
 *
 * <p><b>Enumerated keys should be directly used for localisation keys. This is the reason that the values of the individual items violate
 * the naming convention for a value of an enumerated value.</b></p>
 *
 * @author JMe
 */

var SelloutReleaseMsgKeyEnum;

(function (SelloutReleaseMsgKeyEnum) {
  /**
   * Message key related to situation when client tries to perform price simulation, but release has incorrect state.
   * <br/>
   * Suggested severity: error.
   */
  SelloutReleaseMsgKeyEnum["ReleasePricesSimulationInvalidState"] = "sellout_release_management__open_release_confirmation_invalid_state";
  /**
   * Message key related to situation when client tries to perform price simulation, but release has incorrect workflow type.
   * <br/>
   * Suggested severity: error.
   */

  SelloutReleaseMsgKeyEnum["ReleasePricesSimulationWorkflowType"] = "sellout_release_management__release_prices_simulation_invalid_workflow_type";
  /**
   * Message key related to situation when client tries to confirm opening of release, but release has incorrect state.
   * <br/>
   * Suggested severity: error.
   */

  SelloutReleaseMsgKeyEnum["ReleaseConfirmOpenInvalidState"] = "sellout_release_management__open_release_confirmation_invalid_state";
  /**
   * Message key related to situation when client tries to confirm opening of reopened release, but release has incorrect state.
   * <br/>
   * Suggested severity: error.
   */

  SelloutReleaseMsgKeyEnum["ReleaseConfirmOpenInvalidStateReopen"] = "sellout_release_management__open_release_confirmation_invalid_state_reopen";
  /**
   * Message key related to situation when client tries to confirm opening of release, but release has incorrect workflow type.
   * <br/>
   * Suggested severity: error.
   */

  SelloutReleaseMsgKeyEnum["ReleaseConfirmOpenInvalidWorkflowType"] = "sellout_release_management__open_release_confirmation_invalid_workflow_type";
  /**
   * Message key related to situation when client tries to confirm opening of release, but release does not contain any release part.
   * <br/>
   * Suggested severity: error.
   */

  SelloutReleaseMsgKeyEnum["ReleaseConfirmOpenNoReleasePart"] = "sellout_release_management__open_release_confirmation_no_release_part";
  /**
   * Message key related to situation when client tries to confirm opening of release, but release does not contain any release item.
   * <br/>
   * Suggested severity: error.
   */

  SelloutReleaseMsgKeyEnum["ReleaseConfirmOpenNoReleaseItem"] = "sellout_release_management__open_release_confirmation_no_release_item";
  /**
   * Message key related to situation when client tries to cancel reopened sellout release.
   * <br/>
   * Suggested severity: error.
   */

  SelloutReleaseMsgKeyEnum["ReleaseCancelReopened"] = "sellout_release_management__cancel_reopened";
  /**
   * Message key related to situation when client tries to cancel data related to price simulation of reopened sellout release.
   * <br/>
   * Suggested severity: error.
   */

  SelloutReleaseMsgKeyEnum["ReleasePriceSimulationCancelReopened"] = "sellout_release_management__cancel_price_simulation_reopened";
  /**
   * Message key related to situation when client tries to cancel data related to price simulation but release has invalid state.
   * <br/>
   * Suggested severity: error.
   */

  SelloutReleaseMsgKeyEnum["ReleasePriceSimulationCancelInvalidState"] = "sellout_release_management__cancel_price_simulation_invalid_state";
})(SelloutReleaseMsgKeyEnum = exports.SelloutReleaseMsgKeyEnum || (exports.SelloutReleaseMsgKeyEnum = {}));