/**
 * @file Data cleaning page
 * @author VBr
 * @author SKu
 */

import {PricingPermissions} from '@logio/common-be-fe';
import {
  AgGrid,
  Button,
  ButtonSize,
  FormGroupInline,
  getPath,
  Page,
  translate,
  WithPermission,
  ButtonLoading,
  Tooltip,
  Icon,
  IconColor,
  IconType,
  ProgressBar, LoadingState,
} from '@logio/common-fe';
import {observer, Observer} from 'mobx-react';
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';
import {PriceArchitectureCategoriesPageStore} from '../../../stores/pages/DataManagement/PriceArchitecture/PriceArchitectureCategoriesPageStore';

@observer
class PriceArchitectureCategoriesPage extends React.Component<RouteComponentProps<{}>> {
  store = new PriceArchitectureCategoriesPageStore();

  componentWillMount() {
    this.store.load();
  }

  /** Removes polling in case if has been started */
  componentWillUnmount() {
    this.store.pollingHelper.polling.stopPolling();
  }

  render() {
    const {
      loadingState,
      columnDefs,
      CategoryTraverseHelper: {treeData, categoryAutoGroupColumnDef, getDataPath},
    } = this.store;

    const pushToTierSettings = () => this.props.history.push(getPath(PagePathsEnum.TiersSettings));

    return (
      <Page titleKey={PageNamesEnum.PriceArchitectureCategories} loadingState={loadingState}>
        {/* This will render downloading modal when exported file is ready, initialized by store.export() */}
        {this.store.downloadStore.renderModal()}
        <Observer>
          {() => (
            <>
              {this.store.pollingHelper && (this.store.pollingHelper.pollingState === LoadingState.Pending) && (
                <div className="mb2">
                  <ProgressBar
                    percentage={this.store.pollingHelper.progress || 0}
                    label={'export-loading-progress'}
                  />
                </div>
              )}
              <FormGroupInline right>
                <WithPermission permissionTypes={[PricingPermissions.TIERS_SETTINGS_VIEW]}>
                  <Button
                    buttonSize={ButtonSize.Tiny}
                    onClick={pushToTierSettings}
                    children={<>{translate('price_architecture-tier_settings')}</>}
                  />
                </WithPermission>
                <ButtonLoading
                  buttonSize={ButtonSize.Tiny}
                  isLoading={this.store.exportIsLoading}
                  onClick={() => this.store.export()}
                >
                  <Tooltip
                    overlay={translate('PriceArchitecture_ExportTooltip')}
                    placement="bottom"
                    noHeight
                    children={
                      <div>
                        <Icon iconType={IconType.exportToExcel}/>
                      </div>
                    }
                  />
                </ButtonLoading>
              </FormGroupInline>
              <AgGrid
                rowData={treeData}
                columnDefs={columnDefs}
                treeData
                autoGroupColumnDef={categoryAutoGroupColumnDef}
                getDataPath={getDataPath}
                gridId="PricingPriceArchitectureCategoriesPage"
                saveTreeDataExpansionId="CategoriesTree"
                quickView
                groupDefaultExpanded={3}
              />
            </>
          )}
        </Observer>
      </Page>
    );
  }
}

export default withRouter(PriceArchitectureCategoriesPage);
