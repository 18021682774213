import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  width: 100%;\n\n  .err-form-vertical {\n    position: absolute;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
import styled, { withTheme } from 'styled-components';
/**
 * Create an wrapper for Input
 */

var ErrorWrapper = styled.div(_templateObject());
export default withTheme(ErrorWrapper);