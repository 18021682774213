/**
 * @file Created on Wed Jan 02 2019
 * @author LZe
 */
import {StructPropBuilder, ValueWithUnit} from '@logio/common-be-fe';
import {ColumnGenerator} from '@logio/common-fe';

class PackageSizeHelperClass {
  builder = new StructPropBuilder('packageSize');
  columnGenerator = new ColumnGenerator<ValueWithUnit>(this.schema);

  get schema() {
    return {
      value: this.builder.bigNum('value'),
      unitId: this.builder.str('unitId'),
    };
  }

  colDefs = (products) =>
    this.columnGenerator.getColumnDefinitions([
      {
        field: 'value',
        width: 80,
        valueFormatter: ({value}) => value, // LOG-2095 (value ? Utils.toFixed(value) : ''),
      },
      {
        field: 'unitId',
        filter: 'agSetColumnFilter',
        width: 80,
        valueFormatter: ({value}) => (value && products.units.get(value) ? products.units.get(value).title : ''),
      },
    ]);

  getColumnData = (packageSize) => this.columnGenerator.getColumnData(packageSize);

  /**
   * Show package size columns always together
   */
  onColumnVisible = (event): void => {
    if (event.columnApi && event.visible) {
      if (
        event.columnApi.getColumn('packageSize_value').isVisible() &&
        !event.columnApi.getColumn('packageSize_unitId').isVisible()
      ) {
        event.columnApi.setColumnVisible('packageSize_unitId', true);
      }
      if (
        event.columnApi.getColumn('packageSize_unitId').isVisible() &&
        !event.columnApi.getColumn('packageSize_value').isVisible()
      ) {
        event.columnApi.setColumnVisible('packageSize_value', true);
      }
    }
  };
}

export const packageSizeHelper = new PackageSizeHelperClass();
