import _toConsumableArray from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

/**
 * @file Created on Wed Aug 08 2018
 * @author BPo
 */
import { ValidationError } from '@logio/common-be-fe';
import * as React from 'react';
import translate from '../../i18n/translate';
import Error from '../../inputs/withValidation/styled/Error';
import ErrorWrapper from '../../inputs/withValidation/styled/ErrorWrapper';
/**
 * HOC component that validate child component. This displays localized validation messages under the wrapped element.
 * @param Component React type component
 */

var withValidation = function withValidation(Component) {
  /**
   * Returns wrapped component around the Children
   */
  var WithValidation = function WithValidation(props) {
    var inputErrorType = "input-error ".concat(props.className || '');
    return props.validationError instanceof ValidationError ? React.createElement(React.Fragment, null, React.createElement(Component, Object.assign({}, props, {
      className: "input-error ".concat(inputErrorType)
    })), React.createElement("div", {
      className: "validation--active"
    }, props.validationError && React.createElement(ErrorWrapper, {
      className: inputErrorType
    }, React.createElement(Error, {
      className: props.className ? " ".concat(props.className) : ''
    }, translate.apply(void 0, [props.validationError.messageKey].concat(_toConsumableArray(props.validationError.messageArgs))))))) : React.createElement(Component, Object.assign({}, props));
  };

  return WithValidation;
};

export default withValidation;