import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  /* background: lime!important; */\n\n  .form-horizontal & {\n    padding-bottom: ", "rem;\n    display: flex;\n    flex-wrap: wrap;\n\n    & > .input-wrapper,\n    & > .input-error,\n    & > .uppy-container,\n    & .uppy-ProgressBar {\n      width: 100%;\n      max-width: ", "px;\n      input,\n      textarea {\n        width: 100%;\n      }\n\n      .form-group-wide & {\n        max-width: calc(100% - ", "px);\n      }\n    }\n    & > label {\n      min-width: ", "px;\n      max-width: ", "px;\n      padding: ", ";\n    }\n    .validation--active {\n      padding-left: ", "px;\n      margin-right: -", "px;\n      width: 100%;\n      padding-top: ", ";\n    }\n\n    @media screen and (max-width: ", "px) {\n      & > label {\n        min-width: 100%;\n      }\n\n      .validation--active {\n        padding-left: 0;\n      }\n    }\n  }\n\n  .form-vertical & {\n    padding-bottom: 5px;\n\n    &:last-of-type {\n      padding-bottom: 0;\n    }\n  }\n\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  .form-horizontal & {\n    padding-left: ", "px;\n\n    .validation--active {\n      padding-left: 0;\n    }\n\n    @media screen and (max-width: ", "px) {\n      padding-left: 0;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Wed Aug 08 2018
 * @author PKl
 */
import styled, { css, withTheme } from 'styled-components';
var NoLabel = css(_templateObject(), function (props) {
  return props.theme.form.labelWidth;
}, function (props) {
  return props.theme.form.breakpoint;
});
var StyledFormGroup = styled.div(_templateObject2(), function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.theme.form.inputWidth;
}, function (props) {
  return props.theme.form.labelWidth;
}, function (props) {
  return props.theme.form.labelWidth;
}, function (props) {
  return props.theme.form.labelWidth;
}, function (props) {
  return props.theme.label.padding;
}, function (props) {
  return props.theme.form.labelWidth;
}, function (props) {
  return props.theme.form.labelWidth;
}, function (props) {
  return props.theme.spacing.spacer / 1.5;
}, function (props) {
  return props.theme.form.breakpoint;
}, function (props) {
  return props.noLabel ? NoLabel : null;
});
export default withTheme(StyledFormGroup);