import _classCallCheck from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/classCallCheck";

/**
 * @file Created on Fri Jan 11 2019
 * @author LZe
 */
import axios from 'axios';
import { FileDownload } from '../document/FileDownload';
import { CONSTANTS } from '../../constants';
import logger from '../logger';
import api from '../api';
export var DownloadLayer = function DownloadLayer() {
  _classCallCheck(this, DownloadLayer);

  /**
   * Download file
   * @param fileId
   * @returns Blob with file
   */
  this.download = function (fileId, onProgress, getCancel) {
    var CancelToken = axios.CancelToken;
    var source = CancelToken.source();

    if (getCancel) {
      getCancel(function (message) {
        return source.cancel(message);
      });
    }

    return api(CONSTANTS.API.ENDPOINTS.DOWNLOAD(fileId)).get({
      responseType: 'blob',
      timeout: 0,
      onDownloadProgress: function onDownloadProgress(progressEvent) {
        if (onProgress) {
          onProgress(progressEvent.loaded, progressEvent.total);
        }
      },
      cancelToken: source.token
    }).then(function (response) {
      var download = new FileDownload(response.data, response.headers);
      return download.process();
    }).catch(function (error) {
      logger.error(error);
      return Promise.reject(error);
    });
  };
};