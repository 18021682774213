/**
 * @file Created on Fri Sep 21 2018
 * @author SKu
 */

import {Tier} from '@logio/common-be-fe';
import {AgGrid, Button, ButtonColor, Field, Form, FormGroupInline, Modal, translate} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';
import {TiersModalEnum, TiersPageStore} from '../../stores/pages/Settings/TiersPageStore';

interface Props {
  /** Tier Page Store */
  store: TiersPageStore;
  /**
   * If true render createModal else editModal
   */
  createMode?: boolean;
}

/**
 * Component renders modals for TierPage
 */
export const TiersPageForm: React.SFC<Props> = observer(({createMode = false, store}: Props) => {
  /** Defining mode, createForm or editForm */
  const mode = createMode ? TiersModalEnum.CREATE : TiersModalEnum.EDIT;
  return (
    <Modal title={translate(`${mode}-tier`)} hidden={store.modalHidden[mode]} close={store.getModalToggleEvent(mode)}>
      <Form
        onSubmit={createMode ? store.onCreateSubmit : store.onEditSubmit}
        descriptions={Tier.tierSchema}
        initialValues={store.initialValues}
      >
        <Field name="title" />
        <Field name="description" />
        <Field
          name={'level'}
          component={({input}) => (
            <div className="mb1">
              <AgGrid
                rowData={store.rowDataModal(createMode)}
                columnDefs={store.columnDefsModal()}
                sizeColumnsToFit
                rowDragManaged
                onRowDragEnd={(event) => input.onChange(event.overIndex)}
                height="200px"
                gridId="PricingTiersPageForm"
              />
            </div>
          )}
        />
        <FormGroupInline right={true}>
          <Button buttonColor={ButtonColor.Light} onClick={store.getModalToggleEvent(mode)}>
            {translate('Cancel')}
          </Button>
          <Button type="submit">{translate(`${mode}`)}</Button>
        </FormGroupInline>
      </Form>
    </Modal>
  );
});
