/**
 * @file Created on Fri Nov 16 2018
 * @author SKu
 */
import { Utils } from '@logio/common-be-fe';
import * as React from 'react';
import Tooltip from '../../../../info/Tooltip';
import Icon, { IconColor, IconType } from '../../../../media/Icon';
/** Pure Component is used because of types. You cannot return array of elements in SFC */

export var PriceChangeReportCommentsRenderer = function PriceChangeReportCommentsRenderer(_ref) {
  var value = _ref.value;

  if (Utils.isValueMissing(value) || value.size === 0) {
    return null;
  }
  /** Transform array of comments to JSX list */


  var comments = value.map(function (comment, i) {
    return React.createElement("li", {
      className: "mb1",
      key: i
    }, "".concat(comment.text, " (").concat(comment.username, ")"));
  });
  return React.createElement("div", {
    className: "fixed z1000"
  }, React.createElement(Tooltip, {
    overlay: comments,
    placement: "right"
  }, React.createElement("div", null, React.createElement(Icon, {
    iconType: IconType.info,
    iconColor: IconColor.Secondary,
    iconHeight: 25
  }))));
};