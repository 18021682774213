/**
 * @file Created on Mon Oct 08 2018
 * @author BPo
 */
import {CheckBoxGroupProps, FormGroupInline, FormGroupInlineProps, Select, translate} from '@logio/common-fe';
import * as React from 'react';

interface CompetitorFilterProps {
  className?: string;
}

/**
 * Renders competitors filter
 */
export const CompetitorFilter: React.SFC<
  CompetitorFilterProps & FormGroupInlineProps & Pick<CheckBoxGroupProps, 'values' | 'onChange' | 'options'>
> = ({className, right, values, onChange, options}) => {
  return (
    <div className="form-horizontal">
      <FormGroupInline right={right} className={className}>
        <Select
          multi
          name="competitors-filter"
          value={values}
          onChange={onChange}
          label={translate('filters-competitors')}
          groupLabel={translate('filters-competitors')}
          options={options}
        />
      </FormGroupInline>
    </div>
  );
};
