import moment from 'moment';
import * as React from 'react';
import {observer} from 'mobx-react';
import {FieldRenderProps, FormSpy, FormSpyRenderProps} from 'react-final-form';
import {DateOlderThanValidator, DateYoungerThanValidator, StructPropBuilder} from '@logio/common-be-fe';
import {
  Button,
  CONSTANTS,
  DatePickerInputAdapter,
  Field,
  Form,
  FormGroupInline,
  Icon,
  IconType,
  StringMapping,
  translate,
  ValidationUtils,
} from '@logio/common-fe';
import {IReleaseFilter} from './ReleaseUtils';
import {DayPickerProps} from 'react-day-picker';

export interface ReleasedImpactFilterProps {
  onSubmit?: (data: any) => Promise<void>;
  /** If property passed, find button will not be rendered */
  onChange?: (data: any) => void;
  initialValues?: IReleaseFilter;
}

/** Filter for release headers for page impact mode or release in released state */
export const ReleasedImpactFilter: React.FC<ReleasedImpactFilterProps> = observer(
  (props: ReleasedImpactFilterProps) => {
    /** Form Builder & Descriptions */
    const builder = new StructPropBuilder('ReleasedImpactFilter');
    const descriptions = {
      dateFrom: builder.date('dateFrom', CONSTANTS.FORMAT.DATE),
      dateTo: builder.date('dateTo', CONSTANTS.FORMAT.DATE),
    };

    /** Form initial values */
    function initialValues(): StringMapping<any> {
      const dateFrom = (props.initialValues && props.initialValues.dateFrom) || moment().subtract(7, 'd');
      const dateTo = (props.initialValues && props.initialValues.dateTo) || moment();

      return {
        originDateFrom: dateFrom,
        originDateTo: dateTo,
        dateFrom,
        dateTo,
      };
    }

    if (!CONSTANTS.REPORT.PRICE_IMPACT_REPORT.ENABLED) {
      return null; // LOG-5695
    }

    return (
      <Form
        onSubmit={props.onSubmit}
        onChange={props.onChange}
        initialValues={initialValues()}
        descriptions={descriptions}
        formLayoutVertical={true}
      >
        <FormGroupInline className="form-vertical p0">
          <FormSpy subscription={{values: true}}>
            {(spyProps: FormSpyRenderProps) => (
              <Field
                name="dateFrom"
                component={dateComponent(spyProps, 'dateFrom')}
                validate={(value: StringMapping<any>, formValues: StringMapping<any>) => {
                  return ValidationUtils.validateField(value, formValues, [
                    new DateYoungerThanValidator(
                      formValues.dateTo,
                      false,
                      true,
                      CONSTANTS.FORMAT.DATE,
                      'DATE_FROM_IS_MORE_THAN',
                    ),
                  ]);
                }}
              />
            )}
          </FormSpy>

          <FormSpy subscription={{values: true}}>
            {(spyProps: FormSpyRenderProps) => (
              <Field
                name="dateTo"
                component={dateComponent(spyProps, 'dateTo')}
                validate={(value: StringMapping<any>, formValues: StringMapping<any>) => {
                  return ValidationUtils.validateField(value, formValues, [
                    new DateOlderThanValidator(
                      formValues.dateFrom,
                      false,
                      true,
                      CONSTANTS.FORMAT.DATE,
                      'DATE_TO_IS_LESS_THAN',
                    ),
                  ]);
                }}
              />
            )}
          </FormSpy>

          {!props.onChange && (
            <div className={undefined}>
              <Button iconRight={true} type={'submit'}>
                {translate('Find')} <Icon iconType={IconType.search} />
              </Button>
            </div>
          )}
        </FormGroupInline>
      </Form>
    );
  },
);

/**
 * Date select component
 * @param values
 * @param label
 */
const dateComponent = ({values}: FormSpyRenderProps, label: string) => {
  return (props: FieldRenderProps) => {
    const getDate = (value: moment.Moment) => (value ? value.toDate() : undefined);
    const originDateFrom = getDate(values.originDateFrom);
    // const originDateTo = getDate(values.originDateTo);

    const options: DayPickerProps = {
      month: originDateFrom,
      fromMonth: originDateFrom,
      // toMonth: originDateTo,
      disabledDays: {
        before: originDateFrom,
        // after: originDateTo,
      },
    };

    return DatePickerInputAdapter(label, false, options, true, '', CONSTANTS.FORMAT.DATE)(props);
  };
};
