/**
 * @file Created on Fri Oct 05 2018
 * @author SKu
 */

import {AgGrid, FormGroup} from '@logio/common-fe';
import {observer} from 'mobx-react';
import * as React from 'react';
import {ReleaseDetailCategoryComponentStore} from '../../../stores/components';

export interface Props {
  store: ReleaseDetailCategoryComponentStore;
}

/**
 * Release detail category Top table
 */
const ReleaseDetailCategoryTotalOverview: React.SFC<Props> = observer(({store}: Props) =>
{
  const rowData = store.totalOverviewRowData;

  return (
    <FormGroup>
      <AgGrid
        className={'columns-panel-overflow'}
        rowData={rowData}
        columnDefs={store.totalOverviewColumnDefs}
        gridId={'ReleaseDetailCategoryTotalOverviewGridId'}
        onGridReady={store.onTotalOverviewGridReady}
        statusBar={null}
        height={store.getAgGridTotalHeight(rowData.length, 4)}
      />
    </FormGroup>
  )
});

export default ReleaseDetailCategoryTotalOverview;
