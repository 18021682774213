/**
 * @file Created on Mon Jul 29 2019
 * @author SKu
 */

import {AbstractReleaseTotalDTO, ProductCategory, Utils} from '@logio/common-be-fe';
import {
  ActionProps,
  ActionsGenerator,
  ColumnGenerator,
  GeneralConfigurationsStore,
  ProductCategoryStore,
  rootStore,
  StoreName,
  StringMapping,
} from '@logio/common-fe';
import {List} from 'immutable';
import {computed} from 'mobx';

// FIXME: move to common-fe, could be used for promo
/** Class which helps to handle Ag-grid ProductCategory tree data generation */
export class CategoryTraverseHelper {
  public productCategoryStore = rootStore.getStore(StoreName.ProductCategory) as ProductCategoryStore;
  private generalConfigurationsStore = rootStore.getStore(
    StoreName.GeneralConfigurations,
  ) as GeneralConfigurationsStore;
  /** Ag-grid generators */
  private actionsGenerator = new ActionsGenerator();
  private productCategoryColumnGenerator = new ColumnGenerator<ProductCategory>(ProductCategory.schema);

  /**
   * @param getDataPerRow - function that should return generated data for ag-grid row
   * @param getActionsPerRow - returns icons with actions
   * @param actionForAllLevels - if true, actions will be added for all category levels,
   *  default - only bottom level from {@link GeneralConfigurationsStore}
   */
  constructor(
    private getDataPerRow: (c: ProductCategory) => StringMapping<any>,
    private getActionsPerRow?: (c: ProductCategory) => ActionProps[],
    private actionForAllLevels?: boolean,
  ) {}

  /** Map of categories, allowed for current user */
  @computed
  public get categories() {
    return this.productCategoryStore.userCategoryList;
  }

  /** The lowest category level from configurations */
  @computed
  public get bottomCategoryLevel() {
    return this.generalConfigurationsStore.config.categoryLevel;
  }

  /** Creates ag-grid data for tree view */
  @computed
  public get treeData() {
    const gridData: Array<StringMapping<any>> = [];
    /** Run traverse whenever data are ready */
    if (this.productCategoryStore && this.productCategoryStore.root) {
      this.traverse(gridData, this.productCategoryStore.root, List());
    }

    return gridData;
  }

  /** Return data path for tree view(Used as Ag-grid prop) */
  public getDataPath = ({path}: {path: any}) => path;

  /** Fetches product categories for current user */
  public load = async () => await this.productCategoryStore.getAllForCurrentUser();

  /** Since we need tree view structure we need to traverse through children of each category */
  private traverse = async (gridData: Array<StringMapping<any>>, category: ProductCategory, path: List<string>) => {
    path = path.push(category.name);
    let rowData = this.getDataPerRow(category);

    /** Add actions to the row data */
    if (this.getActionsPerRow && this.shouldActionBeCreated(category.level)) {
      const action = this.actionsGenerator.getColumnData(...this.getActionsPerRow(category));
      rowData = {...action, ...rowData};
    }

    /** Add ag-grid tree path to row data */
    gridData.push({path: path.toArray(), ...rowData});

    if (category.level < this.bottomCategoryLevel) {
      const promises = [];

      /** Traverse for child if DTO exists */
      category.childrenIds.map((categoryId: string) => {
        const childCategory = this.categories.get(categoryId);
        if (childCategory) {
          promises.push(this.traverse(gridData, childCategory, path));
        }
      });

      await Promise.all(promises);
    }

    return Promise.resolve();
  };

  /** Actions will be added to all categories or only to the lowest */
  private shouldActionBeCreated = (categoryLevel: number) =>
    this.actionForAllLevels || (!this.actionForAllLevels && categoryLevel === this.bottomCategoryLevel);

  /** Format category name to delete children count {@author LZe} */
  private deleteChildrenCount = ({value, node}) => {
    const {length: childrenLength} = node.childrenAfterGroup;
    //New AgGrid replaces & with &amp;. We have to transform it back until the AgGrid will be fixed.
    const transformedValue = value.toString().replace(/&amp;/g, '&');
    return [transformedValue, childrenLength ? `(${childrenLength})` : ''].join(' ');
  };

  /** Definition for ag-grid tree data */
  public categoryAutoGroupColumnDef = this.productCategoryColumnGenerator.getAutoGroupColumnDefinitions({
    field: 'name',
    width: 400,
    suppressMovable: true,
    cellRendererParams: {suppressCount: true}, // Remove count of all children (total in all descendant categories)
    valueFormatter: this.deleteChildrenCount, // Add count of direct descendants (excl. 0)
    excel: {
      // LOG-4593
      valueFormatter: ({value, node}) => {
        const pathItems = [...node.data.path];
        const maxPathLength = 5;
        // path[path.length - 1] = this.deleteChildrenCount({value, node}); // replace last item by item with count
        // we need as well "empty levels" of the path due to correct count of semicolons
        for (let i = pathItems.length; i <= maxPathLength; i++) {
          pathItems.push('');
        }
        return pathItems.join(';');
      },
    },
  });
}
