"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Source of creation of an entity (window, window item...). Can be user, import, copy etc.
 * @author Jan Prokop
 */

var CreationSource;

(function (CreationSource) {
  /** Source not available - for older items */
  CreationSource["NOT_AVAILABLE"] = "N/A";
  /** Item is created manually by user */

  CreationSource["MANUAL"] = "MANUAL";
  /** Item is created by copy action */

  CreationSource["COPY"] = "COPY";
  /** Item is created by move action */

  CreationSource["MOVE"] = "MOVE";
  /** Item is created by excel import */

  CreationSource["FILE_IMPORT"] = "EXCEL";
  /** Item is created by Data pump */

  CreationSource["DATA_PUMP"] = "DP";
})(CreationSource = exports.CreationSource || (exports.CreationSource = {}));