"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var StoreSpecificationEnum;

(function (StoreSpecificationEnum) {
  StoreSpecificationEnum["NONE"] = "N/A";
  StoreSpecificationEnum["BOTH"] = "BOTH";
  StoreSpecificationEnum["SPM"] = "Supermarket";
  StoreSpecificationEnum["HPM"] = "Hypermarket";
  StoreSpecificationEnum["ECM"] = "Ecommerce";
})(StoreSpecificationEnum = exports.StoreSpecificationEnum || (exports.StoreSpecificationEnum = {}));