/**
 * @file Created on Thu Sep 12 2018
 * @author LZe
 */
import * as React from 'react';
export var stateRendererStates = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error'
};
/**
 * Renders state type cell - Suitable for cells showing some state of the row using the traffic light colors
 *
 * Sample column definition using the getColumnDefinitions:
 * {field: 'last_column', cellRendererFramework: (props) => StateRenderer({valuesMap, ...props})}
 * Where valuesMap is something like:
 * const valuesMap = new Map([[1, {state: stateRendererStates.SUCCESS, value: 'Great success!}], [2, {state: stateRendererStates.ERROR, value: 'Epic fail :-('}]])
 */

var StateRenderer = function StateRenderer(_ref) {
  var value = _ref.value,
      valuesMap = _ref.valuesMap;
  var valuesObj = valuesMap.get(value);
  return valuesObj ? React.createElement("span", {
    className: "state-".concat(valuesObj.state)
  }, valuesObj.value) : React.createElement("span", null, value);
};

export default StateRenderer;