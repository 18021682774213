/**
 * Converts array codebooks to select option
 * where key - codebook id
 * @param items array of codebooks
 * @returns Array of FormElOption
 */
export function codebookToSelectOption(items) {
  return items.map(function (item) {
    return {
      value: item.id,
      label: item.title
    };
  });
}