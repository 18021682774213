/**
 * @file Created on Thu Sep 13 2018
 * @author LZe
 */

import {
  CodebookLookupSequence,
  DataDesc,
  ResearchTypeEnum,
  StructPropBuilder,
  UploadStateEnum,
  UploadTypeEnum,
} from '@logio/common-be-fe';
import {
  AgGridStateRenderer,
  ColumnDefinition,
  ColumnGenerator,
  DataUploadStore,
  LoadingState,
  PageStore,
  rootStore,
  StoreName,
  StringMapping,
  translate,
} from '@logio/common-fe';
import {action, computed} from 'mobx';
import {dataUploadStateValuesMap} from '../../../../pages/DataManagement/DataUpload/DataUploadPage';

export class DataUploadDetailPageStore extends PageStore {
  id: string;

  constructor(id: string) {
    super();
    this.id = id;
  }

  dataUploadStore = rootStore.getStore(StoreName.DataUpload) as DataUploadStore;

  builder = new StructPropBuilder('DataUpload');

  columnsDescription: DataDesc = {
    id: this.builder.str('id'),
    date: this.builder.date('date'),
    type: this.builder.senum('type', UploadTypeEnum, `${CodebookLookupSequence.UploadType}?view=1`),
    researchType: this.builder.opt(
      this.builder.senum('researchType', ResearchTypeEnum, CodebookLookupSequence.ResearchType),
    ),
    researchName: this.builder.opt(this.builder.str('researchName')),
    state: this.builder.senum('state', UploadStateEnum, CodebookLookupSequence.UploadState),
    userName: this.builder.str('userName'),
  };

  columnGenerator = new ColumnGenerator(this.columnsDescription);

  get columnDefs(): ColumnDefinition[] {
    return [
      ...this.columnGenerator.getColumnDefinitions([
        {field: 'date', suppressMenu: true},
        {field: 'type', suppressMenu: true},
        {field: 'researchType', suppressMenu: true},
        {field: 'researchName', suppressMenu: true},
        {
          field: 'state',
          cellRendererFramework: (props) => AgGridStateRenderer({valuesMap: dataUploadStateValuesMap, ...props}),
          suppressMenu: true,
        },
        {field: 'userName', suppressMenu: true},
      ]),
    ];
  }

  @computed
  get rowData(): Array<StringMapping<string>> {
    const row = this.dataUploadStore.list.get(this.id);
    return [
      {
        ...this.columnGenerator.getColumnData({
          id: row.id,
          date: row.date,
          type: translate(row.type),
          researchType: translate(row.researchType),
          researchName: row.researchName,
          state: row.state,
          userName: row.userName,
        }),
      },
    ];
  }

  /**
   * Fetches all data for this page
   */
  @action
  load = (): void => {
    this.setLoadingState(LoadingState.Pending);
    this.dataUploadStore
      .getOne(this.id)
      .then(() => this.setLoadingState(LoadingState.Success))
      .catch((error) => {
        this.setLoadingState(LoadingState.Error);
        // this.messages.setError(error);
      });
  };
}
