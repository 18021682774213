/**
 * @file Created on Tue Dec 17 2019
 * @author Tomas Leity
 */

/**
 * @enum Alert can be rendered in these types
 */
export var AppAlertType;

(function (AppAlertType) {
  AppAlertType["Error"] = "ERROR";
  AppAlertType["Warning"] = "WARNING";
  AppAlertType["Success"] = "SUCCESS";
  AppAlertType["Info"] = "INFO";
})(AppAlertType || (AppAlertType = {}));