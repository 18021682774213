import * as React from 'react';
import { SplineSeries } from 'react-jsx-highcharts';
import Chart from '../Chart';
import StyledSplineChart from './styled/StyledSplineChart';
import translate from '../../i18n/translate';
import { Utils } from "@logio/common-be-fe";

var renderLineSeries = function renderLineSeries(data) {
  var series = [];
  data.forEach(function (values, seriesName) {
    if (Utils.isObject(values) && ('data' in values || 'props' in values)) {
      series.push(React.createElement(SplineSeries, Object.assign({
        key: seriesName,
        name: translate(seriesName),
        data: values.data,
        id: seriesName
      }, values.props)));
    } else if (Utils.isArray(values)) {
      series.push(React.createElement(SplineSeries, {
        key: seriesName,
        name: translate(seriesName),
        data: values,
        id: seriesName
      }));
    }
  });
  return series;
};

var SplineChart = function SplineChart(props) {
  return React.createElement(StyledSplineChart, null, React.createElement(Chart, Object.assign({
    renderer: renderLineSeries
  }, props)));
};

export default SplineChart;