import _taggedTemplateLiteral from "/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  line-height: ", "px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * @file Created on Tue Aug 14 2018
 * @author PKl
 */
import styled, { withTheme } from 'styled-components';
/**
 * Create an wrapper for Radio
 */

var RadioWrapper = styled.div(_templateObject(), function (props) {
  return props.theme.input.height;
});
export default withTheme(RadioWrapper);