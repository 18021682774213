/**
 * @author VBr
 */

import {PricingRoles} from '@logio/common-be-fe';
import {getPath, TabMenu, translate} from '@logio/common-fe';
import * as React from 'react';
import {PageNamesEnum} from '../../../shared/localization/PageNamesEnum';
import {PagePathsEnum} from '../../../shared/localization/PagePathsEnum';

const Tabs: React.SFC = () => (
  <TabMenu
    tabs={[
      {to: getPath(PagePathsEnum.WorkflowCases), content: translate(PageNamesEnum.WorkflowCases), exact: true},
      {
        to: getPath(PagePathsEnum.WorkflowLocks),
        content: translate(PageNamesEnum.WorkflowLocks),
        roles: [PricingRoles.ADMIN],
      },
    ]}
  />
);

export default Tabs;
