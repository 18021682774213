import { BigDecimal } from '@logio/big-decimal';
import { SelloutImpactRecord, StructPropBuilder } from '@logio/common-be-fe';
import { ColumnDefinition, ColumnGenerator, CONSTANTS, translate } from '@logio/common-fe';
import { List } from 'immutable';
import { computed } from 'mobx';

export class PreviousImpactsColumnGenerator {
  /**
   * Contains functions to obtain column definitions and row data for fields containing desired
   * impact data from previous reopens. This can be used in any agGrid.
   * 
   * @param store Store/object with `reopenCount` field. If it can change, it must be observable, so ColumnGenerator
   *              is correctly recomputed.
   */
  constructor(private readonly store: { reopenCount: number }) {}

  @computed
  private get previousImpactsColumnGenerator() {
    const builder = new StructPropBuilder();

    // column generator dependant on reopen count
    const schema = [...Array(this.store.reopenCount)].reduce((prev, curr, ind) => {
      prev[`discountPercent_${ind}`] = builder.opt(builder.bigNum(`discountPercent_${ind}`));
      return prev;
    }, {});
    
    return new ColumnGenerator<List<SelloutImpactRecord>>(schema);
  }

  /**
   * Gets Ag Grid column definitions for previous impact records.
   */
  public getColumnDefinitions() {
    const colDefs = (val, ind): ColumnDefinition[] => [{
      field: `discountPercent_${ind}`,
      headerName: ind === 0 ?
        translate(`SelloutReleaseItemPrices_discountPercent_initial`) :
        translate('SelloutReleaseItemPrices_discountPercent_reopen', ind.toString(10)),
      valueFormatter: ({ value }) => getRoundedPrice(value),
    }];
  
    return this.previousImpactsColumnGenerator.getColumnDefinitions(
      [].concat.apply([], [...Array(this.store.reopenCount)].map(colDefs)),
    );
  }

  /**
   * Gets Ag Grid column data for previous impact records.
   * 
   * @param impacts
   */
  public getColumnData(impacts: List<{discountPercent: BigDecimal | null, reopen: number}> | null | undefined) {
    if (impacts == null) {
      return {};
    }

    const data = [...Array(this.store.reopenCount)].reduce((prev, curr, ind) => {
      const impact = impacts.find(imp => imp.reopen === ind);
      if (impact) {
        prev[`discountPercent_${ind}`] = impact.discountPercent;
      } else {
        prev[`discountPercent_${ind}`] = null;
      }
      return prev;
    }, {});

    return this.previousImpactsColumnGenerator.getColumnData(data);
  }
}

const getRoundedPrice = (value: any, digits: number = 2): string => {
  if (!value) {
    return CONSTANTS.FORMAT.NULL;
  }

  return value
    .toFixed(digits)
    .toString()
    .replace('.', ',');
};
