"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function UNIMPLEMENTED(message) {
  throw new Error("Unimplemented ".concat(message ? message : ''));
}

exports.UNIMPLEMENTED = UNIMPLEMENTED;