import { ReleaseState } from '@logio/common-be-fe';
import {
  Button,
  FormGroupInline,
  getPath,
  Select,
  translate,
} from '@logio/common-fe';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface ReleasePageHeaderProps {
  releaseStates: string[];
  onReleaseStateFilter(values: string[]): void;
  newPath?: string;
  impactPath?: string;
}

/**
 * Header for pages with release lists.
 * @author Jan Prokop
 */
export default observer(function ReleasePageHeader(props: ReleasePageHeaderProps) {
  /** All possible release filter options */
  const selectOptions = Object.values(ReleaseState).map((value) => ({ label: translate(value), value }));

  return (
    <FormGroupInline className="form-vertical">
      {props.newPath && (
        <div className="mr1">
          <Link to={getPath(props.newPath)}>
            <Button>{translate('create-new-release')}</Button>
          </Link>
        </div>
      )}
      <Select
        multi
        label={translate('release-states')}
        value={props.releaseStates.slice()}
        options={selectOptions}
        onChange={props.onReleaseStateFilter}
      />
      <div className="flex-grow-1">&nbsp;</div>
      {props.impactPath && (
        <Link to={getPath(props.impactPath)}>
          <Button>{translate('release-report-mode')}</Button>
        </Link>
      )}
    </FormGroupInline>
  );
});
