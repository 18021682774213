/**
 * @file Data cleaning page
 * @author Atreo
 */

import {
  PageStore,
  LoadingState,
  rootStore,
  StoreName,
  CRPStore,
  CompetitorPriceStore,
  DataUploadStore,
  CompetitorSiteStore,
  StringMapping,
  ColumnDefinition,
  ColumnGenerator,
} from '@logio/common-fe';
import {action, runInAction, observable, computed} from 'mobx';
import {GridApi, GridReadyEvent, ColumnApi, RowNode} from 'ag-grid-community';
import {
  CompetitorPrice,
  StructPropBuilder,
  CompetitorPriceHistoryIndexDTO,
  CompetitorSite,
  PriceClassificationsBinding,
  PriceClassification,
  CodebookLookupSequence,
  DataUpload,
  ModifiedCompetitorPriceDTO,
  Product,
  CompetitorReferentialPrice,
} from '@logio/common-be-fe';
import { BigDecimal } from '@logio/big-decimal';

export class PriceHistoryModalStore extends PageStore {

  /**
   * zoneId, competitorId, productId:
   * what is currently showed in the modal
   * data from the grid cell on DataCleaningProductsPage
   * all undefined if modal is not visible
   */
  zoneId;
  competitorId;
  productId;

  /**
   * index of row from the grid cell on DataCleaningProductsPage
   * needed due to navigation to previous/next products
   * undefined if modal is not visible
   */
  rowId;

  /**
   * list of shown rows in the grid on page
   * needed due to correct navigation to the prev/next product in modal
   */
  listOfShownRows;

  /**
   * newCRP value returned from endpoint
   */
  @observable
  newCRP: CompetitorReferentialPrice | null;

  /**
   * actual CRP value returned from endpoint
   */
  @observable
  actualCRP: CompetitorReferentialPrice | null;

  /**
   * Boolean to identify which column was clicked and which value should display if actualCRP or newCRP
   */
  @observable
  isNewCRP: boolean;

  /**
   * pageReadOnly from DataCleaningProductsPageStore
   * due to here in the PriceHistoryModalStore is no link to the DataCleaningProductsPageStore,
   * the value is transferred through the PriceHistoryRenderer props
   */
  pageReadOnly;

  /**
   * if the modal is hidden/showed, default is true = modal is hidden
   */
  @observable
  modalHidden = true;

  dataCleaningProductPageModel = undefined;

  /**
   * Api of grid in the DataCleaningProductsPage
   */
  dataCleaningProductsPageGridApi: GridApi;

  /**
   * Apis of grid in the PriceHistoryModal
   */
  modalGridApi: GridApi;
  modalColumnApi: ColumnApi;

  /** Get dependant stores */
  competitorSitesStore = rootStore.getStore(StoreName.CompetitorSite) as CompetitorSiteStore;
  CRPStore = rootStore.getStore(StoreName.CRP) as CRPStore;
  competitorPriceStore = rootStore.getStore(StoreName.CompetitorPrice) as CompetitorPriceStore;
  dataUploadStore = rootStore.getStore(StoreName.DataUpload) as DataUploadStore;

  PriceClassificationBindingBuilder = new StructPropBuilder('PriceClassificationsBinding');
  competitorModifiedPriceBuilder = new StructPropBuilder('ModifiedPrice');

  competitorPriceColumnGenerator = new ColumnGenerator<CompetitorPrice>(CompetitorPrice.schema);
  competitorPriceHistoryIndexColumnGenerator = new ColumnGenerator<CompetitorPriceHistoryIndexDTO>(
    CompetitorPriceHistoryIndexDTO.schema,
  );
  competitorSiteColumnGenerator = new ColumnGenerator<CompetitorSite>(CompetitorSite.schema);
  priceClassificationBindingColumnGenerator = new ColumnGenerator<{flags: PriceClassificationsBinding}>({
    flags: this.PriceClassificationBindingBuilder.opt(
      this.PriceClassificationBindingBuilder.senum<PriceClassification>(
        'userFlag',
        PriceClassification,
        CodebookLookupSequence.PriceClassification,
      ),
    ),
  });
  dataUploadColumnGenerator = new ColumnGenerator<DataUpload>(DataUpload.schema);
  competitorModifiedPriceColumnGenerator = new ColumnGenerator<ModifiedCompetitorPriceDTO>({
    modifiedPrice: this.competitorModifiedPriceBuilder.bigNum('modifiedPrice'),
  });

  /** Return generated column definitions for ag-grid */
  @computed
  get columnDefs(): ColumnDefinition[] {
    return [
      ...this.competitorSiteColumnGenerator.getColumnDefinitions([
        {field: 'externalId', hide: true},
        {field: 'address', hide: true},
        {field: 'city'},
      ]),
      ...this.competitorPriceColumnGenerator.getColumnDefinitions([
        {
          field: 'date',
          lockVisible: true,
        },
        {field: 'value', lockVisible: true},
      ]),
      ...this.competitorPriceHistoryIndexColumnGenerator.getColumnDefinitions([
        {
          field: 'priceChangeIndex',
          hide: true,
          round: true,
        },
      ]),
      ...this.competitorModifiedPriceColumnGenerator.getColumnDefinitions([{field: 'modifiedPrice', hide: true}]),
      ...this.dataUploadColumnGenerator.getColumnDefinitions([{field: 'researchType', hide: true}, {field: 'type'}]),
      ...this.priceClassificationBindingColumnGenerator.getColumnDefinitions([
        {
          field: 'flags',
          /** Couldn't be editable if page in ReadOnly mode */
          editable: !this.pageReadOnly,
          lockVisible: true,
          action: this.updatePriceType,
          filter: 'agSetColumnFilter',
          valueFormatter: ({value}: {value?: PriceClassificationsBinding}) => {
            if (value == null) {
              return '';
            }

            return value instanceof PriceClassificationsBinding ? value.priceClassification() : value;
          },
        },
      ]),
      ...this.competitorPriceColumnGenerator.getColumnDefinitions([
        {
          field: 'promo',
          lockVisible: true,
        },
        {field: 'sellout'},
      ]),
    ];
  }

  /** Return generated data for ag-grid */
  @computed
  get rowData(): Array<StringMapping<any>> {
    const rowData = [];
    const competitorPrices = this.competitorPriceStore.internalProductsMap.get(this.productId);

    if (competitorPrices) {
      competitorPrices.forEach((competitorPrice: CompetitorPrice) => {
        const priceHistoryIndex = this.competitorPriceStore.priceHistoryIndexes.get(competitorPrice.id);
        const competitorSite = this.competitorSitesStore.list.get(competitorPrice.siteId);
        const dataUpload = this.dataUploadStore.list.get(competitorPrice.dataUploadId);
        const modifiedPrice = this.competitorPriceStore.modifiedPrices.get(competitorPrice.id);

        const flags: PriceClassificationsBinding = competitorPrice.flags.find((flag) => {
          return flag.priceZoneId === this.zoneId;
        });

        rowData.push({
          ...this.competitorPriceColumnGenerator.getColumnData(competitorPrice),
          ...this.competitorPriceHistoryIndexColumnGenerator.getColumnData(priceHistoryIndex),
          ...this.competitorSiteColumnGenerator.getColumnData(competitorSite),
          ...this.dataUploadColumnGenerator.getColumnData(dataUpload),
          ...this.priceClassificationBindingColumnGenerator.getColumnData({flags}),
          ...this.competitorModifiedPriceColumnGenerator.getColumnData(modifiedPrice),
        });
      });
    }
    return rowData;
  }

  /** Binds ag-grid api */
  @action.bound
  onGridReady(params: GridReadyEvent) {
    this.modalGridApi = params.api;
    this.modalColumnApi = params.columnApi;
    setTimeout(() => {
      this.modalGridApi.setFocusedCell(0, PriceClassificationsBinding.schema.userFlag.description.nameKey);
    }, 250);
  }

  /** Used to allow navigation to prev product in the modal */
  hasPrev = (): boolean => {
    return (this.listOfShownRows.length > 1 && this.listOfShownRows.indexOf(this.rowId) > 0);
  };

  /** Used to allow navigation to next product in the modal */
  hasNext = (): boolean => {
    return (this.listOfShownRows.length > 1 && this.listOfShownRows.indexOf(this.rowId) < (this.listOfShownRows.length - 1));
  };

  /** Counts ID of prev product and call the navigation function */
  prevProductNavigation = async () => {
    if (this.hasPrev()) {
      const prevNodeId = this.listOfShownRows[this.listOfShownRows.indexOf(this.rowId) - 1];
      await this.productNavigation(this.dataCleaningProductsPageGridApi.getRowNode(prevNodeId));
      this.dataCleaningProductsPageGridApi.setFocusedCell(
        this.getRowIndexBySavedRowNodeId(prevNodeId),
        this.dataCleaningProductsPageGridApi.getFocusedCell().column,
      );
    }
  };

  /** Counts ID of next product and call the navigation function */
  nextProductNavigation = async () => {
    if (this.hasNext()) {
      const nextNodeId = this.listOfShownRows[this.listOfShownRows.indexOf(this.rowId) + 1];
      await this.productNavigation(this.dataCleaningProductsPageGridApi.getRowNode(nextNodeId));
      this.dataCleaningProductsPageGridApi.setFocusedCell(
        this.getRowIndexBySavedRowNodeId(nextNodeId),
        this.dataCleaningProductsPageGridApi.getFocusedCell().column,
      );
    }
  };

  public getRowIndexBySavedRowNodeId = (rowNodeId) => this.dataCleaningProductsPageGridApi.getRowNode(rowNodeId).rowIndex;

  @action
  async productNavigation(rowNode: RowNode) {
    if (rowNode) {
      this.rowId = rowNode.id;
      this.productId = rowNode.data[Product.schema.id.description.nameKey];
      await this.loadModal(this.isNewCRP, this.competitorId, this.zoneId, this.productId, this.rowId);
    }
  }

  /** After filtering in grid is necessary to update list of visible rows due to correct navigation in modal */
  @action
  updateListOfShownRows = () => {
    this.listOfShownRows = [];
    if (this.dataCleaningProductsPageGridApi) {
      this.dataCleaningProductsPageGridApi.forEachNodeAfterFilterAndSort((node: RowNode) => this.listOfShownRows.push(node.id));
    }
  };

  @action
  loadModal = async (isNewCRP, competitorId, zoneId, productId, rowId) => {
    runInAction(() => {
      this.isNewCRP = isNewCRP;
      this.newCRP = undefined;
      this.actualCRP = undefined;
    });
    if (!this.listOfShownRows || Array.isArray(this.listOfShownRows) || this.listOfShownRows.length === 0) {
      this.updateListOfShownRows();
    }
    this.dataCleaningProductPageModel = this.dataCleaningProductsPageGridApi.getFilterModel();
    const crp = this.CRPStore.getDTOForCompetitorAndZone(productId, competitorId, zoneId);
    runInAction(() => {
      this.newCRP = (crp) ? crp.newPrice : null;
      this.actualCRP = (crp) ? crp.actualPrice : null;
    });
    this.zoneId = zoneId;
    this.competitorId = competitorId;
    this.productId = productId;
    this.rowId = rowId;
    runInAction(() => (this.modalHidden = false));
    await this.loadData();
  };

  @action
  closeModal = () => {
    this.listOfShownRows = [];
    if (this.dataCleaningProductPageModel !== undefined) {
      this.dataCleaningProductsPageGridApi.setFilterModel(this.dataCleaningProductPageModel);
      this.dataCleaningProductPageModel = undefined;
    }
    runInAction(() => (this.modalHidden = true));
    runInAction(() => (this.newCRP = undefined));
    runInAction(() => (this.actualCRP = undefined));
    this.zoneId = undefined;
    this.competitorId = undefined;
    this.productId = undefined;
    this.rowId = undefined;
  };

  /** Updates priceType value */
  updatePriceType = async (rowId: string, column?: string, value?: PriceClassification) => {
    const competitorPriceId: string = this.rowData[rowId][CompetitorPrice.schema.id.description.nameKey];
    const competitorPrice = this.competitorPriceStore.internalProductsMap
      .get(this.productId)
      .find((price) => price.id === competitorPriceId);

    const flagIndex = competitorPrice.flags.findIndex((flag) => flag.priceZoneId === this.zoneId);
    const updatedFlags = competitorPrice.flags.update(flagIndex, (flag) => flag.copy('userFlag', value));
    const productId = this.productId;

    return this.competitorPriceStore
      .updateCompetitorPrice(productId, competitorPrice.copy('flags', updatedFlags))
      .then(() => {
        if (this.productId === productId) {
          /** LOG-3142(Updating newCRP value after price type changes) */
          Promise.all([
            this.CRPStore.getNewCRP({
              competitorProductId: competitorPrice.competitorProductId,
              priceZoneId: this.zoneId,
            }),
            this.CRPStore.getCRPAndFlags({productId}),
          ]).then(([newCRP]) => {
            runInAction(() => (this.isNewCRP = true));
            runInAction(() => (this.newCRP = newCRP));
          });
        } else {
          // Modal has been closed, update only CRP and flag in the store for the product
          this.CRPStore.getCRPAndFlags({productId});
        }
      });
  };

  /**
   * Used in modal headline
   * returns CRP/newCRP/null value depends which one should be shown
   */
  public getCRPOrNewCRPValue(): BigDecimal | null {
    if (this.isNewCRP) {
      return (this.newCRP) ? this.newCRP.modifiedValue : null;
    } else {
      return (this.actualCRP) ? this.actualCRP.modifiedValue : null;
    }
  }

  /** Fetches all data for the modal */
  @action
  async loadData() {
    this.setLoadingState(LoadingState.Pending);
    try {
      const [competitorPrices] = await Promise.all([
        this.competitorPriceStore.getCompetitorPricesInZone(this.productId, this.zoneId, this.competitorId),
        this.dataUploadStore.getAll(),
      ]);
      if (this.zoneId) {
        // checking price zone - modal could be closed during loading
        if (competitorPrices.length > 0) {
          const {competitorProductId} = competitorPrices[0];
          const newCRP = await this.CRPStore.getNewCRP({competitorProductId, priceZoneId: this.zoneId});
          runInAction(() => (this.newCRP = newCRP));
        }
      }
      if (this.zoneId) {
        // checking price zone - modal could be closed during loading
        const competitorPriceIds = competitorPrices.map((price) => price.id);
        await Promise.all([
          this.competitorPriceStore.getCompetitorPriceHistoryIndexes(competitorPriceIds, this.zoneId),
          this.competitorPriceStore.getModifiedPrices(competitorPriceIds),
        ]);
      }
      this.setLoadingState(LoadingState.Success);
    } catch (error) {
      // this.messages.setError(error);
      this.setLoadingState(LoadingState.Error);
    }
  }

}
