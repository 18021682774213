"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Enum of possible promotion types.
 * @author OPt
 */

var PromotionTypesEnum;

(function (PromotionTypesEnum) {
  PromotionTypesEnum["Leaflet"] = "Leaflet";
  PromotionTypesEnum["NonLeaflet"] = "Non-Leaflet";
  PromotionTypesEnum["Catalogue"] = "Catalogue";
})(PromotionTypesEnum = exports.PromotionTypesEnum || (exports.PromotionTypesEnum = {}));